const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  mapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  autocompleteInput: {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: 260,
    height: 32,
    padding: '0 12px',
    borderRadius: 3,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: 14,
    outline: 'none',
    textOverflow: 'ellipses',
    position: 'absolute',
    left: 'calc(50% - 130px)',
    top: 20,
  },
  autocompleteInputMobile: {
    width: 230,
    left: 'calc(50% - 115px)',
  },
  sliderContainer: {
    display: 'flex',
    position: 'absolute',
    boxSizing: 'border-box',
    bottom: 13,
    left: 'calc(50% - 140px)',
    width: 280,
    padding: '5px 15px',
    paddingTop: 7,
    backgroundColor: theme.backgrounds.cardBackground,
    borderRadius: 10,
  },
  sliderContainerMobile: {
    width: 230,
    left: 'calc(50% - 115px)',
    height: 34,
  },
  thumb: {
    padding: 0,

    '&:after': {
      width: 12,
      height: 12,
    },

    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 5px rgba(139, 195, 74, 0.16)',
    },
  },
  slider: {
    padding: '11px 0',
  },
  sliderValue: {
    width: 80,
    fontSize: '1rem',
    marginRight: '.5rem',
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
