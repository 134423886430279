import React from 'react'
import { Paper } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import RoomsStatsItemList from './RoomsStatsItemList/RoomsStatsItemList'

class RoomsStats extends React.Component {
  componentWillUnmount() {
    const { closeRoomStatsPopup } = this.props
    closeRoomStatsPopup()
  }

  render() {
    const {
      classes,
      RoomsStatsItems,
      isMobile,
      isRoomsStatsPopupOpen,
      openRoomStatsPopup,
      selectCurrentRoomIdAndName,
      syncCurrentRoomStats,
    } = this.props
    return (
      <Paper className={classNames(
        classes.main,
        !isMobile && classes.mainDesktop,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,

      )}
      >
        <Wrapper className={classes.wrapper}>
          <RoomsStatsItemList
            className={classes.itemList}
            RoomsStatsItems={RoomsStatsItems}
            isRoomsStatsPopupOpen={isRoomsStatsPopupOpen}
            isMobile={isMobile}
            openRoomStatsPopup={openRoomStatsPopup}
            selectCurrentRoomIdAndName={selectCurrentRoomIdAndName}
            syncCurrentRoomStats={syncCurrentRoomStats}
          />
        </Wrapper>
      </Paper>
    )
  }
}

RoomsStats.propTypes = {
  classes: PropTypes.object.isRequired,
  RoomsStatsItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    billing: PropTypes.number.isRequired,
    energy: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  isRoomsStatsPopupOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  openRoomStatsPopup: PropTypes.func.isRequired,
  closeRoomStatsPopup: PropTypes.func.isRequired,
  selectCurrentRoomIdAndName: PropTypes.func.isRequired,
  syncCurrentRoomStats: PropTypes.func.isRequired,
}

RoomsStats.displayName = 'RoomsStats'

export default RoomsStats
