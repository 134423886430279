import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/accounts?only=app_config`

const getAppConfig = (token) => Axios.get(ENDPOINT,
  {
    headers: { 'Access-Token': token },
  })

export default {
  getAppConfig,
}
