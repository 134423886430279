import React from 'react'
import { Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IActionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './Description.style'

interface Props {
  action: IActionFromBackend,
}
const Description: React.FC<Props> = (props) => {
  const {
    action,
  } = props
  const classes = useStyles()
  return (
    <Wrapper className={classes.descriptionWrapper}>
      <Typography
        className={classes.sceneInfoText}
      >
        {action.description}
      </Typography>
    </Wrapper>
  )
}

export default Description
