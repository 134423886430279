import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 40">
      <path fill="currentColor" fillRule="evenodd" d="M19.1 9.77V3.02c0-.29-.2-.51-.33-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.74 20.74 0 0 0 9.7 0C7.24 0 4.95.38 3.27.93c-.83.28-1.54.6-2.05.96-.49.34-.92.8-.92 1.37v6.5c0 .65.48 1.22 1.04 1.68.58.47 1.38.92 2.28 1.31.84.36 1.8.68 2.76.91.13.54.56.97 1.07 1.24.6.34 1.42.54 2.3.54.87 0 1.68-.2 2.3-.54.5-.28.94-.7 1.06-1.25.93-.23 1.85-.53 2.66-.88a9.7 9.7 0 0 0 2.29-1.32c.56-.46 1.03-1.03 1.03-1.68Zm-5.97 3.4.67-.2c.04-.13.07-.27.07-.41 0-1.32-1.85-2.4-4.13-2.4-2.27 0-4.12 1.08-4.12 2.4 0 .15.03.3.07.44l.67.18c.07-.62.54-1.09 1.09-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.68.2 2.3.54.53.3 1 .77 1.08 1.37Zm-7.97-.34c-2.41-.79-4.4-2.02-4.4-3.06V7.33C1.59 6.85 5.02 5.03 9.6 5c4.6 0 8.19 1.85 9.03 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04l.02-.25c0-.92-.64-1.65-1.46-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.81.47-1.45 1.2-1.45 2.12 0 .09 0 .18.02.27ZM.76 3.26c0-1.3 4.1-2.8 8.94-2.8 4.83 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.03-2.3c-4.43 0-8 1.84-8.83 2.31v-1.3Zm0 1.93v1.5A19.1 19.1 0 0 1 9.6 4.37a18.1 18.1 0 0 1 9.03 2.31V5.2A19.26 19.26 0 0 0 9.6 2.87 18.14 18.14 0 0 0 .77 5.2Zm11.91 8.16c0 .9-1.3 1.62-2.93 1.62-1.61 0-2.93-.72-2.93-1.62 0-.9 1.32-1.63 2.93-1.63 1.62 0 2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M5.54 19.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.25-1.15c1 0 1.98-.29 2.76-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.12.1.37.37 0 0 0-.08.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="m7.55 33.2.72.18c-.15.6-.42 1.05-.8 1.36-.41.33-.92.5-1.43.48-.58 0-1.05-.12-1.42-.37a2.25 2.25 0 0 1-.83-1.04 3.8 3.8 0 0 1 .04-2.97c.2-.41.51-.75.9-.97.4-.22.85-.34 1.3-.33.48-.02.96.13 1.35.42.37.3.64.71.76 1.18l-.72.17a1.7 1.7 0 0 0-.53-.87 1.4 1.4 0 0 0-.89-.27c-.36-.01-.72.1-1.02.3-.28.2-.48.48-.59.8a3.6 3.6 0 0 0 .04 2.28c.11.32.33.6.62.78.28.17.6.26.92.26.37 0 .73-.12 1.02-.36.28-.23.47-.57.56-1.04Zm6.18-2.29a2.38 2.38 0 0 0-2.24-1.37 2.4 2.4 0 0 0-1.85.77 3.02 3.02 0 0 0-.72 2.14c0 .47.1.94.31 1.37.2.42.51.78.9 1.02a2.6 2.6 0 0 0 2.65.04c.4-.23.73-.58.93-1a3.6 3.6 0 0 0 .02-2.97Zm-.92 3.1a1.82 1.82 0 0 1-2.65 0 2.2 2.2 0 0 1-.52-1.55c0-.83.18-1.41.55-1.76.35-.35.81-.54 1.3-.53.34 0 .67.09.96.27.29.19.52.46.65.77.16.37.23.77.22 1.17 0 .7-.17 1.25-.51 1.63Zm2.8-3.76v.3h-1.55c0-.08 0-.15.03-.21.04-.12.1-.23.19-.33.1-.13.23-.26.37-.37.2-.16.37-.33.53-.52.1-.1.14-.22.15-.35a.39.39 0 0 0-.13-.3.53.53 0 0 0-.68.02.5.5 0 0 0-.13.36l-.29-.04c.01-.2.1-.39.24-.52.15-.13.34-.2.54-.19.2 0 .39.06.54.2.13.12.2.3.2.48 0 .1-.03.2-.06.28a1.2 1.2 0 0 1-.2.3c-.14.15-.29.3-.44.43a8.1 8.1 0 0 0-.48.46h1.17ZM2.38 30.2c.1-.92.58-1.38 1.47-1.38L2.38 30.2Zm11.38 5.96c.66.2 1.15-.15 1.47-1.05l-1.47 1.05ZM5.1 37.22c.49.3 1.06.37 1.73.22l-1.72-.22Z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M18.2 36.17a2.2 2.2 0 0 0-.18-1.08 3.94 3.94 0 0 0 1.2-1.72c.2-.6.27-1.3 0-2a3 3 0 0 0-1.2-1.43c0-.24-.05-.47-.1-.7-.11-.44-.3-.9-.57-1.3v-.01a4.2 4.2 0 0 0-2.46-1.75 4.07 4.07 0 0 0-2.97.3 4.58 4.58 0 0 0-2.5-2.26 3.41 3.41 0 0 0-2.53-.07c-.81.33-1.51 1-2.09 2.08a5.63 5.63 0 0 0-3.18 1.08 4.2 4.2 0 0 0-1.56 2.7 6.18 6.18 0 0 0 1.67 5.06c-.22.84-.19 1.66.26 2.38a3.77 3.77 0 0 0 2.26 1.49c.24.36.56.65.96.84.51.24 1.09.27 1.66.16a9.55 9.55 0 0 0 3.53-1.71 3.04 3.04 0 0 0 3.06.26c.42.39.92.64 1.52.64.8 0 1.55-.45 2.22-1.08.55-.52.96-1.14 1-1.88Zm-4.55 1.28c.77 1.15 1.77 1.15 3 0 .7-.64.91-1.3.66-1.96-.1-.25-.25-.5-.47-.74.22 0 .49-.14.75-.36.96-.84 1.83-2.9-.41-4 .13-.52-.07-1.34-.5-2a3.4 3.4 0 0 0-2-1.42 3.3 3.3 0 0 0-2.5.28 6.5 6.5 0 0 0-.73.48c-.03-.3-.1-.57-.22-.84a3.73 3.73 0 0 0-2.1-1.9c-1.22-.48-2.7-.4-3.83 2.04-5.72.05-5.25 5.63-2.64 7.8-.7 1.77 0 2.9 2.12 3.4.73 1.55 2.6 1.2 5.64-1 1.06.9 2.14.98 3.23.22Z" clipRule="evenodd"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CO2Icon', '0 0 80 80', {fontSize: 35})

export default icon
