export const mezzoStateTypes = [
  {
    title: 'brightness_mezzo',
    description: 'mezzo_brightness',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'mezzo_brightness',
  },
  {
    title: 'colour_mezzo',
    description: 'mezzo_colour',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'mezzo_colour',
  },
]
