import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button, Card, CardHeader, IconButton, Typography,
} from '@mui/material'
import { ChevronRight } from 'icons'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import getSelectedUsersString from '@app/helpers/scenes/getSelectedUsersString'
import {
  saveActionDeviceStateInfo,
  saveActionStateDescription,
  saveInitialActionInfo,
  saveText,
} from '@app/actions/scenes/currentSceneCreating'
import { CREATE_ACTION_SELECTED, EDIT_ACTION, SELECT_NOTIFICATION_USER } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CustomTextField from '@app/components/Common/CustomTextField/CustomTextField'
import useStyles from './PushAndEmailFields.style'

const PushAndEmailFields = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isEditActionMode } = useSelector((state) => state.scenes.currentSceneCreating)
  const { selectedUsers, text, actionType } = useSelector((state) => state.scenes.currentSceneCreating.currentAction)

  const selectUsers = () => {
    dispatch(saveInitialActionInfo())
    navigate(SELECT_NOTIFICATION_USER)
  }

  const onNextClick = () => {
    dispatch(saveActionStateDescription(getSelectedUsersString(selectedUsers)))
    dispatch(saveActionDeviceStateInfo(
      getActionDeviceStateInfo(actionType, '', 0, '', '', '', '', '', '', '', '', '', selectedUsers, text),
    ))
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }
  const textFieldChange = (event) => {
    dispatch(saveText(event.target.value))
  }
  const checkLongText = () => {
    if (actionType === 'PushNotifications') {
      return text.length >= 51
    }
    return text.length >= 1001
  }

  return (
    <>
      <Card className={classes.userCard}>
        <CardHeader
          action={(
            <IconButton aria-label="settings" size="large">
              <ChevronRight />
            </IconButton>
          )}
          title={`${I18n.t('scene_action_web.users')}:`}
          subheader={selectedUsers.length ? getSelectedUsersString(selectedUsers) : 'N/A'}
          onClick={selectUsers}
          classes={{
            title: classes.userTitle,
            subheader: classes.userSubHeader,
          }}
        />
      </Card>
      <Wrapper className={classes.textFieldWrapper}>
        <CustomTextField
          id="outlined-input"
          label={`${I18n.t('scene_action_web.enter_text')}:`}
          variant="outlined"
          multiline
          value={text}
          onChange={textFieldChange}
          helperText={(
            checkLongText() && (
              <Typography component="span" className={classes.errorText}>
                {actionType === 'PushNotifications' ? I18n.t('scene_action_web.push_too_long') : I18n.t('scene_action_web.email_too_long')}
              </Typography>
            )
          )}
        />
      </Wrapper>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={onNextClick}
        disabled={!selectedUsers.length || !text.length || checkLongText()}
      >
        {I18n.t('buttons.next')}
      </Button>
    </>
  )
}

export default PushAndEmailFields
