import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import sortDevicesGroups from '@app/helpers/scenes/sortDevicesGroups'
import { SYNC_TWR_DEVICES_BY_ROOMS } from '@app/constants/scenes/twrDevices/twrDevicesByRooms'
import { updateTwrDevicesByRooms } from '@app/actions/scenes/twrDevices/twrDevicesByRooms'
import fetchTwrDevicesByRooms from '@app/api/scenes/TwrDevices/fetchTwrDevicesByRooms'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchTwrDevicesByRoomsLogic = createLogic({
  type: SYNC_TWR_DEVICES_BY_ROOMS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_TWR_DEVICES_BY_ROOMS))
    fetchTwrDevicesByRooms(token)
      .then((response) => {
        const { data: { rooms } } = response
        const sortedRooms = sortDevicesGroups(rooms, 'TwrDevices')
        dispatch(updateTwrDevicesByRooms(sortedRooms))
        dispatch(apiRequestDone(SYNC_TWR_DEVICES_BY_ROOMS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchTwrDevicesByRooms failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchTwrDevicesByRoomsLogic
