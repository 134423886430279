import React from 'react'
import { Icon, ListItemIcon, Typography } from '@mui/material'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { checkIconNumber } from '@app/helpers/myDevices/checkIconNumber'
import withSelectDeviceActionBehavior from '@app/hooks/withSelectDeviceActionBehavior'
import { ITwrDeviceBackend, IZwaveDeviceBackend } from '@app/@types/scenes/scenesDevices'
import useStyles from './Device.style'

interface Props {
  device: ITwrDeviceBackend | IZwaveDeviceBackend,
  deviceType: string,
}

const Device: React.FC<Props> = (props) => {
  const {
    device,
    deviceType,
  } = props

  const classes = useStyles()
  const selectDevice = withSelectDeviceActionBehavior(deviceType, device)

  return (
    <Wrapper
      className={classes.device}
      onClick={() => {
        selectDevice()
      }}
    >
      <ListItemIcon className={classes.iconWrapper}>
        <Icon fontSize="large" className={classes.icon}>{devicesIcons[checkIconNumber(device.icon)]}</Icon>
      </ListItemIcon>
      <Typography className={classes.title} variant="h6" component="h2">
        {device.name}
      </Typography>
    </Wrapper>
  )
}

export default Device
