import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  activateChangeSubDeviceMode,
  activateChangeSubDeviceStateMode,
  deactivateChangeSubDeviceMode,
  deactivateChangeSubDeviceStateMode,
  restoreConditionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectCentralSceneStateSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneStateSelected/SelectCentralSceneStateSelected'
import SelectCentralSceneStateSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneStateSelected/SelectCentralSceneStateSelected'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

let enhance = isIOS
  ? withStyles(SelectCentralSceneStateSelectedIOS)
  : withStyles(SelectCentralSceneStateSelected)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const { dataNumber, dataValue } = state.scenes.currentSceneCreating.currentCondition
  return {
    dataNumber,
    dataValue,
    isEditConditionMode,
    isChangeConditionStateMode,
    isMobile,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  activateChangeSubDeviceMode,
  activateChangeSubDeviceStateMode,
  deactivateChangeSubDeviceMode,
  deactivateChangeSubDeviceStateMode,
  restoreConditionInfo,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectCentralSceneStateSelectedEnhanced = enhance

export default withRouter(SelectCentralSceneStateSelectedEnhanced)
