import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 15">
      <path fill="currentColor" d="M22.5 6.25H4.788l4.474-4.487L7.5 0 0 7.5 7.5 15l1.762-1.762L4.787 8.75H22.5v-2.5z"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'ArrowIcon')

export default icon
