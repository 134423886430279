import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Button, Paper, Typography } from '@mui/material'
import getActionDescription from '@app/helpers/scenes/getActionDescription'
import { sceneActionSelected } from '@app/lib/Scenes/actions'
import { MAX_ACTIONS_CHAIN } from '@app/lib/Scenes/scenesRestrictions'
import {
  SCENE_REVIEW, SCENE_REVIEW_EDIT, SCENES, SELECT_ACTION_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import Info from '@app/components/Common/Info/Info'
import EditPopups from '@app/components/MyAutomation/Tabs/ScenesScreens/EditScenesCommon/EditPopups'
import { useDispatch } from '@app/store'
import { deleteScene } from '@app/actions/scenes/scenesList'
import {
  activateChangeActionMode,
  addNewActionToChain,
  closeSceneSaveDraftPopup,
  deactivateChangeActionMode,
  deactivateChangeActionStateMode,
  openSceneSaveDraftPopup,
  postAction,
  saveInitialActionInfo,
  saveScreenName,
} from '@app/actions/scenes/currentSceneCreating'
import {
  openEditDraftSavePopup,
  openEditSceneQuitPopup,
} from '@app/actions/scenes/editScene'
import useStyles from './CreateActionSelected.style'
import StateBlockCommon from './StateBlockCommon'

const defaultProps = {
  buttonName: 'submit',
}

interface Props {
  isMobile: boolean,
  isSaveSceneDraftPopupOpen: boolean,
  actionDescription: string,
  stateDescription: string,
  buttonName: string,
  sceneMode: string,
  isAdditionalActionMode: boolean,
  isEditDraftSavePopupOpen: boolean,
  isEditSceneQuitPopupOpen: boolean,
  isAdditionalConditionMode: boolean,
  actionType: string,
  actionDeviceName: string,
  colorType: string,
  delayDescription: string,
  durationDescription: string,
  durationValueAfter: string,
  colorTypeDurationValueAfter: string,
  sceneActionTypes: Array<string>,
  subDeviceName: string,
}

const CreateActionSelected: React.FC<Props> = (props) => {
  const {
    isMobile,
    isSaveSceneDraftPopupOpen,
    actionDescription,
    stateDescription,
    buttonName = defaultProps.buttonName,
    sceneMode,
    isAdditionalActionMode,
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
    isAdditionalConditionMode,
    actionType,
    actionDeviceName,
    colorType,
    delayDescription,
    durationDescription,
    durationValueAfter,
    colorTypeDurationValueAfter,
    sceneActionTypes,
    subDeviceName,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const [isPostButtonDisabled, changePostButtonDisabled] = useState<boolean>(false)
  const [isTooManyActionsPopupOpen, changeTooManyActionsPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(deactivateChangeActionMode())
    dispatch(deactivateChangeActionStateMode())
    dispatch(saveInitialActionInfo())
  }, [])

  const getScreenName = () => {
    switch (sceneMode) {
    case 'editMezzoScene':
    case 'editDraftScene':
      return SCENE_REVIEW_EDIT
    default:
      return SCENE_REVIEW
    }
  }

  const changeActionType = () => {
    dispatch(activateChangeActionMode())
    navigate(SELECT_ACTION_TYPE)
  }

  const onSubmitClick = () => {
    const screenName = getScreenName()
    dispatch(saveScreenName(screenName))
    changePostButtonDisabled(true)
    dispatch(postAction())
  }

  const saveDraftAccepted = () => {
    dispatch(saveScreenName(SCENES))
    dispatch(postAction())
  }

  const saveDraftDeclined = () => {
    dispatch(deleteScene())
    navigate(SCENES)
    dispatch(closeSceneSaveDraftPopup())
  }

  const onCloseIconClick = () => {
    dispatch(closeSceneSaveDraftPopup())
  }

  const onBackClick = () => {
    if (sceneMode === 'editDraftScene') {
      dispatch(openEditDraftSavePopup())
    } else if (sceneMode === 'editMezzoScene') {
      dispatch(openEditSceneQuitPopup())
    } else if (isAdditionalActionMode) {
      navigate(SCENE_REVIEW)
    } else {
      dispatch(openSceneSaveDraftPopup())
    }
  }

  const onAdditionalActionButtonsClick = () => {
    if (sceneActionTypes.length === MAX_ACTIONS_CHAIN) {
      changeTooManyActionsPopup()
    } else {
      dispatch(saveScreenName(SELECT_ACTION_TYPE))
      dispatch(addNewActionToChain(actionType))
      changePostButtonDisabled(true)
      dispatch(postAction())
    }
  }

  const renderTooManyActionsPopup = () => (
    <>
      <Info
        open={isTooManyActionsPopupOpen}
        title="my_users.info"
        warningText="errors.actions_maximum_error"
        onClick={changeTooManyActionsPopup}
        buttonName="buttons.okay"
      />
    </>
  )

  const changeTooManyActionsPopup = () => {
    changeTooManyActionsPopupOpen(!isTooManyActionsPopupOpen)
  }

  const renderSceneCreatingAdditionalButtons = () => (
    <Wrapper className={classes.sceneCreatingAdditionalWrapper}>
      <Wrapper className={classes.buttonWrapper}>
        <Button
          onClick={() => onAdditionalActionButtonsClick()}
          variant="outlined"
          className={classes.andButton}
        >
          {I18n.t('buttons.and')}
        </Button>
        <Button
          onClick={() => onSubmitClick()}
          variant="outlined"
          className={classes.finishButton}
          disabled={isPostButtonDisabled}
        >
          {I18n.t('buttons.finish')}
        </Button>
      </Wrapper>
    </Wrapper>
  )

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={onBackClick}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.create_action')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        <Wrapper>
          <CardBlock
            key={sceneActionSelected.title}
            title={I18n.t(`scene_web.${sceneActionSelected.title}`)}
            description={getActionDescription(actionType, actionDescription, actionDeviceName)}
            position={sceneActionSelected.position}
            isCardDisabled={sceneActionSelected.isCardDisabled}
            isButtonDisabled={sceneActionSelected.isButtonDisabled}
            onClick={() => {
              changeActionType()
            }}
          />
          <StateBlockCommon
            stateDescription={stateDescription}
            actionType={actionType}
            colorType={colorType}
            delayDescription={delayDescription}
            durationDescription={durationDescription}
            durationValueAfter={durationValueAfter}
            colorTypeDurationValueAfter={colorTypeDurationValueAfter}
            subDeviceName={subDeviceName}
          />
        </Wrapper>
        {(sceneMode !== 'create' || (sceneMode === 'create' && isAdditionalActionMode)) && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.submitButton}
            onClick={onSubmitClick}
            disabled={isPostButtonDisabled}
          >
            {I18n.t(`buttons.${buttonName}`)}
          </Button>
        )}
        {(sceneMode === 'create' && !isAdditionalActionMode) && renderSceneCreatingAdditionalButtons()}
      </Wrapper>
      <WarningComponent
        open={isSaveSceneDraftPopupOpen}
        onClose={() => {
          saveDraftDeclined()
        }}
        withCloseIcon
        onCloseIconClick={onCloseIconClick}
        onClick={saveDraftAccepted}
        warningText="scene_web.save_draft"
        leftButton="yeah"
        rightButton="nope"
      />
      <EditPopups
        isEditDraftSavePopupOpen={isEditDraftSavePopupOpen}
        isEditSceneQuitPopupOpen={isEditSceneQuitPopupOpen}
        sceneMode={sceneMode}
        isAdditionalConditionMode={isAdditionalConditionMode}
        isAdditionalActionMode={isAdditionalActionMode}
      />
      {renderTooManyActionsPopup()}
    </Paper>
  )
}

export default CreateActionSelected
