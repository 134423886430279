const styles = (theme) => ({
  '@keyframes breathe': {
    '0%': {},
    '100%': {
      transform: 'translate(0, 0)',
    },
    '50%': {
      transform: 'translate(10px, 0)',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  mezzoList: {
    display: 'block',
  },
  arrowWrapper: {
    display: 'block',
    marginLeft: -9,
  },
  deviceWrapper: {
    maxWidth: 450,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '13px 40px',
    cursor: 'pointer',
    backgroundColor: theme.backgrounds.mainBg,
    position: 'relative',
    '&:hover $arrowWrapper': {
      animationName: '$breathe',
      animationDuration: '1s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#000',
      opacity: 0,
      transition: '0.2s ease-in',
    },
    '&:hover::before': {
      opacity: 0.1,
    },
  },
  leftSideWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    marginRight: 20,
    marginTop: 4,
  },
  arrowIcon: {
    transform: 'rotate(180deg)',
  },
  sliderNav: {
    marginTop: 15,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nextButton: {
    display: 'block',
    background: 'transparent',
    position: 'absolute',
    right: 45,
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
    border: 'none',
  },
  dots: {
    borderRadius: '50%',
    '& > .carousel__dot': {
      borderRadius: '50%',
      width: 12,
      height: 12,
      padding: 0,
      opacity: 0.2,
      border: '1px solid #000',
      background: theme.palette.primary.main,
      outline: 'none',
      margin: '0 4px',
    },
    '& > .carousel__dot--selected': {
      opacity: 1,
    },
  },
  deviceSsid: {
    fontSize: 12,
  },
})

export default styles
