const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  title: {
    fontSize: 20,
    height: 17,
    textTransform: 'uppercase',
  },
  popupContent: {
    marginTop: 12,
    paddingTop: 4,
    minHeight: 70,
    width: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 0,
  },
  errorMessage: {
    color: theme.myStatus.subtitle,
    fontSize: 16,
  },
  popupButtons: {
    alignSelf: 'flex-end',
  },
})

export default styles
