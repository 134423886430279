const convertUserToForm = (backendUser) => [
  { title: 'preferredName', value: backendUser.preferredName, hint: 'preferred_name' },
  { title: 'firstName', value: backendUser.firstName, hint: 'first_name' },
  { title: 'middleName', value: backendUser.middleName, hint: 'middle_name' },
  { title: 'lastName', value: backendUser.lastName, hint: 'last_name' },
  { title: 'email', value: backendUser.email, hint: 'email' },
  { title: 'mobile', value: backendUser.mobile, hint: 'phone_number' },
]

export default convertUserToForm
