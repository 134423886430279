import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    marginBottom: 20,
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  mapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    marginBottom: 30,
  },
  address: {
    fontSize: '25px',
    lineHeight: '29px',
    letterSpacing: '-0.035em',
    textAlign: 'center',
    marginBottom: 25,
  },
  addressMobile: {
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '-0.010em',
    textAlign: 'center',
    marginBottom: 25,
  },
  wrapperCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '17px 4%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  wrapperCardMobile: {
    padding: '8px 4%',
  },
  chevronIcon: {
    transform: 'rotate(180deg)',
  },
  alertWrapper: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#BC4B51',
    borderRadius: '10px',
    height: 260,
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 60,
  },
  alertWrapperDisabled: {
    backgroundColor: 'transparent',
  },
  alertButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 65,
    background: '#AC2534',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '10px',
    fontSize: '30px',
    lineHeight: '35px',
    letterSpacing: '-0.035em',
    color: '#fff',
  },
  callButtonDisabled: {
    backgroundColor: 'transparent',
    border: '2px solid rgba(255, 255, 255, .35)',
  },
  alertButtonDisabled: {
    border: '2px solid rgba(255, 255, 255, .35)',
  },
  exclamationIcon: {
    marginTop: 29,
  },
  exclamationIconCountdown: {
    marginTop: 29,
    animationName: '$breathe',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  cancelIcon: {
    marginTop: 29,
    transform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000px',
    animationName: '$shake',
    animationDuration: '4s',
    animationTimingFunction: 'cubic-bezier(.36,.07,.19,.97)',
    animationIterationCount: 'infinite',
  },
  alertWrapperCountdown: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#87A63E',
    borderRadius: '10px',
    height: 260,
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 60,
  },
  alertButtonCountdown: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 65,
    background: '#4B833C',
    border: '2px solid #000000',
    boxSizing: 'border-box',
    borderRadius: '10px',
    fontSize: '25px',
    lineHeight: '29px',
    letterSpacing: '-0.035em',
    color: '#000000',
  },
  alertButtonCountdownMobile: {
    fontSize: '16px',
    letterSpacing: '-0.06em',
  },
  medicLoad: {
    marginTop: 80,
  },
  '@keyframes breathe': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes shake': {
    '0%, 20%, 100%': {
      transform: 'translate3d(0, 0, 0)',
    },
    '2%, 6%, 10%, 14%, 18%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '4%, 8%, 12%, 16%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },
}))

export default useStyles
