import React, { useEffect, useState } from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton,
} from '@mui/material'
import { Close } from 'icons'
import useStyles from './TwoButtonsPopup.style'
import Wrapper from '../Wrapper/Wrapper'

interface Props {
  open: boolean,
  firstButtonOnClick: () => void,
  secondButtonOnClick: () => void,
  text: string,
  title: string,
  firstButtonName: string,
  secondButtonName: string,
  disableFirstButton?: boolean,
  disableSecondButton?: boolean,
  withCloseIcon?: boolean,
  closeIconOnClick?: () => void,
}

const TwoButtonsPopup: React.FC<Props> = (props) => {
  const {
    open,
    title,
    text,
    withCloseIcon,
    closeIconOnClick,
    firstButtonName,
    secondButtonName,
    firstButtonOnClick,
    secondButtonOnClick,
    disableFirstButton,
    disableSecondButton,
  } = props
  const [openState, setOpenState] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    setOpenState(open)
  }, [open])

  return (
    <Dialog
      className={classes.root}
      open={openState}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t(`${title}`)}
      </DialogTitle>
      <DialogContent>
        {withCloseIcon && (
          <IconButton
            aria-label="Close"
            className={classes.closeIconButton}
            onClick={closeIconOnClick}
            size="large"
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        )}
        <DialogContentText
          variant="body1"
          className={classes.text}
        >
          {I18n.t(`${text}`)}
        </DialogContentText>
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          className={classes.button}
          onClick={firstButtonOnClick}
          color="primary"
          disabled={disableFirstButton}
        >
          {I18n.t(`${firstButtonName}`)}
        </Button>
        <Button
          className={classes.button}
          onClick={secondButtonOnClick}
          color="primary"
          disabled={disableSecondButton}
        >
          {I18n.t(`${secondButtonName}`)}
        </Button>
      </Wrapper>
    </Dialog>
  )
}

TwoButtonsPopup.defaultProps = {
  disableFirstButton: false,
  disableSecondButton: false,
  withCloseIcon: true,
  closeIconOnClick: () => {},
}

export default TwoButtonsPopup
