import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ROOMS_STATS } from '@app/constants/myEnergy/roomsStats'
import { updateRoomsStats } from '@app/actions/myEnergy/roomsStats'
import Api from '@app/api/myEnergy/fetchRoomsStats'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchRoomsStatsLogic = createLogic({
  type: SYNC_ROOMS_STATS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const currentDate = new Date()
    dispatch(apiRequestSent(SYNC_ROOMS_STATS))
    Api.fetchRoomsStats(AccessToken, currentDate)
      .then((response) => {
        const { data } = response
        dispatch(updateRoomsStats(data.rooms))
        dispatch(apiRequestDone(SYNC_ROOMS_STATS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchRoomsStats failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchRoomsStatsLogic
