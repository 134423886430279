const styles = (theme) => ({
  group: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 36,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 100,
    width: 100,
    cursor: 'pointer',
    padding: '15px 5px 0px 5px',
  },
  title: {
    width: '90%',
    fontSize: '12px',
    marginBottom: 11,
    textAlign: 'center',
    padding: '0 16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
})

export default styles
