import * as type from '@app/constants/scenes/zWaveDevices/subDevices'

export const syncSubDevices = () => ({
  type: type.SYNC_SUB_DEVICES,
})

export const updateSubDevices = (subDevices) => ({
  type: type.UPDATE_SUB_DEVICES,
  subDevices,
})
