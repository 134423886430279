import React from 'react'
import { useDispatch } from 'react-redux'
import { FormControl, Switch } from '@mui/material'
import {
  changeSceneEnabledValue,
  patchSceneEnableValue,
  selectCurrentSceneEnabled,
  selectCurrentSceneId,
} from '@app/actions/scenes/scenesList'
import useStyles from './Toggle.style'

interface Props {
  enabled: boolean,
  sceneIndex: number,
  id: number,
}
const Toggle: React.FC<Props> = (props) => {
  const {
    enabled,
    sceneIndex,
    id,
  } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  const changeToggleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSceneEnabledValue(sceneIndex, event.target.checked))
    dispatch(selectCurrentSceneId(id))
    dispatch(selectCurrentSceneEnabled(!enabled))
    dispatch(patchSceneEnableValue())
  }

  return (
    <FormControl variant="standard">
      <Switch
        classes={{
          switchBase: classes.iOSSwitchBase,
          track: classes.iOSBar,
          thumb: classes.iOSIcon,
          checked: classes.iOSChecked,
          root: classes.form,
        }}
        checked={enabled}
        onChange={(event) => {
          changeToggleValue(event)
        }}
        onClick={(event) => {
          event.stopPropagation()
        }}
      />
    </FormControl>
  )
}

export default Toggle
