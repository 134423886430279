export const SYNC_GEOFENCES = '@@geofences/geofencesSync'
export const SET_GEOFENCES = '@@geofences/geofencesSet'

export const TOGGLE_GEOFENCE_SIZE = '@@geofences/geofencesToggle'

export const POST_GEOFENCE = '@@geofence/postGeofence'
export const SET_POST_GEOFENCE = '@@geofence/setPostGeofence'

export const PATCH_GEOFENCE = '@@geofences/patchGeofence'

export const DELETE_GEOFENCE = '@@geofences/deleteGeofence'

export const SAVE_GEOFENCE_INFO = '@@currentSceneCreating/saveGeofenceInfo'
