import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/billing_periods`

const fetchBillingPeriodsStats = (accessToken) => Axios.get(ENDPOINT, {
  headers: {
    'Access-Token': accessToken,
  },
})

export default {
  fetchBillingPeriodsStats,
}
