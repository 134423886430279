import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '8px 16px',
    marginBottom: 25,
  },
  textContainer: {
    width: '100%',
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.borders.disabled}`,
    borderRadius: '4px',
    '&:hover': {
      borderColor: theme.borders.main,
    },
    padding: '0 14px',
  },
  text: {
    fontSize: '1rem',
  },
}))

export default useStyles
