import {
  zWaveDevicesCapabilities,
  zWaveWallmoteDevicesCapabilities,
  zWaveMultichannelDevicesCapabilities,
  supportedConditionCapabilities,
  supportedConditionMultichannelCapabilities,
  supportedConditionWallmoteCapabilities,
} from '@app/lib/Scenes/zWaveDevicesCapabilities'

const getConditionCapabilities = (capabilities) => {
  const template = []
  if (capabilities.includes('central_scene')) {
    capabilities.forEach((capability) => {
      supportedConditionWallmoteCapabilities.forEach((supportedCapability) => {
        if (capability === supportedCapability) {
          const currentCapability = zWaveWallmoteDevicesCapabilities.find((el) => el.type === capability)
          template.push(currentCapability)
        }
      })
    })
  } else if (capabilities.includes('multichannel')) {
    capabilities.forEach((capability) => {
      supportedConditionMultichannelCapabilities.forEach((supportedCapability) => {
        if (capability === supportedCapability) {
          const currentCapability = zWaveMultichannelDevicesCapabilities.find((el) => el.type === capability)
          template.push(currentCapability)
        }
      })
    })
  } else {
    capabilities.forEach((capability) => {
      supportedConditionCapabilities.forEach((supportedCapability) => {
        if (capability === supportedCapability) {
          const currentCapability = zWaveDevicesCapabilities.find((el) => el.type === capability)
          template.push(currentCapability)
        }
      })
    })
  }
  return template
}

export default getConditionCapabilities
