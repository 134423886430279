import { createLogic } from 'redux-logic'
import { SYNC_ZWAVE_CHANNEL } from '@app/constants/myDevices/channels/zwave/zwaveChannels'
import { syncZwaveChannelSent, setResponseFromZwaveChannel } from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import Api from '@app/api/myDevices/channels/zwave/zwaveChannels'

const subscribeZwaveDeviceLogic = createLogic({
  type: SYNC_ZWAVE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process({ action }, dispatch) {
    Api.myDevicesZwaveChannel({
      request: () => {
        dispatch(syncZwaveChannelSent())
      },
      response: (response) => {
        store.dispatch(setResponseFromZwaveChannel(response.status))
      },
    }, action.newDevice.id)
  },
})

export default subscribeZwaveDeviceLogic
