import React from 'react'
import classNames from 'classnames'
import { Tooltip } from '@mui/material'
import useStyles from './DotsLoading.style'

interface Props {
  className?: string,
  tooltip?: string,
  props: any,
}

const DotsLoading: React.FC<Props> = ({ className, props, tooltip }) => {
  const classes = useStyles(props)
  return (
    <>
      <Tooltip title={tooltip && I18n.t(`${tooltip}`)}>
        <div className={classNames(classes.spinner, className)}>
          <div className={classes.bounce1} />
          <div className={classes.bounce2} />
          <div className={classes.bounce3} />
        </div>
      </Tooltip>
    </>

  )
}

DotsLoading.defaultProps = {
  className: '',
  tooltip: '',
}

export default DotsLoading
