import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Fab, Paper } from '@mui/material'
import { Add } from 'icons'
import { IScene } from '@app/@types/scenes/scenes'
import countCompleteScenes from '@app/helpers/scenes/countCompleteScenes'
import ScenesList from '@app/containers/MyAutomation/ScenesList'
import { addScene } from '@app/actions/scenes/createScene'
import { changeSceneMode, clearCurrentSceneInfo, resetCurrentSceneCreating } from '@app/actions/scenes/currentSceneCreating'
import { resetCurrentSceneConditionsActions } from '@app/actions/scenes/currentSceneConditionsActions'
import { useDispatch } from '@app/store'
import Info from '@app/components/Common/Info/Info'
import { isIOS } from 'react-device-detect'
import useStyles from './MyAutomation.style'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

interface Props {
  admin: boolean,
  scenes: Array<IScene>,
  maxNumberOfScenes: number,
}

const MyAutomation: React.FC<Props> = (props) => {
  const {
    scenes,
    maxNumberOfScenes,
    admin,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isTooManyScenesPopupOpen, changeTooManyScenesPopupOpen] = useState<boolean>(false)
  const [isCreateSceneButtonDisabled, changeCreateSceneButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (isIOS && isWebView) {
      // eslint-disable-next-line no-alert
      prompt('showTopBar')
    }
    clearCurrentSceneInfo()
  }, [])

  const changeTooManyScenesPopup = () => {
    changeTooManyScenesPopupOpen(!isTooManyScenesPopupOpen)
  }

  const renderTooManyScenesPopup = () => (
    <>
      <Info
        open={isTooManyScenesPopupOpen}
        title="my_users.info"
        warningText="errors.scenes_maximum_error"
        onClick={changeTooManyScenesPopup}
        buttonName="buttons.okay"
      />
    </>
  )

  const createScene = () => {
    const savedScenesLength = countCompleteScenes(scenes)
    if (savedScenesLength >= maxNumberOfScenes) {
      changeTooManyScenesPopup()
    } else {
      dispatch(resetCurrentSceneCreating())
      dispatch(resetCurrentSceneConditionsActions())
      dispatch(changeSceneMode('create'))
      changeCreateSceneButtonDisabled(true)
      dispatch(addScene())
    }
  }

  return (
    <Paper className={classNames(
      classes.main,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      {/* @ts-ignore */}
      <ScenesList />
      { admin && (
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.addButton}
          disabled={isCreateSceneButtonDisabled}
          onClick={() => {
            createScene()
          }}
        >
          <Add />
        </Fab>
      )}
      { renderTooManyScenesPopup() }
    </Paper>
  )
}

export default MyAutomation
