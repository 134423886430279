import * as type from '@app/constants/guardPanel'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as GuardPanelAction from '@app/actions/guardPanel'

type GuardPanelActions = ReturnType<InferValueTypes<typeof GuardPanelAction>>

export interface IGuardPanelState {
  status: string,
  timeout: boolean,
  inProgress: boolean,
}

const initialState = {
  status: '',
  timeout: false,
  inProgress: false,
}

const guardPanel = (state: IGuardPanelState = initialState, action: GuardPanelActions): IGuardPanelState => {
  switch (action.type) {
  case type.SET_GUARD_PANEL_STATUS:
    return { ...state, status: action.status }
  case type.SET_GUARD_PANEL_PROGRESS:
    return { ...state, inProgress: action.progress }
  case type.SET_GUARD_PANEL_TIMEOUT:
    return { ...state, timeout: true }
  case type.RESET_GUARD_PANEL_TIMEOUT:
    return { ...state, timeout: false }
  case type.RESET_GUARD_PANEL_PROGRESS:
    return { ...state, inProgress: false }
  case type.RESET_GUARD_PANEL_REDUCER:
    return initialState
  default:
    return state
  }
}

export default guardPanel
