import * as type from '@app/constants/users/currentUser'

export const updateCurrentUser = (user) => ({
  type: type.UPDATE_CURRENT_USER,
  user,
})

export const signInCurrentUser = (user) => ({
  type: type.SIGN_IN_CURRENT_USER,
  user,
})

export const sync = () => ({
  type: type.SYNC_CURRENT_USER,
})

export const set = () => ({
  type: type.SET_CURRENT_USER,
})

export const updateCurrentUserRole = (role) => ({
  type: type.UPDATE_CURRENT_USER_ROLE,
  role,
})
