import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 26">
      <path fill="currentColor" stroke="#fff" d="M19.81 6.09V.5H34.5v24.577H.5V6.59h19.31v-.5Zm0 1.218v-.5H.707V11.46H19.81V7.308ZM1.207 11.68h-.5v3.435H15.19v9.744h3.413v-9.744H19.81V11.68H1.207Zm13.776 4.153v-.5H.707v9.526h14.276v-9.026Zm4.327-.5h-.5v9.526h15.483v-9.526H19.31ZM34.293 1.218v-.5H20.017v14.397h14.276V1.218Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'RoomsDeviceIcon', '0 0 80 80', {fontSize: 35})

export default icon
