import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import convertMezzoErrors from '@app/helpers/convertMezzoErrors'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { sync } from '@app/actions/mezzoErrors'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import MezzoErrors from '@app/components/MezzoErrors/MezzoErrors'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs/index'
import PO from '@app/lib/ProgramOptions/index'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'
import { IProgramOption } from '@app/@types/programOptions'

let enhance = MezzoErrors

const mapStateToProps = (state: AppState) => {
  const mezzoErrorsOption = state.unit.currentOptions.find((option: IProgramOption) => option.number === PO.GET_ERROR_CODE_1242)
  const mezzoErrors = convertMezzoErrors(mezzoErrorsOption)
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isMobile,
    path,
    isWebView,
    mezzoErrors,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MezzoErrorsEnhanced = enhance

export default MezzoErrorsEnhanced
