import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveConditionDeviceStateInfo,
  saveConditionStateDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectDoorLockConditionState
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/DoorLockCondition/SelectDoorLockConditionState/SelectDoorLockConditionState'
import SelectDoorLockConditionStateIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/DoorLockCondition/SelectDoorLockConditionState/SelectDoorLockConditionState'

import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectDoorLockConditionStateIOS) : withStyles(SelectDoorLockConditionState)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  const { conditionDeviceNumber } = state.scenes.currentSceneCreating.currentCondition
  return {
    isMobile,
    isEditConditionMode,
    conditionDeviceNumber,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionStateDescription,
  saveConditionDeviceStateInfo,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectDoorLockConditionStateEnhanced = enhance

export default withRouter(SelectDoorLockConditionStateEnhanced)
