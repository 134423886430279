import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const getDeviceHealth = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/zwave_devices/failed_node/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteUnhealthyDevice = (access_token: string, id: number) => Axios.delete(`${API_URL}/api/devices/zwave_devices/failed_node/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const replaceUnhealthyDevice = (access_token: string, id: number) => Axios.put(
  `${API_URL}/api/devices/zwave_devices/failed_node/${id}`, null, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const cancelReplaceDevice = (access_token: string) => Axios.post(`${API_URL}/api/devices/zwave_devices/cancel`, null, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  getDeviceHealth,
  deleteUnhealthyDevice,
  replaceUnhealthyDevice,
  cancelReplaceDevice,
}
