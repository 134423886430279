import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchGeofences = (access_token) => Axios.get(`${API_URL}/api/geofences`, {
  headers: {
    'Access-Token': access_token,
  },
})

const postGeofence = (access_token, data) => Axios.post(`${API_URL}/api/geofences`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchGeofence = (access_token, id, data) => Axios.patch(`${API_URL}/api/geofences/${id}`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteGeofence = (access_token, id) => Axios.delete(`${API_URL}/api/geofences/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchGeofences,
  postGeofence,
  patchGeofence,
  deleteGeofence,
}
