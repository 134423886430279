import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.16668 8.33332H12.0834C13.2325 8.33332 14.1667 9.26832 14.1667 10.4167V17.9167C14.1667 19.065 13.2325 20 12.0834 20H2.08336C0.93418 20 3.90625e-05 19.065 0 17.9167V10.4167C0 9.41168 0.71668 8.56918 1.66668 8.37586V5.41668C1.66668 2.43 4.09668 0 7.08336 0C10.07 0 12.5 2.43 12.5 5.41668V7.08336C12.5 7.31336 12.3134 7.50004 12.0834 7.50004H10.4167C10.1867 7.50004 10 7.31336 10 7.08336V5.41668C10 3.80832 8.69168 2.5 7.08336 2.5C5.47504 2.5 4.16668 3.80832 4.16668 5.41664V8.33332ZM9 18V16.9336H6.56488L7.5522 15.5877C7.74211 15.3539 7.91512 15.128 8.07122 14.91C8.22732 14.6919 8.36 14.4771 8.46927 14.2654C8.58114 14.0506 8.66699 13.8357 8.72683 13.6209C8.78667 13.4028 8.81659 13.1801 8.81659 12.9526C8.81659 12.5482 8.74504 12.2006 8.60195 11.91C8.46146 11.6161 8.25333 11.3918 7.97756 11.237C7.70439 11.079 7.36878 11 6.97073 11C6.56748 11 6.21886 11.1027 5.92488 11.3081C5.63089 11.5103 5.40325 11.7836 5.24195 12.128C5.08065 12.4692 5 12.8452 5 13.2559H6.1278C6.1278 13.0284 6.15902 12.8246 6.22146 12.6445C6.2865 12.4645 6.38016 12.3239 6.50244 12.2227C6.62732 12.1216 6.77561 12.0711 6.94732 12.0711C7.10862 12.0711 7.2439 12.1122 7.35317 12.1943C7.46504 12.2765 7.54959 12.3934 7.60683 12.545C7.66406 12.6935 7.69268 12.872 7.69268 13.0806C7.69268 13.2196 7.66797 13.3665 7.61854 13.5213C7.57171 13.673 7.49496 13.8452 7.38829 14.0379C7.28163 14.2275 7.13593 14.4471 6.95122 14.6967L5.11317 17.09V18H9Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'Area2ArmedIcon')

export default icon
