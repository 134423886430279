import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 40">
      <path fill="currentColor" d="M5.24 19.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.24-1.15c1 0 1.99-.29 2.77-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.13.1.37.37 0 0 0-.07.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M18.79 9.77V3.02c0-.29-.19-.51-.32-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.74 20.74 0 0 0 9.39 0C6.93 0 4.65.38 2.97.93a8.1 8.1 0 0 0-2.06.96c-.48.34-.91.8-.91 1.37v6.5c0 .65.47 1.22 1.03 1.68.59.47 1.39.92 2.3 1.31.83.36 1.78.68 2.75.91.13.54.56.97 1.06 1.24.61.34 1.43.54 2.3.54.87 0 1.69-.2 2.3-.54.5-.28.94-.7 1.06-1.25a17.4 17.4 0 0 0 2.67-.88 9.7 9.7 0 0 0 2.29-1.32c.56-.46 1.03-1.03 1.03-1.68Zm-5.97 3.4.68-.2c.04-.13.06-.27.06-.41 0-1.32-1.84-2.4-4.12-2.4-2.28 0-4.12 1.08-4.12 2.4 0 .15.02.3.07.44h-.01l.68.18c.07-.62.54-1.09 1.08-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.69.2 2.3.54.54.3 1 .77 1.08 1.37Zm-7.96-.34c-2.41-.79-4.4-2.02-4.4-3.06V7.33C1.29 6.85 4.72 5.03 9.3 5c4.6 0 8.19 1.85 9.02 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04.02-.08.02-.17.02-.25 0-.92-.63-1.65-1.45-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.82.47-1.45 1.2-1.45 2.12 0 .09 0 .18.02.27ZM.46 3.26c0-1.3 4.1-2.8 8.93-2.8 4.84 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.02-2.3c-4.43 0-8 1.84-8.83 2.31v-1.3Zm0 1.93v1.5A19.1 19.1 0 0 1 9.3 4.37a18.1 18.1 0 0 1 9.02 2.31V5.2A19.25 19.25 0 0 0 9.3 2.87 18.1 18.1 0 0 0 .47 5.2Zm11.91 8.16c0 .9-1.31 1.62-2.93 1.62s-2.93-.72-2.93-1.62c0-.9 1.31-1.63 2.93-1.63s2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="m16.2 35.24-1.63-.95 1.42-.83a.55.55 0 0 0-.56-.94l-1.95 1.14-2.93-1.69 2.93-1.7 1.95 1.15a.54.54 0 0 0 .75-.2.55.55 0 0 0-.2-.74l-1.4-.83 1.62-.95a.55.55 0 0 0-.54-.95l-1.64.95-.01-1.65a.55.55 0 1 0-1.1.01l.02 2.27L10 31.02v-3.39l1.97-1.11a.55.55 0 0 0-.54-.96l-1.43.81V24.5a.55.55 0 1 0-1.1 0v1.88l-1.43-.8a.55.55 0 1 0-.54.95l1.97 1.11v3.39l-2.93-1.7.02-2.26a.55.55 0 0 0-1.1-.01l-.01 1.65-1.63-.95a.55.55 0 1 0-.55.95l1.63.95-1.41.83a.55.55 0 0 0 .55.94l1.95-1.15 2.93 1.7-2.93 1.7-1.95-1.15a.55.55 0 1 0-.55.94l1.4.83-1.62.95a.55.55 0 0 0 .55.95l1.63-.95.01 1.64c0 .3.25.55.55.55.3 0 .55-.25.55-.55l-.02-2.27 2.93-1.7v3.4l-1.97 1.11a.55.55 0 0 0 .54.96l1.43-.81v1.88a.55.55 0 1 0 1.1 0v-1.88l1.43.8a.55.55 0 0 0 .74-.2.55.55 0 0 0-.2-.75L10 36.31v-3.4l2.93 1.7-.02 2.27a.55.55 0 0 0 1.1.01l.01-1.65 1.64.95a.55.55 0 0 0 .74-.2.55.55 0 0 0-.2-.75Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'FreezeIcon', '0 0 80 80', {fontSize: 35})

export default icon
