import { createLogic } from 'redux-logic'
import Api from '@app/api/mySecurity/startPanic'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { START_PANIC } from '@app/constants/mySecurity/mySecurity'
import { saveWorkerInfo, openPanicPopup } from '@app/actions/mySecurity/mySecurity'

const startPanic = createLogic({
  type: START_PANIC,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(START_PANIC))
    try {
      const data = await Api.startPanic(action.accessToken)
      const { jid } = data.data
      dispatch(openPanicPopup())
      dispatch(saveWorkerInfo(jid))
      dispatch(apiRequestDone(START_PANIC))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('startPanic failed without error')
      }
    } finally {
      done()
    }
  },
})

export default startPanic
