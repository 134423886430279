import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import SubDeviceCard from '@app/components/MyDevices/DevicesList/SubDeviceCard/SubDeviceCard'
import CameraCard from '@app/components/MyDevices/DevicesList/CameraCard/CameraCard'
import MezzoLightsCard from '@app/components/MyDevices/DevicesList/MezzoLightsCard/MezzoLightsCard'
import VirtualSwitchesCard from '@app/components/MyDevices/DevicesList/VirtualSwitchesCard/VirtualSwitchesCard'
import MyDevicesCard from '@app/components/MyDevices/DevicesList/DeviceCard/DeviceCard'
import { DeviceTypes, TDevice } from '@app/@types/myDevices'
import useStyles from '../MyDevices.style'

interface Props {
  devices: Array<TDevice>,
  mezzoLights: number,
  isMobile: boolean,
  isDragging: boolean,
  inProgress: boolean,
  isMezzoLightsLoading: boolean,
  showMezzoLights: boolean,
}

const DevicesList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {
    isDragging, devices, mezzoLights, isMobile, inProgress, isMezzoLightsLoading, showMezzoLights,
  } = props
  const Device = isDragging ? SortableElement(MyDevicesCard) : MyDevicesCard
  const Camera = isDragging ? SortableElement(CameraCard) : CameraCard
  const MezzoLights = isDragging ? SortableElement(MezzoLightsCard) : MezzoLightsCard
  const VirtualSwitches = isDragging ? SortableElement(VirtualSwitchesCard) : VirtualSwitchesCard
  const SubDevice = isDragging ? SortableElement(SubDeviceCard) : SubDeviceCard

  const renderDevicesList = (device: TDevice, index: number) => {
    if (device.device_type === DeviceTypes.mezzoLight) {
      if (showMezzoLights) {
        return (
          <MezzoLights
            index={index}
            key={`device${index + 1}`}
            isDragging={isDragging}
            mezzoLights={mezzoLights}
            isMezzoLightsLoading={isMezzoLightsLoading}
            isMobile={isMobile}
          />
        )
      } return null
    } if (device.device_type === DeviceTypes.virtualSwitch) {
      return (
        <VirtualSwitches
          index={index}
          key={`device${index + 1}`}
          isDragging={isDragging}
          isMobile={isMobile}
        />
      )
    } if (device.device_type === DeviceTypes.camera) {
      return (
        <Camera
          index={index}
          key={`device${index + 1}`}
          isDragging={isDragging}
          cameraName={device.name}
          isMobile={isMobile}
        />
      )
    } if (device.device_type === DeviceTypes.zwaveSubDevice) {
      return (
        <SubDevice
          index={index}
          key={`device${index + 1}`}
          isDragging={isDragging}
          subDeviceName={device.name}
          parentDevice={device.parent}
          isMobile={isMobile}
        />
      )
    }
    return (
      <Device
        isDragging={isDragging}
        index={index}
        key={`device${index + 1}`}
        device={device}
        isMobile={isMobile}
        deviceType={device.device_type}
        inProgress={inProgress}
      />
    )
  }

  return (
    <Wrapper className={classNames(classes.wrapper, isMobile && classes.wrapperMobile)}>
      {devices.length > 0 && devices.map((device, index) => renderDevicesList(device, index))}
    </Wrapper>
  )
}

export default DevicesList
