import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 13.5H13.5V22.5H16.5V13.5Z" fill="currentColor"/>
      <path d="M15 0C6.71252 0 0 6.71252 0 15C0 23.2874 6.71252 29.9999 15 29.9999C23.2874 29.9999 30 23.2875 30 15C30 6.71245 23.2875 0 15 0ZM15 27C8.38497 27 2.99995 21.615 2.99995 15C2.99995 8.38497 8.38497 3.00002 15 3.00002C21.615 3.00002 27 8.38497 27 15C27 21.615 21.615 27 15 27Z" fill="currentColor"/>
      <path d="M16.5 7.5H13.5V10.5H16.5V7.5Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'InfoIcon', "0 0 30 30")

export default icon
