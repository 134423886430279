import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8409 3.25222V2.48743C10.8409 2.21837 10.6225 2 10.3534 2C10.0844 2 9.86599 2.21837 9.86599 2.48743V3.2566C6.03574 3.52274 3 6.72152 3 10.6183C3 14.6881 6.31187 18 10.3824 18C14.453 18 17.7648 14.6881 17.7648 10.6183C17.7648 6.70203 14.6986 3.48984 10.8409 3.25222ZM10.3824 17.0251C6.84926 17.0251 3.97487 14.1507 3.97487 10.6183C3.97487 7.08539 6.84926 4.21124 10.3824 4.21124C13.9156 4.21124 16.79 7.08539 16.79 10.6183C16.79 14.1507 13.9156 17.0251 10.3824 17.0251Z" fill="currentColor"/>
      <path d="M7.88029 15.0422C6.28906 14.1397 5.30054 12.4442 5.30054 10.618C5.30054 10.483 5.1916 10.3743 5.05683 10.3743C4.92205 10.3743 4.81311 10.483 4.81311 10.618C4.81311 12.6194 5.89643 14.4772 7.63998 15.4665C7.67776 15.4879 7.71919 15.4979 7.75989 15.4979C7.84495 15.4979 7.92732 15.4536 7.97217 15.3746C8.03846 15.2574 7.99727 15.1082 7.88029 15.0422Z" fill="currentColor"/>
      <path d="M9.20946 15.7109C9.11465 15.6858 9.02131 15.6595 8.92845 15.6295C8.79929 15.5885 8.66305 15.6599 8.6221 15.7876C8.5814 15.9158 8.65208 16.0528 8.78028 16.0938C8.88166 16.1264 8.98353 16.1557 9.0876 16.1822C9.10807 16.1873 9.12855 16.1895 9.14877 16.1895C9.25723 16.1895 9.35618 16.1167 9.38445 16.0072C9.41808 15.8773 9.33985 15.744 9.20946 15.7109Z" fill="currentColor"/>
      <path d="M16.6722 5.79494C16.7675 5.88999 16.8918 5.93727 17.0164 5.93727C17.1411 5.93727 17.2662 5.88926 17.3615 5.79397C17.5516 5.60338 17.5511 5.29483 17.3605 5.10474L16.0949 3.84228C15.9048 3.6517 15.5955 3.65267 15.4056 3.84326C15.2158 4.03384 15.2163 4.34239 15.4066 4.53249L16.6722 5.79494Z" fill="currentColor"/>
      <path d="M10.8405 10.5275V6.63074C10.8405 6.36168 10.6221 6.14331 10.353 6.14331C10.084 6.14331 9.8656 6.36168 9.8656 6.63074V10.7381C9.8656 10.8724 9.93555 11.0003 10.033 11.0922L11.4595 12.4254C11.5538 12.5141 11.674 12.5584 11.7941 12.5584C11.9235 12.5584 12.0454 12.5072 12.1409 12.4054C12.3264 12.2094 12.3098 11.9011 12.1141 11.7164L10.8405 10.5275Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'TimersIcon')

export default icon
