import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  mobileButtonDisarm: {
    paddingLeft: 30,
  },
  pink: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  green: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  red: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  darkPink: {
    backgroundColor: theme.palette.secondary.dark,
  },
  darkGreen: {
    backgroundColor: theme.palette.primary.dark,
  },
  darkRed: {
    backgroundColor: theme.palette.secondary.dark,
  },
  greenStatus: {
    backgroundColor: theme.palette.primary.main,
  },
  redStatus: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSecondLine: {
    color: theme.typography.color,
    width: '49%',
    height: 180,
    margin: '20px 0',
    fontSize: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  armStatus: {
    position: 'absolute',
    top: '22%',
    width: 100,
    height: 100,
    borderRadius: '50%',
    borderColor: theme.palette.background.default,
    border: 'solid 3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  armStatusIcon: {
    fontSize: 55,
  },
}))

export default useStyles
