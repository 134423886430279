import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/rooms`

const convertDate = (current_time) => current_time.toISOString()

const fetchRoomsStats = (access_token, current_time) => Axios.get(ENDPOINT, {
  params: { current_time: convertDate(current_time) },
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchRoomsStats,
}
