import React from 'react'
import { IconButton, Paper, Typography } from '@mui/material'
import { NavigateNext } from 'icons'
import {
  maxDaysValue,
  maxHoursValue,
  maxMinutesValue,
  maxSecondsValue,
  startValue,
} from '@app/constants/scenes/counterValues'
import PropTypes from '@app/components/PropTypes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'

class Counter extends React.Component {
  daysIncrease = () => {
    const { day, incDays, resetDays } = this.props
    if (day === maxDaysValue) {
      resetDays()
    } else {
      incDays()
    }
  }

  daysDecrease = () => {
    const { day, decDays, maxDays } = this.props
    if (day === startValue) {
      maxDays()
    } else {
      decDays()
    }
  }

  hoursIncrease = () => {
    const { hour, incHours, resetHours } = this.props
    if (hour === maxHoursValue) {
      resetHours()
    } else {
      incHours()
    }
  }

  hoursDecrease = () => {
    const { hour, decHours, maxHours } = this.props
    if (hour === startValue) {
      maxHours()
    } else {
      decHours()
    }
  }

  minutesIncrease = () => {
    const { minute, incMinutes, resetMinutes } = this.props
    if (minute === maxMinutesValue) {
      resetMinutes()
    } else {
      incMinutes()
    }
  }

  minutesDecrease = () => {
    const { minute, decMinutes, maxMinutes } = this.props
    if (minute === startValue) {
      maxMinutes()
    } else {
      decMinutes()
    }
  }

  secondsIncrease = () => {
    const { second, incSeconds, resetSeconds } = this.props
    if (second === maxSecondsValue) {
      resetSeconds()
    } else {
      incSeconds()
    }
  }

  secondsDecrease = () => {
    const { second, decSeconds, maxSeconds } = this.props
    if (second === startValue) {
      maxSeconds()
    } else {
      decSeconds()
    }
  }

  daysWheelControl = (e) => {
    const {
      day, incDays, decDays, maxDays, resetDays,
    } = this.props
    const delta = e.deltaY
    if (delta > 0 && day !== startValue) {
      decDays()
    } else if (delta > 0 && day === startValue) {
      maxDays()
    } else if (delta < 0 && day !== maxDaysValue) {
      incDays()
    } else if (delta < 0 && day === maxDaysValue) {
      resetDays()
    }
  }

  hoursWheelControl = (e) => {
    const {
      hour, incHours, decHours, maxHours, resetHours,
    } = this.props
    const delta = e.deltaY
    if (delta > 0 && hour !== startValue) {
      decHours()
    } else if (delta > 0 && hour === startValue) {
      maxHours()
    } else if (delta < 0 && hour !== maxHoursValue) {
      incHours()
    } else if (delta < 0 && hour === maxHoursValue) {
      resetHours()
    }
  }

  minutesWheelControl = (e) => {
    const {
      minute, incMinutes, decMinutes, maxMinutes, resetMinutes,
    } = this.props
    const delta = e.deltaY
    if (delta > 0 && minute !== startValue) {
      decMinutes()
    } else if (delta > 0 && minute === startValue) {
      maxMinutes()
    } else if (delta < 0 && minute !== maxMinutesValue) {
      incMinutes()
    } else if (delta < 0 && minute === maxMinutesValue) {
      resetMinutes()
    }
  }

  secondsWheelControl = (e) => {
    const {
      second, incSeconds, decSeconds, maxSeconds, resetSeconds,
    } = this.props
    const delta = e.deltaY
    if (delta > 0 && second !== startValue) {
      decSeconds()
    } else if (delta > 0 && second === startValue) {
      maxSeconds()
    } else if (delta < 0 && second !== maxSecondsValue) {
      incSeconds()
    } else if (delta < 0 && second === maxSecondsValue) {
      resetSeconds()
    }
  }

  renderCounterInfo = () => {
    const {
      classes,
      day,
      hour,
      minute,
      second,
      title,
    } = this.props
    return (
      <Paper className={classes.counterInfo}>
        <Typography className={classes.title}>
          {I18n.t(`scene_web.${title}`)}
          :
        </Typography>
        <Typography className={classes.info}>
          {`${day} ${I18n.t('scene_action_web.days')}
           ${hour} ${I18n.t('scene_action_web.hours')}
            ${minute} ${I18n.t('scene_action_web.minutes')}
             ${second} ${I18n.t('scene_action_web.seconds')}`}
        </Typography>
      </Paper>
    )
  }

  convertStrings = (currentString) => currentString[0].toUpperCase() + currentString.slice(1)

  renderCounterBlocks = () => {
    const {
      classes,
      day,
      hour,
      minute,
      second,
    } = this.props
    const counterBlocks = [
      {
        title: this.convertStrings(I18n.t('scene_action_web.days')),
        value: day,
        increaseFunc: this.daysIncrease,
        decreaseFunc: this.daysDecrease,
        wheelFunc: this.daysWheelControl,
      },
      {
        title: this.convertStrings(I18n.t('scene_action_web.hours')),
        value: hour,
        increaseFunc: this.hoursIncrease,
        decreaseFunc: this.hoursDecrease,
        wheelFunc: this.hoursWheelControl,
      },
      {
        title: this.convertStrings(I18n.t('scene_action_web.minutes')),
        value: minute,
        increaseFunc: this.minutesIncrease,
        decreaseFunc: this.minutesDecrease,
        wheelFunc: this.minutesWheelControl,
      },
      {
        title: this.convertStrings(I18n.t('scene_action_web.seconds')),
        value: second,
        increaseFunc: this.secondsIncrease,
        decreaseFunc: this.secondsDecrease,
        wheelFunc: this.secondsWheelControl,
      },
    ]

    return (
      <Paper className={classes.counter}>
        {
          counterBlocks.map((block, index) => (
            <Wrapper
              key={`block-${index + 1}`}
              className={classes.counterWrapper}
              onWheel={block.wheelFunc}
            >
              <Typography className={classes.counterTitle}>{block.title}</Typography>
              <IconButton
                className={classes.buttonInc}
                onClick={block.increaseFunc}
                color="primary"
                size="large"
              >
                <NavigateNext />
              </IconButton>
              <Wrapper>
                <Typography className={classes.number}>{block.value}</Typography>
              </Wrapper>
              <IconButton
                className={classes.buttonDec}
                onClick={block.decreaseFunc}
                color="primary"
                size="large"
              >
                <NavigateNext />
              </IconButton>
            </Wrapper>
          ))
        }
      </Paper>
    )
  }

  render() {
    const {
      classes,
    } = this.props
    return (
      <Wrapper className={classes.mainWrapper}>
        {this.renderCounterInfo()}
        {this.renderCounterBlocks()}
      </Wrapper>
    )
  }
}

Counter.propTypes = {
  classes: PropTypes.object.isRequired,
  incDays: PropTypes.func.isRequired,
  decDays: PropTypes.func.isRequired,
  incHours: PropTypes.func.isRequired,
  decHours: PropTypes.func.isRequired,
  incMinutes: PropTypes.func.isRequired,
  decMinutes: PropTypes.func.isRequired,
  incSeconds: PropTypes.func.isRequired,
  decSeconds: PropTypes.func.isRequired,
  day: PropTypes.number.isRequired,
  hour: PropTypes.number.isRequired,
  minute: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  resetDays: PropTypes.func.isRequired,
  resetHours: PropTypes.func.isRequired,
  resetMinutes: PropTypes.func.isRequired,
  resetSeconds: PropTypes.func.isRequired,
  maxDays: PropTypes.func.isRequired,
  maxHours: PropTypes.func.isRequired,
  maxMinutes: PropTypes.func.isRequired,
  maxSeconds: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

Counter.displayName = 'CounterIOS'

export default Counter
