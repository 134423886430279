import React, { useEffect } from 'react'
import { useDispatch } from '@app/store'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import { ChevronLeft } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { getTakeOnBoardDeviceType } from '@app/actions/myDevices/twrDevices'
import { takeOnBoardDeviceTypesList, withoutTakeOnBoard } from '@app/lib/MyDevices/takeOnBoardDeviceTypes'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './TakeOnBoardDeviceTypes.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentTakeOnBoardDeviceType: number,
  isMobile: boolean,
}

const TakeOnBoardDeviceTypes: React.FC<Props> = (props) => {
  const { currentDevice, currentTakeOnBoardDeviceType, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (currentDevice.take_on_board_device_type && currentDevice.take_on_board_device_type !== withoutTakeOnBoard) {
      dispatch(getTakeOnBoardDeviceType(currentDevice.id))
    }
  }, [])

  const goTakeOnBoardDeviceTypes = () => {
    navigate(`${location.pathname}/take_on_board_device_types`)
  }

  return (
    <Wrapper
      className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
      onClick={goTakeOnBoardDeviceTypes}
    >
      <Typography className={classNames(classes.cardTitle, classes.marginBottomHelper)}>
        {I18n.t('my_devices.device_types')}
      </Typography>
      <Wrapper className={classes.subTitleContainer}>
        <Typography className={classes.deviceName}>
          {I18n.t(`my_devices.${takeOnBoardDeviceTypesList[currentTakeOnBoardDeviceType]}`)}
        </Typography>
      </Wrapper>
      <ChevronLeft className={classes.arrowRightIcon} />
    </Wrapper>
  )
}
export default TakeOnBoardDeviceTypes
