import * as type from '@app/constants/sphereSort'

export const putSphereSort = () => ({
  type: type.PUT_SPHERE_SORT,
} as const)

export const saveSphereSortModel = (model: string) => ({
  type: type.SAVE_SPHERE_SORT_MODEL,
  model,
} as const)

export const saveSphereSortField = (field: string) => ({
  type: type.SAVE_SPHERE_SORT_FIELD,
  field,
} as const)

export const saveSphereSortOrder = (order: Array<number>) => ({
  type: type.SAVE_SPHERE_SORT_ORDER,
  order,
} as const)
