import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  icon: PropTypes.number,
  capability_id: PropTypes.number,
})

export default propTypes
