import * as type from '@app/constants/scenes/editScene'
import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'
import { Optional } from '@app/@types/common'

export const putChangeset = () => ({
  type: type.PUT_CHANGESET,
} as const)

export const openEditDraftSavePopup = () => ({
  type: type.OPEN_EDIT_DRAFT_SAVE_POPUP,
} as const)

export const closeEditDraftSavePopup = () => ({
  type: type.CLOSE_EDIT_DRAFT_SAVE_POPUP,
} as const)

export const openEditSceneQuitPopup = () => ({
  type: type.OPEN_EDIT_SCENE_QUIT_POPUP,
} as const)

export const closeEditSceneQuitPopup = () => ({
  type: type.CLOSE_EDIT_SCENE_QUIT_POPUP,
} as const)

export const patchChangeset = () => ({
  type: type.PATCH_CHANGESET,
} as const)

export const saveChangesetAction = (changesetAction: string) => ({
  type: type.SAVE_CHANGESET_ACTION,
  changesetAction,
} as const)

export const saveEditedSceneInitialName = (sceneName: string) => ({
  type: type.SAVE_EDITED_SCENE_INITIAL_NAME,
  sceneName,
} as const)

export const saveEditedSceneInitialConditions = (conditions: Optional<Array<IConditionFromBackend>>) => ({
  type: type.SAVE_EDITED_SCENE_INITIAL_CONDITIONS,
  conditions,
} as const)

export const saveEditedSceneInitialActions = (actions: Optional<Array<IActionFromBackend>>) => ({
  type: type.SAVE_EDITED_SCENE_INITIAL_ACTIONS,
  actions,
} as const)

export const resetEditScene = () => ({
  type: type.RESET_EDIT_SCENE,
} as const)

export const selectSceneInitialId = (id: number) => ({
  type: type.SELECT_SCENE_INITIAL_ID,
  id,
} as const)
