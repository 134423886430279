const convertTimeToField = (time) => {
  const hours = time.split(':')[0]
  const minutes = time.split(':')[1]
  const currentTime = new Date(2021, 0, 1, 0, 0)
  currentTime.setHours(hours)
  currentTime.setMinutes(minutes)
  return currentTime
}

export default convertTimeToField
