import React from 'react'
import { Button, Paper, Typography } from '@mui/material'
import classNames from 'classnames'
import { ArrowDownwardIcon, ArrowUpwardIcon } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import DeviceCardsList from './DeviceCardList/DeviceCardsList'

class DeviceManager extends React.Component {
  state = {
    isResetPopupOpen: false,
  }

  componentWillUnmount() {
    const { resetModeOff } = this.props
    resetModeOff()
  }

  checkEmptyState=(devices) => devices.length > 0

  getSortedDevices=() => {
    const {
      refresh,
      toggleSelectedSorting,
    } = this.props
    toggleSelectedSorting()
    refresh()
  }

  showResetOffButtons=() => {
    const {
      classes,
      resetModeOff,
      devicesForReset,
      admin,
    } = this.props
    return (
      (
        <Wrapper>
          <Button
            onClick={() => {
              this.setState({ isResetPopupOpen: true })
            }}
            className={classNames(
              classes.button,
              classes.resetModeButton,
            )}
            disabled={!this.checkEmptyState(devicesForReset)}
          >
            {I18n.t('buttons.submit')}
          </Button>
          <Button
            disabled={!admin}
            onClick={resetModeOff}
            className={classes.button}
          >
            {I18n.t('buttons.reset_mode_off')}
          </Button>
        </Wrapper>
      )
    )
  }

  showResetOnButton=() => {
    const {
      classes,
      resetModeOn,
      admin,
    } = this.props
    return (
      (
        <Button
          onClick={() => {
            resetModeOn()
          }}
          disabled={!admin}
          className={classNames(
            classes.button,
            classes.resetModeButton,
          )}
        >
          {I18n.t('buttons.reset_mode_on')}
        </Button>
      )
    )
  }

  showSortingButton=() => {
    const {
      classes,
      sort,
    } = this.props
    return (
      <Wrapper>
        <Button
          onClick={() => {
            this.getSortedDevices()
          }}
          color="primary"
          className={classes.button}
        >
          {I18n.t('buttons.sort_by_consumption')}
          {sort === 'desc'
            ? <ArrowUpwardIcon className={classes.sortIcon} />
            : <ArrowDownwardIcon className={classes.sortIcon} />}
        </Button>
      </Wrapper>
    )
  }

  showSelectButton =() => {
    const {
      classes,
      addAllDevicesToResetArray,
      devicesForReset,
      clearDevicesResetArray,
    } = this.props
    return (
      <>
        {this.checkEmptyState(devicesForReset) ? (
          <Button
            onClick={() => clearDevicesResetArray()}
            color="primary"
            className={classes.button}
          >
            {I18n.t('buttons.deselect')}
          </Button>
        ) : (
          <Button
            onClick={() => {
              addAllDevicesToResetArray()
            }}
            color="primary"
            className={classes.button}
          >
            {I18n.t('buttons.select')}
          </Button>
        )}
      </>
    )
  }

  toggleLeftButtons=() => {
    const {
      resetMode,
    } = this.props
    return (
      resetMode === true ? this.showSelectButton() : this.showSortingButton()
    )
  }

  toggleResetButtons=() => {
    const {
      resetMode,
    } = this.props
    return (
      resetMode === true ? this.showResetOffButtons() : this.showResetOnButton()
    )
  }

  render() {
    const {
      classes,
      devices,
      resetMode,
      devicesForReset,
      addDeviceToResetArray,
      deleteDeviceFromResetArray,
      isMobile,
      resetDevicesEnergies,
    } = this.props
    const { isResetPopupOpen } = this.state
    return (
      <Paper className={classNames(
        classes.main,
        !isMobile && classes.mainDesktop,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
      )}
      >
        <Wrapper className={classes.buttonsHeader}>
          {this.checkEmptyState(devices) && this.toggleLeftButtons()}
          {this.checkEmptyState(devices) && this.toggleResetButtons()}
        </Wrapper>
        {this.checkEmptyState(devices) ? (
          <DeviceCardsList
            devices={devices}
            resetMode={resetMode}
            devicesForReset={devicesForReset}
            addDeviceToResetArray={addDeviceToResetArray}
            deleteDeviceFromResetArray={deleteDeviceFromResetArray}
          />
        ) : (
          <Typography align="center" variant="h6">
            {I18n.t('my_energy.no_devices')}
          </Typography>
        )}
        {isResetPopupOpen && (
          <SmallErrorPopup
            open
            onClick={() => {
              resetDevicesEnergies()
              this.setState({ isResetPopupOpen: false })
            }}
            buttonName="buttons.okay"
            title="titles.warning"
            warningText="my_energy.removing_energy"
          />
        )}
      </Paper>
    )
  }
}

DeviceManager.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  devices: PropTypes.arrayOf(PropTypes.energyDevice).isRequired,
  resetMode: PropTypes.bool.isRequired,
  sort: PropTypes.string.isRequired,
  resetModeOn: PropTypes.func.isRequired,
  resetModeOff: PropTypes.func.isRequired,
  toggleSelectedSorting: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  devicesForReset: PropTypes.arrayOf(PropTypes.number),
  addDeviceToResetArray: PropTypes.func.isRequired,
  deleteDeviceFromResetArray: PropTypes.func.isRequired,
  addAllDevicesToResetArray: PropTypes.func.isRequired,
  clearDevicesResetArray: PropTypes.func.isRequired,
  resetDevicesEnergies: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

DeviceManager.defaultProps = {
  devicesForReset: [],
}
DeviceManager.displayName = 'DeviceManager'

export default DeviceManager
