import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, FormHelperText, TextField, Tooltip,
} from '@mui/material'

import { withStyles } from '@mui/styles'

import styles from './EditTextField.style'

class EditTextField extends React.PureComponent {
  passwordTypeCheck = (title) => ((title === 'password' || title === 'confirm_password') ? 'password' : 'text')

  createTextField = () => {
    const {
      classes,
      input,
      placeholder,
      autoComplete,
      title,
    } = this.props
    return (
      <TextField
        variant="standard"
        type={this.passwordTypeCheck(title)}
        onChange={(event, index, value) => input.onChange(value)}
        InputProps={{
          ...input,
          placeholder,
          autoComplete,
          inputProps: {
            className: classes.fieldInput,
          },
        }}
      />
    )
  }

  hintControl =() => {
    const {
      hint,
    } = this.props
    if (hint) {
      return (
        <Tooltip title={hint}>{this.createTextField()}</Tooltip>
      )
    }
    return (
      this.createTextField()
    )
  }

  render() {
    const {
      meta: { touched, error },
      classes,
    } = this.props
    return (
      <FormControl variant="standard" className={classes.input}>
        {this.hintControl()}
        {touched && error && (
          <FormHelperText id="component-error-text" className={classes.text}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

EditTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  hint: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
}

EditTextField.defaultProps = {
  hint: null,
}

export default (withStyles(styles)(EditTextField))
