import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Avatar, Button, List, Paper, Tooltip, Typography,
} from '@mui/material'
import { USERS } from '@app/constants/routes'
import AvatarPopup from '@app/containers/Users/UserEdit/AvatarPopup/AvatarPopup'
import SecretCodePopup from '@app/containers/Users/UserEdit/SecretCodePopup/SecretCodePopup'
import PinCodePopup from '@app/containers/Users/UserEdit/PinCodePopup/PinCodePopup'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Info from '@app/components/Common/Info/Info'
import NavBar from '@app/components/Common/NavBar/NavBar'
import { AppWindowIcon, CloudMinusIcon } from 'icons'
import EditText from './EditText/EditText'
import ShowPinPopup from './ShowPinPopup/ShowPinPopup'

class UserEdit extends Component {
  componentDidMount() {
    const {
      closeAvatarPopup,
      cleanAvatarFile,
      closeChangeCodePopup,
      closeWarningChangeEmailPopup,
      closeChangePinPopup,
      closeShowPinPopup,
    } = this.props
    closeAvatarPopup()
    cleanAvatarFile()
    closeChangeCodePopup()
    closeWarningChangeEmailPopup()
    closeChangePinPopup()
    closeShowPinPopup()
  }

  onBackClick=() => {
    const {
      navigate,
    } = this.props
    navigate(USERS)
  }

  resetField =(option) => {
    const {
      change,
      dispatch,
    } = this.props
    dispatch(change(option, ''))
  }

  changingCodeAccepted =() => {
    const {
      openChangeCodePopup,
    } = this.props
    openChangeCodePopup()
  }

  changingPinAccepted=() => {
    const {
      openChangePinPopup,
    } = this.props
    openChangePinPopup()
  }

  showPinCode=() => {
    const {
      getPinCode,
    } = this.props
    getPinCode()
  }

  render() {
    const {
      submitting,
      pristine,
      classes,
      submit,
      userData,
      userAvatar,
      userRole,
      avatarPopupOpen,
      openAvatarPopup,
      changeCodePopupOpen,
      warningChangeEmailPopupOpen,
      closeWarningChangeEmailPopup,
      isMobile,
      isChangePinPopupOpen,
      pinCode,
      isShowPinPopupOpen,
      closeShowPinPopup,
    } = this.props
    return (
      <Paper className={classNames(
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title={I18n.t('my_users.user_editing')}
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
          customTitle
        />
        <form>
          <List component="nav" className={classes.list}>
            <Wrapper className={classes.headerWrapper}>
              <Tooltip title="change avatar">
                <Button
                  onClick={openAvatarPopup}
                  className={classes.avatarButtonWrapper}
                >
                  <Avatar src={userAvatar} className={classes.avatar} />
                </Button>
              </Tooltip>
              <Wrapper className={classes.roleWrapper}>
                {userRole && <Typography className={classes.roleButton}>{I18n.t(`my_users.${userRole}`)}</Typography>}
                <Button
                  onClick={this.showPinCode}
                  variant="outlined"
                  color="primary"
                >
                  {I18n.t('buttons.get_pin_code')}
                </Button>
              </Wrapper>
            </Wrapper>
            <Wrapper className={classes.fieldsWrapper}>
              {userData.map((user) => (
                <EditText
                  key={user.title}
                  className={classes.item}
                  name={user.title}
                  title={I18n.t(`my_users.${user.hint}`)}
                  hint={I18n.t(`my_users.${user.hint}_hint`)}
                  resetField={() => this.resetField(user.title)}
                />
              ))}
              <Wrapper className={classes.changeButtonsWrapper}>
                <Button
                  onClick={this.changingCodeAccepted}
                  variant="outlined"
                  color="primary"
                  className={classes.userCodeButton}
                  startIcon={<CloudMinusIcon />}
                >
                  {I18n.t('buttons.change_secret_code').toUpperCase()}
                </Button>
                <Button
                  onClick={this.changingPinAccepted}
                  variant="outlined"
                  color="primary"
                  className={classes.userCodeButton}
                  startIcon={<AppWindowIcon />}
                >
                  {I18n.t('buttons.change_pin_code').toUpperCase()}
                </Button>
              </Wrapper>
            </Wrapper>
          </List>
          <Wrapper className={classes.buttonWrapper}>
            <Button
              onClick={submit}
              variant="outlined"
              className={classes.button}
              color="primary"
              disabled={submitting || pristine}
            >
              {I18n.t('buttons.save').toUpperCase()}
            </Button>
          </Wrapper>
        </form>
        {avatarPopupOpen && <AvatarPopup /> }
        {changeCodePopupOpen && <SecretCodePopup /> }
        {isChangePinPopupOpen && <PinCodePopup /> }
        {isShowPinPopupOpen && (
          <ShowPinPopup
            pinCode={pinCode}
            closeShowPinPopup={closeShowPinPopup}
          />
        ) }
        <Info
          classes={{ root: classes.changeInfoPopup }}
          open={warningChangeEmailPopupOpen}
          title="my_users.info"
          warningText="my_users.email_change_info"
          buttonName="buttons.okay"
          onClick={closeWarningChangeEmailPopup}
        />
      </Paper>
    )
  }
}

UserEdit.propTypes = {
  userData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  userAvatar: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  avatarPopupOpen: PropTypes.bool.isRequired,
  openAvatarPopup: PropTypes.func.isRequired,
  closeAvatarPopup: PropTypes.func.isRequired,
  cleanAvatarFile: PropTypes.func.isRequired,
  changeCodePopupOpen: PropTypes.bool.isRequired,
  openChangeCodePopup: PropTypes.func.isRequired,
  closeChangeCodePopup: PropTypes.func.isRequired,
  warningChangeEmailPopupOpen: PropTypes.bool.isRequired,
  closeWarningChangeEmailPopup: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  openChangePinPopup: PropTypes.func.isRequired,
  closeChangePinPopup: PropTypes.func.isRequired,
  isChangePinPopupOpen: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  getPinCode: PropTypes.func.isRequired,
  pinCode: PropTypes.string,
  isShowPinPopupOpen: PropTypes.bool.isRequired,
  closeShowPinPopup: PropTypes.func.isRequired,
}

UserEdit.defaultProps = {
  pinCode: null,
}

UserEdit.displayName = 'UserEdit'

export default UserEdit
