import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { POST_CODE } from '@app/constants/wizard/forgotPassword'
import { NEW_PASSWORD } from '@app/constants/routes'
import { openWrongCodePopup } from '@app/actions/wizard/forgotPassword'
import Api from '@app/api/forgotPassword/codeConfirmation'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const codeConfirmationLogic = createLogic({
  type: POST_CODE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { login: { forgotPassword: { code, requestId } } } = getState()
    const { secrets: { clientCredentialsToken } } = getState()
    const request_id = requestId
    const data = { code, request_id }
    next({
      ...action,
      clientCredentialsToken,
      data,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_CODE))
    Api.codeConfirmation(action.clientCredentialsToken, action.data)
      .then(() => {
        dispatch(apiRequestDone(POST_CODE))
        history.push(NEW_PASSWORD)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(openWrongCodePopup())
        } else {
          throw new Error('codeConfirmation failed without error')
        }
      })
      .then(() => done())
  },
})

export default codeConfirmationLogic
