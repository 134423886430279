import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/v5/notification_settings`

const updateNotificationsSettings = (accessToken, data) => Axios.patch(ENDPOINT, data, {
  headers: { 'Access-Token': accessToken },
})

export default { updateNotificationsSettings }
