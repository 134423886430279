import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'

const compareConditions = (initialConditions: Array<IConditionFromBackend>,
  currentConditions: Array<IConditionFromBackend>) => initialConditions.map((el, index) => {
  if (el.operand !== currentConditions[index].operand) {
    return false
  }
  if (el.type !== currentConditions[index].type) {
    return false
  }
  if (el.data) {
    if (el.data.type !== currentConditions[index].data.type || el.data.value !== currentConditions[index].data.value) {
      return false
    }
  }
  if (el.value_title !== currentConditions[index].value_title) {
    return false
  }
  if (el.description !== currentConditions[index].description) {
    return false
  }
  if (el.device) {
    if (el.device.id !== currentConditions[index].device?.id) {
      return false
    }
  }
  return true
})

const compareActions = (initialActions: Array<IActionFromBackend>,
  currentActions: Array<IActionFromBackend>) => initialActions.map((el, index) => {
  if (el.type !== currentActions[index].type) {
    return false
  }
  if (el.value_title !== currentActions[index].value_title) {
    return false
  }
  if (el.description !== currentActions[index].description) {
    return false
  }
  if (el.device) {
    if (el.device.id !== currentActions[index].device?.id) {
      return false
    }
  }
  if (el.data) {
    if (el.data.value !== currentActions[index].data.value) {
      return false
    }
  }
  return true
})

const getPristineValue = (initialSceneName: string, initialActions: Array<IActionFromBackend>,
  initialConditions: Array<IConditionFromBackend>, currentSceneName: string,
  currentActions: Array<IActionFromBackend>, currentConditions: Array<IConditionFromBackend>) => {
  if (initialSceneName !== currentSceneName) {
    return false
  }
  if (initialActions.length !== currentActions.length || initialConditions.length !== currentConditions.length) {
    return false
  }
  const isConditionsEqual = compareConditions(initialConditions, currentConditions)
  const isActionsEqual = compareActions(initialActions, currentActions)

  return !isActionsEqual.filter((el) => !el).length && !isConditionsEqual.filter((el) => !el).length
}

export default getPristineValue
