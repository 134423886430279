import { createLogic } from 'redux-logic'
import { isIOS, isAndroid } from 'react-device-detect'
import { PATCH_SCENE_ENABLE_VALUE } from '@app/constants/scenes/scenesList'
import {
  clearCurrentSceneId,
  clearCurrentSceneEnabled,
  patchSceneEnableValueDoneAndroid,
  patchSceneEnableValueDoneIOS,
  startEnablingProgress,
  stopEnablingProgress,
} from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/patchEnableScene'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'

const useBackendApi = (action, dispatch, done) => {
  dispatch(startEnablingProgress(action.id))
  Api.patchEnableScene(action.token, action.id, null)
    .then(() => {
      dispatch(apiRequestDone(PATCH_SCENE_ENABLE_VALUE))
      dispatch(clearCurrentSceneId())
      dispatch(clearCurrentSceneEnabled())
    })
    .catch((error) => {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
        dispatch(stopEnablingProgress(action.id))
      } else {
        throw new Error('patchEnableScene failed without error')
      }
    })
    .then(() => done())
}

const patchSceneEnableValueLogic = createLogic({
  type: PATCH_SCENE_ENABLE_VALUE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { scenes: { scenesList: { scenes } } } = getState()
    const { scenes: { scenesList: { currentScene: { id, enabled } } } } = getState()
    const data = { enabled }
    next({
      ...action,
      token,
      id,
      data,
      scenes,
      enabled,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_SCENE_ENABLE_VALUE))
    const currentScene = action.scenes.filter((scene) => scene.id === action.id)[0]
    const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
    if (isAndroid && isWebView) {
      // eslint-disable-next-line no-undef
      if (AndroidFunction.enableScene) {
        // eslint-disable-next-line no-undef
        AndroidFunction.enableScene(currentScene.number, action.enabled)
        store.dispatch(patchSceneEnableValueDoneAndroid())
        store.dispatch(clearCurrentSceneId())
        store.dispatch(clearCurrentSceneEnabled())
      } else {
        useBackendApi(action, dispatch, done)
      }
    } else if (isIOS && isWebView) {
      // eslint-disable-next-line no-alert
      const promptValue = prompt(`enableScene&${currentScene.number}&${action.enabled}`)
      if (promptValue === 'Invalid JS Request'
        || !JSON.parse(promptValue).status) {
        useBackendApi(action, dispatch, done)
      } else {
        store.dispatch(patchSceneEnableValueDoneIOS())
        store.dispatch(clearCurrentSceneId())
        store.dispatch(clearCurrentSceneEnabled())
      }
    } else {
      useBackendApi(action, dispatch, done)
    }
  },
})

export default patchSceneEnableValueLogic
