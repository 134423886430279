import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { START_SYNC_COUNTERS } from '@app/constants/syncCounters/countersChannel'
import {
  openSyncErrorPopup,
  openSyncInProgressPopup,
  syncChannel,
} from '@app/actions/syncCounters/countersChannel'
import Api from '@app/api/syncCounters/startSyncCounters'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { syncType } from '@app/lib/Synchronize/syncTypes'

const syncCountersStatusLogic = createLogic({
  type: START_SYNC_COUNTERS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(START_SYNC_COUNTERS))
    Api.startSyncCounters(token)
      .then(() => {
        dispatch(apiRequestDone(START_SYNC_COUNTERS))
        dispatch(syncChannel())
        dispatch(openSyncInProgressPopup())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(openSyncErrorPopup(syncType.SYNC_COUNTERS))
        } else {
          throw new Error('startSyncCounters failed without error')
        }
      })
      .then(() => done())
  },
})

export default syncCountersStatusLogic
