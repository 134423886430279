import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path fill="currentColor" d="M4.25.441C2.45-.594.988.254.988 2.332v15.32c0 2.078 1.461 2.922 3.262 1.891l13.395-7.68c1.796-1.031 1.796-2.71 0-3.742Zm0 0"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PlayButton', '0 0 20 20', {fontSize: 19})

export default icon
