import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import { convertActionTimersForScreen } from '@app/helpers/scenes/convertTimersForScreen'
import getTimerDurationDescription from '@app/helpers/timers/getTimerDurationDescription'
import {
  SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE,
  SELECT_ACTION_TIMER_VALUE,
  TIMERS_IN_SCENES,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import { ReloadCircle } from 'icons'

class SelectTimer extends React.Component {
  onBackClick = () => {
    const { navigate } = this.props
    navigate(SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE)
  }

  onUseClick=(value) => {
    const {
      saveActionDescription,
      navigate,
    } = this.props
    saveActionDescription(value.toString())
    navigate(SELECT_ACTION_TIMER_VALUE)
  }

  onSettingsClick=() => {
    const { navigate } = this.props
    navigate(TIMERS_IN_SCENES)
  }

  onReloadClick=() => {
    const { refresh } = this.props
    refresh()
  }

  render() {
    const {
      classes,
      isMobile,
      timers,
    } = this.props
    const timersInfo = convertActionTimersForScreen(timers)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_condition_web.select_timer')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.reloadButtonWrapper}>
            <Button
              size="small"
              aria-label="Refresh"
              className={classNames(classes.refreshButton)}
              onClick={() => {
                this.onReloadClick()
              }}
            >
              <ReloadCircle />
            </Button>
          </Wrapper>
          {timersInfo.length
            ? (timersInfo.map((timer) => (
              <CardBlock
                key={timer.name}
                title={I18n.t(`timers.timer_${timer.name}`)}
                description={`${I18n.t('timers.type')}: ${I18n.t(`timers.${timer.type}`)}`}
                position="topDoubleDescr"
                secondDescription={`${I18n.t('timers.duration')}: ${getTimerDurationDescription(timer.type, timer.duration)}`}
                buttonName={I18n.t('scene_web.use')}
                isCardDisabled={false}
                isButtonDisabled={false}
                onClick={() => {
                  this.onUseClick(timer.name)
                }}
              />
            )))
            : (
              <>
                <Wrapper className={classes.emptyInfoWrapper}>
                  <Typography>{I18n.t('scene_condition_web.no_timers_configured')}</Typography>
                  <Typography>{I18n.t('scene_condition_web.go_to_settings')}</Typography>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={this.onSettingsClick}
                  >
                    {I18n.t('scene_condition_web.settings')}
                  </Button>
                </Wrapper>
              </>
            )}
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimer.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionDescription: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  timers: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    timer_type: PropTypes.string.isRequired,
    duration: PropTypes.shape({
      day: PropTypes.number.isRequired,
      hour: PropTypes.number.isRequired,
      minute: PropTypes.number.isRequired,
      second: PropTypes.number.isRequired,
    }),
    status: PropTypes.string.isRequired,
  })).isRequired,
}

SelectTimer.defaultProps = {

}

SelectTimer.displayName = 'SelectTimer'

export default SelectTimer
