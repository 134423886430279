import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2352 12.2222H13.2941V13.9999C13.2941 14.1182 13.3439 14.2311 13.4315 14.3146L14.5204 15.3422L15.1858 14.7137L14.2352 13.8159V12.2222Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.7647 17.1111C11.9482 17.1111 10.4706 15.7156 10.4706 14C10.4706 12.2844 11.9482 10.8889 13.7647 10.8889C15.5812 10.8889 17.0588 12.2844 17.0588 14C17.0588 15.7156 15.5812 17.1111 13.7647 17.1111ZM13.7647 10C11.4297 10 9.52942 11.7947 9.52942 14C9.52942 16.2053 11.4297 18 13.7647 18C16.0998 18 18 16.2053 18 14C18 11.7947 16.0998 10 13.7647 10Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1765 5.55556H2.94117V4.22222C2.94117 3.97689 3.15294 3.77778 3.41176 3.77778H4.82353V4.22222C4.82353 4.46756 5.03436 4.66667 5.29412 4.66667C5.55388 4.66667 5.7647 4.46756 5.7647 4.22222V3.77778H12.3529V4.22222C12.3529 4.46756 12.5638 4.66667 12.8235 4.66667C13.0833 4.66667 13.2941 4.46756 13.2941 4.22222V3.77778H14.7059C14.9647 3.77778 15.1765 3.97689 15.1765 4.22222V5.55556ZM14.7059 2.88889H13.2941V2.44444C13.2941 2.19911 13.0833 2 12.8235 2C12.5638 2 12.3529 2.19911 12.3529 2.44444V2.88889H5.7647V2.44444C5.7647 2.19911 5.55388 2 5.29412 2C5.03436 2 4.82353 2.19911 4.82353 2.44444V2.88889H3.41176C2.63341 2.88889 2 3.48711 2 4.22222V17.1111H8.58823V16.2222H2.94117V6.44444H15.1765V9.11111H16.1176V4.22222C16.1176 3.48711 15.4842 2.88889 14.7059 2.88889Z" fill="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DeviceTimeIcon')

export default icon
