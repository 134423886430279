import React from 'react'
import {
  Button, TextField, Typography, Paper,
} from '@mui/material'
import classNames from 'classnames'
import validateName from '@app/helpers/myDevices/validateName'
import { NAME_PATTERN } from '@app/lib/RoomsAndGroups/roomsAndGroups'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { withStyles } from '@app/components/styles'

class EditGroup extends React.Component {
  state = {
    newGroupName: '',
    groupsList: [],
    twrDevices: [],
    zWaveDevices: [],
  }

  componentDidMount() {
    const { location } = this.props
    if (location.state) {
      this.setState({
        newGroupName: location.state.group.name,
        groupsList: location.state.groupsList.filter((group) => group.name !== location.state.group.name),
        twrDevices: location.state.group.twr_devices,
        zWaveDevices: location.state.group.zwave_devices,
      })
    }
  }

  filterDeletedDevice = (deviceId, isZwave) => {
    this.setState((prevState) => (isZwave
      ? ({ zWaveDevices: prevState.zWaveDevices.filter((device) => device.id !== deviceId) })
      : ({ twrDevices: prevState.twrDevices.filter((device) => device.id !== deviceId) })))
  }

  validateNonExistentName = (namesArray, currentName) => namesArray.map((name) => currentName === name).find((name) => name === true)

  handleSave = (data) => {
    const { location, patchGroup } = this.props
    patchGroup({ ...data, id: location.state.group.id })
  }

  render() {
    const {
      classes,
      isMobile,
      location,
      groupDeleteDevice,
    } = this.props
    const {
      newGroupName,
      groupsList,
      twrDevices,
      zWaveDevices,
    } = this.state
    return (
      <Paper className={classes.root}>
        <Wrapper className={classNames(classes.main, isMobile && classes.mainMobile)}>
          <Wrapper className={classNames(classes.nameAndSave, isMobile && classes.nameAndSaveMobile)}>
            <TextField
              variant="standard"
              id="standard-basic"
              label={I18n.t('rooms_and_groups.group_name')}
              className={classes.nameField}
              value={newGroupName}
              inputProps={{ className: classes.innerInput, maxLength: 16, pattern: NAME_PATTERN }}
              onChange={(e) => this.setState({ newGroupName: e.target.value.trimLeft() })}
            />
            {!!groupsList.length && (
              <Typography
                style={this.validateNonExistentName(groupsList.map((group) => group.name), newGroupName)
                  ? { display: 'inline-block' }
                  : { display: 'none' }}
                className={classes.errorMessage}
              >
                {I18n.t('rooms_and_groups.name_exists')}
              </Typography>
            )}

            <Button
              className={classes.saveButton}
              disabled={!newGroupName.length
              || (!!groupsList.length && this.validateNonExistentName(groupsList.map((group) => group.name), newGroupName))
              || !validateName(newGroupName)}
              onClick={() => this.handleSave({ name: newGroupName })}
            >
              {I18n.t('buttons.save')}
            </Button>
          </Wrapper>
          <Wrapper>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.twr_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {location.state && twrDevices.length ? twrDevices.map((device) => (
                <Wrapper
                  className={classNames(
                    classes.devicesCard,
                    isMobile && classes.cardMobile,
                  )}
                  key={`${device.name + 1}`}
                >
                  {device.name}
                  <Button
                    className={classes.cardControls}
                    onClick={() => {
                      groupDeleteDevice(device.id, false)
                      this.filterDeletedDevice(device.id, false)
                    }}
                  >
                    {I18n.t('buttons.delete')}
                  </Button>
                </Wrapper>
              ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.zWaveDevicesContainer}>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.zwave_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {location.state && zWaveDevices.length ? zWaveDevices.map((device) => (
                <Wrapper
                  className={classNames(
                    classes.devicesCard,
                    isMobile && classes.cardMobile,
                  )}
                  key={`${device.name + 1}`}
                >
                  {device.name}
                  <Button
                    className={classes.cardControls}
                    onClick={() => {
                      groupDeleteDevice(device.id, true)
                      this.filterDeletedDevice(device.id, true)
                    }}
                  >
                    {I18n.t('buttons.delete')}
                  </Button>
                </Wrapper>
              ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

EditGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      group: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        twr_devices: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })).isRequired,
        zwave_devices: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })).isRequired,
      }),
      groupsList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })),
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  patchGroup: PropTypes.func.isRequired,
  groupDeleteDevice: PropTypes.func.isRequired,

}

EditGroup.displayName = 'EditGroup'

export default withStyles(EditGroup)
