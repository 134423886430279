import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg fill="currentColor" version="1.1" viewBox="0 0 512 512"><polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "/></svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ArrowIOS','0 0 32 32', {fontSize: 30})

export default icon
