const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  backButton: {
    position: 'absolute',
    top: 10,
    left: 5,
  },
  backButtonDesktop: {
    position: 'absolute',
    top: 20,
    left: 35,
  },
  firstLine: {
    fontSize: '24px',
    marginBottom: 23,
  },
  secondLine: {
    fontSize: '20px',
    marginBottom: 26,
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'center',
  },
  thirdLine: {
    fontSize: '16px',
    marginBottom: 40,
    maxWidth: 320,
  },
})

export default styles
