import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import {
  IconButton, Typography, Button,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { Add, ScenesArrowBack, ReloadCircle } from 'icons'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { setMyDevices } from '@app/actions/myDevices/myDevices'
import {
  COMMIT_TO_MEZZO, COMPLETE, DELETING, IN_PROGRESS, NOT_SAVED,
} from '@app/constants/mezzoAnswers'
import AddRoomPopup from '@app/components/Rooms/RoomsList/AddRoomPopup/AddRoomPopup'
import {
  roomAddDevice, roomDeleteDevice, syncRoomsList,
} from '@app/actions/rooms/rooms'
import { IRooms, IRoom } from '@app/@types/rooms'
import ErrorPopup from '@app/components/Rooms/RoomsList/ErrorPopup/ErrorPopup'
import { maxRoomsNumber } from '@app/constants/rooms/rooms'
import useStyles from './DeviceRooms.style'

interface Props {
  roomsList: IRooms,
  admin: boolean,
  currentDevice: IZwaveOrTwrDevice,
  devices: Array<IZwaveOrTwrDevice>,
  isMobile: boolean,
}

const DeviceRooms: React.FC<Props> = (props) => {
  const {
    roomsList,
    admin,
    currentDevice,
    devices,
    isMobile,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isAddPopupOpen, setIsAddPopupOpen] = useState<boolean>(false)
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(syncRoomsList())
  }, [])

  useEffect(() => {
    setIsAddPopupOpen(false)
  }, [roomsList])

  const renderRoomStatus = (status: string): string => {
    switch (status) {
    case COMMIT_TO_MEZZO: return `(${I18n.t('geofence_web.saving')})`
    case IN_PROGRESS: return `(${I18n.t('geofence_web.saving')})`
    case NOT_SAVED: return `(${I18n.t('geofence_web.not_saved')})`
    case DELETING: return `(${I18n.t('geofence_web.deleting')})`
    case COMPLETE: return ''
    default: return ''
    }
  }

  const chooseOrDeleteDeviceHandler = (room: IRoom) => {
    if (currentDevice) {
      let updatedRooms: IZwaveOrTwrDevice['room']
      if (currentDevice.room?.id === room.id) {
        dispatch(roomDeleteDevice(currentDevice.id, currentDevice.device_type === DeviceTypes.zwaveDevice))
        updatedRooms = null
      } else {
        dispatch(roomAddDevice(currentDevice.id, room.id, currentDevice.device_type === DeviceTypes.zwaveDevice))
        updatedRooms = { id: room.id, name: room.name, number: room.number }
      }
      dispatch(setMyDevices(devices.map((device) => (device.id === currentDevice.id && device.device_type === currentDevice.device_type
        ? { ...device, room: updatedRooms }
        : device))))
    }
    goBackHandler()
  }

  const goBackHandler = () => navigate(-1)

  return (
    <>
      {currentDevice ? (
        <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          <Wrapper className={classes.navigationControls}>
            <Wrapper className={classes.navigationLeftSide}>
              <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
                <ScenesArrowBack />
              </IconButton>
              <Typography className={classes.navigationTitle}>{I18n.t('rooms_and_groups.my_rooms')}</Typography>
            </Wrapper>
            <IconButton
              onClick={() => {
                dispatch(syncRoomsList())
              }}
            >
              <ReloadCircle />
            </IconButton>
          </Wrapper>
          {roomsList.length !== 0 ? (
            <Wrapper className={classes.grid}>
              {roomsList.map((room, index) => (
                <Wrapper className={classes.gridElement} key={`room-${index + 1}`}>
                  <Typography>{`${room.name} ${room.status ? renderRoomStatus(room.status) : ''}`}</Typography>
                  {room.status === COMPLETE && (
                    <Button
                      color="primary"
                      className={classes.button}
                      variant="text"
                      onClick={() => {
                        chooseOrDeleteDeviceHandler(room)
                      }}
                    >
                      {currentDevice.room?.id === room.id
                        ? `${I18n.t('buttons.delete')}`
                        : `${I18n.t('buttons.choose')}`}
                    </Button>
                  )}
                </Wrapper>
              ))}
            </Wrapper>
          ) : (
            <Typography className={classes.emptyError}>{I18n.t('rooms_and_groups.no_rooms')}</Typography>
          )}
          <AddRoomPopup
            open={isAddPopupOpen}
            names={roomsList.map((room) => room.name)}
            close={() => {
              setIsAddPopupOpen(false)
            }}
          />
          <ErrorPopup
            open={isErrorPopupOpen}
            close={() => {
              setIsErrorPopupOpen(!isErrorPopupOpen)
            }}
          />
          {admin && (
            <IconButton
              aria-label="add button"
              className={classNames(classes.addButton, classes.initialAddButton)}
              onClick={() => {
                if (roomsList.length === maxRoomsNumber) {
                  setIsErrorPopupOpen(!isErrorPopupOpen)
                } else {
                  setIsAddPopupOpen(true)
                }
              }}
              size="large"
            >
              <Add />
            </IconButton>
          )}
        </Wrapper>
      ) : <Navigate to={location.pathname.split('/').slice(0, -1).join('/')} />}
    </>
  )
}

export default DeviceRooms
