export const virtualSwitchActionValues = [
  {
    title: null,
    description: 'turn_on',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 1,
  },
  {
    title: null,
    description: 'turn_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
  },
]
