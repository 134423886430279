import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import convertColorConditionDataToBackend from '@app/helpers/scenes/convertColorConditionDataToBackend'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import Swatches from './Swatches/Swatches'

class SelectColorConditionState extends React.Component {
  state = {
    currentColor: 'rgba(255, 0, 0, 1)',
  }

  onChangeColor = (color) => {
    const { rgb } = color
    this.setState({ currentColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` })
  }

  onAddStateClick=() => {
    const {
      saveConditionStateDescription,
      saveConditionDeviceStateInfo,
      isEditConditionMode,
      conditionDeviceNumber,
      navigate,
    } = this.props
    const { currentColor } = this.state
    saveConditionStateDescription(currentColor)
    saveConditionDeviceStateInfo(convertColorConditionDataToBackend(currentColor, conditionDeviceNumber))
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  onBackClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_colour')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.colorPickerWrapper}>
            <Swatches changeColor={this.onChangeColor} />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectColorConditionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
}

SelectColorConditionState.displayName = 'SelectColorConditionState'

export default SelectColorConditionState
