import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Collapse, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material'
import {
  ExpandLess, ExpandMore, HomeMenuIcon, SecurityIcon,
} from 'icons'
import {
  closeAllTabs, toggleHomeTab, toggleMySecurityTab,
  closeMenu,
} from '@app/actions/menu'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
// @ts-ignore
import elementsData from '@app/lib/Menu/ElementsData'
import { IAppConfig } from '@app/@types/appConfig'
import { AppState } from '@app/@types/store'
import { useNavigate } from 'react-router-dom'
import MenuItem from './MenuItem'
import SubMenuItem from './SubMenuItem'
import SubMenuListNew from './SubMenuListNew'
import useStyles from './Controls.style'

interface Props {
  appConfig: IAppConfig,
}

const Controls: React.FC<Props> = (props) => {
  const {
    appConfig,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const { isHomeOpen, isMySecurityMenuOpen } = useSelector((state: AppState) => state.menu)

  const resultConfig = appConfig.menu ? appConfig.menu.controls : defaultConfig.menu.controls
  const [menuHomeConfig, subMenuUsersConfig] = resultConfig

  const openMenuItem = (path: string) => {
    navigate(path)
    dispatch(closeMenu())
  }

  const renderHomeItem = () => (
    <ListItem button onClick={() => dispatch(toggleHomeTab())} disabled={menuHomeConfig.state === States.Disabled}>
      <ListItemIcon style={{ minWidth: '45px' }}>
        <HomeMenuIcon />
      </ListItemIcon>
      <ListItemText primary={I18n.t(`titles.${menuHomeConfig.code}`)} />
      {isHomeOpen ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
    </ListItem>
  )

  const renderHomeList = () => {
    const securityConfig = menuHomeConfig.items![0]
    const securitySubDisabled = securityConfig.state === States.Disabled
    return (
      <Collapse in={isHomeOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {securityConfig.state !== States.Invisible
          && (
            <>
              <ListItem className={classes.subList} disabled={securitySubDisabled}>
                <SubMenuItem
                  primary={I18n.t(`titles.${securityConfig.code}`)}
                  isSubMenuOpen={isMySecurityMenuOpen}
                  icon={<SecurityIcon />}
                  onItemClick={() => !securitySubDisabled && dispatch(toggleMySecurityTab())}
                  disabled={securitySubDisabled}
                />
              </ListItem>
              <ListItem className={classes.subList}>
                <SubMenuListNew
                  isSubMenuOpen={isMySecurityMenuOpen}
                  subMenuData={securityConfig?.items || [] as Array<{ code: string, state: States }>}
                />
              </ListItem>
            </>
          )}
          {menuHomeConfig.items!.map((item) => {
            if (item.state === States.Invisible || item.items) {
              return null
            }
            return (
              <MenuItem
                key={item.code}
                icon={elementsData[item.code].icon}
                title={item.code}
                close={() => dispatch(closeAllTabs())}
                disabled={item.state === States.Disabled}
                open={() => openMenuItem(elementsData[item.code].path)}
                sub
              />
            )
          })}
        </List>
      </Collapse>
    )
  }

  return (
    <>
      {menuHomeConfig.state !== States.Invisible && renderHomeItem()}
      {renderHomeList()}
      {subMenuUsersConfig.state !== States.Invisible && (
        <MenuItem
          key={subMenuUsersConfig.code}
          icon={elementsData[subMenuUsersConfig.code].icon}
          title={subMenuUsersConfig.code}
          disabled={subMenuUsersConfig.state === States.Disabled}
          open={() => openMenuItem(elementsData[subMenuUsersConfig.code].path)}
          close={() => dispatch(closeAllTabs())}
        />
      )}
    </>
  )
}

export default Controls
