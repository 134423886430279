import React, {
  useState, useEffect, useCallback, Dispatch, SetStateAction,
} from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, Typography,
} from '@mui/material'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import RadarCircles from '@app/components/Common/RadarCircles/RadarCircles'
import useStyles from './TriggerPopup.style'

interface Props {
  isTriggerPopupOpen: boolean,
  setIsTriggerPopupOpen: Dispatch<SetStateAction<boolean>>,
  currentDevice: IZwaveOrTwrDevice,
}

const TriggerPopup: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { isTriggerPopupOpen, setIsTriggerPopupOpen, currentDevice } = props
  const triggerTime = 59
  const expiredTime = 0
  const [triggerCounter, setTriggerCounter] = useState(triggerTime)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const timeOutCallback = useCallback(() => setTriggerCounter((current) => current - 1), [])

  useEffect(() => {
    if (isTriggerPopupOpen) {
      if (triggerCounter > 0) {
        const id = setTimeout(timeOutCallback, 1000)
        setTimeoutId(id)
      } else if (triggerCounter === expiredTime) {
        setIsTriggerPopupOpen(false)
      }
    }
  }, [triggerCounter, timeOutCallback, isTriggerPopupOpen])

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(null)
    }
    setTimeout(() => setTriggerCounter(triggerTime), 100)
  }, [isTriggerPopupOpen])

  return (
    <Dialog open={isTriggerPopupOpen}>
      <DialogTitle className={classes.title}>{`${currentDevice.name} device health`}</DialogTitle>
      <DialogContent className={classes.triggerPopupContent}>
        <Typography className={classes.triggerMessage}>Please trigger device.</Typography>
        <Typography className={classes.triggerCounter}>
          {`00 : ${triggerCounter.toString().length === 1 ? `0${triggerCounter}` : triggerCounter}`}
        </Typography>
        <RadarCircles />
        <Button
          className={classes.triggerPopupButton}
          variant="contained"
          color="primary"
          onClick={() => {
            setIsTriggerPopupOpen(!isTriggerPopupOpen)
          }}
        >
          {I18n.t('buttons.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default TriggerPopup
