import * as type from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'

export const update = (info) => ({
  type: type.UPDATE_CLOUD_FIRMWARE_INFO,
  info,
})

export const sync = () => ({
  type: type.SYNC_CLOUD_FIRMWARE_INFO,
})

export const saveSelectedFirmwareVersion = (version) => ({
  type: type.SAVE_SELECTED_FIRMWARE_VERSION,
  version,
})

export const saveDownloadingType = (downloadingType) => ({
  type: type.SAVE_DOWNLOADING_TYPE,
  downloadingType,
})

export const saveAutoInstallSetting = (value) => ({
  type: type.SAVE_AUTO_INSTALL_SETTING,
  value,
})

export const saveFirmwareLang = (lang) => ({
  type: type.SAVE_FIRMWARE_LANG,
  lang,
})

export const syncManualUpdate = () => ({
  type: type.SYNC_MANUAL_UPDATE,
})

export const syncFirmwareLang = () => ({
  type: type.SYNC_FIRMWARE_LANG,
})

export const openChangeLangPopup = () => ({
  type: type.OPEN_CHANGE_LANG_POPUP,
})

export const closeChangeLangPopup = () => ({
  type: type.CLOSE_CHANGE_LANG_POPUP,
})

export const saveSlotForDownload = (slot) => ({
  type: type.SAVE_SLOT_FOR_DOWNLOAD,
  slot,
})
export const saveSlotForInstall = (slot) => ({
  type: type.SAVE_SLOT_FOR_INSTALL,
  slot,
})

export const syncResetProcess = () => ({
  type: type.SYNC_RESET_PROCESS,
})

export const openResetPopup = () => ({
  type: type.OPEN_RESET_POPUP,
})

export const closeResetPopup = () => ({
  type: type.CLOSE_RESET_POPUP,
})

export const syncContinueDownloading = () => ({
  type: type.SYNC_CONTINUE_DOWNLOADING,
})

export const saveContinueDownloadingType = (downloadingType) => ({
  type: type.SAVE_CONTINUE_DOWNLOADING_TYPE,
  downloadingType,
})

export const saveDownloadingTime = (downloadingTime) => ({
  type: type.SAVE_DOWNLOADING_TIME,
  downloadingTime,
})

export const syncPatchCloudFirmwareInfo = () => ({
  type: type.SYNC_PATCH_CLOUD_FIRMWARE_INFO,
})

export const saveSubmittedDownloadingTime = (time) => ({
  type: type.SAVE_SUBMITTED_DOWNLOADING_TIME,
  time,
})

export const saveSubmittedNightlyDownloading = () => ({
  type: type.SAVE_SUBMITTED_NIGHTLY_DOWNLOADING,
})

export const syncStopUploading = () => ({
  type: type.SYNC_STOP_UPLOADING,
})

export const syncInstall = () => ({
  type: type.SYNC_START_INSTALL,
})

export const syncCellularModule = () => ({
  type: type.SYNC_CELLULAR_MODULE,
})

export const saveCellularModule = (cellularModule) => ({
  type: type.SAVE_CELLULAR_MODULE,
  cellularModule,
})

export const openChangeCellularModulePopup = () => ({
  type: type.OPEN_CHANGE_CELLULAR_MODULE_POPUP,
})

export const closeChangeCellularModulePopup = () => ({
  type: type.CLOSE_CHANGE_CELLULAR_MODULE_POPUP,
})

export const startReboot = () => ({
  type: type.START_REBOOT,
})
