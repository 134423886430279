export const timerTypes = [
  {
    type: 'one_shot',
    description: `${I18n.t('timers.one_shot_description')}`,
  },
  {
    type: 'repeating',
    description: `${I18n.t('timers.repeating_description')}`,
  },
  {
    type: 'retriggerable',
    description: `${I18n.t('timers.retriggerable_description')}`,
  },
]
