const convertInfoColor = (sensorType, sensorValue, sensorScale) => {
  const sensor = sensorTypes[sensorType]
  if (sensorValue === 'N/A' || !sensor) return null
  if (sensorValue === sensor.specialNumber) return 'critical'
  if (typeof sensor.criticalThreshold === 'object' || typeof sensor.warningThreshold === 'object') {
    return convertMultiRangeSensorColor(sensorValue, sensor, sensorScale)
  }
  return convertSimpleRangeSensorColor(sensorValue, sensor, sensorScale)
}

const convertMultiRangeSensorColor = (sensorValue, sensor, scale) => {
  if (sensorValue < sensor[`criticalThreshold${scale ? 'Alt' : ''}`].belowThisValue
    || sensorValue >= sensor[`criticalThreshold${scale ? 'Alt' : ''}`].aboveThisValue) return 'critical'
  if (sensorValue < sensor[`warningThreshold${scale ? 'Alt' : ''}`].belowThisValue
    || sensorValue >= sensor[`warningThreshold${scale ? 'Alt' : ''}`].aboveThisValue) return 'warning'
  return 'comfortable'
}

const convertSimpleRangeSensorColor = (sensorValue, sensor, scale) => {
  if (sensor[`criticalThreshold${scale ? 'Alt' : ''}`] < sensor[`comfortableThreshold${scale ? 'Alt' : ''}`]) {
    if (sensorValue < sensor[`criticalThreshold${scale ? 'Alt' : ''}`] || sensorValue === 'not detected') return 'critical'
    if (sensorValue < sensor[`warningThreshold${scale ? 'Alt' : ''}`]) return 'warning'
    return 'comfortable'
  }
  if (sensorValue >= [`warningThreshold${scale ? 'Alt' : ''}`] || sensorValue === 'not detected') return 'critical'
  if (sensorValue >= sensor[`comfortableThreshold${scale ? 'Alt' : ''}`]) return 'warning'
  return 'comfortable'
}

const sensorTypes = {
  air_temperature: {
    criticalThreshold: {
      belowThisValue: 1,
      aboveThisValue: 41,
    },
    warningThreshold: {
      belowThisValue: 15,
      aboveThisValue: 35,
    },
    criticalThresholdAlt: {
      belowThisValue: 33.8,
      aboveThisValue: 105.8,
    },
    warningThresholdAlt: {
      belowThisValue: 59,
      aboveThisValue: 95,
    },
  },
  battery: {
    criticalThreshold: 5,
    warningThreshold: 20,
    comfortableThreshold: 100,
    specialNumber: 255,
  },
  signal_level: {
    criticalThreshold: 3,
    warningThreshold: 7,
    comfortableThreshold: 9,
  },
  illuminance: {
    criticalThresholdAlt: 1000,
    warningThresholdAlt: 16001,
    comfortableThresholdAlt: 100000,
    criticalThreshold: 30,
    warningThreshold: 60,
    comfortableThreshold: 100,
  },
  humidity: {
    criticalThreshold: {
      belowThisValue: 0,
      aboveThisValue: 71,
    },
    warningThreshold: {
      belowThisValue: 40,
      aboveThisValue: 61,
    },
  },
  ultraviolet: {
    criticalThreshold: 10,
    warningThreshold: 6,
    comfortableThreshold: 3,
  },
  motion: {},
}

export default convertInfoColor
