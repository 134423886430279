import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ZWAVE_DEVICE_STATES } from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import { updateZWaveDeviceStates } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { SELECT_CONDITION_STATE_ZWAVE } from '@app/constants/routes'
import { deviceType } from '@app/lib/Scenes/devicesStatesConditions'
import getDeviceStates from '@app/api/scenes/getDeviceStates'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchZWaveDevicesStatesLogic = createLogic({
  type: SYNC_ZWAVE_DEVICE_STATES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionDeviceId } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ZWAVE_DEVICE_STATES))
    getDeviceStates(token, deviceType.zwaveDevices, conditionDeviceId)
      .then((response) => {
        const { data } = response
        dispatch(updateZWaveDeviceStates(data))
        dispatch(apiRequestDone(SYNC_ZWAVE_DEVICE_STATES))
        history.push(SELECT_CONDITION_STATE_ZWAVE)
        done()
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('getDeviceStates failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchZWaveDevicesStatesLogic
