import React from 'react'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './SliderControl.style'

interface Props {
  onOnButtonClick: () => void,
  onOffButtonClick: () => void,
  isFullWidth: boolean,
  center: boolean,
  props: Record<string, any>,
}

const SliderControl: React.FC<Props> = ({
  onOnButtonClick, onOffButtonClick, isFullWidth = false, center = false, ...props
}) => {
  const classes = useStyles()
  return (
    <Wrapper className={classNames(classes.sliderContainer, isFullWidth && classes.fullWidthContainer, center && classes.center)}>
      <Typography onClick={onOffButtonClick} className={classes.offButton}>Off</Typography>
      <Slider
        classes={{
          root: classes.slider,
          thumb: classes.thumb,
        }}
        size="small"
        aria-labelledby="slider"
        {...props}
      />
      <Typography onClick={onOnButtonClick} className={classes.onButton}>On</Typography>
    </Wrapper>
  )
}

export default SliderControl
