import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { SELECT_CONDITION_DEVICE_STATE_TYPE } from '@app/constants/routes'
import { withStyles } from '@app/components/styles'
import {
  saveConditionDescription,
  saveConditionDeviceNumber,
  saveConditionDeviceStateInfo,
  saveConditionStateDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectDevicesConditionState
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectDevicesConditionState/SelectDevicesConditionState'
import SelectDevicesConditionStateIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/SelectDevicesConditionState/SelectDevicesConditionState'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectDevicesConditionStateIOS) : withStyles(SelectDevicesConditionState)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  const { zWaveDeviceStates: deviceStates } = state.scenes.zWaveDevices
  const { type: conditionType } = state.scenes.currentSceneCreating.currentCondition
  const backPath = SELECT_CONDITION_DEVICE_STATE_TYPE
  return {
    isMobile,
    path,
    isEditConditionMode,
    deviceStates,
    backPath,
    conditionType,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionDescription,
  saveConditionDeviceNumber,
  saveConditionStateDescription,
  saveConditionDeviceStateInfo,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectDevicesConditionStateEnhanced = enhance

export default withRouter(SelectDevicesConditionStateEnhanced)
