import React from 'react'
import PropTypes from 'prop-types'

const withRefresh = (WrappedComponent) => {
  class Refreshable extends React.Component {
    componentDidMount() {
      const {
        refresh,
      } = this.props
      refresh()
    }

    componentDidUpdate(prevProps) {
      const {
        location,
        refresh,
      } = this.props
      if (location && location.pathname !== prevProps.location.pathname) {
        refresh()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  Refreshable.propTypes = {
    refresh: PropTypes.func.isRequired,
    updateToken: PropTypes.func,
    updateUser: PropTypes.func,
    accessToken: PropTypes.string || null,
    isWebView: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
      state: PropTypes.object,
      key: PropTypes.string,
    }),
  }

  Refreshable.defaultProps = {
    isWebView: false,
    updateToken: null,
    updateUser: null,
    accessToken: null,
    location: undefined,
  }

  return Refreshable
}

export default withRefresh
