import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { ITwrDeviceBackend, IZwaveDeviceBackend } from '@app/@types/scenes/scenesDevices'
import useStyles from './DevicesList.style'
import Device from './Device'

interface Props {
  devices: Array<ITwrDeviceBackend> | Array<IZwaveDeviceBackend>,
  deviceType: string,
}

const DevicesList: React.FC<Props> = (props) => {
  const {
    devices,
    deviceType,
  } = props

  const classes = useStyles()

  return (
    <List className={classes.root}>
      {devices.length
        ? devices.map((device) => (
          <ListItem className={classes.listItem} key={device.id}>
            <Device
              device={device}
              deviceType={deviceType}
            />
          </ListItem>
        )) : <Typography className={classes.empty} align="center">NO DEVICES</Typography>}
    </List>
  )
}

export default DevicesList
