import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ZWAVE_DEVICE_CAPABILITIES, SYNC_ZWAVE_DEVICES } from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import {
  updateZWaveDeviceCapabilities,
  updateZWaveDevices,
} from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import {
  SELECT_ACTION_DEVICE_STATE_TYPE,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_ZWAVE_DEVICE_TYPE,
  SELECT_CONDITION_DEVICE_TABS,
} from '@app/constants/routes'
import fetchZWaveDeviceCapabilities from '@app/api/scenes/ZWaveDevices/fetchZWaveDeviceCapabilities'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import fetchZWaveDevices from '@app/api/scenes/ZWaveDevices/fetchZWaveDevices'

const fetchZWaveDevicesCapabilitiesLogic = createLogic({
  type: SYNC_ZWAVE_DEVICE_CAPABILITIES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionZWaveCapabilityId, conditionDeviceId } } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentAction: { actionZWaveCapabilityId, actionDeviceId } } } } = store.getState()
    const { scenes: { zWaveDevices: { devices } } } = store.getState()
    if (!devices.length) {
      dispatch(apiRequestSent(SYNC_ZWAVE_DEVICES))
      fetchZWaveDevices(token)
        .then((response) => {
          const { data: { zwave_devices } } = response
          dispatch(updateZWaveDevices(zwave_devices))
          dispatch(apiRequestDone(SYNC_ZWAVE_DEVICES))
          return zwave_devices
        }).catch((error) => {
          if (error) {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          } else {
            throw new Error('fetchZWaveDevices failed without error')
          }
        })
        .then((zwave_devices) => {
          const currentDevice = zwave_devices.find((device) => device.id === conditionDeviceId || device.id === actionDeviceId)
          dispatch(apiRequestSent(SYNC_ZWAVE_DEVICE_CAPABILITIES))
          fetchZWaveDeviceCapabilities(token, conditionZWaveCapabilityId || actionZWaveCapabilityId)
            .then((response) => {
              const { data: { capability } } = response
              dispatch(updateZWaveDeviceCapabilities(capability))
              dispatch(apiRequestDone(SYNC_ZWAVE_DEVICE_CAPABILITIES))
              if (currentDevice.security_option && window.location.pathname === SELECT_CONDITION_DEVICE_TABS) {
                history.push(SELECT_ZWAVE_DEVICE_TYPE)
              } else if (conditionZWaveCapabilityId) {
                history.push(SELECT_CONDITION_DEVICE_STATE_TYPE)
              } else {
                history.push(SELECT_ACTION_DEVICE_STATE_TYPE)
              }
            })
            .catch((error) => {
              if (error) {
                const currentError = handleErrors(error)
                dispatch(apiRequestFailed(currentError))
              } else {
                throw new Error('fetchZWaveDeviceCapabilities failed without error')
              }
            })
            .then(() => done())
        })
    } else {
      const currentDevice = devices.find((device) => device.id === conditionDeviceId || device.id === actionDeviceId)
      dispatch(apiRequestSent(SYNC_ZWAVE_DEVICE_CAPABILITIES))
      fetchZWaveDeviceCapabilities(token, conditionZWaveCapabilityId || actionZWaveCapabilityId)
        .then((response) => {
          const { data: { capability } } = response
          dispatch(updateZWaveDeviceCapabilities(capability))
          dispatch(apiRequestDone(SYNC_ZWAVE_DEVICE_CAPABILITIES))
          if (currentDevice.security_option && window.location.pathname === SELECT_CONDITION_DEVICE_TABS) {
            history.push(SELECT_ZWAVE_DEVICE_TYPE)
          } else if (conditionZWaveCapabilityId) {
            history.push(SELECT_CONDITION_DEVICE_STATE_TYPE)
          } else {
            history.push(SELECT_ACTION_DEVICE_STATE_TYPE)
          }
        })
        .catch((error) => {
          if (error) {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          } else {
            throw new Error('fetchZWaveDeviceCapabilities failed without error')
          }
        })
        .then(() => done())
    }
  },
})

export default fetchZWaveDevicesCapabilitiesLogic
