import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { syncZWaveDevices } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { withAccessDenied, withProgressAndErrorMessage, withRefresh } from '@app/hocs'
import { isIOS } from 'react-device-detect'
import DevicesList
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/DevicesConditions/SelectDeviceCommon/DevicesList/DevicesList'
import DevicesListIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/DevicesConditions/SelectDeviceCommon/DevicesList/DevicesList'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? DevicesListIOS : DevicesList

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { devices } = state.scenes.zWaveDevices
  const { deviceType } = state.scenes.currentSceneCreating.currentCondition
  return {
    deviceType,
    isMobile,
    devices,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: syncZWaveDevices,
  tryAgain: syncZWaveDevices,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const DevicesListEnhanced = enhance

export default DevicesListEnhanced
