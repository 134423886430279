import React from 'react'
import createSvgIcon from './../../helper'

const fragment = (
  <React.Fragment>
    <svg width="37" height="32" viewBox="0 0 57 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z" stroke="currentColor" strokeWidth="1.1"/>
      <rect x="25" y="8" width="7" height="17" fill="#333333"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M41.728 13.4114C43.5495 15.0255 43.5195 17.8786 41.6644 19.454L33.3659 26.5013V21.2672H18.2006C17.6483 21.2672 17.2006 20.8195 17.2006 20.2672V12.6715C17.2006 12.1192 17.6483 11.6715 18.2006 11.6715L33.3659 11.6715V6.00127L41.728 13.4114Z" fill="currentColor" stroke="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Exiting', '0 0 28 28', {fontSize: 100})

export default icon
