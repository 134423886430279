import { createLogic } from 'redux-logic'
import Api from '@app/api/myDevices/deviceSettings/failedNode'
import handleErrors from '@app/helpers/handleErrors'
import { REPLACE_UNHEALTHY_DEVICE } from '@app/constants/myDevices/deviceSettings/failedNode'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'

const replaceUnhealthyDeviceLogic = createLogic({
  type: REPLACE_UNHEALTHY_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(REPLACE_UNHEALTHY_DEVICE))
    try {
      await Api.replaceUnhealthyDevice(action.accessToken, action.id)
      dispatch(apiRequestDone(REPLACE_UNHEALTHY_DEVICE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('REPLACE_UNHEALTHY_DEVICE failed without error')
      }
    }
    done()
  },
})

export default replaceUnhealthyDeviceLogic
