import { createLogic } from 'redux-logic'
import { SYNC_FAILED_NODE_CHANNEL } from '@app/constants/myDevices/channels/deviceSettings/failedNode'
import { syncFailedNodeChannelSent, updateFailedNode } from '@app/actions/myDevices/channels/deviceSettings/failedNodeChannel'
import Api from '@app/api/myDevices/channels/deviceSettings/failedNodeChannel'

const subscribeFailedNodeLogic = createLogic({
  type: SYNC_FAILED_NODE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.failedNodeChannel({
      request: () => {
        dispatch(syncFailedNodeChannelSent())
      },
      response: (data) => {
        store.dispatch(updateFailedNode(data))
      },
    })
  },
})

export default subscribeFailedNodeLogic
