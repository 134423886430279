import * as type from '@app/constants/myDevices/channels/deviceSettings/failedNode'

interface IFailedNodeData {
  zwave_health?: string,
  status?: string,
}

export const subscribeFailedNodeChannel = () => ({
  type: type.OPEN_FAILED_NODE_CHANNEL,
} as const)

export const closeFailedNodeChannel = () => ({
  type: type.CLOSE_FAILED_NODE_CHANNEL,
} as const)

export const syncFailedNodeChannel = () => ({
  type: type.SYNC_FAILED_NODE_CHANNEL,
} as const)

export const syncFailedNodeChannelSent = () => ({
  type: type.SYNC_FAILED_NODE_CHANNEL_SENT,
} as const)

export const updateFailedNode = (data: IFailedNodeData) => ({
  type: type.UPDATE_FAILED_NODE,
  data,
} as const)
