const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: theme.backgrounds.transparentBg,
  },
  main: {
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 20px',
    margin: 0,
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainDesktop: {
    maxWidth: 450,
    position: 'relative',
    marginTop: 0,
    width: '100%',
    display: 'flex',
    border: '2px solid transparent',
    boxSizing: 'border-box',
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainLogo: {
    marginBottom: 50,
  },
  sendButton: {
    maxWidth: 200,
    height: 36,
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: 2,
    boxShadow: '0px 4px 4px rgba(111, 155, 77, 0.5)',
    marginBottom: 43,
    marginTop: 15,
  },
  error: {
    width: '100%',
    color: '#ED1F24',
    textAlign: 'center',
    fontSize: '14px',
  },
  form: {
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  links: {
    maxWidth: 329,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    fontSize: '14px',
    cursor: 'pointer',
    marginBottom: 15,
  },
  agreementText: {
    marginBottom: 10,
  },
  privacy: {
    fontSize: '16px',
    cursor: 'pointer',
  },
})

export default styles
