import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { recommendations } from '@app/lib/MezzoTime/recommendations'
import { syncMezzoTime } from '@app/actions/mezzoTime/mezzoTime'
import { ExpandMore } from 'icons'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './MezzoTime.style'

const MezzoTime = (): Node => {
  const dispatch = useDispatch()
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const { mezzoTime } = useSelector((state) => state.mezzoTime)
  const classes = useStyles()
  const [date, setDate] = useState('')

  useEffect(() => {
    dispatch(syncMezzoTime())
  }, [])

  useEffect(() => {
    if (mezzoTime) {
      setDate(mezzoTime)
    }
  }, [mezzoTime])

  return (
    <Paper className={classes.root}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.timeCard}>
            <Typography className={classNames(classes.cardTitle, isMobile && classes.cardTitleMobile)}>
              {I18n.t('mezzo_time.mezzo_time')}
            </Typography>
            <Wrapper className={classNames(classes.cardDescription, isMobile && classes.cardDescriptionMobile)}>
              {date}
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.cardWrapper}>
            <Wrapper className={classes.cardLeftSideWrapper}>
              <Typography className={classNames(classes.cardTitle, isMobile && classes.cardTitleMobile)}>
                {I18n.t('mezzo_time.automatic_date_time')}
              </Typography>
              <Typography className={classNames(classes.cardDescription, isMobile && classes.cardDescriptionMobile)}>
                {I18n.t('mezzo_time.network_cloud_time')}
              </Typography>
            </Wrapper>
          </Wrapper>
          <Accordion
            className={classNames(classes.recommendations, isMobile && classes.recommendationsMobile)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={classes.recommendationsSummary}
            >
              <Wrapper className={classes.recommendationsLeftSide}>
                <Typography className={classNames(classes.cardTitle, isMobile && classes.cardTitleMobile)}>
                  {I18n.t('mezzo_time.recommendations')}
                </Typography>
                <Typography className={classNames(classes.cardDescription, isMobile && classes.cardDescriptionMobile)}>
                  {I18n.t('mezzo_time.wrong_date_time')}
                </Typography>
              </Wrapper>
            </AccordionSummary>
            <AccordionDetails className={classes.recommendationsDetails}>
              <Typography className={classNames(classes.mainQuestion, isMobile && classes.mainQuestionMobile)}>
                {I18n.t('mezzo_time.recommendation_title')}
              </Typography>
              {recommendations.map((rec, index) => (
                <Wrapper
                  key={`rec-${index + 1}`}
                >
                  <Typography className={classNames(classes.question, isMobile && classes.questionMobile)}>
                    {rec.question}
                  </Typography>
                  <Typography className={classNames(classes.answer, isMobile && classes.answerMobile)}>
                    {rec.answer}
                  </Typography>
                </Wrapper>
              ))}
            </AccordionDetails>
          </Accordion>
        </Wrapper>
      </LocalizationProvider>
    </Paper>
  )
}

export default MezzoTime
