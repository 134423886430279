import React from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { GROUPS } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { ScenesArrowBack } from 'icons'
import { withStyles } from '@app/components/styles'

class AddDeviceToGroup extends React.PureComponent {
  render() {
    const {
      classes,
      isMobile,
      filterAddTwrDevices,
      filterAddZwaveDevices,
      freeTwrDevices,
      freeZwaveDevices,
      groupAddDevice,
      location,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Wrapper className={classNames(classes.main, isMobile && classes.mainMobile)}>
          <Wrapper>
            <Wrapper className={classes.deviceLabel}>
              <Link underline="hover" to={GROUPS}>
                <IconButton className={classes.deviceArrow} size="large">
                  <ScenesArrowBack />
                </IconButton>
              </Link>
              <Typography>{I18n.t('rooms_and_groups.twr_devices')}</Typography>
            </Wrapper>
            <Wrapper className={classes.devicesContainer}>
              {freeTwrDevices.length ? freeTwrDevices.map((device) => (
                <Wrapper
                  className={classNames(
                    classes.devicesCard,
                    isMobile && classes.cardMobile,
                  )}
                  key={`${device.name + 1}`}
                >
                  {device.name}
                  <Button
                    className={classes.cardControls}
                    onClick={() => {
                      filterAddTwrDevices(device.id)
                      groupAddDevice(device.id, location.state.groupId, false)
                    }}
                  >
                    {I18n.t('scene_web.add')}
                  </Button>
                </Wrapper>
              ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.zWaveDevicesContainer}>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.zwave_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {freeZwaveDevices.length ? freeZwaveDevices.map((device) => (
                <Wrapper
                  className={classNames(
                    classes.devicesCard,
                    isMobile && classes.cardMobile,
                  )}
                  key={`${device.name + 1}`}
                >
                  {device.name}
                  <Button
                    className={classes.cardControls}
                    onClick={() => {
                      filterAddZwaveDevices(device.id)
                      groupAddDevice(device.id, location.state.groupId, true)
                    }}
                  >
                    {I18n.t('scene_web.add')}
                  </Button>
                </Wrapper>
              ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    )
  }
}

AddDeviceToGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      groupId: PropTypes.number,
    }),
  }).isRequired,
  freeTwrDevices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  freeZwaveDevices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  filterAddTwrDevices: PropTypes.func.isRequired,
  filterAddZwaveDevices: PropTypes.func.isRequired,
  groupAddDevice: PropTypes.func.isRequired,
}

AddDeviceToGroup.displayName = 'AddDeviceToGroup'

export default withStyles(AddDeviceToGroup)
