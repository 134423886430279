import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_UNIT } from '@app/constants/unit'
import { updateUnit } from '@app/actions/unit'
import Api from '@app/api/unit/fetchUnitInfo'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchUnitInfoLogic = createLogic({
  type: FETCH_UNIT,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      token,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_UNIT))
    Api.fetchUnitInfo(action.token)
      .then((response) => {
        const { unit_device } = response.data
        dispatch(updateUnit(unit_device))
        dispatch(apiRequestDone(FETCH_UNIT))
      })
      .catch((error) => {
        if (error) {
          if (error.response.data.error.code === 340) {
            dispatch(apiRequestFailed(error.response.data.error.message))
          } else {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          }
        } else {
          throw new Error('fetchUnitInfo failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchUnitInfoLogic
