import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import styles from './Arrow.style'

class Arrow extends React.PureComponent {
  getClassName=(trend) => {
    let className = 'noArrow'
    if (trend < 0) {
      className = 'decreasingArrow'
    } else if (trend > 0) {
      className = 'increasingArrow'
    }
    return className
  }

  render() {
    const {
      trend,
      classes,
    } = this.props
    return (
      <div className={classNames(
        classes[this.getClassName(trend)],
      )}
      />
    )
  }
}

Arrow.propTypes = {
  classes: PropTypes.object.isRequired,
  trend: PropTypes.number,
}

Arrow.defaultProps = {
  trend: null,
}

export default withStyles(styles)(Arrow)
