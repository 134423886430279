import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { setPinCode, closeChangePinPopup } from '@app/actions/users/secretCode'
import { pinCodeFields } from '@app/lib/Users/pinCode'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'
import PinCodePopup from '@app/components/Users/UserEdit/PinCodePopup/PinCodePopup'
import { validate } from './formValidate'

let enhance = withStyles(PinCodePopup)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isNCentral } = state.unit
  return {
    isMobile,
    isNCentral,
    enableReinitialize: true,
    initialValues: pinCodeFields.reduce((result, option) => {
      result[option.title] = option.value
      return result
    }, {}),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeChangePinPopup,
  submit: () => dispatch(submit('PinCodePopup')),
}, dispatch)

enhance = reduxForm({
  form: 'PinCodePopup',
  validate: (values) => validate(values, store.getState().unit.isNCentral),
  onSubmit: () => {
    store.dispatch(setPinCode())
  },
})(enhance)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const PinCodePopupEnhanced = enhance

export default PinCodePopupEnhanced
