import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button, Paper, TextField, Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import classNames from 'classnames'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import { deleteAccount } from '@app/actions/wizard/deleteAccount'
import useStyles from './DeleteAccount.style'

interface Props {
  isMobile: boolean,
  isTablet: boolean,
}

const deleteAccountScreen: React.FC<Props> = (props) => {
  const {
    isMobile,
    isTablet,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [username, saveUsername] = useState<string>('')
  const [password, savePassword] = useState<string>('')
  const [isWarningOpen, toggleWarning] = useState<boolean>(false)

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveUsername(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    savePassword(event.target.value)
  }

  const handleDeleteAccount = () => {
    toggleWarning(true)
  }

  const isFormValid = username.trim() !== '' && password.trim() !== ''

  const handleWarningOk = () => {
    dispatch(deleteAccount(username, password))
    toggleWarning(false)
  }

  const handleWarningClose = () => {
    toggleWarning(false)
  }

  return (
    <Paper className={classes.root}>
      <Wrapper className={classNames(
        classes.main,
        (!isMobile || isTablet) && classes.mainDesktop,
      )}
      >
        <Typography className={classes.header}>{I18n.t('wizard_web.delete account')}</Typography>
        <form className={classes.form}>
          <TextField
            className={classes.textField}
            label={I18n.t('wizard_web.email_or_phone')}
            name="emailOrPhone"
            value={username}
            onChange={handleUsernameChange}
            variant="standard"
            margin="normal"
          />
          <TextField
            className={classes.textField}
            label={I18n.t('wizard_web.password')}
            name="password"
            value={password}
            onChange={handlePasswordChange}
            type="password"
            variant="standard"
            margin="normal"
          />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleDeleteAccount}
            disabled={!isFormValid}
          >
            {I18n.t('wizard_web.delete')}
          </Button>
        </form>
        <Typography className={classes.info}>{I18n.t('wizard_web.delete_text')}</Typography>
      </Wrapper>
      <WarningComponent
        open={isWarningOpen}
        onClick={handleWarningClose}
        onClose={handleWarningOk}
        warningText={I18n.t('wizard_web.delete_warning')}
        leftButton="cancel"
        rightButton="ok"
        untranslatableError
      />

    </Paper>
  )
}

export default deleteAccountScreen
