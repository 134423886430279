const sceneReviewPrefixChanger = (scenePrefix: string): string => {
  switch (scenePrefix) {
  case 'if':
    return 'If...'
  case 'when':
    return 'When...'
  case 'while':
    return 'While...'
  default:
    return 'When...'
  }
}

export default sceneReviewPrefixChanger
