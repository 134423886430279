import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M108.226 0H33.71c-.982 0-1.774.793-1.774 1.774v47.993c-.591-.035-1.175-.09-1.775-.09C13.53 49.677 0 63.207 0 79.84 0 96.47 13.53 110 30.161 110c16.632 0 30.162-13.53 30.162-30.161 0-7.443-2.724-14.251-7.207-19.516h55.11c.981 0 1.774-.793 1.774-1.775V1.774C110 .793 109.207 0 108.226 0zM30.161 106.452c-14.674 0-26.613-11.939-26.613-26.613 0-14.675 11.939-26.613 26.613-26.613 14.675 0 26.613 11.938 26.613 26.613 0 14.674-11.938 26.613-26.613 26.613zm76.291-49.678H49.557a30.048 30.048 0 0 0-14.073-6.599V3.548h70.968v53.226z"/>
      <path fill="currentColor" d="M99.356 26.613h3.548v26.613h-3.548V26.613zM99.356 19.516h3.548v3.549h-3.548v-3.549zM92.258 33.71h3.548v19.516h-3.548V33.71zM92.258 26.613h3.548v3.548h-3.548v-3.548zM85.162 40.806h3.548v12.42h-3.548v-12.42zM85.162 33.71h3.548v3.548h-3.548V33.71zM70.967 44.355h3.548v8.87h-3.548v-8.87zM70.967 37.258h3.548v3.548h-3.548v-3.548zM78.064 37.258h3.549v15.968h-3.549V37.258zM78.064 30.161h3.549v3.549h-3.549V30.16zM77.025 19.516h8.136c.472 0 .923-.186 1.255-.52l5.842-5.842v6.362h3.548V8.871h-.015c0-.229-.029-.46-.12-.68a1.772 1.772 0 0 0-1.639-1.094H83.387v3.548h6.362l-5.322 5.323H76.29c-.472 0-.922.186-1.254.52L64.39 27.133l2.508 2.509 10.126-10.126zM42.408 75.529a1.773 1.773 0 0 0-1.602-1.013H35.07l3.71-6.183c.33-.548.337-1.23.023-1.789a1.779 1.779 0 0 0-1.545-.899H26.612c-.672 0-1.286.38-1.587.982L17.928 80.82a1.775 1.775 0 0 0 1.588 2.567h6.598l-2.995 11.99a1.774 1.774 0 0 0 3.092 1.552L42.18 77.413c.435-.53.523-1.263.229-1.884zM28.48 88.554l1.627-6.511a1.773 1.773 0 0 0-1.721-2.204h-6l5.322-10.645h6.414l-3.71 6.182a1.778 1.778 0 0 0-.023 1.79c.317.556.907.898 1.545.898h5.128l-8.582 10.49zM39.033 44.355h23.065v3.548H39.033v-3.548zM39.033 37.258h23.065v3.548H39.033v-3.548zM39.033 30.161h23.065v3.549H39.033V30.16zM46.129 17.742h5.179l-4.79 5.988 2.77 2.218 7.097-8.871a1.779 1.779 0 0 0 .213-1.88A1.777 1.777 0 0 0 55 14.195h-5.736l3.71-6.183-3.043-1.826-5.322 8.87a1.778 1.778 0 0 0-.024 1.79c.316.555.906.897 1.544.897zM30.162 99.355v3.548c12.718 0 23.065-10.347 23.065-23.064h-3.549c0 10.76-8.755 19.516-19.516 19.516zM30.162 56.774c-12.717 0-23.064 10.347-23.064 23.065h3.548c0-10.76 8.756-19.516 19.516-19.516v-3.549z"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'StatisticsBillingIcon')

export default icon
