import * as type from '@app/constants/scenes/twrDevices/twrDevicesByRooms'
import { IRoomTwrBackend } from '@app/@types/scenes/scenesDevices'

export const syncTwrDevicesByRooms = () => ({
  type: type.SYNC_TWR_DEVICES_BY_ROOMS,
} as const)

export const updateTwrDevicesByRooms = (rooms: Array<IRoomTwrBackend>) => ({
  type: type.UPDATE_TWR_DEVICES_BY_ROOMS,
  rooms,
} as const)

export const resetTwrDevicesByRooms = () => ({
  type: type.RESET_TWR_DEVICES_BY_ROOMS,
} as const)
