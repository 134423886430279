import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  privacyPolicy: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  agreementText: {
    marginBottom: 10,
    fontSize: '14px',
  },
  privacy: {
    fontSize: '16px',
    cursor: 'pointer',
    color: theme.typography.color,
  },
}))

export default useStyles
