export const SYNC_VIRTUAL_SWITCHES = '@@virtualSwitches/virtualSwitchesSync'

export const SET_VIRTUAL_SWITCHES = '@@virtualSwitches/virtualSwitchesSet'
export const SAVE_CHANGED_VIRTUAL_SWITCH_DATA = '@@virtualSwitches/saveChangedVirtualSwitchData'

export const TRIGGER_VIRTUAL_SWITCH = '@@virtualSwitches/triggerVirtualSwitch'

export const VIRTUAL_SWITCH_CHANNEL = 'Unit::VirtualSwitchChannel'
export const SYNC_VIRTUAL_SWITCH_CHANNEL = '@@virtualSwitches/virtualSwitchChannelSync'
export const SYNC_VIRTUAL_SWITCH_CHANNEL_SENT = '@@virtualSwitches/virtualSwitchesChannelSyncSent'
export const SYNC_VIRTUAL_SWITCH_CHANNEL_CANCEL = '@@virtualSwitches/virtualSwitchChannelSyncCancel'
export const OPEN_VIRTUAL_SWITCH_CHANNEL = '@@virtualSwitches/virtualSwitchChannelOpen'
export const CLOSE_VIRTUAL_SWITCH_CHANNEL = '@@virtualSwitches/virtualSwitchChannelClose'
export const UNSUBSCRIBE_VIRTUAL_SWITCH_CHANNEL = '@@virtualSwitches/unsubscribeVirtualSwitchChannel'

export const START_ENABLING_PROGRESS = '@@virtualSwitches/startEnablingProgress'
export const STOP_ENABLING_PROGRESS = '@@virtualSwitches/stopEnablingProgress'
export const UPDATE_VIRTUAL_SWITCH_BY_NUMBER = '@@virtualSwitches/updateVirtualSwitchByNumber'
