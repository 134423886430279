import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveActionStateDescription, saveActionType } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectMezzoActionState
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/SelectMezzoActionState/SelectMezzoActionState'
import SelectMezzoActionStateIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/MezzoActions/SelectMezzoActionState/SelectMezzoActionState'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectMezzoActionStateIOS) : withStyles(SelectMezzoActionState)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isEditActionMode,
    isChangeActionMode,
    isChangeActionStateMode,
    initialAction,
  } = state.scenes.currentSceneCreating
  const { actions } = state.scenes.currentSceneConditionsActions
  return {
    isMobile,
    path,
    isEditActionMode,
    isChangeActionMode,
    isChangeActionStateMode,
    actions,
    initialAction,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveActionStateDescription,
  notifications: syncNotifications.sync,
  saveActionType,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectMezzoActionStateEnhanced = enhance

export default withRouter(SelectMezzoActionStateEnhanced)
