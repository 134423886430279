import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g fill="currentColor" clipPath="url(#clip0)">
        <path d="M18.344 24a5.5 5.5 0 010-11 5.288 5.288 0 014.985 3.88.5.5 0 01-.971.241 4.33 4.33 0 00-4.014-3.12 4.5 4.5 0 100 9 .5.5 0 110 .999z"/>
        <path d="M23.343 18h-3a.5.5 0 110-1h2.5v-2.5a.5.5 0 011 0v3a.5.5 0 01-.5.5zM20.223 3.858a1.058 1.058 0 00-1.3-.467l-2.48 1a8.535 8.535 0 00-1.477-.858L14.592.9a1.04 1.04 0 00-1.035-.9h-4.4c-.52-.004-.961.377-1.034.891l-.378 2.638a8.859 8.859 0 00-1.476.859l-2.485-1a1.037 1.037 0 00-1.285.459l-2.2 3.802A1.049 1.049 0 00.556 9l2.1 1.642a7.544 7.544 0 000 1.714L.554 14a1.056 1.056 0 00-.255 1.347l2.194 3.8c.258.456.811.654 1.3.465l2.479-1c.463.334.958.621 1.479.857l.377 2.63c.07.517.512.903 1.035.9h3.436a.5.5 0 00.41-.786 6.458 6.458 0 01-.9-5.567.5.5 0 00-.086-.451.476.476 0 00-.42-.189c-.055 0-.108.01-.243.026a4.511 4.511 0 114.5-4.429 1.707 1.707 0 00-.018.162.5.5 0 00.642.506 6.28 6.28 0 013.018-.162.522.522 0 00.394-.12.565.565 0 00.2-.38l.006-.11c-.001-.287-.017-.573-.048-.858l2.106-1.64c.405-.326.513-.897.255-1.348l-2.192-3.795z"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="currentColor" d="M0 0h24v24H0z"/>
        </clipPath>
      </defs>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'ResetGear')

export default icon
