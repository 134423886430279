export const precision = {
  none: 0,
  decimals: 1,
  centesimals: 2,
  millesimals: 3,
}

export const scales = {
  celsius: {
    type: 0,
    name: '°C',
  },
  fahrenheit: {
    type: 1,
    name: '°F',
  },
  percent: {
    type: 0,
    name: '%',
  },
  lux: {
    type: 1,
    name: 'lux',
  },
  index: {
    type: 0,
    name: 'index',
  },
}

export const sensorsValues = {
  temperatureSensor: {
    celsius: {
      initialValue: 20,
      minValue: -10,
      maxValue: 50,
      valueStep: 1,
    },
    fahrenheit: {
      initialValue: 68,
      minValue: 14,
      maxValue: 122,
      valueStep: 1,
    },
  },
  luminanceSensor: {
    initialValue: 15000,
    minValue: 0,
    maxValue: 30000,
    maxLength: 5,
    valueStep: 1,
  },
  humiditySensor: {
    initialValue: 50,
    minValue: 0,
    maxValue: 100,
    valueStep: 1,
  },
  ultravioletSensor: {
    initialValue: 5,
    minValue: 0,
    maxValue: 10,
    valueStep: 1,
  },
}
