import React from 'react'
import { Typography, IconButton } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { CameraIcon, SortIcon } from 'icons'
import useStyles from './CameraCard.style'

interface Props {
  isDragging: boolean,
  cameraName: string | null,
  isMobile: boolean,
}

const CameraCard: React.FC<Props> = (props) => {
  const { isDragging, cameraName, isMobile } = props
  const classes = useStyles()

  return (
    <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile, isDragging && classes.dragging)}>
      <Wrapper className={classNames(classes.cardInfo, isMobile && classes.cardInfoMobile)}>
        <Wrapper className={classes.cardIconContainer}>
          <CameraIcon />
        </Wrapper>
        <Wrapper className={isMobile ? classes.nameAndStatus : null}>
          <Typography className={classes.cardName}>
            {cameraName ? `${cameraName}` : 'Camera'}
          </Typography>
        </Wrapper>
        <IconButton
          size="small"
          className={classes.chevronIcon}
        >
          {isDragging ? <SortIcon /> : <></>}
        </IconButton>
      </Wrapper>
    </Wrapper>
  )
}

export default CameraCard
