import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width='280' height='280' viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_948_396)">
        <path d="M8.94775 23.5025L13.7186 17L8.94775 10.4975L10.4165 8.5L16.6665 17L10.4165 25.5L8.94775 23.5025Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_948_396">
          <rect width="25" height="34" fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>



  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'RightArrowIcon')

export default icon
