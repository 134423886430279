import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import {
  clearCurrentRoomIdAndName,
  clearCurrentRoomStats,
  openRoomStatsPopup,
  resetCurrentRoomCounters,
  selectCurrentRoomIdAndName,
  sync,
  syncCurrentRoomStats,
  closeRoomStatsPopup,
} from '@app/actions/myEnergy/roomsStats'
import RoomsStats from '@app/components/MyEnergy/Tabs/RoomsStats/RoomsStats'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'

let enhance = withStyles(RoomsStats)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { roomsStats: { isRoomsStatsPopupOpen } } = store.getState()
  const RoomsStatsItems = state.roomsStats.rooms
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isMobile,
    RoomsStatsItems,
    isRoomsStatsPopupOpen,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
  openRoomStatsPopup,
  selectCurrentRoomIdAndName,
  clearCurrentRoomIdAndName,
  syncCurrentRoomStats,
  clearCurrentRoomStats,
  resetCurrentRoomCounters,
  closeRoomStatsPopup,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RoomsStatsEnhanced = enhance

export default RoomsStatsEnhanced
