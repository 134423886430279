import * as type from '@app/constants/mySecurity/mySecurity'

export const sendKeyboardCommand = (command: string) => ({
  type: type.SEND_KEYBOARD_COMMAND,
  command,
} as const)

export const openPanicPopup = () => ({
  type: type.OPEN_PANIC_POPUP,
} as const)

export const closePanicPopup = () => ({
  type: type.CLOSE_PANIC_POPUP,
} as const)

export const startPanic = () => ({
  type: type.START_PANIC,
} as const)

export const cancelPanic = () => ({
  type: type.CANCEL_PANIC,
} as const)

export const saveWorkerInfo = (workerInfo: string) => ({
  type: type.SAVE_WORKER_INFO,
  workerInfo,
} as const)

export const setArmHomeMode = () => ({
  type: type.SET_ARM_HOME_MODE,
} as const)

export const resetMySecurity = () => ({
  type: type.RESET_MY_SECURITY,
} as const)
