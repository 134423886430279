import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'
import { isIOS } from 'react-device-detect'
import TimersInScenesSettings from '@app/components/MyAutomation/Tabs/ScenesScreens/TimersInScenes/TimersInScenesSettings/TimersInScenesSettings'
import TimersInScenesSettingsIOS from '@app/components/MyAutomation/Tabs/iOS/TimersInScenes/TimersInScenesSettings/TimersInScenesSettings'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = isIOS ? TimersInScenesSettingsIOS : TimersInScenesSettings

const mapStateToProps = (state: AppState) => {
  const { timers, timerData } = state.timers
  const { isMobile } = state.mobileDetector
  return {
    isMobile,
    timers,
    timerData,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const TimersInScenesSettingsEnhanced = enhance

export default TimersInScenesSettingsEnhanced
