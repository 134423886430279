import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17">
      <path fill="currentColor" d="M0 .5h16v16H0z" opacity=".01"/>
      <path fill="currentColor" fillRule="evenodd" d="M6.243 1.852a4.233 4.233 0 0 1 0 8.463 4.233 4.233 0 0 1-4.231-4.231 4.24 4.24 0 0 1 4.231-4.232Zm0-.333a4.57 4.57 0 0 0-4.565 4.565 4.57 4.57 0 0 0 4.565 4.565 4.57 4.57 0 0 0 4.565-4.565 4.57 4.57 0 0 0-4.565-4.565Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M6.243 2.102a3.983 3.983 0 0 1 3.982 3.982h.5a4.483 4.483 0 0 0-4.482-4.482v.5Zm3.982 3.982a3.983 3.983 0 0 1-3.982 3.982v.5a4.483 4.483 0 0 0 4.482-4.482h-.5Zm-3.982 3.982a3.983 3.983 0 0 1-3.981-3.982h-.5a4.483 4.483 0 0 0 4.481 4.482v-.5ZM2.262 6.083a3.99 3.99 0 0 1 3.981-3.982v-.5a4.49 4.49 0 0 0-4.481 4.482h.5Zm3.981-4.815a4.82 4.82 0 0 0-4.815 4.815h.5a4.32 4.32 0 0 1 4.315-4.315v-.5ZM1.428 6.084a4.82 4.82 0 0 0 4.815 4.815v-.5a4.32 4.32 0 0 1-4.315-4.315h-.5Zm4.815 4.815a4.82 4.82 0 0 0 4.815-4.815h-.5a4.32 4.32 0 0 1-4.315 4.315v.5Zm4.815-4.815a4.82 4.82 0 0 0-4.815-4.815v.5a4.32 4.32 0 0 1 4.315 4.315h.5Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M6.195 2.633a3.494 3.494 0 0 1 3.499 3.499 3.494 3.494 0 0 1-3.5 3.499 3.494 3.494 0 0 1-3.498-3.499 3.507 3.507 0 0 1 3.499-3.499Zm0-.324a3.838 3.838 0 0 0-3.833 3.833 3.838 3.838 0 0 0 3.833 3.833 3.838 3.838 0 0 0 3.833-3.833 3.838 3.838 0 0 0-3.833-3.833Z" clipRule="evenodd"/>
      <path stroke="currentColor" strokeWidth="1.5" d="m8.686 10.079 5.034 5.05 1.366-1.37-5.015-5.03M10.972 12.386l1.371-1.372"/>
      <path stroke="currentColor" strokeLinecap="square" d="M3.314 7.015h.915l.56-1.837 1.272 2.74 1.281-4.26.743 3.357h1.12"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'TwrDevicesHealthIcon')

export default icon
