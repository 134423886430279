import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M65.233 122.301L64.8155 138.583L84.0194 139V121.883H118.67L100.718 94.3301H113.243L89.0291 57.5922H96.1262L75.2524 29.6214L53.9612 57.5922H61.4757L37.2621 93.9126H50.2039L31 121.883L65.233 122.301Z" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M75.6699 10L78.5922 14.1748L83.6019 15.8447L80.2621 20.0194L80.6796 25.0291L75.6699 23.3592L70.2427 25.0291L70.6602 20.0194L67.3204 15.8447L72.3301 14.1748L75.6699 10Z" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ChristmasTree')

export default icon
