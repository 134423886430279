const getDate = (isoDate, length) => {
  if (isoDate === null) {
    return 'N/A'
  }
  if (isoDate === '') {
    return ''
  }
  const fullDate = new Date(isoDate)
  const date = (`0${fullDate.getDate()}`).slice(-2)
  const month = fullDate.toLocaleString('en-us', { month: length })
  const year = fullDate.getFullYear()
  return `${date} ${month} ${year}`
}

export default getDate
