import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9844 14.0156V9.98438H11.0156V14.0156H12.9844ZM12.9844 18V15.9844H11.0156V18H12.9844ZM0.984375 21L12 2.01562L23.0156 21H0.984375Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'PanicAlertIcon')

export default icon
