import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { closePopup, set } from '@app/actions/users/secretCode'
import { secretCodeFields } from '@app/lib/Users/secretCode'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'
import SecretCodePopup from '@app/components/Users/UserEdit/SecretCodePopup/SecretCodePopup'
import { validate } from './formValidate'

let enhance = withStyles(SecretCodePopup)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  return {
    isMobile,
    enableReinitialize: true,
    initialValues: secretCodeFields.reduce((result, option) => {
      result[option.title] = option.value
      return result
    }, {}),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  closePopup,
  submit: (dispatch) => dispatch(submit('SecretCodePopup')),
}, parentDispatch)

enhance = reduxForm({
  form: 'SecretCodePopup',
  validate,
  onSubmit: () => {
    store.dispatch(set())
  },
})(enhance)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SecretCodePopupEnhanced = enhance

export default SecretCodePopupEnhanced
