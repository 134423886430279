import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isClientCredentialsTokenValid from '@app/helpers/isClientCredentialsTokenValid'
import { withStyles } from '@app/components/styles'
import { passwordFields } from '@app/lib/Wizard/ForgotPasswordFields'
import { clearCode, clearEmail, postNewPassword } from '@app/actions/wizard/forgotPassword'
import NewPassword from '@app/components/Wizard/ForgotPassword/NewPasswordScreen/NewPassword'
import { withAccessDenied, withProgressAndErrorMessage, withRouter } from '@app/hocs'
import { validate } from './formValidate'

let enhance = withStyles(NewPassword)

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { secrets: { clientCredentialsToken } } = store.getState()
  const isTokenValid = isClientCredentialsTokenValid(clientCredentialsToken)
  return {
    isMobile,
    isTablet,
    path,
    initialValues: passwordFields.reduce((result, option) => {
      result[option.title] = option.value
      return result
    }, {}),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isTokenValid,
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  submit: (dispatch) => dispatch(submit('NewPassword')),
  clearEmail,
  clearCode,
}, parentDispatch)

enhance = reduxForm({
  form: 'NewPassword',
  validate,
  onSubmit: () => {
    store.dispatch(postNewPassword())
  },
})(enhance)

enhance = withRouter(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const NewPasswordEnhanced = enhance

export default NewPasswordEnhanced
