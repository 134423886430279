export const groupActionsStateTypes = [
  {
    title: 'device_switch_title',
    description: 'device_switch_rooms_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'device_switch_groups',
  },
  {
    title: 'colour_switch_title',
    description: 'color_switch_rooms_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'color_switch_groups',
  },
]
