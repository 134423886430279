import * as type from '@app/constants/wizard/wizard'

export const signUp = () => ({
  type: type.SIGN_UP,
} as const)

export const showUserExistsError = () => ({
  type: type.SHOW_USER_EXISTS_ERROR,
} as const)

export const hideUserExistsError = () => ({
  type: type.HIDE_USER_EXISTS_ERROR,
} as const)

export const showActivationInfoPopup = () => ({
  type: type.SHOW_ACTIVATION_INFO_POPUP,
} as const)

export const closeActivationInfoPopup = () => ({
  type: type.CLOSE_ACTIVATION_INFO_POPUP,
} as const)

export const showActivationWarningPopup = () => ({
  type: type.SHOW_ACTIVATION_WARNING_POPUP,
} as const)

export const closeActivationWarningPopup = () => ({
  type: type.CLOSE_ACTIVATION_WARNING_POPUP,
} as const)

export const showActivationErrorPopup = () => ({
  type: type.SHOW_ACTIVATION_ERROR_POPUP,
} as const)

export const closeActivationErrorPopup = () => ({
  type: type.CLOSE_ACTIVATION_ERROR_POPUP,
} as const)

export const sendAccountVerificationLink = () => ({
  type: type.SEND_ACCOUNT_VERIFICATION_LINK,
} as const)
