import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2263 14.8971V5.74024C15.4841 4.20092 14.2851 2.95417 12.6294 2H4V18H16.2325V17.0748M12.5241 9.18H15.5869M4.6395 9.18H8.7103M13.721 11.1806H15.5868M4.6395 11.1806H7.51337M4.6395 13.1812H7.79518M13.9858 13.1812H15.5868M4.6395 3.1798H11.9883M4.6395 5.18039H11.9883M4.6395 7.18099H15.5868M4.6395 15.1802H13.6095M4.6395 17.1808H15.5868M8.89767 13.1812H12.3352M8.46566 11.1806H12.7672M16.2263 5.89839L12.5659 5.83197L12.6294 2.15815M12.5917 13.2328C13.3961 12.1653 13.1644 10.6339 12.0741 9.8123C10.9838 8.99071 9.44787 9.19005 8.64346 10.2575C7.83906 11.325 8.07081 12.8564 9.16109 13.678C10.2514 14.4996 11.7873 14.3002 12.5917 13.2328ZM16.5716 15.4158L13.3308 12.9345C13.149 13.379 12.8616 13.7704 12.4947 14.0731L15.7355 16.5545C15.8414 16.6355 15.9745 16.6702 16.1055 16.651C16.2365 16.6317 16.3546 16.56 16.4338 16.4517L16.6707 16.1354C16.7522 16.027 16.7876 15.8897 16.769 15.7544C16.7503 15.6191 16.6792 15.4971 16.5716 15.4158ZM13.5613 12.3997C13.9148 10.8076 12.8832 9.22433 11.2572 8.86325C9.63114 8.50218 8.02639 9.50006 7.67286 11.0921C7.31933 12.6841 8.35091 14.2674 9.97694 14.6285C11.603 14.9896 13.2077 13.9917 13.5613 12.3997Z" stroke="currentColor" strokeWidth="0.7"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EventsIcon')

export default icon
