import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9844 9H18.5156L12.9844 3.51562V9ZM6 2.01562H14.0156L20.0156 8.01562V20.0156C20.0156 20.5469 19.8125 21.0156 19.4062 21.4219C19 21.7969 18.5312 21.9844 18 21.9844H6C5.46875 21.9844 5 21.7969 4.59375 21.4219C4.1875 21.0156 3.98438 20.5469 3.98438 20.0156L4.03125 3.98438C4.03125 3.45312 4.21875 3 4.59375 2.625C5 2.21875 5.46875 2.01562 6 2.01562Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'UniversalIcon')

export default icon
