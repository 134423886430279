import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_CURRENT_MEMBER } from '@app/constants/users/currentMember'
import { updateCurrentMember } from '@app/actions/users/currentMember'
import { roleValues } from '@app/lib/Users/roleValues'
import Api from '@app/api/users/patchUser'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitCurrentMemberLogic = createLogic({
  type: SET_CURRENT_MEMBER,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { form: { MemberDetailsForm: { values } } } = getState()
    const { secrets: { accessToken: { token } } } = getState()
    const { users: { currentMember: { user: { ids: { sphere: sphereId, unit: unitId } } } } } = getState()
    const newRole = { role: roleValues[values.change_role] }
    const formData = { role: roleValues[values.change_role], number: unitId }
    next({
      ...action,
      formData,
      newRole,
      token,
      sphereId,
      unitId,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_CURRENT_MEMBER))
    Api.patchUnitUser(action.token, action.sphereId, action.formData)
      .then(() => {
        dispatch(updateCurrentMember(action.newRole))
        dispatch(apiRequestDone(SET_CURRENT_MEMBER))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchUnitUser failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitCurrentMemberLogic
