import sortDevicesByStatus from './sortDevicesByTheirStatus'

const updateDevices = (state, action) => {
  const update = [action.data]
  const filter = (sensorToUpdateArray, sensorToBeUpdatedWithArray) => (
    sensorToUpdateArray.map((device) => (
      sensorToBeUpdatedWithArray.find((updatedObj) => updatedObj.id === device.id && updatedObj.type === device.type) || device))
  )
  return {
    ...state,
    zWaveDevices: filter(state.zWaveDevices, update),
    backupZWaveDevices: filter(state.backupZWaveDevices, update),
    twrDevices: sortDevicesByStatus(filter(state.twrDevices, update)),
    backupTwrDevices: sortDevicesByStatus(filter(state.backupTwrDevices, update)),
  }
}

export default updateDevices
