export const syncList = [
  {
    title: 'Scene, Geofences, Timers, Virtual Switches',
    status: true,
    type: 'scenes',
  },
  {
    title: 'Z-wave devices',
    status: true,
    type: 'zwave',
  },
  {
    title: 'Rooms, Groups, Keys, TWR-devices',
    status: true,
    type: 'general',
  },
]
