import React from 'react'
import classNames from 'classnames'
import {
  ClickAwayListener, List, ListItem, Paper,
} from '@mui/material'
import reformatType from '@app/helpers/myDevices/reformatType'
import { formatDetailsInfo } from '@app/helpers/myDevices/formatDetailsInfo'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { isTwrKeyfob } from '@app/helpers/myDevices/isTwrKeyfob'
import useStyles from '../DeviceInfo.style'

interface Props {
  device: IZwaveOrTwrDevice,
  type?: string,
  onClickAway: () => void,
  isMobile: boolean,
  showTwrDeviceDetails: boolean,
}

const InfoPopup: React.FC<Props> = (props) => {
  const {
    device, type, onClickAway, isMobile, showTwrDeviceDetails,
  } = props
  const classes = useStyles()
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Paper className={classNames(classes.deviceInfoPopup, isMobile && classes.deviceInfoPopupMobile)}>
        <List className={classes.infoPopupList}>
          {(!device.txid || !isTwrKeyfob(device.txid!)) && (
            <>
              <ListItem>
                {`${I18n.t('my_status.room')}: ${device.room ? device.room.name : I18n.t('my_energy.not_available')}`}
              </ListItem>
              <ListItem>
                {`${I18n.t('my_status.group')}: ${device.group ? device.group.name : I18n.t('my_energy.not_available')}`}
              </ListItem>
            </>
          )}
          {type === DeviceTypes.twrDevice && (
            <>
              {showTwrDeviceDetails && (
                <>
                  <ListItem>
                    {`${I18n.t('my_devices.device_details')}: ${device.details_info
                      ? formatDetailsInfo(device.details_info)
                      : I18n.t('my_devices.not_available')}`}
                  </ListItem>
                  <ListItem>{`${I18n.t('my_devices.details_mode')}: ${I18n.t(`my_devices.${device.option || 'not_available'}`)}`}</ListItem>
                </>
              )}
              <ListItem>{`${I18n.t('my_devices.device_txid')}: ${device.txid || ''}`}</ListItem>
            </>
          )}
          {type === DeviceTypes.zwaveDevice && (
            <>
              <ListItem>
                {`${I18n.t('my_devices.capability')}: ${device.type ? reformatType(device.type) : I18n.t('my_energy.not_available')}`}
              </ListItem>
              <ListItem>
                {`${I18n.t('my_devices.zwave_id')}: ${device.number}`}
              </ListItem>
              <ListItem>
                {`${I18n.t('my_devices.eco_mode')}: ${device.eco_mode
                  ? I18n.t('my_devices.on')
                  : I18n.t('my_devices.off')}`}
              </ListItem>
              <ListItem>
                {`${I18n.t('my_devices.secure_mode')}: 
                ${device.secure_mode && device.secure_mode.charAt(0).toUpperCase() + device.secure_mode.slice(1)}`}
              </ListItem>
            </>
          )}
        </List>
      </Paper>
    </ClickAwayListener>
  )
}

export default InfoPopup
