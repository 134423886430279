export const SEND_KEYBOARD_COMMAND = '@@keyboard/sendKeyboardCommand'
export const RESET_MY_SECURITY = '@@keyboard/resetMySecurity'

export const OPEN_PANIC_POPUP = '@@panic/openPanicPopup'
export const CLOSE_PANIC_POPUP = '@@panic/closePanicPopup'

export const START_PANIC = '@@panic/startPanic'
export const CANCEL_PANIC = '@@panic/cancelPanic'

export const SAVE_WORKER_INFO = '@@panic/saveWorkerInfo'
export const SET_ARM_HOME_MODE = '@@armHomeMode/setArmHomeMode'
