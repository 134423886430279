import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  group: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
