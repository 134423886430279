import * as type from '@app/constants/syncCounters/countersChannel'

export const syncFailed = (error) => ({
  type: type.SYNC_FAILED_COUNTERS_CHANNEL,
  error,
})

export const syncChannel = () => ({
  type: type.SYNC_COUNTERS_CHANNEL,
})

export const syncSentChannel = () => ({
  type: type.SYNC_SENT_COUNTERS_CHANNEL,
})

export const syncDoneChannel = () => ({
  type: type.SYNC_DONE_COUNTERS_CHANNEL,
})
export const openChannel = () => ({
  type: type.OPEN_COUNTERS_CHANNEL,
})

export const closeChannel = () => ({
  type: type.CLOSE_COUNTERS_CHANNEL,
})

export const openSyncWarningPopup = () => ({
  type: type.OPEN_SYNC_WARNING_POPUP,
})

export const closeSyncWarningPopup = () => ({
  type: type.CLOSE_SYNC_WARNING_POPUP,
})

export const openSyncInProgressPopup = () => ({
  type: type.OPEN_SYNC_IN_PROGRESS_POPUP,
})

export const closeSyncInProgressPopup = () => ({
  type: type.CLOSE_SYNC_IN_PROGRESS_POPUP,
})

export const openSyncErrorPopup = (syncType) => ({
  type: type.OPEN_SYNC_ERROR_POPUP,
  syncType,
})

export const closeSyncErrorPopup = () => ({
  type: type.CLOSE_SYNC_ERROR_POPUP,
})

export const syncStart = () => ({
  type: type.START_SYNC_COUNTERS,
})
