import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import {
  Typography, IconButton, Checkbox,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { Edit } from 'icons'
import { IConfigParameter, IConfigParameterValue } from '@app/@types/myDevices'
import { Optional } from '@app/@types/common'
import classNames from 'classnames'
import EditParamValuePopup
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditComponents/EditParamValuePopup/EditParamValuePopup'
import useStyles from './MultiRanges.style'

interface Props {
  currentParameter: IConfigParameter,
  temporaryValues: Optional<Array<IConfigParameterValue>>,
  setTemporaryValues: Dispatch<SetStateAction<Optional<Array<IConfigParameterValue>>>>,
  isMobile: boolean,
}

const MultiRanges: React.FC<Props> = (props) => {
  const {
    currentParameter, temporaryValues, setTemporaryValues, isMobile,
  } = props
  const classes = useStyles()
  const [isEditingValue, setIsEditingValue] = useState<boolean>(false)
  const [editableParameter, setEditableParameter] = useState<any>(null)
  const [values, setValues] = useState<Array<IConfigParameterValue>>(currentParameter.values)

  useEffect(() => {
    setValues(currentParameter.values)
  }, [currentParameter])

  const handleChange = (parameter: IConfigParameterValue) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values.map((param) => (
      param.description === parameter.description
        ? { ...param, isEnabled: event.target.checked }
        : param)))
    setTemporaryValues(values.map((param) => (
      param.description === parameter.description
        ? { ...param, isEnabled: event.target.checked }
        : param)))
  }

  return (
    <Wrapper className={classes.root}>
      {values.map((parameter, index) => (
        <Wrapper className={classes.card} key={`parameter - ${index + 1}`}>
          <Checkbox
            className={classes.checkbox}
            onChange={handleChange(parameter)}
            checked={parameter.isEnabled}
          />
          <Typography className={classNames(classes.cardDescription)}>
            {parameter.description}
          </Typography>
          <Wrapper className={classes.valueWrapper}>
            <Typography className={classes.cardValue}>Set to:</Typography>
            {parameter.value !== null ? (
              <Typography className={classes.cardValue}>
                {temporaryValues !== null
                  ? temporaryValues[index].value
                  : parameter.value}
              </Typography>
            ) : (
              <Typography className={classes.not_received}>not received</Typography>
            )}
          </Wrapper>
          <IconButton
            className={classes.editButton}
            aria-label="Edit"
            onClick={() => {
              setIsEditingValue(true)
              setEditableParameter(parameter)
            }}
            disabled={currentParameter.set_to === null}
            size="small"
          >
            <Edit />
          </IconButton>
        </Wrapper>
      ))}
      {isEditingValue && (
        <EditParamValuePopup
          editableParameter={editableParameter}
          values={values}
          setValues={setValues}
          setTemporaryValues={setTemporaryValues}
          setIsEditingValue={setIsEditingValue}
          isMulti
          isMobile={isMobile}
        />
      )}
    </Wrapper>
  )
}

export default MultiRanges
