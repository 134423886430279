const calculateColorActionType = (actionDelay, actionDuration) => {
  if (Object.keys(actionDuration).length && Object.keys(actionDelay).length) {
    return 'ZwaveColorDelayDuration'
  }
  if (Object.keys(actionDelay).length) {
    return 'ZwaveColorDelayDuration'
  }
  if (Object.keys(actionDuration).length) {
    return 'ZwaveColorSetWithDuration'
  }
  return 'SetZwaveColor'
}

export default calculateColorActionType
