import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.3954 60.582L29 102.6L33.6088 106.774L39.9075 101.209V141.001H60.8008V111.365H89.9899V141.001H110.883V101.209L117.182 106.774L121.791 102.6L75.3954 60.582Z" fill="currentColor"/>
      <path d="M115.568 14.0363L118.698 12.5197L116.194 10.4517C115.881 10.3139 110.091 5.48852 99.6063 9.7624C90.217 13.6227 87.5568 22.1704 85.3659 29.7531L85.053 30.5803C84.114 33.8891 81.4538 35.8193 79.1065 36.7844C79.1065 35.5436 78.95 34.1649 78.637 32.6483C77.5416 28.6502 72.534 25.4793 72.0645 25.0657L68.4653 22.7219L67.9959 26.5822C67.9959 26.7201 66.744 36.9222 57.6677 39.266C46.4007 42.1612 39.6717 49.4681 39.8282 58.981C39.9847 68.7695 46.0877 73.457 50.9388 77.1794C51.0953 77.3173 51.4083 77.4552 51.5648 77.593L55.164 74.4221C54.851 74.1464 54.538 74.0085 54.3815 73.7327C49.6869 70.0103 45.1488 66.5637 45.1488 58.8431C45.1488 54.4314 46.8701 46.7108 59.2326 43.5398C67.2134 41.4718 70.6562 35.4057 72.221 31.1318C73.0035 31.959 73.6294 32.7862 73.7859 33.6134C74.5683 36.3708 73.7859 39.266 73.7859 39.266L72.847 42.5748L76.7592 42.0233C77.2286 42.0233 87.5568 40.5068 90.3735 31.5454L90.6865 30.7182C92.8773 23.5491 94.7552 16.6558 102.11 13.7605C105.24 12.5197 107.587 12.244 109.308 12.3819C105.396 15.1392 100.232 19.9646 99.7627 26.5822C99.1368 34.3028 101.797 38.8524 104.457 43.1262C106.805 46.8487 108.839 50.4332 108.682 56.3615C108.526 63.3927 102.267 68.6317 97.1025 72.9055C96.7895 73.1813 96.32 73.5949 96.007 73.8706L99.6063 77.0416C99.9192 76.7658 100.232 76.4901 100.702 76.2144C106.179 71.6647 113.534 65.3229 113.847 56.4994C114.003 49.3303 111.343 44.9185 108.995 40.9204C106.492 36.7844 104.457 33.1998 104.927 26.8579C105.083 19.2752 115.411 14.0363 115.568 14.0363Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BurningDownTheHouse')

export default icon
