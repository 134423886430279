import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/users/refresh_info`

const hotUpdate = (token) => Axios.get(ENDPOINT,
  {
    headers: { 'Access-Token': token },
  })

export default {
  hotUpdate,
}
