import update from 'immutability-helper'
import * as type from '@app/constants/wizard/wizard'

type State = {
  loginError: string,
  authInProgress: boolean,
  isAccountLocked: boolean,
}

const initialState = {
  loginError: '',
  authInProgress: false,
  isAccountLocked: false,
}

export default function authByPassword(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.SHOW_SIGN_IN_ERROR: {
    return update(state, {
      loginError: { $set: action.loginError },
    })
  }
  case type.HIDE_SIGN_IN_ERROR: {
    return update(state, {
      loginError: { $set: '' },
    })
  }

  case type.AUTH_STARTED: {
    return { ...state, authInProgress: true }
  }

  case type.AUTH_FINISHED: {
    return { ...state, authInProgress: false }
  }

  case type.SHOW_ACCOUNT_LOCKED_ERROR: {
    return { ...state, isAccountLocked: true }
  }

  case type.HIDE_ACCOUNT_LOCKED_ERROR: {
    return { ...state, isAccountLocked: false }
  }

  default:
    return state
  }
}
