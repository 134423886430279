import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import convertMultilevelSensorDataToBackend from '@app/helpers/scenes/convertMultilevelSensorDataToBackend'
import getMultilevelSensorDescription from '@app/helpers/scenes/getMultilevelSensorDescription'
import getMultilevelSensorMarks from '@app/helpers/scenes/getMultilevelSensorMarks'
import { precision, scales, sensorsValues } from '@app/lib/Scenes/multilevelSensors'
import { SELECT_MULTILEVEL_SENSOR_STATE_SELECTED } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './FahrenheitVariant.style'

class FahrenheitVariant extends React.Component {
  state = {
    radioValue: 'greater than',
    sensorValue: sensorsValues.temperatureSensor.fahrenheit.initialValue,
  }

  backendSensorOperand = (radioValue) => (radioValue === 'greater than' ? 'gt' : 'lt')

  onNextClick = () => {
    const {
      navigate,
      saveConditionDeviceStateInfo,
      saveMultilevelSensorValueDescription,
      conditionDeviceNumber,
      sensorType,
    } = this.props
    const { sensorValue, radioValue } = this.state
    saveConditionDeviceStateInfo(convertMultilevelSensorDataToBackend(
      conditionDeviceNumber,
      sensorType,
      sensorValue,
      scales.fahrenheit.type,
      precision.none,
      this.backendSensorOperand(radioValue),
    ))
    saveMultilevelSensorValueDescription(getMultilevelSensorDescription(radioValue, sensorValue, scales.fahrenheit.name))
    navigate(SELECT_MULTILEVEL_SENSOR_STATE_SELECTED)
  }

  handleChange = (event) => {
    this.setState({ radioValue: event.target.value })
  }

  handleSliderChange = (event, newValue) => {
    this.setState({ sensorValue: newValue })
  }

  handleInputChange = (event) => {
    this.setState({ sensorValue: Number(event.target.value) })
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const { radioValue, sensorValue } = this.state
    return (
      <Wrapper className={classes.wrapper}>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.cardBlock}>
            <Wrapper className={classNames(
              isMobile ? classes.cardContentMobile : classes.cardContent,
            )}
            >
              <Wrapper className={classes.cardTopWrapper}>
                <Typography className={classes.title}>{I18n.t('scene_web.value')}</Typography>
              </Wrapper>
              <FormControl variant="standard" className={classes.formControl}>
                <RadioGroup
                  row
                  className={classes.radioWrapper}
                  aria-label="radio"
                  name="radio1"
                  value={radioValue}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    classes={{
                      root: classes.radioButton,
                    }}
                    value="greater than"
                    control={<Radio color="primary" />}
                    label={I18n.t('multisensor_scene.value_greater_than')}
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.radioButton,
                    }}
                    value="less than"
                    control={<Radio color="primary" />}
                    label={I18n.t('multisensor_scene.value_less_than')}
                  />
                </RadioGroup>
              </FormControl>
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.cardBlock}>
            <Wrapper className={classNames(
              isMobile ? classes.cardContentMobile : classes.cardContent,
            )}
            >
              <Wrapper>
                <Typography className={classes.title}>{I18n.t('scene_web.value')}</Typography>
                <TextField
                  variant="standard"
                  value={sensorValue}
                  className={classes.sensorInput}
                  onChange={this.handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{scales.fahrenheit.name}</Typography>
                      </InputAdornment>
                    ),
                    inputProps: {
                      readOnly: true,
                    },
                  }}
                />
              </Wrapper>
              <Slider
                classes={{
                  rail: classes.sliderRail,
                  track: classes.sliderTrack,
                  thumbColorPrimary: classes.sliderThumbColor,
                  mark: classes.sliderMark,
                }}
                value={typeof sensorValue === 'number' ? sensorValue : 0}
                onChange={this.handleSliderChange}
                min={sensorsValues.temperatureSensor.fahrenheit.minValue}
                step={sensorsValues.temperatureSensor.fahrenheit.valueStep}
                max={sensorsValues.temperatureSensor.fahrenheit.maxValue}
                marks={getMultilevelSensorMarks(
                  sensorsValues.temperatureSensor.fahrenheit.minValue,
                  sensorsValues.temperatureSensor.fahrenheit.maxValue,
                )}
              />
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.onNextClick()
            }}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

FahrenheitVariant.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveMultilevelSensorValueDescription: PropTypes.func.isRequired,
  sensorType: PropTypes.number.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
}

FahrenheitVariant.defaultProps = {

}

export default withStyles(styles)(FahrenheitVariant)
