import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  sceneInfoText: {
    fontSize: '18px',
    lineHeight: '21px',
    display: 'inline',
  },
  colorBlock: {
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 2,
    width: 10,
    height: 10,
    border: '1px solid rgba(0,0,0, 0.3)',
  },
}))

export default useStyles
