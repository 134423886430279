const disableSaveButton = (days: string, hours: string, minutes: string, seconds: string): boolean => {
  const result = (days === '' && hours === '' && minutes === '' && seconds === '')
  || (days === '0' || days === '00' || days === '000')
  || (hours === '0' || hours === '00' || hours === '000')
  || (minutes === '0' || minutes === '00' || minutes === '000')
  || (seconds === '0' || seconds === '00' || seconds === '000')
  || (days === '' && hours === '' && minutes === '' && seconds === '')
  return !!result
}

export default disableSaveButton
