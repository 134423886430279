import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    position: 'relative',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
    position: 'relative',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  card: {
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '3% 10%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 100,
  },
  blockTitle: {
    color: theme.scenes.textColor,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subTitle: {
    fontSize: '14px',
    marginTop: 10,
  },
  infoIcon: {
    position: 'absolute',
    left: '5%',
    top: '50%',
    marginTop: '-12px',
    cursor: 'pointer',
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
