import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Chip } from '@mui/material'
import classNames from 'classnames'

class Logo extends React.Component {
  getLogo = (classes, logoOnlinePath, logoOfflinePath) => {
    const { online, isMobile } = this.props
    return (online) ? (
      <img
        src={logoOnlinePath}
        width="74"
        height="50"
        alt="Hub is online"
        className={classNames(isMobile ? classes.logoMobile : classes.logo)}
      />
    ) : (
      <img
        src={logoOfflinePath}
        width="74"
        height="50"
        alt="Hub is offline "
        className={classNames(isMobile ? classes.logoMobile : classes.logo)}
      />
    )
  }

  badgeView = (key, ArmStatus) => (
    <Chip
      color="default"
      label={I18n.t(`sphere.unit_status_${key}`)}
      avatar={<Avatar>{ArmStatus}</Avatar>}
    />
  )

  render() {
    const {
      logos,
      classes,
    } = this.props

    return (
      <div className={classes.root}>
        {this.getLogo(classes, logos.onlinePath, logos.offlinePath)}
      </div>
    )
  }
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  online: PropTypes.bool,
  logos: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

Logo.defaultProps = {
  online: true,
}

Logo.displayName = 'Logo'

export default Logo
