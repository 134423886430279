const getConditionDataForBackend = (condition) => {
  const {
    type,
    description,
    conditionDeviceNumber,
    deviceStateInfo,
    stateTimeData,
    timeOperand,
    anotherSceneNumber,
    geofenceNumber,
    usersInfo,
    geofenceExit,
    roomInfo: {
      roomNumber,
      roomValue,
    },
    groupInfo: {
      groupNumber,
      groupValue,
    },
    dataValue,
    dataNumber,
    dataOperand,
    triggeringDevice: {
      deviceType: triggeringDeviceType,
      deviceNumber: triggeringDeviceNumber,
      deviceState: triggeringDeviceState,
    },
    observingDevice: {
      deviceType: observingDeviceType,
      deviceNumber: observingDeviceNumber,
    },
    operand,
    stateDescription,
  } = condition
  switch (type) {
  case 'TwrDevices':
  case 'ZWaveDevices':
    if (operand) {
      deviceStateInfo.operand = operand
    }
    return deviceStateInfo
  case 'UserTrigger':
  case 'Light':
  case 'Sunset':
  case 'Dark':
  case 'Sunrise':
  case 'Weekdays':
  case 'Weekends':
  case 'IsInAlarm':
  case 'Panic':
  case 'Duress':
  case 'IfArmed':
  case 'IfArmedHome':
  case 'IfDisarmed':
  case 'WhenFire':
  case 'WhenMedicalEvent':
  case 'Chime':
  case 'IfMainsHasFailed':
  case 'WhenMainsFail':
  case 'IfMainsPowerIsPresent':
  case 'WhenMainsRestores':
  case 'NotArmed':
  case 'NotDisarmed':
  case 'WhenArmed':
  case 'WhenDisarmed':
  case 'WhenMezzoSetToHomeMode':
  case 'WhileInMedicalAlarm':
  case 'Siren':
    return operand ? { type, operand } : { type }
  case 'AnotherScene':
    return operand
      ? { type, data: { number: anotherSceneNumber }, operand }
      : { type, data: { number: anotherSceneNumber } }
  case 'Time':
    return operand
      ? { type, data: { time: stateTimeData, operand: timeOperand }, operand }
      : { type, data: { time: stateTimeData, operand: timeOperand } }
  case 'TimeLimits':
    return operand
      ? { type, data: stateTimeData, operand }
      : { type, data: stateTimeData }
  case 'DayOfTheWeek':
    return operand
      ? { type, data: { days: stateTimeData }, operand }
      : { type, data: { days: stateTimeData } }
  case 'Date':
    return operand
      ? { type, data: { time: stateTimeData }, operand }
      : { type, data: { time: stateTimeData } }
  case 'BetweenDatesSimple':
    return operand
      ? { type, data: stateTimeData, operand }
      : { type, data: stateTimeData }
  case 'ZwaveColor':
    return operand
      ? { type, data: deviceStateInfo, operand }
      : { type, data: deviceStateInfo }
  case 'Geofencing':
    return operand
      ? { type, data: { users_id: usersInfo.usersNumbers, geofence_id: geofenceNumber, is_exit: geofenceExit }, operand }
      : { type, data: { users_id: usersInfo.usersNumbers, geofence_id: geofenceNumber, is_exit: geofenceExit } }
  case 'RoomActivity':
    return operand
      ? { type, data: { number: roomNumber, value: roomValue }, operand }
      : { type, data: { number: roomNumber, value: roomValue } }
  case 'GroupActivity':
    return operand
      ? { type, data: { number: groupNumber, value: groupValue }, operand }
      : { type, data: { number: groupNumber, value: groupValue } }
  case 'ZwaveSensorMultilevel':
    return operand
      ? { type, data: deviceStateInfo, operand }
      : { type, data: deviceStateInfo }
  case 'HubLight':
    return operand
      ? { type, data: { number: dataValue }, operand }
      : { type, data: { number: dataValue } }
  case 'IfPanicDuressFire':
    return operand
      ? { type, data: { number: dataNumber, value: dataValue }, operand }
      : { type, data: { number: dataNumber, value: dataValue } }
  case 'ZwaveSceneController':
    return operand
      ? { type, data: { number: conditionDeviceNumber, channel: dataNumber, value: dataValue }, operand }
      : { type, data: { number: conditionDeviceNumber, channel: dataNumber, value: dataValue } }
  case 'ZwaveMultichannel':
    return operand
      ? {
        type,
        data: {
          number: conditionDeviceNumber, channel: dataNumber, value: dataValue, operand: dataOperand,
        },
        operand,
      }
      : {
        type,
        data: {
          number: conditionDeviceNumber, channel: dataNumber, value: dataValue, operand: dataOperand,
        },
      }
  case 'VirtualSwitch':
  case 'TimerExpired':
  case 'ZwaveSensorNotification':
    return operand
      ? { type, data: deviceStateInfo, operand }
      : { type, data: deviceStateInfo }
  case 'DeviceTimeout':
    return operand
      ? {
        type: 'DeviceTimeout',
        data: {
          triggering_device: {
            type: triggeringDeviceType,
            number: triggeringDeviceNumber,
            state: triggeringDeviceState,
          },
          observing_device: {
            type: observingDeviceType,
            number: observingDeviceNumber,
          },
          timer_number: description,
        },
        operand,
      }
      : {
        type: 'DeviceTimeout',
        data: {
          triggering_device: {
            type: triggeringDeviceType,
            number: triggeringDeviceNumber,
            state: triggeringDeviceState,
          },
          observing_device: {
            type: observingDeviceType,
            number: observingDeviceNumber,
          },
          timer_number: description,
        },
      }

  case 'SunWithOffset':
    return operand
      ? {
        type,
        data: {
          sun_time: stateDescription === 'sunrise' ? 1 : 0,
          offset_direction: dataNumber > 0 ? 1 : 0,
          minutes: Math.abs(dataNumber),
          operand: 'eq',
        },
        operand,
      }
      : {
        type,
        data: {
          sun_time: stateDescription === 'sunrise' ? 1 : 0,
          offset_direction: dataNumber > 0 ? 1 : 0,
          minutes: Math.abs(dataNumber),
          operand: 'eq',
        },
      }
  default:
    throw new Error('condition unsupported')
  }
}

export default getConditionDataForBackend
