import * as type from '../../constants/scenes/scenesListChannel'

export const openChannel = () => ({
  type: type.OPEN_SCENES_LIST_CHANNEL,
})

export const closeChannel = () => ({
  type: type.CLOSE_SCENES_LIST_CHANNEL,
})

export const syncScenesListChannel = () => ({
  type: type.SYNC_SCENES_LIST_CHANNEL,
})

export const syncScenesListChannelSent = () => ({
  type: type.SYNC_SCENES_LIST_CHANNEL_SENT,
})

export const subscribeScenesListChannel = () => ({
  type: type.SUBSCRIBE_SCENES_LIST_CHANNEL,
})

export const unsubscribeScenesListChannel = () => ({
  type: type.UNSUBSCRIBE_SCENES_LIST_CHANNEL,
})
