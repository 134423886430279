import { createLogic } from 'redux-logic'
import Api from '@app/api/guardPanel'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { SET_ARM_HOME_MODE } from '@app/constants/mySecurity/mySecurity'

const setArmHomeMode = createLogic({
  type: SET_ARM_HOME_MODE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { users: { currentUser: { user: { ids: { sphere: sphereId } } } } } = store.getState()

    next({
      ...action,
      accessToken,
      sphereId,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_ARM_HOME_MODE))
    try {
      await Api.patchGuardPanelStatus(action.accessToken, action.sphereId, { arm_status: 'home' })
      dispatch(apiRequestDone(SET_ARM_HOME_MODE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('setArmHomeMode failed without error')
      }
    } finally {
      done()
    }
  },
})

export default setArmHomeMode
