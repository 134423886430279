export const mainColors = [
  'rgb(255, 0, 0)',
  'rgb(255, 255, 4)',
  'rgb(255, 128, 1)',
  'rgb(0, 0, 255)',
  'rgb(255, 0, 127)',
  'rgb(19, 179, 23)',
  'rgb(127, 0, 255)',
  'rgb(255, 255, 255)',
]

export const bigCircleColors = [
  'rgb(22, 95, 218)',
  'rgb(3, 145, 226)',
  'rgb(6, 188, 237)',
  'rgb(19, 228, 197)',
  'rgb(2, 195, 169)',
  'rgb(1, 183, 33)',
  'rgb(0, 136, 20)',
  'rgb(0, 0, 0)',
  'rgb(255, 255, 255)',
  'rgb(248, 227, 1)',
  'rgb(255, 100, 0)',
  'rgb(226, 0, 0)',
  'rgb(172, 0, 14)',
  'rgb(158, 1, 95)',
  'rgb(109, 14, 131)',
  'rgb(59, 56, 135)',
]

export const smallCircleColors = [
  'rgb(255, 0, 0)',
  'rgb(230, 0, 1)',
  'rgb(204, 1, 0)',
  'rgb(179, 1, 0)',
  'rgb(153, 0, 0)',
  'rgb(128, 0, 1)',
  'rgb(102, 0, 0)',
  'rgb(77, 0, 0)',
  'rgb(255, 204, 204)',
  'rgb(255, 179, 179)',
  'rgb(255, 153, 153)',
  'rgb(255, 128, 128)',
  'rgb(255, 102, 102)',
  'rgb(255, 77, 77)',
  'rgb(255, 50, 51)',
  'rgb(255, 27, 26)',
]

export const MIN_BRIGHTNESS = 1
