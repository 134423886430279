import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_AVATAR } from '@app/constants/users/avatar'
import { cleanAvatarFile, closePopup } from '@app/actions/users/avatar'
import { sync } from '@app/actions/users/currentUser'
import Api from '@app/api/users/updateUserAvatar'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitChangedAvatar = createLogic({
  type: SET_AVATAR,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { users: { avatar: { avatarData } } } = getState()
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      avatarData,
      token,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_AVATAR))
    Api.updateUserAvatar(action.token, action.avatarData)
      .then(() => {
        dispatch(apiRequestDone(SET_AVATAR))
        dispatch(closePopup())
        dispatch(cleanAvatarFile())
        dispatch(sync())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('updateUserAvatar failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitChangedAvatar
