import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/virtual_switches/trigger`

const triggerVirtualSwitch = (access_token, data) => Axios.put(ENDPOINT, data, {
  headers: { 'Access-Token': access_token },
})

export default { triggerVirtualSwitch }
