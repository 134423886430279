const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '5px 0 25px 0',
  },
  buttonsContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stateButton: {
    display: 'flex',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.myStatus.subtitle,
  },
  iconEnter: {
    width: 20,
    height: 20,
    marginLeft: -10,
    marginRight: 10,
  },
  iconExit: {
    width: 20,
    height: 20,
    marginLeft: 10,
    marginRight: -10,
  },
  active: {
    color: theme.palette.primary.main,
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  address: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
