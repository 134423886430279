import { ColorTypes } from '@app/lib/Scenes/colorActions'
import { brightnessValueStrings } from '@app/lib/Scenes/mezzoBrightnessValues'

const getValue = (value: string): string => {
  switch (value) {
  case 'turn on':
  case 'turn_on': {
    return 'turn on'
  }
  case 'turn off':
  case 'turn_off': {
    return 'turn off'
  }
  case 'turn on previous':
  case 'turn_on_previous': {
    return 'turn on previous'
  }
  default: {
    return value
  }
  }
}

const getColorBeforeString = (colorType: string, stateDescription: string): string => {
  if (colorType === ColorTypes.whiteWarm) {
    return `set white warm on at ${stateDescription}%`
  } if (colorType === ColorTypes.whiteCold) {
    return `set white cold on at ${stateDescription}%`
  }
  return 'set colour to'
}
const getColorAfterString = (colorTypeDurationValueAfter: string, durationValueAfter: string): string => {
  if (colorTypeDurationValueAfter === ColorTypes.whiteWarm) {
    return `then set white warm on at ${durationValueAfter}%`
  } if (colorTypeDurationValueAfter === ColorTypes.whiteCold) {
    return `then set white cold on at ${durationValueAfter}%`
  } if (colorTypeDurationValueAfter === ColorTypes.rgb || colorTypeDurationValueAfter === ColorTypes.swatch) {
    return 'then set colour to'
  }
  return ''
}
// eslint-disable-next-line max-len
const getActionStateDescription = (type: string, stateDescription: string, colorType: string, delayDescription: string, durationDescription: string, durationValueAfter: string, colorTypeDurationValueAfter: string, subDeviceName: string): string | Array<string> => {
  let colorBefore = ''
  let colorAfter = ''
  let delayPart = ''
  let durationPart = ''
  let durationValueAfterResult = ''
  switch (type) {
  case 'Toggle':
    return stateDescription
  case 'ToggleZwaveDeviceWithDuration':
    return `${stateDescription} for ${durationDescription} then ${stateDescription}`
  case 'SetDeviceStatus':
    if (durationDescription) {
      return `${getValue(stateDescription)} for ${durationDescription} then ${getValue(durationValueAfter)}`
    }
    return `${getValue(stateDescription)}`
  case 'DelayedDeviceAction':
    return `${getValue(stateDescription)} after ${delayDescription}`
  case 'DelayedPlusDurationDeviceAction':
    return `${getValue(stateDescription)} for ${durationDescription} after ${delayDescription} then ${getValue(durationValueAfter)}`
  case 'ControlRoom':
    return getValue(stateDescription)
  case 'ControlGroup':
    return getValue(stateDescription)
  case 'Light':
    return stateDescription
  case 'SetZwaveColor':
    return getColorBeforeString(colorType, stateDescription)
  case 'ZwaveColorDelayDuration':
    colorBefore = getColorBeforeString(colorType, stateDescription)
    durationPart = durationDescription && `for ${durationDescription}`
    delayPart = delayDescription && `after ${delayDescription}`
    colorAfter = getColorAfterString(colorTypeDurationValueAfter, durationValueAfter)
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'ZwaveColorSetWithDuration':
    colorBefore = getColorBeforeString(colorType, stateDescription)
    durationPart = durationDescription && `for ${durationDescription}`
    colorAfter = getColorAfterString(colorTypeDurationValueAfter, durationValueAfter)
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'LightMezzo':
    colorBefore = brightnessValueStrings[stateDescription]
    durationPart = durationDescription && `for ${durationDescription}`
    colorAfter = durationDescription && `then ${brightnessValueStrings[durationValueAfter]}`
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'MezzoTopLightColor':
    if (stateDescription === 'none') {
      return 'top RGB light turn off'
    }
    colorBefore = 'top RGB light set to'
    durationPart = durationDescription && `for ${durationDescription}`
    colorAfter = durationDescription && 'then turn off'
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'MezzoBottomLightColor':
    if (stateDescription === 'none') {
      return 'bottom RGB light turn off'
    }
    colorBefore = 'bottom RGB light set to'
    durationPart = durationDescription && `for ${durationDescription}`
    colorAfter = durationDescription && 'then turn off'
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'PlayAudio':
    return I18n.t(`scene_action_web.${stateDescription}`).toLowerCase()
  case 'ArmTheSystem':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'ArmInHomeMode':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'Disarm':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'ToggleVoice':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'MuteOrUnmuteVoice':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'AlarmPanicDuress':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'RoomColorControl':
    return getColorBeforeString(colorType, stateDescription)
  case 'RoomColorDelayDuration':
    colorBefore = getColorBeforeString(colorType, stateDescription)
    durationPart = durationDescription && `for ${durationDescription}`
    delayPart = `after ${delayDescription}`
    colorAfter = durationDescription && getColorAfterString(colorTypeDurationValueAfter, durationValueAfter)
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'RoomColorWithDuration':
    colorBefore = getColorBeforeString(colorType, stateDescription)
    durationPart = durationDescription && `for ${durationDescription}`
    colorAfter = getColorAfterString(colorTypeDurationValueAfter, durationValueAfter)
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'GroupColorControl':
    return getColorBeforeString(colorType, stateDescription)
  case 'GroupColorDelayDuration':
    colorBefore = getColorBeforeString(colorType, stateDescription)
    durationPart = durationDescription && `for ${durationDescription}`
    delayPart = `after ${delayDescription}`
    colorAfter = durationDescription && getColorAfterString(colorTypeDurationValueAfter, durationValueAfter)
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'GroupColorWithDuration':
    colorBefore = getColorBeforeString(colorType, stateDescription)
    durationPart = durationDescription && `for ${durationDescription}`
    colorAfter = getColorAfterString(colorTypeDurationValueAfter, durationValueAfter)
    return [colorBefore, durationPart, delayPart, colorAfter]
  case 'StartTimer':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'StopTimer':
    return I18n.t(`scene_action_web.${stateDescription}`)
  case 'PushNotifications':
    return `send Push Notification to ${stateDescription}`
  case 'Email':
    return `send Email to ${stateDescription}`
  case 'WebRequest':
    return 'execute Web request'
  case 'VirtualSwitch':
    switch (stateDescription) {
    case 'turn_on':
      return 'turn on'
    case 'turn_off':
      return 'turn off'
    case 'toggle':
      return 'toggle'
    default:
      throw new Error('state unsupported')
    }
  case 'VirtualSwitchDelayDuration':
    delayPart = delayDescription ? ` after ${delayDescription}` : ''
    durationPart = durationDescription ? ` for ${durationDescription}` : ''
    durationValueAfterResult = durationValueAfter !== 'none' ? ` then ${getValue(durationValueAfter)}` : ''
    return `${getValue(stateDescription)}${durationPart}${delayPart}${durationValueAfterResult}`
  case 'ZwaveMultichannelControl':
    if (durationDescription) {
      return `${subDeviceName} ${getValue(stateDescription)} for ${durationDescription} then ${getValue(durationValueAfter)}`
    }
    return `${subDeviceName} ${getValue(stateDescription)}`
  default:
    throw new Error('action unsupported')
  }
}

export default getActionStateDescription
