export const conditionTriggeringDeviceTypes = [
  {
    title: 'zwave_device',
    description: 'control_zwave_devices',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'ZWaveDevices',
  },
  {
    title: 'room',
    description: 'room_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'RoomActivity',
  },
  {
    title: 'group',
    description: 'group_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'GroupActivity',
  },
]
export const conditionTriggeringDeviceTypesNCentral = [
  {
    title: 'room',
    description: 'room_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'RoomActivity',
  },
  {
    title: 'group',
    description: 'group_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'GroupActivity',
  },
]
export const conditionObservingDeviceTypes = [
  {
    title: 'zwave_device',
    description: 'control_zwave_devices',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'ZWaveDevices',
  },
  {
    title: 'twr_device',
    description: 'control_twr_device',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'TwrDevices',
  },
  {
    title: 'room',
    description: 'room_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'RoomActivity',
  },
  {
    title: 'group',
    description: 'group_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'GroupActivity',
  },
]
export const conditionObservingDeviceTypesNCentral = [
  {
    title: 'twr_device',
    description: 'control_twr_device',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'TwrDevices',
  },
  {
    title: 'room',
    description: 'room_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'RoomActivity',
  },
  {
    title: 'group',
    description: 'group_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'GroupActivity',
  },
]
