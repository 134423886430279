import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { ADD_NEW_ZWAVE_DEVICE } from '@app/constants/myDevices/zwaveDevices'
import { syncZwaveChannel } from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import { setNewZwaveDevice } from '@app/actions/myDevices/zwaveDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/zwaveDevices'

const postNewZwaveDeviceLogic = createLogic({
  type: ADD_NEW_ZWAVE_DEVICE,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(ADD_NEW_ZWAVE_DEVICE))
    try {
      const newDevice = await Api.postNewZwaveDevice(action.accessToken)
      dispatch(setNewZwaveDevice(newDevice.data.zwave_device))
      dispatch(apiRequestDone(ADD_NEW_ZWAVE_DEVICE))
      dispatch(syncZwaveChannel(newDevice.data.zwave_device))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('ADD_NEW_ZWAVE_DEVICE failed without error')
      }
    }
    done()
  },
})

export default postNewZwaveDeviceLogic
