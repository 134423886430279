import * as type from '@app/constants/myDevices/twrDevices'
import { IDeviceInfoChannelData } from '@app/@types/myDevices'

export const fetchTwrDeviceInfo = (id: number) => ({
  type: type.FETCH_TWR_DEVICE_INFO,
  id,
} as const)

export const updateTwrDeviceInfo = (data: IDeviceInfoChannelData) => ({
  type: type.UPDATE_TWR_DEVICE_INFO,
  data,
} as const)

export const startFetchingDeviceStats = () => ({
  type: type.START_FETCH_DEVICE_STATS,
} as const)

export const endFetchingDeviceStats = () => ({
  type: type.END_FETCH_DEVICE_STATS,
} as const)
