import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_ZWAVE_DEVICE_PARAMS } from '@app/constants/myDevices/zwaveDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/zwaveDevices'

const getZwaveDeviceParametersLogic = createLogic({
  type: GET_ZWAVE_DEVICE_PARAMS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_ZWAVE_DEVICE_PARAMS))
    try {
      await Api.getZwaveDeviceParameters(action.accessToken, action.id, action.parametersNumbers)
      dispatch(apiRequestDone(GET_ZWAVE_DEVICE_PARAMS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('GET_ZWAVE_DEVICE_PARAMS failed without error')
      }
    }
    done()
  },
})

export default getZwaveDeviceParametersLogic
