const styles = () => ({
  root: {
    padding: 0,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 60,
    '&::after': {
      content: '',
      flex: 'auto',
    },
  },
  listItem: {
    padding: 0,
    margin: '10px 10px',
    width: 'auto',
  },
})

export default styles
