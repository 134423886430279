import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveConditionDescription, saveConditionGroupNumber } from '@app/actions/scenes/currentSceneCreating'
import { syncGroupsScenes } from '@app/actions/scenes/groupsScenes/groupsScenes'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGroupCondition
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/GroupCondition/SelectGroupCondition'
import SelectGroupConditionIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/GroupCondition/SelectGroupCondition'
import {
  withAccessDenied, withNotifications, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectGroupConditionIOS) : withStyles(SelectGroupCondition)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { groupsScenes } = state.scenes.groupsScenes
  return {
    isMobile,
    groupsScenes,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncGroupsScenes,
  tryAgain: syncGroupsScenes,
  notifications: syncNotifications.sync,
  saveConditionDescription,
  saveConditionGroupNumber,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGroupConditionEnhanced = enhance

export default withRouter(SelectGroupConditionEnhanced)
