import React from 'react'
import {
  Typography, Paper,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { withStyles } from '@app/components/styles'
import NavBar from '@app/components/Common/NavBar/NavBar'
import { ROOMS } from '@app/constants/routes'

class ShowRoom extends React.PureComponent {
  onBackClick=() => {
    const { navigate } = this.props
    navigate(ROOMS)
  }

  render() {
    const {
      classes,
      isMobile,
      location,
    } = this.props
    const { name, twr_devices: twrDevices, zwave_devices: zWaveDevices } = location.state.room
    return (
      <Paper className={classes.root}>
        <NavBar
          title={name}
          customTitle
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classNames(classes.main, isMobile && classes.mainMobile)}>
          <Wrapper>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.twr_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {twrDevices.length
                ? twrDevices.map((device) => (
                  <Wrapper
                    className={classNames(
                      classes.devicesCard,
                      isMobile && classes.cardMobile,
                    )}
                    key={`${device.name + 1}`}
                  >
                    {device.name}
                  </Wrapper>
                ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.zWaveDevicesContainer}>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.zwave_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {zWaveDevices.length
                ? zWaveDevices.map((device) => (
                  <Wrapper
                    className={classNames(
                      classes.devicesCard,
                      isMobile && classes.cardMobile,
                    )}
                    key={`${device.name + 1}`}
                  >
                    {device.name}
                  </Wrapper>
                ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

ShowRoom.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      room: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        twr_devices: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })).isRequired,
        zwave_devices: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })).isRequired,
      }),
    }),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
}

ShowRoom.displayName = 'ShowRoom'

export default withStyles(ShowRoom)
