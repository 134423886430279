import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem 2rem 160px 2rem',
    minHeight: 710,
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem .5rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  goBackIcon: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
}))

export default useStyles
