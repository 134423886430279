import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  name: PropTypes.string,
  start_on: PropTypes.string,
  schedule_type: PropTypes.string,
  days_interval: PropTypes.number,
  days_of_weeks: PropTypes.string,
  finish_after_days: PropTypes.number,
  pills_dosages: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.string,
    time: PropTypes.string,
  })),
})

export default propTypes
