import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getMultilevelSensorStateDescription from '@app/helpers/scenes/getMultilevelSensorStateDescription'
import { multilevelSensorSelected, multilevelSensorValueSelected } from '@app/lib/Scenes/conditions'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_MULTILEVEL_SENSOR_TYPE,
  SELECT_MULTILEVEL_SENSOR_VALUE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMultilevelSensorStateSelected extends React.Component {
  componentDidMount() {
    const {
      deactivateChangeSensorMode,
      deactivateChangeSensorStateMode,
    } = this.props
    deactivateChangeSensorMode()
    deactivateChangeSensorStateMode()
  }

  selectSensorType=() => {
    const {
      navigate,
      activateChangeSensorMode,
    } = this.props
    activateChangeSensorMode()
    navigate(SELECT_MULTILEVEL_SENSOR_TYPE)
  }

  selectSensorValue=() => {
    const {
      navigate,
      activateChangeSensorStateMode,
    } = this.props
    activateChangeSensorStateMode()
    navigate(SELECT_MULTILEVEL_SENSOR_VALUE)
  }

  onBackClick =() => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
      restoreConditionInfo,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
        restoreConditionInfo()
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    }
  }

  addStatePressed=() => {
    const {
      navigate,
      saveConditionStateDescription,
      sensorType,
      sensorValue,
      isEditConditionMode,
    } = this.props
    saveConditionStateDescription(getMultilevelSensorStateDescription(sensorType, sensorValue))
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      sensorType,
      sensorValue,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <CardBlock
            key={I18n.t(`scene_web.${multilevelSensorSelected.title}`)}
            title={I18n.t(`scene_web.${multilevelSensorSelected.title}`)}
            description={sensorType}
            position={multilevelSensorSelected.position}
            buttonName={I18n.t(`scene_web.${multilevelSensorSelected.buttonName}`)}
            isCardDisabled={multilevelSensorSelected.isCardDisabled}
            isButtonDisabled={multilevelSensorSelected.isButtonDisabled}
            onClick={() => {
              this.selectSensorType()
            }}
          />
          <CardBlock
            key={I18n.t(`scene_web.${multilevelSensorValueSelected.title}`)}
            title={I18n.t(`scene_web.${multilevelSensorValueSelected.title}`)}
            description={sensorValue}
            position={multilevelSensorValueSelected.position}
            buttonName={I18n.t(`scene_web.${multilevelSensorValueSelected.buttonName}`)}
            isCardDisabled={multilevelSensorValueSelected.isCardDisabled}
            isButtonDisabled={multilevelSensorValueSelected.isButtonDisabled}
            onClick={() => {
              this.selectSensorValue()
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.addStatePressed()
            }}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultilevelSensorStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sensorType: PropTypes.string.isRequired,
  sensorValue: PropTypes.string.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  activateChangeSensorMode: PropTypes.func.isRequired,
  activateChangeSensorStateMode: PropTypes.func.isRequired,
  deactivateChangeSensorMode: PropTypes.func.isRequired,
  deactivateChangeSensorStateMode: PropTypes.func.isRequired,
  restoreConditionInfo: PropTypes.func.isRequired,
}

SelectMultilevelSensorStateSelected.displayName = 'SelectMultilevelSensorStateSelected'

export default SelectMultilevelSensorStateSelected
