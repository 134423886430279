import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Typography, Paper, Tab, Tabs, IconButton,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import classNames from 'classnames'
import { IZwaveDetails, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { ScenesArrowBack, SettingsIcon, ReloadCircle } from 'icons'
import ZwaveDetails
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveDetails/ZwaveDetails'
import ZwaveParams
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/ZwaveParams'
import {
  fetchZwaveDeviceDetails,
  resetZwaveDeviceDetails,
  resetZwaveDeviceDetailsError,
  getZwaveDeviceParameters, setIsSleepingDevicePopupOpen,
} from '@app/actions/myDevices/zwaveDevices'
import { useDispatch } from '@app/store'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import useStyles from './ZwaveDetailsAndParams.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceDetails: IZwaveDetails,
  error: string,
  configurationPopup: {
    number: number,
    value: number,
    size: number,
    status: string,
  },
  sleepingDevicePopup: boolean,
  isMobile: boolean,
}

const ZwaveDetailsAndParams: React.FC<Props> = (props) => {
  const {
    currentDevice, isMobile, currentDeviceDetails, error, configurationPopup, sleepingDevicePopup,
  } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [isConfigurationPopupOpen, setIsConfigurationPopupOpen] = useState<boolean>(false)
  const [isParametersFetching, setIsParametersFetching] = useState<boolean>(false)
  const [isSleepingPopupOpen, setIsSleepingPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchZwaveDeviceDetails(currentDevice.id))
    dispatch(resetZwaveDeviceDetails())
    dispatch(resetZwaveDeviceDetailsError())
    return () => {
      dispatch(resetZwaveDeviceDetails())
      dispatch(resetZwaveDeviceDetailsError())
    }
  }, [])

  useEffect(() => {
    if (isParametersFetching) {
      setIsParametersFetching(false)
    }
  }, [currentDeviceDetails])

  useEffect(() => {
    setIsSleepingPopupOpen(sleepingDevicePopup)
  }, [sleepingDevicePopup])

  const getParametersData = () => {
    const parametersNumbers = currentDeviceDetails.config_params.map((parameter) => parameter.number)
    dispatch(getZwaveDeviceParameters(currentDevice.id, parametersNumbers))
    setIsParametersFetching(true)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const goBackHandler = () => navigate(-1)

  const openParamsConfiguration = () => {
    setIsConfigurationPopupOpen(!isConfigurationPopupOpen)
  }

  return (
    <Paper>
      <Wrapper className={classNames(classes.navigation, isMobile && classes.navigationMobile)}>
        <Wrapper className={classes.navigationLeft}>
          <IconButton
            size="small"
            onClick={goBackHandler}
            className={classNames(classes.goBackIcon, isMobile && classes.goBackIconMobile)}
          >
            <ScenesArrowBack />
          </IconButton>
          <Typography>{`${currentDevice.name} details`}</Typography>
        </Wrapper>
        {currentTab === 1 && (
          <Wrapper className={classes.navigationRight}>
            <IconButton
              className={classes.reload}
              aria-label="getParams"
              onClick={getParametersData}
              size="small"
              disabled={!currentDeviceDetails || isParametersFetching}
            >
              <ReloadCircle />
            </IconButton>
            <IconButton
              className={classes.setter}
              aria-label="ParamsConfiguration"
              onClick={openParamsConfiguration}
              size="small"
            >
              <SettingsIcon />
            </IconButton>
          </Wrapper>
        )}
      </Wrapper>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab label="DEVICE INFO" />
        <Tab label="PARAMETERS" />
      </Tabs>
      {currentTab === 0 && (
        <ZwaveDetails
          currentDevice={currentDevice}
          currentDeviceDetails={currentDeviceDetails}
          error={error}
          isMobile={isMobile}
        />
      )}
      {currentTab === 1 && (
        <ZwaveParams
          currentDevice={currentDevice}
          currentDeviceDetails={currentDeviceDetails}
          error={error}
          configurationPopup={configurationPopup}
          isMobile={isMobile}
          setIsConfigurationPopupOpen={setIsConfigurationPopupOpen}
          isConfigurationPopupOpen={isConfigurationPopupOpen}
        />
      )}
      {isSleepingPopupOpen && (
        <SmallErrorPopup
          open
          warningText="my_devices.sleeping_mode"
          buttonName="buttons.try_again"
          title="titles.warning"
          onClick={() => {
            dispatch(resetZwaveDeviceDetailsError())
            dispatch(fetchZwaveDeviceDetails(currentDevice.id))
            dispatch(setIsSleepingDevicePopupOpen(false))
          }}
        />
      )}
    </Paper>
  )
}

export default ZwaveDetailsAndParams
