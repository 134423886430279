import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_USERS_REQUESTS } from '@app/constants/users/usersRequests'
import { update } from '@app/actions/users/usersRequests'
import Api from '@app/api/users/fetchUsersRequests'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchUsersRequestsLogic = createLogic({
  type: SYNC_USERS_REQUESTS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_USERS_REQUESTS))
    Api.fetchUsersRequests(token)
      .then((response) => {
        const requestsList = response.data.user_requests
        dispatch(update(requestsList))
        dispatch(apiRequestDone(SYNC_USERS_REQUESTS))
      })
      .catch((error) => {
        if (error) {
          if (error.response.data.error.code === 340) {
            dispatch(apiRequestFailed(I18n.t(`my_users.${error.response.data.error.message}`)))
          } else {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          }
        } else {
          throw new Error('fetchUsersRequests failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchUsersRequestsLogic
