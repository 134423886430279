import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg  viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.0216 81.4766C24.5836 81.4766 21.3516 82.8166 18.9204 85.2478C16.4892 87.6775 15.1506 90.9096 15.1506 94.3475C15.1506 97.7841 16.4892 101.018 18.9204 103.449C21.3516 105.88 24.5836 107.218 28.0216 107.218C31.4596 107.218 34.6916 105.88 37.1242 103.449C42.1411 98.4291 42.1411 90.2646 37.1242 85.2463C34.6916 82.8166 31.4596 81.4766 28.0216 81.4766ZM34.0895 100.415C32.4678 102.036 30.3126 102.928 28.0216 102.928C25.7306 102.928 23.5754 102.036 21.9537 100.415C20.3333 98.7937 19.441 96.6386 19.441 94.3475C19.441 92.0565 20.3333 89.9013 21.9537 88.281C23.5754 86.6593 25.7291 85.7669 28.0216 85.7669C30.3141 85.7669 32.4678 86.6593 34.0895 88.281C37.4346 91.626 37.4346 97.069 34.0895 100.415Z" fill="currentColor"/>
      <path d="M25.2398 98.4112C26.2271 98.4112 27.0274 97.6109 27.0274 96.6236C27.0274 95.6363 26.2271 94.8359 25.2398 94.8359C24.2525 94.8359 23.4521 95.6363 23.4521 96.6236C23.4521 97.6109 24.2525 98.4112 25.2398 98.4112Z" fill="currentColor"/>
      <path d="M30.2969 93.3565C31.2842 93.3565 32.0845 92.5562 32.0845 91.5689C32.0845 90.5816 31.2842 89.7812 30.2969 89.7812C29.3096 89.7812 28.5093 90.5816 28.5093 91.5689C28.5093 92.5562 29.3096 93.3565 30.2969 93.3565Z" fill="currentColor"/>
      <path d="M56.6237 81.4766C53.1857 81.4766 49.9536 82.8166 47.5239 85.2478C45.0927 87.6775 43.7527 90.9096 43.7527 94.3475C43.7527 97.7841 45.0927 101.018 47.5239 103.449C49.9551 105.88 53.1857 107.218 56.6237 107.218C60.0616 107.218 63.2937 105.88 65.7263 103.449C70.7431 98.4291 70.7431 90.2646 65.7263 85.2463C63.2937 82.8166 60.0616 81.4766 56.6237 81.4766ZM62.6916 100.415C61.0699 102.036 58.9147 102.928 56.6237 102.928C54.3326 102.928 52.1774 102.036 50.5571 100.415C48.9368 98.7937 48.043 96.6386 48.043 94.3475C48.043 92.0565 48.9354 89.9013 50.5571 88.281C52.1774 86.6593 54.3326 85.7669 56.6237 85.7669C58.9161 85.7669 61.0699 86.6593 62.6916 88.281C66.0366 91.626 66.0366 97.069 62.6916 100.415Z" fill="currentColor"/>
      <path d="M53.8433 98.4112C54.8306 98.4112 55.6309 97.6109 55.6309 96.6236C55.6309 95.6363 54.8306 94.8359 53.8433 94.8359C52.856 94.8359 52.0557 95.6363 52.0557 96.6236C52.0557 97.6109 52.856 98.4112 53.8433 98.4112Z" fill="currentColor"/>
      <path d="M58.899 93.3565C59.8862 93.3565 60.6866 92.5562 60.6866 91.5689C60.6866 90.5816 59.8862 89.7812 58.899 89.7812C57.9117 89.7812 57.1113 90.5816 57.1113 91.5689C57.1113 92.5562 57.9117 93.3565 58.899 93.3565Z" fill="currentColor"/>
      <path d="M85.2257 81.4766C81.7892 81.4766 78.5557 82.8166 76.1259 85.2478C73.6947 87.6775 72.3547 90.9096 72.3547 94.3475C72.3547 97.7841 73.6947 101.018 76.1259 103.449C78.5557 105.88 81.7892 107.218 85.2257 107.218C88.6637 107.218 91.8957 105.88 94.3269 103.449C99.3452 98.4291 99.3452 90.2646 94.3269 85.2463C91.8957 82.8166 88.6637 81.4766 85.2257 81.4766ZM91.2936 100.415C89.6719 102.036 87.5167 102.928 85.2257 102.928C82.9347 102.928 80.7795 102.036 79.1592 100.415C77.5374 98.7937 76.6451 96.6386 76.6451 94.3475C76.6451 92.0565 77.5374 89.9013 79.1592 88.281C80.7809 86.6593 82.9347 85.7669 85.2257 85.7669C87.5167 85.7669 89.6719 86.6593 91.2936 88.281C94.6387 91.626 94.6387 97.069 91.2936 100.415Z" fill="currentColor"/>
      <path d="M82.4456 98.4112C83.4329 98.4112 84.2332 97.6109 84.2332 96.6236C84.2332 95.6363 83.4329 94.8359 82.4456 94.8359C81.4583 94.8359 80.658 95.6363 80.658 96.6236C80.658 97.6109 81.4583 98.4112 82.4456 98.4112Z" fill="currentColor"/>
      <path d="M87.5013 93.3565C88.4885 93.3565 89.2889 92.5562 89.2889 91.5689C89.2889 90.5816 88.4885 89.7812 87.5013 89.7812C86.514 89.7812 85.7136 90.5816 85.7136 91.5689C85.7136 92.5562 86.514 93.3565 87.5013 93.3565Z" fill="currentColor"/>
      <path d="M128.129 45.7258H62.1496C61.1242 39.6478 55.8371 35 49.4731 35C48.2718 35 46.161 35.5034 44.6379 38.5753H35.172C33.9865 38.5753 33.0269 39.5349 33.0269 40.7204C33.0269 41.906 33.9865 42.8656 35.172 42.8656H43.3279C43.0491 44.4144 42.8846 46.1034 42.8846 47.871C42.8846 49.5199 43.0248 51.2432 43.3208 52.8763H35.172C33.9865 52.8763 33.0269 53.8359 33.0269 55.0215C33.0269 56.2071 33.9865 57.1667 35.172 57.1667H44.6537C45.6991 59.2947 47.2579 60.7419 49.4731 60.7419C55.8371 60.7419 61.1242 56.0941 62.1496 50.0161H128.129C132.861 50.0161 136.71 53.8645 136.71 58.5968V80.0484C136.71 86.0177 132.381 90.9844 126.699 92.0027V80.7634C126.699 77.2153 123.812 74.328 120.263 74.328H14.4355C10.8874 74.328 8 77.2153 8 80.7634V107.935C8 111.484 10.8874 114.371 14.4355 114.371H120.263C123.812 114.371 126.699 111.484 126.699 107.935V96.3387C134.755 95.2819 141 88.3873 141 80.0484V58.5968C141 51.5006 135.227 45.7258 128.129 45.7258ZM49.4731 56.4516C48.8067 56.4516 47.1764 53.4441 47.1764 47.871C47.1764 42.2978 48.8067 39.2903 49.4731 39.2903C54.2053 39.2903 58.0538 43.1387 58.0538 47.871C58.0538 52.6032 54.2053 56.4516 49.4731 56.4516ZM122.409 107.935C122.409 109.118 121.446 110.081 120.263 110.081H14.4355C13.2528 110.081 12.2903 109.118 12.2903 107.935V80.7634C12.2903 79.5807 13.2528 78.6183 14.4355 78.6183H120.263C121.446 78.6183 122.409 79.5807 122.409 80.7634V107.935Z" fill="currentColor"/>
      <path d="M113.113 83.2656H107.393C103.844 83.2656 100.957 86.153 100.957 89.7011V98.9968C100.957 102.545 103.844 105.432 107.393 105.432H113.113C116.661 105.432 119.548 102.545 119.548 98.9968V89.7011C119.548 86.153 116.661 83.2656 113.113 83.2656ZM107.393 87.5559H113.113C114.296 87.5559 115.258 88.5184 115.258 89.7011V90.4162H105.247V89.7011C105.247 88.5184 106.21 87.5559 107.393 87.5559ZM113.113 101.142H107.393C106.21 101.142 105.247 100.18 105.247 98.9968V94.7065H115.258V98.9968C115.258 100.18 114.296 101.142 113.113 101.142Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PowerStrip')

export default icon
