import React from 'react'
import { Typography, IconButton } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ZwUnknownDeviceIcon, SortIcon } from 'icons'
import { IParentDevice } from '@app/@types/myDevices'
import useStyles from './SubDeviceCard.style'

interface Props {
  isDragging: boolean,
  subDeviceName: string,
  parentDevice?: IParentDevice | null,
  isMobile: boolean,
}

const SubDeviceCard: React.FC<Props> = (props) => {
  const {
    isDragging, subDeviceName, parentDevice, isMobile,
  } = props
  const classes = useStyles()

  return (
    <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile, isDragging && classes.dragging)}>
      <Wrapper className={classNames(classes.cardInfo, isMobile && classes.cardInfoMobile)}>
        <Typography className={classNames(classes.parentName, isDragging && classes.parentNameMobile)}>
          {parentDevice?.name}
        </Typography>
        <Wrapper className={classes.cardIconContainer}>
          <ZwUnknownDeviceIcon />
        </Wrapper>
        <Wrapper className={isMobile ? classes.nameAndStatus : null}>
          <Typography className={classes.cardName}>
            {subDeviceName}
          </Typography>
        </Wrapper>
        <IconButton
          size="small"
          className={classes.sortIcon}
        >
          {isDragging && <SortIcon />}
        </IconButton>
      </Wrapper>
    </Wrapper>
  )
}

SubDeviceCard.defaultProps = {
  parentDevice: null,
}

export default SubDeviceCard
