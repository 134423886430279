import {
  DEVICES_CHANNEL,
  DEVICES_STATUS_CHANNEL,
  MEZZO_LIGHTS_CHANNEL,
  DELETE_TWR_DEVICE_CHANNEL,
  DELETE_ZWAVE_DEVICE_CHANNEL,
} from '@app/constants/channels'
import { closeDevicesChannel, openDevicesChannel } from '@app/actions/myDevices/channels/devices/devicesChannel'
import { closeDevicesStatusChannel, openDevicesStatusChannel } from '@app/actions/myDevices/channels/devices/devicesStatusChannel'
import { closeMezzoLightsChannel, openMezzoLightsChannel } from '@app/actions/myDevices/channels/devices/mezzoLightsChannel'
import { closeDeleteChannel, openDeleteChannel } from '@app/actions/myDevices/channels/devices/deleteChannel'
import openCable from '@app/api/cable'
import { DeviceTypes } from '@app/@types/myDevices'

const devicesChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(DEVICES_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create(DEVICES_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(openDevicesChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeDevicesChannel())
      },
    })
  }
  return subscription
}

const devicesStatusChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(DEVICES_STATUS_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create(DEVICES_STATUS_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(openDevicesStatusChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeDevicesStatusChannel())
      },
    })
  }
  return subscription
}

const deleteDeviceChannel = ({ request, response }, id, deviceType) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(DELETE_ZWAVE_DEVICE_CHANNEL),
  )
    || cable.subscriptions.consumer.subscriptions.subscriptions.find(
      (sub) => sub.identifier.includes(DELETE_TWR_DEVICE_CHANNEL),
    )
  if (!subscription) {
    return cable.subscriptions.create({
      channel: deviceType === DeviceTypes.twrDevice ? DELETE_TWR_DEVICE_CHANNEL : DELETE_ZWAVE_DEVICE_CHANNEL,
      id,
    }, {
      connected() {
        request(this)
        store.dispatch(openDeleteChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeDeleteChannel())
      },
    })
  }
  return subscription
}

const mezzoLightsChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(MEZZO_LIGHTS_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create(MEZZO_LIGHTS_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(openMezzoLightsChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeMezzoLightsChannel())
      },
    })
  }
  return subscription
}

export default {
  devicesChannel,
  devicesStatusChannel,
  deleteDeviceChannel,
  mezzoLightsChannel,
}
