import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { syncTimers } from '@app/actions/timers/timers'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withRefresh, withProgressAndErrorMessage,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import TimersInScenesReset from '@app/components/MyAutomation/Tabs/ScenesScreens/TimersInScenes/TimersInScenesReset/TimersInScenesReset'
import TimersInScenesResetIOS from '@app/components/MyAutomation/Tabs/iOS/TimersInScenes/TimersInScenesReset/TimersInScenesReset'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = isIOS ? TimersInScenesResetIOS : TimersInScenesReset

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { timers } = state.timers
  return {
    isMobile,
    timers,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: syncTimers,
  tryAgain: syncTimers,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const TimersInScenesResetEnhanced = enhance

export default TimersInScenesResetEnhanced
