import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  settingTabsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
  },
  deviceSettingContainer: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: 80,
    },
  },
  deviceSettingsIcon: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.cardBackground,
    transition: 'box-shadow 0.2s ease-out',
  },
  deviceSettingsIconActive: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.cardBackground,
    transition: 'box-shadow 0.2s ease-out',
    boxShadow: '0px 0px 50px rgba(255, 255, 255, 0.5), 0px 0px 15px rgba(255, 255, 255, 0.7)',
  },
  deviceSettingsItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deviceSettingsLabel: {
    marginTop: 10,
    width: 70,
    textAlign: 'center',
    lineHeight: '18px',
  },
}))

export default useStyles
