import startInstallFirmwareLogic from '@app/logics/cloudFirmwareUpdate/startInstallFirmware'
import patchCellularModuleLogic from '@app/logics/cloudFirmwareUpdate/patchCellularModule'
import getTakeOnBoardDeviceType from '@app/logics/myDevices/twrDevices/getTakeOnBoardDeviceType'
import patchTakeOnBoardDeviceTypeLogic from '@app/logics/myDevices/twrDevices/patchTakeOnBoardDeviceType'
import fetchMezzoLightsLogic from '@app/logics/myDevices/mezzoLights/fetchMezzoLights'
import patchMezzoLightsLogic from '@app/logics/myDevices/mezzoLights/patchMezzoLights'
import fetchAnotherScenesListLogic from '@app/logics/scenes/conditions/fetchAnotherScenesList'
import patchEnableWebhook from '@app/logics/scenes/patchEnableWebhook'

import fetchProgramOptionsLogic from './programOptions/fetchProgramOptions'
import submitProgramOptionsLogic from './programOptions/submitProgramOptions'
import fetchMezzoErrorsOptionLogic from './fetchMezzoErrorsOption'

import fetchSecretsLogic from './secrets/fetchSecrets'
import getClientCredentialsToken from './secrets/getClientCredentialsToken'

import submitNotificationsSettingsLogic from './notifications/submitNotificationsSettings'
import fetchNotificationsSettingsLogic from './notifications/fetchNotificationsSettings'
import subscribeNotificationsChannelLogic from './notifications/subscribeNotificationsChannel'
import fetchNotificationsLogic from './notifications/fetchNotifications'

import signOut from './signOut'
import shadowIn from './shadowIn'

import fetchUnits from './unit/fetchUnits'
import patchUnitDevice from './unit/patchUnitDevice'

import patchUnitLocationLogic from './unit/patchUnitLocation'
import fetchUnitInfoLogic from './unit/fetchUnitInfo'

import fetchUsersLogic from './users/fetchUsers'
import fetchGeofenceUsersLogic from './users/fetchGeofenceUsers'
import fetchUsersRequestsLogic from './users/fetchUsersRequests'
import fetchCurrentUserLogic from './users/fetchCurrentUser'
import submitCurrentUserLogic from './users/submitCurrentUser'
import rejectUserRequest from './users/rejectUserRequest'
import approveUserRequest from './users/approveUserRequest'
import submitPinCode from './users/submitPinCode'
import fetchPinCode from './users/fetchPinCode'

import fetchCurrentMemberLogic from './users/fetchCurrentMember'
import submitCurrentMemberLogic from './users/submitCurrentMember'
import submitChangedAvatar from './users/submitChangedAvatar'
import submitSecretCode from './users/submitSecretCode'
import deleteCurrentMemberLogic from './users/deleteCurrentMember'

import fetchEventsLogic from './fetchEvents'

import fetchEnergiesLogic from './myEnergy/fetchEnergies'
import fetchDevicesWithEnergiesLogic from './myEnergy/fetchDevicesWithEnergies'
import fetchEcoModeDevicesLogic from './myEnergy/fetchEcoModeDevices'
import patchEcoModeDeviceLogic from './myEnergy/patchEcoModeDevice'
import postEcoModeDevicesTurnOffLogic from './myEnergy/postEcoModeDevicesTurnOff'
import fetchRoomsStatsLogic from './myEnergy/fetchRoomsStats'
import fetchRoomStatsLogic from './myEnergy/fetchRoomStats'
import submitResetCurrentRoomCountersLogic from './myEnergy/submitResetCurrentRoomCounters'
import submitResetDevicesEnergiesLogic from './myEnergy/submitResetDevicesEnergies'
import fetchBillingPeriodsStatsLogic from './myEnergy/fetchBillingPeriodsStats'
import fetchBillingCurrentStats from './myEnergy/fetchBillingCurrentStats'
import submitSetUpBillingPeriodOptionsLogic from './myEnergy/submitSetUpBillingPeriodOptions'
import submitResetBillingPeriodsStatsLogic from './myEnergy/submitResetBillingPeriodsStats'
import fetchBillingSettingLogic from './myEnergy/fetchBillingSetting'

import fetchScenesLogic from './scenes/fetchScenes'
import deleteSceneLogic from './scenes/deleteScene'
import triggerSceneActionsLogic from './scenes/triggerSceneActions'
import patchSceneLogic from './scenes/patchScene'
import patchSceneEnableValueLogic from './scenes/patchSceneEnableValue'
import activateSceneLogic from './scenes/activateScene'
import postConditionLogic from './scenes/conditions/postCondition'
import addSceneLogic from './scenes/addScene'
import postActionLogic from './scenes/actions/postAction'
import getConditionsAndActionsLogic from './scenes/getConditionsAndActions'
import deleteConditionLogic from './scenes/conditions/deleteCondition'
import deleteActionLogic from './scenes/actions/deleteAction'
import patchConditionLogic from './scenes/conditions/patchCondition'
import patchConditionOperandLogic from './scenes/conditions/patchConditionOperand'
import patchActionLogic from './scenes/actions/patchAction'
import putChangesetLogic from './scenes/putChangeset'
import patchSceneChangesetLogic from './scenes/patchSceneChangeset'
import postConditionChangesetLogic from './scenes/conditions/postConditionChangeset'
import postActionChangesetLogic from './scenes/actions/postActionChangeset'
import fetchSubDevices from './scenes/actions/fetchSubDevices'
import sendCodeToEmailLogic from './wizard/forgotPassword/sendCodeToEmail'
import sendAccountVerificationLink from './wizard/sendAccountVerificationLink'

import codeConfirmationLogic from './wizard/forgotPassword/codeConfirmation'
import submitNewPasswordLogic from './wizard/forgotPassword/submitNewPassword'
import authByPasswordLogic from './wizard/authByPassword'
import signUp from './wizard/signUp'

import refreshTokenInterceptorLogic from './refreshTokenInterceptor'

import fetchTwrDevicesLogic from './scenes/conditions/twrDevices/fetchTwrDevices'
import fetchTwrDevicesByGroupsLogic from './scenes/conditions/twrDevices/fetchTwrDevicesByGroups'
import fetchTwrDevicesByRoomsLogic from './scenes/conditions/twrDevices/fetchTwrDevicesByRooms'
import fetchTwrDeviceStatesLogic from './scenes/conditions/twrDevices/fetchTwrDeviceStates'
import fetchZWaveDevicesLogic from './scenes/conditions/zWaveDevices/fetchZWaveDevices'
import fetchZWaveDevicesByGroupsLogic from './scenes/conditions/zWaveDevices/fetchZWaveDevicesByGroups'
import fetchZWaveDevicesByRoomsLogic from './scenes/conditions/zWaveDevices/fetchZWaveDevicesByRooms'
import fetchZWaveDevicesCapabitities from './scenes/conditions/zWaveDevices/fetchZWaveDevicesCapabilities'
import fetchZWaveDevicesStates from './scenes/conditions/zWaveDevices/fetchZWaveDevicesStates'
import fetchZWaveBinarySensorTypes from './scenes/conditions/zWaveDevices/fetchZWaveBinarySensorTypes'
import fetchZWaveBinarySensorValues from './scenes/conditions/zWaveDevices/fetchZWaveBinarySensorValues'
import fetchSecurityOptionsConditions from './scenes/conditions/zWaveDevices/fetchSecurityOptionsConditions'

import fetchZWaveMultilevelSensorTypes from './scenes/conditions/zWaveDevices/fetchZWaveMultilevelSensorTypes'
import fetchSensorNotifications from './scenes/conditions/zWaveDevices/fetchSensorNotifications'

import fetchMyStatusPopupDeviceStats from './myStatus/fetchMyStatusPopupDeviceStats'
import fetchMyStatusDevices from './myStatus/fetchMyStatusDevices'
import subscribeMyStatusLogic from './myStatus/subscribeMyStatusDevices'

import fetchMyDevices from './myDevices/fetchMyDevices'
import putSortMyDevicesLogic from './myDevices/putSortMyDevices'

import postNewZwaveDeviceLogic from './myDevices/zwaveDevices/postNewZwaveDevice'
import postZwaveReinterview from './myDevices/zwaveDevices/postZwaveReinterview'
import patchZwaveDeviceStatusLogic from './myDevices/zwaveDevices/patchZwaveDeviceStatus'
import patchZwaveDeviceLogic from './myDevices/zwaveDevices/patchZwaveDevice'
import patchNewZwaveDeviceLogic from './myDevices/zwaveDevices/patchNewZwaveDevice'
import cancelZwaveDeviceLogic from './myDevices/zwaveDevices/cancelZwaveDevice'
import deleteZwaveDeviceLogic from './myDevices/zwaveDevices/deleteZwaveDevice'
import cancelZwaveDeleteLogic from './myDevices/zwaveDevices/cancelZwaveDelete'
import fetchZwaveDeviceInfo from './myDevices/deviceInfo/fetchZwaveDeviceInfo'
import fetchZwaveDeviceDetailsLogic from './myDevices/deviceSettings/zwaveDetails/fetchZwaveDeviceDetails'
import getZwaveDeviceParameterLogic from './myDevices/deviceSettings/zwaveDetails/getZwaveDeviceParameter'
import parseZwaveDeviceParameterLogic from './myDevices/deviceSettings/zwaveDetails/parseZwaveDeviceParameter'
import setZwaveDeviceParameterLogic from './myDevices/deviceSettings/zwaveDetails/setZwaveDeviceParameter'
import getZwaveDeviceParametersLogic from './myDevices/deviceSettings/zwaveDetails/getZwaveDeviceParameters'

import postDeviceColoursLogic from './myDevices/deviceSettings/deviceColour/postDeviceColours'

import getTwrAlarmOptions from './myDevices/twrDevices/getTwrAlarmOptions'
import getTwrSirenOption from './myDevices/twrDevices/getTwrSirenOption'
import postNewTwrDeviceLogic from './myDevices/twrDevices/postNewTwrDevice'
import patchNewTwrDeviceLogic from './myDevices/twrDevices/patchNewTwrDevice'
import patchTwrDeviceLogic from './myDevices/twrDevices/patchTwrDevice'
import patchTwrSirenOptionsLogic from './myDevices/twrDevices/patchTwrSirenOptions'
import deleteTwrDeviceLogic from './myDevices/twrDevices/deleteTwrDevice'
import fetchTwrDeviceInfo from './myDevices/deviceInfo/fetchTwrDeviceInfo'

import getTwrHealth from './myDevices/deviceInfo/getTwrHealth'

import patchVoiceAssistantLogic from './myDevices/deviceSettings/voiceAssistant/patchVoiceAssistant'
import patchVoiceAssistantPinLogic from './myDevices/deviceSettings/voiceAssistant/patchVoiceAssistantPin'

import getDeviceHealthLogic from './myDevices/deviceSettings/failedNode/getDeviceHealth'
import deleteUnhealthyDeviceLogic from './myDevices/deviceSettings/failedNode/deleteUnhealthyDevice'
import replaceUnhealthyDeviceLogic from './myDevices/deviceSettings/failedNode/replaceUnhealthyDevice'
import cancelReplaceDeviceLogic from './myDevices/deviceSettings/failedNode/cancelReplaceDevice'
import subscribeFailedNodeLogic from './myDevices/channels/deviceSettings/subscribeFailedNode'
import unsubscribeFailedNodeLogic from './myDevices/channels/deviceSettings/unsubscribeFailedNode'
import subscribeMezzoLightsLogic from './myDevices/channels/devices/subscribeMezzoLights'
import unsubscribeMezzoLightsLogic from './myDevices/channels/devices/unsubscribeMezzoLights'

import patchSecurityOptionLogic from './myDevices/deviceSettings/securityOption/patchSecurityOption'

import subscribeDevicesLogic from './myDevices/channels/devices/subscribeDevices'
import subscribeDevicesStatusLogic from './myDevices/channels/devices/subscribeDevicesStatus'
import subscribeDeleteMyDevicesLogic from './myDevices/channels/devices/subscribeDeleteDevice'
import subscribeMyDevicesTwrLogic from './myDevices/channels/twr/subscribeTwrDevice'
import subscribeMyDevicesZwaveLogic from './myDevices/channels/zwave/subscribeZwaveDevice'
import subscribeDeviceInfoLogic from './myDevices/deviceInfo/subscribeDeviceInfo'
import unsubscribeDevicesLogic from './myDevices/channels/devices/unsubscribeDevices'
import unsubscribeDevicesStatusLogic from './myDevices/channels/devices/unsubscribeDevicesStatus'
import unsubscribeDeleteDeviceLogic from './myDevices/channels/devices/unsubscribeDeleteDevice'
import unsubscribeMyDevicesTwrLogic from './myDevices/channels/twr/unsubscribeTwrDevice'
import unsubscribeMyDevicesZwaveLogic from './myDevices/channels/zwave/unsubscribeZwaveDevice'
import unsubscribeDeviceInfoLogic from './myDevices/deviceInfo/unsubscribeDeviceInfo'

import fetchGeofencesLogic from './geofences/fetchGeofences'
import postGeofencesLogic from './geofences/postGeofence'
import patchGeofenceLogic from './geofences/patchGeofence'
import deleteGeofenceLogic from './geofences/deleteGeofence'

import fetchGroupsListLogic from './groups/fetchGroupsList'
import fetchGroupsDevicesLogic from './groups/fetchGroupDevices'
import postGroupLogic from './groups/postGroup'
import patchGroupLogic from './groups/patchGroup'
import deleteGroupLogic from './groups/deleteGroup'
import postGroupDeviceLogic from './groups/postGroupDevice'
import deleteGroupDeviceLogic from './groups/deleteGroupDevice'

import fetchRoomsListLogic from './rooms/fetchRoomsList'
import fetchRoomsDevicesLogic from './rooms/fetchRoomDevices'
import postRoomLogic from './rooms/postRoom'
import patchRoomLogic from './rooms/patchRoom'
import deleteRoomLogic from './rooms/deleteRoom'
import postRoomDeviceLogic from './rooms/postRoomDevice'
import deleteRoomDeviceLogic from './rooms/deleteRoomDevice'

import fetchContactsLogic from './contacts/fetchContacts'
import postContactLogic from './contacts/postContact'
import patchContactLogic from './contacts/patchContact'
import deleteContactLogic from './contacts/deleteContact'
import updateContactAvatarLogic from './contacts/updateAvatar'
import updateContactAlertMessageLogic from './contacts/updateAlertMessage'

import fetchRoomsScenes from './scenes/conditions/roomsScenes/fetchRoomsScenes'
import fetchGroupsScenes from './scenes/conditions/groupsScenes/fetchGroupsScenes'

import checkPost from './scenes/actions/pushNotifications/checkPost'

import updateGuardPanelStatusLogic from './guardPanel/patchGuardPanelStatus'
import subscribeGuardPanelStatusLogic from './guardPanel/subscribeGuardPanelStatus'
import unsubscribeGuardPanelStatusLogic from './guardPanel/unsubscribeGuardPanelStatus'

import putSphereSort from './putSphereSort'
import fetchTimers from './timers/fetchTimers'
import fetchTimersForScenesLogic from './scenes/timers/fetchTimersForScenes'
import putTimerLogic from './timers/putTimer'
import resetTimerLogic from './timers/resetTimer'
import subscribeTimerChannelLogic from './timers/subscribeTimerChannel'
import unsubscribeTimerChannelLogic from './timers/unsubscribeTimerChannel'
import resetAllTimersLogic from './timers/resetAllTimers'
import timerTrigger from './timers/timerTrigger'

import fetchVirtualSwitchesLogic from './virtualSwitches/fetchVirtualSwitches'
import triggerVirtualSwitchLogic from './virtualSwitches/triggerVirtualSwitch'
import subscribeVirtualSwitchChannelLogic from './virtualSwitches/subscribeVirtualSwitchCable'
import unsubscribeVirtualSwitchChannelLogic from './virtualSwitches/unsubscribeVirtualSwitchChannel'

import fetchActivitiesLogic from './activities/fetchActivities'
import subscribeActivitiesChannelLogic from './activities/subscribeActivitiesChannel'

import hotUpdate from './hotUpdate'

import fetchMezzoTimeLogic from './mezzoTime/fetchMezzoTime'
import subscribeCountersChannelLogic from './syncCounters/subscribeCountersChannel'
import syncCountersStatusLogic from './syncCounters/syncCountersStatus'
import startSyncCountersLogic from './syncCounters/startSyncCounters'
import fetchCloudFirmwareInfoLogic from './cloudFirmwareUpdate/fetchCloudFirmwareInfo'
import manualUpdateLogic from './cloudFirmwareUpdate/manualUpdate'
import patchFirmwareLangLogic from './cloudFirmwareUpdate/patchFirmwareLang'
import subscribeCloudFirmwareUpdateChannelLogic from './cloudFirmwareUpdate/subscribeCloudFirmwareUpdateChannel'
import ResetProcessLogic from './cloudFirmwareUpdate/resetProcess'
import continueDownloadingLogic from './cloudFirmwareUpdate/continueDownloading'
import patchCloudFirmwareInfoLogic from './cloudFirmwareUpdate/patchCloudFirmwareInfo'
import stopUploadingLogic from './cloudFirmwareUpdate/stopUploading'
import rebootLogic from './cloudFirmwareUpdate/reboot'

import subscribeScenesListLogic from './scenes/subscribeScenesList'
import unsubscribeScenesListLogic from './scenes/unsubscribeScenesList'

import fetchPillsReminders from './pillsReminders/fetchPillsReminders'
import deletePillsReminder from './pillsReminders/deletePillsReminder'
import createPillsReminder from './pillsReminders/createPillsReminder'
import patchPillsReminder from './pillsReminders/patchPillsReminder'
import fetchPillsRemindersEvents from './pillsReminders/fetchPillsRemindersEvents'
import fetchProgramOptionsFormConfigLogic from './programOptions/POConfig/fetchProgramOptionsFromConfig'
import submitProgramOptionsFormConfigLogic from './programOptions/POConfig/submitProgramOptionsFromConfig'

import fetchTwrDevicesHealth from './fetchTwrDevicesHealth'
import fetchNumberOfZonesOptionsLogic from './numberOfZones/fetchNumberOfZones'
import submitSecurityRulesOptionsLogic from './numberOfZones/submitNumberOfZones'
import postKeypadCommand from './mySecurity/postKeypadCommand'
import deleteAccountLogic from './wizard/deleteAccount'
import startPanic from './mySecurity/startPanic'
import cancelPanic from './mySecurity/cancelPanic'
import setArmHomeMode from './mySecurity/setArmHomeMode'
import getAppConfigLogic from './appConfig'
import patchUnitDeviceNameLogic from './unit/patchUnitDeviceName'

export default [
  fetchProgramOptionsLogic,
  submitProgramOptionsLogic,
  fetchUnits,
  patchUnitDevice,
  patchUnitLocationLogic,
  fetchUnitInfoLogic,
  fetchMezzoErrorsOptionLogic,
  fetchSecretsLogic,
  shadowIn,
  submitNotificationsSettingsLogic,
  fetchNotificationsSettingsLogic,
  subscribeNotificationsChannelLogic,
  fetchNotificationsLogic,
  fetchUsersLogic,
  fetchGeofenceUsersLogic,
  fetchUsersRequestsLogic,
  fetchCurrentUserLogic,
  submitCurrentUserLogic,
  signOut,
  rejectUserRequest,
  approveUserRequest,
  fetchCurrentMemberLogic,
  submitCurrentMemberLogic,
  submitChangedAvatar,
  submitSecretCode,
  deleteCurrentMemberLogic,
  fetchEventsLogic,
  fetchEnergiesLogic,
  fetchDevicesWithEnergiesLogic,
  fetchEcoModeDevicesLogic,
  patchEcoModeDeviceLogic,
  postEcoModeDevicesTurnOffLogic,
  fetchRoomsStatsLogic,
  fetchRoomStatsLogic,
  submitResetCurrentRoomCountersLogic,
  submitResetDevicesEnergiesLogic,
  fetchBillingPeriodsStatsLogic,
  fetchBillingCurrentStats,
  submitSetUpBillingPeriodOptionsLogic,
  submitResetBillingPeriodsStatsLogic,
  fetchScenesLogic,
  deleteSceneLogic,
  triggerSceneActionsLogic,
  patchSceneLogic,
  activateSceneLogic,
  addSceneLogic,
  postConditionLogic,
  postActionLogic,
  getConditionsAndActionsLogic,
  deleteConditionLogic,
  deleteActionLogic,
  patchConditionLogic,
  patchConditionOperandLogic,
  patchActionLogic,
  patchSceneEnableValueLogic,
  putChangesetLogic,
  patchSceneChangesetLogic,
  postConditionChangesetLogic,
  postActionChangesetLogic,
  fetchTwrDevicesLogic,
  fetchTwrDevicesByGroupsLogic,
  fetchTwrDevicesByRoomsLogic,
  fetchZWaveDevicesLogic,
  fetchZWaveDevicesByGroupsLogic,
  fetchZWaveDevicesByRoomsLogic,
  fetchTwrDeviceStatesLogic,
  sendCodeToEmailLogic,
  codeConfirmationLogic,
  submitNewPasswordLogic,
  fetchZWaveDevicesCapabitities,
  fetchZWaveDevicesStates,
  getClientCredentialsToken,
  authByPasswordLogic,
  signUp,
  refreshTokenInterceptorLogic,
  fetchMyStatusPopupDeviceStats,
  fetchMyStatusDevices,
  subscribeMyStatusLogic,
  fetchZWaveBinarySensorTypes,
  fetchZWaveBinarySensorValues,
  fetchZWaveMultilevelSensorTypes,
  postZwaveReinterview,
  fetchMyDevices,
  getTwrAlarmOptions,
  putSortMyDevicesLogic,
  subscribeDevicesLogic,
  subscribeDevicesStatusLogic,
  subscribeMyDevicesTwrLogic,
  subscribeMyDevicesZwaveLogic,
  subscribeDeleteMyDevicesLogic,
  subscribeDeviceInfoLogic,
  unsubscribeDevicesLogic,
  unsubscribeDevicesStatusLogic,
  unsubscribeMyDevicesTwrLogic,
  unsubscribeMyDevicesZwaveLogic,
  unsubscribeDeleteDeviceLogic,
  unsubscribeDeviceInfoLogic,
  postNewTwrDeviceLogic,
  postNewZwaveDeviceLogic,
  patchNewTwrDeviceLogic,
  patchTwrDeviceLogic,
  patchZwaveDeviceStatusLogic,
  patchZwaveDeviceLogic,
  patchNewZwaveDeviceLogic,
  cancelZwaveDeviceLogic,
  deleteTwrDeviceLogic,
  fetchTwrDeviceInfo,
  getTwrHealth,
  deleteZwaveDeviceLogic,
  cancelZwaveDeleteLogic,
  fetchZwaveDeviceInfo,
  fetchZwaveDeviceDetailsLogic,
  getZwaveDeviceParameterLogic,
  parseZwaveDeviceParameterLogic,
  setZwaveDeviceParameterLogic,
  getZwaveDeviceParametersLogic,
  postDeviceColoursLogic,
  patchVoiceAssistantLogic,
  patchVoiceAssistantPinLogic,
  getDeviceHealthLogic,
  deleteUnhealthyDeviceLogic,
  replaceUnhealthyDeviceLogic,
  cancelReplaceDeviceLogic,
  subscribeFailedNodeLogic,
  unsubscribeFailedNodeLogic,
  fetchGeofencesLogic,
  postGeofencesLogic,
  patchGeofenceLogic,
  deleteGeofenceLogic,
  fetchGroupsListLogic,
  postGroupLogic,
  patchGroupLogic,
  deleteGroupLogic,
  fetchGroupsDevicesLogic,
  postGroupDeviceLogic,
  deleteGroupDeviceLogic,
  fetchRoomsListLogic,
  postRoomLogic,
  patchRoomLogic,
  deleteRoomLogic,
  postRoomDeviceLogic,
  deleteRoomDeviceLogic,
  fetchRoomsDevicesLogic,
  fetchRoomsScenes,
  fetchGroupsScenes,
  fetchContactsLogic,
  postContactLogic,
  patchContactLogic,
  deleteContactLogic,
  updateContactAvatarLogic,
  updateContactAlertMessageLogic,
  putSphereSort,
  updateGuardPanelStatusLogic,
  subscribeGuardPanelStatusLogic,
  unsubscribeGuardPanelStatusLogic,
  fetchTimers,
  putTimerLogic,
  resetTimerLogic,
  subscribeTimerChannelLogic,
  unsubscribeTimerChannelLogic,
  resetAllTimersLogic,
  timerTrigger,
  fetchTimersForScenesLogic,
  checkPost,
  fetchVirtualSwitchesLogic,
  triggerVirtualSwitchLogic,
  subscribeVirtualSwitchChannelLogic,
  unsubscribeVirtualSwitchChannelLogic,
  hotUpdate,
  fetchActivitiesLogic,
  subscribeActivitiesChannelLogic,
  fetchMezzoTimeLogic,
  subscribeCountersChannelLogic,
  syncCountersStatusLogic,
  startSyncCountersLogic,
  fetchSubDevices,
  fetchCloudFirmwareInfoLogic,
  manualUpdateLogic,
  patchFirmwareLangLogic,
  subscribeCloudFirmwareUpdateChannelLogic,
  ResetProcessLogic,
  continueDownloadingLogic,
  patchCloudFirmwareInfoLogic,
  stopUploadingLogic,
  subscribeScenesListLogic,
  unsubscribeScenesListLogic,
  fetchSensorNotifications,
  startInstallFirmwareLogic,
  patchSecurityOptionLogic,
  fetchSecurityOptionsConditions,
  getTwrSirenOption,
  patchTwrSirenOptionsLogic,
  patchCellularModuleLogic,
  getTakeOnBoardDeviceType,
  patchTakeOnBoardDeviceTypeLogic,
  fetchMezzoLightsLogic,
  patchMezzoLightsLogic,
  subscribeMezzoLightsLogic,
  unsubscribeMezzoLightsLogic,
  submitPinCode,
  fetchPinCode,
  fetchPillsReminders,
  deletePillsReminder,
  createPillsReminder,
  patchPillsReminder,
  fetchPillsRemindersEvents,
  fetchAnotherScenesListLogic,
  patchEnableWebhook,
  fetchBillingSettingLogic,
  rebootLogic,
  fetchTwrDevicesHealth,
  fetchProgramOptionsFormConfigLogic,
  submitProgramOptionsFormConfigLogic,
  fetchNumberOfZonesOptionsLogic,
  submitSecurityRulesOptionsLogic,
  postKeypadCommand,
  deleteAccountLogic,
  startPanic,
  cancelPanic,
  setArmHomeMode,
  getAppConfigLogic,
  sendAccountVerificationLink,
  patchUnitDeviceNameLogic,
]
