import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  data: PropTypes.shape({
    value: PropTypes.number,
  }),
  description: PropTypes.string,
  number: PropTypes.number,
  verified: PropTypes.bool,
})

export default propTypes
