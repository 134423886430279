import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    height: 710,
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem',
    height: 'calc(100vh - 60px)',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowBack: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 2.5rem)',
    alignItems: 'center',
  },
  iconContainer: {
    width: 160,
    height: 160,
    borderRadius: '50%',
    backgroundColor: theme.backgrounds.cardBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainerMobile: {
    width: 90,
    height: 90,
  },
  icon: {
    fontSize: 80,
  },
  iconMobile: {
    fontSize: 40,
  },
  iconAndName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameContainer: {
    marginTop: '1rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lightsValue: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  slider: {
    margin: '0px 15px',
  },
  sliderWrapperMobile: {
    display: 'flex',
    minWidth: '70%',
    margin: '60px 0px',
  },
  sliderWrapper: {
    display: 'flex',
    minWidth: '45%',
    margin: '80px 0px',
  },
  saveButton: {
    width: '25%',
  },
}))

export default useStyles
