import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { addMinutes } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import values from '@app/lib/PillsReminders/values'
import { numberOfDaysValidation, periodsInDaysValidation } from '@app/lib/PillsReminders/validators'
import {
  Box, Button, IconButton, Paper, TextField,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import { reminderData } from '@app/helpers/pillsReminders/reminderData'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import SelectWeekdays from '@app/components/PillsReminders/ReminderEditor/SelectWeekdays/SelectWeekdays'
import TextFieldWithTooltip from '@app/components/PillsReminders/ReminderEditor/TextFieldWithTooltip/TextFieldWithTooltip'
import { createPillsReminder, fetchPillsReminders, patchPillsReminder } from '@app/actions/pillsReminders/pillsReminders'
import { ScenesArrowBack } from 'icons'
import { PILLS_REMINDERS } from '@app/constants/routes'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import SelectField from './SelectField/SelectField'
import useStyles from './ReminderEditor.style'

const getID = (pathname): number => {
  const result = pathname.match(/\/web\/pills_reminders\/(\d+)\/edit/)
  return result ? +result[1] : null
}

const ReminderEditor = (): Node => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const { reminders } = useSelector((state) => state.pillsReminders)
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const currentReminderId = getID(window.location.pathname)
  const currentReminderData = reminders.find((reminder) => reminder.id === currentReminderId)
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const [validationState, setValidationState] = useState(false)
  const [reminderName, setReminderName] = useState('')
  const [startDate, setStartDate] = useState(currentReminderData ? new Date(currentReminderData.start_on) : new Date())
  const [duration, setDuration] = useState(0)
  const [numberOfDays, setNumberOfDays] = useState(0)
  const [scheduleType, setScheduleType] = useState('default')
  const [weekdays, setWeekdays] = useState({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  })
  const [periodInDays, setPeriodInDays] = useState(2)
  const [reminderTime, setReminderTime] = useState(0)
  const [timeAndQuantity, setTimeAndQuantity] = useState(currentReminderData
    ? currentReminderData.pills_dosages.map((dosage) => ({
      amount: +dosage.amount,
      time: new Date(dosage.time),
    })) : [{ amount: 1, time: new Date() }])
  const [currentTimer, setCurrentTimer] = useState(0)
  const [isPickerOpened, setIsPickerOpened] = useState(false)

  useEffect(() => {
    dispatch(fetchPillsReminders())
  }, [])

  useEffect(() => {
    if (reminders.length) {
      if (currentReminderId) {
        setReminderName(currentReminderData.name)
        setStartDate(new Date(currentReminderData.start_on))
        setDuration(+currentReminderData.finish_after_days >= 1 ? 1 : 0)
        setNumberOfDays(+currentReminderData.finish_after_days)
        if (currentReminderData.schedule_type === 'days_interval'
        && currentReminderData.days_interval === 1) {
          setScheduleType('default')
          setPeriodInDays(currentReminderData.days_interval)
        } else {
          setScheduleType(currentReminderData.schedule_type)
          setPeriodInDays(currentReminderData.days_interval)
        }
        if (currentReminderData.days_of_weeks) {
          const days = currentReminderData.days_of_weeks.split(',')
          const pickedDays = { ...weekdays }
          Object.keys(pickedDays).forEach((key) => {
            if (days.includes(key)) {
              pickedDays[key] = true
            }
          })
          setWeekdays(pickedDays)
        } else {
          setWeekdays(weekdays)
        }
        setReminderTime(currentReminderData.pills_dosages.length - 1)
        setTimeAndQuantity(currentReminderData.pills_dosages.map((dosage) => ({
          amount: +dosage.amount,
          time: new Date(dosage.time),
        })))
      }
    }
  }, [reminders])

  useEffect(() => {
    renderTimeAndQuantityFields()
  }, [reminderTime])

  useEffect(() => {
    if ((numberOfDaysValidation(numberOfDays) || numberOfDays === 0)) {
      setValidationState(false)
    } else {
      setValidationState(true)
    }
  }, [numberOfDays])

  useEffect(() => {
    if (scheduleType === 'default') {
      setValidationState(false)
    }
    if (scheduleType === 'days_interval') {
      if (periodsInDaysValidation(periodInDays) && !(periodInDays === '1')) {
        setValidationState(false)
      } else {
        setValidationState(true)
      }
    }
  }, [scheduleType, periodInDays])

  useEffect(() => {
    if (scheduleType === 'days_of_weeks') {
      if (!(Object.values(weekdays).filter((day) => day).length === 0)) {
        setValidationState(false)
      } else {
        setValidationState(true)
      }
    }
  }, [scheduleType, weekdays])

  const handleNameChange = (event) => {
    if (event.target.value === '') {
      setReminderName(event.target.value)
      setValidationState(true)
    } else {
      setReminderName(event.target.value)
      setValidationState(false)
    }
  }

  const handleDurationChange = (event) => {
    setDuration(+event.target.value)
    if (+event.target.value === 0) {
      setNumberOfDays(0)
    }
    if (+event.target.value === 1) {
      if (!numberOfDays) {
        setNumberOfDays(1)
      }
    }
  }

  const handleSetNumberOfDaysChange = (event) => {
    if (event.target.value === '' || numberOfDaysValidation(+event.target.value)) {
      setNumberOfDays(event.target.value)
    }
  }

  const handleScheduleTypeChange = (event) => {
    switch (event.target.value) {
    case 'default':
      setPeriodInDays(1)
      break
    case 'days_interval':
      setPeriodInDays(2)
      break
    default:
      setPeriodInDays(0)
      break
    }
    setScheduleType(event.target.value)
  }

  const handleWeekdaysChange = (event) => {
    const checkedDay = values.weekdays.find((day) => day.label === event.target.name)
    setWeekdays({
      ...weekdays,
      [checkedDay.value]: event.target.checked,
    })
  }

  const handleSetPeriodInDaysChange = (event) => {
    if (event.target.value === '' || periodsInDaysValidation(+event.target.value)) {
      setPeriodInDays(event.target.value)
    }
  }

  const handleReminderTimeChange = (event) => {
    setReminderTime(+event.target.value)
    const timeAndQuantityFieldsCount = +event.target.value + 1
    let timeFlag = timeAndQuantity[timeAndQuantity.length - 1].time
    const fillTimeAndQuantityState = (number) => [...new Array(number)].map(() => {
      const minutesStep = 90
      timeFlag = addMinutes(timeFlag, minutesStep)
      return { amount: 1, time: timeFlag }
    })
    setTimeAndQuantity([...timeAndQuantity, ...fillTimeAndQuantityState(timeAndQuantityFieldsCount)].slice(0, timeAndQuantityFieldsCount))
  }

  const handleTimeChange = (newTime) => {
    const changedTimer = timeAndQuantity.map((timer, index) => (index === currentTimer
      ? { ...timer, time: newTime }
      : timer))
    setTimeAndQuantity(changedTimer)
  }

  const handleQuantityChange = (event) => {
    const currentQuantity = +event.target.parentNode.attributes['data-id'].value
    const changedQuantity = timeAndQuantity.map((quantity, index) => (index === currentQuantity
      ? { ...quantity, amount: +event.target.value }
      : quantity))
    setTimeAndQuantity(changedQuantity)
  }

  const renderTimeAndQuantityLabel = (fieldNumber) => {
    switch (fieldNumber) {
    case 1: return '1st time'
    case 2: return '2nd time'
    case 3: return '3rd time'
    default:
      return `${fieldNumber}th time`
    }
  }

  const createReminder = () => {
    dispatch(createPillsReminder(
      reminderData(reminderName, startDate, numberOfDays, scheduleType, periodInDays, weekdays, timeAndQuantity),
    ))
  }

  const patchReminder = () => {
    dispatch(patchPillsReminder(
      currentReminderId,
      reminderData(reminderName, startDate, numberOfDays, scheduleType, periodInDays, weekdays, timeAndQuantity),
    ))
  }

  const handlePickerOpen = () => {
    setIsPickerOpened((prevState) => !prevState)
  }

  const renderTimeAndQuantityFields = () => [...Array(reminderTime + 1)].map((event, index) => (
    <Wrapper className={classes.timeQuantityWrapper} key={`quantity-${index + 1}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {isPickerOpened && (<Wrapper className={classes.pickerBackground} />)}
        <TimePicker
          classes={{ label: classes.label }}
          label={renderTimeAndQuantityLabel(index + 1)}
          value={timeAndQuantity[index].time}
          onChange={(newTime) => {
            handleTimeChange(newTime)
          }}
          onOpen={handlePickerOpen}
          onClose={handlePickerOpen}
          variant="inline"
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={disableKeyboard}
              onClick={() => setCurrentTimer(params.InputProps['data-id'])}
            />
          )}
          InputProps={{
            'data-id': index,
            sx: {
              '& fieldset': {
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: 0,
                borderColor: theme.borders.main,
              },
              '& input': {
                padding: '12px 5px',
              },
            },
          }}
        />
      </LocalizationProvider>
      <SelectField
        dataId={index}
        styleClass={classes.quantity}
        label="Quantity"
        value={timeAndQuantity[index].amount}
        handleValue={handleQuantityChange}
        arrayOfValues={values.quantity}
      />
    </Wrapper>
  ))

  const disableKeyboard = (e) => e.preventDefault()

  return (
    <Paper
      className={classNames((isWebView && classes.webview), classes.root)}
    >
      <Wrapper className={classNames((isWebView && classes.webview), isMobile ? classes.containerMobile : classes.container)}>
        <Wrapper className={classNames(isWebView && classes.navigationWebView, classes.navigation)}>
          {isWebView ? (
            <NavBar
              title={I18n.t('titles.reminders')}
              customTitle
              withButton={false}
              isMobile={isMobile}
              backClick={() => navigate(PILLS_REMINDERS)}
            />
          ) : (
            <IconButton
              size="small"
              className={classes.backButton}
              onClick={() => navigate(PILLS_REMINDERS)}
              aria-label="Back"
            >
              <ScenesArrowBack />
            </IconButton>
          )}
        </Wrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Wrapper className={isMobile ? classes.mainMobile : classes.main}>
            <Box
              className={classes.name}
              component="form"
              sx={{
                '& .MuiTextField-fullWidthroot': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <>
                <TextField
                  fullWidth
                  label="Name"
                  variant="standard"
                  helperText="Required."
                  value={reminderName}
                  onChange={handleNameChange}
                />
              </>
            </Box>
            <Wrapper className={isMobile ? classes.gridMobile : classes.grid}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {isPickerOpened && (<Wrapper className={classes.pickerBackground} />)}
                <DatePicker
                  label="Start date"
                  value={startDate}
                  onChange={(newDate) => {
                    setStartDate(newDate)
                  }}
                  onOpen={handlePickerOpen}
                  onClose={handlePickerOpen}
                  variant="inline"
                  renderInput={(params) => <TextField onKeyDown={disableKeyboard} {...params} />}
                  inputFormat="dd/MM/yyyy"
                  InputProps={{
                    sx: {
                      '& fieldset': {
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderRadius: 0,
                        borderColor: theme.borders.main,
                      },
                    },
                  }}
                />
              </LocalizationProvider>
              <SelectField
                label="Duration"
                value={duration}
                handleValue={handleDurationChange}
                arrayOfValues={values.durations}
              />
              <TextFieldWithTooltip
                disabled={duration === 0}
                tooltipTitle="from 1 to 180"
                label="Set number of days"
                value={numberOfDays}
                handleValue={handleSetNumberOfDaysChange}
                error={(!numberOfDays && !(numberOfDays === 0))}
              />
              <SelectField
                styleClass={classes.scheduleTypes}
                label="Schedule type"
                value={scheduleType}
                handleValue={handleScheduleTypeChange}
                arrayOfValues={values.scheduleTypes}
              />
              <Wrapper className={classes.scheduleChoice}>
                { scheduleType === 'days_of_weeks' && (
                  <SelectWeekdays
                    className={classNames(classes.hide, scheduleType === 'days_of_weeks' && classes.show)}
                    weekdays={weekdays}
                    handleValue={handleWeekdaysChange}
                  />
                )}
                { scheduleType === 'days_interval' && (
                  <TextFieldWithTooltip
                    tooltipTitle="from 2 to 90"
                    label="Set period in days"
                    value={periodInDays}
                    handleValue={handleSetPeriodInDaysChange}
                    error={(!periodInDays || periodInDays === '1')}
                  />
                )}
              </Wrapper>
              <SelectField
                styleClass={classes.reminderTimes}
                label="Reminder times"
                value={reminderTime}
                handleValue={handleReminderTimeChange}
                arrayOfValues={values.reminderTimes}
              />
              {renderTimeAndQuantityFields()}
            </Wrapper>
            <Button
              variant="contained"
              color="primary"
              className={isMobile ? classes.saveButtonMobile : classes.saveButton}
              disabled={validationState || !reminderName}
              onClick={currentReminderId ? patchReminder : createReminder}
            >
              {I18n.t('buttons.save')}
            </Button>
          </Wrapper>
        </LocalizationProvider>
      </Wrapper>
    </Paper>
  )
}

export default ReminderEditor
