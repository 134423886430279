import update from 'immutability-helper'
import * as type from '@app/constants/secrets'

type State = {
  accessToken: {
    token: string,
    expiresIn: number,
    tokenType: string,
    timestamp: number,
    refreshToken: string,
  } | null,
  unitToken: string | null,
  isAuthenticated: boolean,
  isTokenRefreshing: boolean,
  clientCredentialsToken: string | null,
}

const initialState = {
  accessToken: null,
  unitToken: null,
  isAuthenticated: false,
  clientCredentialsToken: null,
  isTokenRefreshing: false,
  tokenStatus: 'none',
}

export default function secrets(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.SAVE_CLIENT_CREDENTIALS_TOKEN:
    return update(state, {
      clientCredentialsToken: { $set: action.clientCredentialsToken },
    })
  case type.CLEAR_CLIENT_CREDENTIALS_TOKEN:
    return update(state, {
      clientCredentialsToken: { $set: null },
    })
  case type.UPDATE_SECRETS: {
    return {
      ...state,
      unitToken: action.unitToken,
      clientId: action.clientId,
      isAuthenticated: true,
    }
  }
  case type.UPDATE_ACCESS_TOKEN: {
    return {
      ...state,
      accessToken: {
        token: action.token,
        expiresIn: action.expiresIn,
        tokenType: action.tokenType,
        timestamp: new Date().getTime(),
        refreshToken: action.refreshToken,
      },
    }
  }
  case type.AUTHENTIFICATION_SUCCESS: {
    return { ...state, isAuthenticated: true }
  }
  case type.TOKEN_STATUS_NONE: {
    return { ...state, tokenStatus: 'none' }
  }
  case type.TOKEN_STATUS_REFRESHING: {
    return { ...state, tokenStatus: 'refreshing' }
  }
  case type.TOKEN_STATUS_REFRESHED: {
    return { ...state, tokenStatus: 'refreshed' }
  }
  case type.RESET_SECRETS:
    return initialState
  default:
    return state
  }
}
