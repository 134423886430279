const styles = (theme) => ({
  main: {
    padding: 10,
    height: '100%',
  },
  mainDesktop: {
    padding: 25,
    height: '100%',
  },
  wrapper: {
    paddingTop: 34,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  button: {
    textDecoration: 'underline',
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
