const styles = () => ({
  info: {
    width: '100%',
    paddingBottom: 50,
    overflowX: 'auto',
    wordWrap: 'break-word',
  },
})

export default styles
