import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CLOUD_FIRMWARE_INFO } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import { syncChannel } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'
import Api from '@app/api/cloudFirmwareUpdate/fetchCloudFirmwareInfo'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchCloudFirmwareInfoLogic = createLogic({
  type: SYNC_CLOUD_FIRMWARE_INFO,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_CLOUD_FIRMWARE_INFO))
    Api.fetchCloudFirmwareInfo(token)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(syncChannel())
        dispatch(apiRequestDone(SYNC_CLOUD_FIRMWARE_INFO))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchCloudFirmwareInfo failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchCloudFirmwareInfoLogic
