import React, { useState, useEffect } from 'react'
import {
  Typography, IconButton, Popover, List, ListItem, Button,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import { useNavigate } from 'react-router-dom'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft, ScenesArrowBack } from 'icons'
import IOSSwitch from '@app/components/Common/IOSSwitch/iOSSwitch'
import { zwaveSecurityOptions } from '@app/lib/MyDevices/zwaveSecurityOptions'
import { patchSecurityOption } from '@app/actions/myDevices/deviceSettings/securityOption'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { setCurrentDevice, setMyDevices } from '@app/actions/myDevices/myDevices'
import useStyles from './ZwaveSecurityOptions.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  devices: Array<IZwaveOrTwrDevice>,
  isMobile: boolean,
}

const ZwaveSecurityOptions: React.FC<Props> = (props) => {
  const { currentDevice, devices, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [switchState, setSwitch] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [currentOption, setCurrentOption] = useState(0)

  useEffect(() => {
    setSwitch(currentDevice.security_option?.enable!)
    if (currentDevice.security_option?.enable) {
      setCurrentOption(zwaveSecurityOptions.findIndex((option) => option.option === currentDevice.security_option?.option))
    }
  }, [])

  useEffect(() => {
    const device = devices.find((dev) => (dev.id === currentDevice.id && dev.device_type === currentDevice.device_type))
    if (device) {
      dispatch(setCurrentDevice(device))
    }
  }, [devices])

  const goBackHandler = () => {
    navigate(-1)
  }

  const handleSwitch = () => {
    setSwitch(!switchState)
  }

  const selectOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (switchState) {
      setAnchorEl(event.currentTarget)
    }
  }

  const closeSelect = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const securityOptionClick = (index: number) => {
    setCurrentOption(index)
    closeSelect()
  }

  const updateSecurityOption = () => {
    const updatedSecurityOptions: IZwaveOrTwrDevice['security_option'] = {
      possibility: true,
      enable: switchState,
      option: zwaveSecurityOptions[currentOption].option,
    }
    dispatch(setMyDevices(devices.map((device) => (
      device.id === currentDevice.id && device.device_type === currentDevice.device_type
        ? { ...device, security_option: updatedSecurityOptions }
        : device))))
    dispatch(patchSecurityOption(
      currentDevice.id,
      {
        enabled: switchState,
        option: zwaveSecurityOptions[currentOption].option,
      },
    ))
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classNames(classes.navigationControls, isMobile && classes.navigationControlsMobile)}>
        <IconButton
          size="small"
          className={classes.arrowBack}
          onClick={goBackHandler}
        >
          <ScenesArrowBack />
        </IconButton>
      </Wrapper>
      <Wrapper className={classes.wrapper}>
        <Typography className={classNames(classes.title, isMobile && classes.titleMobile)}>
          {`${I18n.t('my_devices.security_options')}`}
        </Typography>
        <Wrapper className={classes.cardWrapper}>
          <Wrapper className={classes.cardLeftSideWrapper}>
            <Typography className={classes.cardTitle}>
              {`${I18n.t('my_devices.trigger_security_alarm')}`}
            </Typography>
          </Wrapper>
          <IOSSwitch
            checked={switchState}
            onChange={handleSwitch}
          />
        </Wrapper>
      </Wrapper>
      <Wrapper className={classes.wrapper}>
        <Typography className={classNames(classes.title, isMobile && classes.titleMobile, !switchState && classes.disabled)}>
          {`${I18n.t('my_devices.additional_modes')}`}
        </Typography>
        <Wrapper
          className={classNames(classes.optionCard, !switchState && classes.disabledCursor)}
          onClick={selectOption}
        >
          <Wrapper>
            <Typography className={classNames(classes.optionCardTitle, !switchState && classes.disabled)}>
              {`${I18n.t(`my_devices.${zwaveSecurityOptions[currentOption].name}`)}`}
            </Typography>
            <Typography className={classNames(classes.optionCardDescription, !switchState && classes.disabled)}>
              {`${I18n.t(`my_devices.${zwaveSecurityOptions[currentOption].description}`)}`}
            </Typography>
          </Wrapper>
          <ChevronLeft
            className={classNames(classes.chevronIcon, anchorEl && classes.chevronIconOpened, !switchState && classes.disabled)}
          />
        </Wrapper>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={closeSelect}
          anchorOrigin={{
            vertical: 80,
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List className={classNames(classes.optionList, isMobile && classes.optionListMobile)}>
            {zwaveSecurityOptions.map((option, index) => (
              <ListItem
                key={`option-${index + 1}`}
                className={classes.optionItem}
                onClick={() => securityOptionClick(index)}
              >
                <Typography className={classes.optionTitle}>{`${I18n.t(`my_devices.${option.name}`)}`}</Typography>
                <Typography className={classes.optionDescription}>{`${I18n.t(`my_devices.${option.description}`)}`}</Typography>
              </ListItem>
            ))}
          </List>
        </Popover>
      </Wrapper>
      <Button
        variant="outlined"
        color="primary"
        className={classes.saveButton}
        disabled={currentDevice.security_option?.enable === switchState
        && currentDevice.security_option?.option === zwaveSecurityOptions[currentOption].option}
        onClick={updateSecurityOption}
      >
        {I18n.t('buttons.save')}
      </Button>
    </Wrapper>
  )
}

export default ZwaveSecurityOptions
