import { createLogic } from 'redux-logic'
import { TRIGGER_SCENE_ACTIONS } from '@app/constants/scenes/scenesList'
import Api from '@app/api/scenes/triggerSceneActions'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'

const triggerSceneActionsLogic = createLogic({
  type: TRIGGER_SCENE_ACTIONS,
  cancelType: API_REQUEST_CANCEL,

  async process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(TRIGGER_SCENE_ACTIONS))
    try {
      await Api.triggerSceneActions(token, action.id)
      dispatch(apiRequestDone(TRIGGER_SCENE_ACTIONS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('TRIGGER_SCENE_ACTIONS failed without error')
      }
    }
    done()
  },
})

export default triggerSceneActionsLogic
