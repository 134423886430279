import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  title: {
    fontSize: '18px',
    marginBottom: 11,
    padding: '0 16px',
  },
  devicesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  listItem: {
    width: 120,
    height: 120,
    padding: 0,
  },
  empty: {
    backgroundColor: '#E1E1E1',
    color: '#141414',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    padding: '9px 20px',
    fontSize: '16px',
  },
}))

export default useStyles
