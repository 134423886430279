import {
  VALIDATION_REG_EXP_EMAIL,
  VALIDATION_REG_EXP_FIRST_NAME,
  VALIDATION_REG_EXP_LAST_NAME,
  VALIDATION_REG_EXP_MOBILE,
  VALIDATION_REG_EXP_PREFERRED_NAME,
} from '@app/lib/Users/userEditvalidationRegExps'
import { VALIDATION_REG_EXP_PASSWORD } from '@app/lib/Wizard/forgotPasswordValidationRegExps'

export const validate = (values) => {
  const errors = {}
  const firstName = 'first_name'
  const lastName = 'last_name'
  const preferredName = 'preferred_name'
  const phoneNumber = 'phone_number'
  const email = 'email'
  const password = 'password'
  const passwordConfirmation = 'confirm_password'

  const requiredFields = [firstName, lastName, preferredName, phoneNumber, email, password, passwordConfirmation]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })
  if (values[firstName] && !VALIDATION_REG_EXP_FIRST_NAME.test(values[firstName])) {
    errors[firstName] = I18n.t('my_users.first_name_validation')
  }
  if (values[lastName] && !VALIDATION_REG_EXP_LAST_NAME.test(values[lastName])) {
    errors[lastName] = I18n.t('my_users.last_name_validation')
  }
  if (values[preferredName] && !VALIDATION_REG_EXP_PREFERRED_NAME.test(values[preferredName])) {
    errors[preferredName] = I18n.t('my_users.preferred_name_validation')
  }
  if (values[phoneNumber] && !VALIDATION_REG_EXP_MOBILE.test(values[phoneNumber])) {
    errors[phoneNumber] = I18n.t('my_users.phone_number_validation')
  }
  if (values[email] && !VALIDATION_REG_EXP_EMAIL.test(values[email])) {
    errors[email] = I18n.t('my_users.email_validation')
  }
  if (values[password] && !VALIDATION_REG_EXP_PASSWORD.test(values[password])) {
    errors[password] = I18n.t('my_users.user_code_validation')
  }
  if (values[passwordConfirmation] && !VALIDATION_REG_EXP_PASSWORD.test(values[passwordConfirmation])) {
    errors[passwordConfirmation] = I18n.t('my_users.user_code_validation')
  }
  if (values[password] !== values[passwordConfirmation]) {
    errors[passwordConfirmation] = I18n.t('my_users.user_codes_match')
  }
  return errors
}
