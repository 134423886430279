import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_PILLS_REMINDER } from '@app/constants/pillsReminders/pillsReminders'
import Api from '@app/api/pillsReminders/deletePillsReminder'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const deletePillsReminder = createLogic({
  type: DELETE_PILLS_REMINDER,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(DELETE_PILLS_REMINDER))
    try {
      await Api.deletePillsReminder(action.accessToken, action.id)
      dispatch(apiRequestDone(DELETE_PILLS_REMINDER))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('deletePillsReminder failed without error')
      }
    }
    done()
  },
})

export default deletePillsReminder
