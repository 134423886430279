import * as type from '@app/constants/notifications/notificationsChannel'
import * as NotificationChannelAction from '@app/actions/notifications/notificationsChannel'
import { InferValueTypes } from '@app/@types/typesHelper'
import { INotification } from '@app/@types/notifications'
import { Optional } from '@app/@types/common'

type NotificationChannelActions = ReturnType<InferValueTypes<typeof NotificationChannelAction>>

export interface INotificationsChannelState {
  isChannelOpen: boolean,
  notification: Optional<INotification>,
  progress: boolean,
}

const initialState = {
  isChannelOpen: false,
  notification: null,
  progress: false,
}

export default function notificationsChannel(state: INotificationsChannelState = initialState, action: NotificationChannelActions) {
  switch (action.type) {
  case type.OPEN_NOTIFICATION_CHANNEL:
    return { ...state, isChannelOpen: true }

  case type.CLOSE_NOTIFICATION_CHANNEL:
    return { ...state, isChannelOpen: false }

  case type.UPDATE_NOTIFICATIONS_CHANNEL:
    return { ...state, notification: action.data }

  case type.CLEAR_NOTIFICATIONS_CHANNEL:
    return { ...state, notification: null }

  default:
    return state
  }
}
