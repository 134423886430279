import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 87.7498C6.99998 89.5448 8.4551 91 10.2501 91C12.0451 91 13.5002 89.5449 13.5002 87.7499L13.5002 82L22.0002 76.9593V114H12.0004C9.23896 114 7.00039 116.239 7.00036 119L7.00023 136.25C7.00022 138.045 8.45529 139.5 10.2502 139.5C12.0451 139.5 13.5002 138.045 13.5002 136.25L13.5002 120H45.5003V101H77.5004V81.6363H109.5L109.5 62.3724L140.476 62.3724C142.236 62.3723 143.663 60.9459 143.663 59.1862C143.663 57.4265 142.236 56 140.476 56L125 56V16.1752L138.701 16.1752C140.406 16.1752 141.788 14.7928 141.788 13.0876C141.788 11.3824 140.406 10 138.701 10H122.312C121.774 10 121.24 10.0866 120.73 10.2566L120 10.5L9.00027 77.5C7.77445 78.1129 7.00012 79.3657 7.0001 80.7362L7 87.7498ZM118 20.0296L93.0002 34.8551V75.5H103.5L103.5 59.5C103.5 57.567 105.067 56 107 56L118 56V20.0296ZM86.0002 39.0062V75.5H74.7063C72.6344 75.5883 71.0001 77.2938 71.0001 79.3676L71.0001 95L61.0002 95V53.8316L86.0002 39.0062ZM54.0002 57.9828L29.0002 72.8082V114H39.0002V98.5414C39.0002 96.7863 40.269 95.2885 42.0002 95L54.0002 95V57.9828Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Stairs')

export default icon
