import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './CustomTextInfo.style'
import Wrapper from '../Wrapper/Wrapper'

class CustomTextInfo extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      title,
      warningText,
      buttonName,
      onClick,
      disableButton,
    } = this.props
    return (
      <Dialog
        classes={{
          paper: classes.customPaper,
        }}
        open={open}
      >
        <DialogTitle
          className={classes.title}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="body1"
            className={classes.text}
          >
            {warningText}
          </DialogContentText>
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={onClick}
            disabled={disableButton}
          >
            {I18n.t(`${buttonName}`)}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

CustomTextInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  warningText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  disableButton: PropTypes.bool,
}

CustomTextInfo.defaultProps = {
  disableButton: false,
}

export default withStyles(styles)(CustomTextInfo)
