const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 106px)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    minHeight: 400,
  },
  listItem: {
    width: 132,
    height: 132,
  },
  currentSwitch: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E1E1E1',
    marginBottom: 36,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 100,
    width: 100,
    cursor: 'pointer',
    padding: '15px 5px 0px 5px',
  },
  switchTitle: {
    width: '90%',
    fontSize: '12px',
    marginBottom: 11,
    textAlign: 'center',
    padding: '0 16px',
    textWrap: 'wrap',
    overflow: 'hidden',
    color: '#141414',
  },
})

export default styles
