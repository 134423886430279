export const SYNC_CONTACTS = '@@contacts/syncContacts'
export const SET_CONTACTS = '@@contacts/setContacts'

export const POST_CONTACT = '@@contacts/postContact'
export const SET_POST_CONTACT = '@@contacts/setPostContact'

export const PATCH_CONTACT = '@@contacts/patchContact'
export const SET_PATCH_CONTACT = '@@contacts/setPatchContact'

export const DELETE_CONTACT = '@@contacts/deleteContact'
export const SET_DELETE_CONTACT = '@@contacts/setDeleteContact'

export const UPDATE_ALERT_MESSAGE = '@@contacts/updateAlertMessage'
export const SET_ALERT_MESSAGE = '@@contacts/setAlertMessage'

export const UPDATE_AVATAR = '@@contacts/updateAvatar'
export const SET_AVATAR = '@@contacts/setAvatar'

export const SET_FORM_DATA = '@@contacts/setFormData'
export const SET_FORM_INITIAL_VALUES = '@@contacts/setFormInitialValues'
export const SET_IS_CONTACTS_EMPTY = '@@contacts/setIsContactsEmpty'

export const TOGGLE_SUMMARY_POPUP = '@@contacts/toggleSummaryPopup'
export const TOGGLE_FORM_POPUP = '@@contacts/toggleFormPopup'
export const TOGGLE_AVATAR_POPUP = '@@contacts/toggleAvatarPopup'
export const CLOSE_POPUPS = '@@contacts/closePopups'
