const convertMultilevelSensorDataToBackend = (
  conditionDeviceNumber,
  sensorType,
  sensorValue,
  sensorScale,
  sensorPrecision,
  sensorOperand,
) => ({
  number: conditionDeviceNumber,
  type: sensorType,
  value: sensorValue,
  scale: sensorScale,
  precision: sensorPrecision,
  operand: sensorOperand,
})
export default convertMultilevelSensorDataToBackend
