import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_TWR_DEVICE_INFO } from '@app/constants/myDevices/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { startFetchingDeviceStats, endFetchingDeviceStats } from '@app/actions/myDevices/deviceInfo/fetchTwrDeviceInfo'
import Api from '@app/api/myDevices/deviceInfo/fetchDeviceInfo'

const fetchTwrDeviceInfo = createLogic({
  type: FETCH_TWR_DEVICE_INFO,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { myDevices: { myDevices: { currentDevice } } } = getState()
    next({
      ...action,
      currentDevice,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_TWR_DEVICE_INFO))
    dispatch(startFetchingDeviceStats())
    try {
      await Api.fetchTwrDeviceInfo(action.accessToken, action.id)
      dispatch(apiRequestDone(FETCH_TWR_DEVICE_INFO))
      if (action.currentDevice.info) {
        dispatch(endFetchingDeviceStats())
      }
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('FETCH_TWR_DEVICE_INFO failed without error')
      }
    }
    done()
  },
})

export default fetchTwrDeviceInfo
