import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  textFieldWrapper: {
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 20,
  },
  typeMenuItem: {
    backgroundColor: theme.backgrounds.cardBackground,
  },
  formControl: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    marginBottom: 20,
  },
  urlField: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '3%',
    paddingRight: '3%',
    width: '100%',
  },
  keyValueWrapper: {
    display: 'flex',
  },
  keyWrapper: {
    marginRight: 10,
    paddingLeft: '3%',
  },
  valueWrapper: {
    paddingLeft: '3%',
  },
  dataTitle: {
    marginBottom: 10,
  },
  halfInput: {
    padding: '10px 0',
    width: '100%',
  },
  urlSelect: {
    fontSize: '14px',
  },
  urlInput: {
    padding: '0 5%',
  },
}))

export default useStyles
