import React from 'react'
import PropTypes from 'prop-types'
import Loading from '@app/components/Common/Loading/Loading'
import ServerError from '@app/components/Common/ServerError/ServerError'
import { isIOS } from 'react-device-detect'

const withProgressAndErrorMessage = (WrappedComponent) => {
  class ProgressAndErrorMessageAble extends React.Component {
    render() {
      const {
        inProgress,
        errorMessage,
        tryAgain,
        isAddingLoadingBarFlag,
      } = this.props
      return (
        <>
          <WrappedComponent {...this.props} />
          { !isIOS && (<Loading open={inProgress} isAddingDevice={isAddingLoadingBarFlag} />) }
          <ServerError message={errorMessage} tryAgain={tryAgain} />
        </>
      )
    }
  }

  ProgressAndErrorMessageAble.propTypes = {
    inProgress: PropTypes.bool,
    errorMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    tryAgain: PropTypes.func,
    isAddingLoadingBarFlag: PropTypes.bool,
  }

  ProgressAndErrorMessageAble.defaultProps = {
    inProgress: true,
    errorMessage: null,
    tryAgain: null,
    isAddingLoadingBarFlag: false,
  }

  return ProgressAndErrorMessageAble
}

export default withProgressAndErrorMessage
