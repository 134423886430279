import { API_URL } from '@app/constants/endpoints'

const convertToIso = (date) => new Date(date).toISOString()
const correctToDate = (date) => {
  const convertedDate = new Date(date)
  convertedDate.setHours(23, 59, 59, 999)
  return convertedDate.toISOString()
}
const checkForAllType = (type) => (type === 'all' ? '' : type)

const getParams = (type, from, to, text, currentPage) => {
  const paramsArr = []
  const checkedTypes = checkForAllType(type)
  if (currentPage) {
    paramsArr.push(`page=${currentPage}`)
  }
  if (from && to) {
    paramsArr.push(`from=${convertToIso(from)}&to=${correctToDate(to)}`)
  }
  if (text) {
    paramsArr.push(`message=${encodeURIComponent(text)}`)
  }
  if (checkedTypes) {
    paramsArr.push(`type=${checkedTypes}`)
  }
  return paramsArr.join('&')
}

export const getEndpointForEvents = (type, from, to, text, currentPage) => {
  const endpoint = `${API_URL}/api/v3/events`
  const params = getParams(type, from, to, text, currentPage)
  return params ? `${endpoint}?${params}` : endpoint
}
