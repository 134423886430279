import React from 'react'
import createSvgIcon from './../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
      <path stroke="currentColor" strokeWidth="1.5" d="M4.986 17c9.771-3.739 19.515-7.477 29.286-11.216-.368-.963-.736-1.954-1.104-2.917a2.89 2.89 0 0 0-3.739-1.671c-8.865 3.398-17.73 6.797-26.567 10.196a2.89 2.89 0 0 0-1.671 3.739c.028.056.056.141.085.198.538 1.473 2.209 2.238 3.71 1.671z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M28.72 1.446l.68-.255c.482-.17.907-.226 1.417-.17-1.076 2.436-2.181 4.872-3.257 7.336-.765.283-1.53.595-2.323.878 1.161-2.606 2.323-5.211 3.484-7.789zM33.48 3.655c-.114-.283-.2-.538-.312-.821-.17-.425-.397-.793-.737-1.105l-2.549 5.693c.765-.283 1.53-.595 2.323-.878.396-.963.821-1.926 1.274-2.889zM2.692 16.882c.595.284 1.161.368 1.813.227 1.133-2.578 2.294-5.155 3.427-7.732-.765.283-1.53.594-2.323.878-.963 2.237-1.954 4.446-2.917 6.627zM24.104 3.202l2.294-.878-3.455 7.789-2.323.878 3.484-7.789zM19.488 4.987l2.294-.907-3.456 7.79-2.322.877 3.484-7.76zM14.87 6.742l2.295-.878-3.455 7.79-2.323.877 3.484-7.789zM10.254 8.527l2.294-.906-3.455 7.788-2.323.879 3.484-7.761z" clipRule="evenodd"/>
      <path stroke="currentColor" strokeWidth="1.5" d="M1.729 24.586h34.27"/>
      <path stroke="currentColor" strokeWidth="1.5" d="M4.646 18.553H36v20.761a2.883 2.883 0 0 1-2.889 2.89H4.646a2.883 2.883 0 0 1-2.889-2.89V21.442c-.028-1.586 1.275-2.889 2.89-2.889z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M9.263 24.586H6.799l-4.645-4.645c.34-.538.736-.906 1.33-1.161 1.927 1.954 3.853 3.88 5.779 5.806zM36 21.64v2.465l-5.551-5.552h2.464L36 21.641zM31.525 24.586h2.464l-6.004-6.033h-2.493l6.033 6.033zM26.568 24.586h2.493l-6.033-6.033h-2.464l6.004 6.033zM21.64 24.586h2.464l-6.033-6.033h-2.464l6.033 6.033zM16.684 24.586h2.464l-6.005-6.033h-2.492l6.033 6.033zM11.727 24.586h2.492l-6.032-6.033H5.722l6.005 6.033z" clipRule="evenodd"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'AnotherSceneActionMobile', '0 0 80 80', {fontSize: 35})

export default icon
