import * as type from '@app/constants/shadowIn'

export const shadowIn = (accessToken, path) => ({
  type: type.SHADOW_IN_USER,
  accessToken,
  path,
})

export const savePath = (path) => ({
  type: type.SAVE_SHADOW_IN_PATH,
  path,
})
