import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SettingsType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/Notifications/SettingsType/SettingsType'
import SettingsTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/Notifications/SettingsType/SettingsType'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

let enhance = isIOS ? SettingsTypeIOS : SettingsType

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  return {
    isMobile,
    path,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SettingsTypeEnhanced = enhance

export default withRouter(SettingsTypeEnhanced)
