import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchGroups = (access_token) => Axios.get(`${API_URL}/api/groups`, {
  headers: {
    'Access-Token': access_token,
  },
})

const fetchDevices = (access_token) => Axios.get(`${API_URL}/api/groups/index_devices_without_groups`, {
  headers: {
    'Access-Token': access_token,
  },
})

const postGroup = (access_token, data) => Axios.post(`${API_URL}/api/groups`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchGroup = (access_token, data, id) => Axios.patch(`${API_URL}/api/groups/${id}`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteGroup = (access_token, id) => Axios.delete(`${API_URL}/api/groups/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const groupAddDeviceTwr = (access_token, deviceId, group_id) => Axios.patch(`${API_URL}/api/set_device_association/twr_device/${deviceId}`,
  { group_id }, {
    headers: {
      'Access-Token': access_token,
    },
  })

const groupAddDeviceZwave = (access_token, deviceId, group_id) => Axios.patch(
  `${API_URL}/api/set_device_association/zwave_device/${deviceId}`,
  { group_id }, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const groupDeleteDeviceTwr = (access_token, deviceId) => Axios.patch(`${API_URL}/api/set_device_association/twr_device/${deviceId}`,
  { group_id: 0 }, {
    headers: {
      'Access-Token': access_token,
    },
  })

const groupDeleteDeviceZwave = (access_token, deviceId) => Axios.patch(`${API_URL}/api/set_device_association/zwave_device/${deviceId}`,
  { group_id: 0 }, {
    headers: {
      'Access-Token': access_token,
    },
  })

export default {
  fetchGroups,
  fetchDevices,
  postGroup,
  patchGroup,
  deleteGroup,
  groupAddDeviceTwr,
  groupAddDeviceZwave,
  groupDeleteDeviceTwr,
  groupDeleteDeviceZwave,
}
