import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ITwrDeviceBackend, IZwaveDeviceBackend } from '@app/@types/scenes/scenesDevices'
import Device from '../DevicesList/Device'
import useStyles from './Group.style'

interface Props {
  name: string,
  devices: Array<ITwrDeviceBackend> | Array<IZwaveDeviceBackend>,
  deviceType: string,
}

const Group: React.FC<Props> = (props) => {
  const {
    name,
    devices,
    deviceType,
  } = props

  const classes = useStyles()

  return (
    <Wrapper>
      <Typography className={classes.title}>{name}</Typography>
      <List className={classes.devicesWrapper}>
        {devices.length
          ? devices.map((device) => (
            <ListItem className={classes.listItem} key={device.id}>
              <Device
                device={device}
                deviceType={deviceType}
              />
            </ListItem>
          )) : <Typography className={classes.empty} align="center">NO DEVICES</Typography>}
      </List>
    </Wrapper>
  )
}

export default Group
