export const contactsFields = [
  {
    type: 'text', title: 'preferred_name', value: '', hint: '', autocomplete: 'nope', required: true,
  },
  {
    type: 'number', title: 'phone_number', value: '', hint: '', autocomplete: 'nope', required: true,
  },
  {
    type: 'email', title: 'email', value: '', hint: '', autocomplete: 'nope', required: false,
  },
  {
    type: 'text', title: 'address', value: '', hint: '', autocomplete: 'nope', required: false,
  },
]
