import React from 'react'
import { Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import renderSensorIcon from '@app/helpers/myDevices/renderSensorIcon'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { readableElapsedTime } from '@app/helpers/myDevices/readableElapsedTime'
import useStyles from './DeviceStats.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
}

const ZwaveStats: React.FC<Props> = (props) => {
  const {
    currentDevice,
  } = props
  const classes = useStyles()

  const renderColorState = (colorState: Record<string, number>) => {
    if (colorState) {
      if (colorState.white_warm && colorState.white_warm !== 0) {
        return <Typography>{`White warm ${colorState.white_warm}%`}</Typography>
      }
      if (colorState.white_cold && colorState.white_cold !== 0) {
        return <Typography>{`White cold ${colorState.white_cold}%`}</Typography>
      }
      return (
        <div
          className={classes.colorChannelsStatus}
          style={{ backgroundColor: `rgba(${colorState.red},${colorState.green},${colorState.blue}, 1` }}
        />
      )
    }
    return null
  }

  const renderBatteryStatus = () => {
    if (currentDevice.info && typeof currentDevice.info.zwave_battery.status === 'number') {
      return `${currentDevice.info.zwave_battery.status} %`
    }
    if (currentDevice.info && typeof currentDevice.info.zwave_battery.status === 'string') {
      return `${currentDevice.info.zwave_battery.status}`
    }
    return `${I18n.t('my_devices.not_available')}`
  }

  if (currentDevice.info) {
    return (
      <Wrapper className={classes.grid}>
        {currentDevice.info.zwave_battery && (
          <Wrapper className={classes.gridElement}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon('battery')}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>{I18n.t('my_status.battery_level')}</Typography>
              <Typography className={classes.status}>
                {renderBatteryStatus()}
              </Typography>
              <Typography className={classes.timestamp}>
                {`Last update: ${currentDevice.info.zwave_battery.update
                  ? readableElapsedTime(currentDevice.info.zwave_battery.update)
                  : `${I18n.t('my_devices.not_available')}`}`}
              </Typography>
            </Wrapper>
          </Wrapper>
        )}
        {currentDevice.info.consumings.map((consuming) => (
          <Wrapper className={classes.gridElement} key={consuming.scale}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon('energy')}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>{I18n.t('my_devices.energy_data')}</Typography>
              <Typography className={classes.status}>
                {(typeof consuming.value === 'number')
                  ? `${consuming.value} ${consuming.scale}`
                  : `${I18n.t('my_devices.not_available')}`}
              </Typography>
              <Typography className={classes.timestamp}>
                {`Last update: ${consuming.update
                  ? readableElapsedTime(consuming.update)
                  : `${I18n.t('my_devices.not_available')}`}`}
              </Typography>
            </Wrapper>
          </Wrapper>
        ))}
        {currentDevice.info.notifications.map((notificationSensor) => (
          <Wrapper className={classes.gridElement} key={notificationSensor.id}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon('notification_sensor')}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>
                {`${notificationSensor.notification_type_name} - ${notificationSensor.event_name}`}
              </Typography>
              <Typography className={classes.status}>
                {notificationSensor.last_event_timestamp
                  ? `Detected, ${readableElapsedTime(notificationSensor.last_event_timestamp)}`
                  : `${I18n.t('my_devices.not_available')}`}
              </Typography>
            </Wrapper>
          </Wrapper>
        ))}
        {currentDevice.info.zwave_sensors.map((sensor) => (
          <Wrapper className={classes.gridElement} key={sensor.id}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon(sensor.type)}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>{sensor.title}</Typography>
              <Typography className={classes.status}>
                {sensor.status === null ? `${I18n.t('my_devices.not_available')}` : `${sensor.status} ${sensor.short_title}`}
              </Typography>
              <Typography className={classes.timestamp}>
                {`Last update: ${sensor.update
                  ? readableElapsedTime(sensor.update)
                  : `${I18n.t('my_devices.not_available')}`}`}
              </Typography>
            </Wrapper>
          </Wrapper>
        ))}
        {currentDevice.info.color_state && (
          <Wrapper className={classes.gridElement}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon('color_channels')}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>{I18n.t('my_devices.device_colour')}</Typography>
              {renderColorState(currentDevice.info.color_state.value)}
            </Wrapper>
          </Wrapper>
        )}
      </Wrapper>
    )
  }
  return null
}

export default ZwaveStats
