import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CELLULAR_MODULE } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import patchCellularModule from '@app/api/cloudFirmwareUpdate/patchCellularModule'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchCellularModuleLogic = createLogic({
  type: SYNC_CELLULAR_MODULE,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { cloudFirmwareUpdate: { cloudFirmwareUpdateInfo: { cellularModule } } } = store.getState()
    const cellularData = { cellular_module: cellularModule }
    dispatch(apiRequestSent(SYNC_CELLULAR_MODULE))
    patchCellularModule(token, cellularData)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(apiRequestDone(SYNC_CELLULAR_MODULE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchCellularModule failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchCellularModuleLogic
