const getDelayDurationDescription = (counterInfo) => {
  const {
    day, hour, minute, second,
  } = counterInfo
  const currentDays = day ? `${day}d ` : ''
  const currentHours = hour ? `0${hour}h `.slice(-4) : ''
  const currentMinutes = minute ? `0${minute}m `.slice(-4) : ''
  const currentSeconds = second ? `0${second}s `.slice(-4) : ''

  return `${currentDays}${currentHours}${currentMinutes}${currentSeconds}`.slice(0, -1)
}

export default getDelayDurationDescription
