import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5118 13.5662C13.9807 12.7697 13.7 11.8426 13.7 10.8852V8.16667C13.7 7.051 13.2648 6.00283 12.4745 5.21527C11.8039 4.547 10.9465 4.1353 10.0204 4.0282V3H9.02035V4.032C6.96502 4.28847 5.36665 6.0652 5.36665 8.20647V10.8852C5.36665 11.8426 5.08595 12.7697 4.55488 13.5663L3.59908 15H8.11932C8.32572 15.5819 8.88148 16 9.53332 16C10.1852 16 10.7409 15.5819 10.9473 15H15.4676L14.5118 13.5662ZM5.46545 14C6.05548 13.0663 6.36665 11.9925 6.36665 10.8852V8.20643C6.36665 6.4444 7.78218 5.006 9.52208 5C9.52585 5 9.52958 5 9.53335 5C10.3772 5 11.1707 5.32777 11.7686 5.9236C12.3692 6.52213 12.7 7.31873 12.7 8.16667V10.8852C12.7 11.9926 13.0111 13.0663 13.6012 14H5.46545Z" fill="currentColor"/>
      <path d="M16.651 6.0823L15.9439 6.7894C16.6679 7.51343 17.0667 8.47606 17.0667 9.5C17.0667 10.5015 16.6829 11.4489 15.986 12.1678L16.7041 12.8638C17.5827 11.9573 18.0667 10.7627 18.0667 9.5C18.0667 8.20896 17.5639 6.9952 16.651 6.0823Z" fill="currentColor"/>
      <path d="M15.2368 7.49653L14.5297 8.20366C14.876 8.5499 15.0667 9.01026 15.0667 9.5C15.0667 9.9821 14.8811 10.4373 14.544 10.7819L15.2588 11.4812C15.7798 10.9486 16.0667 10.245 16.0667 9.5C16.0667 8.74316 15.7719 8.03166 15.2368 7.49653Z" fill="currentColor"/>
      <path d="M3.12277 6.7894L2.41567 6.0823C1.50277 6.9952 1 8.20896 1 9.5C1 10.7627 1.48393 11.9573 2.3626 12.8638L3.08063 12.1678C2.3838 11.4489 2 10.5015 2 9.5C2 8.47606 2.39873 7.51343 3.12277 6.7894Z" fill="currentColor"/>
      <path d="M4.53697 8.20362L3.82987 7.49649C3.29473 8.03162 3 8.74316 3 9.49999C3 10.245 3.28687 10.9486 3.8078 11.4812L4.52263 10.7819C4.1856 10.4373 4 9.9821 4 9.49999C4 9.01029 4.1907 8.54989 4.53697 8.20362Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'NotificationSettingsIcon')

export default icon
