import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import { Button, Paper, Typography } from '@mui/material'
import getConditionDescription from '@app/helpers/scenes/getConditionDescription'
import getRedirectForConditionStateChangeButton from '@app/helpers/scenes/getRedirectForConditionStateChangeButton'
import getConditionStateDescription from '@app/helpers/scenes/getConditionStateDescription'
import { sceneConditionSelected, sceneStateSelected } from '@app/lib/Scenes/conditions'
import { SCENE_REVIEW, SCENE_REVIEW_EDIT, SELECT_CONDITION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import { IConditionFromBackend, IConditionFront } from '@app/@types/scenes/scenes'
import {
  activateChangeConditionMode,
  activateChangeConditionStateMode,
  deactivateChangeConditionMode,
  deactivateChangeConditionStateMode,
  patchCondition,
  saveInitialConditionInfo,
  saveScreenName,
} from '@app/actions/scenes/currentSceneCreating'
import useStyles from './EditCondition.style'

const defaultProps = {
  buttonName: 'submit',
}
interface Props {
  isMobile: boolean,
  buttonName: string,
  sceneMode: string,
  currentCondition: IConditionFront,
  editedConditions: Array<IConditionFromBackend>,
}
const EditCondition: React.FC<Props> = (props) => {
  const {
    buttonName = defaultProps.buttonName,
    currentCondition,
    isMobile,
    sceneMode,
    editedConditions,
  } = props
  const {
    type,
    conditionDeviceName,
    description,
    conditionNumber,
    stateDescription,
    timeOperand,
    usersInfo,
    dataNumber,
    triggeringDevice: {
      deviceState,
      deviceName,
    },
  } = currentCondition
  const [isSubmitDisabled, changeSubmitDisabled] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  // @ts-ignore
  const usersNames = Object.entries(usersInfo).length === 0 ? [] : usersInfo.usersNames.join(', ')

  useEffect(() => {
    dispatch(deactivateChangeConditionMode())
    dispatch(deactivateChangeConditionStateMode())
    dispatch(saveInitialConditionInfo())
  }, [])

  const selectConditionType = () => {
    dispatch(activateChangeConditionMode())
    navigate(SELECT_CONDITION_TYPE)
  }

  const selectConditionState = () => {
    dispatch(activateChangeConditionStateMode())
    const redirectUrl = getRedirectForConditionStateChangeButton(type, editedConditions)
    navigate(redirectUrl)
  }

  const onSubmitClick = () => {
    if (sceneMode === 'editMezzoScene' || sceneMode === 'editDraftScene') {
      dispatch(saveScreenName(SCENE_REVIEW_EDIT))
    } else {
      dispatch(saveScreenName(SCENE_REVIEW))
    }
    changeSubmitDisabled(true)
    dispatch(patchCondition())
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.edit_condition')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        <CardBlock
          key={sceneConditionSelected.title}
          title={I18n.t(`scene_web.${sceneConditionSelected.title}`)}
          description={getConditionDescription(type, description, conditionDeviceName, conditionNumber)}
          position={sceneConditionSelected.position}
          buttonName={I18n.t('scene_web.change')}
          isCardDisabled={sceneConditionSelected.isCardDisabled}
          isButtonDisabled={sceneConditionSelected.isButtonDisabled}
          onClick={() => {
            selectConditionType()
          }}
        />
        <CardBlock
          key={sceneStateSelected.title}
          title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
          hasAdditionalDesc={type === 'ZwaveColor'}
          descColor={stateDescription}
          description={getConditionStateDescription(
            type, stateDescription, timeOperand, usersNames, dataNumber, deviceState, deviceName,
          )}
          position={stateDescription === '' ? 'noneDescr' : 'bottom'}
          buttonName={I18n.t('scene_web.change')}
          isCardDisabled={stateDescription === ''}
          isButtonDisabled={false}
          onClick={() => {
            selectConditionState()
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubmitClick}
          disabled={isSubmitDisabled}
        >
          {I18n.t(`buttons.${buttonName}`)}
        </Button>
      </Wrapper>
    </Paper>
  )
}

export default EditCondition
