const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    display: 'flex',
    width: '90%',
    padding: '25px 0',
    justifyContent: 'center',
  },
  slider: {
    width: '85%',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.backgrounds.cardBackground,
    borderRadius: 10,
    width: '100%',
    maxWidth: 500,
    minHeight: 130,
    padding: '15px 20px',
    boxSizing: 'border-box',
  },
  switchContainerMobile: {
    height: 100,
  },
  containerMisc: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  addButton: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  miscTitle: {
    padding: 5,
    fontSize: 18,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
