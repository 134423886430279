import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 90 112" enableBackground="new 0 0 100 100" ><g><path d="M96.7,64.3L84,52.6V21.8c0-2.8-2.1-4.8-5.1-4.8h-3.4h-6H69c0-2.1-1.9-4-4-4H35c-2.1,0-4,1.9-4,4h-0.4h-6h-3.4   c-3,0-5.1,2-5.1,4.8v30.7L3.3,64.3C3.2,64.4,2,65.7,2,68c0,2.2,1.8,4,4,4h88c2.2,0,4-1.8,4-4C98,65.7,96.8,64.4,96.7,64.3z    M82,21.8V52h-2V21.8c0-1.1-0.3-2-0.8-2.8C80.9,19.2,82,20.2,82,21.8z M78,21.8V52h-5V21.8c0-1.1-0.2-2.1-0.6-2.8h3   C77,19,78,20.1,78,21.8z M69.4,19c1.1,0,1.6,1.4,1.6,2.8V52H60V19H69.4z M42,19h16v33H42V19z M35,15h30c1,0,2,1,2,2h-7H40h-7   C33,16,34,15,35,15z M40,19v33H29V21.8c0-1.4,0.5-2.8,1.6-2.8H40z M27.6,19c-0.4,0.8-0.6,1.7-0.6,2.8V52h-5V21.8   c0-1.7,1-2.8,2.6-2.8H27.6z M18,21.8c0-1.6,1.1-2.7,2.8-2.8c-0.5,0.8-0.8,1.7-0.8,2.8V52h-2V21.8z M94,70H6c-1.1,0-2-0.9-2-2   c0-1.5,0.7-2.3,0.7-2.3L17.4,54h65.2l12.7,11.7c0.1,0.1,0.7,0.9,0.7,2.3C96,69.1,95.1,70,94,70z"/><path d="M57,75c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1s1-0.4,1-1V76C58,75.4,57.6,75,57,75z"/><path d="M43,75c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1s1-0.4,1-1V76C44,75.4,43.6,75,43,75z"/><path d="M71,75c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1s1-0.4,1-1V76C72,75.4,71.6,75,71,75z"/><path d="M85,75c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1s1-0.4,1-1V76C86,75.4,85.6,75,85,75z"/><path d="M15,75c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1s1-0.4,1-1V76C16,75.4,15.6,75,15,75z"/><path d="M29,75c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1s1-0.4,1-1V76C30,75.4,29.6,75,29,75z"/></g></svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Downlight')

export default icon
