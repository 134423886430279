import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 36">
      <path stroke="currentColor" d="M23.438 31.881a14.296 14.296 0 0 0 8.685-9.157m-19.197 8.822a14.296 14.296 0 0 1-8.685-9.157M12.926 4.24a14.296 14.296 0 0 0-8.685 9.157M23.685 4.24a14.296 14.296 0 0 1 8.686 9.157m.388 7.695a3.24 3.24 0 1 0 0-6.482 3.24 3.24 0 0 0 0 6.482Zm-28.518 0a3.24 3.24 0 1 0 0-6.482 3.24 3.24 0 0 0 0 6.482ZM18.5 35.352a3.24 3.24 0 1 0 0-6.482 3.24 3.24 0 0 0 0 6.482Zm0-27.87A3.24 3.24 0 1 0 18.5 1a3.24 3.24 0 0 0 0 6.481Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'GroupsDeviceIcon', '0 0 80 80', {fontSize: 35})

export default icon
