import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { maxGroupsNumber } from '@app/constants/groups/groups'
import { GROUPS_ADD_DEVICE, GROUPS_SHOW } from '@app/constants/routes'
import {
  COMMIT_TO_MEZZO, COMPLETE, DELETING, IN_PROGRESS, NOT_SAVED,
} from '@app/constants/mezzoAnswers'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import PropTypes from '@app/components/PropTypes'
import {
  Add, Close, InfoIcon, Refresh,
} from 'icons'
import { withStyles } from '@app/components/styles'
import AddGroupPopup from './AddGroupPopup/AddGroupPopup'
import ErrorPopup from './ErrorPopup/ErrorPopup'
import InfoPopup from './InfoPopup/InfoPopup'

class GroupsListFeature extends React.PureComponent {
    state = {
      isErrorPopupOpen: false,
      isInfoPopupOpen: false,
      isWarningPopupOpen: false,
      infoPopupNumber: null,
      deletingDetails: null,
    }

    writeDeletingDetails = (details) => this.setState({ deletingDetails: details })

    openErrorPopupHandler = () => {
      this.setState((prevState) => ({
        isErrorPopupOpen: !prevState.isErrorPopupOpen,
      }))
    }

    openWarningPopupHandler = () => {
      this.setState((prevState) => ({
        isWarningPopupOpen: !prevState.isWarningPopupOpen,
      }))
    }

    renderRoomStatus = (status) => {
      switch (status) {
      case COMMIT_TO_MEZZO: return `(${I18n.t('geofence_web.saving')})`
      case IN_PROGRESS: return `(${I18n.t('geofence_web.saving')})`
      case NOT_SAVED: return `(${I18n.t('geofence_web.not_saved')})`
      case DELETING: return `(${I18n.t('geofence_web.deleting')})`
      case COMPLETE: return ''
      default: return ''
      }
    }

  openInfoPopupHandler = (index) => {
    this.setState((prevState) => (index !== prevState.infoPopupNumber ? {
      isInfoPopupOpen: true,
      infoPopupNumber: index,
    } : {
      isInfoPopupOpen: false,
      infoPopupNumber: null,
    }))
  }

  renderGroupControlsAdmin = (group, index) => {
    const { classes } = this.props
    return group.status === COMPLETE ? (
      <>
        <Link
          underline="hover"
          to={GROUPS_ADD_DEVICE}
          state={{ groupId: group.id }}
        >
          <IconButton size="large">
            <Add className={classes.addGroupButton} />
          </IconButton>
        </Link>
        <IconButton
          onClick={() => this.openInfoPopupHandler(index)}
          className={classes.iconBackground}
          size="large"
        >
          <Typography className={classes.moreInfoButton}>...</Typography>
        </IconButton>
      </>
    ) : (
      <>
        <IconButton
          className={classes.deleteGroupButton}
          onClick={() => {
            this.writeDeletingDetails(group.id)
            this.openWarningPopupHandler()
          }}
          size="large"
        >
          <Close />
        </IconButton>
      </>
    )
  }

  renderGroupControlsNotAdmin = (group) => {
    const { classes } = this.props
    return group.status === COMPLETE && (
      <Link
        underline="hover"
        className={classes.link}
        to={GROUPS_SHOW}
        state={{ group }}
      >
        <InfoIcon
          className={classes.infoIcon}
          size="large"
        />
      </Link>
    )
  }

  render() {
    const {
      classes,
      isMobile,
      groupsList,
      postNewGroup,
      isAddPopupOpen,
      toggleAddPopup,
      deleteGroup,
      syncGroupsList,
      admin,
    } = this.props
    const {
      isErrorPopupOpen,
      isInfoPopupOpen,
      infoPopupNumber,
      isWarningPopupOpen,
      deletingDetails,
    } = this.state
    return (
      <Wrapper className={classes.root}>
        <IconButton
          className={classNames(classes.refreshIcon, isMobile && classes.refreshIconMobile)}
          onClick={syncGroupsList}
          size="large"
        >
          <Refresh />
        </IconButton>
        <Wrapper className={classNames(classes.main, isMobile && classes.mainMobile)}>
          {groupsList.length ? groupsList.map((group, index) => (
            <Wrapper
              className={classNames(
                classes.groupsCard,
                isMobile && classes.cardMobile,
              )}
              key={`${group.name + 1}`}
            >
              {`${group.name} ${group.status ? this.renderRoomStatus(group.status) : ''}`}
              <Wrapper className={classes.cardControls}>
                {admin ? this.renderGroupControlsAdmin(group, index) : this.renderGroupControlsNotAdmin(group)}
              </Wrapper>
              <InfoPopup
                isPopupOpen={isInfoPopupOpen && infoPopupNumber === index}
                closePopup={this.openInfoPopupHandler}
                className={classes.infoPopup}
                group={group}
                groupsList={groupsList}
                openWarning={this.openWarningPopupHandler}
                writeDeletingDetails={this.writeDeletingDetails}
              />
            </Wrapper>
          ))
            : <Typography className={classes.noGroups}>{I18n.t('rooms_and_groups.no_groups')}</Typography>}

          {admin && (
            <IconButton
              aria-label="addGroup"
              className={classes.addButton}
              onClick={() => {
                if (groupsList.length === maxGroupsNumber) {
                  this.openErrorPopupHandler()
                } else {
                  toggleAddPopup()
                }
                this.openInfoPopupHandler()
              }}
              size="large"
            >
              <Add />
            </IconButton>
          )}
        </Wrapper>
        <AddGroupPopup
          open={isAddPopupOpen}
          names={groupsList.map((group) => group.name)}
          close={toggleAddPopup}
          addGroup={postNewGroup}
        />
        <ErrorPopup open={isErrorPopupOpen} close={this.openErrorPopupHandler} />
        <WarningComponent
          open={isWarningPopupOpen}
          leftButton="cancel"
          rightButton="okay"
          warningText="rooms_and_groups.want_to_delete_group"
          onClose={() => {
            deleteGroup(deletingDetails)
            this.openWarningPopupHandler()
          }}
          onClick={this.openWarningPopupHandler}
        />
      </Wrapper>
    )
  }
}

GroupsListFeature.propTypes = {
  classes: PropTypes.object.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  postNewGroup: PropTypes.func.isRequired,
  isAddPopupOpen: PropTypes.bool.isRequired,
  toggleAddPopup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  syncGroupsList: PropTypes.func.isRequired,
  admin: PropTypes.bool.isRequired,
}

GroupsListFeature.displayName = 'GroupsListFeature'

export default withStyles(GroupsListFeature)
