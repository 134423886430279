export const actionTypes = [
  {
    title: 'devices',
    description: 'devices_description_action',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'ZwaveBinarySensor',
  },
  {
    title: 'notification',
    description: 'send_notification',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'PushNotifications',
  },
  {
    title: 'virtual_switch',
    description: 'virtual_switch_description_action',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'VirtualSwitch',
  },
]
