import React from 'react'
import { Typography } from '@mui/material'
import getContrastColor from '@app/helpers/scenes/getContrastColor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './ColorDescription.style'

interface Props {
  condition: IConditionFromBackend,
  index: number,
}

const ColorDescription: React.FC<Props> = (props) => {
  const {
    index,
    condition,
  } = props
  const classes = useStyles()
  return (
    <Wrapper className={classes.descriptionWrapper}>
      <Typography
        className={classes.sceneInfoText}
      >
        {index > 0 && condition.verified && I18n.t('scene_web.if')}
        {' '}
        {condition.description.split('ௐ')[0]}
        <span
          className={classes.colorBlock}
          style={{ backgroundColor: condition.value_title, borderColor: getContrastColor(condition.value_title) }}
        />
      </Typography>
    </Wrapper>
  )
}

export default ColorDescription
