import { createLogic } from 'redux-logic'
import { SYNC_MEZZO_LIGHTS_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { syncMezzoLightsChannelSent } from '@app/actions/myDevices/channels/devices/mezzoLightsChannel'
import { updateMezzoLights, endMezzoLightsLoading } from '@app/actions/myDevices/mezzoLights'
import Api from '@app/api/myDevices/channels/devices/devicesChannels'

const subscribeMezzoLightsLogic = createLogic({
  type: SYNC_MEZZO_LIGHTS_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.mezzoLightsChannel({
      request: () => {
        dispatch(syncMezzoLightsChannelSent())
      },
      response: (response) => {
        const { value } = response
        store.dispatch(updateMezzoLights(value))
        store.dispatch(endMezzoLightsLoading())
      },
    })
  },
})

export default subscribeMezzoLightsLogic
