import React from 'react'
import { List, ListItem, Typography } from '@mui/material'

import { withStyles } from '@mui/styles'
import PropTypes from '@app/components/PropTypes'
import styles from './EcoModeDevicesList.style'
import EcoModeDevice from './EcoModeDevice'

class EcoModeDevicesList extends React.Component {
  render() {
    const {
      classes,
      ecoModeDevices,
      selectCurrentEcoModeDeviceId,
      selectCurrentEcoModeDeviceStatus,
      patchCurrentEcoModeDevice,
      admin,
    } = this.props
    return (
      <List className={classes.root}>
        {ecoModeDevices.length
          ? ecoModeDevices.map((device) => (
            <ListItem
              key={device.id}
              className={classes.listItem}
            >
              <EcoModeDevice
                className={classes.deviceItem}
                id={device.id}
                admin={admin}
                icon={device.icon}
                name={device.name}
                ecoModeStatus={device.eco_mode}
                selectCurrentEcoModeDeviceId={selectCurrentEcoModeDeviceId}
                selectCurrentEcoModeDeviceStatus={selectCurrentEcoModeDeviceStatus}
                patchCurrentEcoModeDevice={patchCurrentEcoModeDevice}
              />
            </ListItem>
          )) : <Typography align="center" variant="h6">{I18n.t('my_energy.no_devices')}</Typography>}
      </List>
    )
  }
}
EcoModeDevicesList.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  ecoModeDevices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  selectCurrentEcoModeDeviceId: PropTypes.func.isRequired,
  selectCurrentEcoModeDeviceStatus: PropTypes.func.isRequired,
  patchCurrentEcoModeDevice: PropTypes.func.isRequired,
}

export default withStyles(styles)(EcoModeDevicesList)
