import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_TIMER,
  SELECT_OBSERVING_DEVICE_TYPE,
  SELECT_TRIGGERING_DEVICE_TYPE,
  SELECT_TRIGGERING_STATE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTriggeringDevice extends React.Component {
  onBackClick =() => {
    const {
      navigate,
      conditionNumber,
      clearCurrentConditionInfo,
      saveConditionNumber,
      isChangeConditionStateMode,
      isEditConditionMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      const savedConditionNumber = conditionNumber
      clearCurrentConditionInfo()
      saveConditionNumber(savedConditionNumber)
      navigate(SELECT_CONDITION_TIMER)
    }
  }

  selectState=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_TRIGGERING_STATE)
  }

  mapState=(state) => {
    switch (state) {
    case -1:
      return I18n.t('timers.n_a')
    case 0:
      return I18n.t('scene_web.is_off')
    case 1:
      return I18n.t('scene_web.is_on')
    case 2:
      return I18n.t('scene_web.is_any')
    default:
      throw new Error('state unsupported')
    }
  }

  selectTriggeringDevice=() => {
    const {
      navigate,
      selectConditionDeviceType,
    } = this.props
    selectConditionDeviceType('triggering')
    navigate(SELECT_TRIGGERING_DEVICE_TYPE)
  }

  selectObservingDevice=() => {
    const {
      navigate,
      selectConditionDeviceType,
    } = this.props
    selectConditionDeviceType('observing')
    navigate(SELECT_OBSERVING_DEVICE_TYPE)
  }

  getNextButtonState=() => {
    const {
      deviceState,
      triggeringDeviceName,
      observingDeviceName,
    } = this.props
    return !(triggeringDeviceName && observingDeviceName && deviceState >= 0)
  }

  onNextClick=() => {
    const {
      navigate,
      saveConditionType,
      isEditConditionMode,
    } = this.props
    saveConditionType('DeviceTimeout')
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      deviceState,
      triggeringDeviceName,
      observingDeviceName,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_triggering_device_screen')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <CardBlock
              key={I18n.t('scene_web.triggering_device')}
              title={I18n.t('scene_web.triggering_device')}
              description={triggeringDeviceName || I18n.t('timers.n_a')}
              position="bottom"
              buttonName={triggeringDeviceName ? I18n.t('scene_web.change') : I18n.t('scene_web.use')}
              isCardDisabled={false}
              isButtonDisabled={false}
              onClick={() => {
                this.selectTriggeringDevice()
              }}
            />
            <CardBlock
              key={I18n.t('scene_web.state')}
              title={I18n.t('scene_web.state')}
              description={this.mapState(deviceState)}
              position="bottom"
              buttonName={deviceState !== -1 ? I18n.t('scene_web.change') : ''}
              isCardDisabled={deviceState === -1}
              isButtonDisabled={deviceState === -1}
              onClick={() => {
                this.selectState()
              }}
            />
            <CardBlock
              key={I18n.t('scene_web.retriggering_device')}
              title={I18n.t('scene_web.retriggering_device')}
              description={observingDeviceName || I18n.t('timers.n_a')}
              position="bottom"
              buttonName={observingDeviceName ? I18n.t('scene_web.change') : I18n.t('scene_web.use')}
              isCardDisabled={false}
              isButtonDisabled={false}
              onClick={() => {
                this.selectObservingDevice()
              }}
            />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onNextClick}
            disabled={this.getNextButtonState()}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectTriggeringDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  deviceState: PropTypes.number.isRequired,
  triggeringDeviceName: PropTypes.string.isRequired,
  observingDeviceName: PropTypes.string.isRequired,
  selectConditionDeviceType: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  conditionNumber: PropTypes.number.isRequired,
  clearCurrentConditionInfo: PropTypes.func.isRequired,
  saveConditionNumber: PropTypes.func.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
}

SelectTriggeringDevice.displayName = 'SelectTriggeringDeviceIOS'

export default SelectTriggeringDevice
