import React, { useState } from 'react'
import classNames from 'classnames'
import {
  Button, OutlinedInput, InputAdornment, IconButton, InputLabel, FormControl, FormHelperText,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { useDispatch } from '@app/store'
import { sendKeyboardCommand } from '@app/actions/mySecurity/mySecurity'
import * as buttons from '@app/lib/MySecurity/keyboard'
import { ClearKeypadIcon } from 'icons'
import useStyles from './Keyboard.style'

interface Props {
  isMobile: boolean,
}

const Keyboard: React.FC<Props> = (props) => {
  const {
    isMobile,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const [command, saveCommand] = useState<string>('')
  const [info, saveInfo] = useState<string>('')

  const onButtonClick = (addition: string) => {
    saveCommand(`${command}${addition}`)
  }

  const onArmButtonClick = () => {
    const currentString = `${command}${buttons.Arm.value}`
    if (currentString.length === 1) {
      saveCommand(currentString)
    }
  }

  const onEButtonClick = () => {
    if (command.length > 0) {
      dispatch(sendKeyboardCommand(command))
      saveInfo(`${I18n.t('security.your_value')} ${command} ${I18n.t('security.has_been_sent')}`)
      saveCommand('')
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toUpperCase()
    const lastSymbol = inputValue.slice(-1)
    if (lastSymbol === 'E') {
      const commandWithoutE = inputValue.slice(0, -1)
      saveCommand(commandWithoutE)
      onEButtonClick()
    } else if (lastSymbol === 'A') {
      if (inputValue.length === 1) {
        saveCommand(inputValue)
      }
    } else if (/^[A0-9E]*$/.test(inputValue)) {
      saveCommand(inputValue)
    }
  }

  const handleClearValue = () => saveCommand('')

  const handleMouseDownClearValue = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const createButton = (value: string) => (
    <Button
      className={classes.button}
      onClick={() => {
        onButtonClick(value)
      }}
      key={value}
    >
      {value}
    </Button>
  )

  return (
    <Wrapper className={classNames(
      classes.main,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        <FormControl variant="outlined" className={classes.textField}>
          <InputLabel htmlFor="command-input" className={classes.commandLabel}>{I18n.t('security.input_label')}</InputLabel>
          <OutlinedInput
            className={classes.commandInput}
            id="command-input"
            value={command}
            onChange={handleInputChange}
            color="primary"
            // @ts-ignore
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear input value"
                  onClick={handleClearValue}
                  onMouseDown={handleMouseDownClearValue}
                  edge="end"
                >
                  <ClearKeypadIcon className={classes.icon} />
                </IconButton>
              </InputAdornment>
            )}
            label="Enter your value"
          />
          <FormHelperText id="command-input">
            {info}
          </FormHelperText>
        </FormControl>
      </Wrapper>
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        {buttons.FirstLine.map((button: string) => createButton(button))}
      </Wrapper>
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        {buttons.SecondLine.map((button: string) => createButton(button))}
      </Wrapper>
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        {buttons.ThirdLine.map((button: string) => createButton(button))}
      </Wrapper>
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        <Button
          className={classes.button}
          onClick={() => {
            onArmButtonClick()
          }}
        >
          {buttons.Arm.title}
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            onButtonClick(buttons.Zero)
          }}
        >
          {buttons.Zero}
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            onEButtonClick()
          }}
        >
          {buttons.E}
        </Button>
      </Wrapper>
    </Wrapper>
  )
}

export default Keyboard
