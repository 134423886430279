import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.0621 17.0114L11.4777 18.2978M8.93101 17.0114L8.51547 18.2978M7.81499 16.8934L7.04921 18.0001M9.92877 17.0507V18.5M12.1841 16.8934L12.9499 18.0057M16.4998 12.012C16.4998 10.1807 16.4998 8.3495 16.4998 6.51264C16.4998 5.72621 16.4998 4.93417 16.4998 4.14775C16.4998 3.65904 16.5235 3.23774 16.0961 2.85015C15.8527 2.63107 15.6034 2.52434 15.271 2.50749C14.9682 2.49064 14.6655 2.50749 14.3686 2.50749C12.4334 2.50749 10.4982 2.50749 8.56297 2.50749C7.59536 2.50749 6.62775 2.50749 5.66014 2.50749C5.41675 2.50749 5.17337 2.50749 4.93592 2.50749C4.69253 2.50749 4.47289 2.52996 4.24731 2.63107C3.79615 2.83891 3.50528 3.27145 3.50528 3.74892C3.50528 5.58016 3.50528 7.41141 3.50528 9.24266C3.50528 9.69766 3.50528 10.1583 3.50528 10.6133C3.50528 10.8548 3.50528 11.0964 3.50528 11.3435C3.50528 11.5907 3.4934 11.8322 3.50528 12.0794C3.53496 12.54 3.82583 12.9501 4.27105 13.1411C4.4907 13.2366 4.7044 13.2534 4.93592 13.2534C5.16743 13.2534 5.40488 13.2534 5.6364 13.2534C7.57162 13.2534 9.50684 13.2534 11.4421 13.2534C12.4097 13.2534 13.3773 13.2534 14.3449 13.2534C14.5883 13.2534 14.8317 13.2534 15.0691 13.2534C15.3125 13.2534 15.5322 13.231 15.7577 13.1298C16.2148 12.922 16.4998 12.4895 16.4998 12.012ZM11.6261 16.2194C10.2845 16.2194 9.12691 16.2194 8.373 16.2194C8.01089 16.2194 7.71408 15.9385 7.71408 15.5958C7.71408 14.9723 7.71408 14.3488 7.71408 13.7253C9.23376 13.7253 10.7594 13.7253 12.2791 13.7253C12.2791 14.3488 12.2791 14.9723 12.2791 15.5958C12.285 15.9385 11.9882 16.2194 11.6261 16.2194ZM7.45287 13.5287H12.5462V13.7253H7.45287V13.5287Z" stroke="currentColor"/>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SecuritySettingsIcon')

export default icon
