import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.13913 0V20.9745L17.8608 21V0H3.13913ZM4.52355 19.5925V1.38451H16.4762V3.87701H16.0033V1.84639H4.99667V19.1525H16.0033V17.1219H16.4763V19.6132L4.52355 19.5925ZM16.0033 6.03059H16.4762V14.9682H16.0033V6.03059ZM7.27336 10.4994C7.27336 10.9008 6.94791 11.2262 6.54649 11.2262C6.14506 11.2262 5.81962 10.9008 5.81962 10.4994C5.81962 10.098 6.14506 9.77256 6.54649 9.77256C6.94791 9.77256 7.27336 10.098 7.27336 10.4994Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SealedIcon')

export default icon
