import React from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Paper, Typography } from '@mui/material'
import { conditionDeviceTypes, conditionDeviceTypesNCentral } from '@app/lib/Scenes/conditionDeviceTypes'
import {
  SELECT_CONDITION_DEVICE_TABS,
  SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE,
  SELECT_CONDITION_TYPE,
  SELECT_GROUP_CONDITION,
  SELECT_ROOM_CONDITION,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import useStyles from './SelectConditionDeviceType.style'

interface Props {
  selectConditionDeviceType: (type: string) => void,
  isMobile: boolean,
  isNCentral: boolean,
}

const SelectConditionDeviceType: React.FC<Props> = (props) => {
  const {
    selectConditionDeviceType,
    isMobile,
    isNCentral,
  } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const conditionDeviceList = isNCentral ? conditionDeviceTypesNCentral : conditionDeviceTypes

  const onBackClick = () => {
    navigate(SELECT_CONDITION_TYPE)
  }

  const onUseClick = (type: string) => {
    if (type === 'mezzo') {
      navigate(SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE)
    } else if (type === 'ZWaveDevices' || type === 'TwrDevices') {
      selectConditionDeviceType(type)
      navigate(SELECT_CONDITION_DEVICE_TABS)
    } else if (type === 'RoomActivity') {
      navigate(SELECT_ROOM_CONDITION)
    } else if (type === 'GroupActivity') {
      navigate(SELECT_GROUP_CONDITION)
    } else {
      throw new Error('condition unsupported')
    }
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.select_device_room_group')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        {
          conditionDeviceList.map((type) => (
            <CardBlock
              key={type.title}
              title={I18n.t(`scene_web.${type.title}`)}
              description={I18n.t(`scene_web.${type.description}`)}
              position={type.position}
              buttonName={I18n.t(`scene_web.${type.buttonName}`)}
              isCardDisabled={type.isCardDisabled}
              isButtonDisabled={type.isButtonDisabled}
              onClick={() => {
                onUseClick(type.type)
              }}
            />
          ))
        }
      </Wrapper>
    </Paper>
  )
}

export default SelectConditionDeviceType
