import React, { ReactNode } from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from 'icons'

interface Props {
  isSubMenuOpen: boolean,
  primary: string,
  icon: ReactNode,
  onItemClick: () => void,
  disabled: boolean,
}

const SubMenuItem: React.FC<Props> = (props) => {
  const {
    primary, isSubMenuOpen, icon, onItemClick, disabled,
  } = props
  return (
    <ListItem button onClick={onItemClick} disabled={disabled}>
      <ListItemIcon style={{ minWidth: '45px' }}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={primary} />
      {isSubMenuOpen ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
    </ListItem>
  )
}

export default SubMenuItem
