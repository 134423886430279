import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/${id}`

const deleteScene = (accessToken, id) => Axios.delete(ENDPOINT(id), {
  headers: { 'Access-Token': accessToken },
})

export default {
  deleteScene,
}
