export const securityManagementDevicesActions = [
  {
    title: 'device',
    description: 'device',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzo',
  },
  {
    title: 'mezzo_light',
    description: 'backlight_control_mezzo',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzoLight',
  },
  {
    title: 'timers',
    description: 'active_timer',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'timers',
  },
]

export const securityManagementDevicesActionsNCentral = [
  {
    title: 'device',
    description: 'device',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzo',
  },
  {
    title: 'timers',
    description: 'active_timer',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'timers',
  },
]
