import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import { binarySwitchActionValues } from '@app/lib/Scenes/binarySwitchActionValues'
import {
  SELECT_MULTICHANNEL_ACTION_STATE_SELECTED,
  SELECT_MULTICHANNEL_ACTION_SUBDEVICE,
} from '@app/constants/routes'

class SelectMultichannelActionSubDeviceValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      stateDescription,
    } = this.props
    if (stateDescription) {
      navigate(SELECT_MULTICHANNEL_ACTION_STATE_SELECTED)
    } else {
      navigate(SELECT_MULTICHANNEL_ACTION_SUBDEVICE)
    }
  }

  onAddClick=(value) => {
    const {
      navigate,
      saveActionStateDescription,
    } = this.props
    saveActionStateDescription(value.description)
    this.clearDuration()
    navigate(SELECT_MULTICHANNEL_ACTION_STATE_SELECTED)
  }

  clearDuration=() => {
    const {
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            binarySwitchActionValues.map((value) => (
              <CardBlock
                key={value.description}
                title={value.title}
                description={I18n.t(`scene_action_web.${value.description}`)}
                position={value.position}
                buttonName={value.buttonName}
                isCardDisabled={value.isCardDisabled}
                isButtonDisabled={value.isButtonDisabled}
                onClick={() => {
                  this.onAddClick(value)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultichannelActionSubDeviceValue.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  stateDescription: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
}

SelectMultichannelActionSubDeviceValue.displayName = 'SelectMultichannelActionSubDeviceValue'

export default SelectMultichannelActionSubDeviceValue
