import * as type from '@app/constants/users/memberList'

export const sync = () => ({
  type: type.SYNC_USERS,
})

export const update = (users) => ({
  type: type.UPDATE_USERS,
  users,
})
