const calculateGroupColorActionType = (actionDelay, actionDuration) => {
  if (Object.keys(actionDuration).length && Object.keys(actionDelay).length) {
    return 'GroupColorDelayDuration'
  }
  if (Object.keys(actionDelay).length) {
    return 'GroupColorDelayDuration'
  }
  if (Object.keys(actionDuration).length) {
    return 'GroupColorWithDuration'
  }
  return 'GroupColorControl'
}

export default calculateGroupColorActionType
