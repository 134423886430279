export const SYNC_SCENES = '@@scenesList/sync'
export const RESET_SCENES_LIST = '@@scenesList/reset'

export const UPDATE_SCENES = '@@scenesList/update'

export const DELETE_SCENE = '@@scenesList/delete_scene'

export const SELECT_CURRENT_SCENE_ID = '@@scenesList/selectCurrentSceneId'
export const CLEAR_CURRENT_SCENE_ID = '@@scenesList/clearCurrentSceneId'

export const OPEN_DELETE_SCENE_POPUP = '@@scenesList/openDeleteScenePopup'
export const CLOSE_DELETE_SCENE_POPUP = '@@scenesList/closeDeleteScenePopup'

export const SELECT_CURRENT_SCENE_ENABLED = '@@scenesList/selectCurrentSceneEnabled'
export const CLEAR_CURRENT_SCENE_ENABLED = '@@scenesList/clearCurrentSceneEnabled'

export const CLEAR_CURRENT_SCENE_ALL = '@@scenesList/clearCurrentSceneAll'

export const PATCH_SCENE = '@@scenesList/patchScene'
export const CHANGE_SCENE_ENABLED_VALUE = '@@scenesList/changeSceneEnabledValue'
export const CHANGE_SCENE_ENABLED_VALUE_VIA_ID = '@@scenesList/changeSceneEnabledValueViaId'

export const ACTIVATE_SCENE = '@@scenesList/activateScene'

export const ACTIVATE_SCENE_DONE_ANDROID = '@@scenesList/activateSceneDoneAndroid'
export const ACTIVATE_SCENE_DONE_IOS = '@@scenesList/activateSceneDoneIOS'

export const SAVE_CURRENT_SCENE_NAME = '@@scenesList/saveCurrentSceneName'
export const CLEAR_CURRENT_SCENE_NAME = '@@scenesList/clearCurrentSceneName'

export const PATCH_SCENE_ENABLE_VALUE = '@@scenesList/patchSceneEnableValue'
export const PATCH_SCENE_ENABLE_VALUE_DONE_ANDROID = '@@scenesList/patchSceneEnableValueDoneAndroid'
export const PATCH_SCENE_ENABLE_VALUE_DONE_IOS = '@@scenesList/patchSceneEnableValueDoneIOS'

export const SAVE_CURRENT_SCENE_STATUS = '@@scenesList/saveCurrentSceneStatus'

export const OPEN_SCENE_USED_IN_SCENE_POPUP = '@@scenesList/openSceneUsedInScenePopup'
export const CLOSE_SCENE_USED_IN_SCENE_POPUP = '@@scenesList/closeSceneUsedInScenePopup'

export const START_ENABLING_PROGRESS = '@@scenesList/startEnablingProgress'
export const STOP_ENABLING_PROGRESS = '@@scenesList/stopEnablingProgress'

export const TRIGGER_SCENE_ACTIONS = '@@scenesList/triggerSceneActions'

// IOS
export const PATCH_MOBILE_SCENE_IMPOSSIBLE = '@@scenesList/patchMobileSceneImpossible'
export const PATCH_MOBILE_SCENE = '@@scenesList/patchMobileScene'

export const OPEN_WEBHOOK_POPUP = '@@scenesList/openWebhookPopup'
export const CLOSE_WEBHOOK_POPUP = '@@scenesList/closeWebhookPopup'
export const PATCH_ENABLE_WEBHOOK = '@@scenesList/patchEnableWebhook'
export const CHANGE_ENABLE_WEBHOOK_VALUE = '@@scenesList/changeEnableWebhookValue'
export const CHANGE_TEST_CONDITIONS_ENABLE_VALUE = '@@scenesList/changedTestConditionsEnableValue'
