export const ADD_NEW_ZWAVE_DEVICE = '@@myDevices/addNewZwaveDevice'
export const SET_NEW_ZWAVE_DEVICE = '@@myDevices/setNewZwaveDevice'

export const CANCEL_ZWAVE_DEVICE = '@@myDevices/cancelZwaveDevice'

export const PATCH_ZWAVE_DEVICE_STATUS = '@@myDevices/patchZwaveDeviceStatus'
export const PATCH_NEW_ZWAVE_DEVICE = '@@myDevices/patchNewZwaveDevice'

export const PATCH_ZWAVE_DEVICE = '@@myDevices/patchZwaveDevice'

export const DELETE_ZWAVE_DEVICE = '@@myDevices/deleteZwaveDevice'

export const CANCEL_ZWAVE_DELETE = '@@myDevices/cancelZwaveDelete'

export const START_ZWAVE_REINTERVIEW = '@@myDevices/startZwaveReinterview'
export const BREAK_FROM_REINTERVIEW = '@@myDevices/breakFromReinterview'

export const FETCH_ZWAVE_DEVICE_INFO = '@@myDevices/fetchZwaveDeviceInfo'
export const SET_ZWAVE_DEVICE_INFO = '@@myDevices/setZwaveDeviceInfo'

export const START_FETCH_DEVICE_STATS = '@@myDevices/startFetchingDeviceStats'
export const END_FETCH_DEVICE_STATS = '@@myDevices/endFetchingDeviceStats'

export const FETCH_ZWAVE_DEVICE_DETAILS = '@@myDevices/fetchZwaveDeviceDetails'
export const SET_ZWAVE_DEVICE_DETAILS = '@@myDevices/setZwaveDeviceDetails'
export const RESET_ZWAVE_DEVICE_DETAILS = '@@myDevices/resetZwaveDeviceDetails'

export const SET_ZWAVE_DEVICE_DETAILS_ERROR = '@@myDevices/setZwaveDeviceDetailsError'
export const RESET_ZWAVE_DEVICE_DETAILS_ERROR = '@@myDevices/resetZwaveDeviceDetailsError'

export const SET_CONFIGURATION_POPUP_DATA = '@@myDevices/setConfigurationPopupData'
export const SET_CONFIGURATION_POPUP_STATUS = '@@myDevices/setConfigurationPopupStatus'
export const RESET_CONFIGURATION_POPUP_STATUS = '@@myDevices/resetConfigurationPopupStatus'

export const GET_ZWAVE_DEVICE_PARAM = '@@myDevices/getZwaveDeviceParam'
export const PARSE_ZWAVE_DEVICE_PARAM = '@@myDevices/parseZwaveDeviceParam'
export const SET_ZWAVE_DEVICE_PARAM = '@@myDevices/setZwaveDeviceParam'

export const GET_ZWAVE_DEVICE_PARAMS = '@@myDevices/getZwaveDeviceParams'

export const SET_IS_SLEEPING_DEVICE_POPUP_OPEN = '@@myDevices/setIsSleepingDevicePopupOpen'
