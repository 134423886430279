import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import {
  SYNC_CANCEL_CLOUD_FIRMWARE_UPDATE_CHANNEL,
  SYNC_CLOUD_FIRMWARE_UPDATE_CHANNEL,
} from '@app/constants/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'
import {
  openDownloadingErrorPopup,
  syncDoneChannel,
  syncFailed,
  syncSentChannel,
} from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import cloudFirmwareUpdateChannel from '@app/api/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'

const subscribeCloudFirmwareUpdateChannelLogic = createLogic({
  type: SYNC_CLOUD_FIRMWARE_UPDATE_CHANNEL,
  cancelType: SYNC_CANCEL_CLOUD_FIRMWARE_UPDATE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    cloudFirmwareUpdateChannel({
      request: () => {
        dispatch(syncSentChannel())
      },
      response: (data) => {
        if (data.error) {
          store.dispatch(syncFailed(handleErrors(data.error)))
          store.dispatch(openDownloadingErrorPopup())
          store.dispatch(update({ update_status: 'done' }))
        } else {
          store.dispatch(update(data))
          store.dispatch(syncDoneChannel())
        }
      },
    })
  },
})

export default subscribeCloudFirmwareUpdateChannelLogic
