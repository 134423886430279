const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 106px)',
  },
  textTitle: {
    fontWeight: 'bold',
    opacity: 0.7,
  },
  textPart: {
    width: '100%',
    padding: '3% 10%',
    position: 'relative',
    borderBottom: '1px solid #979797',
  },
  textPartMobile: {
    width: '100%',
    padding: '1% 2%',
    position: 'relative',
    borderBottom: '1px solid #979797',
  },
  text: {
    paddingLeft: 15,
    margin: '10px 0 0 0',
    fontSize: '14px',
    lineHeight: '17px',
  },
})

export default styles
