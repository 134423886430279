import React, { useEffect } from 'react'
import {
  Button, IconButton, Radio, RadioGroup, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import { useNavigate } from 'react-router-dom'
import {
  getTakeOnBoardDeviceType,
  patchTakeOnBoardDeviceType,
  saveTakeOnBoardDeviceType,
} from '@app/actions/myDevices/twrDevices'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { takeOnBoardDeviceTypes, takeOnBoardDeviceTypesList } from '@app/lib/MyDevices/takeOnBoardDeviceTypes'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './TwrTakeOnBoardDeviceTypes.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentTakeOnBoardDeviceType: number,
  isMobile: boolean,
}

const TwrTakeOnBoardDeviceTypes: React.FC<Props> = (props) => {
  const { currentDevice, currentTakeOnBoardDeviceType, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentDevice.id) {
      dispatch(getTakeOnBoardDeviceType(currentDevice.id))
    }
  }, [])

  const goBackHandler = () => navigate(-1)

  const submitOption = () => {
    dispatch(patchTakeOnBoardDeviceType(currentDevice.id))
  }

  const renderTakeOnBoardDeviceTypes = () => takeOnBoardDeviceTypes.map((deviceType) => (
    <Wrapper
      className={classes.modeCard}
      key={`take_on_board_device_type_${deviceType + 1}`}
    >
      <Typography className={classes.cardTitle}>
        {I18n.t(`my_devices.${takeOnBoardDeviceTypesList[deviceType]}`)}
      </Typography>
      <Radio value={deviceType} className={classes.radioButton} color="primary" />
    </Wrapper>
  ))

  const saveTakeOnBoardType = (evt: React.ChangeEvent<HTMLInputElement>) => dispatch(saveTakeOnBoardDeviceType(+evt.target.value))

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classes.navigationControls}>
        <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
          <ScenesArrowBack />
        </IconButton>
      </Wrapper>
      <Wrapper>
        <RadioGroup
          className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}
          value={currentTakeOnBoardDeviceType}
          onChange={saveTakeOnBoardType}
        >
          {renderTakeOnBoardDeviceTypes()}
        </RadioGroup>
      </Wrapper>
      <Button
        variant="outlined"
        className={classes.saveButton}
        color="primary"
        onClick={submitOption}
      >
        {I18n.t('buttons.save')}
      </Button>
    </Wrapper>
  )
}

export default TwrTakeOnBoardDeviceTypes
