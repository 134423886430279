import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft } from 'icons'
import { renderIcon } from '@app/helpers/myDevices/renderIcon'
import {
  IControl, IZwaveOrTwrDevice, DeviceTypes,
} from '@app/@types/myDevices'
import { zwaveSensorTypes } from '@app/lib/MyDevices/zwaveSensorTypes'
import useStyles from './MainSettings.style'

interface IControlInfo {
  label: string,
  key: string,
  inverted?: boolean,
}

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceControl: IControl,
  isMobile: boolean,
}

const MainSettings: React.FC<Props> = (props) => {
  const { currentDevice, currentDeviceControl, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [icon, setIcon] = useState<number>(-1)
  const [name, setName] = useState<string>('')
  const [controlInfo, setControlInfo] = useState<IControlInfo>({ label: '', key: '', inverted: false })

  useEffect(() => {
    if (currentDevice) {
      setIcon(currentDevice.icon)
      setName(currentDevice.name)
      if (currentDeviceControl) {
        setControlInfo({
          label: currentDeviceControl.label,
          key: currentDeviceControl.key,
          inverted: currentDevice.invert_status,
        })
      }
    }
  }, [])

  const goEditHandler = () => navigate(`${location.pathname}/edit`)

  const goToControlOptions = () => navigate(`${location.pathname}/control_options`)

  return (
    <Wrapper className={classNames(classes.mainSettingsContainer, isMobile && classes.mainSettingsContainerMobile)}>
      <Typography className={classes.title}>{I18n.t('my_devices.main_settings')}</Typography>
      <Wrapper
        className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
        onClick={goEditHandler}
      >
        <Typography className={classNames(classes.cardTitle, classes.marginBottomHelper)}>
          {I18n.t('my_devices.name_icon_settings')}
        </Typography>
        <Wrapper className={classes.subTitleContainer}>
          <Typography className={classes.deviceName}>{name}</Typography>
          {renderIcon(icon, { className: classes.deviceIcon })}
        </Wrapper>
        <ChevronLeft className={classes.arrowRightIcon} />
      </Wrapper>
      {currentDevice.device_type === DeviceTypes.zwaveDevice
      && !zwaveSensorTypes.includes(currentDevice.type)
      && (
        <Wrapper
          className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
          onClick={goToControlOptions}
        >
          <Typography
            className={classNames(classes.cardTitle, classes.marginBottomHelper)}
          >
            {I18n.t('my_devices.power_buttons')}
          </Typography>
          <Wrapper className={classes.subTitleContainer}>
            <Typography className={classes.deviceName}>
              {controlInfo.label
                ? `${controlInfo.label} ${controlInfo.inverted ? `- ${I18n.t('my_devices.inverted')}` : ''}`
                : I18n.t('my_devices.no_controls')}
            </Typography>
          </Wrapper>
          <ChevronLeft className={classes.arrowRightIcon} />
        </Wrapper>
      )}
    </Wrapper>
  )
}

export default MainSettings
