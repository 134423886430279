import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import {
  FormControl, Switch, Typography, ListItem,
} from '@mui/material'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { IPOFromBackend } from '@app/@types/po/poConfig'
import useStyles from './Toggle.style'

type Props = {
  name: string,
  number: number,
  title: string,
  value: string,
  options: Array<IPOFromBackend>,
  setOptions: Dispatch<SetStateAction<Array<IPOFromBackend>>>,
}

const Toggle: React.FC<Props> = (props) => {
  const classes = useStyles()
  // @ts-ignore
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const [currentValue, setCurrentValue] = useState<boolean>(false)
  const {
    number, title, value, options, setOptions, name,
  } = props

  useEffect(() => {
    setCurrentValue(!!+value)
  }, [])

  useEffect(() => {
    setCurrentValue(!!+value)
  }, [value])

  const handleSelectValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(options.map((rule) => (rule.number === number
      ? { ...rule, value: event.target.checked ? '1' : '0' } : rule)))
    setCurrentValue(event.target.checked)
  }

  return (
    <ListItem className={classes.root}>
      <FormControl variant="standard" className={classes.formControl}>
        <Typography className={classNames(classes.title, isMobile && classes.titleMobile)}>{`${title}`}</Typography>
        <Switch
          classes={{
            switchBase: classes.iOSSwitchBase,
            track: classes.iOSBar,
            thumb: classes.iOSIcon,
            checked: classes.iOSChecked,
            root: classes.form,
          }}
          inputProps={{ name }}
          checked={currentValue}
          onChange={handleSelectValue}
        />
      </FormControl>
    </ListItem>
  )
}

export default Toggle
