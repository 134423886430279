import React, { useState, useEffect } from 'react'
import { Typography, Button } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { useDispatch } from 'react-redux'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import classNames from 'classnames'
import TriggerPopup from '@app/components/MyDevices/DeviceInfo/DeviceStats/TwrHealth/TriggerPopup/TriggerPopup'
import { getTwrHealth } from '@app/actions/myDevices/deviceInfo/getTwrHealth'
import { twrValueType } from '@app/helpers/myDevices/twrValueType'
import useStyles from './TwrHealth.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  twrDevicesHealthScreen?: boolean,
}

const TwrHealth: React.FC<Props> = (props) => {
  const {
    currentDevice,
    twrDevicesHealthScreen,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isTriggerPopupOpen, setIsTriggerPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsTriggerPopupOpen(false)
  }, [currentDevice.info?.health])

  const openTriggerPopup = () => {
    dispatch(getTwrHealth(currentDevice.id))
    setIsTriggerPopupOpen(true)
  }

  const renderSignalLevel = (level: number): string => {
    switch (true) {
    case level === null:
      return 'N/A'
    case level >= 0 && level <= 2:
      return 'Low'
    case level >= 3 && level <= 6:
      return 'Medium'
    case level >= 7 && level <= 9:
      return 'Good'
    default:
      return 'N/A'
    }
  }

  const renderDiagnosticTime = (timestamp: Date): string => {
    const day = timestamp.getDate()
    const month = timestamp.getMonth() + 1
    const year = timestamp.getFullYear()
    const hours = timestamp.getHours()
    const minutes = timestamp.getMinutes()

    return `${day}/${month}/${year} at ${hours}:${minutes.toString().length === 1 ? `0${minutes}` : minutes}`
  }

  const renderStatusColor = () => {
    if (currentDevice.info?.twr_type === 'Door') {
      if (currentDevice.info?.twr_sensor.value) {
        return classes.red
      }
      return classes.green
    }
    if (currentDevice.info?.twr_sensor.value) {
      return classes.green
    }
    return classes.red
  }

  return (
    <>
      <Wrapper className={classes.gridElement}>
        <Typography className={classes.name}>
          {twrDevicesHealthScreen ? `${currentDevice.name}` : `${currentDevice.name} device health`}
        </Typography>
        {(twrDevicesHealthScreen && currentDevice.info?.twr_type !== 'Keyfob') && (
          <Wrapper className={classes.healthWrapper}>
            <Typography className={classes.healthTitle}>Status:</Typography>
            {!currentDevice.info
            || (typeof currentDevice.info?.twr_sensor === 'object' && Object.keys(currentDevice.info?.twr_sensor!).length === 0)
              ? <Typography>{I18n.t('my_devices.not_available')}</Typography>
              : (
                <Typography className={classNames(
                  classes.status, renderStatusColor(),
                )}
                >
                  {currentDevice.info?.twr_sensor.value === null
                    ? `${I18n.t('my_devices.not_available')}`
                    : twrValueType(currentDevice.info?.twr_type!, currentDevice.info?.twr_sensor.value!)}
                </Typography>
              )}
          </Wrapper>
        )}
        <Wrapper className={classes.healthWrapper}>
          <Typography className={classes.healthTitle}>Signal level:</Typography>
          {currentDevice.info?.health?.signal_level === undefined || Object.keys(currentDevice.info?.health!).length === 0
            ? <Typography>{I18n.t('my_devices.not_available')}</Typography>
            : (
              <Typography className={classes[`signal${renderSignalLevel(currentDevice.info?.health?.signal_level!)}`]}>
                {renderSignalLevel(currentDevice.info?.health?.signal_level!)}
              </Typography>
            )}
        </Wrapper>
        <Wrapper className={classes.healthWrapper}>
          <Typography className={classes.healthTitle}>Diagnostic time:</Typography>
          {currentDevice.info?.health?.update === undefined || Object.keys(currentDevice.info?.health!).length === 0
            ? <Typography>{I18n.t('my_devices.not_available')}</Typography>
            : (
              <Typography>
                {currentDevice.info?.health?.update
                  ? renderDiagnosticTime(new Date(currentDevice.info?.health?.update!))
                  : 'N/A'}
              </Typography>
            )}
        </Wrapper>
        <Wrapper className={classes.buttonWrapper}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={openTriggerPopup}
          >
            DIAGNOSTIC
          </Button>
        </Wrapper>
      </Wrapper>
      <TriggerPopup
        isTriggerPopupOpen={isTriggerPopupOpen}
        setIsTriggerPopupOpen={setIsTriggerPopupOpen}
        currentDevice={currentDevice}
      />
    </>
  )
}

export default TwrHealth
