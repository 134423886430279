import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import {
  FormControl, FormGroup, Checkbox, FormControlLabel,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IConfigParameter, IConfigParameterValue } from '@app/@types/myDevices'
import { Optional } from '@app/@types/common'
import useStyles from './Multi.style'

interface Props {
  currentParameter: IConfigParameter,
  setTemporaryValues: Dispatch<SetStateAction<Optional<Array<IConfigParameterValue>>>>,
  isMobile: boolean,
}

const Multi: React.FC<Props> = (props) => {
  const {
    currentParameter, setTemporaryValues, isMobile,
  } = props
  const classes = useStyles()
  const [values, setValues] = useState<Array<IConfigParameterValue>>(currentParameter.values)

  useEffect(() => {
    setValues(currentParameter.values)
  }, [currentParameter])

  const handleChange = (parameter: IConfigParameterValue) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values.map((param) => (
      param.description === parameter.description
        ? { ...param, isEnabled: event.target.checked }
        : param)))
    setTemporaryValues(values.map((param) => (
      param.description === parameter.description
        ? { ...param, isEnabled: event.target.checked }
        : param)))
  }

  return (
    <Wrapper className={classes.root}>
      <FormControl className={classNames(classes.card, isMobile && classes.cardMobile)}>
        <FormGroup>
          {values.map((parameter, index) => (
            <FormControlLabel
              key={`value-${index + 1}`}
              control={(
                <Checkbox
                  onChange={handleChange(parameter)}
                  checked={parameter.isEnabled}
                />
              )}
              label={parameter.description}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Wrapper>
  )
}

export default Multi
