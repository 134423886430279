// @ts-nocheck
import { DEVICE_INFO_CHANNEL } from '@app/constants/channels'
import { openDeviceInfoChannel, closeDeviceInfoChannel } from '@app/actions/myDevices/deviceInfo/deviceInfoChannel'
import openCable from '@app/api/cable'

const deviceInfoChannel = ({ request, response }, id) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(DEVICE_INFO_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create({
      channel: DEVICE_INFO_CHANNEL,
      id,
    }, {
      connected() {
        request(this)
        store.dispatch(openDeviceInfoChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeDeviceInfoChannel())
      },
    })
  }
  return subscription
}

export default {
  deviceInfoChannel,
}
