import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9844 0H19.9688V14.0156C19.9688 14.5469 19.7656 15.0156 19.3594 15.4219C18.9844 15.8281 18.5312 16.0312 18 16.0312H1.96875C1.4375 16.0312 0.96875 15.8281 0.5625 15.4219C0.1875 15.0156 0 14.5469 0 14.0156V2.01562C0 1.48438 0.1875 1.01562 0.5625 0.609375C0.96875 0.203125 1.4375 0 1.96875 0H3L4.96875 4.03125H7.96875L6 0H7.96875L9.98438 4.03125H12.9844L10.9688 0H12.9844L15 4.03125H18L15.9844 0Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SceneActivatedIcon')

export default icon
