import {
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_SECURITY_OPTION_VALUE,
} from '@app/constants/routes'

export const zWaveDeviceTypes = [
  {
    description: 'zwave_device',
    position: 'middle',
    buttonName: 'choose',
    isButtonDisabled: false,
    isCardDisabled: false,
    conditionLink: SELECT_CONDITION_DEVICE_STATE_TYPE,
  },
  {
    description: 'security_device',
    position: 'middle',
    buttonName: 'choose',
    isButtonDisabled: false,
    isCardDisabled: false,
    conditionLink: SELECT_SECURITY_OPTION_VALUE,
  },
]
