export const setColors = {
  description: 'set_colors',
  position: 'middle',
  buttonName: 'choose',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const rgbColors = {
  description: 'rgb_colors',
  position: 'middle',
  buttonName: 'choose',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const whiteWarm = {
  description: 'white_warm',
  position: 'middle',
  buttonName: 'choose',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}
export const whiteCold = {
  description: 'white_cold',
  position: 'middle',
  buttonName: 'choose',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}
