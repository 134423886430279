import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.8307 141.5H90.1693C91.6173 141.5 92.7698 140.356 92.7698 138.928V92.7977H138.9C140.324 92.7977 141.5 91.6217 141.5 90.1973V58.8307C141.5 57.4063 140.324 56.2303 138.9 56.2303H92.7698V10.1004C92.7698 8.67606 91.5937 7.5 90.1693 7.5H58.8307C57.4063 7.5 56.2303 8.67606 56.2303 10.1004V56.2023H10.1004C8.67606 56.2023 7.5 57.3783 7.5 58.8027V90.1693C7.5 91.5937 8.67606 92.7697 10.1004 92.7697H56.2303V138.9C56.2303 140.324 57.4063 141.5 58.8307 141.5ZM87.5689 90.1693V136.299H61.4312V90.1973C61.4312 88.7729 60.2551 87.5969 58.8307 87.5969H12.7009V61.4311H58.8307C60.2551 61.4311 61.4312 60.2551 61.4312 58.8307V12.7009H87.5689V58.8027C87.5689 60.2271 88.7449 61.4031 90.1693 61.4031H136.299V87.5689H90.1693C88.7449 87.5689 87.5689 88.7449 87.5689 90.1693Z" fill="currentColor" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Cross')

export default icon
