import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <g fill="#A9CF39" clipPath="url(#a)">
        <path d="M8.02 8a.7.7 0 0 0 .42-.13l5.62-3.75c.2-.13.34-.36.34-.62V1.25a.75.75 0 0 0-.75-.75.7.7 0 0 0-.41.13L8.02 4.1 2.82.63A.7.7 0 0 0 2.4.5a.75.75 0 0 0-.75.75V3.5c0 .26.13.49.34.62L7.6 7.87a.7.7 0 0 0 .41.13Z"/>
        <path d="M8.02 15.5a.7.7 0 0 0 .42-.13l5.62-3.75c.2-.13.34-.36.34-.62V8.75a.75.75 0 0 0-.75-.75.7.7 0 0 0-.41.13L8.02 11.6l-5.2-3.47A.7.7 0 0 0 2.4 8a.75.75 0 0 0-.75.75V11c0 .26.13.49.34.62l5.62 3.75a.7.7 0 0 0 .41.13Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5.5v15h15V.5z"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ChevronsDownIcon', '0 0 13 18', { fontSize: 18 })

export default icon
