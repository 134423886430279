import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M62.5814 72.5869L61.6013 73.4434C47.6955 85.5967 26.0072 75.179 26.7977 56.7352C27.5884 38.2895 50.0809 29.768 62.8928 43.0934L64.2283 44.4824L81.5543 43.3253V8H14V141H81.5445V70.4461L76.9269 70.9858C72.9332 71.4526 68.9428 71.9 64.9557 72.3278L62.5814 72.5869ZM64.3239 76.5586L65.3971 76.4415C68.02 76.16 70.6443 75.8702 73.2699 75.5719C74.6487 75.4153 76.0278 75.2564 77.4072 75.0951V136.863H18.1373V12.1373H77.417V39.4551L65.8752 40.2259C50.5382 24.2742 23.6106 34.4794 22.6642 56.558C21.7179 78.6367 47.6796 91.1053 64.3239 76.5586Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M61.7289 49.4342C93.0794 47.2618 117.47 46.1756 134.902 46.1756C136.219 45.7951 136.912 46.3122 136.912 47.6781V49.4147C136.912 52.3416 135.887 53.9612 133.175 55.1514C123.627 59.3662 99.3658 63.5712 60.3923 67.7664C50.4311 80.1375 30.4891 72.2739 31.6403 56.4686C32.7916 40.6632 53.6897 35.7558 61.7289 49.4342V49.4342Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M45.0162 106.514C37.6208 103.49 39.7867 92.4844 47.7772 92.4844C55.7677 92.4844 57.9336 103.48 50.5383 106.514L50.987 121.685H44.5674L45.0162 106.514Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DoorLockIcon')

export default icon
