export const SYNC_GROUPS_LIST = '@@groups/syncGroupsList'
export const SET_GROUP_LIST = '@@groups/setGroupsList'

export const SYNC_GROUPS_DEVICES = '@@groups/groupsDevicesSync'
export const SET_GROUPS_DEVICES = '@@groups/groupsDevicesSet'

export const GROUPS_ADD_DEVICE = '@@groups/groupsAddDevice'

export const GROUPS_DELETE_DEVICE = '@@groups/groupsDeleteDevice'

export const POST_ADD_GROUP = '@@groups/groupsListPostGroup'
export const SET_POST_ADD_GROUP = '@@groups/groupsListPostGroupSet'
export const POST_ADD_GROUP_FAILED_POPUP = '@@groups/groupsListPostGroupFailedPopup'

export const PATCH_GROUP = '@@group/groupPatch'
export const SET_PATCH_GROUP = '@@group/groupPatchSet'

export const DELETE_GROUP = '@@group/groupDelete'
export const SET_DELETE_GROUP = '@@group/groupDeleteSet'

export const TOGGLE_ADD_GROUP_POPUP = '@@groups/groupsToggleAddPopup'
export const FILTER_ADD_TWR_DEVICES = '@@rooms/filterAddTwrDevices'
export const FILTER_ADD_ZWAVE_DEVICES = '@@rooms/filterAddZwaveDevices'

export const maxGroupsNumber = 30
