import { createLogic } from 'redux-logic'
import { SYNC_DELETE_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { history } from '@app/store/history'
import { MY_DEVICES } from '@app/constants/routes'
import {
  syncDeleteChannelSent,
  closeDeleteChannel,
  setResponseFromDeleteChannel,
} from '@app/actions/myDevices/channels/devices/deleteChannel'
import { stopDeletingProgress } from '@app/actions/myDevices/myDevicesDelete'
import Api from '@app/api/myDevices/channels/devices/devicesChannels'
import { syncMyDevices } from '@app/actions/myDevices/myDevices'

const subscribeDeleteMyDevicesLogic = createLogic({
  type: SYNC_DELETE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process({ action }, dispatch) {
    Api.deleteDeviceChannel({
      request: () => {
        dispatch(syncDeleteChannelSent())
      },
      response: (response) => {
        store.dispatch(setResponseFromDeleteChannel(response.status))
        if (response.status !== 'sleeping') {
          store.dispatch(stopDeletingProgress())
          store.dispatch(closeDeleteChannel())
          store.dispatch(syncMyDevices())
          history.push(MY_DEVICES)
        }
      },
    }, action.id, action.deviceType)
  },
})

export default subscribeDeleteMyDevicesLogic
