import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import { REVOKE_TOKEN, SIGN_OUT_USER } from '@app/constants/signOut'
import { SIGN_IN } from '@app/constants/routes'
import { whitelist } from '@app/store/reducer'
import openCable from '@app/api/cable'
import resetPersistent from '@app/helpers/resetPersistent'
import handleErrors from '@app/helpers/handleErrors'
import Api from '@app/api/revokeToken'
import oAuthApplication from '@app/helpers/oAuthApplication'
import { tokenStatusNone } from '@app/actions/secrets'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const signOutLogic = createLogic({
  type: SIGN_OUT_USER,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { clientId, secret } = oAuthApplication()
    const cable = openCable()
    dispatch(apiRequestSent(REVOKE_TOKEN))
    Api.revokeToken(token, clientId, secret)
      .then(() => {
        dispatch(apiRequestDone(REVOKE_TOKEN))
        dispatch(tokenStatusNone())
        cable.subscriptions.subscriptions.forEach((sub) => sub.unsubscribe())
        cable.disconnect()
        global.WsIsOpen = false
        whitelist.forEach((target) => dispatch(resetPersistent(target)))
        localStorage.clear()
        sessionStorage.clear()
        history.replace(SIGN_IN)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('revokeToken failed without error')
        }
        cable.subscriptions.subscriptions.forEach((sub) => sub.unsubscribe())
        cable.disconnect()
        global.WsIsOpen = false
        whitelist.forEach((target) => dispatch(resetPersistent(target)))
        localStorage.clear()
        sessionStorage.clear()
        dispatch(history.replace(SIGN_IN))
      })
      .then(() => done())
  },
})

export default signOutLogic
