const sortDevices = (devicesGroups, deviceType) => (
  [...devicesGroups].sort((a, b) => b[deviceType].length - a[deviceType].length)
)

const sortDevicesGroups = (devicesGroups, deviceType) => {
  if (!devicesGroups.length) {
    return devicesGroups
  }
  if (deviceType === 'TwrDevices') {
    return sortDevices(devicesGroups, 'twr_devices')
  }
  if (deviceType === 'ZWaveDevices') {
    return sortDevices(devicesGroups, 'zwave_devices')
  }
  throw new Error('device unsupported')
}

export default sortDevicesGroups
