import { Optional } from '@app/@types/common'

export const zwaveSensorValueTypes = (type: Optional<string>, value: Optional<boolean>) => {
  const zwaveSensorsTypeMap = {
    'Door/Window': value ? 'Unsealed' : 'Sealed',
    Motion: value && 'Detected',
    Tamper: value && 'Detected',
    default: '',
  }

  return (type && zwaveSensorsTypeMap[type]) || zwaveSensorsTypeMap.default
}
