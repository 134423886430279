import type { Node } from 'react'
import React from 'react'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import type { Activity } from '@app/types/activities/activitiesTypes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './ActivitiesList.style'
import ActivityCard from '../ActivityCard/ActivityCard'

type Props = {
  activities: Array<Activity>,
}

const ActivitiesList = (props: Props): Node => {
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const classes = useStyles()
  const {
    activities,
  } = props

  return (
    <Wrapper className={classes.wrapper}>
      {activities.length > 0 ? activities.map((activity, i) => (
        <ActivityCard
          key={`activity${i + 1}`}
          activity={activity}
          isMobile={isMobile}
        />
      )) : (
        <Typography className={classes.noActivities}>
          {I18n.t('my_activity_web.no_activities').toUpperCase()}
        </Typography>
      )}
    </Wrapper>
  )
}

export default ActivitiesList
