import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Snackbar } from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '../Wrapper/Wrapper'
import styles from './Loading.style'

class Loading extends React.PureComponent {
  state = {
    completed: 0,
  }

  componentDidMount() {
    const { animationInterval } = this.props
    this.timer = setInterval(this.progress, animationInterval)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  progress = () => {
    const { completed } = this.state
    const { completedMaxValue } = this.props
    this.setState({ completed: completed >= completedMaxValue ? 0 : completed + 1 })
  }

  render() {
    const { open, circularProgressSize } = this.props
    const { completed } = this.state
    return (
      <Wrapper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          variant="success"
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{I18n.t('sphere.loading')}</span>}
          action={[
            <CircularProgress
              value={completed}
              size={circularProgressSize}
              key="progress"
            />,
          ]}
        />
      </Wrapper>
    )
  }
}
Loading.defaultProps = {
  open: false,
  circularProgressSize: 20,
  animationInterval: 20,
  completedMaxValue: 100,
}

Loading.propTypes = {
  open: PropTypes.bool,
  circularProgressSize: PropTypes.number,
  animationInterval: PropTypes.number,
  completedMaxValue: PropTypes.number,
}

export default withStyles(styles)(Loading)
