const styles = (theme) => ({
  message: {
    fontSize: 16,
    maxWidth: '62%',
    marginRight: 5,
  },
  messageMobile: {
    marginLeft: '-10px',
  },
  date: {
    textAlign: 'right',
    padding: 0,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
