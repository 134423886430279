import Axios from 'axios'

import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (sphereId) => `${API_URL}/api/sphere_user/${sphereId}/pin`

const getPinCode = (access_token, sphereId) => Axios.get(ENDPOINT(sphereId), {
  headers: { 'Access-Token': access_token },
})

export default {
  getPinCode,
}
