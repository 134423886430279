import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_FIRMWARE_LANG } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import patchFirmwareLang from '@app/api/cloudFirmwareUpdate/patchFirmwareLang'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchFirmwareLangLogic = createLogic({
  type: SYNC_FIRMWARE_LANG,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { cloudFirmwareUpdate: { cloudFirmwareUpdateInfo: { firmwareLang } } } = store.getState()
    const langData = { firmware_lang: firmwareLang }
    dispatch(apiRequestSent(SYNC_FIRMWARE_LANG))
    patchFirmwareLang(token, langData)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(apiRequestDone(SYNC_FIRMWARE_LANG))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchFirmwareLang failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchFirmwareLangLogic
