import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M60.5 119C92.8087 119 119 92.8087 119 60.5C119 28.1913 92.8087 2 60.5 2C28.1913 2 2 28.1913 2 60.5C2 92.8087 28.1913 119 60.5 119Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M60.4 12.1C66.9 12.1 72.2 17.4 72.2 23.9C72.2 30.4 66.9 35.7 60.4 35.7C53.9 35.7 48.6 30.4 48.6 23.9C48.6 17.4 53.9 12.1 60.4 12.1V12.1ZM60.4 9.10001C52.2 9.10001 45.6 15.7 45.6 23.9C45.6 32.1 52.2 38.7 60.4 38.7C68.6 38.7 75.2 32.1 75.2 23.9C75.2 15.7 68.6 9.10001 60.4 9.10001V9.10001V9.10001Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M68.1 16.7C67.3 15.9 66.4 15.2 65.4 14.7C65.2 15.1 64.9 15.5 64.7 16C65.6 16.5 66.4 17.1 67.1 17.8C67.4 17.4 67.7 17.1 68.1 16.7V16.7Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M65 19.8C64.5 19.3 63.9 18.8 63.2 18.5C63.4 18.1 63.7 17.7 63.9 17.2C64.7 17.6 65.4 18.1 66 18.8C65.7 19.1 65.4 19.5 65 19.8V19.8Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M66.8 22.6C67.2 22.4 67.7 22.2 68.2 22.1C67.9 21.3 67.5 20.6 67 19.9C66.6 20.2 66.2 20.5 65.8 20.8C66.2 21.3 66.6 21.9 66.8 22.6V22.6Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M69.5 21.5C70 21.3 70.4 21.1 70.8 21C70.4 20 69.9 19.1 69.2 18.2C68.8 18.5 68.4 18.8 68 19.1C68.7 19.8 69.2 20.6 69.5 21.5V21.5Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M60.9 51.7C68.6 51.7 74.8 57.9 74.8 65.6C74.8 73.3 68.6 79.5 60.9 79.5C53.2 79.5 47 73.3 47 65.6C47.1 57.9 53.3 51.7 60.9 51.7V51.7ZM60.9 48.7C51.6 48.7 44 56.3 44 65.6C44 74.9 51.6 82.5 60.9 82.5C70.2 82.5 77.8 74.9 77.8 65.6C77.8 56.3 70.3 48.7 60.9 48.7V48.7V48.7Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.1 29.4C22.8 37.2 40.2 42.7 60.4 42.7C80.7 42.7 98.1 37.2 105.8 29.3C95.9 14.9 79.3 5.5 60.5 5.5C41.6 5.5 25 15 15.1 29.4V29.4Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path d="M29.8 66.2C30.3 61.4 26.5 58 21 57.4C15.8 56.8 12 53 12.4 48.9" stroke="currentColor" strokeWidth="3"/>
            <path d="M32.7 80.6C33.2 75.8 29.4 72.4 23.9 71.8C18.7 71.2 14.9 67.4 15.3 63.3" stroke="currentColor"
                  strokeWidth="3"/>
            <path d="M36.4 96C36.9 91.2 33.1 87.8 27.6 87.2C22.4 86.6 18.6 82.8 19 78.7" stroke="currentColor"
                  strokeWidth="3"/>
            <path d="M92.1 66.2C91.6 61.4 95.4 58 100.9 57.4C106.1 56.8 109.9 53 109.5 48.9" stroke="currentColor"
                  strokeWidth="3"/>
            <path d="M89.2 80.6C88.7 75.8 92.5 72.4 98 71.8C103.2 71.2 107 67.4 106.6 63.3" stroke="currentColor"
                  strokeWidth="3"/>
            <path d="M85.4999 96C84.9999 91.2 88.7999 87.8 94.2999 87.2C99.4999 86.6 103.3 82.8 102.9 78.7"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M60.7 110.5C63.4062 110.5 65.6 108.306 65.6 105.6C65.6 102.894 63.4062 100.7 60.7 100.7C57.9939 100.7 55.8 102.894 55.8 105.6C55.8 108.306 57.9939 110.5 60.7 110.5Z"
                  stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M63.6 72.8C63.6 73.7 62.3 74.5 60.8 74.5C59.2 74.5 58 73.7 58 72.8H63.6V72.8Z" stroke="currentColor"
                  strokeWidth="3"/>
            <path
                d="M67.2 69.7C67.2 69.1961 66.7149 68.6922 66.0358 68.2891C65.7447 67.9868 65.4537 65.0643 65.4537 61.638C65.4537 58.9171 63.3194 56.7 60.7 56.7C58.0805 56.7 55.9462 58.9171 55.9462 61.638V61.9403C55.9462 65.2659 55.6552 67.9868 55.3641 68.2891C54.588 68.6922 54.2 69.1961 54.2 69.7"
                stroke="currentColor" strokeWidth="3"/>
            <path d="M54.1 69.9V71.2" stroke="currentColor" strokeWidth="3"/>
            <path d="M54.1 71.2H67.5" stroke="currentColor" strokeWidth="3"/>
            <path d="M67.5 71.2V69.9" stroke="currentColor" strokeWidth="3"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'UhooIcon')

export default icon
