import React from 'react'
import classNames from 'classnames'
import {
  Button, Checkbox, FormControlLabel, FormGroup, Paper, Typography,
} from '@mui/material'
import convertUsersForNotificationsScreen from '@app/helpers/scenes/convertUsersForNotificationsScreen'
import getSelectedUsersArray from '@app/helpers/scenes/getSelectedUsersArray'
import { SETTINGS_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectNotificationUser extends React.PureComponent {
  handleChange = (user) => {
    const { saveSelectedUsers, selectedUsers } = this.props
    const newSelectedUsers = getSelectedUsersArray(user, selectedUsers)
    saveSelectedUsers(newSelectedUsers)
  }

  onBackClick = () => {
    const {
      navigate,
      restoreActionInfo,
    } = this.props
    restoreActionInfo()
    navigate(SETTINGS_TYPE)
  }

  onNextClick = () => {
    const { navigate } = this.props
    navigate(SETTINGS_TYPE)
  }

  renderScreen=() => {
    const {
      classes,
      isMobile,
      users,
      selectedUsers,
    } = this.props
    const usersWithStatus = convertUsersForNotificationsScreen(users, selectedUsers)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.my_users')}</Typography>
          <Typography className={classes.subTitle}>{I18n.t('scene_action_web.select_user')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <FormGroup>
            {usersWithStatus.map((user, index) => (
              <FormControlLabel
                className={
                  classes.checkbox
                }
                control={(
                  <Checkbox
                    checked={user.checked}
                    onChange={() => this.handleChange(user)}
                    value="user"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                label={`${user.name}`}
                labelPlacement="start"
                key={`${index + 1}`}
              />
            ))}
          </FormGroup>
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={this.onNextClick}
          disabled={!selectedUsers.length}
        >
          {I18n.t('buttons.next')}
        </Button>
      </Paper>
    )
  }

  render() {
    const {
      inProgress,
    } = this.props
    return !inProgress ? this.renderScreen() : null
  }
}

SelectNotificationUser.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  users: PropTypes.users.isRequired,
  saveSelectedUsers: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  })),
}

SelectNotificationUser.defaultProps = {

  selectedUsers: [],
}

SelectNotificationUser.displayName = 'SelectNotificationUser'

export default SelectNotificationUser
