import * as type from '../constants/apiRequest'

export const apiRequestSent = (api: string) => ({
  type: type.API_REQUEST_SENT,
  api,
} as const)

export const apiRequestDone = (api: string) => ({
  type: type.API_REQUEST_DONE,
  api,
} as const)

export const apiRequestFailed = (message: string) => ({
  type: type.API_REQUEST_FAILED,
  message,
} as const)

export const apiRequestClear = () => ({
  type: type.API_REQUEST_CLEAR,
} as const)
