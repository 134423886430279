import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import convertRoomOrGroupStateToBackend from '@app/helpers/scenes/convertRoomOrGroupStateToBackend'
import { roomConditionStates } from '@app/lib/Scenes/roomConditionStates'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_ROOM_CONDITION } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectRoomConditionState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_ROOM_CONDITION)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      isEditConditionMode,
      saveConditionStateDescription,
      saveConditionType,
      saveConditionRoomState,
    } = this.props
    saveConditionType('RoomActivity')
    saveConditionStateDescription(state.description)
    saveConditionRoomState(convertRoomOrGroupStateToBackend(state.stateType))
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            roomConditionStates.map((state) => (
              <CardBlock
                key={state.description}
                description={I18n.t(`scene_condition_web.${state.description}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectRoomConditionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionRoomState: PropTypes.func.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
}

SelectRoomConditionState.displayName = 'SelectRoomConditionStateIOS'

export default SelectRoomConditionState
