import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

export const mergeDevices = (currentDevices: Array<IZwaveOrTwrDevice>, newDevices: Array<IZwaveOrTwrDevice>): Array<IZwaveOrTwrDevice> => {
  const mergedDevices: Array<IZwaveOrTwrDevice> = []
  const devicesMap = new Map<string, IZwaveOrTwrDevice>()

  currentDevices.forEach((currentDevice) => {
    const key = `${currentDevice.id}_${currentDevice.device_type}`
    devicesMap.set(key, currentDevice)
  })

  newDevices.forEach((newDevice) => {
    const key = `${newDevice.id}_${newDevice.device_type}`
    const match = devicesMap.get(key)
    if (match) {
      const mergedDevice = { ...match, ...newDevice }
      mergedDevices.push(mergedDevice)
    } else {
      mergedDevices.push(newDevice)
    }
  })

  return mergedDevices
}
