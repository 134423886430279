import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4667 11.8H15.1333C15.3174 11.8 15.4667 11.9493 15.4667 12.1334V12.323C15.4667 12.4656 15.5624 12.5895 15.6941 12.6441C15.8258 12.6988 15.9792 12.678 16.0801 12.5771L16.2142 12.443C16.3444 12.3128 16.5554 12.3128 16.6856 12.443L17.157 12.9144C17.2872 13.0446 17.2872 13.2557 17.157 13.3858L17.0229 13.5199C16.922 13.6208 16.9012 13.7742 16.9559 13.906C17.0106 14.0377 17.1345 14.1334 17.2771 14.1334H17.4667C17.6508 14.1334 17.8 14.2826 17.8 14.4667V15.1334C17.8 15.3175 17.6508 15.4667 17.4667 15.4667H17.2771C17.1345 15.4667 17.0106 15.5624 16.9559 15.6941C16.9012 15.8259 16.922 15.9793 17.0229 16.0801L17.157 16.2142C17.2872 16.3444 17.2872 16.5555 17.157 16.6856L16.6856 17.157C16.5554 17.2872 16.3444 17.2872 16.2142 17.157L16.0801 17.023C15.9792 16.9221 15.8258 16.9012 15.6941 16.9559C15.5624 17.0106 15.4667 17.1345 15.4667 17.2771V17.4667C15.4667 17.6508 15.3174 17.8 15.1333 17.8H14.4667C14.2826 17.8 14.1333 17.6508 14.1333 17.4667V17.2771C14.1333 17.1345 14.0376 17.0106 13.9059 16.9559C13.7741 16.9012 13.6207 16.9221 13.5199 17.023L13.3858 17.157C13.2556 17.2872 13.0445 17.2872 12.9144 17.157L12.443 16.6856C12.3128 16.5555 12.3128 16.3444 12.443 16.2142L12.5771 16.0801C12.6779 15.9793 12.6988 15.8259 12.6441 15.6941C12.5894 15.5624 12.4655 15.4667 12.3229 15.4667H12.1333C11.9492 15.4667 11.8 15.3175 11.8 15.1334V14.4667C11.8 14.2826 11.9492 14.1334 12.1333 14.1334H12.3229C12.4655 14.1334 12.5894 14.0377 12.6441 13.906C12.6988 13.7742 12.6779 13.6208 12.5771 13.5199L12.443 13.3858C12.3128 13.2557 12.3128 13.0446 12.443 12.9144L12.9144 12.443C13.0445 12.3128 13.2556 12.3128 13.3858 12.443L13.5199 12.5771C13.6207 12.678 13.7742 12.6988 13.9059 12.6441C14.0376 12.5895 14.1333 12.4656 14.1333 12.323V12.1334C14.1333 11.9493 14.2826 11.8 14.4667 11.8Z" stroke="#FF0000" strokeWidth="0.5"/>
      <path d="M15.4667 14.8001C15.4667 15.1683 15.1682 15.4668 14.8 15.4668C14.4318 15.4668 14.1333 15.1683 14.1333 14.8001C14.1333 14.4319 14.4318 14.1334 14.8 14.1334C15.1682 14.1334 15.4667 14.4319 15.4667 14.8001Z" stroke="#FF0000" strokeWidth="0.5"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.57143 2H17.4286L18 2.57143V9H17C16.9523 9 16.9046 9.00048 16.8571 9.00143V3.14286H6.57143V5.42857C7.37143 5.42857 8.17143 5.88571 8.51429 6.57143H11.4857C11.8286 5.88571 12.6286 5.42857 13.4286 5.42857C14.6857 5.42857 15.7143 6.45714 15.7143 7.71429C15.7143 8.30788 15.485 8.85052 15.1105 9.25798C14.5002 9.42866 13.9236 9.67998 13.3929 9.99972C12.152 9.98048 11.1429 8.95951 11.1429 7.71429H8.85714C8.85714 8.97143 7.82857 10 6.57143 10C5.31429 10 4.28571 8.97143 4.28571 7.71429C4.28571 6.91429 4.74286 6.11429 5.42857 5.77143V3.14286H3.14286V16.8571H5.42857V13.4286H8.85714C8.85714 12.1714 9.88571 11.1429 11.1429 11.1429C11.3903 11.1429 11.6288 11.1827 11.8524 11.2563C11.5592 11.5743 11.2951 11.9195 11.0643 12.2878C10.4225 12.3214 10 12.7694 10 13.4286C10 13.7267 10.0864 13.9816 10.2404 14.1745C10.1326 14.5746 10.0594 14.9889 10.0242 15.4139C9.67059 15.2134 9.37544 14.9223 9.2 14.5714H6.57143V16.8571H10V18H2.57143L2 17.4286V2.57143L2.57143 2ZM7.71429 7.71429C7.71429 7.02857 7.25714 6.57143 6.57143 6.57143C5.88571 6.57143 5.42857 7.02857 5.42857 7.71429C5.42857 8.4 5.88571 8.85714 6.57143 8.85714C7.25714 8.85714 7.71429 8.4 7.71429 7.71429ZM14.5714 7.71429C14.5714 7.02857 14.1143 6.57143 13.4286 6.57143C12.7429 6.57143 12.2857 7.02857 12.2857 7.71429C12.2857 8.4 12.7429 8.85714 13.4286 8.85714C14.1143 8.85714 14.5714 8.4 14.5714 7.71429Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'TakeOnBoardSettingsIcon')

export default icon
