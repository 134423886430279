import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ExpandMore } from 'icons'
import styles from './SecretsItem.style'

class SecretsItem extends Component {
  render() {
    const {
      classes,
      name,
      info,
    } = this.props
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.info}>
            {info}
          </Typography>
        </AccordionDetails>
      </Accordion>
    )
  }
}

SecretsItem.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SecretsItem)
