import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { patchUnit, selectUnit } from '@app/actions/unit'
import { updateCurrentUserRole } from '@app/actions/users/currentUser'
import SelectMezzoConnection from '@app/components/Wizard/SelectMezzoConnection/SelectMezzoConnection'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'

let enhance = (SelectMezzoConnection)

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { devices } = state.units
  return {
    isMobile,
    isTablet,
    devices,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    userLoggedIn: false,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  selectUnit,
  patchUnit,
  updateCurrentUserRole,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectMezzoConnectionEnhanced = enhance

export default SelectMezzoConnectionEnhanced
