export const reminderData = (reminderName, startDate, numberOfDays, scheduleType, periodInDays, weekdays, dosages) => {
  const oneDay = 1
  return {
    name: reminderName,
    start_on: startDate.toISOString(),
    finish_after_days: numberOfDays,
    schedule_type: scheduleType === 'default' ? 'days_interval' : scheduleType,
    days_interval: (scheduleType === 'default' || scheduleType === 'days_of_weeks') ? oneDay : periodInDays,
    days_of_weeks: Object.keys(weekdays).filter((key) => weekdays[key]).join(','),
    pills_dosages: dosages.map((dosage) => ({
      amount: dosage.amount,
      time: dosage.time.toISOString(),
    })),
  }
}
