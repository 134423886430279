import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.182404891">
      <g id="Group-25" transform="translate(-521.000000, -233.000000)" fill="#000000">
        <g id="medical-history-reminders" transform="translate(521.000000, 233.000000)">
          <g id="medical-history">
            <path d="M179.525973,18.2310979 L163.958533,18.2310979 C159.43217,18.2310979 155.107564,16.0311737 152.387753,12.342793 C146.694331,4.61848783 137.575302,0 128,0 C118.424698,0 109.305669,4.61848783 103.60583,12.3420808 C100.892436,16.0311737 96.5671175,18.2303857 92.0414669,18.2303857 L76.4740271,18.2303857 C64.6359002,18.2303857 55,27.8562117 55,39.681962 L55,72.9229674 C55,76.2837452 57.7255139,79 61.0833927,79 L194.916607,79 C198.274486,79 201,76.2837452 201,72.9229674 L201,39.6826742 C201,27.8569239 191.3641,18.2310979 179.525973,18.2310979 Z M188.833215,66.8466469 L67.1667855,66.8466469 L67.1667855,39.6826742 C67.1667855,34.5599629 71.3459544,30.3851632 76.4740271,30.3851632 L92.0414669,30.3851632 C100.424044,30.3851632 108.411661,26.3321464 113.406416,19.5500554 C116.874086,14.8468182 122.190371,12.1540653 128,12.1540653 C133.809629,12.1540653 139.126627,14.846106 142.593584,19.5500554 C147.594043,26.3321464 155.581659,30.3851632 163.958533,30.3851632 L179.525973,30.3851632 C184.654046,30.3851632 188.833215,34.5599629 188.833215,39.6826742 L188.833215,66.8466469 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M127.5,25 C117.300754,25 109,33.3000313 109,43.5 C109,53.6992461 117.300031,62 127.5,62 C137.699246,62 146,53.6999687 146,43.5 C146,33.3000313 137.699969,25 127.5,25 Z M127.5,49.665944 C124.096156,49.665944 121.333333,46.9031212 121.333333,43.4992773 C121.333333,40.0954334 124.096156,37.3326106 127.5,37.3326106 C130.903844,37.3326106 133.666667,40.0954334 133.666667,43.4992773 C133.666667,46.9038439 130.903844,49.665944 127.5,49.665944 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M128,116 C124.688152,116 122,118.737735 122,122.12509 L122,158.87491 C122,162.262265 124.688152,165 128,165 C131.311848,165 134,162.262265 134,158.87491 L134,122.12509 C134,118.737735 131.311848,116 128,116 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M146.87491,134 L110.12509,134 C106.744195,134 104,136.681824 104,140 C104,143.318176 106.744195,146 110.12509,146 L146.87491,146 C150.255805,146 153,143.318176 153,140 C153,136.681824 150.255805,134 146.87491,134 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M109.899929,207 L61.1000715,207 C57.7329864,207 55,209.681824 55,213 C55,216.318176 57.7329864,219 61.1000715,219 L109.899929,219 C113.267014,219 116,216.318176 116,213 C116,209.681824 113.267014,207 109.899929,207 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M133.928521,238 L61.0714794,238 C57.7201765,238 55,240.681824 55,244 C55,247.318176 57.7201765,250 61.0714794,250 L133.928521,250 C137.279824,250 140,247.318176 140,244 C140,240.681824 137.279824,238 133.928521,238 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M157.945075,268 L61.0556344,268 C57.7130775,268 55,270.681824 55,274 C55,277.318176 57.7130775,280 61.0556344,280 L157.944366,280 C161.286922,280 164,277.318176 164,274 C164,270.681824 161.287632,268 157.945075,268 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M127.5,91 C100.757966,91 79,112.758676 79,139.5 C79,166.242034 100.758676,188 127.5,188 C154.242034,188 176,166.241324 176,139.5 C176,112.757966 154.242034,91 127.5,91 Z M127.5,175.874822 C107.445073,175.874822 91.1251776,159.560611 91.1251776,139.5 C91.1251776,119.439389 107.445783,103.125178 127.5,103.125178 C147.554927,103.125178 163.874822,119.439389 163.874822,139.5 C163.874822,159.560611 147.554927,175.874822 127.5,175.874822 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M334.45476,142.02796 C320.801465,134.065614 304.896682,131.950015 289.653976,136.022366 C274.331621,140.137455 261.528146,150.038744 253.609534,163.896987 L189.900085,275.407582 C173.613417,303.908335 183.321984,340.49894 211.54524,356.964997 C220.664978,362.2832 230.786721,365 241.047849,365 C246.145344,365 251.279108,364.32543 256.339624,362.96988 C271.661979,358.85479 284.465454,348.953501 292.384066,335.095258 L356.099915,223.584663 C372.386583,195.084622 362.678016,158.493305 334.45476,142.02796 Z M345.565707,217.543452 L281.856258,329.054047 C275.551237,340.080094 265.375447,347.956961 253.202758,351.221537 C241.109007,354.467593 228.481185,352.771552 217.649719,346.456097 C195.167545,333.339382 187.442364,304.176169 200.434293,281.450217 L264.143742,169.939622 C270.448763,158.913576 280.624553,151.036708 292.797242,147.772132 C304.884592,144.508269 317.513125,146.216419 328.344592,152.537573 C350.826766,165.653575 358.557636,194.816789 345.565707,217.543452 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M290.381652,287.103647 C287.599517,285.172715 283.772911,285.838801 281.825776,288.606995 L261.80232,317.09827 C257.656111,324.303454 249.929477,328.776971 241.637058,328.776971 L238.142293,328.776971 C234.751903,328.776971 232,331.508638 232,334.888485 C232,338.268333 234.751903,341 238.142293,341 L241.637058,341 C254.320428,341 266.132086,334.167251 272.170003,323.643814 L291.892571,295.616651 C293.833228,292.848456 293.157309,289.041025 290.381652,287.103647 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M304.95819,158 C292.785366,158 282.129012,164.495781 275.894635,175.545054 L257.970976,203.570733 C256.158575,206.406102 256.955748,210.188981 259.759306,212.02241 C260.774534,212.682731 261.910117,213 263.033664,213 C265.01527,213 266.954398,212.015964 268.108388,210.206886 L286.201252,181.900464 C290.466059,174.37123 297.129466,170.222381 304.958898,170.222381 C308.293433,170.222381 311,167.490859 311,164.111191 C311,160.731522 308.293433,158 304.95819,158 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M323.941383,269.726726 L231.158966,216.814842 C228.233929,215.135392 224.502307,216.137964 222.81685,219.032396 C221.131393,221.920455 222.139089,225.617794 225.0584,227.278836 L317.841533,280.19072 C318.806287,280.740153 319.856924,281 320.888953,281 C323.001679,281 325.05357,279.912464 326.183649,277.973166 C327.86839,275.085815 326.860694,271.388476 323.941383,269.726726 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M240.672002,30.0007122 L195.048741,30.0007122 C191.684268,30.0007122 188.953401,32.7169486 188.953401,36.0777037 L188.953401,66.4626615 L67.0480272,66.4626615 L67.0480272,36.0769916 C67.0480272,32.7162365 64.3171605,30 60.952687,30 L15.3294269,30 C6.87538366,30 0,36.8546869 0,45.2832812 L0,330.807877 C0,339.188044 6.83895306,346 15.2379932,346 L202.283431,346 C204.67285,346 206.842971,344.60841 207.835884,342.439124 C208.823081,340.269838 208.451632,337.723099 206.878687,335.930554 C193.450795,320.586026 191.244243,298.39322 201.399096,280.715605 L255.195669,186.991204 C255.725699,186.073922 256,185.028449 256,183.970868 L256,45.2839934 C256.001428,36.8553991 249.126045,30.0007122 240.672002,30.0007122 Z M243.810748,182.354943 L190.818505,274.675647 C180.170054,293.216418 180.358636,315.71902 190.562777,333.846729 L15.2379932,333.846729 C13.5557564,333.846729 12.1906803,332.479353 12.1906803,330.808589 L12.1906803,45.2839934 C12.1906803,43.5519832 13.598616,42.1546953 15.3294269,42.1546953 L54.8573469,42.1546953 L54.8573469,72.5396531 C54.8573469,75.9004081 57.5882136,78.6166446 60.952687,78.6166446 L195.048741,78.6166446 C198.413215,78.6166446 201.144082,75.9004081 201.144082,72.5396531 L201.144082,42.1539831 L240.672002,42.1539831 C242.402812,42.1539831 243.810748,43.5519832 243.810748,45.2832812 L243.810748,182.354943 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M225.911702,55 L195.47021,55 C192.109624,55 189.381912,57.7224862 189.381912,61.090974 L189.381912,67.181948 L67.6173742,67.181948 L67.6173742,61.090974 C67.6173742,57.7224862 64.8896625,55 61.529076,55 L31.0882983,55 C27.7277117,55 25,57.7224862 25,61.090974 L25,316.909026 C25,320.277514 27.7277117,323 31.0882983,323 L190.606279,323 C192.438548,323 194.180202,322.171262 195.336786,320.74649 C196.49337,319.327427 196.944302,317.451525 196.56686,315.654141 C194.131684,304.075366 195.994633,292.094714 201.814655,281.928576 L231.196599,230.557531 C231.726016,229.637425 232,228.59026 232,227.530247 L232,61.090974 C231.999286,57.7224862 229.272288,55 225.911702,55 Z M219.823403,225.917027 L191.245575,275.87472 C185.169406,286.491274 182.527315,298.673222 183.568312,310.818052 L37.175883,310.818052 L37.175883,67.181948 L55.4407777,67.181948 L55.4407777,73.2729219 C55.4407777,76.6414097 58.1684895,79.3638959 61.529076,79.3638959 L195.47021,79.3638959 C198.830797,79.3638959 201.558509,76.6414097 201.558509,73.2729219 L201.558509,67.181948 L219.823403,67.181948 L219.823403,225.917027 Z" id="Shape" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </g>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'RemindersHistory', '0 0 364 365', {fontSize: 220})

export default icon
