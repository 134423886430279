import type { Node } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, TextField, Zoom } from '@mui/material'
import useStyles from './TextFieldWithTooltip.style'

const TextFieldWithTooltip = (props): Node => {
  const classes = useStyles()
  const {
    tooltipTitle, label, value, handleValue, disabled, error,
  } = props

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, popper: classes.popper }}
      TransitionComponent={Zoom}
      title={tooltipTitle}
      arrow
      open={!disabled}
    >
      <TextField
        disabled={disabled}
        label={label}
        variant="standard"
        value={disabled ? '' : value}
        onChange={handleValue}
        error={error}
      />
    </Tooltip>
  )
}

TextFieldWithTooltip.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

TextFieldWithTooltip.defaultProps = {
  disabled: false,
  error: false,
}

export default TextFieldWithTooltip
