import { createLogic } from 'redux-logic'
import {
  UNSUBSCRIBE_VIRTUAL_SWITCH_CHANNEL,
  VIRTUAL_SWITCH_CHANNEL,
} from '@app/constants/virtualSwitches/virtualSwitches'
import openCable from '@app/api/cable'

const unsubscribeVirtualSwitchChannelLogic = createLogic({
  type: UNSUBSCRIBE_VIRTUAL_SWITCH_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(VIRTUAL_SWITCH_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeVirtualSwitchChannelLogic
