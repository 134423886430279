import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  tooltip: {
    marginTop: '7px !important',
  },
  popper: {
    zIndex: 1000,
  },
}))

export default useStyles
