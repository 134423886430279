import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 40">
      <path fill="currentColor" d="M5.24 19.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.24-1.15c1 0 1.99-.29 2.77-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.13.1.37.37 0 0 0-.07.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M18.79 9.77V3.02c0-.29-.19-.51-.32-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.74 20.74 0 0 0 9.39 0C6.93 0 4.65.38 2.97.93a8.1 8.1 0 0 0-2.06.96c-.48.34-.91.8-.91 1.37v6.5c0 .65.47 1.22 1.03 1.68.59.47 1.39.92 2.3 1.31.83.36 1.78.68 2.75.91.13.54.56.97 1.06 1.24.61.34 1.43.54 2.3.54.87 0 1.69-.2 2.3-.54.5-.28.94-.7 1.06-1.25a17.4 17.4 0 0 0 2.67-.88 9.7 9.7 0 0 0 2.29-1.32c.56-.46 1.03-1.03 1.03-1.68Zm-5.97 3.4.68-.2c.04-.13.06-.27.06-.41 0-1.32-1.84-2.4-4.12-2.4-2.28 0-4.12 1.08-4.12 2.4 0 .15.02.3.07.44h-.01l.68.18c.07-.62.54-1.09 1.08-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.69.2 2.3.54.54.3 1 .77 1.08 1.37Zm-7.96-.34c-2.41-.79-4.4-2.02-4.4-3.06V7.33C1.29 6.85 4.72 5.03 9.3 5c4.6 0 8.19 1.85 9.02 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04.02-.08.02-.17.02-.25 0-.92-.63-1.65-1.45-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.82.47-1.45 1.2-1.45 2.12 0 .09 0 .18.02.27ZM.46 3.26c0-1.3 4.1-2.8 8.93-2.8 4.84 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.02-2.3c-4.43 0-8 1.84-8.83 2.31v-1.3Zm0 1.93v1.5A19.1 19.1 0 0 1 9.3 4.37a18.1 18.1 0 0 1 9.02 2.31V5.2A19.25 19.25 0 0 0 9.3 2.87 18.1 18.1 0 0 0 .47 5.2Zm11.91 8.16c0 .9-1.31 1.62-2.93 1.62s-2.93-.72-2.93-1.62c0-.9 1.31-1.63 2.93-1.63s2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M17.25 23.94H1.8a.28.28 0 0 0-.28.28v15.5a.28.28 0 0 0 .28.28h15.45a.28.28 0 0 0 .28-.28v-15.5a.28.28 0 0 0-.28-.28Zm-.29 15.5H2.08V24.5h14.88v14.92Z"/>
      <path fill="currentColor" d="M2.94 38.86H16.1a.28.28 0 0 0 .28-.28v-13.2a.28.28 0 0 0-.28-.28H2.94a.28.28 0 0 0-.29.28v13.2a.28.28 0 0 0 .29.28Zm6.3-7.44v-5.75h.57v5.75a.28.28 0 0 0 .28.29h5.74v.57h-5.74a.28.28 0 0 0-.28.28v5.73h-.57v-5.73a.28.28 0 0 0-.29-.28H3.22v-.57h5.73a.29.29 0 0 0 .29-.29Zm-6.02 1.42h5.45v5.45H3.22v-5.45Zm7.16 5.45v-5.45h5.45v5.45h-5.45Zm5.45-7.15h-5.45v-5.47h5.45v5.47Zm-7.16-5.47v5.47H3.22v-5.47h5.45Z"/>
      <path fill="currentColor" d="m4.45 27.62.76-.75a.28.28 0 0 0 0-.4.28.28 0 0 0-.4 0l-.76.75a.28.28 0 1 0 .4.4Zm.03 1.5a.28.28 0 0 0 .4 0L6.7 27.3a.28.28 0 0 0-.4-.4l-1.82 1.8a.28.28 0 0 0 0 .4Zm7.82 4.83a.28.28 0 1 0-.41-.4l-.76.75a.28.28 0 0 0-.06.32.29.29 0 0 0 .38.15.28.28 0 0 0 .09-.06l.75-.76Zm1.08.03-1.82 1.82a.28.28 0 0 0 .4.4l1.83-1.82a.29.29 0 1 0-.4-.4Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'WindowIcon', '0 0 80 80', {fontSize: 35})

export default icon
