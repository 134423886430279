import type { Node } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { PILLS_REMINDERS_EVENTS, PILLS_REMINDERS } from '@app/constants/routes'
import Tabs from '@app/components/Tabs/Tabs'

const RemindersTabs = ({ active }): Node => {
  const tabs = [
    { route: PILLS_REMINDERS, titleKey: 'my_reminders.title_tab_reminders' },
    { route: PILLS_REMINDERS_EVENTS, titleKey: 'my_reminders.title_tab_history' },
  ]

  return (
    <Tabs tabs={tabs} active={active} />
  )
}

RemindersTabs.propTypes = {
  active: PropTypes.string.isRequired,
}

export default RemindersTabs
