import ActionCable from 'actioncable'
import { CABLE_URL } from '@app/constants/endpoints'

const openCable = () => {
  const { secrets: { accessToken } } = store.getState()

  if (!accessToken) {
    return null
  }

  if (global.WsIsOpen) {
    return global.cable
  }
  global.WsIsOpen = true

  const encodeData = (data) => Object.keys(data).map((key) => (
    [key, data[key]].map(encodeURIComponent).join('=')
  )).join('&')

  const params = encodeData({ access_token: accessToken.token })
  const cable = ActionCable.createConsumer(`${CABLE_URL}/cable?${params}`)

  global.cable = cable
  return cable
}

export default openCable
