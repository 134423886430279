import Axios from 'axios'
import { API_URL } from '../../constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/${id}/toggle`

const patchEnableScene = (accessToken, id, data) => Axios.patch(ENDPOINT(id), data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  patchEnableScene,
}
