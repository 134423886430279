export const conditionTimeTypeToBackend = {
  after_sunset: 'Dark',
  after_sunrise: 'Light',
  when_sunset: 'SunWithOffset',
  when_sunrise: 'SunWithOffset',
  at_this_time: 'Time',
  between_these_times: 'TimeLimits',
  before_this_time: 'Time',
  after_this_time: 'Time',
  at_this_date: 'Date',
  between_these_dates: 'BetweenDatesSimple',
}
