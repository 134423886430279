import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 42">
      <path fill="currentColor" fillRule="evenodd" d="M1.772 37.475h3.761v3.466H1.772v-3.466zm1.667 1.666v.133h.428v-.133h-.428zM18.237 37.475h3.761v3.466h-3.761v-3.466zm1.667 1.666v.133h.427v-.133h-.427z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M0 1.533C0 .693.645 0 1.515 0h20.898c.87 0 1.515.693 1.515 1.533v35.935c0 .84-.645 1.533-1.515 1.533H1.515c-.87 0-1.515-.693-1.515-1.533V1.533zm1.667.134v35.668h20.595V1.667H1.667z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M9.158 0h5.564v26.423c0 1.525-1.168 2.832-2.782 2.832-1.552 0-2.782-1.293-2.782-2.832V0zm1.667 1.667v24.756c0 .66.523 1.166 1.115 1.166.627 0 1.115-.493 1.115-1.166V1.667h-2.23z" clipRule="evenodd"/>
      <path fill="currentColor" d="M.395 39.567h23.333v1.9H.395v-1.9z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'MezzoDeviceIcon', '0 0 30 30', {fontSize: 30})

export default icon
