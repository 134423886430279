import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  activateChangeSensorMode,
  activateChangeSensorStateMode,
  deactivateChangeSensorMode,
  deactivateChangeSensorStateMode,
  saveConditionStateDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectBinarySensorStateSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/BinarySensorConditions/SelectBinarySensorStateSelected/SelectBinarySensorStateSelected'
import SelectBinarySensorStateSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/BinarySensorConditions/SelectBinarySensorStateSelected/SelectBinarySensorStateSelected'

import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectBinarySensorStateSelectedIOS) : withStyles(SelectBinarySensorStateSelected)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const { sensorTypeDescription, sensorValueDescription } = state.scenes.currentSceneCreating.currentCondition
  return {
    sensorType: sensorTypeDescription,
    sensorValue: sensorValueDescription,
    isEditConditionMode,
    isChangeConditionStateMode,
    isMobile,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionStateDescription,
  activateChangeSensorMode,
  activateChangeSensorStateMode,
  deactivateChangeSensorMode,
  deactivateChangeSensorStateMode,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectBinarySensorStateSelectedEnhanced = enhance

export default withRouter(SelectBinarySensorStateSelectedEnhanced)
