import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path fill="currentColor" d="M.234 11.682c.13 0 .235-.105.235-.234V4.622h13.848v2.576a.234.234 0 00.468 0V2.566c0-.786-.639-1.425-1.425-1.425h-.614V.85a.57.57 0 00-.57-.57h-.435a.57.57 0 00-.57.57v.29H9.618V.85a.57.57 0 00-.569-.57h-.435a.57.57 0 00-.57.57v.29H6.49V.85a.57.57 0 00-.57-.57h-.435a.57.57 0 00-.57.57v.29H3.36V.85a.57.57 0 00-.569-.57h-.435a.57.57 0 00-.57.57v.29h-.361C.639 1.14 0 1.78 0 2.566v8.882c0 .13.105.234.234.234zM11.64.851a.1.1 0 01.101-.101h.436a.1.1 0 01.1.1v1.14a.1.1 0 01-.1.1h-.436a.1.1 0 01-.1-.1V.85zm-3.128 0a.1.1 0 01.1-.101h.436a.1.1 0 01.1.1v1.14a.1.1 0 01-.1.1h-.435a.1.1 0 01-.1-.1V.85zm-3.128 0a.1.1 0 01.1-.101h.436a.1.1 0 01.1.1v1.14a.1.1 0 01-.1.1h-.436a.1.1 0 01-.1-.1V.85zm-3.129 0a.1.1 0 01.1-.101h.436a.1.1 0 01.1.1v1.14a.1.1 0 01-.1.1h-.435a.1.1 0 01-.1-.1V.85zm-.83.759h.361v.38c0 .313.256.569.57.569h.435a.57.57 0 00.57-.57v-.38h1.554v.38c0 .314.255.57.57.57h.435a.57.57 0 00.569-.57v-.38h1.554v.38c0 .314.256.57.57.57h.435a.57.57 0 00.57-.57v-.38h1.554v.38c0 .314.255.57.57.57h.434a.57.57 0 00.57-.57v-.38h.614c.528 0 .957.43.957.957v1.587H.469V2.566c0-.527.429-.956.956-.956zM2.653 13.862H.61a.141.141 0 01-.141-.141v-1.205a.234.234 0 10-.469 0v1.205c0 .336.274.61.61.61h2.043a.234.234 0 000-.47z"/>
      <path fill="currentColor" d="M2.646 5.305H1.83a.234.234 0 000 .469h.815a.234.234 0 100-.469zM4.335 5.774h.814a.234.234 0 100-.469h-.814a.234.234 0 000 .469zM6.838 5.774h.815a.234.234 0 100-.469h-.815a.234.234 0 100 .469zM9.342 5.774h.814a.234.234 0 100-.469h-.814a.234.234 0 000 .469zM12.66 5.305h-.815a.234.234 0 100 .469h.815a.234.234 0 100-.469zM2.646 6.484H1.83a.234.234 0 100 .468h.815a.234.234 0 100-.468zM4.335 6.952h.814a.234.234 0 100-.468h-.814a.234.234 0 100 .468zM6.838 6.952h.815a.234.234 0 100-.468h-.815a.234.234 0 100 .468zM9.342 6.952h.814a.234.234 0 100-.468h-.814a.234.234 0 100 .468zM12.66 6.484h-.815a.234.234 0 100 .468h.815a.234.234 0 100-.468zM4.34 11.554l1.205 1.205c.175.174.406.27.653.27a.916.916 0 00.653-.27l3.3-3.3a.924.924 0 00-1.306-1.306l-2.647 2.648-.552-.553a.924.924 0 00-1.306 1.306zm.332-.974a.453.453 0 01.642 0l.718.718c.092.091.24.091.332 0l2.813-2.813a.455.455 0 01.642.642l-3.3 3.3a.451.451 0 01-.642 0l-1.205-1.205a.455.455 0 010-.642z"/>
      <path fill="currentColor" d="M14.785 10.136v-1.87a.234.234 0 10-.469 0v1.571a3.09 3.09 0 00-1.426-.347 3.113 3.113 0 00-2.841 4.372H3.72a.234.234 0 000 .469h6.588a3.109 3.109 0 104.476-4.195zm-1.895 5.105a2.644 2.644 0 01-2.64-2.641 2.644 2.644 0 012.64-2.64 2.644 2.644 0 012.641 2.64 2.644 2.644 0 01-2.64 2.64z"/>
      <path fill="currentColor" d="M12.89 10.298a2.305 2.305 0 00-2.302 2.302 2.305 2.305 0 002.303 2.302 2.305 2.305 0 002.302-2.302 2.305 2.305 0 00-2.303-2.302zm.21 4.123a.234.234 0 00.025-.104v-.286a.234.234 0 00-.469 0v.286c0 .037.009.072.025.104a1.836 1.836 0 01-1.611-1.611.232.232 0 00.104.024h.285a.234.234 0 100-.468h-.285a.233.233 0 00-.104.024 1.836 1.836 0 011.61-1.611.231.231 0 00-.024.104v.285a.234.234 0 10.469 0v-.285a.234.234 0 00-.025-.104 1.836 1.836 0 011.611 1.611.233.233 0 00-.104-.024h-.285a.234.234 0 100 .468h.286a.232.232 0 00.103-.024 1.836 1.836 0 01-1.61 1.61z"/>
      <path fill="currentColor" d="M13.407 12.366h-.282v-.24a.234.234 0 00-.469 0v.474c0 .13.105.234.234.234h.517a.234.234 0 100-.468z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CalendarIcon', '0 0 16 16')

export default icon
