import * as type from '@app/constants/myEnergy/billingPeriod'

export const initialState = {
  isSetUpPeriodPopupOpen: false,
  isBillingPeriodsStatsPopupOpen: false,
}

const billingPeriod = (state = initialState, action) => {
  switch (action.type) {
  case type.SET_UP_BILLING_PERIOD_OPEN: {
    return {
      ...state,
      isSetUpPeriodPopupOpen: true,
    }
  }
  case type.SET_UP_BILLING_PERIOD_CLOSE: {
    return {
      ...state,
      isSetUpPeriodPopupOpen: false,
    }
  }
  case type.BILLING_PERIODS_STATS_POPUP_OPEN: {
    return {
      ...state,
      isBillingPeriodsStatsPopupOpen: true,
    }
  }
  case type.BILLING_PERIODS_STATS_POPUP_CLOSE: {
    return {
      ...state,
      isBillingPeriodsStatsPopupOpen: false,
    }
  }
  default:
    return state
  }
}

export default billingPeriod
