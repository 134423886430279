import {
  SELECT_ACTION_DEVICE_STATE_TYPE,
  SELECT_ACTION_ROOM_STATE_TYPE,
  SELECT_MEZZO_ACTION_STATE,
  SELECT_MEZZO_ACTION_STATE_TYPE,
  SELECT_ACTION_GROUP_STATE_TYPE,
  SELECT_ACTION_TIMER_VALUE,
  SETTINGS_TYPE,
  SELECT_VIRTUAL_SWITCH_ACTION_VALUE,
  SELECT_VIRTUAL_SWITCH_ACTION_STATE,
} from '@app/constants/routes'

const getRedirectForActionStateChangeButton = (type: string): string => {
  if (type === 'PlayAudio'
    || type === 'ArmTheSystem'
    || type === 'ArmInHomeMode'
    || type === 'Disarm'
    || type === 'ToggleVoice'
    || type === 'MuteOrUnmuteVoice'
    || type === 'AlarmPanicDuress'

  ) {
    return SELECT_MEZZO_ACTION_STATE
  } if (type === 'SetZwaveColor'
    || type === 'ZwaveColorSetWithDuration'
    || type === 'ZwaveColorDelayDuration'
    || type === 'SetDeviceStatus'
    || type === 'Toggle'
    || type === 'ToggleZwaveDeviceWithDuration'
    || type === 'DelayedDeviceAction'
    || type === 'DelayedPlusDurationDeviceAction'
    || type === 'ZwaveMultichannelControl'
  ) {
    return SELECT_ACTION_DEVICE_STATE_TYPE
  }
  if (type === 'MezzoTopLightColor'
    || type === 'MezzoBottomLightColor'
    || type === 'LightMezzo') {
    return SELECT_MEZZO_ACTION_STATE_TYPE
  }
  if (type === 'ControlRoom'
    || type === 'RoomColorControl'
    || type === 'RoomColorWithDuration'
    || type === 'RoomColorDelayDuration') {
    return SELECT_ACTION_ROOM_STATE_TYPE
  }
  if (type === 'ControlGroup'
    || type === 'GroupColorControl'
    || type === 'GroupColorWithDuration'
    || type === 'GroupColorDelayDuration') {
    return SELECT_ACTION_GROUP_STATE_TYPE
  }
  if (type === 'StopTimer'
    || type === 'StartTimer') {
    return SELECT_ACTION_TIMER_VALUE
  }
  if (type === 'PushNotifications'
    || type === 'Email'
    || type === 'WebRequest') {
    return SETTINGS_TYPE
  }
  if (type === 'VirtualSwitch') {
    return SELECT_VIRTUAL_SWITCH_ACTION_VALUE
  }
  if (type === 'VirtualSwitchDelayDuration') {
    return SELECT_VIRTUAL_SWITCH_ACTION_STATE
  }
  throw new Error('action unsupported')
}

export default getRedirectForActionStateChangeButton
