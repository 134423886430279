import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.78022 115.955H40.4221C41.1826 115.955 41.8023 115.34 41.8023 114.576V34.7802C41.8023 34.0182 41.1841 33.4 40.4221 33.4H4.78022C4.01817 33.4 3.4 34.0182 3.4 34.7802V114.575C3.4 115.337 4.01817 115.955 4.78022 115.955ZM39.0419 36.1604V113.194H6.16043V36.1604H39.0419Z" fill="currentColor" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M109.78 115.955H145.422C146.183 115.955 146.802 115.34 146.802 114.576V34.7802C146.802 34.0182 146.184 33.4 145.422 33.4H109.78C109.018 33.4 108.4 34.0182 108.4 34.7802V114.575C108.4 115.337 109.018 115.955 109.78 115.955ZM144.042 36.1604V113.194H111.16V36.1604H144.042Z" fill="currentColor" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M45.7421 115.972H104.258C105.02 115.972 105.639 115.356 105.639 114.591V80.5458C105.639 79.7838 105.02 79.1656 104.258 79.1656H45.7421C44.9801 79.1656 44.3619 79.7838 44.3619 80.5458V114.591C44.3619 115.353 44.9801 115.972 45.7421 115.972ZM102.878 81.9261V113.211H47.1223V81.9261H102.878Z" fill="currentColor" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M88.7933 91.3225C88.7933 95.3039 92.0316 98.5403 96.0127 98.5403C99.9939 98.5403 103.23 95.3037 103.23 91.3225C103.23 87.3414 99.9941 84.1031 96.0127 84.1031C92.0314 84.1031 88.7933 87.3413 88.7933 91.3225ZM91.5537 91.3209C91.5537 88.8622 93.554 86.862 96.0127 86.862C98.4697 86.862 100.47 88.8621 100.47 91.3209C100.47 93.778 98.4698 95.7783 96.0127 95.7783C93.5539 95.7783 91.5537 93.7779 91.5537 91.3209Z" fill="currentColor" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M45.7412 103.727H103.725C104.49 103.727 105.106 103.108 105.106 102.347C105.106 101.585 104.487 100.966 103.725 100.966H45.7412C44.9791 100.966 44.3609 101.585 44.3609 102.347C44.3609 103.109 44.9791 103.727 45.7412 103.727Z" fill="currentColor" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M36 52.5C36 59.9558 29.9558 66 22.5 66C15.0442 66 9 59.9558 9 52.5C9 45.0442 15.0442 39 22.5 39C29.9558 39 36 45.0442 36 52.5ZM36 97C36 104.456 29.9558 110.5 22.5 110.5C15.0442 110.5 9 104.456 9 97C9 89.5442 15.0442 83.5 22.5 83.5C29.9558 83.5 36 89.5442 36 97ZM27 74.75C27 77.0972 25.0972 79 22.75 79C20.4028 79 18.5 77.0972 18.5 74.75C18.5 72.4028 20.4028 70.5 22.75 70.5C25.0972 70.5 27 72.4028 27 74.75Z" stroke="currentColor" strokeWidth="4"/>
      <path d="M141 52.5C141 59.9558 134.956 66 127.5 66C120.044 66 114 59.9558 114 52.5C114 45.0442 120.044 39 127.5 39C134.956 39 141 45.0442 141 52.5ZM141 97C141 104.456 134.956 110.5 127.5 110.5C120.044 110.5 114 104.456 114 97C114 89.5442 120.044 83.5 127.5 83.5C134.956 83.5 141 89.5442 141 97ZM132 74.75C132 77.0972 130.097 79 127.75 79C125.403 79 123.5 77.0972 123.5 74.75C123.5 72.4028 125.403 70.5 127.75 70.5C130.097 70.5 132 72.4028 132 74.75Z" stroke="currentColor" strokeWidth="4"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Stereo')

export default icon
