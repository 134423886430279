import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#141414',
    minHeight: '100vh',
  },
  rootMobile: {
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    minHeight: 'calc(100vh - 42px)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px 3%',
    background: '#141414',
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 42,
    background: '#272827',
    position: 'relative',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: 18,
    padding: 3,
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  subTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 15,
  },
  typeCard: {
    backgroundColor: '#141414',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 88,
    padding: '16px',
    borderRadius: 5,
    border: `1px solid ${theme.backgrounds.cardBackground}`,
    marginBottom: 45,
    cursor: 'pointer',
  },
  chevronIcon: {
    transform: 'rotate(90deg)',
  },
  chevronIconOpened: {
    transform: 'rotate(270deg)',
  },
  typeList: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 360,
    padding: '16px 0',
    border: '1px solid rgba(255, 255, 255, 0.01)',
    borderRadius: 5,
  },
  typeListMobile: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 300,
    padding: '0',
  },
  typeItem: {
    backgroundColor: '#141414',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    transition: '0.2s ease-in',
    padding: '8px 20px',
    '&:hover': {
      backgroundColor: theme.backgrounds.hover,
    },
  },
  typeCardTitle: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 10,
  },
  typeCardDescription: {
    color: '#929292',
    fontSize: '16px',
    lineHeight: '19px',
  },
  typeTitle: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  typeDescription: {
    color: '#929292',
    fontSize: '16px',
    lineHeight: '19px',
  },
  durationCard: {
    position: 'relative',
    backgroundColor: '#141414',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: 5,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: `1px solid ${theme.backgrounds.cardBackground}`,
    marginBottom: 150,
  },
  durationCardTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 15,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  timerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    marginBottom: 20,
  },
  timerWrapperMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
    marginBottom: 20,
  },
  timeInput: {
    width: 52,
  },
  button: {
    position: 'relative',
    width: '100%',
    height: 50,
    marginTop: 20,
    margin: '0 auto',
    maxWidth: 300,
    bottom: 20,
  },
}))

export default useStyles
