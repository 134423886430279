import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/${id}/webhook_settings`

const patchEnableWebhook = (accessToken, id, data) => Axios.patch(ENDPOINT(id), data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  patchEnableWebhook,
}
