import React from 'react'
import { ChromePicker } from 'react-color'
import { withStyles } from '@mui/styles'
import getContrastColor from '@app/helpers/scenes/getContrastColor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './RGBColorPicker.style'

class RGBColorPicker extends React.Component {
  state = {
    oldColor: '',
  }

  componentDidMount() {
    const { oldColor } = this.props
    this.setState({ oldColor })
  }

  render() {
    const {
      classes,
      color,
      changeColor,
    } = this.props
    const { oldColor } = this.state
    return (
      <Wrapper className={classes.pickerContainer}>
        <Wrapper className={classes.colorWrapper}>
          <div
            className={classes.currentColor}
            style={{ backgroundColor: oldColor, color: getContrastColor(oldColor) }}
          >
            {I18n.t('scene_web.current_colour')}
          </div>
          <div
            className={classes.currentColor}
            style={{ backgroundColor: color, color: getContrastColor(color), borderColor: getContrastColor(color) }}
          >
            {I18n.t('scene_web.new_colour')}
          </div>
        </Wrapper>
        <Wrapper className={classes.pickerWrapper}>
          <Wrapper className={classes.overflow}>
            <ChromePicker disableAlpha color={color} onChange={changeColor} className={classes.slider} />
          </Wrapper>
        </Wrapper>
      </Wrapper>

    )
  }
}

RGBColorPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  oldColor: PropTypes.string.isRequired,
  changeColor: PropTypes.func.isRequired,
}

export default withStyles(styles)(RGBColorPicker)
