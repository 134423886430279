import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { TRIGGER_VIRTUAL_SWITCH } from '@app/constants/virtualSwitches/virtualSwitches'
import { startEnablingProgress, stopEnablingProgress } from '@app/actions/virtualSwitches/virtualSwitches'
import Api from '@app/api/virtualSwitches/triggerVirtualSwitch'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const triggerVirtualSwitchLogic = createLogic({
  type: TRIGGER_VIRTUAL_SWITCH,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { virtualSwitches: { changedVirtualSwitchData } } = getState()
    next({
      ...action,
      accessToken,
      changedVirtualSwitchData,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(startEnablingProgress(action.changedVirtualSwitchData.number))
    const data = {
      number: action.changedVirtualSwitchData.number,
    }
    dispatch(apiRequestSent(TRIGGER_VIRTUAL_SWITCH))
    Api.triggerVirtualSwitch(action.accessToken, data)
      .then(() => {
        dispatch(apiRequestDone(TRIGGER_VIRTUAL_SWITCH))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(stopEnablingProgress(action.id))
        } else {
          throw new Error('triggerVirtualSwitch failed without error')
        }
      })
      .then(() => done())
  },
})

export default triggerVirtualSwitchLogic
