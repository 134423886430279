import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 12">
      <path fill="#fff" d="M27.167 6L21.5.333v4.25H.25v2.834H21.5v4.25L27.167 6z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'LightSliderArrow', '0 0 17 17', {fontSize: 26})

export default icon
