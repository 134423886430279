import getDatesForWeekPeriod from './getDatesForWeekPeriod'
import getMonthsForPeriod from './getMonthsForPeriod'

const getData = (pointsArray, currentChart, type, annotation) => {
  const period = pointsArray.map((point, index) => {
    point.push(currentChart[type][index])
    return point
  })
  period.unshift(annotation)
  return period
}

const weekPeriod = (unit, energies, currentChart) => {
  const weekDaysArr = getDatesForWeekPeriod()
  return getData(weekDaysArr, currentChart, 'week', ['Day', 'amount'], unit)
}

const monthPeriod = (unit, energies, currentChart) => {
  const monthWeeksArr = [['1st week'], ['2nd week'], ['3rd week'], ['4th week']]
  return getData(monthWeeksArr, currentChart, 'month', ['Week', 'amount'], unit)
}

const sixMonthPeriod = (unit, energies, currentChart, period) => {
  const sixMonthArr = getMonthsForPeriod(energies.current_time, period)
  return getData(sixMonthArr, currentChart, 'half_year', ['SixMonth', 'amount'], unit)
}

const yearPeriod = (unit, energies, currentChart, period) => {
  const yearMonthsArr = getMonthsForPeriod(energies.current_time, period)
  return getData(yearMonthsArr, currentChart, 'year', ['Year', 'amount'], unit)
}

const getDataForGraph = (period, energies, unit) => {
  const currentChart = energies.chart[unit]

  switch (period) {
  case 'last_week_period':
    return weekPeriod(unit, energies, currentChart)
  case 'last_month_period':
    return monthPeriod(unit, energies, currentChart)
  case 'last_6_month_period':
    return sixMonthPeriod(unit, energies, currentChart, period)
  case 'last_year_period':
    return yearPeriod(unit, energies, currentChart, period)
  default:
    return weekPeriod
  }
}

export default getDataForGraph
