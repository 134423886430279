import { drawerWidth } from '@app/lib/drawer'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    width: 0,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  toolbarUserInfo: {
    flexGrow: 1,
    display: 'block',
    paddingLeft: '10px',
  },
}))

export default useStyles
