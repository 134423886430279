import React from 'react'
import {
  Button, FormControlLabel, Paper, Radio, RadioGroup, Typography, TextField,
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Calendar } from 'icons'
import classNames from 'classnames'
import { convertTimeDataToBackend } from '@app/helpers/scenes/convertTimeDataToBackend'
import { conditionTimeTypeToBackend } from '@app/lib/Scenes/conditionTimeTypeToBackend'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_TIME_CONDITION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import PropTypes from '@app/components/PropTypes'

class SelectTimeConditionStateDate extends React.Component {
  state = {
    selected: 'at_this_date',
    atDate: new Date(),
    betweenStartDate: new Date(),
    betweenFinishDate: new Date((Date.now() + 86400 * 1000)),
    isAtDateIncorrect: false,
    isBetweenDatesIncorrect: false,
  }

  componentDidMount() {
    const {
      isEditConditionMode,
      stateTimeData,
      type,
    } = this.props
    if (isEditConditionMode) {
      switch (type) {
      case 'Date':
        return this.setState({
          selected: 'at_this_date',
          atDate: new Date(stateTimeData),
        })
      case 'BetweenDatesSimple':
        return this.setState({
          selected: 'between_these_dates',
          betweenStartDate: new Date(stateTimeData.from),
          betweenFinishDate: new Date(stateTimeData.to),
        })
      default:
        return false
      }
    }
    return null
  }

  handleChange = (button) => {
    this.setState({ selected: button.target.value })
  }

  handleAtDateChange = (selectedDate) => {
    this.setState({ atDate: selectedDate })
  }

  handleBetweenStartDateChange = (selectedDate) => {
    this.setState({ betweenStartDate: selectedDate })
  }

  handleBetweenFinishDateChange = (selectedDate) => {
    this.setState({ betweenFinishDate: selectedDate })
  }

  descriptionRender=() => {
    const {
      selected,
      atDate,
      betweenStartDate,
      betweenFinishDate,
    } = this.state
    switch (selected) {
    case 'at_this_date':
      return `it's ${atDate.toLocaleDateString('en-GB')}`
    case 'between_these_dates':
      return `${betweenStartDate.toLocaleDateString('en-GB')} to ${betweenFinishDate.toLocaleDateString('en-GB')}`
    default:
      return ''
    }
  }

  backendTimeData=() => {
    const {
      selected,
      atDate,
      betweenStartDate,
      betweenFinishDate,
    } = this.state
    switch (selected) {
    case 'at_this_date':
      return convertTimeDataToBackend(atDate)
    case 'between_these_dates':
      return {
        from: convertTimeDataToBackend(betweenStartDate),
        to: convertTimeDataToBackend(betweenFinishDate),
      }
    default:
      return ''
    }
  }

  onAddStateClick=() => {
    const { selected } = this.state
    const {
      saveConditionType,
      saveConditionDescription,
      saveConditionStateDescription,
      saveConditionStateTimeData,
      isEditConditionMode,
      navigate,
    } = this.props
    saveConditionType(conditionTimeTypeToBackend[selected])
    saveConditionDescription('time_condition_state_date')
    saveConditionStateDescription(this.descriptionRender())
    saveConditionStateTimeData(this.backendTimeData())
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  onBackClick=() => {
    const {
      navigate,
      isEditConditionMode,
      isChangeConditionStateMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_TIME_CONDITION_TYPE)
    }
  }

  timesConflictChecker=() => {
    const {
      selected, atDate, betweenStartDate, betweenFinishDate,
    } = this.state
    const today = new Date()
    const hour = 3600000
    switch (selected) {
    case 'at_this_date':
      if (atDate < today && atDate.toDateString() !== today.toDateString()) {
        this.setState({ isAtDateIncorrect: true })
      } else {
        this.setState({ isAtDateIncorrect: false })
        this.onAddStateClick()
      }
      break
    case 'between_these_dates':
      if (Date.parse(betweenStartDate) > Date.parse(betweenFinishDate)) {
        this.setState({ isBetweenDatesIncorrect: true })
      } else if (betweenStartDate.toDateString() === betweenFinishDate.toDateString()
        || betweenStartDate.getTime() + hour < today.getTime()) {
        this.setState({ isAtDateIncorrect: true })
      } else {
        this.setState({ isBetweenDatesIncorrect: false, isAtDateIncorrect: false })
        this.onAddStateClick()
      }
      break
    default:
      this.onAddStateClick()
    }
  }

  popupClose=() => {
    this.setState({ isAtDateIncorrect: false, isBetweenDatesIncorrect: false })
  }

  render() {
    const {
      classes,
      isMobile,
      isTablet,
    } = this.props
    const {
      selected,
      atDate,
      betweenStartDate,
      betweenFinishDate,
      isBetweenDatesIncorrect,
      isAtDateIncorrect,
    } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RadioGroup className={classes.radioWrapper} aria-label="date" value={selected} onChange={this.handleChange}>
              <Wrapper className={classNames(
                classes.dateWrapper,
                (!isMobile || isTablet) && classes.dateWrapperDesktop,
              )}
              >
                <FormControlLabel
                  className={classes.buttonWrapper}
                  value="at_this_date"
                  control={(
                    <Radio
                      classes={(!isMobile || isTablet) ? { root: classes.radioIconDesktop } : { root: classes.radioIcon }}
                      color="primary"
                    />
                  )}
                  label={I18n.t('scene_web.time_condition_state_at_this_date')}
                  labelPlacement="start"
                />
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={atDate}
                  onChange={(selectedDate) => this.handleAtDateChange(selectedDate)}
                  KeyboardButtonProps={{ classes: { root: classes.iconButton } }}
                  keyboardIcon={<Calendar />}
                  disabled={selected !== 'at_this_date'}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      variant="standard"
                      className={classes.datePicker}
                    />
                  )}
                />
              </Wrapper>
              <Wrapper className={classNames(
                classes.dateWrapper,
                (!isMobile || isTablet) && classes.dateWrapperDesktop,
              )}
              >
                <FormControlLabel
                  className={classes.buttonWrapper}
                  value="between_these_dates"
                  control={(
                    <Radio
                      classes={(!isMobile || isTablet) ? { root: classes.radioIconDesktop } : { root: classes.radioIcon }}
                      color="primary"
                    />
                  )}
                  label={I18n.t('scene_web.time_condition_state_between_these_dates')}
                  labelPlacement="start"
                />
                <Wrapper className={classNames(
                  classes.inlinePickersWrapper,
                  (!isMobile || isTablet) && classes.inlinePickersWrapperDesktop,
                )}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={betweenStartDate}
                    onChange={(selectedDate) => this.handleBetweenStartDateChange(selectedDate)}
                    KeyboardButtonProps={{ classes: { root: classes.iconButton } }}
                    keyboardIcon={<Calendar />}
                    disabled={selected !== 'between_these_dates'}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        className={classNames(
                          classes.datePickerInline,
                          (!isMobile || isTablet) && classes.datePickerInlineDesktop,
                        )}
                      />
                    )}
                  />
                  <DatePicker
                    className={classNames(
                      classes.datePickerInline,
                      (!isMobile || isTablet) && classes.datePickerInlineDesktop,
                    )}
                    inputFormat="dd/MM/yyyy"
                    value={betweenFinishDate}
                    onChange={(selectedDate) => this.handleBetweenFinishDateChange(selectedDate)}
                    KeyboardButtonProps={{ classes: { root: classes.iconButton } }}
                    keyboardIcon={<Calendar />}
                    disabled={selected !== 'between_these_dates'}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        className={classes.datePicker}
                      />
                    )}
                  />
                </Wrapper>
              </Wrapper>
            </RadioGroup>
            <Button
              variant="outlined"
              color="primary"
              className={classNames(
                classes.addButton,
                (!isMobile || isTablet) && classes.addButtonDesktop,
              )}
              onClick={this.timesConflictChecker}
            >
              {I18n.t('buttons.next')}
            </Button>
          </LocalizationProvider>
        </Wrapper>
        <SmallErrorPopup
          open={isBetweenDatesIncorrect}
          title="titles.title_error"
          warningText="errors.error_date"
          buttonName="buttons.okay"
          onClick={this.popupClose}
        />
        <SmallErrorPopup
          open={isAtDateIncorrect}
          title="titles.title_error"
          warningText="errors.error_date_incorrect"
          buttonName="buttons.okay"
          onClick={this.popupClose}
        />
      </Paper>
    )
  }
}

SelectTimeConditionStateDate.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionStateTimeData: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  stateTimeData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

SelectTimeConditionStateDate.displayName = 'SelectTimeConditionStateDate'

export default SelectTimeConditionStateDate
