export const swatchColors = [{
  rgb: {
    r: 255,
    g: 0,
    b: 0,
    a: 1,
  },
}, {
  rgb: {
    r: 255,
    g: 0,
    b: 127,
    a: 1,
  },
}, {
  rgb: {
    r: 127,
    g: 0,
    b: 255,
    a: 1,
  },
}, {
  rgb: {
    r: 0,
    g: 0,
    b: 255,
    a: 1,
  },
}, {
  rgb: {
    r: 255,
    g: 255,
    b: 0,
    a: 1,
  },
}, {
  rgb: {
    r: 0,
    g: 255,
    b: 0,
    a: 1,
  },
}, {
  rgb: {
    r: 255,
    g: 128,
    b: 0,
    a: 1,
  },
}, {
  rgb: {
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },
}]

export const RGBThreshold = 127

export const swatchColorsStrings = [
  'rgba(255, 0, 0, 1)',
  'rgba(255, 0, 127, 1)',
  'rgba(127, 0, 255, 1)',
  'rgba(0, 0, 255, 1)',
  'rgba(255, 0, 0, 1)',
  'rgba(255, 255, 0, 1)',
  'rgba(0, 255, 0, 1)',
  'rgba(255, 128, 0, 1)',
  'rgba(255, 255, 255, 1)',
]

export const ColorTypes = {
  rgb: 'RGB',
  swatch: 'swatch',
  whiteCold: 'whiteCold',
  whiteWarm: 'whiteWarm',
}
