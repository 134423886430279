import { useDispatch as useRegularDispatch } from 'react-redux'
import { Action, AnyAction } from 'redux'
// @ts-ignore
import { createEnhancer } from './middleware'
// @ts-ignore
import configureLocales from './locales'
import configureReducer from './reducer'
import createStore from './store'
// @ts-ignore
// @ts-ignore
import configureMoment from './moment'

const configureStore = () => {
  const rootReducer = configureReducer()
  const enhancer = createEnhancer()
  const { persistor, store } = createStore(rootReducer, enhancer)
  configureLocales(store)
  configureMoment()
  return { persistor, store }
}

export const { store, persistor } = configureStore()
global.store = store

export type AppDispatch = typeof store.dispatch

export interface VoidDispatch<A extends Action = AnyAction> {
  <T extends A>(action: T): void,
}

export const useDispatch = <A extends Action = AnyAction>(): VoidDispatch<A> => {
  const regularDispatch = useRegularDispatch()

  return (action: A) => {
    regularDispatch(action)
  }
}
