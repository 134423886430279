import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.79221 7.27866L7.80348 7.25923L11 8.74876V12H13V9.68073L19.3497 12.6396L19.3693 12.6734L25.8237 15.6811L25.0674 17.304L20.9403 15.3808L23.0312 18.9844H1L6.88998 8.83355L0 5.62292L0.756253 4.00001L7.79221 7.27866ZM17.7788 9.93225L8.7057 5.70434L12.0156 0L17.7788 9.93225ZM13 14H11V16H13V14Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'AlertCancelledIcon')

export default icon
