const getMultichannelBinarySwitchStateDescription = (operand: string): string => {
  switch (operand) {
  case 'eq':
    return 'is off'
  case 'nz':
    return 'is on'
  default:
    throw new Error('condition unsupported')
  }
}

export default getMultichannelBinarySwitchStateDescription
