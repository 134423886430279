import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { setCurrentRequest } from '@app/actions/users/currentRequest'
import { sync as syncRequests } from '@app/actions/users/usersRequests'
import RequestList from '@app/components/Users/RequestList'
import {
  withAccessDenied, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

let enhance = withStyles(RequestList)

const mapStateToProps = (state) => {
  const usersRequests = state.users.usersRequests.requests
  const { isMobile } = state.mobileDetector
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isMobile,
    usersRequests,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncRequests,
  tryAgain: syncRequests,
  setRequest: setCurrentRequest,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withRouter(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RequestsListEnhanced = enhance

export default RequestsListEnhanced
