import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/unit_users`

const fetchGeofenceUsers = (access_token) => Axios.get(ENDPOINT, {
  headers: { 'Access-Token': access_token },
})

export default {
  fetchGeofenceUsers,
}
