import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path stroke="#929292" d="M.5.5h19v19H.5z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'UncheckedCheckbox', '0 0 16 16', { fontSize: 17 })

export default icon
