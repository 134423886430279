const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: theme.backgrounds.transparentBg,
  },
  main: {
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 0px',
    margin: 0,
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainDesktop: {
    maxWidth: 450,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid rgba(255, 255, 255, 0.54)',
    boxSizing: 'border-box',
    boxShadow: `0px 4px 4px ${theme.palette.shadow.color}`,
    borderRadius: 10,
    padding: '35px 0px',
    margin: '32px 0px',
    backgroundColor: theme.backgrounds.mainBg,
    height: 604,
  },
  mainLogo: {
    marginBottom: theme.loginMargin.bottomMargin,
  },
  titleDesktop: {
    fontSize: '25px',
    lineHeight: '29px',
    marginBottom: 24,
  },
  title: {
    fontSize: '20px',
    lineHeight: '20px',
    marginBottom: 24,
  },
})

export default styles
