import React from 'react'
import { Paper, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { SELECT_MULTILEVEL_SENSOR_VALUE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import styles from './UltravioletSensorExample.style'

class UltravioletSensorExample extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MULTILEVEL_SENSOR_VALUE)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classes.wrapper}>
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('titles.examples')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classNames(
            classes.textPart,
            isMobile && classes.textPartMobile,
          )}
          >
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_1')}</Typography>
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_2')}</Typography>
          </Wrapper>
          <Wrapper className={classNames(
            classes.textPart,
            isMobile && classes.textPartMobile,
          )}
          >
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_3')}</Typography>
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_4')}</Typography>
          </Wrapper>
          <Wrapper className={classNames(
            classes.textPart,
            isMobile && classes.textPartMobile,
          )}
          >
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_5')}</Typography>
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_6')}</Typography>
          </Wrapper>
          <Wrapper className={classNames(
            classes.textPart,
            isMobile && classes.textPartMobile,
          )}
          >
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_7')}</Typography>
            <Typography className={classes.text}>{I18n.t('multisensor_scene.text_uv_8')}</Typography>
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

UltravioletSensorExample.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default withStyles(styles)(UltravioletSensorExample)
