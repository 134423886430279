const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.transparentBg,
  },
  main: {
    maxWidth: 450,
    position: 'absolute',
    top: '50%',
    marginTop: '-282px',
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 20px',
    margin: 0,
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainDesktop: {
    maxWidth: 450,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid rgba(255, 255, 255, 0.54)',
    boxSizing: 'border-box',
    boxShadow: `0px 4px 4px ${theme.palette.shadow.color}`,
    borderRadius: 10,
    padding: '35px 34px',
    margin: '32px 0px',
    backgroundColor: theme.backgrounds.mainBg,
  },
  code: {
    maxWidth: 350,
    width: '100%',
  },
  codeInput: {
    fontSize: '16px',
  },
  sendButton: {
    maxWidth: 200,
    height: 36,
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: 2,
    boxShadow: '0px 4px 4px rgba(111, 155, 77, 0.5)',
    marginBottom: 43,
    marginTop: 50,
  },
})

export default styles
