import update from 'immutability-helper'
import * as type from '@app/constants/activities/activities'

const initialState = {
  activities: [],
}

export default function activities(state = initialState, action) {
  switch (action.type) {
  case type.SET_ACTIVITIES:
    return update(state, {
      activities: { $set: action.activities },
    })
  case type.SET_NEW_ACTIVITY:
    return { ...state, activities: [action.activity, ...state.activities] }
  default:
    return state
  }
}
