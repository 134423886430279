import { createLogic } from 'redux-logic'
import {
  PATCH_GEOFENCE,
} from '@app/constants/geofences/geofences'
import Api from '@app/api/geofences'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const patchGeofenceLogic = createLogic({
  type: PATCH_GEOFENCE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_GEOFENCE))
    Api.patchGeofence(action.accessToken, action.id, action.patchedGeofence)
      .then(() => {
        dispatch(apiRequestDone(PATCH_GEOFENCE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchGeofence failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchGeofenceLogic
