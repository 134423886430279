import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
    padding: '30px 16px',
    overflowX: 'auto',
  },
  title: {
    margin: '20px',
  },
  buttonUpdate: {
    marginTop: 10,
  },

  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    padding: '8px 16px',
    marginBottom: 25,
    textDecoration: 'none',
  },
  textContainer: {
    width: '100%',
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.borders.disabled}`,
    borderRadius: '4px',
    '&:hover': {
      borderColor: theme.borders.main,
    },
    padding: '0 14px',
  },
  text: {
    fontSize: '1rem',
  },

  arrowIcon: {
    transform: 'rotate(180deg)',
    color: theme.texts.main,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 32px 30px 32px',
  },
  buttonsSecondWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  button: {
    width: '50%',
    maxWidth: 376.5,
    '&:first-of-type': {
      marginRight: 32,
    },
  },
  resetButton: {
    width: '100%',
  },
  webView: {
    background: theme.webView.background,
  },
  preloader: {
    display: 'flex',
    position: 'fixed',
    width: '100vw',
    height: 'calc(100vh - 65px)',
    top: 65,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1199,
  },
  preloaderMessage: {
    fontSize: '1.5rem',
  },
  blur: {
    filter: 'blur(5px)',
    height: 'calc(100vh - 65px)',
  },
}))

export default useStyles
