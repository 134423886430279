import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
          <defs>
              <path id="a" d="M25.09 13.29c0-1.06-.08-1.84-.26-2.64H13.08v4.79h6.89c-.14 1.19-.89 2.98-2.55 4.19-.01.01-.01.06-.03.16l3.72 2.97c.15.01.24.02.25.02 2.37-2.25 3.73-5.56 3.73-9.49"/>
              <path id="b" d="M13.08 25.91c3.38 0 6.21-1.15 8.28-3.13-.39-.31-3.55-2.84-3.94-3.15-1.06.76-2.48 1.29-4.34 1.29-3.31 0-6.12-2.25-7.12-5.37-.01 0-.06.01-.15.02l-3.86 3.08c-.03.09-.05.14-.05.14 2.06 4.22 6.28 7.12 11.18 7.12"/>
              <path id="c" d="M5.96 15.55c-.27-.8-.42-1.66-.42-2.55 0-.89.15-1.75.4-2.55v-.17L2.03 7.14c-.08.04-.12.06-.13.07A13.21 13.21 0 00.56 13c0 2.08.49 4.04 1.34 5.79.27-.21 1.62-1.29 4.06-3.24"/>
              <path id="d" d="M13.08 5.08c2.35 0 3.93 1.05 4.84 1.92.35-.35 3.17-3.2 3.53-3.55C19.28 1.37 16.46.09 13.08.09c-4.9 0-9.12 2.9-11.18 7.12.4.32 3.64 2.91 4.04 3.24 1.02-3.12 3.83-5.37 7.14-5.37"/>
          </defs>
          <use fill="#4285f4" xlinkHref="#a"/>
          <use fillOpacity="0" stroke="#000" strokeOpacity="0" xlinkHref="#a"/>
          <use fill="#34a853" xlinkHref="#b"/>
          <use fillOpacity="0" stroke="#000" strokeOpacity="0" xlinkHref="#b"/>
          <g>
              <use fill="#fbbc05" xlinkHref="#c"/>
              <use fillOpacity="0" stroke="#000" strokeOpacity="0" xlinkHref="#c"/>
          </g>
          <g>
              <use fill="#eb4335" xlinkHref="#d"/>
              <use fillOpacity="0" stroke="#000" strokeOpacity="0" xlinkHref="#d"/>
          </g>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'GoogleLogo')

export default icon
