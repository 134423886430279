import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ExampleInformation } from 'icons'
import classNames from 'classnames'
import convertMultilevelSensorDataToBackend from '@app/helpers/scenes/convertMultilevelSensorDataToBackend'
import getMultilevelSensorDescription from '@app/helpers/scenes/getMultilevelSensorDescription'
import getMultilevelSensorMarks from '@app/helpers/scenes/getMultilevelSensorMarks'
import { precision, scales, sensorsValues } from '@app/lib/Scenes/multilevelSensors'
import {
  SELECT_MULTILEVEL_SENSOR_STATE_SELECTED,
  ULTRAVIOLET_SENSOR_VALUE_EXAMPLE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './UltravioletSensor.style'

class UltravioletSensor extends React.Component {
  state = {
    radioValue: 'greater than',
    sensorValue: sensorsValues.ultravioletSensor.initialValue,
  }

  backendSensorOperand = (radioValue) => (radioValue === 'greater than' ? 'gt' : 'lt')

  onNextClick = () => {
    const {
      navigate,
      saveConditionDeviceStateInfo,
      saveMultilevelSensorValueDescription,
      conditionDeviceNumber,
      sensorType,
    } = this.props
    const { sensorValue, radioValue } = this.state
    saveConditionDeviceStateInfo(convertMultilevelSensorDataToBackend(
      conditionDeviceNumber,
      sensorType,
      sensorValue,
      scales.index.type,
      precision.none,
      this.backendSensorOperand(radioValue),
    ))
    saveMultilevelSensorValueDescription(getMultilevelSensorDescription(radioValue, sensorValue, scales.index.name))
    navigate(SELECT_MULTILEVEL_SENSOR_STATE_SELECTED)
  }

  handleChange = (event) => {
    this.setState({ radioValue: event.target.value })
  }

  handleSliderChange = (event, newValue) => {
    this.setState({ sensorValue: newValue })
  }

  handleInputChange = (event) => {
    this.setState({ sensorValue: Number(event.target.value) })
  }

  render() {
    const {
      classes,
      isMobile,
      navigate,
    } = this.props
    const { radioValue, sensorValue } = this.state
    return (
      <Wrapper className={classes.wrapper}>
        <Wrapper
          className={classes.exampleInfo}
          onClick={() => {
            navigate(ULTRAVIOLET_SENSOR_VALUE_EXAMPLE)
          }}
        >
          <ExampleInformation />
          <Typography className={classes.exampleInfoText}>Example</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <Wrapper className={classes.cardBlock}>
              <Wrapper className={classNames(
                isMobile ? classes.cardContentMobile : classes.cardContent,
              )}
              >
                <Wrapper className={classes.cardTopWrapper}>
                  <Typography className={classes.title}>{I18n.t('scene_web.value')}</Typography>
                </Wrapper>
                <FormControl variant="standard" className={classes.formControl}>
                  <RadioGroup
                    row
                    className={classes.radioWrapper}
                    aria-label="radio"
                    name="radio1"
                    value={radioValue}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      classes={{
                        root: classes.radioButton,
                      }}
                      value="greater than"
                      control={<Radio color="primary" />}
                      label={I18n.t('multisensor_scene.value_greater_than')}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.radioButton,
                      }}
                      value="less than"
                      control={<Radio color="primary" />}
                      label={I18n.t('multisensor_scene.value_less_than')}
                    />
                  </RadioGroup>
                </FormControl>
              </Wrapper>
            </Wrapper>
            <Wrapper className={classes.cardBlock}>
              <Wrapper className={classNames(
                isMobile ? classes.cardContentMobile : classes.cardContent,
              )}
              >
                <Wrapper>
                  <Typography className={classes.title}>{I18n.t('scene_web.value')}</Typography>
                  <TextField
                    variant="standard"
                    value={sensorValue}
                    className={classes.sensorInput}
                    onChange={this.handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography>{scales.index.name}</Typography>
                        </InputAdornment>
                      ),
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </Wrapper>
                <Slider
                  classes={{
                    rail: classes.sliderRail,
                    track: classes.sliderTrack,
                    thumbColorPrimary: classes.sliderThumbColor,
                    mark: classes.sliderMark,
                  }}
                  value={typeof sensorValue === 'number' ? sensorValue : 0}
                  onChange={this.handleSliderChange}
                  min={sensorsValues.ultravioletSensor.minValue}
                  step={sensorsValues.ultravioletSensor.valueStep}
                  max={sensorsValues.ultravioletSensor.maxValue}
                  marks={getMultilevelSensorMarks(
                    sensorsValues.ultravioletSensor.minValue,
                    sensorsValues.ultravioletSensor.maxValue,
                  )}
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.onNextClick()
            }}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

UltravioletSensor.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveMultilevelSensorValueDescription: PropTypes.func.isRequired,
  sensorType: PropTypes.number.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
}

export default withStyles(styles)(UltravioletSensor)
