import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import {
  selectBinarySwitchActionSelectedDuration,
  selectBinarySwitchActionSelectedValue,
} from '@app/lib/Scenes/actions'
import {
  ADJUST_DURATION_MEZZO_LIGHTS,
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_MEZZO_LIGHTS_ACTION_STATE_VALUE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMezzoLightsActionStateSelected extends React.Component {
  onChooseValueClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MEZZO_LIGHTS_ACTION_STATE_VALUE)
  }

  onChooseDurationClick=() => {
    const {
      navigate,
      saveActionDurationValueAfter,
    } = this.props
    navigate(ADJUST_DURATION_MEZZO_LIGHTS)
    saveActionDurationValueAfter('turn_off')
  }

  clearDuration=() => {
    const {
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
  }

  onAddStateClick = () => {
    const {
      navigate,
      isEditActionMode,
      stateDescription,
      saveActionDeviceStateInfo,
      actionDeviceNumber,
      actionDelay,
      durationValueAfter,
      actionDuration,
      actionType,
    } = this.props
    const actionDeviceStateInfo = getActionDeviceStateInfo(actionType, stateDescription,
      actionDeviceNumber, actionDelay, durationValueAfter, actionDuration, '', '', '', '')
    saveActionDeviceStateInfo(actionDeviceStateInfo)
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  getDescription =() => {
    const {
      stateDescription,
      actionType,
    } = this.props
    const firstPart = `${actionType === 'MezzoTopLightColor' ? I18n.t('scene_web.top_rgb_light') : I18n.t('scene_web.bottom_rgb_light')}`
    return (stateDescription === 'none') ? `${firstPart} turn off` : `${firstPart} ${I18n.t('scene_web.set_to')}`
  }

  render() {
    const {
      classes,
      isMobile,
      stateDescription,
      durationDescription,
      durationValueAfter,
    } = this.props
    const durationFullDescription = `${durationDescription} then ${I18n.t(`scene_action_web.${durationValueAfter}`)}`
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={null}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <CardBlock
            key={I18n.t(`scene_web.${selectBinarySwitchActionSelectedValue.title}`)}
            title={I18n.t(`scene_web.${selectBinarySwitchActionSelectedValue.title}`)}
            description={this.getDescription()}
            hasAdditionalDesc={stateDescription !== 'none'}
            descColor={stateDescription}
            position={selectBinarySwitchActionSelectedValue.position}
            buttonName={I18n.t(`scene_web.${selectBinarySwitchActionSelectedValue.buttonName}`)}
            isCardDisabled={selectBinarySwitchActionSelectedValue.isCardDisabled}
            isButtonDisabled={selectBinarySwitchActionSelectedValue.isButtonDisabled}
            onClick={this.onChooseValueClick}
          />
          {stateDescription !== 'none'
          && (
            <CardBlock
              key={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.title}`)}
              title={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.title}`)}
              description={durationDescription
                ? durationFullDescription
                : I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.description}`)}
              position={selectBinarySwitchActionSelectedDuration.position}
              buttonName={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.buttonName}`)}
              isCardDisabled={selectBinarySwitchActionSelectedDuration.isCardDisabled}
              isButtonDisabled={selectBinarySwitchActionSelectedDuration.isButtonDisabled}
              onClick={this.onChooseDurationClick}
              hasClearButton={!!durationDescription}
              clearDescription={this.clearDuration}
            />
          )}
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoLightsActionStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  stateDescription: PropTypes.string.isRequired,
  saveActionDeviceStateInfo: PropTypes.func.isRequired,
  actionDeviceNumber: PropTypes.number.isRequired,
  actionDelay: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  actionDuration: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  durationDescription: PropTypes.string.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
}

SelectMezzoLightsActionStateSelected.defaultProps = {

}

SelectMezzoLightsActionStateSelected.displayName = 'SelectMezzoLightsActionStateSelected'

export default SelectMezzoLightsActionStateSelected
