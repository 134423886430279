import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { List, Paper, Typography } from '@mui/material'
import { withStyles, withTheme } from '@mui/styles'
import Chart from 'react-google-charts'
import styles from './Charts.style'

class Charts extends React.PureComponent {
  render() {
    const {
      classes,
      title,
      xAxis,
      yAxis,
      data,
      theme,
    } = this.props
    return (
      <Paper className={classNames(
        classes.root,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <List className={classes.item}>
          <Typography className={classes.title}>{title}</Typography>
          <Chart
            width={300}
            height={320}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              chartArea: { width: '70%' },
              fontName: 'Arial',
              hAxis: {
                title: xAxis,
                titleTextStyle: {
                  color: theme.texts.main,
                },
                minValue: 0,
                textStyle: {
                  color: theme.texts.main,
                },
              },
              vAxis: {
                title: yAxis,
                titleTextStyle: {
                  color: theme.texts.main,
                },
                textStyle: {
                  color: theme.texts.main,
                },
              },
              backgroundColor: {
                fill:
                  (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
                    ? theme.webView.background
                    : theme.backgrounds.cardBackground,
              },
              colors: [theme.energyGraph.chartColor[yAxis]],
              legend: 'none',
            }}
            legendToggle
          />
        </List>
      </Paper>
    )
  }
}

Charts.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  xAxis: PropTypes.string.isRequired,
  yAxis: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  )).isRequired,
  theme: PropTypes.object.isRequired,
}

Charts.displayName = 'Charts'

export default withTheme(withStyles(styles)(Charts))
