import React from 'react'
import { Button, Paper } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import PropTypes from '@app/components/PropTypes'
import EcoModeDevicesList from './EcoModeDevicesList/EcoModeDevicesList'

class EcoMode extends React.Component {
  componentWillUnmount() {
    const { closeTurnOffPopup } = this.props
    closeTurnOffPopup()
  }

  turnOffDevices = () => {
    const { openTurnOffPopup } = this.props
    openTurnOffPopup()
  }

  turnOffAccepted = () => {
    const { postEcoModeDevicesTurnOff, closeTurnOffPopup } = this.props
    postEcoModeDevicesTurnOff()
    closeTurnOffPopup()
  }

  turnOffDeclined = () => {
    const { closeTurnOffPopup } = this.props
    closeTurnOffPopup()
  }

  render() {
    const {
      classes,
      ecoModeDevices,
      isMobile,
      selectCurrentEcoModeDeviceId,
      selectCurrentEcoModeDeviceStatus,
      patchCurrentEcoModeDevice,
      isTurnOffPopupOpen,
      admin,
    } = this.props

    return (
      <Paper className={classNames(
        classes.main,
        !isMobile && classes.mainDesktop,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
      )}
      >
        <Wrapper className={classes.wrapper}>
          <Button
            disabled={!admin}
            className={classes.button}
            color="primary"
            onClick={this.turnOffDevices}
          >
            {I18n.t('buttons.eco_mode_energy')}
          </Button>
          <EcoModeDevicesList
            admin={admin}
            ecoModeDevices={ecoModeDevices}
            selectCurrentEcoModeDeviceId={selectCurrentEcoModeDeviceId}
            selectCurrentEcoModeDeviceStatus={selectCurrentEcoModeDeviceStatus}
            patchCurrentEcoModeDevice={patchCurrentEcoModeDevice}
          />
        </Wrapper>
        <WarningComponent
          open={isTurnOffPopupOpen}
          onClose={() => {
            this.turnOffDeclined()
          }}
          onClick={this.turnOffAccepted}
          warningText="confirmations.turn_off_all_eco_devices"
          leftButton="yeah"
          rightButton="nope"
        />
      </Paper>
    )
  }
}

EcoMode.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  ecoModeDevices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    eco_mode: PropTypes.bool.isRequired,
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTurnOffPopupOpen: PropTypes.bool.isRequired,
  selectCurrentEcoModeDeviceId: PropTypes.func.isRequired,
  selectCurrentEcoModeDeviceStatus: PropTypes.func.isRequired,
  patchCurrentEcoModeDevice: PropTypes.func.isRequired,
  postEcoModeDevicesTurnOff: PropTypes.func.isRequired,
  openTurnOffPopup: PropTypes.func.isRequired,
  closeTurnOffPopup: PropTypes.func.isRequired,
}

EcoMode.displayName = 'EcoMode'

export default EcoMode
