import * as type from '@app/constants/numberOfZones'
import { IProgramOptions } from '@app/@types/programOptions'

export const sync = () => ({
  type: type.SYNC_NUMBER_OF_ZONES_OPTIONS,
} as const)

export const set = (programOptions: IProgramOptions) => ({
  type: type.SET_NUMBER_OF_ZONES_OPTIONS,
  programOptions,
} as const)

export const setInitialOptions = (programOptions: IProgramOptions) => ({
  type: type.SET_NUMBER_OF_ZONES_INITIAL_OPTIONS,
  programOptions,
} as const)

export const setCurrentOptions = (programOptions: IProgramOptions) => ({
  type: type.SET_NUMBER_OF_ZONES_CURRENT_OPTIONS,
  programOptions,
} as const)

export const setValidation = (validation: boolean) => ({
  type: type.SET_NUMBER_OF_ZONES_VALIDATION,
  validation,
} as const)
