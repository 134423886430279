import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 18">
      <path fill="#fff" d="M12.06 6.75h-.56V4.5a4.5 4.5 0 0 0-9 0v2.25h-.56C1 6.75.25 7.51.25 8.44v7.87c0 .93.76 1.69 1.69 1.69h10.12a1.7 1.7 0 0 0 1.69-1.69V8.44a1.7 1.7 0 0 0-1.69-1.69ZM4 4.5a3 3 0 0 1 6 0v2.25H4V4.5Zm3.75 8.04v1.71a.75.75 0 1 1-1.5 0v-1.7A1.5 1.5 0 0 1 7 9.74a1.5 1.5 0 0 1 .75 2.8Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'LockedIcon', '0 0 13 18', { fontSize: 18 })

export default icon
