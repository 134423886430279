import React from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Paper } from '@mui/material'
import { alphanumeric } from '@app/lib/RegEx/regex'
import { SCENES } from '@app/constants/routes'
import SceneReviewBase from '@app/containers/MyAutomation/SceneReviewBase'
import NavBar from '@app/components/Common/NavBar/NavBar'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import {
  changeConditionOperandValue,
} from '@app/actions/scenes/currentSceneConditionsActions'
import { useDispatch } from '@app/store'
import { useClearSceneReviewState } from '@app/hooks/withClearSceneReviewState'
import {
  closeSceneSaveDraftPopup,
  openSceneSaveDraftPopup,
  patchConditionOperand,
  saveConditionId,
  saveConditionOperand,
} from '@app/actions/scenes/currentSceneCreating'
import {
  clearCurrentSceneAll, deleteScene, patchScene, saveCurrentSceneName,
} from '@app/actions/scenes/scenesList'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './SceneReview.style'

interface Props {
  title: string,
  isMobile: boolean,
  isSaveSceneDraftPopupOpen: boolean,
  isEditActionMode: boolean,
  isEditConditionMode: boolean,
  isAdditionalConditionMode: boolean,
  isAdditionalActionMode: boolean,
}

const SceneReview: React.FC<Props> = (props) => {
  const {
    title,
    isMobile,
    isSaveSceneDraftPopupOpen,
    isEditActionMode,
    isEditConditionMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  useClearSceneReviewState(isEditActionMode, isEditConditionMode, isAdditionalConditionMode, isAdditionalActionMode)
  const saveDraftAccepted = () => {
    navigate(SCENES)
    dispatch(closeSceneSaveDraftPopup())
    dispatch(clearCurrentSceneAll())
  }

  const saveDraftDeclined = () => {
    dispatch(closeSceneSaveDraftPopup())
    dispatch(deleteScene())
  }

  const onBackClick = () => {
    dispatch(openSceneSaveDraftPopup())
  }

  const onCloseIconClick = () => {
    dispatch(closeSceneSaveDraftPopup())
  }

  const onSubmitClick = () => {
    dispatch(patchScene())
  }

  const changeRadio = (event: React.ChangeEvent<HTMLInputElement>, condition: IConditionFromBackend, index: number) => {
    dispatch(changeConditionOperandValue(index, event.target.value))
    dispatch(saveConditionId(condition.id))
    dispatch(saveConditionOperand(event.target.value))
    dispatch(patchConditionOperand())
  }

  const handleChangeNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (alphanumeric.test(event.target.value) || event.target.value === '') {
      dispatch(saveCurrentSceneName(event.target.value.trimLeft()))
    }
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title={title}
        withButton={false}
        isMobile={isMobile}
      />
      {/* @ts-ignore */}
      <SceneReviewBase
        handleChangeNameInput={handleChangeNameInput}
        changeRadio={changeRadio}
        onCancelClick={onBackClick}
        onSaveClick={onSubmitClick}
      />
      <WarningComponent
        open={isSaveSceneDraftPopupOpen}
        onClose={() => {
          saveDraftDeclined()
        }}
        withCloseIcon
        onCloseIconClick={onCloseIconClick}
        onClick={saveDraftAccepted}
        warningText="scene_web.save_draft"
        leftButton="yeah"
        rightButton="nope"
      />
    </Paper>
  )
}

export default SceneReview
