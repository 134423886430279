import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    minHeight: 'calc(100vh - 106px)',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  webViewMain: {
    paddingBottom: 220,
  },
  formControl: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  typeMenuItem: {
    backgroundColor: theme.backgrounds.cardBackground,
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
