const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 174px)',
    width: '100%',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 210px)',
  },
  cardBlock: {
    backgroundColor: '#141414',
    marginBottom: 22,
    borderRadius: '5px',
    minHeight: 50,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2% 10%',
  },
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1% 3%',
  },
  cardTopWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    color: theme.scenes.descriptionTop,
    marginBottom: 5,
  },
  radioWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  sensorInput: {
    width: 75,
    marginBottom: 10,
  },
  radioButton: {
    marginRight: 7,
  },
  sliderRail: {
    height: 6,
  },
  sliderTrack: {
    height: 6,
  },
  sliderThumbColor: {
    width: 16,
    height: 16,
    color: '#fff',
    border: '1px solid rgba(0,0,0,0.2)',
  },
  sliderMark: {
    width: 0,
    height: 0,
  },
  exampleInfo: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    width: '90%',
    marginTop: 20,
    cursor: 'pointer',
  },
  exampleInfoText: {
    marginLeft: 3,
    fontSize: '10px',
    lineHeight: '11px',
    opacity: 0.5,
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
    margin: '0 auto',
    maxWidth: 300,
  },
})

export default styles
