import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
})

export default useStyles
