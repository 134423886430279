import React from 'react'
import { Paper, Tab, Tabs } from '@mui/material'
import MembersList from '@app/containers/Users/MemberList'
import RequestsList from '@app/containers/Users/RequestList'
import PropTypes from '../PropTypes'

class Users extends React.Component {
  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  createTabs = () => {
    const { value } = this.state
    return (
      <Paper square>
        <Tabs
          value={value}
          onChange={this.handleChange}
          variant="fullWidth"
        >
          <Tab label={I18n.t('my_users.title_tab_users')} />
          <Tab label={I18n.t('my_users.title_tab_requests')} />
        </Tabs>
        {value === 0 && <MembersList />}
        {value === 1 && <RequestsList />}
      </Paper>
    )
  }

  render() {
    const
      {
        admin,
        withUnitDevice,
      } = this.props
    return (
      <Paper>
        { (admin && withUnitDevice === 'activatedMezzo') ? this.createTabs() : <MembersList />}
      </Paper>

    )
  }
}

Users.propTypes = {
  admin: PropTypes.bool,
  withUnitDevice: PropTypes.string.isRequired,
}

Users.defaultProps = {
  admin: false,
}

export default Users
