import * as type from '@app/constants/myEnergy/billingPeriod'

export const setUpBillingPeriodOpen = () => ({
  type: type.SET_UP_BILLING_PERIOD_OPEN,
})

export const setUpBillingPeriodClose = () => ({
  type: type.SET_UP_BILLING_PERIOD_CLOSE,
})

export const billingPeriodsStatsOpen = () => ({
  type: type.BILLING_PERIODS_STATS_POPUP_OPEN,
})

export const billingPeriodsStatsClose = () => ({
  type: type.BILLING_PERIODS_STATS_POPUP_CLOSE,
})
