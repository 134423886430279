import { createLogic } from 'redux-logic'
import convertUser from '@app/helpers/convertUser'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_USERS } from '@app/constants/users/memberList'
import { update } from '@app/actions/users/memberList'
import Api from '@app/api/users/fetchUsers'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchUsersLogic = createLogic({
  type: SYNC_USERS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_USERS))
    Api.fetchUsers(token)
      .then((response) => {
        const { data: { sphere_users } } = response
        const users = sphere_users.map((user) => convertUser(user))
        dispatch(update(users))
        dispatch(apiRequestDone(SYNC_USERS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchUsers failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchUsersLogic
