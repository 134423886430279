import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.7012 19.9835V10.4031C25.7012 5.3138 29.6822 1.18811 34.5929 1.18811C39.5036 1.18811 43.4846 5.3138 43.4846 10.4031V19.9835" stroke="currentColor" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.9841 32.4609C20.0211 31.5234 20.3881 30.0176 19.905 28.6823C19.422 27.3469 18.1903 26.4622 16.8142 26.4622C15.4382 26.4622 14.2065 27.3469 13.7234 28.6823C13.2404 30.0176 13.6074 31.5234 14.6444 32.4609C14.478 34.7803 14.3105 37.0997 14.1421 39.419H19.4864L18.9841 32.4609Z" stroke="currentColor" strokeWidth="2"/>
      <rect x="3.48454" y="19.9835" width="26.6594" height="27.4801" rx="4.99" stroke="currentColor" strokeWidth="2"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DisarmedSecurityIcon')

export default icon
