import { Optional } from '@app/@types/common'

const constructErrorMessage = (message: string): Optional<string> => {
  switch (message) {
  case 'already_exist': return I18n.t('my_devices.already_exists')
  case 'invalid_txid': return I18n.t('my_devices.invalid_txid')
  case 'timeout': return I18n.t('my_devices.timeout')
  case 'failed': return I18n.t('my_devices.failed')
  default: return null
  }
}

export default constructErrorMessage
