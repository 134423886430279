import React from 'react'
import { Button } from '@mui/material'
import classNames from 'classnames'
import { passwordFields } from '@app/lib/Wizard/ForgotPasswordFields'
import { SIGN_IN } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import NavigationAndTitles from '../NavigationAndTitles/NavigationAndTitles'
import CustomerServiceText from '../CustomerServiceText/CustomerServiceText'
import EditText from './EditText/EditText'

class NewPassword extends React.PureComponent {
  render() {
    const {
      classes,
      pristine,
      submit,
      submitting,
      isMobile,
      isTablet,
      navigate,
      clearEmail,
      clearCode,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <NavigationAndTitles
            firstTitle="titles.reset_password"
            secondTitle="forgot_password.password_assistance"
            thirdTitle="forgot_password.enter_new_password_text"
            onBackClick={() => {
              navigate(SIGN_IN)
              clearEmail()
              clearCode()
            }}
            isMobile={isMobile}
          />
          <form className={classes.form}>
            {passwordFields.map((field) => (
              <EditText
                key={field.title}
                className={classes.item}
                name={field.title}
                hint={`${I18n.t(`my_users.${field.hint}`)}`}
                title={field.title}
                placeholder={`${I18n.t(`forgot_password.${field.title}`)}`}
              />
            ))}
            <Button
              className={classes.sendButton}
              variant="contained"
              color="primary"
              disabled={pristine || submitting}
              onClick={submit}
            >
              {I18n.t('forgot_password.restore_password')}
            </Button>
          </form>
          <CustomerServiceText />
        </Wrapper>
      </Wrapper>
    )
  }
}

NewPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
  clearCode: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

NewPassword.displayName = 'NewPassword'

export default NewPassword
