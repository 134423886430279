import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import getActionDataToBackend from '@app/helpers/scenes/getActionDataToBackend'
import { POST_ACTION } from '@app/constants/scenes/currentSceneCreating'
import {
  closeSceneSaveDraftPopup,
} from '@app/actions/scenes/currentSceneCreating'
import Api from '@app/api/scenes/postAction'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const postActionLogic = createLogic({
  type: POST_ACTION,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = getState()
    const {
      scenes: {
        currentSceneCreating:
          {
            currentAction: { stateDescription, actionType, actionDeviceStateInfo },
            screenName,
          },
      },
    } = getState()
    const currentData = getActionDataToBackend(actionType, stateDescription, actionDeviceStateInfo)
    next({
      ...action,
      accessToken,
      id,
      currentData,
      screenName,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_ACTION))
    Api.postAction(action.accessToken, action.id, action.currentData)
      .then(() => {
        dispatch(apiRequestDone(POST_ACTION))
        dispatch(closeSceneSaveDraftPopup())
        history.push(action.screenName)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('postAction failed without error')
        }
      })
      .then(() => done())
  },
})

export default postActionLogic
