import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { ROOMS_ADD_DEVICE } from '@app/constants/rooms/rooms'
import Api from '@app/api/rooms'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const postRoomDevice = createLogic({
  type: ROOMS_ADD_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(ROOMS_ADD_DEVICE))
    const result = action.zwave
      ? Api.roomAddDeviceZwave(AccessToken, action.deviceId, action.roomId)
      : Api.roomAddDeviceTwr(AccessToken, action.deviceId, action.roomId)
    result
      .then(() => {
        dispatch(apiRequestDone(ROOMS_ADD_DEVICE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('roomAddDeviceZwave or roomAddDeviceTwr failed without error')
        }
      })
      .then(() => done())
  },
})

export default postRoomDevice
