import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    marginBottom: 20,
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  mapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    marginBottom: 24,
  },
  autocompleteInput: {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '100%',
    height: '100%',
    padding: '0 35px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: 14,
    outline: 'none',
    textOverflow: 'ellipses',
    borderRadius: 5,
  },
  autocompleteInputMobile: {
    width: '100%',
    height: '100%',
    padding: '0 35px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
  },
  inputWrapper: {
    position: 'relative',
    width: '90%',
    top: 20,
    height: 35,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  hideInput: {
    display: 'none',
  },
  loopIcon: {
    position: 'absolute',
    top: 9,
    left: 10,
    zIndex: 1,
    fontSize: '20px',
  },
  closeIcon: {
    position: 'absolute',
    top: 9,
    right: 10,
    zIndex: 2,
    fontSize: '16px',
    cursor: 'pointer',
  },
  locationCard: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '17px 4%',
    marginBottom: 26,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
  },
  locationCardMobile: {
    padding: '8px 4%',
  },
  mezzo: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 24,
  },
  mezzoMobile: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 14,
  },
  address: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.texts.subText,
  },
  addressMobile: {
    fontSize: '12px',
    lineHeight: '14px',
  },
}))

export default useStyles
