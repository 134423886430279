const styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    width: 187,
    height: 155,
    position: 'relative',
    borderRadius: 5,
    padding: '0 10px',
    backgroundColor: theme.backgrounds.cardBackground,
    color: theme.texts.main,
    boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.25)',
  },
  cardMobile: {
    width: '97%',
  },
  subtitleColor: {
    color: theme.myStatus.subtitle,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 65,
    width: '80%',
    marginTop: -10,
  },
  iconContainer: {
    minWidth: 40,
    minHeight: 40,
    backgroundColor: 'rgba(90, 90, 92, 0.5)',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  icon: {
    width: 25,
    height: 25,
    color: 'white',
  },
  statusIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 20,
    height: 20,
  },
  statusIconMobile: {
    right: 20,
  },
  title: {
    overflow: 'hidden',
    fontSize: 16,
    marginTop: 7,
    maxHeight: 40,
    textOverflow: 'ellipsis',
  },
  content: {
    marginTop: -35,
    width: '100%',
  },
  contentItem: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  info: {
    whiteSpace: 'nowrap',
    fontSize: 14,
  },
  learnMoreButton: {
    marginTop: -15,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  button: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  comfortable: {
    color: '#5BD84D',
  },
  warning: {
    color: '#FF9129',
  },
  critical: {
    color: '#F61718',
  },
})

export default styles
