import React, {
  useState, useEffect,
} from 'react'
import {
  FormControl, Switch, Typography, ListItem,
} from '@mui/material'
import classNames from 'classnames'
import useStyles from './Toggle.style'

type Props = {
  name: string,
  title: string,
  value: boolean,
  isMobile: boolean,
  handleValuesChange: (fieldKey: string, fieldValue: boolean) => void,
}

const Toggle: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState<boolean>(false)
  const {
    name, title, value, isMobile, handleValuesChange,
  } = props

  useEffect(() => {
    setCurrentValue(!!value)
  }, [])

  useEffect(() => {
    setCurrentValue(!!value)
  }, [value])

  const handleSelectValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.checked)
    handleValuesChange(name, event.target.checked)
  }

  return (
    <ListItem className={classes.root}>
      <FormControl variant="standard" className={classes.formControl}>
        <Typography className={classNames(classes.title, isMobile && classes.titleMobile)}>{`${title}`}</Typography>
        <Switch
          classes={{
            switchBase: classes.iOSSwitchBase,
            track: classes.iOSBar,
            thumb: classes.iOSIcon,
            checked: classes.iOSChecked,
            root: classes.form,
          }}
          inputProps={{ name }}
          checked={currentValue}
          onChange={handleSelectValue}
        />
      </FormControl>
    </ListItem>
  )
}

export default Toggle
