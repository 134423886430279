import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  name: PropTypes.string,
  icon: PropTypes.number,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  number: PropTypes.number,
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  eco_mode: PropTypes.bool,
})

export default propTypes
