import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveActionDelay,
  saveActionDelayDescription,
  saveActionDeviceStateInfo,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveActionType,
  saveDurationValueAfterActionColorType,
  saveInitialActionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectRoomColorActionStateSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/RoomActions/SelecRoomColorActionStateSelected/SelectRoomColorActionStateSelected'
import SelectRoomColorActionStateSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/RoomActions/SelecRoomColorActionStateSelected/SelectRoomColorActionStateSelected'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectRoomColorActionStateSelectedIOS) : withStyles(SelectRoomColorActionStateSelected)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    stateDescription: color, colorType,
    actionType,
    actionDeviceNumber,
    delay: actionDelay,
    durationValueAfter,
    duration: actionDuration,
    durationDescription,
    delayDescription,
    colorTypeDurationValueAfter,
    roomInfo: {
      roomNumber,
      roomValue,
    },
  } = state.scenes.currentSceneCreating.currentAction
  const { isEditActionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    color,
    isEditActionMode,
    colorType,
    path,
    actionType,
    actionDeviceNumber,
    actionDelay,
    durationValueAfter,
    actionDuration,
    durationDescription,
    colorTypeDurationValueAfter,
    delayDescription,
    roomNumber,
    roomValue,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveInitialActionInfo,
  saveActionDeviceStateInfo,
  saveActionType,
  saveActionDelay,
  saveActionDelayDescription,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveDurationValueAfterActionColorType,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectRoomColorActionStateSelectedEnhanced = enhance

export default withRouter(SelectRoomColorActionStateSelectedEnhanced)
