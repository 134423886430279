import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { chooseCurrentMember } from '@app/actions/users/currentMember'
import { sync as syncUsers } from '@app/actions/users/memberList'
import MemberList from '@app/components/Users/MemberList'
import { withAccessDenied, withProgressAndErrorMessage, withRefresh } from '@app/hocs'

let enhance = withStyles(MemberList)

const mapStateToProps = (state) => {
  const { users } = state.users.memberList
  const currentUser = state.users.currentUser.user
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    users,
    currentUser,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncUsers,
  tryAgain: syncUsers,
  chooseCurrentMember,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MembersListEnhanced = enhance

export default MembersListEnhanced
