import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_MY_STATUS } from '@app/constants/myStatus'
import { setMyStatusDevices, syncMyStatusChannel, resetPirDevices } from '@app/actions/myStatus'
import Api from '@app/api/myStatus'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchMyStatusDevices = createLogic({
  type: SYNC_MY_STATUS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SYNC_MY_STATUS))
    try {
      const data = await Api.fetchMyStatusDevices(action.accessToken)
      dispatch(setMyStatusDevices(data.data))
      dispatch(apiRequestDone(SYNC_MY_STATUS))
      dispatch(resetPirDevices())
      dispatch(syncMyStatusChannel())
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('fetchMyStatusDevices failed without error')
      }
    } finally {
      done()
    }
  },
})

export default fetchMyStatusDevices
