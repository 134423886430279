import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 106px)',
  },
  card: {
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '3% 10%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 100,
  },
  blockTitle: {
    color: 'rgba(229, 229, 229, 0.67)',
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: 15,
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
    margin: '0 auto',
    maxWidth: 300,
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
