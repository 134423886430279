import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M134.037 42.2681L133.904 42.2367L133.769 42.2131C132.756 42.0357 131.726 41.9728 130.699 42.0255H31.5027C31.4431 42.0255 31.3072 42.023 31.1212 42.0195C30.5497 42.0089 29.5041 41.9894 28.733 42.0072C27.7964 42.0288 25.8722 42.0925 24.297 42.7767L24.2956 42.7773C20.9919 44.2137 18.8558 47.4735 18.8573 51.0756V71.8986C18.8573 72.3115 18.8492 72.7938 18.8405 73.3171C18.82 74.549 18.7956 76.0082 18.8635 77.3234L18.8643 77.3391L18.8652 77.3547C19.0851 81.1238 21.623 84.3598 25.2312 85.4715C26.0669 85.7292 26.9903 85.798 27.4754 85.8296C28.1043 85.8706 28.7903 85.8866 29.4144 85.8922C30.2403 85.8995 31.3003 85.8864 31.8827 85.8792C32.0832 85.8767 32.2275 85.8749 32.2856 85.8749H41.5117V81.1918H32.2856C32.1243 81.1918 31.8881 81.1949 31.6015 81.1986C30.1585 81.2174 27.4405 81.2527 26.6101 80.9961C24.8702 80.46 23.6463 78.8996 23.5403 77.082C23.4807 75.9264 23.5006 74.7758 23.5205 73.6252C23.5304 73.0499 23.5403 72.4745 23.5403 71.8986V51.0756C23.5388 49.3378 24.5691 47.7649 26.1628 47.072C27.1867 46.6273 29.3469 46.67 30.7477 46.6978C31.0375 46.7035 31.2947 46.7086 31.5027 46.7086H130.831C131.544 46.6635 132.259 46.7029 132.962 46.826C135.355 47.3908 136.317 49.3254 136.317 51.6012V76.8304C136.317 79.7212 134.13 81.1974 131.447 81.1974H77.1188V85.8805H131.447C133.595 85.8805 135.958 85.2925 137.875 83.7049C139.906 82.0235 141 79.5716 141 76.8304V51.6012C141 49.8491 140.635 47.8407 139.475 46.0383C138.249 44.1325 136.359 42.8159 134.037 42.2681Z" fill="currentColor"/>
            <rect x="44.8501" y="65.1172" width="2.60008" height="10.6408" fill="currentColor"/>
            <rect x="34.8042" y="61.0625" width="10.6408" height="2.60008" transform="rotate(-49.69 34.8042 61.0625)" fill="currentColor"/>
            <rect x="48.6396" y="54.6289" width="2.60008" height="10.6408" transform="rotate(-40.31 48.6396 54.6289)" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M45.7616 79.659C54.5752 79.659 61.72 72.5142 61.72 63.7006C61.72 54.887 54.5752 47.7422 45.7616 47.7422C36.948 47.7422 29.8032 54.887 29.8032 63.7006C29.8032 72.5142 36.948 79.659 45.7616 79.659ZM45.7616 77.7858C53.5407 77.7858 59.8468 71.4796 59.8468 63.7006C59.8468 55.9216 53.5407 49.6154 45.7616 49.6154C37.9826 49.6154 31.6764 55.9216 31.6764 63.7006C31.6764 71.4796 37.9826 77.7858 45.7616 77.7858Z" fill="currentColor"/>
            <rect x="80.3564" y="65.1172" width="2.60009" height="10.6408" fill="currentColor"/>
            <rect x="70.3105" y="61.0664" width="10.6408" height="2.60008" transform="rotate(-49.69 70.3105 61.0664)" fill="currentColor"/>
            <rect x="84.1416" y="54.625" width="2.60008" height="10.6408" transform="rotate(-40.31 84.1416 54.625)" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M81.268 79.659C90.0815 79.659 97.2264 72.5142 97.2264 63.7006C97.2264 54.887 90.0815 47.7422 81.268 47.7422C72.4544 47.7422 65.3096 54.887 65.3096 63.7006C65.3096 72.5142 72.4544 79.659 81.268 79.659ZM81.268 77.7858C89.047 77.7858 95.3531 71.4796 95.3531 63.7006C95.3531 55.9216 89.047 49.6154 81.268 49.6154C73.4889 49.6154 67.1828 55.9216 67.1828 63.7006C67.1828 71.4796 73.4889 77.7858 81.268 77.7858Z" fill="currentColor"/>
            <rect x="115.862" y="65.1172" width="2.60008" height="10.6408" fill="currentColor"/>
            <rect x="105.816" y="61.0664" width="10.6408" height="2.60008" transform="rotate(-49.69 105.816 61.0664)" fill="currentColor"/>
            <rect x="119.649" y="54.625" width="2.60008" height="10.6408" transform="rotate(-40.31 119.649 54.625)" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M116.774 79.659C125.588 79.659 132.733 72.5142 132.733 63.7006C132.733 54.887 125.588 47.7422 116.774 47.7422C107.961 47.7422 100.816 54.887 100.816 63.7006C100.816 72.5142 107.961 79.659 116.774 79.659ZM116.774 77.7858C124.553 77.7858 130.86 71.4796 130.86 63.7006C130.86 55.9216 124.553 49.6154 116.774 49.6154C108.995 49.6154 102.689 55.9216 102.689 63.7006C102.689 71.4796 108.995 77.7858 116.774 77.7858Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M69.4528 77.7825C68.2275 77.4131 66.9278 77.365 65.6785 77.6427L52.4153 80.4385L48.1489 95.1443L57.8559 104.611C58.7633 105.514 59.8879 106.169 61.1214 106.512L65.5946 107.815L73.9317 79.0853L69.4528 77.7825ZM63.4808 103.963L61.9823 103.526C61.2452 103.327 60.5715 102.942 60.0253 102.408L51.6379 94.2444L54.8642 83.1228L66.3046 80.7072C67.0541 80.5403 67.834 80.5692 68.5692 80.7911L70.0677 81.2272L63.4808 103.963Z" fill="currentColor"/>
            <rect x="70.7603" y="90.0391" width="5.96062" height="13.6882" transform="rotate(-73.82 70.7603 90.0391)" fill="currentColor"/>
            <rect x="66.9868" y="103.059" width="5.89912" height="9.71816" transform="rotate(-73.82 66.9868 103.059)" fill="currentColor"/>
            <rect x="42.5317" y="93.5234" width="15.3097" height="5.8432" transform="rotate(-73.82 42.5317 93.5234)" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.7041 59.4258C18.1726 59.511 15.2862 59.882 13.1177 60.6465C11.0395 61.3791 8.58904 62.8817 8.08466 65.8365C7.63392 68.4771 9.07575 70.6996 10.2176 72.0643C11.4925 73.5882 13.2562 75.0649 15.1805 76.4376C22.8215 81.8882 35.8269 87.6601 44.0495 89.8156L45.9495 82.5676C38.5157 80.6189 26.359 75.2077 19.5318 70.3377C18.0226 69.2611 16.917 68.3101 16.2062 67.5323C17.3514 67.2324 19.1686 66.982 21.8848 66.9165L21.7041 59.4258ZM15.0469 67.9573C15.0236 67.9768 15.009 67.9857 15.0076 67.9853C15.0061 67.9848 15.0178 67.975 15.0469 67.9573Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwPowerStripIcon')

export default icon
