import * as type from '@app/constants/contacts/contacts'

type State = {
  isSummaryPopupOpen: boolean,
  isFormPopupOpen: boolean,
  isAvatarPopupOpen: boolean,
  contactsList: Array<{
    id: number,
    preferred_name: string,
    avatar: string,
    phone_number: string,
    email: string,
    address: string,
  }>,
  formInitialValues: {
    index?: number,
    preferred_name: string,
    phone_number: string,
    email?: string,
    address?: string,
  } | null,
  isContactsEmpty: boolean,
}

const initialState = {
  isSummaryPopupOpen: false,
  isFormPopupOpen: false,
  isAvatarPopupOpen: false,
  contactsList: [],
  formInitialValues: null,
  isContactsEmpty: false,
}
const updateContact = (state, action) => {
  const newArray = [...state.contactsList]
  const contactIndex = newArray.findIndex((contact) => contact.id === action.contact.user_contact.id)
  newArray[contactIndex] = action.contact.user_contact
  return newArray
}

const updateAvatar = (state, action) => {
  const newArray = [...state.contactsList]
  const contactIndex = newArray.findIndex((contact) => contact.id === action.data.user_contact_id)
  newArray[contactIndex] = {
    ...newArray[contactIndex],
    avatar: action.data.avatar_url,
  }
  return newArray
}

const contacts = (state: State = initialState, action: Object): State => {
  switch (action.type) {
  case type.SET_CONTACTS: return { ...state, contactsList: action.contacts.user_contacts }
  case type.SET_POST_CONTACT: return { ...state, contactsList: [...state.contactsList, action.contact.user_contact] }
  case type.SET_PATCH_CONTACT: return {
    ...state,
    contactsList: updateContact(state, action),
    formInitialValues: {
      ...state.formInitialValues,
      ...action.contact.user_contact,
    },
  }
  case type.SET_DELETE_CONTACT: return {
    ...state,
    contactsList: [...state.contactsList.filter((contact) => contact.id !== action.contact.user_contact.id)],
    isSummaryPopupOpen: false,
    formInitialValues: null,
  }
  case type.SET_AVATAR: return { ...state, inProgress: false, contactsList: updateAvatar(state, action) }
  case type.TOGGLE_SUMMARY_POPUP: return { ...state, isSummaryPopupOpen: !state.isSummaryPopupOpen }
  case type.TOGGLE_FORM_POPUP: return { ...state, isFormPopupOpen: !state.isFormPopupOpen }
  case type.TOGGLE_AVATAR_POPUP: return { ...state, isAvatarPopupOpen: !state.isAvatarPopupOpen }
  case type.SET_FORM_INITIAL_VALUES: return { ...state, formInitialValues: action.values }
  case type.SET_IS_CONTACTS_EMPTY: return { ...state, isContactsEmpty: action.status }
  case type.CLOSE_POPUPS: return {
    ...state,
    isFormPopupOpen: false,
    isAvatarPopupOpen: false,
    isSummaryPopupOpen: false,
  }

  default: return state
  }
}

export default contacts
