const styles = (theme) => ({
  root: {
    padding: '3rem 1.5rem',
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    boxSizing: 'border-box',
    position: 'relative',
  },
  card: {
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '1.5rem',
    marginBottom: '2rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  statusText: {
    marginRight: 10,
  },
  title: {
    marginBottom: 35,
    fontSize: 18,
  },
  syncButton: {
    fontSize: 16,
    fontStyle: 'bold',
  },
  refreshButton: {
    position: 'absolute',
    top: 20,
    right: 25,
    width: 30,
    height: 30,
  },
})

export default styles
