const getMezzoConditionStateDescription = (number: number, value: number): string => {
  if (number === 1 && value === 1) {
    return 'ui_is_in_panic_alarm'
  }
  if (number === 1 && value === 0) {
    return 'ui_is_not_in_panic_alarm'
  }

  if (number === 2 && value === 1) {
    return 'ui_is_in_duress_alarm'
  }
  if (number === 2 && value === 0) {
    return 'ui_is_not_in_duress_alarm'
  }

  if (number === 3 && value === 1) {
    return 'ui_is_in_fire_alarm'
  }
  if (number === 3 && value === 0) {
    return 'ui_is_not_in_fire_alarm'
  }
  throw new Error('condition unsupported')
}

export default getMezzoConditionStateDescription
