import React from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import classNames from 'classnames'
import { ScenesArrowBack } from 'icons'
import Wrapper from '../Wrapper/Wrapper'
import useStyles from './NavBar.style'

const defaultProps = {
  buttonName: 'save',
  onButtonClick: null,
  disabled: false,
  backClick: null,
  titleCentered: false,
  customTitle: false,
}

interface Props {
  title: string,
  withButton: boolean,
  isMobile: boolean,
  disabled?: boolean,
  backClick?: () => void,
  onButtonClick?: () => void,
  buttonName?: string,
  titleCentered?: boolean,
  customTitle?: boolean,
}

const NavBar: React.FC<Props> = (props) => {
  const {
    title,
    withButton,
    backClick = defaultProps.backClick,
    onButtonClick = defaultProps.onButtonClick,
    isMobile,
    buttonName = defaultProps.buttonName,
    disabled = defaultProps.disabled,
    titleCentered = defaultProps.titleCentered,
    customTitle = defaultProps.customTitle,
  } = props
  const classes = useStyles()
  return (
    <Wrapper className={classes.navBar}>
      <Wrapper className={classes.wrapper}>
        {backClick && (
          <IconButton
            className={classNames(
              classes.buttonMobile,
              !isMobile && classes.button,
              titleCentered && classes.buttonWithCenteredTitleMobile,
            )}
            onClick={backClick}
            aria-label="Back"
            size="large"
          >
            <ScenesArrowBack />
          </IconButton>
        )}
        <Typography
          className={classNames(
            classes.title,
            !backClick && classes.withoutBackButton,
            titleCentered && classes.titleCentered,
            !backClick && titleCentered && classes.centeredWithoutBackButton,
          )}
        >
          { customTitle ? title : I18n.t(`scene_web.${title}`)}
        </Typography>
      </Wrapper>
      {
        withButton
          && (
            <Button
              className={classes.submit}
              disabled={disabled}
              onClick={onButtonClick || (() => {})}
            >
              {I18n.t(`buttons.${buttonName}`)}
            </Button>
          )
      }
    </Wrapper>
  )
}

export default NavBar
