import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.2718 86.6267L57.4847 93.8625M43.9227 86.6267L41.7097 93.8625M37.9793 85.9632L33.9012 92.1879M49.236 86.8479V95M61.2466 85.9632L65.3247 92.2195M84.2295 58.505C84.2295 48.2042 84.2295 37.9035 84.2295 27.5711C84.2295 23.1475 84.2295 18.6922 84.2295 14.2686C84.2295 11.5196 84.356 9.1498 82.0798 6.96957C80.7837 5.73727 79.4559 5.13692 77.6856 5.04213C76.0733 4.94734 74.461 5.04213 72.8803 5.04213C62.5744 5.04213 52.2685 5.04213 41.9626 5.04213C36.8096 5.04213 31.6567 5.04213 26.5037 5.04213C25.2076 5.04213 23.9114 5.04213 22.6469 5.04213C21.3508 5.04213 20.1811 5.16852 18.9798 5.73727C16.5771 6.90638 15.0281 9.33938 15.0281 12.0252C15.0281 22.3259 15.0281 32.6267 15.0281 42.9275C15.0281 45.4868 15.0281 48.0778 15.0281 50.6372C15.0281 51.9959 15.0281 53.3546 15.0281 54.7449C15.0281 56.1352 14.9649 57.4939 15.0281 58.8842C15.1862 61.4751 16.7352 63.7818 19.1062 64.8561C20.2759 65.3932 21.414 65.488 22.6469 65.488C23.8798 65.488 25.1443 65.488 26.3773 65.488C36.6832 65.488 46.9891 65.488 57.295 65.488C62.448 65.488 67.6009 65.488 72.7539 65.488C74.05 65.488 75.3462 65.488 76.6107 65.488C77.9069 65.488 79.0765 65.3616 80.2778 64.7929C82.7121 63.6238 84.2295 61.1908 84.2295 58.505ZM58.275 82.1715C51.1304 82.1715 44.9659 82.1715 40.951 82.1715C39.0226 82.1715 37.4419 80.5916 37.4419 78.6641C37.4419 75.1568 37.4419 71.6495 37.4419 68.1422C45.5349 68.1422 53.6595 68.1422 61.7525 68.1422C61.7525 71.6495 61.7525 75.1568 61.7525 78.6641C61.7841 80.5916 60.2034 82.1715 58.275 82.1715ZM36.0509 67.0363H63.1751V68.1422H36.0509V67.0363Z" stroke="currentColor" strokeWidth="2"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'StatusDashboardIcon')

export default icon
