import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  ids: PropTypes.shape({ sphere: PropTypes.number, unit: PropTypes.number }),
  userCode: PropTypes.string,
  preferredName: PropTypes.string,
  mobile: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  avatar: PropTypes.string,
})

export default propTypes
