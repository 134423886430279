import { createLogic } from 'redux-logic'
import { UNSUBSCRIBE_GUARD_PANEL_CHANNEL } from '@app/constants/guardPanel'
import { CHANNEL_GUARD_PANEL } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeGuardPanelStatusLogic = createLogic({
  type: UNSUBSCRIBE_GUARD_PANEL_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(CHANNEL_GUARD_PANEL) && sub.unsubscribe())
  },
})

export default unsubscribeGuardPanelStatusLogic
