import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 46 102" enableBackground= "new 0 0 58.984 102.833"><g><path d="M8.322,80.968h7.862v4.365h4.249v0.224c0.003,9.543,7.734,17.274,17.277,17.276c9.54-0.002,17.274-7.733,17.277-17.276   v-2.269c2.351-1.017,3.996-3.355,3.996-6.08v-13.75c0-3.659-2.967-6.625-6.625-6.625s-6.625,2.966-6.625,6.625v13.75   c0,2.822,1.768,5.225,4.253,6.18v2.169c-0.013,6.776-5.498,12.264-12.276,12.274c-6.778-0.011-12.267-5.498-12.277-12.274v-0.224   h4.251v-4.365h7.862V76.71h-2.141l7.125-44.031h3.337v-8.279h-6.372V19.06l-6.583-1.581v-4.253l-8.008-3.192V0h-3.942v10.035   l-8.007,3.191v4.253L6.373,19.06v5.339H0v8.279h3.338l7.124,44.031h-2.14V80.968z M31.413,76.71h-6.508V32.679h13.632L31.413,76.71   z M7.332,32.679h13.631V76.71h-6.505L7.332,32.679z"/></g></svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'OutdoorWallLight')

export default icon
