import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = () => `${API_URL}/api/user_requests/incoming`

const fetchUsersRequests = (access_token) => Axios.get(ENDPOINT(access_token), {
  headers: { 'Access-Token': access_token },
})

export default {
  fetchUsersRequests,
}
