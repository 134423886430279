export const SET_UP_BILLING_PERIOD_OPEN = '@@billingPeriod/setUpBillingPeriodOpen'
export const SET_UP_BILLING_PERIOD_CLOSE = '@@billingPeriod/setUpBillingPeriodClose'

export const BILLING_PERIODS_STATS_POPUP_OPEN = '@@billingPeriod/billingStatsPopupOpen'
export const BILLING_PERIODS_STATS_POPUP_CLOSE = '@@billingPeriod/billingStatsPopupClose'

export const SYNC_BILLING_PERIODS_STATS = '@@billingPeriod/syncPeriods'

export const UPDATE_BILLING_PERIODS_STATS = '@@billingPeriod/updatePeriods'

export const SYNC_BILLING_CURRENT_STATS = '@@billingPeriod/syncCurrent'

export const UPDATE_BILLING_CURRENT_STATS = '@@billingPeriod/updateCurrent'
export const RESET_ALL_BILLING_PERIODS = '@@billingPeriod/resetAllBillingPeriods'
export const RESET_ALL_BILLING_PERIODS_CANCEL = '@@billingPeriod/resetAllBillingPeriodsCancel'
