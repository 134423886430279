import React from 'react'
import classNames from 'classnames'
import {
  List, ListItem, Paper, Typography,
} from '@mui/material'
import { AboutIcon } from 'icons'
import useStyles from '@app/components/MezzoErrors/MezzoErrors.style'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'

interface Props {
  mezzoErrors: Array<string> | string,
  inProgress: boolean,
}

const MezzoErrors: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { mezzoErrors, inProgress } = props

  const showErrors = () => {
    let errorList
    if (mezzoErrors === 'error') {
      errorList = (<ListItem>{I18n.t('mezzo_error_codes.corrupted_error_list')}</ListItem>)
    } else if (mezzoErrors instanceof Array) {
      errorList = mezzoErrors.map((error) => (
        <ListItem className={classes.item} key={error + new Date()}>
          <AboutIcon className={classes.icon} />
          <Typography className={classes.text}>
            {I18n.t(`mezzo_error_codes.mezzo_code_desc_${error}`)}
            {' '}
            (
            {I18n.t('mezzo_error_codes.code_desc')}
            {' '}
            {error}
            )
          </Typography>
        </ListItem>
      ))
    }
    return errorList
  }

  return (
    <>
      {inProgress && (
        <Wrapper className={classes.preloader}>
          <Typography className={classes.preloaderMessage}>Fetching Mezzo errors...</Typography>
        </Wrapper>
      )}
      <Paper className={classNames(inProgress && classes.blur,
        classes.root,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView)}
      >
        <List component="nav">
          {showErrors()}
        </List>
      </Paper>
    </>
  )
}

export default MezzoErrors
