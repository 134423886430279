export const mezzoLightStates = [
  {
    description: 'mezzo_light_off',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
  },
  {
    description: 'mezzo_light_low',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 1,
  },
  {
    description: 'mezzo_light_medium',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 2,
  },
  {
    description: 'mezzo_light_high',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 3,
  },
  {
    description: 'mezzo_light_any_level',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 4,
  },
]
