import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 116.071C8 118.556 10.0147 120.571 12.5 120.571H136.5C138.985 120.571 141 118.556 141 116.071V34.5C141 32.0147 138.985 30 136.5 30H12.5C10.0147 30 8 32.0147 8 34.5V116.071ZM136 115.571H13V35H136V115.571Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M56.4209 77.7031V88.5506C56.4209 90.1669 55.1136 91.4788 53.4973 91.4845H30.418C28.8057 91.4789 27.5001 90.1732 27.4944 88.5609V77.7031" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M54.5305 60.1367H29.3851C27.7734 60.1367 26.4614 63.5253 26.4614 65.1265L27.4945 77.0071H56.421L57.4541 65.1265C57.4541 63.5253 56.1318 60.1367 54.5305 60.1367V60.1367ZM45.7079 64.4536H38.1664V61.6126H45.7079V64.4536Z" fill="currentColor"/>
            <path d="M98.9231 50.0352C83.9214 50.0352 71.6731 62.3027 71.6731 77.3234C71.6731 92.3469 83.9242 104.535 98.9231 104.535C113.925 104.535 126.173 92.2676 126.173 77.2469C126.173 62.2234 113.922 50.0352 98.9231 50.0352ZM98.9231 101.506C85.5919 101.506 74.7005 90.6 74.7005 77.2469C74.7005 63.8969 85.5889 53.0647 98.9231 53.0647C112.254 53.0647 123.146 63.9704 123.146 77.3234C123.146 90.6734 112.257 101.506 98.9231 101.506ZM98.9231 76.0255C98.4964 76.0255 98.1495 76.2439 97.9366 76.5521C97.7372 76.8406 97.6635 77.1872 97.6635 77.5131V87.2495C97.6635 87.5755 97.7372 87.922 97.9366 88.2106C98.1495 88.5187 98.4964 88.7371 98.9231 88.7371C99.3497 88.7371 99.6967 88.5187 99.9096 88.2106C100.109 87.922 100.183 87.5755 100.183 87.2495V77.5131C100.183 77.1872 100.109 76.8406 99.9096 76.5521C99.6967 76.2439 99.3497 76.0255 98.9231 76.0255ZM94.0926 64.7704L87.3819 71.9005C86.8214 72.4959 86.8214 73.4064 87.3819 74.0019C87.6724 74.3105 88.0886 74.4678 88.4331 74.4678C88.7777 74.4678 89.1939 74.3105 89.4844 74.0019L96.1951 66.8718C96.7555 66.2763 96.7555 65.3658 96.1951 64.7704C95.6103 64.1491 94.6773 64.1491 94.0926 64.7704ZM103.754 64.7704C103.169 64.1491 102.236 64.1491 101.651 64.7704C101.091 65.3658 101.091 66.2763 101.651 66.8718L108.362 74.0019C108.652 74.3105 109.069 74.4678 109.413 74.4678C109.758 74.4678 110.174 74.3105 110.464 74.0019C111.025 73.4064 111.025 72.4959 110.464 71.9005L103.754 64.7704Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwPowerPointIcon')

export default icon
