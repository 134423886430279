import * as type from '@app/constants/scenes/currentSceneCreating'
import { ISensorScale, ISubDevice, IWallmoteSettings } from '@app/@types/scenes/scenesDevices'
import {
  IDelay, IDeviceStateInfo, IDuration, IUser, IUsersInfo,
} from '@app/@types/scenes/conditionsTypes'
import { IRequestBody, IRequestHeader } from '@app/@types/scenes/actionTypes'
import { IActionFront, IConditionFront } from '@app/@types/scenes/scenes'

export const saveConditionType = (conditionType: string) => ({
  type: type.SAVE_CONDITION_TYPE,
  conditionType,
} as const)

export const selectConditionDeviceType = (deviceType: string) => ({
  type: type.SELECT_CONDITION_DEVICE_TYPE,
  deviceType,
} as const)

export const saveConditionDescription = (description: string) => ({
  type: type.SAVE_CONDITION_DESCRIPTION,
  description,
} as const)

export const saveConditionAnotherSceneNumber = (anotherSceneNumber: number) => ({
  type: type.SAVE_CONDITION_ANOTHER_SCENE_NUMBER,
  anotherSceneNumber,
} as const)

export const saveConditionDeviceNumber = (conditionDeviceNumber: number) => ({
  type: type.SAVE_CONDITION_DEVICE_NUMBER,
  conditionDeviceNumber,
} as const)

export const saveActionDeviceNumber = (actionDeviceNumber: number) => ({
  type: type.SAVE_ACTION_DEVICE_NUMBER,
  actionDeviceNumber,
} as const)

export const saveActionDeviceType = (deviceType: string) => ({
  type: type.SAVE_ACTION_DEVICE_TYPE,
  deviceType,
} as const)

export const saveActionColorType = (colorType: string) => ({
  type: type.SAVE_ACTION_COLOR_TYPE,
  colorType,
} as const)

export const saveDurationValueAfterActionColorType = (colorTypeDurationValueAfter: string) => ({
  type: type.SAVE_DURATION_VALUE_AFTER_ACTION_COLOR_TYPE,
  colorTypeDurationValueAfter,
} as const)

export const saveConditionOperand = (operand: string) => ({
  type: type.SAVE_CONDITION_OPERAND,
  operand,
} as const)

export const saveConditionTimeOperand = (timeOperand: string) => ({
  type: type.SAVE_CONDITION_TIME_OPERAND,
  timeOperand,
} as const)

export const clearCurrentConditionInfo = () => ({
  type: type.CLEAR_CURRENT_CONDITION_INFO,
} as const)

export const saveConditionId = (id: number) => ({
  type: type.SAVE_CONDITION_ID,
  id,
} as const)

export const saveBinarySensorType = (sensorType: number) => ({
  type: type.SAVE_BINARY_SENSOR_TYPE,
  sensorType,
} as const)

export const saveBinarySensorValueDescription = (description: string) => ({
  type: type.SAVE_BINARY_SENSOR_VALUE_DESCRIPTION,
  description,
} as const)

export const saveBinarySensorTypeDescription = (description: string) => ({
  type: type.SAVE_BINARY_SENSOR_TYPE_DESCRIPTION,
  description,
} as const)

export const saveMultilevelSensorType = (sensorType: number) => ({
  type: type.SAVE_MULTILEVEL_SENSOR_TYPE,
  sensorType,
} as const)

export const saveMultilevelSensorScales = (scales: Array<ISensorScale>) => ({
  type: type.SAVE_MULTILEVEL_SENSOR_SCALES,
  scales,
} as const)

export const saveMultilevelSensorTypeDescription = (description: string) => ({
  type: type.SAVE_MULTILEVEL_SENSOR_TYPE_DESCRIPTION,
  description,
} as const)

export const saveMultilevelSensorValueDescription = (description: string) => ({
  type: type.SAVE_MULTILEVEL_SENSOR_VALUE_DESCRIPTION,
  description,
} as const)

export const saveSensorNotificationsEventType = (sensorEventType: number) => ({
  type: type.SAVE_SENSOR_NOTIFICATIONS_EVENT_TYPE,
  sensorEventType,
} as const)

export const saveSensorNotificationsEventDescription = (sensorEventDescription: string) => ({
  type: type.SAVE_SENSOR_NOTIFICATIONS_EVENT_DESCRIPTION,
  sensorEventDescription,
} as const)

export const saveSensorNotificationsType = (sensorType: number) => ({
  type: type.SAVE_SENSOR_NOTIFICATIONS_TYPE,
  sensorType,
} as const)

export const saveSensorNotificationsTypeDescription = (description: string) => ({
  type: type.SAVE_SENSOR_NOTIFICATIONS_TYPE_DESCRIPTION,
  description,
} as const)

export const saveConditionNumber = (conditionNumber: number) => ({
  type: type.SAVE_CONDITION_NUMBER,
  conditionNumber,
} as const)

export const saveConditionZWaveCapabilityId = (conditionZWaveCapabilityId: number) => ({
  type: type.SAVE_CONDITION_ZWAVE_CAPABILITY_ID,
  conditionZWaveCapabilityId,
} as const)

export const saveConditionDeviceId = (conditionDeviceId: number) => ({
  type: type.SAVE_CONDITION_DEVICE_ID,
  conditionDeviceId,
} as const)

export const saveConditionGeofenceNumber = (geofenceNumber: number) => ({
  type: type.SAVE_CONDITION_GEOFENCE_NUMBER,
  geofenceNumber,
} as const)

export const saveConditionGeofenceName = (geofenceName: string) => ({
  type: type.SAVE_CONDITION_GEOFENCE_NAME,
  geofenceName,
} as const)

export const saveConditionUsersInfo = (usersInfo: IUsersInfo) => ({
  type: type.SAVE_CONDITION_USERS_INFO,
  usersInfo,
} as const)

export const saveConditionGeofenceExit = (geofenceExit: boolean) => ({
  type: type.SAVE_CONDITION_GEOFENCE_EXIT,
  geofenceExit,
} as const)

export const saveConditionRoomNumber = (roomNumber: number) => ({
  type: type.SAVE_CONDITION_ROOM_NUMBER,
  roomNumber,
} as const)

export const saveActionRoomNumber = (roomNumber: number) => ({
  type: type.SAVE_ACTION_ROOM_NUMBER,
  roomNumber,
} as const)

export const saveConditionRoomState = (roomValue: number) => ({
  type: type.SAVE_CONDITION_ROOM_STATE,
  roomValue,
} as const)

export const saveActionRoomState = (roomValue: number) => ({
  type: type.SAVE_ACTION_ROOM_STATE,
  roomValue,
} as const)

export const saveActionGroupNumber = (groupNumber: number) => ({
  type: type.SAVE_ACTION_GROUP_NUMBER,
  groupNumber,
} as const)

export const saveActionGroupState = (groupValue: number) => ({
  type: type.SAVE_ACTION_GROUP_STATE,
  groupValue,
} as const)

export const saveConditionDataValue = (dataValue: number) => ({
  type: type.SAVE_CONDITION_DATA_VALUE,
  dataValue,
} as const)

export const saveConditionDataNumber = (dataNumber: number) => ({
  type: type.SAVE_CONDITION_DATA_NUMBER,
  dataNumber,
} as const)

export const saveConditionDataOperand = (dataOperand: string) => ({
  type: type.SAVE_CONDITION_DATA_OPERAND,
  dataOperand,
} as const)

export const saveConditionGroupNumber = (groupNumber: number) => ({
  type: type.SAVE_CONDITION_GROUP_NUMBER,
  groupNumber,
} as const)

export const saveConditionGroupState = (groupValue: number) => ({
  type: type.SAVE_CONDITION_GROUP_STATE,
  groupValue,
} as const)

export const saveActionZWaveCapabilityId = (actionZWaveCapabilityId: number) => ({
  type: type.SAVE_ACTION_ZWAVE_CAPABILITY_ID,
  actionZWaveCapabilityId,
} as const)

export const saveActionDeviceId = (actionDeviceId: number) => ({
  type: type.SAVE_ACTION_DEVICE_ID,
  actionDeviceId,
} as const)

export const saveReviewSceneConditionTypes = (conditionTypes: Array<string>) => ({
  type: type.SAVE_REVIEW_SCENE_CONDITION_TYPES,
  conditionTypes,
} as const)

export const addNewConditionToChain = (conditionType: string) => ({
  type: type.ADD_NEW_CONDITION_TO_CHAIN,
  conditionType,
} as const)

export const addNewActionToChain = (actionType: string) => ({
  type: type.ADD_NEW_ACTION_TO_CHAIN,
  actionType,
} as const)

export const postCondition = () => ({
  type: type.POST_CONDITION,
} as const)

export const openSceneSaveDraftPopup = () => ({
  type: type.OPEN_SCENE_SAVE_DRAFT_POPUP,
} as const)
export const closeSceneSaveDraftPopup = () => ({
  type: type.CLOSE_SCENE_SAVE_DRAFT_POPUP,
} as const)

export const saveActionType = (actionType: string) => ({
  type: type.SAVE_ACTION_TYPE,
  actionType,
} as const)

export const saveActionDescription = (actionDescription: string) => ({
  type: type.SAVE_ACTION_DESCRIPTION,
  actionDescription,
} as const)

export const saveActionId = (id: number) => ({
  type: type.SAVE_ACTION_ID,
  id,
} as const)

export const clearCurrentActionInfo = () => ({
  type: type.CLEAR_CURRENT_ACTION_INFO,
} as const)

export const postAction = () => ({
  type: type.POST_ACTION,
} as const)

export const activateEditConditionMode = () => ({
  type: type.ACTIVATE_EDIT_CONDITION_MODE,
} as const)
export const deactivateEditConditionMode = () => ({
  type: type.DEACTIVATE_EDIT_CONDITION_MODE,
} as const)

export const activateCreateConditionMode = () => ({
  type: type.ACTIVATE_CREATE_CONDITION_MODE,
} as const)
export const deactivateCreateConditionMode = () => ({
  type: type.DEACTIVATE_CREATE_CONDITION_MODE,
} as const)

export const activateCreateActionMode = () => ({
  type: type.ACTIVATE_CREATE_ACTION_MODE,
} as const)
export const deactivateCreateActionMode = () => ({
  type: type.DEACTIVATE_CREATE_ACTION_MODE,
} as const)

export const patchCondition = () => ({
  type: type.PATCH_CONDITION,
} as const)

export const patchConditionOperand = () => ({
  type: type.PATCH_CONDITION_OPERAND,
} as const)

export const activateEditActionMode = () => ({
  type: type.ACTIVATE_EDIT_ACTION_MODE,
} as const)
export const deactivateEditActionMode = () => ({
  type: type.DEACTIVATE_EDIT_ACTION_MODE,
} as const)

export const patchAction = () => ({
  type: type.PATCH_ACTION,
} as const)

export const activateAdditionalConditionMode = () => ({
  type: type.ACTIVATE_ADDITIONAL_CONDITION_MODE,
} as const)
export const deactivateAdditionalConditionMode = () => ({
  type: type.DEACTIVATE_ADDITIONAL_CONDITION_MODE,
} as const)

export const activateAdditionalActionMode = () => ({
  type: type.ACTIVATE_ADDITIONAL_ACTION_MODE,
} as const)
export const deactivateAdditionalActionMode = () => ({
  type: type.DEACTIVATE_ADDITIONAL_ACTION_MODE,
} as const)

export const saveScreenName = (screenName: string) => ({
  type: type.SAVE_SCREEN_NAME,
  screenName,
} as const)

export const changeSceneMode = (sceneMode: string) => ({
  type: type.CHANGE_SCENE_MODE,
  sceneMode,
} as const)

export const saveActionStateDescription = (stateDescription: string) => ({
  type: type.SAVE_ACTION_STATE_DESCRIPTION,
  stateDescription,
} as const)

export const saveConditionStateDescription = (stateDescription: string) => ({
  type: type.SAVE_CONDITION_STATE_DESCRIPTION,
  stateDescription,
} as const)

export const saveConditionDeviceStateInfo = (deviceStateInfo: IDeviceStateInfo) => ({
  type: type.SAVE_CONDITION_DEVICE_STATE_INFO,
  deviceStateInfo,
} as const)

export const saveSubDevicesAmount = (subDevicesAmount: number) => ({
  type: type.SAVE_SUB_DEVICES_AMOUNT,
  subDevicesAmount,
} as const)

export const saveWallmoteSettings = (wallmoteSettings: Array<IWallmoteSettings>) => ({
  type: type.SAVE_WALLMOTE_SETTINGS,
  wallmoteSettings,
} as const)

export const saveActionDeviceStateInfo = (actionDeviceStateInfo: IDeviceStateInfo) => ({
  type: type.SAVE_ACTION_DEVICE_STATE_INFO,
  actionDeviceStateInfo,
} as const)

export const clearCurrentConditionStateDescription = () => ({
  type: type.CLEAR_CURRENT_CONDITION_STATE_DESCRIPTION,
} as const)

export const saveConditionStateTimeData = (stateTimeData: string | object) => ({
  type: type.SAVE_CONDITION_STATE_TIME_DATA,
  stateTimeData,
} as const)

export const saveActionDeviceName = (name: string) => ({
  type: type.SAVE_ACTION_DEVICE_NAME,
  name,
} as const)

export const saveConditionDeviceName = (name: string) => ({
  type: type.SAVE_CONDITION_DEVICE_NAME,
  name,
} as const)

export const postConditionChangeset = () => ({
  type: type.POST_CONDITION_CHANGESET,
} as const)

export const postActionChangeset = () => ({
  type: type.POST_ACTION_CHANGESET,
} as const)

export const activateChangeConditionMode = () => ({
  type: type.ACTIVATE_CHANGE_CONDITION_MODE,
} as const)
export const deactivateChangeConditionMode = () => ({
  type: type.DEACTIVATE_CHANGE_CONDITION_MODE,
} as const)
export const activateChangeConditionStateMode = () => ({
  type: type.ACTIVATE_CHANGE_CONDITION_STATE_MODE,
} as const)
export const deactivateChangeConditionStateMode = () => ({
  type: type.DEACTIVATE_CHANGE_CONDITION_STATE_MODE,
} as const)

export const activateChangeSensorMode = () => ({
  type: type.ACTIVATE_CHANGE_SENSOR_MODE,
} as const)
export const deactivateChangeSensorMode = () => ({
  type: type.DEACTIVATE_CHANGE_SENSOR_MODE,
} as const)
export const activateChangeSensorStateMode = () => ({
  type: type.ACTIVATE_CHANGE_SENSOR_STATE_MODE,
} as const)
export const deactivateChangeSensorStateMode = () => ({
  type: type.DEACTIVATE_CHANGE_SENSOR_STATE_MODE,
} as const)

export const activateChangeSubDeviceMode = () => ({
  type: type.ACTIVATE_CHANGE_SUB_DEVICE_MODE,
} as const)
export const deactivateChangeSubDeviceMode = () => ({
  type: type.DEACTIVATE_CHANGE_SUB_DEVICE_MODE,
} as const)
export const activateChangeSubDeviceStateMode = () => ({
  type: type.ACTIVATE_CHANGE_SUB_DEVICE_STATE_MODE,
} as const)
export const deactivateChangeSubDeviceStateMode = () => ({
  type: type.DEACTIVATE_CHANGE_SUB_DEVICE_STATE_MODE,
} as const)

export const activateChangeActionStateMode = () => ({
  type: type.ACTIVATE_CHANGE_ACTION_STATE_MODE,
} as const)
export const deactivateChangeActionStateMode = () => ({
  type: type.DEACTIVATE_CHANGE_ACTION_STATE_MODE,
} as const)

export const activateChangeActionMode = () => ({
  type: type.ACTIVATE_CHANGE_ACTION_MODE,
} as const)
export const deactivateChangeActionMode = () => ({
  type: type.DEACTIVATE_CHANGE_ACTION_MODE,
} as const)

export const saveInitialConditionInfo = () => ({
  type: type.SAVE_INITIAL_CONDITION_INFO,
} as const)

export const clearInitialConditionInfo = () => ({
  type: type.CLEAR_INITIAL_CONDITION_INFO,
} as const)

export const restoreConditionInfo = () => ({
  type: type.RESTORE_CONDITION_INFO,
} as const)

export const saveEditedCondition = (condition: IConditionFront) => ({
  type: type.SAVE_EDITED_CONDITION,
  condition,
} as const)

export const clearCurrentSceneInfo = () => ({
  type: type.CLEAR_CURRENT_SCENE_INFO,
} as const)

export const saveEditedAction = (action: IActionFront) => ({
  type: type.SAVE_EDITED_ACTION,
  action,
} as const)

export const saveInitialActionInfo = () => ({
  type: type.SAVE_INITIAL_ACTION_INFO,
} as const)

export const clearInitialActionInfo = () => ({
  type: type.CLEAR_INITIAL_ACTION_INFO,
} as const)

export const restoreActionInfo = () => ({
  type: type.RESTORE_ACTION_INFO,
} as const)

export const saveActionDelay = (delay: Partial<IDelay>) => ({
  type: type.SAVE_ACTION_DELAY,
  delay,
} as const)

export const saveActionDelayDescription = (delayDescription: string) => ({
  type: type.SAVE_ACTION_DELAY_DESCRIPTION,
  delayDescription,
} as const)

export const saveActionDuration = (duration: Partial<IDuration>) => ({
  type: type.SAVE_ACTION_DURATION,
  duration,
} as const)

export const saveActionDurationDescription = (durationDescription: string) => ({
  type: type.SAVE_ACTION_DURATION_DESCRIPTION,
  durationDescription,
} as const)

export const saveActionDurationValueAfter = (value: string) => ({
  type: type.SAVE_ACTION_DURATION_VALUE_AFTER,
  value,
} as const)

export const clearTriggeringAndObservingDevices = () => ({
  type: type.CLEAR_TRIGGERING_AND_OBSERVING_DEVICES,
} as const)

export const saveTriggeringDeviceType = (deviceType: number) => ({
  type: type.SAVE_TRIGGERING_DEVICE_TYPE,
  deviceType,
} as const)

export const saveTriggeringDeviceNumber = (deviceNumber: number) => ({
  type: type.SAVE_TRIGGERING_DEVICE_NUMBER,
  deviceNumber,
} as const)

export const saveTriggeringDeviceName = (deviceName: string) => ({
  type: type.SAVE_TRIGGERING_DEVICE_NAME,
  deviceName,
} as const)

export const saveTriggeringDeviceState = (deviceState: number) => ({
  type: type.SAVE_TRIGGERING_DEVICE_STATE,
  deviceState,
} as const)

export const saveObservingDeviceType = (deviceType: number) => ({
  type: type.SAVE_OBSERVING_DEVICE_TYPE,
  deviceType,
} as const)

export const saveObservingDeviceNumber = (deviceNumber: number) => ({
  type: type.SAVE_OBSERVING_DEVICE_NUMBER,
  deviceNumber,
} as const)

export const saveObservingDeviceName = (deviceName: string) => ({
  type: type.SAVE_OBSERVING_DEVICE_NAME,
  deviceName,
} as const)

export const saveText = (text: string) => ({
  type: type.SAVE_TEXT,
  text,
} as const)

export const saveSelectedUsers = (selectedUsers: Array<IUser>) => ({
  type: type.SAVE_SELECTED_USERS,
  selectedUsers,
} as const)

export const saveRequestUrl = (requestUrl: string) => ({
  type: type.SAVE_REQUEST_URL,
  requestUrl,
} as const)

export const saveRequestType = (requestType: string) => ({
  type: type.SAVE_REQUEST_TYPE,
  requestType,
} as const)

export const updateRequestBody = (entryType: string, data: string, number: number) => ({
  type: type.UPDATE_REQUEST_BODY,
  entryType,
  data,
  number,
} as const)

export const pushNewToRequestBody = () => ({
  type: type.PUSH_NEW_TO_REQUEST_BODY,
} as const)

export const updateRequestHeader = (entryType: string, data: string, number: number) => ({
  type: type.UPDATE_REQUEST_HEADER,
  entryType,
  data,
  number,
} as const)

export const pushNewToRequestHeader = () => ({
  type: type.PUSH_NEW_TO_REQUEST_HEADER,
} as const)

export const saveRequestBody = (body: Array<IRequestBody>) => ({
  type: type.SAVE_REQUEST_BODY,
  body,
} as const)

export const saveRequestHeader = (header: Array<IRequestHeader>) => ({
  type: type.SAVE_REQUEST_HEADER,
  header,
} as const)

export const saveCurrentActionSubdevicesList = (devices: Array<ISubDevice>) => ({
  type: type.SAVE_CURRENT_ACTION_SUBDEVICES_LIST,
  devices,
} as const)

export const saveCurrentActionSubdevice = (device: ISubDevice) => ({
  type: type.SAVE_CURRENT_ACTION_SUBDEVICE,
  device,
} as const)

export const resetCurrentSceneCreating = () => ({
  type: type.RESET_CURRENT_SCENE_CREATING,
} as const)
