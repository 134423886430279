import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M15.94 30h-1.88a2.76 2.76 0 0 1-2.76-2.76v-.64a12.1 12.1 0 0 1-1.88-.78l-.46.45a2.76 2.76 0 0 1-3.9 0l-1.33-1.33a2.76 2.76 0 0 1 0-3.9l.45-.46a12.4 12.4 0 0 1-.78-1.88h-.64A2.76 2.76 0 0 1 0 15.94v-1.88a2.76 2.76 0 0 1 2.76-2.76h.64c.2-.65.47-1.28.78-1.88l-.45-.46a2.76 2.76 0 0 1 0-3.9l1.33-1.33a2.76 2.76 0 0 1 3.9 0l.46.45c.6-.31 1.23-.57 1.88-.78v-.64A2.76 2.76 0 0 1 14.06 0h1.88a2.76 2.76 0 0 1 2.76 2.76v.64c.65.2 1.28.47 1.88.78l.46-.45a2.76 2.76 0 0 1 3.9 0l1.33 1.33a2.76 2.76 0 0 1 0 3.9l-.45.46c.31.6.57 1.23.78 1.88h.64A2.76 2.76 0 0 1 30 14.06v1.88a2.76 2.76 0 0 1-2.76 2.76h-.64c-.2.65-.47 1.28-.78 1.88l.45.46a2.76 2.76 0 0 1 0 3.9l-1.33 1.33a2.76 2.76 0 0 1-3.9 0l-.46-.45c-.6.31-1.23.57-1.88.78v.64A2.76 2.76 0 0 1 15.94 30Zm-6.23-6.03c.84.5 1.74.88 2.69 1.12.38.1.65.45.65.85v1.3a1 1 0 0 0 1 1h1.9a1 1 0 0 0 1-1v-1.3c0-.4.27-.75.65-.85.95-.24 1.85-.62 2.69-1.12a.88.88 0 0 1 1.07.14l.92.92a1 1 0 0 0 1.42 0l1.33-1.33a1 1 0 0 0 0-1.42l-.92-.92a.88.88 0 0 1-.14-1.07c.5-.84.88-1.74 1.12-2.69.1-.38.45-.65.85-.65h1.3a1 1 0 0 0 1-1v-1.9a1 1 0 0 0-1-1h-1.3a.88.88 0 0 1-.85-.65c-.24-.95-.62-1.85-1.12-2.69a.88.88 0 0 1 .14-1.07l.92-.92a1 1 0 0 0 0-1.42L23.7 4.97a1 1 0 0 0-1.42 0l-.92.92a.88.88 0 0 1-1.07.14c-.84-.5-1.74-.88-2.69-1.12a.88.88 0 0 1-.65-.85v-1.3a1 1 0 0 0-1-1h-1.9a1 1 0 0 0-1 1v1.3c0 .4-.27.75-.65.85-.95.24-1.85.62-2.69 1.12a.88.88 0 0 1-1.07-.14l-.92-.92a1 1 0 0 0-1.42 0L4.97 6.3a1 1 0 0 0 0 1.42l.92.92a.9.9 0 0 1 .14 1.07c-.5.84-.88 1.74-1.12 2.69a.88.88 0 0 1-.85.65h-1.3a1 1 0 0 0-1 1v1.9a1 1 0 0 0 1 1h1.3c.4 0 .75.27.85.65.24.95.62 1.85 1.12 2.69a.9.9 0 0 1-.14 1.07l-.92.92a1 1 0 0 0 0 1.42l1.33 1.33a1 1 0 0 0 1.42 0l.92-.92a.9.9 0 0 1 1.07-.14Z"/>
        <path d="M15 21.53a6.53 6.53 0 1 1 .01-13.07A6.53 6.53 0 0 1 15 21.53Zm0-11.3a4.77 4.77 0 1 0 .01 9.55 4.77 4.77 0 0 0-.01-9.55Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h30v30H0z"/>
        </clipPath>
      </defs>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SettingsIcon', "0 0 30 30")

export default icon