import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
  },
  rootMobile: {
    padding: '1rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: 30,
    marginBottom: 40,
  },
  iconWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  subTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 15,
  },
  typeCard: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 88,
    padding: '16px',
    borderRadius: 5,
    marginBottom: 45,
    cursor: 'pointer',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  chevronIcon: {
    transform: 'rotate(90deg)',
  },
  chevronIconOpened: {
    transform: 'rotate(270deg)',
  },
  typeList: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 360,
    padding: '16px 0',
  },
  typeListMobile: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 300,
    padding: '8px 0',
  },
  typeItem: {
    backgroundColor: theme.backgrounds.cardBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    transition: '0.2s ease-in',
    padding: '8px 20px',
    '&:hover': {
      backgroundColor: theme.backgrounds.hover,
    },
  },
  typeCardTitle: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 10,
  },
  typeCardDescription: {
    color: '#929292',
    fontSize: '16px',
    lineHeight: '19px',
  },
  typeTitle: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  typeDescription: {
    color: '#929292',
    fontSize: '16px',
    lineHeight: '19px',
  },
  durationCard: {
    position: 'relative',
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: 5,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: 150,
  },
  durationCardTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 15,
    color: theme.texts.subText,
  },
  timerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    marginBottom: 20,
  },
  timerWrapperMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
    marginBottom: 20,
  },
  timeInput: {
    width: 54,
  },
  button: {
    width: '100%',
    height: 50,
  },
}))

export default useStyles
