import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M4.989 5.225c1.847-1.887 5.066-2.957 7.801-3.447 1.356-.243 2.556-.338 3.354-.327.077.001.149.003.215.006-1.427 1.626-1.868 3.446-2.176 5.126l-.116.652c-.286 1.63-.531 3.025-1.452 4.147-.762.93-1.877 1.434-3.076 1.681-1.198.247-2.433.228-3.37.147l-.345-.03-.15.312.452.217-.451-.217v.001l-.001.002-.002.004-.008.017a6.144 6.144 0 0 0-.13.29c-.084.198-.2.48-.325.828-.25.685-.543 1.63-.724 2.672a.555.555 0 0 1-.232 0 .65.65 0 0 1-.247-.094c.488-3.442 2.759-6.595 4.976-8.925a32.223 32.223 0 0 1 2.995-2.763 27.65 27.65 0 0 1 1.296-.99l.016-.01.004-.003V4.52l-.397-.899.115.487a58.51 58.51 0 0 0-.116-.487h-.002l-.005.002-.018.004a5.08 5.08 0 0 0-.296.082c-.2.06-.485.15-.835.28-.7.259-1.662.673-2.72 1.296C7.144 6.39 4.952 8.162 3.4 10.901c-.35-1.433-.43-3.612 1.589-5.676z"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EcoModeDeviceIcon')

export default icon
