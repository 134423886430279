import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Close } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import DropzoneInput from '../DropzoneInput/DropzoneInput'

class AvatarPopup extends React.PureComponent {
  onCloseButtonClick=() => {
    const {
      closePopup,
      cleanAvatarFile,
    } = this.props
    closePopup()
    cleanAvatarFile()
  }

  render() {
    const {
      classes,
      submit,
      chooseAvatarFile,
      cleanAvatarFile,
      avatarData,
      fileChoosen,
      isMobile,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open
      >
        <DialogTitle
          className={classes.title}
        >
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.onCloseButtonClick}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DropzoneInput
            chooseAvatarFile={chooseAvatarFile}
            cleanAvatarFile={cleanAvatarFile}
            avatarData={avatarData}
            isMobile={isMobile}
          />
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={!fileChoosen}
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

AvatarPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  chooseAvatarFile: PropTypes.func.isRequired,
  cleanAvatarFile: PropTypes.func.isRequired,
  avatarData: PropTypes.object,
  fileChoosen: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
}
AvatarPopup.defaultProps = {
  fileChoosen: false,
  avatarData: null,
}

AvatarPopup.displayName = 'AvatarPopup'

export default AvatarPopup
