import React from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography,
} from '@mui/material'
import { Close } from 'icons'
import Wrapper from '@app//components/Common/Wrapper/Wrapper'
import useStyles from './EditNamePopup.style'

interface Props {
  onClick: (name: string, serialNumber: string) => void,
  onCloseIconClick: () => void,
  ssid: string,
  oldName: string,
  serialNumber: string,
}

const EditNamePopup: React.FC<Props> = (props) => {
  const {
    onClick,
    ssid,
    onCloseIconClick,
    oldName,
    serialNumber,
  } = props
  const [name, setName] = React.useState(oldName)
  const [error, setError] = React.useState(false)
  const [helperText, setHelperText] = React.useState('')
  const disableButton = name === oldName || error
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value
    setName(newName)

    switch (true) {
    case newName.trim() === '':
      setError(true)
      setHelperText("Name shouldn't be empty")
      break
    case newName.length > 16:
      setError(true)
      setHelperText('The device name must be a maximum of 16 characters')
      break
    default:
      setError(false)
      setHelperText('')
    }
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        className={classes.titleWrapper}
      >
        <Typography className={classes.title}>Change device name</Typography>
        <Typography className={classes.ssid}>
          {' '}
          {`Device SSID (${ssid})`}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="Close"
        className={classes.closeIconButton}
        onClick={onCloseIconClick}
        size="large"
      >
        <Close className={classes.closeIcon} />
      </IconButton>
      <DialogContent>
        <DialogContentText
          variant="body1"
          className={classes.text}
        />
        <TextField
          id="standard-basic"
          variant="standard"
          value={name}
          onChange={handleChange}
          error={error}
          helperText={helperText}
          fullWidth
        />
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          color="primary"
          className={classes.button}
          variant="outlined"
          onClick={() => onClick(name.trim(), serialNumber)}
          disabled={disableButton}
        >
          {I18n.t('buttons.save')}
        </Button>
      </Wrapper>
    </Dialog>
  )
}

export default EditNamePopup
