import React from 'react'
import PropTypes from 'prop-types'
import LoadingCard from '@app/components/LoadingCard'
import { withStyles } from '@app/components/styles'

const LoadingCardEnhanced = withStyles(LoadingCard)

const withAuthAccessToken = (WrappedComponent) => {
  class Authable extends React.Component {
    componentDidMount() {
      const { onAuthByToken, validAccessToken, onAuthSkip } = this.props
      if (!validAccessToken) {
        const params = new URLSearchParams(document.URL.split('#')[1])
        const accessToken = params.get('access_token')
        const expiresIn = parseInt(params.get('expires_in'), 10) * 1000
        const tokenType = params.get('token_type')
        const refreshToken = params.get('refresh_token')
        if (!accessToken || !expiresIn) {
          // TODO: @kostina why the code happen?
          return
        }
        onAuthByToken(accessToken, expiresIn, tokenType, refreshToken)
      } else {
        onAuthSkip()
      }
    }

    render() {
      const { inProgress } = this.props
      if (inProgress) {
        return <LoadingCardEnhanced />
      }
      return <WrappedComponent {...this.props} />
    }
  }

  Authable.propTypes = {
    inProgress: PropTypes.bool,
    validAccessToken: PropTypes.bool,
    onAuthByToken: PropTypes.func.isRequired,
    onAuthSkip: PropTypes.func.isRequired,
  }

  Authable.defaultProps = {
    inProgress: true,
    validAccessToken: false,
  }

  return Authable
}

export default withAuthAccessToken
