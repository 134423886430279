import { hiddenNotifications } from '@app/lib/Notifications/hiddenNotifiсations'
import { INotification } from '@app/@types/notifications'

const removePrefix = (message: string): string => {
  const parts = message.split(':')
  return parts.length > 1 ? parts.slice(1).join(':').trim() : message
}

export const getNotificationForStatusBar = (notifications: Array<INotification>, selectedSerialnumber: string): INotification | null => (notifications.length ? notifications.filter((notification) => (!hiddenNotifications.includes(notification.type) && notification.data.serial_number === selectedSerialnumber)).map((notification) => ({
  ...notification,
  message: removePrefix(notification.message),
}))[0]
  : null)
