import React from 'react'
import { Tab, Tabs } from '@mui/material'
import TwrDevicesList
  from '@app/containers/MyAutomation/CreateAction/DevicesActions/TwrDevices/TwrDevicesList'
// eslint-disable-next-line max-len
import TwrDevicesByGroupList
  from '@app/containers/MyAutomation/CreateAction/DevicesActions/TwrDevices/TwrDevicesByGroupList'
// eslint-disable-next-line max-len
import TwrDevicesByRoomsList
  from '@app/containers/MyAutomation/CreateAction/DevicesActions/TwrDevices/TwrDevicesByRoomsList'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { chooseTab } from '@app/actions/scenes/devicesTabs'
import { useDispatch } from '@app/store'
import useStyles from './TwrTabs.style'

interface Props {
  currentTab: number,
}

const TwrTabs: React.FC<Props> = (props) => {
  const { currentTab } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  const handleChange = (event: any, value: number) => {
    dispatch(chooseTab(value))
  }

  return (
    <Wrapper>
      <Tabs
        className={classes.deviceTabs}
        value={currentTab}
        indicatorColor="primary"
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab
          label="By devices"
        />
        <Tab
          label="By room"
        />
        <Tab
          label="By group"
        />
      </Tabs>
      {currentTab === 0 && (
        // @ts-ignore
        <TwrDevicesList />
      )}
      {currentTab === 1 && (
        // @ts-ignore
        <TwrDevicesByRoomsList />
      )}
      {currentTab === 2 && (
        // @ts-ignore
        <TwrDevicesByGroupList />
      )}
    </Wrapper>
  )
}

export default TwrTabs
