const styles = (theme) => ({
  decreasingArrow: {
    position: 'relative',
    margin: '0 auto',
    width: 10,
    height: 13,
    backgroundColor: theme.energyGraph.decreasingArrow,
    marginBottom: 5,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 13,
      right: -5,
      border: '10px solid #ffffff',
      borderRightColor: 'transparent',
      borderLeftColor: 'transparent',
      borderTopColor: theme.energyGraph.decreasingArrow,
      borderBottomColor: 'transparent',
    },
  },
  increasingArrow: {
    position: 'relative',
    margin: '0 auto',
    width: 10,
    height: 13,
    backgroundColor: theme.energyGraph.decreasingArrow,
    marginBottom: -5,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: -20,
      right: -5,
      border: '10px solid #ffffff',
      borderRightColor: 'transparent',
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderBottomColor: theme.energyGraph.decreasingArrow,
    },
  },
  decreasingArrowDisabled: {
    backgroundColor: theme.scenes.disabledElement,
    '&::after': {
      borderTopColor: theme.scenes.disabledElement,
    },
  },
  increasingArrowDisabled: {
    backgroundColor: theme.scenes.disabledElement,
    '&::after': {
      borderBottomColor: theme.scenes.disabledElement,
    },
  },
})

export default styles
