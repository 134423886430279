import React from 'react'
import { Typography } from '@mui/material'
import getContrastColor from '@app/helpers/scenes/getContrastColor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IActionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './DoubleColorDescription.style'

interface Props {
  action: IActionFromBackend,
}

const DoubleColorDescriptionSetColorWithDuration: React.FC<Props> = (props) => {
  const {
    action,
  } = props
  const classes = useStyles()
  const infoArray = action.description.split('ௐ')
  return (
    <Wrapper className={classes.descriptionWrapper}>
      <Typography
        className={classes.sceneInfoText}
      >
        {(infoArray[0].split('then'))[0]}
      </Typography>
      {/* @ts-ignore */}
      {(action.value_title.colours_before !== 'rgba(0, 0, 0, 1)') && (
        <span
          className={classes.colorBlock}
          // @ts-ignore
          style={{ backgroundColor: action.value_title.colours_before, borderColor: getContrastColor(action.value_title.colours_before) }}
        />
      )}
      {(infoArray.length === 2)
      && (
        <>
          <Typography
            className={classes.sceneInfoText}
          >
            {infoArray[1].split(')')[1]}
          </Typography>
        </>
      )}
      {/* @ts-ignore */}
      {((infoArray.length === 2 && action.value_title.colours_after && action.value_title.colours_after !== 'rgba(0, 0, 0, 1)'))
        && (
          <>
            <Typography
              className={classes.sceneInfoText}
            >
              then set color to
            </Typography>
            <span
              className={classes.colorBlock}
              // @ts-ignore
              style={{ backgroundColor: action.value_title.colours_after, borderColor: getContrastColor(action.value_title.colours_after) }}
            />
          </>
        )}
      {((infoArray.length === 3))
        && (
          <>
            <Typography
              className={classes.sceneInfoTextTriple}
            >
              {infoArray[1].split(')')[1].split('then')[0]}
            </Typography>
            <Typography
              className={classes.sceneInfoText}
            >
              then set color to
            </Typography>
            <span
              className={classes.colorBlock}
              // @ts-ignore
              style={{ backgroundColor: action.value_title.colours_after, borderColor: getContrastColor(action.value_title.colours_after) }}
            />
          </>
        )}
    </Wrapper>
  )
}

export default DoubleColorDescriptionSetColorWithDuration
