import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_ZWAVE_DEVICE_INFO } from '@app/constants/myDevices/zwaveDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { startFetchingDeviceStats, endFetchingDeviceStats, setZwaveDeviceInfo } from '@app/actions/myDevices/deviceInfo/fetchZwaveDeviceInfo'
import Api from '@app/api/myDevices/deviceInfo/fetchDeviceInfo'
import { setCurrentDevice } from '@app/actions/myDevices/myDevices'

const fetchZwaveDeviceInfo = createLogic({
  type: FETCH_ZWAVE_DEVICE_INFO,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { myDevices: { myDevices: { currentDevice } } } = getState()
    next({
      ...action,
      accessToken,
      currentDevice,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_ZWAVE_DEVICE_INFO))
    dispatch(startFetchingDeviceStats())
    try {
      const info = await Api.fetchZwaveDeviceInfo(action.accessToken, action.id)
      dispatch(setZwaveDeviceInfo(action.id, info.data))
      dispatch(setCurrentDevice({ ...action.currentDevice, info: info.data }))
      dispatch(apiRequestDone(FETCH_ZWAVE_DEVICE_INFO))
      dispatch(endFetchingDeviceStats())
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('FETCH_ZWAVE_DEVICE_INFO failed without error')
      }
    }
    done()
  },
})

export default fetchZwaveDeviceInfo
