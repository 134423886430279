// @ts-nocheck
import { Reducer, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { i18nReducer } from 'react-redux-i18n'

import { AppState } from '@app/@types/store'
import numberOfZones from '@app/reducers/numberOfZones'
import mySecurity from '@app/reducers/mySecurity/mySecurity'
import appConfig from '@app/reducers/appConfig/appConfig'
import anotherScenesList from './scenes/anotherScenesList'
import healthAlert from './healthAlert'
import menu from './menu'
import unit from './unit'
import secrets from './secrets'
import queue from './queue'
import shadowIn from './shadowIn'
import currentUser from './users/currentUser'
import pillsReminders from './pillsReminders/pillsReminders'
import units from './units'
import apiRequest from './apiRequest'
import app from './app'
import memberList from './users/memberList'
import usersRequests from './users/usersRequests'
import currentRequest from './users/currentRequest'
import currentMember from './users/currentMember'
import geofenceUsers from './users/geofenceUsers'
import avatar from './users/avatar'
import secretCode from './users/secretCode'
import events from './events'
import notifications from './notifications/notifications'
import notificationsSettings from './notifications/notificationsSettings'
import notificationsChannel from './notifications/notificationsChannel'
import myEnergy from './myEnergy/myEnergy'
import energyTab from './myEnergy/energyTab'
import deviceManager from './myEnergy/deviceManager'
import ecoMode from './myEnergy/ecoMode'
import roomsStats from './myEnergy/roomsStats'
import mobileDetector from './mobileDetector'
import billingPeriod from './myEnergy/billingPeriod'
import billingCurrentStats from './myEnergy/billingCurrentStats'
import billingPeriodsStats from './myEnergy/billingPeriodsStats'
import setUpBillingPeriod from './myEnergy/setUpBillingPeriod'
import scenesList from './scenes/scenesList'
import currentSceneCreating from './scenes/currentSceneCreating'
import currentSceneConditionsActions from './scenes/currentSceneConditionsActions'
import counter from './scenes/counter'
import editScene from './scenes/editScene'
import devicesTabs from './scenes/devicesTabs'
import twrDevices from './scenes/twrDevices/twrDevices'
import twrDevicesByGroups from './scenes/twrDevices/twrDevicesByGroups'
import twrDevicesByRooms from './scenes/twrDevices/twrDevicesByRooms'
import zWaveDevices from './scenes/zWaveDevices/zWaveDevices'
import zWaveDevicesByGroups from './scenes/zWaveDevices/zWaveDevicesByGroups'
import zWaveDevicesByRooms from './scenes/zWaveDevices/zWaveDevicesByRooms'
import subDevices from './scenes/zWaveDevices/subDevices'
import sensorNotifications from './scenes/zWaveDevices/sensorNotifications'
import securityOptions from './scenes/zWaveDevices/securityOptions'
import forgotPassword from './wizard/forgotPassword'
import authByPassword from './wizard/authByPassword'
import signUp from './wizard/signUp'
import deleteAccount from './wizard/deleteAccount'
import myStatusDevices from './myStatus/myStatusDevices'
import myStatusPopup from './myStatus/myStatusPopup'
import myDevices from './myDevices/myDevices'
import failedNode from './myDevices/failedNode'
import geofences from './Geofences/geofences'
import roomsScenes from './scenes/roomsScenes/roomsScenes'
import groupsScenes from './scenes/groupsScenes/groupsScenes'
import apiChecking from './scenes/pushNotifications/apiChecking'
import groups from './groups/groups'
import rooms from './rooms/rooms'
import contacts from './contacts/contacts'
import guardPanel from './guardPanel'
import sphereSort from './sphereSort'
import timers from './timers/timers'
import virtualSwitches from './virtualSwitches/virtualSwitches'
import activitiesChannel from './activities/activitiesChannel'
import activities from './activities/activities'
import mezzoTime from './mezzoTime'
import countersChannel from './syncCounters/countersChannel'
import countersStatus from './syncCounters/countersStatus'
import cloudFirmwareUpdateInfo from './cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import cloudFirmwareUpdateChannel from './cloudFirmwareUpdate/cloudFirmwareUpdateChannel'
import poConfig from './po/poConfig'

export const createRootReducer = (): Reducer<AppState> => combineReducers({
  scenes: combineReducers({
    scenesList,
    currentSceneCreating,
    currentSceneConditionsActions,
    counter,
    editScene,
    devicesTabs,
    twrDevices,
    twrDevicesByGroups,
    twrDevicesByRooms,
    zWaveDevices,
    zWaveDevicesByGroups,
    zWaveDevicesByRooms,
    roomsScenes,
    groupsScenes,
    apiChecking,
    subDevices,
    sensorNotifications,
    securityOptions,
    anotherScenesList,
  }),
  login: combineReducers({
    authByPassword,
    signUp,
    forgotPassword,
    deleteAccount,
  }),
  users: combineReducers({
    avatar,
    currentUser,
    memberList,
    usersRequests,
    currentRequest,
    currentMember,
    geofenceUsers,
    secretCode,
  }),
  apiRequest,
  app,
  notifications: combineReducers({
    notifications,
    notificationsSettings,
    notificationsChannel,
  }),
  activities: combineReducers({
    activities,
    activitiesChannel,
  }),
  menu,
  unit,
  secrets,
  queue,
  shadowIn,
  pillsReminders,
  units,
  events,
  myEnergy,
  energyTab,
  deviceManager,
  ecoMode,
  roomsStats,
  mobileDetector,
  billingPeriod,
  billingPeriodsStats,
  billingCurrentStats,
  setUpBillingPeriod,
  forgotPassword,
  guardPanel,
  myStatus: combineReducers({
    myStatusDevices,
    myStatusPopup,
  }),
  myDevices: combineReducers({
    myDevices,
    failedNode,
  }),
  geofences,
  groups,
  rooms,
  contacts,
  sphereSort,
  timers,
  virtualSwitches,
  mezzoTime,
  syncCounters: combineReducers({
    countersChannel,
    countersStatus,
  }),
  cloudFirmwareUpdate: combineReducers({
    cloudFirmwareUpdateChannel,
    cloudFirmwareUpdateInfo,
  }),
  healthAlert,
  poConfig,
  numberOfZones,
  mySecurity,
  appConfig,
  form: formReducer,
  i18n: i18nReducer,
})
