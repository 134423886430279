import React, { useEffect, useState } from 'react'
import { IconButton, Typography, Paper } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import getTimerDurationDescription from '@app/helpers/timers/getTimerDurationDescription'
import getTimerType from '@app/helpers/timers/getTimerType'
import { TIMERS_RESET } from '@app/constants/routes'
import {
  saveTimerData,
  setCurrentTrigger,
  syncTimers,
  timerTrigger,
  unsubscribeTimerChannel,
} from '@app/actions/timers/timers'
import { ChevronLeft, ReloadCircle, ResetGear } from 'icons'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import ProgressPopup from '@app/components/Common/ProgressPopup/ProgressPopup'
import { checkIfGuestOrFamily } from '@app/helpers/checkIfGuestOrFamily'
import { ITimer } from '@app/@types/timers/timersTypes'
import { IScene } from '@app/@types/scenes/scenes'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './Timers.style'

interface Props {
  timers: Array<ITimer>,
  timerData: ITimer,
  role: string,
  isMobile: boolean,
}

const Timers: React.FC<Props> = (props) => {
  const {
    timers, timerData, role, isMobile,
  } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [timersList, setTimersList] = useState<Array<ITimer>>(timers)
  const [timerStopPopup, setTimerStopPopup] = useState<boolean>(false)
  const [timerStopProgress, setTimerStopProgress] = useState<boolean>(false)

  useEffect(() => setTimersList(timers), [])

  useEffect(() => {
    setTimersList(timers)
    const progressTimer = timers.find((timer) => timer.number === timerData.number)
    if (timerStopProgress && progressTimer?.trigger_status === 'success') {
      navigate(`/web/my_devices/my_mezzo_settings/timers/${timerData.number}/settings`)
    }
  }, [timers])

  useEffect(() => () => {
    dispatch(unsubscribeTimerChannel())
  }, [])

  const setCurrentTimer = (timer: ITimer) => {
    const currentType = timer.timer_type === 'no_timer' ? 'one_shot' : timer.timer_type
    return dispatch(saveTimerData({
      number: timer.number,
      type: currentType,
      duration: timer.duration,
      scenes: timer.scenes,
    }))
  }

  const getTimerName = (number: number, status: string): string => {
    let currentName = `${I18n.t(`timers.timer_${number}`)}`
    if (status === 'commit_to_mezzo') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (saving)`
    } else if (status === 'not_saved') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (not saved)`
    } else if (status === 'deleting') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (deleting)`
    }
    return currentName
  }

  const getTimerScenesNames = (scenesList: Array<IScene>): string => {
    if (scenesList) {
      return (scenesList.length ? scenesList.map((scene) => scene.name).join(', ') : I18n.t('timers.n_a'))
    }
    return I18n.t('timers.n_a')
  }

  const goToTimerSettingsPage = (timer: ITimer, index: number, userRole: string) => {
    if (timer.trigger_status === 'progress' || checkIfGuestOrFamily(userRole)) {
      return
    }
    if (timer.trigger_status === 'success' && timer.state === 'start') {
      setCurrentTimer(timer)
      setTimerStopPopup(true)
    } else {
      setCurrentTimer(timer)
      navigate(`/web/my_devices/my_mezzo_settings/timers/${index}/settings`)
    }
  }

  const setTrigger = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, trigger: string, triggerTimer: ITimer) => {
    event.stopPropagation()
    setCurrentTimer(triggerTimer)
    dispatch(setCurrentTrigger(trigger))
    dispatch(timerTrigger())
  }

  return (
    <>
      <Paper className={classNames(classes.root, isMobile && classes.rootMobile)}>
        <Wrapper className={classes.iconWrapper}>
          <Wrapper className={classes.rightSideIcons}>
            {!checkIfGuestOrFamily(role) && (
              <IconButton
                size="small"
                onClick={() => navigate(TIMERS_RESET)}
                aria-label="reset"
              >
                <ResetGear />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => {
                dispatch(syncTimers())
              }}
              aria-label="reload"
            >
              <ReloadCircle />
            </IconButton>
          </Wrapper>
        </Wrapper>
        {timers.length ? (
          <Wrapper className={classNames(classes.settingsGrid, isMobile && classes.settingsGridMobile)}>
            {timersList.map((timer, index) => (
              <Wrapper
                className={classNames(classes.setting,
                  isMobile && classes.settingMobile,
                  timer.trigger_status === 'progress' && classes.settingDisabled,
                  timer.status !== 'complete' && classes.settingCentered)}
                key={`timer-${index + 1}`}
                onClick={() => {
                  goToTimerSettingsPage(timer, index + 1, role)
                }}
              >
                <Wrapper className={classes.cardLeftSide}>
                  <Typography className={classes.timerTitle}>{getTimerName(timer.number, timer.status)}</Typography>
                  <Typography className={classes.timerType}>
                    {`${I18n.t('timers.type')}: ${timer.timer_type === 'no_timer' ? I18n.t('timers.n_a') : getTimerType(timer.timer_type)}`}
                  </Typography>
                  <Typography className={classNames(classes.timerDuration,
                    ((timer.timer_type === 'one_shot' || timer.timer_type === 'repeating') && timer.status === 'complete'))}
                  >
                    {`${I18n.t('timers.duration')}: ${getTimerDurationDescription(timer.timer_type, timer.duration)}`}
                  </Typography>
                  <Typography className={classes.timerType}>
                    {`${I18n.t('timers.scenes')}:
                   ${timer.timer_type === 'no_timer' ? I18n.t('timers.n_a') : getTimerScenesNames(timer.scenes)}`}
                  </Typography>
                  {((timer.timer_type === 'one_shot' || timer.timer_type === 'repeating') && timer.status === 'complete')
                && (
                  <Wrapper className={classes.activityButtons}>
                    {(timer.state === 'stop' && timer.trigger_status !== 'progress') && (
                      <Typography
                        color="primary"
                        className={classes.button}
                        onClick={(event) => {
                          setTrigger(event, 'start', timer)
                        }}
                      >
                        START
                      </Typography>
                    )}
                    {(timer.state === 'start' && timer.trigger_status !== 'progress') && (
                      <Typography
                        color="primary"
                        className={classes.button}
                        onClick={(event) => {
                          setTrigger(event, 'stop', timer)
                        }}
                      >
                        STOP
                      </Typography>
                    )}
                  </Wrapper>
                )}
                  {timer.trigger_status === 'progress' && (
                    <DotsLoading
                      className={classes.progressDots}
                      props={{
                        width: 48, circleWidth: 15, circleHeight: 15,
                      }}
                    />
                  )}
                </Wrapper>
                {!checkIfGuestOrFamily(role) && <ChevronLeft className={classes.chevronIcon} />}
              </Wrapper>
            ))}
          </Wrapper>
        )
          : (
            <Wrapper className={classNames(classes.settingsGrid, isMobile && classes.settingsGridMobile)}>
              {[...Array(8)].map((timer, index) => (
                <Wrapper
                  className={classNames(classes.setting, isMobile && classes.settingMobile)}
                  key={`timer-${index + 1}`}
                  onClick={() => {
                    dispatch(saveTimerData({
                      number: index + 1,
                      type: 'no_timer',
                      duration: {
                        day: '',
                        hour: '',
                        minute: '',
                        second: '',
                      },
                      scenes: [],
                    }))
                    goToTimerSettingsPage(timer, index + 1, role)
                  }}
                >
                  <Wrapper className={classes.cardLeftSide}>
                    <Typography className={classes.timerTitle}>{`${I18n.t(`timers.timer_${index + 1}`)}`}</Typography>
                    <Typography className={classes.timerType}>{`${I18n.t('timers.type')}: ${I18n.t('timers.n_a')}`}</Typography>
                    <Typography className={classes.timerDuration}>{`${I18n.t('timers.duration')}: ${I18n.t('timers.n_a')}`}</Typography>
                  </Wrapper>
                  {!checkIfGuestOrFamily(role) && <ChevronLeft className={classes.chevronIcon} />}
                </Wrapper>
              ))}
            </Wrapper>
          )}
      </Paper>
      <WarningComponent
        open={timerStopPopup}
        warningText="timers.edit_stop_timer"
        onClose={() => {
          setTimerStopPopup(!timerStopPopup)
        }}
        onClick={() => {
          dispatch(setCurrentTrigger('stop'))
          dispatch(timerTrigger())
          setTimerStopPopup(!timerStopPopup)
          setTimerStopProgress(true)
        }}
        rightButton="cancel"
        leftButton="okay"
      />
      {timerStopProgress && (<ProgressPopup text={I18n.t('timers.stop_timer')} />)}
    </>
  )
}

export default Timers
