import React from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SELECT_ACTION_GROUP_STATE_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './Group.style'

class Group extends React.Component {
  onGroupClick = (group) => {
    const {
      navigate,
      saveActionDescription,
      saveActionGroupNumber,
    } = this.props
    saveActionDescription(group.name)
    saveActionGroupNumber(group.number)
    navigate(SELECT_ACTION_GROUP_STATE_TYPE)
  }

  render() {
    const {
      classes,
      group,
    } = this.props
    return (
      <Wrapper
        className={classes.group}
        onClick={() => {
          this.onGroupClick(group)
        }}
      >
        <Typography className={classes.title} variant="h6" component="h2">
          {group.name}
        </Typography>
      </Wrapper>
    )
  }
}

Group.propTypes = {
  classes: PropTypes.object.isRequired,
  saveActionDescription: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  saveActionGroupNumber: PropTypes.func.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

Group.defaultProps = {

}

export default withStyles(styles)(Group)
