import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.8752 8.00478C55.2912 8.05958 53.7636 8.60631 52.5046 9.56904C51.2456 10.5318 50.3176 11.8624 49.8496 13.3767C48.4573 13.5831 47.1019 13.9885 45.825 14.5808C39.8041 17.3709 34.464 21.4405 30.1766 26.5055C25.8891 31.5705 22.7576 37.5093 21 43.9082L29.7274 47.5697C30.2214 50.0788 31.3253 52.4276 32.9418 54.4093C34.5583 56.3909 36.6376 57.9441 38.9963 58.9321C41.3551 59.9202 43.9207 60.3126 46.4669 60.0747C49.0132 59.8368 51.4617 58.9762 53.5966 57.5683L62.324 61.2291C65.6534 55.4887 67.6912 49.0914 68.2954 42.4829C68.8996 35.8743 68.0556 29.2134 65.8224 22.9643C65.351 21.6373 64.6912 20.385 63.8632 19.2459C64.4998 18.0634 64.8129 16.7342 64.771 15.392C64.7292 14.0497 64.3338 12.7424 63.6248 11.6018C62.9158 10.4613 61.9184 9.5277 60.7333 8.89614C59.5481 8.26458 58.2173 7.95729 56.8752 8.00478V8.00478ZM57.0465 12.8895C57.7684 12.8641 58.4711 13.1247 59.0011 13.6155C59.5312 14.1062 59.8457 14.7866 59.8759 15.5083C58.472 14.6169 56.927 13.9707 55.3066 13.5968C55.7848 13.1627 56.4011 12.9117 57.0465 12.8884L57.0465 12.8895ZM52.2668 18.118C54.2385 18.1725 56.1477 18.8215 57.7443 19.9798C59.3408 21.138 60.5502 22.7514 61.214 24.6087C64.7499 34.5018 64.3287 45.3781 60.0384 54.9681L27.0693 41.1461C30.895 31.3636 38.35 23.4357 47.8791 19.0167C49.2526 18.382 50.7544 18.074 52.2668 18.1174L52.2668 18.118ZM35.902 50.1602L47.4205 54.9777C45.2379 55.4831 42.9516 55.2901 40.8847 54.4256C38.8178 53.5611 37.0748 52.0691 35.902 50.1602V50.1602Z" fill="currentColor"/>
      <path d="M95.6558 56.2157C101.011 63.0919 103.919 71.5589 103.919 80.2746V121.378C98.8774 121.93 94.1706 124.172 90.5646 127.739C86.9587 131.306 84.6657 135.989 84.0587 141.024H88.9982C89.64 136.879 91.7436 133.099 94.929 130.369C98.1143 127.639 102.171 126.138 106.366 126.138C110.562 126.138 114.618 127.639 117.804 130.369C120.989 133.099 123.093 136.879 123.734 141.024H128.674C128.067 135.989 125.774 131.306 122.168 127.739C118.562 124.172 113.856 121.93 108.814 121.378V80.2746C108.814 70.4694 105.542 60.9443 99.5172 53.2085L73.9346 20.3613C73.5358 19.8493 72.95 19.5167 72.3059 19.4367C71.6618 19.3566 71.0124 19.5358 70.5003 19.9346C69.9883 20.3334 69.6557 20.9191 69.5756 21.5632C69.4955 22.2072 69.6746 22.8564 70.0734 23.3685L95.6558 56.2157Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ReadingLampScheme')

export default icon
