const styles = (theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 0,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 'auto',
    paddingLeft: 36,
    paddingRight: 36,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  avatarButtonWrapper: {
    borderRadius: '50%',
  },
  fieldsWrapper: {
    width: '100%',
    paddingLeft: 36,
    paddingRight: 36,
  },
  button: {
    minWidth: '30%',
    marginTop: 50,
    marginBottom: 35,
  },
  roleButton: {
    color: theme.typography.shadowColor,
    paddingBottom: 10,
  },
  item: {
    width: '75%',
  },
  changeButtonsWrapper: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  buttonWrapper: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  userCodeButton: {
    marginTop: 30,
    textTransform: 'none',
    minWidth: '25%',
    marginRight: 4,
    fontSize: '0.775rem',
    padding: 5,
  },
  webView: {
    background: theme.webView.background,
  },
  changeInfoPopup: {
    maxWidth: 530,
  },
  roleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
})

export default styles
