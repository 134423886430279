const styles = (theme) => ({
  root: {
    padding: 0,
  },
  list: {
    padding: 0,
  },
  wrapper: {
    padding: '0px 65px 20px 65px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  title: {
    marginTop: 20,
    marginLeft: '20%',
    fontSize: '1rem',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'center',
  },
  btn: {
    minWidth: 116,
    marginTop: 20,
    marginRight: 10,
  },
  container: {
    justifyContent: 'center',
    marginBottom: 30,
  },
  toFilterItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  webView: {
    background: theme.webView.background,

    '&:selected': {
      background: theme.webView.background,
    },
  },

  filterWebView: {
    background: '#272827',
  },
})

export default styles
