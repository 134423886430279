import * as type from '@app/constants/scenes/zWaveDevices/securityOptions'

type State = {
  conditions: Array<{ notification_type: number, notification_name: string, event_type: Array<{ type: number, name: string }> }>,
}
const initialState = {
  conditions: [],
}

export default function securityOptions(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.UPDATE_SECURITY_OPTIONS_CONDITIONS: {
    return {
      ...state,
      conditions: action.securityOptionsConditions,
    }
  }

  default:
    return state
  }
}
