import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveConditionDescription,
  saveConditionStateDescription,
  saveConditionStateTimeData,
  saveConditionTimeOperand,
  saveConditionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectTimeConditionStateTime
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateTime/SelectTimeConditionStateTime'
import SelectTimeConditionStateTimeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateTime/SelectTimeConditionStateTime'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTimeConditionStateTimeIOS) : withStyles(SelectTimeConditionStateTime)

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const { stateTimeData, type, timeOperand } = state.scenes.currentSceneCreating.currentCondition
  return {
    notifications: syncNotifications.sync,
    isMobile,
    isTablet,
    path,
    isEditConditionMode,
    isChangeConditionStateMode,
    stateTimeData,
    type,
    timeOperand,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveConditionType,
  saveConditionTimeOperand,
  saveConditionDescription,
  saveConditionStateDescription,
  saveConditionStateTimeData,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTimeConditionStateTimeEnhanced = enhance

export default withRouter(SelectTimeConditionStateTimeEnhanced)
