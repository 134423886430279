import React, {
  useEffect, useState, Dispatch, SetStateAction,
} from 'react'
import {
  Typography, Paper, LinearProgress, Button, CircularProgress,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IConfigParameter, IZwaveDetails, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import {
  fetchZwaveDeviceDetails,
  resetConfigurationPopupStatus,
  resetZwaveDeviceDetailsError,
} from '@app/actions/myDevices/zwaveDevices'
import { Optional } from '@app/@types/common'
import { useDispatch } from '@app/store'
import { ChevronLeft } from 'icons'
import ConfigurationPopup
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/ConfigurationPopup/ConfigurationPopup'
import EditParam
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditParams'
import useStyles from './ZwaveParams.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceDetails: IZwaveDetails,
  error: string,
  configurationPopup: {
    number: number,
    value: number,
    size: number,
    status: string,
  },
  isMobile: boolean,
  setIsConfigurationPopupOpen: Dispatch<SetStateAction<boolean>>,
  isConfigurationPopupOpen: boolean,
}

const ZwaveParams: React.FC<Props> = (props) => {
  const {
    currentDevice,
    currentDeviceDetails,
    error,
    configurationPopup,
    isMobile,
    setIsConfigurationPopupOpen,
    isConfigurationPopupOpen,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isInfoFetching, setIsInfoFetching] = useState<boolean>(true)
  const [isEditingParameter, setIsEditingParameter] = useState<boolean>(false)
  const [currentParameter, setCurrentParameter] = useState<Optional<IConfigParameter>>(null)
  const [isFailed, setIsFailed] = useState<string>('')

  useEffect(() => {
    if (currentDeviceDetails) {
      setIsInfoFetching(false)
    }
  }, [currentDeviceDetails])

  useEffect(() => {
    setIsFailed(error)
  }, [error])

  const retry = () => {
    setIsInfoFetching(true)
    dispatch(resetZwaveDeviceDetailsError())
    dispatch(fetchZwaveDeviceDetails(currentDevice.id))
  }

  const configurationPopupClose = () => {
    setIsConfigurationPopupOpen(!isConfigurationPopupOpen)
    dispatch(resetConfigurationPopupStatus())
  }

  const editParameter = (param: IConfigParameter) => {
    setCurrentParameter(param)
    setIsEditingParameter(true)
  }

  const renderParamValue = (parameter: IConfigParameter) => {
    if (!parameter.isFetched) {
      return (<CircularProgress size={15} />)
    }
    if (parameter.set_to !== null) {
      return (<Typography className={classes.paramValue}>{parameter.set_to}</Typography>)
    } return (<Typography className={classes.not_received}>not received</Typography>)
  }

  return (
    <>
      {!isFailed ? (
        <Paper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          {isInfoFetching ? (
            <Wrapper className={classes.preloader}>
              <Typography className={classes.preloaderTitle}>Getting device parameters</Typography>
              <LinearProgress className={classes.progress} />
            </Wrapper>
          ) : (
            <>
              {currentDeviceDetails.config_params.length ? (
                <Wrapper className={classNames(classes.paramsList, isEditingParameter && classes.hide)}>
                  {currentDeviceDetails.config_params.map((param, index) => (
                    <Wrapper className={classes.paramCard} key={`param-${index + 1}`}>
                      <Typography className={classes.paramTitle}>{`Parameter: #${param.number}`}</Typography>
                      <Typography className={classes.paramDescription}>{param.description}</Typography>
                      <Wrapper className={classes.valueWrapper}>
                        <Typography className={classes.paramValue}>Set to:</Typography>
                        {renderParamValue(param)}
                      </Wrapper>
                      <ChevronLeft
                        className={classes.chevronIcon}
                        onClick={() => {
                          editParameter(param)
                        }}
                      />
                    </Wrapper>
                  ))}
                </Wrapper>
              ) : (
                <Wrapper className={classes.emptyList}>
                  <Typography>NO PARAMETERS AVAILABLE</Typography>
                </Wrapper>
              )}
            </>
          )}
          {isEditingParameter && (
            <EditParam
              currentDeviceDetails={currentDeviceDetails}
              currentDevice={currentDevice}
              currentParameter={currentParameter}
              setCurrentParameter={setCurrentParameter}
              setIsEditingParameter={setIsEditingParameter}
              isMobile={isMobile}
            />
          )}
        </Paper>
      ) : (
        <Wrapper className={classes.error}>
          <Typography className={classes.errorText}>{error}</Typography>
          <Button
            className={classes.retryButton}
            variant="contained"
            onClick={retry}
          >
            {I18n.t('buttons.try_again')}
          </Button>
        </Wrapper>
      )}
      {isConfigurationPopupOpen && (
        <ConfigurationPopup
          currentDevice={currentDevice}
          currentDeviceDetails={currentDeviceDetails}
          configurationPopup={configurationPopup}
          onClose={configurationPopupClose}
          isMobile={isMobile}
        />
      )}
    </>
  )
}

export default ZwaveParams
