import * as type from '@app/constants/scenes/roomsScenes/roomsScenes'

export const syncRoomsScenes = () => ({
  type: type.SYNC_ROOMS_SCENES,
})

export const updateRoomsScenes = (roomsScenes) => ({
  type: type.UPDATE_ROOMS_SCENES,
  roomsScenes,
})
