import type { Node } from 'react'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import moment from 'moment-timezone'
import * as route from '@app/lib/Statistic/statsRoutes'

import addDataPlaceholder from '@app/helpers/stats/addDeviceChartDataPlaceholder'
import handleErrors from '@app/helpers/handleErrors'
import { TIMEZONE_NUMBER_DEFINITION } from '@app/constants/stats'
import { lastReportRequest, statusLineRequest } from '@app/logics/stats/stats'
import DeviceChart from '../Charts/DeviceChart'
import TimePicker from '../TimePicker/TimePicker'

import useStyles from './DeviceInfo.style'

type Props = {
  setError: () => void,
}

const DeviceInfo = (props: Props): Node => {
  const classes = useStyles()
  const { deviceId } = useParams()
  const navigate = useNavigate()
  const { setError } = props
  const [statusLine, setStatusLine] = useState([
    [
      { type: 'string', id: 'Connection Type' },
      { type: 'string', id: 'Empty label' },
      { type: 'string', id: 'style', role: 'style' },
      {
        type: 'string', id: 'tooltip', role: 'tooltip', p: { html: true },
      },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' },
    ],
  ])
  const [currentTimezone, setCurrentTimezone] = useState(moment.tz.guess())
  const [lastReport, setLastReport] = useState({
    protocol_carrier_key: '',
    last_report_time: '',
  })
  const [showReport, setShowReport] = useState(false)

  const getStatusLine = async (startDate: { [key: any]: any }, endDate: { [key: any]: any }, timezone: string) => {
    try {
      const response = await statusLineRequest(
        startDate.tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION), true).format(),
        endDate.tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION), true).format(),
        deviceId,
      )
      setCurrentTimezone(timezone.slice(TIMEZONE_NUMBER_DEFINITION))
      setStatusLine((prevState) => [
        prevState[0],
        ...(response.data.status_lines.length ? addDataPlaceholder(response.data.status_lines[0].start_at, timezone) : []),
        ...response.data.status_lines.map((line) => [
          I18n.t(`stats.${line.protocol_carrier_key}`),
          '',
          null,
          null,
          moment(moment(line.start_at).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).format('YYYY-MM-DDTHH:mm:ss')).toDate(),
          moment(moment(line.finish_at).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).format('YYYY-MM-DDTHH:mm:ss')).toDate(),
        ]),
      ])
    } catch (err) {
      setError(handleErrors(err))
    }
  }

  const getLastReport = async () => {
    try {
      const response = await lastReportRequest(
        deviceId,
      )
      if (response.data.last_report) {
        setLastReport({
          protocol_carrier_key: response.data.last_report.protocol_carrier_key,
          time: response.data.last_report.time,
        })
      }
      setShowReport(true)
    } catch (err) {
      setError(handleErrors(err))
    }
  }

  const backToMainPage = () => {
    navigate(route.STATS_MAIN_PAGE_REDIRECT)
  }

  return (
    <>
      <Typography variant="h3" className={classes.deviceTitle}>
        {deviceId}
        {I18n.t('stats.device_cropped_title')}
      </Typography>
      <TimePicker
        onSubmitHandler={getStatusLine}
      />
      { statusLine.length > 1 ? <DeviceChart statusLine={statusLine} /> : (
        <div className={classes.chartPlaceholder}>
          <Typography>{I18n.t('stats.choose_different_time')}</Typography>
        </div>
      )}
      <div className={classes.reportContainer}>
        <Typography variant="h6">{I18n.t('stats.last_report')}</Typography>
        <hr />
        <div className={classes.reportControls}>
          <Typography className={classes.report}>
            {showReport && (lastReport.protocol_carrier_key
              ? `${
                I18n.t(`stats.${lastReport.protocol_carrier_key}`)
              } at ${moment(lastReport.time).tz(currentTimezone).format('Do MMM YY, h:mm:ss a')}`
              : I18n.t('stats.never_online'))}
            {showReport ? '' : I18n.t('stats.press_show')}
          </Typography>
          <Button size="large" color="primary" variant="contained" onClick={getLastReport}>{I18n.t('buttons.show')}</Button>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button size="large" color="primary" variant="contained" onClick={backToMainPage}>{I18n.t('stats.back_to_main')}</Button>
      </div>
    </>
  )
}

export default DeviceInfo
