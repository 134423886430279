export const SYNC_DEVICES_CHANNEL = '@@myDevices/devicesChannelSync'
export const SYNC_DEVICES_CHANNEL_SENT = '@@myDevices/devicesChannelSyncSent'
export const OPEN_DEVICES_CHANNEL = '@@myDevices/devicesChannelOpen'
export const CLOSE_DEVICES_CHANNEL = '@@myDevices/devicesChannelClose'
export const UPDATE_DEVICES_CHANNEL = '@@myDevices/devicesChannelUpdate'

export const SYNC_DEVICES_STATUS_CHANNEL = '@@myDevices/statusChannelSync'
export const SYNC_DEVICES_STATUS_CHANNEL_SENT = '@@myDevices/statusChannelSyncSent'
export const OPEN_DEVICES_STATUS_CHANNEL = '@@myDevices/statusChannelOpen'
export const CLOSE_DEVICES_STATUS_CHANNEL = '@@myDevices/statusChannelClose'
export const UPDATE_DEVICES_STATUS = '@@myDevices/statusChannelUpdate'

export const SYNC_DEVICE_INFO_CHANNEL = '@@myDevices/deviceInfoChannelSync'
export const SYNC_DEVICE_INFO_CHANNEL_SENT = '@@myDevices/deviceInfoChannelSyncSent'
export const OPEN_DEVICE_INFO_CHANNEL = '@@myDevices/deviceInfoChannelOpen'
export const CLOSE_DEVICE_INFO_CHANNEL = '@@myDevices/deviceInfoChannelClose'
export const UPDATE_DEVICE_INFO_CHANNEL = '@@myDevices/deviceInfoChannelUpdate'
export const UPDATE_DEVICE_INFO = '@@myDevices/updateDeviceInfo'

export const SET_DEVICE_INFO_ERROR = '@@myDevices/setDeviceInfoError'
export const CLEAR_DEVICE_INFO_ERROR = '@@myDevices/clearDeviceInfoError'

export const SYNC_DELETE_CHANNEL = '@@myDevices/deleteChannelSync'
export const SYNC_DELETE_CHANNEL_SENT = '@@myDevices/deleteChannelSyncSent'
export const OPEN_DELETE_CHANNEL = '@@myDevices/deleteChannelOpen'
export const CLOSE_DELETE_CHANNEL = '@@myDevices/deleteChannelClose'
export const SET_RESPONSE_FROM_DELETE_CHANNEL = '@@myDevices/setResponseFromDeleteChannel'

export const SYNC_MEZZO_LIGHTS_CHANNEL = '@@myDevices/mezzoLightsChannelSync'
export const SYNC_MEZZO_LIGHTS_CHANNEL_SENT = '@@myDevices/mezzoLightsChannelSyncSent'
export const OPEN_MEZZO_LIGHTS_CHANNEL = '@@myDevices/mezzoLightsChannelOpen'
export const CLOSE_MEZZO_LIGHTS_CHANNEL = '@@myDevices/mezzoLightsChannelClose'
