import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { UPDATE_GUARD_PANEL_STATUS } from '@app/constants/guardPanel'
import Api from '@app/api/guardPanel'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { activateInProgressAlarm, deactivateInProgressAlarm } from '@app/actions/healthAlert'

const patchGuardPanelStatus = createLogic({
  type: UPDATE_GUARD_PANEL_STATUS,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { users: { currentUser: { user: { ids: { sphere: sphereId } } } } } = getState()
    const { healthAlert: { address } } = getState()
    next({
      ...action,
      accessToken,
      sphereId,
      address,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(UPDATE_GUARD_PANEL_STATUS))
    dispatch(activateInProgressAlarm())
    Api.patchGuardPanelStatus(action.accessToken, action.sphereId, { arm_status: action.status, address: action.address })
      .then(() => {
        dispatch(apiRequestDone(UPDATE_GUARD_PANEL_STATUS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(deactivateInProgressAlarm())
        } else {
          throw new Error('patchGuardPanelStatus failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchGuardPanelStatus
