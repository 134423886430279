import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { closeFailedNodeChannel } from '@app/actions/myDevices/channels/deviceSettings/failedNodeChannel'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import TwoButtonsPopup from '@app/components/Common/TwoButtonsPopup/TwoButtonsPopup'
import { MY_DEVICES } from '@app/constants/routes'
import {
  getDeviceHealth, deleteUnhealthyDevice, resetFailedNodeData, resetFailedNodeStatus, replaceUnhealthyDevice,
} from '@app/actions/myDevices/deviceSettings/failedNode'
import { ChevronLeft } from 'icons'
import ProgressPopup from '@app/components/Common/ProgressPopup/ProgressPopup'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './FailedNode.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  deviceHealth: string,
  status: string,
  isMobile: boolean,
}

const MyDevicesSettings: React.FC<Props> = (props) => {
  const {
    currentDevice, deviceHealth, status, isMobile,
  } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [deviceHealthState, setDeviceHealthState] = useState<string>('')
  const [progressState, setProgressState] = useState<string>('')

  useEffect(() => () => {
    dispatch(closeFailedNodeChannel())
    clearFailedNodeData()
  }, [])

  useEffect(() => {
    if (deviceHealth !== '') {
      setDeviceHealthState(deviceHealth)
    }
  }, [deviceHealth])

  useEffect(() => {
    if (status !== '') {
      setDeviceHealthState('')
    }
    setProgressState(status)
    if (deviceHealthState === 'removing' && status === 'success') {
      dispatch(resetFailedNodeData())
      navigate(MY_DEVICES)
    }
  }, [status])

  const renderProgressPopupText = (progress: string): string => {
    switch (progress) {
    case 'checking':
      return `${I18n.t('failedNode.сhecking_device_state')}`
    case 'removing':
      return `${I18n.t('failedNode.removing_device')}`
    default:
      return ''
    }
  }

  const checkDeviceHealth = () => {
    dispatch(resetFailedNodeStatus())
    setDeviceHealthState('checking')
    dispatch(getDeviceHealth(currentDevice.id))
  }

  const removeDevice = () => {
    dispatch(resetFailedNodeStatus())
    setDeviceHealthState('removing')
    dispatch(deleteUnhealthyDevice(currentDevice.id))
  }

  const clearFailedNodeData = () => {
    dispatch(resetFailedNodeData())
    setDeviceHealthState('')
  }

  const tryAgainRemoveDevice = () => {
    dispatch(resetFailedNodeStatus())
    setDeviceHealthState('removing')
    dispatch(deleteUnhealthyDevice(currentDevice.id))
  }

  const replaceDevice = () => {
    dispatch(replaceUnhealthyDevice(currentDevice.id))
    navigate(`/web/my_devices/info/zwave_device/${currentDevice.id}/settings/replace_device`)
  }

  return (
    <>
      <Wrapper
        className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
        onClick={() => {
          checkDeviceHealth()
        }}
      >
        <Typography className={classNames(classes.mainTitle)}>{`${I18n.t('failedNode.bad_device')}`}</Typography>
        <Typography className={classNames(classes.cardTitle)}>{`${I18n.t('failedNode.check_device')}`}</Typography>
        <ChevronLeft className={classes.arrowRightIcon} />
      </Wrapper>
      {((deviceHealthState === 'checking' || deviceHealthState === 'removing') && progressState === '')
          && <ProgressPopup text={renderProgressPopupText(deviceHealthState)} />}
      <SmallErrorPopup
        open={deviceHealthState === 'healthy'}
        warningText="failedNode.device_healthy"
        buttonName="buttons.cancel"
        title="titles.warning"
        onClick={() => {
          clearFailedNodeData()
        }}
      />
      <TwoButtonsPopup
        open={deviceHealthState === 'unhealthy'}
        closeIconOnClick={() => {
          clearFailedNodeData()
        }}
        title="titles.warning"
        text="failedNode.device_not_healthy"
        firstButtonName="failedNode.button_remove"
        secondButtonName="failedNode.button_replace"
        firstButtonOnClick={() => {
          removeDevice()
        }}
        secondButtonOnClick={() => {
          replaceDevice()
        }}
      />
      <TwoButtonsPopup
        open={progressState === 'in_sleeping'}
        closeIconOnClick={() => {
          clearFailedNodeData()
        }}
        title="titles.warning"
        text="failedNode.device_sleeping"
        firstButtonName="failedNode.button_remove"
        secondButtonName="failedNode.button_replace"
        firstButtonOnClick={() => {
          removeDevice()
        }}
        secondButtonOnClick={() => {
          replaceDevice()
        }}
      />
      <TwoButtonsPopup
        open={progressState === 'timeout'}
        withCloseIcon={false}
        title="titles.title_error"
        text="failedNode.timeout_ended"
        firstButtonName="buttons.cancel"
        secondButtonName="buttons.try_again"
        firstButtonOnClick={() => {
          clearFailedNodeData()
        }}
        secondButtonOnClick={() => {
          tryAgainRemoveDevice()
        }}
      />
      <TwoButtonsPopup
        open={progressState === 'failed'}
        withCloseIcon={false}
        title="titles.title_error"
        text="failedNode.failed_device"
        firstButtonName="buttons.cancel"
        secondButtonName="buttons.try_again"
        firstButtonOnClick={() => {
          clearFailedNodeData()
        }}
        secondButtonOnClick={() => {
          checkDeviceHealth()
        }}
      />
    </>
  )
}

export default MyDevicesSettings
