import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M64.0715 40.5273V101.684H88.2818V40.5273H64.0715ZM67.0715 43.5273V98.6836H85.2818V43.5273H67.0715Z" fill="currentColor"/>
            <path d="M68.0715 70.1451C67.8303 67.0093 67.4141 63.9103 67.0006 60.8314C66.5137 57.2054 66.0304 53.6071 65.8411 50.0087V49.4834C65.9286 48.8706 66.0599 48.214 66.1913 47.5574C66.3226 46.9007 66.4539 46.2441 66.5415 45.6313C66.7166 44.5807 67.2419 44.2305 68.2925 44.2305H83.8763C84.9269 44.2305 85.4522 44.5807 85.6273 45.6313C85.7148 46.2441 85.8461 46.9007 85.9775 47.5574C86.1088 48.214 86.2401 48.8706 86.3277 49.4834V50.0087C86.0549 52.6001 85.7321 55.218 85.4085 57.8418C84.9013 61.9551 84.3923 66.0827 84.0715 70.1451V78.2144C84.0715 79.4424 83.5817 79.9688 82.4389 79.9688H69.7042C68.5613 79.9688 68.0715 79.4424 68.0715 78.2144V70.1451Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M31 9C31 8.44771 31.4477 8 32 8H117.742C118.294 8 118.742 8.44772 118.742 9V140C118.742 140.552 118.294 141 117.742 141H32C31.4477 141 31 140.552 31 140V9ZM36 136V13H113.742V136H36Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwLightSwitchIcon')

export default icon
