export const emailField = {
  title: 'email', placeholder: 'Email*',
}

export const codeField = {
  title: 'code', placeholder: 'Code*',
}

export const passwordFields = [
  {
    title: 'new_password', value: '', hint: 'new_code_first_hint',
  },
  {
    title: 'password_confirmation', value: '', hint: 'new_code_second',
  },
]
