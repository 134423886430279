import * as type from '@app/constants/appCofig/appConfig'

export const getAppConfig = () => ({
  type: type.GET_APP_CONFIG,
} as const)

export const saveRawConfig = (config: any) => ({
  type: type.SAVE_RAW_CONFIG,
  config,
} as const)

export const saveAppConfig = (config: any) => ({
  type: type.SAVE_CONFIG_INFO,
  config,
} as const)

export const resetAppConfig = () => ({
  type: type.RESET_APP_CONFIG,
} as const)
