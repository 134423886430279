import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g fill="currentColor" clipPath="url(#clip0)">
        <path d="M1.333 7.296h13.434V4.63H1.333a1.333 1.333 0 000 2.666zM1.333 12.646h13.434V9.98H1.333a1.333 1.333 0 000 2.666zM1.333 15.33a1.333 1.333 0 100 2.666h9.333a3.294 3.294 0 01.281-2.666H1.333z"/>
        <path d="M23.506 15.785a1.686 1.686 0 00-2.384 0l-1.384 1.384V2.761a1.686 1.686 0 00-3.372 0V17.17l-1.384-1.385a1.686 1.686 0 00-2.384 2.384l4.262 4.262c.657.657 1.726.66 2.384 0l4.262-4.262a1.686 1.686 0 000-2.384z"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="currentColor" d="M0 0h24v24H0z"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'handSort', '0 0 16 16', { fontSize: 18 })

export default icon
