import * as type from '@app/constants/scenes/zWaveDevices/zWaveDevicesByRooms'
import { IRoomZwaveBackend } from '@app/@types/scenes/scenesDevices'

export const syncZWaveDevicesByRooms = () => ({
  type: type.SYNC_ZWAVE_DEVICES_BY_ROOMS,
} as const)

export const updateZWaveDevicesByRooms = (rooms: Array<IRoomZwaveBackend>) => ({
  type: type.UPDATE_ZWAVE_DEVICES_BY_ROOMS,
  rooms,
} as const)

export const resetZWaveDevicesByRooms = () => ({
  type: type.RESET_ZWAVE_DEVICES_BY_ROOMS,
} as const)
