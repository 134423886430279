import React, { useEffect, useState } from 'react'
import { Typography, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { Calendar } from 'icons'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterToDate } from '@app/actions/events'
import { dateErrors } from '@app/lib/Events/filters'
import { format } from 'date-fns'
import useStyles from './FilterToDate.style'

const FilterToDate = (): Node => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { filterToDate } = useSelector((state) => state.events)
  const [date, setDate] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!filterToDate) {
      setDate(null)
    }
  }, [filterToDate])

  useEffect(() => {
    if (date) {
      if (date.toString() !== 'Invalid Date' && !dateErrors.includes(error)) {
        dispatch(setFilterToDate(date))
      }
    }
  }, [error])

  const handleValidationError = (err, selectedDate) => {
    setError(err)
    setDate(selectedDate)
  }

  const handleDateChange = (selectedDate) => {
    const formattedDate = format(selectedDate, 'yyyy-MM-dd')
    setDate(formattedDate)
    dispatch(setFilterToDate(formattedDate))
  }

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography className={classes.label}>
          {I18n.t('my_events.to')}
        </Typography>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={date ? new Date(date) : null}
          onChange={handleDateChange}
          FormHelperTextProps={{ classes: { error: classes.error } }}
          KeyboardButtonProps={{ classes: { root: classes.iconButton } }}
          keyboardIcon={<Calendar />}
          onError={(err, val) => handleValidationError(err, val)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              className={classes.datePicker}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default FilterToDate
