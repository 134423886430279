import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  addButton: {
    position: 'fixed',
    bottom: 30,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    zIndex: 101,
  },
  initialAddButton: {
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.primary.main,
    color: theme.backgrounds.cardBackground,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  icon: {
    display: 'none',
  },
  activeIcon: {
    display: 'block',
  },
  caption: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 101,
  },
  textHint: {
    position: 'fixed',
    right: 'calc(50% - 180px)',
    bottom: 100,
    transition: 'transform 0.2s, opacity 0.05s ease-out',
    opacity: 0,
    borderRadius: 3,
    width: 160,
    padding: 5,
    textAlign: 'center',
    fontSize: 14,
  },
  twrHint: {
    backgroundColor: theme.backgrounds.componentBackground,
    transform: 'translateY(-100vh)',
  },
  twrHintMobile: {
    right: 'calc(50% - 130px)',
    width: 60,
  },
  zWaveHint: {
    backgroundColor: theme.backgrounds.componentBackground,
    right: 'calc(50% + 25px)',
    transform: 'translateY(-100vh)',
  },
  zWaveHintMobile: {
    width: 60,
    left: 'calc(50% - 130px)',
  },
  addHint: {
    transform: 'translateX(0)',
    opacity: 1,
  },
  addTwrButton: {
    right: 'calc(50% - 128px)',
    backgroundColor: theme.backgrounds.componentBackground,
    '&:hover': {
      backgroundColor: theme.backgrounds.componentBackground,
      opacity: 0.8,
    },
  },
  addZWaveButton: {
    right: 'calc(50% + 72px)',
    backgroundColor: theme.backgrounds.componentBackground,
    '&:hover': {
      backgroundColor: theme.backgrounds.componentBackground,
      opacity: 0.8,
    },
  },
}))

export default useStyles
