import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveActionDurationValueAfter, saveActionStateDescription } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectBinarySwitchValueAfterDuration
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/SelectBinarySwitchValueAfterDuration/SelectBinarySwitchValueAfterDuration'
import SelectBinarySwitchValueAfterDurationIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/SelectBinarySwitchValueAfterDuration/SelectBinarySwitchValueAfterDuration'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectBinarySwitchValueAfterDurationIOS) : withStyles(SelectBinarySwitchValueAfterDuration)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  return {
    isMobile,
    path,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveActionStateDescription,
  saveActionDurationValueAfter,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectBinarySwitchValueAfterDurationEnhanced = enhance

export default withRouter(SelectBinarySwitchValueAfterDurationEnhanced)
