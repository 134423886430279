import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.0475 19C44.143 19 19 44.143 19 75.049C19 105.954 44.1445 131.097 75.0475 131.097C105.954 131.097 131.097 105.954 131.097 75.049C131.097 44.143 105.952 19 75.0475 19ZM75.0475 124.091C48.0055 124.091 26.0065 102.091 26.0065 75.049C26.0065 48.007 48.0055 26.0065 75.0475 26.0065C102.09 26.0065 124.09 48.007 124.09 75.049C124.09 102.091 102.09 124.091 75.0475 124.091Z" fill="currentColor"/>
      <path d="M75.0475 46.2345C59.7205 46.2345 47.2495 58.704 47.2495 74.0325C47.2495 89.361 59.7205 101.831 75.0475 101.831C90.376 101.831 102.845 89.361 102.845 74.0325C102.845 58.704 90.376 46.2345 75.0475 46.2345ZM75.0475 94.8255C63.5815 94.8255 54.256 85.4985 54.256 74.034C54.256 62.5695 63.583 53.241 75.0475 53.241C86.512 53.241 95.8405 62.568 95.8405 74.034C95.8405 85.5 86.512 94.8255 75.0475 94.8255Z" fill="currentColor"/>
      <path d="M81.556 72.909C83.11 72.021 84.121 70.974 84.586 69.768C85.054 68.562 85.2865 67.434 85.2865 66.3825C85.2865 64.044 84.403 62.049 82.6375 60.399C80.872 58.749 78.3805 57.924 75.1645 57.924C71.9455 57.924 69.4525 58.749 67.6885 60.399C65.923 62.0505 65.0395 64.044 65.0395 66.3825C65.0395 67.434 65.2735 68.562 65.7415 69.768C66.2095 70.9755 67.2235 72.096 68.7835 73.1325C67.2085 73.872 66.0145 74.9745 65.1985 76.4415C64.381 77.907 63.9745 79.5405 63.9745 81.3465C63.9745 84.057 64.8985 86.3625 66.7525 88.263C68.6065 90.1635 71.3305 91.1145 74.9305 91.1145C78.5305 91.1145 81.334 90.1635 83.341 88.263C85.348 86.361 86.3515 84.0555 86.3515 81.3465C86.3515 79.542 85.9495 77.907 85.147 76.4415C84.3445 74.973 83.146 73.797 81.556 72.909ZM72.178 64.1055C72.898 63.3885 73.897 63.0285 75.1735 63.0285C76.4635 63.0285 77.461 63.3885 78.166 64.1055C78.8725 64.824 79.225 65.82 79.225 67.092C79.225 68.2605 78.8725 69.216 78.166 69.9555C77.461 70.6965 76.4635 71.0655 75.1735 71.0655C73.8955 71.0655 72.898 70.695 72.178 69.9555C71.4595 69.216 71.098 68.262 71.098 67.092C71.098 65.82 71.4595 64.824 72.178 64.1055ZM78.592 84.6075C77.77 85.482 76.633 85.9185 75.1855 85.9185C73.732 85.9185 72.5965 85.482 71.7775 84.6075C70.9555 83.736 70.546 82.5075 70.546 80.922C70.546 79.2795 70.963 78.033 71.8 77.1825C72.637 76.332 73.765 75.906 75.187 75.906C76.606 75.906 77.7355 76.3305 78.5725 77.1825C79.4095 78.0345 79.825 79.2795 79.825 80.922C79.822 82.5075 79.4125 83.736 78.592 84.6075Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EightBall')

export default icon
