import React, { useEffect, useState } from 'react'
import { TextField, Typography } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Calendar } from 'icons'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterFromDate } from '@app/actions/events'
import { dateErrors } from '@app/lib/Events/filters'
import { format } from 'date-fns'
import useStyles from './FilterFromDate.style'

const FilterFromDate = (): Node => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { filterFromDate } = useSelector((state) => state.events)
  const [selectedDate, setSelectedDate] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!filterFromDate) {
      setSelectedDate(null)
    }
  }, [filterFromDate])

  useEffect(() => {
    if (selectedDate) {
      if (selectedDate.toString() !== 'Invalid Date' && !dateErrors.includes(error)) {
        dispatch(setFilterFromDate(selectedDate))
      }
    }
  }, [error])

  const handleValidationError = (err, value) => {
    setError(err)
    setDate(value)
  }

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd')
    setSelectedDate(formattedDate)
    dispatch(setFilterFromDate(formattedDate))
  }

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography className={classes.title}>
          {I18n.t('my_events.filter_by_date')}
        </Typography>
        <Typography className={classes.label}>
          {I18n.t('my_events.from')}
        </Typography>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={selectedDate ? new Date(selectedDate) : null}
          onChange={handleDateChange}
          FormHelperTextProps={{ classes: { error: classes.error } }}
          KeyboardButtonProps={{ classes: { root: classes.iconButton } }}
          keyboardIcon={<Calendar />}
          onError={(err, val) => handleValidationError(err, val)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              className={classes.datePicker}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default FilterFromDate
