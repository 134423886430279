import * as type from '@app/constants/users/currentRequest'

const initialState = {
  request: null,
  isInfoPopupOpen: false,
}
export default function usersRequests(state = initialState, action) {
  switch (action.type) {
  case type.SET_CURRENT_REQUEST: {
    return {
      ...state,
      request: action.request,
    }
  }

  case type.RESET_CURRENT_REQUEST:
    return initialState

  case type.OPEN_REQUEST_INFO_POPUP:
    return { ...state, isInfoPopupOpen: true }
  case type.CLOSE_REQUEST_INFO_POPUP:
    return { ...state, isInfoPopupOpen: false }

  default:
    return state
  }
}
