import React from 'react'
import {
  Button, Typography,
  DialogContentText,
} from '@mui/material'
// @ts-ignore
import GooglePlayBadge from '@app/images/google-play-badge.png'
// @ts-ignore
import AppStoreBadge from '@app/images/app-store-badge.png'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Info from '@app/components/Common/Info/Info'
import useStyles from './MobileApplicationsUrls.style'

interface Props {
  theme: any,
  isActivationInfoPopupOpened: boolean,
  closeActivationInfoPopup: () => void,
  userEmail: string,
}

const MobileApplicationsUrls: React.FC<Props> = (props) => {
  const {
    theme,
    isActivationInfoPopupOpened,
    closeActivationInfoPopup,
    userEmail,
  } = props
  const classes = useStyles()

  const customTextNode = () => (
    <DialogContentText>
      {`${I18n.t('wizard_web.verify_warning')} `}
      <span className={classes.email}>{userEmail}</span>
      .
      <br />
      {I18n.t('wizard_web.will_blocked_info')}
    </DialogContentText>
  )
  return (
    <Wrapper className={classes.downloadInfo}>
      <Typography
        variant="h6"
        className={classes.register}
      >
        {I18n.t('wizard_web.register_mezzo')}
      </Typography>
      <Typography
        variant="h6"
        color="primary"
        className={classes.download}
      >
        {I18n.t('wizard_web.download_application')}
      </Typography>
      <Wrapper className={classes.buttonsWrapper}>
        <Button
          href={theme.googlePlayUrl}
          target="_blank"
          className={classes.button}
          aria-label="Google Play"
        >
          <img src={GooglePlayBadge} className={classes.badge} alt="Google Play" />
        </Button>
        <Button
          href={theme.appStoreUrl}
          target="_blank"
          className={classes.button}
          aria-label="App Store"
        >
          <img src={AppStoreBadge} className={classes.badge} alt="App Store" />
        </Button>
      </Wrapper>
      <Info
        open={isActivationInfoPopupOpened}
        title="my_users.info"
        buttonName="buttons.okay"
        onClick={closeActivationInfoPopup}
        customTextNode={customTextNode()}
      />
    </Wrapper>
  )
}

export default MobileApplicationsUrls
