import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/billingPeriod'

export const initialState = {
  billing_periods: [],
}

const billingPeriodsStats = (state = initialState, action) => {
  switch (action.type) {
  case type.UPDATE_BILLING_PERIODS_STATS:
    return update(state, {
      billing_periods: { $set: action.stats },
    })
  case type.RESET_ALL_BILLING_PERIODS:
    return { ...state, billing_periods: initialState.billing_periods }
  default:
    return state
  }
}

export default billingPeriodsStats
