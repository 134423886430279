import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@mui/material/SvgIcon'

const createSvgIcon = (path, displayName, viewBox = '0 0 80 80', currentClasses={}) => {
  let Icon = props => (
    <SvgIcon viewBox={viewBox} {...props} style={currentClasses}>
      {path}
    </SvgIcon>
  )

  Icon.displayName = `${displayName}Icon`
  Icon = pure(Icon)
  Icon.muiName = 'SvgIcon'

  return Icon
}

export default createSvgIcon
