import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M93.8183 77.7993L93.8232 77.7924L93.828 77.7854C97.5978 72.2687 99.5827 65.7274 99.5143 59.0461C99.5845 52.3617 97.5996 45.8169 93.828 40.2976L93.8234 40.2909L93.8187 40.2843C89.9277 34.8465 84.0344 30.8032 76.9215 30.8032C69.8103 30.8032 63.8779 34.8448 60.0133 40.2864L60.0132 40.2864L60.0055 40.2976C56.2358 45.8143 54.2509 52.3554 54.3192 59.0366C54.2489 65.7212 56.2339 72.266 60.0055 77.7854L60.0095 77.7913L60.0137 77.7971C63.8776 83.2281 69.7812 87.2703 76.912 87.2703C84.0341 87.2703 89.9272 83.2273 93.8183 77.7993ZM116.532 18.3726L116.533 18.3729C120.543 19.1287 123.562 22.4558 123.927 26.5196L123.927 26.5201C126.996 60.4562 126.996 94.5999 123.927 128.536L123.927 128.537C123.562 132.6 120.543 135.927 116.533 136.683L116.532 136.684C103.469 139.176 90.2009 140.441 76.9021 140.462C63.6034 140.441 50.3356 139.176 37.2726 136.684L37.272 136.683C33.2514 135.92 30.2322 132.571 29.8874 128.493L29.887 128.489C26.8179 94.5531 26.8178 60.4098 29.8869 26.4743C30.2704 22.4281 33.2856 19.124 37.2801 18.3729L37.2821 18.3726C50.3451 15.8798 63.6128 14.6147 76.9116 14.5937C90.2072 14.6152 103.472 15.8804 116.532 18.3726ZM76.9124 10.25L76.9113 10.25C63.3424 10.2604 49.8041 11.5343 36.4715 14.0552L36.4692 14.0556C30.5559 15.1929 26.1076 20.1037 25.5588 26.1002L25.5587 26.1013C22.4804 60.298 22.4804 94.702 25.5587 128.899L25.5588 128.9C26.1076 134.896 30.5559 139.807 36.4692 140.944L36.4715 140.945C49.8009 143.465 63.3361 144.739 76.9017 144.75H76.903C90.4686 144.739 104.004 143.465 117.333 140.945L117.336 140.944C123.249 139.807 127.697 134.896 128.246 128.9L128.246 128.899C131.324 94.702 131.324 60.298 128.246 26.1013L128.246 26.1003C127.698 20.1068 123.255 15.1969 117.346 14.0557L117.343 14.0552C104.013 11.5348 90.4781 10.2609 76.9124 10.25Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PanicButtonKeyFobIcon')

export default icon
