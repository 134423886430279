import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60">
      <path fill="currentColor" d="M18.67 31.57a11 11 0 1 0 15.9-11.36V14a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3l-.03 6.22a11.04 11.04 0 0 0-5.87 11.35ZM25.46 22a2.02 2.02 0 0 0 1.1-1.79V14a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6.21A2 2 0 0 0 33.68 22a9 9 0 1 1-8.22 0Zm4.1 22a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1Zm6.08-.25a1 1 0 0 0-1.88.69l1.7 4.7a1 1 0 1 0 1.89-.69l-1.71-4.7Zm4.34-2.91a1 1 0 0 0-1.53 1.29l3.21 3.83a1 1 0 1 0 1.54-1.29l-3.22-3.83Zm7.42-1.71-4.33-2.5a1 1 0 0 0-1 1.73l4.33 2.5c.15.09.32.13.5.13a1 1 0 0 0 .5-1.86Zm2.05-6.65-4.93-.87a1 1 0 0 0-.35 1.97l4.93.87.17.01a1 1 0 0 0 .18-1.98Zm-6.09-4.93a1 1 0 0 0 1.16.81l4.93-.86a1 1 0 1 0-.35-1.97l-4.93.87a1 1 0 0 0-.8 1.15Zm-1.66-4.57a1 1 0 0 0 1.37.37l4.33-2.5a1 1 0 1 0-1-1.74l-4.33 2.5a1 1 0 0 0-.37 1.37Zm-2.49-3.49a1 1 0 0 0 .77-.36l3.21-3.83a1 1 0 1 0-1.53-1.29l-3.21 3.83a1 1 0 0 0 .77 1.65Zm-20.06-.36a1 1 0 0 0 1.53-1.29l-3.22-3.83a1 1 0 1 0-1.53 1.29l3.22 3.83Zm-7.42 1.72 4.33 2.5a1 1 0 1 0 1-1.73l-4.33-2.5a1 1 0 0 0-1 1.73ZM9.68 27.5l4.93.87.17.01a1 1 0 0 0 .17-1.98l-4.92-.87a1 1 0 0 0-.35 1.97Zm6.08 4.92a1 1 0 0 0-1.15-.81l-4.93.87a1 1 0 0 0 .35 1.97l4.92-.87a1 1 0 0 0 .81-1.16ZM17.43 37a1 1 0 0 0-1.37-.37l-4.33 2.5a1 1 0 1 0 1 1.73l4.33-2.5a1 1 0 0 0 .37-1.37Zm1.72 3.84-3.22 3.83a1 1 0 1 0 1.53 1.29l3.22-3.83a1 1 0 0 0-1.53-1.29Zm5.62 2.32a1 1 0 0 0-1.28.6l-1.71 4.7a1 1 0 1 0 1.88.68l1.7-4.7a1 1 0 0 0-.59-1.28Z"/>
      <path fill="currentColor" d="M.02 35.2a1 1 0 0 0 1.16.82l1.98-.35a26.8 26.8 0 0 0 2.54 6.95l-1.75 1.01a1 1 0 1 0 1 1.74l1.76-1.02c1.32 2.1 2.92 4 4.75 5.67l-1.3 1.55a1 1 0 0 0 1.53 1.29l1.3-1.56a27.07 27.07 0 0 0 6.4 3.7l-.68 1.9a1 1 0 1 0 1.87.7l.7-1.9c2.36.75 4.8 1.19 7.28 1.28V59a1 1 0 1 0 2 0v-2.02a27.5 27.5 0 0 0 7.3-1.29l.68 1.9a1 1 0 1 0 1.88-.68l-.69-1.9c2.3-.94 4.45-2.19 6.4-3.71l1.3 1.56a1 1 0 0 0 1.54-1.29l-1.3-1.55a27.22 27.22 0 0 0 4.75-5.67l1.76 1.02a1 1 0 0 0 1-1.74l-1.76-1.01a26.78 26.78 0 0 0 2.54-6.95l2 .35.17.02a1 1 0 0 0 .17-1.99l-2-.35a26.2 26.2 0 0 0 0-7.4l2-.35a1 1 0 1 0-.35-1.97l-1.99.35a26.78 26.78 0 0 0-2.54-6.95l1.76-1.01a1 1 0 0 0-1-1.74l-1.76 1.02a27.2 27.2 0 0 0-4.76-5.67l1.31-1.55a1 1 0 0 0-1.53-1.29L46.13 8.7a27.05 27.05 0 0 0-6.4-3.7l.7-1.9a1 1 0 1 0-1.89-.7l-.69 1.9a26.85 26.85 0 0 0-7.29-1.28V1a1 1 0 1 0-2 0v2.02c-2.47.1-4.92.53-7.28 1.29l-.7-1.9a1 1 0 1 0-1.87.68L19.4 5c-2.3.94-4.45 2.19-6.4 3.71l-1.31-1.56a1 1 0 0 0-1.53 1.29l1.3 1.55a27.2 27.2 0 0 0-4.75 5.67l-1.76-1.02a1 1 0 0 0-1 1.74l1.75 1.01a26.82 26.82 0 0 0-2.54 6.95l-1.99-.35a1 1 0 0 0-.34 1.97l2 .35a26.43 26.43 0 0 0 0 7.4l-2 .35a1 1 0 0 0-.81 1.16ZM21.01 6.5a25.01 25.01 0 1 1 17.1 47.02A25.01 25.01 0 0 1 21 6.5Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'LuminanceIcon', '0 0 80 80', {fontSize: 35})

export default icon
