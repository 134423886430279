const styles = (theme) => ({
  pickerContainer: {
    width: 300,
    height: 100,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
  },
  sliderContainer: {
    position: 'relative',
  },
  slider: {
    cursor: 'pointer',
    borderRadius: 10,
  },
  pickerPointer: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: -2,
    right: -8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
})

export default styles
