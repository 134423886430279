import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M64.0111 96.6667V96.1667H63.5111H62.5333V48.4H63.5111H64.0111V47.9V44.9444V44.4444H63.5111H57.6H57.1V44.9444V47.9V48.4H57.6H58.5778V96.1667H57.6H57.1V96.6667V99.6222V100.122H57.6H63.5111H64.0111V99.6222V96.6667ZM72.3778 96.1667H71.8778V96.6667V99.6222V100.122H72.3778H78.2889H78.7889V99.6222V96.6667V96.1667H78.2889H77.3111V48.4H78.2889H78.7889V47.9V44.9444V44.4444H78.2889H72.3778H71.8778V44.9444V47.9V48.4H72.3778H73.3556V96.1667H72.3778ZM116.711 7.5H32.4778C31.3844 7.5 30.5 8.38442 30.5 9.47778V139.522C30.5 140.616 31.3844 141.5 32.4778 141.5H116.711C117.804 141.5 118.689 140.616 118.689 139.522V9.47778C118.689 8.38442 117.804 7.5 116.711 7.5ZM114.733 130.156H69.9222V11.4556H114.733V130.156ZM65.9667 11.4556V130.156H34.4556V11.4556H65.9667ZM114.733 137.544H34.4556V134.111H114.733V137.544ZM39.8667 83.8667H53.1667C54.26 83.8667 55.1444 82.9822 55.1444 81.8889V64.1556C55.1444 63.0622 54.26 62.1778 53.1667 62.1778H39.8667C38.7733 62.1778 37.8889 63.0622 37.8889 64.1556V81.8889C37.8889 82.9822 38.7733 83.8667 39.8667 83.8667ZM41.8444 79.9111V72.0444H51.1889V79.9111H41.8444ZM51.1889 66.1333V68.0889H41.8444V66.1333H51.1889Z" fill="currentColor" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Fridge')

export default icon
