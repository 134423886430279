import React from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, List, ListItem, Typography,
} from '@mui/material'
import classNames from 'classnames'
import IconButton from '@mui/material/IconButton'
import { Close } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'

class RoomsStatsPopup extends React.Component {
  state = {
    isResetting: false,
  }

  RoomStatsClose = () => {
    const { closeRoomStatsPopup, clearCurrentRoomIdAndName } = this.props
    clearCurrentRoomIdAndName()
    closeRoomStatsPopup()
  }

  resetCurrentRoomCounters = () => {
    const { resetCurrentRoomCounters, id } = this.props
    resetCurrentRoomCounters(id)
  }

  renderStandardPopup = () => {
    const {
      classes,
      roomName,
      periodStats,
      isMobile,
      admin,
    } = this.props
    return (
      <>
        <DialogTitle
          className={classes.title}
        >
          {`${roomName} ${I18n.t('my_energy.small_statistics')}`}
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => {
              this.RoomStatsClose()
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.statsList}>
          <Wrapper>
            <List>
              {
                periodStats.map((periodStat, index) => (
                  <ListItem
                    key={`periodStats-${index + 1}`}
                    divider
                    className={classes.listItem}
                  >
                    <Wrapper className={classes.listItemWrapper}>
                      <Typography className={classNames(
                        classes.periods,
                        !isMobile && classes.periodsDesktop,
                      )}
                      >
                        {I18n.t(`my_energy.${periodStat.period}`)}
                      </Typography>
                      <Wrapper>
                        <Typography className={classNames(
                          classes.info,
                          !isMobile && classes.infoDesktop,
                        )}
                        >
                          {`${periodStat.energy}`}
                          {' '}
                          {I18n.t('my_energy.power_kwh')}
                        </Typography>
                        <Typography className={classNames(
                          classes.info,
                          !isMobile && classes.infoDesktop,
                        )}
                        >
                          {`${periodStat.billing} $`}
                        </Typography>
                      </Wrapper>
                    </Wrapper>
                  </ListItem>
                ))
              }
              <Button
                disabled={!admin}
                onClick={() => this.setState({ isResetting: true })}
                className={classNames(
                  classes.resetModeButton,
                )}
              >
                Reset counters
              </Button>
            </List>
          </Wrapper>
        </DialogContent>
      </>
    )
  }

  renderResetStatsPopup = () => {
    const { classes } = this.props
    return (
      <>
        <DialogTitle>{I18n.t('titles.warning')}</DialogTitle>
        <DialogContent className={classes.warningContent}>
          <Typography className={classes.warningSubtitle}>{I18n.t('my_energy.want_to_reset')}</Typography>
          <Wrapper className={classes.resetContainerButtons}>
            <Button
              className={classes.cancelReset}
              onClick={() => this.setState({ isResetting: false })}
            >
              {I18n.t('buttons.cancel')}
            </Button>
            <Button
              className={classes.confirmReset}
              onClick={() => {
                this.resetCurrentRoomCounters()
                this.setState({ isResetting: false })
              }}
            >
              {I18n.t('buttons.ok')}
            </Button>
          </Wrapper>
        </DialogContent>
      </>
    )
  }

  render() {
    const {
      classes,
      isRoomsStatsPopupOpen,
      isMobile,
    } = this.props
    const { isResetting } = this.state
    return (
      <Dialog
        className={classNames(
          classes.root,
          !isMobile && classes.rootDesktop,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.rootWebView,
        )}
        open={isRoomsStatsPopupOpen}
      >
        { !isResetting ? this.renderStandardPopup() : this.renderResetStatsPopup() }
      </Dialog>
    )
  }
}

RoomsStatsPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  isRoomsStatsPopupOpen: PropTypes.bool.isRequired,
  closeRoomStatsPopup: PropTypes.func.isRequired,
  periodStats: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  clearCurrentRoomIdAndName: PropTypes.func.isRequired,
  resetCurrentRoomCounters: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
}

RoomsStatsPopup.displayName = 'RoomsStatsPopup'

export default RoomsStatsPopup
