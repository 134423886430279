import * as type from '@app/constants/myDevices/channels/devices/devicesChannels'
import { IDeviceInfoChannelData } from '@app/@types/myDevices'

export const syncDeviceInfoChannel = () => ({
  type: type.SYNC_DEVICE_INFO_CHANNEL,
} as const)

export const syncDeviceInfoChannelSent = () => ({
  type: type.SYNC_DEVICE_INFO_CHANNEL_SENT,
} as const)

export const openDeviceInfoChannel = () => ({
  type: type.OPEN_DEVICE_INFO_CHANNEL,
} as const)

export const closeDeviceInfoChannel = () => ({
  type: type.CLOSE_DEVICE_INFO_CHANNEL,
} as const)

export const updateDeviceInfo = (data: IDeviceInfoChannelData) => ({
  type: type.UPDATE_DEVICE_INFO,
  data,
} as const)

export const setDeviceInfoError = (error: string) => ({
  type: type.SET_DEVICE_INFO_ERROR,
  error,
} as const)

export const clearDeviceInfoError = () => ({
  type: type.CLEAR_DEVICE_INFO_ERROR,
} as const)
