import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import {
  deleteRoom, postNewRoom, syncRoomsList, toggleAddPopup,
} from '@app/actions/rooms/rooms'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import RoomsList from '@app/components/Rooms/RoomsList/RoomsList'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'

let enhance = RoomsList

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const {
    rooms: {
      isAddPopupOpen,
      roomsList,
    },
  } = store.getState()
  return {
    isMobile,
    isTablet,
    roomsList,
    isAddPopupOpen,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    admin: checkIfAdmin(state.users.currentUser.user.role),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncRoomsList,
  tryAgain: syncRoomsList,
  notifications: syncNotifications.sync,
  syncRoomsList,
  postNewRoom,
  toggleAddPopup,
  deleteRoom,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RoomsEnhanced = enhance

export default RoomsEnhanced
