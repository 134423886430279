import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.2381 15.8333C3.26565 15.8333 1.66667 14.2532 1.66667 12.3039C1.66667 10.3547 3.26565 8.7745 5.2381 8.7745C5.47486 8.7745 5.70625 8.79725 5.93014 8.84075M5.93014 8.84075C5.73922 8.3315 5.63492 7.78073 5.63492 7.20588C5.63492 4.60689 7.76691 2.5 10.3968 2.5C12.8466 2.5 14.8643 4.32809 15.1293 6.67915M5.93014 8.84075C6.4005 8.932 6.83774 9.1145 7.22223 9.36883M11.9842 6.68934C12.4806 6.51593 13.0148 6.42157 13.5714 6.42157C14.117 6.42157 14.6411 6.51223 15.1293 6.67915M15.1293 6.67915C16.9942 7.31687 18.3333 9.06758 18.3333 11.1274C18.3333 13.3832 16.7272 15.2684 14.5833 15.7268" stroke="#8BC257" strokeWidth="2" strokeLinecap="round"/>
      <path d="M11.6667 15.8333H9.99999H8.33333" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'CloudMinusIcon')

export default icon
