const styles = () => ({
  root: {
    textAlign: 'center',
    paddingTop: 50,
  },
  subtitle: {
    paddingBottom: 30,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
  },
  Label: {
    fontWeight: 'bold',
    paddingTop: 2,
  },
  button: {
    textDecoration: 'underline',
    color: '#4574b1',
  },
})

export default styles
