import {
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_CONDITION_STATE_TWR,
  SELECT_CONDITION_TIMER_VALUE,
  SELECT_GEOFENCES_CONDITION,
  SELECT_GROUP_CONDITION_STATE,
  SELECT_MEZZO_CONDITION_STATE,
  SELECT_MEZZO_LIGHT_STATE,
  SELECT_ROOM_CONDITION_STATE,
  SELECT_TIME_CONDITION_STATE_DATE,
  SELECT_TIME_CONDITION_STATE_DAY,
  SELECT_TIME_CONDITION_STATE_TIME,
  SELECT_TIME_CONDITION_STATE_WHEN,
  SELECT_TRIGGERING_DEVICE,
  SELECT_VIRTUAL_SWITCH_CONDITION_STATE,
  SELECT_ZWAVE_DEVICE_TYPE,
} from '@app/constants/routes'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'

const getRedirectForConditionStateChangeButton = (type: string, conditions: Array<IConditionFromBackend>): string => {
  if (conditions.some((condition) => condition.device?.security_option === true)) {
    return SELECT_ZWAVE_DEVICE_TYPE
  }
  if (type === 'TwrDevices') {
    return SELECT_CONDITION_STATE_TWR
  }
  if (type === 'ZWaveDevices') {
    return SELECT_CONDITION_DEVICE_STATE_TYPE
  }
  if (type === 'SunWithOffset' || type === 'Dark' || type === 'Light') {
    return SELECT_TIME_CONDITION_STATE_WHEN
  }
  if (type === 'Time' || type === 'TimeLimits') {
    return SELECT_TIME_CONDITION_STATE_TIME
  }
  if (type === 'DayOfTheWeek' || type === 'Weekdays' || type === 'Weekends') {
    return SELECT_TIME_CONDITION_STATE_DAY
  }
  if (type === 'Date' || type === 'BetweenDatesSimple') {
    return SELECT_TIME_CONDITION_STATE_DATE
  }
  if (type === 'IsInAlarm'
      || type === 'Panic'
      || type === 'Duress'
      || type === 'IfArmed'
      || type === 'IfArmedHome'
      || type === 'IfDisarmed'
      || type === 'WhenFire'
      || type === 'WhenMedicalEvent'
      || type === 'Chime'
      || type === 'IfMainsHasFailed'
      || type === 'WhenMainsFail'
      || type === 'IfMainsPowerIsPresent'
      || type === 'WhenMainsRestores'
      || type === 'NotArmed'
      || type === 'NotDisarmed'
      || type === 'WhenArmed'
      || type === 'WhenDisarmed'
      || type === 'WhenMezzoSetToHomeMode'
      || type === 'WhileInMedicalAlarm'
      || type === 'Siren'
      || type === 'IfPanicDuressFire') {
    return SELECT_MEZZO_CONDITION_STATE
  }
  if (type === 'ZwaveColor') {
    return SELECT_CONDITION_DEVICE_STATE_TYPE
  }
  if (type === 'Geofencing') {
    return SELECT_GEOFENCES_CONDITION
  }
  if (type === 'RoomActivity') {
    return SELECT_ROOM_CONDITION_STATE
  }
  if (type === 'GroupActivity') {
    return SELECT_GROUP_CONDITION_STATE
  }
  if (type === 'ZwaveSensorMultilevel') {
    return SELECT_CONDITION_DEVICE_STATE_TYPE
  }
  if (type === 'HubLight') {
    return SELECT_MEZZO_LIGHT_STATE
  }
  if (type === 'ZwaveSceneController') {
    return SELECT_CONDITION_DEVICE_STATE_TYPE
  }
  if (type === 'ZwaveMultichannel') {
    return SELECT_CONDITION_DEVICE_STATE_TYPE
  }
  if (type === 'TimerExpired') {
    return SELECT_CONDITION_TIMER_VALUE
  }
  if (type === 'DeviceTimeout') {
    return SELECT_TRIGGERING_DEVICE
  }
  if (type === 'VirtualSwitch') {
    return SELECT_VIRTUAL_SWITCH_CONDITION_STATE
  }
  if (type === 'ZwaveSensorNotification') {
    return SELECT_CONDITION_DEVICE_STATE_TYPE
  }

  throw new Error('condition unsupported')
}

export default getRedirectForConditionStateChangeButton
