import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import {
  decDays,
  decHours,
  decMinutes,
  decSeconds,
  incDays,
  incHours,
  incMinutes,
  incSeconds,
  maxDays,
  maxHours,
  maxMinutes,
  maxSeconds,
  resetDays,
  resetHours,
  resetMinutes,
  resetSeconds,
} from '@app/actions/scenes/counter'
import { isIOS } from 'react-device-detect'
import Counter from '@app/components/Common/Counter/Counter'
import CounterIOS from '@app/components/Common/iOS/CounterIOS/Counter'
import { withAccessDenied } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(CounterIOS) : withStyles(Counter)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const {
    day, hour, minute, second,
  } = state.scenes.counter
  return {
    isMobile,
    day,
    hour,
    minute,
    second,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  incDays,
  decDays,
  incHours,
  decHours,
  incMinutes,
  decMinutes,
  incSeconds,
  decSeconds,
  resetDays,
  resetHours,
  resetMinutes,
  resetSeconds,
  maxDays,
  maxHours,
  maxMinutes,
  maxSeconds,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const CounterEnhanced = enhance

export default CounterEnhanced
