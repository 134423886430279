export const getMezzoStatusForScreens = (activation: string) => {
  switch (activation) {
  case null:
    return 'withoutMezzo'
  case 'activated':
    return 'activatedMezzo'
  default:
    return 'notActivatedMezzo'
  }
}
