import React from 'react'
import { Typography } from '@mui/material'
import { GeneralSettingsIcon, VoiceAssistantIcon } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './BottomTabs.style'

interface Props {
  settingTab: number,
  setSettingTab: (number: number) => void,
}

const BottomTabs: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { settingTab, setSettingTab } = props
  return (
    <Wrapper className={classes.settingTabsContainer}>
      <Wrapper
        className={classes.deviceSettingContainer}
        onClick={() => {
          setSettingTab(0)
        }}
      >
        <Wrapper className={classes.deviceSettingsItem}>
          <Wrapper className={settingTab === 0 ? classes.deviceSettingsIconActive : classes.deviceSettingsIcon}>
            <GeneralSettingsIcon />
          </Wrapper>
          <Typography className={classes.deviceSettingsLabel}>{I18n.t('my_devices.general_settings')}</Typography>
        </Wrapper>
      </Wrapper>
      <Wrapper
        className={classes.deviceSettingContainer}
        onClick={() => {
          setSettingTab(1)
        }}
      >
        <Wrapper className={classes.deviceSettingsItem}>
          <Wrapper className={settingTab === 1 ? classes.deviceSettingsIconActive : classes.deviceSettingsIcon}>
            <VoiceAssistantIcon />
          </Wrapper>
          <Typography
            className={classes.deviceSettingsLabel}
          >
            {I18n.t('my_devices.voice_assistant_settings')}
          </Typography>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default BottomTabs
