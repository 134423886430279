import React from 'react'
import {
  Button, Paper, Slider, Typography,
} from '@mui/material'
import classNames from 'classnames'
import {
  DEFAULT_VALUE, marks, MAX, MIN, UI_MAX,
} from '@app/lib/Scenes/multilevelSwitchActionStates'
import { ADJUST_DURATION_MULTILEVEL_SWITCH } from '@app/constants/routes'
import NavBar from '@app/components/Common/NavBar/NavBar'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'

class SelectMultilevelSwitchValueAfterDuration extends React.PureComponent {
  state ={
    currentValue: 50,
  }

  onBackClick=() => {
    const {
      navigate,
    } = this.props
    navigate(ADJUST_DURATION_MULTILEVEL_SWITCH)
  }

  getValueTextHandler = (value) => `${value}%`

  getLabelValueHandler = (value) => {
    if (value === MAX) {
      return UI_MAX
    }
    return value
  }

  getOnChangeSliderValue = (evt, value) => {
    if (value === MAX) {
      this.setState({ currentValue: UI_MAX })
    } else {
      this.setState({ currentValue: value })
    }
  }

  onAddButtonClick=() => {
    const { currentValue } = this.state
    const {
      saveActionDurationValueAfter,
      navigate,
    } = this.props
    if (currentValue === MIN) {
      saveActionDurationValueAfter('turn off')
    } else {
      saveActionDurationValueAfter(`turn on at ${currentValue}%`)
    }
    navigate(ADJUST_DURATION_MULTILEVEL_SWITCH)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const { currentValue } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_action_web.select_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classNames(classes.switchContainer, isMobile && classes.switchContainerMobile)}>
            <Wrapper className={classes.containerMisc}>
              <Typography className={classes.miscTitle}>
                {`${I18n.t('scene_action_web.turn_on_at')} ${currentValue}%`}
              </Typography>
              <Button className={classes.addButton} onClick={this.onAddButtonClick}>
                {' '}
                {I18n.t('scene_web.add')}
              </Button>
            </Wrapper>
            <Slider
              className={classes.slider}
              defaultValue={DEFAULT_VALUE}
              min={MIN}
              max={MAX}
              getAriaValueText={this.getValueTextHandler}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay={isMobile ? 'off' : 'auto'}
              valueLabelFormat={this.getLabelValueHandler}
              marks={marks}
              onChange={this.getOnChangeSliderValue}
            />
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultilevelSwitchValueAfterDuration.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
}

SelectMultilevelSwitchValueAfterDuration.defaultProps = {

}

SelectMultilevelSwitchValueAfterDuration.displayName = 'SelectMultilevelSwitchConditionsState'

export default SelectMultilevelSwitchValueAfterDuration
