import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { RESET_ALL_BILLING_PERIODS } from '@app/constants/myEnergy/billingPeriod'
import Api from '@app/api/resetBillingPeriods'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitResetBillingPeriodsStatsLogic = createLogic({
  type: RESET_ALL_BILLING_PERIODS,
  cancelType: API_REQUEST_CANCEL,

  transform({ action }, next) {
    const { secrets: { accessToken: { token } } } = store.getState()
    next({
      ...action,
      token,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(RESET_ALL_BILLING_PERIODS))
    Api.resetBillingPeriods(action.token)
      .then(() => {
        dispatch(apiRequestDone(RESET_ALL_BILLING_PERIODS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('resetBillingPeriods failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitResetBillingPeriodsStatsLogic
