import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectConditionDeviceTabs
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/DevicesConditions/SelectConditionDeviceTabs'
import SelectConditionDeviceTabsIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/DevicesConditions/SelectConditionDeviceTabs'
import { withAccessDenied, withNotifications } from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? SelectConditionDeviceTabsIOS : SelectConditionDeviceTabs

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { deviceType } = state.scenes.currentSceneCreating.currentCondition
  const { currentTab } = state.scenes.devicesTabs
  return {
    isMobile,
    path,
    deviceType,
    currentTab,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectConditionDeviceTabsEnhanced = enhance

export default SelectConditionDeviceTabsEnhanced
