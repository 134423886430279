const styles = (theme) => ({
  root: {
    paddingBottom: '15%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  listDesktop: {
    width: '60%',
  },
  topWrapper: {
    alignItems: 'flex-start',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    width: '60%',
  },
  itemWrapper: {
    borderRadius: '50%',
    width: 109,
    height: 109,
  },
  itemWrapperGreen: {
    backgroundImage: theme.energyGraph.greenGradient,
  },
  itemWrapperRed: {
    backgroundImage: theme.energyGraph.redGradient,
  },
  itemWrapperBlue: {
    backgroundImage: theme.energyGraph.blueGradient,
  },
  info: {
    width: 80,
    height: 80,
    paddingTop: 30,
    textAlign: 'center',
    marginTop: 15,
    borderRadius: '50%',
    backgroundColor: theme.backgrounds.cardBackground,
    margin: '0 auto',
  },
  infoArrows: {
    paddingTop: 15,
  },
  itemTitle: {
    textAlign: 'center',
  },
})

export default styles
