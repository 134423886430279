import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { getMezzoStatusForScreens } from '@app/helpers/getMezzoStatusForScreens'
import { withStyles } from '@app/components/styles'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'
import Users from '@app/components/Users/Users'

let enhance = withStyles(Users)

const mapStateToProps = (state) => {
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  const { activation } = state.unit
  const { isMobile } = state.mobileDetector
  const withUnitDevice = isMobile ? 'activatedMezzo' : getMezzoStatusForScreens(activation)
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isMobile,
    admin,
    isWebView,
    withUnitDevice,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const UsersScreenEnhanced = enhance

export default UsersScreenEnhanced
