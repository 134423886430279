import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.7031 128.531H21" stroke="currentColor" strokeWidth="4" strokeMiterlimit="10"/>
      <path d="M129.063 128.531H96.0205" stroke="currentColor" strokeWidth="4" strokeMiterlimit="10"/>
      <mask id="path-3-outside-1" maskUnits="userSpaceOnUse" x="34.7031" y="5.07812" width="81" height="124" fill="currentColor">
        <rect fill="white" x="34.7031" y="5.07812" width="81" height="124"/>
        <path d="M39.7031 128.531V10.0781H110.359V128.531"/>
      </mask>
      <path d="M39.7031 10.0781V5.07812H34.7031V10.0781H39.7031ZM110.359 10.0781H115.359V5.07812H110.359V10.0781ZM44.7031 128.531V10.0781H34.7031V128.531H44.7031ZM39.7031 15.0781H110.359V5.07812H39.7031V15.0781ZM105.359 10.0781V128.531H115.359V10.0781H105.359Z" fill="currentColor" mask="url(#path-3-outside-1)"/>
      <path d="M37.7097 7.50722L37.125 7.40676V8V130.609V131.031L37.5403 131.102L98.0138 141.493L98.5984 141.593V141V18.3906V17.9692L98.1831 17.8978L37.7097 7.50722ZM42.2812 126.655V13.5804L93.4422 22.3451V135.42L42.2812 126.655Z" fill="currentColor" stroke="currentColor"/>
      <path d="M82.7201 77.8281C84.786 77.8281 86.4607 75.9673 86.4607 73.6719C86.4607 71.3764 84.786 69.5156 82.7201 69.5156C80.6542 69.5156 78.9795 71.3764 78.9795 73.6719C78.9795 75.9673 80.6542 77.8281 82.7201 77.8281Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Door')

export default icon
