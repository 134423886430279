import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    minHeight: 710,
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem .5rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  goBackIcon: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr));',
    gridGap: '1.25rem',
    justifyItems: 'center',
    marginBottom: '3rem',
  },
  card: {
    width: 200,
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 5,
    boxSizing: 'border-box',
    backgroundColor: theme.backgrounds.cardBackground,
    color: theme.texts.main,
    boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.25)',
  },
  cardMobile: {
    width: '100%',
    height: 108,
  },
  cardInfo: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    padding: '1rem',
  },
  cardInfoMobile: {
    display: 'flex',
    paddingBottom: 0,
  },
  cardIconContainer: {
    width: 70,
    height: 55,
    backgroundColor: theme.backgrounds.componentBackground,
    borderRadius: 3,
    marginBottom: '.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 28,
  },
  nameAndStatus: {
    width: 'calc(80% - 70px)',
    marginLeft: '.5rem',
  },
  cardName: {
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 20,
    color: theme.texts.main,
  },
  cardStatus: {
    fontSize: 16,
  },
  green: {
    color: theme.myDevices.green,
  },
  red: {
    color: theme.myDevices.red,
  },
  cardControl: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
  radio: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
  },
  radioGroup: {
    display: 'block',
  },
  saveButton: {
    width: '100%',
  },
  invertContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  invertBtn: {
    boxSizing: 'border-box',
    margin: 0,
    width: 80,
    height: '2rem',
    color: theme.scenes.textColor,
  },
  default: {
    borderRadius: '5px 0 0 5px',
  },
  invert: {
    borderRadius: '0 5px 5px 0',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.backgrounds.hover,
    },
  },
}))

export default useStyles
