import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  main: {
    position: 'relative',
    padding: 10,
    marginBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  webView: {
    background: theme.webView.background,
  },
  statusHeader: {
    marginLeft: 15,
    marginBottom: 15,
    fontSize: 20,
  },
}))

export default useStyles
