import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import convertUserToForm from '@app/helpers/convertUserToForm'
import canChangeRoleHelper from '@app/helpers/canChangeRoleHelper'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  deleteMember, resetCurrentMember, set, sync,
} from '@app/actions/users/currentMember'
import { roleValues } from '@app/lib/Users/roleValues'
import { updateAccessToken } from '@app/actions/secrets'
import { updateCurrentUser } from '@app/actions/users/currentUser'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import MemberDetails from '@app/components/Users/MemberDetails/MemberDetails'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

let enhance = withStyles(MemberDetails)

const mapStateToProps = (state) => {
  const { user: { role: currrentUserRole } } = state.users.currentUser
  const { user } = state.users.currentMember
  const { ssid } = state.unit
  const member = convertUserToForm(user)
  const memberAvatar = user.avatar
  const memberRole = user.role
  const canChangeRole = canChangeRoleHelper(currrentUserRole, memberRole)
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    path,
    isWebView,
    isMobile,
    ssid,
    member,
    memberAvatar,
    memberRole,
    canChangeRole,
    enableReinitialize: true,
    initialValues: { change_role: roleValues.indexOf(memberRole) },
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
  deleteMember,
  resetCurrentMember,
  submit: (dispatch) => dispatch(submit('MemberDetailsForm')),
}, parentDispatch)

enhance = reduxForm({
  form: 'MemberDetailsForm',
  onSubmit: () => {
    store.dispatch(set())
  },
})(enhance)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withRouter(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MemberDetailsEnhanced = enhance

export default MemberDetailsEnhanced
