import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { RESET_CURRENT_ROOM_COUNTERS } from '@app/constants/myEnergy/roomsStats'
import { clearCurrentRoomStats } from '@app/actions/myEnergy/roomsStats'

import Api from '@app/api/myEnergy/resetCurrentRoomCounters'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitResetCurrentRoomCountersLogic = createLogic({
  type: RESET_CURRENT_ROOM_COUNTERS,
  cancelType: API_REQUEST_CANCEL,

  transform({ action }, next) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { roomsStats: { currentRoom: { id } } } = store.getState()
    next({
      ...action,
      token,
      id,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(RESET_CURRENT_ROOM_COUNTERS))
    Api.resetCurrentRoomCounters(action.token, action.id)
      .then((response) => {
        if (response.status === 204) {
          dispatch(clearCurrentRoomStats())
          dispatch(apiRequestDone(RESET_CURRENT_ROOM_COUNTERS))
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('resetCurrentRoomCounters failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitResetCurrentRoomCountersLogic
