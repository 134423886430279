import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0V3H0.75V0.75H3V0H0ZM6 0H12V0.75H6V0ZM15 0.75V0H18V3H17.25V0.75H15ZM17.25 6H18V12H17.25V6ZM17.25 15H18V18H15V17.25H17.25V15ZM12 17.25V18H6V17.25H12ZM3 17.25V18H0V15H0.75V17.25H3ZM0.75 12H0V6H0.75V12ZM11.9942 7.68304C12.2593 7.68304 12.4932 7.78943 12.6959 8.00223C12.8986 8.19866 13 8.43601 13 8.71429V13.9688C13 14.247 12.8986 14.4926 12.6959 14.7054C12.4932 14.9018 12.2593 15 11.9942 15H6.00585C5.74074 15 5.50682 14.9018 5.30409 14.7054C5.10136 14.4926 5 14.247 5 13.9688V8.71429C5 8.43601 5.10136 8.19866 5.30409 8.00223C5.50682 7.78943 5.74074 7.68304 6.00585 7.68304H10.5439V6.62723C10.5439 6.18527 10.3879 5.80878 10.076 5.49777C9.77973 5.17039 9.42105 5.0067 9 5.0067V4C9.68616 4 10.271 4.2619 10.7544 4.78571C11.2534 5.29315 11.5029 5.90699 11.5029 6.62723V7.68304H11.9942Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'PerimeterDisarmedIcon')

export default icon
