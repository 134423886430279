import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  main: {
    position: 'relative',
    marginBottom: 45,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 300,
    maxWidth: 400,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    marginTop: 10,
  },
  webView: {
    background: theme.webView.background,
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: '3%',
  },
  lineWrapperMobile: {
    width: '93%',
  },
  textField: {
    width: '100%',
    margin: 10,
    height: '100%',
  },
  commandLabel: {
    paddingTop: 5,
  },
  commandInput: {
    height: '67px',
  },
  button: {
    height: 60,
    width: '30%',
    backgroundColor: theme.firmwareUpdate.buttons.main,
    color: theme.typography.color,
    fontSize: 25,
  },
}))

export default useStyles
