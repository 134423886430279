import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_SET_UP_BILLING_PERIOD_OPTIONS } from '@app/constants/myEnergy/setUpBillingPeriod'
import { updateBillingSettings } from '@app/actions/myEnergy/setUpBillingPeriod'
import { setUpBillingPeriodClose } from '@app/actions/myEnergy/billingPeriod'
import Api from '@app/api/updateBillingSettings'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitSetUpBillingPeriodOptionsLogic = createLogic({
  type: SET_SET_UP_BILLING_PERIOD_OPTIONS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { form: { SetUpBillingPeriodForm: { values } } } = getState()
    const data = { ...{ time_zone: 'UTC' }, ...values }
    next({
      ...action,
      token,
      data,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_SET_UP_BILLING_PERIOD_OPTIONS))
    Api.updateBillingSettings(action.token, action.data)
      .then(() => {
        dispatch(updateBillingSettings(action.data))
        dispatch(setUpBillingPeriodClose())
        dispatch(apiRequestDone(SET_SET_UP_BILLING_PERIOD_OPTIONS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('updateBillingSettings failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitSetUpBillingPeriodOptionsLogic
