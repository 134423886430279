import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogContent, DialogTitle, Typography, IconButton,
} from '@mui/material'
import classNames from 'classnames'
import { Close } from 'icons'
import { withStyles } from '@mui/styles'
import styles from './ShowPinPopup.style'

class ShowPinPopup extends React.PureComponent {
  render() {
    const {
      classes,
      closeShowPinPopup,
      pinCode,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open
      >
        <DialogTitle
          className={classes.title}
        >
          {I18n.t('my_users.unit_user_pin')}
          <IconButton aria-label="Close" className={classes.closeButton} onClick={closeShowPinPopup}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={classNames(
            classes.dialogContent,
          )}
        >
          <Typography color="primary" className={classes.pin}>{pinCode}</Typography>
        </DialogContent>
      </Dialog>
    )
  }
}

ShowPinPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  closeShowPinPopup: PropTypes.func.isRequired,
  pinCode: PropTypes.string.isRequired,
}

export default withStyles(styles)(ShowPinPopup)
