const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  buttonWrapper: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
  },
})

export default styles
