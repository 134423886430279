import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, List, Tooltip, Typography,
} from '@mui/material'
import {
  DragDropContext, Draggable, Droppable,
  DroppableProvided, DropResult,
} from 'react-beautiful-dnd'
import { AutoSort, HandSort, ReloadCircle } from 'icons'
import getScenesListReorder from '@app/helpers/scenes/getScenesListReorder'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import { getNewScenesIdOrder } from '@app/helpers/scenes/getNewSceneIdOrder'
import { changeSceneMode } from '@app/actions/scenes/currentSceneCreating'
import { syncScenesListChannel, closeChannel as closeScenesListChannel } from '@app/actions/scenes/scenesListChannel'
import {
  saveEditedSceneInitialActions,
  saveEditedSceneInitialConditions,
  saveEditedSceneInitialName,
  saveChangesetAction,
} from '@app/actions/scenes/editScene'
import {
  putSphereSort, saveSphereSortField, saveSphereSortModel, saveSphereSortOrder,
} from '@app/actions/sphereSort'
import {
  closeDeleteScenePopup,
  closeSceneUsedInScenePopup,
  deleteScene,
  sync,
  update,
  clearCurrentSceneAll,
} from '@app/actions/scenes/scenesList'
import { IScene } from '@app/@types/scenes/scenes'
import { useDispatch } from '@app/store'
import Scene from './Scene/Scene'
import useStyles from './ScenesList.style'

interface Props {
  isDeleteScenePopupOpen: boolean,
  scenesList: Array<IScene>,
  admin: boolean,
  isSceneUsedInScenePopupOpen: boolean,
  isWebView: boolean,
  isWebhookPopupOpen: boolean,
}

const ScenesList: React.FC<Props> = (props) => {
  const {
    isDeleteScenePopupOpen,
    scenesList,
    admin,
    isSceneUsedInScenePopupOpen,
    isWebView,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isSorting, changeIsSorting] = useState<boolean>(false)
  const [sortedSceneList, saveSortedScenesList] = useState<Array<IScene>>([])

  if (scenesList !== sortedSceneList) {
    saveSortedScenesList(scenesList)
  }

  useEffect(() => {
    dispatch(syncScenesListChannel())
    dispatch(changeSceneMode('init'))
    dispatch(saveEditedSceneInitialName(''))
    dispatch(saveEditedSceneInitialConditions([]))
    dispatch(saveEditedSceneInitialActions([]))
    dispatch(clearCurrentSceneAll())
    dispatch(saveChangesetAction(''))
    return () => {
      dispatch(closeScenesListChannel())
    }
  }, [])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const sortedScenes = getScenesListReorder(
      sortedSceneList,
      result.source.index,
      result.destination.index,
    )
    saveSortedScenesList(sortedScenes)
    dispatch(update(sortedScenes))
  }

  const saveSortData = (model: string, field: string, order: Array<IScene>) => {
    dispatch(saveSphereSortModel(model))
    dispatch(saveSphereSortField(field))
    dispatch(saveSphereSortOrder(getNewScenesIdOrder(order)))
    dispatch(putSphereSort())
  }

  const autoSort = () => {
    // @ts-ignore
    const autoSortedScenes = [...sortedSceneList].sort((a, b) => b.is_user_triggerable - a.is_user_triggerable || b.id - a.id)
    saveSortedScenesList(autoSortedScenes)
    dispatch(update(autoSortedScenes))
    saveSortData('Unit::Scene', 'id', autoSortedScenes)
  }

  const submitNewOrder = () => {
    changeIsSorting(false)
    saveSortData('Unit::Scene', 'id', scenesList)
  }

  const deleteSceneAccepted = () => {
    dispatch(deleteScene())
    dispatch(closeDeleteScenePopup())
  }

  const deleteSceneDeclined = () => {
    dispatch(closeDeleteScenePopup())
  }

  const closeUsedInScenePopup = () => {
    dispatch(closeSceneUsedInScenePopup())
  }

  const syncScenes = () => {
    dispatch(sync())
  }

  const renderScene = (scene: IScene, index: number) => (
    <Scene
      scene={scene}
      key={scene.id}
      sceneIndex={index}
      admin={admin}
      isSorting={isSorting}
    />
  )

  const renderPopups = () => (
    <>
      <WarningComponent
        open={isDeleteScenePopupOpen}
        onClose={() => {
          deleteSceneDeclined()
        }}
        onClick={deleteSceneAccepted}
        warningText="confirmations.delete_scene"
        leftButton="yeah"
        rightButton="nope"
      />
      <SmallErrorPopup
        open={isSceneUsedInScenePopupOpen}
        warningText="scene_web.scene_deletion_warning"
        buttonName="buttons.okay"
        title="titles.title_error"
        onClick={closeUsedInScenePopup}
      />
    </>
  )

  return (
    <Wrapper>
      <Wrapper className={classes.sortingContainer}>
        {isWebView && (
          <Tooltip title={I18n.t('scene_web.sync')}>
            <IconButton
              className={classes.iconButtonReload}
              onClick={syncScenes}
              size="large"
            >
              <ReloadCircle />
            </IconButton>
          </Tooltip>
        )}
        {isSorting ? (
          <Button
            color="primary"
            onClick={submitNewOrder}
            className={classes.button}
          >
            {I18n.t('buttons.submit')}
          </Button>
        ) : (
          <Wrapper>
            <Tooltip title={I18n.t('scene_web.manual_sort')}>
              <IconButton
                onClick={() => {
                  changeIsSorting(true)
                }}
                size="large"
              >
                <HandSort />
              </IconButton>
            </Tooltip>
            <Tooltip title={I18n.t('scene_web.auto_sort')}>
              <IconButton onClick={autoSort} size="large">
                <AutoSort />
              </IconButton>
            </Tooltip>
          </Wrapper>
        )}
      </Wrapper>
      {isSorting ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Wrapper className={classes.wrapper}>
            <List className={classes.scenesList}>
              <Droppable droppableId="droppable">
                {(provided: DroppableProvided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {scenesList.length
                      ? scenesList.map((scene, index) => (
                        <Draggable key={scene.id} draggableId={`${scene.id}`} index={index}>
                          {/* eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow */}
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {renderScene(scene, index)}
                            </div>
                          )}
                        </Draggable>
                      )) : (
                        <Typography className={classes.noScenes} align="center" variant="h6">
                          {I18n.t('scene_web.no_scenes')}
                        </Typography>
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </List>
            {renderPopups()}
          </Wrapper>
        </DragDropContext>
      ) : (
        <Wrapper className={classes.wrapper}>
          <List className={classes.scenesList}>
            {scenesList.length
              ? scenesList.map((scene, index) => (
                renderScene(scene, index)
              )) : (
                <Typography className={classes.noScenes} align="center" variant="h6">
                  {I18n.t('scene_web.no_scenes')}
                </Typography>
              )}
          </List>
          {renderPopups()}
        </Wrapper>
      )}
    </Wrapper>
  )
}

export default ScenesList
