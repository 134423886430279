import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import { Button, Paper, Typography } from '@mui/material'
import getActionDescription from '@app/helpers/scenes/getActionDescription'
import { sceneAction } from '@app/lib/Scenes/actions'
import { SCENE_REVIEW, SCENE_REVIEW_EDIT, SELECT_ACTION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import {
  activateChangeActionMode,
  deactivateChangeActionMode,
  deactivateChangeActionStateMode,
  patchAction,
  saveInitialActionInfo,
  saveScreenName,
} from '@app/actions/scenes/currentSceneCreating'
import StateBlockCommon from '../CreateAction/CreateActionSelected/StateBlockCommon'
import useStyles from './EditAction.style'

const defaultProps = {
  buttonName: 'submit',
  disabled: true,
  subDeviceName: '',
}

interface Props {
  isMobile: boolean,
  actionDescription: string,
  stateDescription: string,
  buttonName: string,
  disabled: boolean,
  sceneMode: string,
  actionType: string,
  actionDeviceName: string,
  colorType: string,
  delayDescription: string,
  durationDescription: string,
  durationValueAfter: string,
  colorTypeDurationValueAfter: string,
  subDeviceName: string,
}

const EditAction: React.FC<Props> = (props) => {
  const {
    isMobile,
    actionDescription,
    stateDescription,
    buttonName = defaultProps.buttonName,
    disabled = defaultProps.disabled,
    sceneMode,
    actionType,
    actionDeviceName,
    colorType,
    delayDescription,
    durationDescription,
    durationValueAfter,
    colorTypeDurationValueAfter,
    subDeviceName = defaultProps.subDeviceName,
  } = props

  const [isSubmitDisabled, changeSubmitDisabled] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(deactivateChangeActionMode())
    dispatch(deactivateChangeActionStateMode())
    dispatch(saveInitialActionInfo())
  }, [])

  const selectActionType = () => {
    dispatch(activateChangeActionMode())
    navigate(SELECT_ACTION_TYPE)
  }

  const onSubmitClick = () => {
    if (sceneMode === 'editMezzoScene' || sceneMode === 'editDraftScene') {
      dispatch(saveScreenName(SCENE_REVIEW_EDIT))
    } else {
      dispatch(saveScreenName(SCENE_REVIEW))
    }
    changeSubmitDisabled(true)
    dispatch(patchAction())
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.edit_action')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        <CardBlock
          key={sceneAction.title}
          title={I18n.t(`scene_web.${sceneAction.title}`)}
          description={getActionDescription(actionType, actionDescription, actionDeviceName)}
          position={sceneAction.position}
          buttonName={I18n.t('scene_web.change')}
          isCardDisabled={sceneAction.isCardDisabled}
          isButtonDisabled={sceneAction.isButtonDisabled}
          onClick={() => {
            selectActionType()
          }}
        />
        <StateBlockCommon
          stateDescription={stateDescription}
          actionType={actionType}
          colorType={colorType}
          delayDescription={delayDescription}
          durationDescription={durationDescription}
          durationValueAfter={durationValueAfter}
          colorTypeDurationValueAfter={colorTypeDurationValueAfter}
          subDeviceName={subDeviceName}
        />
        <Button
          variant="outlined"
          color="primary"
          disabled={actionDescription === 'none' ? disabled : isSubmitDisabled}
          className={classes.button}
          onClick={onSubmitClick}
        >
          {I18n.t(`buttons.${buttonName}`)}
        </Button>
      </Wrapper>
    </Paper>
  )
}

export default EditAction
