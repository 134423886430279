import React from 'react'
import { Typography } from '@mui/material'
import { ChevronLeft, MezzoDeviceIcon } from 'icons'
import { withStyles } from '@mui/styles'
import {
  ButtonNext, CarouselProvider, DotGroup, Slide, Slider,
} from 'pure-react-carousel'
import './react-carousel.es.css'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './MezzoList.style'

class MezzoList extends React.PureComponent {
  state = {
    chunks: [],
  }

  componentDidMount() {
    const { devices } = this.props
    this.setState({ chunks: this.chunkArrayIntoSlides([...devices], 4) })
  }

  selectDevice=(serialNumber, userRole) => {
    const { selectUnit, patchUnit, updateCurrentUserRole } = this.props
    selectUnit(serialNumber)
    updateCurrentUserRole(userRole)
    patchUnit()
  }

  chunkArrayIntoSlides= (devices, chunkSize) => {
    const results = []
    while (devices.length) {
      results.push(devices.splice(0, chunkSize))
    }
    return results
  }

  createSlideItem=(array) => {
    const {
      classes,
    } = this.props
    return array.map((device, index) => (
      <Wrapper
        key={`device-${index + 1}`}
        className={classes.deviceWrapper}
        onClick={() => {
          this.selectDevice(device.serial_number, device.unit_user.role)
        }}
      >
        <Wrapper className={classes.leftSideWrapper}>
          <Wrapper className={classes.iconWrapper}>
            <MezzoDeviceIcon />
          </Wrapper>
          <Typography>
            {device.name}
            {'\u00A0'}
          </Typography>
          <Typography className={classes.deviceSsid}>{`(${device.ssid})`}</Typography>
        </Wrapper>
        <Wrapper className={classes.arrowWrapper}>
          <ChevronLeft className={classes.arrowIcon} />
        </Wrapper>
      </Wrapper>
    ))
  }

  render() {
    const {
      classes,
      theme,
    } = this.props
    const { chunks } = this.state
    const renderSliderNav = chunks.length > 1
    return (
      <Wrapper className={classes.container}>
        {renderSliderNav
          ? (
            <CarouselProvider
              infinite
              touchEnabled
              dragEnabled
              totalSlides={chunks.length}
              naturalSlideWidth={450}
              naturalSlideHeight={260}
              isIntrinsicHeight
            >
              <Slider>
                {chunks.map((array, index) => (
                  <Slide
                    className={classes.mezzoList}
                    key={`slide-${index + 1}`}
                  >
                    {this.createSlideItem(array)}
                  </Slide>
                ))}
              </Slider>
              <Wrapper className={classes.sliderNav}>
                <DotGroup className={classes.dots} />
                <ButtonNext className={classes.nextButton}>
                  {theme.sliderArrow}
                </ButtonNext>
              </Wrapper>
            </CarouselProvider>
          )
          : (
            <Wrapper>
              {chunks.map((array, index) => (
                <Wrapper
                  className={classes.mezzoList}
                  key={`slide-${index + 1}`}
                >
                  {this.createSlideItem(array)}
                </Wrapper>
              ))}
            </Wrapper>
          )}
      </Wrapper>
    )
  }
}

MezzoList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({
    serial_number: PropTypes.string,
    ssid: PropTypes.string,
  })).isRequired,
  selectUnit: PropTypes.func.isRequired,
  patchUnit: PropTypes.func.isRequired,
  updateCurrentUserRole: PropTypes.func.isRequired,
}

export default withStyles(styles)(MezzoList)
