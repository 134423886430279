import * as type from '@app/constants/myDevices/zwaveDevices'
import { IInfoData } from '@app/@types/myDevices'

export const fetchZwaveDeviceInfo = (id: number) => ({
  type: type.FETCH_ZWAVE_DEVICE_INFO,
  id,
} as const)

export const setZwaveDeviceInfo = (id: number, data: IInfoData) => ({
  type: type.SET_ZWAVE_DEVICE_INFO,
  id,
  data,
} as const)

export const startFetchingDeviceStats = () => ({
  type: type.START_FETCH_DEVICE_STATS,
} as const)

export const endFetchingDeviceStats = () => ({
  type: type.END_FETCH_DEVICE_STATS,
} as const)
