import type { Node } from 'react'
import React, { useEffect } from 'react'
import { Paper } from '@mui/material'
import { useDispatch } from 'react-redux'
import { sync as syncActivities } from '@app/actions/activities/activities'
import { sync as subscribeActivitiesChannel } from '@app/actions/activities/activitiesChannel'
import type { Activity as ActivityType } from '@app/types/activities/activitiesTypes'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './Activity.style'
import ActivitiesList from './ActivitiesList/ActivitiesList'

type Props = {
  activities: Array<ActivityType>,
}

const Activity = (props: Props): Node => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    activities,
  } = props

  useEffect(() => {
    dispatch(syncActivities())
    dispatch(subscribeActivitiesChannel())
  }, [])

  return (
    <Paper className={classes.root}>
      <Wrapper className={classes.main}>
        <Wrapper />
        <ActivitiesList activities={activities} />
      </Wrapper>
    </Paper>
  )
}

export default Activity
