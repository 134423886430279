import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/sphere_users/check_reset_code`

const codeConfirmation = (accessToken, data) => Axios.post(ENDPOINT, data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  codeConfirmation,
}
