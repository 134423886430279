const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    zIndex: 30,
  },
  closeIcon: {
    fontSize: 20,
  },
  popupContent: {
    zIndex: 30,
    backgroundColor: theme.roomsAndGroups.infoPopupColor,
    boxShadow: '0px 2px 3px 0 rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    position: 'absolute',
    width: 170,
    height: 110,
    top: 45,
    right: 0,
  },
  list: {
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: 30,
    paddingBottom: 15,
    width: '100%',
  },
  link: {
    color: theme.typography.color,
    textDecoration: 'none',
    width: '100%',
    padding: '8px 16px',
  },
  listItem: {
    fontSize: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.roomsAndGroups.hoverColor,
    },
  },
  editListItem: {
    padding: 0,
  },
})

export default styles
