import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="150" height="150" viewBox="0 0 45 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.293404 15.8738L3.12625 18.7066C3.31401 18.8944 3.56887 19 3.83441 19C4.10002 19 4.35482 18.8944 4.54258 18.7066L9.49998 13.7492L14.4574 18.7066C14.6452 18.8944 14.9 19 15.1655 19C15.4311 19 15.6859 18.8944 15.8738 18.7066L18.7066 15.8738C19.0978 15.4826 19.0978 14.8485 18.7066 14.4574L13.7493 9.50001L18.7066 4.54257C18.8945 4.35474 19 4.10002 19 3.83441C19 3.56873 18.8945 3.314 18.7066 3.12618L15.8738 0.293344C15.4828 -0.0977925 14.8485 -0.0977925 14.4574 0.293411L9.50004 5.25072L4.54258 0.293411C4.1515 -0.0977257 3.51726 -0.0977257 3.12618 0.293411L0.293404 3.12618C0.105513 3.314 1.67152e-05 3.56873 1.67152e-05 3.83434C1.67152e-05 4.09995 0.105513 4.35468 0.293404 4.5425L5.25074 9.49995L0.293404 14.4574C-0.0978012 14.8485 -0.0978012 15.4826 0.293404 15.8738Z" fill="#F61718"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'humidityIcon')

export default icon