import React, { Component } from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import { maxAvatarSize } from '@app/lib/Users/avatar'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import styles from './DropzoneInput.style'

class DropzoneInput extends Component {
  state ={
    bigFile: false,
  }

  constructor() {
    super()
    this.onDrop = this.onDrop.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  onDrop(file) {
    const { chooseAvatarFile } = this.props
    if (file[0].size > maxAvatarSize) {
      this.setState({ bigFile: true })
      chooseAvatarFile(null)
    } else {
      this.setState({ bigFile: false })
      const data = new FormData()
      data.append('avatar', file[0])
      chooseAvatarFile(data)
    }
  }

  onCancel() {
    const { cleanAvatarFile } = this.props
    cleanAvatarFile()
  }

  render() {
    const { bigFile } = this.state
    const { classes, avatarData, isMobile } = this.props
    return (
      <Wrapper className={classes.root}>
        <Dropzone
          onDrop={this.onDrop}
          onCancel={this.onCancel}
          accept="image/*"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.input}>
              <input {...getInputProps()} />
              <Wrapper className={classes.dropTextWrapper}>
                <Typography className={classes.dropText}>
                  {isMobile ? I18n.t('my_users.choose_file') : I18n.t('my_users.drop_file') }
                </Typography>
                <Typography className={bigFile ? classes.redText : classes.text}>
                  {I18n.t('my_users.max_file_size')}
                </Typography>
              </Wrapper>
            </div>
          )}
        </Dropzone>
        <Wrapper>
          <Typography className={classes.text}>
            {I18n.t('my_users.selected_file')}
            :
          </Typography>
          {avatarData
            ? (<Typography className={classes.fileName}>{avatarData.get('avatar').name}</Typography>)
            : (<Typography className={[classes.fileName, classes.noFile].join(' ')}>{I18n.t('my_users.no_file')}</Typography>)}
        </Wrapper>
      </Wrapper>
    )
  }
}

DropzoneInput.propTypes = {
  classes: PropTypes.object.isRequired,
  chooseAvatarFile: PropTypes.func.isRequired,
  cleanAvatarFile: PropTypes.func.isRequired,
  avatarData: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
}
DropzoneInput.defaultProps = {
  avatarData: null,
}

export default withStyles(styles)(DropzoneInput)
