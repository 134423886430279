import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED,
  SELECT_MULTICHANNEL_SUB_DEVICE_VALUE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMultichannelSubDevice extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSubDeviceMode,
      isEditConditionMode,
    } = this.props
    if (isEditConditionMode) {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    } else if (isChangeSubDeviceMode) {
      navigate(SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED)
    } else {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    }
  }

  selectSubDevice=(subDeviceNumber) => {
    const {
      navigate,
      saveConditionDataNumber,
      isChangeSubDeviceMode,
    } = this.props
    saveConditionDataNumber(subDeviceNumber)
    if (isChangeSubDeviceMode) {
      navigate(SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED)
    } else {
      navigate(SELECT_MULTICHANNEL_SUB_DEVICE_VALUE)
    }
  }

  renderSubDevices=(subDevices) => (
    [...Array(subDevices)].map((el, index) => (
      <CardBlock
        key={`subDevice-${index + 1}`}
        description={`Device ${index + 1}`}
        position="middle"
        buttonName={I18n.t('scene_web.add')}
        isCardDisabled={false}
        isButtonDisabled={false}
        onClick={() => {
          this.selectSubDevice(index + 1)
        }}
      />
    )))

  render() {
    const {
      classes,
      isMobile,
      subDevicesAmount,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_subdevice')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {subDevicesAmount
            ? this.renderSubDevices(subDevicesAmount)
            : <Typography className={classes.somethingWrong}>{I18n.t('errors.something_went_wrong')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultichannelSubDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionDataNumber: PropTypes.func.isRequired,
  subDevicesAmount: PropTypes.number.isRequired,
  isChangeSubDeviceMode: PropTypes.bool.isRequired,
}

SelectMultichannelSubDevice.displayName = 'SelectMultichannelSubDevice'

export default SelectMultichannelSubDevice
