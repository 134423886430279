import * as type from '@app/constants/users/secretCode'

const initialState = {
  changeCodePopupOpen: false,
  warningChangeEmailPopupOpen: false,
  isChangePinPopupOpen: false,
  isShowPinPopupOpen: false,
  pinCode: null,
}
export default function avatar(state = initialState, action) {
  switch (action.type) {
  case type.OPEN_SECRET_CODE_POPUP:
    return { ...state, changeCodePopupOpen: true }
  case type.CLOSE_SECRET_CODE_POPUP:
    return { ...state, changeCodePopupOpen: false }

  case type.OPEN_WARNING_CHANGE_EMAIL_POPUP:
    return { ...state, warningChangeEmailPopupOpen: true }
  case type.CLOSE_WARNING_CHANGE_EMAIL_POPUP:
    return { ...state, warningChangeEmailPopupOpen: false }

  case type.OPEN_CHANGE_PIN_POPUP:
    return { ...state, isChangePinPopupOpen: true }
  case type.CLOSE_CHANGE_PIN_POPUP:
    return { ...state, isChangePinPopupOpen: false }

  case type.OPEN_SHOW_PIN_POPUP:
    return { ...state, isShowPinPopupOpen: true }
  case type.CLOSE_SHOW_PIN_POPUP:
    return { ...state, isShowPinPopupOpen: false }

  case type.SAVE_PIN_CODE:
    return { ...state, pinCode: action.pin }

  default:
    return state
  }
}
