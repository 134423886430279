import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'

import styles from './ComponentGrid.style'

const ComponentGrid = ({ children, classes, dashboardWidth }) => (
  <Grid container className={classes.gridContainer}>
    <Grid className={classes.grid} item lg={dashboardWidth ? 8 : 6}>
      {children}
    </Grid>
  </Grid>
)

ComponentGrid.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  dashboardWidth: PropTypes.bool.isRequired,
}

export default withStyles(styles)(ComponentGrid)
