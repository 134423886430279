import * as type from '@app/constants/myEnergy/roomsStats'

export const sync = () => ({
  type: type.SYNC_ROOMS_STATS,
})

export const updateRoomsStats = (rooms) => ({
  type: type.UPDATE_ROOMS_STATS,
  rooms,
})

export const openRoomStatsPopup = () => ({
  type: type.OPEN_ROOM_STATS_POPUP,
})

export const closeRoomStatsPopup = () => ({
  type: type.CLOSE_ROOM_STATS_POPUP,
})

export const selectCurrentRoomIdAndName = (id, name) => ({
  type: type.SELECT_CURRENT_ROOM_ID_AND_NAME,
  id,
  name,
})

export const clearCurrentRoomIdAndName = () => ({
  type: type.CLEAR_CURRENT_ROOM_ID_AND_NAME,
})

export const syncCurrentRoomStats = () => ({
  type: type.SYNC_CURRENT_ROOM_STATS,
})

export const updateCurrentRoomStats = (stats) => ({
  type: type.UPDATE_CURRENT_ROOM_STATS,
  stats,
})

export const clearCurrentRoomStats = () => ({
  type: type.CLEAR_CURRENT_ROOM_STATS,
})

export const resetCurrentRoomCounters = () => ({
  type: type.RESET_CURRENT_ROOM_COUNTERS,
})
