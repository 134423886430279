import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import PropTypes from 'prop-types'
import Wrapper from '../Wrapper/Wrapper'

class Slider extends React.Component {
  render() {
    const {
      firstComponent,
      secondComponent,
      thirdComponent,
      selectedItem,
      chooseSliderTab,
    } = this.props
    return (
      <Wrapper>
        <Carousel
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows
          swipeable
          emulateTouch
          selectedItem={selectedItem}
          onChange={(e) => {
            chooseSliderTab(e)
          }}
        >
          <Wrapper>
            {firstComponent}
          </Wrapper>
          <Wrapper>
            {secondComponent}
          </Wrapper>
          <Wrapper>
            {thirdComponent}
          </Wrapper>
        </Carousel>
      </Wrapper>
    )
  }
}

Slider.propTypes = {
  firstComponent: PropTypes.node.isRequired,
  secondComponent: PropTypes.node.isRequired,
  thirdComponent: PropTypes.node.isRequired,
  selectedItem: PropTypes.number.isRequired,
  chooseSliderTab: PropTypes.func.isRequired,
}

export default Slider
