import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowBack: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  warningIcon: {
    position: 'absolute',
    top: 5,
    right: 50,
  },
  deleteIcon: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
  failedInterviewPopup: {
    zIndex: 200,
    position: 'absolute',
    top: 25,
    right: 30,
    padding: '.5rem 1rem',
    backgroundColor: theme.roomsAndGroups.infoPopupColor,
  },
  failedInterviewPopupMobile: {
    top: 28,
    width: '80%',
  },
  failedInterviewMessage: {
    fontSize: 14,
    marginBottom: '.5rem',
  },
  failedInterviewMessageMobile: {
    fontSize: 12,
  },
  errorContainer: {
    marginTop: '30%',
    textAlign: 'center',
  },
  errorText: {
    fontSize: 20,
  },
  errorButton: {
    marginTop: 20,
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 2.5rem)',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    width: 160,
    height: 160,
    borderRadius: '50%',
    backgroundColor: theme.backgrounds.cardBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainerMobile: {
    width: 90,
    height: 90,
  },
  deviceContent: {
    width: '100%',
    minHeight: 160,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deviceControl: {
    height: 150,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceControlMobile: {
    width: '90%',
    margin: '32px 0',
  },
  deviceStatus: {
    display: 'block',
  },
  icon: {
    fontSize: 80,
  },
  iconMobile: {
    fontSize: 40,
  },
  iconAndName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 70,
  },
  nameContainer: {
    marginTop: '1rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoIcon: {
    position: 'absolute',
    top: -2,
    left: -45,
  },
  deviceInfoPopup: {
    position: 'absolute',
    top: 30,
    left: -318,
    width: 280,
    backgroundColor: theme.roomsAndGroups.infoPopupColor,
    zIndex: 200,
  },
  deviceInfoPopupMobile: {
    left: 'calc(50% - 125px)',
  },
  infoPopupList: {
    padding: '12px 0',
    '& > li': {
      fontSize: '14px',
      padding: '4px 16px',
    },
  },
  subText: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.75rem',
  },
  progressBar: {
    marginTop: 250,
  },
  green: {
    color: theme.palette.primary.main,
  },
  red: {
    color: theme.palette.error.main,
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 3000,
    backgroundColor: 'rgba(0,0,0,0.7)',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdropInner: {
    zIndex: 3001,
    width: 505,
    textAlign: 'center',
    '& > h5': {
      marginBottom: '1rem',
    },
    '& > h6': {
      marginBottom: '.75rem',
      textAlign: 'center',
    },
  },
  sleepingPopupContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
  },
  sleepingPopupButton: {
    alignSelf: 'flex-end',
    marginTop: '1rem',
  },
  overlayLinearBar: {
    margin: '2rem auto',
  },
  settingsButtonsWrapper: {
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

export default useStyles
