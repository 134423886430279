import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  restoreActionInfo,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveActionStateDescription,
  saveActionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectMezzoLightsActionStateValue
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoLghtsActionStateValue/SelectMezzoLightsActionStateValue'
import SelectMezzoLightsActionStateValueIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoLghtsActionStateValue/SelectMezzoLightsActionStateValue'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectMezzoLightsActionStateValueIOS) : withStyles(SelectMezzoLightsActionStateValue)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isChangeActionStateMode, isChangeActionMode, isEditActionMode, initialAction,
  } = state.scenes.currentSceneCreating
  const { stateDescription, actionType } = state.scenes.currentSceneCreating.currentAction
  const { actions } = state.scenes.currentSceneConditionsActions
  return {
    isMobile,
    path,
    isChangeActionStateMode,
    isChangeActionMode,
    stateDescription,
    actionType,
    isEditActionMode,
    actions,
    initialAction,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveActionStateDescription,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveActionType,
  restoreActionInfo,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectMezzoLightsActionStateValueEnhanced = enhance

export default withRouter(SelectMezzoLightsActionStateValueEnhanced)
