import React from 'react'
import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { DarkSliderArrow, LendleaseLogo } from 'icons'
import logoOnlinePath from '@app/images/lendlease-logo-nav.svg'
import logoOfflinePath from '@app/images/lendlease-logo-nav-offline.svg'

const theme = createTheme(adaptV4Theme({
  palette: {
    mode: 'light',
    primary: {
      main: '#21BAA1',
      contrastText: '#fff',
      dark: 'rgba(33, 186, 161, 0.7)',
    },
    secondary: {
      main: '#1A6C6C',
    },
    error: {
      main: '#d50000',
    },
    shadow: {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  typography: {
    fontFamily: [
      '"roboto-v18-latin-regular"',
    ].join(','),
    color: '#000',
    shadowColor: 'rgba(0, 0, 0, 0.75)',
  },
  loginMargin: {
    bottomMargin: 0,
  },
  overrides: {
    MuiListItemText: {
      root: {
        color: '#000',
      },
    },
    MuiButton: {
      root:
        { color: '#000' },
    },
  },
  title: 'Lendlease',
  loginLogo: <LendleaseLogo />,
  loginLogoMobile: <LendleaseLogo />,
  sliderArrow: <DarkSliderArrow />,
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.nesscorporation.lendlease',
  appStoreUrl: 'https://apps.apple.com/ru/app/lendlease/id1398395682',
  logos: {
    onlinePath: logoOnlinePath,
    offlinePath: logoOfflinePath,
  },
  footerButtons: {
    activeColor: '#000',
    selectedColor: '#d50000',
  },
  dashboard: {
    componentBackground: '#FFF',
    shadowColor: 'rgba(132, 132, 132, 0.7)',
    hoverShadowColor: 'rgba(33, 186, 161, 0.7)',
  },
  energyGraph: {
    chartColor: {
      dollar: '#282DB0',
      kWh: '#21BAA1',
    },
    greenGradient:
      'linear-gradient(#21BAA1 0%, rgba(33, 186, 161, 0.83) 29.48%,rgba(33, 186, 161, 0.36) 64.37%,rgba(33, 186, 161, 0.98) 100%)',
    redGradient:
      'linear-gradient(#FF0000 0%, rgba(255, 0, 0, 0.48) 29.48%,rgba(255, 0, 0, 0.41) 64.37%,rgba(255, 0, 0, 0.86) 100%)',
    blueGradient:
      'linear-gradient(#282DB0 0%, rgba(40, 45, 176, 0.52) 29.48%,rgba(39, 43, 154, 0.41) 64.37%,#282DB0 100%)',
    decreasingArrow: '#21BAA1',
    increasingArrow: '#FF0000',
  },
  scenes: {
    textColor: '#000',
    title: 'rgba(29, 29, 29, 0.67)',
    titleTop: '#3E3F3E',
    descriptionTop: 'rgba(0, 0, 0, 0.7)',
    disabledElement: 'rgba(0, 0, 0, 0.26)',
  },
  backgrounds: {
    mainBg: '#EAEAEA',
    transparentBg: 'transparent',
    // eslint-disable-next-line max-len
    loginBg: 'linear-gradient(0.42deg, rgba(209, 255, 0, 0.65) 2.88%, #65DB46 17.55%, #4BCE67 34.57%, #2EC090 50.52%, #1CB7A7 66.21%, #08AEC3 82%, #749EA1 99.53%)',
    componentBackground: '#FFF',
    cardBackground: '#EAEAEA',
    navigation: '#1BA58E',
    popup: '#EAEAEA',
    hover: 'rgba(33, 186, 161, 0.5)',
  },
  borders: {
    main: 'rgba(0, 0, 0, 0.42)',
    secondary: '#EAEAEA',
    disabled: 'rgba(0, 0, 0, 0.23)',
  },
  texts: {
    main: '#000',
    subText: 'rgba(0, 0, 0, 0.7)',
    halfVisible: 'rgba(0, 0, 0, 0.5)',
  },
  myStatus: {
    subtitle: '#3E3F3E',
  },
  myDevices: {
    outline: '#8BC34A',
    green: '#2F8A41',
    red: '#DE1F26',
  },
  roomsAndGroups: {
    infoPopupColor: '#D0D0D0',
    hoverColor: '#EAEAEA',
  },
  webView: {
    background: '#fff',
  },
  firmwareUpdate: {
    title: '#3E3F3E',
    buttons: {
      main: 'rgba(132, 132, 132, 0.2)',
      hover: 'rgba(255, 255, 255, 0.1)',
      active: 'rgba(255, 255, 255, 0.2)',
      focus: '#35353B',
    },
    disabledElement: 'rgba(0, 0, 0, 0.4)',
  },
}))

export default theme
