import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import {
  IDeviceParameterData,
  IDeviceStatusData,
  IParseConfigParameterData,
} from '@app/@types/myDevices'

const postNewZwaveDevice = (access_token: string) => Axios.post(`${API_URL}/api/devices/zwave_devices`, null, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteZwaveDevice = (access_token: string, id: number) => Axios.delete(`${API_URL}/api/devices/zwave_devices/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchZwaveDeviceStatus = (access_token: string, id: number, data: IDeviceStatusData) => Axios.patch(
  `${API_URL}/api/devices/zwave_devices/${id}`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const postReinterviewZwaveDevice = (access_token: string, id: number) => Axios.post(
  `${API_URL}/api/devices/zwave_devices/reinterview/${id}`, null, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const cancelZwaveDevice = (access_token: string) => Axios.post(`${API_URL}/api/devices/zwave_devices/cancel`, null, {
  headers: {
    'Access-Token': access_token,
  },
})

const cancelZwaveDelete = (access_token: string) => Axios.post(`${API_URL}/api/devices/zwave_devices/cancel`, null, {
  headers: {
    'Access-Token': access_token,
  },
})

const fetchZwaveDeviceDetails = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/zwave_devices/details/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const getZwaveDeviceParameter = (access_token: string, id: number, parameter_number: number) => Axios.get(
  `${API_URL}/api/devices/zwave_devices/configuration/${id}`,
  {
    params: {
      parameter_number,
    },
    headers: {
      'Access-Token': access_token,
    },
  },
)

const setZwaveDeviceParameter = (access_token: string, id: number, param: IDeviceParameterData) => Axios.post(
  `${API_URL}/api/devices/zwave_devices/configuration/${id}`, param, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const parseZwaveDeviceParameter = (access_token: string, id: number, data: IParseConfigParameterData) => Axios.post(
  `${API_URL}/api/devices/zwave_devices/parse_configuration/${id}`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const getZwaveDeviceParameters = (access_token: string, id: number, parametersNumbers: Array<number>) => Axios.put(
  `${API_URL}/api/devices/zwave_devices/configuration/${id}`, { numbers: parametersNumbers }, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

export default {
  postNewZwaveDevice,
  deleteZwaveDevice,
  postReinterviewZwaveDevice,
  patchZwaveDeviceStatus,
  cancelZwaveDevice,
  cancelZwaveDelete,
  fetchZwaveDeviceDetails,
  getZwaveDeviceParameter,
  setZwaveDeviceParameter,
  parseZwaveDeviceParameter,
  getZwaveDeviceParameters,
}
