import { createLogic } from 'redux-logic'
import {
  CANCEL_REPLACE_DEVICE,
} from '@app/constants/myDevices/deviceSettings/failedNode'
import Api from '@app/api/myDevices/deviceSettings/failedNode'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const cancelReplaceDeviceLogic = createLogic({
  type: CANCEL_REPLACE_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(CANCEL_REPLACE_DEVICE))
    try {
      await Api.cancelReplaceDevice(action.accessToken)
      dispatch(apiRequestDone(CANCEL_REPLACE_DEVICE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('CANCEL_REPLACE_DEVICE failed without error')
      }
    }
    done()
  },
})

export default cancelReplaceDeviceLogic
