import * as type from '@app/constants/myDevices/myDevices'

export const syncMezzoLights = () => ({
  type: type.SYNC_MEZZO_LIGHTS,
} as const)

export const updateMezzoLights = (value: number) => ({
  type: type.UPDATE_MEZZO_LIGHTS,
  value,
} as const)

export const patchMezzoLights = (value: number | Array<number>) => ({
  type: type.PATCH_MEZZO_LIGHTS,
  value,
} as const)

export const startMezzoLightsLoading = () => ({
  type: type.START_MEZZO_LIGHTS_LOADING,
} as const)

export const endMezzoLightsLoading = () => ({
  type: type.END_MEZZO_LIGHTS_LOADING,
} as const)
