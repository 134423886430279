import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  settingsCard: {
    boxSizing: 'border-box',
    width: '100%',
    height: 64,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '8px 2%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  settingsCardMobile: {
    padding: '8px 4%',
  },
  subTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deviceName: {
    marginRight: '.75rem',
  },
  cardTitle: {
    fontSize: 12,
    color: theme.texts.halfVisible,
  },
  marginBottomHelper: {
    marginBottom: '0.15rem',
  },
  arrowRightIcon: {
    transform: 'rotate(180deg)',
    position: 'absolute',
    right: '1rem',
    top: 'calc(50% - 12px)',
  },
}))

export default useStyles
