import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import classNames from 'classnames'
import { renderIcon } from '@app/helpers/myDevices/renderIcon'
import { splitArrayIntoChunks } from '@app/helpers/myDevices/splitArrayIntoChunks'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './IconGallery.style'

interface Props {
  activeIcon: number,
  setActiveIcon: (number: number) => void,
  isMobile: boolean,
}

const IconGallery: React.FC<Props> = (props) => {
  const { activeIcon, setActiveIcon, isMobile } = props
  const [activeSlide, setActiveSlide] = useState(0)
  const classes = useStyles()

  const getIcon = (key: number) => (
    <Wrapper
      key={`icon_${key}`}
      className={classNames(classes.iconOutline, isMobile && classes.iconOutlineMobile, +key === activeIcon && classes.active)}
      onClick={() => setActiveIcon(+key)}
    >
      {renderIcon(key, { className: classNames(classes.icon, isMobile && classes.iconMobile) })}
    </Wrapper>
  )

  const iconChunksArray = splitArrayIntoChunks(Object.keys(devicesIcons).map(
    (key) => getIcon(+key),
  ), isMobile ? 16 : 32)

  return (
    <Wrapper className={classes.root}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        onChange={setActiveSlide}
        selectedItem={activeSlide}
      >
        {iconChunksArray.map((iconChunk, i) => (
          <Wrapper
            className={classNames(classes.iconContainer, isMobile && classes.iconContainerMobile)}
            key={`icon_chunk_${i + 1}`}
          >
            {iconChunk}
          </Wrapper>
        ))}
      </Carousel>
    </Wrapper>
  )
}

export default IconGallery
