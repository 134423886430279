import { VIRTUAL_SWITCH_CHANNEL } from '@app/constants/virtualSwitches/virtualSwitches'
import { closeChannel, openChannel } from '@app/actions/timers/timers'
import openCable from '../cable'

const virtualSwitchChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(VIRTUAL_SWITCH_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create(VIRTUAL_SWITCH_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(openChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeChannel())
      },
    })
  }
  return subscription
}

export default {
  virtualSwitchChannel,
}
