const styles = () => ({
  input: {
    maxWidth: '100%',
    marginLeft: '6px',
  },
  text: {
    color: '#f44336',
    position: 'absolute',
    top: 35,
    left: 5,
  },
  icon: {
    fontSize: 15,
  },
})

export default styles
