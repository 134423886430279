import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { syncVirtualSwitches } from '@app/actions/virtualSwitches/virtualSwitches'
import { saveConditionDescription, saveConditionDeviceNumber } from '@app/actions/scenes/currentSceneCreating'
import { isIOS } from 'react-device-detect'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import SelectVirtualSwitchCondition
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchCondition/SelectVirtualSwitchCondition'
import SelectVirtualSwitchConditionIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchCondition/SelectVirtualSwitchCondition'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectVirtualSwitchConditionIOS) : withStyles(SelectVirtualSwitchCondition)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { virtualSwitches } = state.virtualSwitches
  return {
    isMobile,
    virtualSwitches,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  refresh: syncVirtualSwitches,
  tryAgain: syncVirtualSwitches,
  saveConditionDeviceNumber,
  saveConditionDescription,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectVirtualSwitchConditionEnhanced = enhance

export default withRouter(SelectVirtualSwitchConditionEnhanced)
