import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M74.5002 44.8438C58.1339 44.8438 44.8457 58.1319 44.8457 74.4982C44.8457 90.8645 58.1339 104.153 74.5002 104.153C90.8665 104.153 104.155 90.8645 104.155 74.4982C104.155 58.1319 90.8665 44.8438 74.5002 44.8438ZM74.5002 98.9088C61.1273 98.9088 50.0896 87.9994 50.0896 74.4982C50.0896 60.9971 61.1273 50.0877 74.5002 50.0877C87.8696 50.0877 98.9108 61.1288 98.9108 74.4982C98.9108 87.8677 87.8696 98.9088 74.5002 98.9088Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M74.4999 36.5732C76.0514 36.5732 77.1218 35.2599 77.1218 33.9512V9.62195C77.1218 8.07044 75.8085 7 74.4999 7C73.1912 7 71.8779 8.07044 71.8779 9.62195V33.9512C71.8779 35.4496 73.0015 36.5732 74.4999 36.5732Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M74.4999 112.426C72.9484 112.426 71.8779 113.739 71.8779 115.048V139.377C71.8779 140.929 73.1912 141.999 74.4999 141.999C75.2306 141.999 75.8776 141.644 76.3221 141.199C76.7665 140.755 77.1218 140.108 77.1218 139.377V115.048C77.1218 113.549 75.9983 112.426 74.4999 112.426Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M36.5732 74.5009C36.5732 72.9494 35.2599 71.8789 33.9512 71.8789H9.62195C8.07044 71.8789 7 73.1922 7 74.5009C7 75.2316 7.35529 75.8786 7.79975 76.3231C8.24421 76.7675 8.89123 77.1228 9.62195 77.1228H33.9512C35.4496 77.1228 36.5732 75.9993 36.5732 74.5009Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M139.378 71.8789H115.049C113.497 71.8789 112.427 73.1922 112.427 74.5009C112.427 75.2316 112.782 75.8786 113.227 76.3231C113.671 76.7675 114.318 77.1228 115.049 77.1228H139.378C140.929 77.1228 142 75.8095 142 74.5009C142 73.1922 140.929 71.8789 139.378 71.8789Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M43.9218 47.7685C44.1592 48.0059 44.4679 48.2028 44.7674 48.341C45.0613 48.4767 45.446 48.602 45.8454 48.602C46.5191 48.602 47.1781 48.3594 47.769 47.7685C48.8353 46.7021 48.8353 44.9877 47.769 43.9213L21.0068 17.1591C19.9404 16.0928 18.226 16.0928 17.1596 17.1591C16.0933 18.2255 16.0933 19.9399 17.1596 21.0063L43.9218 47.7685Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M105.078 101.229C104.012 100.163 102.297 100.163 101.231 101.229C100.165 102.296 100.165 104.01 101.231 105.077L127.993 131.839C128.231 132.076 128.539 132.273 128.839 132.411C129.133 132.547 129.517 132.672 129.917 132.672C130.59 132.672 131.249 132.43 131.84 131.839C132.907 130.772 132.907 129.058 131.84 127.992L105.078 101.229Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M131.838 21.0081L131.84 21.0063C132.907 19.9399 132.907 18.2255 131.84 17.1591C130.774 16.0928 129.059 16.0928 127.993 17.1591L101.231 43.9213C100.165 44.9877 100.165 46.7021 101.231 47.7685L101.301 47.8385L101.383 47.8934C101.919 48.2508 102.515 48.4668 103.154 48.4668C103.828 48.4668 104.487 48.2244 105.077 47.6341C105.078 47.6338 105.078 47.6336 105.078 47.6333L131.838 21.0081Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M43.9218 101.229L17.1596 127.992C16.0933 129.058 16.0933 130.772 17.1596 131.839C17.397 132.076 17.7057 132.273 18.0052 132.411C18.2991 132.547 18.6839 132.672 19.0832 132.672C19.7569 132.672 20.4159 132.43 21.0068 131.839L47.769 105.077C48.8353 104.01 48.8353 102.296 47.769 101.229C46.7026 100.163 44.9881 100.163 43.9218 101.229Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M129.526 51.79L129.501 51.7006L129.459 51.6175C128.845 50.3892 127.398 49.7325 126.086 50.1699L126.052 50.1811L126.019 50.1947L111.016 56.4122L110.984 56.4258L110.952 56.4416C109.724 57.0558 109.067 58.5029 109.504 59.8149L109.514 59.8427L109.525 59.87C109.959 60.9562 111.024 61.5799 112.075 61.5799C112.082 61.5799 112.09 61.58 112.098 61.58C112.215 61.5801 112.392 61.5804 112.577 61.5494C112.788 61.5144 112.975 61.4476 113.163 61.3563L128.132 55.1531C129.411 54.6369 129.894 53.0772 129.526 51.79Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M36.0679 87.4707L36.0342 87.4819L36.0013 87.4955L20.9983 93.713L20.9656 93.7266L20.9339 93.7424C19.7056 94.3565 19.049 95.8037 19.4863 97.1156L19.4956 97.1435L19.5065 97.1708C19.9409 98.257 21.0063 98.8807 22.0569 98.8807C22.0644 98.8807 22.0721 98.8807 22.0802 98.8807C22.1968 98.8809 22.3737 98.8812 22.5592 98.8502C22.7695 98.8152 22.9568 98.7484 23.1452 98.6571L38.1186 92.4519L38.1513 92.4383L38.183 92.4225C39.4113 91.8084 40.0679 90.3612 39.6306 89.0493L39.6081 88.9818L39.5763 88.9183C39.245 88.2556 38.6633 87.8012 38.0565 87.5584C37.4524 87.3168 36.7337 87.2487 36.0679 87.4707Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M61.662 36.2046L61.6508 36.1709L61.6371 36.138L55.4197 21.135L55.4061 21.1023L55.3903 21.0706C54.7761 19.8423 53.329 19.1857 52.017 19.623L51.9496 19.6455L51.886 19.6773C50.6577 20.2914 50.0011 21.7385 50.4384 23.0505L50.4485 23.0806L50.4604 23.11L56.541 38.1087C56.5413 38.1096 56.5416 38.1104 56.5419 38.1112C56.977 39.1958 58.0415 39.8186 59.0914 39.8186C59.0988 39.8186 59.1066 39.8186 59.1147 39.8187C59.2313 39.8188 59.4081 39.8191 59.5937 39.7881C59.8169 39.7509 60.0142 39.678 60.2144 39.5779C61.4427 38.9638 62.0993 37.5166 61.662 36.2046Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M92.4514 110.874L92.4391 110.845L92.4249 110.817C91.8108 109.588 90.3637 108.932 89.0517 109.369L88.9843 109.392L88.9207 109.423C87.6924 110.037 87.0358 111.485 87.4731 112.797L87.4843 112.83L87.498 112.863L93.7134 127.861C94.1494 128.943 95.2126 129.565 96.2612 129.565C96.2687 129.565 96.2765 129.565 96.2845 129.565C96.4011 129.565 96.5779 129.565 96.7635 129.534C96.9867 129.497 97.1841 129.424 97.3842 129.324C98.6125 128.71 99.2692 127.263 98.8319 125.951L98.8194 125.913L98.804 125.877L92.4514 110.874Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M87.57 38.0222C87.799 38.6329 88.2751 39.2119 88.9815 39.4736C89.1611 39.5585 89.3409 39.6208 89.5415 39.6543C89.7271 39.6852 89.9039 39.685 90.0205 39.6848C90.0286 39.6848 90.0363 39.6848 90.0438 39.6848C91.0924 39.6848 92.1556 39.0635 92.5917 37.9813L98.7953 23.0117C99.4211 21.7067 98.8087 19.9915 97.2994 19.503C95.9932 18.912 94.3025 19.5383 93.8313 21.0478L87.645 35.9757C87.3211 36.6505 87.3368 37.4004 87.57 38.0222Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M61.6375 112.999L61.6812 112.893L61.7 112.78C61.8029 112.163 61.6995 111.488 61.4369 110.915C61.1832 110.362 60.7242 109.79 60.0164 109.529C58.7074 108.921 57.0004 109.546 56.5266 111.063L50.3402 125.991C49.7149 127.296 50.326 129.009 51.8331 129.499C52.0057 129.579 52.1792 129.638 52.3719 129.67C52.5575 129.701 52.7343 129.701 52.8509 129.7C52.859 129.7 52.8668 129.7 52.8742 129.7C53.9228 129.7 54.986 129.079 55.4221 127.997L61.6375 112.999Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M39.7456 60.0018L39.7857 59.9014L39.8035 59.7948C39.9064 59.1775 39.8029 58.5027 39.5403 57.9297C39.2831 57.3685 38.815 56.7884 38.0905 56.5323L23.144 50.3383C21.839 49.7125 20.1238 50.3248 19.6353 51.8342C19.0443 53.1403 19.6707 54.8311 21.1802 55.3022L36.1069 61.4881C36.2952 61.5794 36.4826 61.6462 36.6928 61.6812C36.8784 61.7121 37.0553 61.7119 37.1718 61.7117C37.1799 61.7117 37.1877 61.7117 37.1951 61.7117C38.2458 61.7117 39.3111 61.0879 39.7456 60.0018Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M113.134 87.4955L113.081 87.4736L113.025 87.4578C112.337 87.261 111.603 87.3082 110.977 87.5584C110.373 87.8001 109.791 88.2687 109.528 88.9814C108.92 90.2905 109.545 91.9975 111.063 92.4712L125.99 98.6571C126.178 98.7484 126.365 98.8152 126.576 98.8502C126.761 98.8812 126.938 98.8809 127.055 98.8807C127.063 98.8807 127.07 98.8807 127.078 98.8807C128.116 98.8807 129.169 98.2714 129.613 97.2086C130.241 95.8964 129.618 94.1705 128.09 93.6937L113.134 87.4955Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SunBright')

export default icon
