import * as type from '@app/constants/menu'
import * as MenuAction from '@app/actions/menu'
import { InferValueTypes } from '@app/@types/typesHelper'

type MenuActions = ReturnType<InferValueTypes<typeof MenuAction>>

export interface IMenuState {
  isMenuOpen: boolean,
  isShowNavigation: boolean,
  isHomeOpen: boolean,
  isMySecurityMenuOpen: boolean,
  isMyAutomationMenuOpen: boolean,
  isApplicationMenuOpen: boolean,
  isMezzoMenuOpen: boolean,
  isSecurityMenuOpen: boolean,
}

const initialState = {
  isMenuOpen: false,
  isShowNavigation: true,
  isHomeOpen: false,
  isMySecurityMenuOpen: false,
  isMyAutomationMenuOpen: false,
  isApplicationMenuOpen: false,
  isMezzoMenuOpen: false,
  isSecurityMenuOpen: false,
}

export default function navigation(state: IMenuState = initialState, action: MenuActions): IMenuState {
  switch (action.type) {
  case type.OPEN_MENU:
    return {
      ...state,
      isMenuOpen: true,
    }
  case type.CLOSE_MENU:
    return {
      ...state,
      isMenuOpen: false,
    }
  case type.SET_NAVIGATION_STATUS:
    return {
      ...state,
      // @ts-ignore
      isShowNavigation: action.navigation,
    }
  case type.TOGGLE_HOME_TAB: return { ...state, isHomeOpen: !state.isHomeOpen }
  case type.TOGGLE_MY_SECURITY_TAB: return { ...state, isMySecurityMenuOpen: !state.isMySecurityMenuOpen }
  case type.TOGGLE_SECURITY_TAB: return { ...state, isSecurityMenuOpen: !state.isSecurityMenuOpen }
  case type.TOGGLE_AUTOMATION_TAB: return { ...state, isMyAutomationMenuOpen: !state.isMyAutomationMenuOpen }
  case type.TOGGLE_APPLICATION_TAB: return { ...state, isApplicationMenuOpen: !state.isApplicationMenuOpen }
  case type.TOGGLE_MEZZO_TAB: return { ...state, isMezzoMenuOpen: !state.isMezzoMenuOpen }
  case type.CLOSE_ALL_TABS: return {
    ...state,
    isHomeOpen: false,
    isMySecurityMenuOpen: false,
    isMyAutomationMenuOpen: false,
    isApplicationMenuOpen: false,
    isMezzoMenuOpen: false,
    isSecurityMenuOpen: false,
  }
  case type.RESET_NAVIGATION:
    return initialState
  default:
    return state
  }
}
