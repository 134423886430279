import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="M21.22 18.97l-1.72 1.72V18H18v2.69l-1.72-1.72-1.06 1.06 3.53 3.53 3.53-3.53-1.06-1.06zM18 7.5v3h1.5v-3A7.508 7.508 0 0012 0v1.5a6.007 6.007 0 016 6zM6 16.5v-3H4.5v3A7.508 7.508 0 0012 24v-1.5a6.007 6.007 0 01-6-6zM6 10.5H4.5V12H6v-1.5zM6 7.5H4.5V9H6V7.5zM4.5 6H6V3.31l1.72 1.72 1.06-1.06L5.25.44 1.72 3.97l1.06 1.06L4.5 3.31V6zM19.5 12H18v1.5h1.5V12zM19.5 15H18v1.5h1.5V15z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'autoSort', '0 0 16 16', { fontSize: 18 })

export default icon
