/* eslint-disable no-underscore-dangle */
import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import Axios from 'axios'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { SIGN_IN } from '@app/constants/routes'
import { REFRESH_TOKEN_INTERCEPTOR, REFRESH_TOKEN } from '@app/constants/secrets'
import Api from '@app/api/refreshToken'
import oAuthApplication from '@app/helpers/oAuthApplication'
import { tokenData } from '@app/lib/Wizard/wizardLogicFunctions'
import { whitelist } from '@app/store/reducer'
import resetPersistent from '@app/helpers/resetPersistent'
import { updateAccessToken, tokenStatusRefreshing, tokenStatusRefreshed } from '@app/actions/secrets'
import { updateCurrentUser } from '@app/actions/users/currentUser'
import getActionToSaveDataInWebView from '@app/helpers/getActionToSaveStoreDataInWebView'
import {
  apiRequestSent, apiRequestDone, apiRequestFailed, apiRequestClear,
} from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import handleErrors from '@app/helpers/handleErrors'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const refreshTokenWithInterceptorsLogic = createLogic({
  type: REFRESH_TOKEN_INTERCEPTOR,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken } } = getState()
    const { users: { currentUser: { user: { role } } } } = getState()
    if (accessToken === null) {
      if (isAndroid && isWebView) {
        // eslint-disable-next-line no-undef
        const nativeToken = JSON.parse(AndroidFunction.getToken())
        // eslint-disable-next-line no-undef
        const nativeUser = JSON.parse(AndroidFunction.getUser())
        store.dispatch(updateAccessToken(nativeToken.token, nativeToken.expiresIn, nativeToken.tokenType, nativeToken.refreshToken))
        store.dispatch(updateCurrentUser(nativeUser.user))
      }
      if (isIOS && isWebView) {
        // eslint-disable-next-line no-alert
        const nativeToken = JSON.parse(prompt('getToken'))
        // eslint-disable-next-line no-alert
        const nativeUser = JSON.parse(prompt('getUser'))
        store.dispatch(updateAccessToken(nativeToken.token, nativeToken.expiresIn, nativeToken.tokenType, nativeToken.refreshToken))
        store.dispatch(updateCurrentUser(nativeUser.user))
      }
    }
    if (role === null) {
      if (isAndroid && isWebView) {
        // eslint-disable-next-line no-undef
        const nativeUser = JSON.parse(AndroidFunction.getUser())
        store.dispatch(updateCurrentUser(nativeUser.user))
      }
      if (isIOS && isWebView) {
        // eslint-disable-next-line no-alert
        const nativeUser = JSON.parse(prompt('getUser'))
        store.dispatch(updateCurrentUser(nativeUser.user))
      }
    }
    if (accessToken) {
      const { secrets: { accessToken: { refreshToken } } } = getState()
      const { clientId, secret } = oAuthApplication()
      next({
        ...action,
        accessToken,
        refreshToken,
        clientId,
        secret,
      })
    }
  },
  process({ action }, dispatch, done) {
    Axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config
        const status = error.response ? error.response.status : null
        if ((status === 401 || status === 500) && !originalRequest._retry) {
          originalRequest._retry = true
          if (isIOS && isWebView) {
            // eslint-disable-next-line no-alert
            const refreshToken = JSON.parse(prompt('tokenRefresh'))
            store.dispatch(updateAccessToken(
              refreshToken.token, refreshToken.expiresIn, refreshToken.tokenType, refreshToken.refreshToken,
            ))
            originalRequest.headers['Access-Token'] = refreshToken.token
            try {
              const response = await Axios(originalRequest)
              const api = originalRequest.url.split('/')
              return getActionToSaveDataInWebView(api[api.length - 1].split('?')[0], response.data, response)
            } catch (err) {
              throw new Error(err)
            }
          }
          if (isAndroid && isWebView) {
            // eslint-disable-next-line no-undef
            const refreshToken = JSON.parse(AndroidFunction.tokenRefresh())
            store.dispatch(updateAccessToken(
              refreshToken.token, refreshToken.expiresIn, refreshToken.tokenType, refreshToken.refreshToken,
            ))
            originalRequest.headers['Access-Token'] = refreshToken.token
            try {
              const response = await Axios(originalRequest)
              const api = originalRequest.url.split('/')
              return getActionToSaveDataInWebView(api[api.length - 1].split('?')[0], response.data, response)
            } catch (err) {
              throw new Error(err)
            }
          } else {
            store.dispatch(apiRequestSent(REFRESH_TOKEN))
            store.dispatch(tokenStatusRefreshing())
            try {
              const refreshToken = await Api.refreshToken(action.refreshToken, action.clientId, action.secret)
              const parsedToken = tokenData(refreshToken)
              store.dispatch(updateAccessToken(
                parsedToken.token,
                parsedToken.expiresIn,
                parsedToken.tokenType,
                parsedToken.refreshToken,
                parsedToken.createdAt,
                parsedToken.scope,
              ))
              store.dispatch(apiRequestDone(REFRESH_TOKEN))
              store.dispatch(tokenStatusRefreshed())
              store.dispatch(apiRequestClear())
              if (!isMobile) {
                window.location.reload()
              }
              done()
            } catch (err) {
              if (err === 403) {
                const currentError = handleErrors(err)
                store.dispatch(apiRequestFailed(currentError))
                whitelist.forEach((target) => store.dispatch(resetPersistent(target)))
                history.replace(SIGN_IN)
                done()
              }
              done()
              throw new Error(err)
            }
          }
        }
        return Promise.reject(error)
      },
      done(),
    )
  },
})

export default refreshTokenWithInterceptorsLogic
