import Axios from 'axios'
import { getEndpointForEvents as ENDPOINT } from '@app/helpers/getEndpointForEvents'

const fetchEvents = (token, type, from, to, text, currentPage) => Axios.get(ENDPOINT(type, from, to, text, currentPage),
  {
    headers: { 'Access-Token': token },
  })

export default {
  fetchEvents,
}
