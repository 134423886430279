import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_702_936)">
        <path d="M15.8333 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5ZM15.8333 14.1667C15.8333 14.6087 15.6577 15.0326 15.3452 15.3452C15.0326 15.6577 14.6087 15.8333 14.1667 15.8333C13.7246 15.8333 13.3007 15.6577 12.9882 15.3452C12.6756 15.0326 12.5 14.6087 12.5 14.1667C12.5 13.7246 12.6756 13.3007 12.9882 12.9882C13.3007 12.6756 13.7246 12.5 14.1667 12.5C14.6087 12.5 15.0326 12.6756 15.3452 12.9882C15.6577 13.3007 15.8333 13.7246 15.8333 14.1667ZM15.8333 5.41667C15.8333 5.74819 15.7016 6.06613 15.4672 6.30055C15.2328 6.53497 14.9149 6.66667 14.5833 6.66667H5.41667C5.08515 6.66667 4.7672 6.53497 4.53278 6.30055C4.29836 6.06613 4.16667 5.74819 4.16667 5.41667C4.16667 5.08515 4.29836 4.7672 4.53278 4.53278C4.7672 4.29836 5.08515 4.16667 5.41667 4.16667H14.5833C14.9149 4.16667 15.2328 4.29836 15.4672 4.53278C15.7016 4.7672 15.8333 5.08515 15.8333 5.41667Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_702_936">
          <rect width="20" height="20" fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'AppWindowIcon')

export default icon
