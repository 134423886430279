import React from 'react'
import classNames from 'classnames'
import {
  Card, CardContent, FormControlLabel, Radio, Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './LightBlock.style'

class LightBlock extends React.Component {
  render() {
    const {
      classes,
      isMobile,
      color,
      off,
    } = this.props
    return (
      <Card className={classes.card}>
        <CardContent className={classNames(
          isMobile ? classes.cardContentMobile : classes.cardContent,
        )}
        >
          <Wrapper className={classes.cardData}>
            <div
              className={classes.colorPreview}
              style={{ backgroundColor: color }}
            >
              {off && <Typography className={classes.offText}>{I18n.t('scene_web.off')}</Typography>}
            </div>
            <FormControlLabel label="" className={classes.formControlLabel} value={color} control={<Radio color="primary" />} />
          </Wrapper>
        </CardContent>
      </Card>
    )
  }
}

LightBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  off: PropTypes.bool.isRequired,
}

LightBlock.defaultProps = {
  isMobile: false,
}

export default withStyles(styles)(LightBlock)
