import type { Node } from 'react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Popover, Typography } from '@mui/material'
import { MoreVert } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './RemindersMenu.style'

const RemindersMenu = ({ onDelete, onEdit, id }): Node => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const editReminder = () => {
    setAnchorEl(null)
    onEdit()
  }

  const deleteReminder = () => {
    setAnchorEl(null)
    onDelete()
  }

  const open = Boolean(anchorEl)

  return (
    <Wrapper>
      <IconButton
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.menu}
      >
        <Typography
          sx={{ p: 2 }}
          className={classes.menuItem}
          onClick={editReminder}
        >
          {I18n.t('buttons.edit')}
        </Typography>
        <Typography
          sx={{ p: 2 }}
          className={classes.menuItem}
          onClick={deleteReminder}
        >
          {I18n.t('buttons.delete')}
        </Typography>
      </Popover>
    </Wrapper>
  )
}

RemindersMenu.propTypes = {
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default RemindersMenu
