import React from 'react'
import {
  Button, Checkbox, FormControlLabel, FormGroup, Paper, Typography,
} from '@mui/material'
import classNames from 'classnames'
import {
  fullWeek, resetDays, Weekdays, Weekends,
} from '@app/lib/Scenes/conditionTimeDays'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_TIME_CONDITION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTimeConditionStateDay extends React.Component {
  state = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }

  handleChange = (name) => {
    this.setState((prevState) => ({ [name]: !prevState[name] }))
  }

  selectDays=(array) => {
    this.setState(resetDays)
    const nextState = Object.assign(...array.days.map((day) => ({ [day]: true })))
    this.setState(nextState)
  }

  selectConditionType=() => {
    const currState = this.state
    if (JSON.stringify(currState) === JSON.stringify(Weekdays.state)) {
      return 'Weekdays'
    } if (JSON.stringify(currState) === JSON.stringify(Weekends.state)) {
      return 'Weekends'
    }
    return 'DayOfTheWeek'
  }

  descriptionRender=(type) => {
    const currState = { ...this.state }
    switch (type) {
    case 'DayOfTheWeek':
      if (JSON.stringify(currState) === JSON.stringify(fullWeek.state)) {
        return `${fullWeek.description}`
      }
      return `${Object.keys(currState).filter((key) => currState[key]).map((el) => `${I18n.t(`scene_web.${el}`)}`).join(', ')}`
    case 'Weekdays':
      return `${Weekdays.description}`
    case 'Weekends':
      return `${Weekends.description}`
    default:
      return ''
    }
  }

  backendTimeData=(type) => {
    const currState = { ...this.state }
    switch (type) {
    case 'DayOfTheWeek':
      return Object.keys(currState).filter((key) => currState[key])
    case 'Weekdays':
      return `${type}`
    case 'Weekends':
      return `${type}`
    default:
      return ''
    }
  }

  onAddStateClick=() => {
    const {
      saveConditionType,
      saveConditionDescription,
      saveConditionStateDescription,
      saveConditionStateTimeData,
      isEditConditionMode,
      navigate,
    } = this.props
    const conditionType = this.selectConditionType()
    const conditionStateDescription = this.descriptionRender(conditionType)
    const conditionStateTimeData = this.backendTimeData(conditionType)
    saveConditionType(conditionType)
    saveConditionDescription('time_condition_state_day')
    saveConditionStateDescription(conditionStateDescription)
    saveConditionStateTimeData(conditionStateTimeData)
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  onBackClick=() => {
    const {
      navigate,
      isEditConditionMode,
      isChangeConditionStateMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_TIME_CONDITION_TYPE)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const {
      monday, tuesday, wednesday, thursday, friday, saturday, sunday,
    } = this.state
    const currState = { ...this.state }
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classNames(
          classes.main,
          !isMobile && classes.mainDesktop,
        )}
        >
          <Wrapper className={classes.dayWrapper}>
            <Typography className={classNames(
              classes.stateTitle,
              !isMobile && classes.stateTitleDesktop,
            )}
            >
              {I18n.t('scene_web.every')}
            </Typography>
            <Wrapper className={classNames(
              classes.buttonsWrapper,
              !isMobile && classes.buttonsWrapperDesktop,
            )}
            >
              <Button
                className={classes.everyButton}
                color="primary"
                onClick={() => this.selectDays(Weekdays)}
              >
                {I18n.t('scene_web.weekday')}
              </Button>
              <Button
                className={classes.everyButton}
                color="primary"
                onClick={() => this.selectDays(Weekends)}
              >
                {I18n.t('scene_web.weekend')}
              </Button>
            </Wrapper>
            <Typography className={classNames(
              classes.weekTitle,
              !isMobile && classes.weekTitleDesktop,
            )}
            >
              {I18n.t('scene_web.day_of_week')}
            </Typography>
            <FormGroup className={classes.daysWrapper}>
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={monday}
                    onChange={() => this.handleChange('monday')}
                    value="monday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                label={I18n.t('scene_web.monday')}
                labelPlacement="start"
              />
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={tuesday}
                    onChange={() => this.handleChange('tuesday')}
                    value="tuesday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                label={I18n.t('scene_web.tuesday')}
                labelPlacement="start"
              />
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={wednesday}
                    onChange={() => this.handleChange('wednesday')}
                    value="wednesday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                labelPlacement="start"
                label={I18n.t('scene_web.wednesday')}
              />
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={thursday}
                    onChange={() => this.handleChange('thursday')}
                    value="thursday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                labelPlacement="start"
                label={I18n.t('scene_web.thursday')}
              />
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={friday}
                    onChange={() => this.handleChange('friday')}
                    value="friday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                labelPlacement="start"
                label={I18n.t('scene_web.friday')}
              />
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={saturday}
                    onChange={() => this.handleChange('saturday')}
                    value="saturday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                labelPlacement="start"
                label={I18n.t('scene_web.saturday')}
              />
              <FormControlLabel
                className={classNames(
                  classes.checkbox,
                  !isMobile && classes.checkboxDesktop,
                )}
                control={(
                  <Checkbox
                    checked={sunday}
                    onChange={() => this.handleChange('sunday')}
                    value="sunday"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                labelPlacement="start"
                label={I18n.t('scene_web.sunday')}
              />
            </FormGroup>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
            disabled={Object.keys(currState).every((k) => currState[k] === false || currState[k] === '')}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimeConditionStateDay.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionStateTimeData: PropTypes.func.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
}

SelectTimeConditionStateDay.displayName = 'SelectTimeConditionStateDayIOS'

export default SelectTimeConditionStateDay
