import React from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SELECT_ACTION_ROOM_STATE_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './Room.style'

class Room extends React.Component {
  onRoomClick = (room) => {
    const {
      navigate,
      saveActionDescription,
      saveActionRoomNumber,
    } = this.props
    saveActionDescription(room.name)
    saveActionRoomNumber(room.number)
    navigate(SELECT_ACTION_ROOM_STATE_TYPE)
  }

  render() {
    const {
      classes,
      room,
    } = this.props
    return (
      <Wrapper
        className={classes.room}
        onClick={() => {
          this.onRoomClick(room)
        }}
      >
        <Typography className={classes.title} variant="h6" component="h2">
          {room.name}
        </Typography>
      </Wrapper>
    )
  }
}

Room.propTypes = {
  classes: PropTypes.object.isRequired,
  saveActionDescription: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  saveActionRoomNumber: PropTypes.func.isRequired,
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(Room)
