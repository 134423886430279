import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_MY_DEVICES } from '@app/constants/myDevices/myDevices'
import { setMyDevices } from '@app/actions/myDevices/myDevices'
import { updateMezzoLights } from '@app/actions/myDevices/mezzoLights'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/myDevices'
import { mergeDevices } from '@app/helpers/myDevices/mergeDevices'

const fetchMyDevices = createLogic({
  type: SYNC_MY_DEVICES,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { myDevices: { myDevices: { devices } } } = getState()
    next({
      ...action,
      accessToken,
      devices,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SYNC_MY_DEVICES))
    try {
      const fetchedDevices = await Api.fetchMyDevices(action.accessToken)
      if (action.devices.length !== 0) {
        const updatedDevices = mergeDevices(action.devices, fetchedDevices.data.devices)
        dispatch(setMyDevices(updatedDevices))
        dispatch(apiRequestDone(SYNC_MY_DEVICES))
      } else {
        dispatch(setMyDevices(fetchedDevices.data.devices))
        dispatch(apiRequestDone(SYNC_MY_DEVICES))
      }
      const mezzoLights = fetchedDevices.data.devices.find((device) => device.device_type === 'unit_lights')
      if (mezzoLights) {
        dispatch(updateMezzoLights(mezzoLights.value))
      }
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('SYNC_MY_DEVICES failed without error')
      }
    }
    done()
  },
})

export default fetchMyDevices
