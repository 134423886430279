import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 40,
    position: 'absolute',
    top: 2,
    left: 2,
  },
  root: {
    marginBottom: 10,
  },
  input: {
    width: '100%',
  },
  resetFieldButton: {
    overflow: 'hidden',
  },
  required: {
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    top: 56,
    right: 0,
  },
}))

export default useStyles
