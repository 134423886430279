import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
  },
  rootMobile: {
    height: 'calc(100vh - 60px)',
    minHeight: 500,
    padding: '1rem',
  },

  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  navigationControlsMobile: {
    marginBottom: '1rem',
  },
  arrowBack: {
    position: 'absolute',
    top: 5,
    left: 10,
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 300,
    marginBottom: '2rem',
  },
  contentContainerMobile: {
    flexDirection: 'column',
    height: '85%',
    minHeight: 390,
  },
  content: {
    height: '80%',
    width: '40%',
    textAlign: 'center',
    paddingTop: 30,
  },
  contentMobile: {
    width: '80%',
  },
  statusContainer: {
    width: '100%',
    marginTop: -30,

    '& > p': {
      marginTop: '.5rem',
    },
  },

  progressBar: {
    width: '100%',
  },
  input: {
    marginTop: 8,
    width: '100%',
  },
  title: {
    marginTop: 25,
  },
  subTitle: {
    marginTop: 10,
  },
  submitButton: {
    marginTop: 25,
  },
  subText: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.75rem',
  },
  errorMessage: {
    color: '#FF0000',
    margin: '8px 0',
  },

  icon: {
    marginTop: '2.5rem',
  },
  zWaveIcon: {
    fontSize: 190,
  },
  zWaveIconMobile: {
    marginTop: '.5rem',
    fontSize: 130,
  },
  deviceAddedIcon: {
    fontSize: 200,
  },
  deviceAddedIconMobile: {
    fontSize: 140,
  },

  progressTipContainer: {
    position: 'absolute',
    bottom: 10,
    left: 'calc(50% - 10px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressTip: {
    margin: 2,
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.scenes.textColor,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
}))

export default useStyles
