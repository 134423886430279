import type { Node } from 'react'
import React from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, TextField, Typography,
} from '@mui/material'
import PropTypes from '@app/components/PropTypes'
import { GoogleLogo } from 'icons'
import useStyles from './StatsAuth.style'

type Props = {
  username: string,
  password: string,
  setUsername: (string) => void,
  setPassword: (string) => void,
  onLoginHandler: (any) => void,
  redirectUrl: string,
}

const StatsAuth = (props: Props): Node => {
  const {
    username, password, setUsername, setPassword, onLoginHandler, redirectUrl,
  } = props
  const classes = useStyles()

  const googleSignInLink = (host = window.location.hostname) => {
    if (host === 'localhost') {
      return `http://${host}:3000/auth/google_oauth2?source=statistic&path_url=${redirectUrl}`
    }
    return `https://${host.replace('web', 'adm')}/auth/google_oauth2?source=statistic&path_url=${redirectUrl}`
  }

  const generatedGoogleSignInLink = googleSignInLink()

  return (
    <Dialog open>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Sign in with your admin panel credentials</Typography>
      </DialogTitle>
      <DialogContent>
        <form
          className={classes.formContainer}
          onSubmit={onLoginHandler}
        >
          <TextField
            variant="standard"
            label="Email"
            type="email"
            value={username}
            onChange={(evt) => setUsername(evt.target.value)}
            className={classes.input}
          />
          <TextField
            variant="standard"
            label="Password"
            type="password"
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
            className={classes.input}
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            className={classes.btn}
            disabled={!username && !password}
          >
            Sign In
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.googleButton}
          >
            <GoogleLogo />
            <a
              className={classes.googleLink}
              href={generatedGoogleSignInLink}
            >
              {I18n.t('stats.sign_in_google')}
            </a>
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

StatsAuth.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  onLoginHandler: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string.isRequired,
}

export default StatsAuth
