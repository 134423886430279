import { IAccessToken } from '@app/@types/secrets'
import { Optional } from '@app/@types/common'

const isAccessTokenValid = (accessToken: Optional<IAccessToken>): boolean => {
  if (accessToken === null) {
    return false
  }
  const { timestamp, expiresIn } = accessToken
  const currentTimestamp = new Date().getTime()
  return timestamp + expiresIn > currentTimestamp
}

export default isAccessTokenValid
