import PO from './programOptions'

const aboutOptions = [
  { number: PO.FIRMWARE_REV_1340, value: '00.02.25' },
  { number: PO.PRODUCT_SERIAL_1333, value: 'N/A' },
  { number: PO.CELLULAR_TYPE_1374, value: 'N/A' },
  { number: PO.CELLULAR_IMEI_1341, value: 'N/A' },
  { number: PO.SIM_ICCID_1352, value: 'N/A' },
  { number: PO.MAIN_BOARD_REV_1327, value: '02' },
  { number: PO.BOOT_LOADER_REV_1329, value: 'E9' },
  { number: PO.ZWAVE_REV_1328, value: '4.33.1' },
  { number: PO.VOICE_REV_1330, value: '01' },
]

const securityOptions = [
  { number: PO.ENTRY_DELAY_1300, value: '20' },
  { number: PO.EXIT_DELAY_1301, value: '10' },
  { number: PO.ABORT_TIME_1308, value: '0' },
  { number: PO.MEDICAL_ABORT_TIME_1309, value: '20' },
  { number: PO.SENSITIVITY_MEZZO_UNIT_TAMPER_1335, value: '2' },
  { number: PO.SUPERVISION_TIME_1302, value: '0' },
  { number: PO.LOCK_OUT_1303, value: '0' },
]

const armourOptions = [
  { number: PO.CID_DIALLER_1311, value: '0' },
  { number: PO.TEST_CALL_1310, value: '0' },
  { number: PO.ARMOR_IP_POLL_TIME_1357, value: '0' },
  { number: PO.OPEN_CLOSE_1306, value: '0' },
  { number: PO.LOW_BATTERY_1307, value: '0' },
  { number: PO.INACTIVITY_REPORTING_1351, value: '0' },
  { number: PO.CLIENT_ACCOUNT_1220, value: '0000' },
  { number: PO.CSV_REPORTING_USERNAME_1361, value: '' },
  { number: PO.CSV_REPORTING_PASSWORD_1362, value: '' },
  { number: PO.IP_REPORTING_TCP_UPD_1360, value: '0' },
  { number: PO.PRIMARY_ARMOR_DOMAIN_1363, value: '' },
  { number: PO.SECONDARY_ARMOR_DOMAIN_1364, value: '' },
  { number: PO.ARMOR_IP_1343, value: '' },
  { number: PO.ARMOR_PORT_1344, value: '' },
  { number: PO.SECONDARY_ARMOR_IP_1353, value: '' },
  { number: PO.SECONDARY_ARMOR_PORT_1354, value: '' },
]

const takeOnBoardSettingsOptions = [{ number: PO.EOL_RESISTOR_VALUES_1373, value: '0' }]

const numberOfZonesOptions = [{ number: PO.NUMBER_OF_ZONES_1375, value: '8' }]

const others = [
  { number: PO.APN_1326, value: '' },
  { number: PO.GSM_SIGNAL_1231, value: 'N/A' },
  { number: PO.GET_ERROR_CODE_1242, value: '00' },
  { number: PO.LOCK_OUT_1303, value: '0' },
  { number: PO.VOICE_ON_ARM_1304, value: '1' },
  { number: PO.VOICE_ON_ALARM_1305, value: '1' },
  { number: PO.MAIN_VOLUME_1312, value: '3' },
  { number: PO.DOOR_BELL_1313, value: '0' },
  { number: PO.DOOR_BELL_VOLUME_1314, value: '3' },
  { number: PO.LIGHTS_1315, value: '3' },
  { number: PO.MEZZO_SSID_1316, value: 'N/A' },
  { number: PO.MEZZO_PASSWORD_1317, value: '' },
  { number: PO.ROUTER_MODE_1320, value: '0' },
  { number: PO.ROUTER_MODE_1321, value: 'N/A' },
  { number: PO.SECURENET_PORT_1322, value: 'N/A' },
  { number: PO.CUSTOMER_1323, value: '' },
  { number: PO.DISABLE_ALL_VOICE_1325, value: '0' },
  { number: PO.ROUTER_SECURITY_1334, value: '3' },
  { number: PO.CLOCK_MODE_1336, value: '0' },
  { number: PO.CELLULAR_ANTENNA_1339, value: '0' },
  { number: PO.LOW_POWER_OPERATION_1342, value: '0' },
  { number: PO.ROUTER_DEFAULT_SSID_1345, value: 'N/A' },
  { number: PO.ROUTER_DEFAULT_WIFI_PASSWORD_1346, value: 'N/A' },
  { number: PO.CLOUD_CONNECTION_1347, value: '0' },
]

const programOptions = [
  ...aboutOptions,
  ...securityOptions,
  ...others,
  ...armourOptions,
  ...takeOnBoardSettingsOptions,
  ...numberOfZonesOptions,
]
const programOptionsForSending = [
  ...securityOptions,
  { number: PO.APN_1326, value: '' },
  { number: PO.LOCK_OUT_1303, value: '0' },
  { number: PO.VOICE_ON_ARM_1304, value: '1' },
  { number: PO.VOICE_ON_ALARM_1305, value: '1' },
  { number: PO.LOW_BATTERY_1307, value: '0' },
  { number: PO.TEST_CALL_1310, value: '0' },
  { number: PO.CID_DIALLER_1311, value: '0' },
  { number: PO.MAIN_VOLUME_1312, value: '2' },
  { number: PO.DOOR_BELL_1313, value: '0' },
  { number: PO.DOOR_BELL_VOLUME_1314, value: '2' },
  { number: PO.LIGHTS_1315, value: '3' },
  { number: PO.MEZZO_PASSWORD_1317, value: '' },
  { number: PO.ROUTER_MODE_1320, value: '0' },
  { number: PO.CUSTOMER_1323, value: '' },
  { number: PO.DISABLE_ALL_VOICE_1325, value: '0' },
  { number: PO.ROUTER_SECURITY_1334, value: '3' },
  { number: PO.CLOCK_MODE_1336, value: '0' },
  { number: PO.CELLULAR_ANTENNA_1339, value: '0' },
  { number: PO.LOW_POWER_OPERATION_1342, value: '0' },
  { number: PO.CLOUD_CONNECTION_1347, value: '0' },
  ...armourOptions,
]

export default {
  aboutOptions,
  securityOptions,
  armourOptions,
  programOptions,
  programOptionsForSending,
  takeOnBoardSettingsOptions,
  numberOfZonesOptions,
}
