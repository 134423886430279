import { createLogic } from 'redux-logic'
import { SYNC_GUARD_PANEL_CHANNEL } from '@app/constants/guardPanel'

import {
  resetGuardPanelTimeout, setGuardPanelStatus, syncGuardPanelChannelSent, setGuardPanelProgress,
} from '@app/actions/guardPanel'
import Api from '@app/api/guardPanel'
import { deactivateInProgressAlarm } from '@app/actions/healthAlert'
import { getArmStatus } from '@app/helpers/getArmStatus'
import { updateUnitArmStatuses } from '@app/actions/unit'

const subscribeGuardPanelStatusLogic = createLogic({
  type: SYNC_GUARD_PANEL_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.guardPanelChannel({
      request: () => {
        dispatch(syncGuardPanelChannelSent())
      },
      response: (data) => {
        const { unit: { isNCentral } } = store.getState()
        if (data.arm_status) {
          const statusResult = getArmStatus(data.arm_status, data.arm_types, isNCentral)
          store.dispatch(setGuardPanelStatus(statusResult))
          store.dispatch(updateUnitArmStatuses(data))
          store.dispatch(resetGuardPanelTimeout())
          store.dispatch(deactivateInProgressAlarm())
        } else {
          store.dispatch(setGuardPanelProgress(data.in_progress))
        }
      },
    })
  },
})

export default subscribeGuardPanelStatusLogic
