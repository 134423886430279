import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="M19.707 4.293l-4-4a.997.997 0 00-.073-.066L15.598.2l-.043-.032c-.014-.01-.029-.017-.043-.026l-.04-.024-.046-.022-.043-.02-.045-.016-.048-.017-.045-.012-.05-.012-.053-.008C15.128.01 15.114.006 15.1.005a1.006 1.006 0 00-.198 0l-.043.006-.053.008-.05.012-.045.012-.048.017-.045.016-.043.02-.045.022-.04.024-.044.026-.043.032-.036.027a.997.997 0 00-.073.065l-4 4a1 1 0 101.414 1.415L14 3.414V15a1 1 0 102 0V3.414l2.293 2.293a1 1 0 101.414-1.414zM12.293 18.293L10 20.586V9a1 1 0 10-2 0v11.586l-2.293-2.293a1 1 0 00-1.414 1.414l4 4c.024.024.048.046.073.066l.036.027c.014.01.028.022.043.031.014.01.029.018.043.027l.04.024.046.022.043.02.045.016.048.017.045.011c.017.005.033.01.05.012.017.004.035.006.053.009l.043.006c.066.006.132.006.198 0l.043-.006c.018-.003.036-.005.053-.009.017-.003.033-.008.05-.012l.045-.011.048-.017.045-.016.043-.02.045-.022.04-.024c.015-.009.03-.017.044-.027l.043-.031.036-.027c.025-.02.049-.042.072-.065l.001-.001 4-4a1 1 0 10-1.414-1.414z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'sortIcon', '0 0 16 16', { fontSize: 21 })

export default icon
