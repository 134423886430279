import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import { updateAccessToken } from '@app/actions/secrets'
// @ts-ignore
import { updateCurrentUser } from '@app/actions/users/currentUser'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
// @ts-ignore
import { sync as syncScenes } from '@app/actions/scenes/scenesList'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import MyAutomation from '@app/components/MyAutomation/MyAutomation'
import MyAutomationIOS from '@app/components/MyAutomation/Tabs/iOS/MyAutomation'
import {
  MAX_NUMBER_OF_SCENES, MAX_NUMBER_OF_SCENES_BEFORE_500, MAX_NUMBER_OF_BYTES_SCENE_NAME, MAX_NUMBER_OF_BYTES_SCENE_NAME_BEFORE_500,
} from '@app/lib/Scenes/scenesRestrictions'
import checkBefore500FirmwareVersion from '@app/helpers/scenes/checkBefore500FirmwareVersion'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? MyAutomationIOS : MyAutomation

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { scenes } = state.scenes.scenesList
  const { firmwareVersion } = state.unit
  const before500FWVersion = checkBefore500FirmwareVersion(firmwareVersion)
  const maxNumberOfScenes = before500FWVersion ? MAX_NUMBER_OF_SCENES_BEFORE_500 : MAX_NUMBER_OF_SCENES
  const maxNumberOfBytesSceneName = before500FWVersion ? MAX_NUMBER_OF_BYTES_SCENE_NAME_BEFORE_500 : MAX_NUMBER_OF_BYTES_SCENE_NAME
  return {
    isMobile,
    path,
    isWebView,
    scenes,
    maxNumberOfScenes,
    maxNumberOfBytesSceneName,
    admin: checkIfAdmin(state.users.currentUser.user.role),
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: syncScenes,
  tryAgain: syncScenes,
  notifications: syncNotifications.sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MyAutomationEnhanced = enhance

// @ts-ignore
export default MyAutomationEnhanced
