import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Drawer as MaterialDrawer } from '@mui/material'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { closeMenu } from '@app/actions/menu'
import Drawer from '@app/components/Drawer'
import { withAccessDenied } from '@app/hocs'

let enhance = Drawer

const mapStateToProps = (state) => {
  const { online } = state.unit
  const { config: appConfig } = state.appConfig
  return {
    online,
    appConfig,
    open: state.menu.isMenuOpen,
    user: state.users.currentUser.user,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onClose: closeMenu,
}, dispatch)

enhance = withAccessDenied(enhance, MaterialDrawer)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)
const DrawerEnhanced = enhance

export default DrawerEnhanced
