import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { syncGroupsScenes } from '@app/actions/scenes/groupsScenes/groupsScenes'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectTriggeringGroup
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringGroup/SelectTriggeringGroup'
import SelectTriggeringGroupIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringGroup/SelectTriggeringGroup'
import {
  withAccessDenied, withNotifications, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTriggeringGroupIOS) : withStyles(SelectTriggeringGroup)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { groupsScenes } = state.scenes.groupsScenes
  return {
    isMobile,
    groupsScenes,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncGroupsScenes,
  tryAgain: syncGroupsScenes,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTriggeringGroupEnhanced = enhance

export default withRouter(SelectTriggeringGroupEnhanced)
