import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: '20px 80px 20px 70px',
    position: 'relative',
    minHeight: 92,
    justifyContent: 'center',
    marginBottom: -8,
  },
  cardDescription: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 8,
  },
  cardDescriptionWithoutSetTo: {
    marginBottom: 0,
  },
  checkbox: {
    position: 'absolute',
    left: 22,
    top: 'calc(50% - 21px)',
  },
  editButton: {
    position: 'absolute',
    right: 22,
    top: 'calc(50% - 16px)',
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  cardValue: {
    color: theme.texts.subText,
    marginRight: 10,
  },
  not_received: {
    color: 'red',
  },
}))

export default useStyles
