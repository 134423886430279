import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import { SHADOW_IN_USER } from '@app/constants/shadowIn'
import UserEndpoint from '@app/api/UsersEndpoint'
import { updateCurrentUser } from '@app/actions/users/currentUser'
import { updateAccessToken, updateSecrets } from '@app/actions/secrets'
import { updateUnit } from '@app/actions/unit'
import convertUser from '@app/helpers/convertUser'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { savePath } from '@app/actions/shadowIn'
import handleErrors from '@app/helpers/handleErrors'

const shadowInLogic = createLogic({
  type: SHADOW_IN_USER,
  cancelType: API_REQUEST_CANCEL,

  async process({ action }, dispatch, done) {
    const { accessToken, path } = action
    try {
      dispatch(apiRequestSent(SHADOW_IN_USER))
      const shadowAuth = await UserEndpoint.findByMobileToken(accessToken)
      const { data } = shadowAuth
      const mobile = data.mobile_user
      const unit = data.unit_device.unit_device
      const user = convertUser(mobile)
      dispatch(updateCurrentUser(user))
      dispatch(updateSecrets(
        unit.token,
      ))
      dispatch(updateAccessToken(
        accessToken,
        86400,
        'shadow',
      ))
      dispatch(updateUnit(unit))
      dispatch(apiRequestDone(SHADOW_IN_USER))
      dispatch(savePath(path))
      history.replace(path)
      done()
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('findByMobileToken failed without error')
      }
    }
    done()
  },
})

export default shadowInLogic
