import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { chooseTab } from '@app/actions/myEnergy/myEnergy'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import MyEnergy from '@app/components/MyEnergy/MyEnergy'
import { withAccessDenied, withNotifications } from '@app/hocs'

let enhance = withStyles(MyEnergy)

const mapStateToProps = (state) => {
  const { currentTab } = state.myEnergy
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    currentTab,
    isMobile,
    path,
    isWebView,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  chooseTab,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MyEnergyEnhanced = enhance

export default MyEnergyEnhanced
