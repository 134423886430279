export const SAVE_EMAIL = '@@forgotPassword/saveEmail'
export const CLEAR_EMAIL = '@@forgotPassword/clearEmail'

export const OPEN_WRONG_EMAIL_POPUP = '@@forgotPassword/openWrongEmailPopup'
export const CLOSE_WRONG_EMAIL_POPUP = '@@forgotPassword/closeWrongEmailPopup'

export const SAVE_CODE = '@@forgotPassword/saveCode'
export const CLEAR_CODE = '@@forgotPassword/clearCode'

export const SHOW_ERROR = '@@forgotPassword/showError'
export const HIDE_ERROR = '@@forgotPassword/hideError'

export const POST_EMAIL = '@@forgotPassword/postEmail'

export const POST_CODE = '@@forgotPassword/postCode'

export const SAVE_REQUEST_ID = '@@forgotPassword/saveRequestId'
export const CLEAR_REQUEST_ID = '@@forgotPassword/clearRequestId'

export const OPEN_WRONG_CODE_POPUP = '@@forgotPassword/openWrongCodePopup'
export const CLOSE_WRONG_CODE_POPUP = '@@forgotPassword/closeWrongCodePopup'

export const POST_NEW_PASSWORD = '@@forgotPassword/postNewPassword'

export const RESET_FORGOT_PASSWORD = '@@forgotPassword/reset'
