const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
    height: 535,
  },
  rootDesktop: {
    height: 610,
  },
  rootWebView: {
    marginTop: -40,
  },
  title: {
    textAlign: 'center',
    borderBottom: '1px solid #52525A',
    paddingBottom: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
  },
  statsList: {
    padding: 0,
  },
  listItemWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  listItemWrapperDesktop: {
    fontSize: '18px',
  },
  listItem: {
    padding: '0px 25px',
    minWidth: 290,
  },
  warningContent: {
    marginTop: -15,
  },
  resetModeButton: {
    color: '#d50000',
    textTransform: 'none',
    textDecoration: 'underline',
    marginTop: 10,
    fontSize: '16px',
    marginLeft: 16,
  },
  warningSubtitle: {
    color: theme.myStatus.subtitle,
  },
  resetContainerButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  cancelReset: {
    width: 70,
    backgroundColor: theme.palette.primary.main,
    color: theme.backgrounds.cardBackground,
  },
  confirmReset: {
    width: 70,
    color: theme.palette.primary.main,
  },
  info: {
    fontSize: '14px',
  },
  infoDesktop: {
    fontSize: '18px',
  },
  periods: {
    fontSize: '14px',
    width: 180,
  },
  periodsDesktop: {
    fontSize: '18px',
    width: 180,
  },
})

export default styles
