import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width='280' height='280'viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_776_612)">
        <path d="M16.052 10.4975L11.2812 17L16.052 23.5025L14.5833 25.5L8.33325 17L14.5833 8.5L16.052 10.4975Z" fill="currentColor"/>
      </g>
      <g clipPath="url(#clip1_776_612)">
        <path d="M35.052 10.4975L30.2812 17L35.052 23.5025L33.5833 25.5L27.3333 17L33.5833 8.5L35.052 10.4975Z" fill="currentColor" transform="translate(20)"/>
      </g>
      <g clipPath="url(#clip2_776_612)">
        <path d="M55.052 10.4975L50.2812 17L55.052 23.5025L53.5833 25.5L47.3333 17L53.5833 8.5L55.052 10.4975Z" fill="currentColor" transform="translate(40)"/>
      </g>
      <defs>
        <clipPath id="clip0_776_612">
          <rect width="50" height="68" fill="currentColor" transform="matrix(-1 0 0 -1 25 34)"/>
        </clipPath>
        <clipPath id="clip1_776_612">
          <rect width="50" height="68" fill="currentColor" transform="matrix(-1 0 0 -1 44 34)"/>
        </clipPath>
        <clipPath id="clip2_776_612">
          <rect width="50" height="68" fill="currentColor" transform="matrix(-1 0 0 -1 64 34)"/>
        </clipPath>
      </defs>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ThreeLeftArrowsIcon')

export default icon
