import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 10.8913V138.109C34.0002 138.875 34.3049 139.611 34.8471 140.153C35.3892 140.695 36.1245 141 36.8913 141H101.644C102.411 141 103.147 140.695 103.689 140.153C104.231 139.611 104.536 138.875 104.536 138.109V59.2185L115.423 41.1295C115.518 40.9388 115.589 40.7376 115.637 40.5302C115.692 40.4054 115.738 40.2768 115.775 40.1453C115.852 39.7436 115.843 39.33 115.747 38.9323V38.9272L109.584 14.6636C109.427 14.0403 109.066 13.4874 108.558 13.0925C108.051 12.6977 107.426 12.4834 106.783 12.4838H104.536V10.8913C104.536 10.1245 104.231 9.38925 103.689 8.84707C103.147 8.30489 102.411 8.0002 101.645 8H36.8913C36.1245 8.0002 35.3892 8.30489 34.8471 8.84707C34.3049 9.38925 34.0002 10.1245 34 10.8913V10.8913ZM50.3201 42.517L62.0246 22.8506L66.1695 39.1798L50.3201 65.5131V42.517ZM50.3201 31.2083V24.3201H54.4199L50.3201 31.2083ZM50.3201 78.8144H88.2157V124.68H50.3201V78.8144ZM89.4731 73.0318H52.545L70.9023 42.5299H107.832L89.4731 73.0318ZM109.23 36.7474H71.5154L66.8246 18.2664H104.536L109.23 36.7474ZM39.7826 13.7826H60.7267C60.6975 13.8261 60.6514 13.8501 60.6241 13.8956L57.8613 18.5375H47.4288C46.662 18.5377 45.9267 18.8423 45.3845 19.3845C44.8423 19.9267 44.5377 20.662 44.5375 21.4288V127.571C44.5377 128.338 44.8423 129.073 45.3845 129.615C45.9267 130.158 46.662 130.462 47.4288 130.463H91.107C91.8738 130.462 92.6091 130.158 93.1513 129.615C93.6935 129.073 93.9981 128.338 93.9983 127.571V76.7259L98.7532 68.8261V135.217H39.7826V13.7826Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SwitchVertical')

export default icon
