import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Group
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/Group'
import PropTypes from '@app/components/PropTypes'
import styles from './GroupsList.style'

class GroupsList extends React.Component {
  render() {
    const {
      classes,
      navigate,
      groupsScenes,
    } = this.props
    return (
      <List className={groupsScenes.length ? classes.root : classes.rootEmpty}>
        {groupsScenes.length
          ? groupsScenes.map((group) => (
            <ListItem className={classes.listItem} key={`group-${group.id}`}>
              <Group
                group={group}
                navigate={navigate}
              />
            </ListItem>
          )) : <Typography className={classes.empty} align="center">{I18n.t('rooms_and_groups.no_groups').toUpperCase()}</Typography>}
      </List>
    )
  }
}

GroupsList.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  groupsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

export default withStyles(styles)(GroupsList)
