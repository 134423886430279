import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { chooseTab } from '@app/actions/scenes/devicesTabs'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectObservingZWaveDevice
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingZWaveDevice/SelectObservingZWaveDevice'
import SelectObservingZWaveDeviceIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingZWaveDevice/SelectObservingZWaveDevice'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectObservingZWaveDeviceIOS) : withStyles(SelectObservingZWaveDevice)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { currentTab } = state.scenes.devicesTabs
  return {
    isMobile,
    path,
    currentTab,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  chooseTab,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectObservingZWaveDeviceEnhanced = enhance

export default withRouter(SelectObservingZWaveDeviceEnhanced)
