import update from 'immutability-helper'
import * as type from '@app/constants/virtualSwitches/virtualSwitches'
import type { Action, State } from '@app/types/virtualSwitches/virtualSwitchesTypes'

const initialState = {
  virtualSwitches: [],
  changedVirtualSwitchData: {},
}
const changedVirtualSwitchIndex = (state, action) => state.virtualSwitches.findIndex((el) => el.number === action.number)

const virtualSwitches = (state: State = initialState, action: Action): State => {
  switch (action.type) {
  case type.SET_VIRTUAL_SWITCHES: {
    const convertedVirtualSwitches = action.virtualSwitches.map((virtualSwitch) => {
      const resultStatus = virtualSwitch.status === 'progress'
      return { ...virtualSwitch, isEnablingProgress: resultStatus }
    })
    return {
      ...state,
      virtualSwitches: convertedVirtualSwitches,
    }
  }

  case type.SAVE_CHANGED_VIRTUAL_SWITCH_DATA: {
    return {
      ...state,
      changedVirtualSwitchData: action.changedVirtualSwitchData,
    }
  }
  case type.START_ENABLING_PROGRESS: {
    return update(state, {
      virtualSwitches: {
        [changedVirtualSwitchIndex(state, action)]: {
          isEnablingProgress: { $set: true },
        },
      },
    })
  }

  case type.STOP_ENABLING_PROGRESS: {
    return update(state, {
      virtualSwitches: {
        [changedVirtualSwitchIndex(state, action)]: {
          isEnablingProgress: { $set: false },
        },
      },
    })
  }

  case type.UPDATE_VIRTUAL_SWITCH_BY_NUMBER: {
    return update(state, {
      virtualSwitches: {
        [changedVirtualSwitchIndex(state, action)]: {
          enabled: { $set: action.enabled },
          status: { $set: action.status },
        },
      },
    })
  }

  default:
    return state
  }
}

export default virtualSwitches
