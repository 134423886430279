import * as type from '@app/constants/wizard/deleteAccount'

export const deleteAccount = (username: string, password: string) => ({
  type: type.DELETE_ACCOUNT,
  username,
  password,
} as const)

export const resetDeleteAccount = () => ({
  type: type.DELETE_ACCOUNT_RESET,
} as const)
