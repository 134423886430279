import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { sync } from '@app/actions/secrets'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import Secrets from '@app/components/Secrets'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import PO from '@app/lib/ProgramOptions'

let enhance = withStyles(Secrets)

const mapStateToProps = (state) => {
  const serialOption = state.unit.currentOptions.find((option) => option.number === PO.PRODUCT_SERIAL_1333)
  const serialNumber = Object.is(serialOption, undefined) ? null : serialOption.value
  const { unitToken } = state.secrets
  const mezzoIdentifier = String(state.users.currentUser.user.ids.unit)
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  return {
    isMobile,
    path,
    serialNumber,
    unitToken,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    mezzoIdentifier,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SecretsEnhanced = enhance

export default SecretsEnhanced
