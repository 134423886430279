import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES } from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import { updateZWaveDeviceMultilevelSensorTypes } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { saveMultilevelSensorType, saveMultilevelSensorTypeDescription, saveMultilevelSensorScales }
  from '@app/actions/scenes/currentSceneCreating'
import { SELECT_MULTILEVEL_SENSOR_TYPE, SELECT_MULTILEVEL_SENSOR_VALUE } from '@app/constants/routes'
import fetchMultilevelSensorsTypes
  from '@app/api/scenes/ZWaveDevices/MultilevelSensors/fetchMultilevelSensorsTypes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchZWaveMultilevelSensorTypesLogic = createLogic({
  type: SYNC_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionDeviceId } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES))
    fetchMultilevelSensorsTypes(token, conditionDeviceId)
      .then((response) => {
        const { data: { multilevel_sensors } } = response
        dispatch(updateZWaveDeviceMultilevelSensorTypes(multilevel_sensors))
        dispatch(apiRequestDone(SYNC_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES))
        if (multilevel_sensors.length === 1) {
          dispatch(saveMultilevelSensorType(multilevel_sensors[0].sensor_type))
          dispatch(saveMultilevelSensorTypeDescription(multilevel_sensors[0].title))
          dispatch(saveMultilevelSensorScales(multilevel_sensors[0].scales))
          history.push(SELECT_MULTILEVEL_SENSOR_VALUE)
        } else {
          history.push(SELECT_MULTILEVEL_SENSOR_TYPE)
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchMultilevelSensorsTypes failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchZWaveMultilevelSensorTypesLogic
