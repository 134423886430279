import React from 'react'
import {
  Button, Paper, Slider, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { createMultilevelSwitchStateDescription } from '@app/helpers/scenes/createMultilevelSwitchStateDescription'
import {
  DEFAULT_VALUE, marks, MAX, MIN,
} from '@app/lib/Scenes/multilevelConditionsState'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
} from '@app/constants/routes'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'

class SelectMultilevelSwitchConditionsState extends React.PureComponent {
  state ={
    currentValue: 50,
  }

  onBackClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
  }

  getValueTextHandler = (value) => `${value}%`

  getStateDescriptionValue = (value) => {
    switch (value) {
    case 0:
      return 'is off'
    case 1:
      return 'is on'
    default:
      return createMultilevelSwitchStateDescription(value)
    }
  }

  onAddButtonClick=() => {
    const { currentValue } = this.state
    const {
      saveConditionStateDescription,
      conditionDeviceNumber,
      saveConditionDeviceStateInfo,
      navigate,
      isEditConditionMode,
    } = this.props
    saveConditionStateDescription(this.getStateDescriptionValue(currentValue))
    saveConditionDeviceStateInfo({
      type: 'Device',
      data: {
        type: 2,
        value: currentValue,
        number: conditionDeviceNumber,
      },
    })
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const { currentValue } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <Wrapper className={classNames(classes.switchContainer, isMobile && classes.switchContainerMobile)}>
              <Wrapper className={classes.containerMisc}>
                <Typography className={classes.miscTitle}>
                  {I18n.t('scene_condition_web.value_greater_than')}
                  {` ${currentValue}%`}
                </Typography>
              </Wrapper>
              <Slider
                className={classes.slider}
                defaultValue={DEFAULT_VALUE}
                min={MIN}
                max={MAX}
                getAriaValueText={this.getValueTextHandler}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay={isMobile ? 'off' : 'auto'}
                marks={marks}
                onChange={(evt, value) => {
                  this.setState({ currentValue: value })
                }}
              />
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddButtonClick}
          >
            {' '}
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultilevelSwitchConditionsState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
}

SelectMultilevelSwitchConditionsState.displayName = 'SelectMultilevelSwitchConditionsStateIOS'

export default SelectMultilevelSwitchConditionsState
