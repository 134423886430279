import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, IconButton, Typography } from '@mui/material'
import classNames from 'classnames'
import {
  closePopups,
  setFormInitialValues,
  toggleAvatarPopup,
  toggleFormPopup,
  toggleSummaryPopup,
  updateAvatar,
} from '@app/actions/contacts/contacts'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { Add } from 'icons'
import { withStyles } from '@app/components/styles'
import UserSummaryPopup from '../ContactsPopups/UserSummaryPopup/UserSummaryPopup'
import AvatarPopup from '../../Users/UserEdit/AvatarPopup/AvatarPopup'
import UserInfoForm from '../ContactsPopups/UserInfoFormPopup/UserInfoForm'

const ContactsList = (props) => {
  const { classes } = props
  const {
    contactsList,
    isSummaryPopupOpen,
    isFormPopupOpen,
    isAvatarPopupOpen,
    formInitialValues,
  } = useSelector((state) => state.contacts)
  const dispatch = useDispatch()
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const [avatarData, setAvatarData] = useState(null)
  const [currentContactId, setCurrentContactId] = useState(-1)

  useEffect(() => () => {
    dispatch(closePopups())
  }, [])

  const EnhancedAvatarPopup = withStyles(AvatarPopup)

  const summaryPopupHandler = (index) => {
    if (index || index === 0) {
      dispatch(setFormInitialValues({
        index,
        preferred_name: contactsList[index].preferred_name,
        phone_number: contactsList[index].phone_number,
        email: contactsList[index].email,
        address: contactsList[index].address,
      }))
      setCurrentContactId(contactsList[index].id)
    } else {
      setCurrentContactId(-1)
      dispatch(setFormInitialValues(null))
    }
    dispatch(toggleSummaryPopup())
  }

  const contactInfoPopupHandler = () => {
    if (isSummaryPopupOpen) {
      dispatch(toggleSummaryPopup(false))
    } else {
      dispatch(toggleSummaryPopup(true))
    }
    dispatch(toggleFormPopup())
  }

  const toggleContactPopupWithoutSetback = () => dispatch(toggleFormPopup())

  const avatarPopupHandler = () => {
    if (isSummaryPopupOpen) {
      dispatch(toggleSummaryPopup(false))
    } else {
      dispatch(toggleSummaryPopup(true))
    }
    dispatch(toggleAvatarPopup())
  }

  return (
    <Wrapper className={classes.root}>
      <Wrapper className={classes.main}>
        {contactsList.length ? contactsList.map((contact, index) => (
          <Wrapper key={contact.id} className={classNames(classes.contactsCard, isMobile && classes.cardMobile)}>
            <Wrapper className={classes.name}>
              <Avatar
                src={contact.avatar}
                alt={contact.preferred_name}
                className={classes.avatar}
              />
              {contact.preferred_name}
            </Wrapper>
            <IconButton
              className={classes.editBackgroundButton}
              onClick={() => summaryPopupHandler(index)}
              size="large"
            >
              <Wrapper className={classes.editButton}>...</Wrapper>
            </IconButton>
            {isSummaryPopupOpen && formInitialValues.index === index && (
              <UserSummaryPopup
                close={() => summaryPopupHandler()}
                index={index}
                openAvatarPopup={avatarPopupHandler}
                editContact={contactInfoPopupHandler}
              />
            )}
          </Wrapper>
        )) : <Typography className={classes.noContacts}>{I18n.t('contacts_web.no_contacts')}</Typography>}
      </Wrapper>
      <IconButton
        className={classes.addButton}
        onClick={toggleContactPopupWithoutSetback}
        size="large"
      >
        <Add />
      </IconButton>
      {isAvatarPopupOpen && (
        <EnhancedAvatarPopup
          avatarData={avatarData}
          fileChoosen={!!avatarData}
          chooseAvatarFile={(data) => setAvatarData(data)}
          cleanAvatarFile={() => setAvatarData(null)}
          submit={() => {
            dispatch(updateAvatar(currentContactId, avatarData))
            setAvatarData(null)
            avatarPopupHandler()
          }}
          closePopup={avatarPopupHandler}
          isMobile={isMobile}
        />
      )}
      {isFormPopupOpen && (
        <UserInfoForm
          initialValues={formInitialValues}
          isNewContact={!formInitialValues}
          currentContactId={currentContactId}
          closeEditing={contactInfoPopupHandler}
          closeNew={toggleContactPopupWithoutSetback}
        />
      )}
    </Wrapper>
  )
}

ContactsList.propTypes = {
  classes: PropTypes.object.isRequired,
}

ContactsList.displayName = 'ContactsList'

export default withStyles(ContactsList)
