import React from 'react'
import classNames from 'classnames'
import {
  Button, Checkbox, FormControlLabel, FormGroup, Paper, Typography,
} from '@mui/material'
import convertGeofenceUsersToBackend from '@app/helpers/scenes/convertGeofenceUsersToBackend'
import { SELECT_GEOFENCES_CONDITION, SELECT_GEOFENCES_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectGeofencesUser extends React.PureComponent {
  state = {
    initialUsers: [],
    users: [],
  }

  componentDidMount() {
    const { syncGeofenceUsers } = this.props
    syncGeofenceUsers()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.users !== state.initialUsers) {
      return {
        initialUsers: props.users,
        users: props.users,
      }
    }
    return null
  }

  handleChange = (index) => {
    const { users } = this.state
    let checkedUsers = [...users]
    checkedUsers = checkedUsers.map((user, i) => (i === index ? { ...user, checked: !user.checked } : { ...user }))
    this.setState(() => ({
      users: checkedUsers,
    }))
  }

  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_GEOFENCES_CONDITION)
  }

  onNextClick = () => {
    const { saveConditionUsersInfo, navigate } = this.props
    const { users } = this.state
    const selectedUsers = users.filter((user) => user.checked)
    saveConditionUsersInfo(convertGeofenceUsersToBackend(selectedUsers))
    navigate(SELECT_GEOFENCES_STATE)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const { users } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.my_users')}</Typography>
          <Typography className={classes.subTitle}>{I18n.t('scene_web.for_whom')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <FormGroup>
            {users.map((user, index) => (
              <FormControlLabel
                className={
                  classes.checkbox
                }
                control={(
                  <Checkbox
                    checked={user.checked}
                    onChange={() => this.handleChange(index)}
                    value="user"
                    color="primary"
                    classes={{ root: classes.checkboxIcon }}
                  />
                )}
                label={`${user.preferredName}`}
                labelPlacement="start"
                key={`${index + 1}`}
              />
            ))}
          </FormGroup>
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={this.onNextClick}
          disabled={!users.find((user) => user.checked)}
        >
          {I18n.t('buttons.next')}
        </Button>
      </Paper>
    )
  }
}

SelectGeofencesUser.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  syncGeofenceUsers: PropTypes.func.isRequired,
  saveConditionUsersInfo: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
  })).isRequired,
}

SelectGeofencesUser.displayName = 'SelectGeofencesUser'

export default SelectGeofencesUser
