import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/v4/users`

const findByMobileToken = (accessToken) => Axios.get(`${ENDPOINT}/sign_in_via_token`, {
  headers: { 'Access-Token': accessToken },
})

export default {
  findByMobileToken,
}
