export const actionTimersValues = [
  {
    title: 'turn_on',
    description: 'turn_on',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'StartTimer',
  },
  {
    title: 'turn_off',
    description: 'turn_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'StopTimer',
  },
]
