import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import { ADD_SCENE } from '@app/constants/scenes/createScene'
import {
  selectCurrentSceneId,
  saveCurrentSceneName,
  saveCurrentSceneStatus,
} from '@app/actions/scenes/scenesList'
import { selectSceneInitialId } from '@app/actions/scenes/editScene'
import Api from '@app/api/scenes/addScene'
import handleErrors from '@app/helpers/handleErrors'
import { SELECT_CONDITION_TYPE } from '@app/constants/routes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { isIOS } from 'react-device-detect'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const addScenesLogic = createLogic({
  type: ADD_SCENE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      token,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(ADD_SCENE))
    Api.addScene(action.token)
      .then((response) => {
        const { data: { scene: { id } } } = response
        const { data: { scene: { name } } } = response
        dispatch(apiRequestDone(ADD_SCENE))
        dispatch(selectCurrentSceneId(id))
        dispatch(selectSceneInitialId(id))
        dispatch(saveCurrentSceneName(name))
        dispatch(saveCurrentSceneStatus('incomplete'))
        if (isIOS && isWebView) {
          // eslint-disable-next-line no-alert
          prompt('hideTopBar')
        }
        history.push(SELECT_CONDITION_TYPE)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('addScene failed without error')
        }
      })
      .then(() => done())
  },
})

export default addScenesLogic
