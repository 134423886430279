import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  input: {
    maxWidth: '100%',
    marginLeft: '6px',
  },
  text: {
    color: '#f44336',
    marginLeft: 14,
  },
  icon: {
    fontSize: 15,
  },
  errorState: {
    color: '#f44336',
  },
}))

export default useStyles
