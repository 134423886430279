import { AUTH_ENDPOINT, TEMPORARY_AUTH_ENDPOINT, userCredentials } from '@app/constants/stats'
import { API_URL } from '@app/constants/endpoints'
import { statsAxiosInstance } from './statsAxiosInstance'

export const loginRequest = (username: string, password: string): { data: any } => statsAxiosInstance.post(AUTH_ENDPOINT, {
  username,
  password,
  ...userCredentials,
})

export const temporaryLoginRequest = (username: string, password: string): { data: any } => statsAxiosInstance.post(TEMPORARY_AUTH_ENDPOINT, {
  username,
  password,
  ...userCredentials,
})

export const onlineStatsRequest = (from_date: string, to_date: string): { data: any } => statsAxiosInstance.get(
  `${API_URL}/api/stats/total`,
  {
    params: {
      from_date,
      to_date,
    },
  },
)

export const stateListRequest = (newPage: number, per: number, serial_number: string, ssid: string): { data: any } => statsAxiosInstance.get(
  `${API_URL}/api/stats`,
  {
    params: {
      page: newPage,
      per,
      'filters[serial_number]': serial_number || null,
      'filters[ssid]': ssid || null,
    },
  },
)

export const statusLineRequest = (from_date: string, to_date: string, serial_number: string): { data: any } => statsAxiosInstance.get(
  `${API_URL}/api/stats/status_line`,
  {
    params: {
      from_date,
      to_date,
      serial_number,
    },
  },
)

export const lastReportRequest = (serial_number: string): { data: any } => statsAxiosInstance.get(
  `${API_URL}/api/stats/last_report`,
  {
    params: {
      serial_number,
    },
  },
)
