import React from 'react'
import PropTypes from 'prop-types'
import {
  ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material'
import { EnergyBillingIcon } from 'icons'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import getDate from '@app/helpers/myEnergy/getDate'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import styles from './EnergyBillingPeriod.style'

class EnergyBillingPeriod extends React.PureComponent {
  render() {
    const {
      classes,
      billingPeriod,
      isMobile,
    } = this.props
    const periodFrom = getDate(billingPeriod.from, 'short')
    const periodTo = getDate(billingPeriod.to, 'short')
    const { energy } = billingPeriod
    return (
      <ListItem className={classes.listItem}>
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classNames(
            classes.title,
            !isMobile && classes.titleDesktop,
          )}
          >
            {I18n.t('my_energy.energy_billing_period')}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.infoWrapper}>
          <ListItemIcon>
            <EnergyBillingIcon className={classNames(
              classes.icon,
              !isMobile && classes.iconDesktop,
            )}
            />
          </ListItemIcon>
          <ListItemText
            className={classes.info}
            primary={(
              <>
                <Typography
                  component="span"
                  className={classes.infoTime}
                  color="textPrimary"
                >
                  {periodFrom === ''
                    ? <Typography>{I18n.t('my_energy.period_not_set')}</Typography>
                    : `${periodFrom} - ${periodTo}`}
                </Typography>
              </>
            )}
            secondary={(
              <>
                <Typography
                  component="span"
                  className={classNames(
                    classes.infoNumbers,
                    !isMobile && classes.infoNumbersDesktop,
                  )}
                  color="textPrimary"
                >
                  {periodFrom === ''
                    ? `${I18n.t('my_energy.for_current_day')}: 
                    ${I18n.t('my_energy.not_available')} ${I18n.t('my_energy.power_kwh')}`
                    : `${I18n.t('my_energy.for_current_day')}: ${energy} ${I18n.t('my_energy.power_kwh')}`}
                </Typography>
              </>
            )}
          />
        </Wrapper>
      </ListItem>
    )
  }
}

EnergyBillingPeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  billingPeriod: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    billing: PropTypes.number.isRequired,
    energy: PropTypes.number.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default withStyles(styles)(EnergyBillingPeriod)
