import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.19114 4.29911V6.02679H4.07202V4.29911C4.07202 3.57589 4.31764 2.95982 4.80886 2.45089C5.32595 1.91518 5.93352 1.64732 6.63158 1.64732C7.32964 1.64732 7.92428 1.91518 8.41551 2.45089C8.93259 2.95982 9.19114 3.57589 9.19114 4.29911ZM12.759 6.54911C12.4229 6.20089 12.0351 6.02679 11.5956 6.02679H10.7812V4.29911C10.7812 3.12054 10.3675 2.11607 9.54017 1.28571C8.73869 0.428571 7.76916 0 6.63158 0C5.494 0 4.51154 0.428571 3.68421 1.28571C2.88273 2.11607 2.48199 3.12054 2.48199 4.29911V6.02679H1.66759C1.22807 6.02679 0.840259 6.20089 0.504155 6.54911C0.168052 6.87054 0 7.25893 0 7.71429V16.3125C0 16.7679 0.168052 17.1696 0.504155 17.5179C0.840259 17.8393 1.22807 18 1.66759 18H11.5956C12.0351 18 12.4229 17.8393 12.759 17.5179C13.0951 17.1696 13.2632 16.7679 13.2632 16.3125V7.71429C13.2632 7.25893 13.0951 6.87054 12.759 6.54911ZM9.25361 10.7168C8.98318 9.81115 8.43303 9.15166 7.60317 8.73836C7.06236 8.46902 6.4998 8.36018 5.91549 8.41184C6.23564 8.91395 6.40417 9.47517 6.42107 10.0955C6.45217 10.7106 6.3261 11.3026 6.04285 11.8713C5.7596 12.4401 5.36068 12.9022 4.8461 13.2579C4.34572 13.6083 3.79872 13.8071 3.20509 13.8541C3.51591 14.3515 3.94173 14.7349 4.48254 15.0043C5.3124 15.4176 6.16777 15.4642 7.04866 15.1442C7.94376 14.8189 8.60619 14.2249 9.03594 13.362C9.4657 12.4991 9.53826 11.6174 9.25361 10.7168Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'NightArmedIcon')

export default icon
