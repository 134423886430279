import * as type from '@app/constants/myDevices/channels/twr/twrChannels'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

export const syncTwrChannel = (newDevice: IZwaveOrTwrDevice) => ({
  type: type.SYNC_TWR_CHANNEL,
  newDevice,
} as const)

export const syncTwrChannelSent = () => ({
  type: type.SYNC_TWR_CHANNEL_SENT,
} as const)

export const openTwrChannel = () => ({
  type: type.OPEN_TWR_CHANNEL,
} as const)

export const closeTwrChannel = () => ({
  type: type.CLOSE_TWR_CHANNEL,
} as const)

export const setResponseFromTwrChannel = (channelResponse: string) => ({
  type: type.SET_RESPONSE_FROM_TWR_CHANNEL,
  channelResponse,
} as const)
