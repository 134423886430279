import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ROOMS_SCENES } from '@app/constants/scenes/roomsScenes/roomsScenes'
import { updateRoomsScenes } from '@app/actions/scenes/roomsScenes/roomsScenes'
import Api from '@app/api/scenes/roomsScenes/fetchRoomsScenes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchRooms = createLogic({
  type: SYNC_ROOMS_SCENES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ROOMS_SCENES))
    Api.fetchRoomsScenes(AccessToken)
      .then((response) => {
        dispatch(updateRoomsScenes(response.data.rooms))
        dispatch(apiRequestDone(SYNC_ROOMS_SCENES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchRoomsScenes failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchRooms
