export const resetDays = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
}

export const Weekdays = {
  type: 'Weekdays',
  description: "it's a weekday",
  days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
  state: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  },
}

export const Weekends = {
  type: 'Weekends',
  description: "it's a weekend",
  days: ['saturday', 'sunday'],
  state: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: true,
    sunday: true,
  },
}

export const fullWeek = {
  type: 'fullWeek',
  description: ' full week',
  days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  state: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  },
}
