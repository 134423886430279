import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="100" height="100" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12.6802" y="24.3255" width="1.31231" height="1.1306" stroke="currentColor"/>
      <rect x="2.43079" y="24.3255" width="1.31231" height="1.1306" stroke="currentColor"/>
      <rect x="1.26428" y="1" width="13.8978" height="23.3255" rx="1.4" stroke="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.42371 16.9957V1H6.9935V16.9957C6.9935 17.6853 7.53752 18.2444 8.2086 18.2444C8.87969 18.2444 9.42371 17.6853 9.42371 16.9957V16.9957Z" stroke="currentColor"/>
      <rect x="1" y="25.184" width="14.56" height="1.1712" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6515 1.71641L26.8753 9.21445C27.2354 9.86929 26.777 10.6551 25.9912 10.6879H17.5764C16.8233 10.6879 16.3649 9.86929 16.7251 9.21445L20.9489 1.71641C21.309 1.06157 22.2913 1.06157 22.6515 1.71641ZM26.0239 9.70562L21.8002 2.20758L17.5764 9.70562H26.0239Z" fill="#D0021B"/>
      <path d="M21.1126 5.64552L21.3746 7.38087C21.4073 7.57731 21.571 7.74102 21.8002 7.74102C21.9967 7.74102 22.1931 7.57731 22.2259 7.38087L22.4878 5.64552C22.5533 5.21987 22.2259 4.85971 21.8002 4.85971C21.3745 4.85969 21.0799 5.21987 21.1126 5.64552Z" fill="currentColor"/>
      <path d="M21.8002 8.88703C22.0172 8.88703 22.1931 8.71112 22.1931 8.49411C22.1931 8.27711 22.0172 8.1012 21.8002 8.1012C21.5832 8.1012 21.4073 8.27711 21.4073 8.49411C21.4073 8.71112 21.5832 8.88703 21.8002 8.88703Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'mezzoErrorsHelp')

export default icon