import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ZwaveSecurityOptions from
  // eslint-disable-next-line max-len
  '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/SecurityOptions/ZwaveSecurityOptions/ZwaveSecurityOptions'
import { withAccessDenied, withNotifications } from '@app/hocs'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = ZwaveSecurityOptions

const mapStateToProps = (state: AppState) => {
  const { currentDevice, devices } = state.myDevices.myDevices
  const { isMobile } = state.mobileDetector
  return {
    currentDevice,
    devices,
    isMobile,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ZwaveSecurityOptionsEnhanced = enhance

export default ZwaveSecurityOptionsEnhanced
