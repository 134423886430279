import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 95 130" version="1.1">
            <title>iconDevicePIR</title>
            <desc>Created with Sketch.</desc>
            <defs>
            </defs>
            <g id="Map" stroke="none" strokeWidth="5" fill="none" fillRule="evenodd">
                <g id="iconDevicePIR" transform="translate(-17.000000, 0.000000)">
                    <g id="iconDeviceMotionSensorVolumetric" transform="translate(17.253086, 0.802469)">
                        <polygon id="Shape" fill="currentColor"
                                 points="81.6833333 114.640741 81.2740741 114.841358 48.9265432 59.7117284 49.3358025 59.5111111">
                        </polygon>
                        <rect id="Rectangle-path" stroke="currentColor" x="20.3987654" y="62.3117284" width="54.0222222"
                              height="38.1654321">
                        </rect>
                        <rect id="Rectangle-path" fill="currentColor" x="47.1851852" y="60.0567901" width="1"
                              height="68.5148148">
                        </rect>
                        <polygon id="Shape" fill="currentColor"
                                 points="12.3419753 116.245679 12.7512346 116.446296 45.9012346 59.7117284 45.5 59.5111111">
                        </polygon>
                        <polygon id="Shape" fill="currentColor"
                                 points="0.52962963 91.1364198 0.77037037 91.5216049 43.237037 59.1981481 43.004321 58.8209877">
                        </polygon>
                        <path
                            d="M52.4012346,56.5580247 L52.4012346,42.3623457 C49.2054805,41.0517926 45.6222973,41.0517926 42.4265432,42.3623457 L42.4265432,56.5660494 C45.7275776,57.1321206 49.1011153,57.1294066 52.4012346,56.5580247 L52.4012346,56.5580247 Z"
                            id="Shape" stroke="currentColor">
                        </path>
                        <path
                            d="M10.062963,4.26111111 L10.062963,107.683333 C34.7284588,112.550329 60.1073436,112.550329 84.7728395,107.683333 L84.7728395,4.26111111 C60.1932227,-1.41712176 34.6425798,-1.41712176 10.062963,4.26111111 L10.062963,4.26111111 Z"
                            id="Shape" stroke="currentColor">
                        </path>
                        <polygon id="Shape" fill="currentColor"
                                 points="94.2981481 91.1364198 94.0574074 91.5216049 51.5907407 59.1981481 51.8234568 58.8209877">
                        </polygon>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwPIRIcon')

export default icon
