const styles = () => ({
  root: {
    width: 200,
  },
  input: {
    width: '100%',
    height: 'auto',
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
    boxSizing: 'border-box',
    padding: 10,
    cursor: 'pointer',
  },
  text: {
    marginTop: 10,
    fontSize: 14,
    textAlign: 'center',
  },
  redText: {
    marginTop: 10,
    color: 'red',
  },
  fileName: {
    fontSize: 14,
    wordWrap: 'break-word',
  },
  noFile: {
    textAlign: 'center',
  },
  dropTextWrapper: {
    width: '80%',
    height: 150,
    margin: '0 auto',
  },
  dropText: {
    textAlign: 'center',
    padding: 10,
    marginBottom: 30,
  },

})

export default styles
