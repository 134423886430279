import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
      <g fill="#A9CF39" clipPath="url(#a)">
        <path d="M7.5 7.52c0 .15.04.3.13.42l3.75 5.62c.13.2.36.34.62.34h2.25c.41 0 .75-.34.75-.75a.7.7 0 0 0-.13-.41L11.4 7.52l3.47-5.2A.7.7 0 0 0 15 1.9a.75.75 0 0 0-.75-.75H12a.74.74 0 0 0-.62.34L7.63 7.1a.7.7 0 0 0-.13.41Z"/>
        <path d="M0 7.52c0 .15.05.3.13.42l3.75 5.62c.13.2.36.34.62.34h2.25c.41 0 .75-.34.75-.75a.7.7 0 0 0-.13-.41L3.9 7.52l3.47-5.2a.7.7 0 0 0 .13-.42.75.75 0 0 0-.75-.75H4.5a.74.74 0 0 0-.62.34L.13 7.1a.7.7 0 0 0-.13.41Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M15 0H0v15h15z"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ChevronsOnIcon', '0 0 13 18', { fontSize: 18 })

export default icon
