/* eslint-disable class-methods-use-this */
class LocalStorageService {
  getToken() {
    const tokenJSON = localStorage.getItem('stats_access_token')
    let parsedToken
    if (tokenJSON) {
      parsedToken = JSON.parse(tokenJSON)
    }
    return parsedToken
  }

  setToken(token) {
    return localStorage.setItem('stats_access_token', JSON.stringify(token))
  }

  clearStorage() {
    localStorage.removeItem('stats_access_token')
  }
}

export default LocalStorageService
