import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import {
  Button, List, Paper, Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import {
  set, setCurrentOptions, setInitialOptions, setValidation,
} from '@app/actions/po/poConfig'
import useStyles from '@app/components/SecurityRules/SecurityRules.style'
import ProgramOption from '@app/components/POFromConfig/ProgramOption/ProgramOption'
import { IPOConfigFromBackend, IPOFromBackend } from '@app/@types/po/poConfig'
import { checkIfArraysOrObjectsAreEqual } from '@app/helpers/checkIfArraysOrObjectsAreEqual'

interface Props {
  initialOptions: Array<IPOFromBackend>,
  currentOptions: Array<IPOFromBackend>,
  defaultOptions: Array<IPOFromBackend>,
  items: Array<IPOConfigFromBackend>,
  isMobile: boolean,
  inProgress: boolean,
  validation: boolean,
}

const SecurityRules: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    initialOptions,
    currentOptions,
    defaultOptions,
    isMobile,
    inProgress,
    items,
    validation,
  } = props
  const [programOptions, setProgramOptions] = useState(initialOptions)

  useEffect(() => {
    dispatch(setCurrentOptions(programOptions))
  }, [programOptions])

  useEffect(() => {
    dispatch(setCurrentOptions(initialOptions))
    setProgramOptions(initialOptions)
  }, [initialOptions])

  const submitSecurityOptions = () => {
    dispatch(set(currentOptions))
    dispatch(setInitialOptions(currentOptions))
  }

  const resetSecurityOptions = () => {
    setProgramOptions(initialOptions)
    dispatch(setCurrentOptions(initialOptions))
  }

  const resetDefaultOptions = () => {
    dispatch(set(defaultOptions))
    dispatch(setInitialOptions(defaultOptions))
  }

  return (
    <Paper>
      {inProgress && (
        <Wrapper className={classes.preloader}>
          <Typography className={classes.preloaderMessage}>
            {I18n.t('program_options.fetching_data')}
            ...
          </Typography>
        </Wrapper>
      )}
      <form className={classNames(inProgress && classes.blur)}>
        <List
          component="nav"
          className={classNames(
            classes.root,
            (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
            && classes.webView,
          )}
        >
          {!inProgress && programOptions.slice(0, -2).map((programOption) => (
            <ProgramOption
              items={items}
              programOption={programOption}
              options={programOptions}
              setOptions={setProgramOptions}
              key={programOption.number}
              validationSetter={setValidation}
              inProgress={inProgress}
            />
          ))}
          <Wrapper className={classNames(classes.bottomWrapper, isMobile && classes.bottomWrapperMobile)}>
            {!inProgress && programOptions.slice(-2).map((programOption) => (
              <ProgramOption
                items={items}
                programOption={programOption}
                options={programOptions}
                setOptions={setProgramOptions}
                key={programOption.number}
                validationSetter={setValidation}
                inProgress={inProgress}
              />
            ))}
          </Wrapper>
        </List>
        <Wrapper className={classNames(
          classes.buttonsWrapper,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
        )}
        >
          <Wrapper className={classNames(
            classes.buttonsSecondWrapper,
            (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
            && classes.webView,
          )}
          >
            <Button
              onClick={resetSecurityOptions}
              disabled={checkIfArraysOrObjectsAreEqual(currentOptions, initialOptions)}
              variant="contained"
              className={classes.button}
            >
              {I18n.t('buttons.reset')}
            </Button>
            <Button
              disabled={checkIfArraysOrObjectsAreEqual(currentOptions, initialOptions) || !validation}
              variant="contained"
              className={classes.button}
              onClick={submitSecurityOptions}
            >
              {I18n.t('buttons.submit')}
            </Button>
          </Wrapper>
          <Button
            disabled={checkIfArraysOrObjectsAreEqual(initialOptions, defaultOptions)}
            onClick={resetDefaultOptions}
            variant="outlined"
            className={classes.resetButton}
          >
            {I18n.t('buttons.reset_to_default')}
          </Button>
        </Wrapper>
      </form>
    </Paper>
  )
}

export default SecurityRules
