import { createLogic } from 'redux-logic'
import Geocode from 'react-geocode'
import {
  POST_GEOFENCE,
} from '@app/constants/geofences/geofences'
import Api from '@app/api/geofences'
import handleErrors from '@app/helpers/handleErrors'
import { apiKey } from '@app/lib/Scenes/geofences'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { setPostGeofence } from '@app/actions/geofences/geofences'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const postGeofenceLogic = createLogic({
  type: POST_GEOFENCE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_GEOFENCE))
    Api.postGeofence(action.accessToken, action.data)
      .then((response) => {
        Geocode.setApiKey(apiKey)
        Geocode.fromLatLng(response.data.geofence.latitude, response.data.geofence.longitude)
          .then((res) => {
            const newGeofence = {
              ...response.data.geofence,
              address: res.results[0].formatted_address,
              expanded: false,
            }
            store.dispatch(setPostGeofence(newGeofence))
            store.dispatch(apiRequestDone(POST_GEOFENCE))
          })
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('postGeofence failed without error')
        }
      })
      .then(() => done())
  },
})

export default postGeofenceLogic
