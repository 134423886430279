import React from 'react'
import { Typography, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { VIRTUAL_SWITCHES } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft, SortIcon, VirtualSwitchIcon } from 'icons'
import useStyles from './VirtualSwitchesCard.style'

interface Props {
  isDragging: boolean,
  isMobile: boolean,
}

const VirtualSwitchesCard: React.FC<Props> = (props) => {
  const { isDragging, isMobile } = props
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile, isDragging && classes.dragging)}>
      <Wrapper className={classNames(classes.cardInfo, isMobile && classes.cardInfoMobile)}>
        <Wrapper className={classes.cardIconContainer}>
          <VirtualSwitchIcon />
        </Wrapper>
        <Wrapper className={isMobile ? classes.nameAndStatus : null}>
          <Typography className={classes.cardName}>
            {`${I18n.t('virtual_switches.virtual_switches')}`}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.controlWrapper}>
          <IconButton
            size="small"
            className={classes.chevronIcon}
            onClick={() => navigate(VIRTUAL_SWITCHES)}
          >
            {isDragging ? <SortIcon /> : <ChevronLeft />}
          </IconButton>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default VirtualSwitchesCard
