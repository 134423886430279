import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: 50,
    margin: '0 auto',
    maxWidth: 300,
    position: 'relative',
    top: 5,
  },
  userCard: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    padding: '1% 2%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 80,
  },
  userTitle: {
    fontSize: 13,
    color: 'rgba(229, 229, 229, 0.67)',
  },
  userSubHeader: {
    fontSize: 18,
    color: theme.texts.main,
  },
  textFieldWrapper: {
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    paddingTop: 15,
    minHeight: 100,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
  formWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 231px)',
    position: 'relative',
    justifyContent: 'space-between',
  },
}))

export default useStyles
