export const GET_TWR_ALARM_OPTIONS = '@@myDevices/getTwrAlarmOptions'
export const SET_TWR_ALARM_OPTIONS = '@@myDevices/setTwrAlarmOptions'

export const ADD_NEW_TWR_DEVICE = '@@myDevices/addNewTwrDevice'
export const SET_NEW_TWR_DEVICE = '@@myDevices/setNewTwrDevice'
export const PATCH_NEW_TWR_DEVICE = '@@myDevices/patchNewTwrDevice'

export const PATCH_TWR_DEVICE = '@@myDevices/patchTwrDevice'

export const DELETE_TWR_DEVICE = '@@myDevices/deleteTwrDevice'

export const GET_TWR_SIREN_OPTION = '@@myDevices/getTwrSirenOption'
export const SAVE_TWR_CURRENT_SIREN_OPTION = '@@myDevices/saveTwrCurrentSirenOption'
export const PATCH_TWR_SIREN_OPTIONS = '@@myDevices/patchTwrSirenOptions'

export const GET_TAKE_ON_BOARD_DEVICE_TYPE = '@@myDevices/getTakeOnBoardDeviceType'
export const SAVE_TAKE_ON_BOARD_DEVICE_TYPE = '@@myDevices/saveTakeOnBoardDeviceType'
export const PATCH_TAKE_ON_BOARD_DEVICE_TYPE = '@@myDevices/patchTakeOnBoardDeviceType'

export const FETCH_TWR_DEVICE_INFO = '@@myDevices/fetchTwrDeviceInfo'
export const SET_TWR_DEVICE_INFO = '@@myDevices/setTwrDeviceInfo'
export const UPDATE_TWR_DEVICE_INFO = '@@myDevices/updateTwrDeviceInfo'

export const START_FETCH_DEVICE_STATS = '@@myDevices/startFetchingDeviceStats'
export const END_FETCH_DEVICE_STATS = '@@myDevices/endFetchingDeviceStats'

export const GET_TWR_HEALTH = '@@myDevices/getTwrHealth'
