const styles = () => ({
  listItem: {
    padding: '15px 30px',
    width: '100%',
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roomName: {
    display: 'block',
    fontSize: '20px',
    paddingRight: 10,
  },
  roomPeriod: {
    display: 'block',
    fontSize: '14px',
    opacity: 0.5,
  },
  roomStats: {
    display: 'block',
    fontSize: '18px',
  },
  buttonStatWrapper: {
    position: 'absolute',
    bottom: 10,
    right: 16,
  },
  buttonStat: {
    textTransform: 'none',
    lineHeight: '16px',
    textDecoration: 'underline',
    padding: 0,
  },
  buttonStatDesktop: {
    lineHeight: '24px',
    fontSize: '16px',
    padding: 6,
  },
})

export default styles
