import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveSensorNotificationsEventType,
  saveSensorNotificationsEventDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectEventType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/NotificationSensors/SelectEventType/SelectEventType'
import SelectEventTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/NotificationSensors/SelectEventType/SelectEventType'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectEventTypeIOS) : withStyles(SelectEventType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const notificationSensors = state.scenes.sensorNotifications.devices
  const { sensorType } = state.scenes.currentSceneCreating.currentCondition
  const { isChangeSensorStateMode } = state.scenes.currentSceneCreating
  const events = notificationSensors.find((sensor) => sensor.notification_type === sensorType).event_types
  return {
    isMobile,
    events,
    isChangeSensorStateMode,
    notificationSensors,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveSensorNotificationsEventType,
  saveSensorNotificationsEventDescription,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectEventTypeEnhanced = enhance

export default withRouter(SelectEventTypeEnhanced)
