import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { syncTimersForScenes } from '@app/actions/scenes/timers/timersForScenes'
import {
  clearTriggeringAndObservingDevices,
  saveConditionDescription,
  saveConditionDeviceStateInfo,
  saveConditionStateDescription,
} from '@app/actions/scenes/currentSceneCreating'
import {
  withAccessDenied, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import SelectTimer
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/SelectTimer/SelectTimer'
import SelectTimerIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/SelectTimer/SelectTimer'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTimerIOS) : withStyles(SelectTimer)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { timers } = state.timers
  return {
    isMobile,
    timers,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveConditionDeviceStateInfo,
  saveConditionDescription,
  saveConditionStateDescription,
  clearTriggeringAndObservingDevices,
  refresh: syncTimersForScenes,
  tryAgain: syncTimersForScenes,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTimerEnhanced = enhance

export default withRouter(SelectTimerEnhanced)
