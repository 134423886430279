import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getDelayDurationDescription from '@app/helpers/scenes/getDelayDurationDescription'
import { SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED } from '@app/constants/routes'
import Counter from '@app/containers/MyAutomation/Counter'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class AdjustDelay extends React.Component {
  onAddStateClick=() => {
    const {
      counterInfo,
      saveActionDelay,
      resetCounter,
      navigate,
      saveActionDelayDescription,
    } = this.props
    saveActionDelay(counterInfo)
    saveActionDelayDescription(getDelayDurationDescription(counterInfo))
    resetCounter()
    navigate(SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED)
  }

  onBackClick =() => {
    const {
      navigate,
      resetCounter,
    } = this.props
    navigate(SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED)
    resetCounter()
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const {
      counterInfo: {
        day,
        hour,
        minute,
        second,
      },
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.adjust_delay')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Counter title="delay" />
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={this.onAddStateClick}
          disabled={!(day || hour || minute || second)}
        >
          {I18n.t('buttons.next')}
        </Button>
      </Paper>
    )
  }
}

AdjustDelay.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  counterInfo: PropTypes.shape({
    day: PropTypes.number.isRequired,
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    second: PropTypes.number.isRequired,
  }).isRequired,
  resetCounter: PropTypes.func.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
}

AdjustDelay.displayName = 'AdjustDelay'

export default AdjustDelay
