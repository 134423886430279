import PropTypes from 'prop-types'

const propTypes = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number,
  user_code: PropTypes.string,
  preferred_name: PropTypes.string,
  phone_number: PropTypes.string,
  user_type: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  middle_name: PropTypes.string,
  unit_user_identifier: PropTypes.number,
  avatar: PropTypes.string,
}))

export default propTypes
