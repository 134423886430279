import { createLogic } from 'redux-logic'
import { CLOSE_MEZZO_LIGHTS_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { MEZZO_LIGHTS_CHANNEL } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeMezzoLightsLogic = createLogic({
  type: CLOSE_MEZZO_LIGHTS_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(MEZZO_LIGHTS_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeMezzoLightsLogic
