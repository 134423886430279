import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M82.2512 121.87H78.463L44.9031 76.2062C45.9113 74.2118 46.4928 71.9641 46.4928 69.582C46.4928 69.3524 46.469 69.1297 46.4571 68.9036L87.959 42.0053C85.298 48.1586 85.1263 55.3354 87.9879 61.9002L89.1287 64.5169L132.683 39.3716L130.988 37.0746C126.305 30.7343 118.778 26.9512 110.85 26.9512C110.826 26.9512 110.804 26.9529 110.778 26.9529C110.659 26.6655 110.521 26.3816 110.362 26.1061L102.207 11.9803C100.789 9.52514 98.1385 8 95.2905 8C93.8997 8 92.5259 8.36726 91.3204 9.06267L83.0367 13.8455C79.2315 16.044 77.924 20.9288 80.1207 24.7323L81.6969 27.4596L37.5885 56.0495C35.7965 55.2725 33.8207 54.8373 31.7464 54.8373C23.6157 54.8373 17 61.453 17 69.582C17 77.6174 23.4695 84.1635 31.4727 84.3131L59.0765 121.872H58.8827C49.9733 121.872 42.725 129.119 42.725 138.028V141H98.4088V138.028C98.4088 129.117 91.1623 121.87 82.2512 121.87ZM91.7029 57.1394C89.3549 48.5531 93.0291 39.2849 100.961 34.7061C103.967 32.9701 107.386 32.052 110.848 32.052C116.188 32.052 121.298 34.2045 124.999 37.9145L91.7029 57.1394ZM85.5854 18.2611L93.8674 13.4783C94.2992 13.23 94.7906 13.0974 95.2888 13.0974C96.3209 13.0974 97.2798 13.6466 97.7899 14.529L105.329 27.5871C102.909 28.1397 100.57 29.0408 98.3986 30.2956C95.9944 31.683 93.9235 33.4309 92.1756 35.4117L84.538 22.1819C83.7457 20.8115 84.2167 19.0534 85.5854 18.2611ZM86.9575 36.5747L45.2227 63.6242C44.4797 61.9529 43.4443 60.4379 42.1674 59.161L84.249 31.8837L86.9575 36.5747ZM22.1008 69.582C22.1008 64.2635 26.428 59.9381 31.7464 59.9381C37.0649 59.9381 41.3903 64.2635 41.3903 69.582C41.3903 74.9004 37.0649 79.2242 31.7464 79.2242C26.428 79.2242 22.1008 74.9004 22.1008 69.582ZM37.07 83.3167C38.7889 82.6485 40.3481 81.6658 41.6845 80.4416L72.1329 121.872H65.4066L37.07 83.3167ZM48.0316 135.897C49.0279 130.817 53.5149 126.971 58.8827 126.971H82.2512C87.6189 126.971 92.1059 130.817 93.1023 135.897H48.0316Z" fill="currentColor"/>
      <path d="M92.1603 29.8182C92.6329 30.6361 93.4899 31.0934 94.3706 31.0934C94.8042 31.0934 95.2429 30.9846 95.6441 30.7517C96.8649 30.0478 97.2815 28.4869 96.5776 27.2678L92.7163 20.5807C92.0106 19.3582 90.4515 18.9433 89.2324 19.6472C88.0116 20.3511 87.5951 21.912 88.299 23.1311L92.1603 29.8182Z" fill="currentColor"/>
      <path d="M100.877 40.0648C99.7467 39.2265 98.1502 39.4612 97.3085 40.5919C95.1968 43.4364 93.9692 46.4357 93.6632 49.5047C93.522 50.9057 94.5456 52.1571 95.9466 52.2965C96.0333 52.3067 96.12 52.3101 96.2033 52.3101C97.4973 52.3101 98.6058 51.329 98.7368 50.013C98.9544 47.8435 99.8266 45.7556 101.401 43.6336C102.244 42.5013 102.01 40.903 100.877 40.0648Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ReadingLamp')

export default icon
