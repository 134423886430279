import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/rooms/reset`

const resetCurrentRoomCounters = (accessToken, id) => Axios.post(ENDPOINT, null, {
  headers: { 'Access-Token': accessToken },
  params: {
    room_ids: [id],
  },
})

export default {
  resetCurrentRoomCounters,
}
