import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  SELECT_BINARY_SENSOR_STATE_SELECTED,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectBinarySensorType extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSensorMode,
      isEditConditionMode,
    } = this.props
    if (isEditConditionMode) {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    } else if (isChangeSensorMode) {
      navigate(SELECT_BINARY_SENSOR_STATE_SELECTED)
    } else {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    }
  }

  selectSensor=(sensor) => {
    const {
      saveBinarySensorType,
      syncZWaveDeviceBinarySensorValues,
      saveBinarySensorTypeDescription,
    } = this.props
    saveBinarySensorType(sensor.sensor_type)
    saveBinarySensorTypeDescription(sensor.title)
    syncZWaveDeviceBinarySensorValues()
  }

  renderSensorsList=(sensors) => (sensors.map((sensor) => (
    <CardBlock
      key={sensor.title}
      description={sensor.title.replace('sensor', '')}
      position="middle"
      buttonName={I18n.t('scene_web.add')}
      isCardDisabled={false}
      isButtonDisabled={false}
      onClick={() => {
        this.selectSensor(sensor)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
      sensors,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_sensor_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {sensors.length
            ? this.renderSensorsList(sensors)
            : <Typography className={classes.somethingWrong}>{I18n.t('errors.something_went_wrong')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectBinarySensorType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  saveBinarySensorType: PropTypes.func.isRequired,
  syncZWaveDeviceBinarySensorValues: PropTypes.func.isRequired,
  saveBinarySensorTypeDescription: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      sensor_type: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isChangeSensorMode: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
}

SelectBinarySensorType.displayName = 'SelectBinarySensorTypeIOS'

export default SelectBinarySensorType
