import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ClearIcon } from 'icons'

import styles from './UserInfoInput.style'

const UserInfoInput = (props) => {
  const {
    meta: { touched, error },
    classes,
    hint,
    input,
    resetField,
  } = props

  const createAdorment = () => (
    <InputAdornment position="start">
      <IconButton onClick={resetField} aria-label="Delete" size="large">
        <ClearIcon className={classes.icon} />
      </IconButton>
    </InputAdornment>
  )

  const getInputType = (name) => {
    switch (name) {
    case 'email': return 'email'
    default: return 'text'
    }
  }

  const createTextField = () => (
    <TextField
      variant="standard"
      type={getInputType(input.name)}
      onChange={(event, index, value) => input.onChange(value)}
      className={classes.innerInput}
      InputProps={{
        endAdornment: createAdorment(),
        ...input,
      }}
    />
  )

  return (
    <FormControl variant="standard" className={classes.input}>
      { hint
        ? <Tooltip title={hint}>{createTextField()}</Tooltip>
        : createTextField() }
      {touched && error && (
        <FormHelperText id="component-error-text" className={classes.text}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

UserInfoInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  resetField: PropTypes.func.isRequired,
  hint: PropTypes.string.isRequired,
}

export default (withStyles(styles)(UserInfoInput))
