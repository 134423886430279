import * as type from '@app/constants/myDevices/twrDevices'
import { IZwaveOrTwrDevice, ITwrPatchData } from '@app/@types/myDevices'

export const addTwrDevice = (txid: string) => ({
  type: type.ADD_NEW_TWR_DEVICE,
  txid,
} as const)

export const setNewTwrDevice = (newTwrDevice: IZwaveOrTwrDevice) => ({
  type: type.SET_NEW_TWR_DEVICE,
  newTwrDevice,
} as const)

export const patchNewTwrDevice = (data: Partial<ITwrPatchData>) => ({
  type: type.PATCH_NEW_TWR_DEVICE,
  data,
} as const)

export const patchTwrDevice = (data: Partial<ITwrPatchData>, id?: number) => ({
  type: type.PATCH_TWR_DEVICE,
  data,
  id,
} as const)

export const getTwrAlarmOptions = (id: number) => ({
  type: type.GET_TWR_ALARM_OPTIONS,
  id,
} as const)

export const setTwrAlarmOptions = (options: Array<string>) => ({
  type: type.SET_TWR_ALARM_OPTIONS,
  options,
} as const)

export const getTwrSirenOption = (id: number) => ({
  type: type.GET_TWR_SIREN_OPTION,
  id,
} as const)

export const saveTwrCurrentSirenOption = (option: number) => ({
  type: type.SAVE_TWR_CURRENT_SIREN_OPTION,
  option,
} as const)

export const patchTwrSirenOptions = (id: number) => ({
  type: type.PATCH_TWR_SIREN_OPTIONS,
  id,
} as const)

export const getTakeOnBoardDeviceType = (id: number) => ({
  type: type.GET_TAKE_ON_BOARD_DEVICE_TYPE,
  id,
} as const)

export const saveTakeOnBoardDeviceType = (currentTakeOnBoardDeviceType: number) => ({
  type: type.SAVE_TAKE_ON_BOARD_DEVICE_TYPE,
  currentTakeOnBoardDeviceType,
} as const)

export const patchTakeOnBoardDeviceType = (id: number) => ({
  type: type.PATCH_TAKE_ON_BOARD_DEVICE_TYPE,
  id,
} as const)
