export const GET_GUARD_PANEL_STATUS = '@@guardPanel/getGuardPanelStatus'

export const UPDATE_GUARD_PANEL_STATUS = '@@guardPanel/updateGuardPanelStatus'

export const SYNC_GUARD_PANEL_CHANNEL = '@@guardPanel/syncGuardPanelChannel'
export const SYNC_GUARD_PANEL_CHANNEL_SENT = '@@guardPanel/syncGuardPanelChannelSent'
export const OPEN_GUARD_PANEL_CHANNEL = '@@guardPanel/openChannel'
export const CLOSE_GUARD_PANEL_CHANNEL = '@@guardPanel/closeChannel'
export const UNSUBSCRIBE_GUARD_PANEL_CHANNEL = '@@guardPanel/unsubscribeGuardPanelChannel'

export const SET_GUARD_PANEL_STATUS = '@@guardPanel/setGuardPanelStatus'

export const SET_GUARD_PANEL_TIMEOUT = '@@guardPanel/setGuardPanelTimeout'
export const RESET_GUARD_PANEL_TIMEOUT = '@@guardPanel/resetGuardPanelTimeout'
export const RESET_GUARD_PANEL_PROGRESS = '@@guardPanel/resetGuardPanelProgress'

export const RESET_GUARD_PANEL_REDUCER = '@@guardPanel/reset'

export const SET_GUARD_PANEL_PROGRESS = '@@guardPanel/setGuardPanelProgress'
