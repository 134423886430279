import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg x="0" y="0" enableBackground="new 0 0 100 100" viewBox="0 0 90 112">
      <path d="M49.9 77.3h-.1c-1.2-.1-2.1-1.1-2.1-2.3l.2-3.2-.1-3.3.3-3.1-.6-3.2.3-3.3-.4-3.2V52l.4-3-.4-3.2.2-3.6.3-3-.6-3c-.2-1.2.6-2.3 1.8-2.6 1.2-.2 2.3.6 2.6 1.8l.7 3.6-.3 3.5-.2 3 .4 3.3-.4 3.4v3l.4 3.4-.3 3.3.6 3.3-.4 3.4v3.2l-.2 3.4c0 1.1-.9 2.1-2.1 2.1z"></path>
      <path d="M53.2 75.1L46.9 75 47 72 47 69.2 46.7 66 46.7 63 46.8 59.8 47 56.8 53.3 57.2 53.1 60.1 53 63 53 65.9 53.3 68.9 53.3 72.1z"></path>
      <path d="M26.1 86.8c-.7 0-1.3-.3-1.7-.9-.7-1-.6-2.3.4-3.1l2.6-2 2.8-2.2 2.7-1.8 2.5-2 2.5-2.4 2.6-2.1 2.7-2.1 2.7-2 2.6-2.1c.9-.8 2.3-.6 3.1.3.8.9.6 2.3-.3 3.1l-2.7 2.2-2.7 2-2.6 2.1-2.5 2.1-2.5 2.4-2.8 2.2-2.7 1.8-2.6 2.1-2.7 2.1c-.4.1-.9.3-1.4.3z"></path>
      <path d="M73.9 86.7c-.4 0-.8-.1-1.2-.3l-3.1-2-2.5-2.1-2.8-2-2.4-2.4-2.6-1.8-2.9-2-2.4-2.4-2.5-1.9-2.7-1.9c-1-.7-1.2-2.1-.6-3.1.7-1 2.1-1.2 3.1-.6l2.9 2 2.9 2.2 2.3 2.2 2.7 1.8 2.9 2.1 2.4 2.4 2.7 1.9 2.5 2.1 2.7 1.7c1 .6 1.3 2 .7 3-.6.8-1.3 1.1-2.1 1.1zM29.1 41.7l-3.6-1.1h-2.6l-2.9.9c-1.2.3-2.4-.3-2.7-1.5-.3-1.2.3-2.4 1.5-2.7l3.5-1h3.9l2.9.8 3.2-1 3.5 1 2.8-.3 3.6-.4 3.4.7 2.8.1 3.1-.5 3.4-.4h3.6l3.3.8 2.7.2 3.2-.8 3.5.2 3.3-.1 3.1.3 3-.3c1.2-.1 2.3.7 2.4 1.9.1 1.2-.7 2.3-1.9 2.4l-3.5.4-3.5-.3-3.2.1-3-.2-3.2.8-3.7-.3-3.2-.8H55l-3 .4-3.4.6-3.6-.1-3.1-.6-2.9.4-3.6.4-3-.9-3.3.9z"></path>
      <path d="M63 39.8c-.5 0-.9-.4-.9-.9l-.1-3.4V32l.1-3.4c0-.5.5-.9 1-.9s.9.5.9 1l-.1 3.4v3.4l.1 3.4c0 .5-.4.9-1 .9.1 0 .1 0 0 0zM73.7 39.8c-.5 0-.9-.4-.9-.9v-3.4l.2-6.9c0-.5.5-.9 1-.9s.9.4.9 1l-.2 6.8v3.4c0 .5-.5.9-1 .9z"></path>
      <path d="M60.4 32.7l-3.2-3.4.2-4.3-.3-3.6.3-4.5 3-3.1 4.5-.2 3.6.1H72l4.5-.1 3.2 3.3-.2 8 .1 4.4-3.1 3.3-4.6-.2-3.5-.1-3.5.1-4.5.3zm1.2-5.1l.6.6 2.6-.1 3.7-.1 3.7.1 2.6.1.5-.5-.1-2.7.2-6.2-.8-.8-2.6.1h-3.6l-3.6-.1-2.6.1-.6.6-.1 2.6.3 3.5-.2 2.8z"></path>
      <path d="M69.5 25.5L67.3 25.6 65.4 23.7 65.4 22.6 67.3 20.7 69.5 20.7 71.4 22.6 71.3 23.7z"></path>
      <path d="M33.1 25.5L30.9 25.6 29.1 23.7 29 22.6 30.9 20.7 33.1 20.7 35 22.6 35.1 23.7z"></path>
      <path d="M26.6 39.8c-.6 0-1-.5-.9-1l.1-3.4-.2-3.4.1-3.4c0-.5.4-.9 1-.9.5 0 .9.4.9 1l-.1 3.4.2 3.4-.1 3.5c-.1.4-.5.8-1 .8zM37.5 39.8c-.5 0-.9-.4-.9-.9v-6.8l-.2-3.4c0-.5.4-1 .9-1s1 .4 1 .9l.2 3.4v6.9c-.1.5-.5.9-1 .9z"></path>
      <path d="M40.1 32.6l-4.5-.1H32l-8-.2-3.1-3-.1-4.3-.1-3.6.2-4.5 3-3.1h8l3.6-.1 4.5.1 3.1 3.1-.1 7.9.3 4.5-3.2 3.3zm-4.5-4.5h2.7l.6-.6-.1-2.5.1-6.3-.6-.6-2.6-.1-3.5.1H25.9l-.6.6-.1 2.6.1 6.1.6.5 6.3.1 3.4.1z"></path>
      <path d="M68.6 16.4L68.3 16.4 67.8 16 67.7 12.1 68.3 11.6 68.6 11.6 69.1 12.1 69.1 16z"></path>
      <path d="M32.2 16.5L31.9 16.4 31.4 16 31.4 12.1 31.9 11.6 32.2 11.6 32.7 12.1 32.7 16z"></path>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'FloodLights')

export default icon
