import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M73.5726 65.4467V29.2497C73.5726 15.5834 94.9953 15.5834 94.9953 29.2497V48.0869C94.9953 56.5821 86.5001 64.708 78.7436 66.1854C81.6984 67.6629 83.9146 70.2484 84.2839 73.5726H120.85C134.517 73.5726 134.517 94.9953 120.85 94.9953H101.644C93.5179 94.9953 85.392 86.8694 83.9146 79.1129L83.5452 78.7436C82.4372 81.6984 79.4823 83.9146 76.1581 84.2839V120.85C76.1581 134.517 54.7353 134.517 54.7353 120.85V101.644C54.7353 93.5179 63.2306 85.392 70.9871 83.5452C68.0322 82.4372 65.8161 79.4823 65.4467 76.1581H29.2497C15.5834 76.1581 15.5834 54.7353 29.2497 54.7353H48.0869C56.5821 54.7353 64.708 63.2306 66.1854 70.9871C67.6629 68.0322 70.2484 65.8161 73.5726 65.4467Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DarkFan')

export default icon
