import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import { isIOS } from 'react-device-detect'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_CHANGESET } from '@app/constants/scenes/editScene'
import { saveChangesetAction } from '@app/actions/scenes/editScene'
import {
  patchMobileSceneImpossible,
  saveCurrentSceneStatus,
  clearCurrentSceneAll,
} from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/patchScene'
import getMobileJson from '@app/api/scenes/getMobileJson'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { PATCH_MOBILE_SCENE } from '@app/constants/scenes/scenesList'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const useBackendPatchApi = (action, dispatch, done) => {
  dispatch(apiRequestSent())
  Api.patchScene(action.token, action.id, action.data)
    .then(() => {
      store.dispatch(apiRequestDone())
      history.push(action.screenName)
      store.dispatch(clearCurrentSceneAll())
      store.dispatch(saveChangesetAction(''))
      store.dispatch(saveCurrentSceneStatus(null))
    })
    .catch((error) => {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('patchScene failed without error')
      }
    })
    .then(() => done())
}
const patchSceneChangesetLogic = createLogic({
  type: PATCH_CHANGESET,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { scenes: { scenesList: { currentScene: { id, status, name } } } } = getState()
    const { scenes: { editScene: { changesetAction } } } = getState()
    const { scenes: { currentSceneCreating: { screenName } } } = getState()
    let data = { changeset: changesetAction, name }
    if (status) {
      data = { ...data, status }
    }
    next({
      ...action,
      token,
      id,
      data,
      screenName,
      changesetAction,
      name,
      status,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_CHANGESET))
    const changeset = action.changesetAction
    if (isIOS && changeset !== 'cancel' && isWebView) {
      getMobileJson(action.token, action.id)
        .then((result) => {
          const { data: { scene } } = result
          const newInfo = { ...scene.info, name: action.name }
          // eslint-disable-next-line max-len, no-alert
          const promptValue = prompt(`putScene&${JSON.stringify(newInfo)}&${JSON.stringify(scene.actions)}&${JSON.stringify(scene.conditions)}`)
          if (promptValue === 'Invalid JS Request' || !JSON.parse(promptValue).status) {
            dispatch(patchMobileSceneImpossible())
            useBackendPatchApi(action, dispatch, done)
          } else {
            dispatch(apiRequestSent(PATCH_MOBILE_SCENE))
            const { response } = JSON.parse(promptValue)
            let dataForMobile
            if (response !== null) {
              dataForMobile = {
                changeset, name: action.name, status: 'commit_to_mezzo', number: response,
              }
            } else {
              dataForMobile = {
                changeset, name: action.name, status: 'complete',
              }
            }
            Api.patchScene(action.token, action.id, dataForMobile)
              .then(() => {
                store.dispatch(apiRequestDone(PATCH_MOBILE_SCENE))
                history.push(action.screenName)
                store.dispatch(clearCurrentSceneAll())
                store.dispatch(saveChangesetAction(''))
                store.dispatch(saveCurrentSceneStatus(null))
              })
              .catch((backendError) => {
                if (backendError) {
                  const currentError = handleErrors(backendError)
                  dispatch(apiRequestFailed(currentError))
                } else {
                  throw new Error('patchScene failed without error')
                }
              })
              .then(() => done())
          }
        })
        .catch((error) => {
          if (error) {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          } else {
            throw new Error('getMobileJson failed without error')
          }
        })
        .then(() => done())
    } else {
      useBackendPatchApi(action, dispatch, done)
    }
  },
})

export default patchSceneChangesetLogic
