import React from 'react'
import { Paper, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { SELECT_MULTILEVEL_SENSOR_VALUE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import styles from './LuminanceSensorExample.style'

class LuminanceSensorExample extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MULTILEVEL_SENSOR_VALUE)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classes.wrapper}>
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('titles.examples')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classNames(
            classes.insidePart,
            isMobile && classes.insidePartMobile,
          )}
          >
            <Typography className={classes.textTitle}>{I18n.t('multisensor_scene.inside_title_light')}</Typography>
            <ol className={classes.text}>
              <li>{I18n.t('multisensor_scene.inside_text_light_1')}</li>
              <li>{I18n.t('multisensor_scene.inside_text_light_2')}</li>
              <li>{I18n.t('multisensor_scene.inside_text_light_3')}</li>
              <li>{I18n.t('multisensor_scene.inside_text_light_4')}</li>
            </ol>
          </Wrapper>
          <Wrapper className={classNames(
            classes.outsidePart,
            isMobile && classes.outsidePartMobile,
          )}
          >
            <Typography className={classes.textTitle}>{I18n.t('multisensor_scene.outside_title_light')}</Typography>
            <ol className={classes.text}>
              <li>{I18n.t('multisensor_scene.outside_text_light_1')}</li>
              <li>{I18n.t('multisensor_scene.outside_text_light_2')}</li>
              <li>{I18n.t('multisensor_scene.outside_text_light_3')}</li>
              <li>{I18n.t('multisensor_scene.outside_text_light_4')}</li>
            </ol>
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

LuminanceSensorExample.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default withStyles(styles)(LuminanceSensorExample)
