import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { mezzoStateTypes } from '@app/lib/Scenes/mezzoStateTypes'
import {
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE,
  SELECT_BRIGHTNESS_VALUE,
  SELECT_MEZZO_LIGHTS_ACTION_STATE_VALUE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectMezzoActionStateType extends React.Component {
  onBackClick= () => {
    const {
      navigate,
      isEditActionMode,
      isChangeActionStateMode,
      actions,
      initialAction,
    } = this.props
    if (isChangeActionStateMode) {
      if (isEditActionMode) {
        restoreEditedActionInfo(actions, initialAction)
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      navigate(SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE)
    }
  }

  onChooseClick=(type) => {
    const {
      navigate,
    } = this.props
    if (type === 'mezzo_brightness') {
      navigate(SELECT_BRIGHTNESS_VALUE)
    }
    if (type === 'mezzo_colour') {
      navigate(SELECT_MEZZO_LIGHTS_ACTION_STATE_VALUE)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          { mezzoStateTypes.map((type) => (
            <CardBlock
              key={type.title}
              title={I18n.t(`scene_action_web.${type.title}`)}
              description={I18n.t(`scene_action_web.${type.description}`)}
              position="top"
              buttonName="choose"
              isCardDisabled={type.isCardDisabled}
              isButtonDisabled={type.isButtonDisabled}
              onClick={() => {
                this.onChooseClick(type.type)
              }}
            />
          ))}
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoActionStateType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectMezzoActionStateType.displayName = 'SelectMezzoActionStateTypeIOS'

export default SelectMezzoActionStateType
