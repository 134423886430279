import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 44">
      <path fill="currentColor" d="M40.87 5.41a.9.9 0 0 0 .89-.88V.88a.88.88 0 0 0-1.76 0v3.65c0 .48.39.88.87.88Zm6.86 1.83c.44.24.85.13 1.2-.32l1.83-3.15a.88.88 0 1 0-1.52-.89l-1.83 3.16c-.24.42-.1.96.32 1.2Zm6.22 5.35 3.17-1.82a.88.88 0 1 0-.88-1.53l-3.16 1.82a.88.88 0 0 0-.33 1.2c.16.29.68.64 1.2.33Zm5.17 5.65h-3.65a.88.88 0 0 0 0 1.76h3.65a.88.88 0 0 0 0-1.76ZM32.81 6.92c.36.45.76.56 1.2.33a.88.88 0 0 0 .33-1.2l-1.82-3.17a.88.88 0 1 0-1.52.88l1.81 3.16Zm22.62 28.06a.88.88 0 0 0-1.07.63 7.54 7.54 0 0 1-7.3 5.67h-.01a7.55 7.55 0 0 1-7.54-7.55.88.88 0 1 0-1.77 0 9.3 9.3 0 0 0 3.87 7.55H23.96a.88.88 0 0 0 0 1.76h23.09a9.3 9.3 0 0 0 9.02-6.99.88.88 0 0 0-.64-1.07Zm-35.31 6.3h-8.88a9.65 9.65 0 0 1-9.49-7.88.88.88 0 0 0-1.73.32c1 5.4 5.72 9.32 11.22 9.32h8.88a.88.88 0 1 0 0-1.76Zm30.86-15.99a13.17 13.17 0 0 0 1.74-6.17A11.6 11.6 0 0 0 40.89 7.3a11.9 11.9 0 0 0-10.3 6 15.8 15.8 0 0 0-20.62 6.98c-5 .55-9.05 4.3-9.96 9.29a.88.88 0 0 0 1.74.31A9.62 9.62 0 0 1 10.57 22c5.8.01 10.32 4.24 10.32 9.62a.88.88 0 1 0 1.76 0c0-5.95-4.9-10.68-10.68-11.3a13.94 13.94 0 0 1 11.91-6.77c4.89 0 9.43 2.62 11.91 6.77a10.4 10.4 0 0 0-6.23 3.2.88.88 0 0 0 .04 1.25c.42.33.83.31 1.24-.04 3.82-4.1 10.16-3.2 13.4 1.1.17.23.43.36.7.36 4.25-.24 8.51 1.28 9.5 5.98.25.55.6.78 1.04.69.47-.1.68-.56.68-1.05 0-2.42-2.7-5.98-5.18-6.52Zm-5.6-.87a11.4 11.4 0 0 0-7.6-4.14 15.83 15.83 0 0 0-5.64-6.13 10.08 10.08 0 1 1 17.13 10.54 15.1 15.1 0 0 0-3.9-.27Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'UltravioletIcon', '0 0 80 80', {fontSize: 35})

export default icon
