import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
      <g fill="#EB2C31" clipPath="url(#a)">
        <path d="M7.48 7.5a.7.7 0 0 0-.42.13l-5.62 3.75a.74.74 0 0 0-.34.62v2.25c0 .41.34.75.75.75a.7.7 0 0 0 .41-.13l5.22-3.47 5.2 3.47a.7.7 0 0 0 .42.13c.41 0 .75-.34.75-.75V12a.74.74 0 0 0-.34-.62L7.9 7.63a.7.7 0 0 0-.41-.13Z"/>
        <path d="M7.48 0a.7.7 0 0 0-.42.13L1.44 3.88a.74.74 0 0 0-.34.62v2.25c0 .41.34.75.75.75a.7.7 0 0 0 .41-.13L7.48 3.9l5.2 3.47a.7.7 0 0 0 .42.13c.41 0 .75-.34.75-.75V4.5a.74.74 0 0 0-.34-.62L7.9.13A.7.7 0 0 0 7.48 0Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M15 15V0H0v15z"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ChevronsUpIcon', '0 0 13 18', { fontSize: 18 })

export default icon
