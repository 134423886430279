const styles = () => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  logo: {
    marginLeft: 10,
  },
  logoMobile: {
    marginLeft: 0,
  },
})

export default styles
