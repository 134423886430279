import moment from 'moment-timezone'
import { TIMEZONE_NUMBER_DEFINITION } from '@app/constants/stats'

const addDataPlaceholder = (startDate, timezone) => ([
  [
    I18n.t('stats.tcp_ethernet'),
    '',
    'opacity: 0',
    '',
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('2', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('1', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
  ],
  [
    I18n.t('stats.udp_ethernet'),
    '',
    'opacity: 0',
    '',
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('2', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('1', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
  ],
  [
    I18n.t('stats.offline'),
    '',
    'opacity: 0',
    '',
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('2', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('1', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
  ],
  [
    I18n.t('stats.tcp_gsm'),
    '',
    'opacity: 0',
    '',
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('2', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('1', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
  ],
  [
    I18n.t('stats.udp_gsm'),
    '',
    'opacity: 0',
    '',
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('2', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
    moment(moment(startDate).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).subtract('1', 'ms').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
  ],
])

export default addDataPlaceholder
