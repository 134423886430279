import React from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SELECT_GROUP_CONDITION_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './Group.style'

class Group extends React.Component {
  onGroupClick = (group) => {
    const {
      navigate,
      saveConditionDescription,
      saveConditionGroupNumber,
    } = this.props
    saveConditionDescription(group.name)
    saveConditionGroupNumber(group.number)
    navigate(SELECT_GROUP_CONDITION_STATE)
  }

  render() {
    const {
      classes,
      group,
    } = this.props
    return (
      <Wrapper
        className={classes.group}
        onClick={() => {
          this.onGroupClick(group)
        }}
      >
        <Typography className={classes.title} variant="h6" component="h2">
          {group.name}
        </Typography>
      </Wrapper>
    )
  }
}

Group.propTypes = {
  classes: PropTypes.object.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionGroupNumber: PropTypes.func.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(Group)
