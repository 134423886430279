import * as type from '@app/constants/scenes/anotherScenesList'

const initialState = {
  scenes: [],
}
const anotherScenesList = (state = initialState, action) => {
  switch (action.type) {
  case type.SAVE_SCENES_FOR_ANOTHER_SCENE_CONDITION:
    return { ...state, scenes: action.scenes }
  case type.RESET_ANOTHER_SCENES_LIST:
    return initialState
  default:
    return state
  }
}

export default anotherScenesList
