import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    marginBottom: 25,
  },
  form: {
    marginRight: 0,
    width: 64,
    position: 'absolute',
    right: 14,
    top: 7,
    overflow: 'visible',
  },
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        opacity: 1,
        backgroundColor: '#52d869',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(13px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -4,
    marginLeft: -4,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  formControl: {
    width: '100%',
    border: `1px solid ${theme.borders.disabled}`,
    borderRadius: '4px',
    '&:hover': {
      borderColor: theme.borders.main,
    },
    position: 'relative',
    height: 56,
    padding: '0 14px',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: theme.texts.subText,
    width: '70%',
  },
  titleMobile: {
    color: theme.texts.subText,
    fontSize: '12px',
  },
}))

export default useStyles
