import { createLogic } from 'redux-logic'
import { SYNC_ACTIVITIES_CHANNEL, SYNC_CANCEL_ACTIVITIES_CHANNEL } from '@app/constants/activities/activitiesChannel'

import { syncSent, update } from '@app/actions/activities/activitiesChannel'
import { setNewActivity } from '@app/actions/activities/activities'
import activitiesChannel from '@app/api/activities/activitiesChannel'

const subscribeActivitiesChannelLogic = createLogic({
  type: SYNC_ACTIVITIES_CHANNEL,
  cancelType: SYNC_CANCEL_ACTIVITIES_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    activitiesChannel({
      request: () => {
        dispatch(syncSent())
      },
      response: (data) => {
        if (data.created_at) {
          store.dispatch(setNewActivity(data))
        }
        store.dispatch(update(data))
      },
    })
  },
})

export default subscribeActivitiesChannelLogic
