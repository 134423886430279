import { createLogic } from 'redux-logic'
import { SYNC_MY_STATUS_CHANNEL, SYNC_MY_STATUS_CHANNEL_CANCEL } from '@app/constants/myStatus'

import { syncMyStatusChannelSent, updateMyStatusDevices } from '@app/actions/myStatus'
import Api from '@app/api/myStatus'

const subscribeMyStatusLogic = createLogic({
  type: SYNC_MY_STATUS_CHANNEL,
  cancelType: SYNC_MY_STATUS_CHANNEL_CANCEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.myStatusChannel({
      request: () => {
        dispatch(syncMyStatusChannelSent())
      },
      response: (data) => {
        store.dispatch(updateMyStatusDevices(data))
      },
    })
  },
})

export default subscribeMyStatusLogic
