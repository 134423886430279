import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { PUT_CHANGESET } from '@app/constants/scenes/editScene'
import { saveEditedSceneInitialName } from '@app/actions/scenes/editScene'
import { selectCurrentSceneId } from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/putChangeset'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { isIOS } from 'react-device-detect'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const putChangesetLogic = createLogic({
  type: PUT_CHANGESET,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = getState()
    const { scenes: { currentSceneCreating: { screenName } } } = getState()
    next({
      ...action,
      accessToken,
      id,
      screenName,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PUT_CHANGESET))
    Api.putChangeset(action.accessToken, action.id)
      .then((response) => {
        const { data: { scene: { id, name } } } = response
        dispatch(selectCurrentSceneId(id))
        dispatch(saveEditedSceneInitialName(name))
        dispatch(apiRequestDone(PUT_CHANGESET))
        if (isIOS && isWebView) {
          // eslint-disable-next-line no-alert
          prompt('hideTopBar')
        }
        history.push(action.screenName)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('putChangeset failed without error')
        }
      })
      .then(() => done())
  },
})

export default putChangesetLogic
