import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import {
  useNavigate, useLocation,
  Routes,
  Route,
} from 'react-router-dom'

import {
  Button, Container, Snackbar,
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import classNames from 'classnames'

import LocalStorageService from '@app/helpers/stats/localStorageService'
import {
  createRefreshTokenInterceptor,
  createTokenInterceptor,
  statsAxiosInstance,
} from '@app/logics/stats/statsAxiosInstance'
import { loginRequest, temporaryLoginRequest } from '@app/logics/stats/stats'
import * as route from '@app/lib/Statistic/statsRoutes'
import StatsComponent from './StatsMainPage/StatsMainPage'
import StatsAuth from './Auth/StatsAuth'
import DeviceInfo from './DeviceInfo/DeviceInfo'
import useStyles from './Stats.style'

const localStorageService = new LocalStorageService()

const Stats = (): Node => {
  const classes = useStyles()
  const [token, setToken] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [redirectUrl, setRedirectUrl] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    setRedirectUrl(location.pathname)
    if (location.search.includes('?username')) {
      const temporaryUsername = `${location.search.split('&')[0].slice(10)}`
      const temporaryPassword = `${location.search.split('&')[1].slice(9)}`
      const path = `${location.search.split('&')[2].slice(9)}`;
      (async () => {
        try {
          setError('')
          const response = await temporaryLoginRequest(temporaryUsername, temporaryPassword)
          setToken(response.data.access_token)
          localStorageService.setToken(response.data)
          navigate(`${path}`)
        } catch (err) {
          setError(err.response.data.error_reason)
        }
        return Promise.reject(error)
      })()
    } else if (location.search.includes('?forbidden=true')) {
      navigate(route.STATS_LOGIN_REDIRECT)
      setError(`${I18n.t('stats.forbidden_error')}`)
    } else {
      const tokenFromStorage = localStorageService.getToken()
      if (tokenFromStorage) {
        setToken(tokenFromStorage)
      } else {
        navigate(route.STATS_LOGIN_REDIRECT)
      }
    }
    statsAxiosInstance.interceptors.request.use(createTokenInterceptor, (err) => Promise.reject(err))

    statsAxiosInstance.interceptors.response.use(
      (response) => response,
      (err) => createRefreshTokenInterceptor(err, setToken),
    )
    return () => {
      statsAxiosInstance.interceptors.request.eject(createRefreshTokenInterceptor)
      statsAxiosInstance.interceptors.response.eject(createRefreshTokenInterceptor)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === route.STATS_LOGIN_REDIRECT && token) {
      if (redirectUrl && redirectUrl !== route.STATS_LOGIN_REDIRECT) {
        navigate(`${redirectUrl}`)
      } else {
        navigate(route.STATS_MAIN_PAGE_REDIRECT)
      }
    }
  }, [token])

  useEffect(() => {
    if (error === 'Forbidden Access') {
      setError(`${I18n.t('stats.forbidden_error')}`)
      navigate(route.STATS_LOGIN_REDIRECT)
    }
  }, [error])

  const onLoginHandler = (evt) => {
    evt.preventDefault();
    (async () => {
      try {
        setError('')
        const response = await loginRequest(username, password)
        setToken(response.data.access_token)
        localStorageService.setToken(response.data)
        if (redirectUrl) {
          navigate(`${redirectUrl}`)
        } else {
          navigate(route.STATS_MAIN_PAGE_REDIRECT)
        }
      } catch (err) {
        setError(err.response.data.error_reason)
      }
    })()
  }

  const renderSnackbar = () => (
    <Snackbar
      open={!!error}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      message={error}
      autoHideDuration={10000}
      onClose={() => setError('')}
    />
  )

  const logOut = () => {
    setToken('')
    localStorageService.clearStorage()
    navigate(route.STATS_LOGIN_REDIRECT)
  }

  return (
    <>
      <CssBaseline />
      <Button
        className={classNames(classes.logout, location.pathname === route.STATS_LOGIN_REDIRECT && classes.hidden)}
        size="medium"
        color="primary"
        variant="contained"
        onClick={logOut}
      >
        {I18n.t('stats.sign_out')}
      </Button>
      <Container>
        <Routes>
          {token ? (
            <>
              <Route
                path={route.STATS_MAIN_PAGE}
                element={(
                  <StatsComponent setError={setError} />
                )}
              />
              <Route
                path={route.STATS_DEVICE_INFO}
                element={(
                  <DeviceInfo setError={setError} />
                )}
              />
            </>
          ) : (
            <Route
              path={route.STATS_LOGIN}
              element={(
                <StatsAuth
                  username={username}
                  password={password}
                  setUsername={setUsername}
                  setPassword={setPassword}
                  onLoginHandler={onLoginHandler}
                  redirectUrl={redirectUrl}
                />
              )}
            />
          )}
        </Routes>
      </Container>
      {renderSnackbar()}
    </>
  )
}

export default Stats
