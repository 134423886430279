export const numberOfDaysValidation = (num) => {
  if (Number.isNaN(num) || num < 0 || num > 180) {
    return false
  }
  return num
}
export const periodsInDaysValidation = (num) => {
  if (Number.isNaN(num) || num < 1 || num > 90) {
    return false
  }
  return num
}
