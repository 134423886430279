export const roomConditionStates = [
  {
    description: 'is_on',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOn',
  },
  {
    description: 'is_off',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOff',
  },
  {
    description: 'any_changes',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'AnyChanges',
  },
]

export const groupConditionStates = [
  {
    description: 'is_on',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOn',
  },
  {
    description: 'is_off',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOff',
  },
  {
    description: 'any_changes',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'AnyChanges',
  },
]
