import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { TIMER_TRIGGER } from '@app/constants/timers/timers'
import { updateTimer } from '@app/actions/timers/timers'
import Api from '@app/api/timers/timerTrigger'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const timerTriggerLogic = createLogic({
  type: TIMER_TRIGGER,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { timers: { timerData, currentTrigger } } = getState()
    next({
      ...action,
      accessToken,
      timerData,
      currentTrigger,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(TIMER_TRIGGER))
    const data = {
      number: action.timerData.number,
      state: action.currentTrigger,
    }
    Api.timerTrigger(action.accessToken, data)
      .then((response) => {
        dispatch(updateTimer(response.data.timer))
        dispatch(apiRequestDone(TIMER_TRIGGER))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('timerTrigger failed without error')
        }
      })
      .then(() => done())
  },
})

export default timerTriggerLogic
