import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { restoreConditionInfo, saveConditionType, saveConditionDescription } from '@app/actions/scenes/currentSceneCreating'
import { syncSensorNotifications } from '@app/actions/scenes/zWaveDevices/sensorNotifications'
import {
  syncZWaveDeviceBinarySensorTypes,
  syncZWaveDeviceCapabilities,
  syncZWaveDeviceMultilevelSensorTypes,
  syncZWaveDeviceStates,
} from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import SelectConditionDeviceStateType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectConditionDeviceStateType/SelectConditionDeviceStateType'
import SelectConditionDeviceStateTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/SelectConditionDeviceStateType/SelectConditionDeviceStateType'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectConditionDeviceStateTypeIOS) : withStyles(SelectConditionDeviceStateType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isEditConditionMode, isChangeConditionStateMode, isChangeConditionMode, conditionDeviceId,
  } = state.scenes.currentSceneCreating
  const { capability_names: capabilities } = state.scenes.zWaveDevices.zWaveDeviceCapabilities
  const { devices } = state.scenes.zWaveDevices
  const currentDevice = devices.find((device) => device.id === conditionDeviceId)
  return {
    isMobile,
    path,
    capabilities,
    currentDevice,
    isEditConditionMode,
    isChangeConditionStateMode,
    isChangeConditionMode,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncZWaveDeviceCapabilities,
  tryAgain: syncZWaveDeviceCapabilities,
  notifications: syncNotifications.sync,
  syncZWaveDeviceStates,
  syncZWaveDeviceBinarySensorTypes,
  syncZWaveDeviceMultilevelSensorTypes,
  saveConditionType,
  restoreConditionInfo,
  saveConditionDescription,
  syncSensorNotifications,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectConditionDeviceStateTypeEnhanced = enhance

export default withRouter(SelectConditionDeviceStateTypeEnhanced)
