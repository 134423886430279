import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 83 130" version="1.1">
            <title>iconDeviceDoor</title>
            <desc>Created with Sketch.</desc>
            <defs>
            </defs>
            <g id="Map" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="iconDeviceDoor" transform="translate(-22.000000, 0.000000)">
                    <g transform="translate(24.640523, 0.000000)" id="Shape">
                        <path
                            d="M25.6346405,12.8470588 L4.12941176,12.8470588 C3.031273,12.8470495 1.97835742,13.2844432 1.20345215,14.0625374 C0.428546869,14.8406316 -0.00451908962,15.8953346 -5.65987415e-16,16.9934641 L-5.65987415e-16,110.91634 C-1.88657954e-16,113.196951 1.84880062,115.045752 4.12941176,115.045752 L25.6346405,115.045752"
                            stroke="currentColor" strokeWidth="4">

                        </path>
                        <path
                            d="M76.5640523,9.41437908 L37.3856209,1.00261438 C34.3862745,0.356862745 31.9052288,1.75882353 31.9052288,4.14640523 L31.9052288,126.303922 C31.9052288,128.640523 34.3607843,129.804575 37.3261438,128.920915 L76.1901961,117.382353 C78.2509971,116.777645 79.7332398,114.975373 79.9287582,112.836601 L80.2941176,13.7137255 C80.1561689,11.6060528 78.6311784,9.84831888 76.5640523,9.41437908 L76.5640523,9.41437908 Z M37.1222222,67.8124183 C36.0006536,67.8124183 35.1,66.1130719 35.1,63.9379085 C35.1,61.7627451 36.0091503,60.0633987 37.1222222,60.0633987 C38.2352941,60.0633987 39.1444444,61.7627451 39.1444444,63.9379085 C39.1444444,66.1130719 38.2352941,67.8124183 37.1222222,67.8124183 L37.1222222,67.8124183 Z"
                            fill="currentColor">
                        </path>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwDoorSensorOpenedIcon')

export default icon
