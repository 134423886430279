import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  webView: {
    background: theme.webView.background,
  },
  card: {
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 20,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 75,
  },
  cardMobile: {
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 20,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  icon: {
    marginRight: 24,
    width: 50,
    height: 50,
  },
  cardTitle: {
    fontSize: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardDescription: {
    fontSize: '18px',
    color: theme.texts.subText,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardTitleMobile: {
    fontSize: '16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardDescriptionMobile: {
    fontSize: '14px',
  },
}))

export default useStyles
