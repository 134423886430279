import React from 'react'
import { Button, Dialog, DialogContent } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm, submit } from 'redux-form'
import classNames from 'classnames'
import { contactsFields } from '@app/lib/Contacts/contactsFields'
import { patchContact, postContact, setFormData } from '@app/actions/contacts/contacts'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { withStyles } from '@app/components/styles'
import UserInfoInput from './UserInfoInput/UserInfoInput'
import { validate } from './formValidation'

const UserInfoForm = (props) => {
  const {
    classes,
    pristine,
    submitting,
    invalid,
    change,
    closeEditing,
    closeNew,
    isNewContact,
    currentContactId,
  } = props
  const { isMobile, isTablet } = useSelector((state) => state.mobileDetector)
  const dispatch = useDispatch()

  const resetField = (option) => dispatch(change(option, ''))

  const normalize = (value) => value.trimLeft()

  return (
    <Dialog
      open
      onClose={isNewContact ? closeNew : closeEditing}
      fullScreen={isMobile && !isTablet}
    >
      <DialogContent className={classNames(classes.content, isMobile && !isTablet && classes.contentMobile)}>
        {contactsFields.map((field) => (
          <Wrapper className={classes.formLayout} key={field.title}>
            <Wrapper>
              <label htmlFor={field}>{I18n.t(`contacts_web.${field.title}`)}</label>
              {field.required && <sup> *</sup>}
            </Wrapper>
            <Wrapper className={classNames(classes.input, isMobile && !isTablet && classes.inputMobile)}>
              <Field
                key={field.title}
                id={field}
                name={field.title}
                title={field.title}
                autoComplete={field.autocomplete}
                hint={field.hint}
                resetField={() => resetField(field.title)}
                component={UserInfoInput}
                normalize={normalize}
              />
            </Wrapper>
          </Wrapper>
        ))}
        <Wrapper className={classes.formControlsContainer}>
          <Button className={classes.formControlsButton} onClick={isNewContact ? closeNew : closeEditing}>
            {I18n.t('buttons.cancel')}
          </Button>
          <Button
            className={classes.formControlsButton}
            onClick={() => {
              dispatch(submit('UserInfoForm'))
              dispatch(isNewContact ? postContact() : patchContact(currentContactId))
              setTimeout(() => {
                closeNew()
              }, 1000)
            }}
            disabled={submitting || pristine || invalid}
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      </DialogContent>
    </Dialog>
  )
}

UserInfoForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  isNewContact: PropTypes.bool.isRequired,
  closeNew: PropTypes.func.isRequired,
  closeEditing: PropTypes.func.isRequired,
  currentContactId: PropTypes.number.isRequired,
}

UserInfoForm.displayName = 'UserInfoForm'

export default reduxForm({
  form: 'contacts',
  validate,
  onSubmit: () => store.dispatch(setFormData()),
})(withStyles(UserInfoForm))
