import React from 'react'
import SliderControl
  from '@app/components/MyDevices/DevicesList/DeviceCard/CardUIKit/SliderControl/SliderControl'
import MultiSwitch from '@app/components/Common/MultiSwitch/MultiSwitch'

export interface IDeviceControlComponent {
    label: typeof I18n,
    key: string,
    checked?: typeof I18n | string,
    unchecked?: typeof I18n | string,
    component: React.FC<any>,
}

export const controlComponents: Record<string, IDeviceControlComponent> = {
  onOff: {
    label: I18n.t('my_devices.on_off_label'),
    key: 'switch_on_off',
    checked: I18n.t('my_devices.is_on'),
    unchecked: I18n.t('my_devices.is_off'),
    component: MultiSwitch,
  },
  upDown: {
    label: I18n.t('my_devices.up_down_label'),
    key: 'switch_up_down',
    checked: I18n.t('my_devices.down'),
    unchecked: I18n.t('my_devices.up'),
    component: MultiSwitch,
  },
  leftRight: {
    label: I18n.t('my_devices.left_right_label'),
    key: 'switch_left_right',
    checked: I18n.t('my_devices.right'),
    unchecked: I18n.t('my_devices.left'),
    component: MultiSwitch,
  },
  lockedUnlocked: {
    label: I18n.t('my_devices.locked_unlocked_label'),
    key: 'switch_locked_unlocked',
    checked: I18n.t('my_devices.locked'),
    unchecked: I18n.t('my_devices.unlocked'),
    component: MultiSwitch,
  },
  multiLevel: {
    label: I18n.t('my_devices.slider_on_off_label'),
    key: 'switch_slider',
    component: SliderControl,
  },
  no_control: {
    label: I18n.t('my_devices.no_controls'),
    key: 'no_control',
    component: () => <></>,
    checked: '',
    unchecked: '',
  },
}

export const deviceControls = {
  switch_binary: {
    ...controlComponents.onOff,
  },
  switch_multilevel: {
    ...controlComponents.multiLevel,
    isMultilevel: true,
  },
  entry_control: {
    ...controlComponents.lockedUnlocked,
  },
  switch_on_off: {
    ...controlComponents.onOff,
  },
  switch_up_down: {
    ...controlComponents.upDown,
  },
  switch_left_right: {
    ...controlComponents.leftRight,
  },
  switch_locked_unlocked: {
    ...controlComponents.lockedUnlocked,
  },
  switch_slider: {
    ...controlComponents.multiLevel,
    isMultilevel: true,
  },
  no_control: {
    ...controlComponents.no_control,
  },
}
