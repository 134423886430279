import { createLogic } from 'redux-logic'
import convertUser from '@app/helpers/convertUser'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CURRENT_MEMBER } from '@app/constants/users/currentMember'
import { updateCurrentMember } from '@app/actions/users/currentMember'
import Api from '@app/api/wizard/signIn'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchCurrentMemberLogic = createLogic({
  type: SYNC_CURRENT_MEMBER,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { users: { currentMember: { user: { ids: { sphere } } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_CURRENT_MEMBER))
    Api.signIn(token, sphere)
      .then((response) => {
        const { data: { sphere_user } } = response
        const user = convertUser(sphere_user)
        dispatch(updateCurrentMember(user))
        dispatch(apiRequestDone(SYNC_CURRENT_MEMBER))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('signIn failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchCurrentMemberLogic
