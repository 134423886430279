import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { RESET_DEVICES_ENERGIES } from '@app/constants/myEnergy/deviceManager'
import { resetModeOff } from '@app/actions/myEnergy/deviceManager'
import Api from '@app/api/resetDevicesEnergies'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitResetDevicesEnergiesLogic = createLogic({
  type: RESET_DEVICES_ENERGIES,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { deviceManager: { devicesForReset } } = getState()
    next({
      ...action,
      token,
      devicesForReset,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(RESET_DEVICES_ENERGIES))
    Api.resetDevicesEnergies(action.token, action.devicesForReset)
      .then(() => {
        dispatch(apiRequestDone(RESET_DEVICES_ENERGIES))
        dispatch(resetModeOff())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('resetDevicesEnergies failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitResetDevicesEnergiesLogic
