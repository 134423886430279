import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1667 6.07335V8.51404H5.83333V6.07335C5.83333 5.05166 6.18519 4.18134 6.88889 3.46237C7.62963 2.70557 8.5 2.32717 9.5 2.32717C10.5 2.32717 11.3519 2.70557 12.0556 3.46237C12.7963 4.18134 13.1667 5.05166 13.1667 6.07335ZM18.2778 9.25192C17.7963 8.76 17.2407 8.51404 16.6111 8.51404H15.4444V6.07335C15.4444 4.40838 14.8519 2.98937 13.6667 1.81633C12.5185 0.605443 11.1296 0 9.5 0C7.87037 0 6.46296 0.605443 5.27778 1.81633C4.12963 2.98937 3.55556 4.40838 3.55556 6.07335V8.51404H2.38889C1.75926 8.51404 1.2037 8.76 0.722222 9.25192C0.240741 9.706 0 10.2547 0 10.898V23.0447C0 23.6879 0.240741 24.2555 0.722222 24.7475C1.2037 25.2015 1.75926 25.4286 2.38889 25.4286H16.6111C17.2407 25.4286 17.7963 25.2015 18.2778 24.7475C18.7593 24.2555 19 23.6879 19 23.0447V10.898C19 10.2547 18.7593 9.706 18.2778 9.25192ZM9.83568 17.3669V13.9491H8.85915V17.8552L12.277 19.9303L12.7653 19.1064L9.83568 17.3669ZM9.5 22.4327C8.0759 22.4327 6.84507 21.9241 5.80751 20.9068C4.7903 19.8693 4.28169 18.6385 4.28169 17.2144C4.28169 15.7903 4.7903 14.5696 5.80751 13.5524C6.84507 12.5148 8.0759 11.996 9.5 11.996C10.9241 11.996 12.1448 12.5148 13.162 13.5524C14.1995 14.5696 14.7183 15.7903 14.7183 17.2144C14.7183 18.6385 14.1995 19.8693 13.162 20.9068C12.1448 21.9241 10.9241 22.4327 9.5 22.4327ZM9.5 10.7144C7.7097 10.7144 6.17371 11.3552 4.89202 12.6369C3.63067 13.8982 3 15.4241 3 17.2144C3 19.0047 3.63067 20.5406 4.89202 21.8223C6.17371 23.0837 7.7097 23.7144 9.5 23.7144C11.2903 23.7144 12.8161 23.0837 14.0775 21.8223C15.3592 20.5406 16 19.0047 16 17.2144C16 15.4241 15.3592 13.8982 14.0775 12.6369C12.8161 11.3552 11.2903 10.7144 9.5 10.7144Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'AutoArmingIcon')

export default icon
