const styles = () => ({
  grid: {
    margin: '0 auto',
    width: '100%',
  },
  gridContainer: {
    height: '100%',
  },
})

export default styles
