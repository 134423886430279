import React from 'react'
import {
  Button, Icon, ListItemIcon, Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { EcoModeDeviceIcon } from 'icons'
import { checkIconNumber } from '@app/helpers/myDevices/checkIconNumber'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './EcoModeDevice.style'

class EcoModeDevice extends React.Component {
  toggleEcoModeStatus = () => {
    const {
      id,
      ecoModeStatus,
      selectCurrentEcoModeDeviceId,
      selectCurrentEcoModeDeviceStatus,
      patchCurrentEcoModeDevice,
    } = this.props
    selectCurrentEcoModeDeviceId(id)
    selectCurrentEcoModeDeviceStatus(ecoModeStatus)
    patchCurrentEcoModeDevice()
  }

  render() {
    const {
      classes,
      icon,
      name,
      ecoModeStatus,
      admin,
    } = this.props
    return (
      <Wrapper className={classes.deviceItem}>
        <ListItemIcon>
          <Icon className={classes.deviceIcon}>{devicesIcons[checkIconNumber(icon)]}</Icon>
        </ListItemIcon>
        <Typography className={classes.deviceName}>{name}</Typography>
        <Button
          color="primary"
          className={classNames(ecoModeStatus ? classes.button : classes.buttonDisabled)}
          variant="contained"
          onClick={this.toggleEcoModeStatus}
          disabled={!admin}
        >
          <Wrapper className={classes.svgWrapper}>
            <EcoModeDeviceIcon className={classes.titleIcon} color={ecoModeStatus ? 'primary' : 'disabled'} />
          </Wrapper>
        </Button>
      </Wrapper>
    )
  }
}

EcoModeDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  icon: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  ecoModeStatus: PropTypes.bool.isRequired,
  selectCurrentEcoModeDeviceId: PropTypes.func.isRequired,
  selectCurrentEcoModeDeviceStatus: PropTypes.func.isRequired,
  patchCurrentEcoModeDevice: PropTypes.func.isRequired,
}

export default withStyles(styles)(EcoModeDevice)
