import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, LinearProgress, Radio, RadioGroup, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import {
  Navigate, useNavigate, useLocation,
} from 'react-router-dom'
import { twrModes } from '@app/lib/MyDevices/twrModes'
import { getTwrAlarmOptions, patchTwrDevice } from '@app/actions/myDevices/twrDevices'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './TwrAdditionalModes.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  twrOptions: Array<string>,
  isMobile: boolean,
}

const TwrAdditionalModes: React.FC<Props> = (props) => {
  const { currentDevice, twrOptions, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [currentOption, setCurrentOption] = useState<string>(twrModes.alarm.mode)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentDevice.option) {
      setCurrentOption(currentDevice.option)
    }
    dispatch(getTwrAlarmOptions(currentDevice.id))
  }, [])

  const goBackHandler = () => navigate(-1)

  const submitOption = () => {
    dispatch(patchTwrDevice({ option: currentOption }, currentDevice.id))
    navigate(location.pathname.split('/').slice(0, -2).join('/'))
  }

  return (
    <>
      {currentDevice ? (
        <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          <Wrapper className={classes.navigationControls}>
            <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
              <ScenesArrowBack />
            </IconButton>
          </Wrapper>
          <Wrapper>
            <RadioGroup
              className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}
              value={currentOption}
              onChange={(event) => setCurrentOption(event.target.value)}
            >
              {!!twrOptions.length && twrOptions.map((mode, index) => (twrModes[mode] ? (
                <Wrapper
                  className={classes.modeCard}
                  onClick={() => setCurrentOption(mode)}
                  key={`twr_mode_${index + 1}`}
                >
                  <Typography className={classNames(classes.marginBottomHelper)}>
                    {I18n.t(`my_devices.${mode}`)}
                  </Typography>
                  <Wrapper className={classes.subTitleContainer}>
                    <Typography className={classes.cardSubTitle}>{twrModes[mode].description}</Typography>
                  </Wrapper>
                  <Radio value={mode} className={classes.radioButton} color="primary" />
                </Wrapper>
              ) : null))}
            </RadioGroup>
          </Wrapper>
          {!twrOptions.length && (
            <Wrapper className={classes.progressWrapper}>
              <LinearProgress />
            </Wrapper>
          )}
          <Button
            variant="outlined"
            className={classes.saveButton}
            color="primary"
            onClick={submitOption}
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      ) : <Navigate to={location.pathname.split('/').slice(0, -2).join('/')} />}
    </>
  )
}

export default TwrAdditionalModes
