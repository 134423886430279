import * as types from '@app/constants/myDevices/deviceSettings/failedNode'
import { UPDATE_FAILED_NODE } from '@app/constants/myDevices/channels/deviceSettings/failedNode'

const initialState = {
  inProgress: false,
  error: '',
  deviceHealth: '',
  status: '',
}

const failedNode = (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_FAILED_NODE:
    if (action.data.zwave_health) {
      return { ...state, deviceHealth: action.data.zwave_health }
    } if (action.data.status) {
      return { ...state, status: action.data.status }
    }
    return state
  case types.RESET_FAILED_NODE_DATA:
    return initialState
  case types.RESET_FAILED_NODE_STATUS:
    return { ...state, status: initialState.status }
  default:
    return state
  }
}

export default failedNode
