import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/scenes/states/device`

const getDeviceStates = (accessToken, deviceType, deviceId) => Axios.get(ENDPOINT, {
  params: {
    type: deviceType,
    id: deviceId,
  },
  headers: { 'Access-Token': accessToken },
})

export default getDeviceStates
