import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { PUT_TIMER } from '@app/constants/timers/timers'
import { TIMERS, SCENES, TIMERS_IN_SCENES } from '@app/constants/routes'
import Api from '@app/api/timers/putTimer'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const putTimerLogic = createLogic({
  type: PUT_TIMER,
  cancelType: API_REQUEST_CANCEL,
  latest: true,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { timers: { timerData } } = getState()
    next({
      ...action,
      accessToken,
      timerData,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PUT_TIMER))
    const data = {
      number: action.timerData.number,
      timer_type: action.timerData.type,
      duration: {
        day: action.timerData.duration.day || 0,
        hour: action.timerData.duration.hour || 0,
        minute: action.timerData.duration.minute || 0,
        second: action.timerData.duration.second || 0,
      },
    }
    Api.putTimer(action.accessToken, data)
      .then(() => {
        dispatch(apiRequestDone(PUT_TIMER))
        if (window.location.pathname.includes(SCENES)) {
          history.push(TIMERS_IN_SCENES)
        } else {
          history.push(TIMERS)
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('putTimer failed without error')
        }
      })
      .then(() => done())
  },
})

export default putTimerLogic
