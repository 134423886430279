export const validate = (values) => {
  const errors = {}
  const login = 'email_or_phone'
  const password = 'password'

  const requiredFields = [login, password]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })
  return errors
}
