const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    minHeight: 400,
  },
  listItem: {
    width: 120,
    height: 120,
    padding: 0,
  },
  rootEmpty: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 400,
  },
})

export default styles
