import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import calculateRoomColorActionType from '@app/helpers/scenes/calculateRoomColorActionType'
import {
  selectColorActionSelectedDelay,
  selectColorActionSelectedDuration,
  selectColorActionSelectedValue,
} from '@app/lib/Scenes/actions'
import { ColorTypes } from '@app/lib/Scenes/colorActions'
import {
  ADJUST_DELAY_COLOR_ROOM,
  ADJUST_DURATION_COLOR_ROOM,
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_ROOM_ACTION_COLOR_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectRoomColorActionStateSelected extends React.Component {
  onChooseValueClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_ROOM_ACTION_COLOR_TYPE)
  }

  onAddStateClick = () => {
    const {
      navigate,
      isEditActionMode,
      color,
      actionDeviceNumber,
      actionDelay,
      durationValueAfter,
      actionDuration,
      saveActionDeviceStateInfo,
      colorType,
      saveActionType,
      colorTypeDurationValueAfter,
      roomNumber,
      roomValue,
    } = this.props
    const actionType = calculateRoomColorActionType(actionDelay, actionDuration)
    saveActionType(actionType)
    const actionDeviceStateInfo = getActionDeviceStateInfo(actionType, color,
      actionDeviceNumber, actionDelay, durationValueAfter, actionDuration, colorType, colorTypeDurationValueAfter, roomNumber,
      roomValue)
    saveActionDeviceStateInfo(actionDeviceStateInfo)
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  adjustDuration = () => {
    const {
      navigate,
      saveInitialActionInfo,
      saveActionDurationValueAfter,
      saveDurationValueAfterActionColorType,
    } = this.props
    saveInitialActionInfo()
    saveActionDurationValueAfter('none')
    saveDurationValueAfterActionColorType('')
    navigate(ADJUST_DURATION_COLOR_ROOM)
  }

  adjustDelay = () => {
    const {
      navigate,
    } = this.props
    navigate(ADJUST_DELAY_COLOR_ROOM)
  }

  clearDelay=() => {
    const {
      saveActionDelay,
      saveActionDelayDescription,
    } = this.props
    saveActionDelay({})
    saveActionDelayDescription('')
  }

  clearDuration=() => {
    const {
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      saveDurationValueAfterActionColorType,
    } = this.props
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
    saveDurationValueAfterActionColorType('')
  }

  renderValueBlock =() => {
    const {
      color,
      colorType,
    } = this.props
    if (colorType === ColorTypes.whiteWarm) {
      return (
        <CardBlock
          key={I18n.t(`scene_web.${selectColorActionSelectedValue.title}`)}
          title={I18n.t(`scene_web.${selectColorActionSelectedValue.title}`)}
          description={`set white warm on at ${color}%`}
          position={selectColorActionSelectedValue.position}
          buttonName={I18n.t(`scene_web.${selectColorActionSelectedValue.buttonName}`)}
          isCardDisabled={selectColorActionSelectedValue.isCardDisabled}
          isButtonDisabled={selectColorActionSelectedValue.isButtonDisabled}
          onClick={this.onChooseValueClick}
        />
      )
    } if (colorType === ColorTypes.whiteCold) {
      return (
        <CardBlock
          key={I18n.t(`scene_web.${selectColorActionSelectedValue.title}`)}
          title={I18n.t(`scene_web.${selectColorActionSelectedValue.title}`)}
          description={`set white cold on at ${color}%`}
          position={selectColorActionSelectedValue.position}
          buttonName={I18n.t(`scene_web.${selectColorActionSelectedValue.buttonName}`)}
          isCardDisabled={selectColorActionSelectedValue.isCardDisabled}
          isButtonDisabled={selectColorActionSelectedValue.isButtonDisabled}
          onClick={this.onChooseValueClick}
        />
      )
    }
    return (
      <CardBlock
        key={I18n.t(`scene_web.${selectColorActionSelectedValue.title}`)}
        title={I18n.t(`scene_web.${selectColorActionSelectedValue.title}`)}
        description={color.white ? I18n.t('scene_web.set_color_on') : I18n.t('scene_web.set_color')}
        hasAdditionalDesc
        descColor={color}
        position={selectColorActionSelectedValue.position}
        buttonName={I18n.t(`scene_web.${selectColorActionSelectedValue.buttonName}`)}
        isCardDisabled={selectColorActionSelectedValue.isCardDisabled}
        isButtonDisabled={selectColorActionSelectedValue.isButtonDisabled}
        onClick={this.onChooseValueClick}
      />
    )
  }

  renderDurationValueBlock =() => {
    const {
      colorTypeDurationValueAfter,
      durationDescription,
      durationValueAfter,
    } = this.props
    if (durationValueAfter === 'none') {
      return (
        <CardBlock
          key={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
          title={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
          description={I18n.t(`scene_action_web.${durationValueAfter}`)}
          position={selectColorActionSelectedDuration.position}
          buttonName={I18n.t(`scene_web.${selectColorActionSelectedDuration.buttonName}`)}
          isCardDisabled={selectColorActionSelectedDuration.isCardDisabled}
          isButtonDisabled={selectColorActionSelectedDuration.isButtonDisabled}
          hasClearButton={!!durationDescription}
          clearDescription={this.clearDuration}
          onClick={this.adjustDuration}
        />
      )
    }
    if (colorTypeDurationValueAfter === ColorTypes.whiteWarm) {
      const whiteWarmDurationFullDescription = `${durationDescription} then set white warm on at ${durationValueAfter}%`
      return (
        <CardBlock
          key={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
          title={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
          description={whiteWarmDurationFullDescription}
          position={selectColorActionSelectedDuration.position}
          buttonName={I18n.t(`scene_web.${selectColorActionSelectedDuration.buttonName}`)}
          isCardDisabled={selectColorActionSelectedDuration.isCardDisabled}
          isButtonDisabled={selectColorActionSelectedDuration.isButtonDisabled}
          hasClearButton={!!whiteWarmDurationFullDescription}
          clearDescription={this.clearDuration}
          onClick={this.adjustDuration}
        />
      )
    } if (colorTypeDurationValueAfter === ColorTypes.whiteCold) {
      const whiteColdDurationFullDescription = `${durationDescription} then set white cold on at ${durationValueAfter}%`
      return (
        <CardBlock
          key={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
          title={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
          description={whiteColdDurationFullDescription}
          position={selectColorActionSelectedDuration.position}
          buttonName={I18n.t(`scene_web.${selectColorActionSelectedDuration.buttonName}`)}
          isCardDisabled={selectColorActionSelectedDuration.isCardDisabled}
          isButtonDisabled={selectColorActionSelectedDuration.isButtonDisabled}
          hasClearButton={!!whiteColdDurationFullDescription}
          clearDescription={this.clearDuration}
          onClick={this.adjustDuration}
        />
      )
    }
    const colorDurationFullDescription = `${durationDescription} then ${I18n.t('scene_web.set_color')}`
    return (
      <CardBlock
        key={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
        title={I18n.t(`scene_web.${selectColorActionSelectedDuration.title}`)}
        description={colorDurationFullDescription}
        hasAdditionalDesc
        descColor={durationValueAfter}
        position={selectColorActionSelectedDuration.position}
        buttonName={I18n.t(`scene_web.${selectColorActionSelectedDuration.buttonName}`)}
        isCardDisabled={selectColorActionSelectedDuration.isCardDisabled}
        isButtonDisabled={selectColorActionSelectedDuration.isButtonDisabled}
        hasClearButton={!!colorDurationFullDescription}
        clearDescription={this.clearDuration}
        onClick={this.adjustDuration}
      />
    )
  }

  render() {
    const {
      classes,
      isMobile,
      delayDescription,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={null}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {this.renderValueBlock()}
          <CardBlock
            key={I18n.t(`scene_web.${selectColorActionSelectedDelay.title}`)}
            title={I18n.t(`scene_web.${selectColorActionSelectedDelay.title}`)}
            description={delayDescription || I18n.t(`scene_web.${selectColorActionSelectedDelay.description}`)}
            position={selectColorActionSelectedDelay.position}
            buttonName={I18n.t(`scene_web.${selectColorActionSelectedDelay.buttonName}`)}
            isCardDisabled={selectColorActionSelectedDuration.isCardDisabled}
            isButtonDisabled={selectColorActionSelectedDuration.isButtonDisabled}
            hasClearButton={!!delayDescription}
            clearDescription={this.clearDelay}
            onClick={this.adjustDelay}
          />
          {this.renderDurationValueBlock()}
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectRoomColorActionStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  colorType: PropTypes.string.isRequired,
  colorTypeDurationValueAfter: PropTypes.string.isRequired,
  saveInitialActionInfo: PropTypes.func.isRequired,
  saveActionType: PropTypes.func.isRequired,
  actionDeviceNumber: PropTypes.number.isRequired,
  actionDelay: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  actionDuration: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  saveActionDeviceStateInfo: PropTypes.func.isRequired,
  durationDescription: PropTypes.string.isRequired,
  delayDescription: PropTypes.string.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  saveDurationValueAfterActionColorType: PropTypes.func.isRequired,
  roomNumber: PropTypes.number.isRequired,
  roomValue: PropTypes.number.isRequired,
}

SelectRoomColorActionStateSelected.displayName = 'SelectColorActionStateSelected'

export default SelectRoomColorActionStateSelected
