import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4 13.1489C10.5539 13.1902 10.6452 13.3484 10.604 13.5022C10.5056 13.8696 10.5183 14.258 10.6405 14.6181C10.7628 14.9783 10.9891 15.2941 11.2909 15.5257C11.5926 15.7572 11.9563 15.8941 12.3358 15.9189C12.7153 15.9438 13.0937 15.8556 13.4231 15.6654C13.5611 15.5857 13.7375 15.633 13.8171 15.771C13.8968 15.909 13.8495 16.0854 13.7116 16.1651C13.2834 16.4123 12.7914 16.5269 12.2981 16.4946C11.8047 16.4623 11.3319 16.2844 10.9396 15.9834C10.5474 15.6824 10.2532 15.2718 10.0942 14.8036C9.93529 14.3354 9.91876 13.8305 10.0467 13.3529C10.088 13.199 10.2461 13.1077 10.4 13.1489Z" fill="#FF0000"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2116 11.8349C11.6398 11.5877 12.1317 11.473 12.6251 11.5054C13.1185 11.5377 13.5913 11.7156 13.9835 12.0166C14.3758 12.3176 14.67 12.7282 14.829 13.1964C14.9879 13.6646 15.0044 14.1695 14.8764 14.6471C14.8352 14.8009 14.677 14.8923 14.5231 14.8511C14.3693 14.8098 14.2779 14.6516 14.3192 14.4977C14.4176 14.1304 14.4049 13.742 14.2826 13.3819C14.1604 13.0217 13.9341 12.7059 13.6323 12.4743C13.3306 12.2428 12.9669 12.1059 12.5874 12.081C12.2079 12.0562 11.8294 12.1444 11.5001 12.3346C11.3621 12.4142 11.1857 12.367 11.106 12.229C11.0264 12.091 11.0736 11.9146 11.2116 11.8349Z" fill="#FF0000"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.046 13.0269C10.1587 12.9142 10.3413 12.9142 10.454 13.0269L11.4155 13.9884C11.5282 14.1011 11.5282 14.2837 11.4155 14.3964C11.3029 14.509 11.1202 14.509 11.0076 14.3964L10.25 13.6388L9.49244 14.3964C9.37979 14.509 9.19714 14.509 9.08449 14.3964C8.97184 14.2837 8.97184 14.1011 9.08449 13.9884L10.046 13.0269Z" fill="#FF0000"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5076 13.6038C13.6203 13.4911 13.8029 13.4911 13.9155 13.6038L14.6731 14.3613L15.4307 13.6038C15.5434 13.4911 15.726 13.4911 15.8386 13.6038C15.9513 13.7164 15.9513 13.899 15.8386 14.0117L14.8771 14.9732C14.7644 15.0859 14.5818 15.0859 14.4692 14.9732L13.5076 14.0117C13.395 13.899 13.395 13.7164 13.5076 13.6038Z" fill="#FF0000"/>
      <path d="M1.77778 1.77778H1.27778V2.27778V4.94444V5.44444H1.77778H14.2222H14.7222V4.94444V2.27778V1.77778H14.2222H1.77778ZM14.2222 8C10.4794 8 7.5 10.9794 7.5 14.7222C7.5 15.1522 7.53946 15.5866 7.62056 16H1.77778C1.07614 16 0.5 15.4239 0.5 14.7222V2.27778C0.5 1.57614 1.07614 1 1.77778 1H14.2222C14.9239 1 15.5 1.57614 15.5 2.27778V8.12056C15.0866 8.03946 14.6522 8 14.2222 8Z" stroke="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'CloudFirmwareUpdate')

export default icon
