const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomLine: {
    fontSize: '14px',
    marginBottom: 32,
    maxWidth: 460,
  },
  link: {
    cursor: 'pointer',
  },
})

export default styles
