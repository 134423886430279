import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import 'date-fns'
import {
  Button, TextField, Typography,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import moment from 'moment-timezone'

import PropTypes from '@app/components/PropTypes'
import useStyles from './TimePicker.style'

type Props = {
  onSubmitHandler: (startDate: { [key: any]: any }, endDate: { [key: any]: any }, timezone: string) => Promise<any>,
}

const TimePicker = (props: Props): Node => {
  const {
    onSubmitHandler,
  } = props
  const classes = useStyles()
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(12, 'hours'),
    endDate: moment(),
    timezone: `(GMT ${moment.tz(moment.tz.guess()).format('Z')}) ${moment.tz.guess()}`,
  })
  const [placeholderValue, setPlaceholderValue] = useState(dateRange.timezone)

  const timezones = moment.tz.names()
  const offsetTmz = timezones.map((tz) => `(GMT ${moment.tz(tz).format('Z')}) ${tz.replace(/_/g, ' ')}`)

  const startDateChangeHandler = (newDate: string): void => {
    setDateRange((prevRange) => ({
      ...prevRange,
      startDate: moment(newDate),
    }))
  }

  const endDateChangeHandler = (newDate: string): void => {
    if (dateRange.startDate < moment(newDate)) {
      setDateRange((prevRange) => ({
        ...prevRange,
        endDate: moment(newDate),
      }))
    }
  }

  const changeTimezoneHandler = (timezone: string): void => {
    setDateRange((prevRange) => ({
      ...prevRange,
      timezone,
    }))
  }

  useEffect(() => {
    onSubmitHandler(dateRange.startDate.clone(), dateRange.endDate.clone(), dateRange.timezone.replace(/ /g, '_'))
  }, [])

  return (
    <>
      <div className={classes.root}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            autoOk
            ampm={false}
            id="date-picker-start"
            label="Start date"
            value={dateRange.startDate.format()}
            onChange={startDateChangeHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                className={classes.timePicker}
              />
            )}
          />
          <DateTimePicker
            autoOk
            ampm={false}
            id="date-picker-end"
            label="End date"
            value={dateRange.endDate.format()}
            onChange={endDateChangeHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                className={classes.timePicker}
              />
            )}
          />
          <Autocomplete
            classes={{
              root: classes.autocomplete,
            }}
            options={offsetTmz}
            id="timezone-select"
            getOptionLabel={(option) => option}
            inputValue={placeholderValue}
            onInputChange={(_, tz) => setPlaceholderValue(tz)}
            value={dateRange.timezone}
            onChange={(_, tz) => changeTimezoneHandler(tz)}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Choose your timezone"
                className={classes.timezoneSelect}
              />
            )}
          />
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => onSubmitHandler(dateRange.startDate.clone(), dateRange.endDate.clone(), dateRange.timezone.replace(/ /g, '_'))}
          >
            Show
          </Button>
        </LocalizationProvider>

      </div>
      <div>
        <Typography className={classes.difference}>
          {`Stats for ${dateRange.endDate.diff(dateRange.startDate, 'hours')} hours according to your filters`}
        </Typography>
      </div>
    </>
  )
}

TimePicker.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
}

export default TimePicker
