import React from 'react'
import { Button, List, Paper } from '@mui/material'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import SetUpBillingPopup from '@app/containers/MyEnergy/Tabs/BillingPeriod/SetUpBillingPeriod'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CurrentBillingPeriod from './BillingPeriodListItems/CurrentBillingPeriod'
import EnergyBillingPeriod from './BillingPeriodListItems/EnergyBillingPeriod'
import Statistics from './BillingPeriodListItems/Statistics'

class BillingPeriod extends React.Component {
  componentWillUnmount() {
    const {
      setUpBillingPeriodClose,
      billingPeriodsStatsClose,
    } = this.props
    setUpBillingPeriodClose()
    billingPeriodsStatsClose()
  }

  render() {
    const {
      classes,
      billingCurrentStats,
      isMobile,
      admin,
      isSetUpPeriodPopupOpen,
      setUpBillingPeriodOpen,
      setUpBillingPeriodClose,
      isBillingPeriodsStatsPopupOpen,
      billingPeriodsStatsOpen,
    } = this.props
    return (
      <Paper className={classNames(
        classes.screenWrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <Wrapper className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            color="primary"
            onClick={setUpBillingPeriodOpen}
            disabled={!admin}
          >
            {I18n.t('buttons.set_up_billing_period')}
          </Button>
        </Wrapper>
        <List className={classes.list}>
          <CurrentBillingPeriod
            isMobile={isMobile}
            billingPeriod={billingCurrentStats.billing_period}
          />
          <EnergyBillingPeriod
            isMobile={isMobile}
            billingPeriod={billingCurrentStats.billing_period}
          />
          <Statistics
            isMobile={isMobile}
            isBillingPeriodsStatsPopupOpen={isBillingPeriodsStatsPopupOpen}
            billingPeriodsStatsOpen={billingPeriodsStatsOpen}
            total={billingCurrentStats.total}
          />
        </List>
        {isSetUpPeriodPopupOpen && (
          <SetUpBillingPopup
            isMobile={isMobile}
            isSetUpPeriodPopupOpen={isSetUpPeriodPopupOpen}
            setUpBillingPeriodOpen={setUpBillingPeriodOpen}
            setUpBillingPeriodClose={setUpBillingPeriodClose}
          />
        )}
      </Paper>
    )
  }
}

BillingPeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  billingCurrentStats: PropTypes.shape({
    billing_period: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      billing: PropTypes.number.isRequired,
      energy: PropTypes.number.isRequired,
    }),
    total: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      billing: PropTypes.number.isRequired,
      energy: PropTypes.number.isRequired,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isSetUpPeriodPopupOpen: PropTypes.bool.isRequired,
  isBillingPeriodsStatsPopupOpen: PropTypes.bool.isRequired,
  setUpBillingPeriodOpen: PropTypes.func.isRequired,
  billingPeriodsStatsOpen: PropTypes.func.isRequired,
  setUpBillingPeriodClose: PropTypes.func.isRequired,
  billingPeriodsStatsClose: PropTypes.func.isRequired,
}

BillingPeriod.displayName = 'BillingPeriod'

export default BillingPeriod
