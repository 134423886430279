export const VALIDATION_REG_EXP_1220 = /^[0-9]{4}$/
export const VALIDATION_REG_EXP_1326 = /^[1-30]+$/
export const IP_ADDRESS_REG_EXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
export const CSV_REPORTING_REG_EXP = /^[0-9a-zA-z]{1,10}$/
export const DOMAIN_NAME_REG_EXP = /^(?=.{0,30}$)([a-zA-Z0-9][a-zA-Z0-9-_]{1,28}[a-zA-Z0-9])\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,6})$/
export const VALIDATION_REG_EXP_1317 = /^[0-9a-zA-z]{8,30}$/

export const validation = {
  1220: VALIDATION_REG_EXP_1220,
  1317: VALIDATION_REG_EXP_1317,
  1343: IP_ADDRESS_REG_EXP,
  1353: IP_ADDRESS_REG_EXP,
  1326: VALIDATION_REG_EXP_1326,
  1361: CSV_REPORTING_REG_EXP,
  1362: CSV_REPORTING_REG_EXP,
  1363: DOMAIN_NAME_REG_EXP,
  1364: DOMAIN_NAME_REG_EXP,
}

export const requiredFields = [1220, 1300, 1301, 1308, 1309, 1351]

export const programOptionsMinMaxValues = {
  1300: {
    min: 0,
    max: 60,
  },
  1301: {
    min: 5,
    max: 60,
  },
  1308: {
    min: 0,
    max: 60,
  },
  1309: {
    min: 0,
    max: 60,
  },
  1344: {
    min: 0,
    max: 65535,
  },
  1351: {
    min: 0,
    max: 250,
  },
  1354: {
    min: 0,
    max: 65535,
  },
  1375: {
    min: 1,
    max: 32,
  },
}
