import * as Moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(Moment)

export const getOffsetString = (value: string | number): string => {
  if (value === 0 || value === '') {
    return '0'
  }
  return `${Moment.duration(value, 'minutes').format('h [hours] m [min]')}`
}
