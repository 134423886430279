import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.41421 0L0 1.41422L10.0584 11.4726C9.96446 11.5563 9.87209 11.6436 9.78125 11.7344C8.59375 12.9219 8 14.3438 8 16C8 16.3426 8.02758 16.6759 8.08275 17L6 17V19H17.5858L24.7487 26.163L26.163 24.7487L1.41421 0ZM22 17V18.5858L20.4142 17H22ZM20 16C20 16.1901 19.992 16.3773 19.9759 16.5617L13.4373 10.0231C13.6215 10.0077 13.8091 10 14 10C15.6563 10 17.0781 10.5782 18.2656 11.7344C19.4219 12.9219 20 14.3438 20 16ZM6 13.9531H3V11.9375H6V13.9531ZM22.0312 13.9531V11.9375H25.0312V13.9531H22.0312ZM15 2.00003V4.95316H13.0312V2.00003H15ZM22.4531 5.89065L20.6719 7.71878L19.2656 6.31253L21.0469 4.4844L22.4531 5.89065Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'RestoredAfterAlarmIcon')

export default icon
