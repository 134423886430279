import type { Node } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Paper, Typography } from '@mui/material'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import Geocode from 'react-geocode'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { PILLS_REMINDERS } from '@app/constants/routes'
import { apiKey, libraries } from '@app/lib/Scenes/geofences'
import {
  resetGuardPanelProgress,
  resetGuardPanelTimeout,
  setGuardPanelTimeout,
  updateGuardPanelStatus,
} from '@app/actions/guardPanel'
import { setGuardPanelAddress } from '@app/actions/healthAlert'
import { timeoutDuration } from '@app/lib/guardPanel/guardPanel'
import {
  CancelIcon, ChevronLeft, ExclamationActiveIcon, ExclamationIcon,
} from 'icons'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './HealthAlert.style'
import DotsLoading from '../Common/DotsLoading/DotsLoading'

const HealthAlert = (): Node => {
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const { latitude, longitude } = useSelector((state) => state.unit)
  const { role } = useSelector((state) => state.users.currentUser.user)
  const { status, timeout } = useSelector((state) => state.guardPanel)
  const { inProgress } = useSelector((state) => state.healthAlert)
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [coordinates, setCoordinates] = useState({
    lat: Number(latitude),
    lng: Number(longitude),
  })
  const [address, setAddress] = useState < string > ('')
  const [countdown, setCountdown] = useState < any > (null)
  const [isAlertTriggered, setAlertTriggered] = useState < boolean > (false)
  const [isAlertActive, setAlertActive] = useState < boolean > (false)
  const timeoutTimer = useRef(null)

  useEffect(() => {
    setCoordinates({
      lat: Number(latitude),
      lng: Number(longitude),
    })
    navigator.geolocation.getCurrentPosition((pos) => {
      Geocode.setApiKey(apiKey)
      Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then((response) => {
        setCoordinates({
          lat: Number(pos.coords.latitude),
          lng: Number(pos.coords.longitude),
        })
        setAddress(response.results[0].formatted_address)
        dispatch(setGuardPanelAddress(response.results[0].formatted_address))
      })
    })
    clearTimeout(timeoutTimer.current)
  }, [])

  useEffect(() => {
    if (timeout) {
      timeoutTimer.current = setTimeout(() => {
        setAlertTriggered(!isAlertTriggered)
        setAlertActive(!isAlertActive)
        dispatch(resetGuardPanelTimeout())
        dispatch(resetGuardPanelProgress())
      }, timeoutDuration)
    }
    return () => clearTimeout(timeoutTimer.current)
  }, [timeout])

  useEffect(() => {
    if (status !== 'medical') {
      setAlertActive(false)
      setAlertTriggered(false)
    } else {
      setAlertActive(true)
      setAlertTriggered(true)
    }
  }, [status])

  useEffect(() => {
    const timer = (countdown && countdown > 0) && setInterval(() => setCountdown(countdown - 1), 1000)
    if (countdown === 0) {
      setAlertTriggered(true)
      setAlertActive(true)
      dispatch(setGuardPanelTimeout())
      dispatch(updateGuardPanelStatus('medical'))
    }
    const clearCountdown = (intervalId: any) => {
      clearInterval(intervalId)
    }
    return () => clearCountdown(timer)
  }, [countdown])

  const activateAlert = () => {
    setAlertTriggered(true)
  }

  const terminateAlert = () => {
    setAlertTriggered(false)
  }

  const cancelAlert = () => {
    dispatch(updateGuardPanelStatus('disarmed'))
    dispatch(setGuardPanelTimeout())
  }

  return (
    <Paper className={classes.root}>
      <Wrapper className={classes.main}>
        {!isAlertActive && (!isAlertTriggered ? (
          <Wrapper className={classNames(classes.alertWrapper, role === 'guest' && classes.alertWrapperDisabled)}>
            {(!inProgress && status !== 'medical') && <ExclamationIcon className={classes.exclamationIcon} />}
            {inProgress && (
              <DotsLoading
                className={classes.medicLoad}
                props={{
                  width: 200, circleWidth: 50, circleHeight: 50, circleColor: '#fff',
                }}
              />
            )}
            <Button
              className={classNames(classes.alertButton, role === 'guest' && classes.callButtonDisabled)}
              disabled={inProgress || role === 'guest'}
              onClick={() => {
                activateAlert()
                setCountdown(5)
              }}
            >
              {I18n.t('health_alert.call_for_help')}
            </Button>
          </Wrapper>
        ) : (
          <Wrapper className={classes.alertWrapperCountdown}>
            <ExclamationActiveIcon className={classes.exclamationIconCountdown} />
            <Button
              className={classNames(
                classes.alertButtonCountdown,
                isMobile && classes.alertButtonCountdownMobile,
              )}
              onClick={() => {
                terminateAlert()
                setCountdown(null)
              }}
            >
              {`${I18n.t('health_alert.Alert_activated').replace('%q', countdown)}`}
            </Button>
          </Wrapper>
        ))}
        {isAlertActive && (
          <Wrapper className={classes.alertWrapper}>
            {(!inProgress && status === 'medical') && <CancelIcon className={classes.cancelIcon} />}
            {inProgress && (
              <DotsLoading
                className={classes.medicLoad}
                props={{
                  width: 200, circleWidth: 50, circleHeight: 50, circleColor: '#fff',
                }}
              />
            )}
            <Button
              className={classes.alertButton}
              classes={{ disabled: classes.alertButtonDisabled }}
              onClick={() => {
                cancelAlert()
              }}
              disabled={inProgress}
            >
              {I18n.t('health_alert.cancel_alert')}
            </Button>
          </Wrapper>
        )}
        <Typography className={classNames(classes.address, isMobile && classes.addressMobile)}>
          {address}
        </Typography>
        <Wrapper className={classes.mapContainer}>
          <LoadScript
            id="script-loader"
            googleMapsApiKey={apiKey}
            libraries={libraries}
            onUnmount={() => [...document.querySelectorAll("script[src*='maps.googleapis.com']")].map((script) => (
              script.parentNode && script.parentNode.removeChild(script)))}
          >
            <GoogleMap
              id="searchbox"
              mapContainerStyle={{
                height: '310px',
                width: '100%',
              }}
              zoom={18}
              center={coordinates}
              options={{
                streetViewControl: false, disableDefaultUI: true, draggable: false, draggableCursor: 'default',
              }}
            >
              <Marker position={coordinates} />
            </GoogleMap>
          </LoadScript>
        </Wrapper>
        <Wrapper
          className={classNames(classes.wrapperCard, isMobile && classes.wrapperCardMobile)}
          onClick={() => {
            navigate(PILLS_REMINDERS)
          }}
        >
          <Typography
            className={classNames(classes.pill, isMobile && classes.pillMobile)}
          >
            {I18n.t('health_alert.pill_reminders')}
          </Typography>
          <ChevronLeft className={classes.chevronIcon} />
        </Wrapper>
      </Wrapper>
    </Paper>
  )
}

export default HealthAlert
