import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './TemperatureSensorTabs.style'
import CelsiusVariant from './CelsiusVariant/CelsiusVariant'
import FahrenheitVariant from './FahrenheitVariant/FahrenheitVariant'

class TemperatureSensorTabs extends React.Component {
  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const {
      classes,
      isMobile,
      saveConditionDeviceStateInfo,
      saveMultilevelSensorValueDescription,
      conditionDeviceNumber,
      sensorType,
      navigate,
    } = this.props
    const { value } = this.state
    return (
      <Wrapper className={classes.root}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={this.handleChange}
          variant="fullWidth"
          indicatorColor="primary"
        >
          <Tab label="CELSIUS" />
          <Tab label="FAHRENHEIT" />
        </Tabs>
        {value === 0 && (
          <CelsiusVariant
            isMobile={isMobile}
            navigate={navigate}
            saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
            saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
            conditionDeviceNumber={conditionDeviceNumber}
            sensorType={sensorType}
          />
        )}
        {value === 1 && (
          <FahrenheitVariant
            isMobile={isMobile}
            navigate={navigate}
            saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
            saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
            conditionDeviceNumber={conditionDeviceNumber}
            sensorType={sensorType}
          />
        )}
      </Wrapper>
    )
  }
}

TemperatureSensorTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveMultilevelSensorValueDescription: PropTypes.func.isRequired,
  sensorType: PropTypes.number.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
}

TemperatureSensorTabs.defaultProps = {

}

export default withStyles(styles)(TemperatureSensorTabs)
