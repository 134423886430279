import React from 'react'
import { Divider, List, ListSubheader } from '@mui/material'
import { States, defaultConfig } from '@app/helpers/convertAppConfig'
import { IAppConfig } from '@app/@types/appConfig'
import useStyles from './Menu.style'
import Wrapper from '../Common/Wrapper/Wrapper'
import Settings from './Settings'
import Controls from './Controls'
import Other from './Other'
import Actions from './Actions'

interface Props {
  appConfig: IAppConfig,
  online: boolean,
}

const Menu: React.FC<Props> = (props) => {
  const {
    appConfig,
    online,
  } = props
  const classes = useStyles()
  const allMenuState = appConfig.menu ? appConfig.menu.all_menu : defaultConfig.menu.all_menu
  return (
    <>
      {allMenuState === States.Disabled && (<Wrapper className={classes.menuBlocker} />)}
      <List subheader={<ListSubheader disableSticky>{I18n.t('titles.subheader_controls')}</ListSubheader>}>
        <Controls appConfig={appConfig} />
      </List>
      <Divider />
      <List subheader={<ListSubheader disableSticky>{I18n.t('titles.subheader_settings')}</ListSubheader>}>
        <Settings appConfig={appConfig} />
      </List>
      <Divider />
      <List subheader={<ListSubheader disableSticky>{I18n.t('titles.subheader_other')}</ListSubheader>}>
        <Other appConfig={appConfig} />
      </List>
      <Divider />
      <List subheader={<ListSubheader disableSticky>{I18n.t('titles.subheader_actions')}</ListSubheader>}>
        <Actions appConfig={appConfig} online={online} />
      </List>
    </>
  )
}

export default Menu
