import React from 'react'
import {
  Fab, IconButton, Paper, Typography,
} from '@mui/material'
import classNames from 'classnames'
import Geocode from 'react-geocode'
import { apiKey, maxAmountOfGeofences } from '@app/lib/Scenes/geofences'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_TYPE,
  SELECT_GEOFENCES_LOCATION,
  SELECT_GEOFENCES_USER,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import PropTypes from '@app/components/PropTypes'
import { Add, Refresh } from 'icons'

class SelectGeofencesCondition extends React.Component {
  state = {
    geofencesLimit: false,
  }

  componentDidMount() {
    const { syncGeofences } = this.props
    syncGeofences()
  }

  getGeofenceName = (name, status) => {
    let currentName = name
    if (status === 'in_progress' || status === 'commit_to_mezzo') {
      currentName = `${name} (${I18n.t('geofence_web.saving')})`
    } else if (status === 'not_saved') {
      currentName = `${name} (${I18n.t('geofence_web.not_saved')})`
    } else if (status === 'deleting') {
      currentName = `${name} (${I18n.t('geofence_web.deleting')})`
    }
    return currentName
  }

  onBackClick = () => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_CONDITION_TYPE)
    }
  }

  onAddClick = () => {
    const {
      navigate,
      geofencesList,
    } = this.props
    if (geofencesList.length >= maxAmountOfGeofences) {
      this.setState({ geofencesLimit: true })
    } else {
      navigate(SELECT_GEOFENCES_LOCATION)
    }
  }

  popupClose=() => {
    this.setState({ geofencesLimit: false })
  }

  selectGeofence=(geofence) => {
    const {
      navigate,
      saveConditionType,
      saveConditionDescription,
      saveConditionGeofenceNumber,
      saveGeofenceInfo,
    } = this.props
    saveConditionType('Geofencing')
    saveConditionDescription(geofence.name)
    saveConditionGeofenceNumber(geofence.number)
    Geocode.setApiKey(apiKey)
    Geocode.fromLatLng(geofence.latitude, geofence.longitude).then((response) => {
      const finalAddress = response.results[0].formatted_address
      saveGeofenceInfo(finalAddress, geofence.latitude, geofence.longitude, geofence.size)
      navigate(SELECT_GEOFENCES_USER)
    })
  }

  render() {
    const {
      classes,
      geofencesList,
      isMobile,
      syncGeofences,
      patchGeofence,
    } = this.props
    const { geofencesLimit } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_geofence')}</Typography>
        </Wrapper>
        <Wrapper className={classNames(
          classes.main,
          !geofencesList.length && classes.emptyMain,
        )}
        >
          <IconButton className={classes.refreshIcon} onClick={syncGeofences} size="large">
            <Refresh />
          </IconButton>
          {geofencesList.length ? geofencesList.map((geofence) => (
            <CardBlock
              key={geofence.id}
              description={this.getGeofenceName(geofence.name, geofence.status)}
              position="middle"
              buttonName={geofence.status === 'not_saved' ? I18n.t('scene_web.save') : I18n.t('scene_web.use')}
              isCardDisabled={!(geofence.status === 'complete' || geofence.status === 'not_saved')}
              isButtonDisabled={!(geofence.status === 'complete' || geofence.status === 'not_saved')}
              onClick={() => {
                if (geofence.status === 'not_saved') {
                  const data = {
                    name: geofence.name,
                    latitude: geofence.latitude,
                    longitude: geofence.longitude,
                    size: geofence.size,
                  }
                  patchGeofence(data, geofence.id)
                  syncGeofences()
                } else {
                  this.selectGeofence(geofence)
                }
              }}
            />
          ))
            : <Typography className={classes.noGeofences}>{I18n.t('scene_web.no_geofences')}</Typography>}
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.addButton}
            onClick={() => {
              this.onAddClick()
            }}
          >
            <Add />
          </Fab>
        </Wrapper>
        <SmallErrorPopup
          open={geofencesLimit}
          title="titles.title_error"
          warningText="errors.error_code_-154"
          buttonName="buttons.okay"
          onClick={this.popupClose}
        />
      </Paper>
    )
  }
}

SelectGeofencesCondition.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  syncGeofences: PropTypes.func.isRequired,
  saveGeofenceInfo: PropTypes.func.isRequired,
  patchGeofence: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionGeofenceNumber: PropTypes.func.isRequired,
  geofencesList: PropTypes.arrayOf(PropTypes.geofence.isRequired),
}

SelectGeofencesCondition.defaultProps = {

  geofencesList: [],
}

SelectGeofencesCondition.displayName = 'SelectGeofenceCondition'

export default SelectGeofencesCondition
