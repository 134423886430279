import React from 'react'
import { withStyles } from '@mui/styles'
import PropTypes from '@app/components/PropTypes'
import styles from './WhitePicker.style'

class PickerPointer extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.pickerPointer} />
    )
  }
}

PickerPointer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PickerPointer)
