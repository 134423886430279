const getActionDescription = (type: string, description: string, actionDeviceName: string): string => {
  switch (type) {
  case 'LightMezzo':
  case 'ArmTheSystem':
  case 'ArmInHomeMode':
  case 'Disarm':
  case 'ToggleVoice':
  case 'MuteOrUnmuteVoice':
  case 'AlarmPanicDuress':
  case 'ControlRoom':
  case 'RoomColorControl':
  case 'RoomColorDelayDuration':
  case 'RoomColorWithDuration':
  case 'ControlGroup':
  case 'GroupColorControl':
  case 'GroupColorDelayDuration':
  case 'GroupColorWithDuration':
  case 'MezzoTopLightColor':
  case 'MezzoBottomLightColor':
    return `then ${description}`
  case 'Toggle':
  case 'SetDeviceStatus':
  case 'DelayedDeviceAction':
  case 'SetZwaveColor':
  case 'ZwaveColorDelayDuration':
  case 'ZwaveColorSetWithDuration':
  case 'DelayedPlusDurationDeviceAction':
  case 'ToggleZwaveDeviceWithDuration':
  case 'ZwaveMultichannelControl':
    return `then ${actionDeviceName}`
  case 'PlayAudio':
    return I18n.t(`scene_web.${description.toLowerCase()}`)
  case 'StartTimer':
  case 'StopTimer':
    return `then Timer ${description}`
  case 'PushNotifications':
  case 'Email':
  case 'WebRequest':
    return 'then Notification'
  case 'VirtualSwitch':
  case 'VirtualSwitchDelayDuration':
    return `then Virtual switch ${description}`
  default:
    throw new Error('action unsupported')
  }
}

export default getActionDescription
