import React from 'react'
import classNames from 'classnames'
import { AnyAction } from 'redux'
import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Close } from 'icons'
import { setUpBillingPopupFields } from '@app/lib/MyEnergy/SetUpBillingPopupFields'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { useDispatch } from '@app/store'
// @ts-ignore
import EditText from './EditText/EditText'
import useStyles from './SetUpBillingPopup.style'

interface Props {
  reset: () => void,
  submitting: boolean,
  pristine: boolean,
  submit: () => void,
  setUpBillingPeriodClose: () => void,
  change: (option: string, value: string) => AnyAction,
}

const SetUpBillingPopup: React.FC<Props> = (props) => {
  const {
    reset,
    submitting,
    pristine,
    submit,
    setUpBillingPeriodClose,
    change,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  const resetField = (option: string) => {
    dispatch(change(option, ''))
  }

  return (
    <form>
      <Dialog
        className={classNames(classes.root, (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
            && classes.rootWebView)}
        open
      >
        <DialogTitle
          className={classes.title}
        >
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={setUpBillingPeriodClose}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {setUpBillingPopupFields.map((field) => (
            <EditText
              key={field.title}
              name={field.name}
              hint={field.hint && `${I18n.t(`hint.${field.hint}`)}`}
              title={`${I18n.t(`my_energy.${field.title}`)} *`}
              resetField={() => resetField(field.name)}
            />
          ))}
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button onClick={reset} disabled={pristine || submitting} variant="outlined" className={classes.button}>
            {I18n.t('buttons.reset')}
          </Button>
          <Button
            disabled={pristine || submitting}
            variant="outlined"
            className={classes.button}
            onClick={submit}
          >
            {I18n.t('buttons.submit')}
          </Button>
        </Wrapper>
      </Dialog>
    </form>
  )
}

export default SetUpBillingPopup
