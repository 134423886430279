import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: '24px 24px 24px 70px',
    position: 'relative',
    marginBottom: -8,
  },
  radioButton: {
    position: 'absolute',
    left: 22,
    top: 'calc(50% - 21px)',
  },
  cardMobile: {
    padding: 18,
  },
}))

export default useStyles
