import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_6_1528)">
              <path d="M16.4956 6.86243L16.1492 7.20886C17.9639 9.02365 17.9639 11.9765 16.1492 13.7913L16.4956 14.1377C18.5014 12.1319 18.5014 8.86821 16.4956 6.86243Z" fill="currentColor"/>
              <path d="M15.5334 7.82471L15.1869 8.17114C16.471 9.4553 16.471 11.5447 15.1869 12.8289L15.5334 13.1753C17.0085 11.7001 17.0085 9.29989 15.5334 7.82471Z" fill="currentColor"/>
              <path d="M3.8508 7.20874L3.50436 6.8623C1.49855 8.86812 1.49855 12.1318 3.50436 14.1376L3.8508 13.7911C2.03605 11.9764 2.03605 9.02352 3.8508 7.20874Z" fill="currentColor"/>
              <path d="M4.81311 8.17118L4.46668 7.82471C3.75208 8.53933 3.35852 9.48943 3.35852 10.5C3.35852 11.5106 3.75208 12.4607 4.46668 13.1753L4.81311 12.8289C4.19105 12.2068 3.84846 11.3797 3.84846 10.5C3.84846 9.6203 4.19105 8.79324 4.81311 8.17118Z" fill="currentColor"/>
              <path d="M5.18225 3.72241V17.2775H14.8178V3.72241H5.18225ZM14.3279 16.7876H5.67219V14.6645H14.3279V16.7876ZM14.3279 14.1745H5.67219V4.21235H14.3279V14.1745Z" fill="currentColor" stroke="currentColor" strokeWidth="0.4"/>
              <path d="M7.5503 8.16455C6.96499 8.16455 6.48877 8.64077 6.48877 9.22608C6.48877 9.81143 6.96499 10.2876 7.5503 10.2876C8.13561 10.2876 8.61183 9.81139 8.61183 9.22608C8.61183 8.64077 8.13561 8.16455 7.5503 8.16455ZM7.5503 9.79771C7.23514 9.79771 6.97871 9.54127 6.97871 9.22611C6.97871 8.91096 7.23514 8.65452 7.5503 8.65452C7.86546 8.65452 8.12189 8.91096 8.12189 9.22611C8.12189 9.54127 7.86546 9.79771 7.5503 9.79771Z" fill="currentColor"/>
              <path d="M10 8.16455C9.4147 8.16455 8.93848 8.64077 8.93848 9.22608C8.93848 9.81139 9.4147 10.2876 10 10.2876C10.5854 10.2876 11.0615 9.81143 11.0615 9.22611C11.0615 8.6408 10.5854 8.16455 10 8.16455ZM10 9.79771C9.68485 9.79771 9.42841 9.54127 9.42841 9.22611C9.42841 8.91096 9.68485 8.65452 10 8.65452C10.3152 8.65452 10.5716 8.91096 10.5716 9.22611C10.5716 9.5413 10.3152 9.79771 10 9.79771Z" fill="currentColor"/>
              <path d="M12.4497 8.16455C11.8644 8.16455 11.3882 8.64077 11.3882 9.22608C11.3882 9.81139 11.8644 10.2876 12.4497 10.2876C13.0351 10.2876 13.5112 9.81139 13.5112 9.22608C13.5112 8.64077 13.0351 8.16455 12.4497 8.16455ZM12.4497 9.79771C12.1346 9.79771 11.8781 9.54127 11.8781 9.22611C11.8781 8.91096 12.1346 8.65452 12.4497 8.65452C12.7649 8.65452 13.0213 8.91096 13.0213 9.22611C13.0213 9.5413 12.7649 9.79771 12.4497 9.79771Z" fill="currentColor"/>
              <path d="M7.5503 11.1042C6.96499 11.1042 6.48877 11.5805 6.48877 12.1658C6.48877 12.7511 6.96499 13.2273 7.5503 13.2273C8.13561 13.2273 8.61183 12.7511 8.61183 12.1658C8.61183 11.5805 8.13561 11.1042 7.5503 11.1042ZM7.5503 12.7374C7.23514 12.7374 6.97871 12.481 6.97871 12.1658C6.97871 11.8507 7.23514 11.5942 7.5503 11.5942C7.86546 11.5942 8.12189 11.8507 8.12189 12.1658C8.12189 12.481 7.86546 12.7374 7.5503 12.7374Z" fill="currentColor"/>
              <path d="M10 11.1042C9.4147 11.1042 8.93848 11.5805 8.93848 12.1658C8.93848 12.7511 9.4147 13.2273 10 13.2273C10.5854 13.2273 11.0615 12.7511 11.0615 12.1658C11.0615 11.5805 10.5854 11.1042 10 11.1042ZM10 12.7374C9.68485 12.7374 9.42841 12.481 9.42841 12.1658C9.42841 11.8507 9.68485 11.5942 10 11.5942C10.3152 11.5942 10.5716 11.8507 10.5716 12.1658C10.5716 12.481 10.3152 12.7374 10 12.7374Z" fill="currentColor"/>
              <path d="M12.4497 11.1042C11.8644 11.1042 11.3882 11.5805 11.3882 12.1658C11.3882 12.7511 11.8644 13.2273 12.4497 13.2273C13.0351 13.2273 13.5112 12.7511 13.5112 12.1658C13.5112 11.5805 13.0351 11.1042 12.4497 11.1042ZM12.4497 12.7374C12.1346 12.7374 11.8781 12.481 11.8781 12.1658C11.8781 11.8507 12.1346 11.5942 12.4497 11.5942C12.7649 11.5942 13.0213 11.8507 13.0213 12.1658C13.0213 12.481 12.7649 12.7374 12.4497 12.7374Z" fill="currentColor"/>
              <path d="M7.95869 15.0321H7.46875V15.522H7.95869V15.0321Z" fill="currentColor"/>
              <path d="M12.5314 15.0321H12.0415V15.522H12.5314V15.0321Z" fill="currentColor"/>
              <path d="M11.3883 15.0321H10.8983V15.522H11.3883V15.0321Z" fill="currentColor"/>
              <path d="M13.6748 15.0321H13.1848V15.522H13.6748V15.0321Z" fill="currentColor"/>
              <path d="M10.2451 15.0321H9.75513V15.522H10.2451V15.0321Z" fill="currentColor"/>
              <path d="M9.10188 15.0321H8.61194V15.522H9.10188V15.0321Z" fill="currentColor"/>
              <path d="M6.8155 15.0321H6.32556V15.522H6.8155V15.0321Z" fill="currentColor"/>
              <path d="M7.95869 15.9303H7.46875V16.4202H7.95869V15.9303Z" fill="currentColor"/>
              <path d="M12.5314 15.9303H12.0415V16.4202H12.5314V15.9303Z" fill="currentColor"/>
              <path d="M11.3883 15.9303H10.8983V16.4202H11.3883V15.9303Z" fill="currentColor"/>
              <path d="M13.6748 15.9303H13.1848V16.4202H13.6748V15.9303Z" fill="currentColor"/>
              <path d="M10.2451 15.9303H9.75513V16.4202H10.2451V15.9303Z" fill="currentColor"/>
              <path d="M9.10188 15.9303H8.61194V16.4202H9.10188V15.9303Z" fill="currentColor"/>
              <path d="M6.8155 15.9303H6.32556V16.4202H6.8155V15.9303Z" fill="currentColor"/>
              <path d="M7.93 4.5H7.44006V4.98994H7.93V4.5Z" fill="currentColor"/>
              <path d="M12.5061 4.5H12.0161V4.98994H12.5061V4.5Z" fill="currentColor"/>
              <path d="M11.3627 4.5H10.8728V4.98994H11.3627V4.5Z" fill="currentColor"/>
              <path d="M13.6492 4.5H13.1593V4.98994H13.6492V4.5Z" fill="currentColor"/>
              <path d="M10.2197 4.5H9.72974V4.98994H10.2197V4.5Z" fill="currentColor"/>
              <path d="M9.07636 4.5H8.58643V4.98994H9.07636V4.5Z" fill="currentColor"/>
              <path d="M6.78999 4.5H6.30005V4.98994H6.78999V4.5Z" fill="currentColor"/>
              <path d="M7.93317 5.39819H7.44324V5.88813H7.93317V5.39819Z" fill="currentColor"/>
              <path d="M12.5061 5.39819H12.0161V5.88813H12.5061V5.39819Z" fill="currentColor"/>
              <path d="M11.3627 5.39819H10.8728V5.88813H11.3627V5.39819Z" fill="currentColor"/>
              <path d="M13.6492 5.39819H13.1593V5.88813H13.6492V5.39819Z" fill="currentColor"/>
              <path d="M10.2197 5.39819H9.72974V5.88813H10.2197V5.39819Z" fill="currentColor"/>
              <path d="M9.07636 5.39819H8.58643V5.88813H9.07636V5.39819Z" fill="currentColor"/>
              <path d="M6.78999 5.39819H6.30005V5.88813H6.78999V5.39819Z" fill="currentColor"/>
          </g>
          <defs>
              <clipPath id="clip0_6_1528">
                  <rect width="16" height="16" fill="currentColor" transform="translate(2 2.5)"/>
              </clipPath>
          </defs>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'ArmorIpIcon')

export default icon
