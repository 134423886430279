import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchGroupsScenes = (access_token) => Axios.get(`${API_URL}/api/groups/index_without_devices`, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchGroupsScenes,
}
