import { SYNC_COUNTERS_CHANNEL } from '@app/constants/channels'
import { closeChannel, openChannel } from '@app/actions/syncCounters/countersChannel'
import openCable from '../cable'

const syncCountersChannel = ({ request, response }) => {
  const cable = openCable()
  let subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find((sub) => sub.identifier.includes('SyncCountersChannel'))
  if (!subscription) {
    subscription = cable.subscriptions.create(SYNC_COUNTERS_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(openChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeChannel())
      },
    })
  }
  return subscription
}

export default syncCountersChannel
