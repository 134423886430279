import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9844 0C16.5156 0 16.9844 0.203125 17.3906 0.609375C17.7969 1.01562 18 1.48438 18 2.01562V20.0156C18 20.5469 17.7969 21.0156 17.3906 21.4219C16.9844 21.8281 16.5156 22.0312 15.9844 22.0312H6C5.46875 22.0312 5 21.8281 4.59375 21.4219C4.1875 21.0156 3.98438 20.5469 3.98438 20.0156V17.0156H6V19.0312H15.9844V3H6V5.01562H3.98438V2.01562C3.98438 1.48438 4.1875 1.01562 4.59375 0.609375C5 0.203125 5.46875 0 6 0H15.9844ZM9.98438 7.21875L6 11.2031L9.98438 15.2344L9 16.2188L5.01562 12.2344L0.984375 16.2188L0 15.2344L3.98438 11.2031L0 7.21875L0.984375 6.23438L5.01562 10.2188L9 6.23438L9.98438 7.21875Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'DeviceExcludedIcon')

export default icon
