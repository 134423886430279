import * as React from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { useDispatch, useSelector } from 'react-redux'
import { saveCurrentPage, sync } from '@app/actions/events'

import useStyles from './Pagination.style'

export default function PaginationControlled() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { countPages, currentPage } = useSelector((state) => state.events)

  const handleChange = (event, value) => {
    dispatch(saveCurrentPage(value))
    dispatch(sync())
  }

  return (
    <Stack spacing={2} className={classes.main}>
      <Pagination count={countPages} page={currentPage} onChange={handleChange} />
    </Stack>
  )
}
