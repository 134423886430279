import React, { useEffect, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import getTimerDurationDescription from '@app/helpers/timers/getTimerDurationDescription'
import getTimerType from '@app/helpers/timers/getTimerType'
import {
  SELECT_ACTION_TIMER,
  SELECT_CONDITION_TIMER,
  TIMERS_IN_SCENES_RESET,
} from '@app/constants/routes'
import {
  saveTimerData,
  setCurrentTrigger,
  syncTimers,
  timerTrigger,
  unsubscribeTimerChannel,
} from '@app/actions/timers/timers'
import { ChevronLeft, ReloadCircle, ResetGear } from 'icons'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import ProgressPopup from '@app/components/Common/ProgressPopup/ProgressPopup'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
// @ts-ignore
import { ITimer } from '@app/@types/timers/timersTypes'
import { IScene } from '@app/@types/scenes/scenes'
// @ts-ignore
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import useStyles from './TimersInScenes.style'

interface Props {
  timers: Array<ITimer>,
  timerData: ITimer,
  isMobile: boolean,
  isCreateConditionMode: boolean,
  isCreateActionMode: boolean,
  isEditConditionMode: boolean,
  isEditActionMode: boolean,
}

const TimersInScenesIOS: React.FC<Props> = (props) => {
  const {
    timers, timerData, isMobile,
    isCreateConditionMode, isCreateActionMode, isEditConditionMode, isEditActionMode,
  } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [timersList, setTimersList] = useState<Array<ITimer>>(timers)
  const [timerStopPopup, setTimerStopPopup] = useState<boolean>(false)
  const [timerStopProgress, setTimerStopProgress] = useState<boolean>(false)

  useEffect(() => setTimersList(timers), [])

  useEffect(() => {
    setTimersList(timers)
    const progressTimer = timers.find((timer: ITimer) => timer.number === timerData.number)
    if (timerStopProgress && progressTimer?.trigger_status === 'success') {
      navigate(`/web/scenes/timers/${timerData.number}/settings`)
    }
  }, [timers])

  useEffect(() => () => {
    dispatch(unsubscribeTimerChannel())
  }, [])

  const setCurrentTimer = (timer: ITimer) => {
    dispatch(saveTimerData({
      number: timer.number,
      type: timer.timer_type === 'no_timer' ? 'one_shot' : timer.timer_type,
      duration: timer.duration,
      scenes: timer.scenes,
    }))
  }

  const getTimerName = (number: number, status: string): string => {
    let currentName = `${I18n.t(`timers.timer_${number}`)}`
    if (status === 'commit_to_mezzo') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (saving)`
    } else if (status === 'not_saved') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (not saved)`
    } else if (status === 'deleting') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (deleting)`
    }
    return currentName
  }

  const getTimerScenesNames = (scenesList: Array<IScene>): string => {
    if (scenesList) {
      return (scenesList.length ? scenesList.map((scene) => scene.name).join(', ') : I18n.t('timers.n_a'))
    }
    return I18n.t('timers.n_a')
  }

  const goToTimerSettingsPage = (timer: ITimer, index: number) => {
    if (timer.trigger_status === 'progress') {
      return
    }
    if (timer.trigger_status === 'success' && timer.state === 'start') {
      setCurrentTimer(timer)
      setTimerStopPopup(true)
    } else {
      setCurrentTimer(timer)
      navigate(`/web/scenes/timers/${index}/settings`)
    }
  }

  const onBackClick = () => {
    if (isCreateConditionMode || isEditConditionMode) {
      navigate(SELECT_CONDITION_TIMER)
    } else if (isCreateActionMode || isEditActionMode) {
      navigate(SELECT_ACTION_TIMER)
    }
  }

  return (
    <>
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
        <Wrapper className={classes.iconWrapper}>
          <Wrapper className={classes.rightSideIcons}>
            <IconButton
              size="small"
              onClick={() => navigate(TIMERS_IN_SCENES_RESET)}
              aria-label="reset"
            >
              <ResetGear />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(syncTimers())
              }}
              aria-label="reload"
            >
              <ReloadCircle />
            </IconButton>
          </Wrapper>
        </Wrapper>
        {timers.length ? (
          <Wrapper className={classNames(classes.settingsGrid, isMobile && classes.settingsGridMobile)}>
            {timersList.map((timer, index) => (
              <Wrapper
                className={classNames(classes.setting,
                  isMobile && classes.settingMobile,
                  timer.trigger_status === 'progress' && classes.settingDisabled,
                  timer.status !== 'complete' && classes.settingCentered)}
                key={`timer-${index + 1}`}
                onClick={() => {
                  goToTimerSettingsPage(timer, index + 1)
                }}
              >
                <Wrapper className={classes.cardLeftSide}>
                  <Typography className={classes.timerTitle}>{getTimerName(timer.number, timer.status)}</Typography>
                  <Typography className={classes.timerType}>
                    {`${I18n.t('timers.type')}: ${timer.timer_type === 'no_timer' ? I18n.t('timers.n_a') : getTimerType(timer.timer_type)}`}
                  </Typography>
                  <Typography className={classNames(classes.timerDuration,
                    ((timer.timer_type === 'one_shot' || timer.timer_type === 'repeating') && timer.status === 'complete'))}
                  >
                    {`${I18n.t('timers.duration')}: ${getTimerDurationDescription(timer.timer_type, timer.duration)}`}
                  </Typography>
                  <Typography className={classes.timerType}>
                    {`${I18n.t('timers.scenes')}:
                   ${timer.timer_type === 'no_timer' ? I18n.t('timers.n_a') : getTimerScenesNames(timer.scenes)}`}
                  </Typography>
                  {((timer.timer_type === 'one_shot' || timer.timer_type === 'repeating') && timer.status === 'complete')
                    && (
                      <Wrapper className={classes.activityButtons}>
                        {(timer.state === 'stop' && timer.trigger_status !== 'progress') && (
                          <Typography
                            color="primary"
                            className={classes.button}
                            onClick={(event) => {
                              event.stopPropagation()
                              setCurrentTimer(timer)
                              dispatch(setCurrentTrigger('start'))
                              dispatch(timerTrigger())
                            }}
                          >
                            START
                          </Typography>
                        )}
                        {(timer.state === 'start' && timer.trigger_status !== 'progress') && (
                          <Typography
                            color="primary"
                            className={classes.button}
                            onClick={(event) => {
                              event.stopPropagation()
                              setCurrentTimer(timer)
                              dispatch(setCurrentTrigger('stop'))
                              dispatch(timerTrigger())
                            }}
                          >
                            STOP
                          </Typography>
                        )}
                      </Wrapper>
                    )}
                  {timer.trigger_status === 'progress' && (
                    <DotsLoading
                      className={classes.progressDots}
                      props={{
                        width: 48, circleWidth: 15, circleHeight: 15, circleColor: '#fff',
                      }}
                    />
                  )}
                </Wrapper>
                <ChevronLeft className={classes.chevronIcon} />
              </Wrapper>
            ))}
          </Wrapper>
        )
          : (
            <Wrapper className={classNames(classes.settingsGrid, isMobile && classes.settingsGridMobile)}>
              {[...Array(8)].map((timer, index) => (
                <Wrapper
                  className={classNames(classes.setting, isMobile && classes.settingMobile)}
                  key={`timer-${index + 1}`}
                  onClick={() => {
                    dispatch(saveTimerData({
                      number: index + 1,
                      type: 'no_timer',
                      duration: {
                        day: '',
                        hour: '',
                        minute: '',
                        second: '',
                      },
                      scenes: [],
                    }))
                    goToTimerSettingsPage(timer, index + 1)
                  }}
                >
                  <Wrapper className={classes.cardLeftSide}>
                    <Typography className={classes.timerTitle}>{`${I18n.t(`timers.timer_${index + 1}`)}`}</Typography>
                    <Typography className={classes.timerType}>{`${I18n.t('timers.type')}: ${I18n.t('timers.n_a')}`}</Typography>
                    <Typography className={classes.timerDuration}>{`${I18n.t('timers.duration')}: ${I18n.t('timers.n_a')}`}</Typography>
                  </Wrapper>
                  <ChevronLeft className={classes.chevronIcon} />
                </Wrapper>
              ))}
            </Wrapper>
          )}
      </Wrapper>
      <WarningComponent
        open={timerStopPopup}
        warningText="timers.edit_stop_timer"
        onClose={() => {
          setTimerStopPopup(!timerStopPopup)
        }}
        onClick={() => {
          dispatch(setCurrentTrigger('stop'))
          dispatch(timerTrigger())
          setTimerStopPopup(!timerStopPopup)
          setTimerStopProgress(true)
        }}
        rightButton="cancel"
        leftButton="okay"
      />
      {timerStopProgress && (<ProgressPopup text={I18n.t('timers.stop_timer')} />)}
    </>
  )
}

export default TimersInScenesIOS
