import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_BILLING_SETTING } from '@app/constants/myEnergy/setUpBillingPeriod'
import { updateBillingSettings } from '@app/actions/myEnergy/setUpBillingPeriod'
import Api from '@app/api/myEnergy/fetchBillingSetting'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchBillingSettingLogic = createLogic({
  type: SYNC_BILLING_SETTING,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_BILLING_SETTING))
    Api.fetchBillingSetting(AccessToken)
      .then((response) => {
        const { data: { billing_period_setting } } = response
        dispatch(updateBillingSettings(billing_period_setting))
        dispatch(apiRequestDone(SYNC_BILLING_SETTING))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchBillingSetting failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchBillingSettingLogic
