import Axios from 'axios'

import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/unit_devices/start_sync`

const startSyncCounters = (access_token) => Axios.get(ENDPOINT, {
  headers: { 'Access-Token': access_token },
})

export default {
  startSyncCounters,
}
