import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import {
  Button, Typography,
} from '@mui/material'
import { syncGuardPanelChannel } from '@app/actions/guardPanel'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { useDispatch } from '@app/store'
import { Status } from '@app/lib/guardPanel/guardPanel'
import { BUTTON } from '@app/lib/MySecurity/security'
import { EVENTS } from '@app/constants/routes'
import {
  EventsIcon,
  PanicIcon,
  ArmHomeModeIcon,
  UnSealedSecurityIcon,
  SealedSecurityIcon,
} from 'icons'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import {
  startPanic, cancelPanic, setArmHomeMode,
} from '@app/actions/mySecurity/mySecurity'
import { sync as syncEvents } from '@app/actions/events'
import { getNotificationForStatusBar } from '@app/helpers/notifications/getNotificationForStatusBar'
// @ts-ignore
import { iconMap } from '@app/lib/Events/icons'
import { ITwrDevice } from '@app/@types/myStatus'
import ArmDisarmBlock from '@app/components/MySecurity/ButtonsBlock/ArmDisarmBlock'
import PanicWarning from '../PanicWarning/PanicWarning'
import useStyles from './ButtonsBlock.style'

interface Props {
  isMobile: boolean,
}

const ButtonsBlock: React.FC<Props> = (props) => {
  const {
    isMobile,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { status } = useSelector((state: AppState) => state.guardPanel)
  const { twrDevices } = useSelector((state: AppState) => state.myStatus.myStatusDevices)
  const { online } = useSelector((state: AppState) => state.unit)
  const { isPanicPopupOpen } = useSelector((state: AppState) => state.mySecurity)
  const { notifications } = useSelector((state: AppState) => state.notifications.notifications)
  const { events } = useSelector((state: AppState) => state.events)
  const selectedSerialnumber = useSelector((state: AppState) => state.unit.selectedUnit)
  const isUnSealed = twrDevices.some((device: ITwrDevice) => !device.status)
  const currentNotification = getNotificationForStatusBar(notifications, selectedSerialnumber)
  const currentEvent = currentNotification || events[0]

  useEffect(() => {
    if (online) {
      dispatch(syncGuardPanelChannel())
    }
  }, [online])

  useEffect(() => {
    dispatch(syncEvents())
  }, [])

  const onPanicCLick = () => {
    dispatch(startPanic())
  }

  const onCancelPanicClick = () => {
    dispatch(cancelPanic())
  }

  const onEventsCLick = () => {
    navigate(EVENTS)
  }

  const onArmHomeModeCLick = () => {
    dispatch(setArmHomeMode())
  }

  return (
    <Wrapper className={classNames(
      classes.main,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        <Wrapper className={classes.eventWindow}>
          {currentEvent && (
            <>
              {isUnSealed
                ? <UnSealedSecurityIcon className={classes.unSealedIcon} />
                : <SealedSecurityIcon className={classes.sealedIcon} />}
              <Typography>{currentEvent.message}</Typography>
              {iconMap[currentEvent.icon]}
            </>
          )}
        </Wrapper>
      </Wrapper>
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        <Button
          className={classNames(classes.buttonFirstLine,
            classes.pink,
            isMobile && classes.mobileButtonFirstLine)}
          onClick={onPanicCLick}
          key={BUTTON.panic}
        >
          { !isMobile && <PanicIcon />}
          <Typography>{I18n.t(`security.${BUTTON.panic}`)}</Typography>
        </Button>
        <Button
          className={classNames(classes.buttonFirstLine,
            classes.green,
            isMobile && classes.mobileButtonFirstLine)}
          onClick={onEventsCLick}
          key={BUTTON.events}
        >
          { !isMobile && <EventsIcon />}
          <Typography>{I18n.t(`security.${BUTTON.events}`)}</Typography>
        </Button>
        <Button
          className={classNames(classes.buttonFirstLine,
            classes.red,
            isMobile && classes.mobileButtonFirstLine)}
          onClick={onArmHomeModeCLick}
          disabled={status !== Status.SIMPLE_DISARMED}
          key={BUTTON.armHomeMode}
        >
          { !isMobile && <ArmHomeModeIcon />}
          <Typography className={classNames(isMobile && classes.mobileArmHomeText)}>{I18n.t(`security.${BUTTON.armHomeMode}`)}</Typography>
        </Button>
      </Wrapper>
      <Wrapper className={classNames(classes.lineWrapper, isMobile && classes.lineWrapperMobile)}>
        <ArmDisarmBlock type={1} />
      </Wrapper>
      {isPanicPopupOpen && (
        <PanicWarning
          open={isPanicPopupOpen}
          onCancelClick={() => {
            onCancelPanicClick()
          }}
        />
      )}
    </Wrapper>
  )
}

export default ButtonsBlock
