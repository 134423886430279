import React from 'react'
import { AlphaPicker } from 'react-color'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './WhitePicker.style'
import PickerPointer from './PickerPointer'

class WhitePicker extends React.PureComponent {
  render() {
    const {
      classes,
      changeColor,
      color,
      colorTemperature,
    } = this.props
    return (
      <Wrapper className={classes.pickerContainer}>
        <Typography>{`White ${colorTemperature}`}</Typography>
        <Wrapper className={classes.sliderContainer}>
          <AlphaPicker className={classes.slider} width="100%" color={color} onChange={changeColor} pointer={PickerPointer} />
        </Wrapper>
      </Wrapper>
    )
  }
}

WhitePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  changeColor: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  colorTemperature: PropTypes.string.isRequired,
}

export default withStyles(styles)(WhitePicker)
