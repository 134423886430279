import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  titleWrapper: {
    marginRight: 30,
  },
  title: {
    fontSize: 22,
  },
  ssid: {
    fontSize: 12,
  },
  buttonWrapper: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-around',
    width: '50%',
  },
  button: {
    margin: '20px 10px 20px 10px',
    width: '100%',
  },
  closeIconButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  text: {
    maxWidth: 380,
    whiteSpace: 'pre-line',
  },
}))

export default useStyles
