import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { ListItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './EditText.style'
import EditTextField from './EditTextField'

class EditText extends React.PureComponent {
  render() {
    const {
      classes,
      title,
      name,
      hint,
      placeholder,
      autoComplete,
      ...other
    } = this.props
    return (
      <ListItem disableGutters className={classes.root}>
        <Field
          className={classes.input}
          name={name}
          hint={hint}
          title={title}
          placeholder={placeholder}
          autoComplete={autoComplete}
          component={EditTextField}
          {...other}
        />
      </ListItem>
    )
  }
}

EditText.propTypes = {
  classes: PropTypes.object.isRequired,
  hint: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
}

EditText.defaultProps = {
  hint: null,
}
export default (withStyles(styles)(EditText))
