import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0C8.974 0 0 8.973 0 20C0 31.027 8.974 40 20 40C31.029 40 40 31.027 40 20C40 8.973 31.029 0 20 0ZM23.439 31.749C23.439 32.797 22.588 33.645 21.543 33.645H18.457C17.408 33.645 16.56 32.796 16.56 31.749V28.498C16.56 27.452 17.411 26.602 18.457 26.602H21.543C22.59 26.602 23.439 27.455 23.439 28.498V31.749ZM23.439 22.809C23.439 23.856 22.588 24.705 21.543 24.705H18.457C17.408 24.705 16.56 23.855 16.56 22.809V12.514C16.56 11.465 17.411 10.618 18.457 10.618H21.543C22.59 10.618 23.439 11.467 23.439 12.514V22.809Z" fill="#FF0000"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'MyDevicesWarningIcon', '0 0 40 40')

export default icon
