import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_PIN_CODE } from '@app/constants/users/secretCode'
import { openShowPinPopup, savePinCode } from '@app/actions/users/secretCode'
import Api from '@app/api/users/getPinCode'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchPinCode = createLogic({
  type: GET_PIN_CODE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { users: { currentUser: { user: { ids: { sphere: sphereId } } } } } = getState()
    next({
      ...action,
      token,
      sphereId,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_PIN_CODE))
    Api.getPinCode(action.token, action.sphereId, action.formData)
      .then((response) => {
        dispatch(apiRequestDone(GET_PIN_CODE))
        dispatch(savePinCode(response.data.pin))
        dispatch(openShowPinPopup())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('GET_PIN_CODE failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchPinCode
