import React, {
  useState, useEffect,
} from 'react'
import {
  Typography,
  LinearProgress,
  IconButton,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import { useNavigate, useParams } from 'react-router-dom'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import {
  syncFailedNodeChannel,
  closeFailedNodeChannel,
} from '@app/actions/myDevices/channels/deviceSettings/failedNodeChannel'
import TwoButtonsPopup from '@app/components/Common/TwoButtonsPopup/TwoButtonsPopup'
import {
  cancelReplaceDevice,
  replaceUnhealthyDevice,
  resetFailedNodeData,
  resetFailedNodeStatus,
} from '@app/actions/myDevices/deviceSettings/failedNode'
import { ZWaveDeviceBackIcon, ScenesArrowBack } from 'icons'
import useStyles from './ReplaceDevice.style'

interface Props {
  status: string,
  isMobile: boolean,
}

const ReplaceDevice: React.FC<Props> = (props) => {
  const { status, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { deviceId } = useParams<{ deviceId: string }>()
  const [isReplaced, setIsReplaced] = useState<boolean>(false)
  const [mezzoReady, setMezzoReady] = useState<boolean>(false)
  const [progressState, setProgressState] = useState<string>('')

  useEffect(() => {
    dispatch(syncFailedNodeChannel())
    return () => {
      dispatch(closeFailedNodeChannel())
      dispatch(cancelReplaceDevice())
    }
  }, [])

  useEffect(() => {
    if (status === 'success') {
      setIsReplaced(true)
    }
    if (status === 'pending') {
      setMezzoReady(true)
    }
    setProgressState(status)
  }, [status])

  useEffect(() => {
    if (isReplaced === true) {
      goBackHandler()
    }
  }, [isReplaced])

  const clearFailedNodeData = () => {
    dispatch(resetFailedNodeData())
  }

  const tryAgainReplaceDevice = () => {
    dispatch(resetFailedNodeStatus())
    setMezzoReady(false)
    dispatch(replaceUnhealthyDevice(Number(deviceId)))
  }

  const renderWaitingForMezzoAnswer = () => (
    <>
      <ZWaveDeviceBackIcon className={classNames(classes.zWaveIcon, isMobile && classes.zWaveIconMobile)} />
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile)}>
        <LinearProgress />
        <Typography className={classes.title}>{I18n.t('my_devices.mezzo_prep')}</Typography>
        <Typography className={classNames(classes.subText, classes.subTitle)}>
          {I18n.t('my_devices.wait_for_voice')}
        </Typography>
      </Wrapper>
    </>
  )

  const renderReadyToReplaceDevice = () => (
    <>
      <ZWaveDeviceBackIcon className={classNames(classes.zWaveIcon, isMobile && classes.zWaveIconMobile)} />
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile)}>
        <Wrapper className={classes.statusContainer}>
          {status && status === 'pending' && <LinearProgress className={classes.progressBar} />}
        </Wrapper>
        <Typography className={classes.title}>{I18n.t('my_devices.ready_to_learn_zwave_device')}</Typography>
        <Typography className={classNames(classes.subText, classes.subTitle)}>
          {I18n.t('my_devices.refer_to_your_devices_instructions')}
        </Typography>
        <Typography className={classNames(classes.subText, classes.subTitle)}>
          {I18n.t('my_devices.plug_in_zwave_device')}
        </Typography>
      </Wrapper>
    </>
  )

  const goBackHandler = () => {
    navigate(-1)
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classNames(classes.navigationControls, isMobile && classes.navigationControlsMobile)}>
        <IconButton size="small" className={classes.arrowBack} onClick={goBackHandler}>
          <ScenesArrowBack />
        </IconButton>
        <Typography>{I18n.t('failedNode.replace_bad_device')}</Typography>
      </Wrapper>
      <Wrapper className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}>
        {!mezzoReady && renderWaitingForMezzoAnswer()}
        {mezzoReady && renderReadyToReplaceDevice()}
      </Wrapper>
      <TwoButtonsPopup
        open={progressState === 'timeout'}
        withCloseIcon={false}
        title="titles.title_error"
        text="failedNode.timeout_ended"
        firstButtonName="buttons.cancel"
        secondButtonName="buttons.try_again"
        firstButtonOnClick={() => {
          clearFailedNodeData()
        }}
        secondButtonOnClick={() => {
          tryAgainReplaceDevice()
        }}
      />
      <TwoButtonsPopup
        open={progressState === 'failed'}
        withCloseIcon={false}
        title="titles.title_error"
        text="failedNode.failed_device"
        firstButtonName="buttons.cancel"
        secondButtonName="buttons.try_again"
        firstButtonOnClick={() => {
          clearFailedNodeData()
        }}
        secondButtonOnClick={() => {
          tryAgainReplaceDevice()
        }}
      />
    </Wrapper>
  )
}

export default ReplaceDevice
