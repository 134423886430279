import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_CONDITION_OPERAND } from '@app/constants/scenes/currentSceneCreating'
import { clearCurrentConditionInfo } from '@app/actions/scenes/currentSceneCreating'
import Api from '@app/api/scenes/patchCondition'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchConditionOperandLogic = createLogic({
  type: PATCH_CONDITION_OPERAND,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { scenes: { currentSceneCreating: { currentCondition: { id, operand } } } } = getState()
    const data = { operand }
    next({
      ...action,
      token,
      id,
      data,
    })
  },
  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_CONDITION_OPERAND))
    Api.patchCondition(action.token, action.id, action.data)
      .then(() => {
        dispatch(apiRequestDone(PATCH_CONDITION_OPERAND))
        dispatch(clearCurrentConditionInfo())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchCondition failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchConditionOperandLogic
