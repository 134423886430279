import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import AddTwrDevice from '@app/components/MyDevices/AddDevice/AddTwrDevice/AddTwrDevice'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = AddTwrDevice

const mapStateToProps = (state: AppState) => {
  const { interviewResult, isLoading } = state.myDevices.myDevices
  const { isMobile } = state.mobileDetector
  return {
    interviewResult,
    isLoading,
    isMobile,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const AddTwrDeviceEnhanced = enhance

export default AddTwrDeviceEnhanced
