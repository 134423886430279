import { createLogic } from 'redux-logic'
import { SYNC_SCENES } from '@app/constants/scenes/scenesList'
import { update } from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/fetchScenes'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchScenesLogic = createLogic({
  type: SYNC_SCENES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_SCENES))
    Api.fetchScenes(token)
      .then((response) => {
        const { data: { scenes } } = response
        dispatch(update(scenes))
        dispatch(apiRequestDone(SYNC_SCENES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchScenes failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchScenesLogic
