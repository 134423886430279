const styles = (theme) => ({
  main: {
    position: 'relative',
    padding: 40,
    paddingBottom: 122,
  },
  mainMobile: {
    padding: 20,
    paddingTop: 40,
  },
  webView: {
    background: theme.webView.background,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainTitle: {
    fontSize: '18px',
    margin: '20px 0',
  },
  currentVersion: {
    margin: '5px',
  },
  currentVersionMobile: {
    marginTop: '10px',
  },
  newVersion: {
    margin: '5px',
    color: theme.palette.primary.main,
  },
  titlesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  downloadTitle: {
    marginTop: '45px',
    marginBottom: '20px',
    color: theme.firmwareUpdate.title,
  },
  downloadTitleMobile: {
    marginTop: '25px',
  },

  additionalSettingsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
  },
  downloadButton: {
    width: '100%',
    marginBottom: 20,
  },
  disabledText: {
    color: theme.firmwareUpdate.disabledElement,
  },
  progress: {
    marginTop: 30,
    marginBottom: 100,
    width: '95%',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  installElementsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 50,
    marginBottom: 30,
  },
  pausedButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 30,
    marginBottom: 30,
  },
  pausedTitle: {
    marginLeft: '45%',
  },
  resetButton: {
    width: 'fit-content',
    textDecoration: 'underline',
  },

  langButton: {
    backgroundColor: theme.firmwareUpdate.buttons.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.firmwareUpdate.buttons.hover,
    },
    '&:active': {
      backgroundColor: theme.firmwareUpdate.buttons.active,
    },
    '&:focus': {
      boxShadow: theme.firmwareUpdate.buttons.focus,
    },
  },
  langButtonMobile: {
    width: 40,
    height: 40,
    padding: 0,
  },
  firmwareWaitingText: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  progressHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rebootButton: {
    marginLeft: 20,
    textDecoration: 'underline',
  },
})

export default styles
