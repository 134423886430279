import * as type from '@app/constants/scenes/twrDevices/twrDevicesByGroups'
import { IGroupTwrBackend } from '@app/@types/scenes/scenesDevices'

export const syncTwrDevicesByGroups = () => ({
  type: type.SYNC_TWR_DEVICES_BY_GROUPS,
} as const)

export const updateTwrDevicesByGroups = (groups: Array<IGroupTwrBackend>) => ({
  type: type.UPDATE_TWR_DEVICES_BY_GROUPS,
  groups,
} as const)

export const resetTwrDevicesByGroups = () => ({
  type: type.RESET_TWR_DEVICES_BY_GROUPS,
} as const)
