import {
  saveConditionDescription,
  saveConditionDeviceId,
  saveConditionDeviceName,
  saveConditionDeviceNumber,
  saveConditionType,
  saveConditionZWaveCapabilityId,
  saveSubDevicesAmount,
  saveWallmoteSettings,
} from '@app/actions/scenes/currentSceneCreating'
import { syncTwrDeviceStates } from '@app/actions/scenes/twrDevices/twrDevices'
import { syncZWaveDeviceCapabilities } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { useDispatch } from '@app/store'

const withSelectDeviceConditionBehavior = (deviceType: string, device: any) => {
  const dispatch = useDispatch()

  const selectDevice = () => {
    if (deviceType === 'TwrDevices') {
      dispatch(saveConditionDescription(device.device_type))
      dispatch(saveConditionDeviceNumber(device.number))
      dispatch(saveConditionDeviceName(device.name))
      dispatch(saveConditionDeviceId(device.id))
      dispatch(saveConditionType('TwrDevices'))
      dispatch(syncTwrDeviceStates())
    } else if (deviceType === 'ZWaveDevices') {
      dispatch(saveConditionDeviceName(device.name))
      dispatch(saveConditionDeviceNumber(device.number))
      dispatch(saveConditionZWaveCapabilityId(device.capability_id))
      dispatch(saveConditionDeviceId(device.id))
      dispatch(saveSubDevicesAmount(device.wallmote_buttons || device.sub_device_amount))
      dispatch(saveWallmoteSettings(device.button_settings))
      dispatch(syncZWaveDeviceCapabilities())
    } else {
      throw new Error('condition unsupported')
    }
  }
  return selectDevice
}

export default withSelectDeviceConditionBehavior
