import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ClearIcon } from 'icons'

import styles from './EditTextField.style'

class EditTextField extends React.PureComponent {
  createAdorment =() => {
    const {
      resetField,
      classes,
    } = this.props
    return (
      <InputAdornment position="start">
        <IconButton onClick={resetField} aria-label="Delete" size="large">
          <ClearIcon className={classes.icon} />
        </IconButton>
      </InputAdornment>
    )
  }

  createTextField =() => {
    const {
      input,
    } = this.props
    return (
      <TextField
        variant="standard"
        onChange={(event, index, value) => input.onChange(value)}
        InputProps={{
          endAdornment: this.createAdorment(),
          ...input,
        }}
      />
    )
  }

  hintControl =() => {
    const {
      hint,
    } = this.props
    if (hint) {
      return (
        <Tooltip title={hint}>{this.createTextField()}</Tooltip>
      )
    }
    return (
      this.createTextField()
    )
  }

  render() {
    const {
      meta: { touched, error },
      classes,
    } = this.props
    return (
      <FormControl variant="standard" className={classes.input}>
        {this.hintControl()}
        {touched && error && (
          <FormHelperText id="component-error-text" className={classes.text}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

EditTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  resetField: PropTypes.func.isRequired,
  hint: PropTypes.string,
}

EditTextField.defaultProps = {
  hint: null,
}

export default (withStyles(styles)(EditTextField))
