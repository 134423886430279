import * as type from '@app/constants/myEnergy/billingPeriod'

export const syncPeriodsStats = () => ({
  type: type.SYNC_BILLING_PERIODS_STATS,
})

export const updateBillingPeriodsStats = (billingPeriodsStats) => ({
  type: type.UPDATE_BILLING_PERIODS_STATS,
  stats: billingPeriodsStats,
})

export const resetAllBillingPeriods = () => ({
  type: type.RESET_ALL_BILLING_PERIODS,
})
