import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveBinarySensorValueDescription,
  saveConditionDeviceStateInfo,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectBinarySensorValue
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/BinarySensorConditions/SelectBinarySensorValue/SelectBinarySensorValue'
import SelectBinarySensorValueIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/BinarySensorConditions/SelectBinarySensorValue/SelectBinarySensorValue'

import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectBinarySensorValueIOS) : withStyles(SelectBinarySensorValue)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { binarySensorValues } = state.scenes.zWaveDevices
  const { isChangeSensorStateMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    isChangeSensorStateMode,
    sensors: binarySensorValues,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveBinarySensorValueDescription,
  saveConditionDeviceStateInfo,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectBinarySensorValueEnhanced = enhance

export default withRouter(SelectBinarySensorValueEnhanced)
