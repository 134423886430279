import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M56.2539 85.8359H56.1253L56.0506 85.9405C50.2523 94.0437 47.8369 105.505 49.2279 116.233C50.6191 126.963 55.8248 137.003 65.3214 142.219L65.3776 142.249H65.4417H85.1044H85.1688L85.2251 142.218C94.6991 136.994 99.9047 126.952 101.301 116.223C102.698 105.496 100.294 94.0393 94.4955 85.9404L94.4207 85.8359H94.2922H56.2539ZM96.8391 115.642C95.6174 125.026 91.2743 133.272 83.9609 137.749H66.5811C59.25 133.281 54.9073 125.039 53.6905 115.655C52.5249 106.665 54.3328 97.2609 58.5386 90.3359H92.0079C96.2102 97.2543 98.0095 106.652 96.8391 115.642Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.2981 82.467H108.887L91.9148 9H51.7136L29.2981 82.467V82.467Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M102.041 9L126.798 82.467H132.688L104.821 9H102.041V9Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.5781 82.467L48.3799 9H44.8674L17 82.467H23.5781V82.467Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M122.16 82.467H113.901L95.0341 9H99.2705L122.16 82.467V82.467Z" fill="currentColor"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwLampIcon')

export default icon
