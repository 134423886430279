export const takeOnBoardDeviceTypes = [1, 2, 3, 4, 6]

export const takeOnBoardDeviceTypesList = {
  1: 'motion_detector',
  2: 'glass_break',
  3: 'reed_switch',
  4: 'smoke_alarm',
  6: 'environment_sensor',
}

export const withoutTakeOnBoard = -1
