import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path fill="currentColor" d="M64.452 16.484V0H0v67.113C0 70.359 2.677 73 5.968 73h62.064C71.322 73 74 70.359 74 67.113v-50.63h-9.548zM5.968 70.645c-1.974 0-3.58-1.585-3.58-3.532V2.355h59.677v64.758c0 1.33.466 2.546 1.224 3.532H5.968zm65.645-3.532c0 1.947-1.607 3.532-3.58 3.532-1.975 0-3.581-1.585-3.581-3.532V18.839h7.16v48.274z"/>
      <path fill="currentColor" d="M21.483 18.839c-1.316 0-2.387-1.056-2.387-2.355s1.07-2.355 2.387-2.355 2.387 1.056 2.387 2.355h2.387c0-2.188-1.526-4.016-3.58-4.543V9.42H20.29v2.522c-2.055.527-3.581 2.355-3.581 4.543 0 2.597 2.141 4.71 4.774 4.71 1.317 0 2.387 1.056 2.387 2.354 0 1.299-1.07 2.355-2.387 2.355-1.316 0-2.387-1.056-2.387-2.355H16.71c0 2.188 1.526 4.017 3.58 4.543v2.522h2.388V28.09c2.054-.527 3.58-2.355 3.58-4.543 0-2.597-2.14-4.71-4.774-4.71z"/>
      <path fill="currentColor" d="M4.773 4.71v30.613h33.42V4.71H4.773zm31.033 28.258H7.16V7.065h28.645v25.903zM57.29 65.936v-18.84h-7.16v18.84h-4.775v-14.13h-7.161v14.13h-4.775v-18.84h-7.16v18.84H23.87v2.354h35.807v-2.355H57.29zm-26.258 0h-2.387V49.452h2.387v16.484zm11.936 0H40.58V54.16h2.387v11.775zm11.935 0h-2.387V49.452h2.387v16.484zM59.677 4.71H40.58v2.355h19.097V4.71zM59.677 9.42H40.58v2.354h19.097V9.42zM59.677 14.129H40.58v2.355h19.097v-2.355zM59.677 18.839H40.58v2.355h19.097v-2.355zM54.903 23.548H40.58v2.355h14.323v-2.355zM59.678 23.548h-2.387v2.355h2.387v-2.355zM23.87 37.677H4.773v2.355H23.87v-2.355zM23.87 42.387H4.773v2.355H23.87v-2.355zM23.87 47.097H4.773v2.355H23.87v-2.355zM23.87 51.806H4.773v2.355H23.87v-2.355zM23.872 56.516h-2.388v2.355h2.387v-2.355zM19.096 56.516H4.773v2.355h14.323v-2.355z"/>
      <path fill="currentColor" d="M53.71 37.677c-1.975 0-3.581 1.585-3.581 3.533 0 .048.012.091.014.139l-5.693 2.246a3.579 3.579 0 0 0-2.676-1.208c-1.07 0-2.02.473-2.676 1.21l-5.693-2.248c.002-.047.014-.091.014-.14 0-1.947-1.606-3.532-3.58-3.532-1.975 0-3.581 1.585-3.581 3.533 0 1.947 1.606 3.532 3.58 3.532 1.07 0 2.02-.473 2.676-1.21l5.694 2.247c-.003.048-.015.092-.015.14 0 1.948 1.607 3.533 3.58 3.533 1.975 0 3.582-1.585 3.582-3.533 0-.048-.012-.091-.015-.139l5.693-2.246a3.579 3.579 0 0 0 2.676 1.208c1.974 0 3.581-1.585 3.581-3.532 0-1.948-1.606-3.533-3.58-3.533zm-23.872 4.71a1.187 1.187 0 0 1-1.193-1.177c0-.65.535-1.178 1.194-1.178.658 0 1.193.529 1.193 1.178 0 .648-.535 1.177-1.194 1.177zm11.936 4.71a1.187 1.187 0 0 1-1.194-1.178c0-.648.535-1.177 1.194-1.177.659 0 1.194.529 1.194 1.177 0 .65-.535 1.178-1.194 1.178zm11.935-4.71a1.187 1.187 0 0 1-1.193-1.177c0-.65.535-1.178 1.193-1.178.66 0 1.194.529 1.194 1.178 0 .648-.535 1.177-1.194 1.177zM31.032 18.839h-2.387v2.355h2.387v-2.355zM14.323 18.839h-2.388v2.355h2.388v-2.355z"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BillingStatsIcon')

export default icon
