import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem 2rem 70px 2rem',
    minHeight: 500,
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem .5rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigationTitle: {
    marginTop: 5,
    marginLeft: 75,
  },
  navigationLeftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  goBackIcon: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  grid: {
    padding: '40px 0',
    display: 'grid',
    justifyItems: 'center',
    width: '100%',
    maxWidth: 720,
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridGap: 20,
    margin: '0 auto',
  },
  gridElement: {
    width: '100%',
    maxWidth: 335,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    backgroundColor: theme.backgrounds.cardBackground,
    borderRadius: 6,
    padding: '19px 15px',
  },
  button: {
    border: 'none',
  },
  addButton: {
    position: 'fixed',
    bottom: 30,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    zIndex: 101,
  },
  initialAddButton: {
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.primary.main,
    color: theme.backgrounds.cardBackground,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  emptyError: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    width: '100%',
    height: '100%',
  },
}))

export default useStyles
