import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import {
  Button, List, Paper, Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import {
  setValidation, set, setCurrentOptions, setInitialOptions,
} from '@app/actions/po/poConfig'
import ProgramOption from '@app/components/POFromConfig/ProgramOption/ProgramOption'
import { IPOConfigFromBackend, IPOFromBackend } from '@app/@types/po/poConfig'
import { checkIfArraysOrObjectsAreEqual } from '@app/helpers/checkIfArraysOrObjectsAreEqual'
import useStyles from './TakeOnBoardSettings.style'

interface Props {
  initialOptions: Array<IPOFromBackend>,
  currentOptions: Array<IPOFromBackend>,
  items: Array<IPOConfigFromBackend>,
  inProgress: boolean,
}

const TakeOnBoardSettings: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    initialOptions, currentOptions, inProgress, items,
  } = props
  const [programOptions, setProgramOptions] = useState(initialOptions)

  useEffect(() => {
    dispatch(setCurrentOptions(programOptions))
  }, [programOptions])

  useEffect(() => {
    dispatch(setCurrentOptions(initialOptions))
    setProgramOptions(initialOptions)
  }, [initialOptions])

  const save = () => {
    dispatch(set(currentOptions))
    dispatch(setInitialOptions(currentOptions))
  }

  return (
    <Paper>
      {inProgress && (
        <Wrapper className={classes.preloader}>
          <Typography className={classes.preloaderMessage}>
            {I18n.t('program_options.fetching_data')}
            ...
          </Typography>
        </Wrapper>
      )}
      <form className={classNames(inProgress && classes.blur,
        classes.form,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView)}
      >
        <List
          component="nav"
          className={classes.root}
        >
          {!inProgress && programOptions.map((programOption) => (
            <ProgramOption
              items={items}
              programOption={programOption}
              options={programOptions}
              setOptions={setProgramOptions}
              key={programOption.number}
              validationSetter={setValidation}
              inProgress={inProgress}
            />
          ))}
        </List>
        <Wrapper className={classNames(
          classes.buttonsWrapper,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
        )}
        >
          <Button
            disabled={checkIfArraysOrObjectsAreEqual(currentOptions, initialOptions)}
            onClick={save}
            variant="outlined"
            className={classes.resetButton}
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      </form>
    </Paper>
  )
}

export default TakeOnBoardSettings
