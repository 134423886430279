import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 297 297" space="preserve"><path d="M34.632 203.561c0 22.814 18.492 41.385 41.273 41.516-9.063 4.464-15.314 13.794-15.314 24.561C60.591 284.726 72.864 297 87.95 297s27.361-12.274 27.361-27.362c0-10.762-6.248-20.089-15.303-24.555h105.641c-9.055 4.466-15.301 13.793-15.301 24.555 0 15.088 12.274 27.362 27.359 27.362 15.086 0 27.359-12.274 27.359-27.362 0-10.762-6.246-20.089-15.301-24.555h22.545c5.555 0 10.057-4.503 10.057-10.058 0-5.554-4.502-10.057-10.057-10.057H76.145c-11.799 0-21.4-9.604-21.4-21.407v-16.349a41.27 41.27 0 0 0 21.4 5.955h141.563c21.895 0 43.756-16.493 49.768-37.547l25.965-90.885a10.055 10.055 0 0 0-9.67-12.819H54.745V41.523C54.745 18.627 36.122 0 13.229 0 7.675 0 3.173 4.504 3.173 10.058S7.675 20.115 13.23 20.115c11.801 0 21.402 9.604 21.402 21.409v162.037z"/></svg>  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BuyIcon')

export default icon
