import React from 'react'
import { ListItem, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './Text.style'

type Props = {
  title: string,
  value: string,
}

const Text: React.FC<Props> = (props): JSX.Element => {
  const classes = useStyles()
  const { title, value } = props

  return (
    <ListItem className={classes.root} key={title}>
      <Wrapper className={classes.textContainer}>
        <Typography className={classes.text}>
          {title}
        </Typography>
        <Typography className={classes.text}>
          {value}
        </Typography>
      </Wrapper>
    </ListItem>
  )
}

export default Text
