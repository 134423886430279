import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import { Paper, Typography } from '@mui/material'
import disableAdditionalConditions from '@app/helpers/scenes/disableAdditionalConditions'
import { conditionTypes } from '@app/lib/Scenes/conditionTypes'
// @ts-ignore
import { conditionTypesIcons, conditionTypesIconsMobile } from '@app/lib/Scenes/conditionTypesIcons'
import { InfoIcon } from 'icons'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SCENE_REVIEW,
  SCENE_REVIEW_EDIT,
  SCENES,
  SELECT_ANOTHER_SCENE_CONDITION,
  SELECT_CONDITION_DEVICE_TYPE,
  SELECT_GEOFENCES_CONDITION,
  SELECT_TIME_CONDITION_TYPE,
  SELECT_VIRTUAL_SWITCH_CONDITION,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import {
  clearCurrentConditionStateDescription,
  restoreConditionInfo,
  saveConditionDescription,
  saveConditionDeviceName,
  saveConditionDeviceNumber,
  saveConditionType,
  activateCreateConditionMode,
  clearCurrentConditionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import { deleteScene } from '@app/actions/scenes/scenesList'
import InfoScreen from './InfoScreen/InfoScreen'
import useStyles from './SelectConditionType.style'

interface Props {
  isMobile: boolean,
  isEditConditionMode: boolean,
  isChangeConditionMode: boolean,
  sceneReviewConditionTypes: Array<string>,
  isAdditionalConditionMode: boolean,
  sceneMode: string,
}

const SelectConditionType: React.FC<Props> = (props) => {
  const {
    isEditConditionMode,
    isChangeConditionMode,
    sceneReviewConditionTypes,
    isAdditionalConditionMode,
    sceneMode,
    isMobile,
  } = props
  const [isBackButtonPopupOpen, changeBackButtonPopupOpen] = useState<boolean>(false)
  const [infoScreenShow, changeInfoScreenShow] = useState<boolean>(false)
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isEditConditionMode) {
      dispatch(activateCreateConditionMode())
      dispatch(clearCurrentConditionInfo())
    }
  }, [])

  const onSingleConditionBackClick = () => {
    if (isAdditionalConditionMode) {
      if (sceneMode === 'editMezzoScene' || sceneMode === 'editDraftScene') {
        navigate(SCENE_REVIEW_EDIT)
      } else {
        navigate(SCENE_REVIEW)
      }
    } else if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
      dispatch(restoreConditionInfo())
    } else if (isChangeConditionMode) {
      navigate(CREATE_CONDITION_SELECTED)
      dispatch(restoreConditionInfo())
    } else {
      dispatch(deleteScene())
      navigate(SCENES)
    }
  }

  const selectConditionTypeValue = (typeValue: string, typeDescription: string) => {
    if (typeValue === 'UserTrigger') {
      dispatch(saveConditionDeviceNumber(0))
      dispatch(clearCurrentConditionStateDescription())
      dispatch(saveConditionDeviceName(''))
      dispatch(saveConditionType(typeValue))
      dispatch(saveConditionDescription(typeDescription))
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else if (typeValue === 'devices') {
      navigate(SELECT_CONDITION_DEVICE_TYPE)
    } else if (typeValue === 'time') {
      navigate(SELECT_TIME_CONDITION_TYPE)
    } else if (typeValue === 'another_scene') {
      navigate(SELECT_ANOTHER_SCENE_CONDITION)
    } else if (typeValue === 'geofences') {
      navigate(SELECT_GEOFENCES_CONDITION)
    } else if (typeValue === 'virtual_switch') {
      navigate(SELECT_VIRTUAL_SWITCH_CONDITION)
    } else {
      throw new Error('condition type unsupported')
    }
  }

  const onChainedConditionBackClick = () => {
    changeBackButtonPopupOpen(true)
  }

  const onBackClick = () => {
    if (infoScreenShow) {
      return changeInfoScreenShow(false)
    }
    if ((!isEditConditionMode && !isAdditionalConditionMode) && sceneReviewConditionTypes.length) {
      return onChainedConditionBackClick()
    }
    return onSingleConditionBackClick()
  }

  const toSceneReviewAccepted = () => {
    navigate(SCENE_REVIEW)
  }

  const toSceneReviewDeclined = () => {
    changeBackButtonPopupOpen(false)
  }

  const infoBackClick = () => {
    changeInfoScreenShow(!infoScreenShow)
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <InfoIcon
          className={classes.infoIcon}
          onClick={() => {
            changeInfoScreenShow(!infoScreenShow)
          }}
        />
        <Typography className={classes.title}>{I18n.t('scene_web.select_condition_type')}</Typography>
        <Typography className={classes.subTitle}>{I18n.t('scene_web.scenes_simple_subtitle')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        {
          conditionTypes.map((type, index) => (
            <CardBlock
              key={type.title}
              withIcon={type.withIcon}
              icon={isMobile ? conditionTypesIconsMobile[index] : conditionTypesIcons[index]}
              title={I18n.t(`scene_web.${type.title}`)}
              description={I18n.t(`scene_web.${type.description}`)}
              position={type.position}
              buttonName={I18n.t(`scene_web.${type.buttonName}`)}
              isCardDisabled={
                disableAdditionalConditions(type.title, sceneReviewConditionTypes, isChangeConditionMode) || type.isCardDisabled
              }
              isButtonDisabled={
                disableAdditionalConditions(type.title, sceneReviewConditionTypes, isChangeConditionMode) || type.isButtonDisabled
              }
              onClick={() => {
                selectConditionTypeValue(type.type, type.description)
              }}
              isMobile={isMobile}
            />
          ))
        }
      </Wrapper>
      <WarningComponent
        open={isBackButtonPopupOpen}
        title={I18n.t('titles.info')}
        onClose={toSceneReviewDeclined}
        onClick={toSceneReviewAccepted}
        warningText="scene_web.info_edit_scene"
        leftButton="yeah"
        rightButton="cancel"
      />
      {
        infoScreenShow && (
          <InfoScreen
            isMobile={isMobile}
            onBackClick={infoBackClick}
          />
        )
      }
    </Paper>
  )
}

export default SelectConditionType
