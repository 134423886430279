import { IScene } from '@app/@types/scenes/scenes'

const countCompleteScenes = (scenesList: Array<IScene>): number => scenesList.reduce((sum, current) => {
  if (current.status === 'complete') {
    return sum + 1
  }
  return sum
}, 0)

export default countCompleteScenes
