const styles = () => ({
  root: {
    width: '100%',
    paddingBottom: 50,
    overflowX: 'auto',
  },
  title: {
    margin: '20px',
    textAlign: 'center',
  },
})

export default styles
