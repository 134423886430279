import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import { DeviceTypes, TDevice } from '@app/@types/myDevices'
import { IMezzoLight } from '@app/@types/mezzoLight'

export interface ISortDevicesData {
  order: [{
    id: number,
    device_type: DeviceTypes,
  }],
}

const fetchMyDevices = (access_token: string) => Axios.get<Array<TDevice>>(`${API_URL}/api/devices/sorted`, {
  headers: {
    'Access-Token': access_token,
  },
})

const sortDevices = (access_token: string, data: ISortDevicesData) => Axios.put<ISortDevicesData>(`${API_URL}/api/sphere_sort/my_devices`,
  data, {
    headers: {
      'Access-Token': access_token,
    },
  })

const fetchMezzoLights = (access_token: string) => Axios.get<IMezzoLight>(`${API_URL}/api/mezzo_lights`, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchMezzoLights = (accessToken: string, data: IMezzoLight) => Axios.patch<IMezzoLight>(`${API_URL}/api/mezzo_lights`, data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  fetchMyDevices,
  sortDevices,
  fetchMezzoLights,
  patchMezzoLights,
}
