import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_PILLS_EVENTS } from '@app/constants/pillsReminders/pillsReminders'
import Api from '@app/api/pillsReminders/fetchPillsRemindersEvents'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { setPillsEvents } from '@app/actions/pillsReminders/pillsReminders'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchPillsRemindersEvents = createLogic({
  type: FETCH_PILLS_EVENTS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_PILLS_EVENTS))
    try {
      const events = await Api.fetchPillsRemindersEvents(action.accessToken, action.date)
      dispatch(setPillsEvents(events.data))
      dispatch(apiRequestDone(FETCH_PILLS_EVENTS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('fetchPillsRemindersEvents failed without error')
      }
    }
    done()
  },
})

export default fetchPillsRemindersEvents
