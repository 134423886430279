import React from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './GeofencesErrorPopup.style'

class GeofencesErrorPopup extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      close,
    } = this.props
    return (
      <Dialog className={classes.root} open={open}>
        <DialogTitle className={classes.title}>Error</DialogTitle>
        <DialogContent className={classes.popupContent}>
          <Typography className={classes.errorMessage}>{I18n.t('errors.error_code_-154')}</Typography>
          <Wrapper className={classes.popupButtons}>
            <Button
              className={classes.retryButton}
              onClick={close}
            >
              {I18n.t('buttons.okay')}
            </Button>
          </Wrapper>
        </DialogContent>
      </Dialog>
    )
  }
}

GeofencesErrorPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}

GeofencesErrorPopup.displayName = 'GeofencesErrorPopup'

export default withStyles(styles)(GeofencesErrorPopup)
