import * as type from '@app/constants/groups/groups'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as GroupsAction from '@app/actions/groups/groups'
import { IGroup } from '@app/@types/groups'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

type GroupsActions = ReturnType<InferValueTypes<typeof GroupsAction>>

export interface IGroupsState {
  isAddPopupOpen: boolean,
  groupsList: Array<IGroup>,
  twrDevices: Array<IZwaveOrTwrDevice>,
  zWaveDevices: Array<IZwaveOrTwrDevice>,
  freeTwrDevices: Array<IZwaveOrTwrDevice>,
  freeZwaveDevices: Array<IZwaveOrTwrDevice>,
}

const initialState = {
  isAddPopupOpen: false,
  groupsList: [],
  twrDevices: [],
  zWaveDevices: [],
  freeTwrDevices: [],
  freeZwaveDevices: [],
}

const groups = (state: IGroupsState = initialState, action: GroupsActions): IGroupsState => {
  switch (action.type) {
  case type.SET_GROUP_LIST: return { ...state, groupsList: action.data.groups }
  case type.SET_GROUPS_DEVICES: return {
    ...state, freeTwrDevices: action.devices.twr_devices, freeZwaveDevices: action.devices.zwave_devices,
  }
  case type.SET_POST_ADD_GROUP: return {
    ...state, isAddPopupOpen: false, groupsList: [...state.groupsList, action.group],
  }
  case type.POST_ADD_GROUP_FAILED_POPUP: return {
    ...state, isAddPopupOpen: false,
  }
  case type.SET_DELETE_GROUP: return {
    ...state,
    groupsList: state.groupsList.map((group) => (group.id === action.group.id ? action.group : group)),
  }
  case type.SET_PATCH_GROUP: return {
    ...state,
    groupsList: state.groupsList.map((group) => (group.id === action.group.id ? action.group : group)),
  }
  case type.TOGGLE_ADD_GROUP_POPUP: return { ...state, isAddPopupOpen: !state.isAddPopupOpen }
  case type.FILTER_ADD_TWR_DEVICES: return {
    ...state,
    freeTwrDevices: state.freeTwrDevices.filter((device) => device.id !== action.id),
  }
  case type.FILTER_ADD_ZWAVE_DEVICES: return {
    ...state,
    freeZwaveDevices: state.freeZwaveDevices.filter((device) => device.id !== action.id),
  }
  default: return state
  }
}

export default groups
