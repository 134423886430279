import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_UNIT_NAME } from '@app/constants/unit'
import { updateUnitName } from '@app/actions/units'
import { updateUnit, closeEditUnitNamePopup } from '@app/actions/unit'
import Api from '@app/api/unit/patchUnitName'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchUnitDeviceNameLogic = createLogic({
  type: PATCH_UNIT_NAME,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { unit: { serialNumber } } = getState()
    const unit = { serial_number: action.serialNumber, name: action.name }
    next({
      ...action,
      token,
      unit,
      serialNumber,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_UNIT_NAME))
    try {
      const response = await Api.patchUnitName(action.token, action.unit)
      const { unit_device } = response.data
      dispatch(updateUnitName(unit_device))
      if (action.unit.serial_number === action.serialNumber) {
        store.dispatch(updateUnit(unit_device))
      }
      dispatch(apiRequestDone(PATCH_UNIT_NAME))
      dispatch(closeEditUnitNamePopup())
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
        done()
      } else {
        throw new Error('patchUnitDeviceName failed without error')
      }
    }
    return done()
  },
})

export default patchUnitDeviceNameLogic
