export type IVoiceAssistantSettings = {
  title: string,
  description: string,
  type: string,
}

export const voiceAssistantSettings = [
  {
    title: 'without_confirmation_title',
    description: 'without_confirmation_description',
    type: 'visible',
  },
  {
    title: 'dont_show_device_title',
    description: 'dont_show_device_description',
    type: 'hidden',
  },
  {
    title: 'control_confirmation_title',
    description: 'control_confirmation_description',
    type: 'confirmation',
  },
  {
    title: 'user_code_title',
    description: 'user_code_description',
    second_description: 'user_code_second_description',
    type: 'user_code',
  },
]

export const codeMaxLength = 6
