import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 36C9 30.4771 13.4772 26 19 26H34C39.5228 26 44 30.4772 44 36V131C44 136.523 39.5228 141 34 141H19C13.4772 141 9 136.523 9 131V36ZM19 31H34C36.7614 31 39 33.2386 39 36V131C39 133.761 36.7614 136 34 136H19C16.2386 136 14 133.761 14 131V36C14 33.2386 16.2386 31 19 31Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M90.0282 29.4692C84.6927 30.8954 81.5236 36.3769 82.9498 41.7124L107.483 133.49C108.909 138.826 114.39 141.995 119.726 140.568L134.217 136.695C139.553 135.269 142.722 129.787 141.296 124.452L116.763 32.674C115.336 27.3385 109.855 24.1694 104.519 25.5956L90.0282 29.4692ZM91.3194 34.2996L105.811 30.426C108.478 29.7129 111.219 31.2974 111.932 33.9652L136.465 125.743C137.178 128.411 135.594 131.151 132.926 131.864L118.435 135.738C115.767 136.451 113.026 134.867 112.313 132.199L87.7802 40.4212C87.0671 37.7534 88.6517 35.0127 91.3194 34.2996Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M39 18C39 12.4772 43.4772 8 49 8H77C82.5229 8 87 12.4772 87 18V131C87 136.523 82.5228 141 77 141H49C43.4772 141 39 136.523 39 131V18ZM49 13H77C79.7614 13 82 15.2386 82 18V131C82 133.761 79.7614 136 77 136H49C46.2386 136 44 133.761 44 131V18C44 15.2386 46.2386 13 49 13Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51 46V103H74V46H51ZM56 51H69V98H56V51Z" fill="currentColor"/>
      <path d="M11 42H42V47H11V42Z" fill="currentColor"/>
      <path d="M11 119H42V124H11V119Z" fill="currentColor"/>
      <path d="M103 111.008L132.948 103L134.239 107.83L104.292 115.838L103 111.008Z" fill="currentColor"/>
      <path d="M106 122.008L135.948 114L137.239 118.83L107.292 126.838L106 122.008Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Books')

export default icon
