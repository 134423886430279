import React from 'react'
import {
  Antenna,
  BigCharger,
  BigDrop,
  BigFan,
  BigFridge,
  BigGazebo,
  BigHome,
  BigStairs,
  BillardAndBall,
  BoilingKettle,
  Bookcase,
  Books,
  BrokenWindow,
  Bulb,
  BurningDownTheHouse,
  Buttons,
  Car,
  Cat,
  Charger,
  ChristmasTree,
  Cinema,
  Co,
  CoffeeMachine,
  CoffeeMachineLatte,
  Computer,
  ConditionerWithColdAir,
  Cross,
  Curtains,
  DarkDoor,
  DarkFan,
  DarkStereo,
  Door,
  DoorLockIcon,
  DoorSensorIcon,
  Drop,
  EightBall,
  Fan,
  Faucet,
  Fire,
  FourButtonKeyFobIcon,
  Fridge,
  FullPuppy,
  FullTv,
  Garage,
  GarageCar,
  Gate,
  Gates,
  Gazebo,
  GlassBreakSensorIcon,
  GlowingBulb,
  GlowingLamp,
  HappyChristmasTree,
  Heater,
  Home,
  HomePlant,
  HomeSecurityAlarm,
  InsectLampKiller,
  Kettle,
  Kitchen,
  Kitten,
  Lamp,
  LargeSofa,
  LightSwitch,
  LightSwitchOn,
  Medical,
  MeterPower,
  MezzoDeviceIcon,
  MezzoLightIcon,
  Music,
  MyCamera,
  OpenedGarage,
  PanicButtonKeyFobIcon,
  PeperGrinder,
  PIRIcon,
  PowerButton,
  PowerPoint,
  PowerStrip,
  Pump,
  Puppy,
  ReadingLamp,
  ReadingLampScheme,
  Remote,
  RemoteDark,
  ShatteredWindow,
  Shower,
  SmokeAlarmIcon,
  Sofa,
  Solar,
  SolarLamp,
  SolarPanels,
  Speaker,
  Stairs,
  StandingLamp,
  StandingLampBig,
  Stereo,
  StudioMicrophone,
  Sun,
  SunBright,
  Switch,
  SwitchHorizontal,
  SwitchVertical,
  SwitchWifi,
  Tap,
  Thermostat,
  ThermostatDark,
  Toilets,
  TreeLights,
  Tv,
  WallPlugType,
  WarmHeater,
  WaterPump,
  WifiGate,
  WindowSensorIcon,
  ZwAirconIcon,
  ZwAVIcon,
  ZwCelingFanIcon,
  ZwCO2Icon,
  ZwCurtainIcon,
  ZwDimmerIcon,
  ZwEnergyIcon,
  ZwFanIcon,
  ZwFridgeIcon,
  ZwGarageDoorIcon,
  ZwGasMeterIcon,
  ZwHumidityIcon,
  ZwLampIcon,
  ZwLightBulbIcon,
  ZwLightSwitchIcon,
  ZwLuxMeterIcon,
  ZwMeterGenericIcon,
  ZwMotionIcon,
  ZwMultisensorIcon,
  ZwNaturalGasIcon,
  ZwNotificationIcon,
  ZwPoolIcon,
  ZwPowerPointIcon,
  ZwPowerStripIcon,
  ZwRepeaterIcon,
  ZwSirenIcon,
  ZwSmokeIcon,
  ZwStereoIcon,
  ZwTempSensorIcon,
  ZwTVIcon,
  ZwUnknownDeviceIcon,
  ZwUVIcon,
  ZwVibrationIcon,
  ZwWaterMeterIcon,
  ZwWaterSensorIcon,
  ZwWaterStorageIcon,
  ComputerSecond,
  SmartLock,
  DoorLockSecond,
  Downlight,
  FloodLights,
  Hub,
  Laptop,
  OutdoorLight,
  OutdoorLightSecond,
  OutdoorWallLight,
  Pool,
  PoolSecond,
  Router,
  WallLights,
  ThreeDPrinter,
  CeilingBulbs,
  ChargerTwo,
  ComputerThird,
  ComputerFourth,
  DownLightSecond,
  FairyLights,
  FairyLightsSecond,
  Fax,
  FluorescentLamp,
  FluorescentLampSecond,
  Phone,
  PhoneSecond,
  PocketDoor,
  PoolFourth,
  PoolFifth,
  PoolSixth,
  Printer,
  SconceLights,
  SlidingDoor,
  SolarPanelsSecond,
} from 'icons'

export const devicesIcons: { [index: number]: JSX.Element } = {
  1: <PIRIcon />,
  2: <GlassBreakSensorIcon />,
  3: <DoorSensorIcon />,
  4: <SmokeAlarmIcon />,
  5: <PanicButtonKeyFobIcon />,
  6: <FourButtonKeyFobIcon />,
  7: <WindowSensorIcon />,
  8: <ZwCurtainIcon />,
  9: <DoorLockIcon />,
  10: <ZwEnergyIcon />,
  11: <ZwFanIcon />,
  12: <ZwFridgeIcon />,
  13: <ZwGarageDoorIcon />,
  14: <ZwLampIcon />,
  15: <ZwLightBulbIcon />,
  16: <ZwPoolIcon />,
  17: <ZwSirenIcon />,
  18: <ZwTempSensorIcon />,
  19: <ZwWaterStorageIcon />,
  20: <ZwWaterSensorIcon />,
  21: <ZwCO2Icon />,
  22: <ZwTVIcon />,
  23: <ZwMotionIcon />,
  24: <ZwSmokeIcon />,
  25: <MeterPower />,
  26: <ZwAirconIcon />,
  27: <ZwCelingFanIcon />,
  28: <ZwDimmerIcon />,
  29: <ZwGasMeterIcon />,
  30: <ZwLightSwitchIcon />,
  31: <ZwWaterMeterIcon />,
  32: <ZwRepeaterIcon />,
  33: <ZwUnknownDeviceIcon />,
  34: <ZwLuxMeterIcon />,
  35: <ZwNaturalGasIcon />,
  36: <ZwAVIcon />,
  37: <ZwHumidityIcon />,
  38: <ZwMultisensorIcon />,
  39: <ZwPowerPointIcon />,
  40: <ZwPowerStripIcon />,
  41: <ZwStereoIcon />,
  42: <ZwUVIcon />,
  43: <ZwVibrationIcon />,
  44: <MyCamera />,
  45: <ZwNotificationIcon />,
  46: <ZwMeterGenericIcon />,
  47: <MezzoLightIcon />,
  // newIcons
  48: <Antenna />,
  49: <BurningDownTheHouse />,
  50: <Car />,
  51: <Cat />,
  52: <ChristmasTree />,
  53: <HappyChristmasTree />,
  54: <Cinema />,
  55: <CoffeeMachineLatte />,
  56: <CoffeeMachine />,
  57: <Computer />,
  58: <Door />,
  59: <DarkDoor />,
  60: <EightBall />,
  61: <Fan />,
  62: <DarkFan />,
  63: <BigFan />,
  64: <Fire />,
  65: <Fridge />,
  66: <BigFridge />,
  67: <Garage />,
  68: <OpenedGarage />,
  69: <GarageCar />,
  70: <HomeSecurityAlarm />,
  71: <BigHome />,
  72: <HomePlant />,
  73: <Home />,
  74: <Kettle />,
  75: <BoilingKettle />,
  76: <Kitchen />,
  77: <Kitten />,
  78: <LightSwitch />,
  79: <Music />,
  80: <PowerPoint />,
  81: <Pump />,
  82: <ShatteredWindow />,
  83: <Shower />,
  84: <Sofa />,
  85: <Speaker />,
  86: <Stairs />,
  87: <BigStairs />,
  88: <DarkStereo />,
  89: <Stereo />,
  90: <Tv />,
  91: <FullTv />,
  92: <Toilets />,
  93: <WallPlugType />,
  94: <WaterPump />,
  95: <BillardAndBall />,
  96: <Bookcase />,
  97: <Books />,
  98: <BrokenWindow />,
  99: <Bulb />,
  100: <GlowingBulb />,
  101: <Switch />,
  102: <Buttons />,
  103: <Charger />,
  104: <BigCharger />,
  105: <Co />,
  106: <ConditionerWithColdAir />,
  107: <Curtains />,
  108: <Drop />,
  109: <BigDrop />,
  110: <Gate />,
  111: <WifiGate />,
  112: <Gates />,
  113: <BigGazebo />,
  114: <Gazebo />,
  115: <Heater />,
  116: <WarmHeater />,
  117: <InsectLampKiller />,
  118: <Lamp />,
  119: <GlowingLamp />,
  120: <LargeSofa />,
  121: <LightSwitchOn />,
  122: <Medical />,
  123: <Cross />,
  124: <PeperGrinder />,
  125: <PowerStrip />,
  126: <Puppy />,
  127: <FullPuppy />,
  128: <ReadingLampScheme />,
  129: <ReadingLamp />,
  130: <Remote />,
  131: <RemoteDark />,
  132: <Solar />,
  133: <SolarLamp />,
  134: <SolarPanels />,
  135: <StandingLamp />,
  136: <StandingLampBig />,
  137: <StudioMicrophone />,
  138: <Sun />,
  139: <SunBright />,
  140: <SwitchHorizontal />,
  141: <SwitchVertical />,
  142: <SwitchWifi />,
  143: <PowerButton />,
  144: <Tap />,
  145: <Faucet />,
  146: <ThermostatDark />,
  147: <Thermostat />,
  148: <TreeLights />,
  149: <MezzoDeviceIcon />,
  150: <Router />,
  151: <PoolSecond />,
  152: <Pool />,
  153: <OutdoorWallLight />,
  154: <OutdoorLightSecond />,
  155: <OutdoorLight />,
  156: <Laptop />,
  157: <Hub />,
  158: <FloodLights />,
  159: <Downlight />,
  160: <DoorLockSecond />,
  161: <SmartLock />,
  162: <ComputerSecond />,
  163: <ThreeDPrinter />,
  164: <CeilingBulbs />,
  165: <ChargerTwo />,
  166: <ComputerThird />,
  167: <ComputerFourth />,
  168: <DownLightSecond />,
  169: <FairyLights />,
  170: <FairyLightsSecond />,
  171: <Fax />,
  172: <FluorescentLamp />,
  173: <FluorescentLampSecond />,
  174: <Phone />,
  175: <PhoneSecond />,
  176: <PocketDoor />,
  177: <PoolFourth />,
  178: <PoolFifth />,
  179: <PoolSixth />,
  180: <Printer />,
  181: <SconceLights />,
  182: <SlidingDoor />,
  183: <SolarPanelsSecond />,
  184: <WallLights />,
}
