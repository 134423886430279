import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.backgrounds.componentBackground,
    minHeight: 'calc(100vh - 64px)',
    padding: '0px 0px 30px 0px',
  },
  webview: {
    backgroundColor: theme.webView.background,
  },
  eventsList: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '90%',
  },
  emptyEventsWrapper: {
    margin: '0 auto',
    position: 'relative',
  },
  emptyEvents: {
    opacity: 0.15,
  },
  emptyText: {
    textAlign: 'center',
    width: 100,
    position: 'absolute',
    left: 'calc(50% - 50px)',
    top: '17%',
  },
}))

export default useStyles
