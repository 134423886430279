import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/changeset/${id}/another_scenes`

const fetchAnotherScenesList = (access_token, id) => Axios.get(ENDPOINT(id), {
  headers: { 'Access-Token': access_token },
})

export default {
  fetchAnotherScenesList,
}
