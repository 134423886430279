import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.2572 16.3835C14.1489 15.6721 15.6277 14.0921 16.2277 12.1185M7.45204 16.2273C5.56033 15.5158 4.08146 13.9359 3.48148 11.9623M7.45204 3.50943C5.56033 4.22087 4.08146 5.80082 3.48148 7.77445M12.3704 3.50943C14.2621 4.22087 15.7409 5.80082 16.3409 7.77445M16.5185 11.3585C17.3367 11.3585 18 10.6827 18 9.84906C18 9.01542 17.3367 8.33962 16.5185 8.33962C15.7003 8.33962 15.037 9.01542 15.037 9.84906C15.037 10.6827 15.7003 11.3585 16.5185 11.3585ZM3.48148 11.3585C4.29968 11.3585 4.96296 10.6827 4.96296 9.84906C4.96296 9.01542 4.29968 8.33962 3.48148 8.33962C2.66328 8.33962 2 9.01542 2 9.84906C2 10.6827 2.66328 11.3585 3.48148 11.3585ZM10 18C10.8182 18 11.4815 17.3242 11.4815 16.4906C11.4815 15.6569 10.8182 14.9811 10 14.9811C9.1818 14.9811 8.51852 15.6569 8.51852 16.4906C8.51852 17.3242 9.1818 18 10 18ZM10 5.01887C10.8182 5.01887 11.4815 4.34307 11.4815 3.50943C11.4815 2.6758 10.8182 2 10 2C9.1818 2 8.51852 2.6758 8.51852 3.50943C8.51852 4.34307 9.1818 5.01887 10 5.01887Z" stroke="currentColor"/>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'GroupsIcon')

export default icon
