import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveConditionStateDescription,
  saveConditionType,
  saveConditionDeviceStateInfo,
  activateChangeSensorMode,
  activateChangeSensorStateMode,
  deactivateChangeSensorMode,
  deactivateChangeSensorStateMode,
  restoreConditionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectNotificationSensorStateSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/NotificationSensors/SelectNotificationSensorStateSelected/SelectNotificationSensorStateSelected'
import SelectNotificationSensorStateSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/NotificationSensors/SelectNotificationSensorStateSelected/SelectNotificationSensorStateSelected'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectNotificationSensorStateSelectedIOS) : withStyles(SelectNotificationSensorStateSelected)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const notificationSensors = state.scenes.sensorNotifications.devices
  const {
    sensorTypeDescription, sensorEventDescription,
    conditionDeviceNumber,
    sensorType,
    sensorEventType,
  } = state.scenes.currentSceneCreating.currentCondition
  return {
    isMobile,
    sensorTypeDescription,
    sensorEventDescription,
    conditionDeviceNumber,
    sensorType,
    sensorEventType,
    isEditConditionMode,
    isChangeConditionStateMode,
    notificationSensors,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionStateDescription,
  saveConditionType,
  saveConditionDeviceStateInfo,
  activateChangeSensorMode,
  activateChangeSensorStateMode,
  deactivateChangeSensorMode,
  deactivateChangeSensorStateMode,
  restoreConditionInfo,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectNotificationSensorStateSelectedEnhanced = enhance

export default withRouter(SelectNotificationSensorStateSelectedEnhanced)
