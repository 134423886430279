import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.272 74.4841C35.272 96.674 53.295 114.726 75.4868 114.726C97.6501 114.726 115.729 96.6754 115.729 74.4841C115.729 52.2932 97.6777 34.2422 75.4868 34.2422C53.3226 34.2422 35.272 52.2937 35.272 74.4841ZM40.5218 74.5112C40.5218 55.2113 56.1889 39.5191 75.4868 39.5191C94.7591 39.5191 110.479 55.2128 110.479 74.5112C110.479 93.81 94.7857 109.503 75.4868 109.503C56.2155 109.503 40.5218 93.8105 40.5218 74.5112Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M72.4824 23.518C72.4824 24.964 73.6614 26.1429 75.1073 26.1429C76.5913 26.1429 77.7323 24.9531 77.7323 23.518V9.62492C77.7323 8.17893 76.5533 7 75.1073 7C73.6614 7 72.4824 8.17893 72.4824 9.62492V23.518Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M99.3275 32.4479L99.3562 32.4647L99.386 32.4796C99.7474 32.6603 100.173 32.8018 100.646 32.8018C101.563 32.8018 102.439 32.3209 102.919 31.4904L102.92 31.4889L109.852 19.4672C110.598 18.1904 110.142 16.6113 108.897 15.8815C107.619 15.1327 106.038 15.5882 105.308 16.8343L105.308 16.8343L105.304 16.8405L98.3727 28.8622C97.6267 30.139 98.0825 31.7181 99.3275 32.4479Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M118.129 46.4281L118.129 46.4281L118.122 46.4317C116.877 47.1618 116.421 48.7416 117.168 50.0186C117.649 50.8476 118.524 51.3276 119.441 51.3276C119.928 51.3276 120.365 51.1929 120.739 50.9851L120.746 50.9812L120.753 50.9773L132.777 44.0443L132.777 44.0443L132.783 44.0406C134.029 43.3102 134.485 41.7289 133.736 40.4515C133.006 39.2065 131.427 38.7507 130.15 39.4967L118.129 46.4281Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M126.455 76.7889H140.348C141.794 76.7889 142.973 75.61 142.973 74.164C142.973 72.718 141.794 71.5391 140.348 71.5391H126.455C125.009 71.5391 123.83 72.718 123.83 74.164C123.83 75.61 125.009 76.7889 126.455 76.7889Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M130.504 108.906L130.511 108.91L130.519 108.915C130.901 109.127 131.365 109.257 131.817 109.257C132.733 109.257 133.608 108.777 134.089 107.948C134.836 106.671 134.381 105.091 133.135 104.361L133.135 104.361L133.13 104.358L121.108 97.3995C119.831 96.6529 118.252 97.1086 117.522 98.3538C116.773 99.6313 117.228 101.213 118.474 101.943L118.474 101.943L118.479 101.946L130.504 108.906Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M105.954 131.805L105.955 131.807C106.436 132.637 107.311 133.118 108.229 133.118C108.643 133.118 109.098 133.033 109.551 132.762C110.793 132.031 111.247 130.454 110.502 129.179L103.57 117.157L103.57 117.157L103.567 117.151C102.836 115.905 101.255 115.449 99.9774 116.198C98.7324 116.928 98.2766 118.507 99.0226 119.784L105.954 131.805Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M73.2139 139.377C73.2139 140.823 74.3928 142.002 75.8388 142.002C77.2848 142.002 78.4637 140.823 78.4637 139.377V125.484C78.4637 124.038 77.2848 122.859 75.8388 122.859C74.3928 122.859 73.2139 124.038 73.2139 125.484V139.377Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M42.0218 133.139L42.0318 133.145L42.0419 133.151C42.4237 133.363 42.8876 133.493 43.34 133.493C44.2573 133.493 45.1329 133.012 45.6137 132.182L45.6146 132.18L52.546 120.159C53.292 118.882 52.8361 117.303 51.5912 116.573C50.3137 115.824 48.7325 116.28 48.002 117.526L48.002 117.526L47.9984 117.532L41.067 129.554C40.321 130.83 40.7768 132.409 42.0218 133.139Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M18.1691 104.979L18.1691 104.979L18.1629 104.983C16.9176 105.713 16.4618 107.292 17.2088 108.569C17.6899 109.398 18.5647 109.878 19.4811 109.878C19.9533 109.878 20.3793 109.737 20.7408 109.556L20.7673 109.543L20.7931 109.528L32.8175 102.595L32.8175 102.595L32.8237 102.591C34.0698 101.861 34.5253 100.28 33.7765 99.0023C33.0467 97.7573 31.4677 97.3015 30.1908 98.0475L18.1691 104.979Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M27.1425 74.8653L27.1429 74.8506V74.8359C27.1429 73.3899 25.964 72.2109 24.518 72.2109H10.6249C9.17893 72.2109 8 73.3899 8 74.8359C8 76.2819 9.17893 77.4608 10.6249 77.4608H24.4909C25.9314 77.4608 27.0997 76.3207 27.1425 74.8653Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M29.8397 51.6012L29.8474 51.6056L29.8551 51.6098C30.2369 51.822 30.7008 51.9523 31.1532 51.9523C32.0705 51.9523 32.9461 51.4714 33.4269 50.6409L33.432 50.632L33.437 50.623C34.122 49.3814 33.7425 47.8016 32.4714 47.0565L32.4714 47.0564L32.4666 47.0537L20.4443 40.0948C19.1831 39.3575 17.5545 39.7856 16.8449 41.0718C16.1599 42.3133 16.5394 43.8932 17.8106 44.6383L17.8105 44.6383L17.8153 44.6411L29.8397 51.6012Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path d="M47.4027 31.8444L47.4036 31.8459C47.8844 32.6764 48.76 33.1573 49.6773 33.1573C50.0554 33.1573 50.5601 33.0859 51.0191 32.7894C52.2462 32.0535 52.6916 30.4863 51.9502 29.2176L45.0189 17.196L45.0189 17.196L45.0153 17.1898C44.2848 15.9437 42.7035 15.4882 41.4261 16.237C40.1811 16.9668 39.7253 18.5459 40.4713 19.8227L47.4027 31.8444Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Sun')

export default icon
