import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    marginTop: 40,
  },
  listItem: {
    width: 120,
    height: 120,
    padding: 0,
  },
  empty: {
    backgroundColor: '#E1E1E1',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    padding: '9px 20px',
    fontSize: '16px',
  },
}))

export default useStyles
