import React, { useEffect } from 'react'
import {
  Button, Snackbar, Typography, IconButton,
} from '@mui/material'
import authorizationErrorsChecker from '@app/helpers/authorizationErrorsChecker'
import { Close } from 'icons'
import { apiRequestFailed } from '@app/actions/apiRequest'
import useStyles from './ServerError.styles'

interface Props {
  message: string | number,
  tryAgain: () => void,
}

const ServerError: React.FC<Props> = (props) => {
  const {
    message,
    tryAgain,
  } = props
  const classes = useStyles()

  useEffect(() => {
    if (authorizationErrorsChecker(message)) {
      window.location.reload()
    }
  }, [message])

  const errorMessageConditions = () => {
    if (authorizationErrorsChecker(message)) {
      return 'Access token refreshing...'
    } return message
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    store.dispatch(apiRequestFailed(''))
  }

  const actions = []
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  actions.push(
    <IconButton
      key="close"
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <Close fontSize="small" />
    </IconButton>,
  )
  if (tryAgain != null) {
    actions.push(
      <Button key="tryAgain" onClick={tryAgain} variant="outlined" className={classes.button}>
        {I18n.t('buttons.try_again')}
      </Button>,
    )
  }
  return (
    <>
      {!isWebView
      && message
        && (
          <Snackbar
            className={classes.root}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={!!message}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            onClose={handleClose}
            message={(
              <Typography
                color="error"
              >
                {errorMessageConditions()}
              </Typography>
            )}
            action={actions}
          />
        )}
    </>
  )
}

export default ServerError
