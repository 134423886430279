import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { getConditionsActions } from '@app/actions/scenes/currentSceneConditionsActions'
import { isIOS } from 'react-device-detect'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied,
  withNotifications,
  withProgressAndErrorMessage,
  withRefresh,
} from '@app/hocs'
import SceneReview from '@app/components/MyAutomation/Tabs/ScenesScreens/SceneReview/SceneReview'
import SceneReviewIOS from '@app/components/MyAutomation/Tabs/iOS/SceneReview/SceneReview'
import { AppDispatch } from '@app/store'
import { AppState } from '@app/@types/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? SceneReviewIOS : SceneReview

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isEditActionMode,
    isEditConditionMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
    isSaveSceneDraftPopupOpen,
  } = state.scenes.currentSceneCreating
  const title = 'review_scene'
  const withButton = isIOS
  return {
    title,
    withButton,
    isMobile,
    path,
    isEditActionMode,
    isEditConditionMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
    isSaveSceneDraftPopupOpen,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: getConditionsActions,
  tryAgain: getConditionsActions,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SceneReviewEnhanced = enhance

export default SceneReviewEnhanced
