import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  sceneInfoText: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  sceneInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '2% 3%',
    marginBottom: 10,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 50,
  },
  iconButton: {
    padding: 5,
  },
  rightElementWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  errorOutline: {
    marginRight: 3,
  },
  descriptionWrapper: {
    display: 'flex',
  },
  colorBlock: {
    display: 'inline-block',
    marginLeft: 3,
    width: 10,
    height: 10,
    border: '1px solid rgba(0,0,0, 0.3)',
  },
}))

export default useStyles
