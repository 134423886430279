import React from 'react'
import { List, ListItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from '@app/components/PropTypes'
import styles from './DeviceCardList.style'

import DeviceCard from './DeviceCard'

class DeviceCardsList extends React.PureComponent {
  render() {
    const {
      classes,
      devices,
      resetMode,
      devicesForReset,
      addDeviceToResetArray,
      deleteDeviceFromResetArray,
    } = this.props
    return (
      <List className={classes.root}>
        {devices.map((device) => (
          <ListItem className={classes.listItem} key={`${device.id}-${device.sub_id && device.name}`}>
            <DeviceCard
              device={device}
              resetMode={resetMode}
              devicesForReset={devicesForReset}
              addDeviceToResetArray={addDeviceToResetArray}
              deleteDeviceFromResetArray={deleteDeviceFromResetArray}
            />
          </ListItem>
        ))}
      </List>
    )
  }
}

DeviceCardsList.propTypes = {
  classes: PropTypes.object.isRequired,
  resetMode: PropTypes.bool.isRequired,
  devices: PropTypes.arrayOf(PropTypes.energyDevice).isRequired,
  devicesForReset: PropTypes.arrayOf(PropTypes.number),
  addDeviceToResetArray: PropTypes.func.isRequired,
  deleteDeviceFromResetArray: PropTypes.func.isRequired,
}

DeviceCardsList.defaultProps = {
  devicesForReset: [],
}

export default (withStyles(styles)(DeviceCardsList))
