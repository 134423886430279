import type { Node } from 'react'
import React from 'react'
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
  Pagination,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { PaginationItem } from '@mui/lab'

import { STATE_LIST_LIMIT_MAX, STATE_LIST_LIMIT_MEDIUM, STATE_LIST_LIMIT_MIN } from '@app/constants/stats'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'

import { Search } from 'icons'
import useStyles from './StateList.style'

type Props = {
  stateList: {
    total: number,
    unit_devices: Array<{
      serial_number: string,
      ssid: string,
      protocol_carriers: string,
      last_online_time: string | null,
    }>,
  },
  changeLimitHandler: (any) => void,
  total: number,
  page: number,
  itemLimit: number,
  ssidFilter: string,
  serialFilter: string,
  setSsidFilter: (string) => void,
  setSerialFilter: (string) => void,
}

const StateList = (props: Props): Node => {
  const {
    stateList, changeLimitHandler, page, itemLimit, total, serialFilter, setSerialFilter, ssidFilter, setSsidFilter,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        {I18n.t('stats.last_states')}
      </Typography>
      <div className={classes.listSortControls}>
        <FormControl variant="standard">
          <InputLabel htmlFor="serial-number-input">{I18n.t('stats.serial_number')}</InputLabel>
          <Input
            id="serial-number-input"
            value={serialFilter}
            onChange={(evt) => setSerialFilter(evt.target.value.trimLeft())}
            className={classes.controlField}
            endAdornment={(
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel htmlFor="mezzo-ssid-input">{I18n.t('stats.mezzo_ssid')}</InputLabel>
          <Input
            id="mezzo-ssid-input"
            value={ssidFilter}
            onChange={(evt) => setSsidFilter(evt.target.value.trimLeft())}
            className={classes.controlField}
            endAdornment={(
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )}
          />
        </FormControl>
      </div>
      <div className={classes.paginationAndLimitContainer}>
        <Typography className={classes.total}>{`Mezzo Total: ${stateList.total}`}</Typography>
        <Wrapper className={classes.pagination}>
          {stateList.total > itemLimit && (
            <Pagination
              page={page}
              count={total / itemLimit > 1 ? Math.ceil(total / itemLimit) : 1}
              color="primary"
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/statistic${item.page === 1
                    ? `?limit=${itemLimit}`
                    : `?page=${item.page}&limit=${itemLimit}`}`}
                  {...item}
                />
              )}
            />
          )}
        </Wrapper>
        <FormControl variant="standard" className={classes.limit}>
          <InputLabel id="demo-simple-select-label">{I18n.t('stats.per_page')}</InputLabel>
          <Select
            variant="standard"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={itemLimit}
            onChange={changeLimitHandler}
          >
            <MenuItem value={STATE_LIST_LIMIT_MIN}>{STATE_LIST_LIMIT_MIN}</MenuItem>
            <MenuItem value={STATE_LIST_LIMIT_MEDIUM}>{STATE_LIST_LIMIT_MEDIUM}</MenuItem>
            <MenuItem value={STATE_LIST_LIMIT_MAX}>{STATE_LIST_LIMIT_MAX}</MenuItem>
          </Select>
        </FormControl>
      </div>

      <List className={classes.listContainer}>
        <ListItem divider className={classes.listItem}>
          <Typography>
            {I18n.t('stats.serial_number')}
          </Typography>
          <Typography>
            {I18n.t('stats.mezzo_ssid')}
          </Typography>
          <Typography>
            {I18n.t('stats.online_protocol')}
          </Typography>
          <Typography>
            {I18n.t('stats.date')}
          </Typography>
          <Typography className={classes.action}>
            {I18n.t('stats.actions')}
          </Typography>
        </ListItem>
        {stateList.unit_devices.length ? stateList.unit_devices.map((item, i) => (
          <ListItem divider className={classes.listItem} key={`${+item.serial_number + i}`}>
            <Typography>
              {item.serial_number}
            </Typography>
            <Typography>
              {item.ssid || I18n.t('sphere.not_available')}
            </Typography>
            <Typography>
              {item.protocol_carriers || I18n.t('sphere.not_available')}
            </Typography>
            <Typography>
              {item.last_online_time ? new Date(item.last_online_time).toDateString() : I18n.t('stats.never_online')}
            </Typography>
            <Link underline="hover" to={`/statistic/device/${item.serial_number}`} className={classes.link}>
              <Button variant="contained" color="primary">
                {I18n.t('stats.timeline')}
              </Button>
            </Link>
          </ListItem>
        )) : (
          <ListItem className={classes.listItem}>
            <Typography className={classes.noDevices}>{I18n.t('stats.no_devices')}</Typography>
          </ListItem>
        )}
      </List>
      {stateList.total > itemLimit && (
        <Pagination
          page={page}
          count={total / itemLimit > 1 ? Math.ceil(total / itemLimit) : 1}
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/statistic${item.page === 1
                ? `?limit=${itemLimit}`
                : `?page=${item.page}&limit=${itemLimit}`}`}
              {...item}
            />
          )}
        />
      )}
    </div>
  )
}

StateList.propTypes = {
  changeLimitHandler: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  itemLimit: PropTypes.number.isRequired,
  ssidFilter: PropTypes.string.isRequired,
  serialFilter: PropTypes.string.isRequired,
  setSsidFilter: PropTypes.func.isRequired,
  setSerialFilter: PropTypes.func.isRequired,
}

export default StateList
