import { createLogic } from 'redux-logic'
import { SET_PO_FROM_CONFIG } from '@app/constants/po/poConfig'
import { deps } from '@app/constants/channels'
import { PROGRAM_OPTIONS_TIMEOUT } from '@app/constants/timeouts'
import { setInitialOptions } from '@app/actions/po/poConfig'
import programOptionChannel from '@app/api/programOptions'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import extractControlledOptions from '@app/helpers/programOptions/extractControlledOptions'

const submitProgramOptionsFormConfigLogic = createLogic({
  type: SET_PO_FROM_CONFIG,
  cancelType: API_REQUEST_CANCEL,
  warnTimeout: PROGRAM_OPTIONS_TIMEOUT,

  transform({ action }, next) {
    next({
      ...action,
    })
  },

  process({ action }, dispatch, done) {
    programOptionChannel({
      request: (channel) => {
        channel.perform('update_program_options', { programOptions: extractControlledOptions(action.programOptions), ...deps })
        dispatch(apiRequestSent(SET_PO_FROM_CONFIG))
      },

      response: (data, isFinished) => {
        const { programOptions, error } = data
        if (error && error.msg) {
          dispatch(apiRequestFailed(error.msg, error.code))
        } else {
          dispatch(setInitialOptions(programOptions))
          if (isFinished) {
            dispatch(apiRequestDone(SET_PO_FROM_CONFIG))
          }
        }
        if (isFinished) {
          done()
        }
      },
    })
  },
})

export default submitProgramOptionsFormConfigLogic
