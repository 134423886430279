import * as type from '@app/constants/rooms/rooms'
import { IRoom, IRooms, IRoomWithoutDevices } from '@app/@types/rooms'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

export const syncRoomsList = () => ({
  type: type.SYNC_ROOMS_LIST,
} as const)

export const setRoomsList = (data: { rooms: IRooms }) => ({
  type: type.SET_ROOMS_LIST,
  data,
} as const)

export const postNewRoom = (data: { name: string }) => ({
  type: type.POST_ADD_ROOM,
  data,
} as const)

export const setPostNewRoom = (room: IRoom) => ({
  type: type.SET_POST_ADD_ROOM,
  room,
} as const)

export const postAddRoomFailedPopup = () => ({
  type: type.POST_ADD_ROOM_FAILED_POPUP,
} as const)

export const roomAddDevice = (deviceId: number, roomId: number, zwave: boolean) => ({
  type: type.ROOMS_ADD_DEVICE,
  deviceId,
  roomId,
  zwave,
} as const)

export const roomDeleteDevice = (deviceId: number, zwave: boolean) => ({
  type: type.ROOMS_DELETE_DEVICE,
  deviceId,
  zwave,
} as const)

export const patchRoom = (data: IRoomWithoutDevices) => ({
  type: type.PATCH_ROOM,
  data,
} as const)

export const setPatchRoom = (room: IRoom) => ({
  type: type.SET_PATCH_ROOM,
  room,
} as const)

export const deleteRoom = (id: number) => ({
  type: type.DELETE_ROOM,
  id,
} as const)

export const setDeleteRoom = (room: IRoom) => ({
  type: type.SET_DELETE_ROOM,
  room,
} as const)

export const toggleAddPopup = () => ({
  type: type.TOGGLE_ADD_ROOM_POPUP,
} as const)

export const syncRoomDevices = () => ({
  type: type.SYNC_ROOMS_DEVICES,
} as const)

export const setRoomDevices = (devices: { zwave_devices: Array<IZwaveOrTwrDevice>, twr_devices: Array<IZwaveOrTwrDevice> }) => ({
  type: type.SET_ROOMS_DEVICES,
  devices,
} as const)

export const filterAddTwrDevices = (id: number) => ({
  type: type.FILTER_ADD_TWR_DEVICES,
  id,
} as const)

export const filterAddZwaveDevices = (id: number) => ({
  type: type.FILTER_ADD_ZWAVE_DEVICES,
  id,
} as const)
