const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '5px 3% 25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 106px)',
  },
  buttonsContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stateButton: {
    display: 'flex',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.myStatus.subtitle,
  },
  iconEnter: {
    width: 20,
    height: 20,
    marginLeft: -10,
    marginRight: 10,
  },
  iconExit: {
    width: 20,
    height: 20,
    marginLeft: 10,
    marginRight: -10,
  },
  active: {
    color: theme.palette.primary.main,
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  address: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
    overflow: 'scroll',
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
    margin: '0 auto',
    maxWidth: 300,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
