import React from 'react'
import { IconButton } from '@mui/material'
import { Delete, Edit, ErrorOutline } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'
import RenderRadioGroup from './RenderRadioGroup'
import useStyles from './RenderCondition.style'
import ColorDescription from './RenderConditionDescriptions/ColorDescription'
import Description from './RenderConditionDescriptions/Description'

interface Props {
  index: number,
  conditions: Array<IConditionFromBackend>,
  onEditConditionClick: (action: IConditionFromBackend) => void,
  onDeleteConditionClick: (id: number) => void,
  changeRadio: (event: React.ChangeEvent<HTMLInputElement>, condition: IConditionFromBackend, index: number) => void,
}

const RenderCondition: React.FC<Props> = (props) => {
  const {
    index,
    conditions,
    onEditConditionClick,
    onDeleteConditionClick,
    changeRadio,
  } = props
  const classes = useStyles()
  const condition = conditions[index]
  const showOperand = index !== conditions.length - 1

  const getRightElement = () => {
    if (condition.verified) {
      return (
        <Wrapper className={classes.rightElementWrapper}>
          <IconButton
            className={classes.iconButton}
            aria-label="Edit"
            onClick={() => {
              onEditConditionClick(condition)
            }}
            size="large"
          >
            <Edit />
          </IconButton>
          {conditions.length > 1 && (
            <IconButton
              className={classes.iconButton}
              aria-label="Delete"
              onClick={() => {
                onDeleteConditionClick(condition.id)
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          )}
        </Wrapper>
      )
    }
    return (
      <Wrapper className={classes.rightElementWrapper}>
        <ErrorOutline color="error" className={classes.errorOutline} />
        <Wrapper>
          {conditions.length > 1 && (
            <IconButton
              className={classes.iconButton}
              aria-label="Delete"
              onClick={() => {
                onDeleteConditionClick(condition.id)
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          )}
        </Wrapper>
      </Wrapper>
    )
  }

  const renderConditionDescription = (type: string) => {
    if (type === 'ZwaveColor') {
      return <ColorDescription index={index} condition={condition} />
    }
    return <Description index={index} condition={condition} />
  }

  return (
    <>
      <Wrapper className={classes.sceneInfo}>
        {renderConditionDescription(condition.type)}
        {getRightElement()}
      </Wrapper>
      {(condition.operand && showOperand) && (
        <RenderRadioGroup
          index={index}
          condition={condition}
          changeRadio={changeRadio}
        />
      )}
    </>
  )
}

export default RenderCondition
