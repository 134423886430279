import { createLogic } from 'redux-logic'
import Api from '@app/api/contacts'
import handleErrors from '@app/helpers/handleErrors'
import { UPDATE_ALERT_MESSAGE } from '@app/constants/contacts/contacts'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { setAlertMessage } from '@app/actions/contacts/contacts'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const updateAlertMessage = createLogic({
  type: UPDATE_ALERT_MESSAGE,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(UPDATE_ALERT_MESSAGE))
    Api.updateUserAlertMessage(AccessToken, action.newAlertMessage)
      .then((response) => {
        dispatch(setAlertMessage(response.data))
        dispatch(apiRequestDone(UPDATE_ALERT_MESSAGE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('updateUserAlertMessage failed without error')
        }
      })
      .then(() => done())
  },
})

export default updateAlertMessage
