const getWallmoteMultichannelBinarySwitchStateDescription = (value: number): string => {
  switch (value) {
  case 0:
    return 'pressed 1 time'
  case 3:
    return 'pressed 2 times'
  default:
    throw new Error('condition unsupported')
  }
}

export default getWallmoteMultichannelBinarySwitchStateDescription
