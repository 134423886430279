import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_TWR_HEALTH } from '@app/constants/myDevices/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceInfo/getTwrHealth'

const getTwrHealth = createLogic({
  type: GET_TWR_HEALTH,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { myDevices: { myDevices: { currentDevice } } } = getState()
    next({
      ...action,
      accessToken,
      currentDevice,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_TWR_HEALTH))
    try {
      await Api.getTwrHealth(action.accessToken, action.id)
      dispatch(apiRequestDone(GET_TWR_HEALTH))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('GET_TWR_HEALTH failed without error')
      }
    }
    done()
  },
})

export default getTwrHealth
