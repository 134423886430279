import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_DEVICE_HEALTH } from '@app/constants/myDevices/deviceSettings/failedNode'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { syncFailedNodeChannel } from '@app/actions/myDevices/channels/deviceSettings/failedNodeChannel'
import Api from '@app/api/myDevices/deviceSettings/failedNode'

const getDeviceHealthLogic = createLogic({
  type: GET_DEVICE_HEALTH,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_DEVICE_HEALTH))
    try {
      await Api.getDeviceHealth(action.accessToken, action.id)
      dispatch(syncFailedNodeChannel())
      dispatch(apiRequestDone(GET_DEVICE_HEALTH))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('GET_DEVICE_HEALTH failed without error')
      }
    }
    done()
  },
})

export default getDeviceHealthLogic
