import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_VOICE_ASSISTANT } from '@app/constants/myDevices/deviceSettings/voiceAssistant'
import { syncMyDevices } from '@app/actions/myDevices/myDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/voiceAssistant'

const patchVoiceAssistantLogic = createLogic({
  type: PATCH_VOICE_ASSISTANT,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      token,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_VOICE_ASSISTANT))
    try {
      Api.patchVoiceAssistant(action.token, action.data.id, action.data)
      dispatch(syncMyDevices())
      dispatch(apiRequestDone(PATCH_VOICE_ASSISTANT))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_VOICE_ASSISTANT failed without error')
      }
    }
    done()
  },
})

export default patchVoiceAssistantLogic
