import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2331 2331"
      id="vector">
      <g
        id="group"
        transform="translate(115 116)">
        <path
          id="path"
          d="M 1015 0 L 1086 0 L 1086 182 L 1015 182 L 1015 0 Z M 1016 1 L 1016 181 L 1085 181 L 1085 1 L 1016 1 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_1"
          d="M 654.5 179 L 781.5 307 L 654.5 179 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_2"
          d="M 1446.5 179 L 1319.5 307 L 1446.5 179 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_3"
          d="M 831.5 258 L 783.5 307 L 831.5 258 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_4"
          d="M 1269.5 258 L 1317.5 307 L 1269.5 258 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_5"
          d="M 1029.5 296 L 1035 296.5 L 1029.5 297 L 1029.5 296 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_6"
          d="M 1066.5 296 L 1072 296.5 L 1066.5 297 L 1066.5 296 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_7"
          d="M 1020.5 297 L 1024 297.5 L 1020.5 298 L 1020.5 297 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_8"
          d="M 1077.5 297 L 1081 297.5 L 1077.5 298 L 1077.5 297 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_9"
          d="M 1013.5 298 L 1016 298.5 L 1013.5 299 L 1013.5 298 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_10"
          d="M 1085.5 298 L 1088 298.5 L 1085.5 299 L 1085.5 298 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_11"
          d="M 1007.5 299 L 1010 299.5 L 1007.5 300 L 1007.5 299 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_12"
          d="M 1091.5 299 L 1094 299.5 L 1091.5 300 L 1091.5 299 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_13"
          d="M 1002.5 300 L 1005 300.5 L 1002.5 301 L 1002.5 300 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_14"
          d="M 1096.5 300 L 1099 300.5 L 1096.5 301 L 1096.5 300 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_15"
          d="M 1162.5 324 L 1163.5 326 L 1162.5 324 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_16"
          d="M 928.5 330 L 927.5 332 L 928.5 330 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_17"
          d="M 1172.5 330 L 1173.5 332 L 1172.5 330 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_18"
          d="M 919.5 336 L 918.5 338 L 919.5 336 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_19"
          d="M 916.5 338 L 915.5 340 L 916.5 338 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_20"
          d="M 1184.5 338 L 1185.5 340 L 1184.5 338 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_21"
          d="M 912.5 341 L 911.5 343 L 912.5 341 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_22"
          d="M 1188.5 341 L 1189.5 343 L 1188.5 341 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_23"
          d="M 908.5 344 L 906.5 347 L 908.5 344 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_24"
          d="M 1192.5 344 L 1193.5 346 L 1192.5 344 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_25"
          d="M 903.5 348 L 901.5 351 L 903.5 348 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_26"
          d="M 1197.5 348 L 1199.5 351 L 1197.5 348 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_27"
          d="M 896.5 354 L 879.5 372 L 896.5 354 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_28"
          d="M 1204.5 354 L 1221.5 372 L 1204.5 354 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_29"
          d="M 875.5 376 L 873.5 379 L 875.5 376 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_30"
          d="M 1225.5 376 L 1227.5 379 L 1225.5 376 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_31"
          d="M 870.5 382 L 869.5 384 L 870.5 382 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_32"
          d="M 1230.5 382 L 1231.5 384 L 1230.5 382 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_33"
          d="M 867.5 386 L 866.5 388 L 867.5 386 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_34"
          d="M 1233.5 386 L 1234.5 388 L 1233.5 386 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_35"
          d="M 864.5 390 L 863.5 392 L 864.5 390 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_36"
          d="M 1236.5 390 L 1237.5 392 L 1236.5 390 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_37"
          d="M 854.5 405 L 853.5 407 L 854.5 405 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_38"
          d="M 1246.5 405 L 1247.5 407 L 1246.5 405 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_39"
          d="M 1275.5 476 L 1276 478.5 L 1275 478.5 L 1275.5 476 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_40"
          d="M 1276.5 481 L 1277 483.5 L 1276 483.5 L 1276.5 481 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_41"
          d="M 823.5 487 L 824 489.5 L 823 489.5 L 823.5 487 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_42"
          d="M 1277.5 487 L 1278 489.5 L 1277 489.5 L 1277.5 487 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_43"
          d="M 525 490 L 706 490.5 L 526 491 L 526 560 L 705.5 560 L 705.5 561 L 525 561 L 525 490 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_44"
          d="M 1395.5 490 L 1576 490 L 1576 561 L 1395 560.5 L 1575 560 L 1575 491 L 1395.5 491 L 1395.5 490 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_45"
          d="M 1278.5 493 L 1279 496.5 L 1278 496.5 L 1278.5 493 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_46"
          d="M 822.5 494 L 823 496.5 L 822 496.5 L 822.5 494 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_47"
          d="M 1279.5 501 L 1280 506.5 L 1279 506.5 L 1279.5 501 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_48"
          d="M 821.5 502 L 822 506.5 L 821 506.5 L 821.5 502 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_49"
          d="M 1280.5 515 L 1281 535.5 L 1280 535.5 L 1280.5 515 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_50"
          d="M 820.5 516 L 821 535.5 L 820 535.5 L 820.5 516 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_51"
          d="M 821.5 544 L 822 549.5 L 821 549.5 L 821.5 544 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_52"
          d="M 1279.5 544 L 1280 549.5 L 1279 549.5 L 1279.5 544 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_53"
          d="M 822.5 554 L 823 557.5 L 822 557.5 L 822.5 554 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_54"
          d="M 1278.5 554 L 1279 557.5 L 1278 557.5 L 1278.5 554 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_55"
          d="M 823.5 561 L 824 563.5 L 823 563.5 L 823.5 561 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_56"
          d="M 1277.5 561 L 1278 563.5 L 1277 563.5 L 1277.5 561 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_57"
          d="M 824.5 567 L 825 569.5 L 824 569.5 L 824.5 567 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_58"
          d="M 1276.5 567 L 1277 569.5 L 1276 569.5 L 1276.5 567 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_59"
          d="M 825.5 572 L 826 574.5 L 825 574.5 L 825.5 572 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_60"
          d="M 1275.5 572 L 1276 574.5 L 1275 574.5 L 1275.5 572 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_61"
          d="M 1255.5 630 L 1254.5 632 L 1255.5 630 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_62"
          d="M 851.5 641 L 852.5 643 L 851.5 641 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_63"
          d="M 1249.5 641 L 1248.5 643 L 1249.5 641 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_64"
          d="M 854.5 646 L 855.5 648 L 854.5 646 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_65"
          d="M 1246.5 646 L 1245.5 648 L 1246.5 646 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_66"
          d="M 1244.5 649 L 1243.5 651 L 1244.5 649 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_67"
          d="M 1240.5 655 L 1239.5 657 L 1240.5 655 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_68"
          d="M 862.5 658 L 863.5 660 L 862.5 658 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_69"
          d="M 1238.5 658 L 1237.5 660 L 1238.5 658 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_70"
          d="M 864.5 661 L 865.5 663 L 864.5 661 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_71"
          d="M 1235.5 662 L 1234.5 664 L 1235.5 662 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_72"
          d="M 867.5 665 L 869.5 668 L 867.5 665 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_73"
          d="M 1232.5 666 L 1231.5 668 L 1232.5 666 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_74"
          d="M 871.5 670 L 873.5 673 L 871.5 670 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_75"
          d="M 1229.5 670 L 1227.5 673 L 1229.5 670 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_76"
          d="M 876.5 676 L 879.5 680 L 876.5 676 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_77"
          d="M 1223.5 677 L 1220.5 681 L 1223.5 677 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_78"
          d="M 1205.5 695 L 1201.5 700 L 1205.5 695 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_79"
          d="M 896.5 696 L 899.5 700 L 896.5 696 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_80"
          d="M 1197.5 702 L 1195.5 705 L 1197.5 702 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_81"
          d="M 904.5 703 L 905.5 705 L 904.5 703 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_82"
          d="M 1192.5 706 L 1191.5 708 L 1192.5 706 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_83"
          d="M 909.5 707 L 910.5 709 L 909.5 707 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_84"
          d="M 913.5 710 L 914.5 712 L 913.5 710 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_85"
          d="M 1184.5 712 L 1183.5 714 L 1184.5 712 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_86"
          d="M 920.5 715 L 921.5 717 L 920.5 715 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_87"
          d="M 923.5 717 L 924.5 719 L 923.5 717 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_88"
          d="M 1172.5 720 L 1171.5 722 L 1172.5 720 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_89"
          d="M 931.5 722 L 932.5 724 L 931.5 722 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_90"
          d="M 938.5 726 L 939.5 728 L 938.5 726 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_91"
          d="M 1160.5 727 L 1159.5 729 L 1160.5 727 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_92"
          d="M 1000.5 750 L 1003 750.5 L 1000.5 751 L 1000.5 750 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_93"
          d="M 1098.5 750 L 1101 750.5 L 1098.5 751 L 1098.5 750 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_94"
          d="M 1005.5 751 L 1008 751.5 L 1005.5 752 L 1005.5 751 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_95"
          d="M 1093.5 751 L 1096 751.5 L 1093.5 752 L 1093.5 751 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_96"
          d="M 1010.5 752 L 1013 752.5 L 1010.5 753 L 1010.5 752 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_97"
          d="M 1017.5 753 L 1020 753.5 L 1017.5 754 L 1017.5 753 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_98"
          d="M 1081.5 753 L 1084 753.5 L 1081.5 754 L 1081.5 753 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_99"
          d="M 1024.5 754 L 1029 754.5 L 1024.5 755 L 1024.5 754 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_100"
          d="M 1072.5 754 L 1077 754.5 L 1072.5 755 L 1072.5 754 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_101"
          d="M 1036.5 755 L 1065 755.5 L 1036.5 756 L 1036.5 755 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_102"
          d="M 831.5 794 L 704.5 922 L 654 872.5 L 703.5 921 L 831.5 794 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_103"
          d="M 1269.5 794 L 1397.5 921 L 1447 872.5 L 1396.5 922 L 1269.5 794 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_104"
          d="M 1015.5 870 L 1016 1050.5 L 1015 1050.5 L 1015.5 870 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_105"
          d="M 1085.5 870 L 1086 1050.5 L 1085 1050.5 L 1085.5 870 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_106"
          d="M 262.5 1206 L 564 1206.5 L 262.5 1207 L 262.5 1206 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_107"
          d="M 686.5 1206 L 990 1206.5 L 686.5 1207 L 686.5 1206 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_108"
          d="M 1111.5 1206 L 1415 1206.5 L 1111.5 1207 L 1111.5 1206 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_109"
          d="M 1537.5 1206 L 1839 1206.5 L 1537.5 1207 L 1537.5 1206 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_110"
          d="M 569.5 1208 L 570.5 1210 L 569.5 1208 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_111"
          d="M 680.5 1208 L 679.5 1210 L 680.5 1208 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_112"
          d="M 1531.5 1208 L 1530.5 1210 L 1531.5 1208 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_113"
          d="M 255.5 1209 L 251.5 1214 L 255.5 1209 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_114"
          d="M 1845.5 1209 L 1849.5 1214 L 1845.5 1209 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_115"
          d="M 998.5 1210 L 999.5 1212 L 998.5 1210 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_116"
          d="M 1102.5 1210 L 1101.5 1212 L 1102.5 1210 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_117"
          d="M 574.5 1213 L 575.5 1215 L 574.5 1213 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_118"
          d="M 675.5 1213 L 674.5 1215 L 675.5 1213 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_119"
          d="M 1425.5 1213 L 1426.5 1215 L 1425.5 1213 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_120"
          d="M 1526.5 1213 L 1525.5 1215 L 1526.5 1213 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_121"
          d="M 1003.5 1219 L 1004 1428.5 L 1003 1428.5 L 1003.5 1219 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_122"
          d="M 1097.5 1220 L 1098 1428.5 L 1097 1428.5 L 1097.5 1220 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_123"
          d="M 1428.5 1220 L 1429 1223.5 L 1428 1223.5 L 1428.5 1220 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_124"
          d="M 577.5 1221 L 578 1223.5 L 577 1223.5 L 577.5 1221 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_125"
          d="M 672.5 1221 L 673 1223.5 L 672 1223.5 L 672.5 1221 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_126"
          d="M 1523.5 1221 L 1524 1223.5 L 1523 1223.5 L 1523.5 1221 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_127"
          d="M 1429.5 1227 L 1430 1230.5 L 1429 1230.5 L 1429.5 1227 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_128"
          d="M 576.5 1228 L 577 1230.5 L 576 1230.5 L 576.5 1228 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_129"
          d="M 671.5 1228 L 672 1230.5 L 671 1230.5 L 671.5 1228 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_130"
          d="M 1524.5 1228 L 1525 1230.5 L 1524 1230.5 L 1524.5 1228 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_131"
          d="M 1430.5 1234 L 1431 1237.5 L 1430 1237.5 L 1430.5 1234 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_132"
          d="M 575.5 1235 L 576 1237.5 L 575 1237.5 L 575.5 1235 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_133"
          d="M 670.5 1235 L 671 1237.5 L 670 1237.5 L 670.5 1235 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_134"
          d="M 1525.5 1235 L 1526 1237.5 L 1525 1237.5 L 1525.5 1235 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_135"
          d="M 669.5 1241 L 670 1244.5 L 669 1244.5 L 669.5 1241 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_136"
          d="M 1431.5 1241 L 1432 1244.5 L 1431 1244.5 L 1431.5 1241 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_137"
          d="M 574.5 1242 L 575 1244.5 L 574 1244.5 L 574.5 1242 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_138"
          d="M 1526.5 1242 L 1527 1244.5 L 1526 1244.5 L 1526.5 1242 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_139"
          d="M 668.5 1248 L 669 1251.5 L 668 1251.5 L 668.5 1248 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_140"
          d="M 1432.5 1248 L 1433 1251.5 L 1432 1251.5 L 1432.5 1248 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_141"
          d="M 573.5 1249 L 574 1251.5 L 573 1251.5 L 573.5 1249 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_142"
          d="M 1527.5 1249 L 1528 1251.5 L 1527 1251.5 L 1527.5 1249 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_143"
          d="M 572.5 1255 L 573 1258.5 L 572 1258.5 L 572.5 1255 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_144"
          d="M 667.5 1255 L 668 1258.5 L 667 1258.5 L 667.5 1255 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_145"
          d="M 1433.5 1255 L 1434 1257.5 L 1433 1257.5 L 1433.5 1255 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_146"
          d="M 1528.5 1255 L 1529 1258.5 L 1528 1258.5 L 1528.5 1255 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_147"
          d="M 571.5 1262 L 572 1265.5 L 571 1265.5 L 571.5 1262 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_148"
          d="M 666.5 1262 L 667 1265.5 L 666 1265.5 L 666.5 1262 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_149"
          d="M 1434.5 1262 L 1435 1264.5 L 1434 1264.5 L 1434.5 1262 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_150"
          d="M 1529.5 1262 L 1530 1265.5 L 1529 1265.5 L 1529.5 1262 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_151"
          d="M 570.5 1269 L 571 1272.5 L 570 1272.5 L 570.5 1269 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_152"
          d="M 665.5 1269 L 666 1272.5 L 665 1272.5 L 665.5 1269 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_153"
          d="M 1435.5 1269 L 1436 1271.5 L 1435 1271.5 L 1435.5 1269 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_154"
          d="M 1530.5 1269 L 1531 1272.5 L 1530 1272.5 L 1530.5 1269 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_155"
          d="M 569.5 1276 L 570 1279.5 L 569 1279.5 L 569.5 1276 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_156"
          d="M 664.5 1276 L 665 1279.5 L 664 1279.5 L 664.5 1276 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_157"
          d="M 1436.5 1276 L 1437 1278.5 L 1436 1278.5 L 1436.5 1276 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_158"
          d="M 1531.5 1276 L 1532 1279.5 L 1531 1279.5 L 1531.5 1276 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_159"
          d="M 568.5 1283 L 569 1286.5 L 568 1286.5 L 568.5 1283 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_160"
          d="M 663.5 1283 L 664 1286.5 L 663 1286.5 L 663.5 1283 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_161"
          d="M 1437.5 1283 L 1438 1285.5 L 1437 1285.5 L 1437.5 1283 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_162"
          d="M 1532.5 1283 L 1533 1286.5 L 1532 1286.5 L 1532.5 1283 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_163"
          d="M 567.5 1290 L 568 1293.5 L 567 1293.5 L 567.5 1290 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_164"
          d="M 662.5 1290 L 663 1293.5 L 662 1293.5 L 662.5 1290 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_165"
          d="M 1438.5 1290 L 1439 1292.5 L 1438 1292.5 L 1438.5 1290 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_166"
          d="M 1533.5 1290 L 1534 1293.5 L 1533 1293.5 L 1533.5 1290 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_167"
          d="M 566.5 1297 L 567 1300.5 L 566 1300.5 L 566.5 1297 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_168"
          d="M 661.5 1297 L 662 1299.5 L 661 1299.5 L 661.5 1297 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_169"
          d="M 1439.5 1297 L 1440 1299.5 L 1439 1299.5 L 1439.5 1297 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_170"
          d="M 1534.5 1297 L 1535 1300.5 L 1534 1300.5 L 1534.5 1297 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_171"
          d="M 565.5 1304 L 566 1306.5 L 565 1306.5 L 565.5 1304 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_172"
          d="M 660.5 1304 L 661 1306.5 L 660 1306.5 L 660.5 1304 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_173"
          d="M 1440.5 1304 L 1441 1306.5 L 1440 1306.5 L 1440.5 1304 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_174"
          d="M 1535.5 1304 L 1536 1307.5 L 1535 1307.5 L 1535.5 1304 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_175"
          d="M 1441.5 1310 L 1442 1313.5 L 1441 1313.5 L 1441.5 1310 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_176"
          d="M 564.5 1311 L 565 1313.5 L 564 1313.5 L 564.5 1311 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_177"
          d="M 659.5 1311 L 660 1313.5 L 659 1313.5 L 659.5 1311 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_178"
          d="M 1536.5 1311 L 1537 1313.5 L 1536 1313.5 L 1536.5 1311 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_179"
          d="M 1442.5 1317 L 1443 1320.5 L 1442 1320.5 L 1442.5 1317 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_180"
          d="M 563.5 1318 L 564 1320.5 L 563 1320.5 L 563.5 1318 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_181"
          d="M 658.5 1318 L 659 1320.5 L 658 1320.5 L 658.5 1318 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_182"
          d="M 1537.5 1318 L 1538 1320.5 L 1537 1320.5 L 1537.5 1318 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_183"
          d="M 1443.5 1324 L 1444 1327.5 L 1443 1327.5 L 1443.5 1324 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_184"
          d="M 562.5 1325 L 563 1327.5 L 562 1327.5 L 562.5 1325 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_185"
          d="M 657.5 1325 L 658 1327.5 L 657 1327.5 L 657.5 1325 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_186"
          d="M 1538.5 1325 L 1539 1327.5 L 1538 1327.5 L 1538.5 1325 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_187"
          d="M 1444.5 1331 L 1445 1334.5 L 1444 1334.5 L 1444.5 1331 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_188"
          d="M 561.5 1332 L 562 1334.5 L 561 1334.5 L 561.5 1332 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_189"
          d="M 656.5 1332 L 657 1334.5 L 656 1334.5 L 656.5 1332 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_190"
          d="M 1539.5 1332 L 1540 1334.5 L 1539 1334.5 L 1539.5 1332 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_191"
          d="M 1445.5 1338 L 1446 1341.5 L 1445 1341.5 L 1445.5 1338 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_192"
          d="M 560.5 1339 L 561 1341.5 L 560 1341.5 L 560.5 1339 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_193"
          d="M 655.5 1339 L 656 1341.5 L 655 1341.5 L 655.5 1339 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_194"
          d="M 1540.5 1339 L 1541 1341.5 L 1540 1341.5 L 1540.5 1339 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_195"
          d="M 1446.5 1345 L 1447 1348.5 L 1446 1348.5 L 1446.5 1345 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_196"
          d="M 559.5 1346 L 560 1348.5 L 559 1348.5 L 559.5 1346 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_197"
          d="M 654.5 1346 L 655 1348.5 L 654 1348.5 L 654.5 1346 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_198"
          d="M 1541.5 1346 L 1542 1348.5 L 1541 1348.5 L 1541.5 1346 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_199"
          d="M 653.5 1352 L 654 1355.5 L 653 1355.5 L 653.5 1352 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_200"
          d="M 1447.5 1352 L 1448 1355.5 L 1447 1355.5 L 1447.5 1352 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_201"
          d="M 558.5 1353 L 559 1355.5 L 558 1355.5 L 558.5 1353 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_202"
          d="M 1542.5 1353 L 1543 1355.5 L 1542 1355.5 L 1542.5 1353 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_203"
          d="M 557.5 1359 L 558 1362.5 L 557 1362.5 L 557.5 1359 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_204"
          d="M 652.5 1359 L 653 1362.5 L 652 1362.5 L 652.5 1359 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_205"
          d="M 1448.5 1359 L 1449 1362.5 L 1448 1362.5 L 1448.5 1359 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_206"
          d="M 1543.5 1360 L 1544 1362.5 L 1543 1362.5 L 1543.5 1360 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_207"
          d="M 556.5 1366 L 557 1369.5 L 556 1369.5 L 556.5 1366 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_208"
          d="M 651.5 1366 L 652 1369.5 L 651 1369.5 L 651.5 1366 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_209"
          d="M 1449.5 1366 L 1450 1368.5 L 1449 1368.5 L 1449.5 1366 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_210"
          d="M 1544.5 1366 L 1545 1369.5 L 1544 1369.5 L 1544.5 1366 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_211"
          d="M 555.5 1373 L 556 1376.5 L 555 1376.5 L 555.5 1373 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_212"
          d="M 650.5 1373 L 651 1376.5 L 650 1376.5 L 650.5 1373 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_213"
          d="M 1450.5 1373 L 1451 1375.5 L 1450 1375.5 L 1450.5 1373 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_214"
          d="M 1545.5 1373 L 1546 1376.5 L 1545 1376.5 L 1545.5 1373 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_215"
          d="M 554.5 1380 L 555 1383.5 L 554 1383.5 L 554.5 1380 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_216"
          d="M 649.5 1380 L 650 1383.5 L 649 1383.5 L 649.5 1380 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_217"
          d="M 1451.5 1380 L 1452 1382.5 L 1451 1382.5 L 1451.5 1380 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_218"
          d="M 1546.5 1380 L 1547 1383.5 L 1546 1383.5 L 1546.5 1380 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_219"
          d="M 553.5 1387 L 554 1390.5 L 553 1390.5 L 553.5 1387 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_220"
          d="M 648.5 1387 L 649 1390.5 L 648 1390.5 L 648.5 1387 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_221"
          d="M 1452.5 1387 L 1453 1389.5 L 1452 1389.5 L 1452.5 1387 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_222"
          d="M 1547.5 1387 L 1548 1390.5 L 1547 1390.5 L 1547.5 1387 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_223"
          d="M 552.5 1394 L 553 1397.5 L 552 1397.5 L 552.5 1394 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_224"
          d="M 647.5 1394 L 648 1397.5 L 647 1397.5 L 647.5 1394 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_225"
          d="M 1453.5 1394 L 1454 1396.5 L 1453 1396.5 L 1453.5 1394 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_226"
          d="M 1548.5 1394 L 1549 1397.5 L 1548 1397.5 L 1548.5 1394 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_227"
          d="M 551.5 1401 L 552 1404.5 L 551 1404.5 L 551.5 1401 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_228"
          d="M 646.5 1401 L 647 1403.5 L 646 1403.5 L 646.5 1401 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_229"
          d="M 1454.5 1401 L 1455 1403.5 L 1454 1403.5 L 1454.5 1401 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_230"
          d="M 1549.5 1401 L 1550 1404.5 L 1549 1404.5 L 1549.5 1401 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_231"
          d="M 550.5 1408 L 551 1411.5 L 550 1411.5 L 550.5 1408 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_232"
          d="M 645.5 1408 L 646 1410.5 L 645 1410.5 L 645.5 1408 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_233"
          d="M 1455.5 1408 L 1456 1410.5 L 1455 1410.5 L 1455.5 1408 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_234"
          d="M 1550.5 1408 L 1551 1411.5 L 1550 1411.5 L 1550.5 1408 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_235"
          d="M 549.5 1415 L 550 1417.5 L 549 1417.5 L 549.5 1415 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_236"
          d="M 644.5 1415 L 645 1417.5 L 644 1417.5 L 644.5 1415 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_237"
          d="M 1456.5 1415 L 1457 1417.5 L 1456 1417.5 L 1456.5 1415 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_238"
          d="M 1551.5 1415 L 1552 1417.5 L 1551 1417.5 L 1551.5 1415 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_239"
          d="M 1457.5 1421 L 1458 1429.5 L 1457 1429.5 L 1457.5 1421 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_240"
          d="M 190.5 1422 L 191 1429.5 L 190 1429.5 L 190.5 1422 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_241"
          d="M 548.5 1422 L 549 1424.5 L 548 1424.5 L 548.5 1422 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_242"
          d="M 643.5 1422 L 644 1429.5 L 643 1429.5 L 643.5 1422 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_243"
          d="M 1552.5 1422 L 1553 1424.5 L 1552 1424.5 L 1552.5 1422 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_244"
          d="M 1910.5 1422 L 1911 1429.5 L 1910 1429.5 L 1910.5 1422 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_245"
          d="M 544.5 1436 L 542.5 1439 L 544.5 1436 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_246"
          d="M 1556.5 1436 L 1558.5 1439 L 1556.5 1436 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_247"
          d="M 997.5 1438 L 996.5 1440 L 997.5 1438 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_248"
          d="M 1103.5 1438 L 1104.5 1440 L 1103.5 1438 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_249"
          d="M 540.5 1439 L 539.5 1441 L 540.5 1439 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_250"
          d="M 1560.5 1439 L 1561.5 1441 L 1560.5 1439 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_251"
          d="M 516.5 1536 L 519 1536.5 L 516.5 1537 L 516.5 1536 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_252"
          d="M 636.5 1536 L 639 1536.5 L 636.5 1537 L 636.5 1536 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_253"
          d="M 990.5 1536 L 993 1536.5 L 990.5 1537 L 990.5 1536 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_254"
          d="M 1108.5 1536 L 1111 1536.5 L 1108.5 1537 L 1108.5 1536 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_255"
          d="M 1582.5 1536 L 1585 1536.5 L 1582.5 1537 L 1582.5 1536 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_256"
          d="M 996.5 1538 L 997.5 1540 L 996.5 1538 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_257"
          d="M 1104.5 1538 L 1103.5 1540 L 1104.5 1538 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_258"
          d="M 1101.5 1541 L 1100.5 1543 L 1101.5 1541 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_259"
          d="M 529.5 1547 L 530 1549.5 L 529 1549.5 L 529.5 1547 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_260"
          d="M 625.5 1547 L 626 1549.5 L 625 1549.5 L 625.5 1547 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_261"
          d="M 1475.5 1547 L 1476 1549.5 L 1475 1549.5 L 1475.5 1547 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_262"
          d="M 1571.5 1547 L 1572 1550.5 L 1571 1550.5 L 1571.5 1547 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_263"
          d="M 1003.5 1549 L 1004 1758.5 L 1003 1758.5 L 1003.5 1549 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_264"
          d="M 1097.5 1549 L 1098 1757.5 L 1097 1757.5 L 1097.5 1549 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_265"
          d="M 1476.5 1553 L 1477 1556.5 L 1476 1556.5 L 1476.5 1553 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_266"
          d="M 1571.5 1553 L 1572 1556.5 L 1571 1556.5 L 1571.5 1553 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_267"
          d="M 529.5 1554 L 530 1556.5 L 529 1556.5 L 529.5 1554 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_268"
          d="M 624.5 1554 L 625 1556.5 L 624 1556.5 L 624.5 1554 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_269"
          d="M 1477.5 1560 L 1478 1563.5 L 1477 1563.5 L 1477.5 1560 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_270"
          d="M 528.5 1561 L 529 1563.5 L 528 1563.5 L 528.5 1561 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_271"
          d="M 623.5 1561 L 624 1563.5 L 623 1563.5 L 623.5 1561 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_272"
          d="M 1572.5 1561 L 1573 1563.5 L 1572 1563.5 L 1572.5 1561 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_273"
          d="M 1478.5 1567 L 1479 1570.5 L 1478 1570.5 L 1478.5 1567 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_274"
          d="M 527.5 1568 L 528 1570.5 L 527 1570.5 L 527.5 1568 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_275"
          d="M 622.5 1568 L 623 1570.5 L 622 1570.5 L 622.5 1568 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_276"
          d="M 1573.5 1568 L 1574 1570.5 L 1573 1570.5 L 1573.5 1568 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_277"
          d="M 621.5 1574 L 622 1577.5 L 621 1577.5 L 621.5 1574 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_278"
          d="M 1479.5 1574 L 1480 1577.5 L 1479 1577.5 L 1479.5 1574 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_279"
          d="M 1574.5 1574 L 1575 1577.5 L 1574 1577.5 L 1574.5 1574 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_280"
          d="M 526.5 1575 L 527 1577.5 L 526 1577.5 L 526.5 1575 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_281"
          d="M 620.5 1581 L 621 1584.5 L 620 1584.5 L 620.5 1581 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_282"
          d="M 1480.5 1581 L 1481 1584.5 L 1480 1584.5 L 1480.5 1581 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_283"
          d="M 1575.5 1581 L 1576 1584.5 L 1575 1584.5 L 1575.5 1581 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_284"
          d="M 525.5 1582 L 526 1584.5 L 525 1584.5 L 525.5 1582 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_285"
          d="M 619.5 1588 L 620 1591.5 L 619 1591.5 L 619.5 1588 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_286"
          d="M 1481.5 1588 L 1482 1591.5 L 1481 1591.5 L 1481.5 1588 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_287"
          d="M 1576.5 1588 L 1577 1591.5 L 1576 1591.5 L 1576.5 1588 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_288"
          d="M 524.5 1589 L 525 1591.5 L 524 1591.5 L 524.5 1589 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_289"
          d="M 618.5 1595 L 619 1598.5 L 618 1598.5 L 618.5 1595 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_290"
          d="M 1482.5 1595 L 1483 1597.5 L 1482 1597.5 L 1482.5 1595 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_291"
          d="M 1577.5 1595 L 1578 1598.5 L 1577 1598.5 L 1577.5 1595 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_292"
          d="M 523.5 1596 L 524 1598.5 L 523 1598.5 L 523.5 1596 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_293"
          d="M 617.5 1602 L 618 1605.5 L 617 1605.5 L 617.5 1602 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_294"
          d="M 1483.5 1602 L 1484 1604.5 L 1483 1604.5 L 1483.5 1602 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_295"
          d="M 1578.5 1602 L 1579 1605.5 L 1578 1605.5 L 1578.5 1602 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_296"
          d="M 522.5 1603 L 523 1605.5 L 522 1605.5 L 522.5 1603 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_297"
          d="M 521.5 1609 L 522 1612.5 L 521 1612.5 L 521.5 1609 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_298"
          d="M 616.5 1609 L 617 1612.5 L 616 1612.5 L 616.5 1609 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_299"
          d="M 1484.5 1609 L 1485 1611.5 L 1484 1611.5 L 1484.5 1609 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_300"
          d="M 1579.5 1609 L 1580 1612.5 L 1579 1612.5 L 1579.5 1609 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_301"
          d="M 520.5 1616 L 521 1619.5 L 520 1619.5 L 520.5 1616 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_302"
          d="M 615.5 1616 L 616 1619.5 L 615 1619.5 L 615.5 1616 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_303"
          d="M 1485.5 1616 L 1486 1618.5 L 1485 1618.5 L 1485.5 1616 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_304"
          d="M 1580.5 1616 L 1581 1619.5 L 1580 1619.5 L 1580.5 1616 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_305"
          d="M 519.5 1623 L 520 1626.5 L 519 1626.5 L 519.5 1623 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_306"
          d="M 614.5 1623 L 615 1625.5 L 614 1625.5 L 614.5 1623 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_307"
          d="M 1486.5 1623 L 1487 1625.5 L 1486 1625.5 L 1486.5 1623 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_308"
          d="M 1581.5 1623 L 1582 1626.5 L 1581 1626.5 L 1581.5 1623 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_309"
          d="M 518.5 1630 L 519 1633.5 L 518 1633.5 L 518.5 1630 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_310"
          d="M 613.5 1630 L 614 1632.5 L 613 1632.5 L 613.5 1630 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_311"
          d="M 1487.5 1630 L 1488 1632.5 L 1487 1632.5 L 1487.5 1630 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_312"
          d="M 1582.5 1630 L 1583 1632.5 L 1582 1632.5 L 1582.5 1630 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_313"
          d="M 517.5 1637 L 518 1640.5 L 517 1640.5 L 517.5 1637 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_314"
          d="M 612.5 1637 L 613 1639.5 L 612 1639.5 L 612.5 1637 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_315"
          d="M 1488.5 1637 L 1489 1639.5 L 1488 1639.5 L 1488.5 1637 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_316"
          d="M 1583.5 1637 L 1584 1639.5 L 1583 1639.5 L 1583.5 1637 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_317"
          d="M 516.5 1644 L 517 1647.5 L 516 1647.5 L 516.5 1644 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_318"
          d="M 611.5 1644 L 612 1646.5 L 611 1646.5 L 611.5 1644 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_319"
          d="M 1489.5 1644 L 1490 1646.5 L 1489 1646.5 L 1489.5 1644 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_320"
          d="M 1584.5 1644 L 1585 1646.5 L 1584 1646.5 L 1584.5 1644 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_321"
          d="M 1490.5 1650 L 1491 1653.5 L 1490 1653.5 L 1490.5 1650 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_322"
          d="M 515.5 1651 L 516 1654.5 L 515 1654.5 L 515.5 1651 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_323"
          d="M 610.5 1651 L 611 1653.5 L 610 1653.5 L 610.5 1651 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_324"
          d="M 1585.5 1651 L 1586 1653.5 L 1585 1653.5 L 1585.5 1651 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_325"
          d="M 1491.5 1657 L 1492 1660.5 L 1491 1660.5 L 1491.5 1657 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_326"
          d="M 514.5 1658 L 515 1660.5 L 514 1660.5 L 514.5 1658 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_327"
          d="M 609.5 1658 L 610 1660.5 L 609 1660.5 L 609.5 1658 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_328"
          d="M 1586.5 1658 L 1587 1660.5 L 1586 1660.5 L 1586.5 1658 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_329"
          d="M 1492.5 1664 L 1493 1667.5 L 1492 1667.5 L 1492.5 1664 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_330"
          d="M 513.5 1665 L 514 1667.5 L 513 1667.5 L 513.5 1665 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_331"
          d="M 608.5 1665 L 609 1667.5 L 608 1667.5 L 608.5 1665 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_332"
          d="M 1587.5 1665 L 1588 1667.5 L 1587 1667.5 L 1587.5 1665 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_333"
          d="M 1493.5 1671 L 1494 1674.5 L 1493 1674.5 L 1493.5 1671 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_334"
          d="M 512.5 1672 L 513 1674.5 L 512 1674.5 L 512.5 1672 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_335"
          d="M 607.5 1672 L 608 1674.5 L 607 1674.5 L 607.5 1672 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_336"
          d="M 1588.5 1672 L 1589 1674.5 L 1588 1674.5 L 1588.5 1672 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_337"
          d="M 1494.5 1678 L 1495 1681.5 L 1494 1681.5 L 1494.5 1678 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_338"
          d="M 511.5 1679 L 512 1681.5 L 511 1681.5 L 511.5 1679 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_339"
          d="M 606.5 1679 L 607 1681.5 L 606 1681.5 L 606.5 1679 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_340"
          d="M 1589.5 1679 L 1590 1681.5 L 1589 1681.5 L 1589.5 1679 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_341"
          d="M 605.5 1685 L 606 1688.5 L 605 1688.5 L 605.5 1685 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_342"
          d="M 1495.5 1685 L 1496 1688.5 L 1495 1688.5 L 1495.5 1685 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_343"
          d="M 1590.5 1685 L 1591 1688.5 L 1590 1688.5 L 1590.5 1685 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_344"
          d="M 510.5 1686 L 511 1688.5 L 510 1688.5 L 510.5 1686 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_345"
          d="M 604.5 1692 L 605 1695.5 L 604 1695.5 L 604.5 1692 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_346"
          d="M 1496.5 1692 L 1497 1695.5 L 1496 1695.5 L 1496.5 1692 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_347"
          d="M 1591.5 1692 L 1592 1695.5 L 1591 1695.5 L 1591.5 1692 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_348"
          d="M 509.5 1693 L 510 1695.5 L 509 1695.5 L 509.5 1693 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_349"
          d="M 603.5 1699 L 604 1702.5 L 603 1702.5 L 603.5 1699 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_350"
          d="M 1497.5 1699 L 1498 1701.5 L 1497 1701.5 L 1497.5 1699 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_351"
          d="M 1592.5 1699 L 1593 1702.5 L 1592 1702.5 L 1592.5 1699 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_352"
          d="M 508.5 1700 L 509 1702.5 L 508 1702.5 L 508.5 1700 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_353"
          d="M 602.5 1706 L 603 1709.5 L 602 1709.5 L 602.5 1706 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_354"
          d="M 1498.5 1706 L 1499 1708.5 L 1498 1708.5 L 1498.5 1706 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_355"
          d="M 1593.5 1706 L 1594 1709.5 L 1593 1709.5 L 1593.5 1706 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_356"
          d="M 507.5 1707 L 508 1709.5 L 507 1709.5 L 507.5 1707 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_357"
          d="M 601.5 1713 L 602 1716.5 L 601 1716.5 L 601.5 1713 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_358"
          d="M 1499.5 1713 L 1500 1715.5 L 1499 1715.5 L 1499.5 1713 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_359"
          d="M 1594.5 1713 L 1595 1716.5 L 1594 1716.5 L 1594.5 1713 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_360"
          d="M 506.5 1714 L 507 1716.5 L 506 1716.5 L 506.5 1714 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_361"
          d="M 505.5 1720 L 506 1723.5 L 505 1723.5 L 505.5 1720 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_362"
          d="M 600.5 1720 L 601 1723.5 L 600 1723.5 L 600.5 1720 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_363"
          d="M 1500.5 1720 L 1501 1722.5 L 1500 1722.5 L 1500.5 1720 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_364"
          d="M 1595.5 1720 L 1596 1723.5 L 1595 1723.5 L 1595.5 1720 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_365"
          d="M 504.5 1727 L 505 1730.5 L 504 1730.5 L 504.5 1727 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_366"
          d="M 599.5 1727 L 600 1730.5 L 599 1730.5 L 599.5 1727 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_367"
          d="M 1501.5 1727 L 1502 1729.5 L 1501 1729.5 L 1501.5 1727 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_368"
          d="M 1596.5 1727 L 1597 1730.5 L 1596 1730.5 L 1596.5 1727 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_369"
          d="M 503.5 1734 L 504 1737.5 L 503 1737.5 L 503.5 1734 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_370"
          d="M 598.5 1734 L 599 1736.5 L 598 1736.5 L 598.5 1734 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_371"
          d="M 1502.5 1734 L 1503 1736.5 L 1502 1736.5 L 1502.5 1734 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_372"
          d="M 1597.5 1734 L 1598 1736.5 L 1597 1736.5 L 1597.5 1734 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_373"
          d="M 502.5 1741 L 503 1744.5 L 502 1744.5 L 502.5 1741 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_374"
          d="M 597.5 1741 L 598 1743.5 L 597 1743.5 L 597.5 1741 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_375"
          d="M 1503.5 1741 L 1504 1743.5 L 1503 1743.5 L 1503.5 1741 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_376"
          d="M 1598.5 1741 L 1599 1743.5 L 1598 1743.5 L 1598.5 1741 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_377"
          d="M 501.5 1748 L 502 1751.5 L 501 1751.5 L 501.5 1748 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_378"
          d="M 596.5 1748 L 597 1750.5 L 596 1750.5 L 596.5 1748 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_379"
          d="M 1504.5 1748 L 1505 1750.5 L 1504 1750.5 L 1504.5 1748 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_380"
          d="M 1599.5 1748 L 1600 1750.5 L 1599 1750.5 L 1599.5 1748 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_381"
          d="M 95.5 1752 L 96 1759.5 L 95 1759.5 L 95.5 1752 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_382"
          d="M 2005.5 1752 L 2006 1759.5 L 2005 1759.5 L 2005.5 1752 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_383"
          d="M 500.5 1755 L 501 1758.5 L 500 1758.5 L 500.5 1755 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_384"
          d="M 1600.5 1755 L 1601 1757.5 L 1600 1757.5 L 1600.5 1755 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_385"
          d="M 98.5 1765 L 101.5 1769 L 98.5 1765 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_386"
          d="M 2002.5 1765 L 1999.5 1769 L 2002.5 1765 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_387"
          d="M 496.5 1766 L 495.5 1768 L 496.5 1766 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_388"
          d="M 999.5 1766 L 998.5 1768 L 999.5 1766 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_389"
          d="M 1101.5 1766 L 1102.5 1768 L 1101.5 1766 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_390"
          d="M 1604.5 1766 L 1605.5 1768 L 1604.5 1766 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_391"
          d="M 602.5 1768 L 603.5 1770 L 602.5 1768 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_392"
          d="M 1498.5 1768 L 1497.5 1770 L 1498.5 1768 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_393"
          d="M 108.5 1771 L 488 1771.5 L 108.5 1772 L 108.5 1771 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_394"
          d="M 609.5 1771 L 991 1771.5 L 609.5 1772 L 609.5 1771 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_395"
          d="M 1110.5 1771 L 1492 1771.5 L 1110.5 1772 L 1110.5 1771 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_396"
          d="M 1613.5 1771 L 1993 1771.5 L 1613.5 1772 L 1613.5 1771 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_397"
          d="M 72.5 1865 L 469 1865.5 L 72.5 1866 L 72.5 1865 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_398"
          d="M 591.5 1865 L 991 1865.5 L 591.5 1866 L 591.5 1865 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_399"
          d="M 1110.5 1865 L 1510 1865.5 L 1110.5 1866 L 1110.5 1865 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_400"
          d="M 1632.5 1865 L 2029 1865.5 L 1632.5 1866 L 1632.5 1865 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_401"
          d="M 65.5 1868 L 64.5 1870 L 65.5 1868 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_402"
          d="M 2035.5 1868 L 2036.5 1870 L 2035.5 1868 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_403"
          d="M 998.5 1869 L 999.5 1871 L 998.5 1869 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_404"
          d="M 1102.5 1869 L 1101.5 1871 L 1102.5 1869 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_405"
          d="M 62.5 1871 L 61.5 1873 L 62.5 1871 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_406"
          d="M 2038.5 1871 L 2039.5 1873 L 2038.5 1871 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_407"
          d="M 479.5 1872 L 480.5 1874 L 479.5 1872 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_408"
          d="M 580.5 1872 L 579.5 1874 L 580.5 1872 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_409"
          d="M 1520.5 1872 L 1521.5 1874 L 1520.5 1872 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_410"
          d="M 1621.5 1872 L 1620.5 1874 L 1621.5 1872 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_411"
          d="M 1003.5 1878 L 1004 2087.5 L 1003 2087.5 L 1003.5 1878 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_412"
          d="M 1097.5 1879 L 1098 2087.5 L 1097 2087.5 L 1097.5 1879 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_413"
          d="M 1523.5 1879 L 1524 1882.5 L 1523 1882.5 L 1523.5 1879 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_414"
          d="M 482.5 1880 L 483 1882.5 L 482 1882.5 L 482.5 1880 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_415"
          d="M 577.5 1880 L 578 1882.5 L 577 1882.5 L 577.5 1880 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_416"
          d="M 1618.5 1880 L 1619 1882.5 L 1618 1882.5 L 1618.5 1880 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_417"
          d="M 1524.5 1886 L 1525 1889.5 L 1524 1889.5 L 1524.5 1886 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_418"
          d="M 481.5 1887 L 482 1889.5 L 481 1889.5 L 481.5 1887 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_419"
          d="M 576.5 1887 L 577 1889.5 L 576 1889.5 L 576.5 1887 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_420"
          d="M 1619.5 1887 L 1620 1889.5 L 1619 1889.5 L 1619.5 1887 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_421"
          d="M 1525.5 1893 L 1526 1896.5 L 1525 1896.5 L 1525.5 1893 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_422"
          d="M 480.5 1894 L 481 1896.5 L 480 1896.5 L 480.5 1894 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_423"
          d="M 575.5 1894 L 576 1896.5 L 575 1896.5 L 575.5 1894 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_424"
          d="M 1620.5 1894 L 1621 1896.5 L 1620 1896.5 L 1620.5 1894 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_425"
          d="M 479.5 1900 L 480 1903.5 L 479 1903.5 L 479.5 1900 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_426"
          d="M 1526.5 1900 L 1527 1903.5 L 1526 1903.5 L 1526.5 1900 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_427"
          d="M 574.5 1901 L 575 1903.5 L 574 1903.5 L 574.5 1901 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_428"
          d="M 1621.5 1901 L 1622 1903.5 L 1621 1903.5 L 1621.5 1901 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_429"
          d="M 478.5 1907 L 479 1910.5 L 478 1910.5 L 478.5 1907 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_430"
          d="M 1527.5 1907 L 1528 1910.5 L 1527 1910.5 L 1527.5 1907 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_431"
          d="M 573.5 1908 L 574 1910.5 L 573 1910.5 L 573.5 1908 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_432"
          d="M 1622.5 1908 L 1623 1910.5 L 1622 1910.5 L 1622.5 1908 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_433"
          d="M 477.5 1914 L 478 1917.5 L 477 1917.5 L 477.5 1914 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_434"
          d="M 572.5 1914 L 573 1917.5 L 572 1917.5 L 572.5 1914 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_435"
          d="M 1528.5 1914 L 1529 1917.5 L 1528 1917.5 L 1528.5 1914 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_436"
          d="M 1623.5 1915 L 1624 1917.5 L 1623 1917.5 L 1623.5 1915 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_437"
          d="M 476.5 1921 L 477 1924.5 L 476 1924.5 L 476.5 1921 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_438"
          d="M 571.5 1921 L 572 1924.5 L 571 1924.5 L 571.5 1921 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_439"
          d="M 1529.5 1921 L 1530 1924.5 L 1529 1924.5 L 1529.5 1921 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_440"
          d="M 1624.5 1921 L 1625 1924.5 L 1624 1924.5 L 1624.5 1921 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_441"
          d="M 475.5 1928 L 476 1931.5 L 475 1931.5 L 475.5 1928 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_442"
          d="M 570.5 1928 L 571 1931.5 L 570 1931.5 L 570.5 1928 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_443"
          d="M 1530.5 1928 L 1531 1930.5 L 1530 1930.5 L 1530.5 1928 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_444"
          d="M 1625.5 1928 L 1626 1931.5 L 1625 1931.5 L 1625.5 1928 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_445"
          d="M 474.5 1935 L 475 1938.5 L 474 1938.5 L 474.5 1935 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_446"
          d="M 569.5 1935 L 570 1938.5 L 569 1938.5 L 569.5 1935 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_447"
          d="M 1531.5 1935 L 1532 1937.5 L 1531 1937.5 L 1531.5 1935 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_448"
          d="M 1626.5 1935 L 1627 1938.5 L 1626 1938.5 L 1626.5 1935 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_449"
          d="M 473.5 1942 L 474 1945.5 L 473 1945.5 L 473.5 1942 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_450"
          d="M 568.5 1942 L 569 1945.5 L 568 1945.5 L 568.5 1942 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_451"
          d="M 1532.5 1942 L 1533 1944.5 L 1532 1944.5 L 1532.5 1942 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_452"
          d="M 1627.5 1942 L 1628 1945.5 L 1627 1945.5 L 1627.5 1942 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_453"
          d="M 472.5 1949 L 473 1951.5 L 472 1951.5 L 472.5 1949 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_454"
          d="M 567.5 1949 L 568 1952.5 L 567 1952.5 L 567.5 1949 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_455"
          d="M 1533.5 1949 L 1534 1951.5 L 1533 1951.5 L 1533.5 1949 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_456"
          d="M 1628.5 1949 L 1629 1952.5 L 1628 1952.5 L 1628.5 1949 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_457"
          d="M 471.5 1956 L 472 1958.5 L 471 1958.5 L 471.5 1956 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_458"
          d="M 566.5 1956 L 567 1959.5 L 566 1959.5 L 566.5 1956 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_459"
          d="M 1534.5 1956 L 1535 1958.5 L 1534 1958.5 L 1534.5 1956 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_460"
          d="M 1629.5 1956 L 1630 1959.5 L 1629 1959.5 L 1629.5 1956 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_461"
          d="M 470.5 1963 L 471 1965.5 L 470 1965.5 L 470.5 1963 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_462"
          d="M 565.5 1963 L 566 1965.5 L 565 1965.5 L 565.5 1963 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_463"
          d="M 1535.5 1963 L 1536 1965.5 L 1535 1965.5 L 1535.5 1963 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_464"
          d="M 1630.5 1963 L 1631 1966.5 L 1630 1966.5 L 1630.5 1963 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_465"
          d="M 469.5 1970 L 470 1972.5 L 469 1972.5 L 469.5 1970 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_466"
          d="M 564.5 1970 L 565 1972.5 L 564 1972.5 L 564.5 1970 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_467"
          d="M 1536.5 1970 L 1537 1972.5 L 1536 1972.5 L 1536.5 1970 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_468"
          d="M 1631.5 1970 L 1632 1972.5 L 1631 1972.5 L 1631.5 1970 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_469"
          d="M 468.5 1977 L 469 1979.5 L 468 1979.5 L 468.5 1977 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_470"
          d="M 563.5 1977 L 564 1979.5 L 563 1979.5 L 563.5 1977 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_471"
          d="M 1537.5 1977 L 1538 1979.5 L 1537 1979.5 L 1537.5 1977 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_472"
          d="M 1632.5 1977 L 1633 1979.5 L 1632 1979.5 L 1632.5 1977 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_473"
          d="M 467.5 1984 L 468 1986.5 L 467 1986.5 L 467.5 1984 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_474"
          d="M 562.5 1984 L 563 1986.5 L 562 1986.5 L 562.5 1984 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_475"
          d="M 1538.5 1984 L 1539 1986.5 L 1538 1986.5 L 1538.5 1984 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_476"
          d="M 1633.5 1984 L 1634 1986.5 L 1633 1986.5 L 1633.5 1984 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_477"
          d="M 1539.5 1990 L 1540 1993.5 L 1539 1993.5 L 1539.5 1990 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_478"
          d="M 466.5 1991 L 467 1993.5 L 466 1993.5 L 466.5 1991 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_479"
          d="M 561.5 1991 L 562 1993.5 L 561 1993.5 L 561.5 1991 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_480"
          d="M 1634.5 1991 L 1635 1993.5 L 1634 1993.5 L 1634.5 1991 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_481"
          d="M 1540.5 1997 L 1541 2000.5 L 1540 2000.5 L 1540.5 1997 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_482"
          d="M 465.5 1998 L 466 2000.5 L 465 2000.5 L 465.5 1998 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_483"
          d="M 560.5 1998 L 561 2000.5 L 560 2000.5 L 560.5 1998 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_484"
          d="M 1635.5 1998 L 1636 2000.5 L 1635 2000.5 L 1635.5 1998 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_485"
          d="M 464.5 2004 L 465 2007.5 L 464 2007.5 L 464.5 2004 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_486"
          d="M 1541.5 2004 L 1542 2007.5 L 1541 2007.5 L 1541.5 2004 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_487"
          d="M 559.5 2005 L 560 2007.5 L 559 2007.5 L 559.5 2005 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_488"
          d="M 1636.5 2005 L 1637 2007.5 L 1636 2007.5 L 1636.5 2005 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_489"
          d="M 463.5 2011 L 464 2014.5 L 463 2014.5 L 463.5 2011 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_490"
          d="M 1542.5 2011 L 1543 2014.5 L 1542 2014.5 L 1542.5 2011 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_491"
          d="M 558.5 2012 L 559 2014.5 L 558 2014.5 L 558.5 2012 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_492"
          d="M 1637.5 2012 L 1638 2014.5 L 1637 2014.5 L 1637.5 2012 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_493"
          d="M 462.5 2018 L 463 2021.5 L 462 2021.5 L 462.5 2018 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_494"
          d="M 1543.5 2018 L 1544 2021.5 L 1543 2021.5 L 1543.5 2018 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_495"
          d="M 557.5 2019 L 558 2021.5 L 557 2021.5 L 557.5 2019 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_496"
          d="M 1638.5 2019 L 1639 2021.5 L 1638 2021.5 L 1638.5 2019 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_497"
          d="M 461.5 2025 L 462 2028.5 L 461 2028.5 L 461.5 2025 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_498"
          d="M 556.5 2025 L 557 2028.5 L 556 2028.5 L 556.5 2025 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_499"
          d="M 1544.5 2025 L 1545 2028.5 L 1544 2028.5 L 1544.5 2025 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_500"
          d="M 1639.5 2025 L 1640 2028.5 L 1639 2028.5 L 1639.5 2025 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_501"
          d="M 460.5 2032 L 461 2035.5 L 460 2035.5 L 460.5 2032 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_502"
          d="M 555.5 2032 L 556 2035.5 L 555 2035.5 L 555.5 2032 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_503"
          d="M 1545.5 2032 L 1546 2035.5 L 1545 2035.5 L 1545.5 2032 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_504"
          d="M 1640.5 2032 L 1641 2035.5 L 1640 2035.5 L 1640.5 2032 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_505"
          d="M 459.5 2039 L 460 2042.5 L 459 2042.5 L 459.5 2039 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_506"
          d="M 554.5 2039 L 555 2042.5 L 554 2042.5 L 554.5 2039 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_507"
          d="M 1546.5 2039 L 1547 2042.5 L 1546 2042.5 L 1546.5 2039 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_508"
          d="M 1641.5 2039 L 1642 2042.5 L 1641 2042.5 L 1641.5 2039 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_509"
          d="M 458.5 2046 L 459 2049.5 L 458 2049.5 L 458.5 2046 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_510"
          d="M 553.5 2046 L 554 2049.5 L 553 2049.5 L 553.5 2046 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_511"
          d="M 1547.5 2046 L 1548 2048.5 L 1547 2048.5 L 1547.5 2046 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_512"
          d="M 1642.5 2046 L 1643 2049.5 L 1642 2049.5 L 1642.5 2046 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_513"
          d="M 457.5 2053 L 458 2056.5 L 457 2056.5 L 457.5 2053 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_514"
          d="M 552.5 2053 L 553 2056.5 L 552 2056.5 L 552.5 2053 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_515"
          d="M 1548.5 2053 L 1549 2055.5 L 1548 2055.5 L 1548.5 2053 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_516"
          d="M 1643.5 2053 L 1644 2056.5 L 1643 2056.5 L 1643.5 2053 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_517"
          d="M 456.5 2060 L 457 2062.5 L 456 2062.5 L 456.5 2060 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_518"
          d="M 551.5 2060 L 552 2063.5 L 551 2063.5 L 551.5 2060 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_519"
          d="M 1549.5 2060 L 1550 2062.5 L 1549 2062.5 L 1549.5 2060 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_520"
          d="M 1644.5 2060 L 1645 2063.5 L 1644 2063.5 L 1644.5 2060 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_521"
          d="M 455.5 2067 L 456 2069.5 L 455 2069.5 L 455.5 2067 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_522"
          d="M 550.5 2067 L 551 2070.5 L 550 2070.5 L 550.5 2067 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_523"
          d="M 1550.5 2067 L 1551 2069.5 L 1550 2069.5 L 1550.5 2067 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_524"
          d="M 1645.5 2067 L 1646 2070.5 L 1645 2070.5 L 1645.5 2067 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_525"
          d="M 454.5 2074 L 455 2076.5 L 454 2076.5 L 454.5 2074 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_526"
          d="M 549.5 2074 L 550 2076.5 L 549 2076.5 L 549.5 2074 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_527"
          d="M 1551.5 2074 L 1552 2076.5 L 1551 2076.5 L 1551.5 2074 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_528"
          d="M 1646.5 2074 L 1647 2076.5 L 1646 2076.5 L 1646.5 2074 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_529"
          d="M 0.5 2081 L 1 2088.5 L 0 2088.5 L 0.5 2081 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_530"
          d="M 453.5 2081 L 454 2083.5 L 453 2083.5 L 453.5 2081 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_531"
          d="M 548.5 2081 L 549 2088.5 L 548 2088.5 L 548.5 2081 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_532"
          d="M 1552.5 2081 L 1553 2088.5 L 1552 2088.5 L 1552.5 2081 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_533"
          d="M 1647.5 2081 L 1648 2083.5 L 1647 2083.5 L 1647.5 2081 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_534"
          d="M 2100.5 2081 L 2101 2088.5 L 2100 2088.5 L 2100.5 2081 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_535"
          d="M 2.5 2093 L 3.5 2095 L 2.5 2093 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_536"
          d="M 550.5 2093 L 551.5 2095 L 550.5 2093 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_537"
          d="M 2098.5 2093 L 2097.5 2095 L 2098.5 2093 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_538"
          d="M 997.5 2097 L 996.5 2099 L 997.5 2097 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_539"
          d="M 1103.5 2097 L 1104.5 2099 L 1103.5 2097 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_540"
          d="M 990.5 2100 L 993 2100.5 L 990.5 2101 L 990.5 2100 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_541"
          d="M 1108.5 2100 L 1111 2100.5 L 1108.5 2101 L 1108.5 2100 Z"
          fill="currentColor"
          fillOpacity="0.6117647"
          stroke="currentColor"
          strokeOpacity="0.6117647"
          strokeWidth="1"/>
        <path
          id="path_542"
          d="M 1016 1 L 1085 1 L 1085 181 L 1016 181 L 1016 1 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_543"
          d="M 703.5 130 L 832 257.5 L 782.5 307 L 655 179.5 L 703.5 130 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_544"
          d="M 1396.5 130 L 1446 178.5 L 1318.5 307 L 1269 257.5 L 1396.5 130 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_545"
          d="M 1035.5 296 L 1065.5 296 L 1066.5 297 L 1084.5 298 L 1112.5 304 L 1138.5 313 Q 1190.9 335.1 1225 375.5 Q 1256.3 411.2 1272 462.5 L 1277 484.5 L 1278 496.5 L 1279 497.5 L 1279 506.5 L 1280 507.5 L 1280 543.5 L 1279 544.5 L 1279 553.5 L 1278 554.5 L 1277 566.5 L 1272 588.5 Q 1266.1 609.6 1257 627.5 Q 1237.2 666.7 1206.5 695 Q 1174.5 725 1129.5 742 L 1102.5 750 L 1080.5 754 L 1072.5 754 L 1071.5 755 L 1029.5 755 L 1028.5 754 L 1013.5 753 L 990.5 748 L 954.5 735 Q 910.5 714.5 880 680.5 Q 849.9 647.6 833 601.5 L 826 576.5 L 822 553.5 L 822 544.5 L 821 543.5 L 821 507.5 L 822 506.5 L 822 497.5 L 823 496.5 L 824 484.5 L 830 459.5 Q 837.8 433.8 850 412.5 Q 870.3 376.8 900.5 351 Q 927.3 327.8 962.5 313 Q 980 305.5 1000.5 301 L 1016.5 298 L 1023.5 298 L 1024.5 297 L 1034.5 297 L 1035.5 296 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_546"
          d="M 526 491 L 706 491 L 706 560 L 526 560 L 526 491 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_547"
          d="M 1395 491 L 1575 491 L 1575 560 L 1395 560 L 1395 491 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_548"
          d="M 781.5 745 L 783.5 745 L 832 793.5 L 703.5 921 L 655 871.5 L 781.5 745 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_549"
          d="M 1317.5 745 L 1319.5 745 L 1446 871.5 L 1397.5 921 L 1269 793.5 L 1317.5 745 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_550"
          d="M 1016 870 L 1085 870 L 1085 1051 L 1016 1051 L 1016 870 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_551"
          d="M 260.5 1207 L 565.5 1207 L 574 1212.5 Q 578.5 1217 577 1227.5 L 572 1255.5 L 572 1261.5 L 571 1262.5 L 559 1352.5 L 557 1359.5 L 548 1428.5 Q 546.5 1435.4 541.5 1439 L 534.5 1442 L 203.5 1442 Q 196.6 1440.5 193 1435.5 Q 189.2 1430.8 191 1420.5 L 196 1406.5 L 249 1219.5 Q 250.8 1212.3 256.5 1209 L 260.5 1207 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_552"
          d="M 684.5 1207 L 992.5 1207 L 1001 1213.5 L 1003 1217.5 L 1003 1430.5 L 998.5 1438 L 990.5 1442 L 656.5 1442 Q 649.5 1440.5 646 1435.5 L 644 1431.5 L 644 1418.5 L 648 1397.5 L 648 1391.5 L 649 1390.5 L 661 1300.5 L 663 1293.5 L 672 1224.5 L 674 1215.5 L 678.5 1210 L 684.5 1207 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_553"
          d="M 1108.5 1207 L 1416.5 1207 Q 1422.3 1208.3 1425 1212.5 L 1428 1218.5 L 1432 1251.5 L 1434 1258.5 L 1434 1264.5 L 1435 1265.5 L 1435 1271.5 L 1436 1272.5 L 1448 1362.5 L 1450 1369.5 L 1450 1375.5 L 1451 1376.5 L 1451 1382.5 L 1452 1383.5 L 1452 1389.5 L 1457 1418.5 L 1457 1431.5 L 1451.5 1439 L 1444.5 1442 L 1110.5 1442 L 1101 1436.5 L 1098 1430.5 L 1098 1217.5 L 1104.5 1209 L 1108.5 1207 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_554"
          d="M 1535.5 1207 L 1840.5 1207 L 1850 1214.5 L 1910 1420.5 L 1910 1431.5 L 1904.5 1439 L 1897.5 1442 L 1566.5 1442 Q 1559.1 1440.9 1556 1435.5 L 1553 1428.5 L 1553 1422.5 L 1552 1421.5 L 1545 1366.5 L 1543 1359.5 L 1543 1353.5 L 1542 1352.5 L 1542 1346.5 L 1541 1345.5 L 1529 1255.5 L 1524 1227.5 L 1524 1218.5 L 1529.5 1210 L 1535.5 1207 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_555"
          d="M 167.5 1536 L 515.5 1536 Q 523.3 1537.2 527 1542.5 Q 530 1546.5 530 1553.5 L 529 1554.5 L 523 1602.5 L 521 1609.5 L 521 1615.5 L 520 1616.5 L 520 1622.5 L 519 1623.5 L 519 1629.5 L 518 1630.5 L 518 1636.5 L 517 1637.5 L 517 1643.5 L 516 1644.5 L 516 1650.5 L 515 1651.5 L 515 1657.5 L 514 1658.5 L 514 1664.5 L 513 1665.5 L 513 1671.5 L 512 1672.5 L 512 1678.5 L 511 1679.5 L 511 1685.5 L 510 1686.5 L 507 1713.5 L 505 1720.5 L 500 1760.5 L 493.5 1769 L 489.5 1771 L 106.5 1771 L 98 1764.5 Q 94.3 1760.2 96 1750.5 L 113 1694.5 L 125 1649.5 L 128 1642.5 L 142 1590.5 L 145 1583.5 L 153 1552.5 L 157 1542.5 L 160.5 1539 L 167.5 1536 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_556"
          d="M 639.5 1536 L 989.5 1536 Q 996.3 1537.3 1000 1541.5 L 1003 1547.5 L 1003 1760.5 L 996.5 1769 L 992.5 1771 L 606.5 1771 L 599 1765.5 L 596 1759.5 L 596 1751.5 L 600 1730.5 L 600 1724.5 L 601 1723.5 L 601 1717.5 L 602 1716.5 L 614 1626.5 L 616 1619.5 L 625 1550.5 Q 626.2 1542.7 631.5 1539 L 639.5 1536 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_557"
          d="M 1111.5 1536 L 1462.5 1536 Q 1469.4 1537.5 1473 1542.5 L 1476 1550.5 L 1476 1556.5 L 1477 1557.5 L 1481 1591.5 L 1483 1598.5 L 1483 1604.5 L 1484 1605.5 L 1496 1695.5 L 1498 1702.5 L 1498 1708.5 L 1499 1709.5 L 1499 1715.5 L 1500 1716.5 L 1500 1722.5 L 1505 1751.5 L 1505 1759.5 L 1499.5 1768 L 1494.5 1771 L 1108.5 1771 L 1100 1764.5 L 1098 1760.5 L 1098 1547.5 L 1102 1540 L 1111.5 1536 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_558"
          d="M 1585.5 1536 L 1933.5 1536 Q 1940.5 1537.5 1944 1542.5 L 1948 1552.5 L 1999 1732.5 L 2004 1746.5 L 2005 1760.5 L 1998.5 1769 L 1994.5 1771 L 1611.5 1771 L 1603 1764.5 L 1600 1754.5 L 1600 1748.5 L 1599 1747.5 L 1599 1741.5 L 1598 1740.5 L 1598 1734.5 L 1597 1733.5 L 1597 1727.5 L 1596 1726.5 L 1596 1720.5 L 1595 1719.5 L 1595 1713.5 L 1594 1712.5 L 1594 1706.5 L 1593 1705.5 L 1593 1699.5 L 1589 1678.5 L 1589 1672.5 L 1588 1671.5 L 1588 1665.5 L 1587 1664.5 L 1587 1658.5 L 1586 1657.5 L 1586 1651.5 L 1585 1650.5 L 1585 1644.5 L 1584 1643.5 L 1584 1637.5 L 1583 1636.5 L 1583 1630.5 L 1582 1629.5 L 1582 1623.5 L 1581 1622.5 L 1581 1616.5 L 1580 1615.5 L 1580 1609.5 L 1579 1608.5 L 1579 1602.5 L 1578 1601.5 L 1578 1595.5 L 1577 1594.5 L 1577 1588.5 L 1576 1587.5 L 1576 1581.5 L 1575 1580.5 L 1575 1574.5 L 1571 1551.5 L 1574 1542.5 L 1577.5 1539 L 1585.5 1536 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_559"
          d="M 70.5 1866 L 471.5 1866 L 479 1871.5 Q 483.5 1876 482 1886.5 L 479 1900.5 L 479 1906.5 L 478 1907.5 L 466 1997.5 L 464 2004.5 L 453 2087.5 Q 451.4 2094.5 446.5 2098 L 439.5 2101 L 13.5 2101 L 4 2095.5 Q -1.1 2091.1 1 2079.5 L 6 2065.5 L 59 1878.5 L 63 1870 L 70.5 1866 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_560"
          d="M 588.5 1866 L 992.5 1866 L 1001 1872.5 L 1003 1876.5 L 1003 2089.5 L 998.5 2097 L 989.5 2101 L 561.5 2101 L 552 2095.5 L 549 2090.5 L 549 2077.5 L 551 2070.5 L 551 2064.5 L 552 2063.5 L 552 2057.5 L 553 2056.5 L 553 2050.5 L 554 2049.5 L 554 2043.5 L 555 2042.5 L 555 2036.5 L 556 2035.5 L 565 1966.5 L 567 1959.5 L 567 1953.5 L 568 1952.5 L 578 1877.5 L 584.5 1868 L 588.5 1866 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_561"
          d="M 1108.5 1866 L 1512.5 1866 L 1520 1871.5 L 1522 1874.5 L 1524 1883.5 L 1524 1889.5 L 1525 1890.5 L 1529 1924.5 L 1531 1931.5 L 1531 1937.5 L 1532 1938.5 L 1532 1944.5 L 1533 1945.5 L 1533 1951.5 L 1534 1952.5 L 1546 2042.5 L 1547 2043.5 L 1551 2076.5 L 1552 2077.5 L 1552 2090.5 L 1546.5 2098 L 1539.5 2101 L 1111.5 2101 Q 1104.8 2099.8 1101 2095.5 L 1098 2089.5 L 1098 1876.5 L 1104.5 1868 L 1108.5 1866 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_562"
          d="M 1629.5 1866 L 2030.5 1866 L 2038 1870 L 2042 1878.5 L 2095 2065.5 L 2100 2079.5 L 2100 2090.5 L 2094.5 2098 L 2087.5 2101 L 1661.5 2101 Q 1654.6 2099.5 1651 2094.5 L 1648 2087.5 L 1640 2025.5 L 1638 2018.5 L 1638 2012.5 L 1637 2011.5 L 1637 2005.5 L 1636 2004.5 L 1636 1998.5 L 1635 1997.5 L 1635 1991.5 L 1634 1990.5 L 1634 1984.5 L 1633 1983.5 L 1633 1977.5 L 1632 1976.5 L 1632 1970.5 L 1631 1969.5 L 1631 1963.5 L 1630 1962.5 L 1630 1956.5 L 1625 1927.5 L 1625 1921.5 L 1624 1920.5 L 1620 1887.5 L 1619 1886.5 L 1619 1877.5 L 1625.5 1868 L 1629.5 1866 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SolarPanelsSecond')

export default icon
