import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 218.144 218.144" space="preserve"><path d="M218.144 129.573c0 23.949-19.414 43.363-43.363 43.363H30.534C13.67 172.935 0 159.265 0 142.402s13.67-30.534 30.534-30.534c2.391 0 4.714.284 6.946.803a65.048 65.048 0 0 1-.107-3.599c0-35.271 28.593-63.864 63.863-63.864 27.979 0 51.738 17.999 60.374 43.044a43.328 43.328 0 0 1 13.171-2.043c23.949.001 43.363 19.415 43.363 43.364z"/></svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'CloudIcon')

export default icon
