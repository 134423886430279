import React from 'react'
import { Typography } from '@mui/material'
import { useDispatch } from '@app/store'
import classNames from 'classnames'
import { saveChangedVirtualSwitchData, triggerVirtualSwitch } from '@app/actions/virtualSwitches/virtualSwitches'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import { VirtualSwitchIcon } from 'icons'
import ColoredSwitch from '@app/components/MyDevices/DevicesList/DeviceCard/CardUIKit/ColoredSwitch/ColoredSwitch'
import { IVirtualSwitch } from '@app/@types/virtualSwitches'
import useStyles from './VirtualSwitchCard.style'

interface Props {
  virtualSwitch: IVirtualSwitch,
  isMobile: boolean,
}

const VirtualSwitchCard: React.FC<Props> = (props) => {
  const { virtualSwitch, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSwitchChange = () => {
    dispatch(saveChangedVirtualSwitchData(virtualSwitch))
    dispatch(triggerVirtualSwitch())
  }

  return (
    <Wrapper
      className={classNames(classes.card, isMobile && classes.cardMobile)}
    >
      <Wrapper className={classes.cardTopSide}>
        <Wrapper className={classes.cardIcon}>
          <VirtualSwitchIcon />
        </Wrapper>
        <Typography className={classes.cardTitle}>{virtualSwitch.number}</Typography>
      </Wrapper>
      <Wrapper className={classes.cardBottomSide}>
        { virtualSwitch.isEnablingProgress ? (
          <DotsLoading
            props={{
              width: 50, circleWidth: 12, circleHeight: 12,
            }}
          />
        )
          : (
            <Typography className={classNames(
              classes.cardStatus,
              virtualSwitch.enabled ? classes.cardStatusOn : classes.cardStatusOff,
            )}
            >
              {(virtualSwitch.enabled ? `${I18n.t('virtual_switches.is_on')}` : `${I18n.t('virtual_switches.is_off')}`)}
            </Typography>
          )}
        <ColoredSwitch
          disabled={virtualSwitch.isEnablingProgress}
          className={classes.switchRoot}
          checked={virtualSwitch.enabled}
          onChange={handleSwitchChange}
        />
      </Wrapper>
    </Wrapper>
  )
}

export default VirtualSwitchCard
