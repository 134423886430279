import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import { ITwrPatchData } from '@app/@types/myDevices'

const postNewTwrDevice = (access_token: string, txid: string) => Axios.post(`${API_URL}/api/devices/twr_devices`, { txid }, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteTwrDevice = (access_token: string, id: number) => Axios.delete(`${API_URL}/api/devices/twr_devices/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchTwrDevice = (access_token: string, id: number, data: ITwrPatchData) => Axios.patch(
  `${API_URL}/api/devices/twr_devices/${id}`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const getTwrAlarmOptions = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/twr_devices/options/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const getTwrSirenOption = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/twr_devices/radio_siren_option/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchTwrSirenOptions = (access_token: string, id: number, data: { option: number }) => Axios.patch(
  `${API_URL}/api/devices/twr_devices/radio_siren_option/${id}`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const getTakeOnBoardDeviceType = (access_token: string, id: number) => Axios.get(
  `${API_URL}/api/devices/twr_devices/take_on_board_device_type/${id}`, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const patchTakeOnBoardDeviceType = (access_token: string, id: number, data: { device_type: number }) => Axios.patch(
  `${API_URL}/api/devices/twr_devices/take_on_board_device_type/${id}`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

export default {
  postNewTwrDevice,
  deleteTwrDevice,
  patchTwrDevice,
  getTwrAlarmOptions,
  getTwrSirenOption,
  patchTwrSirenOptions,
  getTakeOnBoardDeviceType,
  patchTakeOnBoardDeviceType,
}
