import * as type from '@app/constants/myDevices/myDevices'
import { IControl, IZwaveOrTwrDevice, TDevice } from '@app/@types/myDevices'

export const syncMyDevices = () => ({
  type: type.SYNC_MY_DEVICES,
} as const)

export const setMyDevices = (devices: Array<TDevice>) => ({
  type: type.SET_MY_DEVICES,
  devices,
} as const)

export const sortDevices = () => ({
  type: type.SORT_MY_DEVICES,
} as const)

export const clearInterviewResult = () => ({
  type: type.CLEAR_INTERVIEW_RESULT,
} as const)

export const setCurrentDevice = (device: IZwaveOrTwrDevice) => ({
  type: type.SET_CURRENT_DEVICE,
  device,
} as const)

export const clearCurrentDevice = () => ({
  type: type.CLEAR_CURRENT_DEVICE,
} as const)

export const setCurrentDeviceControl = (control: IControl) => ({
  type: type.SET_CURRENT_DEVICE_CONTROL,
  control,
} as const)

export const resetMyDevices = () => ({
  type: type.RESET_MY_DEVICES,
} as const)
