import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22 2.2L19.8 0L11 8.8L2.2 0L0 2.2L8.8 11L0 19.8L2.2 22L11 13.2L19.8 22L22 19.8L13.2 11L22 2.2Z" fill="#D0021B"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'UnSealedSecurityIcon')

export default icon
