import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/oauth/token`

const config = () => ({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  },
})

const authByPassword = (
  username,
  password,
  clientId,
  clientSecret,
  scope,
  grantType = 'password',
) => (
  Axios.post(
    ENDPOINT,
    {
      username,
      password,
      client_id: clientId,
      client_secret: clientSecret,
      scope,
      grant_type: grantType,
    },
    config(),
  )
)

export default { authByPassword }
