import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveSensorNotificationsType,
  saveSensorNotificationsTypeDescription,
  saveSensorNotificationsEventType,
  saveSensorNotificationsEventDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectNotificationSensorType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/NotificationSensors/SelectNotificationSensorType/SelectNotificationlSensorType'
import SelectNotificationSensorTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/NotificationSensors/SelectNotificationSensorType/SelectNotificationlSensorType'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectNotificationSensorTypeIOS) : withStyles(SelectNotificationSensorType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isChangeSensorMode } = state.scenes.currentSceneCreating
  const sensors = state.scenes.sensorNotifications.devices
  return {
    isMobile,
    sensors,
    isChangeSensorMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveSensorNotificationsType,
  saveSensorNotificationsTypeDescription,
  saveSensorNotificationsEventType,
  saveSensorNotificationsEventDescription,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectNotificationSensorTypeEnhanced = enhance

export default withRouter(SelectNotificationSensorTypeEnhanced)
