import React, { useEffect } from 'react'

type LocationType = {
  pathname: string,
}
interface ScrollToTopProps{
  children?: React.ReactNode,
  location: LocationType,
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return children ? <>{children}</> : null
}

export default ScrollToTop
