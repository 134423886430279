import updateDevices from '@app/helpers/myStatus/updateDevicesViaActionCable'
import filterDevicesByName from '@app/helpers/myStatus/filterDevicesByName'
import sortDevicesByStatus from '@app/helpers/myStatus/sortDevicesByTheirStatus'
import * as type from '@app/constants/myStatus'

type State = {
  isChannelOpen: boolean,
  twrDevices: Array<{
    group: string | null,
    icon: number,
    id: number,
    name: string,
    room: string | null,
    signal_level: number,
    status: boolean,
    type: string,
    update: string,
  }>,
  zWaveDevices: Array<{
    group: string | null,
    room: string | null,
    icon: number,
    id: number,
    name: string,
    type: string,
  }>,
  backupTwrDevices: Array<{
    group: string | null,
    icon: number,
    id: number,
    name: string,
    room: string | null,
    signal_level: number,
    status: boolean,
    type: string,
    update: string,
  }>,
  backupZWaveDevices: Array<{
    group: string | null,
    room: string | null,
    icon: number,
    id: number,
    name: string,
    type: string,
  }>,
}

const initialState = {
  isChannelOpen: false,
  zWaveDevices: [],
  twrDevices: [],
  backupZWaveDevices: [],
  backupTwrDevices: [],
}

const myStatusDevices = (state: State = initialState, action: Object): State => {
  switch (action.type) {
  case type.SET_MY_STATUS_DEVICES: return {
    ...state,
    zWaveDevices: action.data.zwave_devices,
    twrDevices: sortDevicesByStatus(action.data.twr_devices),
    backupTwrDevices: sortDevicesByStatus(action.data.twr_devices),
    backupZWaveDevices: action.data.zwave_devices,
  }
  case type.FILTER_MY_STATUS_DEVICES: return filterDevicesByName(state, action)
  case type.OPEN_MY_STATUS_CHANNEL: return { ...state, isChannelOpen: true }
  case type.CLOSE_MY_STATUS_CHANNEL: return { ...state, isChannelOpen: false }
  case type.UPDATE_MY_STATUS_DEVICES: return updateDevices(state, action)
  case type.RESET_MY_STATUS_PIR: return {
    ...state,
    twrDevices: action.data.twrDevices,
    backupTwrDevices: action.data.backupTwrDevices,
  }
  default: return state
  }
}

export default myStatusDevices
