import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'

import { withStyles } from '@app/components/styles'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import SelectZWaveDeviceType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/DevicesConditions/SelectZWaveDevice/SelectZWaveDeviceType/SelectZWaveDeviceType'
import SelectZWaveDeviceTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/DevicesConditions/SelectZWaveDevice/SelectZWaveDeviceType/SelectZWaveDeviceType'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectZWaveDeviceTypeIOS) : withStyles(SelectZWaveDeviceType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  return {
    isMobile,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectZWaveDeviceTypeEnhanced = enhance

export default withRouter(SelectZWaveDeviceTypeEnhanced)
