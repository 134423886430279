import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 15 20" fill='none' xmlns="http://www.w3.org/2000/svg">
      <path d="M3.77778 3.77778H3.27778V4.27778V6.94444V7.44444H3.77778H16.2222H16.7222V6.94444V4.27778V3.77778H16.2222H3.77778ZM16.2222 10C12.4794 10 9.5 12.9794 9.5 16.7222C9.5 17.1522 9.53946 17.5866 9.62056 18H3.77778C3.07614 18 2.5 17.4239 2.5 16.7222V4.27778C2.5 3.57614 3.07614 3 3.77778 3H16.2222C16.9239 3 17.5 3.57614 17.5 4.27778V10.1206C17.0866 10.0395 16.6522 10 16.2222 10Z" stroke="currentColor"/>
      <path d="M15.3333 14.0778C15.8243 14.0778 16.2222 13.6799 16.2222 13.1889C16.2222 12.698 15.8243 12.3 15.3333 12.3C14.8424 12.3 14.4445 12.698 14.4445 13.1889C14.4445 13.6799 14.8424 14.0778 15.3333 14.0778Z" fill="currentColor"/>
      <path d="M16.2222 14.9667H14.4445V18.5222H16.2222V14.9667Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'AboutIcon')

export default icon
