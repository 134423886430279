import React from 'react'
import classNames from 'classnames'
import {
  Avatar, Divider, IconButton, SwipeableDrawer as MaterialDrawer, Typography,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { ChevronLeft } from 'icons'
import { closeAllTabs } from '@app/actions/menu'
import { ICurrentUser } from '@app/@types/users'
import { IAppConfig } from '@app/@types/appConfig'
import Menu from '@app/components/Menu/Menu'
import GuardPanel from '@app/components/GuardPanel/GuardPanel'
import useStyles from './Drawer.style'

interface Props {
  open: boolean,
  user: ICurrentUser,
  onClose: () => void,
  online: boolean,
  appConfig: IAppConfig,
}

const Drawer: React.FC<Props> = (props) => {
  const {
    open,
    user,
    onClose,
    online,
    appConfig,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <MaterialDrawer
      variant="temporary"
      open={open}
      anchor="left"
      classes={{
        paper: classNames(
          classes.drawerPaper,
        ),
      }}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={() => {
        dispatch(closeAllTabs())
        onClose()
      }}
      onOpen={() => {}}
    >
      <div className={classes.toolbarIcon}>
        <Avatar src={user.avatar} />
        <div className={classes.toolbarUserInfo}>
          <Typography variant="h6">
            {`${user.preferredName}`}
          </Typography>
          <Typography variant="caption">
            {user.role && I18n.t(`my_users.${user.role}`)}
          </Typography>
        </div>
        <IconButton
          onClick={() => {
            onClose()
            dispatch(closeAllTabs())
          }}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
      </div>
      <GuardPanel appConfig={appConfig} online={online} />
      <Divider />
      <Menu appConfig={appConfig} online={online} />
    </MaterialDrawer>
  )
}

export default Drawer
