import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_PATCH_CLOUD_FIRMWARE_INFO } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import {
  saveSubmittedDownloadingTime,
  saveSubmittedNightlyDownloading,
  update,
} from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import patchCloudFirmwareInfo from '@app/api/cloudFirmwareUpdate/patchCloudFirmwareInfo'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchCloudFirmwareInfoLogic = createLogic({
  type: SYNC_PATCH_CLOUD_FIRMWARE_INFO,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const {
      cloudFirmwareUpdate: {
        cloudFirmwareUpdateInfo: {
          downloadingType, autoInstall, selectedFirmwareVersion, downloadingTime, slotForDownload, slotForInstall,
        },
      },
    } = store.getState()
    const newData = {
      downloading_type: downloadingType,
      autoinstall: autoInstall,
      selected_version: selectedFirmwareVersion,
      time: downloadingTime,
      slot_for_download: slotForDownload,
      slot_for_install: slotForInstall,
    }
    dispatch(apiRequestSent(SYNC_PATCH_CLOUD_FIRMWARE_INFO))
    patchCloudFirmwareInfo(token, newData)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        if (data.downloading_type === 'set_time') {
          dispatch(saveSubmittedDownloadingTime(data.time))
        }
        if (data.downloading_type === 'nightly') {
          dispatch(saveSubmittedNightlyDownloading())
        }
        dispatch(apiRequestDone(SYNC_PATCH_CLOUD_FIRMWARE_INFO))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchCloudFirmwareInfo failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchCloudFirmwareInfoLogic
