import * as type from '@app/constants/numberOfZones'
import { initialValues } from '@app/lib/ProgramOptions'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as NumberOfZonesAction from '@app/actions/numberOfZones'
import { IProgramOptions } from '@app/@types/programOptions'
import ProgramOptions from '@app/lib/ProgramOptions/programOptions'

type NumberOfZonesActions = ReturnType<InferValueTypes<typeof NumberOfZonesAction>>

export interface INumberOfZonesState {
  initialOptions: IProgramOptions,
  currentOptions: IProgramOptions,
  defaultOptions: IProgramOptions,
  validation: boolean,
}

const initialState = {
  initialOptions: initialValues.numberOfZonesOptions,
  currentOptions: initialValues.numberOfZonesOptions,
  defaultOptions: initialValues.numberOfZonesOptions,
  validation: true,
}

const numberOfZones = (state: INumberOfZonesState = initialState, action: NumberOfZonesActions) => {
  switch (action.type) {
  case type.SET_NUMBER_OF_ZONES_INITIAL_OPTIONS: {
    const updatedNumberOfZones = action.programOptions.filter((el) => el.number === ProgramOptions.NUMBER_OF_ZONES_1375)
    return {
      ...state,
      initialOptions: updatedNumberOfZones,
    }
  }
  case type.SET_NUMBER_OF_ZONES_CURRENT_OPTIONS: {
    return {
      ...state,
      currentOptions: action.programOptions,
    }
  }

  case type.SET_NUMBER_OF_ZONES_VALIDATION: {
    return {
      ...state,
      validation: action.validation,
    }
  }

  default:
    return state
  }
}

export default numberOfZones
