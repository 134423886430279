import React from 'react'
import { List, ListItem, Typography } from '@mui/material'

import { withStyles } from '@mui/styles'
import RoomsStatsPopup from '@app/containers/MyEnergy/Tabs/RoomStats/RoomsStatsPopup'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './RoomsStatsItemList.style'
import RoomsStatsItem from './RoomsStatsItem'

class RoomsStatsItemList extends React.Component {
  render() {
    const {
      classes,
      RoomsStatsItems,
      isRoomsStatsPopupOpen,
      isMobile,
      openRoomStatsPopup,
      selectCurrentRoomIdAndName,
      syncCurrentRoomStats,
    } = this.props
    return (
      <Wrapper>
        <List className={classes.root}>
          {RoomsStatsItems.length
            ? RoomsStatsItems.map((room) => (
              <ListItem
                className={classes.listItem}
                key={room.id}
              >
                <RoomsStatsItem
                  className={classes.deviceItem}
                  name={room.name}
                  billing={room.billing}
                  energy={room.energy}
                  isRoomsStatsPopupOpen={isRoomsStatsPopupOpen}
                  isMobile={isMobile}
                  openRoomStatsPopup={openRoomStatsPopup}
                  id={room.id}
                  selectCurrentRoomIdAndName={selectCurrentRoomIdAndName}
                  syncCurrentRoomStats={syncCurrentRoomStats}
                />
              </ListItem>
            )) : <Typography align="center" variant="h6">{I18n.t('my_energy.no_rooms')}</Typography>}
        </List>
        {isRoomsStatsPopupOpen && (
          <RoomsStatsPopup />
        )}
      </Wrapper>
    )
  }
}
RoomsStatsItemList.propTypes = {
  classes: PropTypes.object.isRequired,
  RoomsStatsItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    billing: PropTypes.number.isRequired,
    energy: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  isRoomsStatsPopupOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  openRoomStatsPopup: PropTypes.func.isRequired,
  selectCurrentRoomIdAndName: PropTypes.func.isRequired,
  syncCurrentRoomStats: PropTypes.func.isRequired,
}

export default withStyles(styles)(RoomsStatsItemList)
