import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  logout: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    zIndex: '999',
  },
  hidden: {
    display: 'none',
  },
})

export default useStyles
