import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/billing_periods/current`

const convertDate = (currentTime) => currentTime.toISOString()

const fetchBillingCurrentStats = (currentTime, accessToken) => Axios.get(ENDPOINT, {
  params: { current_time: convertDate(currentTime) },
  headers: {
    'Access-Token': accessToken,
  },
})

export default {
  fetchBillingCurrentStats,
}
