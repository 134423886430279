import { AxiosError } from 'axios'

const checkUnknownError = (error: any) => {
  let unknownError = ''
  if (error === undefined || error === null
    || error === '' || error === 0 || (error.error === 'named resource not exist')) {
    unknownError = 'Unknown error type. Please contact administrator to fix it.'
  }
  return unknownError
}

const handleErrors = (error: AxiosError): string => {
  let currentError
  if (error) {
    if (error.response) {
      currentError = checkUnknownError(error.response.data.error) || error.response.data.error.message
    } else if (error.message) {
      currentError = error.message
    } else {
      currentError = error
    }
  } else {
    currentError = checkUnknownError(error)
  }
  return currentError
}

export default handleErrors
