import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 60">
      <path fill="currentColor" d="M18.75 52.97a1.17 1.17 0 0 0 0-2.34 9.39 9.39 0 0 1-9.37-9.38c0-2.6 1.03-5.68 2.76-8.22a1.17 1.17 0 0 0-1.94-1.32c-1.98 2.92-3.17 6.48-3.17 9.54 0 6.46 5.26 11.72 11.72 11.72ZM6.21 54.73a1.17 1.17 0 1 0 0-2.35 1.17 1.17 0 0 0 0 2.35Z"/>
      <path fill="currentColor" d="M18.75 0c-.51 0-.95.33-1.1.8C13.5 11.82 9.03 20.9 4.37 27.75 1.6 31.86 0 36.77 0 41.25c0 2.98.72 5.94 2.07 8.58a1.17 1.17 0 1 0 2.09-1.08 16.22 16.22 0 0 1-1.82-7.5c0-4.02 1.45-8.46 3.98-12.18 4.36-6.4 8.53-14.68 12.43-24.62 2.96 7.53 6.1 14.17 9.38 19.77 1 1.7 2.02 3.34 3.05 4.85a22.37 22.37 0 0 1 3.98 12.18A16.44 16.44 0 0 1 10.97 55.7a1.17 1.17 0 0 0-1.1 2.06 18.78 18.78 0 0 0 25.99-8.83 15.24 15.24 0 0 0 19.22-14.71c0-3.87-1.49-8.3-3.98-11.87C47.45 17.13 43.86 9.5 40.97.85L40.96.8a1.17 1.17 0 0 0-2.23 0c-1.95 5.84-5.15 14.04-9.5 20.6A153.87 153.87 0 0 1 19.85.8c-.16-.47-.6-.8-1.11-.8Zm21.1 4.74c2.13 5.89 5.26 13.13 9.33 18.95 2.22 3.19 3.55 7.12 3.55 10.53a12.9 12.9 0 0 1-16.04 12.5c.54-1.77.81-3.6.81-5.47 0-4.48-1.6-9.4-4.38-13.5a80.4 80.4 0 0 1-2.6-4.08c4.06-5.82 7.19-13.05 9.32-18.93Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'HumidityIcon', '0 0 80 80', {fontSize: 35})

export default icon
