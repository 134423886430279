import convertBatteryBundle from '@app/helpers/myStatus/expandMyBatteryBundleInformation'
import * as type from '@app/constants/myStatus'

type State = {
  isMyStatusDevicePopupOpen: boolean,
  currentDeviceId: string,
  currentDeviceName: string,
  zWaveInfo: Array<{
    status: number,
    type: string,
    update: string,
  }>,
}

const initialState = {
  isMyStatusDevicePopupOpen: false,
  currentDeviceId: '',
  currentDeviceName: '',
  zWaveInfo: [],
}

const myStatusPopup = (state: State = initialState, action: Object): State => {
  switch (action.type) {
  case type.MY_STATUS_POPUP_OPEN: return {
    ...state, isMyStatusDevicePopupOpen: true, currentDeviceId: action.deviceId, currentDeviceName: action.deviceName,
  }
  case type.MY_STATUS_POPUP_CLOSED: return {
    ...state, isMyStatusDevicePopupOpen: false, currentDeviceId: '', currentDeviceName: '', zWaveInfo: [],
  }
  case type.UPDATE_MY_STATUS_POPUP_DATA: return {
    ...state,
    zWaveInfo: [
      convertBatteryBundle(action.data.zwave_battery),
      ...action.data.zwave_sensors.sort((a, b) => a.title.localeCompare(b.title)),
    ],
  }
  default: return state
  }
}

export default myStatusPopup
