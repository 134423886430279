import React from 'react'
import classNames from 'classnames'
import {
  Button, Paper, Slider, Typography,
} from '@mui/material'
import {
  Autocomplete, Circle, GoogleMap, LoadScript, Marker,
} from '@react-google-maps/api'
import Geocode from 'react-geocode'
import calculateBounds from '@app/helpers/scenes/calculateBoundsForCircle'
import {
  apiKey, circleSizeBounds, libraries, options,
} from '@app/lib/Scenes/geofences'
import { SELECT_GEOFENCES_CONDITION, SELECT_GEOFENCES_NAME } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectGeofencesLocation extends React.PureComponent {
  constructor(props) {
    super(props)
    const { location: { state } } = props
    const linkState = state
    this.state = {
      zoom: 17,
      size: 100,
      coordinates: {
        lat: linkState ? linkState.latitude : -33.8219067,
        lng: linkState ? linkState.longitude : 150.9541388,
      },
      address: 'Choose a location...',
      dragged: false,
    }
    this.map = null
    this.autocomplete = null
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        Geocode.setApiKey(apiKey)
        Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then((response) => {
          this.setState({
            coordinates: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            },
            address: response.results[0].formatted_address,
          })
        })
      })
    }
  }

  onLoadAutoComplete = (autocomplete) => {
    this.autocomplete = autocomplete
  }

  onLoadMap = (map) => {
    this.map = map
  }

  onPlaceChanged = () => {
    const { coordinates, dragged } = this.state
    Geocode.setApiKey(apiKey)
    let autoLat
    let autoLng
    let finalAddress
    if (this.autocomplete !== null && this.autocomplete.getPlace()) {
      autoLat = this.autocomplete.getPlace().geometry.location.lat()
      autoLng = this.autocomplete.getPlace().geometry.location.lng()
    }
    Geocode.fromLatLng((!dragged && autoLat) || coordinates.lat, (!dragged && autoLng) || coordinates.lng).then((response) => {
      finalAddress = response.results[0].formatted_address
      this.setState({
        coordinates: this.autocomplete.getPlace() && !dragged
          ? {
            lat: autoLat,
            lng: autoLng,
          }
          : {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
          },
        address: finalAddress,
      })
    })
  }

  onSizeChanged = (_, size) => {
    this.setState({ size })

    const newZoom = calculateBounds(size)
    this.setState({ zoom: newZoom })
  }

  onChangeCenter = () => {
    if (this.map !== null) {
      const { coordinates, dragged } = this.state
      Geocode.setApiKey(apiKey)
      let autoLat
      let autoLng
      Geocode.fromLatLng((!dragged && autoLat) || coordinates.lat, (!dragged && autoLng) || coordinates.lng).then((response) => {
        this.setState({
          coordinates: {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
          },
          address: response.results[0].formatted_address,
          dragged: true,
        })
      })
    }
  }

  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_GEOFENCES_CONDITION)
  }

  onContinueClick = () => {
    const { navigate, saveGeofenceInfo } = this.props
    const {
      coordinates, size, address,
    } = this.state
    saveGeofenceInfo(address, coordinates.lat, coordinates.lng, size)
    navigate(SELECT_GEOFENCES_NAME)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const {
      coordinates, size, zoom, address,
    } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.create_geofence')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.mapContainer}>
            <LoadScript
              id="script-loader"
              googleMapsApiKey={apiKey}
              libraries={libraries}
            >
              <GoogleMap
                id="searchbox"
                mapContainerStyle={{
                  height: isMobile ? '400px' : '580px',
                  width: '100%',
                }}
                zoom={zoom}
                center={coordinates}
                onLoad={this.onLoadMap}
                onDragEnd={this.onChangeCenter}
                options={{ streetViewControl: false, disableDefaultUI: true }}
              >
                <Autocomplete
                  onLoad={this.onLoadAutoComplete}
                  onPlaceChanged={this.onPlaceChanged}
                >
                  <input
                    type="text"
                    placeholder={address}
                    className={classNames(classes.autocompleteInput, isMobile && classes.autocompleteInputMobile)}
                    onChange={() => this.setState({ dragged: false })}
                  />
                </Autocomplete>
                <Circle
                  radius={size}
                  center={coordinates}
                  options={options}
                />
                <Marker position={coordinates} />
                <Wrapper className={classNames(classes.sliderContainer, isMobile && classes.sliderContainerMobile)}>
                  <Typography className={classes.sliderValue}>{`${size} m`}</Typography>
                  <Slider
                    classes={{
                      root: classes.slider,
                      thumb: classes.thumb,
                    }}
                    className={classes.slider}
                    aria-label="size"
                    defaultValue={size}
                    min={circleSizeBounds.min}
                    max={circleSizeBounds.max}
                    onChange={this.onSizeChanged}
                  />
                </Wrapper>
              </GoogleMap>
            </LoadScript>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onContinueClick}
          >
            {I18n.t('buttons.continue')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectGeofencesLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveGeofenceInfo: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
}

SelectGeofencesLocation.defaultProps = {

  location: {
    state: {
      latitude: null,
      longitude: null,
    },
  },
}

SelectGeofencesLocation.displayName = 'SelectGeofencesLocation'

export default SelectGeofencesLocation
