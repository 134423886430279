export enum TwrValues {
  Opened = 'Opened',
  Closed = 'Closed',
  Sealed = 'Sealed',
  Unsealed = 'Unsealed',
  Detected = 'Detected',
  NotDetected = 'Not detected',
  Pressed = 'Pressed',
  NotPressed = 'Not pressed',
  Broken = 'Broken',
  NotBroken = 'Not broken',
  Triggered = 'Triggered',
  NotTriggered = 'Not triggered',
}

export const twrValueType = (type: string, value: boolean) => {
  const twrSensorValueMap = {
    Door: value ? TwrValues.Unsealed : TwrValues.Sealed,
    Motion: value ? TwrValues.Unsealed : TwrValues.Sealed,
    Siren: value ? TwrValues.NotDetected : TwrValues.Detected,
    Keyfob: value ? TwrValues.NotPressed : TwrValues.Pressed,
    'Glass break': value ? TwrValues.NotBroken : TwrValues.Broken,
    REED_SWITCH: value ? TwrValues.Closed : TwrValues.Opened,
    PIR: value ? TwrValues.Unsealed : TwrValues.Sealed,
    GLASS_BREAK: value ? TwrValues.NotBroken : TwrValues.Broken,
    SMOKE_ALARM: value ? TwrValues.NotTriggered : TwrValues.Triggered,
    ENVIRONMENTAL_SENSOR: value ? TwrValues.NotTriggered : TwrValues.Triggered,
    ZONE: value ? TwrValues.Opened : TwrValues.Closed,
    default: 'Unknown',
  }

  return twrSensorValueMap[type] || twrSensorValueMap.default
}
