import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const patchUser = (accessToken, sphereId, data) => Axios.patch(`${API_URL}/api/sphere_users`, data, {
  headers: { 'Access-Token': accessToken },
})

const patchUnitUser = (accessToken, sphereId, data) => Axios.patch(`${API_URL}/api/sphere_users/${sphereId}/unit_user`, data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  patchUser,
  patchUnitUser,
}
