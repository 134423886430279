import * as type from '@app/constants/myDevices/zwaveDevices'
import {
  IZwaveOrTwrDevice, IDeviceStatusData, IZwavePatchData, IZwaveDetails, IDeviceParameterData, IParseConfigParameterData,
} from '@app/@types/myDevices'

export const addZwaveDevice = () => ({
  type: type.ADD_NEW_ZWAVE_DEVICE,
} as const)

export const startZwaveReinterview = (id: number) => ({
  type: type.START_ZWAVE_REINTERVIEW,
  id,
} as const)

export const breakFromReinterview = () => ({
  type: type.BREAK_FROM_REINTERVIEW,
} as const)

export const setNewZwaveDevice = (newZwaveDevice: IZwaveOrTwrDevice) => ({
  type: type.SET_NEW_ZWAVE_DEVICE,
  newZwaveDevice,
} as const)

export const patchZwaveDeviceStatus = (data: IDeviceStatusData) => ({
  type: type.PATCH_ZWAVE_DEVICE_STATUS,
  data,
} as const)

export const patchZwaveDevice = (data: Partial<IZwavePatchData>, id?: number) => ({
  type: type.PATCH_ZWAVE_DEVICE,
  data,
  id,
} as const)

export const patchNewZwaveDevice = (data: Partial<IZwavePatchData>) => ({
  type: type.PATCH_NEW_ZWAVE_DEVICE,
  data,
} as const)

export const cancelZwaveDevice = () => ({
  type: type.CANCEL_ZWAVE_DEVICE,
} as const)

export const fetchZwaveDeviceDetails = (id: number) => ({
  type: type.FETCH_ZWAVE_DEVICE_DETAILS,
  id,
} as const)

export const setZwaveDeviceDetails = (details: Array<IZwaveDetails>) => ({
  type: type.SET_ZWAVE_DEVICE_DETAILS,
  details,
} as const)

export const resetZwaveDeviceDetails = () => ({
  type: type.RESET_ZWAVE_DEVICE_DETAILS,
} as const)

export const setZwaveDeviceDetailsError = (error: string) => ({
  type: type.SET_ZWAVE_DEVICE_DETAILS_ERROR,
  error,
} as const)

export const resetZwaveDeviceDetailsError = () => ({
  type: type.RESET_ZWAVE_DEVICE_DETAILS_ERROR,
} as const)

export const setConfigurationPopupData = (data: { number: number, value: number, size: number }) => ({
  type: type.SET_CONFIGURATION_POPUP_DATA,
  data,
} as const)

export const setConfigurationPopupStatus = (status: string) => ({
  type: type.SET_CONFIGURATION_POPUP_STATUS,
  status,
} as const)

export const resetConfigurationPopupStatus = () => ({
  type: type.RESET_CONFIGURATION_POPUP_STATUS,
} as const)

export const getZwaveDeviceParameter = (id: number, parameterNumber: number) => ({
  type: type.GET_ZWAVE_DEVICE_PARAM,
  id,
  parameterNumber,
} as const)

export const parseZwaveDeviceParameter = (id: number, data: IParseConfigParameterData) => ({
  type: type.PARSE_ZWAVE_DEVICE_PARAM,
  id,
  data,
} as const)

export const setZwaveDeviceParameter = (id: number, param: IDeviceParameterData) => ({
  type: type.SET_ZWAVE_DEVICE_PARAM,
  id,
  param,
} as const)

export const getZwaveDeviceParameters = (id: number, parametersNumbers: Array<number>) => ({
  type: type.GET_ZWAVE_DEVICE_PARAMS,
  id,
  parametersNumbers,
} as const)

export const setIsSleepingDevicePopupOpen = (status: boolean) => ({
  type: type.SET_IS_SLEEPING_DEVICE_POPUP_OPEN,
  status,
} as const)
