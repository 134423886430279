import { IScene } from '@app/@types/scenes/scenes'

const getScenesListReorder = (list: Array<IScene>, startIndex: number, endIndex: number): Array<IScene> => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default getScenesListReorder
