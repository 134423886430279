import * as type from '@app/constants/users/avatar'

export const openPopup = () => ({
  type: type.OPEN_AVATAR_POPUP,
})
export const closePopup = () => ({
  type: type.CLOSE_AVATAR_POPUP,
})

export const set = () => ({
  type: type.SET_AVATAR,
})

export const chooseAvatarFile = (data) => ({
  type: type.CHOOSE_AVATAR_FILE,
  data,
})
export const cleanAvatarFile = () => ({
  type: type.CLEAN_AVATAR_FILE,
})
