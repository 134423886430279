import { ITimerDuration } from '@app/@types/timers/timersTypes'

const getTimerDurationDescription = (type: string, duration: ITimerDuration): string => {
  if (type === 'no_timer' || (Object.entries(duration).filter((timeType) => (timeType[1] !== 0)).length === 0)) {
    return 'N/A'
  }
  return Object.entries(duration).filter((timeType) => (timeType[1] !== 0)).map((t) => (`${t[1]}${t[0].charAt(0)}`)).join(' ')
}

export default getTimerDurationDescription
