import { ColorTypes } from '@app/lib/Scenes/colorActions'

const convertColorActionDataToBackend = (color, colorType) => {
  let result
  if (color === 'none') {
    result = {}
  }
  if (colorType === ColorTypes.swatch || colorType === ColorTypes.rgb) {
    const colorsOnly = color.substring(color.indexOf('(') + 1, color.lastIndexOf(')')).split(/,\s*/)
    result = {
      red: Number(colorsOnly[0]),
      green: Number(colorsOnly[1]),
      blue: Number(colorsOnly[2]),
    }
  } if (colorType === ColorTypes.whiteWarm) {
    result = {
      white_warm: color,
    }
  } if (colorType === ColorTypes.whiteCold) {
    result = {
      white_cold: color,
    }
  }
  return result
}
export default convertColorActionDataToBackend
