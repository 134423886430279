import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchTwrDevicesHealth = (access_token: string) => Axios.get(`${API_URL}/api/devices/twr_devices/health`, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchTwrDevicesHealth,
}
