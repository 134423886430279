import * as type from '@app/constants/syncCounters/countersStatus'

export const sync = () => ({
  type: type.SYNC_COUNTERS_STATUS,
})

export const update = (countersStatus) => ({
  type: type.UPDATE_COUNTERS_STATUS,
  countersStatus,
})
