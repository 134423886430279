import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.backgrounds.componentBackground,
    minHeight: 'calc(100vh - 64px)',
  },
  webview: {
    backgroundColor: theme.webView.background,
  },
  remindersList: {
    padding: '40px 3% ',
    marginBottom: 20,
  },
  emptyReminders: {
    padding: 30,
    width: '100%',
    minHeight: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyText: {
    marginTop: 30,
  },
  addButton: {
    position: 'fixed',
    bottom: 30,
    right: 'calc(50% - 28px)',
  },
}))

export default useStyles
