import React from 'react'
import { Paper, Typography } from '@mui/material'
import filterRoomGroupForScreen from '@app/helpers/scenes/filterRoomGroupForScreen'
import { SELECT_CONDITION_DEVICE_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import GroupsList from './GroupsList/GroupsList'

class SelectGroupCondition extends React.Component {
  onBackClick= () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_TYPE)
  }

  render() {
    const {
      classes,
      isMobile,
      groupsScenes,
      saveConditionDescription,
      saveConditionGroupNumber,
      navigate,
    } = this.props
    const filteredGroups = filterRoomGroupForScreen(groupsScenes)
    return (
      <Paper className={classes.wrapper}>
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>Select group</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <GroupsList
            navigate={navigate}
            groupsScenes={filteredGroups}
            saveConditionDescription={saveConditionDescription}
            saveConditionGroupNumber={saveConditionGroupNumber}
          />
        </Wrapper>
      </Paper>
    )
  }
}

SelectGroupCondition.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionGroupNumber: PropTypes.func.isRequired,
  groupsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

SelectGroupCondition.defaultProps = {

}

SelectGroupCondition.displayName = 'SelectGroupCondition'

export default SelectGroupCondition
