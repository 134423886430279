import * as type from '@app/constants/wizard/wizard'

export const authByPassword = () => ({
  type: type.AUTH_BY_PASSWORD,
})

export const authStarted = () => ({
  type: type.AUTH_STARTED,
})

export const authFinished = () => ({
  type: type.AUTH_FINISHED,
})

export const showSignInError = (loginError) => ({
  type: type.SHOW_SIGN_IN_ERROR,
  loginError,
})

export const hideSignInError = () => ({
  type: type.HIDE_SIGN_IN_ERROR,
})

export const showAccountLockedError = () => ({
  type: type.SHOW_ACCOUNT_LOCKED_ERROR,
})

export const hideAccountLockedError = () => ({
  type: type.HIDE_ACCOUNT_LOCKED_ERROR,
})
