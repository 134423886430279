import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.98438 10.2067V15.9844H2.01562V2.01562H5V10H7V7.21619L6.05872 6.27492L7 5.33364V2.01562H9.98438V5.7596L10.9476 6.72281L12 5.67039V1.5C12 1.0625 11.8594 0.703125 11.5781 0.421875C11.2969 0.140625 10.9375 0 10.5 0H1.5C1.0625 0 0.703125 0.140625 0.421875 0.421875C0.140625 0.703125 0 1.0625 0 1.5V15.5C0 15.9375 0.140625 16.2969 0.421875 16.5781C0.703125 16.8594 1.0625 17 1.5 17H2V18H1C0.447715 18 0 18.4477 0 19C0 19.5523 0.447715 20 1 20H11C11.5523 20 12 19.5523 12 19C12 18.4477 11.5523 18 11 18H10V17H10.5C10.9375 17 11.2969 16.8594 11.5781 16.5781C11.8594 16.2969 12 15.9375 12 15.5V9.72213L10.7499 10.9722L9.98438 10.2067ZM8 18H4V17H8V18ZM18.04 1L10.808 8.14336L7.928 5.31119L7 6.25524L10.808 10L19 1.94406L18.04 1Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'RestoredAfterTamperIcon')

export default icon
