import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M76.0731 25.8938V8M82.0935 26.0515L92.2722 12.099M59.3254 12.099L69.5041 26.0515M89.4597 27.7068L103.121 19.7453M47.9414 19.7453L61.6023 27.7068" stroke="#ED1F24" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M48.5727 128.2H58.2789V139.699H48.5727V128.2ZM51.8956 132.319V135.58H54.9559V132.319H51.8956Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M91.0609 128.2H100.767V139.699H91.0609V128.2ZM94.3839 132.319V135.58H97.4441V132.319H94.3839Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44 39.3784C44 37.3031 45.6653 35.5898 47.9103 35.5898H101.838C104.083 35.5898 105.748 37.3031 105.748 39.3784V128.184C105.748 130.26 104.083 131.973 101.838 131.973H47.9103C45.6653 131.973 44 130.26 44 128.184V39.3784ZM48.3009 39.7086V127.854H101.447V39.7086H48.3009Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M67.6326 35.5898H81.9899V100.888C81.9899 104.658 78.9764 107.888 74.8112 107.888C70.8063 107.888 67.6326 104.692 67.6326 100.888V35.5898ZM71.9335 39.7086V100.888C71.9335 102.518 73.2851 103.769 74.8112 103.769C76.4284 103.769 77.689 102.552 77.689 100.888V39.7086H71.9335Z" fill="currentColor"/>
            <path d="M45.0191 136.306H105.232V140.999H45.0191V136.306Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M48.5727 128.2H58.2789V139.699H48.5727V128.2ZM51.8956 132.319V135.58H54.9559V132.319H51.8956Z" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M91.0609 128.2H100.767V139.699H91.0609V128.2ZM94.3839 132.319V135.58H97.4441V132.319H94.3839Z" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44 39.3784C44 37.3031 45.6653 35.5898 47.9103 35.5898H101.838C104.083 35.5898 105.748 37.3031 105.748 39.3784V128.184C105.748 130.26 104.083 131.973 101.838 131.973H47.9103C45.6653 131.973 44 130.26 44 128.184V39.3784ZM48.3009 39.7086V127.854H101.447V39.7086H48.3009Z" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M67.6326 35.5898H81.9899V100.888C81.9899 104.658 78.9764 107.888 74.8112 107.888C70.8063 107.888 67.6326 104.692 67.6326 100.888V35.5898ZM71.9335 39.7086V100.888C71.9335 102.518 73.2851 103.769 74.8112 103.769C76.4284 103.769 77.689 102.552 77.689 100.888V39.7086H71.9335Z" stroke="currentColor"/>
            <path d="M45.0191 136.306H105.232V140.999H45.0191V136.306Z" stroke="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'MezzoLightIcon')

export default icon
