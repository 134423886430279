import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/energy/rooms/${id}`

const convertDate = (current_time) => current_time.toISOString()

const fetchRoomStats = (access_token, id, current_time) => Axios.get(ENDPOINT(id), {
  params: { current_time: convertDate(current_time) },
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchRoomStats,
}
