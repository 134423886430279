export const sceneAction = {
  title: 'action',
  position: 'bottom',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const sceneActionSelected = {
  title: 'action',
  position: 'bottom',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const sceneStateSelected = {
  title: 'state',
  position: 'bottom',
  disabled: false,
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectColorActionSelectedValue = {
  title: 'value',
  position: 'top',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectColorActionSelectedDelay = {
  title: 'delay',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectColorActionSelectedDuration = {
  title: 'duration',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectBinarySwitchActionSelectedValue = {
  title: 'value',
  description: 'none',
  position: 'top',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectBinarySwitchActionSelectedDelay = {
  title: 'delay',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: true,
  isButtonDisabled: true,
}

export const selectBinarySwitchActionSelectedDuration = {
  title: 'duration',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const adjustDurationValue = {
  title: 'value_after_duration',
  description: 'none',
  position: 'top',
  buttonName: 'choose',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const multilevelSwitchToggleAdjustDurationValue = {
  title: 'value_after_duration',
  description: 'toggle',
  position: 'top',
  buttonName: 'choose',
  isCardDisabled: false,
  isButtonDisabled: true,
}

export const selectVirtualSwitchActionSelectedValue = {
  title: 'value',
  description: 'none',
  position: 'top',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectVirtualSwitchActionSelectedDelay = {
  title: 'delay',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: true,
  isButtonDisabled: true,
}

export const selectVirtualSwitchActionSelectedDuration = {
  title: 'duration',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectMultichannelActionSelectedDuration = {
  title: 'duration',
  description: 'none',
  position: 'top',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectMultichannelActionValue = {
  title: 'value',
  description: 'none',
  position: 'top',
  buttonName: 'choose',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const selectMultichannelActionSubDevice = {
  title: 'state',
  description: 'none',
  position: 'top',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}
