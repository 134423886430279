import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
      <svg viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.76562 4.3125L4.35938 5.67187L2.57813 3.89063L3.98438 2.48438L5.76562 4.3125ZM3 11.9531H0V9.93749H3L3 11.9531ZM5.08275 15H16.9218C16.974 14.6759 17 14.3425 17 14C17 12.3437 16.4219 10.9219 15.2656 9.73437C14.0781 8.57812 12.6563 8 11 8C9.34375 8 7.9375 8.57812 6.78125 9.73437C5.59375 10.9219 5 12.3437 5 14C5 14.3425 5.02758 14.6759 5.08275 15ZM19.0312 11.9531V9.93748H22.0312V11.9531H19.0312ZM12 0V2.95312H10.0312V0H12ZM19.4531 3.89062L17.6719 5.71875L16.2656 4.3125L18.0469 2.48437L19.4531 3.89062ZM19 17V15H3V17H19Z" fill="currentColor"/>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'AlarmIcon')

export default icon
