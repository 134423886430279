import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/v4/sphere_users/by_password`

const deleteAccount = (username, password) => Axios.delete(ENDPOINT, {
  data: {
    username,
    password,
  },
})

export default {
  deleteAccount,
}
