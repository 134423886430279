import { CHECK_MOBILE, CHECK_TABLET } from '@app/constants/mobileDetector'
import { TMobileDetectorAction } from '@app/actions/mobileDetector'

export interface IMobileDetectorState {
  isMobile: boolean,
  isTablet: boolean,
}

const initialState = {
  isMobile: false,
  isTablet: false,
}

const mobileDetector = (state: IMobileDetectorState = initialState, action: TMobileDetectorAction): IMobileDetectorState => {
  switch (action.type) {
  case CHECK_MOBILE:
    return { ...state, isMobile: action.isMobile }
  case CHECK_TABLET:
    return { ...state, isTablet: action.isTablet }
  default:
    return state
  }
}

export default mobileDetector
