import React, {
  useEffect, Dispatch, SetStateAction,
} from 'react'
import {
  Radio, Typography, Slider,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { MIN_BRIGHTNESS } from '@app/lib/MyDevices/deviceColours'
import { ColorBrightness, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './Brightness.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  whiteWarmValue: number,
  setWhiteWarmValue: Dispatch<SetStateAction<number>>,
  whiteColdValue: number,
  setWhiteColdValue: Dispatch<SetStateAction<number>>,
  setPickedColor: Dispatch<SetStateAction<string>>,
  pickedBrightness: string,
  setPickedBrightness: Dispatch<SetStateAction<string>>,
}

const Brightness: React.FC<Props> = (props) => {
  const {
    currentDevice,
    whiteWarmValue,
    setWhiteWarmValue,
    whiteColdValue,
    setWhiteColdValue,
    setPickedColor,
    pickedBrightness,
    setPickedBrightness,
  } = props
  const classes = useStyles()
  const { red, green, blue } = currentDevice.info?.color_state?.value!

  useEffect(() => {
    if (whiteWarmValue >= MIN_BRIGHTNESS) {
      setPickedBrightness(ColorBrightness.whiteWarm)
    } else if (whiteColdValue >= MIN_BRIGHTNESS) {
      setPickedBrightness(ColorBrightness.whiteCold)
    } else {
      setPickedBrightness(ColorBrightness.whiteWarm)
      setWhiteWarmValue(MIN_BRIGHTNESS)
      setPickedColor(`rgb(${red}, ${green}, ${blue})`)
    }
  }, [])

  const handleChange = (brightness: string) => {
    setPickedBrightness(brightness)
    if (brightness === ColorBrightness.whiteWarm) {
      setWhiteWarmValue((typeof whiteWarmValue === 'number' && whiteWarmValue !== 0) ? whiteWarmValue : MIN_BRIGHTNESS)
      setWhiteColdValue(0)
    } else if (brightness === ColorBrightness.whiteCold) {
      setWhiteColdValue((typeof whiteColdValue === 'number' && whiteColdValue !== 0) ? whiteColdValue : MIN_BRIGHTNESS)
      setWhiteWarmValue(0)
    }
  }

  const handleWhiteWarmChange = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    setWhiteWarmValue(newValue as number)
    setWhiteColdValue(0)
  }

  const handleWhiteColdChange = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    setWhiteColdValue(newValue as number)
    setWhiteWarmValue(0)
  }

  const labelText = (value: number) => `${value} %`

  return (
    <Wrapper className={classes.brightnessWrapper}>
      <Wrapper className={classes.radioWrapper}>
        <Radio
          className={classes.radioButton}
          checked={pickedBrightness === ColorBrightness.whiteWarm}
          onChange={() => {
            handleChange(ColorBrightness.whiteWarm)
          }}
          value={ColorBrightness.whiteWarm}
        />
        <Typography className={classNames(classes.title, pickedBrightness !== ColorBrightness.whiteWarm && classes.disabled)}>
          {I18n.t('zwave_colour.white_warm')}
        </Typography>
      </Wrapper>
      <Wrapper className={classNames(classes.sliderWrapper, pickedBrightness !== ColorBrightness.whiteWarm && classes.disabled)}>
        <Slider
          className={classes.slider}
          value={whiteWarmValue}
          onChange={handleWhiteWarmChange}
          valueLabelDisplay="on"
          valueLabelFormat={labelText}
          min={MIN_BRIGHTNESS}
        />
      </Wrapper>
      <Wrapper className={classes.radioWrapper}>
        <Radio
          className={classes.radioButton}
          checked={pickedBrightness === ColorBrightness.whiteCold}
          onChange={() => {
            handleChange(ColorBrightness.whiteCold)
          }}
          value={ColorBrightness.whiteCold}
        />
        <Typography className={classNames(classes.title, pickedBrightness !== ColorBrightness.whiteCold && classes.disabled)}>
          {I18n.t('zwave_colour.white_cold')}
        </Typography>
      </Wrapper>
      <Wrapper className={classNames(classes.sliderWrapper, pickedBrightness !== ColorBrightness.whiteCold && classes.disabled)}>
        <Slider
          className={classes.slider}
          value={whiteColdValue}
          onChange={handleWhiteColdChange}
          valueLabelDisplay="on"
          valueLabelFormat={labelText}
          min={MIN_BRIGHTNESS}
        />
      </Wrapper>
    </Wrapper>
  )
}

export default Brightness
