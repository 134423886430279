import React from 'react'
import {
  Button, Icon, Paper, Typography,
} from '@mui/material'
import classNames from 'classnames'

import Toolbar from '@mui/material/Toolbar'
import {
  BillingPeriodIcon, DeviceManagerIcon, EcoModeIcon, EnergyIcon, RoomsStatsIcon,
} from 'icons'

import Energy from '@app/containers/MyEnergy/Tabs/EnergyTab'
import BillingPeriod from '@app/containers/MyEnergy/Tabs/BillingPeriod/BillingPeriod'
import DeviceManager from '@app/containers/MyEnergy/Tabs/DeviceManager'
import EcoMode from '@app/containers/MyEnergy/Tabs/EcoMode'
import RoomsStats from '@app/containers/MyEnergy/Tabs/RoomStats/RoomsStats'
import Wrapper from '../Common/Wrapper/Wrapper'
import PropTypes from '../PropTypes'

class MyEnergy extends React.Component {
  selectTab = (tab) => {
    switch (tab) {
    case 'energy':
      return (<Energy />)
    case 'device_manager':
      return (<DeviceManager />)
    case 'eco_mode':
      return (<EcoMode />)
    case 'rooms_stats':
      return (<RoomsStats />)
    case 'billing_period':
      return (<BillingPeriod />)
    default:
      return (<Energy />)
    }
  }

  createButton = (icon, text) => {
    const {
      classes,
      chooseTab,
      currentTab,
    } = this.props
    return (
      <Button
        onClick={() => chooseTab(text)}
        variant="outlined"
        classes={{
          root: classes.root,
        }}
      >
        <Wrapper className={classes.buttonInnerWrapper}>
          <Icon className={classNames(classes.icon, currentTab === text && classes.active)}>{icon}</Icon>
          <Typography className={classNames(
            classes.buttonText,
            currentTab === text && classes.active,
          )}
          >
            {I18n.t(`buttons.${text}`)}
          </Typography>
        </Wrapper>
      </Button>
    )
  }

  renderMain = () => {
    const {
      classes,
      currentTab,
    } = this.props
    return (
      <Wrapper className={classes.main}>
        {this.selectTab(currentTab)}
      </Wrapper>
    )
  }

  renderBottomPanel=() => {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Toolbar
        className={classNames(
          classes.buttonWrapper,
          !isMobile && classes.buttonWrapperDesktop,
        )}
        color="primary"
      >
        {this.createButton(<DeviceManagerIcon />, 'device_manager')}
        {this.createButton(<EcoModeIcon />, 'eco_mode')}
        {this.createButton(<EnergyIcon />, 'energy')}
        {this.createButton(<RoomsStatsIcon />, 'rooms_stats')}
        {this.createButton(<BillingPeriodIcon />, 'billing_period')}
      </Toolbar>
    )
  }

  // TODO: i18n
  render() {
    const {
      classes,
    } = this.props
    return (
      <Paper className={classes.paper}>
        {this.renderMain()}
        {this.renderBottomPanel()}
      </Paper>
    )
  }
}

MyEnergy.propTypes = {
  classes: PropTypes.object.isRequired,
  chooseTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

MyEnergy.displayName = 'MyEnergy'

export default MyEnergy
