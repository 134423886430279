import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import { ChevronLeft } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { twrModes } from '@app/lib/MyDevices/twrModes'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './AdditionalModes.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  isMobile: boolean,
}

const AdditionalModes: React.FC<Props> = (props) => {
  const { currentDevice, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [option, setOption] = useState('')

  useEffect(() => {
    if (currentDevice.option) {
      setOption(currentDevice.option)
    }
  }, [])

  const currentTwrMode = Object.keys(twrModes).find((key) => key === option)

  const goTwrAdditionalModes = () => navigate(`${location.pathname}/additional_modes`)

  return (
    <Wrapper
      className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
      onClick={goTwrAdditionalModes}
    >
      <Typography
        className={classNames(classes.cardTitle, classes.marginBottomHelper)}
      >
        {I18n.t('my_devices.additional_modes')}
      </Typography>
      <Wrapper className={classes.subTitleContainer}>
        <Typography className={classes.deviceName}>{currentTwrMode ? I18n.t(`my_devices.${currentTwrMode}`) : ''}</Typography>
      </Wrapper>
      <ChevronLeft className={classes.arrowRightIcon} />
    </Wrapper>
  )
}
export default AdditionalModes
