import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M48 134C48 124.611 55.6112 117 65 117H84C93.3889 117 101 124.611 101 134V140C101 141.105 100.105 142 99 142H50C48.8954 142 48 141.105 48 140V134ZM65 122H84C90.6274 122 96 127.373 96 134V137H53V134C53 127.373 58.3726 122 65 122Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M68.0052 75C59.7131 75 53.5404 82.6587 55.3019 90.7616L61.8755 121H87.3755L93.6477 90.6293C95.3137 82.5627 89.1532 75 80.9164 75H68.0052ZM68.0052 80H80.9164C85.9852 80 89.7762 84.654 88.7511 89.618L83.3026 116H65.9053L60.1878 89.6994C59.1038 84.713 62.9023 80 68.0052 80Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M66 64V80H83V64H66ZM71 69V75H78V69H71Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M36.3229 7C34.7963 7 33.5132 8.14645 33.3419 9.66343L27.0193 65.6634C26.8185 67.4423 28.2102 69 30.0004 69H119.785C121.575 69 122.967 67.4423 122.766 65.6634L116.443 9.66343C116.272 8.14645 114.989 7 113.462 7H36.3229ZM32.2389 64L38.1098 12H111.675L117.546 64H32.2389Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Lamp')

export default icon
