import PropTypes from 'prop-types'
import { withStyles as muiWithStyles } from '@mui/styles'
import MezzoErrorsStyles from './MezzoErrors/MezzoErrors.style'
import MenuItemStyles from './Menu/MenuItem.style'
import DrawerStyles from './Drawer/Drawer.style'
import ToolbarStyles from './Toolbar/Toolbar.style'
import LogoStyles from './Toolbar/Logo/Logo.style'
import SecretsStyles from './Secrets/Secrets.style'
import HomeStyles from './Home/Home.style'
import LoadingCardStyles from './LoadingCard/LoadingCard.style'
import MemberListStyles from './Users/MemberList.style'
import RequestListStyles from './Users/RequestList.style'
import UserEditStyles from './Users/UserEdit/UserEdit.style'
import UserRequestDetailsStyles from './Users/UserRequestDetails/UserRequestDetails.style'
import MemberDetailsStyles from './Users/MemberDetails/MemberDetails.style'
import AvatarPopupStyles from './Users/UserEdit/AvatarPopup/AvatarPopup.style'
import SecretCodePopupStyles from './Users/UserEdit/SecretCodePopup/SecretCodePopup.style'
import PinCodePopupStyles from './Users/UserEdit/PinCodePopup/PinCodePopup.style'
import EventsStyles from './Events/Events.style'
import FiltersStyles from './Events/Filters/Filters.style'
import SnackbarStyles from './Common/Snackbar/Snackbar.style'
import MyEnergyStyles from './MyEnergy/MyEnergy.style'
import EnergyTabStyles from './MyEnergy/Tabs/EnergyTab/EnergyTab.style'
import ChartsStyles from './Charts/Charts.style'
import DeviceManagerStyles from './MyEnergy/Tabs/DeviceManager/DeviceManager.style'
import EcoModeStyles from './MyEnergy/Tabs/EcoMode/EcoMode.style'
import RoomsStatsStyles from './MyEnergy/Tabs/RoomsStats/RoomsStats.style'
import RoomsStatsPopupStyles from './MyEnergy/Tabs/RoomsStats/RoomsStatsPopup/RoomsStatsPopup.style'
import BillingPeriodStyles from './MyEnergy/Tabs/BillingPeriod/BillingPeriod.style'
import SetUpBillingPopupStyles from './MyEnergy/Tabs/BillingPeriod/SetUpBillingPopup/SetUpBillingPopup.style'
import BillingStatsPopupStyles from './MyEnergy/Tabs/BillingPeriod/BillingStatsPopup/BillingStatsPopup.style'
import SignInStyles from './Wizard/SignIn/SignIn.style'
import SignUpStyles from './Wizard/SignUp/SignUp.style'
import MyStatusDevicePopupStyles from './MyStatus/MyStatusDevicePopup/MyStatusDevicePopup.style'
import MyStatusDeviceCardStyles from './MyStatus/MyStatusDeviceCard/MyStatusDeviceCard.style'
import MyStatusDeviceCardListStyles from './MyStatus/MyStatusDevices.style'
import SelectColorActionHueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/SelectColorActionScreens/SelectColorActionHue/SelectColorActionHue.style'
import SelectColorActionHueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/SelectColorActionScreens/SelectColorActionHue/SelectColorActionHue.style'
import SelectActionColorTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/SelectColorActionScreens/SelectActionColorType/SelectActionColorType.style'
import SelectActionColorTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/SelectColorActionScreens/SelectActionColorType/SelectActionColorType.style'
import SelectColorActionStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/SelectColorActionScreens/SelectColorActionStateSelected/SelectColorActionStateSelected.style'
import SelectColorActionStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/SelectColorActionScreens/SelectColorActionStateSelected/SelectColorActionStateSelected.style'
import SelectMultilevelSwitchActionTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MultilevelSwitchActions/SelectMultilevelSwitchActionType/SelectMultilevelSwitchActionType.style'
import SelectMultilevelSwitchActionTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MultilevelSwitchActions/SelectMultilevelSwitchActionType/SelectMultilevelSwitchActionType.style'
import SelectMultilevelSwitchConditionsStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSwitchConditions/SelectMultilevelSwitchConditionsState/SelectMultilevelSwitchConditionsState.style'
import SelectMultilevelSwitchConditionsStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultilevelSwitchConditions/SelectMultilevelSwitchConditionsState/SelectMultilevelSwitchConditionsState.style'
import SelectBinarySwitchActionStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/SelectBinarySwitchActionStateSelected/SelectBinarySwitchActionStateSelected.style'
import SelectBinarySwitchActionStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/SelectBinarySwitchActionStateSelected/SelectBinarySwitchActionStateSelected.style'
import SelectMezzoLightsActionStateValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoLghtsActionStateValue/SelectMezzoLightsActionStateValue.style'
import SelectMezzoLightsActionStateValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoLghtsActionStateValue/SelectMezzoLightsActionStateValue.style'
import SelectMezzoLightsActionStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoLightsActionStateSelected/SelectMezzoLightsActionStateSelected.style'
import SelectMezzoLightsActionStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoLightsActionStateSelected/SelectMezzoLightsActionStateSelected.style'
import SelectBrightnessValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/MezzoLightsActions/SelectBrightnessValue/SelectBrightnessValue.style'
import SelectBrightnessValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/MezzoLightsActions/SelectBrightnessValue/SelectBrightnessValue.style'
import SelectMezzoActionStateTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoActionStateType/SelectMezzoActionStateType.style'
import SelectMezzoActionStateTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/MezzoLightsActions/SelectMezzoActionStateType/SelectMezzoActionStateType.style'
import SelectBinarySwitchActionStateValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/SelectBinarySwitchActionStateValue/SelectBinarySwitchActionStateValue.style'
import SelectBinarySwitchActionStateValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/SelectBinarySwitchActionStateValue/SelectBinarySwitchActionStateValue.style'
import SelectDevicesConditionStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectDevicesConditionState/SelectDevicesConditionState.style'
import SelectDevicesConditionStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/SelectDevicesConditionState/SelectDevicesConditionState.style'
import SelectMezzoChimesStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/SelectMezzoChimesState/SelectMezzoChimesState.style'
import SelectMezzoChimesStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/SelectMezzoChimesState/SelectMezzoChimesState.style'
import SelectConditionDeviceTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectConditionDeviceType/SelectConditionDeviceType.style'
import SelectConditionDeviceTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/SelectConditionDeviceType/SelectConditionDeviceType.style'
import SelectConditionDeviceStateTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectConditionDeviceStateType/SelectConditionDeviceStateType.style'
import SelectConditionDeviceStateTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/SelectConditionDeviceStateType/SelectConditionDeviceStateType.style'
import SelectMezzoActionStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/SelectMezzoActionState/SelectMezzoActionState.style'
import SelectMezzoActionStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/SelectMezzoActionState/SelectMezzoActionState.style'
import SelectTimeConditionTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionType/SelectTimeConditionType.style'
import SelectTimeConditionTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionType/SelectTimeConditionType.style'
import SelectTimeConditionStateWhenStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateWhen/SelectTimeConditionStateWhen.style'
import SelectTimeConditionStateWhenIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateWhen/SelectTimeConditionStateWhen.style'
import SelectTimeConditionStateTimeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateTime/SelectTimeConditionStateTime.style'
import SelectTimeConditionStateTimeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateTime/SelectTimeConditionStateTime.style'
import SelectTimeConditionStateDayStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateDay/SelectTimeConditionStateDay.style'
import SelectTimeConditionStateDayIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateDay/SelectTimeConditionStateDay.style'
import SelectTimeConditionStateDateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateDate/SelectTimeConditionStateDate.style'
import SelectTimeConditionStateDateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateDate/SelectTimeConditionStateDate.style'
import SelectColorConditionStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/ColorCondition/SelectColorConditionState.style'
import SelectColorConditionStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/ColorCondition/SelectColorConditionState.style'
import AdjustDelayStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/AdjustDelay/AdjustDelay.style'
import AdjustDelayIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/AdjustDelay/AdjustDelay.style'
import AdjustDurationStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/AdjustDuration/AdjustDuration.style'
import AdjustDurationIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/AdjustDuration/AdjustDuration.style'
import SelectBinarySwitchValueAfterDurationStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/SelectBinarySwitchValueAfterDuration/SelectBinarySwitchValueAfterDuration.style'
import SelectBinarySwitchValueAfterDurationIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/SelectBinarySwitchValueAfterDuration/SelectBinarySwitchValueAfterDuration.style'
import SelectActionColorTypeAfterDurationStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/SelectColorActionScreens/SelectColorValueAfterDuration/SelectColorValueAfterDuration.style'
import SelectActionColorTypeAfterDurationIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/SelectColorActionScreens/SelectColorValueAfterDuration/SelectColorValueAfterDuration.style'
import CounterStyles from './Common/Counter/Counter.style'
import CounterIOSStyles from './Common/iOS/CounterIOS/Counter.style'
import EmailStyles from './Wizard/ForgotPassword/EmailScreen/Email.style'
import CodeStyles from './Wizard/ForgotPassword/CodeScreen/Code.style'
import NewPasswordStyles from './Wizard/ForgotPassword/NewPasswordScreen/NewPassword.style'
import SelectBinarySensorStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/BinarySensorConditions/SelectBinarySensorStateSelected/SelectBinarySensorStateSelected.style'
import SelectBinarySensorStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/BinarySensorConditions/SelectBinarySensorStateSelected/SelectBinarySensorStateSelected.style'
import SelectBinarySensorTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/BinarySensorConditions/SelectBinarySensorType/SelectBinarySensorType.style'
import SelectBinarySensorTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/BinarySensorConditions/SelectBinarySensorType/SelectBinarySensorType.style'
import SelectBinarySensorValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/BinarySensorConditions/SelectBinarySensorValue/SelectBinarySensorValue.style'
import SelectBinarySensorValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/BinarySensorConditions/SelectBinarySensorValue/SelectBinarySensorValue.style'
import SelectSceneConditionStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectAnotherSceneCondition/SelectAnotherSceneCondition.style'
import SelectSceneConditionIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/SelectAnotherSceneCondition/SelectAnotherSceneCondition.style'
import SelectMultilevelSensorStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorStateSelected/SelectMultilevelSensorStateSelected.style'
import SelectMultilevelSensorStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorStateSelected/SelectMultilevelSensorStateSelected.style'
import SelectMultilevelSensorTypeStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorType/SelectMultilevelSensorType.style'
import SelectMultilevelSensorTypeIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorType/SelectMultilevelSensorType.style'
import SelectMultilevelSensorValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorValue/SelectMultilevelSensorValue.style'
import SelectMultilevelSensorValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorValue/SelectMultilevelSensorValue.style'
import SelectMezzoConditionStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/SelectMezzoConditionState/SelectMezzoConditionState.style'
import SelectMezzoConditionStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/SelectMezzoConditionState/SelectMezzoConditionState.style'
import SelectMezzoLightStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/MezzoLightCondition/SelectMezzoLightState/SelectMezzoLightState.style'
import SelectMezzoLightStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/MezzoLightCondition/SelectMezzoLightState/SelectMezzoLightState.style'
import SelectCentralSceneStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneStateSelected/SelectCentralSceneStateSelected.style'
import SelectCentralSceneStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneStateSelected/SelectCentralSceneStateSelected.style'
import SelectCentralSceneSubDeviceStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDevice/SelectCentralSceneSubDevice.style'
import SelectCentralSceneSubDeviceIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDevice/SelectCentralSceneSubDevice.style'
import SelectCentralSceneSubDeviceValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDeviceValue/SelectCentralSceneSubDeviceValue.style'
import SelectCentralSceneSubDeviceValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDeviceValue/SelectCentralSceneSubDeviceValue.style'
import SelectMultichannelBinarySwitchStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelBinarySwitchStateSelected/SelectMultichannelBinarySwitchStateSelected.style'
import SelectMultichannelBinarySwitchStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelBinarySwitchStateSelected/SelectMultichannelBinarySwitchStateSelected.style'
import SelectMultichannelSubDeviceStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelSubDevice/SelectMultichannelSubDevice.style'
import SelectMultichannelSubDeviceIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelSubDevice/SelectMultichannelSubDevice.style'
import SelectMultichannelSubDeviceValueStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelSubDeviceValue/SelectMultichannelSubDeviceValue.style'
import SelectMultichannelSubDeviceValueIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelSubDeviceValue/SelectMultichannelSubDeviceValue.style'
import SelectDoorLockConditionStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/DoorLockCondition/SelectDoorLockConditionState/SelectDoorLockConditionState.style'
import SelectDoorLockConditionStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/DoorLockCondition/SelectDoorLockConditionState/SelectDoorLockConditionState.style'
import SelectGeofenceConditionStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesCondition/SelectGeofencesCondition.style'
import SelectGeofenceConditionIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesCondition/SelectGeofencesCondition.style'
import SelectGeofencesLocationStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesLocation/SelectGeofencesLocation.style'
import SelectGeofencesLocationIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesLocation/SelectGeofencesLocation.style'
import SelectGeofencesNameStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesName/SelectGeofencesName.style'
import SelectGeofencesNameIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesName/SelectGeofencesName.style'
import SelectGeofencesStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesState/SelectGeofencesState.style'
import SelectGeofencesStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesState/SelectGeofencesState.style'
import SelectGeofencesUserStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesUser/SelectGeofencesUser.style'
import SelectGeofencesUserIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesUser/SelectGeofencesUser.style'
import SelectRoomConditionStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/RoomCondition/SelectRoomCondition.style'
import SelectRoomConditionIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/RoomCondition/SelectRoomCondition.style'
import SelectRoomConditionStateStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/RoomCondition/SelectRoomConditionState.style'
import SelectRoomConditionStateIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/RoomCondition/SelectRoomConditionState.style'
import SelectGroupConditionStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GroupCondition/SelectGroupCondition.style'
import SelectGroupConditionIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/GroupCondition/SelectGroupCondition.style'
import SelectGroupActionStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/GroupsList/SelectGroupAction.style'
import SelectGroupActionIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/GroupActions/GroupsList/SelectGroupAction.style'
import SelectActionGroupStateTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/SelectActionGroupStateType/SelectActionGroupStateType.style'
import SelectActionGroupStateTypeIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/GroupActions/SelectActionGroupStateType/SelectActionGroupStateType.style'
import SelectGroupActionStateStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/SelectGroupActionState/SelectGroupActionState.style'
import SelectGroupActionStateIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/GroupActions/SelectGroupActionState/SelectGroupActionState.style'
import SelectGroupActionStateSelectedStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/SelectGroupActionStateSelected/SelectGroupActionStateSelected.style'
import SelectGroupActionStateSelectedIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/GroupActions/SelectGroupActionStateSelected/SelectGroupActionStateSelected.style'
import SelectTimerStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/SelectTimer/SelectTimer.style'
import SelectTimerIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/SelectTimer/SelectTimer.style'
import SelectTimerValueStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/SelectTimerValue/SelectTimerValue.style'
import SelectTimerValueIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/SelectTimerValue/SelectTimerValue.style'
import SelectConditionSecurityManagementDeviceStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/SelectConditionSecurityManagementDevice/SelectConditionSecurityManagementDevice.style'
import SelectConditionSecurityManagementDeviceIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/SelectConditionSecurityManagementDevice/SelectConditionSecurityManagementDevice.style'
import SelectActionSecurityManagementDeviceStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/SelectActionSecurityManagementDevice/SelectActionSecurityManagementDevice.style'
import SelectActionSecurityManagementDeviceIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/SelectActionSecurityManagementDevice/SelectActionSecurityManagementDevice.style'
import SelectTriggeringDeviceStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/SelectTriggeringDevice/SelectTriggeringDevice.style'
import SelectTriggeringDeviceIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/SelectTriggeringDevice/SelectTriggeringDevice.style'
import SelectTriggeringDeviceTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringDeviceType/SelectTriggeringDeviceType.style'
import SelectTriggeringDeviceTypeIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringDeviceType/SelectTriggeringDeviceType.style'
import SelectObservingDeviceTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingDeviceType/SelectObservingDeviceType.style'
import SelectObservingDeviceTypeIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingDeviceType/SelectObservingDeviceType.style'
import SelectTriggeringStateStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringState/SelectTriggeringState.style'
import SelectTriggeringStateIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringState/SelectTriggeringState.style'
import SelectObservingTWRDeviceStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingTWRDevice/SelectObservingTWRDevice.style'
import SelectObservingTWRDeviceIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingTWRDevice/SelectObservingTWRDevice.style'
import SelectObservingZWaveDeviceStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingZWaveDevice/SelectObservingZWaveDevice.style'
import SelectObservingZWaveDeviceIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingZWaveDevice/SelectObservingZWaveDevice.style'
import SelectTriggeringRoomStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringRoom/SelectTriggeringRoom.style'
import SelectTriggeringRoomIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringRoom/SelectTriggeringRoom.style'
import SelectObservingRoomStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingRoom/SelectObservingRoom.style'
import SelectObservingRoomIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingRoom/SelectObservingRoom.style'
import SelectTriggeringGroupStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringGroup/SelectTriggeringGroup.style'
import SelectTriggeringGroupIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringGroup/SelectTriggeringGroup.style'
import SelectObservingGroupStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingGroup/SelectObservingGroup.style'
import SelectObservingGroupIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingGroup/SelectObservingGroup.style'
import SettingsTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/Notifications/SettingsType/SettingsType.style'
import SelectNotificationUserStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/Notifications/SelectNotificationUser/SelectNotificationUser.style'
import SelectNotificationUserIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/Notifications/SelectNotificationUser/SelectNotificationUser.style'
import SelectVirtualSwitchConditionStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchCondition/SelectVirtualSwichCondition.style'
import SelectVirtualSwitchConditionIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchCondition/SelectVirtualSwichCondition.style'
import SelectVirtualSwitchConditionStateStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchConditionState/SelectVirtualSwitchConditionState.style'
import SelectVirtualSwitchConditionStateIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchConditionState/SelectVirtualSwitchConditionState.style'
import SelectVirtualSwitchActionStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/VirtualSwitchAction/SelectVirtualSwitchAction/SelectVirtualSwitchAction.style'
import SelectVirtualSwitchActionIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/VirtualSwitchAction/SelectVirtualSwitchAction/SelectVirtualSwitchAction.style'
import SelectVirtualSwitchActionValueStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/VirtualSwitchAction/SelectVirtualSwitchActionValue/SelectVirtualSwitchActionValue.style'
import SelectVirtualSwitchActionValueIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/VirtualSwitchAction/SelectVirtualSwitchActionValue/SelectVirtualSwitchActionValue.style'
import SelectVirtualSwitchActionStateStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/VirtualSwitchAction/SelectVirtualSwitchActionState/SelectVirtualSwitchActionState.style'
import SelectVirtualSwitchActionStateIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/VirtualSwitchAction/SelectVirtualSwitchActionState/SelectVirtualSwitchActionState.style'
import SelectVirtualSwitchValueAfterDurationStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/VirtualSwitchAction/SelectVirtualSwitchValueAfterDuration/SelectVirtualSwitchValueAfterDuration.style'
import SelectZWaveDeviceTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/DevicesConditions/SelectZWaveDevice/SelectZWaveDeviceType/SelectZWaveDeviceType.style'
import SelectZWaveDeviceTypeIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/DevicesConditions/SelectZWaveDevice/SelectZWaveDeviceType/SelectZWaveDeviceType.style'
import SelectSecurityOptionValueStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/SecurityOptionCondition/SelectSecurityOptionValue.style'
import SelectVirtualSwitchValueAfterDurationIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/VirtualSwitchAction/SelectVirtualSwitchValueAfterDuration/SelectVirtualSwitchValueAfterDuration.style'
// eslint-disable-next-line max-len
import SelectGroupConditionStateStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/GroupCondition/SelectGroupConditionState.style'
import SelectGroupConditionStateIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/GroupCondition/SelectGroupConditionState.style'
import CreateGeofenceScreenStyles from './Geofences/CreateGeofenceScreen/CreateGeofenceScreen.style'
import GeofencesListStyles from './Geofences/GeofencesList/GeofencesList.style'
import GroupsListFeatureStyles from './Groups/GroupsList/GroupsList.style'
import RoomsListFeatureStyles from './Rooms/RoomsList/RoomsList.style'
import AddDeviceToRoomStyles from './Rooms/AddDeviceToRoom/AddDeviceToRoom.style'
import AddDeviceToGroupStyles from './Groups/AddDeviceToGroup/AddDeviceToGroup.style'
import EditRoomStyles from './Rooms/EditRoom/EditRoom.style'
import ShowRoomStyles from './Rooms/ShowRoom/ShowRoom.style'
import ShowGroupStyles from './Groups/ShowGroup/ShowGroup.style'
import EditGroupStyles from './Groups/EditGroup/EditGroup.style'
import ContactsListStyles from './Contacts/ContactsList/ContactsList.style'
import UserSummaryPopupStyles from './Contacts/ContactsPopups/UserSummaryPopup/UserSummaryPopup.style'
import UserInfoFormStyles from './Contacts/ContactsPopups/UserInfoFormPopup/UserInfoForm.style'
import SelectBrightnessActionStateSelectedStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/MezzoLightsActions/SelectBrightnessActionStateSelected/SelectBrightnessActionStateSelected.style'
import SelectBrightnessActionStateSelectedIOSStyles
  from './MyAutomation/Tabs/iOS/CreateAction/MezzoActions/MezzoLightsActions/SelectBrightnessActionStateSelected/SelectBrightnessActionStateSelected.style'
import SelectMezzoConnectionStyles from './Wizard/SelectMezzoConnection/SelectMezzoConnection.style'
import OffsetSettingsStyles
  from './MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateWhen/OffsetSettings/OffsetSettings.style'
import OffsetSettingsIOSStyles
  from './MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateWhen/OffsetSettings/OffsetSettings.style'
import SynchronizeScreenStyles from './Synchronize/SynchronizeScreen/SynchronizeScreen.style'
import ActionsStyles from './Menu/Actions.style'
import SelectMultichannelActionSubDeviceStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionSubDevice/SelectMultichannelActionSubDevice.style'
import SelectMultichannelActionSubDeviceIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionSubDevice/SelectMultichannelActionSubDevice.style'
import SelectMultichannelActionSubDeviceValueStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionSubDeviceValue/SelectMultichannelActionSubDeviceValue.style'
import SelectMultichannelActionSubDeviceValueIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionSubDeviceValue/SelectMultichannelActionSubDeviceValue.style'
import SelectMultichannelActionStateSelectedStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionStateSelected/SelectMultichannelActionStateSelected.style'
import SelectMultichannelActionStateSelectedIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionStateSelected/SelectMultichannelActionStateSelected.style'
import SelectMultichannelActionValueAfterDurationStyles from './MyAutomation/Tabs/ScenesScreens/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionValueAfterDuration/SelectMultichannelActionValueAfterDuration.style'
import SelectMultichannelActionValueAfterDurationIOSStyles from './MyAutomation/Tabs/iOS/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionValueAfterDuration/SelectMultichannelActionValueAfterDuration.style'
import CloudFirmwareUpdateStyles from './CloudFirmwareUpdate/CloudFirmwareUpdate.style'
import SelectNotificationSensorTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/NotificationSensors/SelectNotificationSensorType/SelectNotificationSensorType.style'
import SelectNotificationSensorTypeIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/NotificationSensors/SelectNotificationSensorType/SelectNotificationSensorType.style'
import SelectEventTypeStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/NotificationSensors/SelectEventType/SelectEventType.style'
import SelectEventTypeIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/NotificationSensors/SelectEventType/SelectEventType.style'
import SelectNotificationSensorStateSelectedStyles from './MyAutomation/Tabs/ScenesScreens/CreateCondition/NotificationSensors/SelectNotificationSensorStateSelected/SelectNotificationSensorStateSelected.style'
import SelectNotificationSensorStateSelectedIOSStyles from './MyAutomation/Tabs/iOS/CreateCondition/NotificationSensors/SelectNotificationSensorStateSelected/SelectNotificationSensorStateSelected.style'

const mapComponentToStyles = {
  MezzoErrors: MezzoErrorsStyles,
  MenuItem: MenuItemStyles,
  Drawer: DrawerStyles,
  Toolbar: ToolbarStyles,
  Logo: LogoStyles,
  Secrets: SecretsStyles,
  Home: HomeStyles,
  LoadingCard: LoadingCardStyles,
  MemberList: MemberListStyles,
  RequestList: RequestListStyles,
  UserEdit: UserEditStyles,
  UserRequestDetails: UserRequestDetailsStyles,
  MemberDetails: MemberDetailsStyles,
  AvatarPopup: AvatarPopupStyles,
  SecretCodePopup: SecretCodePopupStyles,
  PinCodePopup: PinCodePopupStyles,
  Events: EventsStyles,
  Filters: FiltersStyles,
  Snackbar: SnackbarStyles,
  MyEnergy: MyEnergyStyles,
  EnergyTab: EnergyTabStyles,
  Charts: ChartsStyles,
  DeviceManager: DeviceManagerStyles,
  EcoMode: EcoModeStyles,
  RoomsStats: RoomsStatsStyles,
  RoomsStatsPopup: RoomsStatsPopupStyles,
  BillingPeriod: BillingPeriodStyles,
  SetUpBillingPopup: SetUpBillingPopupStyles,
  BillingStatsPopup: BillingStatsPopupStyles,
  SelectMezzoChimesState: SelectMezzoChimesStateStyles,
  SelectMezzoChimesStateIOS: SelectMezzoChimesStateIOSStyles,
  SelectConditionDeviceType: SelectConditionDeviceTypeStyles,
  SelectConditionDeviceTypeIOS: SelectConditionDeviceTypeIOSStyles,
  SelectConditionDeviceStateType: SelectConditionDeviceStateTypeStyles,
  SelectConditionDeviceStateTypeIOS: SelectConditionDeviceStateTypeIOSStyles,
  SelectMezzoActionState: SelectMezzoActionStateStyles,
  SelectMezzoActionStateIOS: SelectMezzoActionStateIOSStyles,
  SelectDevicesConditionState: SelectDevicesConditionStateStyles,
  SelectDevicesConditionStateIOS: SelectDevicesConditionStateIOSStyles,
  AdjustDelay: AdjustDelayStyles,
  AdjustDelayIOS: AdjustDelayIOSStyles,
  AdjustDuration: AdjustDurationStyles,
  AdjustDurationIOS: AdjustDurationIOSStyles,
  Counter: CounterStyles,
  CounterIOS: CounterIOSStyles,
  Email: EmailStyles,
  Code: CodeStyles,
  NewPassword: NewPasswordStyles,
  SignIn: SignInStyles,
  SignUp: SignUpStyles,
  MyStatusDevicePopup: MyStatusDevicePopupStyles,
  MyStatusDeviceCard: MyStatusDeviceCardStyles,
  MyStatusDeviceCardList: MyStatusDeviceCardListStyles,
  SelectBinarySensorStateSelected: SelectBinarySensorStateSelectedStyles,
  SelectBinarySensorStateSelectedIOS: SelectBinarySensorStateSelectedIOSStyles,
  SelectBinarySensorType: SelectBinarySensorTypeStyles,
  SelectBinarySensorTypeIOS: SelectBinarySensorTypeIOSStyles,
  SelectBinarySensorValue: SelectBinarySensorValueStyles,
  SelectBinarySensorValueIOS: SelectBinarySensorValueIOSStyles,
  SelectMultilevelSensorStateSelected: SelectMultilevelSensorStateSelectedStyles,
  SelectMultilevelSensorStateSelectedIOS: SelectMultilevelSensorStateSelectedIOSStyles,
  SelectMultilevelSensorType: SelectMultilevelSensorTypeStyles,
  SelectMultilevelSensorTypeIOS: SelectMultilevelSensorTypeIOSStyles,
  SelectMultilevelSensorValue: SelectMultilevelSensorValueStyles,
  SelectMultilevelSensorValueIOS: SelectMultilevelSensorValueIOSStyles,
  SelectCentralSceneStateSelected: SelectCentralSceneStateSelectedStyles,
  SelectCentralSceneStateSelectedIOS: SelectCentralSceneStateSelectedIOSStyles,
  SelectCentralSceneSubDevice: SelectCentralSceneSubDeviceStyles,
  SelectCentralSceneSubDeviceIOS: SelectCentralSceneSubDeviceIOSStyles,
  SelectCentralSceneSubDeviceValue: SelectCentralSceneSubDeviceValueStyles,
  SelectCentralSceneSubDeviceValueIOS: SelectCentralSceneSubDeviceValueIOSStyles,
  SelectMultichannelBinarySwitchStateSelected: SelectMultichannelBinarySwitchStateSelectedStyles,
  SelectMultichannelBinarySwitchStateSelectedIOS: SelectMultichannelBinarySwitchStateSelectedIOSStyles,
  SelectMultichannelSubDevice: SelectMultichannelSubDeviceStyles,
  SelectMultichannelSubDeviceIOS: SelectMultichannelSubDeviceIOSStyles,
  SelectMultichannelSubDeviceValue: SelectMultichannelSubDeviceValueStyles,
  SelectMultichannelSubDeviceValueIOS: SelectMultichannelSubDeviceValueIOSStyles,
  SelectDoorLockConditionState: SelectDoorLockConditionStateStyles,
  SelectDoorLockConditionStateIOS: SelectDoorLockConditionStateIOSStyles,
  SelectTimeConditionType: SelectTimeConditionTypeStyles,
  SelectTimeConditionTypeIOS: SelectTimeConditionTypeIOSStyles,
  SelectTimeConditionStateWhen: SelectTimeConditionStateWhenStyles,
  SelectTimeConditionStateWhenIOS: SelectTimeConditionStateWhenIOSStyles,
  SelectAnotherSceneCondition: SelectSceneConditionStyles,
  SelectAnotherSceneConditionIOS: SelectSceneConditionIOSStyles,
  SelectTimeConditionStateTime: SelectTimeConditionStateTimeStyles,
  SelectTimeConditionStateTimeIOS: SelectTimeConditionStateTimeIOSStyles,
  SelectTimeConditionStateDay: SelectTimeConditionStateDayStyles,
  SelectTimeConditionStateDayIOS: SelectTimeConditionStateDayIOSStyles,
  SelectTimeConditionStateDate: SelectTimeConditionStateDateStyles,
  SelectTimeConditionStateDateIOS: SelectTimeConditionStateDateIOSStyles,
  SelectColorConditionState: SelectColorConditionStateStyles,
  SelectColorConditionStateIOS: SelectColorConditionStateIOSStyles,
  SelectColorActionStateSelected: SelectColorActionStateSelectedStyles,
  SelectColorActionStateSelectedIOS: SelectColorActionStateSelectedIOSStyles,
  SelectColorActionHue: SelectColorActionHueStyles,
  SelectColorActionHueIOS: SelectColorActionHueIOSStyles,
  SelectActionColorType: SelectActionColorTypeStyles,
  SelectActionColorTypeIOS: SelectActionColorTypeIOSStyles,
  SelectMultilevelSwitchActionType: SelectMultilevelSwitchActionTypeStyles,
  SelectMultilevelSwitchActionTypeIOS: SelectMultilevelSwitchActionTypeIOSStyles,
  SelectMultilevelSwitchConditionsState: SelectMultilevelSwitchConditionsStateStyles,
  SelectMultilevelSwitchConditionsStateIOS: SelectMultilevelSwitchConditionsStateIOSStyles,
  SelectMezzoConditionState: SelectMezzoConditionStateStyles,
  SelectMezzoConditionStateIOS: SelectMezzoConditionStateIOSStyles,
  SelectMezzoLightState: SelectMezzoLightStateStyles,
  SelectMezzoLightStateIOS: SelectMezzoLightStateIOSStyles,
  SelectGeofencesLocation: SelectGeofencesLocationStyles,
  SelectGeofencesLocationIOS: SelectGeofencesLocationIOSStyles,
  SelectGeofencesName: SelectGeofencesNameStyles,
  SelectGeofencesNameIOS: SelectGeofencesNameIOSStyles,
  SelectGeofencesState: SelectGeofencesStateStyles,
  SelectGeofencesStateIOS: SelectGeofencesStateIOSStyles,
  SelectGeofencesUser: SelectGeofencesUserStyles,
  SelectGeofencesUserIOS: SelectGeofencesUserIOSStyles,
  SelectGeofenceCondition: SelectGeofenceConditionStyles,
  SelectGeofenceConditionIOS: SelectGeofenceConditionIOSStyles,
  SelectRoomCondition: SelectRoomConditionStyles,
  SelectRoomConditionIOS: SelectRoomConditionIOSStyles,
  SelectRoomConditionState: SelectRoomConditionStateStyles,
  SelectRoomConditionStateIOS: SelectRoomConditionStateIOSStyles,
  SelectGroupCondition: SelectGroupConditionStyles,
  SelectGroupConditionIOS: SelectGroupConditionIOSStyles,
  SelectGroupAction: SelectGroupActionStyles,
  SelectGroupActionIOS: SelectGroupActionIOSStyles,
  SelectGroupConditionState: SelectGroupConditionStateStyles,
  SelectGroupConditionStateIOS: SelectGroupConditionStateIOSStyles,
  CreateGeofenceScreen: CreateGeofenceScreenStyles,
  GeofencesList: GeofencesListStyles,
  GroupsListFeature: GroupsListFeatureStyles,
  RoomsListFeature: RoomsListFeatureStyles,
  AddDeviceToRoom: AddDeviceToRoomStyles,
  AddDeviceToGroup: AddDeviceToGroupStyles,
  EditRoom: EditRoomStyles,
  ShowRoom: ShowRoomStyles,
  ShowGroup: ShowGroupStyles,
  EditGroup: EditGroupStyles,
  SelectBinarySwitchActionStateSelected: SelectBinarySwitchActionStateSelectedStyles,
  SelectBinarySwitchActionStateSelectedIOS: SelectBinarySwitchActionStateSelectedIOSStyles,
  SelectBinarySwitchActionStateValue: SelectBinarySwitchActionStateValueStyles,
  SelectBinarySwitchActionStateValueIOS: SelectBinarySwitchActionStateValueIOSStyles,
  ContactsList: ContactsListStyles,
  UserSummaryPopup: UserSummaryPopupStyles,
  UserInfoForm: UserInfoFormStyles,
  SelectBinarySwitchValueAfterDuration: SelectBinarySwitchValueAfterDurationStyles,
  SelectBinarySwitchValueAfterDurationIOS: SelectBinarySwitchValueAfterDurationIOSStyles,
  SelectActionColorTypeAfterDuration: SelectActionColorTypeAfterDurationStyles,
  SelectActionColorTypeAfterDurationIOS: SelectActionColorTypeAfterDurationIOSStyles,
  SelectMezzoLightsActionStateSelected: SelectMezzoLightsActionStateSelectedStyles,
  SelectMezzoLightsActionStateSelectedIOS: SelectMezzoLightsActionStateSelectedIOSStyles,
  SelectMezzoLightsActionStateValue: SelectMezzoLightsActionStateValueStyles,
  SelectMezzoLightsActionStateValueIOS: SelectMezzoLightsActionStateValueIOSStyles,
  SelectMezzoActionStateType: SelectMezzoActionStateTypeStyles,
  SelectMezzoActionStateTypeIOS: SelectMezzoActionStateTypeIOSStyles,
  SelectBrightnessValue: SelectBrightnessValueStyles,
  SelectBrightnessValueIOS: SelectBrightnessValueIOSStyles,
  SelectBrightnessActionStateSelected: SelectBrightnessActionStateSelectedStyles,
  SelectBrightnessActionStateSelectedIOS: SelectBrightnessActionStateSelectedIOSStyles,
  SelectMezzoConnection: SelectMezzoConnectionStyles,
  SelectActionGroupStateType: SelectActionGroupStateTypeStyles,
  SelectActionGroupStateTypeIOS: SelectActionGroupStateTypeIOSStyles,
  SelectGroupActionState: SelectGroupActionStateStyles,
  SelectGroupActionStateIOS: SelectGroupActionStateIOSStyles,
  SelectGroupActionStateSelected: SelectGroupActionStateSelectedStyles,
  SelectGroupActionStateSelectedIOS: SelectGroupActionStateSelectedIOSStyles,
  SelectTimer: SelectTimerStyles,
  SelectTimerIOS: SelectTimerIOSStyles,
  SelectTimerValue: SelectTimerValueStyles,
  SelectTimerValueIOS: SelectTimerValueIOSStyles,
  SelectConditionSecurityManagementDevice: SelectConditionSecurityManagementDeviceStyles,
  SelectConditionSecurityManagementDeviceIOS: SelectConditionSecurityManagementDeviceIOSStyles,
  SelectActionSecurityManagementDevice: SelectActionSecurityManagementDeviceStyles,
  SelectActionSecurityManagementDeviceIOS: SelectActionSecurityManagementDeviceIOSStyles,
  SelectTriggeringDevice: SelectTriggeringDeviceStyles,
  SelectTriggeringDeviceIOS: SelectTriggeringDeviceIOSStyles,
  SelectTriggeringDeviceType: SelectTriggeringDeviceTypeStyles,
  SelectTriggeringDeviceTypeIOS: SelectTriggeringDeviceTypeIOSStyles,
  SelectObservingDeviceType: SelectObservingDeviceTypeStyles,
  SelectObservingDeviceTypeIOS: SelectObservingDeviceTypeIOSStyles,
  SelectTriggeringState: SelectTriggeringStateStyles,
  SelectTriggeringStateIOS: SelectTriggeringStateIOSStyles,
  SelectObservingTWRDevice: SelectObservingTWRDeviceStyles,
  SelectObservingTWRDeviceIOS: SelectObservingTWRDeviceIOSStyles,
  SelectObservingZWaveDevice: SelectObservingZWaveDeviceStyles,
  SelectObservingZWaveDeviceIOS: SelectObservingZWaveDeviceIOSStyles,
  SelectTriggeringRoom: SelectTriggeringRoomStyles,
  SelectTriggeringRoomIOS: SelectTriggeringRoomIOSStyles,
  SelectObservingRoom: SelectObservingRoomStyles,
  SelectObservingRoomIOS: SelectObservingRoomIOSStyles,
  SelectTriggeringGroup: SelectTriggeringGroupStyles,
  SelectTriggeringGroupIOS: SelectTriggeringGroupIOSStyles,
  SelectObservingGroup: SelectObservingGroupStyles,
  SelectObservingGroupIOS: SelectObservingGroupIOSStyles,
  SettingsType: SettingsTypeStyles,
  SelectNotificationUser: SelectNotificationUserStyles,
  SelectNotificationUserIOS: SelectNotificationUserIOSStyles,
  SelectVirtualSwitchCondition: SelectVirtualSwitchConditionStyles,
  SelectVirtualSwitchConditionIOS: SelectVirtualSwitchConditionIOSStyles,
  SelectVirtualSwitchConditionState: SelectVirtualSwitchConditionStateStyles,
  SelectVirtualSwitchConditionStateIOS: SelectVirtualSwitchConditionStateIOSStyles,
  SelectVirtualSwitchAction: SelectVirtualSwitchActionStyles,
  SelectVirtualSwitchActionIOS: SelectVirtualSwitchActionIOSStyles,
  SelectVirtualSwitchActionValue: SelectVirtualSwitchActionValueStyles,
  SelectVirtualSwitchActionValueIOS: SelectVirtualSwitchActionValueIOSStyles,
  SelectVirtualSwitchActionState: SelectVirtualSwitchActionStateStyles,
  SelectVirtualSwitchActionStateIOS: SelectVirtualSwitchActionStateIOSStyles,
  SelectVirtualSwitchValueAfterDuration: SelectVirtualSwitchValueAfterDurationStyles,
  SelectVirtualSwitchValueAfterDurationIOS: SelectVirtualSwitchValueAfterDurationIOSStyles,
  SynchronizeScreen: SynchronizeScreenStyles,
  OffsetSettings: OffsetSettingsStyles,
  OffsetSettingsIOS: OffsetSettingsIOSStyles,
  Actions: ActionsStyles,
  SelectMultichannelActionSubDevice: SelectMultichannelActionSubDeviceStyles,
  SelectMultichannelActionSubDeviceIOS: SelectMultichannelActionSubDeviceIOSStyles,
  SelectMultichannelActionSubDeviceValue: SelectMultichannelActionSubDeviceValueStyles,
  SelectMultichannelActionSubDeviceValueIOS: SelectMultichannelActionSubDeviceValueIOSStyles,
  SelectMultichannelActionStateSelected: SelectMultichannelActionStateSelectedStyles,
  SelectMultichannelActionStateSelectedIOS: SelectMultichannelActionStateSelectedIOSStyles,
  SelectMultichannelActionValueAfterDuration: SelectMultichannelActionValueAfterDurationStyles,
  SelectMultichannelActionValueAfterDurationIOS: SelectMultichannelActionValueAfterDurationIOSStyles,
  CloudFirmwareUpdate: CloudFirmwareUpdateStyles,
  SelectNotificationSensorType: SelectNotificationSensorTypeStyles,
  SelectNotificationSensorTypeIOS: SelectNotificationSensorTypeIOSStyles,
  SelectEventType: SelectEventTypeStyles,
  SelectEventTypeIOS: SelectEventTypeIOSStyles,
  SelectNotificationSensorStateSelected: SelectNotificationSensorStateSelectedStyles,
  SelectZWaveDeviceType: SelectZWaveDeviceTypeStyles,
  SelectZWaveDeviceTypeIOS: SelectZWaveDeviceTypeIOSStyles,
  SelectSecurityOptionValue: SelectSecurityOptionValueStyles,
  SelectNotificationSensorStateSelectedIOS: SelectNotificationSensorStateSelectedIOSStyles,
}

export const withStyles = (component) => {
  const name = component.displayName
  return muiWithStyles(mapComponentToStyles[name])(component)
}

export const createStyled = (styles) => {
  const Styled = (props) => {
    const { children, ...other } = props
    return children(other)
  }
  Styled.propTypes = {
    children: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }
  return muiWithStyles(styles)(Styled)
}
