import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  restoreActionInfo,
  saveActionGroupState,
  saveActionStateDescription,
  saveActionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGroupActionState
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/SelectGroupActionState/SelectGroupActionState'
import SelectGroupActionStateIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/GroupActions/SelectGroupActionState/SelectGroupActionState'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectGroupActionStateIOS) : withStyles(SelectGroupActionState)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const {
    isEditActionMode, isChangeActionStateMode, isChangeActionMode, initialAction,
  } = state.scenes.currentSceneCreating
  const { stateDescription } = state.scenes.currentSceneCreating.currentAction
  const { actions } = state.scenes.currentSceneConditionsActions
  return {
    isMobile,
    isEditActionMode,
    isChangeActionStateMode,
    isChangeActionMode,
    stateDescription,
    actions,
    initialAction,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveActionType,
  saveActionStateDescription,
  saveActionGroupState,
  restoreActionInfo,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGroupActionStateEnhanced = enhance

export default withRouter(SelectGroupActionStateEnhanced)
