export const SAVE_PO_CONFIG = '@@poConfig/savePoConfig'

export const RESET_PO_CONFIG = '@@poConfig/reset'

export const SYNC_PO_FROM_CONFIG = '@@poConfig/sync'

export const SET_PO_FROM_CONFIG = '@@poConfig/set'

export const SET_PO_FROM_CONFIG_INITIAL_OPTIONS = '@@poConfig/setInitialOptions'
export const SET_PO_FROM_CONFIG_CURRENT_OPTIONS = '@@poConfig/setCurrentOptions'

export const SET_PO_FROM_CONFIG_VALIDATION = '@@poConfig/setPoFromConfigValidation'

export const START_SAVING_PROGRESS = '@@poConfig/startSavingProgress'
export const END_SAVING_PROGRESS = '@@poConfig/endSavingProgress'
export const API_REQUEST_FAILED = '@@poConfig/apiRequestFailed'
