import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import convertRoomStats from '@app/helpers/convertRoomStats'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { withStyles } from '@app/components/styles'
import {
  clearCurrentRoomIdAndName,
  closeRoomStatsPopup,
  resetCurrentRoomCounters,
} from '@app/actions/myEnergy/roomsStats'
import RoomsStatsPopup from '@app/components/MyEnergy/Tabs/RoomsStats/RoomsStatsPopup/RoomsStatsPopup'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'

let enhance = withStyles(RoomsStatsPopup)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { roomsStats: { isRoomsStatsPopupOpen } } = store.getState()
  const roomName = state.roomsStats.currentRoom.name
  const periodStats = convertRoomStats(state.roomsStats.roomStats)
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  return {
    isMobile,
    isRoomsStatsPopupOpen,
    periodStats,
    roomName,
    admin,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeRoomStatsPopup,
  clearCurrentRoomIdAndName,
  resetCurrentRoomCounters,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RoomsStatsPopupEnhanced = enhance

export default RoomsStatsPopupEnhanced
