import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_ACTION } from '@app/constants/scenes/currentSceneConditionsActions'
import { getConditionsActions } from '@app/actions/scenes/currentSceneConditionsActions'
import Api from '@app/api/scenes/deleteAction'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { clearCurrentActionInfo } from '@app/actions/scenes/currentSceneCreating'

const deleteActionLogic = createLogic({
  type: DELETE_ACTION,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentAction: { id } } } } = store.getState()
    dispatch(apiRequestSent(DELETE_ACTION))
    Api.deleteAction(token, id)
      .then(() => {
        dispatch(apiRequestDone(DELETE_ACTION))
        dispatch(getConditionsActions())
        dispatch(clearCurrentActionInfo())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteAction failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteActionLogic
