import * as type from '@app/constants/myEnergy/myEnergy'

const initialState = {
  currentTab: 'energy',
}

const myEnergy = (state = initialState, action) => {
  switch (action.type) {
  case type.CHOOSE_TAB: {
    return {
      ...state,
      currentTab: action.chosenTab,
    }
  }

  case type.RESET_MY_ENERGY:
    return initialState
  default:
    return state
  }
}

export default myEnergy
