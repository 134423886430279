import * as type from '@app/constants/timers/timers'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/timers/timers'
import { ITimer, ITimerData } from '@app/@types/timers/timersTypes'

type ITimers = ReturnType<InferValueTypes<typeof actions>>

export interface ITimersState {
  timers: Array<ITimer>,
  timerData: ITimerData | {},
  currentTrigger: string,
}

const initialState = {
  timers: [],
  timerData: {},
  currentTrigger: '',
}

export default function timers(state: ITimersState = initialState, action: ITimers) {
  switch (action.type) {
  case type.SET_TIMERS: return {
    ...state,
    timers: action.timers,
  }
  case type.SAVE_TIMER_DATA: {
    return {
      ...state,
      timerData: action.timerData,
    }
  }
  case type.SET_CURRENT_TRIGGER: return {
    ...state,
    currentTrigger: action.trigger,
  }

  case type.UPDATE_TIMER: return {
    ...state,
    timers: state.timers.map((timer) => (timer.number === action.timer.number ? action.timer : timer)),
  }

  case type.RESET_TIMERS_REDUCER:
    return initialState

  default:
    return state
  }
}
