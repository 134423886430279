import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { IconButton, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment-timezone'
import { useNavigate, useLocation } from 'react-router-dom'

import handleErrors from '@app/helpers/handleErrors'
import { DEFAULT_ITEMS_LIMIT, DEFAULT_PAGE, TIMEZONE_NUMBER_DEFINITION } from '@app/constants/stats'
import { onlineStatsRequest, stateListRequest } from '@app/logics/stats/stats'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft } from 'icons'
import StateList from '../StateList/StateList'
import OnlineChart from '../Charts/OnlineChart'
import TimePicker from '../TimePicker/TimePicker'

import useStyles from './StatsMainPage.style'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type OnlineStatsType = {
  primary: Array<Array<string | number>>,
  secondary: Array<Array<string | number>>,
}

type Props = {
  setError: () => void,
}

let debounceTimer

const StatsMainPage = (props: Props): Node => {
  const classes = useStyles()
  const { setError } = props
  const isChartFullWidth = useMediaQuery('(max-width: 1280px)')
  const location = useLocation()
  const navigate = useNavigate()

  const query = new URLSearchParams(location.search)
  const [stateList, setStateList] = useState({
    total: 0,
    unit_devices: [],
  })
  const [showStats, setShowStats] = useState < boolean > (true)
  const [page, setPage] = useState < number > (1)
  const [itemLimit, setItemLimit] = useState < number > (10)
  const [serialFilter, setSerialFilter] = useState < string > ('')
  const [ssidFilter, setSsidFilter] = useState < string > ('')
  const [onlineStats, setOnlineStats] = useState < OnlineStatsType > ({
    primary: [['Time Range', 'Total online', 'Total offline', 'Online by Ethernet', 'Online by GSM']],
    secondary: [['Time Range', 'TCP', 'UDP']],
  })

  const switchChartHandler = () => {
    setShowStats((prevStats) => !prevStats)
  }

  const getOnlineStats = async (startDate: { [key: any]: any }, endDate: { [key: any]: any }, timezone: string): Promise<any> => {
    try {
      const response = await onlineStatsRequest(
        startDate.tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION), true).format(),
        endDate.tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION), true).format(),
      )
      setOnlineStats((prevStats) => ({
        ...prevStats,
        primary: [prevStats.primary[0],
          ...response.data.totals.map((total) => [
            moment(total.created_at).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).format('Do MMM YY, h:mm:ss a'),
            total.online, total.offline, total.ethernet, total.gsm]),
        ],
        secondary: [prevStats.secondary[0],
          ...response.data.totals.map((total) => [
            moment(total.created_at).tz(timezone.slice(TIMEZONE_NUMBER_DEFINITION)).format('Do MMM YY, h:mm:ss a'),
            total.tcp, total.udp]),
        ],
      }))
    } catch (err) {
      setError(handleErrors(err))
    }
  }

  const changeLimitHandler = (evt) => {
    setItemLimit(evt.target.value)
    navigate(`${location.pathname}${page === 1
      ? `?limit=${evt.target.value}`
      : `${location.search.split('&')[0]}&limit=${evt.target.value}`}`)
    setPage(1)
  }

  const onListSubmitHandler = async (): Promise<any> => {
    try {
      const res = await stateListRequest(page, itemLimit, serialFilter, ssidFilter)
      setStateList({
        ...res.data,
      })
    } catch (err) {
      setError(handleErrors(err))
    }
  }

  useEffect(() => {
    setPage(parseInt(query.get('page') || DEFAULT_PAGE, 10))
    setItemLimit(parseInt(query.get('limit') || DEFAULT_ITEMS_LIMIT, 10))
  }, [location])

  useEffect(() => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      if (ssidFilter || serialFilter) {
        if (page !== 1) {
          navigate(`${location.pathname}?page=1&?limit=${itemLimit}`)
        } else {
          onListSubmitHandler()
        }
      }
    }, 1000)
  }, [ssidFilter, serialFilter])

  useEffect(() => {
    onListSubmitHandler()
  }, [page, itemLimit])

  return (
    <>
      <Wrapper className={classes.chartContainer}>
        <Typography variant="h3">{I18n.t('stats.main_title')}</Typography>
        <TimePicker
          onSubmitHandler={getOnlineStats}
        />
        <IconButton
          className={classNames(classes.leftArrow, isChartFullWidth && classes.leftInner)}
          onClick={switchChartHandler}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          className={classNames(classes.rightArrow, isChartFullWidth && classes.rightInner)}
          onClick={switchChartHandler}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
        {(onlineStats.primary.length > 1 || onlineStats.secondary.length > 1)
          ? (
            <OnlineChart
              data={showStats ? onlineStats.primary : onlineStats.secondary}
              isFullWidth={isChartFullWidth}
            />
          )
          : (
            <div className={classes.chartPlaceholder}>
              <Typography>{I18n.t('stats.choose_different_time')}</Typography>
            </div>
          )}
        <Wrapper className={classes.progressTipContainer}>
          <div className={classNames(classes.progressTip, showStats && classes.active)} />
          <div className={classNames(classes.progressTip, !showStats && classes.active)} />
        </Wrapper>
      </Wrapper>
      <StateList
        total={stateList.total}
        stateList={stateList}
        ssidFilter={ssidFilter}
        serialFilter={serialFilter}
        setSsidFilter={setSsidFilter}
        setSerialFilter={setSerialFilter}
        changeLimitHandler={changeLimitHandler}
        page={page}
        itemLimit={itemLimit}
      />
    </>
  )
}

export default StatsMainPage
