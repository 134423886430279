const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    width: '100%',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  cardBlock: {
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 50,
  },
  cardValueBlock: {
    minHeight: 120,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2% 10%',
  },
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1% 8%',
  },
  cardTopWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: '16px',
    color: theme.scenes.descriptionTop,
    marginBottom: 5,
  },
  radioWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  radioButton: {
    marginRight: 7,
  },
  sliderRail: {
    height: 6,
  },
  sliderTrack: {
    height: 6,
  },
  sliderThumbColor: {
    width: 16,
    height: 16,
    color: '#fff',
    border: '1px solid rgba(0,0,0,0.2)',
  },
  sliderMark: {
    width: 0,
    height: 0,
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  errorText: {
    color: theme.palette.error.main,
  },
})

export default styles
