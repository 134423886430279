import { mezzoLightsColors } from '@app/lib/Scenes/mezzoLightsColors'
import { brightnessValues } from '@app/lib/Scenes/mezzoBrightnessValues'
import convertColorActionDataToBackend from './convertColorActionDataToBackend'

const getValueNumber = (stateDescription) => {
  switch (stateDescription) {
  case 'turn_on': {
    return 1
  }
  case 'turn on at 100%': {
    return 99
  }
  case 'turn_off':
  case 'turn off': {
    return 0
  }
  case 'turn_on_previous':
  case 'turn on previous': {
    return 0xFF
  }
  case 'lock': {
    return 255
  }
  case 'unlock': {
    return 0
  }
  case 'toggle': {
    return 2
  }
  default: {
    return Number(stateDescription.replace(/\D+/g, ''))
  }
  }
}

const getDurationValueAfter = (durationValueAfter) => {
  switch (durationValueAfter) {
  case 'lock': {
    return 255
  }
  case 'unlock': {
    return 0
  }
  case 'turn on at 100%': {
    return 99
  }
  case 'turn_on': {
    return 1
  }
  case 'turn on': {
    return 99
  }
  case 'turn_off':
  case 'turn off': {
    return 0
  }
  case 'none': {
    return 255
  }
  case 'toggle': {
    return 2
  }
  default: {
    return Number(durationValueAfter.replace(/\D+/g, ''))
  }
  }
}

const getDelayValueAfterVirtualSwitch = (stateDescription) => {
  switch (stateDescription) {
  case 'turn_on': {
    return 1
  }
  case 'turn_off': {
    return 0
  }
  case 'toggle': {
    return 2
  }
  default: {
    throw new Error('action unsupported') }
  }
}

const getDurationValueAfterVirtualSwitch = (durationValueAfter) => {
  switch (durationValueAfter) {
  case 'turn_on': {
    return 1
  }
  case 'turn_off': {
    return 0
  }
  case 'none': {
    return 0
  }
  case 'toggle': {
    return 2
  }
  default: {
    throw new Error('action unsupported') }
  }
}
// eslint-disable-next-line max-len
const getActionDeviceStateInfo = (
  actionType,
  stateDescription,
  deviceNumber,
  actionDelay,
  durationValueAfter,
  actionDuration,
  colorType,
  colorTypeDurationValueAfter,
  roomNumber,
  roomValue,
  groupNumber,
  groupValue,
  selectedUsers,
  text,
  requestBody,
  requestHeader,
  requestUrl,
  requestType,
  subDeviceNumber,
) => {
  const resultDurationValueAfter = getDurationValueAfter(durationValueAfter)
  const valueNumber = getValueNumber(stateDescription)
  switch (actionType) {
  case 'LightMezzo':
    return {
      value: brightnessValues.indexOf(stateDescription),
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
        value_after: durationValueAfter === 'none' ? 0 : brightnessValues.indexOf(durationValueAfter),
      },
    }
  case 'MezzoTopLightColor':
    return {
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
      colours: mezzoLightsColors.find((el) => el.color === stateDescription).value,
    }
  case 'MezzoBottomLightColor':
    return {
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
      colours: mezzoLightsColors.find((el) => el.color === stateDescription).value,
    }
  case 'SetZwaveColor':
    return {
      number: deviceNumber,
      colours: convertColorActionDataToBackend(stateDescription, colorType),
    }
  case 'ZwaveColorSetWithDuration':
    return {
      number: deviceNumber,
      duration: actionDuration,
      colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      colours_after: convertColorActionDataToBackend(durationValueAfter, colorTypeDurationValueAfter),
    }
  case 'ZwaveColorDelayDuration':
    if (durationValueAfter === 'none') {
      return {
        number: deviceNumber,
        duration: {
          day: actionDuration.day || 0,
          hour: actionDuration.hour || 0,
          minute: actionDuration.minute || 0,
          second: actionDuration.second || 0,
        },
        delay: actionDelay,
        colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      }
    }
    return {
      number: deviceNumber,
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
      delay: actionDelay,
      colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      colours_after: convertColorActionDataToBackend(durationValueAfter, colorTypeDurationValueAfter),
    }
  case 'DelayedDeviceAction':
    return {
      type: 2,
      value: valueNumber,
      number: deviceNumber,
      delay: actionDelay,
    }
  case 'SetDeviceStatus':
    return {
      type: 2,
      value: valueNumber,
      number: deviceNumber,
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
        value_after: resultDurationValueAfter,
      },
    }
  case 'DelayedPlusDurationDeviceAction':
    return {
      type: 2,
      number: deviceNumber,
      delay: {
        day: actionDelay.day,
        hour: actionDelay.hour,
        minute: actionDelay.minute,
        second: actionDelay.second,
        value_after: valueNumber,
      },
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
        value_after: resultDurationValueAfter,
      },
    }
  case 'Toggle':
    return { number: deviceNumber }
  case 'ToggleZwaveDeviceWithDuration':
    return {
      number: deviceNumber,
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
        value_after: resultDurationValueAfter,
      },
    }
  case 'ControlRoom':
    return {
      value: roomValue,
      number: roomNumber,
    }
  case 'RoomColorControl':
    return {
      number: roomNumber,
      colours: convertColorActionDataToBackend(stateDescription, colorType),
    }
  case 'RoomColorDelayDuration':
    return {
      number: roomNumber,
      colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      colours_after: durationValueAfter ? convertColorActionDataToBackend(durationValueAfter, colorTypeDurationValueAfter) : {},
      delay: {
        day: actionDelay.day,
        hour: actionDelay.hour,
        minute: actionDelay.minute,
        second: actionDelay.second,
      },
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
    }
  case 'RoomColorWithDuration':
    return {
      number: roomNumber,
      colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      colours_after: durationValueAfter ? convertColorActionDataToBackend(durationValueAfter, colorTypeDurationValueAfter) : {},
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
    }
  case 'ControlGroup':
    return {
      value: groupValue,
      number: groupNumber,
    }
  case 'GroupColorControl':
    return {
      number: groupNumber,
      colours: convertColorActionDataToBackend(stateDescription, colorType),
    }
  case 'GroupColorWithDuration':
    return {
      number: groupNumber,
      colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      colours_after: durationValueAfter ? convertColorActionDataToBackend(durationValueAfter, colorTypeDurationValueAfter) : {},
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
    }
  case 'GroupColorDelayDuration':
    return {
      number: groupNumber,
      colours_before: convertColorActionDataToBackend(stateDescription, colorType),
      colours_after: durationValueAfter ? convertColorActionDataToBackend(durationValueAfter, colorTypeDurationValueAfter) : {},
      delay: {
        day: actionDelay.day,
        hour: actionDelay.hour,
        minute: actionDelay.minute,
        second: actionDelay.second,
      },
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
      },
    }
  case 'StartTimer':
    return {
      number: deviceNumber,
    }
  case 'StopTimer':
    return {
      number: deviceNumber,
    }
  case 'PushNotifications':
    return {
      web_action_type: 'PushNotifications',
      users: selectedUsers,
      text,
    }
  case 'Email':
    return {
      web_action_type: 'Email',
      users: selectedUsers,
      text,
    }
  case 'WebRequest':
    return {
      web_action_type: 'WebRequest',
      url: requestUrl,
      request_type: requestType,
      body: requestBody.slice(0, requestBody.length - 1),
      headers: requestHeader.slice(0, requestHeader.length - 1),
    }
  case 'ZwaveMultichannelControl':
    return {
      type: 2,
      value: valueNumber,
      number: deviceNumber,
      channel: subDeviceNumber,
      command_class: 0,
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
        value_after: resultDurationValueAfter,
      },
    }
  case 'VirtualSwitch':
    return {
      number: deviceNumber,
      value: getDelayValueAfterVirtualSwitch(stateDescription),
    }
  case 'VirtualSwitchDelayDuration':
    return {
      number: deviceNumber,
      delay: {
        day: actionDelay.day || 0,
        hour: actionDelay.hour || 0,
        minute: actionDelay.minute || 0,
        second: actionDelay.second || 0,
        value_after: getDelayValueAfterVirtualSwitch(stateDescription),
      },
      duration: {
        day: actionDuration.day || 0,
        hour: actionDuration.hour || 0,
        minute: actionDuration.minute || 0,
        second: actionDuration.second || 0,
        value_after: getDurationValueAfterVirtualSwitch(durationValueAfter),
      },
    }
  default:
    throw new Error('action unsupported')
  }
}

export default getActionDeviceStateInfo
