const convertGeofenceUsersToBackend = (users) => {
  const usersNumbers = users.map((user) => (user.id))
  const usersNames = users.map((user) => (user.preferredName))
  return {
    usersNumbers,
    usersNames,
  }
}

export default convertGeofenceUsersToBackend
