import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 472 472">
      <path fill="currentColor" d="M357.4 55.7c-4.6-5.3-9.5-10.3-14.8-15h0c-65.9-58.9-167-53.2-225.9 12.7 -22.5 25.2-36.5 56.9-39.9 90.5 -5.4 51.6 14.7 102.6 54 136.5 14.9 12.9 26.4 29.3 33.5 47.6h-0.3c-4.4 0-8 3.6-8 8s3.6 8 8 8h5.3c1.8 7.9 2.7 15.9 2.7 24v48c0 13.3 10.7 24 24 24h8c0 17.7 14.3 32 32 32 17.7 0 32-14.3 32-32h8c13.3 0 24-10.7 24-24v-48c0-8.1 0.9-16.1 2.6-24h5.4c4.4 0 8-3.6 8-8s-3.6-8-8-8h-0.5c6.9-18.1 18.2-34.2 32.8-46.9C407.3 223.6 415 122.7 357.4 55.7zM236 456c-8.8 0-16-7.2-16-16h32C252 448.8 244.9 456 236 456zM284 416c0 4.4-3.6 8-8 8h-80c-4.4 0-8-3.6-8-8v-8h96V416zM284 392h-96v-16h96V392zM284.3 360h-96.5c-0.4-5.4-1.1-10.7-2.2-16H286.4C285.4 349.3 284.7 354.6 284.3 360zM344.5 254.6c-4.5 5.1-9.3 10-14.5 14.4h0c-18.3 15.7-31.9 36.1-39.4 59H244v-56h8c57.4-0.1 103.9-46.6 104-104 0-4.4-3.6-8-8-8h-16c-35.8 0-69 18.5-88 48.8v-41.3c36.4-4.1 64-34.9 64-71.5 0-4.4-3.6-8-8-8 -27 0-51.7 15.2-64 39.2 -12.3-24-37-39.2-64-39.2 -4.4 0-8 3.6-8 8 0.1 36.6 27.6 67.4 64 71.5v41.3c-19-30.3-52.2-48.8-88-48.8h-16c-4.4 0-8 3.6-8 8 0.1 57.4 46.6 103.9 104 104h8v56h-46.7c-7.8-23.1-21.6-43.7-40-59.7 -59.9-52.3-66.1-143.3-13.7-203.2s143.3-66.1 203.2-13.7C390.6 103.7 396.8 194.7 344.5 254.6zM244.4 256c4.2-45.3 42.2-79.9 87.6-80h7.6c-4.2 45.3-42.2 79.9-87.6 80H244.4zM244.7 151.3c3.8-24 22.6-42.9 46.7-46.7C287.6 128.7 268.7 147.5 244.7 151.3zM227.4 151.3c-24-3.8-42.9-22.6-46.7-46.7C204.7 108.5 223.6 127.3 227.4 151.3zM227.7 256h-7.6c-45.5-0.1-83.4-34.7-87.6-80h7.6C185.5 176.1 223.5 210.7 227.7 256z"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EcoModeIcon')

export default icon
