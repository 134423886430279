import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M94.8467 37.5508L104.763 47.4672C106.053 48.7569 106.778 50.5065 106.778 52.3308C106.778 54.1551 106.053 55.9046 104.763 57.1943L99.7102 62.2472C98.42 63.5369 97.6951 65.2865 97.6951 67.1108C97.6951 68.9351 98.42 70.6846 99.7102 71.9743L104.743 77.0571C106.033 78.3468 106.758 80.0964 106.758 81.9207C106.758 83.7449 106.033 85.4945 104.743 86.7842L94.8467 96.6707" stroke="currentColor" strokeWidth="5"/>
        <path d="M76.6079 126.22L86.5244 116.304C87.8146 115.014 88.5395 113.264 88.5395 111.44C88.5395 109.616 87.8146 107.866 86.5244 106.576L81.4715 101.524C80.1812 100.234 79.4563 98.4843 79.4563 96.66C79.4563 94.8357 80.1812 93.0862 81.4715 91.7965L86.5244 86.7436C87.8146 85.4539 88.5395 83.7043 88.5395 81.88C88.5395 80.0557 87.8146 78.3062 86.5244 77.0165L81.4715 71.9735C80.1812 70.6838 79.4563 68.9343 79.4563 67.11C79.4563 65.2857 80.1812 63.5362 81.4715 62.2465L86.5244 57.1936C87.8146 55.9038 88.5395 54.1543 88.5395 52.33C88.5395 50.5057 87.8146 48.7562 86.5244 47.4665L81.4715 42.4136C80.1812 41.1238 79.4563 39.3743 79.4563 37.55C79.4563 35.7257 80.1812 33.9762 81.4715 32.6865L86.5244 27.6336C87.8146 26.3439 88.5395 24.5943 88.5395 22.77C88.5395 20.9457 87.8146 19.1962 86.5244 17.9065L76.6079 8" stroke="currentColor" strokeWidth="5"/>
        <path d="M73.1698 141L63.2534 131.083C61.9632 129.793 61.2383 128.044 61.2383 126.22C61.2383 124.395 61.9632 122.646 63.2534 121.356L68.3063 116.303C69.5965 115.013 70.3214 113.264 70.3214 111.44C70.3214 109.615 69.5965 107.866 68.3063 106.576L63.2534 101.533C61.9632 100.243 61.2383 98.4938 61.2383 96.6695C61.2383 94.8452 61.9632 93.0957 63.2534 91.8059L68.3063 86.753C69.5965 85.4633 70.3214 83.7138 70.3214 81.8895C70.3214 80.0652 69.5965 78.3157 68.3063 77.026L63.2534 71.9731C61.9632 70.6833 61.2383 68.9338 61.2383 67.1095C61.2383 65.2852 61.9632 63.5357 63.2534 62.246L68.3063 57.1931C69.5965 55.9033 70.3214 54.1538 70.3214 52.3295C70.3214 50.5052 69.5965 48.7557 68.3063 47.466L63.2534 42.4131C61.9632 41.1234 61.2383 39.3738 61.2383 37.5495C61.2383 35.7252 61.9632 33.9757 63.2534 32.686L73.1698 22.7695" stroke="currentColor" strokeWidth="5"/>
        <path d="M54.9814 52.332L45.0151 62.2485C43.7249 63.5382 43 65.2877 43 67.112C43 68.9363 43.7249 70.6859 45.0151 71.9756L50.0879 77.0584C51.3782 78.3481 52.1031 80.0976 52.1031 81.9219C52.1031 83.7462 51.3782 85.4957 50.0879 86.7855L45.0151 91.8085C43.7249 93.0982 43 94.8477 43 96.672C43 98.4963 43.7249 100.246 45.0151 101.536L54.9814 111.452" stroke="currentColor" strokeWidth="5"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwVibrationIcon')

export default icon
