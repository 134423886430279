import React, { useEffect, useState } from 'react'
import { LinearProgress } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import ZwaveStats from '@app/components/MyDevices/DeviceInfo/DeviceStats/ZwaveStats'
import TwrStats from '@app/components/MyDevices/DeviceInfo/DeviceStats/TwrStats'
import useStyles from './DeviceStats.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  isMobile: boolean,
  fetchingDeviceStats: boolean,
}

const DeviceStats: React.FC<Props> = (props) => {
  const {
    currentDevice,
    isMobile,
    fetchingDeviceStats,
  } = props
  const classes = useStyles()
  const [fetchingStats, setFetchingStats] = useState<boolean>(true)
  const [rerender, setRerender] = useState<boolean>(false)
  const PRELOAD = 1000

  useEffect(() => {
    const rerenderInterval = setInterval(() => setRerender(!rerender), 1000)
    return () => {
      clearInterval(rerenderInterval)
    }
  }, [rerender])

  useEffect(() => {
    setTimeout(() => setFetchingStats(false), PRELOAD)
  }, [fetchingDeviceStats, currentDevice])

  const renderDeviceStats = () => {
    if (currentDevice.device_type === DeviceTypes.zwaveDevice) {
      return <ZwaveStats currentDevice={currentDevice} />
    } if (currentDevice.device_type === DeviceTypes.twrDevice) {
      return <TwrStats currentDevice={currentDevice} />
    }
    return null
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      {fetchingStats || fetchingDeviceStats ? <LinearProgress className={classes.progress} /> : renderDeviceStats()}
    </Wrapper>
  )
}

export default DeviceStats
