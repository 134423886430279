const styles = (theme) => ({
  paper: {
    padding: 0,
    width: '100%',
    boxShadow: 'none',
    position: 'relative',
  },
  root: {
    border: 'none',
    padding: 0,
    margin: 0,
    width: '20%',
    minHeight: 60,
    '&:hover': {
      border: 'none',
    },
  },
  disabled: {
  },
  icon: {
    color: theme.footerButtons.activeColor,
    padding: 0,
    margin: 0,
  },
  buttonText: {
    fontSize: '0.5rem',
    textTransform: 'none',
    color: theme.footerButtons.activeColor,
    padding: 0,
    margin: 0,
  },
  active: {
    color: theme.footerButtons.selectedColor,
  },
  buttonInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 0,
    height: 45,
    padding: 0,
    margin: 0,
  },
  buttonWrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.main,
  },
  buttonWrapperDesktop: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
})

export default styles
