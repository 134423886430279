import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M84.176 63.8022C89.8782 59.0021 90.6096 50.4884 85.8095 44.7861C81.0095 39.0839 72.4957 38.3525 66.7935 43.1525C61.0912 47.9525 60.3598 56.4663 65.1599 62.1686C69.9599 67.8708 78.4737 68.6022 84.176 63.8022Z" stroke="currentColor" strokeWidth="3"/>
            <mask id="path-2-outside-1" maskUnits="userSpaceOnUse" x="45.2489" y="65.7344" width="61" height="80" fill="currentColor">
                <rect fill="white" x="45.2489" y="65.7344" width="61" height="80"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M72.9009 66.7344C71.5376 101.354 70.9274 122.305 71.0702 129.589C62.0859 129.771 54.8122 132.013 49.2489 136.317V141.001H101.704V136.317C96.1341 132.013 88.8603 129.771 79.8825 129.589C80.0318 122.312 79.4216 101.36 78.0519 66.7344"/>
            </mask>
            <path d="M71.0702 129.589L71.1511 133.588L75.1478 133.507L75.0695 129.51L71.0702 129.589ZM49.2489 136.317L46.8013 133.154L45.2489 134.355V136.317H49.2489ZM49.2489 141.001H45.2489V145.001H49.2489V141.001ZM101.704 141.001V145.001H105.704V141.001H101.704ZM101.704 136.317H105.704V134.353L104.15 133.152L101.704 136.317ZM79.8825 129.589L75.8834 129.507L75.8013 133.507L79.8015 133.588L79.8825 129.589ZM68.904 66.577C67.543 101.139 66.9253 122.236 67.071 129.667L75.0695 129.51C74.9296 122.375 75.5323 101.569 76.8978 66.8918L68.904 66.577ZM70.9893 125.59C61.3867 125.784 53.2113 128.195 46.8013 133.154L51.6965 139.481C56.413 135.832 62.7852 133.757 71.1511 133.588L70.9893 125.59ZM45.2489 136.317V141.001H53.2489V136.317H45.2489ZM49.2489 145.001H101.704V137.001H49.2489V145.001ZM105.704 141.001V136.317H97.7038V141.001H105.704ZM104.15 133.152C97.7345 128.195 89.5606 125.784 79.9635 125.59L79.8015 133.588C88.16 133.757 94.5336 135.832 99.2581 139.482L104.15 133.152ZM83.8817 129.671C84.0341 122.243 83.4162 101.144 82.0488 66.5763L74.055 66.8925C75.4271 101.577 76.0296 122.38 75.8834 129.507L83.8817 129.671Z" fill="currentColor" mask="url(#path-2-outside-1)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M94.4494 79.1993C93.5006 77.7442 91.9424 76.7986 90.2137 76.6286C83.7286 75.7133 79.7071 73.094 76.2504 67.534C76.125 67.2046 75.8458 66.958 75.5035 66.8744C75.1612 66.7907 74.7996 66.8808 74.5366 67.1153C71.6803 68.8161 69.1259 70.4389 66.8733 71.9839C65.336 73.0381 64.3455 74.7197 64.1687 76.5753C63.992 78.4309 64.6474 80.2693 65.958 81.5947C70.8851 86.5737 76.364 91.5722 82.3946 96.5901C91.2361 103.922 103.476 93.1042 97.3024 83.4253C96.4131 82.0166 95.4621 80.6079 94.4494 79.1993V79.1993Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M101.197 34.5124C99.7424 35.4613 98.7968 37.0194 98.6268 38.7482C97.7115 45.2332 95.0922 49.2547 89.5321 52.7115C89.2028 52.8368 88.9562 53.1161 88.8725 53.4584C88.7889 53.8007 88.879 54.1622 89.1134 54.4252C90.8142 57.2815 92.4371 59.836 93.9821 62.0885C95.0363 63.6258 96.7179 64.6164 98.5735 64.7931C100.429 64.9698 102.267 64.3145 103.593 63.0038C108.572 58.0767 113.57 52.5946 118.588 46.5575C125.901 37.7257 115.083 25.5054 105.404 31.6594C104.008 32.5487 102.606 33.4997 101.197 34.5124V34.5124Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M56.5129 27.7649C57.4534 29.2348 59.0128 30.1956 60.7487 30.3745C67.2337 31.2898 71.2552 33.9092 74.712 39.4692C74.8373 39.7985 75.1166 40.0452 75.4589 40.1288C75.8012 40.2124 76.1627 40.1223 76.4257 39.8879C79.282 38.1871 81.8365 36.5642 84.089 35.0192C85.6263 33.9651 86.6169 32.2834 86.7936 30.4278C86.9703 28.5723 86.315 26.7339 85.0043 25.4085C80.0772 20.397 74.5951 15.392 68.558 10.3935C59.7262 3.11002 47.5059 13.8795 53.6599 23.5584C54.5492 24.9476 55.5002 26.3498 56.5129 27.7649V27.7649Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M49.7649 72.4496C51.2348 71.5092 52.1956 69.9497 52.3745 68.2139C53.2898 61.7289 55.9092 57.7073 61.4692 54.2506C61.7985 54.1253 62.0452 53.846 62.1288 53.5037C62.2124 53.1614 62.1223 52.7999 61.8879 52.5368C60.1871 49.6805 58.5642 47.1261 57.0192 44.8736C55.9651 43.3363 54.2834 42.3457 52.4278 42.169C50.5723 41.9923 48.7339 42.6476 47.4085 43.9582C42.397 48.8853 37.392 54.3642 32.3935 60.3948C25.11 69.2363 35.8795 81.4859 45.5584 75.3027C46.9476 74.4133 48.3498 73.4623 49.7649 72.4496V72.4496Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwFanIcon')

export default icon
