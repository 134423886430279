import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.9062 44.1199L84.9079 44.1199C86.6575 44.1108 88.3739 43.6419 89.8852 42.7604C91.3965 41.8788 92.6495 40.6155 93.5187 39.097C94.3879 37.5786 94.8427 35.8583 94.8375 34.1087C94.8324 32.3591 94.3674 30.6416 93.4893 29.1283C92.6112 27.615 91.3507 26.3591 89.8343 25.4865C88.3178 24.6138 86.5986 24.1551 84.849 24.1563C83.0994 24.1574 81.3808 24.6184 79.8655 25.4931C78.3502 26.3678 77.0915 27.6254 76.2153 29.1398L76.2145 29.1413C75.3416 30.6619 74.8823 32.3847 74.8823 34.138C74.8823 35.8914 75.3416 37.6142 76.2145 39.1348L76.2145 39.1348L76.2162 39.1377C77.1015 40.6564 78.3702 41.916 79.8952 42.7904C81.4203 43.6647 83.1483 44.1232 84.9062 44.1199ZM88.023 28.7373C88.9708 29.2836 89.7583 30.0693 90.3069 31.0157C90.8497 31.9668 91.1353 33.0429 91.1353 34.138C91.1353 35.2303 90.8512 36.3037 90.3111 37.2529C89.4525 38.6197 88.0979 39.601 86.5313 39.9907C84.9615 40.3812 83.3014 40.1471 81.9007 39.3377C80.5001 38.5284 79.4682 37.207 79.0226 35.6519C78.5778 34.0997 78.7517 32.4357 79.5075 31.0091C80.0558 30.0656 80.8419 29.2823 81.7875 28.7373C82.7357 28.1908 83.8108 27.9031 84.9053 27.9031C85.9997 27.9031 87.0748 28.1908 88.023 28.7373Z" fill="currentColor" stroke="currentColor"/>
      <path d="M28.5239 38.4637L28.5255 38.4637C30.2751 38.4545 31.9916 37.9857 33.5029 37.1041C35.0142 36.2225 36.2672 34.9592 37.1364 33.4408C38.0056 31.9223 38.4604 30.2021 38.4552 28.4525C38.4501 26.7029 37.9851 24.9854 37.107 23.4721C36.2289 21.9588 34.9684 20.7029 33.452 19.8302C31.9355 18.9576 30.2163 18.4988 28.4667 18.5C26.7171 18.5012 24.9985 18.9622 23.4832 19.8369C21.9679 20.7115 20.7091 21.9691 19.833 23.4836L19.8322 23.485C18.9593 25.0057 18.5 26.7284 18.5 28.4818C18.5 30.2351 18.9593 31.9579 19.8322 33.4785L19.8322 33.4785L19.8339 33.4814C20.7192 35.0002 21.9878 36.2597 23.5129 37.1341C25.038 38.0085 26.7659 38.467 28.5239 38.4637ZM31.6407 23.0811C32.5884 23.6273 33.376 24.413 33.9245 25.3594C34.4674 26.3105 34.753 27.3867 34.753 28.4818C34.753 29.574 34.4689 30.6475 33.9288 31.5967C33.0702 32.9635 31.7156 33.9448 30.149 34.3344C28.5792 34.7249 26.9191 34.4908 25.5184 33.6815C24.1177 32.8721 23.0859 31.5507 22.6403 29.9956C22.1955 28.4435 22.3693 26.7794 23.1251 25.3529C23.6735 24.4094 24.4596 23.6261 25.4052 23.0811C26.3533 22.5345 27.4285 22.2469 28.5229 22.2469C29.6173 22.2469 30.6925 22.5345 31.6407 23.0811Z" fill="currentColor" stroke="currentColor"/>
      <path d="M61.5239 128.464L61.5255 128.464C63.2751 128.455 64.9916 127.986 66.5029 127.104C68.0142 126.223 69.2672 124.959 70.1364 123.441C71.0056 121.922 71.4604 120.202 71.4552 118.452C71.4501 116.703 70.9851 114.985 70.107 113.472C69.2289 111.959 67.9684 110.703 66.452 109.83C64.9355 108.958 63.2163 108.499 61.4667 108.5C59.7171 108.501 57.9985 108.962 56.4832 109.837C54.9679 110.712 53.7091 111.969 52.833 113.484L52.8322 113.485C51.9593 115.006 51.5 116.728 51.5 118.482C51.5 120.235 51.9593 121.958 52.8322 123.479L52.8322 123.479L52.8339 123.481C53.7192 125 54.9878 126.26 56.5129 127.134C58.038 128.008 59.7659 128.467 61.5239 128.464ZM64.6407 113.081C65.5884 113.627 66.376 114.413 66.9245 115.359C67.4674 116.311 67.753 117.387 67.753 118.482C67.753 119.574 67.4689 120.647 66.9288 121.597C66.0702 122.963 64.7156 123.945 63.149 124.334C61.5792 124.725 59.9191 124.491 58.5184 123.681C57.1177 122.872 56.0859 121.551 55.6403 119.996C55.1955 118.443 55.3693 116.779 56.1251 115.353C56.6735 114.409 57.4596 113.626 58.4052 113.081C59.3533 112.535 60.4285 112.247 61.5229 112.247C62.6173 112.247 63.6925 112.535 64.6407 113.081Z" fill="currentColor" stroke="currentColor"/>
      <path d="M42.2613 38.296L42.2613 38.296L42.2552 38.2972C41.268 38.4955 40.3612 38.9801 39.6479 39.6907C38.9346 40.4013 38.4465 41.3063 38.2444 42.2927L38.2443 42.2926L38.2421 42.3048C38.1393 42.879 38.1771 43.4696 38.3523 44.026C38.527 44.5804 38.8329 45.0844 39.244 45.4952L119.219 129.131C119.22 129.132 119.221 129.132 119.222 129.133C119.942 129.905 120.808 130.526 121.769 130.962C122.732 131.397 123.771 131.638 124.827 131.67L124.827 131.671L124.841 131.671C126.337 131.674 127.791 131.181 128.977 130.27C130.163 129.358 131.013 128.079 131.395 126.633L131.395 126.633L131.398 126.62C131.71 125.298 131.66 123.915 131.253 122.618C130.846 121.322 130.096 120.159 129.084 119.252L129.034 119.207L129.014 119.197L45.4557 39.2832C45.0444 38.8721 44.5392 38.5672 43.9837 38.395C43.4262 38.2222 42.8349 38.1882 42.2613 38.296ZM122.001 126.538L121.992 126.527L121.983 126.517L41.9365 42.9487C41.9399 42.9192 41.9532 42.8662 41.9893 42.7892C42.0736 42.6093 42.2211 42.426 42.3056 42.3359L42.3056 42.3359L42.3082 42.333C42.4636 42.1647 42.6657 42.0477 42.8876 41.9963L42.8888 41.9974L126.464 121.954L126.475 121.964L126.487 121.974C127.014 122.421 127.411 123.002 127.636 123.656C127.859 124.304 127.905 125.001 127.769 125.673C127.627 126.185 127.356 126.651 126.981 127.028C126.608 127.403 126.145 127.676 125.636 127.821C124.975 127.943 124.292 127.891 123.657 127.669C123.015 127.444 122.444 127.054 122.001 126.538Z" fill="currentColor" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BillardAndBall')

export default icon
