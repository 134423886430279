import { Role } from '@app/helpers/convertAppConfig'

export const tokenData = (response) => {
  const { data } = response
  const token = data.access_token
  const expiresIn = parseInt(data.expires_in, 10) * 1000
  const tokenType = data.token_type
  const refreshToken = data.refresh_token
  const createdAt = data.created_at
  const { scope } = data
  return {
    token,
    expiresIn,
    tokenType,
    refreshToken,
    createdAt,
    scope,
  }
}

export const userData = (response) => {
  const user = response.data.sphere_user
  const { email, avatar, id } = user
  const ids = { sphere: id }
  const firstName = user.first_name
  const lastName = user.last_name
  const mobile = user.phone_number
  const preferredName = user.preferred_name
  const role = Role.NoUnitDevice
  return {
    avatar,
    email,
    ids,
    firstName,
    lastName,
    mobile,
    preferredName,
    role,
  }
}
