import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.8999 112.163H8V37H49.8999V44.763H16.7399V68.9776H47.8949V76.6892H16.7399V104.348H49.8999V112.163ZM99.8199 112.163L74.3201 45.5342H73.9088C74.3886 50.8124 74.6285 57.0845 74.6285 64.3506V112.163H66.557V37H79.7182L103.521 99.0015H103.933L127.942 37H141V112.163H132.26V63.7337C132.26 58.1813 132.5 52.1491 132.98 45.637H132.569L106.863 112.163H99.8199Z" fill="currentColor"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwEnergyIcon')

export default icon
