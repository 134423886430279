import { createLogic } from 'redux-logic'
import { PATCH_ENABLE_WEBHOOK } from '@app/constants/scenes/scenesList'
import {
  changedEnableWebhookValue,
  changedTestConditionsEnableValue,
} from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/patchEnableWebhook'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { webhookEnabling } from '@app/lib/Scenes/webhookAction'

const patchEnableWebhook = createLogic({
  type: PATCH_ENABLE_WEBHOOK,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { scenes: { scenesList: { scenes } } } = getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = getState()
    let data
    let isWebhookEnabledNewValue
    let isTestConditionsEnabledNewValue
    const currentScene = scenes.find((scene) => scene.id === id)
    if (action.typeOfChange === webhookEnabling) {
      isWebhookEnabledNewValue = !currentScene.webhook_enabled
      data = { webhook_enabled: isWebhookEnabledNewValue }
    } else {
      isTestConditionsEnabledNewValue = !currentScene.webhook_test_conditions
      data = { webhook_test_conditions: isTestConditionsEnabledNewValue }
    }
    next({
      ...action,
      token,
      id,
      data,
      currentScene,
      isWebhookEnabledNewValue,
      isTestConditionsEnabledNewValue,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_ENABLE_WEBHOOK))
    Api.patchEnableWebhook(action.token, action.id, action.data)
      .then(() => {
        dispatch(apiRequestDone(PATCH_ENABLE_WEBHOOK))
        if (action.typeOfChange === webhookEnabling) {
          dispatch(changedEnableWebhookValue(action.isWebhookEnabledNewValue, action.id))
        } else {
          dispatch(changedTestConditionsEnableValue(action.isTestConditionsEnabledNewValue, action.id))
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchEnableWebhook failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchEnableWebhook
