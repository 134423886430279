import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  Lock,
  LockOpen,
  PerimeterArmedIcon,
  Area1ArmedIcon,
  Area2ArmedIcon,
} from 'icons'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { syncGuardPanelChannel, updateGuardPanelStatus } from '@app/actions/guardPanel'
import { Status } from '@app/lib/guardPanel/guardPanel'
import { States, defaultConfig } from '@app/helpers/convertAppConfig'
import { IAppConfig } from '@app/@types/appConfig'
import { AppState } from '@app/@types/store'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './GuardPanel.style'
import DotsLoading from '../Common/DotsLoading/DotsLoading'

interface Props {
  appConfig: IAppConfig,
  online?: boolean,
}

const GuardPanel: React.FC<Props> = (props) => {
  const {
    online = false,
    appConfig,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { status, inProgress } = useSelector((state: AppState) => state.guardPanel)
  const guardConfig = appConfig.menu ? appConfig.menu.guard_panel : defaultConfig.menu.guard_panel
  const disabled = guardConfig === States.Disabled
  const { arm_types } = useSelector((state: AppState) => state.unit)
  const inPanic = arm_types?.panic

  useEffect(() => {
    if (!disabled && online) {
      dispatch(syncGuardPanelChannel())
    }
  }, [disabled, online])

  return (
    <Wrapper className={classes.root}>
      <Button
        variant="contained"
        color="secondary"
        className={classNames(
          classes.btn,
          classes.btnArm,
          !inProgress && classes.armed,
        )}
        onClick={() => dispatch(updateGuardPanelStatus(Status.SIMPLE_ARMED))}
        disabled={(status === Status.SIMPLE_ARMED && !inPanic) || disabled}
      >
        <Typography className={classes.armText}>{I18n.t('buttons.arm_all')}</Typography>
        {(!inProgress && status === Status.SIMPLE_ARMED) && <Lock className={classes.armedIcon} />}
        {(!inProgress && status === Status.ARMED_PERIMETER) && <PerimeterArmedIcon className={classes.armedIcon} />}
        {(!inProgress && status === Status.ARMED_AREA_1) && <Area1ArmedIcon className={classes.armedIcon} />}
        {(!inProgress && status === Status.ARMED_AREA_2) && <Area2ArmedIcon className={classes.armedIcon} />}
        {inProgress && (
          <DotsLoading
            className={classes.armLoad}
            props={{
              width: 35, circleWidth: 9, circleHeight: 9, circleColor: '#fff',
            }}
          />
        )}
      </Button>
      { disabled && <Wrapper className={classes.disarmButtonCover} />}
      <Button
        variant="contained"
        color="primary"
        className={
          classNames(classes.btn,
            classes.btnDisarm,
            (status === Status.SIMPLE_DISARMED && !inProgress) && classes.disarmed)
        }
        onClick={() => dispatch(updateGuardPanelStatus(Status.SIMPLE_DISARMED))}
        // disabled={(status === Status.SIMPLE_DISARMED && !inPanic) || disabled}
      >
        <Typography className={classes.disarmText}>{I18n.t('buttons.disarm_all')}</Typography>
        {(!inProgress && status === Status.SIMPLE_DISARMED) && <LockOpen className={classes.disarmedIcon} />}
        {inProgress && (
          <DotsLoading
            className={classes.disarmLoad}
            props={{
              width: 35, circleWidth: 9, circleHeight: 9, circleColor: '#fff',
            }}
          />
        )}
      </Button>
    </Wrapper>
  )
}

export default GuardPanel
