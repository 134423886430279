import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import {
  FormControl, Select, ListItem, MenuItem, InputLabel, SelectChangeEvent,
} from '@mui/material'
import useStyles from '@app/components/ProgramOptions/ProgramOption/SelectString/SelectString.style'
import { IProgramOptions } from '@app/@types/programOptions'

type Props = {
  name: string,
  title: string,
  number: number,
  values: Array<string>,
  startValue: number,
  value: string,
  options: IProgramOptions,
  setOptions: Dispatch<SetStateAction<IProgramOptions>>,
}

const SelectString: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState<string>('')
  const {
    name, title, number, values, startValue, value, options, setOptions,
  } = props

  useEffect(() => {
    setCurrentValue(value)
  }, [])

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleSelectValue = (event: SelectChangeEvent) => {
    setOptions(options.map((rule) => (rule.number === number
      ? { ...rule, value: event.target.value } : rule)))
    setCurrentValue(event.target.value)
  }

  const createItems = (itemNumber: number, itemValues: Array<string>, itemStartValue: number) => itemValues.map((el, index) => (
    <MenuItem key={itemNumber + el} value={index + itemStartValue}>
      {`${I18n.t(`drop_down_menu.${el}`)}`}
    </MenuItem>
  ))

  return (
    <ListItem className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-label">{`${title}`}</InputLabel>
        <Select
          labelId="select-label"
          label={title}
          variant="outlined"
          onChange={handleSelectValue}
          value={currentValue}
          inputProps={{ name }}
        >
          {createItems(number, values, startValue)}
        </Select>
      </FormControl>
    </ListItem>
  )
}

export default SelectString
