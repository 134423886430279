import React, { useEffect, useState } from 'react'
import {
  Typography, Paper, LinearProgress, CircularProgress, Button,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IZwaveDetails, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { zwaveDetailsFields } from '@app/lib/MyDevices/zwaveDetailsFields'
import { fetchZwaveDeviceDetails, resetZwaveDeviceDetailsError } from '@app/actions/myDevices/zwaveDevices'
import { useDispatch } from '@app/store'
import useStyles from './ZwaveDetails.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceDetails: IZwaveDetails,
  error: string,
  isMobile: boolean,
}

const ZwaveDetails: React.FC<Props> = (props) => {
  const {
    currentDevice,
    currentDeviceDetails,
    error,
    isMobile,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isInfoFetching, setIsInfoFetching] = useState<boolean>(true)
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const [isFailed, setIsFailed] = useState<string>('')

  useEffect(() => {
    if (currentDeviceDetails) {
      setIsInfoFetching(false)
    } else {
      setIsInfoFetching(true)
    }
  }, [currentDeviceDetails])

  useEffect(() => {
    setIsFailed(error)
  }, [error])

  const retry = () => {
    setIsInfoFetching(true)
    dispatch(resetZwaveDeviceDetailsError())
    dispatch(fetchZwaveDeviceDetails(currentDevice.id))
  }

  return (
    <>
      {!isFailed ? (
        <Paper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          {isInfoFetching && !currentDeviceDetails ? (
            <Wrapper className={classes.preloader}>
              <Typography className={classes.preloaderTitle}>Getting device info</Typography>
              <LinearProgress className={classes.progress} />
            </Wrapper>
          ) : (
            <>
              <Wrapper className={classNames(classes.productDetailCard, isMobile && classes.productDetailCardMobile)}>
                <Wrapper className={classes.infoWrapper}>
                  <Wrapper className={classes.details}>
                    {zwaveDetailsFields.map((detail, index) => (
                      <Typography className={classes.detail} key={`detail-${index + 1}`}>
                        {`${I18n.t(`my_devices.${detail}`)}:`}
                      </Typography>
                    ))}
                  </Wrapper>
                  <Wrapper className={classes.values}>
                    {zwaveDetailsFields.map((detail, index) => {
                      if (currentDeviceDetails[detail]) {
                        return (
                          <Typography className={classes.detail} key={`detailValue-${index + 1}`}>
                            {currentDeviceDetails[detail]}
                          </Typography>
                        )
                      }
                      return (
                        <Typography className={classes.detail} key={`detailValue-${index + 1}`}>
                          {I18n.t('my_devices.not_available')}
                        </Typography>
                      )
                    })}
                  </Wrapper>
                </Wrapper>
                <Wrapper className={classNames(classes.imageWrapper, isMobile && classes.imageWrapperMobile)}>
                  {currentDeviceDetails.image && (
                    <img
                      alt="Device"
                      className={classes.image}
                      style={{ display: isImageLoaded ? 'block' : 'none' }}
                      src={currentDeviceDetails.image}
                      onLoad={() => setIsImageLoaded(true)}
                    />
                  )}
                  <div
                    style={{ display: isImageLoaded ? 'none' : 'flex' }}
                    className={classes.imagePreloader}
                  >
                    <CircularProgress />
                  </div>
                </Wrapper>
              </Wrapper>
              <Wrapper className={classes.productDescription}>
                <Typography className={classes.descriptionTitle}>{`${I18n.t('my_devices.description')}:`}</Typography>
                <Typography>{currentDeviceDetails.description}</Typography>
              </Wrapper>
            </>
          )}
        </Paper>
      ) : (
        <Wrapper className={classes.error}>
          <Typography className={classes.errorText}>{error}</Typography>
          <Button
            className={classes.retryButton}
            variant="contained"
            onClick={retry}
          >
            {I18n.t('buttons.try_again')}
          </Button>
        </Wrapper>
      )}
    </>
  )
}

export default ZwaveDetails
