import React from 'react'
import { Typography } from '@mui/material'
import getContrastColor from '@app/helpers/scenes/getContrastColor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IActionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './MezzoLightsColorDescription.style'

interface Props {
  action: IActionFromBackend,
}

const MezzoLightsColorDescription: React.FC<Props> = (props) => {
  const {
    action,
  } = props
  const classes = useStyles()
  const infoArray = action.description.split('ௐ')

  return (
    <Wrapper>
      <Typography
        className={classes.sceneInfoText}
      >
        {(infoArray[0].split('then'))[0]}
      </Typography>
      <span
        className={classes.colorBlock}
        // @ts-ignore
        style={{ backgroundColor: action.value_title, borderColor: getContrastColor(action.value_title) }}
      />
      {(infoArray.length === 2)
      && (
        <>
          <Typography
            className={classes.sceneInfoText}
          >
            {infoArray[1].split(')')[1]}
          </Typography>

        </>
      )}
    </Wrapper>
  )
}

export default MezzoLightsColorDescription
