import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import {
  filterMyStatusDevicesByName,
  setMyStatusPopupOpen,
  syncMyStatus,
  syncMyStatusPopup,
  setMyStatusPopupClosed,
} from '@app/actions/myStatus'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import MyStatusDeviceCardList from '@app/components/MyStatus/MyStatusDevices'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'

let enhance = MyStatusDeviceCardList

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { myStatus: { myStatusPopup: { isMyStatusDevicePopupOpen } } } = store.getState()
  return {
    isMobile,
    isTablet,
    isMyStatusDevicePopupOpen,
    filteredDevices: state.myStatus.myStatusDevices.filteredDevices,
    myTwrStatusItems: state.myStatus.myStatusDevices.twrDevices,
    myZwaveStatusItems: state.myStatus.myStatusDevices.zWaveDevices,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncMyStatus,
  tryAgain: null,
  notifications: syncNotifications.sync,
  syncMyStatus,
  setMyStatusPopupOpen,
  syncMyStatusPopup,
  filterMyStatusDevicesByName,
  setMyStatusPopupClosed,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MyStatusEnhanced = enhance

export default MyStatusEnhanced
