export const filterNames = [
  'system_outputs',
  'system',
  'zone_of_user',
  'arming_events',
  'scene',
  'all',
]

export const dateErrors = [
  'Invalid Date',
  'Date should not be after maximal date',
  'Date should not be before minimal date',
]
