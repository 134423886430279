import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { multichannelBinarySwitchValues } from '@app/lib/Scenes/centralSceneValues'
import {
  SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED,
  SELECT_MULTICHANNEL_SUB_DEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMultichannelSubDeviceValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSubDeviceStateMode,
    } = this.props
    if (isChangeSubDeviceStateMode) {
      navigate(SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED)
    } else {
      navigate(SELECT_MULTICHANNEL_SUB_DEVICE)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      saveConditionStateDescription,
      saveConditionDataValue,
      saveConditionDataOperand,
    } = this.props
    saveConditionStateDescription(state.description)
    saveConditionDataValue(state.value)
    saveConditionDataOperand(state.operand)
    navigate(SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            multichannelBinarySwitchValues.map((state) => (
              <CardBlock
                key={state.description}
                description={state.description}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultichannelSubDeviceValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  isChangeSubDeviceStateMode: PropTypes.bool.isRequired,
  saveConditionDataValue: PropTypes.func.isRequired,
  saveConditionDataOperand: PropTypes.func.isRequired,
}

SelectMultichannelSubDeviceValue.displayName = 'SelectMultichannelSubDeviceValueIOS'

export default SelectMultichannelSubDeviceValue
