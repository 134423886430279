import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03125 3.46875V0H7.0625V3.46875H9.03125ZM0 4.21875L0.00341079 4.22044C0.0622264 4.24956 0.0775504 4.25715 2.46875 6.28125L3.875 4.875L1.40625 2.76562L0 4.21875ZM15.8125 4.21875L13.625 6.28125L12.2188 4.875L14.4062 2.76562L15.8125 4.21875ZM12.6094 10.3906C12.3594 10.1094 12.0469 9.96875 11.6719 9.96875H10.0312V8H6V9.96875H4.35938C3.98438 9.96875 3.65625 10.1094 3.375 10.3906C3.125 10.6406 3 10.9531 3 11.3281V26.6562C3 27.0312 3.125 27.3438 3.375 27.5938C3.65625 27.8438 3.98438 27.9688 4.35938 27.9688H11.6719C12.0469 27.9688 12.3594 27.8438 12.6094 27.5938C12.8906 27.3438 13.0312 27.0312 13.0312 26.6562V11.3281C13.0312 10.9531 12.8906 10.6406 12.6094 10.3906Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'BatteryRestoredIcon')

export default icon
