import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/pills_events`

const fetchPillsRemindersEvents = (access_token, data) => Axios.get(ENDPOINT, {
  params: {
    date: data,
  },
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchPillsRemindersEvents,
}
