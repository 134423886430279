import * as type from '@app/constants/scenes/zWaveDevices/sensorNotifications'

export const syncSensorNotifications = () => ({
  type: type.SYNC_SENSOR_NOTIFICATIONS,
})

export const updateSensorNotifications = (sensorNotifications) => ({
  type: type.UPDATE_SENSOR_NOTIFICATIONS,
  sensorNotifications,
})
