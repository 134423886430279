import React, { useEffect, useState } from 'react'
import {
  Paper, List, Typography, Link, Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import Text from '@app/components/POFromConfig/ProgramOption/Text/Text'
import { useDispatch } from 'react-redux'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft } from 'icons'
import aboutData from '@app/lib/About/aboutData'
import ProgramOption from '@app/components/POFromConfig/ProgramOption/ProgramOption'
import {
  set, setCurrentOptions, setInitialOptions, setValidation,
} from '@app/actions/po/poConfig'
import { IPOConfigFromBackend, IPOFromBackend } from '@app/@types/po/poConfig'
import { checkIfArraysOrObjectsAreEqual } from '@app/helpers/checkIfArraysOrObjectsAreEqual'
import checkIfControlledInputs from '@app/helpers/programOptions/checkIfControlledInputs'
import useStyles from './About.style'

interface Props {
  appVersion: string | null,
  withUnitDevice: boolean,
  initialOptions: Array<IPOFromBackend>,
  currentOptions: Array<IPOFromBackend>,
  defaultOptions: Array<IPOFromBackend>,
  inProgress: boolean,
  items: Array<IPOConfigFromBackend>,
}

const About: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    appVersion,
    withUnitDevice,
    initialOptions,
    currentOptions,
    defaultOptions,
    inProgress,
    items,
  } = props
  // @ts-ignore
  const disabledIfNotActivatedMezzo = withUnitDevice !== 'activatedMezzo'
  const [programOptions, setProgramOptions] = useState(initialOptions)

  useEffect(() => {
    dispatch(setCurrentOptions(programOptions))
  }, [programOptions])

  useEffect(() => {
    dispatch(setCurrentOptions(initialOptions))
    setProgramOptions(initialOptions)
  }, [initialOptions, items])

  const submitProgramOptions = () => {
    dispatch(set(currentOptions))
    dispatch(setInitialOptions(currentOptions))
  }

  const resetProgramOptions = () => {
    setProgramOptions(initialOptions)
    dispatch(setCurrentOptions(initialOptions))
  }

  const resetDefaultOptions = () => {
    dispatch(set(defaultOptions))
    dispatch(setInitialOptions(defaultOptions))
  }

  const renderAppVersion = () => appVersion && (
    <Text
      title={I18n.t('app_info.app_version')}
      value={`v${appVersion}`}
    />
  )

  // @ts-ignore
  const onLinkClick = (link) => {
    if (link) {
      navigate(link)
    }
  }

  return (
    <Paper className={classes.root}>
      {inProgress && (
        <Wrapper className={classes.preloader}>
          <Typography className={classes.preloaderMessage}>
            {I18n.t('program_options.fetching_data')}
            ...
          </Typography>
        </Wrapper>
      )}
      <form className={classNames(inProgress && classes.blur)}>
        <List>
          { renderAppVersion() }
          {!inProgress && programOptions.map((programOption) => (
            <ProgramOption
              items={items}
              programOption={programOption}
              options={programOptions}
              setOptions={setProgramOptions}
              key={programOption.number}
              validationSetter={setValidation}
              inProgress={inProgress}
            />
          ))}
        </List>
        <List className={classes.linksContainer}>
          {aboutData.map((aboutCard) => {
            const disabled = disabledIfNotActivatedMezzo && aboutCard.mezzoDependent
            return (
              <Link
                key={aboutCard.name}
                component="a"
                href={aboutCard.href && aboutCard.href}
                onClick={() => {
                  onLinkClick(aboutCard.link)
                }}
                className={classNames(classes.link, disabled && classes.disabledLink)}
                target="_blank"
              >
                <Wrapper className={classes.textContainer}>
                  <Typography color="textPrimary">{I18n.t(`titles.${aboutCard.name}`)}</Typography>
                  <ChevronLeft className={classes.arrowIcon} />
                </Wrapper>
              </Link>
            )
          })}
        </List>
        {checkIfControlledInputs(items) && (
          <Wrapper className={classNames(
            classes.buttonsWrapper,
            (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
          )}
          >
            <Wrapper className={classNames(
              classes.buttonsSecondWrapper,
              (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
            && classes.webView,
            )}
            >
              <Button
                onClick={resetProgramOptions}
                disabled={checkIfArraysOrObjectsAreEqual(currentOptions, initialOptions)}
                variant="contained"
                className={classes.button}
              >
                {I18n.t('buttons.reset')}
              </Button>
              <Button
                disabled={checkIfArraysOrObjectsAreEqual(currentOptions, initialOptions)}
                variant="contained"
                className={classes.button}
                onClick={submitProgramOptions}
              >
                {I18n.t('buttons.submit')}
              </Button>
            </Wrapper>
            <Button
              disabled={checkIfArraysOrObjectsAreEqual(initialOptions, defaultOptions)}
              onClick={resetDefaultOptions}
              variant="outlined"
              className={classes.resetButton}
            >
              {I18n.t('buttons.reset_to_default')}
            </Button>
          </Wrapper>
        )}
      </form>
    </Paper>
  )
}

export default About
