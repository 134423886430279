export const conditionTimeTypes = [
  {
    title: 'time_condition_state_time',
    position: 'top',
    buttonName: 'choose',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'time',
  },
  {
    title: 'time_condition_state_day',
    position: 'top',
    buttonName: 'choose',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'day',
  },
  {
    title: 'time_condition_state_date',
    position: 'top',
    buttonName: 'choose',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'date',
  },
  {
    title: 'time_condition_state_when',
    position: 'top',
    buttonName: 'choose',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'when',
  },
]
