import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { syncZWaveDeviceCapabilities, syncZWaveDeviceStates } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { restoreActionInfo, saveActionType } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import SelectActionDeviceStateType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/DevicesActions/SelectActionDeviceStateType/SelectActionDeviceStateType'
import SelectActionDeviceStateTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/DevicesActions/SelectActionDeviceStateType/SelectActionDeviceStateType'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectActionDeviceStateTypeIOS) : withStyles(SelectActionDeviceStateType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditActionMode, isChangeActionStateMode, initialAction } = state.scenes.currentSceneCreating
  const { actions } = state.scenes.currentSceneConditionsActions
  const { capability_names: capabilities } = state.scenes.zWaveDevices.zWaveDeviceCapabilities
  return {
    isMobile,
    path,
    capabilities,
    isEditActionMode,
    isChangeActionStateMode,
    actions,
    initialAction,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncZWaveDeviceCapabilities,
  tryAgain: syncZWaveDeviceCapabilities,
  notifications: syncNotifications.sync,
  syncZWaveDeviceStates,
  saveActionType,
  restoreActionInfo,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectActionDeviceStateTypeEnhanced = enhance

export default withRouter(SelectActionDeviceStateTypeEnhanced)
