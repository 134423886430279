export const securityManagementDevicesConditions = [
  {
    title: 'device',
    description: 'device',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzo',
  },
  {
    title: 'mezzo_light',
    description: 'backlight_control_mezzo',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzoLight',
  },
  {
    title: 'timers',
    description: 'when_timer_active',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'timers',
  },
]

export const securityManagementDevicesConditionsNCentral = [
  {
    title: 'device',
    description: 'device',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzo',
  },
  {
    title: 'timers',
    description: 'when_timer_active',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'timers',
  },
]
