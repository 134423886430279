import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M87.3491 40.1762L87.3493 40.1763C90.6699 41.7195 93.661 43.814 96.2687 46.3938C96.7937 46.9175 97.4465 47.1445 98.0946 47.094C98.7159 47.0456 99.2641 46.7495 99.6595 46.3541C100.055 45.9588 100.351 45.41 100.397 44.7872C100.445 44.137 100.211 43.4864 99.6815 42.9671C94.5058 37.8217 87.8062 34.2793 80.5831 33.0633L80.5828 33.0632C73.446 31.864 66.1171 32.8143 59.533 35.8284C55.7209 37.5732 52.254 40.0324 49.2891 42.9682L49.289 42.9682L49.2816 42.9756C48.7606 43.5024 48.5374 44.1559 48.5894 44.8026C48.6392 45.422 48.9342 45.9693 49.3287 46.3653C49.7233 46.7612 50.2705 47.059 50.8923 47.108C51.5415 47.1591 52.1938 46.9307 52.7182 46.407C57.2937 41.8594 63.136 38.8557 69.4887 37.7807C75.4789 36.7755 81.8451 37.6169 87.3491 40.1762Z" fill="currentColor"/>
      <path d="M26.4238 31.7438C31.4732 26.7087 37.2622 22.47 43.6202 19.227C49.7649 16.0836 56.3931 13.9216 63.192 12.7695C69.8771 11.6316 76.7614 11.532 83.475 12.4139C90.1886 13.2957 96.8168 15.2017 102.961 18.0464C109.419 21.0334 115.393 25.016 120.613 29.8521C121.282 30.4637 121.936 31.1038 122.576 31.7438C123.87 33.0382 125.89 31.0327 124.581 29.7383C119.347 24.5182 113.316 20.0946 106.731 16.7379C100.359 13.4806 93.5169 11.1906 86.4762 10.0101C79.4924 8.82949 72.3663 8.67303 65.3398 9.59757C58.1995 10.5363 51.2583 12.5276 44.7012 15.5573C38.0303 18.6296 31.8572 22.7971 26.4665 27.7754C25.7695 28.4155 25.0868 29.0698 24.4183 29.7383C23.1239 31.0327 25.1294 33.0382 26.4238 31.7438Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M57 66H92C94.7614 66 97 68.2386 97 71V133C97 135.761 94.7614 138 92 138H57C54.2386 138 52 135.761 52 133V71C52 68.2386 54.2386 66 57 66ZM47 71C47 65.4772 51.4772 61 57 61H92C97.5228 61 102 65.4772 102 71V133C102 138.523 97.5228 143 92 143H57C51.4772 143 47 138.523 47 133V71Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M83 84.5C83 89.1944 79.1944 93 74.5 93C69.8056 93 66 89.1944 66 84.5C66 79.8056 69.8056 76 74.5 76C79.1944 76 83 79.8056 83 84.5ZM88 84.5C88 91.9558 81.9558 98 74.5 98C67.0442 98 61 91.9558 61 84.5C61 77.0442 67.0442 71 74.5 71C81.9558 71 88 77.0442 88 84.5Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Remote')

export default icon
