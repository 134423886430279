import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  subList: {
    padding: 0,
    paddingLeft: 16,
    margin: 0,
  },
}))

export default useStyles
