export const CHOOSE_CURRENT_MEMBER = '@@currentMember/choose'

export const SYNC_CURRENT_MEMBER = '@@currentMember/sync'

export const UPDATE_CURRENT_MEMBER = '@@currentMember/update'

export const SET_CURRENT_MEMBER = '@@currentMember/set'

export const DELETE_CURRENT_MEMBER = '@@currentMember/delete'

export const RESET_CURRENT_MEMBER = '@@currentMember/reset'
