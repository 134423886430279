const getMultilevelSensorMarks = (minValue, maxValue) => {
  if (minValue === undefined || !minValue === undefined) {
    throw new Error('sensor unsupported')
  }
  return [
    {
      value: minValue,
      label: minValue,
    },
    {
      value: maxValue,
      label: maxValue,
    },
  ]
}

export default getMultilevelSensorMarks
