import * as type from '@app/constants/myEnergy/ecoMode'

export const sync = () => ({
  type: type.SYNC_ECO_MODE_DEVICES,
})

export const updateEcoModeDevices = (devices) => ({
  type: type.UPDATE_ECO_MODE_DEVICES,
  devices,
})

export const selectCurrentEcoModeDeviceId = (id) => ({
  type: type.SELECT_CURRENT_ECO_MODE_DEVICE_ID,
  id,
})

export const clearCurrentEcoModeDeviceId = () => ({
  type: type.CLEAR_CURRENT_ECO_MODE_DEVICE_ID,
})

export const selectCurrentEcoModeDeviceStatus = (status) => ({
  type: type.SELECT_CURRENT_ECO_MODE_DEVICE_STATUS,
  status,
})

export const clearCurrentEcoModeDeviceStatus = () => ({
  type: type.CLEAR_CURRENT_ECO_MODE_DEVICE_STATUS,
})

export const patchCurrentEcoModeDevice = () => ({
  type: type.PATCH_CURRENT_ECO_MODE_DEVICE,
})

export const postEcoModeDevicesTurnOff = () => ({
  type: type.POST_ECO_MODE_DEVICES_TURN_OFF,
})

export const openTurnOffPopup = () => ({
  type: type.OPEN_TURN_OFF_POPUP,
})

export const closeTurnOffPopup = () => ({
  type: type.CLOSE_TURN_OFF_POPUP,
})
