import * as type from '@app/constants/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'

const initialState = {
  isChannelOpen: false,
  progress: false,
  error: '',
  isDownloadingErrorPopupOpen: false,
}

export default function cloudFirmwareUpdateChannel(state = initialState, action) {
  switch (action.type) {
  case type.OPEN_CLOUD_FIRMWARE_UPDATE_CHANNEL:
    return { ...state, isChannelOpen: true }
  case type.CLOSE_CLOUD_FIRMWARE_UPDATE_CHANNEL:
    return { ...state, isChannelOpen: false }

  case type.SYNC_SENT_CLOUD_FIRMWARE_UPDATE_CHANNEL:
    return { ...state, error: '', progress: true }
  case type.SYNC_DONE_CLOUD_FIRMWARE_UPDATE_CHANNEL:
    return { ...state, error: '', progress: false }
  case type.SYNC_FAILED_CLOUD_FIRMWARE_UPDATE_CHANNEL:
    return { ...state, error: action.error }

  case type.OPEN_DOWNLOADING_ERROR_POPUP:
    return { ...state, isDownloadingErrorPopupOpen: true }
  case type.CLOSE_DOWNLOADING_ERROR_POPUP:
    return { ...state, isDownloadingErrorPopupOpen: false }

  default:
    return state
  }
}
