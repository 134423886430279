import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  popupWrapper: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    background: 'rgba(0,0,0, 0.3)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  popup: {
    position: 'relative',
    background: theme.backgrounds.componentBackground,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    padding: '24px 50px',
    maxWidth: 300,
    width: '100%',
  },
  popupMobile: {
    padding: 20,
  },
  popupTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  field: {
    width: '100%',
    marginBottom: 4,
    borderRadius: 8,
    border: '1px solid rgba(189, 189, 189, 0.2)',
    alignSelf: 'center',
  },
  rangeValues: {
    marginBottom: 22,
    fontSize: '12px',
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
  okButton: {
    maxWidth: 120,
  },
  closeIconButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

export default useStyles
