import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 90 112" enableBackground="new 0 0 100 100"><g><path d="M83.817,23.708H16.183c-1.739,0-3.143,1.412-3.143,3.142v40.221c0,1.732,1.403,3.144,3.143,3.144h67.635   c1.731,0,3.143-1.411,3.143-3.144V26.85C86.96,25.12,85.549,23.708,83.817,23.708z M84.966,67.071c0,0.632-0.51,1.15-1.148,1.15   H16.183c-0.638,0-1.148-0.519-1.148-1.15V26.85c0-0.63,0.511-1.148,1.148-1.148h67.635c0.639,0,1.148,0.519,1.148,1.148V67.071z"/><path d="M99.003,74.306h-6.036c0.144-0.351,0.223-0.741,0.223-1.147V20.765c0-1.731-1.411-3.143-3.143-3.143H9.954   c-1.731,0-3.144,1.412-3.144,3.143v52.394c0,0.406,0.08,0.797,0.225,1.147H0.997C0.446,74.306,0,74.745,0,75.303   c0,4.036,6.42,7.076,14.947,7.076h70.107c8.525,0,14.945-3.04,14.945-7.076C100,74.745,99.555,74.306,99.003,74.306z    M85.055,80.385H14.947c-6.588,0-11.454-1.994-12.667-4.085h7.674h30.539c0.359,0.68,1.068,1.141,1.898,1.141h15.218   c0.829,0,1.539-0.461,1.897-1.141h30.54h7.673C96.508,78.391,91.643,80.385,85.055,80.385z M8.805,73.158V20.765   c0-0.63,0.511-1.148,1.149-1.148h80.093c0.638,0,1.148,0.519,1.148,1.148v52.394c0,0.631-0.511,1.147-1.148,1.147H9.954   C9.315,74.306,8.805,73.789,8.805,73.158z"/></g></svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Laptop')

export default icon
