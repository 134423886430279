import * as type from '@app/constants/programOptions'
import { IProgramOptions } from '@app/@types/programOptions'

export const sync = () => ({
  type: type.SYNC_PROGRAM_OPTIONS,
} as const)

export const set = (programOptions: IProgramOptions) => ({
  type: type.SET_PROGRAM_OPTIONS,
  programOptions,
} as const)

export const setInitialOptions = (programOptions: IProgramOptions) => ({
  type: type.SET_PROGRAM_INITIAL_OPTIONS,
  programOptions,
} as const)

export const setCurrentOptions = (programOptions: IProgramOptions) => ({
  type: type.SET_PROGRAM_CURRENT_OPTIONS,
  programOptions,
} as const)

export const setValidation = (validation: boolean) => ({
  type: type.SET_PROGRAM_OPTIONS_VALIDATION,
  validation,
} as const)
