import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveMultilevelSensorScales,
  saveMultilevelSensorType,
  saveMultilevelSensorTypeDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectMultilevelSensorType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorType/SelectMultilevelSensorType'
import SelectMultilevelSensorTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorType/SelectMultilevelSensorType'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectMultilevelSensorTypeIOS) : withStyles(SelectMultilevelSensorType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { multilevelSensorTypes } = state.scenes.zWaveDevices
  const { isEditConditionMode, isChangeConditionStateMode, isChangeSensorMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    sensors: multilevelSensorTypes,
    isEditConditionMode,
    isChangeConditionStateMode,
    isChangeSensorMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveMultilevelSensorType,
  saveMultilevelSensorTypeDescription,
  saveMultilevelSensorScales,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectMultilevelSensorTypeEnhanced = enhance

export default withRouter(SelectMultilevelSensorTypeEnhanced)
