import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  main: {
    padding: '5%',
  },
  title: {
    fontSize: '14px',
    marginBottom: 10,
  },
  description: {
    fontSize: '14px',
    marginBottom: 20,
  },
}))

export default useStyles
