import { drawerWidth } from '@app/lib/drawer'

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    height: '100%',
    position: 'relative',
    minHeight: '100vh',
    background: theme.backgrounds.mainBg,
  },
  homeContent: {
    width: '100%',
    flexGrow: 1,
    height: '100%',
    left: 0,
    top: 0,
    background: theme.dashboard.componentBackground,
  },
  login: {
    width: '100%',
    flexGrow: 1,
    height: '100vh',
    left: 0,
    top: 0,
    background: theme.backgrounds.loginBg,
  },
  appBarSpacer: {
    minHeight: 64,
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fabSpacer: theme.mixins.toolbar,
  webView: {
    background: theme.webView.background,
  },
})

export default styles
