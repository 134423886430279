import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_BILLING_PERIODS_STATS } from '@app/constants/myEnergy/billingPeriod'
import { updateBillingPeriodsStats } from '@app/actions/myEnergy/billingPeriodsStats'
import Api from '@app/api/myEnergy/fetchBillingPeriodsStats'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchBillingPeriodsStatsLogic = createLogic({
  type: SYNC_BILLING_PERIODS_STATS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_BILLING_PERIODS_STATS))
    Api.fetchBillingPeriodsStats(AccessToken)
      .then((response) => {
        const { data: { billing_periods } } = response
        dispatch(updateBillingPeriodsStats(billing_periods))
        dispatch(apiRequestDone(SYNC_BILLING_PERIODS_STATS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchBillingPeriodsStats failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchBillingPeriodsStatsLogic
