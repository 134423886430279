import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogContent, DialogTitle, LinearProgress,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './SyncLoading.style'

class SyncLoading extends React.PureComponent {
  render() {
    const {
      classes,
      open,
    } = this.props
    return (
      <Dialog
        open={open}
        className={classes.root}
      >
        <DialogTitle className={classes.title}>
          {I18n.t('sync_web.sync_in_progress')}
          {' '}
          ...
        </DialogTitle>
        <DialogContent>
          <LinearProgress className={classes.progress} />
        </DialogContent>
      </Dialog>
    )
  }
}

SyncLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SyncLoading)
