// import Axios from 'axios'
// import { API_URL } from '@app/constants/endpoints'
// TODO: implement proper database and API and then use it on the screen
// const ENDPOINT = `${API_URL}/api/notifications`

const fetchNotifications = () => new Promise((_resolve, reject) => reject())

export default {
  fetchNotifications,
}
