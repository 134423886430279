import * as type from '@app/constants/groups/groups'
import { IGroup, IGroups, IGroupWithoutDevices } from '@app/@types/groups'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

export const syncGroupsList = () => ({
  type: type.SYNC_GROUPS_LIST,
} as const)

export const setGroupsList = (data: { groups: IGroups }) => ({
  type: type.SET_GROUP_LIST,
  data,
} as const)

export const postNewGroup = (data: { name: string }) => ({
  type: type.POST_ADD_GROUP,
  data,
} as const)

export const setPostNewGroup = (group: IGroup) => ({
  type: type.SET_POST_ADD_GROUP,
  group,
} as const)

export const postAddGroupFailedPopup = () => ({
  type: type.POST_ADD_GROUP_FAILED_POPUP,
} as const)

export const groupAddDevice = (deviceId: number, groupId: number, zwave: boolean) => ({
  type: type.GROUPS_ADD_DEVICE,
  deviceId,
  groupId,
  zwave,
} as const)

export const groupDeleteDevice = (deviceId: number, zwave: boolean) => ({
  type: type.GROUPS_DELETE_DEVICE,
  deviceId,
  zwave,
} as const)

export const patchGroup = (data: IGroupWithoutDevices) => ({
  type: type.PATCH_GROUP,
  data,
} as const)

export const setPatchGroup = (group: IGroup) => ({
  type: type.SET_PATCH_GROUP,
  group,
} as const)

export const deleteGroup = (id: number) => ({
  type: type.DELETE_GROUP,
  id,
} as const)

export const setDeleteGroup = (group: IGroup) => ({
  type: type.SET_DELETE_GROUP,
  group,
} as const)

export const toggleAddPopup = () => ({
  type: type.TOGGLE_ADD_GROUP_POPUP,
} as const)

export const syncGroupDevices = () => ({
  type: type.SYNC_GROUPS_DEVICES,
} as const)

export const setGroupDevices = (devices: { zwave_devices: Array<IZwaveOrTwrDevice>, twr_devices: Array<IZwaveOrTwrDevice> }) => ({
  type: type.SET_GROUPS_DEVICES,
  devices,
} as const)

export const filterAddTwrDevices = (id: number) => ({
  type: type.FILTER_ADD_TWR_DEVICES,
  id,
} as const)

export const filterAddZwaveDevices = (id: number) => ({
  type: type.FILTER_ADD_ZWAVE_DEVICES,
  id,
} as const)
