import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
  },
  rootMobile: {
    padding: '1rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cardsGrid: {
    display: 'grid',
    gridGap: 20,
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr));',
  },
  cardsGridMobile: {
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  sync: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 200,
  },
  message: {
    marginBottom: 20,
  },
}))

export default useStyles
