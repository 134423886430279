import update from 'immutability-helper'
import * as type from '@app/constants/users/currentUser'

const initialState = {
  user: {
    ids: {
      sphere: null,
      unit: null,
    },
    firstName: null,
    lastName: null,
    middleName: null,
    role: null,
    email: null,
    avatar: null,
    mobile: null,
    preferredName: null,
    userCode: null,
    push_setting: {
      arming_events: true,
      pills_reminders: true,
      system_outputs: true,
      scene: true,
      pir_event: true,
      zone_sealed: true,
      zone_alarms: true,
      zone_tamper: true,
      other_zone: true,
      power_failure: true,
      battery_failure: true,
      report_failure: true,
      other_system: true,
    },
  },
}
export default function currentUser(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_CURRENT_USER:
    return update(state, {
      user: { $merge: action.user },
    })
  case type.SIGN_IN_CURRENT_USER:
    return update(state, {
      user: { $merge: action.user },
    })
  case type.UPDATE_CURRENT_USER_ROLE:
    return update(state, {
      user: { role: { $set: action.role } },
    })
  case type.RESET_CURRENT_USER:
    return initialState

  default:
    return state
  }
}
