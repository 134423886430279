import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { START_ZWAVE_REINTERVIEW } from '@app/constants/myDevices/zwaveDevices'
import { syncZwaveChannel } from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import { setNewZwaveDevice } from '@app/actions/myDevices/zwaveDevices'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/zwaveDevices'

const postNewZwaveDeviceLogic = createLogic({
  type: START_ZWAVE_REINTERVIEW,
  latest: true,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(START_ZWAVE_REINTERVIEW))
    try {
      const deviceData = await Api.postReinterviewZwaveDevice(action.accessToken, action.id)
      dispatch(apiRequestDone(START_ZWAVE_REINTERVIEW))
      dispatch(setNewZwaveDevice(deviceData))
      dispatch(syncZwaveChannel())
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('START_ZWAVE_REINTERVIEW failed without error')
      }
    }
    done()
  },
})

export default postNewZwaveDeviceLogic
