import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.53341 0.666677C0.836442 2.32728 0.339472 4.24243 0.0970481 6.24243C-0.00598219 7.06668 -0.00598219 9.72122 0.0970481 10.5455C0.278866 12.0303 0.636442 13.6546 1.06068 14.8788C1.37584 15.7939 1.80614 16.7879 1.88493 16.7818C1.92735 16.7818 2.11523 16.703 2.30311 16.6182L2.65462 16.4546L2.50311 16.1273C1.92735 14.897 1.31523 12.8 1.08493 11.2667C0.557654 7.80001 1.01826 4.11516 2.37584 0.963647L2.64856 0.333344L2.30311 0.169707C2.11523 0.0848587 1.92735 0.00607081 1.88493 0.00607081C1.84856 1.02012e-05 1.69099 0.30304 1.53341 0.666677Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.7455 0.145432C37.5637 0.22422 37.4061 0.315129 37.4 0.345432C37.3879 0.375735 37.503 0.690887 37.6485 1.0424C38.3212 2.65452 38.7879 4.45452 39.0243 6.39392C39.1212 7.1818 39.1273 9.61816 39.0303 10.3939C38.7818 12.3454 38.3212 14.1333 37.6485 15.7454C37.503 16.0969 37.3818 16.406 37.3818 16.4363C37.3818 16.4969 38.0485 16.8 38.1273 16.7697C38.2121 16.7454 38.5818 15.8666 38.9031 14.9212C40.3637 10.6424 40.3576 6.09089 38.897 1.85452C38.5697 0.909069 38.2061 0.0424018 38.1273 0.0181594C38.1031 0.0120988 37.9273 0.0666443 37.7455 0.145432Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5453 2.10307C3.76348 4.07277 3.4059 5.79398 3.33923 7.87882C3.27863 9.82428 3.52711 11.6788 4.08469 13.4243C4.35742 14.2606 4.84833 15.4546 4.92712 15.4546C5.09075 15.4546 5.81803 15.0849 5.78773 15.0121C5.41803 14.1637 5.29682 13.8425 5.09075 13.2122C4.56348 11.5758 4.33318 10.1273 4.33318 8.39397C4.33318 6.25458 4.76348 4.14549 5.56954 2.27883C5.70288 1.9758 5.78773 1.71519 5.76348 1.69095C5.70288 1.6364 4.98166 1.33337 4.90894 1.33337C4.87863 1.33337 4.715 1.67883 4.5453 2.10307Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.7154 1.46673C34.5639 1.53339 34.382 1.61218 34.3033 1.64248C34.1699 1.69703 34.1699 1.69703 34.2669 1.92127C35.3396 4.36976 35.8123 6.96975 35.6366 9.394C35.4911 11.3395 35.0608 13.0607 34.2669 14.8667L34.1699 15.091L34.5154 15.2425C35.1335 15.5213 35.0972 15.5334 35.3517 14.9273C35.879 13.6788 36.279 12.2304 36.5154 10.7273C36.6729 9.73339 36.6729 7.0546 36.5154 6.06066C36.279 4.54551 35.9214 3.2546 35.382 1.95157C35.2426 1.61218 35.0911 1.33339 35.0548 1.33945C35.0184 1.33945 34.8669 1.40006 34.7154 1.46673Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56376 3.07274C7.36376 3.55759 7.01831 4.52729 7.01831 4.6182C7.01831 4.64244 7.30316 4.66668 7.64255 4.66668H8.27285L8.35164 4.40608C8.39406 4.26668 8.53346 3.90911 8.66073 3.61214C8.788 3.30911 8.87891 3.05456 8.86679 3.03638C8.81224 2.98184 7.90922 2.60608 7.83043 2.60608C7.788 2.60608 7.66679 2.8182 7.56376 3.07274Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.5573 2.83031L31.0967 3.04243L31.3876 3.7697C31.5512 4.16364 31.6846 4.53334 31.6846 4.58183C31.6846 4.64849 31.8058 4.66667 32.3209 4.66667C32.83 4.66667 32.9573 4.64849 32.9573 4.58183C32.9573 4.46061 32.6118 3.50304 32.3936 3.01213C32.2785 2.74546 32.1815 2.60607 32.1149 2.61213C32.0603 2.61213 31.8118 2.71516 31.5573 2.83031Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8424 4.16973C10.7818 4.29094 10.709 4.45458 10.6909 4.52731L10.6484 4.6667H11.4424C12.1454 4.6667 12.2363 4.65458 12.2181 4.56973C12.206 4.48488 11.6484 4.20609 11.0666 3.98185C10.9818 3.95155 10.9333 4.00003 10.8424 4.16973Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.3819 4.21214C28.0486 4.3576 27.7759 4.50911 27.7759 4.5576C27.7759 4.6182 27.9577 4.64244 28.5516 4.65457C28.9759 4.66063 29.3213 4.6485 29.3213 4.62426C29.3213 4.53941 29.0547 3.93942 29.0244 3.94548C29.0062 3.94548 28.7153 4.06669 28.3819 4.21214Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.32745 8.31513C6.51533 9.99392 5.8426 11.4 5.82442 11.4363C5.80018 11.4969 5.97594 11.5151 6.57594 11.5151H7.35169L8.47291 9.18179L9.59412 6.84846H10.3214L11.0487 6.85452L11.8365 8.24846L12.6244 9.63634L13.0426 9.62422L13.4608 9.60604L14.485 7.49089C15.0426 6.32725 15.5032 5.35149 15.5032 5.32725C15.5032 5.29695 15.1153 5.27271 14.6426 5.27877H13.7759L13.382 6.10301L12.982 6.92725L12.5093 6.10301L12.0426 5.27271H10.4184H8.79412L7.32745 8.31513Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0424 7.4424L14 9.60604L16.9152 9.62423C20.2182 9.64241 20.1091 9.65453 21.0424 9.16362C21.3394 9.00604 22.0485 8.52725 22.6182 8.09695C23.8182 7.18786 24.3697 6.81816 24.7273 6.68483C24.9152 6.61816 25.303 6.5818 26.1212 6.56362L27.2606 6.52725L27.5333 5.95149C27.6849 5.63634 27.8061 5.35149 27.8061 5.32119C27.8061 5.28483 27.1818 5.27271 26.2182 5.28483C24.6546 5.30301 24.6182 5.30907 24.2424 5.46058C23.6909 5.69695 23.3212 5.93331 22.0485 6.87877C19.9939 8.39998 20.1152 8.35756 18.0364 8.36362C16.9697 8.36362 16.4727 8.34544 16.4727 8.29695C16.4727 8.26059 16.503 8.1818 16.5333 8.11513C16.5939 8.00604 16.6788 7.99998 18.097 7.99998H19.6L19.8242 7.54544C19.9455 7.29089 20.0485 7.07271 20.0485 7.06059C20.0485 7.0424 19.3818 7.03028 18.5636 7.03028H17.0788L17.1515 6.87877L17.2182 6.72725H18.7091H20.2L20.5515 6.03028C20.7394 5.64846 20.897 5.32119 20.897 5.30301C20.897 5.28483 19.8121 5.27271 18.4909 5.27271L16.0788 5.27877L15.0424 7.4424Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.4608 5.33938C29.8366 5.48483 28.8547 6.07271 27.5032 7.10907C27.085 7.43029 26.7396 7.67877 26.7275 7.66665C26.7153 7.65453 26.7759 7.50907 26.8608 7.33938C26.9456 7.16968 27.0184 6.99998 27.0184 6.96362C27.0184 6.91514 26.7396 6.90301 26.0002 6.9212C24.9941 6.93938 24.9759 6.94544 24.5881 7.13332C24.3759 7.24241 23.6608 7.73332 22.9941 8.23635C21.7153 9.19392 21.1396 9.55756 20.485 9.8121L20.079 9.96968H16.2002H12.3274L11.5759 8.64847L10.8305 7.33332H10.3638H9.89714L8.94563 9.3212C8.42441 10.4121 7.97593 11.3515 7.95169 11.406C7.91532 11.5091 7.97593 11.5151 8.67896 11.5151H9.4426L9.86078 10.6727L10.279 9.83635L10.7517 10.6788L11.2305 11.5212L15.7153 11.497C20.1093 11.4788 20.2062 11.4788 20.6244 11.3515C21.4669 11.0909 22.1638 10.7212 23.1214 10.0363L23.679 9.63635H24.6487C25.5153 9.63635 25.6669 9.61817 26.0911 9.48483C26.7335 9.28483 27.2729 8.9515 28.5638 7.96968C29.7456 7.07271 30.4123 6.69695 30.9881 6.60604C31.1881 6.56968 31.885 6.54544 32.5335 6.54544H33.7153L33.9457 5.94544C34.0669 5.61817 34.1699 5.33332 34.1699 5.31514C34.1699 5.25453 30.7214 5.27877 30.4608 5.33938Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.8304 6.96968C30.4001 7.04241 30.0364 7.26059 28.9576 8.09695C27.794 8.99392 27.3031 9.3212 26.697 9.60604C25.988 9.93938 25.6667 9.99998 24.497 9.99998H23.4607L23.1334 10.703C22.9516 11.0909 22.8001 11.4363 22.788 11.4606C22.7516 11.5515 25.5334 11.5212 26.0486 11.4303C27.091 11.2424 27.8546 10.8303 29.6243 9.49089C30.2425 9.03029 30.8546 8.59998 30.988 8.53938C31.188 8.44847 31.388 8.42423 32.1092 8.42423H32.994L33.2607 7.71513C33.4122 7.32726 33.5455 6.98786 33.5516 6.95756C33.5758 6.89695 31.2122 6.90301 30.8304 6.96968Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.01831 12.1697C7.01831 12.4182 7.73346 14.1818 7.83043 14.1818C7.90922 14.1818 8.81224 13.8061 8.86679 13.7515C8.87891 13.7333 8.788 13.4788 8.66073 13.1758C8.53346 12.8788 8.39406 12.5212 8.35164 12.3758L8.27285 12.1212H7.64255C7.30316 12.1212 7.01831 12.1455 7.01831 12.1697Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.7027 12.2242C31.6846 12.2849 31.5391 12.6545 31.3815 13.0424L31.0967 13.7455L31.5755 13.9636C31.8421 14.0849 32.0906 14.1818 32.1391 14.1818C32.2543 14.1818 32.9573 12.4909 32.9573 12.2061C32.9573 12.1394 32.8361 12.1212 32.3512 12.1212C31.824 12.1212 31.733 12.1333 31.7027 12.2242Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.8241 12.8969C11.6847 13.006 11.5635 13.2666 11.5635 13.4606C11.5635 13.5575 11.6059 13.5757 11.8059 13.5757C11.9998 13.5757 12.0483 13.5515 12.0483 13.4666C12.0483 13.2303 12.1514 13.2121 13.3513 13.2121C14.1695 13.2121 14.5332 13.2363 14.5816 13.2848C14.6241 13.3272 14.6544 13.5333 14.6544 13.8C14.6544 14.1697 14.6362 14.2484 14.5392 14.303C14.4665 14.3394 13.9271 14.3636 13.1998 14.3636C12.4423 14.3636 11.9332 14.3878 11.8544 14.4303C11.6423 14.5454 11.5635 14.8545 11.5635 15.5757V16.2424H13.321H15.0786V16.0303V15.8181H13.6423C12.8483 15.8181 12.1635 15.7939 12.1211 15.7636C12.0665 15.7333 12.0483 15.5878 12.0604 15.2666L12.0786 14.8181L13.4301 14.7878L14.7877 14.7575L14.9332 14.5878C15.0665 14.4303 15.0786 14.3636 15.0786 13.7818C15.0786 13.1575 15.0726 13.1394 14.9029 12.9636L14.7271 12.7878H13.3392C12.1211 12.7878 11.9392 12.806 11.8241 12.8969Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7395 12.9273C16.7577 13 17.0304 13.7697 17.3455 14.6364L17.9152 16.2121L18.1213 16.2303L18.3213 16.2485L18.8304 14.8485C19.1092 14.0848 19.3395 13.4545 19.3516 13.4545C19.3577 13.4545 19.588 14.0848 19.8728 14.8485L20.3819 16.2485L20.5819 16.2303L20.788 16.2121L21.3577 14.6364C22.091 12.6242 22.0546 12.7879 21.7698 12.7879H21.5334L21.1152 13.9515C20.8849 14.5939 20.6728 15.1879 20.6425 15.2727C20.5819 15.4364 20.5577 15.3939 19.8728 13.497L19.6122 12.7879H19.3516H19.091L18.8183 13.5273C18.6728 13.9394 18.4607 14.5273 18.3455 14.8303C18.2364 15.1394 18.1274 15.3576 18.1031 15.3212C18.0789 15.2848 17.8607 14.697 17.6122 14.0242L17.1698 12.7879H16.9334C16.7213 12.7879 16.7031 12.8 16.7395 12.9273Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5153 12.8545C22.2608 13.006 22.2305 13.1939 22.2305 14.7575V16.2424H22.4729H22.7153V15.6363V15.0303H23.988H25.2608V15.6363V16.2424H25.4729H25.685V14.6727V13.103L25.4971 12.9454L25.3093 12.7878H23.9699C23.1699 12.7878 22.582 12.8181 22.5153 12.8545ZM25.1881 13.2848C25.2366 13.3333 25.2609 13.5697 25.2609 13.9515V14.5454H23.9881H22.7154V13.9212C22.7154 13.5757 22.7336 13.2727 22.7578 13.2545C22.8306 13.1757 25.1093 13.206 25.1881 13.2848Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.9636 12.8606C25.9879 12.903 26.3455 13.3939 26.7576 13.9515L27.503 14.9697V15.606V16.2424H27.7152H27.9273V15.6181V14.9878L28.7152 13.9272C29.1515 13.3394 29.503 12.8424 29.503 12.8242C29.503 12.806 29.3879 12.7878 29.2485 12.7878L28.9879 12.7939L28.3576 13.6L27.7273 14.4121L27.0849 13.6L26.4424 12.7878H26.1818C25.9879 12.7878 25.9333 12.806 25.9636 12.8606Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Ness2WayIcon', "0 0 40 20", {fontSize: 50})

export default icon
