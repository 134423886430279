import React from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import { Optional } from '@app/@types/common'
import Wrapper from '../Wrapper/Wrapper'
import useStyles from './SmallErrorPopup.style'

interface Props {
  open: boolean,
  onClick: () => void,
  warningText?: Optional<string>,
  error?: Optional<string>,
  title: string,
  buttonName: string,
  disableButton?: boolean,
}

const SmallErrorPopup: React.FC<Props> = (props) => {
  const {
    open,
    title,
    warningText,
    error,
    buttonName,
    onClick,
    disableButton,
  } = props
  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t(`${title}`)}
      </DialogTitle>
      <DialogContent>
        {warningText && (
          <DialogContentText
            variant="body1"
            className={classes.text}
          >
            {I18n.t(`${warningText}`)}
          </DialogContentText>
        )}
        {error && (
          <DialogContentText
            variant="body1"
            className={classes.text}
          >
            {error}
          </DialogContentText>
        )}
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          className={classes.button}
          onClick={onClick}
          color="primary"
          disabled={disableButton}
        >
          {I18n.t(`${buttonName}`)}
        </Button>
      </Wrapper>
    </Dialog>
  )
}

SmallErrorPopup.defaultProps = {
  disableButton: false,
}

export default SmallErrorPopup
