import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_CONDITION_TIMER } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTimerValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_CONDITION_TIMER)
    }
  }

  selectValue=(value) => {
    const {
      saveConditionStateDescription,
      saveConditionType,
      isEditConditionMode,
      navigate,
    } = this.props
    saveConditionStateDescription(value)
    saveConditionType('TimerExpired')
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_condition_web.select_timer_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <CardBlock
            key="expired"
            description="expired"
            position="middle"
            buttonName={I18n.t('scene_web.add')}
            isCardDisabled={false}
            isButtonDisabled={false}
            onClick={() => {
              this.selectValue('expired')
            }}
          />
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimerValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
}

SelectTimerValue.defaultProps = {

}

SelectTimerValue.displayName = 'SelectTimerValue'

export default SelectTimerValue
