import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  buttonWrapper: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    margin: '20px 10px 20px 10px',
  },
  text: {
    maxWidth: 380,
    whiteSpace: 'pre-line',
  },
  email: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles
