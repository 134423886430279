import React from 'react'
import {
  List, ListItem, TextField, Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import { Refresh } from 'icons'
import MyStatusPopupEnhanced from '@app/containers/MyStatus/MyStatusPopup'
import styles from './MyStatusDevices.style'
import Wrapper from '../Common/Wrapper/Wrapper'
import PropTypes from '../PropTypes'
import TwrCard from './MyStatusDeviceCard/TwrCard'
import ZwaveCard from './MyStatusDeviceCard/ZwaveCard'

class MyStatusDevices extends React.PureComponent {
  componentWillUnmount() {
    const { setMyStatusPopupClosed } = this.props
    setMyStatusPopupClosed()
  }

  render() {
    const {
      classes,
      isMobile,
      isTablet,
      myTwrStatusItems,
      myZwaveStatusItems,
      syncMyStatus,
      setMyStatusPopupOpen,
      syncMyStatusPopup,
      filterMyStatusDevicesByName,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Wrapper className={classNames(classes.interfaceElements, isTablet && classes.interfaceElementsTablet)}>
          <TextField
            variant="standard"
            id="standard-search"
            label="Name"
            type="search"
            className={classNames(classes.searchField, isMobile && classes.searchFieldMobile)}
            margin="normal"
            onChange={(evt) => {
              store.dispatch(() => filterMyStatusDevicesByName(evt.target.value))
            }}
          />
          <Refresh
            className={classNames(
              classes.refreshIcon,
              isMobile && classes.refreshIconMobile,
              isTablet && classes.refreshIconTablet,
            )}
            onClick={syncMyStatus}
          />
        </Wrapper>
        <List className={classNames(classes.list, isMobile && classes.listMobile, isTablet && classes.listTablet)}>
          {myTwrStatusItems.length ? myTwrStatusItems.map((item, i) => (
            <ListItem
              className={classNames(
                classes.listItem,
                isMobile && classes.listItemMobile,
                isTablet && classes.listItemTablet,
              )}
              key={`device-${i + 2}`}
            >
              <TwrCard
                isMobile={isMobile}
                className={classes.deviceItem}
                deviceStats={item}
              />
            </ListItem>
          )) : null}
          {myZwaveStatusItems.length ? myZwaveStatusItems.map((item, i) => (
            <ListItem
              className={classNames(
                classes.listItem,
                isMobile && classes.listItemMobile,
                isTablet && classes.listItemTablet,
              )}
              key={`device-${i + 2}`}
            >
              <ZwaveCard
                isMobile={isMobile}
                className={classes.deviceItem}
                deviceStats={item}
                setMyStatusPopupOpen={setMyStatusPopupOpen}
                syncMyStatusPopup={syncMyStatusPopup}
              />
            </ListItem>
          )) : null}
          <MyStatusPopupEnhanced />
          {myZwaveStatusItems.length === 0 && myTwrStatusItems.length === 0
            && <Typography align="center" variant="h6" className={classes.message}>{I18n.t('my_status.no_devices_found')}</Typography>}
        </List>
      </Wrapper>
    )
  }
}

MyStatusDevices.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  syncMyStatus: PropTypes.func.isRequired,
  syncMyStatusPopup: PropTypes.func.isRequired,
  filterMyStatusDevicesByName: PropTypes.func.isRequired,
  setMyStatusPopupOpen: PropTypes.func.isRequired,
  setMyStatusPopupClosed: PropTypes.func.isRequired,
  myTwrStatusItems: PropTypes.arrayOf(PropTypes.myStatus),
  myZwaveStatusItems: PropTypes.arrayOf(PropTypes.myStatus),
}

MyStatusDevices.defaultProps = {
  myTwrStatusItems: [],
  myZwaveStatusItems: [],
}

MyStatusDevices.displayName = 'MyStatusDevices'

export default withStyles(styles)(MyStatusDevices)
