import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
  Button,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import {
  selectVirtualSwitchActionSelectedValue,
  selectVirtualSwitchActionSelectedDelay,
  selectVirtualSwitchActionSelectedDuration,
} from '@app/lib/Scenes/actions'
import {
  ADJUST_DURATION_VIRTUAL_SWITCH,
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_VIRTUAL_SWITCH_ACTION_VALUE,
  ADJUST_DELAY_VIRTUAL_SWITCH,
} from '@app/constants/routes'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import calculateVirtualSwitchActionType from '@app/helpers/scenes/calculateVirtualSwitchActionType'

class SelectVirtualSwitchActionState extends React.Component {
  onChooseValueClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_VIRTUAL_SWITCH_ACTION_VALUE)
  }

  onChooseDelayClick =() => {
    const {
      navigate,
    } = this.props
    navigate(ADJUST_DELAY_VIRTUAL_SWITCH)
  }

  onChooseDurationClick=() => {
    const {
      navigate,
      saveActionDurationValueAfter,
      saveInitialActionInfo,
      stateDescription,
    } = this.props
    navigate(ADJUST_DURATION_VIRTUAL_SWITCH)
    saveInitialActionInfo()
    if (stateDescription === 'toggle') {
      saveActionDurationValueAfter('toggle')
    } else {
      saveActionDurationValueAfter('none')
    }
  }

  clearDelay=() => {
    const {
      saveActionDelay,
      saveActionDelayDescription,
    } = this.props
    saveActionDelay({})
    saveActionDelayDescription('')
  }

  clearDuration=() => {
    const {
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
  }

  onAddStateClick = () => {
    const {
      navigate,
      isEditActionMode,
      saveActionType,
      stateDescription,
      saveActionDeviceStateInfo,
      actionDeviceNumber,
      actionDelay,
      durationValueAfter,
      actionDuration,
    } = this.props
    const actionType = calculateVirtualSwitchActionType(stateDescription, actionDelay, actionDuration)
    saveActionType(actionType)
    const actionDeviceStateInfo = getActionDeviceStateInfo(actionType, stateDescription,
      actionDeviceNumber, actionDelay, durationValueAfter, actionDuration, '', '', '', '')
    saveActionDeviceStateInfo(actionDeviceStateInfo)
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      stateDescription,
      delayDescription,
      durationDescription,
      durationValueAfter,
    } = this.props
    const durationFullDescription = `${durationDescription} then ${I18n.t(`scene_action_web.${durationValueAfter}`)}`
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={null}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <CardBlock
            key={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedValue.title}`)}
            title={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedValue.title}`)}
            description={I18n.t(`scene_action_web.${stateDescription}`)}
            position={selectVirtualSwitchActionSelectedValue.position}
            buttonName={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedValue.buttonName}`)}
            isCardDisabled={selectVirtualSwitchActionSelectedValue.isCardDisabled}
            isButtonDisabled={selectVirtualSwitchActionSelectedValue.isButtonDisabled}
            onClick={this.onChooseValueClick}
          />
          <CardBlock
            key={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDelay.title}`)}
            title={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDelay.title}`)}
            description={delayDescription || I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDelay.description}`)}
            position={selectVirtualSwitchActionSelectedDelay.position}
            buttonName={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDelay.buttonName}`)}
            isCardDisabled={selectVirtualSwitchActionSelectedValue.isCardDisabled}
            isButtonDisabled={selectVirtualSwitchActionSelectedValue.isButtonDisabled}
            onClick={this.onChooseDelayClick}
            hasClearButton={!!delayDescription}
            clearDescription={this.clearDelay}
          />
          <CardBlock
            key={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDuration.title}`)}
            title={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDuration.title}`)}
            description={durationDescription
              ? durationFullDescription
              : I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDuration.description}`)}
            position={selectVirtualSwitchActionSelectedDuration.position}
            buttonName={I18n.t(`scene_web.${selectVirtualSwitchActionSelectedDuration.buttonName}`)}
            isCardDisabled={selectVirtualSwitchActionSelectedValue.isCardDisabled}
            isButtonDisabled={selectVirtualSwitchActionSelectedValue.isButtonDisabled}
            onClick={this.onChooseDurationClick}
            hasClearButton={!!durationDescription}
            clearDescription={this.clearDuration}
          />
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectVirtualSwitchActionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  stateDescription: PropTypes.string.isRequired,
  saveActionType: PropTypes.func.isRequired,
  saveActionDeviceStateInfo: PropTypes.func.isRequired,
  actionDeviceNumber: PropTypes.number.isRequired,
  delayDescription: PropTypes.string.isRequired,
  actionDelay: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  actionDuration: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  durationDescription: PropTypes.string.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  saveInitialActionInfo: PropTypes.func.isRequired,
}

SelectVirtualSwitchActionState.displayName = 'SelectVirtualSwitchActionState'

export default SelectVirtualSwitchActionState
