import * as type from '@app/constants/scenes/zWaveDevices/subDevices'

type State = {
  subDevices: Array<{ number: number, name: string }>,
}
const initialState = {
  subDevices: [],
}

export default function subDevices(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.UPDATE_SUB_DEVICES: {
    return {
      ...state,
      subDevices: action.subDevices,
    }
  }

  default:
    return state
  }
}
