import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
    padding: '30px 16px',
  },
  item: {
    minHeight: 70,
    display: 'flex',
    borderBottom: '1px solid rgba(50, 50, 50, 1)',
    justifyContent: 'space-between',
  },
  form: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    width: '100%',
  },
  button: {
    width: 'calc(100% - 28px)',
    display: 'block',
  },
  webView: {
    background: theme.webView.background,
  },
  iconWrapper: {
    padding: '35px 16px 16px 16px',
  },
}))

export default useStyles
