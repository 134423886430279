import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CURRENT_ROOM_STATS } from '@app/constants/myEnergy/roomsStats'
import { updateCurrentRoomStats } from '@app/actions/myEnergy/roomsStats'
import Api from '@app/api/myEnergy/fetchRoomStats'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchRoomStatsLogic = createLogic({
  type: SYNC_CURRENT_ROOM_STATS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const currentDate = new Date()
    const { roomsStats: { currentRoom: { id } } } = store.getState()
    dispatch(apiRequestSent(SYNC_CURRENT_ROOM_STATS))
    Api.fetchRoomStats(AccessToken, id, currentDate)
      .then((response) => {
        const { data } = response
        dispatch(updateCurrentRoomStats(data.room))
        dispatch(apiRequestDone(SYNC_CURRENT_ROOM_STATS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchRoomStats failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchRoomStatsLogic
