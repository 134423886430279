export const intervalInput = 'period'

export const intervalValues = [
  'last_week_period',
  'last_month_period',
  'last_6_month_period',
  'last_year_period']

export const defaultPeriod = 'last_week_period'

export const matchValues = {
  last_week_period: 'week',
  last_month_period: 'month',
  last_6_month_period: 'half_year',
  last_year_period: 'year',
}
