import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, Radio, RadioGroup, Typography,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import {
  Navigate, useNavigate, useLocation,
} from 'react-router-dom'
import classNames from 'classnames'
import { renderIcon } from '@app/helpers/myDevices/renderIcon'
import { controlComponents, IDeviceControlComponent } from '@app/lib/MyDevices/deviceControls'
import { patchZwaveDevice } from '@app/actions/myDevices/zwaveDevices'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import * as slider from '@app/lib/MyDevices/sliderValues'
import { IControl, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { useDispatch } from '@app/store'
import { removeFieldFromObject } from '@app/helpers/removeFieldFromObject'
import { setCurrentDevice } from '@app/actions/myDevices/myDevices'
import useStyles from './ControlOptions.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceControl: IControl,
  isMobile: boolean,
}

const ControlOptions: React.FC<Props> = (props) => {
  const { currentDevice, currentDeviceControl, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [icon, setIcon] = useState<number>(1)
  const [chosenControl, setChosenControl] = useState<string>('')
  const [isInverted, setIsInverted] = useState<boolean>(false)
  const [status, setStatus] = useState<Array<number>>(Object.keys(controlComponents).map(() => 0))

  useEffect(() => {
    if (currentDevice) {
      setIcon(currentDevice.icon)
      if (currentDeviceControl) {
        setChosenControl(currentDeviceControl.key)
      }
    }
  }, [])

  const goBackHandler = (): void => navigate(-1)

  const setNewStatus = (value: number, index: number): void => {
    setStatus((prevStatus) => {
      const newStatus = [...prevStatus]
      newStatus[index] = value
      return newStatus
    })
  }

  const getCheckedStatus = (currentStatus: boolean | string | number, control: any) => (
    currentStatus ? control.checked : control.unchecked
  )

  const changeChosenControlHandler = (evt: React.ChangeEvent<HTMLInputElement>) => setChosenControl(evt.target.value)

  const sliderPropsWrap = (control: IDeviceControlComponent, index: number) => (!control.checked
    ? (
      {
        value: status[index],
        isFullWidth: !isMobile,
        onOnButtonClick: () => setNewStatus(slider.MAX_SLIDER_UI_STUB, index),
        onOffButtonClick: () => setNewStatus(slider.MIN_SLIDER_VALUE, index),
      })
    : {
      id: index,
      multiSwitchValue: status[index],
      multiSwitchHandler: (value: number): void => {
        setStatus((prevStatus) => {
          const newStatus = [...prevStatus]
          newStatus[index] = value
          return newStatus
        })
      },
    })

  const submitControl = (): void => {
    dispatch(patchZwaveDevice({ widget: chosenControl, invert_status: isInverted, id: currentDevice.id }))
    dispatch(setCurrentDevice({ ...currentDevice, widget: chosenControl, invert_status: isInverted }))
    navigate(location.pathname.split('/').slice(0, -2).join('/'))
  }

  return (
    <>
      {currentDevice ? (
        <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          <Wrapper className={classes.navigationControls}>
            <IconButton
              size="small"
              onClick={goBackHandler}
              className={classes.goBackIcon}
            >
              <ScenesArrowBack />
            </IconButton>
            <Tooltip title={I18n.t('my_devices.zwave_control_tooltip')}>
              <div className={classes.invertContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classNames(classes.invertBtn, classes.default, !isInverted && classes.selected)}
                  onClick={() => setIsInverted(false)}
                >
                  {I18n.t('my_devices.default_widget')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classNames(classes.invertBtn, classes.invert, isInverted && classes.selected)}
                  onClick={() => setIsInverted(true)}
                >
                  {I18n.t('my_devices.inverted_widget')}
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
          <RadioGroup
            value={chosenControl}
            classes={{ root: classes.radioGroup }}
          >
            <Wrapper className={classes.wrapper}>
              {Object.keys(currentDevice.type === 'switch_binary'
                ? removeFieldFromObject(controlComponents, 'multiLevel')
                : controlComponents).map((control, index) => {
                const ControlComponent = controlComponents[control].component
                return (
                  <Wrapper
                    className={classNames(classes.card, isMobile && classes.cardMobile)}
                    key={`control_example_${index + 1}`}
                  >
                    <Wrapper className={classNames(classes.cardInfo, isMobile && classes.cardInfoMobile)}>
                      <Radio
                        className={classes.radio}
                        value={controlComponents[control].key}
                        onChange={changeChosenControlHandler}
                        color="primary"
                      />
                      <Wrapper className={classes.cardIconContainer}>
                        {renderIcon(icon, { className: classes.icon })}
                      </Wrapper>
                      <Wrapper className={isMobile ? classes.nameAndStatus : null}>
                        <Typography className={classes.cardName}>
                          {I18n.t('my_devices.name')}
                        </Typography>
                        <Typography className={classNames(
                          classes.cardStatus,
                          (controlComponents[control].checked && (status[index] ? classes.green : classes.red)),
                        )}
                        >
                          {controlComponents[control].checked
                            ? getCheckedStatus(status[index], controlComponents[control])
                            : `${status[index]}%`}
                        </Typography>
                      </Wrapper>
                    </Wrapper>
                    <Wrapper className={classes.cardControl}>
                      <ControlComponent
                        {...sliderPropsWrap(controlComponents[control], index)}
                        onChange={(_: any, value: number) => setNewStatus(value, index)}
                        {...control !== 'multiLevel' && {
                          locker: control === 'lockedUnlocked',
                          chevronsLeftRight: control === 'leftRight',
                          chevronsUpDown: control === 'upDown',
                          showcase: true,
                        }}

                      />
                    </Wrapper>
                  </Wrapper>
                )
              })}
            </Wrapper>
          </RadioGroup>
          <Button
            variant="outlined"
            color="primary"
            className={classes.saveButton}
            onClick={submitControl}
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      ) : <Navigate to={location.pathname.split('/').slice(0, -2).join('/')} />}
    </>
  )
}

export default ControlOptions
