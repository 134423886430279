export const SAVE_CONDITION_TYPE = '@@currentSceneCreating/saveConditionType'
export const SELECT_CONDITION_DEVICE_TYPE = '@@currentSceneCreating/selectConditionDeviceType'
export const SAVE_CONDITION_DESCRIPTION = '@@currentSceneCreating/saveConditionDescription'
export const SAVE_CONDITION_ID = '@@currentSceneCreating/saveConditionId'
export const SAVE_CONDITION_NUMBER = '@@currentSceneCreating/saveConditionNumber'
export const SAVE_CONDITION_OPERAND = '@@currentSceneCreating/saveConditionOperand'
export const SAVE_CONDITION_STATE_DESCRIPTION = '@@currentSceneCreating/saveConditionStateDescription'
export const CLEAR_CURRENT_CONDITION_STATE_DESCRIPTION = '@@currentSceneCreating/clearCurrentConditionStateDescription'

export const CLEAR_CURRENT_CONDITION_INFO = '@@currentSceneCreating/clearCurrentConditionInfo'
export const CLEAR_INITIAL_CONDITION_INFO = '@@currentSceneCreating/clearInitialConditionInfo'

export const SAVE_CONDITION_DEVICE_NUMBER = '@@currentSceneCreating/saveConditionDeviceNumber'
export const SAVE_CONDITION_DEVICE_NAME = '@@currentSceneCreating/saveConditionDeviceName'
export const SAVE_CONDITION_DEVICE_STATE_INFO = '@@currentSceneCreating/saveConditionDeviceStateInfo'
export const SAVE_SUB_DEVICES_AMOUNT = '@@currentSceneCreating/saveSubDevicesAmount'
export const SAVE_WALLMOTE_SETTINGS = '@@currentSceneCreating/saveWallmoteSettings'
export const SAVE_CONDITION_ZWAVE_CAPABILITY_ID = '@@currentSceneCreating/saveConditionZWaveCapabilityId'
export const SAVE_CONDITION_DEVICE_ID = '@@currentSceneCreating/saveConditionDeviceId'
export const SAVE_BINARY_SENSOR_TYPE = '@@currentSceneCreating/saveBinarySensorType'
export const SAVE_BINARY_SENSOR_TYPE_DESCRIPTION = '@@currentSceneCreating/saveBinarySensorTypeDescription'
export const SAVE_BINARY_SENSOR_VALUE_DESCRIPTION = '@@currentSceneCreating/saveBinarySensorValueDescription'

export const SAVE_MULTILEVEL_SENSOR_TYPE = '@@currentSceneCreating/saveMultilevelSensorType'
export const SAVE_MULTILEVEL_SENSOR_SCALES = '@@currentSceneCreating/saveMultilevelSensorScales'
export const SAVE_MULTILEVEL_SENSOR_TYPE_DESCRIPTION = '@@currentSceneCreating/saveMultilevelSensorTypeDescription'
export const SAVE_MULTILEVEL_SENSOR_VALUE_DESCRIPTION = '@@currentSceneCreating/saveMultilevelSensorValueDescription'

export const SAVE_SENSOR_NOTIFICATIONS_TYPE = '@@currentSceneCreating/saveSensorNotificationsType'
export const SAVE_SENSOR_NOTIFICATIONS_TYPE_DESCRIPTION = '@@currentSceneCreating/saveSensorNotificationsDescription'
export const SAVE_SENSOR_NOTIFICATIONS_EVENT_TYPE = '@@currentSceneCreating/saveSensorNotificationsEventType'
export const SAVE_SENSOR_NOTIFICATIONS_EVENT_DESCRIPTION = '@@currentSceneCreating/saveSensorNotificationsEventDescription'

export const SAVE_CONDITION_TIME_OPERAND = '@@currentSceneCreating/saveConditionTimeOperand'
export const SAVE_CONDITION_STATE_TIME_DATA = '@@currentSceneCreating/saveConditionStateTimeData'

export const SAVE_CONDITION_ANOTHER_SCENE_NUMBER = '@@currentSceneCreating/saveConditionAnotherSceneNumber'

export const SAVE_CONDITION_GEOFENCE_NUMBER = '@@currentSceneCreating/saveConditionGeofenceNumber'
export const SAVE_CONDITION_GEOFENCE_NAME = '@@currentSceneCreating/saveConditionGeofenceName'
export const SAVE_CONDITION_USERS_INFO = '@@currentSceneCreating/saveConditionUsersInfo'
export const SAVE_CONDITION_GEOFENCE_EXIT = '@@currentSceneCreating/saveConditionGeofenceExit'

export const SAVE_CONDITION_ROOM_NUMBER = '@@currentSceneCreating/saveConditionRoomNumber'
export const SAVE_CONDITION_ROOM_STATE = '@@currentSceneCreating/saveConditionRoomState'

export const SAVE_CONDITION_GROUP_NUMBER = '@@currentSceneCreating/saveConditionGroupNumber'
export const SAVE_CONDITION_GROUP_STATE = '@@currentSceneCreating/saveConditionGroupState'

export const SAVE_CONDITION_DATA_VALUE = '@@currentSceneCreating/saveConditionDataValue'
export const SAVE_CONDITION_DATA_NUMBER = '@@currentSceneCreating/saveConditionDataNumber'
export const SAVE_CONDITION_DATA_OPERAND = '@@currentSceneCreating/saveConditionDataOperand'

export const SAVE_REVIEW_SCENE_CONDITION_TYPES = '@@scurrentSceneCreating/aveReviewSceneConditionTypes'
export const ADD_NEW_CONDITION_TO_CHAIN = '@@currentSceneCreating/addNewConditionToChain'
export const ADD_NEW_ACTION_TO_CHAIN = '@@currentSceneCreating/addNewActionToChain'

export const POST_CONDITION = '@@currentSceneCreating/postCondition'

export const SAVE_EDITED_CONDITION = '@@currentSceneCreating/saveEditedCondition'

export const OPEN_SCENE_SAVE_DRAFT_POPUP = '@@currentSceneCreating/openSceneSaveDraftPopup'
export const CLOSE_SCENE_SAVE_DRAFT_POPUP = '@@currentSceneCreating/closeSceneSaveDraftPopup'

export const SAVE_ACTION_TYPE = '@@currentSceneCreating/saveActionType'
export const SAVE_ACTION_ID = '@@currentSceneCreating/saveActionId'
export const SAVE_ACTION_STATE_DESCRIPTION = '@@currentSceneCreating/saveActionStateDescription'
export const SAVE_ACTION_DESCRIPTION = '@@currentSceneCreating/saveActionDescription'

export const SAVE_ACTION_COLOR_TYPE = '@@currentSceneCreating/saveActionColorType'
export const SAVE_ACTION_DEVICE_NUMBER = '@@currentSceneCreating/saveActionDeviceNumber'
export const SAVE_ACTION_DEVICE_NAME = '@@currentSceneCreating/saveActionDeviceName'
export const SAVE_ACTION_DEVICE_STATE_INFO = '@@currentSceneCreating/saveActionDeviceStateInfo'
export const SAVE_ACTION_ZWAVE_CAPABILITY_ID = '@@currentSceneCreating/saveActionZWaveCapabilityId'
export const SAVE_ACTION_DEVICE_ID = '@@currentSceneCreating/saveActionDeviceId'
export const SAVE_ACTION_DEVICE_TYPE = '@@currentSceneCreating/saveActionDeviceType'
export const CLEAR_CURRENT_SCENE_INFO = '@@currentSceneCreating/clearCurrentSceneInfo'

export const CLEAR_CURRENT_ACTION_INFO = '@@currentSceneCreating/clearCurrentActionInfo'
export const CLEAR_INITIAL_ACTION_INFO = '@@currentSceneCreating/clearInitialActionInfo'

export const SAVE_EDITED_ACTION = '@@currentSceneCreating/saveEditedAction'

export const POST_ACTION = '@@currentSceneCreating/postAction'

export const ACTIVATE_EDIT_CONDITION_MODE = '@@currentSceneCreating/activateEditConditionMode'
export const DEACTIVATE_EDIT_CONDITION_MODE = '@@currentSceneCreating/deactivateEditConditionMode'

export const ACTIVATE_CREATE_CONDITION_MODE = '@@currentSceneCreating/activateCreateConditionMode'
export const DEACTIVATE_CREATE_CONDITION_MODE = '@@currentSceneCreating/deactivateCreateConditionMode'

export const ACTIVATE_CREATE_ACTION_MODE = '@@currentSceneCreating/activateCreateActionMode'
export const DEACTIVATE_CREATE_ACTION_MODE = '@@currentSceneCreating/deactivateCreateActionMode'

export const PATCH_CONDITION = '@@currentSceneCreating/patchCondition'

export const PATCH_CONDITION_OPERAND = '@@currentSceneCreating/patchConditionOperand'

export const ACTIVATE_EDIT_ACTION_MODE = '@@currentSceneCreating/activateEditActionMode'
export const DEACTIVATE_EDIT_ACTION_MODE = '@@currentSceneCreating/deactivateEditActionMode'

export const PATCH_ACTION = '@@currentSceneCreating/patchAction'

export const ACTIVATE_ADDITIONAL_CONDITION_MODE = '@@currentSceneCreating/activateAdditionalConditionMode'
export const DEACTIVATE_ADDITIONAL_CONDITION_MODE = '@@currentSceneCreating/deactivateAdditionalConditionMode'

export const ACTIVATE_ADDITIONAL_ACTION_MODE = '@@currentSceneCreating/activateAdditionalActionMode'
export const DEACTIVATE_ADDITIONAL_ACTION_MODE = '@@currentSceneCreating/deactivateAdditionalActionMode'

export const CHANGE_SCENE_MODE = '@@currentSceneCreating/changeSceneMode'

export const SAVE_SCREEN_NAME = '@@currentSceneCreating/saveScreenName'

export const POST_CONDITION_CHANGESET = '@@currentSceneCreating/postConditionChangeset'

export const POST_ACTION_CHANGESET = '@@currentSceneCreating/postActionChangeset'

export const ACTIVATE_CHANGE_CONDITION_MODE = '@@currentSceneCreating/activateChangeConditionMode'
export const DEACTIVATE_CHANGE_CONDITION_MODE = '@@currentSceneCreating/deactivateChangeConditionMode'
export const ACTIVATE_CHANGE_CONDITION_STATE_MODE = '@@currentSceneCreating/activateChangeConditionStateMode'
export const DEACTIVATE_CHANGE_CONDITION_STATE_MODE = '@@currentSceneCreating/deactivateChangeConditionStateMode'

export const ACTIVATE_CHANGE_ACTION_MODE = '@@currentSceneCreating/activateChangeActionMode'
export const DEACTIVATE_CHANGE_ACTION_MODE = '@@currentSceneCreating/deactivateChangeActionMode'
export const ACTIVATE_CHANGE_ACTION_STATE_MODE = '@@currentSceneCreating/activateChangeActionStateMode'
export const DEACTIVATE_CHANGE_ACTION_STATE_MODE = '@@currentSceneCreating/deactivateChangeActionStateMode'

export const ACTIVATE_CHANGE_SENSOR_MODE = '@@currentSceneCreating/activateChangeSensorMode'
export const DEACTIVATE_CHANGE_SENSOR_MODE = '@@currentSceneCreating/deactivateChangeSensorMode'
export const ACTIVATE_CHANGE_SENSOR_STATE_MODE = '@@currentSceneCreating/activateChangeSensorStateMode'
export const DEACTIVATE_CHANGE_SENSOR_STATE_MODE = '@@currentSceneCreating/deactivateChangeSensorStateMode'

export const ACTIVATE_CHANGE_SUB_DEVICE_MODE = '@@currentSceneCreating/activateChangeSubDeviceMode'
export const DEACTIVATE_CHANGE_SUB_DEVICE_MODE = '@@currentSceneCreating/deactivateChangeSubDeviceMode'
export const ACTIVATE_CHANGE_SUB_DEVICE_STATE_MODE = '@@currentSceneCreating/activateChangeSubDeviceStateMode'
export const DEACTIVATE_CHANGE_SUB_DEVICE_STATE_MODE = '@@currentSceneCreating/deactivateChangeSubDeviceStateMode'

export const SAVE_ACTION_ROOM_NUMBER = '@@currentSceneCreating/saveActionRoomNumber'
export const SAVE_ACTION_ROOM_STATE = '@@currentSceneCreating/saveActionRoomState'

export const SAVE_ACTION_GROUP_NUMBER = '@@currentSceneCreating/saveActionGroupNumber'
export const SAVE_ACTION_GROUP_STATE = '@@currentSceneCreating/saveActionGroupState'

export const SAVE_INITIAL_CONDITION_INFO = '@@currentSceneCreating/saveInitialConditionInfo'
export const RESTORE_CONDITION_INFO = '@@currentSceneCreating/restoreConditionInfo'

export const SAVE_INITIAL_ACTION_INFO = '@@currentSceneCreating/saveInitialActionInfo'
export const RESTORE_ACTION_INFO = '@@currentSceneCreating/restoreActionInfo'

export const RESET_CURRENT_SCENE_CREATING = '@@currentSceneCreating/reset'

export const SAVE_ACTION_DELAY = '@@currentSceneCreating/saveActionDelay'
export const SAVE_ACTION_DELAY_DESCRIPTION = '@@currentSceneCreating/saveActionDelayDescription'

export const SAVE_ACTION_DURATION = '@@currentSceneCreating/saveActionDuration'
export const SAVE_ACTION_DURATION_DESCRIPTION = '@@currentSceneCreating/saveActionDurationDescription'
export const SAVE_ACTION_DURATION_VALUE_AFTER = '@@currentSceneCreating/saveActionDurationValueAfter'
export const SAVE_DURATION_VALUE_AFTER_ACTION_COLOR_TYPE = '@@currentSceneCreating/saveDurationValueAfterActionColorType'

export const CLEAR_TRIGGERING_AND_OBSERVING_DEVICES = '@@currentSceneCreating/clearTriggeringAndObservingDevices'
export const SAVE_TRIGGERING_DEVICE_TYPE = '@@currentSceneCreating/saveTriggeringDeviceType'
export const SAVE_TRIGGERING_DEVICE_NUMBER = '@@currentSceneCreating/saveTriggeringDeviceMumber'
export const SAVE_TRIGGERING_DEVICE_NAME = '@@currentSceneCreating/saveTriggeringDeviceName'
export const SAVE_TRIGGERING_DEVICE_STATE = '@@currentSceneCreating/saveTriggeringDeviceState'
export const SAVE_OBSERVING_DEVICE_TYPE = '@@currentSceneCreating/saveObservingDeviceType'
export const SAVE_OBSERVING_DEVICE_NUMBER = '@@currentSceneCreating/saveObservingDeviceNumber'
export const SAVE_OBSERVING_DEVICE_NAME = '@@currentSceneCreating/saveObservingDeviceName'

export const SAVE_TEXT = '@@currentSceneCreating/saveText'
export const SAVE_SELECTED_USERS = '@@currentSceneCreating/saveSelectedUsers'

export const SAVE_REQUEST_URL = '@@currentSceneCreating/saveRequestUrl'
export const SAVE_REQUEST_TYPE = '@@currentSceneCreating/saveRequestType'
export const UPDATE_REQUEST_BODY = '@@currentSceneCreating/updateRequestBody'
export const PUSH_NEW_TO_REQUEST_BODY = '@@currentSceneCreating/pushNewToRequestBody'
export const SAVE_REQUEST_BODY = '@@currentSceneCreating/saveRequestBody'

export const UPDATE_REQUEST_HEADER = '@@currentSceneCreating/updateRequestHeader'
export const PUSH_NEW_TO_REQUEST_HEADER = '@@currentSceneCreating/pushNewToRequestHeader'
export const SAVE_REQUEST_HEADER = '@@currentSceneCreating/saveRequestHeader'

export const SAVE_CURRENT_ACTION_SUBDEVICE = '@@currentSceneCreating/saveCurrentActionSubdevice'
export const SAVE_CURRENT_ACTION_SUBDEVICES_LIST = '@@currentSceneCreating/saveCurrentActionSubdevicesList'
