import { createLogic } from 'redux-logic'
import Api from '@app/api/contacts'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CONTACTS } from '@app/constants/contacts/contacts'
import { setContacts, setIsContactsEmpty } from '@app/actions/contacts/contacts'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const fetchContacts = createLogic({
  type: SYNC_CONTACTS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_CONTACTS))
    Api.fetchContacts(AccessToken)
      .then((response) => {
        dispatch(setContacts(response.data))
        if (response.data.user_contacts.length === 0) {
          dispatch(setIsContactsEmpty(true))
        } else {
          dispatch(setIsContactsEmpty(false))
        }
        dispatch(apiRequestDone(SYNC_CONTACTS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchContacts failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchContacts
