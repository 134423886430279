import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { ITwrDeviceBackend, IZwaveDeviceBackend } from '@app/@types/scenes/scenesDevices'
import Device
  from '../Device/Device'
import useStyles from './DevicesList.style'

interface Props {
  devices: Array<ITwrDeviceBackend> | Array<IZwaveDeviceBackend>,
  deviceType: string,
}

const DevicesList: React.FC<Props> = (props) => {
  const {
    devices,
    deviceType,
  } = props

  const classes = useStyles()

  return (
    <List className={classes.root}>
      {devices.length
        ? devices.map((device) => (
          <ListItem className={classes.listItem} key={device.id}>
            <Device device={device} deviceType={deviceType} />
          </ListItem>
        )) : <Typography className={classes.empty} align="center">{I18n.t('my_energy.no_devices')}</Typography>}
    </List>
  )
}

export default DevicesList
