import * as type from '@app/constants/users/usersRequests'

export const sync = () => ({
  type: type.SYNC_USERS_REQUESTS,
})

export const update = (requests) => ({
  type: type.UPDATE_USERS_REQUESTS,
  requests,
})

export const set = () => ({
  type: type.SET_USERS_REQUESTS,
})
