import * as type from '@app/constants/scenes/counter'

export const incDays = () => ({
  type: type.INC_DAYS,
})

export const decDays = () => ({
  type: type.DEC_DAYS,
})

export const incHours = () => ({
  type: type.INC_HOURS,
})

export const decHours = () => ({
  type: type.DEC_HOURS,
})

export const incMinutes = () => ({
  type: type.INC_MINUTES,
})

export const decMinutes = () => ({
  type: type.DEC_MINUTES,
})

export const incSeconds = () => ({
  type: type.INC_SECONDS,
})

export const decSeconds = () => ({
  type: type.DEC_SECONDS,
})

export const maxDays = () => ({
  type: type.MAX_DAYS,
})

export const maxHours = () => ({
  type: type.MAX_HOURS,
})

export const maxMinutes = () => ({
  type: type.MAX_MINUTES,
})

export const maxSeconds = () => ({
  type: type.MAX_SECONDS,
})

export const resetDays = () => ({
  type: type.RESET_DAYS,
})

export const resetHours = () => ({
  type: type.RESET_HOURS,
})

export const resetMinutes = () => ({
  type: type.RESET_MINUTES,
})

export const resetSeconds = () => ({
  type: type.RESET_SECONDS,
})

export const resetCounter = () => ({
  type: type.RESET_COUNTER,
})
