import * as type from '@app/constants/events'
import { IEvent } from '@app/@types/events'

export const sync = () => ({
  type: type.SYNC_EVENTS,
} as const)

export const updateEvents = (events: Array<IEvent>, total: number, countPages: number) => ({
  type: type.UPDATE_EVENTS,
  events,
  total,
  countPages,
} as const)

export const setFilterByType = (filterByType: string) => ({
  type: type.SET_FILTER_BY_TYPE,
  filterByType,
} as const)

export const clearFilterByType = () => ({
  type: type.CLEAR_FILTER_BY_TYPE,
} as const)

export const setFilterFromDate = (filterFromDate: string) => ({
  type: type.SET_FILTER_FROM_DATE,
  filterFromDate,
} as const)

export const clearFilterFromDate = () => ({
  type: type.CLEAR_FILTER_FROM_DATE,
} as const)

export const setFilterToDate = (filterToDate: string) => ({
  type: type.SET_FILTER_TO_DATE,
  filterToDate,
} as const)

export const clearFilterToDate = () => ({
  type: type.CLEAR_FILTER_TO_DATE,
} as const)

export const setFilterByText = (filterByText: string) => ({
  type: type.SET_FILTER_BY_TEXT,
  filterByText,
} as const)

export const clearFilterByText = () => ({
  type: type.CLEAR_FILTER_BY_TEXT,
} as const)

export const saveCurrentPage = (currentPage: number) => ({
  type: type.SAVE_CURRENT_PAGE,
  currentPage,
} as const)

export const resetEventsReducer = () => ({
  type: type.RESET_EVENTS_REDUCER,
} as const)
