import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { ROOMS_DELETE_DEVICE } from '@app/constants/rooms/rooms'
import Api from '@app/api/rooms'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const deleteRoomDevice = createLogic({
  type: ROOMS_DELETE_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(ROOMS_DELETE_DEVICE))
    const result = action.zwave
      ? Api.roomDeleteDeviceZwave(AccessToken, action.deviceId)
      : Api.roomDeleteDeviceTwr(AccessToken, action.deviceId)
    result
      .then(() => {
        dispatch(apiRequestDone(ROOMS_DELETE_DEVICE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('roomDeleteDeviceZwave or roomDeleteDeviceTwr failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteRoomDevice
