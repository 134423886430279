export const MIN = -255
export const MAX = 255
export const DEFAULT_VALUE = 0
export const STEP = 1

export const marks = [
  {
    value: -255,
    label: '-255 mins',
  },
  {
    value: 0,
    label: '0',
  },
  {
    value: 255,
    label: '255 mins',
  },
]
