import {
  VALIDATION_REG_EXP_COST_OF_KWH,
  VALIDATION_REG_EXP_PAYMENT_DAY,
} from '@app/lib/MyEnergy/billingPopupValidationRegExps'

export const validate = (values) => {
  const errors = {}
  const cost_of_kwh = 'cost_of_kwh'
  const payment_day = 'payment_day'

  const requiredFields = [cost_of_kwh, payment_day]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })

  if (values[cost_of_kwh] && !VALIDATION_REG_EXP_COST_OF_KWH.test(values[cost_of_kwh])) {
    errors[cost_of_kwh] = I18n.t('validations.incorrect_cost')
  }
  if (values[payment_day] && !VALIDATION_REG_EXP_PAYMENT_DAY.test(values[payment_day])) {
    errors[payment_day] = I18n.t('validations.incorrect_payment_day')
  }
  return errors
}
