import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  current_time: PropTypes.string,
  chart: PropTypes.shape({
    kwh: PropTypes.shape({
      week: PropTypes.arrayOf(PropTypes.number),
      month: PropTypes.arrayOf(PropTypes.number),
      half_year: PropTypes.arrayOf(PropTypes.number),
      year: PropTypes.arrayOf(PropTypes.number),
    }),
    dollar: PropTypes.shape({
      week: PropTypes.arrayOf(PropTypes.number),
      month: PropTypes.arrayOf(PropTypes.number),
      half_year: PropTypes.arrayOf(PropTypes.number),
      year: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  total: PropTypes.shape({
    consumption: PropTypes.shape({
      week: PropTypes.number,
      month: PropTypes.number,
      half_year: PropTypes.number,
      year: PropTypes.number,
    }),
    comparison: PropTypes.shape({
      week: PropTypes.number,
      month: PropTypes.number,
      half_year: PropTypes.number,
      year: PropTypes.number,
    }),
    payment: PropTypes.shape({
      week: PropTypes.number,
      month: PropTypes.number,
      half_year: PropTypes.number,
      year: PropTypes.number,
    }),
  }),
})

export default propTypes
