import * as type from '@app/constants/shadowIn'

const initialState = {
  path: null,
}

const shadowIn = (state = initialState, action) => {
  switch (action.type) {
  case type.SHADOW_IN_USER:
    return { ...state, inProgress: true, path: null }
  case type.SAVE_SHADOW_IN_PATH:
    return { ...state, path: action.path }
  default:
    return state
  }
}

export default shadowIn
