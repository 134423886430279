import { createLogic } from 'redux-logic'
import Api from '@app/api/groups'
import handleErrors from '@app/helpers/handleErrors'
import { POST_ADD_GROUP } from '@app/constants/groups/groups'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setPostNewGroup, postAddGroupFailedPopup } from '@app/actions/groups/groups'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const postGroup = createLogic({
  type: POST_ADD_GROUP,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(POST_ADD_GROUP))
    Api.postGroup(AccessToken, action.data)
      .then((response) => {
        dispatch(setPostNewGroup(response.data.group))
        dispatch(apiRequestDone(POST_ADD_GROUP))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(postAddGroupFailedPopup())
        } else {
          throw new Error('postGroup failed without error')
        }
      })
      .then(() => done())
  },
})

export default postGroup
