import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  root: {
    width: '100%',
    padding: '30px 16px',
    overflowX: 'auto',
    minHeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid: {
    display: 'grid',
    justifyItems: 'center',
    width: '100%',
    maxWidth: 720,
    gridTemplateColumns: 'repeat(auto-fit, minmax(290px, 1fr))',
    gridGap: 20,
    margin: '0 auto',
  },
}))

export default useStyles
