import * as type from '@app/constants/mySecurity/mySecurity'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/mySecurity/mySecurity'

type IMySecurity = ReturnType<InferValueTypes<typeof actions>>

export interface IMySecurityState {
  command: string,
  workerInfo: string,
  isPanicPopupOpen: boolean,
}

const initialState = {
  command: '',
  workerInfo: '',
  isPanicPopupOpen: false,
}

const mySecurity = (state: IMySecurityState = initialState, action: IMySecurity) => {
  switch (action.type) {
  case type.SEND_KEYBOARD_COMMAND: {
    return {
      ...state,
      command: action.command,
    }
  }

  case type.OPEN_PANIC_POPUP: {
    return {
      ...state,
      isPanicPopupOpen: true,
    }
  }

  case type.CLOSE_PANIC_POPUP: {
    return {
      ...state,
      isPanicPopupOpen: false,
    }
  }

  case type.SAVE_WORKER_INFO: {
    return {
      ...state,
      workerInfo: action.workerInfo,
    }
  }

  case type.RESET_MY_SECURITY:
    return initialState

  default:
    return state
  }
}

export default mySecurity
