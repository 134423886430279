import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import sortDevicesGroups from '@app/helpers/scenes/sortDevicesGroups'
import { SYNC_ZWAVE_DEVICES_BY_ROOMS } from '@app/constants/scenes/zWaveDevices/zWaveDevicesByRooms'
import { updateZWaveDevicesByRooms } from '@app/actions/scenes/zWaveDevices/zWaveDevicesByRooms'
import fetchZWaveDevicesByRooms from '@app/api/scenes/ZWaveDevices/fetchZWaveDevicesByRooms'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchZWaveDevicesByRoomsLogic = createLogic({
  type: SYNC_ZWAVE_DEVICES_BY_ROOMS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ZWAVE_DEVICES_BY_ROOMS))
    fetchZWaveDevicesByRooms(token)
      .then((response) => {
        const { data: { rooms } } = response
        const sortedRooms = sortDevicesGroups(rooms, 'ZWaveDevices')
        dispatch(updateZWaveDevicesByRooms(sortedRooms))
        dispatch(apiRequestDone(SYNC_ZWAVE_DEVICES_BY_ROOMS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchZWaveDevicesByRooms failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchZWaveDevicesByRoomsLogic
