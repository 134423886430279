const convertUser = (backendUser) => {
  const convertedUser = {
    ids: {
      sphere: backendUser.id,
      unit: backendUser.unit_user_identifier,
    },
    firstName: backendUser.first_name,
    lastName: backendUser.last_name,
    middleName: backendUser.middle_name,
    role: backendUser.user_type,
    email: backendUser.email,
    avatar: backendUser.avatar,
    mobile: backendUser.phone_number,
    preferredName: backendUser.preferred_name,
  }
  return convertedUser
}

export default convertUser
