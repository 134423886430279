import { IProgramOption } from '@app/@types/programOptions'

const convertMezzoErrors = (option: IProgramOption): Array<string> | string => {
  if (!option) {
    return 'error'
  }
  if (option.value.length % 2 === 0) {
    return String(option.value).match(/.{1,2}/g) as Array<string>
  }
  return 'error'
}

export default convertMezzoErrors
