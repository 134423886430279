import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_TWR_SIREN_OPTIONS } from '@app/constants/myDevices/twrDevices'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'

const patchTwrSirenOptionsLogic = createLogic({
  type: PATCH_TWR_SIREN_OPTIONS,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { myDevices: { myDevices: { twrCurrentSirenOption } } } = getState()
    const data = { option: twrCurrentSirenOption }
    next({
      ...action,
      token,
      data,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_TWR_SIREN_OPTIONS))
    try {
      await Api.patchTwrSirenOptions(action.token, action.id, action.data)
      dispatch(apiRequestDone(PATCH_TWR_SIREN_OPTIONS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_TWR_SIREN_OPTIONS failed without error')
      }
    }
    done()
  },
})

export default patchTwrSirenOptionsLogic
