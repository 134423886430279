import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  deviceSettingsContainer: {
    marginTop: 60,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& > a': {
      color: theme.scenes.textColor,
      textDecoration: 'none',
    },
  },
  deviceSettingsIcon: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.cardBackground,
    transition: 'box-shadow 0.2s ease-out',
    '&:hover': {
      boxShadow: '0px 0px 50px rgba(255, 255, 255, 0.5), 0px 0px 15px rgba(255, 255, 255, 0.7)',
    },
    '& > svg': {
      fontSize: '25px !important',
    },
  },
  deviceSettingsItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deviceSettingsLabel: {
    marginTop: 5,
  },
}))

export default useStyles
