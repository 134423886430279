import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { syncContacts } from '@app/actions/contacts/contacts'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import ContactsList from '@app/components/Contacts/ContactsList/ContactsList'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'

let enhance = ContactsList

const mapStateToProps = (state) => ({
  userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  inProgress: state.apiRequest.inProgress,
  errorMessage: state.apiRequest.errorMessage,
  accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
  accessDenied: !isAccessTokenValid(state.secrets.accessToken),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncContacts,
  tryAgain: syncContacts,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ContatsListEnhanced = enhance

export default ContatsListEnhanced
