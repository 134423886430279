import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 60">
      <path fill="currentColor" d="M16 40.68V9a1 1 0 0 0-.3-.7.86.86 0 0 0-.7-.3l-4 .01a1 1 0 0 0-1 1v31.67a7 7 0 1 0 6 0Zm1.72 7.99a5.04 5.04 0 0 1-5.8 3.22 5 5 0 0 1-.59-9.6 1 1 0 0 0 .67-.94V10.01h2v31.34c0 .43.27.8.67.95a5 5 0 0 1 3.05 6.37Z"/>
      <path fill="currentColor" d="M23.96 40A13 13 0 0 0 20 36.04V30h3a1 1 0 0 0 0-2h-3v-2h3a1 1 0 0 0 0-2h-3v-2h3a1 1 0 0 0 0-2h-3v-2h3a1 1 0 0 0 0-2h-3v-2h3a1 1 0 0 0 0-2h-3V7A7 7 0 0 0 6 7v29.04A13 13 0 1 0 23.96 40ZM13 58a11 11 0 0 1-5.5-20.53 1 1 0 0 0 .5-.87V7a5 5 0 0 1 10 0v29.6c0 .36.2.69.5.87A11 11 0 0 1 13 58Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'AirTemperatureIcon', '0 0 80 80', {fontSize: 35})

export default icon
