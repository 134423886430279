import { TextEncoder } from 'text-encoding'
import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'

const getButtonDisableValue = (conditions: Array<IConditionFromBackend>,
  actions: Array<IActionFromBackend>, sceneName: string,
  maxNumberOfBytesSceneName: number) => {
  const bytes = ((new TextEncoder('utf-8').encode(sceneName)).length)
  const isBrokenConditionExist = conditions.map((condition) => condition.verified).includes(false)
  const isBrokenActionExist = actions.map((action) => action.verified).includes(false)
  return !sceneName.length
    || bytes > maxNumberOfBytesSceneName
    || !conditions.length
    || !actions.length
    || isBrokenConditionExist
    || isBrokenActionExist
}

export default getButtonDisableValue
