import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import convertMultilevelSensorDataToBackend from '@app/helpers/scenes/convertMultilevelSensorDataToBackend'
import getMultilevelSensorDescription from '@app/helpers/scenes/getMultilevelSensorDescription'
import { precision } from '@app/lib/Scenes/multilevelSensors'
import { SELECT_MULTILEVEL_SENSOR_STATE_SELECTED } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './UniversalSensor.style'

class UniversalSensor extends React.Component {
  state = {
    radioValue: 'greater than',
    sensorValue: '',
    error: '',
  }

  backendSensorOperand = (radioValue) => (radioValue === 'greater than' ? 'gt' : 'lt')

  onNextClick = () => {
    const {
      navigate,
      saveConditionDeviceStateInfo,
      saveMultilevelSensorValueDescription,
      conditionDeviceNumber,
      sensorType,
      scale,
    } = this.props
    const { sensorValue, radioValue } = this.state
    const sensorDescription = getMultilevelSensorDescription(radioValue, sensorValue, scale.short)
    saveMultilevelSensorValueDescription(sensorDescription)
    const backendSensorOperand = this.backendSensorOperand(radioValue)
    saveConditionDeviceStateInfo(convertMultilevelSensorDataToBackend(
      conditionDeviceNumber,
      sensorType,
      Number(sensorValue),
      scale.type,
      precision.none,
      backendSensorOperand,
    ))
    navigate(SELECT_MULTILEVEL_SENSOR_STATE_SELECTED)
  }

  handleChange = (event) => {
    this.setState({ radioValue: event.target.value })
  }

  handleInputChange = (event) => {
    const result = Number(event.target.value)
    if (!Number.isNaN(result)) {
      if (result < 0) {
        this.setState({ error: 'only positive numbers' })
      } else {
        this.setState({ sensorValue: result })
        this.setState({ error: '' })
      }
    } else {
      this.setState({ error: 'only numbers' })
    }
  }

  render() {
    const {
      classes,
      isMobile,
      scale,
    } = this.props
    const inputWidth = scale.short.length * 9 + 70
    const { radioValue, sensorValue, error } = this.state
    return (
      <Wrapper className={classes.wrapper}>
        <Wrapper className={classes.main}>
          <Wrapper>
            <Wrapper className={classes.cardBlock}>
              <Wrapper className={classNames(
                isMobile ? classes.cardContentMobile : classes.cardContent,
              )}
              >
                <Wrapper className={classes.cardTopWrapper}>
                  <Typography className={classes.title}>{I18n.t('scene_web.value')}</Typography>
                </Wrapper>
                <FormControl variant="standard" className={classes.formControl}>
                  <RadioGroup
                    row
                    className={classes.radioWrapper}
                    aria-label="radio"
                    name="radio1"
                    value={radioValue}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      classes={{
                        root: classes.radioButton,
                      }}
                      value="greater than"
                      control={<Radio color="primary" />}
                      label={I18n.t('multisensor_scene.value_greater_than')}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.radioButton,
                      }}
                      value="less than"
                      control={<Radio color="primary" />}
                      label={I18n.t('multisensor_scene.value_less_than')}
                    />
                  </RadioGroup>
                </FormControl>
              </Wrapper>
            </Wrapper>
            <Wrapper className={classNames(
              classes.cardBlock, classes.cardValueBlock,
            )}
            >
              <Wrapper className={classNames(
                isMobile ? classes.cardContentMobile : classes.cardContent,
              )}
              >
                <Wrapper>
                  <Typography className={classes.title}>{I18n.t('scene_web.value')}</Typography>
                  <TextField
                    variant="standard"
                    value={sensorValue}
                    style={{ width: inputWidth }}
                    onChange={this.handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography>{scale.short}</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText id="component-error-text" className={classes.errorText}>{error}</FormHelperText>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.onNextClick()
            }}
            disabled={sensorValue === ''}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

UniversalSensor.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveMultilevelSensorValueDescription: PropTypes.func.isRequired,
  sensorType: PropTypes.number.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  scale: PropTypes.shape({
    type: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    short: PropTypes.string.isRequired,
  }).isRequired,
}

UniversalSensor.defaultProps = {

}

export default withStyles(styles)(UniversalSensor)
