const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  buttonWrapper: {
    margin: '0 auto',
    display: 'flex',
    maxWidth: '90%',
    justifyContent: 'space-around',
  },
  button: {
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 10,
    width: '100%',
  },
  title: {
    marginBottom: 15,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    paddingBottom: 5,
  },
})

export default styles
