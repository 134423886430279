import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { shadowIn } from '@app/actions/shadowIn'
import getParamByName from '@app/helpers/getParamByName'
import AccessDenied from '@app/containers/Auth/AccessDenied'
import Redirect from '@app/containers/Auth/Redirect'

const withShadowAuth = (WrappedComponent) => {
  class ShadowAuthable extends React.Component {
    componentDidMount() {
      const { auth } = this.props
      const accessToken = getParamByName('access_token')
      if (accessToken) {
        const navigation = getParamByName('navigation')
        const redirectPath = getParamByName('redirect_path') || ''
        const postfix = navigation ? `?navigation=${navigation}` : ''
        const path = `/web/${redirectPath}${postfix}`
        auth(accessToken, path)
      }
    }

    render() {
      const { inProgress, error, path } = this.props
      if (error) {
        return <AccessDenied message={error} />
      }
      if (inProgress) {
        return <WrappedComponent {...this.props} />
      }
      return <Redirect to={path} />
    }
  }

  ShadowAuthable.propTypes = {
    auth: PropTypes.func.isRequired,
    inProgress: PropTypes.bool,
    path: PropTypes.string,
    error: PropTypes.string,
  }

  ShadowAuthable.defaultProps = {
    inProgress: true,
    path: null,
    error: null,
  }

  const mapStateToProps = (state) => {
    const { isMobile } = state.mobileDetector
    return {
      isMobile,
      inProgress: state.apiRequest.inProgress,
      errorMessage: state.apiRequest.errorMessage,
      path: state.shadowIn.path,
    }
  }

  const mapDispatchToProps = (dispatch) => bindActionCreators({
    auth: shadowIn,
  }, dispatch)

  let enhance = ShadowAuthable

  enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

  const ShadowEnhanced = enhance
  return ShadowEnhanced
}

export default withShadowAuth
