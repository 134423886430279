import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2331 2331"
      id="vector">
      <g
        id="group"
        transform="translate(221 454)">
        <path
          id="path"
          d="M 1 0 L 1888 0 L 1888 1425 L 1 1425 L 1 0 Z M 37 37 L 37 1388 L 39 1389 L 926 1389 L 926 37 L 37 37 Z M 963 37 L 963 1389 L 1851 1389 L 1852 1388 L 1852 37 L 963 37 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_1"
          d="M 82 82 L 881 82 L 881 1344 L 82 1344 L 82 82 Z M 120 118 L 118 120 L 118 1307 L 845 1307 L 845 120 L 844 118 L 120 118 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_2"
          d="M 1007 82 L 1807 82 L 1807 1344 L 1007 1344 L 1007 82 Z M 1044 118 L 1044 1307 L 1770 1307 L 1770 118 L 1044 118 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_3"
          d="M 535.5 604 L 537 604.5 L 537 657.5 L 537.5 658 L 699 658 L 699 768 L 537.5 768 L 537 768.5 L 537 820.5 L 534.5 821 L 348 712.5 L 535.5 604 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_4"
          d="M 1353.5 604 L 1541 713.5 L 1353.5 822 L 1353 768 L 1190 768 L 1190 658 L 1352.5 658 L 1353 657.5 L 1353.5 604 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_5"
          d="M 766 617 L 802.5 617 L 803 617.5 L 803 809 L 767.5 809 L 766 807.5 L 766 617 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_6"
          d="M 1086 617 L 1122.5 617 L 1123 617.5 L 1123 807.5 L 1121.5 809 L 1086 809 L 1086 617 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_7"
          d="M 0.5 0 L 1 1425 L 1888 1425 L 1888 0.5 L 1889 0.5 L 1889 1424.5 L 1887.5 1426 L 1.5 1426 L 0 1424.5 L 0.5 0 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_8"
          d="M 37.5 37 L 38 1388 L 926 1388.5 L 38.5 1389 L 37 1387.5 L 37.5 37 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_9"
          d="M 1851.5 37 L 1852 1387.5 L 1850.5 1389 L 963.5 1389 L 963.5 1388 L 1851 1388 L 1851.5 37 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_10"
          d="M 82.5 81 L 880.5 81 L 882 82.5 L 882 1343.5 L 880.5 1345 L 82.5 1345 L 81 1343.5 L 81 82.5 L 82.5 81 Z M 82 82 L 82 1344 L 881 1344 L 881 82 L 82 82 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_11"
          d="M 1007.5 81 L 1807 81.5 L 1007.5 82 L 1007.5 81 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_12"
          d="M 119.5 118 L 843.5 118 L 845 119.5 L 844.5 1307 L 844 119 L 119 119 L 119 1306.5 L 118 1306.5 L 118 119.5 L 119.5 118 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_13"
          d="M 1044.5 118 L 1770 118.5 L 1044.5 119 L 1044.5 118 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_14"
          d="M 803.5 617 L 804 808.5 L 803 808.5 L 803.5 617 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_15"
          d="M 1085.5 617 L 1086 808.5 L 1085 808.5 L 1085.5 617 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_16"
          d="M 348.5 711 L 348.5 714 Q 345.5 712.5 348.5 711 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_17"
          d="M 1540.5 712 L 1541.5 714 L 1540.5 712 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_18"
          d="M 1190.5 768 L 1353 768.5 L 1190.5 769 L 1190.5 768 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_19"
          d="M 1007.5 1344 L 1807 1344.5 L 1007.5 1345 L 1007.5 1344 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SlidingDoor')

export default icon
