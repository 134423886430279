import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { BackToLoginIcon } from 'icons'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './NavigationAndTitle.style'

class NavigationAndTitle extends React.Component {
  render() {
    const {
      classes,
      isMobile,
      title,
      onBackClick,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <IconButton
          className={classNames(
            !isMobile && classes.backButtonDesktop,
          )}
          onClick={onBackClick}
          size="large"
        >
          <BackToLoginIcon />
        </IconButton>
        <Typography
          className={classes.title}
        >
          {title}
        </Typography>
      </Wrapper>
    )
  }
}

NavigationAndTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default withStyles(styles)(NavigationAndTitle)
