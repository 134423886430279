import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
      <path fill="#929292" d="M7.797 5.87l5.085-4.082c.316-.254.316-.665 0-.918a.955.955 0 00-1.144 0L6.653 4.95 1.568.87a.955.955 0 00-1.144 0c-.316.253-.316.664 0 .918L5.51 5.869.424 9.951c-.316.253-.316.664 0 .918.158.126.365.19.572.19a.917.917 0 00.572-.19l5.085-4.082 5.085 4.081c.158.127.365.19.572.19a.917.917 0 00.572-.19c.316-.253.316-.664 0-.917L7.797 5.869z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'InputClearIcon')

export default icon
