import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ROOMS_DEVICES } from '@app/constants/rooms/rooms'
import Api from '@app/api/rooms'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setRoomDevices } from '@app/actions/rooms/rooms'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const fetchRoomDevices = createLogic({
  type: SYNC_ROOMS_DEVICES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ROOMS_DEVICES))
    Api.fetchDevices(AccessToken)
      .then((response) => {
        dispatch(setRoomDevices(response.data))
        dispatch(apiRequestDone(SYNC_ROOMS_DEVICES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchDevices failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchRoomDevices
