import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import {
  ListItem,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { useDispatch } from '@app/store'
import { regexpChecker } from '@app/helpers/regexpChecker'
import { ClearIcon } from 'icons'
import { IPOFromBackend } from '@app/@types/po/poConfig'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as poConfigActions from '@app/actions/po/poConfig'
import useStyles from './InputField.style'

type Props = {
  title: string,
  number: number,
  value: string,
  hint: string,
  required: boolean,
  resetInputField: () => void,
  options: Array<IPOFromBackend>,
  setOptions: Dispatch<SetStateAction<Array<IPOFromBackend>>>,
  name: string,
  min: number,
  max: number,
  regexpString: string,
  validationSetter: (validation: boolean) => ReturnType<InferValueTypes<
    typeof poConfigActions>>,
  inProgress: boolean,
}

const InputField: React.FC<Props> = (props) => {
  const classes: any = useStyles()
  const dispatch = useDispatch()
  const [currentValue, setCurrentValue] = useState<string>('')
  const [fieldIsNotValid, setFieldIsNotValid] = useState<boolean>(false)
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const {
    title, number, value, hint, options, setOptions, name,
    resetInputField, validationSetter, inProgress,
    required, min, max, regexpString,
  } = props

  useEffect(() => {
    setCurrentValue(value)
  }, [])

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (currentValue === '' && required) {
      setFieldIsNotValid(true)
      setIsRequired(true)
    } else {
      setIsRequired(false)
      isFieldNotValid()
    }
  }, [currentValue])

  useEffect(() => {
    dispatch(validationSetter(!fieldIsNotValid))
  }, [fieldIsNotValid])

  const setInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: string
    if (isRangeField()) {
      if (event.target.value === '0') {
        newValue = '0'
      } else {
        newValue = event.target.value.replace(/^\s*0*(\d*)\s*$/, '$1')
      }
    } else {
      newValue = event.target.value.replace(/\s+/g, '')
    }
    setOptions(options.map((rule) => (rule.number === number
      ? { ...rule, value: newValue } : rule)))
    setCurrentValue(newValue)
  }

  const isRangeField = () => min !== undefined

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isRangeField()) {
      if (!Number.isNaN(+event.target.value)) {
        setInputValue(event)
      }
    } else {
      setInputValue(event)
    }
  }

  const numberFieldsValidation = () => (+currentValue >= min && +value <= max)

  const isFieldNotValid = () => {
    if (isRangeField()) {
      setFieldIsNotValid(!numberFieldsValidation())
    } else if (currentValue) {
      if (regexpString) {
        const regexp = new RegExp(regexpString)
        setFieldIsNotValid(!regexpChecker(value, regexp))
      }
    }
  }

  const createAdorment = () => (
    <InputAdornment position="start">
      <IconButton className={classes.resetFieldButton} onClick={resetInputField} aria-label="Clear">
        <ClearIcon className={classes.icon} />
      </IconButton>
    </InputAdornment>
  )

  const createTextField = () => (
    <TextField
      label={title}
      variant="outlined"
      onChange={handleChange}
      value={currentValue}
      InputProps={{
        endAdornment: createAdorment(),
        name,
      }}
      error={!inProgress && fieldIsNotValid}
    />
  )

  return (
    <ListItem className={classes.root} key={name}>
      <FormControl variant="standard" className={classes.input}>
        {createTextField()}
        <FormHelperText className={classes.text}>{hint}</FormHelperText>
        {isRequired && (
          <FormHelperText className={classes.required}>{I18n.t('validations.name_required')}</FormHelperText>
        )}
      </FormControl>
    </ListItem>
  )
}

export default InputField
