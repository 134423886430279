import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/timers/reset_all`

const resetAllTimers = (accessToken) => Axios.post(ENDPOINT, null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  resetAllTimers,
}
