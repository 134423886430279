export const OPEN_MENU = '@@menu/open'
export const CLOSE_MENU = '@@menu/close'
export const SET_NAVIGATION_STATUS = '@@navigationStatus/set'

export const TOGGLE_HOME_TAB = '@@menu/toggleHomeTab'
export const TOGGLE_AUTOMATION_TAB = '@@menu/toggleAutomationTab'
export const TOGGLE_APPLICATION_TAB = '@@menu/toggleApplicationTab'
export const TOGGLE_MEZZO_TAB = '@@menu/toggleMezzoTab'
export const TOGGLE_SECURITY_TAB = '@@menu/toggleSecurityTab'
export const TOGGLE_MY_SECURITY_TAB = '@@menu/toggleMySecurityTab'
export const CLOSE_ALL_TABS = '@@menu/closeAllTabs'

export const RESET_NAVIGATION = '@@navigation/reset'
