const styles = (theme) => ({
  root: {
    padding: 15,
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    position: 'relative',
    boxSizing: 'border-box',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
    height: '100%',
    boxSizing: 'border-box',
  },
  mainMobile: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '75%',
  },
  deviceLabel: {
    marginLeft: '20px 0',
    paddingLeft: '2%',
    position: 'relative',
  },
  deviceArrow: {
    position: 'absolute',
    top: -11,
    left: -50,
  },
  zWaveDevicesContainer: {
    marginTop: 30,
  },
  devicesContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  devicesCard: {
    boxSizing: 'border-box',
    width: '46%',
    height: 57,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '12px 4%',
    paddingRight: 5,
    margin: '2%',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  cardMobile: {
    paddingLeft: 20,
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
  cardControls: {
    textDecoration: 'underline',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  noDevices: {
    margin: '0 auto',
    marginTop: 80,
    textTransform: 'uppercase',
    fontSize: 20,
  },
  infoPopup: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 50,
    height: 40,
  },
  addButton: {
    position: 'fixed',
    bottom: 20,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    backgroundColor: theme.palette.primary.main,
    zIndex: 101,
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
})

export default styles
