import type { Node } from 'react'
import React from 'react'
import { Chart } from 'react-google-charts'
import { CircularProgress } from '@mui/material'

import useStyles from './Chart.style'

type Props = {
  data: Array<Array<string | number>>,
  isFullWidth: boolean,
}

const OnlineChart = (props: Props): Node => {
  const { isFullWidth, data } = props
  const classes = useStyles()

  return (
    <div
      style={{
        width: isFullWidth ? '85%' : '100%', margin: '1.5rem auto', padding: '1.5rem', backgroundColor: '#fff',
      }}
      className={classes.chartContainer}
    >
      <Chart
        width="100%"
        height={300}
        chartType="Bar"
        loader={(
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '4rem auto',
          }}
          >
            <CircularProgress />
          </div>
        )}
        data={data}
        options={{
          colors: ['#3366CC', '#DC3912', '#FF9900', '#109618'],
          chartArea:
            {
              width: '80%',
              left: 30,
            },
          hAxis: {
            minValue: 0,
          },
        }}
        legendToggle
      />
    </div>
  )
}

export default OnlineChart
