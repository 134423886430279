import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M80 8V141L26 130.706V18.2941L80 8ZM65.75 74.2941C61.25 74.2941 61.25 83.3529 65.75 83.3529C67.25 83.3529 68.75 82.1176 69.125 80.0588H71.375V78H69.125C68.75 75.9412 67.25 74.2941 65.75 74.2941Z" fill="currentColor"/>
      <path d="M83.375 17.8828H116V131.118H83.375V125.353H110.375V24.0593H83.375V17.8828Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DarkDoor')

export default icon
