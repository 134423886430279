import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.16668 8.33332H12.0834C13.2325 8.33332 14.1667 9.26832 14.1667 10.4167V17.9167C14.1667 19.065 13.2325 20 12.0834 20H2.08336C0.93418 20 3.90625e-05 19.065 0 17.9167V10.4167C0 9.41168 0.71668 8.56918 1.66668 8.37586V5.41668C1.66668 2.43 4.09668 0 7.08336 0C10.07 0 12.5 2.43 12.5 5.41668V7.08336C12.5 7.31336 12.3134 7.50004 12.0834 7.50004H10.4167C10.1867 7.50004 10 7.31336 10 7.08336V5.41668C10 3.80832 8.69168 2.5 7.08336 2.5C5.47504 2.5 4.16668 3.80832 4.16668 5.41664V8.33332ZM8 16.5V11.5H7.85331L5 12.2368V13.0147L6.63722 12.6378V16.5H8Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'Area1ArmedIcon')

export default icon
