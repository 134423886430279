import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  '@keyframes sk-bouncedelay': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1.0)',
    },
  },
  spinner: {
    width: (props: React.CSSProperties) => (props.width ? props.width : 40),
    textAlign: 'center',

    '& $bounce2': {
      animationDelay: '-0.16s',
    },

    '& $bounce1': {
      animationDelay: '-0.32s',
    },

    '& div': {
      borderRadius: '100%',
      display: 'inline-block',
      animationName: '$sk-bouncedelay',
      animationDuration: '1.4s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      animationFillMode: 'both',
      width: (props: any) => (props.circleWidth ? props.circleWidth : 10),
      height: (props: any) => (props.circleHeight ? props.circleHeight : 10),
      backgroundColor: (props: any) => (props.circleColor ? props.circleColor : theme.palette.primary.main),
    },
  },
  bounce1: {},
  bounce2: {},
  bounce3: {},
}))

export default useStyles
