import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getDelayDurationDescription from '@app/helpers/scenes/getDelayDurationDescription'
import { adjustDurationValue } from '@app/lib/Scenes/actions'
import {
  SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED,
  SELECT_BINARY_SWITCH_ACTION_VALUE_AFTER_DURATION,
} from '@app/constants/routes'
import Counter from '@app/containers/MyAutomation/Counter'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import PropTypes from '@app/components/PropTypes'

class AdjustDuration extends React.Component {
  onAddStateClick=() => {
    const {
      counterInfo,
      saveActionDuration,
      resetCounter,
      navigate,
      saveActionDurationDescription,
    } = this.props
    saveActionDuration(counterInfo)
    saveActionDurationDescription(getDelayDurationDescription(counterInfo))
    resetCounter()
    navigate(SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED)
  }

  onBackClick =() => {
    const {
      navigate,
      resetCounter,
      restoreActionInfo,
    } = this.props
    navigate(SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED)
    resetCounter()
    restoreActionInfo()
  }

  onChooseValueClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_BINARY_SWITCH_ACTION_VALUE_AFTER_DURATION)
  }

  onDisableButtonValue=() => {
    const {
      durationValueAfter,
      counterInfo: {
        day,
        hour,
        minute,
        second,
      },
    } = this.props
    if (day || hour || minute || second) {
      return durationValueAfter === 'none'
    }
    return true
  }

  render() {
    const {
      classes,
      isMobile,
      durationValueAfter,
      stateDescription,
    } = this.props
    const isButtonDisabled = this.onDisableButtonValue()
    const isToggle = stateDescription === 'toggle'
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.adjust_duration')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Counter title="duration" />
          <CardBlock
            key={adjustDurationValue.title}
            title={I18n.t(`scene_web.${adjustDurationValue.title}`)}
            description={I18n.t(`scene_action_web.${durationValueAfter}`)}
            position={adjustDurationValue.position}
            buttonName={stateDescription !== 'toggle' && I18n.t(`scene_web.${adjustDurationValue.buttonName}`)}
            isCardDisabled={adjustDurationValue.isCardDisabled}
            isButtonDisabled={isToggle}
            onClick={this.onChooseValueClick}
          />
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={this.onAddStateClick}
          disabled={isButtonDisabled}
        >
          {I18n.t('buttons.next')}
        </Button>
      </Paper>
    )
  }
}

AdjustDuration.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  counterInfo: PropTypes.shape({
    day: PropTypes.number.isRequired,
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    second: PropTypes.number.isRequired,
  }).isRequired,
  resetCounter: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  stateDescription: PropTypes.string.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
}

AdjustDuration.displayName = 'AdjustDuration'

export default AdjustDuration
