import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 20,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cardMobile: {
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 20,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cardExpanded: {
    minHeight: 50,
    height: 'auto',
  },
  badgeWarning: {
    color: '#333',
    backgroundColor: '#ffeb3b',
  },
  badgeSuccess: {
    color: '#333',
    backgroundColor: '#8bc34a',
  },
  badgeDanger: {
    color: '#333',
    backgroundColor: '#ef5350',
  },
  badgeInfo: {
    color: '#333',
    backgroundColor: '#80deea',
  },
  cardTitle: {
    fontSize: '18px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardTitleMobile: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  expanded: {
    whiteSpace: 'normal',
    overflow: 'none',
  },
}))

export default useStyles
