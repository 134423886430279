import * as type from '@app/constants/appCofig/appConfig'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/appConig/appConfig'
import { defaultConfig } from '@app/helpers/convertAppConfig'

type IAppConfig = ReturnType<InferValueTypes<typeof actions>>

export interface IAppConfigState {
  rawConfig: any, // TODO
  config: any, // TODO
}

const initialState = {
  rawConfig: [],
  config: defaultConfig,
}

const appConfig = (state: IAppConfigState = initialState, action: IAppConfig) => {
  switch (action.type) {
  case type.SAVE_RAW_CONFIG: {
    return {
      ...state,
      rawConfig: action.config,
    }
  }
  case type.SAVE_CONFIG_INFO: {
    return {
      ...state,
      config: action.config,
    }
  }

  case type.RESET_APP_CONFIG:
    return initialState

  default:
    return state
  }
}

export default appConfig
