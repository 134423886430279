import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { BillingStatsIcon, Close } from 'icons'
import classNames from 'classnames'
import IconButton from '@mui/material/IconButton'
import getDate from '@app/helpers/myEnergy/getDate'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import BillingStatsNotAvailableIcon from 'icons/myEnergyIcons/BillingStatsNotAvailable'

class BillingStatsPopup extends React.PureComponent {
  renderLoadingState=() => {
    const {
      classes,
    } = this.props
    return (
      <Wrapper className={classes.statsNotAvailable}>
        <Typography className={classes.text}>Loading...</Typography>
      </Wrapper>
    )
  }

  renderEmptyState = () => {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Wrapper className={classes.statsNotAvailable}>
        <ListItemIcon>
          <BillingStatsNotAvailableIcon className={classNames(
            classes.iconPeriodStats,
            !isMobile && classes.iconDesktop,
          )}
          />
        </ListItemIcon>
        <Typography className={classes.text} component="div">
          {I18n.t('my_energy.no_statistics_for_all_periods')}
        </Typography>
      </Wrapper>
    )
  }

renderWithInfoState =() => {
  const {
    classes,
    isMobile,
    billingPeriodsStats,
    resetAllBillingPeriods,
    admin,
  } = this.props
  return (
    <Wrapper>
      <List>
        {
          billingPeriodsStats.map((stats, index) => (
            <ListItem
              key={`period-${index + 1}`}
              component="div"
              divider
              className={classes.listItem}
            >
              <ListItemIcon>
                <BillingStatsIcon
                  className={classNames(
                    classes.icon,
                    !isMobile && classes.iconDesktop,
                  )}
                />
              </ListItemIcon>
              <ListItemText
                className={classes.info}
                primary={(
                  <>
                    <Typography
                      component="span"
                      className={classNames(
                        classes.infoTime,
                        !isMobile && classes.infoTimeDesktop,
                      )}
                    >
                      {`${I18n.t('my_energy.period_for_billing')}:
                       ${getDate(stats.from, 'short')} - ${getDate(stats.to, 'short')}`}
                    </Typography>
                  </>
                )}
                secondary={(
                  <>
                    <Typography
                      component="span"
                      className={classNames(
                        classes.infoStat,
                        !isMobile && classes.infoStatDesktop,
                      )}
                    >
                      {`${I18n.t('my_energy.billing')}: ${stats.billing} $`}
                    </Typography>
                    <Typography
                      component="span"
                      className={classNames(
                        classes.infoStat,
                        !isMobile && classes.infoStatDesktop,
                      )}
                    >
                      {`${I18n.t('my_energy.energy')}: ${stats.energy} ${I18n.t('my_energy.power_kwh')}`}
                    </Typography>
                  </>
                )}
              />
            </ListItem>
          ))
        }
      </List>
      <Wrapper className={classes.resetButtonWrapper}>
        <Button
          onClick={() => {
            resetAllBillingPeriods()
          }}
          disabled={!admin}
          className={classNames(
            classes.button,
            classes.resetPeriodsButton,
          )}
        >
          {I18n.t('buttons.reset_all_billing_periods')}
        </Button>
      </Wrapper>
    </Wrapper>
  )
}

renderInfo=() => {
  const {
    billingPeriodsStats,
  } = this.props
  return billingPeriodsStats.length ? this.renderWithInfoState() : this.renderEmptyState()
}

render() {
  const {
    classes,
    isMobile,
    billingPeriodsStatsClose,
    inProgress,
  } = this.props
  return (
    <Dialog
      open
      className={classNames(
        classes.root,
        !isMobile && classes.rootDesktop,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.rootWebView,
      )}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t('titles.statistics_for_all_periods')}
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={billingPeriodsStatsClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.statsList}>
        {inProgress ? this.renderLoadingState() : this.renderInfo()}
      </DialogContent>
    </Dialog>
  )
}
}

BillingStatsPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  billingPeriodsStats: PropTypes.arrayOf(PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    billing: PropTypes.number.isRequired,
    energy: PropTypes.number.isRequired,
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  billingPeriodsStatsClose: PropTypes.func.isRequired,
  resetAllBillingPeriods: PropTypes.func.isRequired,
}

BillingStatsPopup.displayName = 'BillingStatsPopup'

export default BillingStatsPopup
