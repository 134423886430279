const styles = (theme) => ({
  downloadButton: {
    width: '100%',
    marginBottom: 20,
  },
  progress: {
    marginTop: 30,
    marginBottom: 100,
    width: '95%',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  installElementsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 50,
    marginBottom: 30,
  },
  pausedButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 30,
    marginBottom: 30,
  },
  pausedTitle: {
    marginLeft: '45%',
  },
  resetButton: {
    width: 'fit-content',
    textDecoration: 'underline',
    position: 'absolute',
    left: '75%',
  },
  resetButtonMobile: {
    width: 35,
  },
  buttonInPaused: {
    width: 'fit-content',
    textDecoration: 'underline',
  },
  progressHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
    margin: '12px 0',
  },
  installationError: {
    color: theme.palette.error.main,
  },
  installationErrorLabel: {
    display: 'flex',
  },
  installationProcessTitle: {
    textAlign: 'center',
  },
})

export default styles
