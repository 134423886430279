import { createLogic } from 'redux-logic'
import Api from '@app/api/contacts'
import handleErrors from '@app/helpers/handleErrors'
import { POST_CONTACT } from '@app/constants/contacts/contacts'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { setPostContact } from '@app/actions/contacts/contacts'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const postContact = createLogic({
  type: POST_CONTACT,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const { form: { contacts: { values: contactData } } } = store.getState()
    dispatch(apiRequestSent(POST_CONTACT))
    Api.postContact(AccessToken, contactData)
      .then((response) => {
        dispatch(setPostContact(response.data))
        dispatch(apiRequestDone(POST_CONTACT))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('postContact failed without error')
        }
      })
      .then(() => done())
  },
})

export default postContact
