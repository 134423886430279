import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
      <path stroke="#555" d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z" clipRule="evenodd"/>
      <path fill="#6F6F6F" d="M11.665 7.314a.568.568 0 01-.42-.154.617.617 0 01-.154-.434c0-.252.084-.476.252-.672.177-.205.387-.308.63-.308.186 0 .317.06.391.182a.794.794 0 01.127.462c0 .233-.08.448-.239.644a.742.742 0 01-.588.28zm-.714 6.538c-.01.037-.02.089-.029.154a.847.847 0 00-.014.126c0 .112.056.168.168.168.066 0 .131-.014.197-.042.074-.037.144-.075.21-.112.074-.047.14-.084.195-.112a.273.273 0 01.14-.056c.094 0 .16.093.197.28a5.573 5.573 0 01-.519.35c-.177.103-.35.196-.518.28-.168.084-.326.15-.476.196-.149.056-.27.084-.364.084a.648.648 0 01-.293-.07c-.094-.037-.14-.135-.14-.294 0-.028.004-.075.014-.14a.93.93 0 01.028-.154l.49-2.38a73.4 73.4 0 00.265-1.358c.066-.317.108-.527.127-.63a1.74 1.74 0 00.027-.182c0-.112-.042-.168-.126-.168a.43.43 0 00-.167.056c-.075.028-.154.06-.239.098-.084.028-.163.06-.237.098a.425.425 0 01-.154.042c-.056 0-.098-.028-.126-.084a.958.958 0 01-.07-.196c.168-.093.364-.196.587-.308.224-.121.44-.233.644-.336.215-.103.402-.187.56-.252.168-.065.28-.098.337-.098.158 0 .238.08.238.238 0 .065-.01.135-.029.21l-.924 4.592z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ExampleInformation', '0 0 20 20', { fontSize: 17 })

export default icon
