import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 14">
      <path fill="#929292" d="M16.868 11.904l-3.985-3.212c1.025-.943 1.586-2.13 1.586-3.367 0-2.888-3.033-5.238-6.76-5.238-3.728 0-6.76 2.35-6.76 5.238 0 2.888 3.032 5.238 6.76 5.238 1.4 0 2.733-.327 3.873-.948l4.015 3.236c.168.135.394.21.635.21.23 0 .447-.068.611-.19.35-.262.362-.695.025-.967zm-9.16-10.45c2.756 0 4.997 1.736 4.997 3.87 0 2.136-2.241 3.873-4.996 3.873-2.755 0-4.997-1.737-4.997-3.872S4.954 1.453 7.71 1.453z"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SearchLoopIcon')

export default icon
