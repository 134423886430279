import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1782 12.7047C8.95116 13.22 8.15385 14.0539 8.15385 14.9951C8.15385 16.5599 10.358 17.8284 13.0769 17.8284C15.7959 17.8284 18 16.5599 18 14.9951C18 14.0304 17.1623 13.1783 15.8828 12.6667M4.0244 7.37138C2.79731 7.88665 2 8.72062 2 9.66176C2 11.2266 4.20414 12.4951 6.92308 12.4951C8.17471 12.4951 9.31725 12.2263 10.1859 11.7835M10.8687 7.967C10.5507 7.72218 10.1655 7.50794 9.72891 7.33333M13.0154 13.3333C14.5448 13.3333 15.7846 11.9902 15.7846 10.3333C15.7846 8.67648 14.5448 7.33333 13.0154 7.33333C11.486 7.33333 10.2462 8.67648 10.2462 10.3333C10.2462 11.9902 11.486 13.3333 13.0154 13.3333ZM6.86155 8C8.39096 8 9.63078 6.65685 9.63078 5C9.63078 3.34315 8.39096 2 6.86155 2C5.33215 2 4.09232 3.34315 4.09232 5C4.09232 6.65685 5.33215 8 6.86155 8Z" stroke="currentColor"/>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'MyUsersIcon')

export default icon
