import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 130 130" version="1.1">
            <title>iconDeviceGlassbreak</title>
            <desc>Created with Sketch.</desc>
            <defs>
            </defs>
            <g id="Map" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="iconDeviceGlassbreak">
                    <g>
                        <path
                            d="M2.56438356,2.56438356 L2.56438356,126.999315 L126.999315,126.999315 L126.999315,2.56438356 L2.56438356,2.56438356 Z M62.5157534,117.943836 L11.5753425,117.943836 L11.5753425,67.0390411 L62.5157534,67.0390411 L62.5157534,117.943836 Z M62.5157534,62.5157534 L11.5753425,62.5157534 L11.5753425,11.5753425 L62.5157534,11.5753425 L62.5157534,62.5157534 Z M117.943836,117.943836 L67.0390411,117.943836 L67.0390411,67.0390411 L117.934932,67.0390411 L117.943836,117.943836 Z M117.943836,62.5157534 L67.0390411,62.5157534 L67.0390411,11.5753425 L117.934932,11.5753425 L117.943836,62.5157534 Z"
                            id="Shape" fill="currentColor">
                        </path>
                        <rect id="Rectangle-path" stroke="currentColor" strokeWidth="2" x="0.445205479"
                              y="0.445205479" width="128.673288" height="128.673288">
                        </rect>
                        <polygon id="Shape" stroke="currentColor" strokeWidth="2"
                                 points="97.2773943 39.4273973 110.206161 39.4273973 97.2773943 35.2424658 106.404107 27.2554795 97.2773943 29.7664384 80.6890381 18.0486301 93.7780792 32.9630137 80.1636956 30.4520548 91.4184902 36.6849315 73.6993121 51.5993151 93.3150655 41.3239726 102.904792 51.5993151">
                        </polygon>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwGlassbreackIcon')

export default icon
