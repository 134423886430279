export const SYNC_EVENTS = '@@events/sync'

export const UPDATE_EVENTS = '@@events/update'

export const SET_FILTER_BY_TYPE = '@@eventsFilterByType/set'
export const CLEAR_FILTER_BY_TYPE = '@@eventsFilterByType/clear'

export const SET_FILTER_FROM_DATE = '@@eventsFilterFromDate/set'
export const CLEAR_FILTER_FROM_DATE = '@@eventsFilterFromDate/clear'

export const SET_FILTER_TO_DATE = '@@eventsFilterToDate/set'
export const CLEAR_FILTER_TO_DATE = '@@eventsFilterToDate/clear'

export const SET_FILTER_BY_TEXT = '@@eventsFilterByText/set'
export const CLEAR_FILTER_BY_TEXT = '@@eventsFilterByText/clear'

export const SAVE_CURRENT_PAGE = '@@events/saveCurrentPage'
export const RESET_EVENTS_REDUCER = '@@events/reset'
