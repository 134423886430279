const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 700,
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
  },
  refreshButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2.5),
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(204, 204, 204, 0.1)',
      borderRadius: '50%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2.5),
    padding: 0,
  },
  statsList: {
    width: 350,
    marginTop: -20,
    padding: 20,
    boxSizing: 'border-box',
  },
  statsListMobile: {
    width: 310,
    padding: 10,
  },
  listItemWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'rows',
    minHeight: '50px',
  },
  labels: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    justifyContent: 'center',
  },
  data: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '27px',
    position: 'relative',
  },
  valueIconContainer: {
    display: 'flex',
  },
  lastUpdate: {
    fontSize: '14px',
    lineHeight: 1.5,
    color: theme.myStatus.subtitle,
  },
  listItem: {
    padding: '5px 25px',
    minWidth: 290,
  },
  info: {
    fontSize: '16px',
    width: 100,
  },
  devices: {
    fontSize: '16px',
    width: 150,
    lineHeight: 1.1,
  },
  icon: {
    position: 'absolute',
    top: '-4px',
    right: '-33px',
    '&:before': {
      fontFamily: 'my_status_icons',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 18,
      speak: 'none',
      display: 'inline-block',
      textDecoration: 'inherit',
      width: '1em',
      marginRight: '.2em',
      textAlign: 'center',
      fontVariant: 'normal',
      textTransform: 'none',
      lineHeight: '1em',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      marginLeft: -30,
    },
  },
  iconBatterycomfortable: {
    '&:before': {
      content: '\'\\31\'',
      fontSize: 14,
    },
  },
  iconBatterywarning: {
    '&:before': {
      content: '\'\\32\'',
      fontSize: 14,
    },
  },
  iconBatterycritical: {
    '&:before': {
      content: '\'\\33\'',
      fontSize: 14,
    },
  },
  iconIlluminanceLevelcomfortable: {
    '&:before': {
      content: '\'\\43\'',
    },
  },
  iconIlluminanceLevelwarning: {
    '&:before': {
      content: '\'\\43\'',
    },
  },
  iconIlluminanceLevelcritical: {
    '&:before': {
      content: '\'\\43\'',
    },
  },
  iconSmokeLevelcomfortable: {
    '&:before': {
      content: '\'\\48\'',
    },
  },
  iconSmokeLevelwarning: {
    '&:before': {
      content: '\'\\49\'',
    },
  },
  iconSmokeLevelcritical: {
    '&:before': {
      content: '\'\\47\'',
    },
  },
  humidityLevelcomfortable: {
    '&:before': {
      content: '\'\\42\'',
    },
  },
  humidityLevelwarning: {
    '&:before': {
      content: '\'\\42\'',
    },
  },
  humidityLevelcritical: {
    '&:before': {
      content: '\'\\42\'',
    },
  },
  ultravioletLevelcomfortable: {
    '&:before': {
      content: '\'\\39\'',
    },
  },
  ultravioletLevelwarning: {
    '&:before': {
      content: '\'\\41\'',
    },
  },
  ultravioletLevelcritical: {
    '&:before': {
      content: '\'\\43\'',
    },
  },
  airTemperatureLevelcomfortable: {
    '&:before': {
      content: '\'\\35\'',
    },
  },
  airTemperatureLevelwarning: {
    '&:before': {
      content: '\'\\36\'',
    },
  },
  airTemperatureLevelcritical: {
    '&:before': {
      content: '\'\\38\'',
    },
  },
  comfortable: {
    color: '#5BD84D',
  },
  warning: {
    color: '#FF9129',
  },
  critical: {
    color: '#F61718',
  },
  errorMessage: {
    textAlign: 'center',
  },
})

export default styles
