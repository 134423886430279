import React from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './CustomWarningComponent.style'

interface Props {
  open: boolean,
  onClose: () => void,
  onClick: () => void,
  warningText: string,
  userEmail: string,
  additionalWarningText: string,
  leftButton: string,
  rightButton: string,
}

const CustomWarningComponent: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onClick,
    warningText,
    leftButton,
    rightButton,
    userEmail,
    additionalWarningText,
  } = props
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t('titles.warning') }
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          className={classes.text}
        >
          {warningText}
          <span className={classes.email}>{userEmail}</span>
          .
          <br />
          {additionalWarningText}
        </DialogContentText>
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          color="primary"
          className={classes.button}
          variant="contained"
          onClick={onClick}
        >
          {I18n.t(`buttons.${leftButton}`)}
        </Button>
        <Button
          color="primary"
          className={classes.button}
          onClick={onClose}
        >
          {I18n.t(`buttons.${rightButton}`)}
        </Button>
      </Wrapper>
    </Dialog>
  )
}

export default CustomWarningComponent
