import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  menu: {
    cursor: 'pointer',
  },
  menuItem: {
    backgroundColor: theme.backgrounds.cardBackground,
    '&:hover': {
      backgroundColor: theme.backgrounds.hover,
      opacity: 70,
    },
  },
}))

export default useStyles
