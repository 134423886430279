import { createLogic } from 'redux-logic'
import { CLOSE_TWR_CHANNEL } from '@app/constants/myDevices/channels/twr/twrChannels'
import { INTERVIEW_CHANNEL_TWR } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeMyDevicesTwrLogic = createLogic({
  type: CLOSE_TWR_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(INTERVIEW_CHANNEL_TWR) && sub.unsubscribe())
  },
})

export default unsubscribeMyDevicesTwrLogic
