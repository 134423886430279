const styles = (theme) => ({
  decreasingArrow: {
    position: 'relative',
    margin: '0 auto',
    width: 10,
    height: 20,
    backgroundColor: theme.energyGraph.decreasingArrow,
    marginBottom: 5,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 15,
      right: -5,
      border: '10px solid #ffffff',
      borderRightColor: 'transparent',
      borderLeftColor: 'transparent',
      borderTopColor: theme.energyGraph.decreasingArrow,
      borderBottomColor: 'transparent',
    },
  },
  increasingArrow: {
    position: 'relative',
    margin: '0 auto',
    width: 10,
    height: 15,
    backgroundColor: theme.energyGraph.increasingArrow,
    marginBottom: 5,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: -20,
      right: -5,
      border: '10px solid #ffffff',
      borderRightColor: 'transparent',
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderBottomColor: theme.energyGraph.increasingArrow,
    },
  },
  noArrow: {
    display: 'none',
  },
})

export default styles
