import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: 44,
    height: 26,
    padding: 0,
    margin: '1rem',
    display: 'flex',
  },
  rootBig: {
    width: 88,
    height: 52,
  },
  rootMedium: {
    width: 66,
    height: 39,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(18px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#A9CF39',
      },
    },
    '&.Mui-disabled': {
      '& + $track': {
        opacity: '0.1!important',
        backgroundColor: '#fff!important',
      },
    },
  },
  switchBaseBig: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(36px)',
    },
  },
  switchBaseMedium: {
    padding: 1.5,
    '&$checked': {
      transform: 'translateX(27px)',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none',
    backgroundColor: theme.backgrounds.componentBackground,
  },
  thumbBig: {
    width: 48,
    height: 48,
  },
  thumbMedium: {
    width: 36,
    height: 36,
  },
  track: {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#DE1F26',
  },
  trackBig: {
    borderRadius: 48 / 2,
  },
  trackMedium: {
    borderRadius: 39 / 2,
  },
  checked: {},
}))

export default useStyles
