import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft } from 'icons'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './SecurityOptions.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  isMobile: boolean,
}

const SecurityOptions: React.FC<Props> = (props) => {
  const { currentDevice, isMobile } = props
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <>
      <Wrapper
        className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
        onClick={() => {
          navigate(`/web/my_devices/info/zwave_device/${currentDevice.id}/settings/zwave_security_options`)
        }}
      >
        <Typography className={classNames(classes.mainTitle)}>{`${I18n.t('my_devices.security_options')}`}</Typography>
        <Typography className={classNames(classes.cardTitle)}>{`${I18n.t('my_devices.trigger_security_alarm')}`}</Typography>
        <ChevronLeft className={classes.arrowRightIcon} />
      </Wrapper>
    </>
  )
}

export default SecurityOptions
