import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
