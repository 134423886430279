import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/energyTab'
import { defaultPeriod } from '@app/lib/MyEnergy/intervalValues'

const initialState = {
  periodSelector: defaultPeriod,
  selectedSliderTab: 0,
  allEnergies: {
    current_time: '2019-05-17 17:48:00 +007',
    chart: {
      kwh: {
        week: [0, 0, 0, 0, 0, 0, 0],
        month: [0, 0, 0, 0],
        half_year: [0, 0, 0, 0, 0, 0],
        year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      dollar: {
        week: [0, 0, 0, 0, 0, 0, 0],
        month: [0, 0, 0, 0],
        half_year: [0, 0, 0, 0, 0, 0],
        year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    },
    total: {
      consumption: {
        week: 0,
        month: 0,
        half_year: 0,
        year: 0,
      },
      payment: {
        week: 0,
        month: 0,
        half_year: 0,
        year: 0,
      },
      title: {
        week: 'Loading title...',
        month: 'Loading title...',
        half_year: 'Loading title...',
        year: 'Loading title...',
      },
    },
    comparison: {
      week: 0,
      month: 0,
      half_year: 0,
      year: 0,
    },
  },
}

const myEnergy = (state = initialState, action) => {
  switch (action.type) {
  case type.CHOOSE_PERIOD: {
    return {
      ...state,
      periodSelector: action.chosenPeriod,
    }
  }
  case type.CHOOSE_SLIDER_TAB: {
    return {
      ...state,
      selectedSliderTab: action.chosenTab,
    }
  }

  case type.UPDATE_ENERGIES:
    return update(state, {
      allEnergies: { $merge: action.energies },
    })

  case type.RESET_ENERGY_TAB:
    return initialState
  default:
    return state
  }
}

export default myEnergy
