import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { closeDownloadingErrorPopup } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  closeChangeLangPopup,
  closeResetPopup,
  openChangeLangPopup,
  openResetPopup,
  saveAutoInstallSetting,
  saveContinueDownloadingType,
  saveDownloadingTime,
  saveDownloadingType,
  saveFirmwareLang,
  saveSelectedFirmwareVersion,
  sync as syncCloudFirmwareUpdate,
  syncContinueDownloading,
  syncFirmwareLang,
  syncManualUpdate,
  syncPatchCloudFirmwareInfo,
  syncResetProcess,
  syncStopUploading,
  saveSlotForDownload,
  saveSlotForInstall,
  syncInstall,
  closeChangeCellularModulePopup,
  syncCellularModule,
  saveCellularModule,
  openChangeCellularModulePopup,
  startReboot,
} from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import CloudFirmwareUpdate from '@app/components/CloudFirmwareUpdate/CloudFirmwareUpdate'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'

let enhance = withStyles(CloudFirmwareUpdate)

const mapStateToProps = (state) => {
  const {
    firmwareLang,
    currentVersion,
    newVersion,
    autoInstall,
    downloadingType,
    firmwareList,
    selectedFirmwareVersion,
    isChangeLangPopupOpen,
    updateStatus,
    downloadingTime,
    fwToInstall,
    isResetPopupOpen,
    uploadProcess,
    submittedDownloadingTime,
    submittedNightlyDownloading,
    uploadStatusValue,
    slotForDownload,
    slotForInstall,
    installationMessage,
    fwInSlots,
    isChangeCellularModulePopupOpen,
    cellularModule,
  } = state.cloudFirmwareUpdate.cloudFirmwareUpdateInfo
  const {
    isDownloadingErrorPopupOpen,
    error: channelError,
  } = state.cloudFirmwareUpdate.cloudFirmwareUpdateChannel
  const isButtonsDisabled = updateStatus !== 'done'
  const isSubmitButtonDisabled = true
  const { isMobile } = state.mobileDetector
  return {
    isMobile,
    channelError,
    isDownloadingErrorPopupOpen,
    firmwareLang,
    firmwareList,
    downloadingType,
    newVersion,
    currentVersion,
    autoInstall,
    selectedFirmwareVersion,
    isChangeLangPopupOpen,
    updateStatus,
    isButtonsDisabled,
    downloadingTime,
    fwToInstall,
    isResetPopupOpen,
    uploadProcess,
    isSubmitButtonDisabled,
    submittedDownloadingTime,
    submittedNightlyDownloading,
    uploadStatusValue,
    slotForDownload,
    slotForInstall,
    installationMessage,
    fwInSlots,
    isChangeCellularModulePopupOpen,
    cellularModule,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncCloudFirmwareUpdate,
  notifications: syncNotifications.sync,
  saveSelectedFirmwareVersion,
  saveAutoInstallSetting,
  syncManualUpdate,
  syncFirmwareLang,
  saveFirmwareLang,
  openChangeLangPopup,
  closeChangeLangPopup,
  closeDownloadingErrorPopup,
  saveDownloadingType,
  syncResetProcess,
  closeResetPopup,
  openResetPopup,
  saveContinueDownloadingType,
  syncContinueDownloading,
  saveDownloadingTime,
  syncPatchCloudFirmwareInfo,
  syncStopUploading,
  saveSlotForDownload,
  saveSlotForInstall,
  syncInstall,
  closeChangeCellularModulePopup,
  syncCellularModule,
  saveCellularModule,
  openChangeCellularModulePopup,
  startReboot,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const CloudFirmwareUpdateEnhanced = enhance

export default CloudFirmwareUpdateEnhanced
