const styles = (theme) => ({
  colorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 320,
    width: 300,
    alignItems: 'center',
  },
  singleColor: {
    borderRadius: 5,
    width: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
    cursor: 'pointer',
    padding: 15,
    boxSizing: 'border-box',
  },
  colorPreview: {
    width: 50,
    borderRadius: 3,
    height: 30,
    border: '1px solid rgba(0,0,0, 0.3)',
  },
  colorControls: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '1px solid',
    borderColor: `${theme.myStatus.subTitle}`,
    boxShadow: `inset 0 0 0 2px ${theme.backgrounds.cardBackground}`,
  },
  chosenControlsColor: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
})

export default styles
