import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M74.5757 24.9384C78.977 24.9384 82.5449 21.3704 82.5449 16.9692C82.5449 12.5679 78.977 9 74.5757 9C70.1745 9 66.6065 12.5679 66.6065 16.9692C66.6065 21.3704 70.1745 24.9384 74.5757 24.9384ZM79.5449 16.9692C79.5449 19.7136 77.3201 21.9384 74.5757 21.9384C71.8313 21.9384 69.6065 19.7136 69.6065 16.9692C69.6065 14.2248 71.8313 12 74.5757 12C77.3201 12 79.5449 14.2248 79.5449 16.9692Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.0267 39.2648C31.8558 41.4654 33.1759 46.3535 30.9753 50.1826C28.7747 54.0117 23.8866 55.3318 20.0575 53.1312C16.2284 50.9305 14.9083 46.0425 17.1089 42.2134C19.3095 38.3843 24.1976 37.0642 28.0267 39.2648ZM26.5318 41.8658C28.9244 43.2409 29.7493 46.2951 28.3743 48.6877C26.9992 51.0803 23.9449 51.9052 21.5524 50.5301C19.1598 49.1551 18.3349 46.1008 19.71 43.7082C21.085 41.3157 24.1393 40.4908 26.5318 41.8658Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.8708 111.663C31.6824 109.462 32.9883 104.588 30.7877 100.777C28.5871 96.9652 23.7132 95.6592 19.9016 97.8599C16.09 100.061 14.784 104.934 16.9846 108.746C19.1853 112.558 24.0592 113.864 27.8708 111.663ZM28.1896 102.277C29.5618 104.654 28.7475 107.693 26.3708 109.065C23.994 110.437 20.9549 109.623 19.5827 107.246C18.2105 104.869 19.0248 101.83 21.4016 100.458C23.7783 99.0857 26.8174 99.9001 28.1896 102.277Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M129.255 97.8622C133.067 100.063 134.373 104.937 132.172 108.748C129.971 112.56 125.097 113.866 121.286 111.665C117.474 109.465 116.168 104.591 118.369 100.779C120.57 96.9675 125.443 95.6616 129.255 97.8622ZM127.755 100.46C130.132 101.832 130.946 104.872 129.574 107.248C128.202 109.625 125.163 110.439 122.786 109.067C120.409 107.695 119.595 104.656 120.967 102.279C122.339 99.9024 125.378 99.0881 127.755 100.46Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M82.5449 134.03C82.5449 138.432 78.977 141.999 74.5757 141.999C70.1745 141.999 66.6065 138.432 66.6065 134.03C66.6065 129.629 70.1745 126.061 74.5757 126.061C78.977 126.061 82.5449 129.629 82.5449 134.03ZM79.5449 134.03C79.5449 136.775 77.3201 138.999 74.5757 138.999C71.8313 138.999 69.6065 136.775 69.6065 134.03C69.6065 131.286 71.8313 129.061 74.5757 129.061C77.3201 129.061 79.5449 131.286 79.5449 134.03Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M132.64 42.3939C134.841 46.223 133.521 51.1111 129.692 53.3117C125.863 55.5124 120.975 54.1922 118.774 50.3631C116.573 46.534 117.894 41.646 121.723 39.4453C125.552 37.2447 130.44 38.5648 132.64 42.3939ZM130.039 43.8888C131.414 46.2814 130.59 49.3356 128.197 50.7107C125.804 52.0857 122.75 51.2609 121.375 48.8683C120 46.4757 120.825 43.4214 123.217 42.0464C125.61 40.6713 128.664 41.4962 130.039 43.8888Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M74.5001 92C83.6128 92 91.0001 84.6127 91.0001 75.5C91.0001 66.3873 83.6128 59 74.5001 59C65.3874 59 58.0001 66.3873 58.0001 75.5C58.0001 84.6127 65.3874 92 74.5001 92ZM88.0001 75.5C88.0001 82.9558 81.9559 89 74.5001 89C67.0442 89 61.0001 82.9558 61.0001 75.5C61.0001 68.0442 67.0442 62 74.5001 62C81.9559 62 88.0001 68.0442 88.0001 75.5Z" fill="currentColor"/>
            <path d="M61.39 53.25C56.3421 53.25 52.25 57.3421 52.25 62.39V88.8754C52.25 93.9233 56.3421 98.0154 61.39 98.0154H87.8754C92.9233 98.0154 97.0154 93.9233 97.0154 88.8754V62.39C97.0154 57.3421 92.9233 53.25 87.8754 53.25H61.39ZM54.75 62.39C54.75 58.7228 57.7228 55.75 61.39 55.75H87.8754C91.5426 55.75 94.5154 58.7228 94.5154 62.39V88.8754C94.5154 92.5426 91.5426 95.5154 87.8754 95.5154H61.39C57.7228 95.5154 54.75 92.5426 54.75 88.8754V62.39Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwMultisensorIcon')

export default icon
