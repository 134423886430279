import * as type from '@app/constants/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'

export const syncFailed = (error) => ({
  type: type.SYNC_FAILED_CLOUD_FIRMWARE_UPDATE_CHANNEL,
  error,
})

export const syncChannel = () => ({
  type: type.SYNC_CLOUD_FIRMWARE_UPDATE_CHANNEL,
})

export const syncSentChannel = () => ({
  type: type.SYNC_SENT_CLOUD_FIRMWARE_UPDATE_CHANNEL,
})

export const syncDoneChannel = () => ({
  type: type.SYNC_DONE_CLOUD_FIRMWARE_UPDATE_CHANNEL,
})
export const openChannel = () => ({
  type: type.OPEN_CLOUD_FIRMWARE_UPDATE_CHANNEL,
})

export const closeChannel = () => ({
  type: type.CLOSE_CLOUD_FIRMWARE_UPDATE_CHANNEL,
})

export const openDownloadingErrorPopup = () => ({
  type: type.OPEN_DOWNLOADING_ERROR_POPUP,
})

export const closeDownloadingErrorPopup = () => ({
  type: type.CLOSE_DOWNLOADING_ERROR_POPUP,
})
