const weekdays = [{
  label: 'Monday',
  value: 'mon',
},
{
  label: 'Tuesday',
  value: 'tue',
},
{
  label: 'Wednesday',
  value: 'wed',
},
{
  label: 'Thursday',
  value: 'thu',
},
{
  label: 'Friday',
  value: 'fri',
},
{
  label: 'Saturday',
  value: 'sat',
},
{
  label: 'Sunday',
  value: 'sun',
},
]

const durations = [
  { title: I18n.t('my_reminders.continous_form'), value: 0 },
  { title: I18n.t('my_reminders.number_of_days'), value: 1 },
]
const scheduleTypes = [
  { title: I18n.t('my_reminders.every_day'), value: 'default' },
  { title: I18n.t('my_reminders.specific_days'), value: 'days_of_weeks' },
  { title: I18n.t('my_reminders.days_interval'), value: 'days_interval' },
]

const reminderTimes = [
  { title: I18n.t('my_reminders.reminder_times_1'), value: 0 },
  { title: I18n.t('my_reminders.reminder_times_2'), value: 1 },
  { title: I18n.t('my_reminders.reminder_times_3'), value: 2 },
  { title: I18n.t('my_reminders.reminder_times_4'), value: 3 },
  { title: I18n.t('my_reminders.reminder_times_5'), value: 4 },
  { title: I18n.t('my_reminders.reminder_times_6'), value: 5 },
  { title: I18n.t('my_reminders.reminder_times_7'), value: 6 },
  { title: I18n.t('my_reminders.reminder_times_8'), value: 7 },
  { title: I18n.t('my_reminders.reminder_times_9'), value: 8 },
  { title: I18n.t('my_reminders.reminder_times_10'), value: 9 },
  { title: I18n.t('my_reminders.reminder_times_11'), value: 10 },
  { title: I18n.t('my_reminders.reminder_times_12'), value: 11 },
]

const quantity = [
  { title: '0.5', value: 0.5 },
  { title: '1.0', value: 1.0 },
  { title: '1.5', value: 1.5 },
  { title: '2.0', value: 2.0 },
  { title: '2.5', value: 2.5 },
  { title: '3.0', value: 3.0 },
  { title: '3.5', value: 3.5 },
  { title: '4.0', value: 4.0 },
  { title: '4.5', value: 4.5 },
  { title: '5.0', value: 5.0 },
  { title: '5.5', value: 5.5 },
  { title: '6.0', value: 6.0 },
  { title: '6.5', value: 6.5 },
  { title: '7.0', value: 7.0 },
  { title: '7.5', value: 7.5 },
  { title: '8.0', value: 8.0 },
  { title: '8.5', value: 8.5 },
  { title: '9.0', value: 9.0 },
  { title: '9.5', value: 9.5 },
  { title: '10.0', value: 10.0 },
]

export default {
  weekdays, durations, scheduleTypes, reminderTimes, quantity,
}
