import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2331 2331"
      id="vector">
      <g
        id="group"
        transform="translate(654 116)">
        <path
          id="path"
          d="M 0.5 0 L 925 0.5 L 0.5 1 L 0.5 0 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_1"
          d="M 84.5 85 L 85 2100.5 L 84 2100.5 L 84.5 85 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_2"
          d="M 840.5 85 L 841 2100.5 L 840 2100.5 L 840.5 85 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_3"
          d="M 542.5 105 L 830.5 105 L 832 106.5 L 831.5 231 L 831 106 L 542.5 106 L 542 106.5 L 542 2100.5 L 541 2100.5 L 541 106.5 L 542.5 105 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_4"
          d="M 546.5 1198 L 547 1281 L 604 1281 L 604 1198.5 L 605 1198.5 L 605 1282 L 546 1282 L 546.5 1198 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_5"
          d="M 668.5 1848 L 830.5 1848 L 832 1849.5 L 831.5 2101 L 831 1849 L 668.5 1849 L 668.5 1848 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_6"
          d="M 1 1 L 924 1 L 924 2101 L 841.5 2101 L 841 2100.5 L 841 84 L 84 84 L 84 2100.5 L 83.5 2101 L 1 2101 L 1 1 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_7"
          d="M 542 106 L 831 106 L 831 231 L 668.5 231 L 668 231.5 L 668 1849 L 831 1849 L 831 2101 L 542.5 2101 L 542 2100.5 L 542 106 Z M 546 1198 L 546 1282 L 605 1282 L 605 1198 L 546 1198 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_8"
          d="M 0.5 1 L 1 2100.5 L 0 2100.5 L 0.5 1 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_9"
          d="M 924.5 1 L 925 2100.5 L 924 2100.5 L 924.5 1 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_10"
          d="M 85.5 84 L 840 84.5 L 85.5 85 L 85.5 84 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_11"
          d="M 668.5 231 L 831 231.5 L 668.5 232 L 668.5 231 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PocketDoor')

export default icon
