import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 113.317H15.9894V125.714C15.9894 128.019 17.0658 129.763 18.5472 130.917C20.0163 132.062 21.8896 132.635 23.5439 132.635H33.0164C34.6707 132.635 36.544 132.062 38.0131 130.917C39.4944 129.763 40.5709 128.019 40.5709 125.714V113.317H108.429V125.714C108.429 128.019 109.506 129.763 110.987 130.917C112.456 132.062 114.329 132.635 115.984 132.635H125.456C127.11 132.635 128.984 132.062 130.453 130.917C131.934 129.763 133.011 128.019 133.011 125.714V113.317H141H141.5V112.817V70.8621C141.5 64.1132 137.097 58.3941 131.008 56.4183L116.194 26.2888C113.829 20.7965 108.14 17.5 102.174 17.5H46.8264C40.8602 17.5 35.1711 20.7965 32.8062 26.2888L17.9924 56.4183C11.9032 58.3941 7.5 64.1132 7.5 70.8621V112.817V113.317H8ZM111.846 28.2615L111.852 28.2755L111.859 28.2891L125.105 55.2305H23.8952L37.1415 28.2891L37.1482 28.2755L37.154 28.2615C38.6508 24.6833 42.4908 22.273 46.8264 22.273H102.174C106.509 22.273 110.349 24.6833 111.846 28.2615ZM12.273 70.8621C12.273 65.1134 16.9333 60.4531 22.6821 60.4531H126.318C132.067 60.4531 136.727 65.1134 136.727 70.8621V108.544H12.273V70.8621ZM33.0164 127.862H23.5439C21.9021 127.862 20.7624 126.805 20.7624 125.714V113.77H35.7979V125.714C35.7979 126.805 34.6582 127.862 33.0164 127.862ZM125.456 127.862H115.984C114.342 127.862 113.202 126.805 113.202 125.714V113.77H128.238V125.714C128.238 126.805 127.098 127.862 125.456 127.862Z" fill="currentColor" stroke="currentColor" strokeMiterlimit="10"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.5911 86.3852H28.1366C25.5885 86.3852 23.5228 84.3195 23.5228 81.7714V76.3169C23.5228 73.7688 25.5885 71.7031 28.1366 71.7031H33.5911C36.1393 71.7031 38.2049 73.7688 38.2049 76.3169V81.7714C38.2049 84.3195 36.1393 86.3852 33.5911 86.3852ZM28.1366 84.4987H33.5911C35.0974 84.4987 36.3184 83.2776 36.3184 81.7714V76.3169C36.3184 74.8107 35.0974 73.5896 33.5911 73.5896H28.1366C26.6304 73.5896 25.4094 74.8107 25.4094 76.3169V81.7714C25.4094 83.2776 26.6304 84.4987 28.1366 84.4987Z" fill="currentColor"/>
      <path d="M99.0453 79.9898H49.9547V78.1033H99.0453V79.9898Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M115.409 86.3852H120.863C123.412 86.3852 125.477 84.3195 125.477 81.7714V76.3169C125.477 73.7688 123.412 71.7031 120.863 71.7031H115.409C112.861 71.7031 110.795 73.7688 110.795 76.3169V81.7714C110.795 84.3195 112.861 86.3852 115.409 86.3852ZM120.863 84.4987H115.409C113.903 84.4987 112.682 83.2776 112.682 81.7714V76.3169C112.682 74.8107 113.903 73.5896 115.409 73.5896H120.863C122.37 73.5896 123.591 74.8107 123.591 76.3169V81.7714C123.591 83.2776 122.37 84.4987 120.863 84.4987Z" fill="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Car')

export default icon
