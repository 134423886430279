import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M138.229 36H10.7708C10.036 36 9.33122 36.2336 8.8116 36.6493C8.29197 37.065 8.00004 37.6288 8 38.2167V98.0667C8.00004 98.6546 8.29197 99.2184 8.8116 99.6341C9.33122 100.05 10.036 100.283 10.7708 100.283H66.1875V104.715L41.9359 109.776C41.5839 109.85 41.2534 109.978 40.9633 110.154C40.6732 110.329 40.4291 110.549 40.2452 110.8C40.0612 111.051 39.9408 111.329 39.8909 111.617C39.8411 111.905 39.8627 112.199 39.9546 112.48C40.0464 112.762 40.2067 113.026 40.4263 113.259C40.6459 113.491 40.9205 113.686 41.2344 113.833C41.5483 113.98 41.8953 114.077 42.2557 114.116C42.6161 114.156 42.9828 114.139 43.3349 114.066L74.5 107.562L105.665 114.066C106.017 114.139 106.384 114.156 106.744 114.116C107.105 114.077 107.452 113.98 107.766 113.833C108.08 113.686 108.354 113.491 108.574 113.259C108.793 113.026 108.954 112.762 109.045 112.48C109.137 112.199 109.159 111.905 109.109 111.617C109.059 111.329 108.939 111.051 108.755 110.8C108.571 110.549 108.327 110.329 108.037 110.154C107.747 109.978 107.416 109.85 107.064 109.776L82.8125 104.715V100.283H138.229C138.964 100.283 139.669 100.05 140.188 99.6341C140.708 99.2184 141 98.6546 141 98.0667V38.2167C141 37.6288 140.708 37.065 140.188 36.6493C139.669 36.2336 138.964 36 138.229 36V36ZM77.2708 102.5H71.7292V100.283H77.2708V102.5Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Tv')

export default icon
