import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '35px',
  },
  status: {
    marginLeft: 8,
  },
  red: {
    color: '#DE2027',
  },
  green: {
    color: '#7ED321',
  },
}))

export default useStyles
