import React from 'react'
import Typography from '@mui/material/Typography'
import {
  ZwDoorSensorOpenedIcon, ZwDoorSensorClosedIcon, ZwMotionIcon, TamperIcon,
} from 'icons'
import classNames from 'classnames'
import { Optional } from '@app/@types/common'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { zwaveSensorValueTypes } from '@app/helpers/myDevices/zwaveSensorValueTypes'
import useStyles from './SensorStatus.style'

interface Props {
  sensorType: Optional<string>,
  sensorStatus: Optional<boolean>,
}

const SensorStatus: React.FC<Props> = ({
  sensorType,
  sensorStatus,
}) => {
  const classes = useStyles()

  const renderSensorIcon = () => {
    switch (sensorType) {
    case 'Door/Window':
      return sensorStatus
        ? <ZwDoorSensorOpenedIcon className={classes.icon} />
        : <ZwDoorSensorClosedIcon className={classes.icon} />
    case 'Motion':
      return <ZwMotionIcon className={classes.icon} />
    case 'Tamper':
      return <TamperIcon className={classes.icon} />
    default:
      return null
    }
  }

  return (
    <Wrapper className={classNames(classes.wrapper,
      sensorStatus ? classes.red : classes.green)}
    >
      {renderSensorIcon()}
      <Typography className={classNames(classes.status,
        sensorStatus ? classes.red : classes.green)}
      >
        {zwaveSensorValueTypes(sensorType, sensorStatus)}
      </Typography>
    </Wrapper>
  )
}

export default SensorStatus
