import { IAppConfig, IItemConfig } from '@app/@types/appConfig'

export enum Activation {
  Activated = 'activated',
  NotActivated = 'not_activated',
}

export enum States {
  Visible = 'visible',
  Disabled = 'disabled',
  Invisible = 'invisible',
}

export enum Role {
  Admin = 'admin',
  SubAdmin = 'sub_admin',
  Family = 'family',
  Guest = 'guest',
  NoUnitDevice = 'no_unit_device',
}

export interface IConfigForRoles {
    admin: States,
    guest: States,
    family: States,
    sub_admin: States,
  }

export interface IConfigStateActivation {
  activated: IConfigForRoles,
  not_activated: IConfigForRoles,
  without_device: States,
}

export interface IConfigStateItem {
  code: string,
  state: IConfigStateActivation,
  title: string,
}
export interface IConfigResultStateItem {
  code: string,
  state: States,
  title: string,
}

export const defaultConfigNCentral = {
  home: {
    items: [
      { code: 'security', state: States.Visible },
      { code: 'devices', state: States.Visible },
      { code: 'scenes', state: States.Disabled },
      { code: 'energy', state: States.Invisible },
      { code: 'health', state: States.Invisible }],
    elements: {
      arm_disarm_panel: States.Visible,
      events_panel: States.Visible,
    },
  },
  menu: {
    all_menu: States.Visible,
    guard_panel: States.Visible,
    controls: [{
      code: 'home',
      state: States.Visible,
      items: [{
        code: 'security',
        state: States.Visible,
        items: [
          { code: 'events', state: States.Visible },
          { code: 'status', state: States.Visible },
        ],

      },
      { code: 'scenes', state: States.Visible },
      { code: 'devices', state: States.Visible },
      { code: 'energy', state: States.Visible },
      ],
    },
    {
      code: 'users',
      state: States.Visible,
    },
    ],
    settings: [{
      code: 'application',
      state: States.Visible,
      items: [
        { code: 'synchronize_all', state: States.Visible },
        { code: 'notification_settings', state: States.Visible },
        { code: 'alert_contacts', state: States.Visible },
        { code: 'reminders', state: States.Visible },
      ],
    },
    {
      code: 'automation',
      state: States.Visible,
      items: [
        { code: 'timers', state: States.Visible },
        { code: 'geofences', state: States.Visible },
        { code: 'device_rooms', state: States.Visible },
        { code: 'device_groups', state: States.Visible },
      ],
    },
    {
      code: 'hardware',
      state: States.Visible,
      items: [
        { code: 'cloud_activity', state: States.Visible },
        { code: 'device_time', state: States.Visible },
        { code: 'device_location', state: States.Visible },
        { code: 'hardware_errors', state: States.Visible },
      ],
    },
    {
      code: 'submenu_security',
      state: States.Visible,
    },
    ],
    other: [{
      code: 'about',
      state: States.Visible,
    },
    {
      code: 'firmware_update',
      state: States.Visible,
    },
    {
      code: 'help',
      state: States.Visible,
    },
    ],
    actions: [{
      code: 'synchronize_all',
      state: States.Visible,
    }],
    toolbar: [{
      code: 'secrets',
      state: States.Visible,
    }],
  },
  devices: {
    fab: {
      state: 'invisible',
    },
    twr: {
      delete: {
        state: 'invisible',
      },
      details_info: {
        state: 'invisible',
      },
      advanced_settings: {
        state: 'invisible',
      },
      zones: {
        state: 'visible',
      },
    },
    mezzo_lights: {
      code: 'mezzo_lights',
      state: 'invisible',
    },
  },
}
const defaultConfigMezzo = {
  home: {
    items: [
      { code: 'security', state: States.Visible },
      { code: 'devices', state: States.Visible },
      { code: 'scenes', state: States.Visible },
      { code: 'energy', state: States.Visible },
      { code: 'health', state: States.Visible }],
    elements: {
      arm_disarm_panel: States.Visible,
      events_panel: States.Visible,
    },
  },
  menu: {
    all_menu: States.Visible,
    guard_panel: States.Visible,
    controls: [{
      code: 'home',
      state: States.Visible,
      items: [{
        code: 'security',
        state: States.Visible,
        items: [
          { code: 'events', state: States.Visible },
          { code: 'status', state: States.Visible },
        ],

      },
      { code: 'scenes', state: States.Visible },
      { code: 'devices', state: States.Visible },
      { code: 'energy', state: States.Visible },
      ],
    },
    {
      code: 'users',
      state: States.Visible,
    },
    ],
    settings: [{
      code: 'application',
      state: States.Visible,
      items: [
        { code: 'synchronize_all', state: States.Visible },
        { code: 'notification_settings', state: States.Visible },
        { code: 'alert_contacts', state: States.Visible },
        { code: 'reminders', state: States.Visible },
      ],
    },
    {
      code: 'automation',
      state: States.Visible,
      items: [
        { code: 'timers', state: States.Visible },
        { code: 'geofences', state: States.Visible },
        { code: 'device_rooms', state: States.Visible },
        { code: 'device_groups', state: States.Visible },
      ],
    },
    {
      code: 'hardware',
      state: States.Visible,
      items: [
        { code: 'cloud_activity', state: States.Visible },
        { code: 'device_time', state: States.Visible },
        { code: 'device_location', state: States.Visible },
        { code: 'hardware_errors', state: States.Visible },
      ],
    },
    {
      code: 'submenu_security',
      state: States.Visible,
      items: [
        { code: 'security_rules', state: States.Visible },
        { code: 'take_on_board_settings', state: States.Visible },
        { code: 'alarm_reporting_settings', state: States.Visible },
      ],
    },
    ],
    other: [{
      code: 'about',
      state: States.Visible,
    },
    {
      code: 'firmware_update',
      state: States.Visible,
    },
    {
      code: 'help',
      state: States.Visible,
    },
    ],
    actions: [{
      code: 'synchronize_all',
      state: States.Visible,
    }],
    toolbar: [{
      code: 'secrets',
      state: States.Visible,
    }],
  },
  devices: {
    fab: {
      state: 'visible',
    },
    twr: {
      delete: {
        state: 'visible',
      },
      details_info: {
        state: 'visible',
      },
      advanced_settings: {
        state: 'visible',
      },
      zones: {
        state: 'invisible',
      },
    },
    mezzo_lights: {
      code: 'mezzo_lights',
      state: 'visible',
    },
  },
}
export const defaultConfig = defaultConfigMezzo

const useDevice = (config: any, isNCentral: boolean) => {
  const withDeviceConfig = isNCentral ? config.find((item: any) => item.app_config.title === 'Ness Central')
    : config.find((item: any) => item.app_config.title === 'Mezzo')
  return withDeviceConfig.app_config
}

const useActivation = (configState: IConfigStateActivation, activation: Activation | null) => {
  switch (activation) {
  case null:
    return configState.without_device
  case Activation.Activated:
    return configState.activated
  case Activation.NotActivated:
    return configState.not_activated
  default:
    return configState.without_device
  }
}

const useRole = (configStateRole: IConfigForRoles | States, role: Role) => {
  if (configStateRole === States.Visible || configStateRole === States.Disabled || configStateRole === States.Invisible) {
    return configStateRole
  }
  switch (role) {
  case Role.NoUnitDevice:
    return configStateRole
  case Role.Admin:
    return configStateRole.admin
  case Role.SubAdmin:
    return configStateRole.sub_admin
  case Role.Family:
    return configStateRole.family
  case Role.Guest:
    return configStateRole.guest
  default:
    return configStateRole.guest
  }
}

const selectElements = (config: any, resultConfig: IItemConfig, activation: Activation | null, role: Role) => {
  config.forEach((el: any, index: number) => {
    convertElement(resultConfig[index], config[index], activation, role)
  })
}

const convertElement = (resultItem: any, config: any, activation: Activation | null, role: Role) => {
  Object.keys(config).forEach((key) => {
    if (key === 'state') {
      resultItem.state = getResultedValue(config[key], activation, role)
    } if (key === 'items') {
      config[key].forEach((item: any, index: number) => convertElement(resultItem.items[index], item, activation, role))
    }
  })
}

const getResultedValue = (stateItem: IConfigStateActivation, activation: Activation | null, role: Role) => {
  const filteredActivation = useActivation(stateItem, activation)
  return useRole(filteredActivation, role)
}

export const convertAppConfig = (
  config: any,
  isNCentral: boolean,
  activation: Activation | null,
  role: Role | null,
): IAppConfig => {
  if (!config || !config.length || isNCentral === null || role === null) {
    return defaultConfig
  }
  const currentDefaultConfig = isNCentral ? defaultConfigNCentral : defaultConfigMezzo
  const resultConfig = JSON.parse(JSON.stringify(currentDefaultConfig))
  const withDeviceConfig = useDevice(config, isNCentral)

  if (withDeviceConfig.home.items !== undefined) {
    const homeScreenItems = withDeviceConfig.home.items
    homeScreenItems.forEach((homeItem: IConfigStateItem) => {
      const filteredActivation = useActivation(homeItem.state, activation)
      const filteredRole = useRole(filteredActivation, role)
      const resultIndex = resultConfig.home.items.findIndex((el: IConfigResultStateItem) => el.code === homeItem.code)
      if (resultIndex !== -1) {
        resultConfig.home.items[resultIndex].state = filteredRole
      }

      if (homeItem.code === 'arm_disarm_panel') {
        resultConfig.home.elements.arm_disarm_panel = filteredRole
      }
      if (homeItem.code === 'events_panel') {
        resultConfig.home.elements.events_panel = filteredRole
      }
    })
  }

  if (Object.keys(withDeviceConfig.menu_web).length === 0) {
    return resultConfig
  }
  const menuItems = withDeviceConfig.menu_web
  if (menuItems.all_menu.state !== undefined) {
    resultConfig.menu.all_menu = getResultedValue(menuItems.all_menu.state, activation, role)
  }
  if (menuItems.guard_panel.state !== undefined) {
    resultConfig.menu.guard_panel = getResultedValue(menuItems.guard_panel.state, activation, role)
  }

  if (menuItems.controls !== undefined) {
    selectElements(menuItems.controls, resultConfig.menu.controls, activation, role)
  }
  if (menuItems.settings !== undefined) {
    selectElements(menuItems.settings, resultConfig.menu.settings, activation, role)
  }
  if (menuItems.other !== undefined) {
    selectElements(menuItems.other, resultConfig.menu.other, activation, role)
  }
  if (menuItems.actions !== undefined) {
    selectElements(menuItems.actions, resultConfig.menu.actions, activation, role)
  }
  if (menuItems.toolbar !== undefined) {
    selectElements(menuItems.toolbar, resultConfig.menu.toolbar, activation, role)
  }

  if (Object.keys(withDeviceConfig.devices).length === 0) {
    return resultConfig
  }
  const devicesItems = withDeviceConfig.devices
  if (devicesItems.fab !== undefined) {
    resultConfig.devices.fab.state = getResultedValue(devicesItems.fab.state, activation, role)
  }
  if (devicesItems.mezzo_lights !== undefined) {
    resultConfig.devices.mezzo_lights.state = getResultedValue(devicesItems.mezzo_lights.state, activation, role)
  }
  if (devicesItems.twr !== undefined) {
    const twrItems = devicesItems.twr
    if (twrItems.delete !== undefined) {
      resultConfig.devices.twr.delete.state = getResultedValue(twrItems.delete.state, activation, role)
    }
    if (twrItems.details_info !== undefined) {
      resultConfig.devices.twr.details_info.state = getResultedValue(twrItems.details_info.state, activation, role)
    }
    if (twrItems.advanced_settings !== undefined) {
      resultConfig.devices.twr.advanced_settings.state = getResultedValue(twrItems.advanced_settings.state, activation, role)
    }
    if (twrItems.zones !== undefined) {
      resultConfig.devices.twr.zones.state = getResultedValue(twrItems.zones.state, activation, role)
    }
  }

  return resultConfig
}

export default convertAppConfig
