const styles = (theme) => ({
  root: {
    padding: 15,
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    boxShadow: '0 5px 5px 0 rgba(0,0,0, 0.3)',
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: 5,
  },
  refreshIcon: {
    alignSelf: 'flex-end',
    marginRight: '7%',
  },
  refreshIconMobile: {
    marginRight: 10,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0 160px',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    boxSizing: 'border-box',
  },
  mainMobile: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  groupsCard: {
    boxSizing: 'border-box',
    width: '46%',
    height: 57,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '12px 4%',
    margin: '2%',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  cardMobile: {
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
  addGroupButton: {
    fontSize: 20,
  },
  deleteGroupButton: {
    fontSize: 16,
    marginLeft: 45,
  },
  cardControls: {
    display: 'flex',
    width: 75,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconBackground: {
    width: 46,
    height: 46,
    boxSizing: 'border-box',
    position: 'relative',
  },
  moreInfoButton: {
    position: 'absolute',
    top: 8,
    right: 17,
  },
  noGroups: {
    margin: '0 auto',
    marginTop: '20%',
    textTransform: 'uppercase',
    fontSize: 20,
  },
  infoPopup: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 50,
    height: 40,
  },
  addButton: {
    position: 'fixed',
    bottom: 20,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    backgroundColor: theme.palette.primary.main,
    zIndex: 101,
    color: theme.backgrounds.cardBackground,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  infoIcon: {
    width: 20,
    height: 20,
  },
  link: {
    color: theme.typography.color,
    textDecoration: 'none',
    padding: 0,
    marginLeft: 'auto',
  },
})

export default styles
