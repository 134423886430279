import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { RESET_ALL_TIMERS } from '@app/constants/timers/timers'
import Api from '@app/api/timers/resetAllTimers'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const resetAllTimersLogic = createLogic({
  type: RESET_ALL_TIMERS,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(RESET_ALL_TIMERS))
    Api.resetAllTimers(action.accessToken)
      .then(() => {
        dispatch(apiRequestDone(RESET_ALL_TIMERS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('resetAllTimers failed without error')
        }
      })
      .then(() => done())
  },
})

export default resetAllTimersLogic
