import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ListItem, ListItemText, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import ListItemIcon from '@mui/material/ListItemIcon'
import parseDate from '@app/helpers/parseDate'
import parseTime from '@app/helpers/parseTime'
import { iconMap } from '@app/lib/Events/icons'

import styles from './EventItem.style'

class EventItem extends React.PureComponent {
  render() {
    const { event, classes, isMobile } = this.props
    return (
      <ListItem
        className={classNames(
          classes.button,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
        )}
        divider
        dense
      >
        <ListItemIcon>
          {iconMap[event.icon]}
        </ListItemIcon>
        <Typography className={classNames(classes.message, isMobile && classes.messageMobile)}>{event.message}</Typography>
        <ListItemText
          className={classes.date}
          primary={parseDate(event.happened_at)}
          secondary={parseTime(event.happened_at)}
        />
      </ListItem>
    )
  }
}

EventItem.propTypes = {
  event: PropTypes.shape({
    created_at: PropTypes.string,
    happened_at: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default withStyles(styles)(EventItem)
