import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  navBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 42,
    background: '#272827',
    position: 'relative',
  },
  title: {
    color: '#fff',
    fontSize: '22px',
    lineHeight: '26px',
  },
  titleCentered: {
    marginLeft: 'calc(50% - 140px)',
  },
  centeredWithoutBackButton: {
    paddingLeft: 60,
  },
  button: {
    marginRight: 34,
  },
  buttonMobile: {
    marginRight: 18,
    position: 'absolute',
    left: 0,
    top: 0,
    height: 42,
    width: 42,
  },
  buttonWithCenteredTitleMobile: {
    marginRight: 12,
  },
  submit: {
    textTransform: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    height: 42,
    padding: 12,
  },
  noBackButton: {
    position: 'relative',
    left: -20,
  },
}))

export default useStyles
