import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import {
  filterAddTwrDevices,
  filterAddZwaveDevices,
  groupAddDevice,
  syncGroupDevices,
} from '@app/actions/groups/groups'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import GroupsAddDevice from '@app/components/Groups/AddDeviceToGroup/AddDeviceToGroup'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

let enhance = GroupsAddDevice

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const {
    groups: {
      zWaveDevices,
      twrDevices,
      freeTwrDevices,
      freeZwaveDevices,
    },
  } = store.getState()
  return {
    isMobile,
    isTablet,
    zWaveDevices,
    twrDevices,
    freeTwrDevices,
    freeZwaveDevices,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncGroupDevices,
  tryAgain: syncGroupDevices,
  notifications: syncNotifications.sync,
  filterAddTwrDevices,
  filterAddZwaveDevices,
  groupAddDevice,
}, dispatch)

enhance = withRouter(enhance)
enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const GroupsAddDeviceEnhanced = enhance

export default GroupsAddDeviceEnhanced
