import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchUnits = (access_token) => Axios.get(`${API_URL}/api/unit_devices/sphere`, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchUnits,
}
