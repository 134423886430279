import React, { useState, useEffect } from 'react'
import useStyles from './RadarCircles.style'

interface Circle {
  id: number,
}

const RadarCircles: React.FC = () => {
  const classes = useStyles()
  const [circles, setCircles] = useState<Array<Circle>>([])

  useEffect(() => {
    const animationInterval = setInterval(addCircle, 800)

    return () => {
      clearInterval(animationInterval)
    }
  }, [])

  const addCircle = (): void => {
    const circle: Circle = {
      id: new Date().getTime(),
    }

    setCircles((prevCircles) => [...prevCircles, circle])
  }

  const removeCircle = (id: number): void => {
    setCircles((prevCircles) => prevCircles.filter((circle) => circle.id !== id))
  }

  return (
    <div className={classes.circlesContainer}>
      {circles.map((circle) => (
        <div
          key={circle.id}
          className={classes.circle}
          onAnimationEnd={() => removeCircle(circle.id)}
        />
      ))}
    </div>
  )
}

export default RadarCircles
