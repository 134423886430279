import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { isIOS } from 'react-device-detect'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'
import CreateActionSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/CreateActionSelected/CreateActionSelected'
import CreateActionSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/CreateActionSelected/CreateActionSelected'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? CreateActionSelectedIOS : CreateActionSelected

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isSaveSceneDraftPopupOpen,
    sceneMode,
    isAdditionalActionMode,
    isAdditionalConditionMode,
    sceneActionTypes,
  } = state.scenes.currentSceneCreating
  const {
    currentAction: {
      actionDescription,
      stateType,
      stateDescription,
      actionType,
      actionDeviceName,
      colorType,
      delayDescription,
      durationDescription,
      durationValueAfter,
      colorTypeDurationValueAfter,
    },
  } = state.scenes.currentSceneCreating
  const subDeviceName = state.scenes.currentSceneCreating.currentAction.currentSubDevice.name
  const { isEditDraftSavePopupOpen, isEditSceneQuitPopupOpen } = state.scenes.editScene
  return {
    isMobile,
    path,
    actionDescription,
    stateType,
    stateDescription,
    isSaveSceneDraftPopupOpen,
    sceneMode,
    isAdditionalActionMode,
    isAdditionalConditionMode,
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
    actionType,
    actionDeviceName,
    colorType,
    delayDescription,
    durationDescription,
    durationValueAfter,
    colorTypeDurationValueAfter,
    sceneActionTypes,
    subDeviceName,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const CreateActionSelectedEnhanced = enhance

export default CreateActionSelectedEnhanced
