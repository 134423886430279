import React from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, Typography,
} from '@mui/material'
import useStyles from '../DeviceInfo.style'

interface Props {
  clearInterviewResult: () => void,
}

const SleepingModePopup: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { clearInterviewResult } = props
  return (
    <Dialog open onClose={clearInterviewResult}>
      <DialogTitle>{I18n.t('titles.warning')}</DialogTitle>
      <DialogContent className={classes.sleepingPopupContent}>
        <Typography>{I18n.t('my_devices.in_sleep')}</Typography>
        <Button
          className={classes.sleepingPopupButton}
          color="primary"
          onClick={clearInterviewResult}
        >
          {I18n.t('buttons.okay')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default SleepingModePopup
