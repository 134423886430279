import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.84 120.444H66.3614C68.7255 120.444 70.7014 118.619 70.7014 116.305V33.654C70.7014 31.3428 68.7282 29.5 66.3614 29.5H11.84C9.4732 29.5 7.5 31.3428 7.5 33.654V116.29C7.5 118.601 9.4732 120.444 11.84 120.444ZM11.6093 33.654C11.6093 33.5686 11.6882 33.4587 11.84 33.4587H66.3614C66.5132 33.4587 66.5921 33.5686 66.5921 33.654V116.305C66.5921 116.39 66.5132 116.5 66.3614 116.5H11.84C11.6882 116.5 11.6093 116.39 11.6093 116.305V33.654Z" fill="currentColor" stroke="currentColor"/>
      <path d="M17.543 89.5451C17.543 100.895 27.2428 110.09 39.1084 110.09C50.9741 110.09 60.6739 100.895 60.6739 89.5451C60.6739 78.1952 50.9741 69 39.1084 69C27.2428 69 17.543 78.1952 17.543 89.5451ZM21.6523 89.5451C21.6523 80.4209 29.4578 72.9587 39.1084 72.9587C48.7591 72.9587 56.5646 80.4209 56.5646 89.5451C56.5646 98.6693 48.7591 106.132 39.1084 106.132C29.4578 106.132 21.6523 98.6693 21.6523 89.5451Z" fill="currentColor" stroke="currentColor"/>
      <path d="M26.3604 51.8026H51.8566C53.0108 51.8026 53.989 50.9066 53.989 49.7493V41.9088C53.989 40.7515 53.0108 39.8555 51.8566 39.8555H26.3604C25.2063 39.8555 24.228 40.7515 24.228 41.9088V49.7493C24.228 50.9066 25.2063 51.8026 26.3604 51.8026ZM49.8797 43.8142V47.8439H28.3373V43.8142H49.8797Z" fill="currentColor" stroke="currentColor"/>
      <path d="M78.7986 103.926H78.2986V104.426V116.29C78.2986 118.601 80.2718 120.444 82.6386 120.444H137.16C139.528 120.444 141.482 118.616 141.5 116.309V116.305V33.654C141.5 31.3428 139.527 29.5 137.16 29.5H82.6386C80.2718 29.5 78.2986 31.3428 78.2986 33.654V98.5675V99.0675H78.7986H81.9079H82.4079V98.5675V33.654C82.4079 33.5686 82.4868 33.4587 82.6386 33.4587H137.16C137.312 33.4587 137.391 33.5686 137.391 33.654V116.29C137.391 116.375 137.312 116.485 137.16 116.485H82.6386C82.4868 116.485 82.4079 116.375 82.4079 116.29V104.426V103.926H81.9079H78.7986Z" fill="currentColor" stroke="currentColor"/>
      <path d="M30.291 89.5434C30.291 94.2067 34.2697 97.9579 39.1084 97.9579C43.947 97.9579 47.9257 94.2067 47.9257 89.5434C47.9257 84.8801 43.947 81.1289 39.1084 81.1289C34.2697 81.1289 30.291 84.8801 30.291 89.5434ZM34.4003 89.5434C34.4003 87.1059 36.4847 85.0876 39.1084 85.0876C41.732 85.0876 43.8164 87.1059 43.8164 89.5434C43.8164 91.9809 41.732 93.9992 39.1084 93.9992C36.4847 93.9992 34.4003 91.9809 34.4003 89.5434Z" fill="currentColor" stroke="currentColor"/>
      <path d="M88.3262 89.5451C88.3262 100.895 98.026 110.09 109.892 110.09C121.773 110.09 131.457 100.895 131.457 89.5451C131.457 78.1952 121.757 69 109.892 69C98.026 69 88.3262 78.1952 88.3262 89.5451ZM92.4355 89.5451C92.4355 80.4209 100.241 72.9587 109.892 72.9587C119.542 72.9587 127.348 80.4209 127.348 89.5451C127.348 98.6693 119.542 106.132 109.892 106.132C100.241 106.132 92.4355 98.6693 92.4355 89.5451Z" fill="currentColor" stroke="currentColor"/>
      <path d="M101.074 89.5434C101.074 94.2067 105.053 97.9579 109.892 97.9579C114.73 97.9579 118.709 94.2067 118.709 89.5434C118.709 84.8801 114.73 81.1289 109.892 81.1289C105.053 81.1289 101.074 84.8801 101.074 89.5434ZM105.184 89.5434C105.184 87.1059 107.268 85.0876 109.892 85.0876C112.515 85.0876 114.6 87.1059 114.6 89.5434C114.6 91.9809 112.515 93.9992 109.892 93.9992C107.268 93.9992 105.184 91.9809 105.184 89.5434Z" fill="currentColor" stroke="currentColor"/>
      <path d="M97.1434 51.8026H122.64C123.794 51.8026 124.772 50.9066 124.772 49.7493V41.9088C124.772 40.7515 123.794 39.8555 122.64 39.8555H97.1434C95.9892 39.8555 95.011 40.7515 95.011 41.9088V49.7493C95.011 50.9066 95.9892 51.8026 97.1434 51.8026ZM120.663 43.8142V47.8439H99.1203V43.8142H120.663Z" fill="currentColor" stroke="currentColor"/>
      <path d="M32.9832 58.4961H32.4832V58.9961V61.9548V62.4548H32.9832H36.8698H37.3698V61.9548V58.9961V58.4961H36.8698H32.9832Z" fill="currentColor" stroke="currentColor"/>
      <path d="M41.3472 58.4961H40.8472V58.9961V61.9548V62.4548H41.3472H45.2338H45.7338V61.9548V58.9961V58.4961H45.2338H41.3472Z" fill="currentColor" stroke="currentColor"/>
      <path d="M103.766 58.4961H103.266V58.9961V61.9548V62.4548H103.766H107.653H108.153V61.9548V58.9961V58.4961H107.653H103.766Z" fill="currentColor" stroke="currentColor"/>
      <path d="M112.13 58.4961H111.63V58.9961V61.9548V62.4548H112.13H116.017H116.517V61.9548V58.9961V58.4961H116.017H112.13Z" fill="currentColor" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Speaker')

export default icon
