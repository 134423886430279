import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginTop: 100,
  },
}))

export default useStyles
