import { createLogic } from 'redux-logic'
import { SYNC_TWR_CHANNEL } from '@app/constants/myDevices/channels/twr/twrChannels'
import { syncTwrChannelSent, setResponseFromTwrChannel } from '@app/actions/myDevices/channels/twr/twrChannel'
import Api from '@app/api/myDevices/channels/twr/twrChannels'

const subscribeTwrDeviceLogic = createLogic({
  type: SYNC_TWR_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process({ action }, dispatch) {
    Api.myDevicesTwrChannel({
      request: () => {
        dispatch(syncTwrChannelSent())
      },
      response: (response) => {
        store.dispatch(setResponseFromTwrChannel(response.status))
      },
    }, action.newDevice.id)
  },
})

export default subscribeTwrDeviceLogic
