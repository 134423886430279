export const loginFields = [
  {
    title: 'email_or_phone', autocomplete: 'nope',
  },
  {
    title: 'password', autocomplete: 'new-password',
  },
]

export const signUpFields = [
  {
    title: 'first_name', value: '', hint: '', autocomplete: 'nope',
  },
  {
    title: 'last_name', value: '', hint: '', autocomplete: 'nope',
  },
  {
    title: 'preferred_name', value: '', hint: '', autocomplete: 'nope',
  },
  {
    title: 'phone_number', value: '', hint: '', autocomplete: 'nope',
  },
  {
    title: 'email', value: '', hint: '', autocomplete: 'nope',
  },
  {
    title: 'password', value: '', hint: '', autocomplete: 'new-password',
  },
  {
    title: 'confirm_password', value: '', hint: '', autocomplete: 'new-password',
  },
]
