const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    position: 'relative',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '60px 0 25px 0',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  emptyMain: {
    width: '90%',
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 400,
    height: '100%',
  },
  refreshIcon: {
    position: 'absolute',
    right: 10,
    top: 125,
    marginRight: 30,
  },
  card: {
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '3% 10%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  noGeofences: {
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontSize: 20,
  },
  addButton: {
    position: 'fixed',
    bottom: 30,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    backgroundColor: theme.palette.primary.main,
    zIndex: 101,
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
