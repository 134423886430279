import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import getConditionDataForBackend from '@app/helpers/scenes/getConditionDataForBackend'
import { POST_CONDITION_CHANGESET } from '@app/constants/scenes/currentSceneCreating'
import { saveChangesetAction } from '@app/actions/scenes/editScene'
import { clearCurrentSceneAll } from '@app/actions/scenes/scenesList'
import postCondition from '@app/api/scenes/postCondition'
import patchScene from '@app/api/scenes/patchScene'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const postConditionChangesetLogic = createLogic({
  type: POST_CONDITION_CHANGESET,
  cancelType: API_REQUEST_CANCEL,

  async process(_, dispatch, done) {
    const { secrets: { accessToken: { token: accessToken } } } = store.getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = store.getState()
    const { scenes: { editScene: { changesetAction } } } = store.getState()
    const {
      scenes:
      {
        currentSceneCreating:
        {
          currentCondition, screenName,
        },
      },
    } = store.getState()
    const conditionData = getConditionDataForBackend(currentCondition)
    const changesetData = { changeset: changesetAction }
    dispatch(apiRequestSent(POST_CONDITION_CHANGESET))
    try {
      await postCondition.postCondition(accessToken, id, conditionData)
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('postCondition failed without error')
      }
    }
    try {
      await patchScene.patchScene(accessToken, id, changesetData)
      history.push(screenName)
      dispatch(clearCurrentSceneAll())
      dispatch(saveChangesetAction(''))
      dispatch(apiRequestDone(POST_CONDITION_CHANGESET))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('patchScene failed without error')
      }
    }
    done()
  },
})

export default postConditionChangesetLogic
