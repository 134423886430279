import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { withStyles } from '@app/components/styles'
import {
  billingPeriodsStatsClose,
  billingPeriodsStatsOpen,
  setUpBillingPeriodClose,
  setUpBillingPeriodOpen,
} from '@app/actions/myEnergy/billingPeriod'
import { syncCurrentStats } from '@app/actions/myEnergy/billingCurrentStats'
import { syncPeriodsStats } from '@app/actions/myEnergy/billingPeriodsStats'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import BillingPeriod from '@app/components/MyEnergy/Tabs/BillingPeriod/BillingPeriod'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'

let enhance = withStyles(BillingPeriod)

const mapStateToProps = (state) => {
  const { billingCurrentStats } = state.billingCurrentStats
  const { isSetUpPeriodPopupOpen } = state.billingPeriod
  const { isBillingPeriodsStatsPopupOpen } = state.billingPeriod
  const { isMobile } = state.mobileDetector
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isSetUpPeriodPopupOpen,
    isBillingPeriodsStatsPopupOpen,
    billingCurrentStats,
    isMobile,
    admin,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncCurrentStats,
  tryAgain: syncCurrentStats,
  notifications: syncNotifications.sync,
  setUpBillingPeriodOpen,
  billingPeriodsStatsOpen,
  setUpBillingPeriodClose,
  billingPeriodsStatsClose,
  syncPeriodsStats,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const BillingPeriodEnhanced = enhance

export default BillingPeriodEnhanced
