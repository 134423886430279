import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_ACCOUNT } from '@app/constants/wizard/deleteAccount'
import Api from '@app/api/wizard/deleteAccount'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { history } from '@app/store/history'
import { SIGN_IN } from '../../constants/routes'

const deleteAccountLogic = createLogic({
  type: DELETE_ACCOUNT,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(DELETE_ACCOUNT))
    Api.deleteAccount(action.username, action.password)
      .then(() => {
        dispatch(apiRequestDone(DELETE_ACCOUNT))
        history.push(SIGN_IN)
      })
      .catch((error) => {
        if (error) {
          if (error.response.status === '403') {
            const currentError = handleErrors('No user found with this login and password')
            dispatch(apiRequestFailed(currentError))
          } else {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          }
        } else {
          throw new Error('deleteAccount failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteAccountLogic
