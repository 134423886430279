import * as type from '@app/constants/scenes/scenesList'
import { IScene } from '@app/@types/scenes/scenes'

export const sync = () => ({
  type: type.SYNC_SCENES,
} as const)

export const update = (scenes: Array<IScene>) => ({
  type: type.UPDATE_SCENES,
  scenes,
} as const)

export const selectCurrentSceneId = (id: number) => ({
  type: type.SELECT_CURRENT_SCENE_ID,
  id,
} as const)

export const clearCurrentSceneId = () => ({
  type: type.CLEAR_CURRENT_SCENE_ID,
} as const)

export const deleteScene = () => ({
  type: type.DELETE_SCENE,
} as const)

export const openDeleteScenePopup = () => ({
  type: type.OPEN_DELETE_SCENE_POPUP,
} as const)

export const closeDeleteScenePopup = () => ({
  type: type.CLOSE_DELETE_SCENE_POPUP,
} as const)

export const openSceneUsedInScenePopup = () => ({
  type: type.OPEN_SCENE_USED_IN_SCENE_POPUP,
} as const)

export const closeSceneUsedInScenePopup = () => ({
  type: type.CLOSE_SCENE_USED_IN_SCENE_POPUP,
} as const)

export const patchScene = () => ({
  type: type.PATCH_SCENE,
} as const)

export const patchSceneEnableValue = () => ({
  type: type.PATCH_SCENE_ENABLE_VALUE,
} as const)

export const patchSceneEnableValueDoneAndroid = () => ({
  type: type.PATCH_SCENE_ENABLE_VALUE_DONE_ANDROID,
} as const)
export const patchSceneEnableValueDoneIOS = () => ({
  type: type.PATCH_SCENE_ENABLE_VALUE_DONE_IOS,
} as const)

export const changeSceneEnabledValue = (sceneIndex: number, enabled: boolean) => ({
  type: type.CHANGE_SCENE_ENABLED_VALUE,
  sceneIndex,
  enabled,
} as const)

export const changeSceneEnabledValueViaId = (id: number, enabled: boolean) => ({
  type: type.CHANGE_SCENE_ENABLED_VALUE_VIA_ID,
  id,
  enabled,
} as const)

export const selectCurrentSceneEnabled = (enabled: boolean) => ({
  type: type.SELECT_CURRENT_SCENE_ENABLED,
  enabled,
} as const)

export const clearCurrentSceneEnabled = () => ({
  type: type.CLEAR_CURRENT_SCENE_ENABLED,
} as const)

export const activateScene = () => ({
  type: type.ACTIVATE_SCENE,
} as const)

export const activateSceneDoneAndroid = () => ({
  type: type.ACTIVATE_SCENE_DONE_ANDROID,
} as const)

export const activateSceneDoneIOS = () => ({
  type: type.ACTIVATE_SCENE_DONE_IOS,
} as const)

export const saveCurrentSceneName = (name: string) => ({
  type: type.SAVE_CURRENT_SCENE_NAME,
  name,
} as const)

export const clearCurrentSceneName = () => ({
  type: type.CLEAR_CURRENT_SCENE_NAME,
} as const)

export const saveCurrentSceneStatus = (status: string) => ({
  type: type.SAVE_CURRENT_SCENE_STATUS,
  status,
} as const)

// IOS

export const patchMobileSceneImpossible = () => ({
  type: type.PATCH_MOBILE_SCENE_IMPOSSIBLE,
} as const)

export const startEnablingProgress = (id: number) => ({
  type: type.START_ENABLING_PROGRESS,
  id,
} as const)

export const stopEnablingProgress = (id: number) => ({
  type: type.STOP_ENABLING_PROGRESS,
  id,
} as const)

export const triggerSceneActions = (id: number) => ({
  type: type.TRIGGER_SCENE_ACTIONS,
  id,
} as const)

export const openWebhookPopup = () => ({
  type: type.OPEN_WEBHOOK_POPUP,
} as const)

export const closeWebhookPopup = () => ({
  type: type.CLOSE_WEBHOOK_POPUP,
} as const)

export const patchEnableWebhook = (typeOfChange: string) => ({
  type: type.PATCH_ENABLE_WEBHOOK,
  typeOfChange,
} as const)

export const changedEnableWebhookValue = (value: boolean, id: number) => ({
  type: type.CHANGE_ENABLE_WEBHOOK_VALUE,
  value,
  id,
} as const)

export const changedTestConditionsEnableValue = (value: boolean, id: number) => ({
  type: type.CHANGE_TEST_CONDITIONS_ENABLE_VALUE,
  value,
  id,
} as const)

export const resetScenesList = () => ({
  type: type.RESET_SCENES_LIST,
} as const)

export const clearCurrentSceneAll = () => ({
  type: type.CLEAR_CURRENT_SCENE_ALL,
} as const)
