import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import {
  filterAddTwrDevices, filterAddZwaveDevices, roomAddDevice, syncRoomDevices,
} from '@app/actions/rooms/rooms'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import RoomsAddDevice from '@app/components/Rooms/AddDeviceToRoom/AddDeviceToRoom'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

let enhance = RoomsAddDevice

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const {
    rooms: {
      zWaveDevices,
      twrDevices,
      freeTwrDevices,
      freeZwaveDevices,
    },
  } = store.getState()
  return {
    isMobile,
    isTablet,
    zWaveDevices,
    twrDevices,
    freeTwrDevices,
    freeZwaveDevices,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncRoomDevices,
  tryAgain: syncRoomDevices,
  notifications: syncNotifications.sync,
  filterAddTwrDevices,
  filterAddZwaveDevices,
  roomAddDevice,
}, dispatch)

enhance = withRouter(enhance)
enhance = withNotifications(enhance)
enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RoomsAddDeviceEnhanced = enhance

export default RoomsAddDeviceEnhanced
