import * as type from '@app/constants/scenes/twrDevices/twrDevicesByGroups'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/twrDevices/twrDevicesByGroups'
import { IGroupTwrBackend } from '@app/@types/scenes/scenesDevices'

type ITwrDevicesByGroups = ReturnType<InferValueTypes<typeof actions>>

interface TwrDevicesByGroupsState {
  groups: Array<IGroupTwrBackend>,
}

const initialState = {
  groups: [],
}

export default function twrDevicesByGroups(state: TwrDevicesByGroupsState = initialState, action: ITwrDevicesByGroups) {
  switch (action.type) {
  case type.RESET_TWR_DEVICES_BY_GROUPS:
    return initialState

  case type.UPDATE_TWR_DEVICES_BY_GROUPS: {
    return {
      ...state,
      groups: action.groups,
    }
  }

  default:
    return state
  }
}
