import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M52.531 37.6818C50.2019 40.0794 47.8728 41.655 44.7217 43.025C41.5705 44.3951 37.5973 45.5939 34.7544 46.827C31.9115 48.06 30.2332 49.3958 29.0687 51.1769C27.9041 52.9923 27.2533 55.2529 27.2191 57.7533C27.1848 60.2194 27.7328 62.891 28.7946 65.0489C29.8907 67.1725 31.5005 68.7823 32.4596 69.6043C33.4529 70.3921 33.7954 70.3579 34.0694 70.0838C34.3434 69.8098 34.5489 69.2618 35.1997 67.6862C35.8505 66.1107 36.9123 63.5075 38.7961 61.0414C40.68 58.5753 43.3858 56.2804 46.126 54.8076C48.8661 53.3348 51.6405 52.684 53.0448 52.3415C54.4491 51.999 54.4834 51.9305 54.4149 52.0675C54.3464 52.2045 54.1409 52.4785 53.7983 53.1293C53.4901 53.7801 53.0448 54.7734 52.0515 56.2804C51.0925 57.7875 49.5854 59.7741 47.633 61.7607C45.6807 63.7473 43.2831 65.6996 41.365 68.3713C39.4469 71.0429 38.0083 74.4681 38.6591 78.818C39.3099 83.168 42.05 88.4427 51.709 90.6348C61.368 92.827 77.9458 91.9364 89.5913 74.8448C104.32 47.6148 87.502 14.0139 56.881 9.49266C26.26 4.97144 0.434263 32.27 6.70232 62.617C3.99644 71.4539 5.16099 79.8798 5.84603 82.8255C11.5318 81.5924 17.2176 80.3594 22.8691 79.1263C21.636 73.3035 20.0605 67.6177 14.1349 61.0072C8.58616 33.7771 33.9324 10.3832 60.6487 18.1241C87.3307 25.8307 96.3047 59.1576 77.0895 79.229C69.4514 86.2849 58.7991 85.8739 52.805 84.2983C46.7768 82.7227 45.4067 80.0168 45.7835 78.2015C46.1602 76.3862 48.3523 75.4614 50.7499 74.7078C53.1818 73.9543 55.8534 73.4063 58.2853 72.2417C60.7172 71.0429 62.9093 69.2276 64.8959 65.9394C66.8825 62.6512 68.5951 57.856 68.9718 53.4375C69.3486 49.0191 68.321 45.0116 66.5057 40.6617C64.6904 36.3117 62.053 31.722 58.4908 24.0496C57.2235 32.0645 54.8601 35.2842 52.531 37.6818Z" stroke="currentColor" strokeWidth="2"/>
      <path d="M9.78491 84.0241L11.6003 90.8744" stroke="currentColor" strokeWidth="2"/>
      <path d="M19.5124 81.9005L21.3277 88.7851" stroke="currentColor" strokeWidth="2"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EnergyIcon')

export default icon
