import React from 'react'
import {
  List, ListItem, Paper, Typography,
} from '@mui/material'
import { SELECT_CONDITION_TYPE, SELECT_VIRTUAL_SWITCH_CONDITION_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectVirtualSwitchCondition extends React.Component {
  onBackClick= () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_TYPE)
  }

  onSwitchClick=(currentSwitch) => {
    const {
      navigate,
      saveConditionDeviceNumber,
      saveConditionDescription,
    } = this.props
    saveConditionDeviceNumber(currentSwitch.number)
    saveConditionDescription(currentSwitch.number.toString())
    navigate(SELECT_VIRTUAL_SWITCH_CONDITION_STATE)
  }

  render() {
    const {
      classes,
      isMobile,
      virtualSwitches,
    } = this.props
    return (
      <Paper className={classes.wrapper}>
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_virtual_switch')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <List className={classes.root}>
            {virtualSwitches.map((currentSwitch) => (
              <ListItem className={classes.listItem} key={`virtual-switches-${currentSwitch.number}`}>
                <Wrapper
                  className={classes.currentSwitch}
                  onClick={() => {
                    this.onSwitchClick(currentSwitch)
                  }}
                >
                  <Typography className={classes.switchTitle} variant="h6" component="h2">
                    {`${I18n.t('scene_web.virtual_switch')} ${currentSwitch.number}`}
                  </Typography>
                </Wrapper>
              </ListItem>
            ))}
          </List>
        </Wrapper>
      </Paper>
    )
  }
}

SelectVirtualSwitchCondition.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionDeviceNumber: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  virtualSwitches: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  })).isRequired,
}

SelectVirtualSwitchCondition.defaultProps = {

}

SelectVirtualSwitchCondition.displayName = 'SelectVirtualSwitchConditionIOS'

export default SelectVirtualSwitchCondition
