// @ts-nocheck
import update from 'immutability-helper'

const merge = (initialOptions, newOptions) => (
  initialOptions.concat(newOptions)
    .reduce((result, current) => {
      const index = result.findIndex((element) => current.number === element.number)
      if (index !== -1) {
        return update(result, {
          [index]: { $merge: { ...current } },
        })
      }
      return [...result, current]
    }, [])
)

export default merge
