import * as EventsAction from '@app/actions/events'
import * as type from '@app/constants/events'
import { InferValueTypes } from '@app/@types/typesHelper'
import { IEvent } from '@app/@types/events'

type EventsActions = ReturnType<InferValueTypes<typeof EventsAction>>

export interface IEventsState {
  events: Array<IEvent>,
  filterByType: string,
  filterByText: string,
  filterFromDate: string,
  filterToDate: string,
  total: number,
  countPages: number,
  currentPage: number,
}

export const initialState = {
  events: [],
  filterByType: '',
  filterByText: '',
  filterFromDate: '',
  filterToDate: '',
  total: -1,
  countPages: 0,
  currentPage: 1,
}

export default function events(state: IEventsState = initialState, action: EventsActions): IEventsState {
  switch (action.type) {
  case type.UPDATE_EVENTS:
    return {
      ...state, events: action.events, total: action.total, countPages: action.countPages,
    }
  case type.SET_FILTER_BY_TYPE:
    return { ...state, filterByType: action.filterByType }
  case type.CLEAR_FILTER_BY_TYPE:
    return { ...state, filterByType: '' }
  case type.SET_FILTER_FROM_DATE:
    return { ...state, filterFromDate: action.filterFromDate }
  case type.CLEAR_FILTER_FROM_DATE:
    return { ...state, filterFromDate: '' }
  case type.SET_FILTER_TO_DATE:
    return { ...state, filterToDate: action.filterToDate }
  case type.CLEAR_FILTER_TO_DATE:
    return { ...state, filterToDate: '' }
  case type.SET_FILTER_BY_TEXT:
    return { ...state, filterByText: action.filterByText }
  case type.CLEAR_FILTER_BY_TEXT:
    return { ...state, filterByText: '' }

  case type.SAVE_CURRENT_PAGE:
    return { ...state, currentPage: action.currentPage }
  case type.RESET_EVENTS_REDUCER:
    return initialState
  default:
    return state
  }
}
