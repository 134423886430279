export const MIN = 0
export const MAX = 99
export const DEFAULT_VALUE = 50

export const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 99,
    label: '99%',
  },
]
