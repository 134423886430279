import React from 'react'
import { Link, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { supportLink } from '@app/lib/Help/help'
import styles from './CustomerServiceText.style'

class CustomerServiceText extends React.Component {
  render() {
    const {
      classes,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Typography className={classes.bottomLine}>
          {I18n.t('forgot_password.email_changed_text')}
        </Typography>
        <Typography
          className={classes.bottomLine}
          align="center"
        >
          {I18n.t('forgot_password.customer_service_text_part1')}
          {' '}
          <Link
            href={supportLink}
            className={classes.link}
            color="primary"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            {I18n.t('forgot_password.customer_service_text_part2')}
          </Link>
          {' '}
          {I18n.t('forgot_password.customer_service_text_part3')}
        </Typography>
      </Wrapper>
    )
  }
}

CustomerServiceText.propTypes = {
  classes: PropTypes.object.isRequired,

}

export default withStyles(styles)(CustomerServiceText)
