import * as type from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import {
  IBinarySensorType,
  IBinarySensorValue,
  IZwaveDeviceBackend,
  IZWaveDeviceCapabilities,
  IZWaveDeviceStates,
  IMultilevelSensorType,
} from '@app/@types/scenes/scenesDevices'

export const syncZWaveDevices = () => ({
  type: type.SYNC_ZWAVE_DEVICES,
} as const)

export const updateZWaveDevices = (zWaveDevices: Array<IZwaveDeviceBackend>) => ({
  type: type.UPDATE_ZWAVE_DEVICES,
  zWaveDevices,
} as const)

export const syncZWaveDeviceStates = () => ({
  type: type.SYNC_ZWAVE_DEVICE_STATES,
} as const)

export const updateZWaveDeviceStates = (zWaveDeviceStates: IZWaveDeviceStates) => ({
  type: type.UPDATE_ZWAVE_DEVICE_STATES,
  zWaveDeviceStates,
} as const)

export const syncZWaveDeviceCapabilities = () => ({
  type: type.SYNC_ZWAVE_DEVICE_CAPABILITIES,
} as const)

export const updateZWaveDeviceCapabilities = (zWaveDeviceCapabilities: IZWaveDeviceCapabilities) => ({
  type: type.UPDATE_ZWAVE_DEVICE_CAPABILITIES,
  zWaveDeviceCapabilities,
} as const)

export const syncZWaveDeviceBinarySensorTypes = () => ({
  type: type.SYNC_ZWAVE_DEVICE_BINARY_SENSOR_TYPES,
} as const)

export const updateZWaveDeviceBinarySensorTypes = (binarySensorTypes: Array<IBinarySensorType>) => ({
  type: type.UPDATE_ZWAVE_DEVICE_BINARY_SENSOR_TYPES,
  binarySensorTypes,
} as const)

export const syncZWaveDeviceBinarySensorValues = () => ({
  type: type.SYNC_ZWAVE_DEVICE_BINARY_SENSOR_VALUES,
} as const)

export const updateZWaveDeviceBinarySensorValues = (binarySensorValues: Array<IBinarySensorValue>) => ({
  type: type.UPDATE_ZWAVE_DEVICE_BINARY_SENSOR_VALUES,
  binarySensorValues,
} as const)

export const syncZWaveDeviceMultilevelSensorTypes = () => ({
  type: type.SYNC_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES,
} as const)

export const updateZWaveDeviceMultilevelSensorTypes = (multilevelSensorTypes: Array<IMultilevelSensorType>) => ({
  type: type.UPDATE_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES,
  multilevelSensorTypes,
} as const)

export const resetZWaveDevices = () => ({
  type: type.RESET_ZWAVE_DEVICES,
} as const)
