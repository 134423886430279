import React from 'react'
import { List, ListItem, Paper } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { withStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { ROOMS_EDIT } from '@app/constants/routes'
import PropTypes from '@app/components/PropTypes'
import { Close } from 'icons'
import classNames from 'classnames'
import styles from './InfoPopup.style'

class DeviceInfoPopup extends React.PureComponent {
  render() {
    const {
      classes,
      isPopupOpen,
      closePopup,
      writeDeletingDetails,
      room,
      roomsList,
      openWarning,
    } = this.props
    return (
      <Paper className={classes.popupContent} style={isPopupOpen ? { display: 'block' } : { display: 'none' }}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={closePopup}
          size="large"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <List className={classes.list}>
          <ListItem className={classNames(classes.listItem, classes.editListItem)}>
            <Link
              underline="hover"
              className={classes.link}
              to={ROOMS_EDIT}
              state={{ room, roomsList }}
            >
              {I18n.t('rooms_and_groups.room_info')}
            </Link>
          </ListItem>
          <ListItem
            className={classes.listItem}
            onClick={() => {
              writeDeletingDetails(room.id)
              openWarning()
              closePopup()
            }}
          >
            {I18n.t('rooms_and_groups.remove_room')}
          </ListItem>

        </List>
      </Paper>
    )
  }
}

DeviceInfoPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  isPopupOpen: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.number,
  }).isRequired,
  writeDeletingDetails: PropTypes.func.isRequired,
  openWarning: PropTypes.func.isRequired,
  roomsList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
}

DeviceInfoPopup.displayName = 'DeviceInfoPopup'

export default withStyles(styles)(DeviceInfoPopup)
