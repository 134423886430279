import React, { useState, useEffect } from 'react'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import classNames from 'classnames'
import {
  Paper, Typography, TextField, Button, IconButton,
} from '@mui/material'
import { Close } from 'icons'
import {
  getZwaveDeviceParameter,
  resetConfigurationPopupStatus,
  setZwaveDeviceParameter,
} from '@app/actions/myDevices/zwaveDevices'
import { IZwaveDetails, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { useDispatch } from '@app/store'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import useStyles from './Configuration.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceDetails: IZwaveDetails,
  configurationPopup: {
    number: number,
    value: number,
    size: number,
    status: string,
  },
  onClose: () => void,
  isMobile: boolean,
}

const ConfigurationPopup: React.FC<Props> = (props) => {
  const {
    currentDevice, currentDeviceDetails, configurationPopup, onClose, isMobile,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [number, setNumber] = useState<string>('')
  const [value, setValue] = useState<string>('')
  const [size, setSize] = useState<string>('')
  const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false)
  const [isGetButtonDisabled, setIsGetButtonDisabled] = useState<boolean>(false)
  const [isSetButtonDisabled, setIsSetButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    dispatch(resetConfigurationPopupStatus())
  }, [])

  useEffect(() => {
    if (configurationPopup.number.toString() === number) {
      setValue(Number.isFinite(configurationPopup.value) ? configurationPopup.value.toString() : '')
      setSize(Number.isFinite(configurationPopup.size) ? configurationPopup.size.toString() : '')
      setIsSetButtonDisabled(true)
    }
  }, [configurationPopup])

  useEffect(() => {
    if (currentDeviceDetails) {
      const currentParam = currentDeviceDetails.config_params.find((parameter) => parameter.number === +number)
      if (currentParam && currentParam.set_to === +value && currentParam.size === +size) {
        setIsSetButtonDisabled(true)
      } else {
        setIsSetButtonDisabled(false)
      }
    } else if (value !== '' && size !== '') {
      setIsSetButtonDisabled(false)
    }
  }, [value, size])

  useEffect(() => {
    setIsWaitingForResponse(false)
  }, [number, value, size])

  useEffect(() => {
    setValue('')
    setSize('')
  }, [number])

  useEffect(() => {
    if (configurationPopup.number.toString() === number) {
      setIsWaitingForResponse(false)
    }
  }, [configurationPopup.status])

  const handleNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === '') {
      setNumber(event.target.value)
    } else {
      event.preventDefault()
    }
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === '') {
      setValue(event.target.value)
    } else {
      event.preventDefault()
    }
  }

  const handleSizeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === '') {
      setSize(event.target.value)
    } else {
      event.preventDefault()
    }
  }

  const getCurrentParam = () => {
    dispatch(resetConfigurationPopupStatus())
    setIsWaitingForResponse(true)
    dispatch(getZwaveDeviceParameter(currentDevice.id, +number))
    setIsGetButtonDisabled(true)
    setTimeout(() => {
      setIsGetButtonDisabled(false)
    }, 2000)
  }

  const setCurrentParam = () => {
    dispatch(resetConfigurationPopupStatus())
    setIsWaitingForResponse(true)
    dispatch(setZwaveDeviceParameter(currentDevice.id, {
      parameter_number: +number,
      is_default: false,
      value: +value,
      size: +size,
    }))
    setIsSetButtonDisabled(true)
    setTimeout(() => {
      setIsSetButtonDisabled(false)
    }, 2000)
  }

  return (
    <Paper className={classes.configurationWrapper}>
      <div
        className={classNames(classes.configurationPopup, isMobile && classes.configurationPopupMobile)}
      >
        <Typography className={classes.popupTitle}>CONFIGURATION</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeIconButton}
          onClick={onClose}
          size="large"
        >
          <Close />
        </IconButton>
        <TextField
          className={classes.field}
          label="Number (decimal)"
          value={number}
          onChange={handleNumberChange}
        />
        <TextField
          className={classes.field}
          label="Value"
          value={value}
          onChange={handleValueChange}
        />
        <TextField
          className={classes.field}
          label="Size"
          value={size}
          onChange={handleSizeChange}
        />
        <Wrapper className={classes.resultWrapper}>
          <Typography className={classes.resultTitle}>Result:</Typography>
          {isWaitingForResponse && (
            <DotsLoading
              className={classes.progressDots}
              props={{
                width: 48, circleWidth: 15, circleHeight: 15,
              }}
            />
          )}
          {configurationPopup.number.toString() === number && (
            <Typography className={classNames(
              configurationPopup.status === 'Done'
                ? classes.result
                : classes.error,
            )}
            >
              {configurationPopup.status}
            </Typography>
          )}
        </Wrapper>
        <Wrapper className={classes.buttonsWrapper}>
          <Button
            className={classes.getButton}
            variant="contained"
            color="primary"
            onClick={getCurrentParam}
            disabled={number === '' || isGetButtonDisabled}
          >
            {I18n.t('buttons.get')}
          </Button>
          <Button
            className={classes.setButton}
            variant="contained"
            color="primary"
            onClick={setCurrentParam}
            disabled={number === '' || value === '' || size === '' || isSetButtonDisabled}
          >
            {I18n.t('buttons.set')}
          </Button>
        </Wrapper>
      </div>
    </Paper>
  )
}

export default ConfigurationPopup
