export const zWaveDevicesCapabilities = [
  {
    title: 'binary_switch_title',
    description: 'binary_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_binary',
  },
  {
    title: 'multilevel_switch_title',
    description: 'multilevel_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_multilevel',
  },
  {
    title: 'binary_sensor_title',
    description: 'binary_sensor_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'sensor_binary',
  },
  {
    title: 'multilevel_sensor_title',
    description: 'multilevel_sensor_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'sensor_multilevel',
  },
  {
    title: 'multichannel_title',
    description: 'multichannel_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'multichannel',
  },
  {
    title: 'notification_title',
    description: 'notification_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'notification',
  },
  {
    title: 'colour_switch_title',
    description: 'colour_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_color',
  },
  {
    title: 'door_lock_title',
    description: 'door_lock_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'door_lock',
  },
]

export const zWaveWallmoteDevicesCapabilities = [
  {
    title: 'wallmote_central_scene_title',
    description: 'wallmote_central_scene_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'central_scene',
  },
  {
    title: 'binary_switch_title',
    description: 'binary_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_binary',
  },
  {
    title: 'multilevel_switch_title',
    description: 'multilevel_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_multilevel',
  },
  {
    title: 'binary_sensor_title',
    description: 'binary_sensor_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'sensor_binary',
  },
  {
    title: 'multilevel_sensor_title',
    description: 'multilevel_sensor_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'sensor_multilevel',
  },
  {
    title: 'notification_title',
    description: 'notification_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'notification',
  },
  {
    title: 'colour_switch_title',
    description: 'colour_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_color',
  },
  {
    title: 'door_lock_title',
    description: 'door_lock_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'door_lock',
  },
]

export const zWaveMultichannelDevicesCapabilities = [
  {
    title: 'binary_switch_title',
    description: 'binary_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_binary',
  },
  {
    title: 'multilevel_switch_title',
    description: 'multilevel_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_multilevel',
  },
  {
    title: 'binary_sensor_title',
    description: 'binary_sensor_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'sensor_binary',
  },
  {
    title: 'multilevel_sensor_title',
    description: 'multilevel_sensor_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'sensor_multilevel',
  },
  {
    title: 'multichannel_binary_switch_title',
    description: 'multichannel_binary_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'multichannel_switch',
  },
  {
    title: 'notification_title',
    description: 'notification_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'notification',
  },
  {
    title: 'colour_switch_title',
    description: 'colour_switch_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'switch_color',
  },
  {
    title: 'door_lock_title',
    description: 'door_lock_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'door_lock',
  },
]

export const supportedConditionCapabilities = ['switch_binary', 'sensor_binary', 'switch_multilevel', 'switch_color', 'sensor_multilevel', 'multichannel', 'door_lock', 'notification']

export const supportedConditionWallmoteCapabilities = ['central_scene', 'switch_binary', 'sensor_binary', 'switch_multilevel', 'switch_color', 'sensor_multilevel', 'door_lock', 'notification']

export const supportedConditionMultichannelCapabilities = ['switch_binary', 'multichannel_switch', 'sensor_binary', 'switch_multilevel', 'switch_color', 'sensor_multilevel', 'door_lock', 'notification']

export const supportedActionCapabilities = ['switch_binary', 'switch_color', 'switch_multilevel', 'door_lock', 'multichannel']

export const supportedActionWallmoteMultichannelCapabilities = []

export const supportedActionMultichannelCapabilities = []
