/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import { createBrowserHistory } from 'history'
import LocalStorageService from '@app/helpers/stats/localStorageService'
import { API_URL } from '@app/constants/endpoints'
import { userCredentials } from '@app/constants/stats'

export const statsAxiosInstance = axios.create()

const localStorageService = new LocalStorageService()
const history = createBrowserHistory()

export const createTokenInterceptor = (config) => {
  const newConfig = { ...config }
  const tokenFromStorage = localStorageService.getToken()
  let token
  if (tokenFromStorage) {
    token = tokenFromStorage.access_token
  }
  if (token) {
    newConfig.headers['Access-Token'] = token
  }
  return newConfig
}

export const createRefreshTokenInterceptor = async (error, setToken) => {
  const originalRequest = error.config
  if (
    error.response.status === 401
    && originalRequest.url === `${API_URL}/oauth/token`
  ) {
    setToken(null)
    localStorageService.clearStorage()
    history.push('/statistic/login')
    return Promise.reject(error)
  }

  if (error.response.status === 403) {
    setToken(null)
    localStorageService.clearStorage()
    return Promise.reject(error)
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    const refreshTokenFromStore = localStorageService.getToken()
    let refresh
    if (refreshTokenFromStore) {
      refresh = refreshTokenFromStore.refresh_token
    }
    try {
      const response = await statsAxiosInstance.post(`${API_URL}/oauth/token`, {
        refresh_token: refresh,
        client_id: userCredentials.client_id,
        client_secret: userCredentials.client_secret,
        grant_type: 'refresh_token',
      })
      if (response && response.status === 200) {
        localStorageService.setToken(response.data)
        originalRequest.headers['Access-Token'] = localStorageService.getToken()
          ? localStorageService.getToken().access_token
          : null
      }
    } catch (err) {
      return Promise.reject(err)
    }

    return axios(originalRequest)
  }
  return Promise.reject(error)
}
