import React, { Dispatch, SetStateAction } from 'react'
// @ts-ignore
import { programOptionTypes } from '@app/lib/ProgramOptions'
import SelectString from '@app/components/ProgramOptions/ProgramOption/SelectString/SelectString'
import InputField from '@app/components/ProgramOptions/ProgramOption/InputField/InputField'
import Text from '@app/components/ProgramOptions/ProgramOption/Text/Text'
import Toggle from '@app/components/ProgramOptions/ProgramOption/Toggle/Toggle'
import {
  IProgramOption, IProgramOptions, IProgramOptionSelect,
} from '@app/@types/programOptions'
import * as programOptionsActions from '@app/actions/programOptions'
import { InferValueTypes } from '@app/@types/typesHelper'

interface Props {
  programOption: IProgramOption,
  options: IProgramOptions,
  setOptions: Dispatch<SetStateAction<IProgramOptions>>,
  validationChecker: (value: string, min: number, max: number) => boolean,
  validationIntervals: Record<string, { min: number, max: number, required?: boolean }>,
  validationSetter: (validation: boolean) => ReturnType<InferValueTypes<
    typeof programOptionsActions>>,
  inProgress: boolean,
}

export const getName = (number: number): string => `programOptions_${number}`

const ProgramOption: React.FC<Props> = (props) => {
  const {
    programOption, options, setOptions, validationChecker, validationIntervals, validationSetter, inProgress,
  } = props
  const getTypeByNumber = (number: number) => {
    if (programOptionTypes.strings.find((el: IProgramOptionSelect) => el.number === number)) {
      return 'strings'
    }
    if (programOptionTypes.texts.find((el: number) => el === number)) {
      return 'text'
    }
    if (programOptionTypes.switches.find((el: number) => el === number)) {
      return 'switch'
    }
    if (programOptionTypes.inputs.find((el: { number: number, hint: boolean }) => el.number === number)) {
      return 'inputs'
    }
    return null
  }

  const getTitle = (number: number) => I18n.t(`program_options.po_${number}`)

  const getValues = (number: number) => {
    const current = programOptionTypes.strings.find((el: IProgramOptionSelect) => el.number === number)
    return current ? current.values : []
  }

  const getStartValue = (number: number) => {
    const current = programOptionTypes.strings.find((el: IProgramOptionSelect) => el.number === number)
    return current?.startValue || 0
  }

  const resetInputField = () => {
    setOptions(options.map((rule) => (rule.number === programOption.number
      ? { ...rule, value: '' } : rule)))
  }

  switch (getTypeByNumber(programOption.number)) {
  case 'strings':
    return (
      <SelectString
        name={getName(programOption.number)}
        title={getTitle(programOption.number)}
        number={programOption.number}
        values={getValues(programOption.number)}
        startValue={getStartValue(programOption.number)}
        value={programOption.value}
        options={options}
        setOptions={setOptions}
      />
    )
  case 'switch':
    return (
      <Toggle
        name={getName(programOption.number)}
        title={getTitle(programOption.number)}
        number={programOption.number}
        value={programOption.value}
        options={options}
        setOptions={setOptions}
      />
    )
  case 'text':
    return (
      <Text
        title={getTitle(programOption.number)}
        value={programOption.value}
        key={programOption.number}
      />
    )
  case 'inputs':
    return (
      <InputField
        name={getName(programOption.number)}
        number={programOption.number}
        value={programOption.value}
        options={options}
        setOptions={setOptions}
        resetInputField={resetInputField}
        validationChecker={validationChecker}
        validationIntervals={validationIntervals}
        validationSetter={validationSetter}
        inProgress={inProgress}
      />
    )
  default:
    return null
  }
}

export default ProgramOption
