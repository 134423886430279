import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { buttonDisableForEvents } from '@app/helpers/buttonDisableForEvents'
import { withStyles } from '@app/components/styles'
import {
  clearFilterByText,
  clearFilterByType,
  clearFilterFromDate,
  clearFilterToDate,
  setFilterByText,
  setFilterByType,
  setFilterFromDate,
  setFilterToDate,
  sync,
  saveCurrentPage,
} from '@app/actions/events'
import Filters from '@app/components/Events/Filters/Filters'

let enhance = withStyles(Filters)

const mapStateToProps = () => {
  const { events: { filterByType } } = store.getState()
  const { events: { filterFromDate } } = store.getState()
  const { events: { filterToDate } } = store.getState()
  const { events: { filterByText } } = store.getState()
  const isButtonDisabled = buttonDisableForEvents(filterByType, filterFromDate, filterToDate, filterByText)

  return {
    filterByType,
    filterFromDate,
    filterToDate,
    filterByText,
    isButtonDisabled,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterByType,
  clearFilterByType,
  setFilterFromDate,
  clearFilterFromDate,
  setFilterToDate,
  clearFilterToDate,
  setFilterByText,
  clearFilterByText,
  saveCurrentPage,
  submit: sync,
  reset: sync,
}, dispatch)

enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const FiltersEnhanced = enhance

export default FiltersEnhanced
