import React from 'react'
import {
  Button, IconButton, Typography, Paper,
} from '@mui/material'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { CREATE_GEOFENCE } from '@app/constants/routes'
import {
  COMMIT_TO_MEZZO, COMPLETE, DELETING, IN_PROGRESS, NOT_SAVED,
} from '@app/constants/mezzoAnswers'
import { maxAmountOfGeofences } from '@app/lib/Scenes/geofences'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import PropTypes from '@app/components/PropTypes'
import {
  Add, ChevronLeft, Delete, Edit, Refresh,
} from 'icons'
import { withStyles } from '@app/components/styles'
import { checkIfGuestOrFamily } from '@app/helpers/checkIfGuestOrFamily'
import GeofencesErrorPopup from './ErrorPopup/GeofencesErrorPopup'

class GeofencesList extends React.Component {
  state = {
    isErrorPopupOpen: false,
    isUsedInScenePopupOpen: false,
  }

  openErrorPopupHandler = () => {
    this.setState((prevState) => ({
      isErrorPopupOpen: !prevState.isErrorPopupOpen,
    }))
  }

  usedInScenePopupHandler = () => {
    this.setState((prevState) => ({
      isUsedInScenePopupOpen: !prevState.isUsedInScenePopupOpen,
    }))
  }

  renderGeofenceStatus = (status) => {
    switch (status) {
    case COMMIT_TO_MEZZO: return `(${I18n.t('geofence_web.saving')})`
    case IN_PROGRESS: return `(${I18n.t('geofence_web.saving')})`
    case NOT_SAVED: return `(${I18n.t('geofence_web.not_saved')})`
    case DELETING: return `(${I18n.t('geofence_web.deleting')})`
    case COMPLETE: return ''
    default: return ''
    }
  }

  renderAddButton = () => {
    const { classes, role, geofencesList } = this.props
    if (!checkIfGuestOrFamily(role)) {
      if (geofencesList.length === maxAmountOfGeofences) {
        return (
          <IconButton
            aria-label="Close"
            className={classes.addButton}
            onClick={this.openErrorPopupHandler}
            size="large"
          >
            <Add />
          </IconButton>
        )
      }
      return (
        <Link underline="hover" to={CREATE_GEOFENCE}>
          <IconButton aria-label="Close" className={classes.addButton} size="large">
            <Add />
          </IconButton>
        </Link>
      )
    }
    return null
  }

  render() {
    const {
      classes,
      isMobile,
      isTablet,
      syncGeofences,
      deleteGeofence,
      geofencesList,
      toggleGeofenceSize,
      patchGeofence,
      role,
    } = this.props
    const {
      isErrorPopupOpen,
      isUsedInScenePopupOpen,
    } = this.state
    return (
      <Paper className={classes.root}>
        <IconButton
          className={classNames(classes.refreshIcon, isMobile && !isTablet && classes.refreshIconMobile)}
          onClick={syncGeofences}
          size="large"
        >
          <Refresh />
        </IconButton>
        <Wrapper className={classes.main}>
          {geofencesList.length ? geofencesList.map((geofence, index) => (
            <Wrapper
              className={classNames(
                classes.geofencesCard,
                geofence.expanded && classes.geofenceExpanded,
                isMobile && classes.cardMobile,
              )}
              key={`${geofence.name + 1}`}
            >
              <Wrapper className={classes.cardContent}>
                <Typography
                  className={classes.name}
                  onClick={() => toggleGeofenceSize(index)}
                >
                  {`${geofence.name} ${geofence.status && this.renderGeofenceStatus(geofence.status)}`}
                </Typography>
                {geofence.expanded && (
                  <Wrapper className={classNames(classes.geofenceDetails, isMobile && classes.detailsMobile)}>
                    <Typography>
                      {I18n.t('geofence_web.scenes')}
                      {': '}
                      {geofence.scenes.length
                        ? geofence.scenes.map((scene) => scene.name).join(', ')
                        : I18n.t('my_energy.not_available')}
                    </Typography>
                    <Typography>
                      {I18n.t('geofence_web.address')}
                      {': '}
                      {geofence.address}
                    </Typography>
                  </Wrapper>
                )}
              </Wrapper>
              {geofence.status === 'not_saved' ? (
                <Button
                  className={classes.saveButton}
                  onClick={() => {
                    patchGeofence({
                      name: geofence.name,
                      latitude: geofence.latitude,
                      longitude: geofence.longitude,
                      size: geofence.size,
                    }, geofence.id)
                    syncGeofences()
                  }}
                >
                  {I18n.t('buttons.save')}
                </Button>
              ) : (
                <IconButton
                  className={classNames(
                    classes.icon, geofence.expanded && classes.flipIcon,
                  )}
                  onClick={() => toggleGeofenceSize(index)}
                  size="large"
                >
                  <ChevronLeft className={classes.iconInner} />
                </IconButton>
              )}
              {(geofence.expanded && !checkIfGuestOrFamily(role)) && (
                <Wrapper className={classNames(classes.geofenceControls, isMobile && classes.controlsMobile)}>
                  <Link
                    underline="hover"
                    to={CREATE_GEOFENCE}
                    state={{
                      name: geofence.name,
                      latitude: geofence.latitude,
                      longitude: geofence.longitude,
                      size: geofence.size,
                      id: geofence.id,
                    }}
                  >
                    <IconButton size="large">
                      <Edit />
                    </IconButton>
                  </Link>
                  <IconButton
                    onClick={() => (geofence.scenes.length ? this.usedInScenePopupHandler() : deleteGeofence(geofence.id))}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Wrapper>
              )}
            </Wrapper>
          ))
            : <Typography className={classes.noGeofences}>{I18n.t('scene_web.no_geofences')}</Typography>}
          {this.renderAddButton()}
          <GeofencesErrorPopup open={isErrorPopupOpen} close={this.openErrorPopupHandler} />
          <SmallErrorPopup
            open={isUsedInScenePopupOpen}
            warningText="geofence_web.used_in_scene"
            buttonName="buttons.okay"
            title="titles.warning"
            onClick={this.usedInScenePopupHandler}
          />
        </Wrapper>
      </Paper>
    )
  }
}

GeofencesList.propTypes = {
  classes: PropTypes.object.isRequired,
  geofencesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    status: PropTypes.string,
    scenes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  syncGeofences: PropTypes.func.isRequired,
  toggleGeofenceSize: PropTypes.func.isRequired,
  deleteGeofence: PropTypes.func.isRequired,
  patchGeofence: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
}

GeofencesList.displayName = 'GeofencesList'

export default withStyles(GeofencesList)
