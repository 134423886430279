import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Button, Paper, Typography } from '@mui/material'
import classNames from 'classnames'
import { change } from 'redux-form'
import {
  HOME, DELETE_ACCOUNT, EMAIL, SIGN_UP,
} from '@app/constants/routes'
import { loginFields } from '@app/lib/Wizard/wizardFields'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { useNavigate } from 'react-router-dom'
import { withTheme } from '@mui/styles'
// @ts-ignore
import EditText from './EditText/EditText'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import useStyles from './SignIn.style'

interface Props {
    theme: any,
    submitting: boolean,
    isMobile: boolean,
    isTablet: boolean,
    loginError: string,
    hideAccountLockedError: () => void,
    hideSignInError: () => void,
    submit: () => void,
    userLoggedIn: boolean,
    isAccountLocked: boolean,
}

const SignIn: React.FC<Props> = (props) => {
  const {
    submit,
    submitting,
    isMobile,
    isTablet,
    loginError,
    isAccountLocked,
    theme,
    hideAccountLockedError,
    hideSignInError,
    userLoggedIn,
  } = props
  const classes = useStyles()
  const [cookies, setCookie] = useCookies(['username'])
  const [username, setUsername] = useState < string >('')
  const [password, setPassword] = useState < string >('')
  const navigate = useNavigate()

  useEffect(() => {
    store.dispatch(change('SignIn', 'email_or_phone', cookies.username))
    window.addEventListener('keydown', handleLoginPress)
    return () => {
      window.removeEventListener('keydown', handleLoginPress)
    }
  }, [])

  useEffect(() => {
    if (userLoggedIn) {
      navigate(HOME)
    }
  }, [userLoggedIn])

  const handleLoginPress = (evt: KeyboardEvent) => {
    if (evt.which === 13 || evt.keyCode === 13) {
      submit()
    }
  }
  const forgotPasswordLink = () => {
    navigate(EMAIL)
  }

  const signUpLink = () => {
    navigate(SIGN_UP)
  }

  const onDeleteButtonClick = () => {
    navigate(DELETE_ACCOUNT)
  }

  return (
    <Paper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classNames(
        classes.main,
        (!isMobile || isTablet) && classes.mainDesktop,
        isMobile && classes.mainMobile,
      )}
      >
        <Wrapper className={classes.mainLogo}>
          {!isMobile ? theme.loginLogo : theme.loginLogoMobile}
        </Wrapper>
        <form className={classes.form}>
          {loginFields.map((field) => (
            <EditText
              key={field.title}
              className={classes.item}
              name={field.title}
              title={field.title}
              placeholder={field.title === 'hidden' ? 'hidden' : I18n.t(`wizard_web.${field.title}`)}
              autoComplete={field.autocomplete}
              initialValue={field.title === 'email_or_phone' ? username : password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => (
                field.title === 'email_or_phone'
                  ? setUsername(e.target.value)
                  : setPassword(e.target.value)
              )}
            />
          ))}
          <input type="password" name="password_fake" id="password_fake" style={{ display: 'none' }} />
          <Wrapper className={classes.loginErrorWrapper}>
            <Typography className={classes.loginError}>{loginError}</Typography>
            {
              isAccountLocked && <Typography className={classes.loginError}>{I18n.t('errors.sign_in_account_locked')}</Typography>
            }
          </Wrapper>
          <Button
            disableRipple
            className={classes.sendButton}
            variant="contained"
            disabled={submitting}
            onClick={() => {
              setCookie('username', username, { expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT') })
              submit()
            }}
            autoFocus
          >
            {I18n.t('wizard_web.login')}
          </Button>
        </form>
        <Typography
          className={classes.forgotLink}
          color="primary"
          onClick={() => {
            hideSignInError()
            hideAccountLockedError()
            forgotPasswordLink()
          }}
        >
          {I18n.t('wizard_web.forgot_password')}
        </Typography>
        <Typography
          className={classes.signUpLink}
          color="primary"
          onClick={() => {
            hideSignInError()
            hideAccountLockedError()
            signUpLink()
          }}
        >
          {I18n.t('wizard_web.register')}
        </Typography>
        <PrivacyPolicy />
        <Button
          className={classes.deleteButton}
          onClick={onDeleteButtonClick}
        >
          {I18n.t('wizard_web.delete account')}
        </Button>
      </Wrapper>
    </Paper>
  )
}

export default withTheme(SignIn)
