import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  main: {
    position: 'relative',
    padding: 10,
    marginBottom: 10,
    backgroundColor: '#141414',
    boxShadow: 'none',
  },
  addButton: {
    position: 'fixed',
    bottom: 30,
    right: 26,
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
