const getTimerLimit = (timePart: string): number => {
  switch (timePart) {
  case 'day':
    return 255
  case 'hour':
    return 23
  case 'minute':
  case 'second':
    return 59
  default:
    throw new Error('not supported timer limit')
  }
}

export default getTimerLimit
