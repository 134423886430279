import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_ZWAVE_DEVICE_DETAILS } from '@app/constants/myDevices/zwaveDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/zwaveDevices'

const fetchZwaveDeviceDetailsLogic = createLogic({
  type: FETCH_ZWAVE_DEVICE_DETAILS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_ZWAVE_DEVICE_DETAILS))
    try {
      await Api.fetchZwaveDeviceDetails(action.accessToken, action.id)
      dispatch(apiRequestDone(FETCH_ZWAVE_DEVICE_DETAILS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('FETCH_ZWAVE_DEVICE_DETAILS failed without error')
      }
    }
    done()
  },
})

export default fetchZwaveDeviceDetailsLogic
