import { createLogic } from 'redux-logic'
import { updateSecurityOptionsConditions } from '@app/actions/scenes/zWaveDevices/securityOptions'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import Api from '@app/api/scenes/ZWaveDevices/securityOptions/fetchSecurityOptionsConditions'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_SECURITY_OPTIONS_CONDITIONS } from '@app/constants/scenes/zWaveDevices/securityOptions'

const fetchSecurityOptionsConditions = createLogic({
  type: SYNC_SECURITY_OPTIONS_CONDITIONS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionDeviceId } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_SECURITY_OPTIONS_CONDITIONS))
    Api.fetchSecurityOptionsConditions(token, conditionDeviceId)
      .then((response) => {
        dispatch(updateSecurityOptionsConditions(response.data))
        dispatch(apiRequestDone(SYNC_SECURITY_OPTIONS_CONDITIONS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchSecurityOptionsConditions failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchSecurityOptionsConditions
