import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M100.492 95.4983C100.465 95.3552 100.438 95.2122 100.411 95.0691C100.291 94.499 100.15 93.929 99.9955 93.3656C99.8971 93.0034 99.7965 92.6435 99.6937 92.2858V92.2188H83.2825V92.4535C83.3764 93.3052 83.43 94.157 83.477 95.0087V95.4983H100.492Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M65.4696 95.4983V95.0087C65.5165 94.157 65.5702 93.3052 65.6641 92.4535V92.2188H49.3267V92.2858C49.2238 92.6435 49.1232 93.0034 49.0249 93.3656C48.8706 93.929 48.7298 94.499 48.609 95.0691C48.5777 95.2122 48.5509 95.3552 48.5286 95.4983H65.4696Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M43.5453 95.4989H27.8718V95.4587C27.8711 95.4475 27.8711 95.4363 27.8718 95.4251C27.8699 95.4073 27.8699 95.3893 27.8718 95.3715C27.8948 95.3105 27.924 95.2521 27.959 95.1971C28.0344 95.0742 28.1196 94.9576 28.2138 94.8484C28.3419 94.6976 28.4785 94.5543 28.6229 94.4191C28.8891 94.169 29.169 93.9339 29.4613 93.7149C29.8637 93.4131 30.2795 93.1314 30.702 92.8699L31.7281 92.1992H44.2159L44.1824 92.3334C44.0013 93.004 43.8337 93.7149 43.6995 94.4124C43.6325 94.788 43.5453 95.1435 43.5453 95.4989V95.4989Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M124.958 92.2211C125.065 92.3776 125.168 92.5364 125.266 92.6973C125.433 92.9801 125.577 93.2762 125.695 93.5826C125.806 93.8698 125.891 94.166 125.95 94.4679C126.005 94.7487 126.037 95.0336 126.044 95.3196C126.044 95.3778 126.044 95.4381 126.044 95.5007H129.847V84.4414H19.1465V95.5074H22.9559C22.9559 95.4493 22.9559 95.3889 22.9559 95.3263C22.9633 95.0403 22.9947 94.7554 23.0498 94.4746C23.1089 94.1727 23.1942 93.8765 23.3046 93.5893C23.4231 93.2829 23.5667 92.9868 23.7338 92.704C23.8277 92.5431 23.935 92.3821 24.0424 92.2279H22.426V87.7143H126.574V92.2211H124.958Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M78.3262 92.2188H70.6739V92.3059C70.6001 92.8559 70.5464 93.4058 70.5062 93.9625C70.466 94.5191 70.4257 94.9685 70.3721 95.4983H78.581C78.581 94.9886 78.5207 94.4722 78.4804 93.9625C78.4402 93.4528 78.3865 92.8559 78.3128 92.3059L78.3262 92.2188Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M121.128 95.5017C121.128 95.5017 121.128 95.5017 121.128 95.4615C121.129 95.4503 121.129 95.4391 121.128 95.4279C121.13 95.4101 121.13 95.3921 121.128 95.3743C121.105 95.3133 121.076 95.2549 121.041 95.1999C120.965 95.077 120.88 94.9604 120.786 94.8511C120.658 94.7004 120.521 94.5571 120.377 94.4219C120.107 94.1691 119.822 93.9318 119.525 93.711C119.123 93.4092 118.707 93.1275 118.284 92.866L117.258 92.1953H104.784L104.817 92.3294C104.999 93.0001 105.166 93.711 105.3 94.4085C105.367 94.7573 105.421 95.1127 105.468 95.4682L121.128 95.5017Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.6555 105.345L21.5945 106.687C22.3255 106.177 23.0364 105.64 23.7205 105.07C24.2716 104.614 24.7914 104.121 25.2764 103.595C25.6828 103.16 26.0424 102.683 26.3495 102.173C26.4863 101.94 26.6073 101.698 26.7116 101.449C26.8098 101.211 26.8905 100.967 26.9531 100.718C27.0711 100.251 27.1208 99.7699 27.1006 99.2891C27.0781 98.8607 27.0106 98.4359 26.8994 98.0216C26.8055 97.6527 26.6848 97.2905 26.5574 96.9284C26.43 96.5662 26.3696 96.3717 26.3025 96.0833C26.2476 95.8641 26.2205 95.6387 26.2221 95.4127C26.2247 95.3091 26.2359 95.2059 26.2556 95.1041C26.2793 94.9897 26.313 94.8775 26.3562 94.7688C26.4101 94.6318 26.4751 94.4995 26.5507 94.3731C26.6507 94.2043 26.7628 94.043 26.886 93.8902C26.9799 93.7762 27.0738 93.6622 27.1744 93.5549C27.3867 93.3373 27.6106 93.1313 27.8451 92.9379C28.1268 92.6897 28.4286 92.4617 28.7304 92.2672C29.0322 92.0727 29.4815 91.7508 29.8638 91.5094L28.9919 90.1211C23.1772 93.7561 24.5856 96.0565 25.2161 98.0752C25.8465 100.094 25.6922 101.811 20.6555 105.345V105.345Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M45.1482 105.23L46.6639 105.853C46.8986 105.277 47.1267 104.693 47.3346 104.103C47.5022 103.613 47.6498 103.124 47.7772 102.621C47.8837 102.203 47.9665 101.78 48.0254 101.353C48.0755 100.989 48.0979 100.621 48.0924 100.253C48.0863 99.9265 48.0504 99.601 47.9851 99.2808C47.9274 98.996 47.842 98.7174 47.7303 98.4492C47.635 98.2165 47.5205 97.9922 47.3882 97.7785C47.2876 97.6242 47.1803 97.47 47.073 97.3224L46.8986 97.1212L46.8316 97.0139V96.9402C46.8276 96.8955 46.8276 96.8506 46.8316 96.806C46.8253 96.7122 46.8253 96.6181 46.8316 96.5243C46.8316 96.4237 46.8316 96.3231 46.8316 96.2225C46.8316 96.0146 46.8718 95.8067 46.8986 95.5988C46.9255 95.3909 46.9925 95.0288 47.0529 94.7404C47.1132 94.452 47.2138 93.9959 47.3077 93.6271C47.4016 93.2582 47.5492 92.7015 47.6833 92.2455C47.8174 91.7894 48.0119 91.1322 48.1863 90.5755L46.6237 90.0859C44.558 96.7322 45.1482 97.3962 45.8122 98.3016C46.4761 99.207 47.1401 100.334 45.1482 105.23V105.23Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M67.7431 89.9688L69.346 90.3041C69.1717 91.129 69.0442 91.9673 68.957 92.8057C68.8699 93.644 68.8229 94.3952 68.776 95.1932C68.776 95.6091 68.729 96.0249 68.7089 96.4407C68.662 97.1918 68.615 97.943 68.5412 98.6941C68.4384 99.7493 68.2931 100.798 68.1053 101.84C67.8773 103.127 67.5822 104.395 67.2334 105.656L65.6775 105.233C67.6358 97.9631 66.6835 95.0323 67.7431 89.9688V89.9688Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M119.143 91.4742L120.015 90.0859C125.829 93.721 124.421 96.0213 123.791 98.0401C123.16 100.059 123.308 101.809 128.344 105.344L127.405 106.685C126.674 106.175 125.963 105.639 125.279 105.069C124.728 104.612 124.208 104.119 123.723 103.593C123.317 103.158 122.957 102.681 122.65 102.171C122.514 101.938 122.393 101.696 122.288 101.447C122.19 101.21 122.109 100.965 122.047 100.716C121.929 100.249 121.879 99.7683 121.899 99.2875C121.922 98.8591 121.989 98.4343 122.1 98.0199C122.194 97.6511 122.315 97.2889 122.443 96.9267C122.57 96.5646 122.63 96.3701 122.697 96.0817C122.752 95.8624 122.779 95.6371 122.778 95.411C122.775 95.3074 122.764 95.2042 122.744 95.1025C122.721 94.988 122.687 94.8758 122.644 94.7672C122.59 94.6302 122.525 94.4978 122.449 94.3715C122.349 94.2027 122.237 94.0413 122.114 93.8886C122.02 93.7746 121.926 93.6606 121.825 93.5533C121.613 93.3357 121.389 93.1298 121.155 92.9363C120.873 92.6881 120.571 92.4601 120.27 92.2656C119.968 92.0711 119.532 91.7157 119.143 91.4742V91.4742Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M101.947 97.3358L102.101 97.1212L102.168 97.0139V96.9402C102.172 96.8955 102.172 96.8506 102.168 96.806C102.174 96.7122 102.174 96.6181 102.168 96.5243C102.168 96.4237 102.168 96.3231 102.168 96.2225C102.168 96.0146 102.128 95.8067 102.101 95.5988C102.074 95.3909 102.007 95.0288 101.947 94.7404C101.887 94.452 101.786 93.9959 101.692 93.6271C101.598 93.2582 101.451 92.7015 101.316 92.2455C101.182 91.7894 100.988 91.1322 100.813 90.5755L102.376 90.0859C104.522 96.7322 103.905 97.4029 103.235 98.3217C102.564 99.2405 101.893 100.334 103.858 105.23L102.343 105.853C102.108 105.277 101.88 104.693 101.672 104.103C101.504 103.613 101.357 103.124 101.229 102.621C101.123 102.203 101.04 101.78 100.981 101.353C100.931 100.989 100.909 100.621 100.914 100.253C100.92 99.9265 100.956 99.601 101.021 99.2808C101.079 98.9959 101.164 98.7174 101.276 98.4492C101.372 98.2165 101.486 97.9922 101.618 97.7785C101.726 97.6511 101.833 97.4834 101.947 97.3358V97.3358Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M79.6606 90.2767L81.2635 89.9414C82.3232 94.9982 81.3709 97.9358 83.356 105.199L81.7733 105.628C81.4312 104.367 81.1026 103.1 80.9014 101.812C80.7136 100.77 80.5683 99.722 80.4654 98.6668C80.3917 97.9156 80.338 97.1645 80.2978 96.4133C80.2978 95.9975 80.2508 95.5817 80.2307 95.1659C80.1838 94.3678 80.1368 93.5697 80.0496 92.7783C79.9617 91.9385 79.8319 91.1036 79.6606 90.2767H79.6606Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M131.49 95.5005H141V44H8V95.5005H17.5101V78.7674H131.49V95.5005Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwAirconIcon')

export default icon
