import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  progressContainer: {
    width: 444,
    height: 143,
    backgroundColor: theme.backgrounds.popup,
    borderRadius: '5px',
    padding: 35,
  },
  errorText: {
    fontSize: '20px',
    marginBottom: 40,
  },
}))

export default useStyles
