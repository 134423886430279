import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path stroke="#929292" d="M.5.5h19v19H.5z"/>
      <path fill="#A9CF39" d="M17.766 3.234a.8.8 0 00-1.132 0L7.05 12.82 3.366 9.135a.8.8 0 00-1.132 1.131l4.25 4.25a.8.8 0 001.132 0l10.15-10.15a.8.8 0 000-1.132z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CheckedCheckbox', '0 0 16 16', { fontSize: 17 })

export default icon
