import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ECO_MODE_DEVICES } from '@app/constants/myEnergy/ecoMode'
import { updateEcoModeDevices } from '@app/actions/myEnergy/ecoMode'
import Api from '@app/api/myEnergy/fetchEcoModeDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchEcoModeDevicesLogic = createLogic({
  type: SYNC_ECO_MODE_DEVICES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ECO_MODE_DEVICES))
    Api.fetchEcoModeDevices(AccessToken)
      .then((response) => {
        const { data } = response
        dispatch(updateEcoModeDevices(data.zwave_devices))
        dispatch(apiRequestDone(SYNC_ECO_MODE_DEVICES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchEcoModeDevices failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchEcoModeDevicesLogic
