import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { closeSyncErrorPopup, closeSyncWarningPopup, syncStart } from '@app/actions/syncCounters/countersChannel'
import { set } from '@app/actions/numberOfZones'
import SyncPopups from '@app/components/Synchronize/SyncPopups/SyncPopups'

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const {
    isSyncWarningPopupOpen,
    isSyncInProgressPopupOpen,
    isSyncErrorPopupOpen,
    error: errorText,
    syncType: currentSyncType,
  } = state.syncCounters.countersChannel
  return {
    isMobile,
    isWebView,
    isSyncWarningPopupOpen,
    isSyncInProgressPopupOpen,
    isSyncErrorPopupOpen,
    errorText,
    currentSyncType,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeSyncWarningPopup,
  closeSyncErrorPopup,
  syncStart,
  set,
}, dispatch)

const SyncPopupsEnhanced = connect(mapStateToProps, mapDispatchToProps)(SyncPopups)

export default SyncPopupsEnhanced
