import React, { useState, useEffect } from 'react'
import { Typography, IconButton } from '@mui/material'
import { useDispatch } from '@app/store'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
// @ts-ignore
import { MEZZO_LIGHTS } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft, MezzoLightIcon, SortIcon } from 'icons'
import { patchMezzoLights, startMezzoLightsLoading } from '@app/actions/myDevices/mezzoLights'
import { mezzoLightsOffValue, mezzoLightsMaxValue, mezzoLightsValues } from '@app/lib/MyDevices/mezzoLights'
import MultiSwitch from '@app/components/Common/MultiSwitch/MultiSwitch'
import useStyles from './MezzoLightsCard.style'

interface Props {
  mezzoLights: number,
  isMezzoLightsLoading: boolean,
  isDragging: boolean,
  isMobile: boolean,
}

const MezzoLightsCard: React.FC<Props> = (props) => {
  const {
    mezzoLights, isMezzoLightsLoading, isDragging, isMobile,
  } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [mezzoLightValue, setMezzoLightValue] = useState<boolean>(!!mezzoLights)

  useEffect(() => {
    if (!isMezzoLightsLoading) {
      setMezzoLightValue(!!mezzoLights)
    }
  }, [isMezzoLightsLoading])

  useEffect(() => {
    setMezzoLightValue(!!mezzoLights)
  }, [mezzoLights])

  const handleMezzoLightSwitch = (multiSwitchState: boolean) => {
    setMezzoLightValue(multiSwitchState)
    const newValue = multiSwitchState ? mezzoLightsMaxValue : mezzoLightsOffValue
    dispatch(patchMezzoLights(newValue))
    dispatch(startMezzoLightsLoading())
  }

  return (
    <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile, isDragging && classes.dragging)}>
      <Wrapper className={classNames(classes.cardInfo, isMobile && classes.cardInfoMobile)}>
        <IconButton
          size="small"
          className={classes.chevronIcon}
          onClick={() => navigate(MEZZO_LIGHTS)}
        >
          {isDragging ? <SortIcon /> : <ChevronLeft />}
        </IconButton>
        <Wrapper className={classes.cardIconContainer}>
          <MezzoLightIcon />
        </Wrapper>
        <Wrapper className={isMobile ? classes.nameAndStatus : null}>
          <Typography className={classes.cardName}>
            {`${I18n.t('my_devices.mezzo_lights')}`}
          </Typography>
          <Typography
            className={classNames(classes.lightsValue, mezzoLights
              ? classes.lightsValueOn : classes.lightsValueOff)}
          >
            {`${I18n.t(`my_devices.${mezzoLightsValues[mezzoLights]}`)}`}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.controlWrapper}>
          <MultiSwitch
            id="mezzoLight"
            multiSwitchValue={mezzoLightValue}
            multiSwitchHandler={(multiSwitchState) => {
              handleMezzoLightSwitch(multiSwitchState)
            }}
            inProgress={isMezzoLightsLoading}
          />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default MezzoLightsCard
