import * as type from '@app/constants/mezzoTime/mezzoTime'
import type {
  ActionWithoutPayload,
  MezzoTime,
  SetMezzoTime,
} from '@app/types/mezzoTime/mezzoTimeTypes'

export const syncMezzoTime = (): ActionWithoutPayload => ({
  type: type.SYNC_MEZZO_TIME,
})

export const setMezzoTime = (data: MezzoTime): SetMezzoTime => ({
  type: type.SET_MEZZO_TIME,
  data,
})
