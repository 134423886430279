import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 28C9 16.9543 17.9543 8 29 8H121.093C132.139 8 141.093 16.9543 141.093 28V121C141.093 132.046 132.139 141 121.093 141H29C17.9543 141 9 132.046 9 121V28ZM29 13H121.093C129.377 13 136.093 19.7157 136.093 28V121C136.093 129.284 129.377 136 121.093 136H29C20.7157 136 14 129.284 14 121V28C14 19.7157 20.7157 13 29 13Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M71.787 79.5855C72.8518 79.4344 73.9402 79.3562 75.0467 79.3562C87.746 79.3562 98.0407 89.651 98.0407 102.35C98.0407 115.049 87.746 125.344 75.0467 125.344C62.3475 125.344 52.0528 115.049 52.0528 102.35C52.0528 101.602 52.0885 100.862 52.1584 100.131L34.9561 60.9492L71.787 79.5855Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M99.5439 22.2903L99.7172 20.2979L95.7322 19.9514L95.559 21.9438L93.6021 44.4486L93.4288 46.4411L97.4137 46.7876L97.587 44.7951L99.5439 22.2903ZM53.5545 22.9059L53.3649 20.915L49.3829 21.2942L49.5725 23.2852L51.5294 43.833L51.7191 45.824L55.701 45.4447L55.5114 43.4538L53.5545 22.9059ZM28.0292 26.403L27.4228 24.4972L23.6111 25.71L24.2175 27.6158L31.0668 49.1421L31.6732 51.048L35.4849 49.8352L34.8785 47.9293L28.0292 26.403ZM124.438 26.5327L120.558 25.5625L120.073 27.5028L115.181 47.0722L114.695 49.0125L118.576 49.9826L119.061 48.0423L123.953 28.473L124.438 26.5327ZM77.0467 20.1602V18.1602H73.0467V20.1602V41.6864V43.6864H77.0467V41.6864V20.1602Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwMeterGenericIcon')

export default icon
