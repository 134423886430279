import { FAILED_NODE_CHANNEL } from '@app/constants/channels'
import {
  subscribeFailedNodeChannel,
  closeFailedNodeChannel,
  updateFailedNode,
} from '@app/actions/myDevices/channels/deviceSettings/failedNodeChannel'
import openCable from '@app/api/cable'

const failedNodeChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(FAILED_NODE_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create(FAILED_NODE_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(subscribeFailedNodeChannel())
      },
      received(data) {
        response(data)
        store.dispatch(updateFailedNode(data))
      },
      disconnected: () => {
        store.dispatch(closeFailedNodeChannel())
      },
    })
  }
  return subscription
}

export default {
  failedNodeChannel,
}
