const styles = () => ({
  input: {
    maxWidth: 350,
    width: '100%',
    fontSize: '14px',
  },
  text: {
    color: '#f44336',
    position: 'absolute',
    top: 28,
  },
  icon: {
    fontSize: '18px',
  },
})

export default styles
