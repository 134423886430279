import * as type from '@app/constants/myEnergy/billingPeriod'

export const syncCurrentStats = () => ({
  type: type.SYNC_BILLING_CURRENT_STATS,
})

export const updateBillingCurrentStats = (stats) => ({
  type: type.UPDATE_BILLING_CURRENT_STATS,
  stats,
})
