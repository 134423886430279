import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 41">
      <path fill="currentColor" d="M6.84 19.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.46-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.41.09.44.44 0 0 0-.32-.08.45.45 0 0 0-.29.16Zm4.25-1.15a5 5 0 0 0 2.77-.82c.1-.06.15-.16.17-.26a.35.35 0 0 0-.1-.29.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.13.1.37.37 0 0 0-.08.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M20.4 9.77V3.02c0-.29-.19-.51-.33-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.19-.6-2.02-.89A20.74 20.74 0 0 0 11 0C8.54 0 6.26.38 4.58.93a8.1 8.1 0 0 0-2.06.96c-.48.34-.91.8-.91 1.37v6.5c0 .65.47 1.22 1.03 1.68.58.47 1.39.92 2.29 1.31.84.36 1.79.68 2.76.91.12.54.56.97 1.06 1.24a4.8 4.8 0 0 0 2.3.54c.87 0 1.68-.2 2.3-.54.5-.28.94-.7 1.06-1.25.94-.23 1.85-.53 2.66-.88a9.7 9.7 0 0 0 2.3-1.32c.55-.46 1.02-1.03 1.02-1.68Zm-5.97 3.4.67-.2c.05-.13.07-.27.07-.41 0-1.32-1.85-2.4-4.12-2.4-2.28 0-4.13 1.08-4.13 2.4 0 .15.03.3.07.44l.67.18c.08-.62.54-1.09 1.09-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.68.2 2.3.54.54.3 1 .77 1.08 1.37Zm-7.97-.34c-2.4-.79-4.39-2.02-4.39-3.06V7.33a18.5 18.5 0 0 1 8.84-2.32c4.6 0 8.18 1.85 9.02 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04l.02-.25c0-.92-.64-1.65-1.45-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.82.47-1.46 1.2-1.46 2.12 0 .09 0 .18.02.27ZM2.07 3.26c0-1.3 4.1-2.8 8.93-2.8 4.84 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.02-2.3c-4.44 0-8 1.84-8.84 2.31v-1.3Zm0 1.93v1.5a19.1 19.1 0 0 1 8.84-2.32 18.1 18.1 0 0 1 9.02 2.31V5.2a19.26 19.26 0 0 0-9.02-2.32c-4.6.01-8.02 1.84-8.84 2.32Zm11.9 8.16c0 .9-1.3 1.62-2.92 1.62-1.62 0-2.93-.72-2.93-1.62 0-.9 1.3-1.63 2.93-1.63 1.61 0 2.93.73 2.93 1.63ZM5.3 35.44a.62.62 0 1 0-.76.51c.44.11.72.25.83.42-.11-.17-.13-.48-.06-.93Zm11.45-.99a.62.62 0 1 1 .77.51c-.44.11-.72.25-.83.42.11-.17.13-.48.06-.93ZM2.4 33.59a.62.62 0 1 0-.26.88c.41-.2.71-.27.91-.21-.2-.06-.41-.28-.65-.67Z" clipRule="evenodd"/>
      <path stroke="currentColor" d="M7.39 37.55c-.56.08-1.49.17-1.97.6-.2.18-.14.34.07.48.44.3.91.36 1.4.46 1.38.26 2.75.32 4.14.32 1.4 0 2.76-.06 4.14-.32.49-.1.96-.16 1.4-.46.2-.14.28-.3.07-.48-.48-.43-1.41-.51-1.97-.6"/>
      <path stroke="currentColor" d="M15.24 36.87c1.38.1 2.75.24 4.1.57.34.08 1.74.34 1.72.93-.02.61-1.43.84-1.79.92-2.74.65-5.46.71-8.24.71-2.78 0-5.5-.06-8.24-.7-.36-.1-1.77-.31-1.79-.93-.02-.6 1.38-.85 1.72-.93 1.35-.32 2.72-.46 4.1-.57"/>
      <path fill="currentColor" fillRule="evenodd" d="M7.74 30.94a4.44 4.44 0 1 0 6.57 0c-2.19-2.41-3.29-4.34-3.29-5.79 0 1.45-1.09 3.38-3.28 5.8Zm11.92 4.76a.62.62 0 1 1 .26.88c-.41-.2-.71-.27-.91-.21.2-.06.41-.28.65-.67Z" clipRule="evenodd"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'WaterIcon', '0 0 80 80', {fontSize: 35})

export default icon
