import update from 'immutability-helper'
import * as type from '@app/constants/wizard/forgotPassword'

type State = {
  email: string,
  code: string,
  password: string,
  validateError: boolean,
  requestId: number | null,
  isWrongEmailPopupOpened: boolean,
  isWrongCodePopupOpened: boolean,
}

const initialState = {
  email: '',
  code: '',
  password: '',
  validateError: false,
  requestId: null,
  isWrongEmailPopupOpened: false,
  isWrongCodePopupOpened: false,
}

export default function forgotPassword(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.SAVE_EMAIL:
    return update(state, {
      email: { $set: action.email },
    })
  case type.CLEAR_EMAIL:
    return update(state, {
      email: { $set: '' },
    })
  case type.OPEN_WRONG_EMAIL_POPUP: {
    return {
      ...state,
      isWrongEmailPopupOpened: true,
    }
  }
  case type.CLOSE_WRONG_EMAIL_POPUP: {
    return {
      ...state,
      isWrongEmailPopupOpened: false,
    }
  }

  case type.SAVE_CODE:
    return update(state, {
      code: { $set: action.code },
    })

  case type.CLEAR_CODE:
    return update(state, {
      code: { $set: '' },
    })

  case type.SHOW_ERROR: {
    return {
      ...state,
      validateError: true,
    }
  }
  case type.HIDE_ERROR: {
    return {
      ...state,
      validateError: false,
    }
  }

  case type.SAVE_REQUEST_ID:
    return update(state, {
      requestId: { $set: action.requestId },
    })
  case type.CLEAR_REQUEST_ID:
    return update(state, {
      requestId: { $set: null },
    })

  case type.OPEN_WRONG_CODE_POPUP: {
    return {
      ...state,
      isWrongCodePopupOpened: true,
    }
  }
  case type.CLOSE_WRONG_CODE_POPUP: {
    return {
      ...state,
      isWrongCodePopupOpened: false,
    }
  }

  case type.RESET_FORGOT_PASSWORD:
    return initialState
  default:
    return state
  }
}
