export const virtualSwitchesStatesCondition = [
  {
    title: 'is_on',
    description: 'is_on',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'on',
  },
  {
    title: 'is_off',
    description: 'is_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'off',
  },
]

export const virtualSwitchesStatesAction = [
  {
    title: 'turn_on',
    description: 'turn_on',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'on',
    value: 1,
  },
  {
    title: 'turn_off',
    description: 'turn_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'off',
    value: 0,
  },
  {
    title: 'toggle',
    description: 'toggle',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'toggle',
    value: 2,
  },
]
