export const downloadFirmwareTypes = [
  {
    value: 'manual',
    mainText: 'manual_download',
    infoText: 'press_a_button',
  },
  /* {
    value: 'set_time',
    mainText: 'set_time_download',
    infoText: 'preset_time',
  },
  {
    value: 'nightly',
    mainText: 'nightly_download',
    infoText: 'downloading_at_night',
    secondInfoText: 'automatically',
  },
  {
    value: 'disabled',
    mainText: 'disable_cloud_update_firmware',
    infoText: 'firmware_update_not_available',
    secondInfoText: 'via_cloud',
  }, */
]
