import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (unitId, sphereId) => `${API_URL}/api/v3/unit_links/${unitId}?sphere_user_id=${sphereId}`

const deleteMember = (accessToken, sphereId, unitId) => Axios.delete(ENDPOINT(unitId, sphereId), {
  headers: { 'Access-Token': accessToken },
})

export default {
  deleteMember,
}
