import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/${id}/actions`

const postAction = (accessToken, id, data) => Axios.post(ENDPOINT(id), data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  postAction,
}
