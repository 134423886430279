import { createLogic } from 'redux-logic'
import { TIMER_CHANNEL, UNSUBSCRIBE_TIMER_CHANNEL } from '@app/constants/timers/timers'
import openCable from '@app/api/cable'

const unsubscribeTimerChannelLogic = createLogic({
  type: UNSUBSCRIBE_TIMER_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(TIMER_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeTimerChannelLogic
