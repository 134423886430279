import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Icon,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { ExpandMore } from 'icons'
import { withStyles } from '@mui/styles'
import { checkIconNumber } from '@app/helpers/myDevices/checkIconNumber'
import parseDate from '@app/helpers/parseDate'
import { deviceCardValues } from '@app/lib/MyEnergy/DeviceManager'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './DeviceCard.style'

class DeviceCard extends React.PureComponent {
  setCheckboxStatus=(id, isChecked) => {
    const {
      addDeviceToResetArray,
      deleteDeviceFromResetArray,
    } = this.props
    if (isChecked) {
      deleteDeviceFromResetArray(id)
    } else {
      addDeviceToResetArray(id)
    }
  }

  clickCheckbox = (event) => {
    event.stopPropagation()
  }

  showValue = (value) => (value || 0)

  render() {
    const {
      classes,
      device,
      resetMode,
      devicesForReset,
    } = this.props
    const deviceEnergyInfo = device.energy_info
    const resetDateRow = deviceCardValues[3]
    const isChecked = !!devicesForReset.find((id) => id === device.id)
    return (
      <Wrapper>
        <Accordion className={classes.root}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Wrapper className={classes.titleWrapper}>
              <ListItemIcon>
                <Icon className={classes.titleIcon}>{devicesIcons[checkIconNumber(device.icon)]}</Icon>
              </ListItemIcon>
              <Typography className={classes.titleDevice} variant="h6" component="h2">
                {device.name}
              </Typography>
              {resetMode ? (
                <Checkbox
                  className={classes.checkbox}
                  checked={isChecked}
                  onClick={this.clickCheckbox}
                  onChange={(event) => {
                    this.setCheckboxStatus(device.id, isChecked, event)
                  }}
                  value="checkedA"
                  color="primary"
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              ) : <Wrapper className={classes.fakeCheckbox} />}
            </Wrapper>
          </AccordionSummary>
          <AccordionDetails>
            <Table className={classes.table}>
              <TableBody>
                {deviceCardValues.map((value) => (
                  <TableRow key={value}>
                    <TableCell component="th" scope="row">
                      {I18n.t(`my_energy.${value}`)}
                      :
                    </TableCell>
                    <TableCell align="right">
                      {value === resetDateRow
                        ? parseDate(deviceEnergyInfo[value]) : this.showValue(deviceEnergyInfo[value])}
                      {' '}
                      {!(value === resetDateRow) && I18n.t('my_energy.power_kwh')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Wrapper>
    )
  }
}

DeviceCard.propTypes = {
  classes: PropTypes.object.isRequired,
  resetMode: PropTypes.bool.isRequired,
  device: PropTypes.energyDevice.isRequired,
  devicesForReset: PropTypes.arrayOf(PropTypes.number),
  addDeviceToResetArray: PropTypes.func.isRequired,
  deleteDeviceFromResetArray: PropTypes.func.isRequired,
}

DeviceCard.defaultProps = {
  devicesForReset: [],
}

export default (withStyles(styles)(DeviceCard))
