import { IMyDevicesState } from '@app/reducers/myDevices/myDevices'
import { DeviceTypes, IDeviceInfoChannelData, TDevice } from '@app/@types/myDevices'

const getTwrBattery = (data: IDeviceInfoChannelData, currentTwrDevice: TDevice) => {
  if (data.twr_battery && data.twr_battery?.value !== null) {
    return data.twr_battery
  } if ('info' in currentTwrDevice && currentTwrDevice.info?.twr_battery) {
    return currentTwrDevice.info?.twr_battery
  }
  return {}
}

const getTwrSensor = (data: IDeviceInfoChannelData, currentTwrDevice: TDevice) => {
  if (data.twr_sensor && data.twr_sensor?.value !== null) {
    return data.twr_sensor
  } if ('info' in currentTwrDevice && currentTwrDevice.info?.twr_sensor) {
    return currentTwrDevice.info?.twr_sensor
  }
  return {}
}

const getTwrHealth = (data: IDeviceInfoChannelData, currentTwrDevice: TDevice) => {
  if (data.health && data.health?.signal_level !== null) {
    return data.health
  } if ('info' in currentTwrDevice && currentTwrDevice.info?.health) {
    return currentTwrDevice.info?.health
  }
  return {}
}

export const updateDeviceInfoViaActionCable = (state: IMyDevicesState, data: IDeviceInfoChannelData) => {
  const currentTwrDevice = state.devices.find((device) => (
    'id' in device
    && device.device_type === DeviceTypes.twrDevice
    && device.id === data.twr_device_id
  ))

  const updatedInfo = () => {
    if (state.currentDevice && state.currentDevice.id === data.twr_device_id) {
      if (data.twr_info) {
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            twr_type: data.twr_type || (state.currentDevice.info?.twr_type !== undefined ? state.currentDevice.info?.twr_type : null),
            twr_battery: data.twr_battery && data.twr_battery?.value !== null
              ? data.twr_battery
              : state.currentDevice.info?.twr_battery ?? {},
            twr_sensor: data.twr_sensor && data.twr_sensor?.value !== null
              ? data.twr_sensor
              : state.currentDevice.info?.twr_sensor ?? {},
            health: data.health && data.health?.signal_level !== null
              ? data.health
              : state.currentDevice.info?.health ?? {},
          },
        }
      } if (data.twr_health) {
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            health: data.health?.signal_level !== null ? data.health : state.currentDevice.info?.health ?? {},
          },
        }
      }
    } else if (state.currentDevice && state.currentDevice?.id === data.zwave_device_id) {
      if (data.battery) {
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            zwave_battery: state.currentDevice.info?.zwave_battery
              ? {
                ...state.currentDevice.info.zwave_battery,
                status: data.status,
                update: data.update,
              }
              : { status: data.status, update: data.update },
          },
        }
      }
      if (data.sensor) {
        const updatedSensors = state.currentDevice.info?.zwave_sensors.map((sensor) => (
          sensor.id === data.sensor_id
            ? {
              ...sensor,
              status: typeof data.status !== 'number' && data.status?.value,
              scales: typeof data.status !== 'number' && data.status?.scales,
              update: data.update,
            }
            : sensor
        ))
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            zwave_sensors: updatedSensors,
          },
        }
      }
      if (data.zwave_notification) {
        const updatedNotifications = state.currentDevice.info?.notifications.map((notification) => (
          notification.id === data.notification_id
            ? {
              ...notification,
              last_event: data.last_event,
              last_event_timestamp: data.last_event_timestamp,
              last_event_params: data.last_event_params,
              notification_type_name: data.notification_type_name,
              event_name: data.event_name,
            }
            : notification
        ))
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            notifications: updatedNotifications,
          },
        }
      }
      if (data.color) {
        const updatedColor = {
          ...state.currentDevice.info?.color_state,
          value: data.colors,
        }
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            color_state: updatedColor,
          },
        }
      }
      if (data.energy) {
        const updatedConsumings = state.currentDevice.info?.consumings.map((consuming) => (
          consuming.scale === data.scale
            ? {
              ...consuming,
              value: data.value,
              update: data.update,
            }
            : consuming
        ))
        return {
          ...state.currentDevice,
          info: {
            ...state.currentDevice.info,
            consumings: updatedConsumings,
          },
        }
      }
    } else if (data.twr_info) {
      if (currentTwrDevice) {
        return {
          ...currentTwrDevice,
          info: {
            twr_type: data.twr_type
              || ('info' in currentTwrDevice && currentTwrDevice.info?.twr_type !== undefined
                ? currentTwrDevice.info?.twr_type : null),
            twr_battery: getTwrBattery(data, currentTwrDevice),
            twr_sensor: getTwrSensor(data, currentTwrDevice),
            health: getTwrHealth(data, currentTwrDevice),
          },
        }
      }
    } if (data.twr_health) {
      if (currentTwrDevice) {
        return {
          ...currentTwrDevice,
          info: {
            ...'info' in currentTwrDevice && currentTwrDevice.info,
            health: getTwrHealth(data, currentTwrDevice),
          },
        }
      }
    }
    return state.currentDevice
  }
  if (state.currentDevice) {
    const { twr_device_id, zwave_device_id } = data
    if (twr_device_id === state.currentDevice.id
    ) {
      return {
        ...state,
        devices: state.devices.map((device) => ('id' in device
        && device.device_type === DeviceTypes.twrDevice
        && device.id === twr_device_id
          ? updatedInfo()
          : device)),
      }
    }
    if (zwave_device_id === state.currentDevice.id) {
      return {
        ...state,
        devices: state.devices.map((device) => ('id' in device
        && device.device_type === DeviceTypes.zwaveDevice
        && device.id === zwave_device_id
          ? updatedInfo()
          : device)),
      }
    }
  }
  if (currentTwrDevice && 'id' in currentTwrDevice && data.twr_device_id === currentTwrDevice.id) {
    return {
      ...state,
      devices: state.devices.map((device) => ('id' in device
      && device.id === data.twr_device_id
      && device.device_type === DeviceTypes.twrDevice
        ? updatedInfo()
        : device)),
    }
  } return state
}

export default updateDeviceInfoViaActionCable
