import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@app/components/styles'
import Logo from '@app/components/Toolbar/Logo'

let enhance = withStyles(Logo)

const mapStateToProps = (state) => ({
  online: state.unit.online,
  isMobile: state.mobileDetector.isMobile,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)
const LogoEnhanced = enhance

export default LogoEnhanced
