import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  title: {
    textAlign: 'left',
  },
  text: {
    maxWidth: 240,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    width: 240,
    margin: 'auto',
  },
  button: {
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.backgrounds.hover,
    },
  },
  closeIconButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

export default useStyles
