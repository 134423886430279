export const SYNC_COUNTERS_CHANNEL = '@@countersChannel/syncChannel'
export const SYNC_SENT_COUNTERS_CHANNEL = '@@countersChannel/syncSentChannel'
export const SYNC_CANCEL_COUNTERS_CHANNEL = '@@countersChannel/cancelledChannel'
export const SYNC_DONE_COUNTERS_CHANNEL = '@@countersChannel/doneChannel'
export const SYNC_FAILED_COUNTERS_CHANNEL = '@@countersChannel/syncFailed'

export const OPEN_COUNTERS_CHANNEL = '@@countersChannel/open'
export const CLOSE_COUNTERS_CHANNEL = '@@countersChannel/close'

export const OPEN_SYNC_WARNING_POPUP = '@@countersChannel/openSyncWarningPopup'
export const CLOSE_SYNC_WARNING_POPUP = '@@countersChannel/closeSyncWarningPopup'

export const OPEN_SYNC_IN_PROGRESS_POPUP = '@@countersChannel/openSyncInProgressPopup'
export const CLOSE_SYNC_IN_PROGRESS_POPUP = '@@countersChannel/closeSyncInProgressPopup'

export const OPEN_SYNC_ERROR_POPUP = '@@countersChannel/openSyncErrorPopup'
export const CLOSE_SYNC_ERROR_POPUP = '@@countersChannel/closeSyncErrorPopup'

export const START_SYNC_COUNTERS = '@@startSyncCounters/syncStart'
