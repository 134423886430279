const DEFAULT_THEME = 'Ness'
const MAP_DOMAINS_TO_THEMES: Record<string, string> = {
  // Smartlink
  smartlink: 'Smartlink',
  'smartlink-sphere': 'Smartlink',
  'smartlink-sphere-dev': 'Smartlink',
  'smartlink-web': 'Smartlink',
  // Lendlease
  lendlease: 'Lendlease',
  'lendlease-sphere': 'Lendlease',
  'lendlease-sphere-dev': 'Lendlease',
  'lendlease-web': 'Lendlease',
  // Production
  cloud: 'Ness',
  staging: 'Ness',
  'staging-dev': 'Ness',
  nesskuber: 'Ness',
  // Development
  localhost: 'Ness',
  test: 'Ness',
}

const getTheme = (host: string, params: { theme?: string } = {}): string => {
  const subdomain = host.split('.')[0]
  const currentTheme = MAP_DOMAINS_TO_THEMES[`${subdomain}`] || DEFAULT_THEME
  return params.theme || currentTheme
}

export default getTheme
