import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 79">
            <title> iconMeterPower</title>
            <desc> Created with Sketch.</desc>
            <g fill="none">
                <g fill="currentColor">
                    <circle cx="56.4" cy="49.5" r="6.6"/>
                    <path d="M26.1 27.9L52.7 43C50.2 44.1 48.6 46.3 48.3 49L26.1 27.9Z"/>
                    <path
                        d="M110.7 47.7C109.4 47.7 108.1 48 106.9 48.6L94.5 55.3 94.5 71.1 106.9 77.7C108.1 78.4 109.4 78.7 110.7 78.7L115.6 78.7 115.6 47.7 110.7 47.7ZM112.4 75.5L110.7 75.5C109.9 75.5 109.2 75.3 108.5 74.9L97.8 69.2 97.8 57.2 108.5 51.5C109.2 51.1 109.9 50.9 110.7 50.9L112.4 50.9 112.4 75.5Z"/>
                    <rect x="115.6" y="53.3" width="14.2" height="6.2"/>
                    <rect x="115.6" y="67.5" width="10.1" height="6.1"/>
                    <rect x="88.5" y="55.3" width="6.1" height="15.9"/>
                    <path
                        d="M103.5 46.8C100.2 25.6 81.7 11.1 61.1 9.1 40.6 7.2 19.8 17.9 11.8 37.6 9.9 42.2 8.9 47.2 8.8 52.2 8.6 53.7 8.7 55.2 9 56.7 9.1 57.2 9.3 57.6 9.6 58 11.1 59.8 12.8 59.2 14.5 59.2L88.3 59.2C88.4 59.2 88.5 59.1 88.5 59.2L88.5 66.8C88.5 67 88.7 67.2 88.4 67.2L13.6 67.2C11.4 67.2 9.2 67.1 7.1 66.1 3.5 64.3 1.1 60.8 0.8 56.8 0.4 51.5 0.9 46.2 2.2 41.1 4.3 32.4 8.8 24.4 15.1 18 29.6 3.1 51.5-2.4 71.3 2.8 91.1 8 107.6 23.6 111.3 44.5L110.7 44.5C108.8 44.4 107 44.9 105.4 45.8L103.5 46.8Z"/>
                </g>
            </g>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwPowerMeterIcon')

export default icon
