export const BECOME_ONLINE_UNIT = '@@unit/becomeOnlineUnit'
export const BECOME_OFFLINE_UNIT = '@@unit/becomeOfflineUnit'

export const UPDATE_UNIT = '@@unit/update'
export const UPDATE_UNIT_ARM_STATUSES = '@@unit/updateUnitArmStatuses'
export const RESET_UNIT = '@@unit/reset'
export const CHANGE_UNIT = '@@unit/change'
export const SELECT_UNIT = '@@unit/select'

export const PATCH_UNIT = '@@unit/patchUnit'

export const FETCH_UNIT = '@@unit/fetchUnit'

export const UPDATE_UNIT_COORDINATES = '@@unit/updateUnitCoordinates'
export const PATCH_UNIT_LOCATION = '@@unit/patchUnitLocation'
export const PATCH_UNIT_NAME = '@@unit/patchUnitName'

export const OPEN_EDIT_UNIT_NAME_POPUP = '@@unit/openEditUnitNamePopup'
export const CLOSE_EDIT_UNIT_NAME_POPUP = '@@unit/closeEditUnitNamePopup'

export const OPEN_UNIT_LIST = '@@unit/openUnitList'
export const CLOSE_UNIT_LIST = '@@unit/closeUnitList'
