import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6144 16.9963H16.3705V10.882C16.3705 7.36922 13.5127 4.51139 9.99985 4.51139C6.48704 4.51139 3.62921 7.36922 3.62921 10.882V16.9963H3.38526C2.1819 16.9963 1.20283 17.9753 1.20283 19.1787V21.127H18.7969V19.1786C18.7968 17.9753 17.8178 16.9963 16.6144 16.9963ZM4.88433 10.882C4.88433 8.06133 7.17916 5.76646 9.99989 5.76646C12.8206 5.76646 15.1155 8.06133 15.1155 10.882V16.9963H4.88433V10.882ZM17.5418 19.8718H2.45791V19.1786C2.45791 18.6673 2.87388 18.2513 3.38526 18.2513H16.6144C17.1258 18.2513 17.5418 18.6673 17.5418 19.1786V19.8718Z" fill="currentColor"/>
      <path d="M9.99981 6.39403V7.64915C11.7824 7.64915 13.2327 9.09943 13.2327 10.8821H14.4878C14.4878 8.40736 12.4745 6.39403 9.99981 6.39403Z" fill="currentColor"/>
      <path d="M10.6276 0H9.37249V3.53517H10.6276V0Z" fill="currentColor"/>
      <path d="M17.0256 2.65038L14.5259 5.15007L15.4134 6.03754L17.9131 3.53784L17.0256 2.65038Z" fill="currentColor"/>
      <path d="M2.97406 2.65059L2.0866 3.53805L4.58629 6.03774L5.47375 5.15028L2.97406 2.65059Z" fill="currentColor"/>
      <path d="M6.53749 0.564097L5.3779 1.04443L6.73083 4.31065L7.89043 3.83032L6.53749 0.564097Z" fill="currentColor"/>
      <path d="M13.4629 0.563668L12.11 3.82989L13.2696 4.31022L14.6225 1.044L13.4629 0.563668Z" fill="currentColor"/>
      <path d="M19.5197 5.94127L16.2535 7.29421L16.7338 8.45381L20 7.10087L19.5197 5.94127Z" fill="currentColor"/>
      <path d="M0.480332 5.9415L0 7.1011L3.26622 8.45404L3.74655 7.29444L0.480332 5.9415Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PanicIcon')

export default icon
