import { createLogic } from 'redux-logic'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { SYNC_SUB_DEVICES } from '@app/constants/scenes/zWaveDevices/subDevices'
import { updateSubDevices } from '@app/actions/scenes/zWaveDevices/subDevices'
import getSubDevices from '@app/api/scenes/ZWaveDevices/getSubDevices'
import handleErrors from '@app/helpers/handleErrors'

const fetchSubDevicesLogic = createLogic({
  type: SYNC_SUB_DEVICES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentAction: { actionDeviceId } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_SUB_DEVICES))
    getSubDevices(token, actionDeviceId)
      .then((response) => {
        const { data } = response
        dispatch(updateSubDevices(data))
        dispatch(apiRequestDone(SYNC_SUB_DEVICES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('getSubDevices failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchSubDevicesLogic
