import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  goBackIcon: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  title: {
    marginBottom: '1.5rem',
  },
  mainSettingsContainer: {
    marginTop: '2rem',
    padding: '0 1rem',
  },
  mainSettingsContainerMobile: {
    marginTop: '1rem',
  },
  arrowRightIcon: {
    transform: 'rotate(180deg)',
    position: 'absolute',
    right: '1rem',
    top: 'calc(50% - 12px)',
  },
  deviceSettingContainer: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: 80,
    },
  },
  voiceSettingContainer: {
    boxSizing: 'border-box',
    width: '100%',
    height: 78,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '8px 2%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  voiceSettingLeftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  voiceSettingTitle: {
    fontSize: '18px',
  },
  voiceSettingDescription: {
    opacity: 0.5,
  },
  voiceSettingButton: {
    fontSize: '20px',
  },
  voiceSettingAccordion: {
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    backgroundColor: theme.backgrounds.cardBackground,
    '&:before': {
      height: 0,
    },
  },
  voiceSettingAccordionSummary: {
    padding: '8px 2%',
    height: 82,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  voiceSettingAccordionDetails: {
    padding: '8px 2%',
    height: 82,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  },
  changeCodePopupContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0 ,0 ,0, 0.5)',
  },
  changeCodePopup: {
    maxWidth: 333,
    width: '100%',
    height: 188,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: '20px 25px',
  },
  changeCodePopupTitle: {
    fontSize: '20px',
    marginBottom: 20,
  },
  changeCodePopupInput: {
    marginBottom: 30,
  },
  changeCodePopupButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  changeCodePopupButton: {
    marginRight: 8,
  },
}))

export default useStyles
