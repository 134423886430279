import React from 'react'
import { Button, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { checkIconNumber } from '@app/helpers/myDevices/checkIconNumber'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'

import styles from './MyStatusDeviceCard.style'

class ZwaveCard extends React.PureComponent {
  renderIcon = (iconId) => {
    const { classes } = this.props
    return React.cloneElement(devicesIcons[checkIconNumber(iconId)], { className: classes.icon })
  }

  render() {
    const {
      classes,
      deviceStats,
      setMyStatusPopupOpen,
      syncMyStatusPopup,
      isMobile,
    } = this.props
    return (
      <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile)}>
        <Wrapper className={classes.cardHeader}>
          <Wrapper className={classes.iconContainer}>
            {this.renderIcon(deviceStats.icon)}
          </Wrapper>
          <Typography className={classes.title} variant="h5" component="h2" gutterBottom>
            {deviceStats.name}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.content}>
          <Wrapper className={classes.contentItem}>
            <Typography
              component="span"
              className={classNames(classes.info, classes.subtitleColor)}
            >
              {I18n.t('my_status.room')}
              :
              {' '}
            </Typography>
            <Typography
              component="span"
              className={classes.info}
            >
              {deviceStats.room ? deviceStats.room : I18n.t('my_energy.not_available')}
            </Typography>
          </Wrapper>
          <Wrapper className={classes.contentItem}>
            <Typography
              component="span"
              className={classNames(classes.info, classes.subtitleColor)}
            >
              {I18n.t('my_status.group')}
              :
              {' '}
            </Typography>
            <Typography
              component="span"
              className={classes.info}
            >
              {deviceStats.group ? deviceStats.group : I18n.t('my_energy.not_available')}
            </Typography>
          </Wrapper>
        </Wrapper>
        <Wrapper className={classes.learnMoreButton}>
          <Button
            size="small"
            className={classes.button}
            onClick={() => {
              setMyStatusPopupOpen(deviceStats.id, deviceStats.name)
              syncMyStatusPopup()
            }}
          >
            {I18n.t('my_status.learn_more')}
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

ZwaveCard.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setMyStatusPopupOpen: PropTypes.func.isRequired,
  syncMyStatusPopup: PropTypes.func.isRequired,
  deviceStats: PropTypes.myStatus.isRequired,
}

ZwaveCard.displayName = 'MyStatusDeviceCard'

export default withStyles(styles)(ZwaveCard)
