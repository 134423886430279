import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import { ISecurityOptionData } from '@app/actions/myDevices/deviceSettings/securityOption'

const patchSecurityOption = (access_token: string, id: number, data: ISecurityOptionData) => Axios.patch(
  `${API_URL}/api/devices/zwave_devices/${id}/security_option`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

export default { patchSecurityOption }
