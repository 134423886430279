import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Room
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/Room'
import PropTypes from '@app/components/PropTypes'
import styles from './RoomsList.style'

class RoomsList extends React.Component {
  render() {
    const {
      classes,
      navigate,
      roomsScenes,
    } = this.props
    return (
      <List className={roomsScenes.length ? classes.root : classes.rootEmpty}>
        {roomsScenes.length
          ? roomsScenes.map((room) => (
            <ListItem className={classes.listItem} key={`room-${room.id}`}>
              <Room
                room={room}
                navigate={navigate}
              />
            </ListItem>
          )) : <Typography className={classes.empty} align="center">{I18n.t('rooms_and_groups.no_rooms').toUpperCase()}</Typography>}
      </List>
    )
  }
}

RoomsList.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  roomsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

export default withStyles(styles)(RoomsList)
