import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  sliderContainer: {
    position: 'relative',
    width: '100px',
    right: 26,
    top: 10,
  },
  fullWidthContainer: {
    position: 'relative',
    width: '100px',
    right: 26,
    top: 10,
  },
  center: {
    marginRight: 0,
  },
  onButton: {
    position: 'absolute',
    top: 2,
    right: -35,
    fontSize: 14,
    color: theme.myDevices.green,
    cursor: 'pointer',
  },
  offButton: {
    position: 'absolute',
    top: 2,
    left: -40,
    fontSize: 14,
    color: theme.myDevices.red,
    cursor: 'pointer',
  },
  slider: {
    padding: '11px 0',
  },
  thumb: {
    '&:after': {
      width: 12,
      height: 12,
    },

    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 5px rgba(139, 195, 74, 0.16)',
    },
  },
}))

export default useStyles
