import React from 'react'
import {
  SCENES, MY_DEVICES, MY_ENERGY, USERS, EVENTS, MY_STATUS,
  CONTACTS, NOTIFICATIONS, PILLS_REMINDERS, SYNCHRONIZE_SCREEN, NUMBER_OF_ZONES,
  TIMERS, CLOUD_FIRMWARE_UPDATE, GEOFENCES, ROOMS, GROUPS, MEZZO_TIME,
  ACTIVITY, ARMOR_IP, SECURITY_RULES, TAKE_ON_BOARD_SETTINGS, ABOUT, MEZZO_ERRORS,
  TWR_DEVICES_HEALTH, SECRETS, MEZZO_LOCATION,
} from '@app/constants/routes'
import {
  EnergyIcon,
  AnotherSceneMenuIcon,
  DevicesMenuIcon,
  MyUsersIcon,
  EventsIcon,
  IconMyStatus,
  AboutIcon,
  HomeMenuIcon,
  SecuritySettingsIcon,
  SecurityIcon,
  SyncIcon,
  ArmorIpIcon,
  SecurityRulesIcon,
  TakeOnBoardSettingsIcon,
  NotificationSettingsIcon,
  EmergencyAlertIcon,
  TwrDevicesHealthIcon,
  HardwareErrorsIcon,
  CloudFirmwareUpdate,
  RemindersSettingsIcon,
  TimersIcon,
  GeoFencesIcon,
  RoomsIcon,
  GroupsIcon,
  ActivityIcon,
  DeviceTimeIcon,
  DeviceLocationIcon,
  ApplicationIcon,
  HardwareIcon,
  AutomationIcon, HelpIcon,
} from 'icons'

const elementsData = {
  home: {
    icon: <HomeMenuIcon />,
    path: null,
  },
  security: {
    icon: <SecurityIcon />,
    path: null,
  },
  devices: {
    icon: <DevicesMenuIcon />,
    path: MY_DEVICES,
  },
  scenes: {
    icon: <AnotherSceneMenuIcon />,
    path: SCENES,
  },
  energy: {
    icon: <EnergyIcon />,
    path: MY_ENERGY,
  },
  users: {
    icon: <MyUsersIcon />,
    path: USERS,
  },
  events: {
    icon: <EventsIcon />,
    path: EVENTS,
  },
  status: {
    icon: <IconMyStatus />,
    path: MY_STATUS,
  },
  synchronize_all: {
    icon: <SyncIcon />,
    path: SYNCHRONIZE_SCREEN,
  },
  notification_settings: {
    icon: <NotificationSettingsIcon />,
    path: NOTIFICATIONS,
  },
  alert_contacts: {
    icon: <EmergencyAlertIcon />,
    path: CONTACTS,
  },
  reminders: {
    icon: <RemindersSettingsIcon />,
    path: PILLS_REMINDERS,
  },
  timers: {
    icon: <TimersIcon />,
    path: TIMERS,
  },
  geofences: {
    icon: <GeoFencesIcon />,
    path: GEOFENCES,
  },
  device_rooms: {
    icon: <RoomsIcon />,
    path: ROOMS,
  },
  device_groups: {
    icon: <GroupsIcon />,
    path: GROUPS,
  },
  cloud_activity: {
    icon: <ActivityIcon />,
    path: ACTIVITY,
  },
  device_time: {
    icon: <DeviceTimeIcon />,
    path: MEZZO_TIME,
  },
  device_location: {
    icon: <DeviceLocationIcon />,
    path: MEZZO_LOCATION,
  },
  twr_devices_health: {
    icon: <TwrDevicesHealthIcon />,
    path: TWR_DEVICES_HEALTH,
  },
  hardware_errors: {
    icon: <HardwareErrorsIcon />,
    path: MEZZO_ERRORS,
  },
  security_rules: {
    icon: <SecurityRulesIcon />,
    path: SECURITY_RULES,
  },
  take_on_board_settings: {
    icon: <TakeOnBoardSettingsIcon />,
    path: TAKE_ON_BOARD_SETTINGS,
  },
  alarm_reporting_settings: {
    icon: <ArmorIpIcon />,
    path: ARMOR_IP,
  },
  security_ncentral: {
    icon: <SecuritySettingsIcon />,
    path: NUMBER_OF_ZONES,
  },
  about: {
    icon: <AboutIcon />,
    path: ABOUT,
  },
  firmware_update: {
    icon: <CloudFirmwareUpdate />,
    path: CLOUD_FIRMWARE_UPDATE,
  },
  help: {
    icon: <HelpIcon />,
    path: null,
  },
  application: {
    icon: <ApplicationIcon />,
    path: null,
  },
  automation: {
    icon: <AutomationIcon />,
    path: null,
  },
  hardware: {
    icon: <HardwareIcon />,
    path: null,
  },
  submenu_security: {
    icon: <SecuritySettingsIcon />,
    path: NUMBER_OF_ZONES,
  },
  secrets: {
    icon: null,
    path: SECRETS,
  },
}

export default elementsData
