import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { withStyles } from '@app/components/styles'
import { billingPeriodsStatsClose, billingPeriodsStatsOpen } from '@app/actions/myEnergy/billingPeriod'
import { resetAllBillingPeriods, syncPeriodsStats } from '@app/actions/myEnergy/billingPeriodsStats'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import BillingStatsPopup from '@app/components/MyEnergy/Tabs/BillingPeriod/BillingStatsPopup/BillingStatsPopup'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'

let enhance = withStyles(BillingStatsPopup)

const mapStateToProps = (state) => {
  const billingPeriodsStats = state.billingPeriodsStats.billing_periods
  const { isBillingPeriodsStatsPopupOpen } = state.billingPeriod
  const { isMobile } = state.mobileDetector
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  return {
    isBillingPeriodsStatsPopupOpen,
    billingPeriodsStats,
    isMobile,
    admin,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncPeriodsStats,
  tryAgain: syncPeriodsStats,
  notifications: syncNotifications.sync,
  billingPeriodsStatsOpen,
  billingPeriodsStatsClose,
  resetAllBillingPeriods,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const BillingStatsPopupEnhanced = enhance

export default BillingStatsPopupEnhanced
