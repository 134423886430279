import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_UNIT } from '@app/constants/unit'
import { HOME } from '@app/constants/routes'
import { updateUnit } from '@app/actions/unit'
import { updateSecrets } from '@app/actions/secrets'
import ApiUnit from '@app/api/unit/patchUnitDevice'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setGuardPanelStatus } from '@app/actions/guardPanel'
import { getArmStatus } from '@app/helpers/getArmStatus'
import { saveRawConfig, saveAppConfig } from '@app/actions/appConig/appConfig'
import { convertAppConfig } from '@app/helpers/convertAppConfig'
import ApiConfig from '@app/api/appConfig'
import { GET_APP_CONFIG } from '@app/constants/appCofig/appConfig'
import { checkIfNCentral } from '../../helpers/checkIfNCentral'

const patchUnitDeviceLogic = createLogic({
  type: PATCH_UNIT,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { unit: { selectedUnit } } = getState()
    const { users: { currentUser: { user: { role } } } } = getState()
    next({
      ...action,
      token,
      selectedUnit,
      role,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_UNIT))
    try {
      const response = await ApiUnit.patchUnitDevice(action.token, action.selectedUnit)
      const { unit_device } = response.data
      dispatch(updateSecrets(
        unit_device.token,
      ))
      dispatch(updateUnit(unit_device))
      const isNCentral = checkIfNCentral(action.selectedUnit)
      const statusResult = getArmStatus(unit_device.arm_status, unit_device.arm_types, isNCentral)
      dispatch(setGuardPanelStatus(statusResult))
      dispatch(apiRequestDone(PATCH_UNIT))
      try {
        const configData = await ApiConfig.getAppConfig(action.token)
        const rawConfigs = configData.data.accounts
        dispatch(saveRawConfig(rawConfigs))
        const convertedConfig = convertAppConfig(rawConfigs, isNCentral, unit_device.activation, action.role)
        dispatch(saveAppConfig(convertedConfig))
        dispatch(apiRequestDone(GET_APP_CONFIG))
        history.push(HOME)
      } catch (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      }
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
        done()
      } else {
        throw new Error('patchUnitDevice failed without error')
      }
    }
    return done()
  },
})

export default patchUnitDeviceLogic
