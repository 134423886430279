import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, LinearProgress, TextField, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import { useNavigate } from 'react-router-dom'
import validateName from '@app/helpers/myDevices/validateName'
import renderError from '@app/helpers/myDevices/constructErrorMessage'
import { clearInterviewResult } from '@app/actions/myDevices/myDevices'
import { addZwaveDevice, cancelZwaveDevice, patchNewZwaveDevice } from '@app/actions/myDevices/zwaveDevices'
import { closeZwaveChannel } from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import { MY_DEVICES } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Warning from '@app/components/Common/Warning/WarningComponent'
import { DeviceAddedIcon, ScenesArrowBack, ZWaveDeviceBackIcon } from 'icons'
import useStyles from './AddZwaveDevice.style'

interface Props {
  interviewResult: string,
  isLoading: boolean,
  isMobile: boolean,
}

const AddZwaveDevice: React.FC<Props> = (props) => {
  const { interviewResult, isLoading, isMobile } = props
  const [isAdded, setIsAdded] = useState<boolean>(false)
  const [mezzoReady, setMezzoReady] = useState<boolean>(false)
  const [deviceName, setDeviceName] = useState<string>('')
  const [omittingName, setOmittingName] = useState<boolean>(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (interviewResult === 'success') {
      setIsAdded(true)
    }
    if (interviewResult === 'pending') {
      setMezzoReady(true)
    }
  }, [interviewResult])

  useEffect(() => {
    dispatch(clearInterviewResult())
    dispatch(addZwaveDevice())
    return () => {
      dispatch(closeZwaveChannel())
      dispatch(clearInterviewResult())
      dispatch(cancelZwaveDevice())
    }
  },
  [])

  const retryButtonHandler = () => {
    setMezzoReady(false)
    dispatch(closeZwaveChannel())
    dispatch(clearInterviewResult())
    dispatch(addZwaveDevice())
  }

  const setDeviceNameHandler = (evt: React.ChangeEvent<HTMLInputElement>) => setDeviceName(evt.target.value.trimLeft())

  const submitHandler = () => {
    dispatch(patchNewZwaveDevice({ name: deviceName }))
    navigate(MY_DEVICES)
  }

  const goBackHandler = () => {
    if (!isAdded) {
      navigate(MY_DEVICES)
    } else {
      setOmittingName(true)
    }
  }

  const renderWaitingForMezzoAnswer = () => (
    <>
      <ZWaveDeviceBackIcon className={classNames(classes.zWaveIcon, isMobile && classes.zWaveIconMobile)} />
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile)}>
        {interviewResult ? <Typography className={classes.errorMessage}>{renderError(interviewResult)}</Typography> : <LinearProgress />}
        <Typography className={classes.title}>{I18n.t('my_devices.mezzo_prep')}</Typography>
        <Typography className={classNames(classes.subText, classes.subTitle)}>
          {I18n.t('my_devices.wait_for_voice')}
        </Typography>
      </Wrapper>
    </>
  )

  const renderDeviceNotAdded = () => (
    <>
      <ZWaveDeviceBackIcon className={classNames(classes.zWaveIcon, isMobile && classes.zWaveIconMobile)} />
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile)}>
        <Wrapper className={classes.statusContainer}>
          {isLoading && <LinearProgress className={classes.progressBar} />}
          {interviewResult && interviewResult !== 'pending' && (
            <>
              <Typography className={classes.errorMessage}>{renderError(interviewResult)}</Typography>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={retryButtonHandler}
              >
                {I18n.t('buttons.retry')}
              </Button>
            </>
          )}
        </Wrapper>
        <Typography className={classes.title}>{I18n.t('my_devices.ready_to_learn_zwave_device')}</Typography>
        <Typography className={classNames(classes.subText, classes.subTitle)}>
          {I18n.t('my_devices.refer_to_your_devices_instructions')}
        </Typography>
        <Typography className={classNames(classes.subText, classes.subTitle)}>
          {I18n.t('my_devices.plug_in_zwave_device')}
        </Typography>
      </Wrapper>
    </>
  )

  const renderDeviceAdded = () => (
    <>
      <DeviceAddedIcon className={classNames(classes.deviceAddedIcon, isMobile && classes.deviceAddedIconMobile)} />
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile)}>
        <Typography>{I18n.t('my_devices.zwave_added')}</Typography>
        <TextField
          variant="standard"
          value={deviceName}
          inputProps={{ maxLength: 16 }}
          label={I18n.t('my_devices.name_your_device')}
          className={classes.input}
          onChange={setDeviceNameHandler}
        />
        <Typography className={classNames(classes.subText, classes.subTitle)}>{I18n.t('my_devices.name_hint')}</Typography>
        <Button
          className={classes.submitButton}
          variant="outlined"
          color="primary"
          disabled={!validateName(deviceName)}
          onClick={submitHandler}
        >
          {I18n.t('buttons.submit')}
        </Button>
      </Wrapper>
    </>
  )

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classNames(classes.navigationControls, isMobile && classes.navigationControlsMobile)}>
        <IconButton size="small" className={classes.arrowBack} onClick={goBackHandler}>
          <ScenesArrowBack />
        </IconButton>
        {omittingName && (
          <Warning
            open
            leftButton="ok"
            onClick={() => navigate(MY_DEVICES)}
            onClose={() => setOmittingName(false)}
            rightButton="cancel"
            warningText="my_devices.name_warning"
          />
        )}
        <Typography>{I18n.t('my_devices.add_device')}</Typography>
      </Wrapper>
      <Wrapper className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}>
        {!mezzoReady && renderWaitingForMezzoAnswer()}
        {mezzoReady && !isAdded && renderDeviceNotAdded()}
        {isAdded && renderDeviceAdded()}
      </Wrapper>
      <Wrapper className={classes.progressTipContainer}>
        <div className={classNames(classes.progressTip, !isAdded && classes.active)} />
        <div className={classNames(classes.progressTip, isAdded && classes.active)} />
      </Wrapper>
    </Wrapper>
  )
}

export default AddZwaveDevice
