import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton,
} from '@mui/material'
import classNames from 'classnames'
import { Close } from 'icons'
import { secretCodeFields } from '@app/lib/Users/secretCode'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Password from '../Password/Password'

class SecretCodePopup extends React.PureComponent {
  render() {
    const {
      classes,
      pristine,
      submitting,
      submit,
      closePopup,
      isMobile,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open
      >
        <DialogTitle
          className={classes.title}
        >
          {I18n.t('my_users.change_password_title')}
          <IconButton aria-label="Close" className={classes.closeButton} onClick={closePopup}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={classNames(
            classes.dialogContent,
            !isMobile && classes.dialogContentDesktop,
          )}
        >
          {secretCodeFields.map((field) => (
            <Password
              key={field.title}
              className={classes.item}
              name={field.title}
              title={I18n.t(`my_users.${field.title}`)}
              hint={I18n.t('my_users.new_code_first_hint')}
              isMobile={isMobile}
            />
          ))}
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            disabled={pristine || submitting}
            variant="outlined"
            className={classes.button}
            onClick={submit}
            color="primary"
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

SecretCodePopup.propTypes = {
  classes: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
}

SecretCodePopup.displayName = 'SecretCodePopup'

export default SecretCodePopup
