import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0387 16.3406V9.25497C18.0387 9.08129 18.0042 8.90933 17.9373 8.74904C17.8705 8.58876 17.7725 8.44331 17.649 8.32113L11.2979 2.03483C10.7859 1.52794 9.96122 1.52794 9.44908 2.03483L3.09795 8.32113C2.97452 8.44331 2.87653 8.58876 2.80966 8.74904C2.74278 8.90933 2.70835 9.08129 2.70834 9.25497V16.3406C2.70834 17.0663 3.2967 17.6547 4.02237 17.6547H16.7246C17.4503 17.6547 18.0387 17.0663 18.0387 16.3406Z" stroke="currentColor"/>
      <path d="M14.5346 14.1505V10.4275L10.3735 6.34631L6.21239 10.4275V14.1505H14.5346Z" stroke="currentColor" strokeLinejoin="round"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'IconMyStatus')

export default icon
