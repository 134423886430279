const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 0,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 36,
    paddingRight: 36,
    paddingBottom: 20,
  },
  fieldsWrapper: {
    width: '100%',
    padding: '0 36px',
  },
  button: {
    width: '25%',
  },
  buttonWrapper: {
    padding: '40px 0',
    margin: '0 36px',
    borderTop: '1px solid',
    borderColor: theme.typography.color,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  webView: {
    background: theme.webView.background,
  },
  avatar: {
    width: 80,
    height: 80,
    marginRight: '15%',
  },
})

export default styles
