import * as type from '@app/constants/scenes/pushNotifications/apiChecking'

export const checkPost = () => ({
  type: type.CHECK_POST,
})

export const checkPostFailed = (errorText, errorStatus) => ({
  type: type.CHECK_POST_FAILED,
  errorText,
  errorStatus,
})

export const openErrorPopup = () => ({
  type: type.OPEN_ERROR_POPUP,
})

export const closeErrorPopup = () => ({
  type: type.CLOSE_ERROR_POPUP,
})
