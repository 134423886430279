export const doorlockActionValues = [
  {
    title: null,
    description: 'lock',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'SetDeviceStatus',
  },
  {
    title: null,
    description: 'unlock',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'SetDeviceStatus',
  },
]
