const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #505050',
  },
  title: {
    fontSize: 16,
  },
  subTitle: {
    fontSize: 14,
    textAlign: 'center',
  },
  main: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 0 25px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    margin: '0 2px 0 0',
    padding: '15px 5%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #505050',
  },
  checkboxIcon: {
    padding: 0,
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  button: {
    alignSelf: 'center',
    width: '90%',
    height: 50,
    marginTop: 20,
    marginBottom: 25,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
