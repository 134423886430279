import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import classNames from 'classnames'
import {
  codeMaxLength,
  IVoiceAssistantSettings,
  voiceAssistantSettings,
} from '@app/lib/MyDevices/deviceVoiceAssistantSettings'
import { patchVoiceAssistant, patchVoiceAssistantPin } from '@app/actions/myDevices/deviceSettings/voiceAssistant'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ExpandMore, ScenesArrowBack } from 'icons'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './VoiceAssistant.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  isMobile: boolean,
}

const VoiceAssistant: React.FC<Props> = (props) => {
  const { currentDevice, isMobile } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [voiceAssistant, setVoiceAssistant] = useState<string | undefined>('')
  const [isChangeCodePopupOpen, setIsChangeCodePopupOpen] = useState<boolean>(false)
  const [codeValue, setCodeValue] = useState<string>('')

  useEffect(() => {
    if (currentDevice) {
      setVoiceAssistant(currentDevice.voice_assistant_status)
    }
  }, [currentDevice])

  const goBackHandler = () => navigate(-1)

  const setVoiceAssistantSetting = (setting: IVoiceAssistantSettings) => {
    setVoiceAssistant(setting.type)
    dispatch(patchVoiceAssistant({ id: currentDevice.id, voice_assistant_status: setting.type }))
  }

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/^(|\d)+$/)) {
      setCodeValue(event.target.value)
    }
  }

  return (
    <>
      <Wrapper className={classes.navigationControls}>
        <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
          <ScenesArrowBack />
        </IconButton>
      </Wrapper>
      <Wrapper className={classNames(classes.mainSettingsContainer, isMobile && classes.mainSettingsContainerMobile)}>
        <Typography className={classes.title}>
          {I18n.t('my_devices.voice_assistant')}
        </Typography>
        {voiceAssistantSettings.map((setting, index: number) => (
          setting.type === 'user_code' ? (
            <Accordion
              className={classes.voiceSettingAccordion}
              key={`setting-${index + 1}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className={classes.voiceSettingAccordionSummary}
              >
                <Wrapper className={classes.voiceSettingLeftSide}>
                  <Typography className={classes.voiceSettingTitle}>
                    {I18n.t(`my_devices.${setting.title}`)}
                  </Typography>
                  <Typography className={classes.voiceSettingDescription}>
                    {I18n.t(`my_devices.${setting.description}`)}
                  </Typography>
                </Wrapper>
              </AccordionSummary>
              <AccordionDetails className={classes.voiceSettingAccordionDetails}>
                <Typography>{I18n.t(`my_devices.${setting.second_description}`)}</Typography>
                <Button
                  onClick={() => {
                    setIsChangeCodePopupOpen(!isChangeCodePopupOpen)
                  }}
                  className={classes.voiceSettingButton}
                >
                  {I18n.t('buttons.change')}
                </Button>
                <Button
                  onClick={() => {
                    setVoiceAssistantSetting(setting)
                  }}
                  color="primary"
                  className={classes.voiceSettingButton}
                  disabled={setting.type === voiceAssistant}
                >
                  {I18n.t('buttons.set')}
                </Button>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Wrapper
              className={classes.voiceSettingContainer}
              key={`setting-${index + 1}`}
            >
              <Wrapper className={classes.voiceSettingLeftSide}>
                <Typography className={classes.voiceSettingTitle}>
                  {I18n.t(`my_devices.${setting.title}`)}
                </Typography>
                <Typography className={classes.voiceSettingDescription}>
                  {I18n.t(`my_devices.${setting.description}`)}
                </Typography>
              </Wrapper>
              <Button
                onClick={() => {
                  setVoiceAssistantSetting(setting)
                }}
                color="primary"
                className={classes.voiceSettingButton}
                disabled={setting.type === voiceAssistant}
              >
                {I18n.t('buttons.set')}
              </Button>
            </Wrapper>
          )))}
      </Wrapper>
      {isChangeCodePopupOpen && (
        <Wrapper className={classes.changeCodePopupContainer}>
          <Wrapper className={classes.changeCodePopup}>
            <Typography className={classes.changeCodePopupTitle}>
              {I18n.t('my_devices.change_user_code')}
            </Typography>
            <TextField
              variant="standard"
              className={classes.changeCodePopupInput}
              placeholder={`${I18n.t('my_devices.enter_user_code')}`}
              value={codeValue}
              onChange={handleCodeChange}
              inputProps={{
                maxLength: codeMaxLength,
              }}
            />
            <Wrapper className={classes.changeCodePopupButtons}>
              <Button
                onClick={() => {
                  setIsChangeCodePopupOpen(!isChangeCodePopupOpen)
                }}
                color="primary"
                className={classes.changeCodePopupButton}
              >
                {I18n.t('buttons.cancel')}
              </Button>
              <Button
                onClick={() => {
                  dispatch(patchVoiceAssistantPin({ voice_assistant_pin: `${codeValue}` }))
                  setIsChangeCodePopupOpen(!isChangeCodePopupOpen)
                }}
                color="primary"
                disabled={codeValue.length !== codeMaxLength}
              >
                {I18n.t('buttons.save')}
              </Button>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      )}
    </>
  )
}

export default VoiceAssistant
