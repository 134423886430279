import { createLogic } from 'redux-logic'
import {
  SYNC_VIRTUAL_SWITCH_CHANNEL,
  SYNC_VIRTUAL_SWITCH_CHANNEL_CANCEL,
} from '@app/constants/virtualSwitches/virtualSwitches'

import {
  syncVirtualSwitchChannelSent,
  updateVirtualSwitchByNumber,
  startEnablingProgress,
  stopEnablingProgress,
} from '@app/actions/virtualSwitches/virtualSwitches'
import Api from '@app/api/virtualSwitches/virtualSwitchChannel'

const subscribeVirtualSwitchChannelLogic = createLogic({
  type: SYNC_VIRTUAL_SWITCH_CHANNEL,
  cancelType: SYNC_VIRTUAL_SWITCH_CHANNEL_CANCEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.virtualSwitchChannel({
      request: () => {
        dispatch(syncVirtualSwitchChannelSent())
      },
      response: (data) => {
        const { number, enabled, status } = data
        if (status === 'progress') {
          store.dispatch(startEnablingProgress(number))
        } else {
          store.dispatch(updateVirtualSwitchByNumber(number, enabled, status))
          store.dispatch(stopEnablingProgress(number))
        }
      },
    })
  },
})

export default subscribeVirtualSwitchChannelLogic
