import React from 'react'
import createSvgIcon from './../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 70 70">
      <g data-name="Devices">
        <path color="currentColor" d="M56.908 11.938a6 6 0 1 0-9.322 6 .908.908 0 0 1 .414.739V22a3 3 0 0 0 6 0v-3.32a.963.963 0 0 1 .447-.768 6 6 0 0 0 2.461-5.974zM51 23a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1zm2.293-6.721A2.935 2.935 0 0 0 52 18.68V20h-2v-1.325a2.922 2.922 0 0 0-1.27-2.38 4 4 0 1 1 4.563-.016zM51 6a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1zM47 7.072a1 1 0 0 1-.867-.5l-1-1.732a1 1 0 1 1 1.732-1l1 1.732a1 1 0 0 1-.865 1.5zM44.071 10a.992.992 0 0 1-.5-.134l-1.732-1a1 1 0 0 1 1-1.732l1.732 1a1 1 0 0 1-.5 1.866zM43 14h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM61 14h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM57.929 10a1 1 0 0 1-.5-1.866l1.732-1a1 1 0 1 1 1 1.732l-1.732 1a.992.992 0 0 1-.5.134zM42.341 19a1 1 0 0 1-.5-1.866l1.732-1a1 1 0 0 1 1 1.732l-1.732 1a.992.992 0 0 1-.5.134zM59.659 19a.992.992 0 0 1-.5-.134l-1.732-1a1 1 0 1 1 1-1.732l1.732 1a1 1 0 0 1-.5 1.866zM55 7.072a1 1 0 0 1-.865-1.5l1-1.732a1 1 0 0 1 1.732 1l-1 1.732a1 1 0 0 1-.867.5zM32.03 54a18.894 18.894 0 0 1-2.116-.12 18 18 0 0 1-8.052-32.75 3.044 3.044 0 0 1 2.473-.433 2.969 2.969 0 0 1 1.9 1.541 3.005 3.005 0 0 1-1.01 3.862A11.989 11.989 0 0 0 24 44.94a11.995 11.995 0 0 0 19.935-7.7 12.035 12.035 0 0 0-5.231-11.187 2.973 2.973 0 0 1-.956-3.793 2.991 2.991 0 0 1 1.923-1.554 3.043 3.043 0 0 1 2.478.435 18 18 0 0 1 1.84 28.285A17.786 17.786 0 0 1 32.03 54zm-8.452-31.4a1.052 1.052 0 0 0-.591.184 16 16 0 1 0 18.037.008 1.048 1.048 0 0 0-.849-.151.98.98 0 0 0-.638.515.968.968 0 0 0 .29 1.24 14 14 0 1 1-15.735.056 1.012 1.012 0 0 0 .376-1.284.977.977 0 0 0-.636-.536 1.04 1.04 0 0 0-.254-.032z"/>
        <path color="currentColor" d="M32 38a3 3 0 0 1-3-3V19a3 3 0 0 1 6 0v16a3 3 0 0 1-3 3zm0-20a1 1 0 0 0-1 1v16a1 1 0 0 0 2 0V19a1 1 0 0 0-1-1zM11 16a1 1 0 0 1-1-1 5.006 5.006 0 0 1 5-5 1 1 0 0 1 0 2 3 3 0 0 0-3 3 1 1 0 0 1-1 1zM3 16a1 1 0 0 1-1-1A13.015 13.015 0 0 1 15 2a1 1 0 0 1 0 2A11.013 11.013 0 0 0 4 15a1 1 0 0 1-1 1z"/>
        <path color="currentColor" d="M7 16a1 1 0 0 1-1-1 9.011 9.011 0 0 1 9-9 1 1 0 0 1 0 2 7.008 7.008 0 0 0-7 7 1 1 0 0 1-1 1zM7.5 32a.976.976 0 0 1-.2-.02 1 1 0 0 1-.78-1.18A26.071 26.071 0 0 1 32 10a1 1 0 0 1 0 2A24.063 24.063 0 0 0 8.48 31.2a1 1 0 0 1-.98.8zM7 37a1 1 0 0 1-1-1q0-.522.021-1.04a1 1 0 0 1 2 .08Q8 35.517 8 36a1 1 0 0 1-1 1zM32 62a1 1 0 0 1 0-2 24.063 24.063 0 0 0 23.52-19.2 1 1 0 1 1 1.96.4A26.071 26.071 0 0 1 32 62zM56.981 38h-.039a1 1 0 0 1-.961-1.038c.012-.319.019-.64.019-.962a1 1 0 0 1 2 0q0 .522-.021 1.038a1 1 0 0 1-.998.962zM11 51H9v-2a1 1 0 0 0-2 0v2H5a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2zM18 60h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DevicesCondition', '0 0 80 80', {fontSize: 56})

export default icon
