import * as type from '@app/constants/scenes/zWaveDevices/securityOptions'

export const syncSecurityOptionsConditions = () => ({
  type: type.SYNC_SECURITY_OPTIONS_CONDITIONS,
})

export const updateSecurityOptionsConditions = (securityOptionsConditions) => ({
  type: type.UPDATE_SECURITY_OPTIONS_CONDITIONS,
  securityOptionsConditions,
})
