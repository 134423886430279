const compareTimers = (a, b) => {
  let result
  if (a.number > b.number) {
    result = 1
  }
  if (a.number === b.number) {
    result = 0
  }
  if (a.number < b.number) {
    result = -1
  }
  return result
}

export const convertActionTimersForScreen = (backendTimers) => {
  const filteredTimers = backendTimers.filter((timer) => (timer.timer_type !== 'no_timer')
    && (timer.number >= 1)
    && (timer.number <= 8)
    && (timer.status === 'complete')
    && (timer.timer_type !== 'retriggerable'))
  const sortedTimers = filteredTimers.sort(compareTimers)
  return sortedTimers.map((sortedTimer) => ({
    name: sortedTimer.number,
    type: sortedTimer.timer_type,
    duration: sortedTimer.duration,
  }))
}

export const convertConditionTimersForScreen = (backendTimers) => {
  if (!backendTimers.length) {
    return 'all_used'
  }
  const filteredTimers = backendTimers.filter((timer) => (timer.timer_type !== 'no_timer')
    && (timer.number >= 1)
    && (timer.number <= 8)
    && (timer.status === 'complete'))
  const sortedTimers = filteredTimers.sort(compareTimers)
  const resultTimers = sortedTimers.map((sortedTimer) => ({
    name: sortedTimer.number,
    type: sortedTimer.timer_type,
    duration: sortedTimer.duration,
  }))
  if (!resultTimers.length) {
    return 'no_timers_configured'
  }
  return resultTimers
}
