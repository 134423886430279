import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import { Button, Paper, Typography } from '@mui/material'
import {
  Autocomplete, Circle, GoogleMap, LoadScript, Marker,
} from '@react-google-maps/api'
import Geocode from 'react-geocode'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { apiKey, libraries, options } from '@app/lib/Scenes/geofences'
import { fetchUnit, patchUnitLocation, updateUnitCoordinates } from '@app/actions/unit'
import { InputClearIcon, SearchLoopIcon } from 'icons'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './MezzoLocation.style'

const MezzoLocation = (): Node => {
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const {
    latitude, longitude, ssid, online,
  } = useSelector((state) => state.unit)
  const { role } = useSelector((state) => state.users.currentUser.user)
  const isAdmin = checkIfAdmin(role)

  const classes = useStyles()
  const dispatch = useDispatch()
  const [coordinates, setCoordinates] = useState({
    lat: Number(latitude),
    lng: Number(longitude),
  })
  const [address, setAddress] = useState < string > ('')
  const [map, setMap] = useState < any > (null)
  const [autocompleteAddress, setAutocomplete] = useState < any > (null)
  const [dragged, setDragged] = useState < boolean > (false)
  const [autocompleteValue, setAutocompleteValue] = useState < string > ('')
  const [isCoordinatesChanged, setIsCoordinatesChanged] = useState < boolean > (false)

  useEffect(() => {
    dispatch(fetchUnit())
    Geocode.setApiKey(apiKey)
    Geocode.fromLatLng(latitude, longitude).then((response) => {
      setAddress(response.results[0].formatted_address)
    })
  }, [])

  useEffect(() => {
    setIsCoordinatesChanged(false)
  }, [latitude, longitude])

  const onLoadAutoComplete = (autocomplete) => {
    setAutocomplete(autocomplete)
  }

  const onLoadMap = (mapArg) => {
    setMap(mapArg)
  }

  const clearAutocompleteValue = () => {
    setAutocompleteValue('')
  }

  const onPlaceChanged = () => {
    Geocode.setApiKey(apiKey)
    let autoLat
    let autoLng
    let finalAddress
    if (autocompleteAddress && autocompleteAddress.getPlace()) {
      autoLat = autocompleteAddress.getPlace().geometry.location.lat()
      autoLng = autocompleteAddress.getPlace().geometry.location.lng()
    }
    Geocode.fromLatLng((!dragged && autoLat) || coordinates.lat, (!dragged && autoLng) || coordinates.lng).then((response) => {
      finalAddress = response.results[0].formatted_address
      setCoordinates({
        lat: (autocompleteAddress && autocompleteAddress.getPlace() && !dragged) ? autoLat : map && map.getCenter().lat(),
        lng: (autocompleteAddress && autocompleteAddress.getPlace() && !dragged) ? autoLng : map && map.getCenter().lng(),
      })
      setAddress(finalAddress)
      setIsCoordinatesChanged(true)
    })
  }

  const onChangeCenter = () => {
    Geocode.setApiKey(apiKey)
    let autoLat
    let autoLng
    Geocode.fromLatLng((!dragged && autoLat) || coordinates.lat, (!dragged && autoLng) || coordinates.lng).then((response) => {
      setCoordinates({
        lat: map && map.getCenter().lat(),
        lng: map && map.getCenter().lng(),
      })
      setAddress(response.results[0].formatted_address)
      setIsCoordinatesChanged(true)
      setDragged(true)
    })
  }

  return (
    <Paper className={classes.root}>
      <Wrapper className={classes.main}>
        <Wrapper className={classNames(classes.locationCard, isMobile && classes.locationCardMobile)}>
          <Typography className={classNames(classes.mezzo, isMobile && classes.mezzoMobile)}>
            {`${ssid} ${I18n.t('geofence_web.location')}`}
          </Typography>
          <Typography className={classNames(classes.address, isMobile && classes.addressMobile)}>
            {`${I18n.t('geofence_web.address')}: ${address}`}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.mapContainer}>
          <LoadScript
            id="script-loader"
            googleMapsApiKey={apiKey}
            libraries={libraries}
            onUnmount={() => [...document.querySelectorAll("script[src*='maps.googleapis.com']")].map((script) => (
              script.parentNode && script.parentNode.removeChild(script)))}
          >
            <GoogleMap
              id="searchbox"
              mapContainerStyle={{
                height: isMobile ? '400px' : '580px',
                width: '100%',
              }}
              zoom={18}
              center={coordinates}
              onLoad={onLoadMap}
              onDragEnd={onChangeCenter}
              options={{ streetViewControl: false, disableDefaultUI: true }}
            >
              <Autocomplete
                onLoad={onLoadAutoComplete}
                onPlaceChanged={onPlaceChanged}
              >
                <Wrapper className={classNames(classes.inputWrapper, !isAdmin && classes.hideInput)}>
                  <SearchLoopIcon className={classes.loopIcon} />
                  <InputClearIcon
                    className={classes.closeIcon}
                    onClick={() => {
                      clearAutocompleteValue()
                    }}
                  />
                  <input
                    type="text"
                    placeholder={address}
                    value={autocompleteValue}
                    className={classNames(classes.autocompleteInput, isMobile && classes.autocompleteInputMobile)}
                    onChange={(event) => {
                      setDragged(false)
                      setAutocompleteValue(event.target.value)
                    }}
                  />
                </Wrapper>
              </Autocomplete>
              <Circle
                radius={100}
                center={coordinates}
                options={options}
              />
              <Marker position={coordinates} />
            </GoogleMap>
          </LoadScript>
        </Wrapper>
        {isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            disabled={!isCoordinatesChanged || !online}
            onClick={() => {
              dispatch(updateUnitCoordinates({ lat: coordinates.lat, lng: coordinates.lng }))
              dispatch(patchUnitLocation())
            }}
          >
            {I18n.t('buttons.save')}
          </Button>
        )}

      </Wrapper>
    </Paper>
  )
}

export default MezzoLocation
