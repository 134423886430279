import { createLogic } from 'redux-logic'
import Api from '@app/api/groups'
import handleErrors from '@app/helpers/handleErrors'
import { GROUPS_DELETE_DEVICE } from '@app/constants/groups/groups'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const deleteGroupDevice = createLogic({
  type: GROUPS_DELETE_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(GROUPS_DELETE_DEVICE))
    const result = action.zwave
      ? Api.groupDeleteDeviceZwave(AccessToken, action.deviceId)
      : Api.groupDeleteDeviceTwr(AccessToken, action.deviceId)
    result
      .then(() => {
        dispatch(apiRequestDone(GROUPS_DELETE_DEVICE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('groupDeleteDeviceZwave or groupDeleteDeviceTwr failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteGroupDevice
