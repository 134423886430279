import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M135.366 36.6346H13.627V104.457H135.366V36.6346Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8 31V110.084H141V31H8ZM10.9338 107.15V33.9338H138.066V107.15H10.9338Z" fill="currentColor"/>
            <path d="M77.5069 108.609V108.109H77.0069H71.9927H71.4927V108.609V117.677H44.314H43.814V118.177V119.982V120.482H44.314H104.693H105.193V119.982V118.177V117.677H104.693H77.5069V108.609Z" fill="currentColor" stroke="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwTVIcon')

export default icon
