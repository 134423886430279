import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    width: '100%',
    display: 'inline-flex',
    minHeight: '36px',
    position: 'relative',
  },
  btn: {
    height: 36,
    borderRadius: 0,
    color: '#fff',
    position: 'absolute',
    boxShadow: 'none',
    width: '50%',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  btnArm: {
    left: 0,
    top: 0,
  },
  armed: {
    width: '58%',
    zIndex: 200,
    transition: 'all 0.2s ease-out',
    borderRadius: '0 18px 18px 0',
    '&:hover.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },

  },
  armText: {
    position: 'absolute',
    left: 30,
  },
  btnDisarm: {
    right: 0,
    top: 0,
  },
  disarmed: {
    width: '58%',
    zIndex: 200,
    transition: 'all 0.2s ease-out',
    borderRadius: '18px 0 0 18px',
    '&:hover.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },

  },
  disarmText: {
    position: 'absolute',
    right: 30,
  },
  armedIcon: {
    position: 'absolute',
    left: 'calc(90% - 12px)',
  },
  armLoad: {
    position: 'absolute',
    left: 'calc(90% - 19px)',
  },
  disarmLoad: {
    position: 'absolute',
    right: 'calc(90% - 19px)',
  },
  disarmedIcon: {
    position: 'absolute',
    right: 'calc(90% - 12px)',
  },
  disarmButtonCover: {
    height: 36,
    backgroundColor: theme.palette.shadow.color,
    position: 'absolute',
    boxShadow: 'none',
    right: 0,
    top: 0,
    width: '58%',
    zIndex: 300,
    transition: 'all 0.2s ease-out',
    borderRadius: '18px 0 0 18px',
  },
}))

export default useStyles
