import React from 'react'
import PropTypes from 'prop-types'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import SyncLoading from '@app/components/Common/SyncLoading/SyncLoading'
import { syncType } from '@app/lib/Synchronize/syncTypes'

class SyncPopups extends React.PureComponent {
  onDeclineWarningClick=() => {
    const { closeSyncWarningPopup } = this.props
    closeSyncWarningPopup()
  }

  onAcceptWarningClick=() => {
    const { closeSyncWarningPopup, syncStart } = this.props
    closeSyncWarningPopup()
    syncStart()
  }

  onRetryErrorClick=() => {
    const {
      closeSyncErrorPopup, syncStart, set, currentSyncType,
    } = this.props
    closeSyncErrorPopup()
    if (currentSyncType === syncType.SYNC_COUNTERS) {
      syncStart()
    } else {
      set()
    }
  }

  onCancelErrorClick=() => {
    const { closeSyncErrorPopup } = this.props
    closeSyncErrorPopup()
  }

  render() {
    const {
      isSyncWarningPopupOpen,
      isSyncErrorPopupOpen,
      isSyncInProgressPopupOpen,
      errorText,
    } = this.props
    return (
      <>
        <WarningComponent
          open={isSyncWarningPopupOpen}
          onClose={() => {
            this.onDeclineWarningClick()
          }}
          onClick={() => {
            this.onAcceptWarningClick()
          }}
          warningText="sync_web.sync_warning"
          leftButton="warning_popup_accept_button"
          rightButton="warning_popup_decline_button"
        />
        {errorText && (
          <WarningComponent
            open={isSyncErrorPopupOpen}
            onClose={() => {
              this.onCancelErrorClick()
            }}
            onClick={() => {
              this.onRetryErrorClick()
            }}
            title={I18n.t('sync_web.error')}
            warningText={errorText}
            leftButton="retry"
            rightButton="cancel"
            untranslatableError
          />
        )}
        <SyncLoading
          open={isSyncInProgressPopupOpen}
        />
      </>
    )
  }
}

SyncPopups.propTypes = {
  closeSyncWarningPopup: PropTypes.func.isRequired,
  closeSyncErrorPopup: PropTypes.func.isRequired,
  isSyncWarningPopupOpen: PropTypes.bool.isRequired,
  isSyncErrorPopupOpen: PropTypes.bool.isRequired,
  isSyncInProgressPopupOpen: PropTypes.bool.isRequired,
  syncStart: PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired,
  currentSyncType: PropTypes.string.isRequired,
  set: PropTypes.func.isRequired,
}

export default SyncPopups
