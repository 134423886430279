import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CONTINUE_DOWNLOADING } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import continueDownloading from '@app/api/cloudFirmwareUpdate/continueDownloading'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const continueDownloadingLogic = createLogic({
  type: SYNC_CONTINUE_DOWNLOADING,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { cloudFirmwareUpdate: { cloudFirmwareUpdateInfo: { continueDownloadingType } } } = store.getState()
    const downloadingTypeData = { process: continueDownloadingType }
    dispatch(apiRequestSent(SYNC_CONTINUE_DOWNLOADING))
    continueDownloading(token, downloadingTypeData)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(apiRequestDone(SYNC_CONTINUE_DOWNLOADING))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('continueDownloading failed without error')
        }
      })
      .then(() => done())
  },
})

export default continueDownloadingLogic
