// @ts-nocheck
/* eslint-disable max-len */
import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

import * as routes from '@app/constants/routes'
import { NotFound } from '@app/components'
import About from './About/About'
import ShadowAuth from './Auth/ShadowAuth'
import SecurityRules from './SecurityRules/SecurityRules'
import ProgramOptions from './ProgramOptions/ProgramOptions'
import POFromConfig from './ProgramOptions/POFromConfig'
import ArmorIpSettings from './ArmorIpSettings/ArmorIpSettings'
import TakeOnBoardSettings from './TakeOnBoardSettings/TakeOnBoardSettings'
import NumberOfZones from './NumberOfZones/NumberOfZones'
import MezzoTime from './MezzoTime/MezzoTime'
import MezzoLocation from './MezzoLocation/MezzoLocation'
import TwrDevicesHealth from './TwrDevicesHealth/TwrDevicesHealth'
import MezzoErrors from './MezzoErrors/MezzoErrors'
import Home from './Home'
import Secrets from './Secrets'
import NotificationsSettings from './Notifications/NotificationsSettings/NotificationsSettings'
import Users from './Users'
import UserEdit from './Users/UserEdit'
import UserRequestDetails from './Users/UserRequestDetails'
import MemberDetails from './Users/MemberDetails'
import Events from './Events'
import MyEnergy from './MyEnergy'
import MyAutomation from './MyAutomation/MyAutomation'
import CreateConditionSelected from './MyAutomation/CreateCondition/CreateConditionSelected'
import EditCondition from './MyAutomation/EditCondition'
import SelectConditionType from './MyAutomation/CreateCondition/SelectConditionType'
import SelectConditionDeviceType from './MyAutomation/CreateCondition/SelectConditionDeviceType'
import SelectConditionStateTwr from './MyAutomation/CreateCondition/SelectConditionStateTwr'
import SelectConditionStateZWave from './MyAutomation/CreateCondition/SelectConditionStateZWave'
import SelectTimeConditionType from './MyAutomation/CreateCondition/TimeConditions/SelectTimeConditionType'
import SelectTimeConditionStateWhen from './MyAutomation/CreateCondition/TimeConditions/SelectTimeConditionStateWhen'
import SelectTimeConditionStateTime from './MyAutomation/CreateCondition/TimeConditions/SelectTimeConditionStateTime'
import SelectTimeConditionStateDay from './MyAutomation/CreateCondition/TimeConditions/SelectTimeConditionStateDay'
import SelectTimeConditionStateDate from './MyAutomation/CreateCondition/TimeConditions/SelectTimeConditionStateDate'
import SelectMezzoConditionState from './MyAutomation/CreateCondition/MezzoConditions/SelectMezzoConditionState'
import AdjustDurationMezzoLights from './MyAutomation/CreateAction/MezzoActions/MezzoLights/AdjustDurationMezzoLights'
import SelectMultilevelSwitchConditionsState from './MyAutomation/CreateCondition/SelectMultilevelSwitchConditionState'
import SelectColorConditionState from './MyAutomation/CreateCondition/SelectColorConditionState'
import SelectGeofencesCondition from './MyAutomation/CreateCondition/GeofencesConditions/SelectGeofencesCondition'
import SelectGeofencesLocation from './MyAutomation/CreateCondition/GeofencesConditions/SelectGeofencesLocation'
import SelectGeofencesName from './MyAutomation/CreateCondition/GeofencesConditions/SelectGeofencesName'
import SelectGeofencesUser from './MyAutomation/CreateCondition/GeofencesConditions/SelectGeofencesUser'
import SelectGeofencesState from './MyAutomation/CreateCondition/GeofencesConditions/SelectGeofencesState'
import SelectRoomCondition from './MyAutomation/CreateCondition/RoomCondition/SelectRoomCondition'
import SelectRoomConditionState from './MyAutomation/CreateCondition/RoomCondition/SelectRoomConditionState'
import SelectGroupCondition from './MyAutomation/CreateCondition/GroupCondition/SelectGroupCondition'
import SelectGroupConditionState from './MyAutomation/CreateCondition/GroupCondition/SelectGroupConditionState'
import CreateActionSelected from './MyAutomation/CreateAction/CreateActionSelected'
import SelectAnotherSceneCondition from './MyAutomation/CreateCondition/SelectAnotherSceneCondition'
import SelectBinarySensorStateSelected from './MyAutomation/CreateCondition/BinarySensorConditions/SelectBinarySensorStateSelected'
import SelectBinarySensorType from './MyAutomation/CreateCondition/BinarySensorConditions/SelectBinarySensorType'
import SelectBinarySensorValue from './MyAutomation/CreateCondition/BinarySensorConditions/SelectBinarySensorValue'
import SelectMultilevelSensorStateSelected from './MyAutomation/CreateCondition/MultilevelSensorConditions/SelectMultilevelSensorStateSelected'
import SelectMultilevelSensorType from './MyAutomation/CreateCondition/MultilevelSensorConditions/SelectMultilevelSensorType'
import SelectMultilevelSensorValue from './MyAutomation/CreateCondition/MultilevelSensorConditions/SelectMultilevelSensorValue'
import LuminanceSensorExample from './MyAutomation/CreateCondition/MultilevelSensorConditions/MultilevelSensorExamples/LuminanceSensorExample'
import UltravioletSensorExample from './MyAutomation/CreateCondition/MultilevelSensorConditions/MultilevelSensorExamples/UltravioletSensorExample'
import SelectCentralSceneStateSelected from './MyAutomation/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneStateSelected'
import SelectCentralSceneSubDevice from './MyAutomation/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDevice'
import SelectCentralSceneSubDeviceValue from './MyAutomation/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDeviceValue'
import SelectMultichannelBinarySwitchStateSelected from './MyAutomation/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelBinarySwitchStateSelected'
import SelectMultichannelSubDevice from './MyAutomation/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelSubDevice'
import SelectMultichannelSubDeviceValue from './MyAutomation/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelSubDeviceValue'
import SelectDoorLockConditionState from './MyAutomation/CreateCondition/DoorLockCondition/SelectDoorLockConditionState'
import EditAction from './MyAutomation/EditAction'
import SelectActionDeviceType from './MyAutomation/CreateAction/SelectActionDeviceType'
import SelectConditionDeviceStateType from './MyAutomation/CreateCondition/SelectConditionDeviceStateType'
import SelectActionDeviceStateType from './MyAutomation/CreateAction/DevicesActions/SelectActionDeviceStateType'
import SelectActionType from './MyAutomation/CreateAction/SelectActionType'
import SelectMezzoActionState from './MyAutomation/CreateAction/MezzoActions/SelectMezzoActionState'
import SelectMezzoChimesState from './MyAutomation/CreateAction/MezzoActions/SelectMezzoChimesState'
import SelectColorActionStateSelected from './MyAutomation/CreateAction/ColorActions/SelectColorActionStateSelected'
import SelectActionColorType from './MyAutomation/CreateAction/ColorActions/SelectActionColorType'
import SelectColorActionHue from './MyAutomation/CreateAction/ColorActions/SelectColorActionHue'
import SelectColorActionHueAfterDuration from './MyAutomation/CreateAction/ColorActions/SelectColorActionHueAfterDuration'
import SelectBinarySwitchActionStateSelected from './MyAutomation/CreateAction/BinarySwitchActions/SelectBinarySwitchActionStateSelected'
import SelectBinarySwitchActionStateValue from './MyAutomation/CreateAction/BinarySwitchActions/SelectBinarySwitchActionStateValue'
import SelectMezzoActionStateType from './MyAutomation/CreateAction/MezzoActions/MezzoLights/SelectMezzoActionStateType'
import SelectMezzoLightState from './MyAutomation/CreateCondition/MezzoConditions/SelectMezzoLightState'
import SelectMezzoLightsActionStateSelected from './MyAutomation/CreateAction/MezzoActions/MezzoLights/SelectMezzoLightsActionStateSelected'
import SelectMezzoLightsActionStateValue from './MyAutomation/CreateAction/MezzoActions/MezzoLights/SelectMezzoLightsActionStateValue'
import AdjustDurationBrightness from './MyAutomation/CreateAction/MezzoActions/MezzoLights/AdjustDurationBrightness'
import SelectBrightnessValueAfterDuration from './MyAutomation/CreateAction/MezzoActions/MezzoLights/SelectBrightnessValueAfterDuration'
import SelectMultilevelSwitchValueAfterDuration from './MyAutomation/CreateAction/MultilevelSwitchActions/SelectMultilevelSwitchValueAfterDuration'
import SelectDoorlockValueAfterDuration from './MyAutomation/CreateAction/DoorlockActions/SelectDoorlockValueAfterDuration'
import SelectMultilevelSwitchActionType from './MyAutomation/CreateAction/MultilevelSwitchActions/SelectMultilevelSwitchActionType'
import SelectMultilevelSwitchActionStateValue from './MyAutomation/CreateAction/MultilevelSwitchActions/SelectMultilevelSwitchActionStateValue'
import SelectDoorlockActionStateValue from './MyAutomation/CreateAction/DoorlockActions/SelectDoorlockActionStateValue'
import SelectMultilevelSwitchActionStateSelected from './MyAutomation/CreateAction/MultilevelSwitchActions/SelectMutilevelSwitchActionStateSelected'
import AdjustDelayMultilevelSwitch from './MyAutomation/CreateAction/MultilevelSwitchActions/AdjustDelayMultilevelSwitch'
import AdjustDelayDoorlock from './MyAutomation/CreateAction/DoorlockActions/AdjustDelayDoorlock'
import AdjustDurationDoorlock from './MyAutomation/CreateAction/DoorlockActions/AdjustDurationDoorlock'
import AdjustDurationMultilevelSwitch from './MyAutomation/CreateAction/MultilevelSwitchActions/AdjustDurationMultilevelSwitch'
import SelectRoomAction from './MyAutomation/CreateAction/RoomsActions/SelectRoomAction'
import SelectRoomActionState from './MyAutomation/CreateAction/RoomsActions/SelectRoomActionState'
import SelectRoomActionStateSelected from './MyAutomation/CreateAction/RoomsActions/SelectRoomActionStateSelected'
import SelectActionRoomStateType from './MyAutomation/CreateAction/RoomsActions/SelectActionRoomStateType'
import SceneReview from './MyAutomation/SceneReview'
import SelectConditionDeviceTabs from './MyAutomation/CreateCondition/DevicesConditions/SelectConditionDeviceTabs'
import SelectActionDeviceTabs from './MyAutomation/CreateAction/DevicesActions/SelectActionDeviceTabs'
import SceneReviewEdit from './MyAutomation/SceneReviewEdit'
import AdjustDelay from './MyAutomation/CreateAction/BinarySwitchActions/AdjustDelay'
import AdjustDelayColor from './MyAutomation/CreateAction/ColorActions/AdjustDelayColor'
import AdjustDurationBinarySwitch from './MyAutomation/CreateAction/BinarySwitchActions/AdjustDurationBinarySwitch'
import AdjustDurationColor from './MyAutomation/CreateAction/ColorActions/AdjustDurationColor'
import SelectBinarySwitchValueAfterDuration from './MyAutomation/CreateAction/BinarySwitchActions/SelectBinarySwitchValueAfterDuration'
import SelectActionColorTypeAfterDuration from './MyAutomation/CreateAction/ColorActions/SelectActionColorTypeAfterDuration'
import Email from './Wizard/ForgotPassword/Email'
import Code from './Wizard/ForgotPassword/Code'
import NewPassword from './Wizard/ForgotPassword/NewPassword'
import SignIn from './Wizard/SignIn/SignIn'
import SignUp from './Wizard/SignUp/SignUp'
import SelectMezzoConnection from './Wizard/SelectMezzoConnection/SelectMezzoConnection'
import MyStatus from './MyStatus/MyStatus'
import MyDevices from './MyDevices/MyDevices'
import AddTwrDevice from './MyDevices/AddDevice/AddTwrDevice'
import AddZwaveDevice from './MyDevices/AddDevice/AddZwaveDevice'
import DeviceInfo from './MyDevices/DeviceInfo/DeviceInfo'
import DeviceRooms from './MyDevices/DeviceInfo/DeviceRooms'
import DeviceGroups from './MyDevices/DeviceInfo/DeviceGroups'
import DeviceColours from './MyDevices/DeviceInfo/DeviceColours'
import DeviceSettings from './MyDevices/DeviceSettings/DeviceSettings'
import EditIcon from './MyDevices/DeviceSettings/GeneralSettings/MainSettings/EditIcon'
import TwrAdditionalModes from './MyDevices/DeviceSettings/GeneralSettings/AdvancedSettings/twr/TwrAdditionalModes'
import TwrSirenOptions from './MyDevices/DeviceSettings/GeneralSettings/AdvancedSettings/twr/TwrSirenOptions'
import TwrTakeOnBoardDeviceTypes from './MyDevices/DeviceSettings/GeneralSettings/AdvancedSettings/twr/TwrTakeOnBoardDeviceTypes'
import ControlOptions from './MyDevices/DeviceSettings/GeneralSettings/MainSettings/ControlOptions'
import ZwaveDetailsAndParams from './MyDevices/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams'
import ReplaceDevice from './MyDevices/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/FailedNode/ReplaceDevice'
import ZwaveSecurityOptions from './MyDevices/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveSecurityOptions'
import Timers from './Timers/Timers'
import TimersReset from './Timers/TimersReset'
import TimerSettings from './Timers/TimerSettings'
import Geofences from './Geofences/Geofences'
import CreateGeofence from './Geofences/CreateGeofence'
import GroupsList from './Groups/GroupsList'
import GroupsAddDevice from './Groups/GroupsAddDevice'
import EditGroup from './Groups/GroupsEdit'
import ShowGroup from './Groups/ShowGroup'
import RoomsList from './Rooms/RoomsList'
import EditRoom from './Rooms/RoomsEdit'
import ShowRoom from './Rooms/ShowRoom'
import RoomsAddDevice from './Rooms/RoomsAddDevice'
import ContactsList from './Contacts/Contacts'
import PillsReminders from './PillsReminders/PillsReminders'
import ReminderEditor from './PillsReminders/ReminderEditor'
import PillsRemindersEvents from './PillsReminders/PillsRemindersEvents'
import SelectDoorlockActionStateSelected from './MyAutomation/CreateAction/DoorlockActions/SelectDoorlockActionStateSelected'
import SelectBrightnessValue from './MyAutomation/CreateAction/MezzoActions/MezzoLights/SelectBrightnessValue'
import SelectBrightnessActionStateSelected
  from './MyAutomation/CreateAction/MezzoActions/MezzoLights/SelectBrightnessActionStateSelected'
import SelectRoomColorActionStateSelected from './MyAutomation/CreateAction/RoomsActions/SelectRoomColorActionStateSelected'
import SelectRoomActionColorType from './MyAutomation/CreateAction/RoomsActions/SelectRoomActionColorType'
import SelectRoomColorActionHue from './MyAutomation/CreateAction/RoomsActions/SelectRoomColorActionHue'
import AdjustDelayColorRoom from './MyAutomation/CreateAction/RoomsActions/AdjustDelayColorRoom'
import AdjustDurationColorRoom from './MyAutomation/CreateAction/RoomsActions/AdjustDurationColorRoom'
import SelectActionRoomColorTypeAfterDuration from './MyAutomation/CreateAction/RoomsActions/SelectActionRoomColorTypeAfterDuration'
import SelectColorRoomActionHueAfterDuration from './MyAutomation/CreateAction/RoomsActions/SelectColorRoomActionHueAfterDuration'
import SelectGroupActionStateSelected from './MyAutomation/CreateAction/GroupsActions/SelectGroupActionStateSelected'
import SelectGroupAction from './MyAutomation/CreateAction/GroupsActions/SelectGroupAction'
import SelectGroupActionState from './MyAutomation/CreateAction/GroupsActions/SelectGroupActionState'
import SelectActionGroupStateType from './MyAutomation/CreateAction/GroupsActions/SelectActionGroupStateType'
import SelectGroupColorActionStateSelected from './MyAutomation/CreateAction/GroupsActions/SelectGroupColorActionStateSelected'
import SelectGroupActionColorType from './MyAutomation/CreateAction/GroupsActions/SelectGroupActionColorType'
import SelectGroupColorActionHue from './MyAutomation/CreateAction/GroupsActions/SelectGroupColorActionHue'
import AdjustDelayColorGroup from './MyAutomation/CreateAction/GroupsActions/AdjustDelayColorGroup'
import AdjustDurationColorGroup from './MyAutomation/CreateAction/GroupsActions/AdjustDurationColorGroup'
import SelectActionGroupColorTypeAfterDuration from './MyAutomation/CreateAction/GroupsActions/SelectActionGroupColorTypeAfterDuration'
import SelectColorGroupActionHueAfterDuration from './MyAutomation/CreateAction/GroupsActions/SelectColorGroupActionHueAfterDuration'
import SelectConditionSecurityManagementDevice from './MyAutomation/CreateCondition/MezzoConditions/SelectConditionSecurityManagementDevice'
import SelectConditionTimer from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectTimer'
import SelectConditionTimerValue from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectTimerValue'
import SelectActionSecurityManagementDevice from './MyAutomation/CreateAction/MezzoActions/SelectActionSecurityManagementDevice'
import SelectActionTimer from './MyAutomation/CreateAction/MezzoActions/TimersActions/SelectTimer'
import SelectActionTimerValue from './MyAutomation/CreateAction/MezzoActions/TimersActions/SelectTimerValue'
import SelectTriggeringDevice from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectTriggeringDevice'
import SelectObservingDeviceType from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingDeviceType'
import SelectTriggeringDeviceType from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringDeviceType'
import SelectTriggeringZWaveDevice from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringZWaveDevice'
import SelectTriggeringState from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringState'
import SelectObservingTWRDevice from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingTWRDevice'
import SelectObservingZWaveDevice from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingZWaveDevice'
import SelectTriggeringRoom from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringRoom'
import SelectTriggeringGroup from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringGroup'
import SelectObservingGroup from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingGroup'
import SelectObservingRoom from './MyAutomation/CreateCondition/MezzoConditions/TimersConditions/ObservingDevices/SelectObservingRoom'
import HealthAlert from './HealthAlert/HealthAlert'
import SettingsType from './MyAutomation/CreateAction/Notifications/SettingsType'
import SelectNotificationUser from './MyAutomation/CreateAction/Notifications/SelectNotificationUser'
import SelectVirtualSwitchCondition from './MyAutomation/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchCondition'
import SelectVirtualSwitchConditionState from './MyAutomation/CreateCondition/VirtualSwitchCondition/SelectVirtualSwitchConditionState'
import VirtualSwitches from './MyDevices/MyMezzo/VirtualSwitches'
import Activity from './Activity/Activity'
import SelectVirtualSwitchAction from './MyAutomation/CreateAction/VirtualSwitchAction/SelectVirtualSwitchAction'
import SelectVirtualSwitchActionState from './MyAutomation/CreateAction/VirtualSwitchAction/SelectVirtualSwitchActionState'
import SelectVirtualSwitchActionValue from './MyAutomation/CreateAction/VirtualSwitchAction/SelectVirtualSwitchActionValue'
import SynchronizeScreen from './Synchronize/SynchronizeScreen'
import OffsetSettings from './MyAutomation/CreateCondition/TimeConditions/OffsetSettings'
import SelectMultichannelActionSubDevice from './MyAutomation/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionSubDevice'
import SelectMultichannelActionSubDeviceValue from './MyAutomation/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionSubDeviceValue'
import SelectMultichannelActionStateSelected from './MyAutomation/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionStateSelected'
import AdjustDurationMultichannelAction from './MyAutomation/CreateAction/ZwaveMultichannelAction/AdjustDurationMultichannelAction'
import SelectMultichannelActionValueAfterDuration from './MyAutomation/CreateAction/ZwaveMultichannelAction/SelectMultichannelActionValueAfterDuration'
import CloudFirmwareUpdate from './CloudFirmwareUpdate/CloudFirmwareUpdate'
import AdjustDelayVirtualSwitch from './MyAutomation/CreateAction/VirtualSwitchAction/AdjustDelayVirtualSwitch'
import AdjustDurationVirtualSwitch from './MyAutomation/CreateAction/VirtualSwitchAction/AdjustDurationVirtualSwitch'
import SelectVirtualSwitchValueAfterDuration from './MyAutomation/CreateAction/VirtualSwitchAction/SelectVirtualSwitchValueAfterDuration'
import SelectNotificationSensorType from './MyAutomation/CreateCondition/NotificationSensors/SelectNotificationSensorType'
import SelectEventType from './MyAutomation/CreateCondition/NotificationSensors/SelectEventType'
import SelectNotificationSensorStateSelected
  from './MyAutomation/CreateCondition/NotificationSensors/SelectNotificationSensorStateSelected'
import SelectZWaveDeviceType
  from './MyAutomation/CreateCondition/DevicesConditions/ZWaveDevices/SelectZWaveDeviceType'
import SelectSecurityOptionValue
  from './MyAutomation/CreateCondition/DevicesConditions/ZWaveDevices/SelectSecurityOptionValue'
import TimersInScenes from './MyAutomation/TimersInScenes/TimersInScenes'
import TimersInScenesSettings from './MyAutomation/TimersInScenes/TimersInScenesSettings'
import TimersInScenesReset from './MyAutomation/TimersInScenes/TimersInScenesReset'
import MezzoLightsSettings from './MyDevices/MyMezzo/MezzoLightsSettings'
import MySecurity from './MySecurity/MySecurity'
import DeleteAccountScreen from './Wizard/DeleteAccount/DeleteAccount'

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path={routes.WEB} element={<Home />} />
    <Route path={routes.HOME} element={<Home />} />
    <Route path={routes.EMAIL} element={<Email />} />
    <Route path={routes.CODE} element={<Code />} />
    <Route path={routes.NEW_PASSWORD} element={<NewPassword />} />
    <Route path={routes.SIGN_IN} element={<SignIn />} />
    <Route path={routes.SIGN_UP} element={<SignUp />} />
    <Route path={routes.SELECT_MEZZO_DEVICE_CONNECTION} element={<SelectMezzoConnection />} />
    <Route path={routes.MEMBER_DETAILS} element={<MemberDetails />} />
    <Route path={routes.USER_REQUEST_DETAILS} element={<UserRequestDetails />} />
    <Route path={routes.USER_EDIT} element={<UserEdit />} />
    <Route path={routes.USERS} element={<Users />} />
    <Route path={routes.ABOUT} element={<About />} />
    <Route path={routes.SECURITY_RULES} element={<SecurityRules />} />
    <Route exact path={routes.MEZZO_LIGHTS} element={<MezzoLightsSettings />} />
    <Route exact path={routes.MY_DEVICES} element={<MyDevices />} />
    <Route path={routes.ADD_ZWAVE_DEVICE} element={<AddZwaveDevice />} />
    <Route path={routes.ADD_TWR_DEVICE} element={<AddTwrDevice />} />
    <Route exact path={routes.DEVICE_INFO} element={<DeviceInfo />} />
    <Route exact path={routes.DEVICE_ROOMS} element={<DeviceRooms />} />
    <Route exact path={routes.DEVICE_GROUPS} element={<DeviceGroups />} />
    <Route exact path={routes.DEVICE_COLOURS} element={<DeviceColours />} />
    <Route exact path={routes.DEVICE_SETTINGS} element={<DeviceSettings />} />
    <Route path={routes.DEVICE_ICONS} element={<EditIcon />} />
    <Route path={routes.TWR_ADDITIONAL_MODES} element={<TwrAdditionalModes />} />
    <Route path={routes.TWR_SIREN_OPTIONS} element={<TwrSirenOptions />} />
    <Route path={routes.TAKE_ON_BOARD_DEVICE_TYPES} element={<TwrTakeOnBoardDeviceTypes />} />
    <Route path={routes.CONTROL_OPTIONS} element={<ControlOptions />} />
    <Route exact path={routes.ZWAVE_DETAILS} element={<ZwaveDetailsAndParams />} />
    <Route exact path={routes.FAILED_NODE_REPLACE_DEVICE} element={<ReplaceDevice />} />
    <Route exact path={routes.ZWAVE_SECURITY_OPTIONS} element={<ZwaveSecurityOptions />} />
    <Route path={routes.MEZZO_TIME} element={<MezzoTime />} />
    <Route path={routes.MEZZO_LOCATION} element={<MezzoLocation />} />
    <Route path={routes.TWR_DEVICES_HEALTH} element={<TwrDevicesHealth />} />
    <Route path={routes.MEZZO_ERRORS} element={<MezzoErrors />} />
    <Route path={routes.SHADOW_AUTH} element={<ShadowAuth />} />
    <Route path={routes.SHADOW_AUTH_TOKEN} element={<ShadowAuth />} />
    <Route path={routes.SECRETS} element={<Secrets />} />
    <Route exact path={routes.NOTIFICATIONS} element={<NotificationsSettings />} />
    <Route path={routes.ARMOR_IP} element={<ArmorIpSettings />} />
    <Route path={routes.ARMOR_IP_OLD} element={<ArmorIpSettings />} />
    <Route path={routes.TAKE_ON_BOARD_SETTINGS} element={<TakeOnBoardSettings />} />
    <Route path={routes.NUMBER_OF_ZONES} element={<NumberOfZones />} />
    <Route path={routes.PROGRAM_OPTIONS} element={<ProgramOptions />} />
    <Route path={routes.PROGRAM_OPTIONS_FROM_CONFIG} element={<POFromConfig />} />
    <Route path={routes.EVENTS} element={<Events />} />
    <Route path={routes.MY_ENERGY} element={<MyEnergy />} />
    <Route path={routes.CLOUD_FIRMWARE_UPDATE} element={<CloudFirmwareUpdate />} />
    <Route path={routes.PILLS_REMINDERS_EVENTS} element={<PillsRemindersEvents />} />
    <Route path={routes.PILLS_REMINDER_EDIT} element={<ReminderEditor />} />
    <Route path={routes.PILLS_REMINDER_NEW} element={<ReminderEditor />} />
    <Route path={routes.PILLS_REMINDERS} element={<PillsReminders />} />

    <Route path={routes.SELECT_NOTIFICATION_USER} element={<SelectNotificationUser />} />
    <Route path={routes.SETTINGS_TYPE} element={<SettingsType />} />
    <Route path={routes.MULTICHANNEL_ACTION_VALUE_AFTER_DURATION} element={<SelectMultichannelActionValueAfterDuration />} />
    <Route path={routes.ADJUST_DURATION_MULTICHANNEL_ACTION} element={<AdjustDurationMultichannelAction />} />
    <Route path={routes.SELECT_MULTICHANNEL_ACTION_STATE_SELECTED} element={<SelectMultichannelActionStateSelected />} />
    <Route path={routes.SELECT_MULTICHANNEL_ACTION_SUBDEVICE_VALUE} element={<SelectMultichannelActionSubDeviceValue />} />
    <Route path={routes.SELECT_MULTICHANNEL_ACTION_SUBDEVICE} element={<SelectMultichannelActionSubDevice />} />
    <Route path={routes.SELECT_ACTION_TIMER_VALUE} element={<SelectActionTimerValue />} />
    <Route path={routes.SELECT_ACTION_TIMER} element={<SelectActionTimer />} />
    <Route path={routes.SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE} element={<SelectActionSecurityManagementDevice />} />
    <Route path={routes.ADJUST_DELAY_COLOR_GROUP} element={<AdjustDelayColorGroup />} />
    <Route path={routes.ADJUST_DURATION_COLOR_GROUP} element={<AdjustDurationColorGroup />} />
    <Route path={routes.SELECT_ACTION_GROUP_COLOR_TYPE_AFTER_DURATION} element={<SelectActionGroupColorTypeAfterDuration />} />
    <Route path={routes.SELECT_COLOR_GROUP_ACTION_HUE_AFTER_DURATION} element={<SelectColorGroupActionHueAfterDuration />} />
    <Route path={routes.SELECT_GROUP_COLOR_ACTION_HUE} element={<SelectGroupColorActionHue />} />
    <Route path={routes.SELECT_GROUP_ACTION_COLOR_TYPE} element={<SelectGroupActionColorType />} />
    <Route path={routes.SELECT_GROUP_COLOR_ACTION_STATE_SELECTED} element={<SelectGroupColorActionStateSelected />} />
    <Route path={routes.SELECT_GROUP_ACTION_STATE_SELECTED} element={<SelectGroupActionStateSelected />} />
    <Route path={routes.SELECT_ACTION_GROUP_STATE_TYPE} element={<SelectActionGroupStateType />} />
    <Route path={routes.SELECT_GROUP_ACTION_STATE} element={<SelectGroupActionState />} />
    <Route path={routes.SELECT_GROUP_ACTION} element={<SelectGroupAction />} />
    <Route path={routes.ADJUST_DELAY_COLOR_ROOM} element={<AdjustDelayColorRoom />} />
    <Route path={routes.ADJUST_DURATION_COLOR_ROOM} element={<AdjustDurationColorRoom />} />
    <Route path={routes.SELECT_ACTION_ROOM_COLOR_TYPE_AFTER_DURATION} element={<SelectActionRoomColorTypeAfterDuration />} />
    <Route path={routes.SELECT_COLOR_ROOM_ACTION_HUE_AFTER_DURATION} element={<SelectColorRoomActionHueAfterDuration />} />
    <Route path={routes.SELECT_ROOM_COLOR_ACTION_HUE} element={<SelectRoomColorActionHue />} />
    <Route path={routes.SELECT_ROOM_ACTION_COLOR_TYPE} element={<SelectRoomActionColorType />} />
    <Route path={routes.SELECT_ROOM_COLOR_ACTION_STATE_SELECTED} element={<SelectRoomColorActionStateSelected />} />
    <Route path={routes.SELECT_ROOM_ACTION_STATE_SELECTED} element={<SelectRoomActionStateSelected />} />
    <Route path={routes.SELECT_ACTION_ROOM_STATE_TYPE} element={<SelectActionRoomStateType />} />
    <Route path={routes.SELECT_ROOM_ACTION_STATE} element={<SelectRoomActionState />} />
    <Route path={routes.SELECT_ROOM_ACTION} element={<SelectRoomAction />} />
    <Route path={routes.SELECT_DOORLOCK_ACTION_VALUE_AFTER_DURATION} element={<SelectDoorlockValueAfterDuration />} />
    <Route path={routes.ADJUST_DURATION_DOORLOCK} element={<AdjustDurationDoorlock />} />
    <Route path={routes.ADJUST_DELAY_DOORLOCK} element={<AdjustDelayDoorlock />} />
    <Route path={routes.SELECT_DOORLOCK_ACTION_STATE_SELECTED} element={<SelectDoorlockActionStateSelected />} />
    <Route path={routes.SELECT_MULTILEVEL_SWITCH_ACTION_VALUE_AFTER_DURATION} element={<SelectMultilevelSwitchValueAfterDuration />} />
    <Route path={routes.ADJUST_DURATION_MULTILEVEL_SWITCH} element={<AdjustDurationMultilevelSwitch />} />
    <Route path={routes.ADJUST_DELAY_MULTILEVEL_SWITCH} element={<AdjustDelayMultilevelSwitch />} />
    <Route path={routes.SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED} element={<SelectMultilevelSwitchActionStateSelected />} />
    <Route path={routes.SELECT_DOORLOCK_ACTION_STATE_VALUE} element={<SelectDoorlockActionStateValue />} />
    <Route path={routes.SELECT_MULTILEVEL_SWITCH_ACTION_TYPE} element={<SelectMultilevelSwitchActionType />} />
    <Route path={routes.SELECT_MULTILEVEL_SWITCH_ACTION_STATE_VALUE} element={<SelectMultilevelSwitchActionStateValue />} />
    <Route path={routes.SELECT_BINARY_SWITCH_ACTION_VALUE_AFTER_DURATION} element={<SelectBinarySwitchValueAfterDuration />} />
    <Route path={routes.SELECT_ACTION_COLOR_TYPE_AFTER_DURATION} element={<SelectActionColorTypeAfterDuration />} />
    <Route path={routes.SELECT_COLOR_ACTION_HUE_AFTER_DURATION} element={<SelectColorActionHueAfterDuration />} />
    <Route path={routes.ADJUST_DURATION_BINARY_SWITCH} element={<AdjustDurationBinarySwitch />} />

    <Route path={routes.ADJUST_DURATION_COLOR} element={<AdjustDurationColor />} />
    <Route path={routes.ADJUST_DELAY_BINARY_SWITCH} element={<AdjustDelay />} />
    <Route path={routes.ADJUST_DELAY_COLOR} element={<AdjustDelayColor />} />
    <Route path={routes.ADJUST_DURATION_BRIGHTNESS} element={<AdjustDurationBrightness />} />
    <Route path={routes.SELECT_BRIGHTNESS_VALUE_AFTER_DURATION} element={<SelectBrightnessValueAfterDuration />} />

    <Route path={routes.ADJUST_DURATION_MEZZO_LIGHTS} element={<AdjustDurationMezzoLights />} />
    <Route path={routes.SELECT_BRIGHTNESS_ACTION_STATE_SELECTED} element={<SelectBrightnessActionStateSelected />} />
    <Route path={routes.SELECT_BRIGHTNESS_VALUE} element={<SelectBrightnessValue />} />

    <Route path={routes.SELECT_MEZZO_LIGHTS_ACTION_STATE_SELECTED} element={<SelectMezzoLightsActionStateSelected />} />
    <Route path={routes.SELECT_MEZZO_LIGHTS_ACTION_STATE_VALUE} element={<SelectMezzoLightsActionStateValue />} />
    <Route path={routes.SELECT_MEZZO_ACTION_STATE_TYPE} element={<SelectMezzoActionStateType />} />
    <Route path={routes.SELECT_BINARY_SWITCH_ACTION_STATE_VALUE} element={<SelectBinarySwitchActionStateValue />} />
    <Route path={routes.SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED} element={<SelectBinarySwitchActionStateSelected />} />
    <Route path={routes.SELECT_COLOR_ACTION_HUE} element={<SelectColorActionHue />} />
    <Route path={routes.SELECT_MEZZO_ACTION_STATE} element={<SelectMezzoActionState />} />
    <Route path={routes.SELECT_ACTION_COLOR_TYPE} element={<SelectActionColorType />} />
    <Route path={routes.SELECT_COLOR_ACTION_STATE_SELECTED} element={<SelectColorActionStateSelected />} />
    <Route path={routes.SELECT_CONDITION_DEVICE_TABS} element={<SelectConditionDeviceTabs />} />
    <Route path={routes.SELECT_ACTION_DEVICE_TABS} element={<SelectActionDeviceTabs />} />
    <Route path={routes.SELECT_MEZZO_CHIMES_STATE} element={<SelectMezzoChimesState />} />
    <Route path={routes.SELECT_ACTION_DEVICE_TYPE} element={<SelectActionDeviceType />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_VALUE_AFTER_DURATION} element={<SelectVirtualSwitchValueAfterDuration />} />
    <Route path={routes.ADJUST_DURATION_VIRTUAL_SWITCH} element={<AdjustDurationVirtualSwitch />} />
    <Route path={routes.ADJUST_DELAY_VIRTUAL_SWITCH} element={<AdjustDelayVirtualSwitch />} />
    <Route path={routes.SELECT_NOTIFICATION_SENSOR_STATE_SELECTED} element={<SelectNotificationSensorStateSelected />} />
    <Route path={routes.SELECT_EVENT_TYPE} element={<SelectEventType />} />
    <Route path={routes.SELECT_NOTIFICATION_SENSOR_TYPE} element={<SelectNotificationSensorType />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_CONDITION_STATE} element={<SelectVirtualSwitchConditionState />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_CONDITION} element={<SelectVirtualSwitchCondition />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_ACTION} element={<SelectVirtualSwitchAction />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_ACTION_VALUE} element={<SelectVirtualSwitchActionValue />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_ACTION_STATE} element={<SelectVirtualSwitchActionState />} />
    <Route path={routes.SELECT_VIRTUAL_SWITCH_ACTION_STATE} element={<SelectVirtualSwitchActionState />} />
    <Route path={routes.SELECT_CONDITION_STATE_TWR} element={<SelectConditionStateTwr />} />
    <Route path={routes.SELECT_CONDITION_STATE_ZWAVE} element={<SelectConditionStateZWave />} />
    <Route path={routes.SELECT_CONDITION_TIMER_VALUE} element={<SelectConditionTimerValue />} />
    <Route path={routes.SELECT_OBSERVING_GROUP} element={<SelectObservingGroup />} />
    <Route path={routes.SELECT_OBSERVING_ROOM} element={<SelectObservingRoom />} />
    <Route path={routes.SELECT_OBSERVING_TWR_DEVICE} element={<SelectObservingTWRDevice />} />
    <Route path={routes.SELECT_OBSERVING_ZWAVE_DEVICE} element={<SelectObservingZWaveDevice />} />
    <Route path={routes.SELECT_OBSERVING_DEVICE_TYPE} element={<SelectObservingDeviceType />} />
    <Route path={routes.SELECT_TRIGGERING_STATE} element={<SelectTriggeringState />} />
    <Route path={routes.SELECT_TRIGGERING_GROUP} element={<SelectTriggeringGroup />} />
    <Route path={routes.SELECT_TRIGGERING_ROOM} element={<SelectTriggeringRoom />} />
    <Route path={routes.SELECT_TRIGGERING_ZWAVE_DEVICE} element={<SelectTriggeringZWaveDevice />} />
    <Route path={routes.SELECT_TRIGGERING_DEVICE_TYPE} element={<SelectTriggeringDeviceType />} />
    <Route path={routes.SELECT_TRIGGERING_DEVICE} element={<SelectTriggeringDevice />} />
    <Route path={routes.SELECT_CONDITION_TIMER} element={<SelectConditionTimer />} />
    <Route path={routes.SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE} element={<SelectConditionSecurityManagementDevice />} />
    <Route path={routes.SELECT_CONDITION_TYPE} element={<SelectConditionType />} />
    <Route path={routes.SELECT_CONDITION_DEVICE_TYPE} element={<SelectConditionDeviceType />} />
    <Route path={routes.SELECT_CONDITION_DEVICE_STATE_TYPE} element={<SelectConditionDeviceStateType />} />
    <Route path={routes.SELECT_ZWAVE_DEVICE_TYPE} element={<SelectZWaveDeviceType />} />
    <Route path={routes.SELECT_SECURITY_OPTION_VALUE} element={<SelectSecurityOptionValue />} />
    <Route path={routes.SELECT_ACTION_DEVICE_STATE_TYPE} element={<SelectActionDeviceStateType />} />
    <Route path={routes.SELECT_TIME_CONDITION_TYPE} element={<SelectTimeConditionType />} />
    <Route path={routes.OFFSET_SETTINGS} element={<OffsetSettings />} />
    <Route path={routes.SELECT_TIME_CONDITION_STATE_WHEN} element={<SelectTimeConditionStateWhen />} />
    <Route path={routes.SELECT_TIME_CONDITION_STATE_TIME} element={<SelectTimeConditionStateTime />} />
    <Route path={routes.SELECT_TIME_CONDITION_STATE_DAY} element={<SelectTimeConditionStateDay />} />
    <Route path={routes.SELECT_TIME_CONDITION_STATE_DATE} element={<SelectTimeConditionStateDate />} />
    <Route path={routes.SELECT_COLOR_CONDITION_STATE} element={<SelectColorConditionState />} />
    <Route path={routes.SELECT_MEZZO_CONDITION_STATE} element={<SelectMezzoConditionState />} />
    <Route path={routes.SELECT_MEZZO_LIGHT_STATE} element={<SelectMezzoLightState />} />
    <Route path={routes.SELECT_GEOFENCES_CONDITION} element={<SelectGeofencesCondition />} />
    <Route path={routes.SELECT_GEOFENCES_LOCATION} element={<SelectGeofencesLocation />} />
    <Route path={routes.SELECT_GEOFENCES_NAME} element={<SelectGeofencesName />} />
    <Route path={routes.SELECT_GEOFENCES_USER} element={<SelectGeofencesUser />} />
    <Route path={routes.SELECT_GEOFENCES_STATE} element={<SelectGeofencesState />} />
    <Route path={routes.CREATE_ACTION_SELECTED} element={<CreateActionSelected />} />
    <Route path={routes.SELECT_ACTION_TYPE} element={<SelectActionType />} />
    <Route path={routes.SELECT_ANOTHER_SCENE_CONDITION} element={<SelectAnotherSceneCondition />} />
    <Route path={routes.SELECT_ROOM_CONDITION} element={<SelectRoomCondition />} />
    <Route path={routes.SELECT_ROOM_CONDITION_STATE} element={<SelectRoomConditionState />} />
    <Route path={routes.SELECT_GROUP_CONDITION} element={<SelectGroupCondition />} />
    <Route path={routes.SELECT_GROUP_CONDITION_STATE} element={<SelectGroupConditionState />} />
    <Route path={routes.SELECT_MULTILEVEL_SWITCH_STATE} element={<SelectMultilevelSwitchConditionsState />} />
    <Route path={routes.SELECT_BINARY_SENSOR_TYPE} element={<SelectBinarySensorType />} />
    <Route path={routes.SELECT_BINARY_SENSOR_VALUE} element={<SelectBinarySensorValue />} />
    <Route path={routes.SELECT_BINARY_SENSOR_STATE_SELECTED} element={<SelectBinarySensorStateSelected />} />
    <Route path={routes.SELECT_MULTILEVEL_SENSOR_TYPE} element={<SelectMultilevelSensorType />} />
    <Route path={routes.SELECT_MULTILEVEL_SENSOR_VALUE} element={<SelectMultilevelSensorValue />} />
    <Route path={routes.SELECT_MULTILEVEL_SENSOR_STATE_SELECTED} element={<SelectMultilevelSensorStateSelected />} />
    <Route path={routes.LUMINANCE_SENSOR_VALUE_EXAMPLE} element={<LuminanceSensorExample />} />
    <Route path={routes.ULTRAVIOLET_SENSOR_VALUE_EXAMPLE} element={<UltravioletSensorExample />} />
    <Route path={routes.SELECT_CENTRAL_SCENE_STATE_SELECTED} element={<SelectCentralSceneStateSelected />} />
    <Route path={routes.SELECT_CENTRAL_SCENE_SUB_DEVICE} element={<SelectCentralSceneSubDevice />} />
    <Route path={routes.SELECT_CENTRAL_SCENE_SUB_DEVICE_VALUE} element={<SelectCentralSceneSubDeviceValue />} />
    <Route path={routes.SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED} element={<SelectMultichannelBinarySwitchStateSelected />} />
    <Route path={routes.SELECT_MULTICHANNEL_SUB_DEVICE} element={<SelectMultichannelSubDevice />} />
    <Route path={routes.SELECT_MULTICHANNEL_SUB_DEVICE_VALUE} element={<SelectMultichannelSubDeviceValue />} />
    <Route path={routes.SELECT_DOOR_LOCK_STATE} element={<SelectDoorLockConditionState />} />

    <Route path={routes.CREATE_CONDITION_SELECTED} element={<CreateConditionSelected />} />
    <Route path={routes.TIMERS_IN_SCENES_RESET} element={<TimersInScenesReset />} />
    <Route path={routes.TIMERS_IN_SCENES_SETTINGS} element={<TimersInScenesSettings />} />
    <Route path={routes.TIMERS_IN_SCENES} element={<TimersInScenes />} />
    <Route path={routes.EDIT_ACTION} element={<EditAction />} />
    <Route path={routes.EDIT_CONDITION} element={<EditCondition />} />
    <Route path={routes.SCENE_REVIEW} element={<SceneReview />} />
    <Route path={routes.SCENE_REVIEW_EDIT} element={<SceneReviewEdit />} />
    <Route path={routes.SCENES} element={<MyAutomation />} />
    <Route path={routes.MY_STATUS} element={<MyStatus />} />
    <Route exact path={routes.TIMERS} element={<Timers />} />
    <Route path={routes.TIMERS_RESET} element={<TimersReset />} />
    <Route exact path={routes.TIMER_SETTINGS} element={<TimerSettings />} />
    <Route exact path={routes.GEOFENCES} element={<Geofences />} />
    <Route path={routes.CREATE_GEOFENCE} element={<CreateGeofence />} />
    <Route exact path={routes.GROUPS} element={<GroupsList />} />
    <Route path={routes.GROUPS_ADD_DEVICE} element={<GroupsAddDevice />} />
    <Route path={routes.GROUPS_EDIT} element={<EditGroup />} />
    <Route path={routes.GROUPS_SHOW} element={<ShowGroup />} />
    <Route exact path={routes.ROOMS} element={<RoomsList />} />
    <Route path={routes.ROOMS_ADD_DEVICE} element={<RoomsAddDevice />} />
    <Route path={routes.ROOMS_EDIT} element={<EditRoom />} />
    <Route path={routes.ROOMS_SHOW} element={<ShowRoom />} />
    <Route path={routes.CONTACTS} element={<ContactsList />} />
    <Route path={routes.HEALTH} element={<HealthAlert />} />
    <Route path={routes.VIRTUAL_SWITCHES} element={<VirtualSwitches />} />
    <Route path={routes.ACTIVITY} element={<Activity />} />
    <Route path={routes.SYNCHRONIZE_SCREEN} element={<SynchronizeScreen />} />
    <Route path={routes.DELETE_ACCOUNT} element={<DeleteAccountScreen />} />
    <Route element={<NotFound />} />
    <Route path={routes.MY_SECURITY} element={<MySecurity />} />
  </Routes>
)

export default AppRouter
