import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M36.5488 94.2108C36.8679 92.0196 35.3937 89.9695 33.2149 89.5744C31.0361 89.1793 28.9359 90.5813 28.4653 92.745C27.9946 94.9088 29.3227 97.0564 31.4688 97.6021C34.3988 98.3481 36.2346 99.2796 36.9761 100.396C36.2346 99.2615 36.0921 97.1996 36.5488 94.2108V94.2108Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M112.458 87.6796C112.139 85.4884 113.613 83.4382 115.792 83.0432C117.97 82.6481 120.071 84.05 120.541 86.2138C121.012 88.3775 119.684 90.5252 117.538 91.0708C114.603 91.7988 112.772 92.7167 112.044 93.8245C112.772 92.7167 112.91 90.6684 112.458 87.6796V87.6796Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.2866 81.9581C16.1235 80.0711 13.6736 79.4462 11.7489 80.5458C9.82409 81.6453 9.11799 84.0731 10.1528 86.0334C11.1876 87.9937 13.5905 88.7803 15.5842 87.8114C18.2972 86.4865 20.3003 86.0118 21.5935 86.3871C20.3048 86.0118 18.8692 84.5355 17.2866 81.9581V81.9581Z" fill="currentColor"/>
            <path d="M50.3573 108.211C46.6608 108.767 40.5092 109.323 37.2875 112.179C35.931 113.393 36.4126 114.43 37.7623 115.366C40.6652 117.401 43.8054 117.781 47.061 118.405C56.1834 120.148 65.2719 120.555 74.5096 120.555C83.7473 120.555 92.8222 120.155 101.958 118.405C105.214 117.781 108.354 117.381 111.257 115.366C112.613 114.43 113.095 113.393 111.732 112.179C108.51 109.323 102.358 108.787 98.6619 108.211" stroke="currentColor" strokeWidth="4"/>
            <path d="M102.42 103.715C111.583 104.393 120.631 105.322 129.611 107.499C131.862 108.049 141.141 109.724 140.998 113.644C140.87 117.714 131.537 119.22 129.149 119.782C110.979 124.069 92.9175 124.462 74.4964 124.462C56.0752 124.462 38.0136 124.069 19.8434 119.782C17.4559 119.22 8.13006 117.748 8.0012 113.638C7.87911 109.717 17.1372 108.035 19.3957 107.493C28.3553 105.356 37.4303 104.407 46.5934 103.715" stroke="currentColor" strokeWidth="4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M52.7179 64.409C35.5719 83.3118 48.9876 113.589 74.476 113.589C99.9645 113.589 113.414 83.3118 96.2681 64.409C81.7401 48.3844 74.476 35.5813 74.476 26C74.476 35.5813 67.2233 48.3844 52.7179 64.409V64.409Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M131.734 95.9386C132.897 94.0515 135.347 93.4267 137.271 94.5262C139.196 95.6258 139.902 98.0535 138.867 100.014C137.833 101.974 135.43 102.761 133.436 101.792C130.723 100.467 128.72 99.9922 127.427 100.368C128.715 99.9922 130.151 98.5159 131.734 95.9386V95.9386Z" fill="currentColor"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwWaterSensorIcon')

export default icon
