import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
  },
  rootMobile: {
    height: 'calc(100vh - 60px)',
    minHeight: 500,
    padding: '1rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  navigationControlsMobile: {
    marginBottom: '1rem',
  },
  arrowBack: {
    position: 'absolute',
    top: 5,
    left: 10,
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 300,
    marginBottom: '2rem',
  },
  contentContainerMobile: {
    flexDirection: 'column',
    height: '85%',
    minHeight: 390,
  },
  content: {
    height: '80%',
    width: '40%',
    textAlign: 'center',
    paddingTop: 15,
  },
  contentMobile: {
    width: '80%',
    marginTop: -70,
  },
  contentMobileAdded: {
    height: '50%',
    marginTop: -40,
  },
  statusContainer: {
    width: '100%',
    marginTop: -30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  errorMessage: {
    color: '#FF0000',
    margin: '8px 0',
  },
  title: {
    textAlign: 'center',
    marginTop: 25,
  },
  titleMobile: {
    marginTop: 0,
  },
  subTitle: {
    marginTop: 8,
  },
  progressBar: {
    width: '100%',
  },
  input: {
    marginTop: 8,
    width: '100%',
  },
  submitButton: {
    marginTop: 25,
  },

  subText: {
    textAlign: 'center',
    color: theme.scenes.descriptionTop,
    fontSize: '.75rem',
  },
  iconContainer: {
    position: 'relative',
  },
  iconHint: {
    position: 'absolute',
    bottom: '45%',
    left: 0,
  },
  iconHintMobile: {
    bottom: '50%',
  },
  icon: {
    marginTop: '2.5rem',
  },
  iconMobile: {
    marginTop: 0,
  },
  deviceAddedIcon: {
    fontSize: 200,
  },
  deviceAddedIconMobile: {
    fontSize: 140,
  },
  progressTipContainer: {
    position: 'absolute',
    bottom: 10,
    left: 'calc(50% - 10px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressTip: {
    margin: 2,
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.scenes.textColor,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
}))

export default useStyles
