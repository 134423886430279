import React from 'react'
import { IconButton, Typography } from '@mui/material'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { maxRoomsNumber } from '@app/constants/rooms/rooms'
import { ROOMS_ADD_DEVICE, ROOMS_SHOW } from '@app/constants/routes'
import {
  COMMIT_TO_MEZZO, COMPLETE, DELETING, IN_PROGRESS, NOT_SAVED,
} from '@app/constants/mezzoAnswers'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import PropTypes from '@app/components/PropTypes'
import {
  Add, Close, Refresh, InfoIcon,
} from 'icons'
import { withStyles } from '@app/components/styles'
import AddRoomPopup from './AddRoomPopup/AddRoomPopup'
import ErrorPopup from './ErrorPopup/ErrorPopup'
import InfoPopup from './InfoPopup/InfoPopup'

class RoomsListFeature extends React.PureComponent {
    state = {
      isErrorPopupOpen: false,
      isInfoPopupOpen: false,
      isWarningPopupOpen: false,
      infoPopupNumber: null,
      deletingDetails: null,
    }

    writeDeletingDetails = (details) => this.setState({ deletingDetails: details })

    openErrorPopupHandler = () => {
      this.setState((prevState) => ({
        isErrorPopupOpen: !prevState.isErrorPopupOpen,
      }))
    }

    openWarningPopupHandler = () => {
      this.setState((prevState) => ({
        isWarningPopupOpen: !prevState.isWarningPopupOpen,
      }))
    }

    renderRoomStatus = (status) => {
      switch (status) {
      case COMMIT_TO_MEZZO: return `(${I18n.t('geofence_web.saving')})`
      case IN_PROGRESS: return `(${I18n.t('geofence_web.saving')})`
      case NOT_SAVED: return `(${I18n.t('geofence_web.not_saved')})`
      case DELETING: return `(${I18n.t('geofence_web.deleting')})`
      case COMPLETE: return ''
      default: return ''
      }
    }

    openInfoPopupHandler = (index) => {
      this.setState((prevState) => (index !== prevState.infoPopupNumber ? {
        isInfoPopupOpen: true,
        infoPopupNumber: index,
      } : {
        isInfoPopupOpen: false,
        infoPopupNumber: null,
      }))
    }

    renderRoomControlsAdmin = (room, index) => {
      const { classes } = this.props
      return room.status === COMPLETE ? (
        <>
          <Link
            underline="hover"
            to={ROOMS_ADD_DEVICE}
            state={{ roomId: room.id }}
          >
            <IconButton size="large">
              <Add className={classes.addGroupButton} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => this.openInfoPopupHandler(index)}
            className={classes.iconBackground}
            size="large"
          >
            <Typography className={classes.moreInfoButton}>...</Typography>
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            className={classes.deleteGroupButton}
            onClick={() => {
              this.writeDeletingDetails(room.id)
              this.openWarningPopupHandler()
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </>
      )
    }

  renderRoomControlsNotAdmin = (room) => {
    const { classes } = this.props
    return room.status === COMPLETE && (
      <Link
        underline="hover"
        className={classes.link}
        to={ROOMS_SHOW}
        state={{ room }}
      >
        <InfoIcon
          className={classes.infoIcon}
          size="large"
        />
      </Link>
    )
  }

  render() {
    const {
      postNewRoom,
      isAddPopupOpen,
      toggleAddPopup,
      deleteRoom,
      classes,
      isMobile,
      roomsList,
      syncRoomsList,
      admin,
    } = this.props
    const {
      isErrorPopupOpen,
      isInfoPopupOpen,
      isWarningPopupOpen,
      infoPopupNumber,
      deletingDetails,
    } = this.state
    return (
      <Wrapper className={classes.root}>
        <IconButton
          className={classNames(classes.refreshIcon, isMobile && classes.refreshIconMobile)}
          onClick={syncRoomsList}
          size="large"
        >
          <Refresh />
        </IconButton>
        <Wrapper className={classNames(classes.main, isMobile && classes.mainMobile)}>
          {roomsList.length ? roomsList.map((room, index) => (
            <Wrapper
              className={classNames(
                classes.roomsCard,
                isMobile && classes.cardMobile,
              )}
              key={`${room.name + 1}`}
            >
              {`${room.name} ${room.status ? this.renderRoomStatus(room.status) : ''}`}
              <Wrapper className={classes.cardControls}>
                {admin ? this.renderRoomControlsAdmin(room, index) : this.renderRoomControlsNotAdmin(room)}
              </Wrapper>
              <InfoPopup
                isPopupOpen={isInfoPopupOpen && infoPopupNumber === index}
                closePopup={this.openInfoPopupHandler}
                className={classes.infoPopup}
                room={room}
                roomsList={roomsList}
                openWarning={this.openWarningPopupHandler}
                writeDeletingDetails={this.writeDeletingDetails}
              />
            </Wrapper>
          ))
            : <Typography className={classes.noRooms}>{I18n.t('rooms_and_groups.no_rooms')}</Typography>}

          {admin && (
            <IconButton
              aria-label="addRoom"
              className={classes.addButton}
              onClick={() => {
                if (roomsList.length === maxRoomsNumber) {
                  this.openErrorPopupHandler()
                } else {
                  toggleAddPopup()
                }
                this.openInfoPopupHandler()
              }}
              size="large"
            >
              <Add />
            </IconButton>
          )}
        </Wrapper>
        <AddRoomPopup
          open={isAddPopupOpen}
          names={roomsList.map((room) => room.name)}
          close={toggleAddPopup}
          addRoom={postNewRoom}
        />
        <ErrorPopup open={isErrorPopupOpen} close={this.openErrorPopupHandler} />
        <WarningComponent
          open={isWarningPopupOpen}
          leftButton="cancel"
          rightButton="okay"
          warningText="rooms_and_groups.want_to_delete_room"
          onClose={() => {
            deleteRoom(deletingDetails)
            this.openWarningPopupHandler()
          }}
          onClick={this.openWarningPopupHandler}
        />
      </Wrapper>
    )
  }
}

RoomsListFeature.propTypes = {
  classes: PropTypes.object.isRequired,
  roomsList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  postNewRoom: PropTypes.func.isRequired,
  isAddPopupOpen: PropTypes.bool.isRequired,
  toggleAddPopup: PropTypes.func.isRequired,
  deleteRoom: PropTypes.func.isRequired,
  syncRoomsList: PropTypes.func.isRequired,
  admin: PropTypes.bool.isRequired,
}

RoomsListFeature.displayName = 'RoomsListFeature'

export default withStyles(RoomsListFeature)
