import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path stroke="none" fillRule="nonzero" fill="currentColor" fillOpacity="1" d="M13.32 6.914c-5.023.82-9.054 4.188-10.617 8.875-.469 1.39-.601 2.203-.648 3.86-.047 1.64.078 2.648.484 4.054.969 3.328 3.313 6.235 6.344 7.875l.687.375H6.125c-3.547 0-3.86.024-4.016.313-.132.25-.086.586.102.765l.18.172h35.242l.164-.18a.688.688 0 0 0 .094-.757c-.125-.235-.516-.313-1.524-.313h-.953l.32-.351c.461-.516.891-1.25 1.086-1.875.14-.438.172-.735.172-1.485 0-.75-.031-1.047-.172-1.484-.226-.727-.656-1.422-1.25-2.016-.57-.57-1.125-.922-1.914-1.21l-.57-.212h-5.39c-5.352 0-5.4 0-5.938.172-1.625.516-2.938 1.899-3.36 3.54-.242.945-.148 2.241.243 3.163.172.414.593 1.063.937 1.446l.297.328-3.242-.04c-2.953-.03-3.297-.046-3.922-.195-2.625-.632-4.805-1.922-6.5-3.843-2.125-2.414-3.219-5.727-2.93-8.875.477-5.305 4.188-9.532 9.43-10.743 1.008-.234 3.21-.289 4.344-.117 4.539.719 8.226 3.906 9.625 8.336.398 1.281.53 2.352.484 4.031-.031 1.07-.016 1.532.047 1.618.289.367.906.257 1.062-.196.055-.148.118-.797.141-1.437.242-6.586-4.281-12.235-10.836-13.516-.93-.187-3.328-.226-4.258-.078Zm13.867 21.328v3.711h-2.296c-2.063 0-2.344-.015-2.79-.156-1.343-.414-2.343-1.586-2.585-3.016-.282-1.718.859-3.554 2.57-4.093.398-.133.734-.149 2.781-.149l2.32-.008Zm6.04-3.547c2.703.899 3.468 4.32 1.398 6.258-.46.43-.945.719-1.492.875-.344.102-.844.125-2.563.125h-2.133v-7.422h2.149c1.969 0 2.195.016 2.64.164Zm0 0"/>
      <path stroke="none" fillRule="nonzero" fill="currentColor" fillOpacity="1" d="M13.86 9.297c-3.524.46-6.704 2.726-8.297 5.906-.32.633-.75 1.883-.922 2.688-.204.937-.204 3.28 0 4.218.671 3.078 2.445 5.563 5.101 7.164 1.828 1.094 4.195 1.649 6.305 1.47.578-.048.726-.087.875-.243a.603.603 0 0 0 .039-.82c-.133-.164-.211-.188-.625-.172l-.477.008v-.36c0-.453-.132-.726-.414-.86-.25-.116-.468-.07-.68.165-.117.125-.156.273-.156.617 0 .531.047.516-.96.336a9.705 9.705 0 0 1-2.711-.898c-.79-.399-1.954-1.203-1.954-1.352 0-.047.133-.219.29-.383.367-.375.382-.703.062-.984-.29-.25-.602-.188-1 .203l-.297.29-.203-.235c-1.063-1.274-1.922-3.274-2.094-4.907l-.054-.523h.421c.579 0 .844-.195.844-.625s-.266-.625-.844-.625h-.421l.054-.531c.172-1.625 1.031-3.625 2.094-4.899l.203-.234.297.289c.398.39.71.453 1 .203.328-.281.305-.61-.078-1-.164-.164-.274-.351-.258-.406.07-.172 1.242-.969 1.977-1.336a9.838 9.838 0 0 1 2.671-.875c1.008-.18.961-.195.961.336 0 .344.04.492.157.617.21.234.43.281.68.164.28-.133.413-.406.413-.867 0-.43-.03-.422.985-.25 1.578.281 3.148 1 4.484 2.062l.188.149-.328.36c-.446.476-.446.882-.008 1.1.328.173.43.15.804-.194.188-.172.368-.313.407-.313.093.008.726.86 1.078 1.453.601 1.016 1.11 2.531 1.25 3.758l.054.414h-.39c-.5 0-.758.117-.875.398a.513.513 0 0 0 0 .454c.117.28.375.398.867.398.219 0 .39.023.39.055 0 .039-.038.304-.078.61-.101.648-.015.92.32 1.062.282.109.548.015.727-.258.172-.266.313-1.766.25-2.68-.226-3.312-1.851-6.273-4.5-8.18-.812-.586-2.304-1.32-3.218-1.578-1.352-.39-3.086-.531-4.407-.36ZM30.703 11.016a.941.941 0 0 0-.203.351c-.031.125-.102.195-.195.195-.235 0-.461.282-.461.57 0 .274.258.602.476.602.063 0 .164.118.227.258.062.133.21.297.328.367.188.102.234.102.492-.023.195-.094.29-.195.32-.352.032-.164.11-.242.29-.296.523-.157.507-.993-.024-1.094-.164-.04-.234-.094-.234-.203 0-.079-.086-.235-.196-.336-.234-.242-.601-.258-.82-.04Zm0 0"/>
      <path stroke="none" fillRule="nonzero" fill="currentColor" fillOpacity="1" d="M14.773 14.734c-.156.196-.164.313-.164 1.711v1.508l-.421.242a2.092 2.092 0 0 0-.946 2.516l.125.344-1.492 1.476c-1.328 1.328-1.484 1.508-1.484 1.742 0 .344.234.57.593.57.25 0 .399-.116 1.79-1.476l1.523-1.476.289.109c1.031.367 2.312-.305 2.633-1.383.156-.515.156-.719 0-1.234-.14-.477-.61-1.031-1.063-1.266l-.297-.148v-1.453c0-1.79-.07-1.985-.703-1.985a.475.475 0 0 0-.383.203Zm1.024 4.633c.226.203.258.274.258.633 0 .36-.032.43-.258.633-.367.336-.797.32-1.156-.04-.22-.218-.266-.327-.266-.593 0-.266.047-.375.266-.594.359-.36.789-.375 1.156-.039Zm0 0"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ReminderIcon', '0 0 40 40', {fontSize: 20})

export default icon
