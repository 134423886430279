import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import {
  deleteGeofence, patchGeofence, syncGeofences, toggleGeofenceSize,
} from '@app/actions/geofences/geofences'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import GeofencesList from '@app/components/Geofences/GeofencesList/GeofencesList'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh, withForbiddenAccess,
} from '@app/hocs'

let enhance = GeofencesList

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { geofences: { geofencesList } } = store.getState()
  const { role } = state.users.currentUser.user
  const forbiddenRoles = ['guest']
  return {
    isMobile,
    isTablet,
    geofencesList,
    role,
    forbiddenRoles,
    currentRole: role,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncGeofences,
  tryAgain: syncGeofences,
  notifications: syncNotifications.sync,
  syncGeofences,
  deleteGeofence,
  toggleGeofenceSize,
  patchGeofence,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withForbiddenAccess(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const GeofencesEnhanced = enhance

export default GeofencesEnhanced
