export const SYNC_ZWAVE_DEVICES = '@@zWaveDevices/syncZWaveDevices'

export const RESET_ZWAVE_DEVICES = '@@zWaveDevices/reset'

export const UPDATE_ZWAVE_DEVICES = '@@scenesList/updateZWaveDevices'

export const SYNC_ZWAVE_DEVICE_STATES = '@@zWaveDevices/syncZWaveDeviceStates'

export const UPDATE_ZWAVE_DEVICE_STATES = '@@scenesList/updateZWaveDeviceStates'

export const SYNC_ZWAVE_DEVICE_CAPABILITIES = '@@zWaveDevices/syncZWaveDeviceCapabilities'

export const UPDATE_ZWAVE_DEVICE_CAPABILITIES = '@@scenesList/updateZWaveDeviceCapabilities'

export const SYNC_ZWAVE_DEVICE_BINARY_SENSOR_TYPES = '@@zWaveDevices/syncZWaveDeviceBinarySensorTypes'

export const UPDATE_ZWAVE_DEVICE_BINARY_SENSOR_TYPES = '@@scenesList/updateZWaveDeviceBinarySensorTypes'

export const SYNC_ZWAVE_DEVICE_BINARY_SENSOR_VALUES = '@@zWaveDevices/syncZWaveDeviceBinarySensorValues'

export const UPDATE_ZWAVE_DEVICE_BINARY_SENSOR_VALUES = '@@scenesList/updateZWaveDeviceBinarySensorValues'

export const SYNC_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES = '@@zWaveDevices/syncZWaveDeviceMultilevelSensorTypes'

export const UPDATE_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES = '@@scenesList/updateZWaveDeviceMultilevelSensorTypes'
