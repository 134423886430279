import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_MY_STATUS_POPUP } from '@app/constants/myStatus'
import { updateMyStatusDeviceStats } from '@app/actions/myStatus'
import Api from '@app/api/myStatus'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchMyStatusPopupInfoLogic = createLogic({
  type: SYNC_MY_STATUS_POPUP,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const { myStatus: { myStatusPopup: { currentDeviceId } } } = store.getState()
    dispatch(apiRequestSent(SYNC_MY_STATUS_POPUP))
    Api.fetchMyStatusDeviceStats(AccessToken, currentDeviceId)
      .then((response) => {
        const { data } = response
        dispatch(updateMyStatusDeviceStats(data))
        dispatch(apiRequestDone(SYNC_MY_STATUS_POPUP))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchMyStatusDeviceStats failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchMyStatusPopupInfoLogic
