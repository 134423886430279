import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import getDeviceStateInfo from '@app/helpers/scenes/getDeviceStateInfo'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import convertZwaveChangedDescription from '@app/helpers/scenes/convertZwaveChangedDescription'
import getSpecialPrefix from '@app/helpers/scenes/getSpecialPrefix'

class SelectDevicesConditionState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      backPath,
    } = this.props
    navigate(backPath)
  }

  selectState=(state) => {
    const {
      navigate,
      isEditConditionMode,
      saveConditionStateDescription,
      saveConditionDeviceStateInfo,
      saveConditionDescription,
    } = this.props
    saveConditionStateDescription(convertZwaveChangedDescription(state.title))
    saveConditionDescription(getSpecialPrefix(state.title))
    const deviceState = getDeviceStateInfo(state)
    saveConditionDeviceStateInfo(deviceState)
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  renderZwaveStates=() => {
    const {
      classes,
      deviceStates,
    } = this.props
    return (
      <>
        <Typography className={classes.description}>Always monitors the state of the selected device</Typography>
        {
          deviceStates.slice(0, 2).map((state) => (
            <CardBlock
              key={state.title}
              description={state.title}
              position="middle"
              buttonName={I18n.t('scene_web.add')}
              isCardDisabled={false}
              isButtonDisabled={false}
              isValueButton
              onClick={() => {
                this.selectState(state)
              }}
            />
          ))
        }
        <Typography className={classes.description}>Only detects when the state of the device changes</Typography>
        {
          deviceStates.slice(2, deviceStates.length).map((state) => (
            <CardBlock
              key={state.title}
              description={convertZwaveChangedDescription(state.title)}
              position="middle"
              buttonName={I18n.t('scene_web.add')}
              isCardDisabled={false}
              isButtonDisabled={false}
              isValueButton
              onClick={() => {
                this.selectState(state)
              }}
            />
          ))
        }
      </>
    )
  }

  renderTwrStates=() => {
    const {
      deviceStates,
    } = this.props
    return (
      <>
        {
          deviceStates.map((state) => (
            <CardBlock
              key={state.title}
              description={state.title}
              position="middle"
              buttonName={I18n.t('scene_web.add')}
              isCardDisabled={false}
              isButtonDisabled={false}
              isValueButton
              onClick={() => {
                this.selectState(state)
              }}
            />
          ))
        }
      </>
    )
  }

  render() {
    const {
      classes,
      isMobile,
      conditionType,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {conditionType === 'ZWaveDevices' ? this.renderZwaveStates() : this.renderTwrStates()}
        </Wrapper>
      </Paper>
    )
  }
}

SelectDevicesConditionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  deviceStates: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.number,
        value: PropTypes.number,
        number: PropTypes.number.isRequired,
      }),
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  backPath: PropTypes.string.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  conditionType: PropTypes.string.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
}

SelectDevicesConditionState.defaultProps = {

}

SelectDevicesConditionState.displayName = 'SelectDevicesConditionStateIOS'

export default SelectDevicesConditionState
