import moment from 'moment'

const getDatesForWeekPeriod = () => {
  const yesterday = moment().subtract(1, 'days').format('DD MMM YYYY')
  const second = moment().subtract(2, 'days').format('DD MMM YYYY')
  const third = moment().subtract(3, 'days').format('DD MMM YYYY')
  const fourth = moment().subtract(4, 'days').format('DD MMM YYYY')
  const fifth = moment().subtract(5, 'days').format('DD MMM YYYY')
  const sixth = moment().subtract(6, 'days').format('DD MMM YYYY')
  return [[sixth], [fifth], [fourth], [third], [second], [yesterday], ['today']]
}

export default getDatesForWeekPeriod
