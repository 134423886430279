import React from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import Wrapper from '../Wrapper/Wrapper'
import useStyles from './Info.style'

const DefaultProps = {
  disableButton: false,
}

interface InfoProps {
  open: boolean,
  title: string,
  warningText?: string,
  buttonName: string,
  onClick: () => void,
  disableButton?: boolean,
  customTextNode?: React.ReactNode,
}

const Info: React.FC<InfoProps> = (props) => {
  const {
    open,
    title,
    warningText,
    buttonName,
    onClick,
    disableButton = DefaultProps.disableButton,
    customTextNode,
  } = props
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t(`${title}`)}
      </DialogTitle>
      <DialogContent>
        {customTextNode || (
          <DialogContentText
            variant="body1"
          >
            {I18n.t(`${warningText}`)}
          </DialogContentText>
        )}
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          color="primary"
          className={classes.button}
          variant="contained"
          onClick={onClick}
          disabled={disableButton}
        >
          {I18n.t(`${buttonName}`)}
        </Button>
      </Wrapper>
    </Dialog>
  )
}

export default Info
