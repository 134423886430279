import React from 'react'
import PropTypes from 'prop-types'
import LoadingCard from '@app/components/LoadingCard'
import { withStyles } from '@app/components/styles'

const LoadingCardEnhanced = withStyles(LoadingCard)

const withAuthAuthorizationCode = (WrappedComponent) => {
  class Authable extends React.Component {
    componentDidMount() {
      const {
        onAuthByCode,
        validAccessToken,
        onAuthSkip,
        urlParams,
      } = this.props
      if (!validAccessToken) {
        const params = new URLSearchParams(urlParams)
        const code = params.get('code')
        if (!code) {
          return
        }
        onAuthByCode(code)
      } else {
        onAuthSkip()
      }
    }

    render() {
      const { inProgress } = this.props
      if (inProgress) {
        return <LoadingCardEnhanced />
      }
      return <WrappedComponent {...this.props} />
    }
  }

  Authable.propTypes = {
    inProgress: PropTypes.bool,
    validAccessToken: PropTypes.bool,
    onAuthByCode: PropTypes.func.isRequired,
    onAuthSkip: PropTypes.func.isRequired,
    urlParams: PropTypes.string.isRequired,
  }

  Authable.defaultProps = {
    inProgress: true,
    validAccessToken: false,
  }

  return Authable
}

export default withAuthAuthorizationCode
