import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const config = () => ({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  },
})

const revokeToken = (
  accessToken,
  clientId,
  clientSecret,
) => Axios.post(
  `${API_URL}/oauth/revoke`,
  {
    token: accessToken,
    client_id: clientId,
    client_secret: clientSecret,
  },
  config(),
)

export default {
  revokeToken,
}
