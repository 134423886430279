import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M70.6524 93.167C74.777 93.167 78.1207 89.8234 78.1207 85.6988C78.1207 81.5741 74.777 78.2305 70.6524 78.2305C66.5277 78.2305 63.1841 81.5741 63.1841 85.6988C63.1841 89.8234 66.5277 93.167 70.6524 93.167Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36.5659 60.4023L66.4784 77.4143C63.7375 78.5901 61.84 81.1481 61.51 84.1122L36.5659 60.4023Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M123.662 81.6562C119.935 57.8049 99.0872 41.4611 75.9827 39.3149C52.8781 37.1688 29.4356 49.1809 20.4894 71.3578C18.3639 76.5508 17.2153 82.0916 17.1011 87.7016C16.9323 89.4172 17.0064 91.148 17.3212 92.8429C17.4497 93.3665 17.6972 93.8534 18.0445 94.2658C19.6875 96.239 21.5742 95.5708 23.5474 95.5708H106.516C106.595 95.5708 106.721 95.5079 106.721 95.6415V104.21C106.721 104.36 106.941 104.651 106.65 104.651H22.549C19.9941 104.651 17.62 104.525 15.2223 103.338C11.1838 101.352 8.49805 97.3777 8.16277 92.8901C7.7032 86.9711 8.21842 81.0169 9.68787 75.2649C12.0696 65.4492 17.1002 56.4756 24.2314 49.3224C40.4729 32.4834 65.0947 26.3123 87.4052 32.1454C109.716 37.9785 128.19 55.6037 132.388 79.0305H131.735C129.643 79.009 127.58 79.5261 125.745 80.532L123.662 81.6562Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M118.064 98.7241C113.288 103.991 113.552 112.097 118.661 117.042C123.769 121.987 131.88 121.987 136.988 117.042C142.097 112.097 142.361 103.991 137.584 98.7241C131.075 91.5493 127.82 85.8157 127.82 81.5234C127.841 85.8157 124.589 91.5493 118.064 98.7241V98.7241Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwWaterMeterIcon')

export default icon
