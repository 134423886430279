import React from 'react'
import { Link, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './PrivacyPolicy.style'

const PrivacyPolicy: React.FC = () => {
  const classes = useStyles()

  return (
    <Wrapper className={classes.privacyPolicy}>
      <Typography
        className={classes.agreementText}
        align="center"
      >
        {I18n.t('wizard_web.privacy_policy')}
      </Typography>
      <Link
        href="https://support.mezzobyness.com/privacy-policy/"
        target="_blank"
        className={classes.privacy}
        underline="always"
      >
        {I18n.t('wizard_web.mezzo_privacy_statement')}
      </Link>
    </Wrapper>
  )
}

export default PrivacyPolicy
