import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { sync } from '@app/actions/po/poConfig'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import About from '@app/components/About/About'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'
import { getMezzoStatusForScreens } from '@app/helpers/getMezzoStatusForScreens'

let enhance = About

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { activation, isNCentral } = state.unit
  const withUnitDevice = getMezzoStatusForScreens(activation)
  const {
    initialOptions, currentOptions, defaultOptions, items, inProgress, errorMessage,
  } = state.poConfig
  return {
    isMobile,
    path,
    currentOptions,
    defaultOptions,
    items,
    initialOptions,
    withUnitDevice,
    isNCentral,
    inProgress,
    errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    appVersion: state.app.version,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const AboutEnhanced = enhance

export default AboutEnhanced
