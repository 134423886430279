export const conditionTypes = [
  {
    title: 'user_trigger',
    description: 'user_trigger_description',
    position: 'top',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'UserTrigger',
  },
  {
    title: 'devices',
    description: 'devices_description_condition',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'devices',
  },
  {
    title: 'geofence',
    description: 'geofence_description',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'geofences',
  },
  {
    title: 'another_scene',
    description: 'another_scene_description_condition',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'another_scene',
  },
  {
    title: 'time',
    description: 'time_description',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'time',
  },
  {
    title: 'virtual_switch',
    description: 'virtual_switch_description_condition',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    withIcon: true,
    type: 'virtual_switch',
  },
]
