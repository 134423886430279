const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  title: {
    textAlign: 'left',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12,
  },
  button: {
    marginLeft: 10,
  },
})

export default styles
