import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_TAKE_ON_BOARD_DEVICE_TYPE } from '@app/constants/myDevices/twrDevices'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { history } from '@app/store/history'

const patchTakeOnBoardDeviceTypeLogic = createLogic({
  type: PATCH_TAKE_ON_BOARD_DEVICE_TYPE,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { myDevices: { myDevices: { currentTakeOnBoardDeviceType } } } = getState()
    const data = { device_type: currentTakeOnBoardDeviceType }
    next({
      ...action,
      token,
      data,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_TAKE_ON_BOARD_DEVICE_TYPE))
    try {
      await Api.patchTakeOnBoardDeviceType(action.token, action.id, action.data)
      dispatch(apiRequestDone(PATCH_TAKE_ON_BOARD_DEVICE_TYPE))
      history.push(window.location.pathname.split('/').slice(0, -2).join('/'))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_TAKE_ON_BOARD_DEVICE_TYPE failed without error')
      }
    }
    done()
  },
})

export default patchTakeOnBoardDeviceTypeLogic
