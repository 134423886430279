import * as type from '@app/constants/scenes/zWaveDevices/zWaveDevicesByGroups'
import { IGroupZwaveBackend } from '@app/@types/scenes/scenesDevices'

export const syncZWaveDevicesByGroups = () => ({
  type: type.SYNC_ZWAVE_DEVICES_BY_GROUPS,
} as const)

export const updateZWaveDevicesByGroups = (groups: Array<IGroupZwaveBackend>) => ({
  type: type.UPDATE_ZWAVE_DEVICES_BY_GROUPS,
  groups,
} as const)

export const resetZWaveDevicesByGroups = () => ({
  type: type.RESET_ZWAVE_DEVICES_BY_GROUPS,
} as const)
