import * as ApiRequestAction from '@app/actions/wizard/deleteAccount'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as type from '@app/constants/wizard/deleteAccount'

type ApiRequestActions = ReturnType<InferValueTypes<typeof ApiRequestAction>>

export interface IApiRequestState {
  username: string,
  password: string,
}

const initialState = {
  username: '',
  password: '',
}

export default function apiRequest(state: IApiRequestState = initialState, action: ApiRequestActions): IApiRequestState {
  switch (action.type) {
  case type.DELETE_ACCOUNT:
    return { ...state, username: action.username, password: action.password }

  case type.DELETE_ACCOUNT_RESET:
    return initialState

  default:
    return state
  }
}
