import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '40px 70px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  rootMobile: {
    padding: '20px',
  },
  paramsList: {
    width: '100%',
  },
  paramCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    minHeight: 100,
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: 20,
    marginBottom: 10,
  },
  paramDescription: {
    maxWidth: '90%',
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  paramValue: {
    color: theme.texts.subText,
    marginRight: 10,
  },
  chevronIcon: {
    position: 'absolute',
    right: 20,
    top: 'calc(50% - 12px)',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      scale: '1.15',
    },
  },
  preloader: {
    width: '100vw',
    height: 'calc(100vh - 269px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
  },
  preloaderTitle: {
    marginBottom: 34,
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    fontWeight: 500,
  },
  progress: {
    maxWidth: 400,
    width: '80%',
  },
  error: {
    display: 'flex',
    height: 'calc(100vh - 189px)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    padding: '0 10%',
  },
  errorText: {
    marginBottom: 20,
    color: 'red',
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    fontWeight: 500,
  },
  emptyList: {
    height: 'calc(100vh - 269px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  not_received: {
    color: 'red',
  },
  hide: {
    display: 'none',
  },
}))

export default useStyles
