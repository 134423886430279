import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M95 34.6707H65.3672V5H34.6707V34.6707H5V65.3293H34.6707V95H65.3672V65.3293H95V34.6707Z" stroke="currentColor" strokeWidth="2"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'HealthDashboardIcon')

export default icon
