import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 5,
    boxSizing: 'border-box',
    backgroundColor: theme.backgrounds.cardBackground,
    color: theme.texts.main,
    boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.25)',
    marginBottom: 15,
    position: 'relative',
  },
  cardMobile: {
    minHeight: 73,
  },
  cardInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    minHeight: 73,
    padding: '15px 25px',
  },
  notificationTime: {
    width: 90,
    textAlign: 'center',
    marginRight: 30,
    opacity: 0.7,
  },
  notificationTimeMobile: {
    marginRight: 0,
    marginBottom: 10,
  },
  notificationMessage: {
    fontSize: '18px',
  },
  notificationMessageMobile: {
    fontSize: '14px',
  },
  textWrapper: {
    marginRight: 'auto',
  },
}))

export default useStyles
