import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { setMyStatusPopupClosed, syncMyStatusPopup } from '@app/actions/myStatus'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'
import MyStatusDevicePopup from '@app/components/MyStatus/MyStatusDevicePopup/MyStatusDevicePopup'

let enhance = withStyles(MyStatusDevicePopup)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { myStatus: { myStatusPopup: { isMyStatusDevicePopupOpen, currentDeviceName } } } = store.getState()
  return {
    isMobile,
    isMyStatusDevicePopupOpen,
    deviceName: currentDeviceName,
    deviceStats: state.myStatus.myStatusPopup.zWaveInfo,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setMyStatusPopupClosed,
  syncMyStatusPopup,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MyStatusPopupEnhanced = enhance

export default MyStatusPopupEnhanced
