import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_CONDITIONS_ACTIONS } from '@app/constants/scenes/currentSceneConditionsActions'
import { saveActions, saveConditions } from '@app/actions/scenes/currentSceneConditionsActions'
import { saveEditedSceneInitialActions, saveEditedSceneInitialConditions } from '@app/actions/scenes/editScene'
import getConditions from '@app/api/scenes/getConditions'
import getActions from '@app/api/scenes/getActions'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const getConditionsAndActionsLogic = createLogic({
  type: GET_CONDITIONS_ACTIONS,
  cancelType: API_REQUEST_CANCEL,

  async process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = store.getState()
    const { scenes: { editScene: { editedSceneInitialActions, editedSceneInitialConditions } } } = store.getState()
    dispatch(apiRequestSent(GET_CONDITIONS_ACTIONS))
    try {
      const conditionsData = await getConditions(token, id)
      const { data: { conditions } } = conditionsData
      dispatch(saveConditions(conditions))
      if (!editedSceneInitialConditions.length) {
        dispatch(saveEditedSceneInitialConditions(conditions))
      }
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('getConditions failed without error')
      }
    }
    try {
      const actionsData = await getActions(token, id)
      const { data: { actions } } = actionsData
      dispatch(saveActions(actions))
      if (!editedSceneInitialActions.length) {
        dispatch(saveEditedSceneInitialActions(actions))
      }
      dispatch(apiRequestDone(GET_CONDITIONS_ACTIONS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('getActions failed without error')
      }
    }
    done()
  },
})

export default getConditionsAndActionsLogic
