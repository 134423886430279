import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  grid: {
    margin: '0 auto',
  },
  root: {
    padding: '30px 16px 0px 16px',
  },
  bottomWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomWrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 32px 30px 32px',
  },
  buttonsSecondWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  button: {
    width: '50%',
    maxWidth: 376.5,
    '&:first-of-type': {
      marginRight: 32,
    },
  },
  resetButton: {
    width: '100%',
  },
  webView: {
    background: theme.webView.background,
  },
  preloader: {
    display: 'flex',
    position: 'fixed',
    width: '100vw',
    height: 'calc(100vh - 65px)',
    top: 65,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1199,
  },
  preloaderMessage: {
    fontSize: '1.5rem',
  },
  blur: {
    filter: 'blur(5px)',
    height: 'calc(100vh - 65px)',
  },
}))

export default useStyles
