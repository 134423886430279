import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { triggeringDeviceStates } from '@app/lib/Scenes/triggeringDeviceStates'
import {
  SELECT_TRIGGERING_DEVICE,
  SELECT_TRIGGERING_GROUP,
  SELECT_TRIGGERING_ROOM,
  SELECT_TRIGGERING_ZWAVE_DEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTriggeringState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      deviceType,
    } = this.props
    if (deviceType) {
      switch (deviceType) {
      case 2:
        return navigate(SELECT_TRIGGERING_ZWAVE_DEVICE)
      case 3:
        return navigate(SELECT_TRIGGERING_ROOM)
      case 4:
        return navigate(SELECT_TRIGGERING_GROUP)
      default:
        throw new Error('device type not supported')
      }
    } else {
      return navigate(SELECT_TRIGGERING_DEVICE)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      saveTriggeringDeviceState,
    } = this.props
    saveTriggeringDeviceState(state.value)
    navigate(SELECT_TRIGGERING_DEVICE)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            triggeringDeviceStates.map((state) => (
              <CardBlock
                key={state.description}
                description={I18n.t(`scene_web.${state.description}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={state.isCardDisabled}
                isButtonDisabled={state.isButtonDisabled}
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectTriggeringState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveTriggeringDeviceState: PropTypes.func.isRequired,
  deviceType: PropTypes.number.isRequired,
}

SelectTriggeringState.defaultProps = {

}

SelectTriggeringState.displayName = 'SelectTriggeringState'

export default SelectTriggeringState
