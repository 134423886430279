import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  main: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 25,
  },
}))

export default useStyles
