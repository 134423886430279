import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M66.3834 92.8562C70.1611 92.8562 73.2236 89.7937 73.2236 86.016C73.2236 82.2382 70.1611 79.1758 66.3834 79.1758C62.6057 79.1758 59.5432 82.2382 59.5432 86.016C59.5432 89.7937 62.6057 92.8562 66.3834 92.8562Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.1636 62.8398L62.5604 78.4211C60.05 79.498 58.3121 81.8409 58.0098 84.5557L35.1636 62.8398Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M122.365 83.2389C120.998 83.2222 119.649 83.5569 118.448 84.2109L105.66 91.0367V107.403L118.448 114.2C119.648 114.857 120.997 115.194 122.365 115.179H127.362V83.2389H122.365ZM124.035 111.896H122.365C121.545 111.904 120.737 111.701 120.018 111.306L108.994 105.416V93.0319L120.018 87.1421C120.737 86.7475 121.545 86.5442 122.365 86.5517H124.035V111.896Z" fill="currentColor"/>
      <rect x="127.362" y="89.043" width="14.638" height="6.37218" fill="currentColor"/>
      <rect x="127.362" y="103.613" width="10.3899" height="6.30739" fill="currentColor"/>
      <rect x="99.418" y="91.0391" width="6.24977" height="16.3661" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M114.934 82.3119C111.522 60.4664 92.4266 45.4972 71.2652 43.5315C50.1038 41.5659 28.6328 52.5678 20.439 72.8796C18.4923 77.6359 17.4403 82.7106 17.3357 87.8488C17.1811 89.4201 17.249 91.0054 17.5373 92.5577C17.655 93.0373 17.8817 93.4833 18.1997 93.861C19.7046 95.6682 21.4326 95.0562 23.2399 95.0562H99.2308C99.3028 95.0562 99.418 94.9986 99.418 95.121V102.969C99.418 103.106 99.6196 103.372 99.3532 103.372H22.3255C19.9854 103.372 17.8109 103.257 15.6149 102.17C11.916 100.352 9.45617 96.7111 9.14908 92.601C8.72816 87.1798 9.20005 81.7263 10.5459 76.4581C12.7273 67.4679 17.3349 59.249 23.8663 52.6974C38.7419 37.2746 61.293 31.6224 81.7271 36.965C102.161 42.3075 119.082 58.4504 122.927 79.907H122.329C120.413 79.8873 118.523 80.3609 116.843 81.2822L114.934 82.3119Z" fill="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'MeterPower')

export default icon
