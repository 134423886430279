import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveConditionDescription, saveConditionRoomNumber } from '@app/actions/scenes/currentSceneCreating'
import { syncRoomsScenes } from '@app/actions/scenes/roomsScenes/roomsScenes'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectRoomCondition
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/RoomCondition/SelectRoomCondition'
import SelectRoomConditionIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/RoomCondition/SelectRoomCondition'
import {
  withAccessDenied, withNotifications, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectRoomConditionIOS) : withStyles(SelectRoomCondition)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { roomsScenes } = state.scenes.roomsScenes
  return {
    isMobile,
    roomsScenes,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncRoomsScenes,
  tryAgain: syncRoomsScenes,
  notifications: syncNotifications.sync,
  saveConditionDescription,
  saveConditionRoomNumber,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectRoomConditionEnhanced = enhance

export default withRouter(SelectRoomConditionEnhanced)
