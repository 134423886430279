const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 0,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 36,
    paddingRight: 36,
    paddingBottom: 20,
  },
  fieldsWrapper: {
    width: '100%',
    padding: '0 36px',
  },
  formWrapper: {
    paddingBottom: 50,
  },
  buttonDelete: {
    margin: '0 auto',
    marginTop: 60,
    width: '25%',
  },
  buttonDeleteWrapper: {
    paddingBottom: 40,
    display: 'flex',
    alignItems: 'center',
  },
  webView: {
    background: theme.webView.background,
  },
  avatar: {
    width: 80,
    height: 80,
    marginRight: 20,
  },
})

export default styles
