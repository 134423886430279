import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ZWAVE_DEVICE_BINARY_SENSOR_VALUES } from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import { updateZWaveDeviceBinarySensorValues } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { saveBinarySensorValueDescription, saveConditionDeviceStateInfo } from '@app/actions/scenes/currentSceneCreating'
import { SELECT_BINARY_SENSOR_STATE_SELECTED, SELECT_BINARY_SENSOR_VALUE } from '@app/constants/routes'
import fetchBinarySensorsValues from '@app/api/scenes/ZWaveDevices/BinarySensors/fetchBinarySensorsValues'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import getDeviceStateInfo from '@app/helpers/scenes/getDeviceStateInfo'

const fetchZWaveBinarySensorValuesLogic = createLogic({
  type: SYNC_ZWAVE_DEVICE_BINARY_SENSOR_VALUES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionDeviceId, sensorType } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ZWAVE_DEVICE_BINARY_SENSOR_VALUES))
    fetchBinarySensorsValues(token, conditionDeviceId, sensorType)
      .then((response) => {
        const { data } = response
        dispatch(updateZWaveDeviceBinarySensorValues(data))
        dispatch(apiRequestDone(SYNC_ZWAVE_DEVICE_BINARY_SENSOR_VALUES))
        if (data.length === 1) {
          dispatch(saveBinarySensorValueDescription(data[0].title))
          const deviceState = getDeviceStateInfo(data[0])
          dispatch(saveConditionDeviceStateInfo(deviceState))
          history.push(SELECT_BINARY_SENSOR_STATE_SELECTED)
        } else {
          history.push(SELECT_BINARY_SENSOR_VALUE)
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchBinarySensorsValues failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchZWaveBinarySensorValuesLogic
