import { connect } from 'react-redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withAccessDenied } from '@app/hocs'
import { isIOS } from 'react-device-detect'
import SceneReviewBase from '@app/components/MyAutomation/Tabs/ScenesScreens/SceneReviewCommon/SceneReviewBase'
import SceneReviewBaseIOS from '@app/components/MyAutomation/Tabs/iOS/SceneReviewCommon/SceneReviewBase'
import checkBefore500FirmwareVersion from '@app/helpers/scenes/checkBefore500FirmwareVersion'
import {
  MAX_NUMBER_OF_BYTES_SCENE_NAME,
  MAX_NUMBER_OF_BYTES_SCENE_NAME_BEFORE_500,
  MAX_NUMBER_OF_SCENES,
  MAX_NUMBER_OF_SCENES_BEFORE_500,
} from '@app/lib/Scenes/scenesRestrictions'
import { AppState } from '@app/@types/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? SceneReviewBaseIOS : SceneReviewBase

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { scenes } = state.scenes.scenesList
  const { conditions, actions } = state.scenes.currentSceneConditionsActions
  const { name: sceneName, status: sceneStatus } = state.scenes.scenesList.currentScene
  const { initialSceneId } = state.scenes.editScene
  const { firmwareVersion } = state.unit
  const before500FWVersion = checkBefore500FirmwareVersion(firmwareVersion)
  const maxNumberOfScenes = before500FWVersion ? MAX_NUMBER_OF_SCENES_BEFORE_500 : MAX_NUMBER_OF_SCENES
  const maxNumberOfBytesSceneName = before500FWVersion ? MAX_NUMBER_OF_BYTES_SCENE_NAME_BEFORE_500 : MAX_NUMBER_OF_BYTES_SCENE_NAME
  return {
    conditions,
    actions,
    isMobile,
    sceneName,
    scenes,
    sceneStatus,
    initialSceneId,
    maxNumberOfScenes,
    maxNumberOfBytesSceneName,
    firmwareVersion,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps)(enhance)

const SceneReviewBaseEnhanced = enhance

export default SceneReviewBaseEnhanced
