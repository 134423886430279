import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const config = () => ({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  },
})

const getClientCredentialsToken = (
  clientId,
  clientSecret,
  grantType = 'client_credentials',
) => (
  Axios.post(
    `${API_URL}/oauth/token`,
    {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: grantType,
    },
    config(),
  )
)

export default { getClientCredentialsToken }
