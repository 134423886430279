import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

class Redirect extends React.Component {
  render() {
    const { to } = this.props
    return (
      <Typography>
        {`Redirecting to ${to}...`}
      </Typography>
    )
  }
}

Redirect.propTypes = {
  to: PropTypes.string,
}
Redirect.defaultProps = {
  to: null,
}

export default Redirect
