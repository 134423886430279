import { VALIDATION_REG_EXP_PIN_CODE, VALIDATION_REG_EXP_PIN_CODE_NCENTRAL } from '@app/lib/Users/userEditvalidationRegExps'

export const validate = (values, isNCentral) => {
  const errors = {}
  const newCodeFirst = 'new_pin_code'
  const newCodeSecond = 'repeat_pin_code'
  const validationMessage = isNCentral ? I18n.t('my_users.pin_code_hint_ncentral') : I18n.t('my_users.pin_code_hint_mezzo')
  const pinCodeRegexp = isNCentral ? VALIDATION_REG_EXP_PIN_CODE_NCENTRAL : VALIDATION_REG_EXP_PIN_CODE

  const requiredFields = [newCodeFirst, newCodeSecond]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = validationMessage
    }
  })

  if (values[newCodeFirst] && !pinCodeRegexp.test(values[newCodeFirst])) {
    errors[newCodeFirst] = validationMessage
  }
  if (values[newCodeSecond] && !pinCodeRegexp.test(values[newCodeSecond])) {
    errors[newCodeSecond] = validationMessage
  }
  if (values[newCodeFirst] !== values[newCodeSecond]) {
    errors[newCodeSecond] = I18n.t('my_users.pin_codes_match')
  }
  return errors
}
