const styles = (theme) => ({
  main: {
    padding: 10,
    height: '100%',
  },
  mainDesktop: {
    padding: 25,
    height: '100%',
  },
  buttonsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  button: {
    textDecoration: 'underline',
    textTransform: 'none',
    padding: 0,
    margin: 0,
  },
  resetModeButton: {
    color: '#d50000',
  },
  sortIcon: {
    marginLeft: 5,
    fontSize: 20,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
