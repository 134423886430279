import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { CANCEL_ZWAVE_DEVICE } from '@app/constants/myDevices/zwaveDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/zwaveDevices'

const cancelZwaveDeviceLogic = createLogic({
  type: CANCEL_ZWAVE_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(CANCEL_ZWAVE_DEVICE))
    try {
      await Api.cancelZwaveDevice(action.accessToken)
      dispatch(apiRequestDone(CANCEL_ZWAVE_DEVICE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('CANCEL_ZWAVE_DEVICE failed without error')
      }
    }
    done()
  },
})

export default cancelZwaveDeviceLogic
