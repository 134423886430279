import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchPoScreen = (access_token: string, screen: string) => Axios.get(`${API_URL}/api/program_options/screen`, {
  params: {
    screen,
  },
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchPoScreen,
}
