import Axios from 'axios'

import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (sphereId) => `${API_URL}/api/sphere_user/${sphereId}/change_password`

const changePassword = (accessToken, sphereId, data) => Axios.patch(ENDPOINT(sphereId), data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  changePassword,
}
