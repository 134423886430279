import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/${id}/activate`

const activateScene = (accessToken, id) => Axios.post(ENDPOINT(id), null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  activateScene,
}
