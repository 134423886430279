import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width="100" height="100" viewBox="0 0 390 470"><path d="M302.9 149.8c5.6-6.1 5-15.5-1.2-20.9L164.6 8.9c-6.2-5.4-16.2-5.3-22.3 0.3L4.8 135.3c-6.1 5.6-6.4 14.9-0.7 20.9l3.4 3.6c5.7 6 15 6.7 20.6 1.6l10.3-9.2v134.8c0 8.3 6.7 15 15 15h53.6c8.3 0 15-6.7 15-15v-94.3h68.4v94.3c-0.1 8.3 5.8 15 14.1 15h56.8c8.3 0 15-6.7 15-15V154c0 0 2.8 2.5 6.3 5.6 3.5 3.1 10.8 0.6 16.4-5.5L302.9 149.8z" fill="currentColor"/></svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'HomeIcon')

export default icon
