import { createLogic } from 'redux-logic'
import { SYNC_NOTIFICATIONS } from '@app/constants/notifications/notifications'
import { update } from '@app/actions/notifications/notifications'
import Api from '@app/api/notifications/fetchNotifications'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import handleErrors from '@app/helpers/handleErrors'

const fetchNotificationsLogic = createLogic({
  type: SYNC_NOTIFICATIONS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_NOTIFICATIONS))
    Api.fetchNotifications(token)
      .then((response) => {
        const { data } = response
        dispatch(update(data.notifications))
        dispatch(apiRequestDone(SYNC_NOTIFICATIONS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchNotifications failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchNotificationsLogic
