import * as type from '@app/constants/notifications/notificationsSettings'
import * as NotificationsSettingsAction from '@app/actions/notifications/notificationsSettings'
import { InferValueTypes } from '@app/@types/typesHelper'

type NotificationsSettingsActions = ReturnType<InferValueTypes<typeof NotificationsSettingsAction>>

export interface INotificationsSettingsState {
  push_setting: Record<string, boolean>,
}

const initialState = {
  push_setting: {
    arming_events: true,
    pills_reminders: true,
    system_outputs: true,
    scene: true,
    pir_event: true,
    zone_sealed: true,
    zone_alarms: true,
    zone_tamper: true,
    other_zone: true,
    power_failure: true,
    battery_failure: true,
    report_failure: true,
    other_system: true,
  },
}

export default function notificationsSettings(state: INotificationsSettingsState = initialState, action: NotificationsSettingsActions) {
  switch (action.type) {
  case type.UPDATE_NOTIFICATIONS_SETTING:
    return { ...state, push_setting: action.settings }
  default:
    return state
  }
}
