const styles = (theme) => ({
  root: {
    paddingRight: 24,
  },
  homeButton: {
    marginBottom: -5,
    padding: 0,
    color: 'currentColor',
  },
  menuButtonHidden: {
    display: 'none',
  },
  logout: {
    paddingLeft: theme.spacing(2.5),
  },
  iconRoot: {
    padding: 5,
  },
  expandIcon: {
    fontSize: '14px',
    marginLeft: 9,
  },
  list: {
    outline: 'none',
    paddingLeft: 10,
  },
  unitsList: {
    marginTop: 35,
  },
  listItem: {
    display: 'flex',
  },
  ssidButtonMobile: {
    display: 'flex',
    color: theme.typography.color,
    flexWrap: 'wrap',
    width: 'fit-content',
    alignItems: 'flex-start',
  },
  ssidButton: {
    color: theme.typography.color,
  },
  mobileWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  ssidMobileText: {
    fontSize: '12px',
  },
  nameText: {
    textTransform: 'none',
  },
  nameMobileText: {
    fontSize: '10px',
  },
})

export default styles
