import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { resetCounter } from '@app/actions/scenes/counter'
import { saveActionDelay, saveActionDelayDescription } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import AdjustDelay
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/BinarySwitchActions/AdjustDelay/AdjustDelay'
import AdjustDelayIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/BinarySwitchActions/AdjustDelay/AdjustDelay'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(AdjustDelayIOS) : withStyles(AdjustDelay)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { counter: counterInfo } = state.scenes
  return {
    isMobile,
    path,
    counterInfo,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  resetCounter,
  saveActionDelay,
  saveActionDelayDescription,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const AdjustDelayEnhanced = enhance

export default withRouter(AdjustDelayEnhanced)
