import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 50,
  },
  rootMobile: {
    justifyContent: 'center',
    paddingTop: 5,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    padding: '0 40px',
  },
  cardContainerMobile: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 0,
  },
  card: {
    minWidth: 200,
    maxWidth: 220,
    minHeight: 200,
    maxHeight: 220,
    // eslint-disable-next-line max-len
    background: 'linear-gradient(90deg, #424242 0%, #474747 5.26%, #4D4D4D 10.53%, #525252 15.79%, #565656 21.05%, #575757 26.32%, #585858 31.58%, #595959 36.84%, #5A5A5A 42.11%, #5B5B5B 47.37%, #5B5B5B 52.63%, #5C5C5C 57.89%, #5B5B5B 63.16%, #595959 68.42%, #585757 73.68%, #565555 78.95%, #525151 84.21%, #4D4C4C 89.47%, #474747 94.74%, #424242 100%)',
    boxShadow: `0px 4px 30px ${theme.dashboard.shadowColor}`,
    transition: 'all 1s ease-out',
    margin: 15,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.texts.main,
    textDecoration: 'none',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: `0px 4px 30px ${theme.dashboard.hoverShadowColor}`,
    },
  },
  cardMobile: {
    minWidth: '40vw',
    maxWidth: '40vw',
    minHeight: '40vw',
    maxHeight: '40vw',
    margin: '5px 2px',
    transform: 'none',
    '&:hover': {
      transform: 'none',
      boxShadow: `0px 4px 30px ${theme.dashboard.shadowColor}`,
    },
  },
  cardDisabled: {
    transition: 'none',
    cursor: 'default',
    color: '#8b8b8b',
  },
  cardLabel: {
    fontSize: '1vw',
    marginTop: 30,
    textTransform: 'uppercase',
  },
  cardLabelMobile: {
    fontSize: 15,
    marginTop: 10,
  },
  cardLabelTablet: {
    fontSize: 50,
  },
  icon: {
    fontSize: '5vw',
  },
  iconMobile: {
    fontSize: 80,
  },
  iconTablet: {
    fontSize: 220,
  },
  notActivated: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 15%',
  },
  elementsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  elementsWrapperMobile: {
    flexWrap: 'wrap',
  },
  topElement: {
    width: 430,
    // eslint-disable-next-line max-len
    background: 'linear-gradient(90deg, #424242 0%, #474747 5.26%, #4D4D4D 10.53%, #525252 15.79%, #565656 21.05%, #575757 26.32%, #585858 31.58%, #595959 36.84%, #5A5A5A 42.11%, #5B5B5B 47.37%, #5B5B5B 52.63%, #5C5C5C 57.89%, #5B5B5B 63.16%, #595959 68.42%, #585757 73.68%, #565555 78.95%, #525151 84.21%, #4D4C4C 89.47%, #474747 94.74%, #424242 100%)',
    margin: '10px',
  },
  topElementMobile: {
    minWidth: '100%',
    maxWidth: '100%',
    marginBottom: 10,
  },
  armBlockWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 35px',
  },
  armButtonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
  },
  armButtonsWrapperMobile: {
    width: '100%',
  },
  eventWindow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'space-between',
  },
  eventWindowMobile: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  eventMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  eventMessage: {
    fontSize: 17,
    margin: '0 10px',
  },
  sealedIcon: {
    marginRight: 10,
    fontSize: 30,
  },
  unSealedIcon: {
    fontSize: 22,
    color: theme.palette.secondary.dark,
    marginRight: 10,
    marginTop: 5,
  },
  eventIcon: {
    marginTop: 10,
    marginRight: 10,
  },
  arrowIconInner: {
    fontSize: 30,
  },
}))

export default useStyles
