import * as type from '@app/constants/rooms/rooms'
import { IRoom } from '@app/@types/rooms'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as RoomsAction from '@app/actions/rooms/rooms'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

type RoomsActions = ReturnType<InferValueTypes<typeof RoomsAction>>

export interface IRoomsState {
  isAddPopupOpen: boolean,
  roomsList: Array<IRoom>,
  twrDevices: Array<IZwaveOrTwrDevice>,
  zWaveDevices: Array<IZwaveOrTwrDevice>,
  freeTwrDevices: Array<IZwaveOrTwrDevice>,
  freeZwaveDevices: Array<IZwaveOrTwrDevice>,
}

const initialState = {
  isAddPopupOpen: false,
  roomsList: [],
  twrDevices: [],
  zWaveDevices: [],
  freeTwrDevices: [],
  freeZwaveDevices: [],
}

const rooms = (state: IRoomsState = initialState, action: RoomsActions): IRoomsState => {
  switch (action.type) {
  case type.SET_ROOMS_LIST: return { ...state, roomsList: action.data.rooms }
  case type.SET_ROOMS_DEVICES: return {
    ...state, freeTwrDevices: action.devices.twr_devices, freeZwaveDevices: action.devices.zwave_devices,
  }
  case type.SET_POST_ADD_ROOM: return {
    ...state, isAddPopupOpen: false, roomsList: [...state.roomsList, action.room],
  }
  case type.POST_ADD_ROOM_FAILED_POPUP: return {
    ...state, isAddPopupOpen: false,
  }
  case type.SET_DELETE_ROOM: return {
    ...state,
    roomsList: state.roomsList.map((room) => (room.id === action.room.id ? action.room : room)),
  }
  case type.SET_PATCH_ROOM: return {
    ...state,
    roomsList: state.roomsList.map((room) => (room.id === action.room.id ? action.room : room)),
  }
  case type.TOGGLE_ADD_ROOM_POPUP: return { ...state, isAddPopupOpen: !state.isAddPopupOpen }
  case type.FILTER_ADD_TWR_DEVICES: return {
    ...state,
    freeTwrDevices: state.freeTwrDevices.filter((device) => device.id !== action.id),
  }
  case type.FILTER_ADD_ZWAVE_DEVICES: return {
    ...state,
    freeZwaveDevices: state.freeZwaveDevices.filter((device) => device.id !== action.id),
  }
  default: return state
  }
}

export default rooms
