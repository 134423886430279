import React from 'react'
import Switch from '@mui/material/Switch'
import classNames from 'classnames'
import useStyles from './ColoredSwitch.style'

interface Props {
  big?: boolean,
  medium?: boolean,
  [props: string]: any,
}

const ColoredSwitch: React.FC<Props> = ({ big, medium, ...props }) => {
  const classes = useStyles()
  return (
    <Switch
      disableRipple
      classes={{
        root: classNames(classes.root, big && classes.rootBig, medium && classes.rootMedium),
        switchBase: classNames(classes.switchBase, big && classes.switchBaseBig, medium && classes.switchBaseMedium),
        thumb: classNames(classes.thumb, big && classes.thumbBig, medium && classes.thumbMedium),
        track: classNames(classes.track, big && classes.trackBig, medium && classes.trackMedium),
        checked: classes.checked,
      }}
      {...props}
    />
  )
}

ColoredSwitch.defaultProps = {
  big: false,
  medium: false,
}

export default ColoredSwitch
