import buttons from './buttons.json'
import confirmations from './confirmations.json'
import errors from './errors.json'
import mezzo_error_codes from './mezzo_error_codes.json'
import mezzo_time from './mezzo_time.json'
import multisensor_scene from './multisensor_scene.json'
import my_activity_web from './my_activity_web.json'
import my_energy from './my_energy.json'
import my_reminders from './my_reminders.json'
import my_users from './my_users.json'
import notification_settings from './notification_settings.json'
import sphere from './sphere.json'
import titles from './titles.json'
import validations from './validations.json'
import program_options from './program_options.json'
import drop_down_menu from './drop_down_menu.json'
import app_info from './app_info.json'
import hint from './hint.json'
import secrets from './secrets.json'
import my_events from './my_events.json'
import scene_action_web from './scene_action_web.json'
import scene_web from './scene_web.json'
import forgot_password from './forgot_password.json'
import wizard_web from './wizard_web.json'
import my_status from './my_status.json'
import my_devices from './my_devices.json'
import zwave_colour from './zwave_colour.json'
import failedNode from './failedNode.json'
import scene_condition_web from './scene_condition_web.json'
import geofence_web from './geofence_web.json'
import rooms_and_groups from './rooms_and_groups.json'
import contacts_web from './contacts_web.json'
import stats from './stats.json'
import timers from './timers.json'
import health_alert from './health_alert.json'
import virtual_switches from './virtual_switches.json'
import home from './home.json'
import sync_web from './sync_web.json'
import cloud_firmware_update from './cloud_firmware_update.json'
import security from './security.json'

export default {
  buttons,
  confirmations,
  errors,
  mezzo_error_codes,
  mezzo_time,
  multisensor_scene,
  my_energy,
  my_reminders,
  my_users,
  notification_settings,
  sphere,
  titles,
  validations,
  program_options,
  drop_down_menu,
  app_info,
  hint,
  secrets,
  my_events,
  scene_web,
  forgot_password,
  wizard_web,
  my_status,
  my_devices,
  zwave_colour,
  failedNode,
  scene_condition_web,
  geofence_web,
  scene_action_web,
  rooms_and_groups,
  contacts_web,
  stats,
  timers,
  health_alert,
  virtual_switches,
  home,
  my_activity_web,
  sync_web,
  cloud_firmware_update,
  security,
}
