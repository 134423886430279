import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_DEVICE_ENERGIES } from '@app/constants/myEnergy/deviceManager'
import { updateDeviceEnergies } from '@app/actions/myEnergy/deviceManager'
import Api from '@app/api/myEnergy/fetchDevicesWithEnergies'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchDevicesWithEnergiesLogic = createLogic({
  type: SYNC_DEVICE_ENERGIES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const { deviceManager: { selectedSorting } } = store.getState()
    const currentDate = new Date()
    dispatch(apiRequestSent(SYNC_DEVICE_ENERGIES))
    Api.fetchDevicesWithEnergies(currentDate, AccessToken, selectedSorting)
      .then((response) => {
        const { data } = response
        dispatch(updateDeviceEnergies(data.energy_devices, data.sort))
        dispatch(apiRequestDone(SYNC_DEVICE_ENERGIES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchDevicesWithEnergies failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchDevicesWithEnergiesLogic
