import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import { IVoiceAssistantData, IVoiceAssistantPin } from '@app/actions/myDevices/deviceSettings/voiceAssistant'

const patchVoiceAssistant = (access_token: string, id: number, data: IVoiceAssistantData) => Axios.patch(
  `${API_URL}/api/devices/zwave_devices/${id}/voice_assistant_status`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const patchVoiceAssistantPin = (access_token: string, data: { voice_assistant_pin: IVoiceAssistantPin }) => Axios.patch(
  `${API_URL}/api/unit_devices/voice_assistant_pin`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

export default {
  patchVoiceAssistant,
  patchVoiceAssistantPin,
}
