import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 40">
      <path fill="currentColor" d="M5.24 19.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.24-1.15c1 0 1.99-.29 2.77-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.13.1.37.37 0 0 0-.07.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M18.79 9.77V3.02c0-.29-.19-.51-.32-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.74 20.74 0 0 0 9.39 0C6.93 0 4.65.38 2.97.93a8.1 8.1 0 0 0-2.06.96c-.48.34-.91.8-.91 1.37v6.5c0 .65.47 1.22 1.03 1.68.59.47 1.39.92 2.3 1.31.83.36 1.78.68 2.75.91.13.54.56.97 1.06 1.24.61.34 1.43.54 2.3.54.87 0 1.69-.2 2.3-.54.5-.28.94-.7 1.06-1.25a17.4 17.4 0 0 0 2.67-.88 9.7 9.7 0 0 0 2.29-1.32c.56-.46 1.03-1.03 1.03-1.68Zm-5.97 3.4.68-.2c.04-.13.06-.27.06-.41 0-1.32-1.84-2.4-4.12-2.4-2.28 0-4.12 1.08-4.12 2.4 0 .15.02.3.07.44h-.01l.68.18c.07-.62.54-1.09 1.08-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.69.2 2.3.54.54.3 1 .77 1.08 1.37Zm-7.96-.34c-2.41-.79-4.4-2.02-4.4-3.06V7.33C1.29 6.85 4.72 5.03 9.3 5c4.6 0 8.19 1.85 9.02 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04.02-.08.02-.17.02-.25 0-.92-.63-1.65-1.45-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.82.47-1.45 1.2-1.45 2.12 0 .09 0 .18.02.27ZM.46 3.26c0-1.3 4.1-2.8 8.93-2.8 4.84 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.02-2.3c-4.43 0-8 1.84-8.83 2.31v-1.3Zm0 1.93v1.5A19.1 19.1 0 0 1 9.3 4.37a18.1 18.1 0 0 1 9.02 2.31V5.2A19.26 19.26 0 0 0 9.3 2.87 18.1 18.1 0 0 0 .47 5.2Zm11.91 8.16c0 .9-1.31 1.62-2.93 1.62s-2.93-.72-2.93-1.62c0-.9 1.31-1.63 2.93-1.63s2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="m17.56 27.27-.02-.03a1.02 1.02 0 0 0-1.41 0l-1.5 1.52a.2.2 0 0 1-.28 0l-2.1-1.95c-.2-.17-.45-.29-.74-.29H8.4c-.09 0-.15.03-.18.06L5.58 29.1c-.38.4-.4 1.03-.06 1.4.17.2.43.32.72.32.28 0 .54-.11.71-.31l1.87-1.98h.66L5.12 33.4h-2.7c-.66 0-1.17.5-1.2 1.1-.03.3.08.62.31.85.23.2.52.35.83.35h4.02c.08 0 .14-.03.2-.09l2.06-2.2 1.73 1.8-.52 3.33a1.17 1.17 0 0 0 1.63 1.35c.3-.15.52-.43.6-.78l.9-4.79c0-.08-.03-.2-.1-.26l-2.37-2.38 1.9-1.89 1.31 1.32a1 1 0 0 0 1.38 0l2.46-2.47c.38-.37.38-1 0-1.37Zm-.4.97-2.46 2.47c-.18.14-.43.14-.58 0l-1.52-1.52a.28.28 0 0 0-.4 0l-2.3 2.3a.26.26 0 0 0-.08.2c0 .08.03.14.12.22l2.46 2.47-.86 4.62a.8.8 0 0 1-.31.37.58.58 0 0 1-.5 0c-.25-.12-.36-.4-.3-.72l.54-3.5a.33.33 0 0 0-.09-.23L8.85 32.8a.26.26 0 0 0-.2-.08c-.1 0-.18.02-.2.08l-2.19 2.32h-3.9a.58.58 0 0 1-.43-.17.7.7 0 0 1-.14-.43c0-.31.29-.54.63-.54h2.81c.09 0 .15-.03.2-.03l4.88-5.45c.08-.09.08-.2.06-.32-.03-.11-.15-.17-.26-.17H8.67a.31.31 0 0 0-.2.09l-1.95 2.06a.35.35 0 0 1-.28.15.46.46 0 0 1-.32-.15.44.44 0 0 1 .03-.6l2.55-2.44h3.04c.12 0 .26.06.35.15l2.09 1.95a.8.8 0 0 0 1.09-.03l1.49-1.52a.44.44 0 0 1 .57 0l.03.03a.4.4 0 0 1 0 .54Z"/>
      <path fill="currentColor" d="M14.4 27.38a1.72 1.72 0 1 0-1.71-1.72c0 .95.77 1.72 1.72 1.72Zm0-2.87c.64 0 1.16.52 1.16 1.15a1.15 1.15 0 0 1-2.3 0c0-.63.52-1.15 1.15-1.15Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'MotionIcon', '0 0 80 80', {fontSize: 35})

export default icon
