import * as type from '@app/constants/contacts/contacts'

export const syncContacts = () => ({
  type: type.SYNC_CONTACTS,
})

export const setContacts = (contacts) => ({
  type: type.SET_CONTACTS,
  contacts,
})

export const postContact = () => ({
  type: type.POST_CONTACT,
})

export const setPostContact = (contact) => ({
  type: type.SET_POST_CONTACT,
  contact,
})

export const patchContact = (id) => ({
  type: type.PATCH_CONTACT,
  id,
})

export const setPatchContact = (contact) => ({
  type: type.SET_PATCH_CONTACT,
  contact,
})

export const deleteContact = (id) => ({
  type: type.DELETE_CONTACT,
  id,
})

export const setDeleteContact = (contact) => ({
  type: type.SET_DELETE_CONTACT,
  contact,
})

export const updateAvatar = (contactId, avatar) => ({
  type: type.UPDATE_AVATAR,
  contactId,
  avatar,
})

export const setAvatar = (data) => ({
  type: type.SET_AVATAR,
  data,
})

export const updateAlertMessage = (newAlertMessage) => ({
  type: type.UPDATE_ALERT_MESSAGE,
  newAlertMessage,
})

export const setAlertMessage = (newAlertMessage) => ({
  type: type.SET_ALERT_MESSAGE,
  newAlertMessage,
})

export const setFormData = (data) => ({
  type: type.SET_FORM_DATA,
  data,
})

export const toggleSummaryPopup = () => ({
  type: type.TOGGLE_SUMMARY_POPUP,
})

export const toggleFormPopup = () => ({
  type: type.TOGGLE_FORM_POPUP,
})

export const toggleAvatarPopup = () => ({
  type: type.TOGGLE_AVATAR_POPUP,
})

export const closePopups = () => ({
  type: type.CLOSE_POPUPS,
})

export const setFormInitialValues = (values) => ({
  type: type.SET_FORM_INITIAL_VALUES,
  values,
})

export const setIsContactsEmpty = (status) => ({
  type: type.SET_IS_CONTACTS_EMPTY,
  status,
})
