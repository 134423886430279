const parseDate = (isoDate) => {
  if (isoDate === null) {
    return 'N/A'
  }
  const fullDate = new Date(isoDate)
  const date = (`0${fullDate.getDate()}`).slice(-2)
  const month = (`0${fullDate.getMonth() + 1}`).slice(-2)
  const year = fullDate.getFullYear()
  return `${date}.${month}.${year}`
}
export default parseDate
