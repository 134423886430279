import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { ListItem } from '@mui/material'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import styles from './Password.style'
import PasswordField from './PasswordField'

class Password extends React.PureComponent {
  render() {
    const {
      classes,
      title,
      name,
      hint,
      isMobile,
      ...other
    } = this.props
    return (
      <ListItem
        disableGutters
        className={classNames(
          classes.root,
          !isMobile && classes.rootDesktop,
        )}
      >
        <Field
          title={title}
          name={name}
          hint={((name !== 'old_code') && hint) || ''}
          component={PasswordField}
          {...other}
        />
      </ListItem>
    )
  }
}

Password.propTypes = {
  classes: PropTypes.object.isRequired,
  hint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default (withStyles(styles)(Password))
