import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 32">
      <path fill="currentColor" d="M36.87 26.24c-.22-1.2-1.15-2.1-2.2-2.69a9.68 9.68 0 0 0-4.64-1.08c-1.76 0-3.4.4-4.64 1.08-1.06.6-2 1.51-2.21 2.72l-1.09-.3a2.7 2.7 0 0 1-.1-.75c0-1.24.86-2.4 2.32-3.24a11.58 11.58 0 0 1 5.72-1.38c2.24 0 4.26.53 5.72 1.38 1.46.85 2.32 2 2.32 3.24 0 .24-.03.48-.1.72l-1.1.3Zm11.89-6.6V6.2c0-.65-.4-1.13-.69-1.42a6.63 6.63 0 0 0-1.26-.94c-1-.6-2.4-1.22-4.05-1.78A41.2 41.2 0 0 0 29.94 0c-4.91 0-9.46.76-12.82 1.86-1.67.56-3.1 1.21-4.13 1.94-.96.68-1.87 1.64-1.87 2.85v13c0 1.36.99 2.54 2.1 3.45a20.1 20.1 0 0 0 4.59 2.65c1.65.7 3.5 1.33 5.42 1.8a4.25 4.25 0 0 0 2.16 2.49 9.68 9.68 0 0 0 4.64 1.09c1.75 0 3.4-.4 4.64-1.1a4.2 4.2 0 0 0 2.17-2.51 34.3 34.3 0 0 0 5.22-1.74 19.4 19.4 0 0 0 4.6-2.64c1.11-.93 2.1-2.12 2.1-3.5Zm-9.44 5.87v-.3c0-1.9-1.3-3.4-2.95-4.36a12.8 12.8 0 0 0-6.34-1.54c-2.42 0-4.66.57-6.34 1.54-1.64.96-2.96 2.46-2.96 4.37l.01.33a25.05 25.05 0 0 1-5.9-2.78 8.57 8.57 0 0 1-1.84-1.6 2.49 2.49 0 0 1-.66-1.52v-4.78a36.3 36.3 0 0 1 17.4-4.56c8.99-.02 16 3.56 17.8 4.57v4.77c0 .48-.23 1-.65 1.52a8.27 8.27 0 0 1-1.8 1.6 24.22 24.22 0 0 1-5.77 2.74ZM12.34 6.65c0-.57.45-1.2 1.36-1.84.9-.64 2.2-1.24 3.8-1.78a41.13 41.13 0 0 1 12.44-1.8c4.78 0 9.2.9 12.43 1.99a20.3 20.3 0 0 1 3.81 1.67c.45.27.8.53 1.03.76.23.23.32.41.32.54v2.77a36.23 36.23 0 0 0-17.78-4.5c-8.5 0-15.4 3.41-17.4 4.52V6.65Zm35.2 6.58a36.23 36.23 0 0 0-17.8-4.5 37.97 37.97 0 0 0-17.4 4.52V10.6a36.3 36.3 0 0 1 17.4-4.56c8.99-.02 16 3.56 17.8 4.57v2.62ZM35.69 26.8c0 .81-.6 1.59-1.63 2.16a8.47 8.47 0 0 1-4.04.93 8.47 8.47 0 0 1-4.05-.93c-1.03-.57-1.62-1.35-1.62-2.16 0-.82.6-1.6 1.62-2.17a8.47 8.47 0 0 1 4.05-.93c1.59 0 3.02.36 4.04.93 1.03.58 1.63 1.35 1.63 2.17Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M52.25 20.88c0-.25.09-.5.32-.65 0 0 1.74-1.45 1.74-3.74 0-2.35-1.74-4-1.74-4a.85.85 0 0 1-.1-1.15c.28-.35.74-.4 1.06-.1.1.1 2.3 2.15 2.3 5.25 0 3.14-2.25 4.99-2.35 5.09-.32.25-.82.2-1.05-.15a.98.98 0 0 1-.18-.55Z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M55.78 8.6c0-.2.09-.45.23-.6.32-.3.78-.3 1.05.05.14.15 2.94 3.5 2.94 8.48 0 5-2.84 8-2.98 8.14-.32.3-.78.3-1.06-.05-.27-.35-.27-.85.05-1.15.04 0 2.52-2.64 2.52-6.94 0-4.34-2.52-7.33-2.52-7.33-.14-.2-.23-.4-.23-.6ZM7.75 11.36c0 .25-.09.5-.32.65 0 0-1.74 1.45-1.74 3.74 0 2.35 1.74 4 1.74 4 .32.3.37.8.1 1.14-.28.35-.74.4-1.06.1-.1-.1-2.3-2.14-2.3-5.24 0-3.14 2.25-4.99 2.35-5.09.32-.25.82-.2 1.05.15.14.2.18.4.18.55Z" clipRule="evenodd"/>
      <path fill="currentColor" fillRule="evenodd" d="M4.22 23.64c0 .2-.1.45-.23.6-.32.3-.78.3-1.05-.05C2.8 24.04 0 20.69 0 15.7s2.84-7.98 2.98-8.13c.32-.3.78-.3 1.06.05.27.35.27.84-.05 1.14-.04 0-2.52 2.65-2.52 6.94 0 4.34 2.52 7.34 2.52 7.34.14.2.23.4.23.6Z" clipRule="evenodd"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DefaultIcon', '0 0 80 80', {fontSize: 35})

export default icon
