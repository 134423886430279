import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M89.0658 34.1108C90.6113 34.1108 91.866 32.8574 91.866 31.3119V25.8003C91.866 24.2534 90.6113 23 89.0658 23H59.6039C58.0597 23 56.805 24.2534 56.805 25.8003V31.3119C56.805 32.8574 58.0583 34.1108 59.6039 34.1108H71.7284V38.3072H62.1578C59.0667 38.3072 56.5586 40.8139 56.5586 43.9063V121.348C56.5586 124.441 59.0667 126.946 62.1578 126.946H86.2669C89.3606 126.946 91.8674 124.441 91.8674 121.348V43.9063C91.8674 40.8139 89.3606 38.3072 86.2669 38.3072H76.4216V34.1108H89.0658ZM81.7394 45.6336C83.7493 45.6336 85.377 47.2612 85.377 49.2712V115.589C85.377 117.597 83.7493 119.226 81.7394 119.226H66.0768C64.0668 119.226 62.4391 117.597 62.4391 115.589V49.2712C62.4391 47.2612 64.0668 45.6336 66.0768 45.6336H71.7271V50.6296H69.8908C68.3776 50.6296 67.1498 51.8547 67.1498 53.3693V80.2814C67.1498 81.7946 68.3776 83.0197 69.8908 83.0197H78.1798C79.693 83.0197 80.9181 81.7946 80.9181 80.2814V53.3707C80.9181 51.8561 79.693 50.631 78.1798 50.631H76.4189V45.6349H81.7394V45.6336Z" fill="currentColor"/>
      <path d="M38.1768 98.6862C31.7968 98.9312 24.8029 103.867 18.5656 104.164C14.8687 104.221 11.3778 102.623 8 101.286V114.364C11.3778 115.7 14.8687 117.298 18.5656 117.241C24.8029 116.942 31.7968 112.008 38.1768 111.763C41.9787 111.641 45.5315 113.274 49.1503 114.588V101.511C45.5315 100.197 41.9773 98.5637 38.1768 98.6862Z" fill="currentColor"/>
      <path d="M38.1768 77.8734C31.7968 78.1211 24.8029 83.0552 18.5656 83.3514C14.8687 83.4107 11.3778 81.8126 8 80.4771V93.5548C11.3778 94.8903 14.8687 96.487 18.5656 96.4278C24.8029 96.1814 31.7968 91.1248 38.1768 90.9511C41.9787 90.8004 45.5315 92.459 49.1503 93.7918V80.6979C45.5315 79.3866 41.9773 77.7536 38.1768 77.8734Z" fill="currentColor"/>
      <path d="M38.1768 57.0453C31.7968 57.2944 24.8029 62.2285 18.5656 62.5233C14.8687 62.5812 11.3778 60.9858 8 59.649V72.7267C11.3778 74.0622 14.8687 75.6603 18.5656 75.5997C24.8029 75.3547 31.7968 70.2967 38.1768 70.123C41.9787 69.9736 45.5315 71.6309 49.1503 72.9637V59.8725C45.5315 58.5572 41.9773 56.9255 38.1768 57.0453Z" fill="currentColor"/>
      <path d="M130.025 75.5949C123.648 75.3485 116.653 70.4144 110.414 70.1169C106.72 70.059 103.228 71.6571 99.8486 72.9926V59.9162C103.228 58.5807 106.72 56.984 110.414 57.0405C116.654 57.338 123.648 62.2722 130.025 62.5172C133.827 62.6397 137.381 61.008 141 59.6927V72.7691C137.381 74.083 133.827 75.7161 130.025 75.5949Z" fill="currentColor"/>
      <path d="M130.025 96.4114C123.648 96.1637 116.653 91.2296 110.414 90.9347C106.72 90.8768 103.228 92.4749 99.8486 93.8104V80.7326C103.228 79.3958 106.72 77.7991 110.414 77.857C116.654 78.1034 123.648 83.1613 130.025 83.335C133.827 83.4858 137.381 81.8272 141 80.4944V93.5869C137.381 94.8995 133.827 96.5339 130.025 96.4114Z" fill="currentColor"/>
      <path d="M130.025 117.236C123.648 116.988 116.653 112.054 110.414 111.758C106.72 111.698 103.228 113.296 99.8486 114.632V101.556C103.228 100.22 106.72 98.6221 110.414 98.6813C116.654 98.9277 123.648 103.984 130.025 104.158C133.827 104.307 137.381 102.651 141 101.317V114.41C137.381 115.722 133.827 117.355 130.025 117.236Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'WaterPump')

export default icon
