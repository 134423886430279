import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.9365 56.1603C34.9154 56.2742 34.8935 56.3887 34.8708 56.5038L35.129 56.2456C35.0655 56.2202 35.0013 56.1917 34.9365 56.1603Z" fill="currentColor"/>
      <path d="M22.7367 41.2716C27.0935 36.9147 37.8107 40.6991 34.9365 56.1603C34.4306 55.9153 33.8846 55.4966 33.2841 55.036C32.3984 54.3567 31.3939 53.5864 30.2237 53.1475C28.1583 52.6312 26.3511 52.1148 24.5439 51.0822C21.1877 49.0168 19.6386 44.3697 22.7367 41.2716Z" fill="currentColor"/>
      <path d="M116.039 55.1528C115.2 55.783 114.425 56.3655 113.872 56.5038C112.839 51.8567 113.097 46.6932 115.679 42.8206C121.875 33.7846 134.267 45.6605 123.94 51.5985C122.875 52.2072 121.72 52.5469 120.581 52.8818C119.788 53.115 119.003 53.3459 118.261 53.6639C117.541 54.0236 116.766 54.6061 116.039 55.1528Z" fill="currentColor"/>
      <path d="M116.015 83.1795C115.287 83.6894 114.466 84.2638 113.097 84.9028H112.839C112.581 78.1903 115.163 69.9288 119.293 64.7653C123.424 59.86 132.46 57.5365 137.624 62.6999C143.045 68.1216 139.947 76.6413 133.493 79.4812C131.083 80.5139 128.444 80.6286 125.805 80.7433C124.485 80.8007 123.166 80.8581 121.875 81.0302C118.37 81.5309 117.347 82.2473 116.015 83.1795Z" fill="currentColor"/>
      <path d="M35.3953 84.7757C35.3927 84.818 35.3899 84.8604 35.3872 84.9028H35.6453C35.5599 84.8601 35.4766 84.8177 35.3953 84.7757Z" fill="currentColor"/>
      <path d="M35.3953 84.7757C36.6044 65.2315 19.6196 53.6834 10.8607 62.6999C5.43911 68.1216 8.27902 76.3831 14.7333 79.223C17.2609 80.3062 20.041 80.5057 22.8087 80.7043C23.9988 80.7897 25.1866 80.8749 26.3511 81.0302C30.3317 81.3763 31.2958 82.0705 32.7433 83.1128C33.4104 83.5931 34.1802 84.1474 35.3953 84.7757Z" fill="currentColor"/>
      <path d="M112.145 104.368C112.897 104.778 113.631 105.178 114.388 105.557H114.646C100.447 122.596 48.554 122.854 34.6126 105.557C41.7911 102.382 43.2858 101.052 44.7409 101.094C46.007 101.13 47.2432 102.206 52.1684 104.008C65.077 108.913 83.9236 108.913 96.8323 104.008C97.8221 103.627 98.7222 103.134 99.5854 102.661C101.649 101.531 103.502 100.516 105.868 101.426C108.423 102.338 110.333 103.38 112.145 104.368Z" fill="currentColor"/>
      <path d="M121.447 97.2677C119.611 96.166 117.925 95.1547 116.97 93.9388H117.228C133.493 84.1283 154.147 107.88 129.878 125.694C102.77 145.832 46.4886 145.573 19.3804 125.694C-6.95318 106.331 19.8968 83.6119 31.7727 93.9388C31.5298 94.4247 30.4 95.1574 28.908 96.125C22.4722 100.299 9.29836 108.842 31.5146 120.789C66.8842 139.894 130.911 125.178 129.62 107.106C129.278 101.966 125.069 99.441 121.447 97.2677Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M47.2631 54.1802C48.554 47.7259 64.0443 21.1341 74.6294 9C76.6948 10.549 87.538 27.8466 89.6034 31.2029C94.5087 38.6899 99.1558 46.9514 101.996 55.4711C108.45 76.1249 96.0578 97.0369 73.8549 96.7787C51.1357 96.2624 40.2924 74.5759 47.2631 54.1802ZM67.4006 51.8567C79.2765 53.4057 80.8256 33.0101 74.1131 27.0721C73.4237 28.0679 72.6434 29.1318 71.8329 30.2369C69.9116 32.8567 67.8205 35.7081 66.3679 38.4317C64.3025 42.0461 60.4299 50.824 67.4006 51.8567Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BigDrop')

export default icon
