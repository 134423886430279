import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_TWR_DEVICES_HEALTH } from '@app/constants/twrDevicesHealth'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/twrDevicesHealth'

const fetchTwrDevicesHealth = createLogic({
  type: FETCH_TWR_DEVICES_HEALTH,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_TWR_DEVICES_HEALTH))
    try {
      await Api.fetchTwrDevicesHealth(action.accessToken)
      dispatch(apiRequestDone(FETCH_TWR_DEVICES_HEALTH))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('FETCH_TWR_DEVICES_HEALTH failed without error')
      }
    }
    done()
  },
})

export default fetchTwrDevicesHealth
