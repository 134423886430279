// @ts-nocheck
import getSpecialPrefix from '@app/helpers/scenes/getSpecialPrefix'
import { IConditionFromBackend, IConditionFront } from '@app/@types/scenes/scenes'
import getBinarySensorStateDescription from './getBinarySensorStateDescription'
import getMultilevelSensorStateDescription from './getMultilevelSensorStateDescription'
import { createMultilevelSwitchStateDescription } from './createMultilevelSwitchStateDescription'
import convertRoomOrGroupStateFromEditedConditionInfo from './convertRoomOrGroupStateFromEditedConditionInfo'
import convertMezzoLightStateFromEditedConditionInfo from './convertMezzoLightStateFromEditedConditionInfo'
import getMezzoConditionStateDescription from './getMezzoConditionStateDescription'
import getWallmoteMultichannelBinarySwitchStateDescription from './getWallmoteMultichannelBinarySwitchStateDescription'
import getMultichannelBinarySwitchStateDescription from './getMultichannelBinarySwitchStateDescription'
import convertTimeDataFromBackend from './convertTimeDataFromBackend'
import convertZwaveChangedDescription from './convertZwaveChangedDescription'

const getEditedConditionInfo = (condition: IConditionFromBackend): IConditionFront => {
  const conditionInfo = {
    id: 0,
    conditionNumber: 0,
    description: 'none',
    type: '',
    deviceType: '',
    conditionDeviceNumber: 0,
    conditionDeviceName: '',
    stateDescription: '',
    deviceStateInfo: '',
    conditionDeviceId: 0,
    subDevicesAmount: 0,
    anotherSceneNumber: 0,
    stateTimeData: '',
    timeOperand: '',
    switch_type: '',
    conditionZWaveCapabilityId: 0,
    geofenceNumber: 0,
    geofenceExit: false,
    geofenceName: '',
    usersInfo: {},
    roomInfo: {
      roomNumber: 0,
      roomValue: 0,
    },
    groupInfo: {
      groupNumber: 0,
      groupValue: 0,
    },
    dataNumber: 0,
    dataValue: 0,
    dataOperand: '',
    triggeringDevice: {
      deviceType: 0,
      deviceNumber: 0,
      deviceName: '',
      deviceState: -1,
    },
    observingDevice: {
      deviceType: 0,
      deviceNumber: 0,
      deviceName: '',
    },
    sensorType: 0,
    sensorTypeDescription: '',
    sensorEventType: 0,
    sensorEventDescription: '',
    wallmoteSettings: [],
    sensorValueDescription: '',
    sensorScales: [],
    operand: '',
  }
  conditionInfo.id = condition.id
  conditionInfo.conditionNumber = condition.number
  conditionInfo.operand = condition.operand
  switch (condition.type) {
  case 'Device':
    if (condition.data.type === 1) {
      conditionInfo.type = 'TwrDevices'
      conditionInfo.stateDescription = condition.value_title
      conditionInfo.description = condition.description
    } else if (condition.data.type === 2) {
      conditionInfo.type = 'ZWaveDevices'
      conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
      conditionInfo.switch_type = condition.device.switch_type
      switch (conditionInfo.switch_type) {
      case 'switch_binary':
        conditionInfo.stateDescription = condition.value_title
        conditionInfo.description = getSpecialPrefix(condition.value_title)
        break
      case 'switch_multilevel':
        conditionInfo.stateDescription = condition.data.value === 0 || condition.data.value === 1
          ? condition.value_title : createMultilevelSwitchStateDescription(condition.data.value)
        conditionInfo.description = 'If'
        break
      case 'door_lock':
        conditionInfo.stateDescription = condition.value_title
        conditionInfo.description = condition.description
        break
      default:
        throw new Error('condition unsupported')
      }
    } else {
      throw new Error('condition unsupported')
    }
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.deviceStateInfo = { type: condition.type, data: condition.data }
    conditionInfo.conditionDeviceId = condition.device.id
    return conditionInfo
  case 'AuxButton':
    conditionInfo.type = 'TwrDevices'
    conditionInfo.description = condition.description
    conditionInfo.stateDescription = condition.value_title
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.deviceStateInfo = { type: condition.type, data: condition.data }
    conditionInfo.conditionDeviceId = condition.device.id
    return conditionInfo
  case 'ReedSwitchAtLevel':
    conditionInfo.type = 'TwrDevices'
    conditionInfo.description = getSpecialPrefix(condition.value_title)
    conditionInfo.stateDescription = condition.value_title
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.deviceStateInfo = { type: condition.type, data: condition.data }
    conditionInfo.conditionDeviceId = condition.device.id
    return conditionInfo
  case 'ZwaveChanged':
    conditionInfo.type = 'ZWaveDevices'
    conditionInfo.description = condition.description
    conditionInfo.stateDescription = convertZwaveChangedDescription(condition.value_title)
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.deviceStateInfo = { type: condition.type, data: condition.data }
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    return conditionInfo
  case 'UserTrigger':
    conditionInfo.type = 'UserTrigger'
    conditionInfo.description = 'user_trigger_description'
    return conditionInfo
  case 'ZwaveBinarySensor':
  case 'ZwaveV1BinarySensor':
    conditionInfo.type = 'ZWaveDevices'
    conditionInfo.description = condition.description
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name

    conditionInfo.deviceStateInfo = { type: condition.type, data: condition.data }
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    if (condition.sensor.sensor_type !== null) {
      conditionInfo.stateDescription = getBinarySensorStateDescription(condition.sensor.title, condition.value_title)
      conditionInfo.sensorTypeDescription = condition.sensor.title
      conditionInfo.sensorValueDescription = condition.value_title
    } else {
      conditionInfo.stateDescription = `when ${condition.value_title}`
    }

    return conditionInfo
  case 'Sunset':
    conditionInfo.type = 'Sunset'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_when'
    return conditionInfo
  case 'Sunrise':
    conditionInfo.type = 'Sunrise'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_when'
    return conditionInfo
  case 'Light':
    conditionInfo.type = 'Light'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_when'
    return conditionInfo
  case 'Dark':
    conditionInfo.type = 'Dark'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_when'
    return conditionInfo
  case 'Time':
    conditionInfo.type = 'Time'
    conditionInfo.stateDescription = condition.value_title
    conditionInfo.description = 'time_condition_state_time'
    conditionInfo.stateTimeData = condition.data.time
    conditionInfo.timeOperand = condition.data.operand
    return conditionInfo
  case 'TimeLimits':
    conditionInfo.type = 'TimeLimits'
    conditionInfo.stateDescription = `${condition.value_title[0]} to ${condition.value_title[1]}`
    conditionInfo.description = 'time_condition_state_time'
    conditionInfo.stateTimeData = {
      from: convertTimeDataFromBackend(condition.data.from),
      to: convertTimeDataFromBackend(condition.data.to),
    }
    return conditionInfo
  case 'AnotherScene':
    conditionInfo.type = 'AnotherScene'
    conditionInfo.anotherSceneNumber = condition.data.number
    conditionInfo.description = condition.description
    return conditionInfo
  case 'IsInAlarm':
    conditionInfo.type = 'IsInAlarm'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_when_alarm'
    return conditionInfo
  case 'Panic':
    conditionInfo.type = 'Panic'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_when_panic'
    return conditionInfo
  case 'Duress':
    conditionInfo.type = 'Duress'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_when_duress'
    return conditionInfo
  case 'IfArmed':
    conditionInfo.type = 'IfArmed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_arm_system'
    return conditionInfo
  case 'IfArmedHome':
    conditionInfo.type = 'IfArmedHome'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_arm_home_mode'
    return conditionInfo
  case 'IfDisarmed':
    conditionInfo.type = 'IfDisarmed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_disarm'
    return conditionInfo
  case 'WhenFire':
    conditionInfo.type = 'WhenFire'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_fire_alarm'
    return conditionInfo
  case 'WhenMedicalEvent':
    conditionInfo.type = 'WhenMedicalEvent'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_health_alarm'
    return conditionInfo
  case 'Chime':
    conditionInfo.type = 'Chime'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_sound_chime'
    return conditionInfo
  case 'IfMainsHasFailed':
    conditionInfo.type = 'IfMainsHasFailed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_mains_power_missing'
    return conditionInfo
  case 'WhenMainsFail':
    conditionInfo.type = 'WhenMainsFail'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_when_mains_power_fails'
    return conditionInfo
  case 'IfMainsPowerIsPresent':
    conditionInfo.type = 'IfMainsPowerIsPresent'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_mains_power_present'
    return conditionInfo
  case 'WhenMainsRestores':
    conditionInfo.type = 'WhenMainsRestores'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_when_mains_power_restores'
    return conditionInfo
  case 'NotArmed':
    conditionInfo.type = 'NotArmed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_not_armed'
    return conditionInfo
  case 'NotDisarmed':
    conditionInfo.type = 'NotDisarmed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_not_disarmed'
    return conditionInfo
  case 'WhenArmed':
    conditionInfo.type = 'WhenArmed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_has_been_armed'
    return conditionInfo
  case 'WhenDisarmed':
    conditionInfo.type = 'WhenDisarmed'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_has_been_disarmed'
    return conditionInfo
  case 'WhenMezzoSetToHomeMode':
    conditionInfo.type = 'WhenMezzoSetToHomeMode'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_has_been_home_mode'
    return conditionInfo
  case 'WhileInMedicalAlarm':
    conditionInfo.type = 'WhileInMedicalAlarm'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_while_in_medical_alarm'
    return conditionInfo
  case 'Siren':
    conditionInfo.type = 'Siren'
    conditionInfo.description = 'my Device'
    conditionInfo.stateDescription = 'ui_sounding_siren'
    return conditionInfo
  case 'DayOfTheWeek':
    conditionInfo.type = 'DayOfTheWeek'
    if (condition.description === "it's full week") {
      conditionInfo.stateDescription = condition.description
    } else {
      conditionInfo.stateDescription = condition.value_title
    }
    conditionInfo.description = 'time_condition_state_day'
    conditionInfo.stateTimeData = condition.data.days
    return conditionInfo
  case 'Weekdays':
    conditionInfo.type = 'Weekdays'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_day'
    return conditionInfo
  case 'Weekends':
    conditionInfo.type = 'Weekends'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_day'
    return conditionInfo
  case 'Date':
    conditionInfo.type = 'Date'
    conditionInfo.stateDescription = condition.description
    conditionInfo.description = 'time_condition_state_date'
    conditionInfo.stateTimeData = condition.data.time
    conditionInfo.timeOperand = condition.data.operand
    return conditionInfo
  case 'BetweenDatesSimple':
    conditionInfo.type = 'BetweenDatesSimple'
    conditionInfo.stateDescription = `${condition.value_title[0]} to ${condition.value_title[1]}`
    conditionInfo.description = 'time_condition_state_date'
    conditionInfo.stateTimeData = condition.data
    return conditionInfo
  case 'ZwaveColor':
    conditionInfo.description = condition.description
    conditionInfo.type = 'ZwaveColor'
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.stateDescription = condition.value_title
    conditionInfo.deviceStateInfo = condition.data
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    return conditionInfo
  case 'Geofencing':
    conditionInfo.type = 'Geofencing'
    conditionInfo.geofenceNumber = condition.data.geofence_id
    conditionInfo.geofenceExit = condition.data.is_exit
    conditionInfo.usersInfo = { usersNumbers: condition.value_title.user_numbers, usersNames: condition.value_title.user_names }
    conditionInfo.description = condition.value_title.geofence_name
    conditionInfo.stateDescription = condition.value_title.direction
    return conditionInfo
  case 'RoomActivity':
    conditionInfo.type = 'RoomActivity'
    conditionInfo.description = condition.value_title
    conditionInfo.stateDescription = convertRoomOrGroupStateFromEditedConditionInfo(condition.data.value)
    conditionInfo.roomInfo.roomNumber = condition.data.number
    conditionInfo.roomInfo.roomValue = condition.data.value
    return conditionInfo
  case 'GroupActivity':
    conditionInfo.type = 'GroupActivity'
    conditionInfo.description = condition.value_title
    conditionInfo.stateDescription = convertRoomOrGroupStateFromEditedConditionInfo(condition.data.value)
    conditionInfo.groupInfo.groupNumber = condition.data.number
    conditionInfo.groupInfo.groupValue = condition.data.value
    return conditionInfo
  case 'ZwaveSensorMultilevel':
    conditionInfo.type = 'ZwaveSensorMultilevel'
    conditionInfo.description = condition.description
    conditionInfo.stateDescription = getMultilevelSensorStateDescription(condition.sensor.title, condition.value_title)
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.sensorTypeDescription = condition.sensor.title
    conditionInfo.sensorValueDescription = condition.value_title
    conditionInfo.deviceStateInfo = condition.data
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    return conditionInfo
  case 'HubLight':
    conditionInfo.type = 'HubLight'
    conditionInfo.description = 'Device light'
    conditionInfo.stateDescription = convertMezzoLightStateFromEditedConditionInfo(condition.data.number)
    conditionInfo.dataValue = condition.data.number
    return conditionInfo
  case 'IfPanicDuressFire':
    conditionInfo.type = 'IfPanicDuressFire'
    conditionInfo.description = 'my Device'
    conditionInfo.dataNumber = condition.data.number
    conditionInfo.dataValue = condition.data.value
    conditionInfo.stateDescription = getMezzoConditionStateDescription(condition.data.number, condition.data.value)
    return conditionInfo
  case 'ZwaveSceneController':
    conditionInfo.type = 'ZwaveSceneController'
    conditionInfo.description = `When ${condition.device.name}`
    conditionInfo.stateDescription = getWallmoteMultichannelBinarySwitchStateDescription(condition.data.value)
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    conditionInfo.subDevicesAmount = condition.device.wallmote_buttons
    conditionInfo.dataNumber = condition.data.channel
    conditionInfo.dataValue = condition.data.value
    conditionInfo.wallmoteSettings = condition.device.button_settings
    return conditionInfo
  case 'ZwaveMultichannel':
    conditionInfo.type = 'ZwaveMultichannel'
    conditionInfo.description = `If ${condition.device.name}`
    conditionInfo.stateDescription = getMultichannelBinarySwitchStateDescription(condition.data.operand)
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    conditionInfo.subDevicesAmount = condition.device.sub_device_amount
    conditionInfo.dataNumber = condition.data.channel
    conditionInfo.dataValue = condition.data.value
    conditionInfo.dataOperand = condition.data.operand
    return conditionInfo
  case 'TimerExpired':
    conditionInfo.type = 'TimerExpired'
    conditionInfo.description = condition.data.number
    conditionInfo.stateDescription = 'expired'
    conditionInfo.deviceStateInfo = { number: condition.data.number }
    return conditionInfo
  case 'DeviceTimeout':
    conditionInfo.type = 'DeviceTimeout'
    conditionInfo.description = condition.data.timer_number.toString()
    conditionInfo.stateDescription = `exceeded Timeout value, while observing ${condition.observing_device.name}`
    conditionInfo.triggeringDevice.deviceType = condition.data.triggering_device.type
    conditionInfo.triggeringDevice.deviceNumber = condition.data.triggering_device.number
    conditionInfo.triggeringDevice.deviceState = condition.data.triggering_device.state
    conditionInfo.triggeringDevice.deviceName = condition.triggering_device.name
    conditionInfo.observingDevice.deviceName = condition.observing_device.name
    conditionInfo.observingDevice.deviceNumber = condition.data.observing_device.number
    conditionInfo.observingDevice.deviceType = condition.data.observing_device.type

    return conditionInfo
  case 'KeyfobPanicIndependantSwitch':
    conditionInfo.type = 'TwrDevices'
    conditionInfo.description = condition.description
    conditionInfo.stateDescription = condition.value_title
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.deviceStateInfo = { type: condition.type, data: condition.data }
    conditionInfo.conditionDeviceId = condition.device.id
    return conditionInfo
  case 'VirtualSwitch':
    conditionInfo.type = 'VirtualSwitch'
    conditionInfo.description = condition.data.number.toString()
    conditionInfo.stateDescription = condition.data.value === 1 ? 'is_on' : 'is_off'
    conditionInfo.conditionDeviceNumber = condition.data.number
    conditionInfo.deviceStateInfo = condition.data
    return conditionInfo
  case 'SunWithOffset':
    conditionInfo.type = 'SunWithOffset'
    conditionInfo.description = 'time_condition_state_when'
    if (!condition.data.minutes) {
      conditionInfo.dataNumber = 0
    } else {
      conditionInfo.dataNumber = condition.data.offset_direction > 0 ? condition.data.minutes : (-condition.data.minutes)
    }
    conditionInfo.stateDescription = condition.data.sun_time === 1 ? 'sunrise' : 'sunset'
    return conditionInfo
  case 'ZwaveSensorNotification':
    conditionInfo.description = condition.description
    conditionInfo.type = 'ZwaveSensorNotification'
    conditionInfo.conditionDeviceNumber = condition.device.number
    conditionInfo.stateDescription = `${condition.value_title.notification_name} is ${condition.value_title.event_name}`
    conditionInfo.conditionDeviceName = condition.device.name
    conditionInfo.conditionDeviceId = condition.device.id
    conditionInfo.sensorType = condition.data.notification_type
    conditionInfo.sensorTypeDescription = condition.value_title.notification_name
    conditionInfo.sensorEventType = condition.data.event_type
    conditionInfo.sensorEventDescription = condition.value_title.event_name
    conditionInfo.deviceStateInfo = {
      number: condition.device.number,
      notification_type: condition.data.notification_type,
      event_type: condition.data.event_type,
    }
    conditionInfo.conditionZWaveCapabilityId = condition.device.capability_id
    return conditionInfo
  default:
    throw new Error('condition unsupported')
  }
}

export default getEditedConditionInfo
