import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_CURRENT_ECO_MODE_DEVICE } from '@app/constants/myEnergy/ecoMode'
import { clearCurrentEcoModeDeviceId, clearCurrentEcoModeDeviceStatus, sync } from '@app/actions/myEnergy/ecoMode'
import Api from '@app/api/myEnergy/patchEcoModeDevice'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchEcoModeDeviceLogic = createLogic({
  type: PATCH_CURRENT_ECO_MODE_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { ecoMode: { currentEcoModeDevice: { id, eco_mode } } } = getState()
    const data = { eco_mode: !eco_mode }
    next({
      ...action,
      token,
      id,
      data,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_CURRENT_ECO_MODE_DEVICE))
    Api.patchEcoModeDevice(action.token, action.id, action.data)
      .then(() => {
        dispatch(apiRequestDone(PATCH_CURRENT_ECO_MODE_DEVICE))
        dispatch(clearCurrentEcoModeDeviceId())
        dispatch(clearCurrentEcoModeDeviceStatus())
        dispatch(sync())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchEcoModeDevice failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchEcoModeDeviceLogic
