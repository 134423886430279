import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6862 39.9403C41.9802 31.7739 63.2124 23.6076 84.5064 15.4413C83.704 13.3379 82.9017 11.1726 82.0993 9.0691C80.8648 5.7902 77.2233 4.18169 73.952 5.41901C54.6331 12.8429 35.3142 20.2669 16.0571 27.6908C12.7858 28.9281 11.181 32.5782 12.4155 35.8571C12.4772 35.9808 12.5389 36.1664 12.6006 36.2902C13.7734 39.5072 17.4149 41.1776 20.6862 39.9403V39.9403Z" stroke="currentColor" strokeWidth="2.7057"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M72.4089 5.97439C72.9027 5.78879 73.3965 5.60319 73.8902 5.4176C74.9395 5.0464 75.8653 4.92267 76.9763 5.0464C74.6309 10.3669 72.2237 15.6874 69.8783 21.0697C68.2118 21.6884 66.5453 22.3689 64.8171 22.9876C67.3477 17.2959 69.8783 11.6042 72.4089 5.97439Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M82.7781 10.8005C82.5312 10.1818 82.346 9.62501 82.0992 9.00635C81.7288 8.07835 81.2351 7.27409 80.4944 6.59357C78.6427 10.7386 76.7911 14.8836 74.9395 19.0287C76.6059 18.41 78.2724 17.7295 80.0006 17.1108C80.8647 15.0074 81.7905 12.9039 82.7781 10.8005Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6868 39.6919C16.9829 40.3106 18.2174 40.4962 19.637 40.1868C22.1058 34.557 24.6364 28.9272 27.1053 23.2974C25.4388 23.9161 23.7723 24.5966 22.0441 25.2152C19.9456 30.1027 17.7853 34.9282 15.6868 39.6919Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M62.3484 9.81079L67.3478 7.89294L59.8178 24.9061L54.7566 26.824L62.3484 9.81079Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.2877 13.7082L57.2872 11.7285L49.7571 28.7416L44.6959 30.6595L52.2877 13.7082Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.227 17.5434L47.2265 15.6255L39.6964 32.6387L34.6353 34.5565L42.227 17.5434Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.1664 21.4413L37.1658 19.4615L29.6358 36.4747L24.5746 38.3925L32.1664 21.4413Z" fill="currentColor"/>
      <path d="M13.5881 56.5188H88.2714" stroke="currentColor" strokeWidth="1.0408"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.9455 43.342C42.7208 43.342 65.4961 43.342 88.2714 43.342C88.2714 58.4374 88.2714 73.5945 88.2714 88.6899C88.2714 92.2162 85.4322 95.0002 81.9758 95.0002C61.299 95.0002 40.6223 95.0002 19.9455 95.0002C16.4274 95.0002 13.6499 92.1544 13.6499 88.6899C13.6499 75.698 13.6499 62.6443 13.6499 49.6524C13.5882 46.1879 16.4274 43.342 19.9455 43.342V43.342Z" stroke="currentColor" strokeWidth="1.5"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.0062 56.519C28.2163 56.519 26.4263 56.519 24.6364 56.519C21.2417 53.1163 17.9087 49.7756 14.514 46.3729C15.2547 45.1975 16.1188 44.3932 17.415 43.8364C21.612 48.1052 25.8091 52.3121 30.0062 56.519Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M88.2715 50.0854V55.4678L76.1741 43.342H81.5439L88.2715 50.0854Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M78.5194 56.5195H83.8892L70.8042 43.342H65.3727L78.5194 56.5195Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M67.7182 56.5195H73.1497L60.003 43.342H54.6332L67.7182 56.5195Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M56.9785 56.5195H62.3483L49.2016 43.342H43.8318L56.9785 56.5195Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M46.1771 56.5195H51.5469L38.4619 43.342H33.0304L46.1771 56.5195Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.3759 56.5195H40.8074L27.6607 43.342H22.2909L35.3759 56.5195Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'AnotherSceneMenuIcon', '0 0 80 80')

export default icon
