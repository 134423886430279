import * as type from '@app/constants/timers/timers'

import { ITimer, ITimerData } from '@app/@types/timers/timersTypes'

export const syncTimers = () => ({
  type: type.SYNC_TIMERS,
} as const)

export const setTimers = (timers: Array<ITimer>) => ({
  type: type.SET_TIMERS,
  timers,
} as const)

export const saveTimerData = (timerData: ITimerData) => ({
  type: type.SAVE_TIMER_DATA,
  timerData,
} as const)

export const putTimer = () => ({
  type: type.PUT_TIMER,
} as const)

export const resetTimer = () => ({
  type: type.RESET_TIMER,
} as const)

export const openChannel = () => ({
  type: type.OPEN_TIMER_CHANNEL,
} as const)

export const closeChannel = () => ({
  type: type.CLOSE_TIMER_CHANNEL,
} as const)

export const syncTimerChannel = () => ({
  type: type.SYNC_TIMER_CHANNEL,
} as const)

export const syncTimerChannelSent = () => ({
  type: type.SYNC_TIMER_CHANNEL_SENT,
} as const)

export const unsubscribeTimerChannel = () => ({
  type: type.UNSUBSCRIBE_TIMER_CHANNEL,
} as const)

export const resetAllTimers = () => ({
  type: type.RESET_ALL_TIMERS,
} as const)

export const setCurrentTrigger = (trigger: string) => ({
  type: type.SET_CURRENT_TRIGGER,
  trigger,
} as const)

export const timerTrigger = () => ({
  type: type.TIMER_TRIGGER,
} as const)

export const updateTimer = (timer: ITimer) => ({
  type: type.UPDATE_TIMER,
  timer,
} as const)

export const resetTimersReducer = () => ({
  type: type.RESET_TIMERS_REDUCER,
} as const)
