import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import IconButton from '@mui/material/IconButton'
import { Paper, Button } from '@mui/material'
import { SortableContainer } from 'react-sortable-hoc'
import { reorderArray } from '@app/helpers/myDevices/reorderArray'
import {
  clearCurrentDevice, setMyDevices, sortDevices, syncMyDevices,
} from '@app/actions/myDevices/myDevices'
import { syncDevicesChannel } from '@app/actions/myDevices/channels/devices/devicesChannel'
import { closeMezzoLightsChannel, syncMezzoLightsChannel } from '@app/actions/myDevices/channels/devices/mezzoLightsChannel'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CustomBackdrop from '@app/components/Common/CustomBackdrop/CustomBackdrop'
import { HandSort, ReloadCircle } from 'icons'
import AddButtons from '@app/components/MyDevices/AddDevice/AddButtons/AddButtons'
import { TDevice } from '@app/@types/myDevices'
import { syncDeviceInfoChannel } from '@app/actions/myDevices/deviceInfo/deviceInfoChannel'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
import useStyles from './MyDevices.style'
import MyDevicesList from './DevicesList/DevicesList'

interface Props {
  devices: Array<TDevice>,
  mezzoLights: number,
  guardPanelStatus: string,
  isMobile: boolean,
  inProgress: boolean,
  isMezzoLightsLoading: boolean,
}

const MyDevices: React.FC<Props> = (props) => {
  const {
    devices, guardPanelStatus, mezzoLights, isMobile, inProgress, isMezzoLightsLoading,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [isChoosingWhichDeviceToAdd, setIsChoosingWhichDeviceToAdd] = useState<boolean>(false)
  const DevicesList = isDragging ? SortableContainer(MyDevicesList) : MyDevicesList

  const { devices: devicesConfig } = useSelector((state: AppState) => state.appConfig.config)
  const fabState = devicesConfig?.fab?.state ?? defaultConfig?.devices?.fab?.state
  const showFab = fabState === States.Visible
  const mezzoLightsState = devicesConfig?.mezzo_lights?.state ?? defaultConfig?.devices?.mezzo_lights?.state
  const showMezzoLights = mezzoLightsState === States.Visible

  useEffect(() => {
    dispatch(syncMyDevices())
    dispatch(syncDevicesChannel())
    dispatch(syncDeviceInfoChannel())
    dispatch(clearCurrentDevice())
    dispatch(syncMezzoLightsChannel())
    return () => {
      dispatch(closeMezzoLightsChannel())
    }
  }, [])

  const toggleAddButtons = () => {
    setIsChoosingWhichDeviceToAdd(!isChoosingWhichDeviceToAdd)
  }

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    dispatch(setMyDevices(reorderArray(devices, oldIndex, newIndex)))
  }

  const onSortSubmit = () => {
    setIsDragging(false)
    dispatch(sortDevices())
  }

  return (
    <Paper className={classes.root}>
      {isDragging ? (
        <Button
          className={classes.submitDragButton}
          color="primary"
          onClick={onSortSubmit}
        >
          {I18n.t('buttons.submit')}
        </Button>
      ) : (
        <>
          <IconButton
            size="small"
            aria-label="Sort"
            className={classNames(classes.sortButton)}
            onClick={() => setIsDragging(true)}
          >
            <HandSort />
          </IconButton>
          <IconButton
            size="small"
            aria-label="Refresh"
            className={classNames(classes.refreshButton)}
            onClick={() => {
              dispatch(syncMyDevices())
            }}
          >
            <ReloadCircle />
          </IconButton>
        </>
      )}
      <Wrapper>
        <DevicesList
          onSortEnd={onSortEnd}
          axis={isMobile ? 'y' : 'xy'}
          isDragging={isDragging}
          devices={devices}
          mezzoLights={mezzoLights}
          isMobile={isMobile}
          inProgress={inProgress}
          isMezzoLightsLoading={isMezzoLightsLoading}
          showMezzoLights={showMezzoLights}
        />
      </Wrapper>
      {isChoosingWhichDeviceToAdd && <CustomBackdrop backdropClass={classes.backdrop} clicked={toggleAddButtons} />}
      {!isDragging && showFab && (
        <AddButtons
          isChoosingWhichDeviceToAdd={isChoosingWhichDeviceToAdd}
          toggleChooseDevice={toggleAddButtons}
          guardPanelStatus={guardPanelStatus}
          isMobile={isMobile}
        />
      )}
    </Paper>
  )
}

export default MyDevices
