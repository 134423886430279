import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
    color: theme.texts.main,
  },
  grid: {
    display: 'grid',
    justifyItems: 'center',
    width: '100%',
    maxWidth: 720,
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridGap: 20,
    margin: '0 auto',
  },
  gridElement: {
    width: '100%',
    maxWidth: 350,
    display: 'flex',
    height: 102,
    backgroundColor: theme.backgrounds.cardBackground,
    borderRadius: 6,
    padding: '0 15px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontSize: '12px',
    lineHeight: '14px',
    marginBottom: 5,
  },
  status: {
    fontSize: '16px',
    lineHeight: '21px',
    padding: '5px 0',
  },
  timestamp: {
    fontSize: '14px',
    color: theme.texts.subText,
  },
  colorChannelsStatus: {
    width: 25,
    height: 25,
    border: `1px solid ${theme.borders.main}`,
    borderRadius: '50%',
  },
  iconWrapper: {
    height: '100%',
    paddingRight: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    maxWidth: 400,
    margin: '0 auto',
  },
}))

export default useStyles
