import * as type from '@app/constants/users/secretCode'

export const openPopup = () => ({
  type: type.OPEN_SECRET_CODE_POPUP,
})
export const closePopup = () => ({
  type: type.CLOSE_SECRET_CODE_POPUP,
})

export const set = () => ({
  type: type.SET_SECRET_CODE,
})

export const openWarningChangeEmailPopup = () => ({
  type: type.OPEN_WARNING_CHANGE_EMAIL_POPUP,
})
export const closeWarningChangeEmailPopup = () => ({
  type: type.CLOSE_WARNING_CHANGE_EMAIL_POPUP,
})

export const openChangePinPopup = () => ({
  type: type.OPEN_CHANGE_PIN_POPUP,
})

export const closeChangePinPopup = () => ({
  type: type.CLOSE_CHANGE_PIN_POPUP,
})

export const getPinCode = () => ({
  type: type.GET_PIN_CODE,
})

export const setPinCode = () => ({
  type: type.SET_PIN_CODE,
})

export const savePinCode = (pin) => ({
  type: type.SAVE_PIN_CODE,
  pin,
})

export const openShowPinPopup = () => ({
  type: type.OPEN_SHOW_PIN_POPUP,
})

export const closeShowPinPopup = () => ({
  type: type.CLOSE_SHOW_PIN_POPUP,
})
