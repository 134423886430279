import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  syncButton: {
    margin: '0 15px',
    width: '90%',
    color: theme.scenes.textColor,
  },
}))

export default useStyles
