const styles = (theme) => ({
  root: {
    width: '100%',
  },
  tabs: {
    background: 'transparent',
    color: theme.scenes.textColor,
  },
})

export default styles
