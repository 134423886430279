import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M108.868 91.172C108.868 92.5544 109.943 93.7831 111.326 93.7831L139.356 94.4743H139.433C140.815 94.4743 141.967 93.3991 141.967 92.0168C141.967 90.6345 140.892 89.4057 139.51 89.4057L111.479 88.7146C110.02 88.6378 108.868 89.7897 108.868 91.172Z" fill="currentColor"/>
      <path d="M139.817 105.074L110.557 100.467C109.175 100.236 107.869 101.235 107.639 102.617C107.409 103.999 108.407 105.305 109.789 105.535L139.049 110.143C139.202 110.143 139.279 110.143 139.433 110.143C140.661 110.143 141.737 109.221 141.967 107.993C142.197 106.61 141.199 105.305 139.817 105.074Z" fill="currentColor"/>
      <path d="M11.5674 94.4743C11.5674 94.4743 11.6442 94.4743 11.5674 94.4743L39.6748 93.7831C41.0571 93.7831 42.209 92.5544 42.1322 91.172C42.1322 89.7897 40.9803 88.6378 39.5212 88.7146L11.4906 89.4057C10.1083 89.4057 8.95638 90.6345 9.03317 92.0168C9.03317 93.3991 10.1851 94.4743 11.5674 94.4743Z" fill="currentColor"/>
      <path d="M43.3609 102.617C43.1305 101.235 41.825 100.236 40.4427 100.467L11.1834 105.074C9.80109 105.305 8.80274 106.61 9.03313 107.993C9.26352 109.221 10.3387 110.143 11.5674 110.143C11.721 110.143 11.7978 110.143 11.9514 110.143L41.2106 105.535C42.6698 105.305 43.5913 103.999 43.3609 102.617Z" fill="currentColor"/>
      <path d="M25.6977 84.413C27.464 84.413 28.9232 82.9539 28.9232 81.1876C28.9232 79.1141 31.3806 49.6245 33.3005 27.584L53.6515 39.1802C51.6548 40.1785 49.6581 41.3305 47.7382 42.636C46.2791 43.6344 45.8951 45.6311 46.8934 47.0902C47.5078 48.0117 48.5061 48.4725 49.5045 48.4725C50.1189 48.4725 50.7332 48.3189 51.2708 47.9349C54.4962 45.7847 57.9521 44.0183 61.5615 42.7896C61.7919 42.7128 61.9454 42.7128 62.1758 42.5592C66.7068 41.0233 71.4682 40.2553 76.3063 40.2553C80.6069 40.2553 84.9075 40.8697 88.9776 42.0984C89.592 42.636 90.36 42.8664 91.2047 42.7896C94.3534 43.9416 97.4252 45.4007 100.343 47.2438C101.803 48.1653 103.799 47.7814 104.721 46.2454C105.642 44.7863 105.258 42.7896 103.722 41.8681C102.033 40.7929 100.267 39.7946 98.5003 38.9498L118.544 27.5072C120.464 46.4758 123.459 76.7334 123.459 81.1876C123.459 82.9539 124.918 84.413 126.684 84.413C128.451 84.413 129.91 82.9539 129.91 81.1876C129.91 74.8135 124.688 24.0514 124.457 21.9011C124.381 20.826 123.689 19.8276 122.691 19.3668C121.693 18.8293 120.541 18.9061 119.619 19.4436L90.7439 35.9548C86.0594 34.5724 81.1444 33.8045 76.2295 33.8045C71.161 33.8045 66.0924 34.5724 61.2543 36.1084L32.0718 19.4436C31.1503 18.9061 29.9215 18.9061 28.9232 19.3668C27.9248 19.9044 27.3105 20.826 27.2337 21.9779C26.7729 27.7376 22.3955 78.039 22.3955 81.1876C22.3955 82.9539 23.8546 84.413 25.6977 84.413Z" fill="currentColor"/>
      <path d="M113.706 109.524C105.182 119.508 91.8191 125.037 76.2295 125.037C60.6399 125.037 47.2774 119.508 38.7531 109.524C37.6011 108.142 35.5276 107.988 34.2221 109.14C32.8398 110.292 32.6862 112.366 33.8381 113.671C43.668 125.114 58.72 131.488 76.3063 131.488C93.8926 131.488 108.945 125.19 118.775 113.671C119.926 112.289 119.773 110.292 118.391 109.14C116.931 107.988 114.858 108.142 113.706 109.524Z" fill="currentColor"/>
      <path d="M56.2627 108.759C56.3395 108.836 60.1793 111.217 65.0943 111.217C68.5501 111.217 72.4667 110.065 76.0761 106.148C79.6855 110.065 83.6021 111.217 87.0579 111.217C91.9729 111.217 95.8127 108.836 95.8895 108.759C97.0414 107.991 97.4254 106.378 96.6574 105.227C95.8895 104.075 94.2767 103.691 93.1248 104.459C92.8176 104.689 85.4452 109.297 79.3783 102.155C81.2982 100.772 83.2949 98.7757 83.2949 96.4718C83.2949 92.0176 79.9159 92.3248 75.7689 92.3248C71.6219 92.3248 68.2429 92.0176 68.2429 96.4718C68.2429 98.9293 70.47 101.003 72.5435 102.385C66.707 108.99 59.8721 104.996 59.0274 104.459C57.8754 103.691 56.2627 104.075 55.4948 105.227C54.7268 106.378 55.1108 107.991 56.2627 108.759Z" fill="currentColor"/>
      <path d="M61.101 82.4163C64.494 82.4163 67.2446 79.6656 67.2446 76.2726C67.2446 72.8795 64.494 70.1289 61.101 70.1289C57.7079 70.1289 54.9573 72.8795 54.9573 76.2726C54.9573 79.6656 57.7079 82.4163 61.101 82.4163Z" fill="currentColor"/>
      <path d="M91.8192 82.4163C95.2123 82.4163 97.9629 79.6656 97.9629 76.2726C97.9629 72.8795 95.2123 70.1289 91.8192 70.1289C88.4262 70.1289 85.6755 72.8795 85.6755 76.2726C85.6755 79.6656 88.4262 82.4163 91.8192 82.4163Z" fill="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Cat')

export default icon
