import React from 'react'
import { Typography, Paper } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import useStyles from './InfoScreen.style'

interface Props {
  isMobile: boolean,
  onBackClick: () => void,
}

const InfoScreen: React.FC<Props> = (props) => {
  const { isMobile, onBackClick } = props
  const classes = useStyles()

  return (
    <Paper className={classes.wrapper}>
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.main}>
        <Typography className={classes.title}>{I18n.t('scene_web.info_title_first').toUpperCase()}</Typography>
        <Typography className={classes.description}>{I18n.t('scene_web.info_description_first')}</Typography>
        <Typography className={classes.title}>{I18n.t('scene_web.info_title_second').toUpperCase()}</Typography>
        <Typography className={classes.description}>
          {I18n.t('scene_web.info_description_second')}
        </Typography>
        <Typography className={classes.title}>{I18n.t('scene_web.info_title_third').toUpperCase()}</Typography>
        <Typography className={classes.description}>
          {I18n.t('scene_web.info_description_third')}
        </Typography>
        <Typography className={classes.title}>{I18n.t('scene_web.info_title_forth').toUpperCase()}</Typography>
        <Typography className={classes.description}>
          {`'${I18n.t('scene_web.info_description_forth')}`}
        </Typography>
        <Typography className={classes.title}>{I18n.t('scene_web.info_title_fifth').toUpperCase()}</Typography>
        <Typography className={classes.description}>
          {`'${I18n.t('scene_web.info_description_fifth')}`}
        </Typography>
        <Typography className={classes.title}>{I18n.t('scene_web.info_title_sixth').toUpperCase()}</Typography>
        <Typography className={classes.description}>
          {`'${I18n.t('scene_web.info_description_sixth')}`}
        </Typography>
      </Wrapper>
    </Paper>
  )
}

export default InfoScreen
