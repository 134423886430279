import { update as updateUsers } from '@app/actions/users/memberList'
import { update as updateRequest } from '@app/actions/users/usersRequests'
import { updateEvents } from '@app/actions/events'
import { updateDeviceEnergies } from '@app/actions/myEnergy/deviceManager'
import { updateEcoModeDevices } from '@app/actions/myEnergy/ecoMode'
import { updateEnergies } from '@app/actions/myEnergy/energyTab'
import { updateRoomsStats } from '@app/actions/myEnergy/roomsStats'
import { update as updateScenesList } from '@app/actions/scenes/scenesList'
import { updateBillingCurrentStats } from '@app/actions/myEnergy/billingCurrentStats'
import { apiRequestDone } from '@app/actions/apiRequest'
import convertUser from './convertUser'

const getActionToSaveDataInWebView = (pathname, data, response) => {
  if (pathname === 'sphere_users') {
    const { sphere_users } = data
    const users = sphere_users.map((user) => convertUser(user))
    store.dispatch(updateUsers(users))
    store.dispatch(apiRequestDone('updateUsers'))
  } else if (pathname === 'user_requests') {
    const requests = data.user_requests
    store.dispatch(updateRequest(requests))
    store.dispatch(apiRequestDone('updateRequest'))
  } else if (pathname === 'events') {
    store.dispatch(updateEvents(data.events, data.total, data.pages_count))
    store.dispatch(apiRequestDone('updateEvents'))
  } else if (pathname === 'devices') {
    store.dispatch(updateDeviceEnergies(data.energy_devices, data.sort))
    store.dispatch(apiRequestDone('updateDeviceEnergies'))
  } else if (pathname === 'energy_devices') {
    store.dispatch(updateEcoModeDevices(data.zwave_devices))
    store.dispatch(apiRequestDone('updateEcoModeDevices'))
  } else if (pathname === 'energies') {
    store.dispatch(updateEnergies(data.energies))
    store.dispatch(apiRequestDone('updateEnergies'))
  } else if (pathname === 'rooms') {
    store.dispatch(updateRoomsStats(data.rooms))
    store.dispatch(apiRequestDone('updateRoomsStats'))
  } else if (pathname === 'scenes') {
    store.dispatch(updateScenesList(data.scenes))
    store.dispatch(apiRequestDone('updateScenesList'))
  } else if (pathname === 'current') {
    if (response.status === 204) {
      store.dispatch(apiRequestDone('updateBillingCurrentStats'))
    } else {
      store.dispatch(updateBillingCurrentStats(data))
      store.dispatch(apiRequestDone('updateBillingCurrentStats'))
    }
  } else {
    store.dispatch(apiRequestDone('apiRequestWithoutStoreUpdate'))
  }
}

export default getActionToSaveDataInWebView
