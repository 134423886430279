import getMonth from './getMonth'

const fullYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const sixMonthLastIndex = 5
const convertMonthsToArray = (monthArray) => monthArray.map((month) => [month])

const getMonthsForPeriod = (isoDate, period) => {
  const fullDate = new Date(isoDate)
  const currentMonthIndex = fullYear.findIndex((el) => el === getMonth(fullDate).toString())
  let result = fullYear.filter((el, index) => index <= currentMonthIndex)
  const previousMonths = fullYear.filter((el, index) => index > currentMonthIndex)
  result = [...previousMonths, ...result]

  const sixMonthPeriodArray = convertMonthsToArray(result.filter((el, index) => index > sixMonthLastIndex))
  const yearPeriodArray = convertMonthsToArray(result)

  switch (period) {
  case 'last_6_month_period':
    return sixMonthPeriodArray
  case 'last_year_period':
    return yearPeriodArray
  default:
    return yearPeriodArray
  }
}
export default getMonthsForPeriod
