import React from 'react'
import classNames from 'classnames'
import {
  Avatar, Button, List, ListItem, ListItemText, Paper, Typography,
} from '@mui/material'
import { MEMBER_DETAILS, USER_EDIT } from '@app/constants/routes'
import { useNavigate } from 'react-router-dom'
import { ICurrentUser } from '@app/@types/users'
import useStyles from './MemberList.style'

interface Props {
  users: Array<ICurrentUser>,
  currentUser: ICurrentUser,
  chooseCurrentMember: (user: ICurrentUser) => void,
}

const MemberList: React.FC<Props> = (props) => {
  const {
    users, currentUser, chooseCurrentMember,
  } = props
  const navigate = useNavigate()
  const classes = useStyles()

  const onEdit = (id: number) => {
    navigate(USER_EDIT.replace(':user_id', String(id)))
  }

  const onShow = (user: ICurrentUser) => {
    chooseCurrentMember(user)
    navigate(MEMBER_DETAILS.replace(':member_id', String(user.ids.sphere)))
  }

  const createListItem = (user: ICurrentUser) => {
    if (user.ids.sphere === currentUser.ids.sphere) {
      return (
        <ListItem
          key={user.ids.sphere}
        >
          <Avatar className={classes.avatarIcon} alt={user.preferredName} src={user.avatar} />
          <ListItemText
            primary={`${user.firstName} ${user.lastName}`}
            secondary={`${I18n.t(`my_users.${currentUser.role}`)} (${I18n.t('my_users.you')})`}
          />
          <Button
            className={classes.button}
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => onEdit(user.ids.sphere)}
          >
            {I18n.t('buttons.edit')}
          </Button>
        </ListItem>
      )
    }
    return (
      <ListItem
        key={user.ids.sphere}
      >
        <Avatar className={classes.avatarIcon} alt={user.preferredName} src={user.avatar} />
        <ListItemText
          primary={`${user.firstName} ${user.lastName}`}
          secondary={I18n.t(`my_users.${user.role}`)}
        />
        <Button
          className={classes.button}
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => onShow(user)}
        >
          {I18n.t('buttons.show')}
        </Button>
      </ListItem>
    )
  }

  if (users.length) {
    return (
      <Paper>
        <List className={classNames(
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
            && classes.webView,
        )}
        >
          {users.map((user) => (createListItem(user)))}
        </List>
      </Paper>
    )
  }
  return (
    <Paper className={classNames(
      classes.paper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <Typography align="center" variant="h6">
        {I18n.t('my_users.no_users')}
      </Typography>
    </Paper>
  )
}

export default MemberList
