import * as type from '@app/constants/scenes/groupsScenes/groupsScenes'

export const syncGroupsScenes = () => ({
  type: type.SYNC_GROUPS_SCENES,
})

export const updateGroupsScenes = (groupsScenes) => ({
  type: type.UPDATE_GROUPS_SCENES,
  groupsScenes,
})
