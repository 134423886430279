import { createLogic } from 'redux-logic'
import { GET_CLIENT_CREDENTIALS_TOKEN } from '@app/constants/secrets'
import { saveClientCredentialsToken, getClientCredentialsTokenDone } from '@app/actions/secrets'
import Api from '@app/api/getClientCredentialsToken'
import oAuthApplication from '@app/helpers/oAuthApplication'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import handleErrors from '@app/helpers/handleErrors'

const getClientCredentialTokenLogic = createLogic({
  type: GET_CLIENT_CREDENTIALS_TOKEN,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { clientId, secret } = oAuthApplication()
    dispatch(apiRequestSent(GET_CLIENT_CREDENTIALS_TOKEN))
    Api.getClientCredentialsToken(clientId, secret)
      .then((response) => {
        const { data } = response
        const clientCredentialToken = data.access_token
        dispatch(getClientCredentialsTokenDone())
        dispatch(apiRequestDone(GET_CLIENT_CREDENTIALS_TOKEN))
        dispatch(saveClientCredentialsToken(clientCredentialToken))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('getClientCredentialsToken failed without error')
        }
      })
      .then(() => done())
  },
})

export default getClientCredentialTokenLogic
