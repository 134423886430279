import * as type from '@app/constants/scenes/groupsScenes/groupsScenes'

type State = {
  groupsScenes: Array<{ id: number, number: number, name: string }>,
}
const initialState = {
  groupsScenes: [],
}

export default function groupsScenes(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.RESET_GROUPS_SCENES:
    return initialState

  case type.UPDATE_GROUPS_SCENES: {
    return {
      ...state,
      groupsScenes: action.groupsScenes,
    }
  }
  default:
    return state
  }
}
