import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import {
  Route, Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
// @ts-ignore
import { ThemeProvider } from '@mui/material/styles'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
// @ts-ignore
import themes from '@app/themes'
import getTheme from '@app/helpers/getTheme'
import QueryString from 'query-string'
import { persistor, store } from './store'
// @ts-ignore
import { history } from './store/history'
import { App } from './containers'
// @ts-ignore
import Stats from './components/Stats/Stats'
// @ts-ignore
import Loading from './components/Common/Loading/Loading'

const container = document.body.appendChild(document.createElement('app'))
const root = createRoot(container)

const params = QueryString.parse(window.location.search)
const brand = getTheme(window.location.hostname, params)
const theme = themes[brand]

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate
          loading={<Loading />}
          persistor={persistor}
        >
          <HistoryRouter history={history}>
            <Routes>
              <Route path="/statistic/*" element={<Stats />} />
              <Route path="/*" element={<App />} />
            </Routes>
          </HistoryRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
)
