import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03125 3.46875V0H7.0625V3.46875H9.03125ZM0 4.21875L0.00341079 4.22044C0.0622264 4.24956 0.0775504 4.25715 2.46875 6.28125L3.875 4.875L1.40625 2.76562L0 4.21875ZM15.8125 4.21875L13.625 6.28125L12.2188 4.875L14.4062 2.76562L15.8125 4.21875ZM13.2968 13.1406C12.8906 12.7031 12.4375 12.4844 11.9375 12.4844L11.8906 8.5H9.92184V12.4844H5.89059V8.5H3.92184V12.4844H3.87497C3.88181 12.4878 3.83999 12.5002 3.76621 12.5221C3.50296 12.6002 2.83291 12.7989 2.51559 13.1406C2.10934 13.5469 1.90622 14 1.90622 14.5V19.9844L5.42184 23.5V26.5H10.3906V23.5L13.9062 19.9844V14.5C13.9062 14 13.7031 13.5469 13.2968 13.1406Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'MainsPowerRestoredIcon')

export default icon
