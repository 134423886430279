import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_TWR_SIREN_OPTION } from '@app/constants/myDevices/twrDevices'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { saveTwrCurrentSirenOption } from '@app/actions/myDevices/twrDevices'

const getTwrSirenOption = createLogic({
  type: GET_TWR_SIREN_OPTION,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_TWR_SIREN_OPTION))
    try {
      const sirenOption = await Api.getTwrSirenOption(action.accessToken, action.id)
      dispatch(saveTwrCurrentSirenOption(sirenOption.data.option))
      dispatch(apiRequestDone(GET_TWR_SIREN_OPTION))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('GET_TWR_SIREN_OPTION failed without error')
      }
    }
    done()
  },
})

export default getTwrSirenOption
