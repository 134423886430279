import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, IconButton, Typography,
} from '@mui/material'
import { ReloadCircle, SyncButtonIcon } from 'icons'
import classNames from 'classnames'
import { syncList } from '@app/lib/Synchronize/syncList'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'

class SynchronizeScreen extends React.PureComponent {
  onSyncButtonClick=() => {
    const {
      openSyncWarningPopup,
    } = this.props
    openSyncWarningPopup()
  }

  onRefreshClick=() => {
    const {
      refresh,
    } = this.props
    refresh()
  }

  typesList = () => {
    const {
      countersStatus,
      classes,
    } = this.props
    return (syncList.map((item) => (
      <Card className={classes.card} key={item.title}>
        <Typography variant="subtitle1" component="p" color="textSecondary" gutterBottom>
          {I18n.t(`sync_web.${item.type}_title`)}
        </Typography>
        <Wrapper variant="body2" component="p" className={classes.descriptionWrapper}>
          <Typography className={classes.statusText}>
            {' '}
            {I18n.t('sync_web.status')}
            :
          </Typography>
          {countersStatus[item.type]
            ? (
              <Typography color="error">
                {' '}
                {I18n.t('sync_web.sync_required')}
              </Typography>
            )
            : <Typography color="primary" className={classes.descriptionWrapper}>{I18n.t('sync_web.synchronised')}</Typography>}
        </Wrapper>
      </Card>
    )))
  }

  render() {
    const {
      classes,
      countersStatus,
    } = this.props
    const isSyncButtonDisabled = !(countersStatus.scenes || countersStatus.zwave || countersStatus.general)
    return (
      <Wrapper className={classes.root}>
        <Typography className={classes.title}>{I18n.t('titles.synchronize_all')}</Typography>
        <IconButton
          size="small"
          aria-label="Refresh"
          className={classNames(classes.refreshButton)}
          onClick={this.onRefreshClick}
        >
          <ReloadCircle />
        </IconButton>
        { this.typesList()}
        <Button
          className={classes.syncButton}
          fullWidth
          variant="outlined"
          disabled={isSyncButtonDisabled}
          onClick={this.onSyncButtonClick}
          startIcon={<SyncButtonIcon />}
        >
          {I18n.t('sync_web.sync_button')}
        </Button>
      </Wrapper>
    )
  }
}

SynchronizeScreen.propTypes = {
  countersStatus: PropTypes.shape({
    scenes: PropTypes.bool,
    zwave: PropTypes.bool,
    general: PropTypes.bool,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  openSyncWarningPopup: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

SynchronizeScreen.displayName = 'SynchronizeScreen'

export default SynchronizeScreen
