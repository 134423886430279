import React from 'react'
import { Collapse, List } from '@mui/material'
// @ts-ignore
import elementsData from '@app/lib/Menu/ElementsData'
import { States } from '@app/helpers/convertAppConfig'
import { useDispatch } from 'react-redux'
import { closeAllTabs, closeMenu } from '@app/actions/menu'
import { useNavigate } from 'react-router-dom'
import MenuItem from './MenuItem'

interface Props {
  isSubMenuOpen: boolean,
  subMenuData: Array<{ code: string, state: States }>,
}

const SubMenuListNew: React.FC<Props> = (props) => {
  const {
    isSubMenuOpen, subMenuData,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openMenuItem = (path: string) => {
    navigate(path)
    dispatch(closeMenu())
  }

  return (
    <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit style={{ width: '100%' }}>
      <List component="div" disablePadding>
        {subMenuData.map((item) => item.state !== States.Invisible && (
          <MenuItem
            key={item.code}
            icon={elementsData[item.code].icon}
            title={item.code}
            open={() => openMenuItem(elementsData[item.code].path)}
            close={() => dispatch(closeAllTabs())}
            disabled={item.state === States.Disabled}
            sub
          />
        ))}
      </List>
    </Collapse>
  )
}

export default SubMenuListNew
