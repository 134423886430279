const styles = (theme) => ({
  root: {
    padding: 0,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 60,
    '&::after': {
      content: '',
      flex: 'auto',
    },
  },
  listItem: {
    padding: 0,
    margin: '10px 10px',
    width: 290,
    backgroundColor: theme.backgrounds.cardBackground,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
  },
})

export default styles
