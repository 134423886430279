import React from 'react'
import classNames from 'classnames'
import {
  Avatar, Button, List, ListItem, ListItemText, Paper, Typography,
} from '@mui/material'
import { USER_REQUEST_DETAILS } from '@app/constants/routes'
import PropTypes from '../PropTypes'

class RequestList extends React.PureComponent {
  onShow=(request) => {
    const { navigate, setRequest } = this.props
    setRequest(request)
    navigate(USER_REQUEST_DETAILS.replace(':request_id', request.id))
  }

   createListItem=(request) => {
     const {
       classes,
     } = this.props
     return (
       <ListItem
         key={request.id}
         className={classes.listItem}
       >
         <Avatar alt={request.preferred_name} src={request.avatar} className={classes.avatar} />
         <ListItemText primary={`${request.first_name} ${request.last_name}`} />
         <Button
           className={classes.button}
           variant="outlined"
           color="primary"
           type="button"
           onClick={() => this.onShow(request)}
         >
           {I18n.t('buttons.show')}
         </Button>
       </ListItem>
     )
   }

   render() {
     const {
       usersRequests,
       classes,
     } = this.props
     if (usersRequests.length) {
       return (
         <Paper>
           <List className={classNames(
             (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
             && classes.webView,
           )}
           >
             {usersRequests.map((request) => (this.createListItem(request)))}
           </List>
         </Paper>
       )
     }
     return (
       <Paper className={classNames(
         classes.paper,
         (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
         && classes.webView,
       )}
       >
         <Typography align="center" variant="h6">
           {I18n.t('my_users.no_requests')}
         </Typography>
       </Paper>
     )
   }
}

RequestList.propTypes = {
  classes: PropTypes.object.isRequired,
  usersRequests: PropTypes.usersRequests,
  setRequest: PropTypes.func,
  navigate: PropTypes.func.isRequired,
}

RequestList.defaultProps = {
  usersRequests: null,
  setRequest: null,
}

RequestList.displayName = 'RequestList'

export default RequestList
