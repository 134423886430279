import Ness from './Ness'
import Lendlease from './Lendlease'
import Smartlink from './Smartlink'

export { default as Ness } from './Ness'
export { default as Lendlease } from './Lendlease'
export { default as Smartlink } from './Smartlink'

export default {
  Ness,
  Lendlease,
  Smartlink,
}
