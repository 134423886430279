import * as type from '@app/constants/myStatus'

export const setMyStatusPopupOpen = (deviceId, deviceName) => ({
  type: type.MY_STATUS_POPUP_OPEN,
  deviceId,
  deviceName,
})

export const setMyStatusPopupClosed = () => ({
  type: type.MY_STATUS_POPUP_CLOSED,
})

export const syncMyStatus = () => ({
  type: type.SYNC_MY_STATUS,
})

export const syncMyStatusPopup = () => ({
  type: type.SYNC_MY_STATUS_POPUP,
})

export const updateMyStatusDeviceStats = (data) => ({
  type: type.UPDATE_MY_STATUS_POPUP_DATA,
  data,
})

export const filterMyStatusDevicesByName = (deviceName) => ({
  type: type.FILTER_MY_STATUS_DEVICES,
  deviceName,
})

export const setMyStatusDevices = (data) => ({
  type: type.SET_MY_STATUS_DEVICES,
  data,
})

export const syncMyStatusChannel = () => ({
  type: type.SYNC_MY_STATUS_CHANNEL,
})

export const openChannel = () => ({
  type: type.OPEN_MY_STATUS_CHANNEL,
})

export const closeChannel = () => ({
  type: type.CLOSE_MY_STATUS_CHANNEL,
})

export const syncMyStatusChannelSent = () => ({
  type: type.SYNC_MY_STATUS_CHANNEL_SENT,
})

export const updateMyStatusDevices = (data) => ({
  type: type.UPDATE_MY_STATUS_DEVICES,
  data,
})

export const resetPir = (data) => ({
  type: type.RESET_MY_STATUS_PIR,
  data,
})

export const resetPirDevices = () => ({
  type: type.RESET_MY_PIR_DEVICES,
})
