import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { securityManagementDevicesActions, securityManagementDevicesActionsNCentral } from '@app/lib/Scenes/securityManagementDevicesActions'
import {
  SELECT_ACTION_DEVICE_TYPE,
  SELECT_ACTION_TIMER,
  SELECT_MEZZO_ACTION_STATE,
  SELECT_MEZZO_ACTION_STATE_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectActionSecurityManagementDevice extends React.Component {
  onBackClick = () => {
    const { navigate } = this.props
    navigate(SELECT_ACTION_DEVICE_TYPE)
  }

  onUseClick=(type) => {
    const {
      navigate,
      saveActionDescription,
    } = this.props
    switch (type.type) {
    case 'mezzo':
      saveActionDescription(I18n.t('scene_action_web.device'))
      return navigate(SELECT_MEZZO_ACTION_STATE)
    case 'mezzoLight':
      saveActionDescription(I18n.t('scene_action_web.device_light'))
      return navigate(SELECT_MEZZO_ACTION_STATE_TYPE)
    case 'timers':
      return navigate(SELECT_ACTION_TIMER)
    default:
      throw new Error('action unsupported')
    }
  }

  render() {
    const {
      classes,
      isMobile,
      isNCentral,
    } = this.props
    const actionsList = isNCentral ? securityManagementDevicesActionsNCentral : securityManagementDevicesActions
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_security_management_device')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            actionsList.map((type) => (
              <CardBlock
                key={type.title}
                title={I18n.t(`scene_web.${type.title}`)}
                description={I18n.t(`scene_web.${type.description}`)}
                position={type.position}
                buttonName={I18n.t(`scene_web.${type.buttonName}`)}
                isCardDisabled={type.isCardDisabled}
                isButtonDisabled={type.isButtonDisabled}
                onClick={() => {
                  this.onUseClick(type)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectActionSecurityManagementDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionDescription: PropTypes.func.isRequired,
  isNCentral: PropTypes.bool.isRequired,
}

SelectActionSecurityManagementDevice.displayName = 'SelectActionSecurityManagementDeviceIOS'

export default SelectActionSecurityManagementDevice
