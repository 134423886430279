import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  sceneInfoText: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
}))

export default useStyles
