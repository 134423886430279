import { connect } from 'react-redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { isIOS } from 'react-device-detect'
import ScenesList from '@app/components/MyAutomation/Tabs/ScenesList/ScenesList'
import ScenesListIOS from '@app/components/MyAutomation/Tabs/iOS/ScenesList/ScenesList'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'
import { AppState } from '@app/@types/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? ScenesListIOS : ScenesList

const mapStateToProps = (state: AppState) => {
  const {
    scenes: scenesList, isDeleteScenePopupOpen, isSceneUsedInScenePopupOpen, isWebhookPopupOpen,
  } = state.scenes.scenesList
  const { id: currentSceneId } = state.scenes.scenesList.currentScene
  return {
    currentSceneId,
    isWebhookPopupOpen,
    scenesList,
    isWebView,
    isDeleteScenePopupOpen,
    isSceneUsedInScenePopupOpen,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    admin: checkIfAdmin(state.users.currentUser.user.role),
  }
}

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps)(enhance)

const ScenesListEnhanced = enhance

export default ScenesListEnhanced
