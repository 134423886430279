import { Optional } from '@app/@types/common'

export enum DeviceTypes {
  zwaveDevice = 'zwave_device',
  twrDevice = 'twr_device',
  mezzoLight = 'unit_lights',
  virtualSwitch = 'virtual_switches',
  camera = 'cam',
  zwaveSubDevice = 'zwave_sub_device'
}

export enum TwrTypes {
  door = 'Door',
  motion = 'Motion',
  siren = 'Siren',
  keyfob = 'Keyfob',
  glassBreak = 'Glass break',
  glassBreakUpper = 'GLASS_BREAK',
  reedSwitch = 'REED_SWITCH',
  pir = 'PIR',
  smokeAlarm = 'SMOKE_ALARM',
  environmentalSensor = 'ENVIRONMENTAL_SENSOR',
}

export interface IZwaveOrTwrDevice {
  eco_mode?: boolean,
  icon: number,
  id: number,
  name: string,
  number: number,
  status: Optional<number | boolean>,
  type: string,
  device_type: DeviceTypes.zwaveDevice | DeviceTypes.twrDevice,
  txid?: string,
  switch_capability?: Optional<string>,
  widget?: string,
  invert_status?: boolean,
  option?: string,
  details_info?: string,
  voice_assistant_status?: string,
  security_option?: {
    possibility: boolean,
    enable: boolean,
    option: string,
  },
  secure_mode?: string,
  interview_status?: string,
  room: Optional<{
    id: number,
    name: string,
    number: number,
  }>,
  group: Optional<{
    id: number,
    name: string,
    number: number,
  }>,
  radio_siren_option?: number,
  take_on_board_device_type?: number,
  parent?: IParentDevice,
  parent_number?: number,
  info?: IInfoData,
  last_event?: Optional<{
    status: Optional<boolean>,
    sensor_type: Optional<string>,
    update: Optional<string>,
  }>,
  activate_status: string,
}

export interface IParentDevice {
  eco_mode?: boolean,
  icon: number,
  id: number,
  name: string,
  number: number,
  status: Optional<number | boolean>,
  type: string,
  device_type: DeviceTypes.zwaveDevice,
  txid?: string,
  switch_capability?: string,
  widget?: string,
  invert_status?: boolean,
  option?: string,
  details_info?: string,
  voice_assistant_status?: string,
  security_option?: {
    possibility: boolean,
    enable: boolean,
    option: string,
  },
  secure_mode?: string,
  interview_status?: string,
  room: Optional<{
    id: number,
    name: string,
    number: number,
  }>,
  group: Optional<{
    id: number,
    name: string,
    number: number,
  }>,
  activate_status: string,
}

export interface IMezzoLightType {
  device_type: DeviceTypes.mezzoLight,
}

export interface IVirtualSwitchesType {
  device_type: DeviceTypes.virtualSwitch,
}

export interface ICamera {
  id: number,
  device_type: DeviceTypes.camera,
  name: Optional<string>,
}

export interface IZwaveSubDevice {
  device_type: DeviceTypes.zwaveSubDevice,
  name: string,
  parent: IParentDevice,
}

export type TDevice = IZwaveOrTwrDevice | IMezzoLightType | IVirtualSwitchesType | ICamera | IZwaveSubDevice

export interface IControl {
  label: string,
  key: string,
  component: HTMLElement,
}

export interface IDeviceStatusData {
  id: number,
  status: number,
}

export interface ITwrPatchData {
  name?: string,
  icon?: number,
  option?: string,
}

export interface IZwavePatchData {
  id?: number,
  name?: string,
  icon?: number,
  widget?: string,
  invert_status?: boolean,
}

export interface IZwaveSensor {
  id: number,
  type: string,
  title: string,
  status: Optional<number>,
  scale: Optional<number>,
  short_title: string,
  update: Optional<string>,
}

export interface INotificationSensor {
  id: number,
  notification_type: number,
  event_types: Array<number>,
  last_event: number,
  last_event_timestamp: string,
  notification_type_name: string,
  event_name: string,
  last_event_params: Array<any>,
}

export interface IInfoData {
  zwave_battery: {
    status: Optional<number | string>,
    update: Optional<string>,
  },
  zwave_sensors: Array<IZwaveSensor>,
  color_state: Optional<{
    id: number,
    value: {
      red: number,
      green: number,
      blue: number,
      white_warm: number,
      white_cold: number,
    },
  }>,
  notifications: Array<INotificationSensor>,
  consumings: Array<{
    scale: string,
    value: number,
    update: Optional<string>,
  }>,
  twr_type: string,
  twr_battery: { value: boolean, update: string },
  twr_sensor: { value: boolean, update: string },
  health: { signal_level: number, update: string },
}

export interface IZwaveDetails {
  product_name: Optional<string>,
  brand_name: Optional<string>,
  product_line: Optional<string>,
  product_code: Optional<string>,
  ness_id: Optional<string>,
  node_id: Optional<number>,
  hardware: Optional<number>,
  firmware: Optional<string>,
  inclusion_info: Optional<boolean>,
  description: Optional<string>,
  image: Optional<string>,
  config_params: Array<IConfigParameter>,
}

export enum ConfigParametersTypes {
  range = 'range',
  ranges = 'ranges',
  bitset = 'bitset',
  multiBitsets = 'multi_bitsets',
  camera = 'cam',
  zwaveSubDevice = 'zwave_sub_device'
}

export enum ConfigParametersUITypes {
  single = 'single',
  multi = 'multi',
}

export interface IConfigParameter {
  type: string,
  ui_type: string,
  default: number,
  number: number,
  description: string,
  name: string,
  size: number,
  values: Array<IConfigParameterValue>,
  set_to: number,
  isFetched?: boolean,
}

export interface IDeviceInfoChannelData {
  zwave_device_id?: number,
  sensor?: boolean,
  battery?: boolean,
  color?: boolean,
  energy?: boolean,
  sensor_id?: number,
  status?: { value: number, scales: Array<number> } | number,
  colors?: Record<string, number>,
  zwave_notification?: boolean,
  notification_id?: number,
  notification_type?: number,
  last_event?: number,
  last_event_timestamp?: string,
  last_event_params?: Array<any>,
  notification_type_name?: string,
  event_name?: string,
  update?: Optional<string>,
  value?: number,
  scale?: string,
  zwave_details?: boolean,
  details?: IZwaveDetails,
  zwave_config_params?: boolean,
  config_params?: Array<IDeviceParameterData>,
  size?: number,
  error?: string,
  result?: string,
  parameter_number?: number,
  values?: Array<IConfigParameterValue>,
  device_is_sleeping?: boolean,
  twr_info?: boolean,
  twr_type?: boolean,
  twr_device_id?: number,
  twr_battery?: { value: boolean, update: string },
  twr_sensor?: { value: boolean, update: string },
  twr_health?: boolean,
  health?: { signal_level: boolean, update: string },
}

export interface IConfigParameterValue {
  from: number,
  to: number,
  ui_from: number,
  ui_to: number,
  description: string,
  isEnabled: boolean,
  value: number,
}

export interface IDeviceParameterData {
  parameter_number: number,
  is_default: boolean,
  value?: number,
  size?: number,
  values?: Array<IConfigParameterValue>,
  ui_type?: string,
  set_to?: number,
}

export interface IParseConfigParameterData {
  parameter_number: number,
  ui_type: string,
  set_to: number,
  default: number,
  values: Array<IConfigParameterValue>,
}

export interface IDeviceColoursData {
  colors: {
    red?: number,
    green?: number,
    blue?: number,
    white_warm?: number,
    white_cold?: number,
  },
}

export enum ColorBrightness {
  whiteWarm = 'white_warm',
  whiteCold = 'white_cold',
}
