import React from 'react'
import { LinearProgress, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './ProgressPopup.style'

interface Props {
  text: string,
}

const ProgressPopup: React.FC<Props> = (props) => {
  const { text } = props
  const classes = useStyles()

  return (
    <Wrapper className={classes.root}>
      <Wrapper className={classes.progressContainer}>
        <Typography className={classes.errorText}>
          {text}
        </Typography>
        <LinearProgress className={classes.progressBar} />
      </Wrapper>
    </Wrapper>
  )
}

export default ProgressPopup
