import React from 'react'
import Error from '@app/components/Common/ServerError'

class AccessDenied extends React.Component {
  render() {
    return <Error message="Access Denied" />
  }
}

export default AccessDenied
