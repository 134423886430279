import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { mezzoChimesState } from '@app/lib/Scenes/mezzoChimesState'
import { CREATE_ACTION_SELECTED, EDIT_ACTION, SELECT_MEZZO_ACTION_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMezzoChimesState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MEZZO_ACTION_STATE)
  }

  onAddClick=(type) => {
    const {
      navigate,
      isEditActionMode,
      saveActionType,
      saveActionStateDescription,
    } = this.props
    saveActionStateDescription(type.description)
    saveActionType('PlayAudio')
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            mezzoChimesState.map((type) => (
              <CardBlock
                key={type.description}
                title={type.title}
                description={I18n.t(`scene_action_web.${type.description}`)}
                position={type.position}
                buttonName={type.buttonName}
                isCardDisabled={type.isCardDisabled}
                isButtonDisabled={type.isButtonDisabled}
                isValueButton
                onClick={() => {
                  this.onAddClick(type)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoChimesState.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  saveActionType: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

SelectMezzoChimesState.displayName = 'SelectMezzoChimesStateIOS'

export default SelectMezzoChimesState
