export const actionDeviceTypes = [
  {
    title: 'device',
    description: 'security_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzo',
  },
  {
    title: 'zwave_device',
    description: 'control_zwave_devices',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'ZWaveDevices',
  },
  {
    title: 'room',
    description: 'room_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'Room',
  },
  {
    title: 'group',
    description: 'group_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'Group',
  },
]

export const actionDeviceTypesNCentral = [
  {
    title: 'device',
    description: 'security_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'mezzo',
  },
  {
    title: 'room',
    description: 'room_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'Room',
  },
  {
    title: 'group',
    description: 'group_management',
    position: 'top',
    buttonName: 'use',
    isCardDisabled: false,
    isButtonDisabled: false,
    type: 'Group',
  },
]
