const checkDateIntegrity = (from, to) => {
  let integrity
  if (from && to) {
    integrity = true
  } else if (from || to) {
    integrity = false
  } else {
    integrity = true
  }
  return integrity
}

export const buttonDisableForEvents = (type, from, to, text) => {
  let status = true
  if (from && to) {
    status = false
  }
  if (type) {
    status = !checkDateIntegrity(from, to)
  }
  if (text) {
    status = !checkDateIntegrity(from, to)
  }
  return status
}
