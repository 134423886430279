import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchRooms = (access_token) => Axios.get(`${API_URL}/api/rooms`, {
  headers: {
    'Access-Token': access_token,
  },
})

const fetchDevices = (access_token) => Axios.get(`${API_URL}/api/rooms/index_devices_without_rooms`, {
  headers: {
    'Access-Token': access_token,
  },
})

const postRoom = (access_token, data) => Axios.post(`${API_URL}/api/rooms`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchRoom = (access_token, data, id) => Axios.patch(`${API_URL}/api/rooms/${id}`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteRoom = (access_token, id) => Axios.delete(`${API_URL}/api/rooms/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const roomAddDeviceTwr = (access_token, deviceId, room_id) => Axios.patch(`${API_URL}/api/set_device_association/twr_device/${deviceId}`,
  { room_id }, {
    headers: {
      'Access-Token': access_token,
    },
  })

const roomAddDeviceZwave = (access_token, deviceId, room_id) => Axios.patch(
  `${API_URL}/api/set_device_association/zwave_device/${deviceId}`,
  { room_id }, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

const roomDeleteDeviceTwr = (access_token, deviceId) => Axios.patch(`${API_URL}/api/set_device_association/twr_device/${deviceId}`,
  { room_id: 0 }, {
    headers: {
      'Access-Token': access_token,
    },
  })

const roomDeleteDeviceZwave = (access_token, deviceId) => Axios.patch(`${API_URL}/api/set_device_association/zwave_device/${deviceId}`,
  { room_id: 0 }, {
    headers: {
      'Access-Token': access_token,
    },
  })

export default {
  fetchRooms,
  fetchDevices,
  postRoom,
  patchRoom,
  deleteRoom,
  roomAddDeviceTwr,
  roomAddDeviceZwave,
  roomDeleteDeviceTwr,
  roomDeleteDeviceZwave,
}
