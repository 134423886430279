export const SYNC_ROOMS_LIST = '@@rooms/roomsListSync'
export const SET_ROOMS_LIST = '@@rooms/setRoomsList'

export const SYNC_ROOMS_DEVICES = '@@rooms/roomsDevicesSync'
export const SET_ROOMS_DEVICES = '@@rooms/roomsDevicesSet'

export const ROOMS_ADD_DEVICE = '@@rooms/roomsAddDevice'

export const ROOMS_DELETE_DEVICE = '@@rooms/roomsDeleteDevice'

export const POST_ADD_ROOM = '@@rooms/roomsListPostRoom'
export const SET_POST_ADD_ROOM = '@@rooms/roomsListPostRoomSet'
export const POST_ADD_ROOM_FAILED_POPUP = '@@rooms/roomsListPostRoomFailedPopup'

export const PATCH_ROOM = '@@rooms/roomPatch'
export const SET_PATCH_ROOM = '@@rooms/roomPatchSet'

export const DELETE_ROOM = '@@rooms/roomDelete'
export const SET_DELETE_ROOM = '@@rooms/roomDeleteSet'

export const TOGGLE_ADD_ROOM_POPUP = '@@rooms/roomsToggleAddPopup'
export const FILTER_ADD_TWR_DEVICES = '@@rooms/filterAddTwrDevices'
export const FILTER_ADD_ZWAVE_DEVICES = '@@rooms/filterAddZwaveDevices'

export const maxRoomsNumber = 30
