import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import deleteAccountScreen from '@app/components/Wizard/DeleteAccount/DeleteAccount'
import { withProgressAndErrorMessage } from '@app/hocs'

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  return {
    isMobile,
    isTablet,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

let enhance = withProgressAndErrorMessage(deleteAccountScreen)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const deleteAccountScreenEnhanced = enhance
export default deleteAccountScreenEnhanced
