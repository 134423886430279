const styles = (theme) => ({
  root: {
    padding: 20,
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    position: 'relative',
    boxSizing: 'border-box',
    marginBottom: 20,
  },
  refreshIcon: {
    alignSelf: 'flex-end',
    marginRight: '5%',
  },
  refreshIconMobile: {
    marginRight: '2%',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  geofencesCard: {
    boxSizing: 'border-box',
    width: '100%',
    height: 76,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '12px 4%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  cardMobile: {
    paddingLeft: 20,
  },
  cardContent: {
    width: '100%',
  },
  name: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: 40,
    cursor: 'pointer',
  },
  geofenceExpanded: {
    minHeight: 180,
    paddingBottom: 8,
  },
  geofenceDetails: {
    height: 100,
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: theme.scenes.descriptionTop,
    fontSize: 16,
  },
  detailsMobile: {
    paddingTop: 5,
    paddingBottom: 20,
    '& > p': {
      fontSize: 12,
    },
  },
  icon: {
    position: 'absolute',
    top: 15,
    right: 10,
    transition: 'transform 0.3s ease-out',
  },
  flipIcon: {
    transform: 'rotate(180deg)',
  },
  iconInner: {
    transform: 'rotate(-90deg)',
  },
  noGeofences: {
    alignSelf: 'center',
    marginTop: '20%',
    textTransform: 'uppercase',
    fontSize: 20,
  },
  addButton: {
    position: 'fixed',
    bottom: 20,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    color: theme.backgrounds.cardBackground,
    backgroundColor: theme.palette.primary.main,
    zIndex: 101,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  geofenceControls: {
    position: 'absolute',
    right: 17,
    bottom: 20,
  },
  saveButton: {
    color: theme.palette.primary.main,
  },
  controlsMobile: {
    right: 10,
    bottom: 0,
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
})

export default styles
