import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  downloadInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
  },
  register: {
    marginBottom: 30,
  },
  download: {
    marginBottom: 30,
  },
  buttonsWrapper: {
    display: 'flex',
    maxWidth: 320,
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    height: 40,
    width: 128,
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  email: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles
