import * as type from '@app/constants/users/memberList'

const initialState = {
  users: [],
}

export default function memberList(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_USERS: {
    return {
      ...state,
      users: action.users,
    }
  }

  default:
    return state
  }
}
