export const mezzoActionStates = [
  {
    title: null,
    description: 'mezzo_chimes',
    position: 'middle',
    buttonName: 'choose',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'PlayAudio',
    isValueButton: false,
  },
  {
    title: null,
    description: 'arm_system',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'ArmTheSystem',
    isValueButton: true,
  },
  {
    title: null,
    description: 'arm_home_mode',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'ArmInHomeMode',
    isValueButton: true,
  },
  {
    title: null,
    description: 'disarm',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'Disarm',
    isValueButton: true,
  },
  {
    title: null,
    description: 'toggle_voice',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'ToggleVoice',
    isValueButton: true,
  },
  {
    title: null,
    description: 'mute_voice',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'MuteOrUnmuteVoice',
    isValueButton: true,
  },
  {
    title: null,
    description: 'unmute_voice',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'MuteOrUnmuteVoice',
    isValueButton: true,
  },

  {
    title: null,
    description: 'panic',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'AlarmPanicDuress',
    isValueButton: true,
  },
  {
    title: null,
    description: 'duress',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'AlarmPanicDuress',
    isValueButton: true,
  },
]
