import * as type from '@app/constants/units'

export const reset = () => ({
  type: type.RESET_UNITS,
})

export const update = (devices) => ({
  type: type.UPDATE_UNITS,
  devices,
})

export const sync = () => ({
  type: type.SYNC_UNITS,
})

export const updateUnitName = (unit) => ({
  type: type.UPDATE_UNIT_NAME,
  unit,
})
