import * as type from '@app/constants/syncCounters/countersChannel'

const initialState = {
  isChannelOpen: false,
  progress: false,
  error: '',
  isSyncWarningPopupOpen: false,
  isSyncInProgressPopupOpen: false,
  isSyncErrorPopupOpen: false,
  syncType: '',
}

export default function countersChannel(state = initialState, action) {
  switch (action.type) {
  case type.OPEN_COUNTERS_CHANNEL:
    return { ...state, isChannelOpen: true }
  case type.CLOSE_COUNTERS_CHANNEL:
    return { ...state, isChannelOpen: false }

  case type.SYNC_SENT_COUNTERS_CHANNEL:
    return { ...state, progress: true }
  case type.SYNC_DONE_COUNTERS_CHANNEL:
    return { ...state, progress: false }
  case type.SYNC_FAILED_COUNTERS_CHANNEL:
    return { ...state, error: action.error }

  case type.OPEN_SYNC_WARNING_POPUP:
    return { ...state, isSyncWarningPopupOpen: true }
  case type.CLOSE_SYNC_WARNING_POPUP:
    return { ...state, isSyncWarningPopupOpen: false }

  case type.OPEN_SYNC_IN_PROGRESS_POPUP:
    return { ...state, isSyncInProgressPopupOpen: true }
  case type.CLOSE_SYNC_IN_PROGRESS_POPUP:
    return { ...state, isSyncInProgressPopupOpen: false }

  case type.OPEN_SYNC_ERROR_POPUP:
    return { ...state, isSyncErrorPopupOpen: true, syncType: action.syncType }
  case type.CLOSE_SYNC_ERROR_POPUP:
    return {
      ...state, isSyncErrorPopupOpen: false, syncType: '', error: '',
    }

  default:
    return state
  }
}
