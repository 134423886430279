import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: theme.backgrounds.transparentBg,
  },
  rootMobile: {
    alignItems: 'flex-start',
  },
  main: {
    maxWidth: 450,
    minHeight: 600,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 20px',
    margin: 0,
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainDesktop: {
    maxWidth: 450,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid rgba(255, 255, 255, 0.54)',
    boxSizing: 'border-box',
    boxShadow: `0px 4px 4px ${theme.palette.shadow.color}`,
    borderRadius: 10,
    padding: '35px 34px',
    margin: '32px 0px',
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainMobile: {
    padding: '30px 34px',
    margin: '0 0px',
  },
  mainLogo: {
    marginBottom: theme.loginMargin.bottomMargin,
  },
  sendButton: {
    maxWidth: 200,
    height: 36,
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: 2,
    boxShadow: '0px 4px 4px rgba(111, 155, 77, 0.5)',
    marginTop: 25,
    marginBottom: 20,
    color: theme.typography.color,
  },
  form: {
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  links: {
    maxWidth: 329,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  forgotLink: {
    fontSize: '16px',
    cursor: 'pointer',
    marginBottom: 45,
  },
  signUpLink: {
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: 60,
    marginBottom: 25,
    textDecoration: 'underline',
  },
  agreementText: {
    marginBottom: 10,
  },
  privacy: {
    fontSize: '16px',
    cursor: 'pointer',
  },
  loginErrorWrapper: {
    position: 'relative',
    width: '100%',
    display: 'block',
  },
  loginError: {
    position: 'absolute',
    width: '100%',
    color: '#ED1F24',
    textAlign: 'center',
    fontSize: '14px',
  },
  deleteButton: {
    textTransform: 'none',
    color: theme.palette.error.main,
    fontSize: 15,
  },
}))

export default useStyles
