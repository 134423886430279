import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { history } from '@app/store/history'
import { PATCH_ROOM } from '@app/constants/rooms/rooms'
import Api from '@app/api/rooms'
import { ROOMS } from '@app/constants/routes'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setPatchRoom } from '@app/actions/rooms/rooms'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const patchRoom = createLogic({
  type: PATCH_ROOM,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(PATCH_ROOM))
    Api.patchRoom(AccessToken, action.data, action.data.id)
      .then((response) => {
        dispatch(setPatchRoom(response.data.room))
        dispatch(apiRequestDone(PATCH_ROOM))
        history.push(ROOMS)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchRoom failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchRoom
