export const doorLockStates = [
  {
    description: 'locked',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 255,
  },
  {
    description: 'unlocked',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
  },
]
