import * as type from '@app/constants/scenes/twrDevices/twrDevices'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/twrDevices/twrDevices'
import { ITwrDeviceBackend } from '@app/@types/scenes/scenesDevices'

type ITwrDevices = ReturnType<InferValueTypes<typeof actions>>

interface ITwrDevicesState {
  devices: Array<ITwrDeviceBackend>,
  twrDeviceStates: Array<string>,
}

const initialState = {
  devices: [],
  twrDeviceStates: [],
}

export default function twrDevices(state: ITwrDevicesState = initialState, action: ITwrDevices) {
  switch (action.type) {
  case type.RESET_TWR_DEVICES:
    return initialState

  case type.UPDATE_TWR_DEVICES: {
    return {
      ...state,
      devices: action.twrDevices,
    }
  }

  case type.UPDATE_TWR_DEVICE_STATES: {
    return {
      ...state,
      twrDeviceStates: action.twrDeviceStates,
    }
  }

  default:
    return state
  }
}
