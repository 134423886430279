import React from 'react'
import {
  Dialog,
  DialogTitle,
  Tooltip,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Link,
  ListItem,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { closeWebhookPopup, patchEnableWebhook } from '@app/actions/scenes/scenesList'
import { useDispatch } from 'react-redux'
import { Close, ContentCopy } from 'icons'
import { API_URL } from '@app/constants/endpoints'
import { webhookEnabling, testConditionsEnabling } from '@app/lib/Scenes/webhookAction'
import { IScene } from '@app/@types/scenes/scenes'
import useStyles from './WebhokPopup.style'

interface Props {
  scenes: Array<IScene>,
  id: number,
  isWebhookPopupOpen: boolean,
}

const WebhookPopup: React.FC<Props> = (props) => {
  const { isWebhookPopupOpen, scenes, id } = props
  const currentScene = scenes.find((scene) => scene.id === id)
  let isUserTriggerable: boolean
  let webhookGuid: string
  let isWebhookEnabled: boolean
  let isTestConditionsEnabled: boolean
  if (currentScene) {
    isUserTriggerable = currentScene.is_user_triggerable
    webhookGuid = currentScene.webhook_guid
    isWebhookEnabled = currentScene.webhook_enabled
    isTestConditionsEnabled = currentScene.webhook_test_conditions
  } else {
    throw new Error('problem with scene id')
  }

  const dispatch = useDispatch()
  const classes = useStyles()

  const handleCheckboxChange = (type: string) => {
    dispatch(patchEnableWebhook(type))
  }

  const copyText = () => (navigator.clipboard.writeText(`${API_URL}/api/scenes/webhook?tkn=${webhookGuid}`))

  return (
    <Dialog
      className={classes.root}
      open={isWebhookPopupOpen}
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        {I18n.t('scene_web.title_webhook_dialog_box')}
      </DialogTitle>
      <IconButton
        aria-label="Close"
        className={classes.closeIconButton}
        onClick={() => {
          dispatch(closeWebhookPopup())
        }}
        size="large"
      >
        <Close className={classes.closeIcon} />
      </IconButton>
      <DialogContent>
        <Wrapper className={classes.checkboxesWrapper}>
          <FormControlLabel
            label={I18n.t('scene_web.name_check_box')}
            className={classes.checkbox}
            control={(
              <Checkbox
                checked={isWebhookEnabled}
                onChange={() => {
                  handleCheckboxChange(webhookEnabling)
                }}
                inputProps={{ 'aria-label': 'webhookEnabling' }}
              />
            )}
          />
          {isUserTriggerable && (
            <FormControlLabel
              label={I18n.t('scene_web.test_condition_webhook')}
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={isTestConditionsEnabled}
                  onChange={() => {
                    handleCheckboxChange(testConditionsEnabling)
                  }}
                  inputProps={{ 'aria-label': 'testConditionsEnabling' }}
                />
              )}
            />
          )}
        </Wrapper>
        <Typography className={classes.advancedTitle}>{I18n.t('scene_web.advanced')}</Typography>
        {I18n.t('scene_web.warning_first_part').split('\\n')
          .map((text: string) => (<ListItem sx={{ display: 'list-item' }} className={classes.info} key={text}>{text}</ListItem>))}
        <Typography className={classNames(classes.info, classes.infoWithoutList)}>{I18n.t('scene_web.warning_second_part')}</Typography>
        {I18n.t('scene_web.warning_third_part').split('\\n')
          .map((text: string) => (<ListItem sx={{ display: 'list-item' }} className={classes.info} key={text}>{text}</ListItem>))}
        <Wrapper className={classes.linkWrapper}>
          <Typography className={classes.info}>{I18n.t('scene_web.more_info')}</Typography>
          <Link href="https://support.mezzobyness.com/webhook-functions" target="_blank" rel="noopener" className={classes.link}>
            {I18n.t('scene_web.mezzo_knowledge_base')}
          </Link>
        </Wrapper>
        <Typography className={classes.url}>{`${API_URL}/api/scenes/webhook?tkn=${webhookGuid}`}</Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip
          title="Copy"
          placement="left"
        >
          <IconButton
            className={classes.button}
            aria-label="Copy"
            onClick={copyText}
            size="large"
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

export default WebhookPopup
