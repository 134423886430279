import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles } from '@app/components/styles'
// eslint-disable-next-line max-len
import {
  saveConditionStateDescription,
  saveObservingDeviceName,
  saveObservingDeviceNumber,
  saveTriggeringDeviceName,
  saveTriggeringDeviceNumber,
} from '@app/actions/scenes/currentSceneCreating'
import { isIOS } from 'react-device-detect'
import { withRouter } from '@app/hocs'
import Group
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/GroupsList/Group'
import GroupIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/GroupsList/Group'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(GroupIOS) : withStyles(Group)

const mapStateToProps = (state) => {
  const { deviceType } = state.scenes.currentSceneCreating.currentCondition
  return { deviceType }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveConditionStateDescription,
  saveTriggeringDeviceNumber,
  saveTriggeringDeviceName,
  saveObservingDeviceNumber,
  saveObservingDeviceName,
}, dispatch)

enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const GroupEnhanced = enhance

export default withRouter(GroupEnhanced)
