import { createLogic } from 'redux-logic'
import { PATCH_MEZZO_LIGHTS } from '@app/constants/myDevices/myDevices'
import Api from '@app/api/myDevices/myDevices'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { endMezzoLightsLoading } from '@app/actions/myDevices/mezzoLights'

const patchMezzoLightsLogic = createLogic({
  type: PATCH_MEZZO_LIGHTS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_MEZZO_LIGHTS))
    try {
      await Api.patchMezzoLights(action.accessToken, { value: action.value })
      dispatch(apiRequestDone(PATCH_MEZZO_LIGHTS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(endMezzoLightsLoading())
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_MEZZO_LIGHTS failed without error')
      }
    }
    done()
  },
})

export default patchMezzoLightsLogic
