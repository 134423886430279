import { createLogic } from 'redux-logic'
import { PUT_SPHERE_SORT } from '@app/constants/sphereSort'
import Api from '@app/api/putSphereSort'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const putSphereSortLogic = createLogic({
  type: PUT_SPHERE_SORT,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { sphereSort: { model, field, order } } = getState()
    next({
      ...action,
      accessToken,
      model,
      field,
      order,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PUT_SPHERE_SORT))
    Api.putSphereSort(action.accessToken, action.model, action.field, action.order)
      .then(() => {
        dispatch(apiRequestDone(PUT_SPHERE_SORT))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('putSphereSort failed without error')
        }
      })
      .then(() => done())
  },
})

export default putSphereSortLogic
