export const twrModes = {
  alarm: {
    mode: 'alarm',
    description: I18n.t('my_devices.alarm_desc'),
  },
  perimeter: {
    mode: 'perimeter',
    description: I18n.t('my_devices.perimeter_desc'),
  },
  fire_24_hours: {
    mode: 'fire_24_hours',
    description: I18n.t('my_devices.fire_24_hours_desc'),
  },
  chime: {
    mode: 'chime',
    description: I18n.t('my_devices.chime_desc'),
  },
  alarm_chime: {
    mode: 'alarm_chime',
    description: I18n.t('my_devices.alarm_chime_desc'),
  },
  silent: {
    mode: 'silent',
    description: I18n.t('my_devices.silent_desc'),
  },
  no_alarm: {
    mode: 'no_alarm',
    description: I18n.t('my_devices.no_alarm_desc'),
  },
  home: {
    mode: 'home',
    description: I18n.t('my_devices.home_desc'),
  },
  panic: {
    mode: 'panic',
    description: I18n.t('my_devices.panic_desc'),
  },
  medical: {
    mode: 'medical',
    description: I18n.t('my_devices.medic_desc'),
  },
  duress: {
    mode: 'duress',
    description: I18n.t('my_devices.duress_desc'),
  },
  bell: {
    mode: 'bell',
    description: I18n.t('my_devices.bell_desc'),
  },
  no_panic: {
    mode: 'no_panic',
    description: I18n.t('my_devices.no_panic_desc'),
  },
}
