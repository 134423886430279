export const SYNC_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/syncChannel'
export const SYNC_SENT_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/syncSentChannel'
export const SYNC_CANCEL_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/cancelledChannel'
export const SYNC_DONE_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/doneChannel'

export const SYNC_FAILED_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/syncFailed'

export const OPEN_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/open'
export const CLOSE_CLOUD_FIRMWARE_UPDATE_CHANNEL = '@@cloudFirmwareUpdateChannel/close'

export const OPEN_DOWNLOADING_ERROR_POPUP = '@@cloudFirmwareUpdateChannel/openDownloadingErrorPopup'
export const CLOSE_DOWNLOADING_ERROR_POPUP = '@@cloudFirmwareUpdateChannel/closeDownloadingErrorPopup'
