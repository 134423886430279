import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 36" x="0px" y="0px"><g><path d="M7,14.31a1.06,1.06,0,1,0-1.06-1.06A1.05,1.05,0,0,0,7,14.31Zm0-1.12a.06.06,0,0,1,.06.06L7,13.31Zm0,3.87A1.06,1.06,0,1,0,5.92,16,1.06,1.06,0,0,0,7,17.06Zm0-1.12A.05.05,0,0,1,7,16L7,16.06Zm0,3.87a1.06,1.06,0,1,0-1.06-1.06A1.05,1.05,0,0,0,7,19.81Zm0-1.12a.06.06,0,0,1,.06.06L7,18.81Zm3-.44h2.81V17.1h.59V13.75h-4V17.1H10Zm.37-3.5h2V16.1h-.59v1.15H11V16.1h-.63Zm4,3.5h2.81V17.1h.59V13.75h-4V17.1h.63Zm.37-3.5h2V16.1h-.59v1.15h-.81V16.1h-.63Zm4,3.5h2.81V17.1h.59V13.75H18V17.1h.64Zm.36-3.5h2V16.1h-.59v1.15h-.81V16.1H19Zm4,3.5h2.81V17.1h.59V13.75h-4V17.1h.63Zm.37-3.5h2V16.1h-.59v1.15h-.81V16.1h-.63ZM27.91,9H4.09a2,2,0,0,0-2,2V21a2,2,0,0,0,2,2h.36v.55A1.52,1.52,0,0,0,6,25h.87a1.52,1.52,0,0,0,1.52-1.52V23H23.64v.55A1.52,1.52,0,0,0,25.16,25H26a1.52,1.52,0,0,0,1.52-1.52V23h.36a2,2,0,0,0,2-2V11A2,2,0,0,0,27.91,9ZM7.36,23.52a.52.52,0,0,1-.52.52H6a.52.52,0,0,1-.52-.52V23H7.36Zm19.19,0A.52.52,0,0,1,26,24h-.87a.52.52,0,0,1-.52-.52V23h1.91ZM28.91,21a1,1,0,0,1-1,1H4.09a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H27.91a1,1,0,0,1,1,1Zm-1-10.39H4.08a.65.65,0,0,0-.64.65v9.54a.65.65,0,0,0,.64.65H27.92a.65.65,0,0,0,.64-.65V11.23A.65.65,0,0,0,27.92,10.58Zm-.36,9.84H4.44V11.58H27.56Z"/></g></svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Hub')

export default icon
