import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchContacts = (access_token) => Axios.get(`${API_URL}/api/user_contacts`, {
  headers: {
    'Access-Token': access_token,
  },
})

const postContact = (access_token, contact) => Axios.post(`${API_URL}/api/user_contacts`, contact, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchContact = (access_token, id, contact) => Axios.patch(`${API_URL}/api/user_contacts/${id}`, contact, {
  headers: {
    'Access-Token': access_token,
  },
})

const deleteContact = (access_token, id) => Axios.delete(`${API_URL}/api/user_contacts/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const updateUserAvatar = (accessToken, form) => Axios.post(`${API_URL}/api/user_contacts/avatar_file`, form, {
  headers: {
    'Access-Token': accessToken,
    'Content-Type': 'multipart/form-data',
  },
})

const updateUserAlertMessage = (access_token, message) => Axios.patch(`${API_URL}/api/user_contacts/message`, { message }, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchContacts,
  postContact,
  patchContact,
  deleteContact,
  updateUserAvatar,
  updateUserAlertMessage,
}
