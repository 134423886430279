import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/setUpBillingPeriod'

const initialState = {
  billingSettings: {
    cost_of_kwh: '',
    payment_day: '',
  },
}

export default function setUpBillingPeriod(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_BILLING_SETTINGS:
    return update(state, {
      billingSettings: { $set: action.settings },
    })
  case type.RESET_SET_UP_BILLING_PERIOD_OPTIONS:
    return initialState
  default:
    return state
  }
}
