import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import styles from './WarningLang.style'

class CellularModulePopup extends React.PureComponent {
  handleCellularModuleChange=(event) => {
    const {
      saveCellularModule,
    } = this.props
    saveCellularModule(event.target.value)
  }

  onChangeCellularModuleSubmitClick=() => {
    const {
      syncCellularModule,
      closeChangeCellularModulePopup,
    } = this.props
    syncCellularModule()
    closeChangeCellularModulePopup()
  }

  render() {
    const {
      classes,
      open,
      onCancel,
      warningText,
      leftButton,
      rightButton,
      cellularModule,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open={open}
      >
        <DialogTitle
          className={classes.title}
        >
          {I18n.t('titles.warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="body1"
            className={classes.text}
          >
            { I18n.t(`${warningText}`)}
            ?
          </DialogContentText>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              aria-label="cellular_module"
              name="cellular_module"
              value={cellularModule}
              onChange={this.handleCellularModuleChange}
            >
              <FormControlLabel
                value="3G"
                control={<Radio color="primary" />}
                label="3G"
              />
              <FormControlLabel
                value="4G"
                control={<Radio color="primary" />}
                label="4G"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            className={classes.button}
            onClick={onCancel}
          >
            {I18n.t(`buttons.${leftButton}`)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={this.onChangeCellularModuleSubmitClick}
          >
            {I18n.t(`buttons.${rightButton}`)}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

CellularModulePopup.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  warningText: PropTypes.string.isRequired,
  leftButton: PropTypes.string.isRequired,
  rightButton: PropTypes.string.isRequired,
  syncCellularModule: PropTypes.func.isRequired,
  saveCellularModule: PropTypes.func.isRequired,
  closeChangeCellularModulePopup: PropTypes.func.isRequired,
  cellularModule: PropTypes.string.isRequired,
}

export default withStyles(styles)(CellularModulePopup)
