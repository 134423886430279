import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { groupActionStates } from '@app/lib/Scenes/roomActionStates'
import {
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_ACTION_GROUP_STATE_TYPE,
  SELECT_GROUP_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectGroupActionState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      stateDescription,
      isEditActionMode,
      restoreActionInfo,
      isChangeActionStateMode,
      isChangeActionMode,
      actions,
      initialAction,
    } = this.props
    if (isEditActionMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    } else if (isChangeActionStateMode || isChangeActionMode) {
      restoreActionInfo()
      navigate(CREATE_ACTION_SELECTED)
    } else if (stateDescription !== 'none') {
      navigate(SELECT_GROUP_ACTION_STATE_SELECTED)
    } else {
      navigate(SELECT_ACTION_GROUP_STATE_TYPE)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      saveActionStateDescription,
      saveActionGroupState,
    } = this.props
    saveActionStateDescription(state.description)
    saveActionGroupState(state.description === 'turn_off' ? 0 : 1)
    navigate(SELECT_GROUP_ACTION_STATE_SELECTED)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            groupActionStates.map((state) => (
              <CardBlock
                key={state.description}
                description={I18n.t(`scene_condition_web.${state.description}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectGroupActionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  saveActionGroupState: PropTypes.func.isRequired,
  stateDescription: PropTypes.string.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isChangeActionMode: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectGroupActionState.displayName = 'SelectGroupActionStateIOS'

export default SelectGroupActionState
