import * as type from '@app/constants/users/currentRequest'

export const setCurrentRequest = (request) => ({
  type: type.SET_CURRENT_REQUEST,
  request,
})

export const setReject = () => ({
  type: type.SET_REJECT_REQUEST,
})

export const setApprove = () => ({
  type: type.SET_APPROVE_REQUEST,
})

export const openRequestInfoPopup = () => ({
  type: type.OPEN_REQUEST_INFO_POPUP,
})

export const closeRequestInfoPopup = () => ({
  type: type.CLOSE_REQUEST_INFO_POPUP,
})

export const resetCurrentRequest = () => ({
  type: type.RESET_CURRENT_REQUEST,
})
