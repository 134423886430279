import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  deviceTabs: {
    background: 'transparent',
    color: theme.scenes.textColor,
  },
}))

export default useStyles
