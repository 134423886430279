import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import oAuthApplication from '@app/helpers/oAuthApplication'
import handleErrors from '@app/helpers/handleErrors'
import { AUTH_BY_PASSWORD } from '@app/constants/wizard/wizard'
import { HOME, SELECT_MEZZO_DEVICE_CONNECTION } from '@app/constants/routes'
import {
  authStarted,
  authFinished,
  hideAccountLockedError,
  hideSignInError,
  showAccountLockedError,
  showSignInError,
} from '@app/actions/wizard/signIn'
import { authentificationSuccess, updateAccessToken, updateSecrets } from '@app/actions/secrets'
import { signInCurrentUser, updateCurrentUser, updateCurrentUserRole } from '@app/actions/users/currentUser'
import { updateUnit } from '@app/actions/unit'
import { incorrectUserCredentials, userLocked } from '@app/lib/Wizard/signInErrors'
import { tokenData } from '@app/lib/Wizard/wizardLogicFunctions'
import { update } from '@app/actions/units'
import { setGuardPanelStatus } from '@app/actions/guardPanel'
import AuthToken from '@app/api/wizard/authByPassword'
import Api from '@app/api/fetchUnits'
import ApiConfig from '@app/api/appConfig'
import refreshData from '@app/api/hotUpdate'
import wizardUserInfo from '@app/api/wizard/wizardUserInfo'
import openCable from '@app/api/cable'
import ApiUnit from '@app/api/unit/patchUnitDevice'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { PATCH_UNIT } from '@app/constants/unit'
import { SYNC_UNITS } from '@app/constants/units'
import { SYNC_HOT_UPDATE } from '@app/constants/hotUpdate'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import convertUser from '@app/helpers/convertUser'
import { getArmStatus } from '@app/helpers/getArmStatus'
import { saveRawConfig, saveAppConfig } from '@app/actions/appConig/appConfig'
import { convertAppConfig } from '@app/helpers/convertAppConfig'
import { GET_APP_CONFIG } from '@app/constants/appCofig/appConfig'
import { checkIfNCentral } from '../../helpers/checkIfNCentral'

const authByPasswordLogic = createLogic({
  type: AUTH_BY_PASSWORD,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { clientId, secret, scope } = oAuthApplication()
    const { form: { SignIn: { values: { email_or_phone, password } } } } = getState()
    const { users: { currentUser: { user: { role } } } } = getState()

    next({
      ...action,
      email_or_phone,
      password,
      clientId,
      secret,
      scope,
      role,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(AUTH_BY_PASSWORD))
    dispatch(authStarted())
    try {
      const jwt = await AuthToken.authByPassword(action.email_or_phone, action.password, action.clientId, action.secret, action.scope)
      const parsedToken = tokenData(jwt)
      dispatch(updateAccessToken(
        parsedToken.token,
        parsedToken.expiresIn,
        parsedToken.tokenType,
        parsedToken.refreshToken,
        parsedToken.createdAt,
        parsedToken.scope,
      ))
      try {
        openCable()
        const userInfo = await wizardUserInfo(parsedToken.token)
        const { data } = userInfo
        const convertedUser = convertUser(data.sphere_user)
        dispatch(signInCurrentUser(convertedUser))
      } catch (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      }
      try {
        dispatch(apiRequestSent(SYNC_UNITS))
        const unitDevices = await Api.fetchUnits(parsedToken.token)
        const { data } = unitDevices
        dispatch(update(data.unit_devices))
        dispatch(apiRequestDone(SYNC_UNITS))
        if (data.unit_devices.length > 1) {
          history.push(SELECT_MEZZO_DEVICE_CONNECTION)
        } else if (data.unit_devices.length === 1) {
          try {
            dispatch(apiRequestSent(PATCH_UNIT))
            const choosedUnit = await ApiUnit.patchUnitDevice(parsedToken.token, data.unit_devices[0].serial_number)
            const { unit_device } = choosedUnit.data
            dispatch(updateSecrets(
              unit_device.token,
            ))
            dispatch(updateUnit(unit_device))
            const currentRole = unitDevices.data.unit_devices[0].unit_user.role
            dispatch(updateCurrentUserRole(currentRole))
            dispatch(apiRequestDone(PATCH_UNIT))
            try {
              const configData = await ApiConfig.getAppConfig(parsedToken.token)
              const rawConfigs = configData.data.accounts
              dispatch(saveRawConfig(rawConfigs))
              const isNCentral = checkIfNCentral(unit_device.serial_number)
              const convertedConfig = convertAppConfig(rawConfigs, isNCentral, unit_device.activation, currentRole)
              dispatch(saveAppConfig(convertedConfig))
              dispatch(apiRequestDone(GET_APP_CONFIG))
              history.push(HOME)
            } catch (error) {
              const currentError = handleErrors(error)
              dispatch(apiRequestFailed(currentError))
            }
          } catch (error) {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
            done()
          }
        } else { // without device
          try {
            const configData = await ApiConfig.getAppConfig(parsedToken.token)
            const rawConfigs = configData.data.accounts
            dispatch(saveRawConfig(rawConfigs))
            const convertedConfig = convertAppConfig(rawConfigs, false, null, 'no_unit_device')
            dispatch(saveAppConfig(convertedConfig))
            dispatch(apiRequestDone(GET_APP_CONFIG))
            history.push(HOME)
          } catch (error) {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          }
        }
      } catch (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      }
      try {
        dispatch(apiRequestSent(SYNC_HOT_UPDATE))
        const actualInfo = await refreshData.hotUpdate(parsedToken.token)
        const { mobileUser, unitDevice } = actualInfo.data
        dispatch(updateCurrentUser(mobileUser))
        if (unitDevice) {
          dispatch(updateUnit(unitDevice))
          dispatch(updateSecrets(
            unitDevice.token,
          ))
          const isNCentral = checkIfNCentral(unitDevice.serial_number)
          const statusResult = getArmStatus(unitDevice.arm_status, unitDevice.arm_types, isNCentral)
          dispatch(setGuardPanelStatus(statusResult))
        }
        dispatch(apiRequestDone(SYNC_HOT_UPDATE))
        dispatch(authentificationSuccess())
        dispatch(apiRequestDone(AUTH_BY_PASSWORD))
        dispatch(hideAccountLockedError())
        dispatch(hideSignInError())
        dispatch(authFinished())
      } catch (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      }
    } catch (error) {
      if (error) {
        if (error === 403) {
          dispatch(showSignInError(incorrectUserCredentials))
          dispatch(apiRequestFailed())
          done()
        } else if (error.response.data.error_reason === incorrectUserCredentials) {
          dispatch(showSignInError(error.response.data.error_reason))
          dispatch(hideAccountLockedError())
          dispatch(apiRequestFailed())
          done()
        } else if (error.response.data.error_reason === userLocked) {
          dispatch(hideSignInError())
          dispatch(showAccountLockedError())
          dispatch(apiRequestFailed())
          done()
        } else if (error.response.status === 403) {
          dispatch(hideSignInError())
          dispatch(apiRequestFailed())
          done()
        } else {
          const currentError = handleErrors(error)
          dispatch(showSignInError(currentError))
          dispatch(apiRequestFailed())
          done()
        }
      } else {
        throw new Error('authByPassword failed without error')
      }
    }
    return done()
  },
})

export default authByPasswordLogic
