import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { START_REBOOT } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import Api from '@app/api/cloudFirmwareUpdate/reboot'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const rebootLogic = createLogic({
  type: START_REBOOT,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(START_REBOOT))
    Api.reboot(token)
      .then(() => {
        dispatch(apiRequestDone(START_REBOOT))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('reboot failed without error')
        }
      })
      .then(() => done())
  },
})

export default rebootLogic
