import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
     <svg viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.56075 0V1.74936H0V22.225L5.56075 22.2296V24L15.7138 22.1763L15.7243 1.83633L5.56075 0ZM7.24475 11.1966C7.58574 11.1966 7.86219 11.5563 7.86219 12C7.86219 12.4438 7.58579 12.8034 7.24475 12.8034C6.90372 12.8034 6.62732 12.4438 6.62732 12C6.62727 11.5563 6.90377 11.1966 7.24475 11.1966ZM1.65103 20.5753V3.40039H5.56075V20.5785L1.65103 20.5753Z" fill="currentColor"/>
     </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'UnsealedIcon')

export default icon
