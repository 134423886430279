import * as type from '@app/constants/users/geofenceUsers'

export const syncGeofenceUsers = () => ({
  type: type.SYNC_GEOFENCE_USERS,
})

export const updateGeofenceUsers = (users) => ({
  type: type.UPDATE_GEOFENCE_USERS,
  users,
})
