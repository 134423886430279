import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 819 1024" enableBackground="new 0 0 1024 1024" ><g><path d="M620.4,962.7H205.3c-12.4,0-22.8-9.7-23.7-22L148.3,482h529l-33.3,458.7C643.2,953,632.8,962.7,620.4,962.7z M169.9,502   l31.7,437.3c0.1,2,1.7,3.4,3.7,3.4h415.1c2,0,3.6-1.5,3.7-3.4L655.8,502H169.9z"/><path d="M572.4,906.9H253.2c-8.2,0-14.8-6.3-15.2-14.4L216.4,554c-0.3-4.2,1.2-8.3,4.1-11.4c2.9-3.1,6.9-4.8,11.1-4.8h362.5   c4.2,0,8.2,1.8,11.1,4.8c2.9,3.1,4.4,7.2,4.1,11.4l-21.6,338.6C587.1,900.7,580.4,906.9,572.4,906.9z M257.7,886.9H568l21-329.1   H236.7L257.7,886.9z"/><path d="M747,502H78.6c-5.2,0-9.8-3.4-11.3-8.4c-1.5-5,0.4-10.3,4.7-13.3l307.7-210.4h66.4l307.5,210.4c4.3,2.9,6.3,8.1,4.9,13.1   C756.9,498.5,752.3,502,747,502z M105,482h615.7L439.9,289.9h-54L105,482z"/><path d="M938.2,616.7H886c-10.5,0-19-8.5-19-19V80.3c0-10.5,8.5-19,19-19h52.3c10.5,0,19,8.5,19,19v517.5l0,0.1   C957.1,608.4,948.7,616.7,938.2,616.7z M887,596.7h50.3V81.3H887V596.7z"/><path d="M887,270.2L440.7,162.4l-60.1-13.3l-17.4-3.8c-6.4-1.3-11.1-7.1-11.1-13.8V97.7c0-7.8,6.3-14.1,14.1-14.1H887V270.2z    M372.2,126.8l12.7,2.8l60.4,13.3L867,244.8V103.6H372.2V126.8z"/><path d="M440.5,215.1h-55.2c-6.9,0-12.5-5.6-12.5-12.5v-75.7l80.2,17.7v57.9C453,209.5,447.4,215.1,440.5,215.1z M392.8,195.1H433   v-34.4l-40.2-8.9V195.1z"/><rect x="402.8" y="205.2" width="20" height="74.7"/><path d="M412.8,847.2c-48.1,0-106.9-73.5-106.9-159.3c0-57.5,27.8-77.8,56.4-93.5l2.3-1.3h96.7l2.3,1.3   c28.5,15.8,56.4,36,56.4,93.5C519.8,773.7,461,847.2,412.8,847.2z M369.7,613.1c-25,14.1-43.8,29.3-43.8,74.8   c0,76.9,52.6,139.3,86.9,139.3c34.4,0,87-62.4,87-139.3c0-45.5-18.8-60.7-43.8-74.8H369.7z"/><path d="M468.6,613.2H357.1v-75.4h111.5V613.2z M377.1,593.2h71.5v-35.4h-71.5V593.2z"/></g>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'OutdoorLightSecond')

export default icon
