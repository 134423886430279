import React, { useEffect, useState } from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import { useDispatch } from '@app/store'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  syncVirtualSwitches,
  unsubscribeVirtualSwitchChannel,
} from '@app/actions/virtualSwitches/virtualSwitches'
import { MY_DEVICES } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { IVirtualSwitches } from '@app/@types/virtualSwitches'
import useStyles from './VirtualSwitches.style'
import VirtualSwitchCard from './VirtualSwitchCard/VirtualSwitchCard'

interface Props {
  virtualSwitches: IVirtualSwitches,
  isMobile: boolean,
}

const VirtualSwitches: React.FC<Props> = (props) => {
  const { virtualSwitches, isMobile } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [virtualSwitchesList, setVirtualSwitchesList] = useState<IVirtualSwitches>(virtualSwitches)

  useEffect(() => setVirtualSwitchesList(virtualSwitches), [])

  useEffect(() => setVirtualSwitchesList(virtualSwitches), [virtualSwitches])

  useEffect(() => () => dispatch(unsubscribeVirtualSwitchChannel()), [])

  const goBack = () => navigate(MY_DEVICES)

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classes.iconWrapper}>
        <IconButton
          size="small"
          onClick={goBack}
          aria-label="Back"
        >
          <ScenesArrowBack />
        </IconButton>
      </Wrapper>
      {virtualSwitchesList.length ? (
        <Wrapper className={classNames(classes.cardsGrid, isMobile && classes.cardsGridMobile)}>
          {virtualSwitchesList.map((virtualSwitch, index) => (
            <VirtualSwitchCard
              key={`vs-${index + 1}`}
              virtualSwitch={virtualSwitch}
              isMobile={isMobile}
            />
          ))}
        </Wrapper>
      )
        : (
          <Wrapper className={classes.sync}>
            <Typography className={classes.message}>
              {`${I18n.t('virtual_switches.message_not_received')}`}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                dispatch(syncVirtualSwitches())
              }}
            >
              {I18n.t('buttons.try_again')}
            </Button>
          </Wrapper>
        )}
    </Wrapper>
  )
}

export default VirtualSwitches
