import * as type from '@app/constants/scenes/editScene'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/editScene'
import { Optional } from '@app/@types/common'
import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'

type IEditScene = ReturnType<InferValueTypes<typeof actions>>

interface IEditSceneState {
  isEditDraftSavePopupOpen: boolean,
  isEditSceneQuitPopupOpen: boolean,
  changesetAction: string,
  editedSceneInitialName: string,
  editedSceneInitialConditions: Optional<Array<IActionFromBackend>>,
  editedSceneInitialActions: Optional<Array<IConditionFromBackend>>,
  initialSceneId: number,
}

const initialState = {
  isEditDraftSavePopupOpen: false,
  isEditSceneQuitPopupOpen: false,
  changesetAction: '',
  editedSceneInitialName: '',
  editedSceneInitialConditions: [],
  editedSceneInitialActions: [],
  initialSceneId: 0,
}

export default function editScene(state: IEditSceneState = initialState, action: IEditScene) {
  switch (action.type) {
  case type.OPEN_EDIT_DRAFT_SAVE_POPUP: {
    return {
      ...state,
      isEditDraftSavePopupOpen: true,
    }
  }
  case type.CLOSE_EDIT_DRAFT_SAVE_POPUP: {
    return {
      ...state,
      isEditDraftSavePopupOpen: false,
    }
  }

  case type.OPEN_EDIT_SCENE_QUIT_POPUP: {
    return {
      ...state,
      isEditSceneQuitPopupOpen: true,
    }
  }
  case type.CLOSE_EDIT_SCENE_QUIT_POPUP: {
    return {
      ...state,
      isEditSceneQuitPopupOpen: false,
    }
  }

  case type.SAVE_CHANGESET_ACTION: {
    return {
      ...state,
      changesetAction: action.changesetAction,
    }
  }

  case type.SAVE_EDITED_SCENE_INITIAL_NAME: {
    return {
      ...state,
      editedSceneInitialName: action.sceneName,
    }
  }
  case type.SAVE_EDITED_SCENE_INITIAL_CONDITIONS: {
    return {
      ...state,
      editedSceneInitialConditions: action.conditions,
    }
  }
  case type.SAVE_EDITED_SCENE_INITIAL_ACTIONS: {
    return {
      ...state,
      editedSceneInitialActions: action.actions,
    }
  }

  case type.SELECT_SCENE_INITIAL_ID: {
    return {
      ...state,
      initialSceneId: action.id,
    }
  }
  case type.RESET_EDIT_SCENE:
    return initialState

  default:
    return state
  }
}
