import update from 'immutability-helper'
import * as type from '@app/constants/units'

const initialState = {
  devices: [],
}

export default function units(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_UNITS:
    return update(state, {
      devices: { $set: action.devices },
    })

  case type.UPDATE_UNIT_NAME:
    return update(state, {
      devices: {
        $apply: (devices) => devices.map((device) => {
          if (device.serial_number === action.unit.serial_number) {
            return {
              ...device,
              name: action.unit.name,
            }
          }
          return device
        }),
      },
    })

  case type.RESET_UNITS:
    return initialState

  default:
    return state
  }
}
