import React from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton,
} from '@mui/material'
import { Close } from 'icons'
import useStyles from './WarningComponent.style'
import Wrapper from '../Wrapper/Wrapper'

interface Props {
  open: boolean,
  onClose: () => void,
  onClick: () => void,
  warningText: string,
  leftButton: string,
  rightButton: string,
  onCloseIconClick?: () => void,
  disableButton?: boolean,
  withCloseIcon?: boolean,
  ssid?: string,
  title?: string,
  additionalWarningText?: string,
  untranslatableError?: boolean,
}

const WarningComponent: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onClick,
    withCloseIcon,
    onCloseIconClick,
    warningText,
    leftButton,
    rightButton,
    disableButton,
    ssid,
    title,
    untranslatableError,
    additionalWarningText,
  } = props
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle
        className={classes.title}
      >
        {title || I18n.t('titles.warning') }
      </DialogTitle>
      {withCloseIcon && (
        <IconButton
          aria-label="Close"
          className={classes.closeIconButton}
          onClick={onCloseIconClick}
          size="large"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
      <DialogContent>
        <DialogContentText
          variant="body1"
          className={classes.text}
        >
          {`${untranslatableError ? warningText : I18n.t(`${warningText}`).replace('%d', ssid)}
            ${additionalWarningText}`}
        </DialogContentText>
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          color="primary"
          className={classes.button}
          variant="contained"
          onClick={onClick}
          disabled={disableButton}
        >
          {I18n.t(`buttons.${leftButton}`)}
        </Button>
        <Button
          color="primary"
          className={classes.button}
          onClick={onClose}
        >
          {I18n.t(`buttons.${rightButton}`)}
        </Button>
      </Wrapper>
    </Dialog>
  )
}

WarningComponent.defaultProps = {
  disableButton: false,
  withCloseIcon: false,
  onCloseIconClick: () => {},
  untranslatableError: false,
  ssid: 'My_Mezzo-XXXX',
  title: '',
  additionalWarningText: '',
}

export default WarningComponent
