import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/sphere_users/reset_password`

const sendCodeToEmail = (accessToken, email) => Axios.post(ENDPOINT, email, {
  headers: { 'Access-Token': accessToken },
})

export default {
  sendCodeToEmail,
}
