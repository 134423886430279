import type { Node } from 'react'
import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import {
  Chip, ListItem, Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import useStyles from './EventCard.style'

const EventCard = ({ event }): Node => {
  const classes = useStyles()
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const [isExpanded, setIsExpanded] = useState(false)

  const badgeClass = (eventStatus) => {
    switch (eventStatus) {
    case 'snoozed':
      return classes.badgeWarning
    case 'taken':
      return classes.badgeSuccess
    case 'skipped':
      return classes.badgeDanger
    case 'skipped_by_system':
      return classes.badgeDanger
    default:
      return classes.badgeInfo
    }
  }

  const eventDescription = (name, time, amount) => {
    const date = new Date(time)
    const formattedTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return I18n.t('my_reminders.event_description', { name, time: formattedTime, amount })
  }

  const handleCardExpand = () => setIsExpanded((prevState) => !prevState)

  return (
    <ListItem
      className={classNames(
        isMobile ? classes.cardMobile : classes.card,
        isExpanded && classes.cardExpanded,
      )}
      onClick={handleCardExpand}
    >
      <Typography
        className={classNames(
          isMobile ? classes.cardTitleMobile : classes.cardTitle,
          isExpanded && classes.expanded,
        )}
      >
        {eventDescription(event.reminder_name, event.dosage_time, event.dosage_amount)}
      </Typography>
      <Chip
        variant="outlined"
        className={badgeClass(event.status)}
        label={I18n.t(`my_reminders.status_${event.status}`)}
      />
    </ListItem>
  )
}

EventCard.propTypes = {
  event: PropTypes.shape({
    status: PropTypes.string.isRequired,
    reminder_name: PropTypes.string.isRequired,
    dosage_time: PropTypes.string.isRequired,
    dosage_amount: PropTypes.number.isRequired,
  }).isRequired,
}

export default EventCard
