import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2317 2317"
      id="vector">
      <g
        id="group"
        transform="translate(64 72)">
        <path
          id="path"
          d="M 1066.5 790 L 1070.5 795 L 1066.5 790 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_1"
          d="M 194.5 812 L 192.5 815 L 194.5 812 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_2"
          d="M 1216.5 884 L 1212.5 889 L 1216.5 884 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_3"
          d="M 1482.5 993 L 1483.5 995 L 1482.5 993 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_4"
          d="M 1580.5 994 L 1577.5 998 L 1580.5 994 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_5"
          d="M 1486.5 997 L 1487.5 999 L 1486.5 997 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_6"
          d="M 1322.5 1046 L 1325.5 1050 L 1322.5 1046 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_7"
          d="M 1485.5 1047 L 1484.5 1049 L 1485.5 1047 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_8"
          d="M 1850.5 1156 L 1849.5 1158 L 1850.5 1156 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_9"
          d="M 192.5 1885 L 194.5 1888 L 192.5 1885 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_10"
          d="M 1739.5 1979 L 1740.5 1981 L 1739.5 1979 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_11"
          d="M 9.5 2176 L 12.5 2180 L 9.5 2176 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_12"
          d="M 1030.5 2176 L 1033.5 2180 L 1030.5 2176 Z"
          fill="currentColor"
          fillOpacity="0.3960784"
          stroke="currentColor"
          strokeOpacity="0.3960784"
          strokeWidth="1"/>
        <path
          id="path_13"
          d="M 9.5 13 L 8.5 15 L 9.5 13 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_14"
          d="M 0.5 35 L 1 2154.5 L 0 2154.5 L 0.5 35 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_15"
          d="M 875.5 74 L 876 2115.5 L 875 2115.5 L 875.5 74 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_16"
          d="M 2188.5 108 L 2189 1789.5 L 2188 1789.5 L 2188.5 108 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_17"
          d="M 218.5 219 L 732 219.5 L 218.5 220 L 218.5 219 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_18"
          d="M 949.5 219 L 2008 219.5 L 949.5 220 L 949.5 219 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_19"
          d="M 192.5 231 L 191.5 233 L 192.5 231 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_20"
          d="M 2042.5 252 L 2043 255.5 L 2042 255.5 L 2042.5 252 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_21"
          d="M 766.5 254 L 767 403.5 L 766 403.5 L 766.5 254 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_22"
          d="M 1969.5 293 L 1970 1604.5 L 1969 1604.5 L 1969.5 293 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_23"
          d="M 256.5 365 L 693 365.5 L 256.5 366 L 256.5 365 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_24"
          d="M 1091.5 365 L 1827 365.5 L 1091.5 366 L 1091.5 365 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_25"
          d="M 1071.5 373 L 1070.5 375 L 1071.5 373 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_26"
          d="M 216.5 438 L 734 438.5 L 216.5 439 L 216.5 438 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_27"
          d="M 1131.5 438 L 1787 438.5 L 1131.5 439 L 1131.5 438 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_28"
          d="M 1851.5 790 L 1847.5 795 L 1851.5 790 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_29"
          d="M 754.5 812 L 757.5 816 L 754.5 812 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_30"
          d="M 766.5 837 L 767 1862.5 L 766 1862.5 L 766.5 837 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_31"
          d="M 474.5 876 L 475 1057.5 L 474 1057.5 L 474.5 876 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_32"
          d="M 1848.5 885 L 1850.5 888 L 1848.5 885 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_33"
          d="M 1737.5 993 L 1738.5 995 L 1737.5 993 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_34"
          d="M 1325.5 994 L 1321.5 999 L 1325.5 994 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_35"
          d="M 1484.5 995 L 1485.5 997 L 1484.5 995 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_36"
          d="M 1568.5 1018 L 1569 1025.5 L 1568 1025.5 L 1568.5 1018 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_37"
          d="M 1495.5 1024 L 1496 1026.5 L 1495 1026.5 L 1495.5 1024 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_38"
          d="M 1741.5 1046 L 1739.5 1049 L 1741.5 1046 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_39"
          d="M 1347.5 1058 L 1461 1058.5 L 1347.5 1059 L 1347.5 1058 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_40"
          d="M 1603.5 1058 L 1717 1058.5 L 1603.5 1059 L 1603.5 1058 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_41"
          d="M 378.5 1066 L 377.5 1068 L 378.5 1066 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_42"
          d="M 438.5 1131 L 512 1131.5 L 438.5 1132 L 438.5 1131 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_43"
          d="M 1457.5 1240 L 1826 1240.5 L 1457.5 1241 L 1457.5 1240 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_44"
          d="M 429.5 1264 L 425.5 1269 L 429.5 1264 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_45"
          d="M 1422.5 1275 L 1423 1424.5 L 1422 1424.5 L 1422.5 1275 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_46"
          d="M 560.5 1285 L 556.5 1290 L 560.5 1285 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_47"
          d="M 547.5 1310 L 548 1535.5 L 547 1535.5 L 547.5 1310 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_48"
          d="M 620.5 1350 L 621 1495.5 L 620 1495.5 L 620.5 1350 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_49"
          d="M 1496.5 1386 L 1787 1386.5 L 1496.5 1387 L 1496.5 1386 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_50"
          d="M 1456.5 1459 L 1827 1459.5 L 1456.5 1460 L 1456.5 1459 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_51"
          d="M 556.5 1556 L 559.5 1560 L 556.5 1556 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_52"
          d="M 401.5 1569 L 402 1823.5 L 401 1823.5 L 401.5 1569 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_53"
          d="M 2042.5 1642 L 2043 1645.5 L 2042 1645.5 L 2042.5 1642 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_54"
          d="M 2180.5 1810 L 2179.5 1812 L 2180.5 1810 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_55"
          d="M 757.5 1884 L 753.5 1889 L 757.5 1884 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_56"
          d="M 1021.5 2003 L 1022 2155.5 L 1021 2155.5 L 1021.5 2003 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_57"
          d="M 1094.5 2043 L 1095 2115.5 L 1094 2115.5 L 1094.5 2043 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_58"
          d="M 939.5 2176 L 935.5 2181 L 939.5 2176 Z"
          fill="currentColor"
          fillOpacity="0.5019608"
          stroke="currentColor"
          strokeOpacity="0.5019608"
          strokeWidth="1"/>
        <path
          id="path_59"
          d="M 34.5 0 L 915 0.5 L 34.5 1 L 34.5 0 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_60"
          d="M 936.5 9 L 939.5 13 L 936.5 9 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_61"
          d="M 949.5 73 L 2155 73.5 L 949.5 74 L 949.5 73 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_62"
          d="M 214.5 219 L 217 219.5 L 214.5 220 L 214.5 219 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_63"
          d="M 732.5 219 L 735 219.5 L 732.5 220 L 732.5 219 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_64"
          d="M 2008.5 219 L 2011 219.5 L 2008.5 220 L 2008.5 219 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_65"
          d="M 2030.5 228 L 2033.5 232 L 2030.5 228 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_66"
          d="M 755.5 229 L 756.5 231 L 755.5 229 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_67"
          d="M 182.5 254 L 183 403.5 L 182 403.5 L 182.5 254 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_68"
          d="M 1849.5 375 L 1850.5 377 L 1849.5 375 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_69"
          d="M 1860.5 398 L 1861 770.5 L 1860 770.5 L 1860.5 398 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_70"
          d="M 757.5 426 L 753.5 431 L 757.5 426 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_71"
          d="M 1092.5 803 L 1827 803.5 L 1092.5 804 L 1092.5 803 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_72"
          d="M 196.5 810 L 195.5 812 L 196.5 810 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_73"
          d="M 191.5 815 L 190.5 817 L 191.5 815 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_74"
          d="M 766.5 834 L 767 836.5 L 766 836.5 L 766.5 834 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_75"
          d="M 182.5 837 L 183 1862.5 L 182 1862.5 L 182.5 837 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_76"
          d="M 256.5 876 L 474 876.5 L 256.5 877 L 256.5 876 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_77"
          d="M 548.5 876 L 693 876.5 L 548.5 877 L 548.5 876 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_78"
          d="M 1852.5 889 L 1853.5 891 L 1852.5 889 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_79"
          d="M 1860.5 908 L 1861 1134.5 L 1860 1134.5 L 1860.5 908 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_80"
          d="M 1203.5 909 L 1204 1133.5 L 1203 1133.5 L 1203.5 909 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_81"
          d="M 1735.5 991 L 1736.5 993 L 1735.5 991 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_82"
          d="M 1582.5 992 L 1581.5 994 L 1582.5 992 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_83"
          d="M 1576.5 998 L 1575.5 1000 L 1576.5 998 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_84"
          d="M 1320.5 1044 L 1321.5 1046 L 1320.5 1044 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_85"
          d="M 1487.5 1045 L 1486.5 1047 L 1487.5 1045 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_86"
          d="M 1576.5 1045 L 1580.5 1050 L 1576.5 1045 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_87"
          d="M 1483.5 1049 L 1482.5 1051 L 1483.5 1049 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_88"
          d="M 1737.5 1050 L 1736.5 1052 L 1737.5 1050 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_89"
          d="M 1717.5 1058 L 1720 1058.5 L 1717.5 1059 L 1717.5 1058 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_90"
          d="M 1277.5 1094 L 1787 1094.5 L 1277.5 1095 L 1277.5 1094 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_91"
          d="M 539.5 1118 L 535.5 1123 L 539.5 1118 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_92"
          d="M 512.5 1131 L 516 1131.5 L 512.5 1132 L 512.5 1131 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_93"
          d="M 438.5 1132 L 439 1243.5 L 438 1243.5 L 438.5 1132 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_94"
          d="M 1852.5 1154 L 1851.5 1156 L 1852.5 1154 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_95"
          d="M 1212.5 1155 L 1216.5 1160 L 1212.5 1155 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_96"
          d="M 1848.5 1158 L 1847.5 1160 L 1848.5 1158 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_97"
          d="M 1434.5 1249 L 1431.5 1253 L 1434.5 1249 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_98"
          d="M 1853.5 1255 L 1854.5 1257 L 1853.5 1255 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_99"
          d="M 373.5 1264 L 374.5 1266 L 373.5 1264 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_100"
          d="M 376.5 1267 L 377.5 1269 L 376.5 1267 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_101"
          d="M 1860.5 1273 L 1861 1426.5 L 1860 1426.5 L 1860.5 1273 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_102"
          d="M 399.5 1277 L 405 1277.5 L 399.5 1278 L 399.5 1277 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_103"
          d="M 562.5 1283 L 561.5 1285 L 562.5 1283 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_104"
          d="M 621.5 1350 L 693 1350.5 L 621.5 1351 L 621.5 1350 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_105"
          d="M 1431.5 1447 L 1434.5 1451 L 1431.5 1447 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_106"
          d="M 1850.5 1448 L 1849.5 1450 L 1850.5 1448 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_107"
          d="M 256.5 1495 L 441 1495.5 L 256.5 1496 L 256.5 1495 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_108"
          d="M 621.5 1495 L 693 1495.5 L 621.5 1496 L 621.5 1495 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_109"
          d="M 462.5 1504 L 465.5 1508 L 462.5 1504 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_110"
          d="M 2033.5 1666 L 2030.5 1670 L 2033.5 1666 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_111"
          d="M 949.5 1678 L 2010 1678.5 L 949.5 1679 L 949.5 1678 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_112"
          d="M 949.5 1824 L 1203 1824.5 L 949.5 1825 L 949.5 1824 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_113"
          d="M 1283.5 1824 L 1488.5 1824 L 1490 1825.5 L 1488.5 1825 L 1283.5 1825 L 1283.5 1824 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_114"
          d="M 1569 1824 L 2154 1824.5 L 1570.5 1825 L 1569.5 1826 L 1569 1824 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_115"
          d="M 766.5 1863 L 767 1865.5 L 766 1865.5 L 766.5 1863 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_116"
          d="M 190.5 1883 L 191.5 1885 L 190.5 1883 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_117"
          d="M 195.5 1888 L 196.5 1890 L 195.5 1888 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_118"
          d="M 1055.5 1969 L 1143 1969.5 L 1055.5 1970 L 1055.5 1969 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_119"
          d="M 1223.5 1969 L 1550 1969.5 L 1223.5 1970 L 1223.5 1969 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_120"
          d="M 1630.5 1969 L 1717 1969.5 L 1630.5 1970 L 1630.5 1969 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_121"
          d="M 1737.5 1977 L 1738.5 1979 L 1737.5 1977 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_122"
          d="M 1741.5 1981 L 1742.5 1983 L 1741.5 1981 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_123"
          d="M 1677.5 2043 L 1678 2114.5 L 1676.5 2116 L 1095.5 2116 L 1095.5 2115 L 1676.5 2115 L 1677 2114.5 L 1677.5 2043 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_124"
          d="M 74.5 2115 L 875 2115.5 L 74.5 2116 L 74.5 2115 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_125"
          d="M 1028.5 2174 L 1029.5 2176 L 1028.5 2174 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_126"
          d="M 13.5 2180 L 14.5 2182 L 13.5 2180 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_127"
          d="M 1034.5 2180 L 1035.5 2182 L 1034.5 2180 Z"
          fill="currentColor"
          fillOpacity="0.2470588"
          stroke="currentColor"
          strokeOpacity="0.2470588"
          strokeWidth="1"/>
        <path
          id="path_128"
          d="M 934.5 8 L 935.5 10 L 934.5 8 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_129"
          d="M 12.5 10 L 10.5 13 L 12.5 10 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_130"
          d="M 948.5 34 L 949 73.5 L 948 73.5 L 948.5 34 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_131"
          d="M 73.5 74 L 74 2115.5 L 73 2115.5 L 73.5 74 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_132"
          d="M 2175.5 82 L 2179.5 87 L 2175.5 82 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_133"
          d="M 948.5 147 L 949 218.5 L 948 218.5 L 948.5 147 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_134"
          d="M 2115.5 147 L 2116 1750.5 L 2115 1750.5 L 2115.5 147 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_135"
          d="M 194.5 229 L 193.5 231 L 194.5 229 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_136"
          d="M 2042.5 256 L 2043 1641.5 L 2042 1641.5 L 2042.5 256 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_137"
          d="M 256.5 292 L 692.5 292 L 694 293.5 L 693.5 365 L 693 293 L 256.5 293 L 256.5 292 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_138"
          d="M 949.5 292 L 1969 292.5 L 949 293 L 949 1605 L 1968.5 1605 L 1968.5 1606 L 949.5 1606 L 948 1604.5 L 948 293.5 L 949.5 292 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_139"
          d="M 1846.5 373 L 1847.5 375 L 1846.5 373 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_140"
          d="M 1069.5 375 L 1067.5 378 L 1069.5 375 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_141"
          d="M 191.5 425 L 195.5 430 L 191.5 425 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_142"
          d="M 1787.5 439 L 1788 729.5 L 1787 729.5 L 1787.5 439 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_143"
          d="M 547.5 876 L 548 1096.5 L 547 1096.5 L 547.5 876 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_144"
          d="M 693.5 876 L 694 1276.5 L 693 1276.5 L 693.5 876 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_145"
          d="M 1787.5 949 L 1788 1094.5 L 1787 1094.5 L 1787.5 949 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_146"
          d="M 1347.5 985 L 1462 985.5 L 1347.5 986 L 1347.5 985 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_147"
          d="M 1602.5 985 L 1717 985.5 L 1602.5 986 L 1602.5 985 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_148"
          d="M 1739.5 995 L 1741.5 998 L 1739.5 995 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_149"
          d="M 1313.5 1018 L 1314 1025.5 L 1313 1025.5 L 1313.5 1018 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_150"
          d="M 1495.5 1019 L 1496 1023.5 L 1495 1023.5 L 1495.5 1019 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_151"
          d="M 1750.5 1024 L 1751 1026.5 L 1750 1026.5 L 1750.5 1024 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_152"
          d="M 398.5 1058 L 474 1058.5 L 398.5 1059 L 398.5 1058 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_153"
          d="M 376.5 1068 L 374.5 1071 L 376.5 1068 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_154"
          d="M 365.5 1090 L 366 1093.5 L 365 1093.5 L 365.5 1090 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_155"
          d="M 365.5 1095 L 366 1244.5 L 365 1244.5 L 365.5 1095 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_156"
          d="M 1217.5 1159 L 1218.5 1161 L 1217.5 1159 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_157"
          d="M 1236.5 1167 L 1828 1167.5 L 1236.5 1168 L 1236.5 1167 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_158"
          d="M 1436.5 1248 L 1435.5 1250 L 1436.5 1248 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_159"
          d="M 1847.5 1249 L 1851.5 1254 L 1847.5 1249 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_160"
          d="M 1431.5 1253 L 1430.5 1255 L 1431.5 1253 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_161"
          d="M 1496.5 1313 L 1786.5 1313 L 1788 1314.5 L 1787.5 1386 L 1787 1314 L 1496.5 1314 L 1496 1314.5 L 1496 1385.5 L 1495 1385.5 L 1495 1314.5 L 1496.5 1313 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_162"
          d="M 693.5 1350 L 694 1495.5 L 693 1495.5 L 693.5 1350 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_163"
          d="M 1851.5 1446 L 1850.5 1448 L 1851.5 1446 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_164"
          d="M 1847.5 1450 L 1846.5 1452 L 1847.5 1450 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_165"
          d="M 460.5 1503 L 461.5 1505 L 460.5 1503 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_166"
          d="M 474.5 1529 L 475 1823.5 L 474 1823.5 L 474.5 1529 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_167"
          d="M 579.5 1568 L 582 1568.5 L 579.5 1569 L 579.5 1568 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_168"
          d="M 693.5 1569 L 694 1823.5 L 693 1823.5 L 693.5 1569 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_169"
          d="M 2034.5 1664 L 2033.5 1666 L 2034.5 1664 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_170"
          d="M 2029.5 1669 L 2028.5 1671 L 2029.5 1669 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_171"
          d="M 948.5 1678 L 949 1750.5 L 948 1750.5 L 948.5 1678 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_172"
          d="M 2178.5 1812 L 2176.5 1815 L 2178.5 1812 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_173"
          d="M 948.5 1824 L 949 2155.5 L 948 2155.5 L 948.5 1824 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_174"
          d="M 1034.5 1978 L 1030.5 1983 L 1034.5 1978 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_175"
          d="M 1750.5 2001 L 1751 2003.5 L 1750 2003.5 L 1750.5 2001 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_176"
          d="M 1741.5 2176 L 1738.5 2180 L 1741.5 2176 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_177"
          d="M 33.5 2188 L 916 2188.5 L 33.5 2189 L 33.5 2188 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_178"
          d="M 1054.5 2188 L 1718 2188.5 L 1054.5 2189 L 1054.5 2188 Z"
          fill="currentColor"
          fillOpacity="0.7490196"
          stroke="currentColor"
          strokeOpacity="0.7490196"
          strokeWidth="1"/>
        <path
          id="path_179"
          d="M 936.5 10 L 939.5 14 L 936.5 10 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_180"
          d="M 2029.5 228 L 2033.5 233 L 2029.5 228 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_181"
          d="M 754.5 229 L 756.5 232 L 754.5 229 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_182"
          d="M 1848.5 375 L 1850.5 378 L 1848.5 375 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_183"
          d="M 757.5 425 L 753.5 430 L 757.5 425 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_184"
          d="M 1071.5 794 L 1072.5 796 L 1071.5 794 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_185"
          d="M 1218.5 883 L 1217.5 885 L 1218.5 883 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_186"
          d="M 1487.5 1044 L 1486.5 1046 L 1487.5 1044 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_187"
          d="M 1577.5 1045 L 1581.5 1050 L 1577.5 1045 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_188"
          d="M 538.5 1118 L 534.5 1123 L 538.5 1118 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_189"
          d="M 1212.5 1154 L 1216.5 1159 L 1212.5 1154 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_190"
          d="M 1847.5 1158 L 1846.5 1160 L 1847.5 1158 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_191"
          d="M 1434.5 1250 L 1432.5 1253 L 1434.5 1250 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_192"
          d="M 373.5 1263 L 375.5 1266 L 373.5 1263 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_193"
          d="M 377.5 1267 L 378.5 1269 L 377.5 1267 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_194"
          d="M 1431.5 1446 L 1435.5 1451 L 1431.5 1446 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_195"
          d="M 1849.5 1448 L 1848.5 1450 L 1849.5 1448 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_196"
          d="M 462.5 1505 L 465.5 1509 L 462.5 1505 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_197"
          d="M 2032.5 1666 L 2030.5 1669 L 2032.5 1666 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_198"
          d="M 190.5 1882 L 191.5 1884 L 190.5 1882 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_199"
          d="M 1737.5 1978 L 1738.5 1980 L 1737.5 1978 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_200"
          d="M 1741.5 1982 L 1742.5 1984 L 1741.5 1982 Z"
          fill="currentColor"
          fillOpacity="0.9098039"
          stroke="currentColor"
          strokeOpacity="0.9098039"
          strokeWidth="1"/>
        <path
          id="path_201"
          d="M 32.5 1 L 916.5 1 Q 933.6 4.4 942 16.5 L 948 30.5 L 948 74 L 2156.5 74 Q 2172.9 77.1 2181 88.5 Q 2186.1 94.9 2188 104.5 L 2188 1793.5 Q 2184.5 1809 2173.5 1817 L 2166.5 1821 L 2155.5 1824 L 1568 1824.5 L 1629.5 1970 L 1718.5 1970 Q 1732.9 1972.6 1741 1981.5 L 1749 1994.5 L 1751 2004.5 L 1751 2153.5 Q 1748.5 2155 1750 2160.5 L 1746 2170.5 L 1736.5 2181 Q 1730.7 2186.2 1721.5 2188 L 1050.5 2188 Q 1037.4 2184.6 1030 2175.5 Q 1024.1 2168.9 1022 2158.5 L 1022 2000.5 Q 1025.5 1985 1036.5 1977 Q 1043.1 1971.6 1053.5 1970 L 1144 1970 L 1204 1824 L 948 1824 L 948 2159.5 Q 944.4 2173.4 934.5 2181 L 919.5 2188 L 29.5 2188 Q 16.4 2184.6 9 2175.5 Q 3.1 2168.9 1 2158.5 L 1 31.5 Q 4.5 16 15.5 8 L 24.5 3 L 32.5 1 Z M 73 74 L 73 2116 L 876 2116 L 876 74 L 73 74 Z M 948 147 L 948 220 L 2012 220 Q 2021 221 2027 226 Q 2038 234 2042 250 L 2042 1649 L 2036 1663 Q 2028 1675 2012 1678 L 948 1678 L 948 1751 L 949 1751 L 2116 1751 L 2116 147 L 948 147 Z M 948 292 L 948 1606 L 1970 1606 L 1970 292 L 948 292 Z M 1282 1824 L 1222 1969 L 1223 1970 L 1550 1970 L 1548 1963 L 1490 1826 L 1490 1824 L 1282 1824 Z M 1094 2043 L 1094 2116 L 1095 2116 L 1678 2116 L 1678 2044 L 1678 2043 L 1094 2043 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_202"
          d="M 213.5 220 L 735.5 220 Q 751.7 223.8 760 235.5 L 766 250.5 L 766 407.5 Q 762.5 424 750.5 432 L 736.5 438 L 212.5 438 Q 197.9 434.1 190 423.5 L 185 414.5 L 183 406.5 L 183 252.5 Q 185.8 235.3 197.5 227 Q 203.9 221.9 213.5 220 Z M 256 292 L 256 366 L 257 366 L 694 366 L 694 293 L 694 292 L 256 292 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_203"
          d="M 1088.5 366 L 1830.5 366 Q 1845.5 369.5 1853 380.5 Q 1858.1 386.9 1860 396.5 L 1860 771.5 Q 1857.1 787.1 1846.5 795 Q 1839.5 801 1828.5 803 L 1090.5 803 Q 1074.9 800.6 1067 790.5 L 1060 778.5 L 1058 769.5 L 1058 398.5 Q 1060.8 381.3 1072.5 373 Q 1078.9 367.9 1088.5 366 Z M 1131 438 L 1131 730 L 1788 730 L 1788 438 L 1131 438 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_204"
          d="M 216.5 803 L 732.5 803 L 746.5 807 L 758 816.5 Q 763.9 823.1 766 833.5 L 766 1866.5 Q 762.8 1881.3 752.5 1889 L 744.5 1894 L 733.5 1897 L 216.5 1897 L 207.5 1895 L 199.5 1891 L 192 1884.5 Q 185.1 1876.9 183 1864.5 L 183 835.5 Q 185.8 820.3 195.5 812 L 207.5 805 L 216.5 803 Z M 256 876 L 256 1496 L 443 1496 Q 452 1498 459 1502 Q 470 1510 474 1526 L 474 1824 L 694 1824 L 694 1569 L 583 1569 L 573 1567 L 562 1561 Q 551 1553 548 1539 L 548 1308 Q 550 1298 555 1292 L 568 1281 L 582 1277 L 694 1277 L 694 876 L 547 876 L 547 1102 L 541 1116 Q 533 1128 517 1131 L 438 1131 L 438 1246 L 436 1254 L 430 1264 Q 422 1274 407 1277 L 398 1277 L 390 1275 L 376 1267 L 370 1259 L 366 1249 L 366 1088 Q 368 1078 373 1073 Q 381 1063 395 1059 L 475 1059 L 475 876 L 256 876 Z M 620 1350 L 620 1496 L 694 1496 L 694 1350 L 620 1350 Z M 256 1569 L 256 1824 L 402 1824 L 402 1569 L 256 1569 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_205"
          d="M 1237.5 876 L 1827.5 876 Q 1843.9 878.6 1852 889.5 Q 1858 896.5 1860 907.5 L 1860 1136.5 Q 1857.4 1150.4 1848.5 1158 Q 1842 1164.5 1831.5 1167 L 1232.5 1167 Q 1217.8 1162.8 1210 1151.5 Q 1205.5 1145 1204 1135.5 L 1204 908.5 Q 1206.4 892.9 1216.5 885 L 1228.5 878 L 1237.5 876 Z M 1277 949 L 1277 1095 L 1278 1095 L 1788 1095 L 1788 950 L 1788 949 L 1277 949 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_206"
          d="M 1342.5 986 L 1466.5 986 Q 1479.6 989.4 1487 998.5 L 1495 1014.5 L 1495 1028.5 Q 1492 1041.5 1483.5 1049 Q 1476.4 1055.9 1464.5 1058 L 1343.5 1058 Q 1329.7 1054.8 1322 1045.5 L 1314 1029.5 L 1314 1014.5 Q 1317.6 1000.6 1327.5 993 L 1342.5 986 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_207"
          d="M 1597.5 986 L 1721.5 986 Q 1735.4 989.6 1743 999.5 Q 1750.6 1007.9 1751 1023.5 L 1749 1033.5 L 1745 1041.5 L 1737.5 1050 Q 1730.9 1055.9 1720.5 1058 L 1599.5 1058 Q 1583 1054.5 1575 1042.5 L 1569 1028.5 L 1569 1015.5 Q 1572 1001.5 1581.5 994 L 1597.5 986 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
        <path
          id="path_208"
          d="M 1453.5 1241 L 1829.5 1241 Q 1845 1244.5 1853 1255.5 Q 1858.4 1262.1 1860 1272.5 L 1860 1428.5 Q 1856.2 1444.7 1844.5 1453 L 1830.5 1459 L 1452.5 1459 Q 1436.6 1455.4 1429 1443.5 Q 1424.5 1437.5 1423 1428.5 L 1423 1271.5 Q 1426.5 1255 1438.5 1247 L 1453.5 1241 Z M 1495 1313 L 1495 1387 L 1496 1387 L 1788 1387 L 1788 1314 L 1788 1313 L 1495 1313 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ComputerThird')

export default icon
