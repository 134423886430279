import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { HOME } from '@app/constants/routes'
import styles from './NotFound.style'
import Wrapper from '../Common/Wrapper/Wrapper'

class NotFound extends React.PureComponent {
  render() {
    const {
      classes,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Typography
          variant="h2"
          className={classes.title}
        >
          {I18n.t('sphere.error_404')}
        </Typography>
        <Typography
          variant="h5"
          className={classes.subtitle}
        >
          {I18n.t('sphere.page_not_found')}
        </Typography>
        <Typography
          variant="h6"
          className={classes.text}
        >
          {I18n.t('sphere.page_does_not_exist')}
        </Typography>
        <Typography
          variant="h6"
          className={classes.text}
        >
          {I18n.t('sphere.page_have_moved')}
        </Typography>
        <Wrapper
          className={classes.wrapper}
        >
          <Typography
            variant="h6"
            className={classes.Label}
          >
            {I18n.t('sphere.you_can_go_to')}
          </Typography>
          <Button
            className={classes.button}
            onClick={() => {
              push(HOME)
            }}
          >
            {I18n.t('sphere.return_homepage')}
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NotFound)
