import * as type from '@app/constants/scenes/zWaveDevices/zWaveDevicesByGroups'
import { IGroupZwaveBackend } from '@app/@types/scenes/scenesDevices'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/zWaveDevices/zWaveDevicesByGroups'

type IZWaveDevicesByGroups = ReturnType<InferValueTypes<typeof actions>>

interface State {
groups: Array<IGroupZwaveBackend>,
}
const initialState = {
  groups: [],
}

export default function zWaveDevicesByGroups(state: State = initialState, action: IZWaveDevicesByGroups): State {
  switch (action.type) {
  case type.RESET_ZWAVE_DEVICES_BY_GROUPS:
    return initialState

  case type.UPDATE_ZWAVE_DEVICES_BY_GROUPS: {
    return {
      ...state,
      groups: action.groups,
    }
  }

  default:
    return state
  }
}
