import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
      <rect x="0" y="0" fill="none" stroke="none" />
      <path fill="currentColor" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ContentCopyIcon')

export default icon
