import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { choosePeriod, chooseSliderTab, sync } from '@app/actions/myEnergy/energyTab'
import EnergyTab from '@app/components/MyEnergy/Tabs/EnergyTab/EnergyTab'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'

let enhance = withStyles(EnergyTab)

const mapStateToProps = (state) => {
  const { periodSelector } = state.energyTab
  const energies = state.energyTab.allEnergies
  const { selectedSliderTab } = state.energyTab
  const { isMobile } = state.mobileDetector
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    periodSelector,
    isMobile,
    energies,
    selectedSliderTab,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
  chooseSliderTab,
  choosePeriod,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const EnergyTabEnhanced = enhance

export default EnergyTabEnhanced
