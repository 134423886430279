import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M42.7811 8C40.9826 8 39.2578 8.71443 37.9861 9.98613C36.7144 11.2578 36 12.9826 36 14.7811V129.841C36 131.398 36.7657 132.777 37.9411 133.621C37.8879 133.902 37.8601 134.193 37.8601 134.49V135.73C37.8601 138.182 39.5675 139.479 40.0903 139.829C40.7813 140.293 41.4748 140.525 41.9196 140.649C42.86 140.91 43.8709 141 44.7427 141H51.4392C52.3109 141 53.3218 140.91 54.2622 140.649C54.7071 140.525 55.4005 140.293 56.0915 139.829C56.6144 139.479 58.3217 138.182 58.3217 135.73V134.491H91.8042V135.73C91.8042 137.995 93.2725 139.287 93.8645 139.72C94.5544 140.225 95.254 140.482 95.7052 140.619C96.6497 140.905 97.651 141 98.5007 141H104.639C105.489 141 106.49 140.905 107.435 140.619C107.886 140.482 108.585 140.225 109.275 139.72C109.867 139.287 111.336 137.995 111.336 135.73V134.49C111.336 134.253 111.318 134.021 111.284 133.794C112.605 132.974 113.484 131.51 113.484 129.841V14.7811C113.484 12.9826 112.77 11.2578 111.498 9.98613C110.226 8.71443 108.502 8 106.703 8H42.7811ZM96.4545 134.491H106.685V135.73C106.685 135.894 106.47 136.052 106.086 136.168C105.702 136.284 105.182 136.35 104.639 136.35H98.5007C97.958 136.35 97.4376 136.284 97.0538 136.168C96.6701 136.052 96.4545 135.894 96.4545 135.73V134.491ZM106.815 129.841H108.834V14.7811C108.834 14.216 108.609 13.674 108.21 13.2744C107.81 12.8748 107.268 12.6503 106.703 12.6503H42.7811C42.216 12.6503 41.674 12.8748 41.2744 13.2744C40.8748 13.674 40.6503 14.216 40.6503 14.7811V129.841H42.3813C42.4242 129.84 42.4673 129.839 42.5105 129.839H53.6713C53.7145 129.839 53.7576 129.84 53.8005 129.841H96.3254C96.3683 129.84 96.4113 129.839 96.4545 129.839H106.685C106.729 129.839 106.772 129.84 106.815 129.841ZM42.5105 135.73V134.491H53.6713V135.73C53.6713 135.894 53.4362 136.052 53.0175 136.168C52.5989 136.284 52.0312 136.35 51.4392 136.35H44.7427C44.1506 136.35 43.5829 136.284 43.1643 136.168C42.7457 136.052 42.5105 135.894 42.5105 135.73Z" fill="currentColor"/>
      <path d="M76.1394 12.649C76.1394 11.8785 75.5147 11.2539 74.7442 11.2539C73.9738 11.2539 73.3491 11.8785 73.3491 12.649V73.0454H40.6504C39.8799 73.0454 39.2552 73.67 39.2552 74.4405C39.2552 75.211 39.8799 75.8356 40.6504 75.8356H108.834C109.604 75.8356 110.229 75.211 110.229 74.4405C110.229 73.67 109.604 73.0454 108.834 73.0454H76.1394V12.649Z" fill="currentColor"/>
      <path d="M39.2552 95.6796C39.2552 94.9091 39.8799 94.2844 40.6504 94.2844H108.834C109.604 94.2844 110.229 94.9091 110.229 95.6796C110.229 96.45 109.604 97.0747 108.834 97.0747H40.6504C39.8799 97.0747 39.2552 96.45 39.2552 95.6796Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.3045 41.721H55.566C58.2838 41.721 60.487 43.9241 60.487 46.6419V63.6878C60.487 66.4056 58.2838 68.6088 55.566 68.6088H51.3045C48.5868 68.6088 46.3836 66.4056 46.3836 63.6878V46.6419C46.3836 43.9241 48.5868 41.721 51.3045 41.721ZM51.3045 44.5112H55.566C56.7428 44.5112 57.6967 45.4651 57.6967 46.6419V51.8028H49.1738V46.6419C49.1738 45.4651 50.1278 44.5112 51.3045 44.5112ZM49.1738 54.593V63.6878C49.1738 64.8646 50.1278 65.8185 51.3045 65.8185H55.566C56.7428 65.8185 57.6967 64.8646 57.6967 63.6878V54.593H49.1738Z" fill="currentColor"/>
      <path d="M49.1738 99.1673C50.7148 99.1673 51.964 100.417 51.964 101.958H49.1738V104.088C49.1738 104.653 49.3983 105.195 49.7979 105.595C50.1975 105.995 50.7394 106.219 51.3045 106.219H98.1808C98.7459 106.219 99.2878 105.995 99.6874 105.595C100.087 105.195 100.312 104.653 100.312 104.088V101.958H97.5213C97.5213 100.417 98.7705 99.1673 100.312 99.1673C101.852 99.1673 103.102 100.417 103.102 101.958V104.088C103.102 105.393 102.583 106.645 101.66 107.568C100.738 108.491 99.4859 109.009 98.1808 109.009H51.3045C49.9994 109.009 48.7478 108.491 47.8249 107.568C46.902 106.645 46.3836 105.393 46.3836 104.088V101.958C46.3836 100.417 47.6328 99.1673 49.1738 99.1673Z" fill="currentColor"/>
      <path d="M51.964 83.1309C51.964 81.5899 50.7148 80.3407 49.1738 80.3407C47.6328 80.3407 46.3836 81.5899 46.3836 83.1309V85.2616C46.3836 86.5667 46.902 87.8184 47.8249 88.7412C48.7478 89.6641 49.9994 90.1826 51.3045 90.1826H98.1808C99.4859 90.1826 100.738 89.6641 101.66 88.7412C102.583 87.8184 103.102 86.5667 103.102 85.2616V83.1309C103.102 81.5899 101.852 80.3407 100.312 80.3407C98.7705 80.3407 97.5213 81.5899 97.5213 83.1309H100.312V85.2616C100.312 85.8267 100.087 86.3687 99.6874 86.7683C99.2878 87.1679 98.7459 87.3923 98.1808 87.3923H51.3045C50.7394 87.3923 50.1975 87.1679 49.7979 86.7683C49.3983 86.3687 49.1738 85.8267 49.1738 85.2616V83.1309H51.964Z" fill="currentColor"/>
      <path d="M66.2208 21.8734C66.9913 21.8734 67.6159 22.498 67.6159 23.2685V63.7525C67.6159 64.523 66.9913 65.1476 66.2208 65.1476C65.4503 65.1476 64.8257 64.523 64.8257 63.7525V23.2685C64.8257 22.498 65.4503 21.8734 66.2208 21.8734Z" fill="currentColor"/>
      <path d="M84.6628 23.2685C84.6628 22.498 84.0382 21.8734 83.2677 21.8734C82.4972 21.8734 81.8726 22.498 81.8726 23.2685V63.7525C81.8726 64.523 82.4972 65.1476 83.2677 65.1476C84.0382 65.1476 84.6628 64.523 84.6628 63.7525V23.2685Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BigFridge')

export default icon
