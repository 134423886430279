import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Checkbox, Fab, Paper, Typography, Button,
} from '@mui/material'

import convertTimeToTitle from '@app/helpers/cloudFirmwareUpdate/convertTimeToTitle'
import convertTimeToField from '@app/helpers/cloudFirmwareUpdate/convertTimeToField'
import CellularModulePopup from '@app/components/CloudFirmwareUpdate/Popups/CellularModulePopup'
import Wrapper from '../Common/Wrapper/Wrapper'
import WarningLang from './Popups/WarningLang'
import WarningComponent from '../Common/Warning/WarningComponent'
import FirmwareDownloadSection from './FirmwareDownloadSection/FirmwareDownloadSection'
import ButtonsOrProgressComponent from './ButtonsOrProgressComponent/ButtonsOrProgressComponent'

class CloudFirmwareUpdate extends React.Component {
  handleAutoInstallChange=() => {
    const {
      saveAutoInstallSetting,
      autoInstall,
      syncPatchCloudFirmwareInfo,
    } = this.props
    saveAutoInstallSetting(!autoInstall)
    syncPatchCloudFirmwareInfo()
  }

  renderDesktopHeader =() => {
    const {
      classes,
      newVersion,
      currentVersion,
    } = this.props
    return (
      <Wrapper className={classes.headerWrapper}>
        {this.renderLangButton()}
        <Wrapper className={classes.titlesWrapper}>
          <Typography align="center" className={classes.mainTitle}>
            {I18n.t('titles.cloud_update_firmware')}
          </Typography>
          <Typography align="center" className={classes.currentVersion}>
            {I18n.t('cloud_firmware_update.сurrent_firmware_version')}
            {' '}
            {currentVersion}
          </Typography>
          {newVersion && (
            <Typography align="center" className={classes.newVersion}>
              {I18n.t('cloud_firmware_update.new')}
              {' '}
              {newVersion}
              {' '}
              {I18n.t('cloud_firmware_update.firmware_released')}
            </Typography>
          )}
        </Wrapper>
        {this.renderCellularModuleButton()}
      </Wrapper>
    )
  }

  renderMobileHeader =() => {
    const {
      classes,
      newVersion,
      currentVersion,
    } = this.props
    return (
      <Wrapper className={classes.headerWrapper}>
        {this.renderLangButton()}
        <Wrapper className={classes.titlesWrapper}>
          <Typography align="center" className={classes.currentVersionMobile}>
            {I18n.t('cloud_firmware_update.сurrent_firmware_version')}
            {' '}
            {currentVersion}
          </Typography>
          {newVersion && (
            <Typography align="center" className={classes.newVersion}>
              {I18n.t('cloud_firmware_update.new')}
              {' '}
              {newVersion}
              {' '}
              {I18n.t('cloud_firmware_update.firmware_released')}
            </Typography>
          )}
        </Wrapper>
        {this.renderCellularModuleButton()}
      </Wrapper>
    )
  }

  renderAdditionalSettings=() => {
    const {
      classes,
      autoInstall,
      startReboot,
    } = this.props
    return (
      <>
        <Wrapper className={classes.additionalSettingsWrapper}>
          <Typography align="left">
            {I18n.t('cloud_firmware_update.auto_install_after_download')}
          </Typography>
          <Checkbox
            color="primary"
            checked={autoInstall}
            onChange={this.handleAutoInstallChange}
            inputProps={{ 'aria-label': 'autoInstall' }}
          />
          <Button
            color="secondary"
            className={classes.rebootButton}
            onClick={() => {
              startReboot()
            }}
          >
            Reboot
          </Button>
        </Wrapper>
      </>
    )
  }

  renderLangButton =() => {
    const {
      classes,
      firmwareLang,
      openChangeLangPopup,
      isMobile,
      isButtonsDisabled,
    } = this.props
    return (
      <Fab
        aria-label="ChangeLanguage"
        className={classNames(classes.langButton, isMobile && classes.langButtonMobile)}
        onClick={() => {
          openChangeLangPopup()
        }}
        disabled={isButtonsDisabled}
      >
        {firmwareLang}
      </Fab>
    )
  }

  renderCellularModuleButton =() => {
    const {
      classes,
      cellularModule,
      openChangeCellularModulePopup,
      isMobile,
      isButtonsDisabled,
    } = this.props
    return (
      <Fab
        aria-label="ChangeCellularModule"
        className={classNames(classes.langButton, isMobile && classes.langButtonMobile)}
        onClick={() => {
          openChangeCellularModulePopup()
        }}
        disabled={isButtonsDisabled}
      >
        {cellularModule}
      </Fab>
    )
  }

  render() {
    const {
      classes,
      syncManualUpdate,
      syncFirmwareLang,
      saveFirmwareLang,
      isChangeLangPopupOpen,
      closeChangeLangPopup,
      isMobile,
      isDownloadingErrorPopupOpen,
      closeDownloadingErrorPopup,
      channelError,
      closeResetPopup,
      isResetPopupOpen,
      syncResetProcess,
      downloadingType,
      selectedFirmwareVersion,
      firmwareList,
      saveSelectedFirmwareVersion,
      isButtonsDisabled,
      saveDownloadingType,
      downloadingTime,
      saveDownloadingTime,
      submittedDownloadingTime,
      submittedNightlyDownloading,
      updateStatus,
      fwToInstall,
      openResetPopup,
      uploadProcess,
      syncStopUploading,
      uploadStatusValue,
      syncPatchCloudFirmwareInfo,
      saveContinueDownloadingType,
      syncContinueDownloading,
      saveSlotForDownload,
      saveSlotForInstall,
      slotForDownload,
      slotForInstall,
      syncInstall,
      installationMessage,
      fwInSlots,
      isChangeCellularModulePopupOpen,
      closeChangeCellularModulePopup,
      syncCellularModule,
      saveCellularModule,
      cellularModule,
    } = this.props
    return (
      <Paper className={classNames(
        classes.main,
        isMobile && classes.mainMobile,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        {isMobile ? this.renderMobileHeader() : this.renderDesktopHeader()}
        <FirmwareDownloadSection
          downloadingType={downloadingType}
          selectedFirmwareVersion={selectedFirmwareVersion}
          firmwareList={firmwareList}
          saveSelectedFirmwareVersion={saveSelectedFirmwareVersion}
          isMobile={isMobile}
          isButtonsDisabled={isButtonsDisabled}
          saveDownloadingType={saveDownloadingType}
          downloadingTime={downloadingTime}
          saveDownloadingTime={saveDownloadingTime}
        />
        {this.renderAdditionalSettings()}
        <ButtonsOrProgressComponent
          syncManualUpdate={syncManualUpdate}
          updateStatus={updateStatus}
          fwToInstall={fwToInstall}
          openResetPopup={openResetPopup}
          downloadingType={downloadingType}
          uploadProcess={uploadProcess}
          syncStopUploading={syncStopUploading}
          uploadStatusValue={uploadStatusValue}
          syncPatchCloudFirmwareInfo={syncPatchCloudFirmwareInfo}
          saveContinueDownloadingType={saveContinueDownloadingType}
          syncContinueDownloading={syncContinueDownloading}
          isMobile={isMobile}
          saveSlotForDownload={saveSlotForDownload}
          saveSlotForInstall={saveSlotForInstall}
          slotForDownload={slotForDownload}
          slotForInstall={slotForInstall}
          syncInstall={syncInstall}
          installationMessage={installationMessage}
          fwInSlots={fwInSlots}
        />
        { submittedDownloadingTime && (
          <Typography className={classes.firmwareWaitingText}>
            {I18n.t('cloud_firmware_update.downloading_start_info')}
            {' '}
            {convertTimeToTitle(convertTimeToField(submittedDownloadingTime))}
          </Typography>
        )}
        {submittedNightlyDownloading && (
          <Typography className={classes.firmwareWaitingText}>
            {I18n.t('cloud_firmware_update.firmware_download_at_night')}
          </Typography>
        )}
        <WarningLang
          open={isChangeLangPopupOpen}
          onCancel={() => {
            closeChangeLangPopup()
          }}
          warningText="cloud_firmware_update.lang_warning_text"
          leftButton="cancel"
          rightButton="yeah"
          syncFirmwareLang={syncFirmwareLang}
          saveFirmwareLang={saveFirmwareLang}
          closeChangeLangPopup={closeChangeLangPopup}
        />
        <CellularModulePopup
          open={isChangeCellularModulePopupOpen}
          onCancel={() => {
            closeChangeCellularModulePopup()
          }}
          cellularModule={cellularModule}
          warningText="cloud_firmware_update.cellular_module_warning_text"
          leftButton="cancel"
          rightButton="yeah"
          syncCellularModule={syncCellularModule}
          saveCellularModule={saveCellularModule}
          closeChangeCellularModulePopup={closeChangeCellularModulePopup}
        />
        <WarningComponent
          title="ERROR"
          warningText={channelError}
          untranslatableError
          open={isDownloadingErrorPopupOpen}
          onClose={syncManualUpdate}
          withCloseIcon
          onCloseIconClick={closeDownloadingErrorPopup}
          onClick={closeDownloadingErrorPopup}
          leftButton="cancel"
          rightButton="retry"
        />
        <WarningComponent
          untranslatableError
          warningText={I18n.t('cloud_firmware_update.interrupt_all_process')}
          open={isResetPopupOpen}
          onClick={closeResetPopup}
          onClose={() => {
            closeResetPopup()
            syncResetProcess()
          }}
          leftButton="cancel"
          rightButton="yeah"
        />
      </Paper>
    )
  }
}

CloudFirmwareUpdate.propTypes = {
  classes: PropTypes.object.isRequired,
  newVersion: PropTypes.string.isRequired,
  currentVersion: PropTypes.string.isRequired,
  downloadingType: PropTypes.string.isRequired,
  firmwareLang: PropTypes.string.isRequired,
  selectedFirmwareVersion: PropTypes.string.isRequired,
  autoInstall: PropTypes.bool.isRequired,
  firmwareList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  saveSelectedFirmwareVersion: PropTypes.func.isRequired,
  saveAutoInstallSetting: PropTypes.func.isRequired,
  syncManualUpdate: PropTypes.func.isRequired,
  syncFirmwareLang: PropTypes.func.isRequired,
  saveFirmwareLang: PropTypes.func.isRequired,
  isChangeLangPopupOpen: PropTypes.bool.isRequired,
  openChangeLangPopup: PropTypes.func.isRequired,
  closeChangeLangPopup: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  updateStatus: PropTypes.string.isRequired,
  isButtonsDisabled: PropTypes.bool.isRequired,
  isDownloadingErrorPopupOpen: PropTypes.bool.isRequired,
  closeDownloadingErrorPopup: PropTypes.func.isRequired,
  channelError: PropTypes.string.isRequired,
  saveDownloadingType: PropTypes.func.isRequired,
  fwToInstall: PropTypes.string.isRequired,
  syncResetProcess: PropTypes.func.isRequired,
  closeResetPopup: PropTypes.func.isRequired,
  openResetPopup: PropTypes.func.isRequired,
  isResetPopupOpen: PropTypes.bool.isRequired,
  saveContinueDownloadingType: PropTypes.func.isRequired,
  syncContinueDownloading: PropTypes.func.isRequired,
  uploadProcess: PropTypes.string.isRequired,
  saveDownloadingTime: PropTypes.func.isRequired,
  syncPatchCloudFirmwareInfo: PropTypes.func.isRequired,
  downloadingTime: PropTypes.string.isRequired,
  submittedDownloadingTime: PropTypes.string.isRequired,
  submittedNightlyDownloading: PropTypes.bool.isRequired,
  syncStopUploading: PropTypes.func.isRequired,
  uploadStatusValue: PropTypes.number.isRequired,
  saveSlotForDownload: PropTypes.func.isRequired,
  saveSlotForInstall: PropTypes.func.isRequired,
  slotForDownload: PropTypes.number.isRequired,
  slotForInstall: PropTypes.number.isRequired,
  syncInstall: PropTypes.func.isRequired,
  installationMessage: PropTypes.string || PropTypes.shape({
    message: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
  }),
  fwInSlots: PropTypes.shape({
    A: PropTypes.string,
    B: PropTypes.string,
  }),
  isChangeCellularModulePopupOpen: PropTypes.bool.isRequired,
  closeChangeCellularModulePopup: PropTypes.func.isRequired,
  syncCellularModule: PropTypes.func.isRequired,
  saveCellularModule: PropTypes.func.isRequired,
  cellularModule: PropTypes.string.isRequired,
  openChangeCellularModulePopup: PropTypes.func.isRequired,
  startReboot: PropTypes.func.isRequired,
}
CloudFirmwareUpdate.defaultProps = {
  installationMessage: '',
  fwInSlots: null,
}

CloudFirmwareUpdate.displayName = 'CloudFirmwareUpdate'

export default CloudFirmwareUpdate
