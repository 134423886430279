import { CHECK_MOBILE, CHECK_TABLET } from '@app/constants/mobileDetector'

export type TMobileDetectorAction =
  | { type: '@@mobileDetector/checkMobile', isMobile: boolean }
  | { type: '@@mobileDetector/checkTablet', isTablet: boolean }

export const checkMobile = (isMobile: boolean): TMobileDetectorAction => ({
  type: CHECK_MOBILE,
  isMobile,
})

export const checkTablet = (isTablet: boolean): TMobileDetectorAction => ({
  type: CHECK_TABLET,
  isTablet,
})
