import { getOffsetString } from './getOffsetString'

const getConditionStateDescription = (type: string,
  stateDescription: string, timeOperand: string,
  usersNames: string | never[], dataNumber: number, deviceState: number, triggeringDevice: string): string => {
  let offset
  switch (type) {
  case 'TwrDevices':
    return stateDescription
  case 'ZWaveDevices':
    return stateDescription
  case 'Light':
    return stateDescription
  case 'Dark':
    return stateDescription
  case 'SunWithOffset':
    offset = getOffsetString(dataNumber)
    if (stateDescription === 'sunrise') {
      return offset === '0' ? 'when it’s sunrise' : `when it’s sunrise with an offset of ${offset}`
    }
    return offset === '0' ? 'when it’s sunset' : `when it’s sunset with an offset of ${offset}`
  case 'Time':
    if (timeOperand === 'eq') {
      return `it's ${stateDescription}`
    }
    if (timeOperand === 'gt') {
      return `after ${stateDescription}`
    }
    if (timeOperand === 'lt') {
      return `before ${stateDescription}`
    }
    throw new Error('condition unsupported')
  case 'TimeLimits':
    return `from ${stateDescription}`
  case 'DayOfTheWeek':
    if (stateDescription === "it's full week") {
      return stateDescription
    }
    return `it's ${stateDescription}`
  case 'Weekdays':
    return stateDescription
  case 'Weekends':
    return stateDescription
  case 'Date':
    return stateDescription
  case 'BetweenDatesSimple':
    return `it's from ${stateDescription}`
  case 'UserTrigger':
    return 'none'
  case 'IsInAlarm':
    return 'alarm triggers'
  case 'Panic':
    return 'panic alarm triggers'
  case 'Duress':
    return 'duress alarm triggers'
  case 'IfArmed':
    return 'is armed'
  case 'IfArmedHome':
    return 'is armed in Home mode'
  case 'IfDisarmed':
    return 'is disarmed'
  case 'WhenFire':
    return 'fire alarm triggers'
  case 'WhenMedicalEvent':
    return 'is in Health alarm'
  case 'Chime':
    return 'sound a chime'
  case 'IfMainsHasFailed':
    return 'mains power is missing'
  case 'WhenMainsFail':
    return 'mains power fails'
  case 'IfMainsPowerIsPresent':
    return 'mains power is present'
  case 'WhenMainsRestores':
    return 'mains power restores'
  case 'NotArmed':
    return 'not armed'
  case 'NotDisarmed':
    return 'not disarmed'
  case 'WhenArmed':
    return 'has been armed'
  case 'WhenDisarmed':
    return 'has been disarmed'
  case 'WhenMezzoSetToHomeMode':
    return 'has been set to Home mode'
  case 'WhileInMedicalAlarm':
    return 'in Medical alarm'
  case 'Siren':
    return 'sounding siren'
  case 'ZwaveColor':
    return 'colour is set to'
  case 'Geofencing':
    return `and ${usersNames} ${stateDescription}`
  case 'RoomActivity':
    return I18n.t(`scene_condition_web.${stateDescription}`)
  case 'GroupActivity':
    return I18n.t(`scene_condition_web.${stateDescription}`)
  case 'ZwaveSensorMultilevel':
    return stateDescription
  case 'HubLight':
    return stateDescription
  case 'IfPanicDuressFire':
    if (stateDescription === 'ui_is_in_fire_alarm') {
      return 'is in fire alarm'
    }
    if (stateDescription === 'ui_is_in_duress_alarm') {
      return 'is in duress alarm'
    }
    if (stateDescription === 'ui_is_in_panic_alarm') {
      return 'is in panic alarm'
    }
    if (stateDescription === 'ui_is_not_in_fire_alarm') {
      return 'is not in fire alarm'
    }
    if (stateDescription === 'ui_is_not_in_duress_alarm') {
      return 'is not in duress alarm'
    }
    if (stateDescription === 'ui_is_not_in_panic_alarm') {
      return 'is not in panic alarm'
    }
    throw new Error('condition unsupported')
  case 'ZwaveSceneController':
    return `Button ${dataNumber} is ${stateDescription}`
  case 'ZwaveMultichannel':
    return `Device ${dataNumber} ${stateDescription}`
  case 'TimerExpired':
  case 'ZwaveSensorNotification':
    return stateDescription
  case 'AnotherScene':
    return 'none'
  case 'DeviceTimeout':
    switch (deviceState) {
    case 0:
      return `${stateDescription} and ${triggeringDevice} in Off state`
    case 1:
      return `${stateDescription} and ${triggeringDevice} in On state`
    case 2:
      return `${stateDescription} and ${triggeringDevice} in Any state`
    default:
      throw new Error('state unsupported')
    }
  case 'VirtualSwitch':
    return stateDescription === 'is_off' ? 'is off' : 'is on'
  default:
    throw new Error('condition unsupported')
  }
}

export default getConditionStateDescription
