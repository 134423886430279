import type { Node } from 'react'
import React from 'react'
import { CircularProgress } from '@mui/material'
import { Chart } from 'react-google-charts'

import useStyles from './Chart.style'

type Props = {
  statusLine: Array<Array<{ type: string, id: string } | string | Date>>,
}

const DeviceChart = (props: Props): Node => {
  const classes = useStyles()
  const { statusLine } = props
  return (
    <div
      className={classes.chartContainer}
      style={{
        marginTop: '2rem', width: '95%', backgroundColor: '#fff', padding: '1.5rem 1.5rem 0 1.5rem',
      }}
    >
      <Chart
        width="100%"
        height="250px"
        chartType="Timeline"
        loader={(
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '4rem auto',
          }}
          >
            <CircularProgress />
          </div>
        )}
        data={statusLine}
        options={{
          showRowNumber: true,
          colors: ['#3366CC', '#3366CC', '#DC3912', '#109618', '#109618'],
          timeline: {
            colorByRowLabel: true,
          },
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  )
}

export default DeviceChart
