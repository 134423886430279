const styles = () => ({
  card: {
    marginBottom: 22,
    borderRadius: '5px',
    minHeight: 50,
    background: '#141414',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '2% 5%',
    '&:last-child': {
      paddingBottom: '2%',
    },
  },
  cardContentMobile: {
    display: 'flex',
    alignItems: 'center',
    padding: '1% 2%',
    '&:last-child': {
      paddingBottom: '2%',
    },
  },
  cardData: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colorPreview: {
    width: 130,
    borderRadius: 3,
    height: 30,
    border: '1px solid rgba(0,0,0, 0.3)',
  },
  formControlLabel: {
    margin: 0,
  },
  offText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 2,
  },
})

export default styles
