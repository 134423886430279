import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/billingPeriod'

export const initialState = {
  billingCurrentStats: {
    billing_period: {
      from: '',
      to: '',
      billing: 0,
      energy: 0,
    },
    total: {
      from: '',
      to: '',
      billing: 0,
      energy: 0,
    },
  },
}

const billingCurrentStats = (state = initialState, action) => {
  switch (action.type) {
  case type.UPDATE_BILLING_CURRENT_STATS:
    return update(state, {
      billingCurrentStats: { $set: action.stats },
    })
  default:
    return state
  }
}

export default billingCurrentStats
