import update from 'immutability-helper'
import { QUEUE_POP, QUEUE_PUSH } from '@app/constants/queue'

const initialState = []

export default function queue(state = initialState, action) {
  switch (action.type) {
  case QUEUE_PUSH:
    return update(state, { $push: [action.func] })
  case QUEUE_POP:
    return update(state, { $splice: [[state[-1], 1]] })
  default:
    return state
  }
}
