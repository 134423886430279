import React, { useState } from 'react'
import { Field } from 'redux-form'
import { ListItem } from '@mui/material'
import classNames from 'classnames'
import useStyles from './Pincode.style'
import PincodeField from './PincodeField'

interface Props {
  title: string,
  name: string,
  isMobile: boolean,
  isNCentral: boolean,
}

const Pincode: React.FC<Props> = (props) => {
  const {
    title, name, isMobile, isNCentral,
  } = props
  const [showHint, setShowHint] = useState(false)
  const classes = useStyles()

  const deleteZeros = (value: string) => {
    if (value.startsWith('0') && isNCentral) {
      setShowHint(true)
      return value.slice(1)
    } if (value !== '') {
      setShowHint(false)
      return value
    }
    return value
  }

  return (
    <ListItem
      disableGutters
      className={classNames(
        classes.root,
        !isMobile && classes.rootDesktop,
      )}
    >
      <Field
        title={title}
        name={name}
        isNCentral={isNCentral}
        component={PincodeField}
        normalize={deleteZeros}
        showHint={showHint}
      />
    </ListItem>
  )
}

export default Pincode
