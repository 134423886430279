import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { intervalInput } from '@app/lib/MyEnergy/intervalValues'
import styles from './SelectString.style'

class SelectString extends React.PureComponent {
  handleChange = (event) => {
    const {
      choosePeriod,
    } = this.props
    choosePeriod(event.target.value)
  }

  createItems = (values) => values.map((el) => (
    <MenuItem key={el} value={el}>
      {`${I18n.t(`drop_down_menu.${el}`)}`}
    </MenuItem>
  ))

  render() {
    const {
      values,
      classes,
      periodSelector,
    } = this.props
    return (
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel htmlFor="period">{I18n.t('my_energy.period')}</InputLabel>
        <Select
          variant="standard"
          value={periodSelector}
          inputProps={{
            name: intervalInput,
            id: intervalInput,
          }}
          onChange={this.handleChange}
        >
          {this.createItems(values)}
        </Select>
      </FormControl>
    )
  }
}

SelectString.propTypes = {
  classes: PropTypes.object.isRequired,
  choosePeriod: PropTypes.func.isRequired,
  periodSelector: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default withStyles(styles)(SelectString)
