import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  sceneInfoText: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  sceneInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#141414',
    padding: '2% 0',
    marginBottom: 10,
    borderRadius: '5px',
    minHeight: 50,
  },
  sceneInfoTextMobile: {
    maxWidth: '70%',
  },
  iconButton: {
    padding: 5,
    marginRight: 15,
  },
  andText: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    fontSize: '17px',
  },
  deleteWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rightElementWrapper: {
    display: 'flex',
  },
  errorOutline: {
    marginRight: 3,
  },
}))

export default useStyles
