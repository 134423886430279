import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 27C17 21.4772 21.4772 17 27 17H123C128.523 17 133 21.4772 133 27V123C133 128.523 128.523 133 123 133H27C21.4772 133 17 128.523 17 123V27ZM27 22H123C125.761 22 128 24.2386 128 27V123C128 125.761 125.761 128 123 128H27C24.2386 128 22 125.761 22 123V27C22 24.2386 24.2386 22 27 22Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M118 75C118 98.7482 98.7482 118 75 118C51.2518 118 32 98.7482 32 75C32 51.2518 51.2518 32 75 32C98.7482 32 118 51.2518 118 75ZM111.5 75C111.5 95.1584 95.1584 111.5 75 111.5C54.8416 111.5 38.5 95.1584 38.5 75C38.5 54.8416 54.8416 38.5 75 38.5C95.1584 38.5 111.5 54.8416 111.5 75Z" fill="currentColor"/>
      <path d="M71 83H80V103H71V83Z" fill="currentColor"/>
      <path d="M81.9785 67.6992L87.6786 61.9991L100.258 74.5789L94.5583 80.279L81.9785 67.6992Z" fill="currentColor"/>
      <path d="M63.5796 62L69.2797 67.7001L56.4467 80.5331L50.7466 74.833L63.5796 62Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'WallPlugType')

export default icon
