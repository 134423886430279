import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 38">
      <path fill="currentColor" d="M16.03 13.49c-.3.37-.25.93.13 1.23l11.04 8.9a1.3 1.3 0 0 0 2.14-1.01v-6.73l10.31 8.31a.88.88 0 1 0 1.1-1.36l-11.04-8.9a1.3 1.3 0 0 0-1.39-.17 1.3 1.3 0 0 0-.74 1.18v6.73l-10.31-8.32a.88.88 0 0 0-1.24.14Z"/>
      <path fill="currentColor" d="M54.08 32.72V26.9h3.42a2.5 2.5 0 0 0 2.5-2.5V13.14a2.5 2.5 0 0 0-2.5-2.5h-3.42v-5.8A4.84 4.84 0 0 0 49.25 0H4.83A4.84 4.84 0 0 0 0 4.83v27.89a4.84 4.84 0 0 0 4.83 4.83h44.42a4.84 4.84 0 0 0 4.83-4.83ZM57.5 12.4c.4 0 .74.33.74.74V24.4c0 .41-.33.75-.74.75h-3.42V12.4h3.42ZM4.83 35.8a3.08 3.08 0 0 1-3.07-3.07V4.83c0-1.7 1.38-3.07 3.07-3.07h44.42c1.7 0 3.08 1.38 3.08 3.07v27.89c0 1.7-1.38 3.07-3.08 3.07H4.83Z"/>
      <path fill="currentColor" d="M48.9 30.31V7.24c0-1.14-.92-2.06-2.05-2.06H15.1a.88.88 0 1 0 0 1.76h31.74c.16 0 .3.13.3.3V30.3a.3.3 0 0 1-.3.3h-4.6a.88.88 0 1 0 0 1.76h4.6c1.13 0 2.06-.92 2.06-2.06ZM39 31.5c0-.5-.38-.89-.87-.89H7.23a.3.3 0 0 1-.3-.3V7.24c0-.17.14-.3.3-.3H11a.88.88 0 1 0 0-1.76H7.24c-1.14 0-2.06.92-2.06 2.06V30.3c0 1.14.92 2.06 2.06 2.06h30.89c.49 0 .88-.4.88-.88Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BatteryIcon', '0 0 80 80', {fontSize: 35})

export default icon
