import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 4V6M6 6V8M6 6H8M6 6H4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.3333 5.65004H10.3363C10.2805 4.94951 10.0552 4.27481 9.68258 3.68337H14.6666C15.1043 3.68337 15.5239 3.85721 15.8334 4.16665C16.1428 4.47608 16.3166 4.89577 16.3166 5.33337V10.9834H13.3333C12.7101 10.9834 12.1123 11.231 11.6716 11.6717C11.2309 12.1124 10.9833 12.7101 10.9833 13.3334V16.3167H5.33331C4.89571 16.3167 4.47602 16.1429 4.16659 15.8334C3.85715 15.524 3.68331 15.1043 3.68331 14.6667V9.68292C3.99721 9.88084 4.33608 10.0382 4.69191 10.1504C5.26022 10.3296 5.85914 10.3896 6.45125 10.327C6.52195 10.3423 6.59416 10.3501 6.66665 10.35C6.66671 10.35 6.66677 10.35 6.66683 10.35H13.3333C13.6029 10.35 13.8615 10.2429 14.0522 10.0523C14.2429 9.8616 14.35 9.60301 14.35 9.33337C14.35 9.06374 14.2429 8.80514 14.0522 8.61448C13.8615 8.42382 13.6029 8.31671 13.3333 8.31671H9.68247C9.80946 8.11523 9.91983 7.90342 10.0123 7.68337H13.3333C13.6029 7.68337 13.8615 7.57626 14.0522 7.3856C14.2429 7.19494 14.35 6.93634 14.35 6.66671C14.35 6.39707 14.2429 6.13848 14.0522 5.94782C13.8615 5.75715 13.6029 5.65004 13.3333 5.65004ZM15.8331 13.2812L13.2812 15.8332C13.2003 15.914 13.1116 15.9859 13.0166 16.048V13.3334C13.0166 13.2494 13.05 13.1688 13.1094 13.1095C13.1688 13.0501 13.2493 13.0167 13.3333 13.0167H16.0479C15.9859 13.1117 15.914 13.2004 15.8331 13.2812ZM5.94775 11.2811C5.75709 11.4718 5.64998 11.7304 5.64998 12C5.64998 12.2697 5.75709 12.5283 5.94775 12.7189C6.13842 12.9096 6.39701 13.0167 6.66665 13.0167H9.33331C9.60295 13.0167 9.86154 12.9096 10.0522 12.7189C10.2429 12.5283 10.35 12.2697 10.35 12C10.35 11.7304 10.2429 11.4718 10.0522 11.2811C9.86154 11.0905 9.60295 10.9834 9.33331 10.9834H6.66665C6.39701 10.9834 6.13842 11.0905 5.94775 11.2811Z" stroke="currentColor" strokeWidth="0.7"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'RemindersSettingsIcon')

export default icon
