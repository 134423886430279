import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import {
  LockedIcon, UnlockedIcon, ChevronsOnIcon, ChevronsOffIcon, ChevronsUpIcon, ChevronsDownIcon,
} from 'icons'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './MultiSwitch.style'

gsap.registerPlugin(Draggable)

const DefaultProps = {
  locker: false,
  chevronsLeftRight: false,
  chevronsUpDown: false,
  bigSize: false,
  showcase: false,
}

interface Props {
  id: number | string,
  multiSwitchValue: boolean,
  multiSwitchHandler: (multiSwitchState: boolean) => void,
  inProgress: boolean,
  locker?: boolean,
  chevronsLeftRight?: boolean,
  chevronsUpDown?: boolean,
  bigSize?: boolean,
  showcase?: boolean,
}

const MultiSwitch: React.FC<Props> = (props) => {
  const {
    id,
    multiSwitchValue,
    multiSwitchHandler,
    inProgress,
    locker = DefaultProps.locker,
    chevronsLeftRight = DefaultProps.chevronsLeftRight,
    chevronsUpDown = DefaultProps.chevronsUpDown,
    bigSize = DefaultProps.bigSize,
    showcase = DefaultProps.showcase,
  } = props
  const classes = useStyles()
  const [state, setState] = useState<boolean>(multiSwitchValue)
  const [progress, setProgress] = useState<boolean>(inProgress)

  useEffect(() => {
    setState(multiSwitchValue)
  }, [multiSwitchValue])

  useEffect(() => {
    setProgress(inProgress)
  }, [inProgress])

  const toggleOn = () => {
    gsap.to(`#toggleButton-${id}`, { x: 22, duration: 0.2 })
    gsap.to(`#toggleButton-${id}`, { x: 0, duration: 0.2, delay: 0.25 })
  }
  const toggleOff = () => {
    gsap.to(`#toggleButton-${id}`, { x: -22, duration: 0.2 })
    gsap.to(`#toggleButton-${id}`, { x: 0, duration: 0.2, delay: 0.25 })
  }

  const handleState = (event: Event) => {
    if (progress) {
      event.preventDefault()
    } else {
      multiSwitchHandler(!state)
      if (!showcase) {
        setProgress(true)
      }
      setState((prevState) => !prevState)
      if (state) {
        toggleOff()
      } else {
        toggleOn()
      }
    }
  }

  const turnOn = () => {
    multiSwitchHandler(true)
    if (!showcase) {
      setProgress(true)
    }
    setState(true)
    toggleOn()
  }

  const turnOff = () => {
    multiSwitchHandler(false)
    if (!showcase) {
      setProgress(true)
    }
    setState(false)
    toggleOff()
  }

  const handleRightSideClick = (event: Event) => {
    if (progress) {
      event.preventDefault()
    } else {
      turnOn()
    }
  }

  const handleLeftSideClick = (event: Event) => {
    if (progress) {
      event.preventDefault()
    } else {
      turnOff()
    }
  }

  const renderSwitchState = (): JSX.Element => {
    if (locker) {
      return state ? <LockedIcon /> : <UnlockedIcon />
    }
    if (chevronsLeftRight) {
      return state ? <ChevronsOnIcon /> : <ChevronsOffIcon />
    }
    if (chevronsUpDown) {
      return state ? <ChevronsDownIcon /> : <ChevronsUpIcon />
    }
    return (
      <Typography className={classes.toggleState}>
        {state ? `${I18n.t('my_devices.on')}` : `${I18n.t('my_devices.off')}`}
      </Typography>
    )
  }

  const toggle = document.getElementById(`toggleButton-${id}`)
  const toggleCoordinates = toggle?.getBoundingClientRect()
  const initialToggleX = toggleCoordinates && (toggleCoordinates.x + toggleCoordinates.width) / 2

  const draggableSwitch = Draggable.create(`#toggleButton-${id}`, {
    type: 'x',
    bounds: { minX: -22, maxX: 22 },
    throwProps: true,
    onPress: () => {
      if (progress) {
        draggableSwitch[0].kill()
      }
    },
    onDragEnd: () => {
      const draggableToggle = document.getElementById(`toggleButton-${id}`)?.getBoundingClientRect()
      const draggableToggleX = draggableToggle && (draggableToggle.x + draggableToggle.width) / 2
      if (initialToggleX && draggableToggleX && draggableToggleX > initialToggleX) {
        turnOn()
      } else if (initialToggleX && draggableToggleX && draggableToggleX < initialToggleX) {
        turnOff()
      }
    },
  })

  return (
    <Wrapper
      className={classNames(classes.switch, bigSize && classes.bigSize, progress && classes.disabled)}
    >
      <Wrapper
        className={classNames(classes.redGradient, !state ? classes.show : classes.hide)}
        onClick={(event: Event) => handleLeftSideClick(event)}
      />
      <Wrapper
        className={classNames(classes.toggleWrapper)}
        onClick={(event: Event) => handleState(event)}
      >
        <Wrapper
          id={`toggleButton-${id}`}
          className={classNames(classes.toggleButton, progress && classes.disabled)}
        >
          {progress ? (
            <DotsLoading
              className={classes.progress}
              props={{
                width: 18, circleWidth: 6, circleHeight: 6, circleColor: '#C4C4C4',
              }}
            />
          ) : renderSwitchState()}
        </Wrapper>
      </Wrapper>
      <Wrapper
        className={classNames(classes.greenGradient, state ? classes.show : classes.hide)}
        onClick={(event: Event) => handleRightSideClick(event)}
      />
    </Wrapper>
  )
}

export default MultiSwitch
