import React from 'react'
import { Tab, Tabs, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './UniversalSensorTabs.style'
import UniversalSensor from './UniversalSensor'

class UniversalSensorTabs extends React.Component {
  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const {
      classes,
      isMobile,
      saveConditionDeviceStateInfo,
      saveMultilevelSensorValueDescription,
      conditionDeviceNumber,
      sensorType,
      sensorScales,
      navigate,
    } = this.props
    const { value } = this.state
    return (
      <Wrapper className={classes.root}>
        {sensorScales.length > 1 ? (
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={this.handleChange}
            variant="fullWidth"
            indicatorColor="primary"
          >
            { sensorScales.map((scale) => (<Tab label={scale.title} key={scale.title} />))}
          </Tabs>
        ) : <Typography className={classes.title}>{sensorScales[0].title}</Typography>}
        {sensorScales.map((scale, index) => (value === index && (
          <UniversalSensor
            isMobile={isMobile}
            navigate={navigate}
            saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
            saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
            conditionDeviceNumber={conditionDeviceNumber}
            sensorType={sensorType}
            scale={scale}
            key={scale.title}
          />
        )))}
      </Wrapper>
    )
  }
}

UniversalSensorTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveMultilevelSensorValueDescription: PropTypes.func.isRequired,
  sensorType: PropTypes.number.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  sensorScales: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    short: PropTypes.string.isRequired,
  })).isRequired,
}

UniversalSensorTabs.defaultProps = {

}

export default withStyles(styles)(UniversalSensorTabs)
