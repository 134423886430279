import { createLogic } from 'redux-logic'
import { SYNC_SCENES_FOR_ANOTHER_SCENE_CONDITION } from '@app/constants/scenes/anotherScenesList'
import Api from '@app/api/scenes/fetchAnotherScenesList'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { saveScenesForAnotherSceneCondition } from '@app/actions/scenes/anotherScenesList'

const fetchAnotherScenesListLogic = createLogic({
  type: SYNC_SCENES_FOR_ANOTHER_SCENE_CONDITION,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_SCENES_FOR_ANOTHER_SCENE_CONDITION))
    Api.fetchAnotherScenesList(token, id)
      .then((response) => {
        const { data: { scenes } } = response
        dispatch(saveScenesForAnotherSceneCondition(scenes))
        dispatch(apiRequestDone(SYNC_SCENES_FOR_ANOTHER_SCENE_CONDITION))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchAnotherScenesList failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchAnotherScenesListLogic
