import React from 'react'

type ReactNode = React.ReactNode | void

interface Props {
  children?: ReactNode | ReactNode[],
  customStyle?: React.CSSProperties,
  [other: string]: any,
}

const Wrapper: React.FC<Props> = (props) => {
  const { children, customStyle, ...other } = props
  return (
    <div {...other} style={customStyle}>
      <>{ children }</>
    </div>
  )
}

Wrapper.defaultProps = {
  children: null,
  customStyle: {},
}

export default Wrapper
