import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import { SELECT_CONDITION_DEVICE_TABS } from '@app/constants/routes'
import { zWaveDeviceTypes } from '@app/lib/Scenes/zwaveDeviceTypes'

class SelectZWaveDeviceType extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_TABS)
  }

  render() {
    const {
      classes,
      isMobile,
      navigate,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {zWaveDeviceTypes.map((deviceType) => (
            <CardBlock
              key={deviceType.description}
              description={`${I18n.t(`scene_web.${deviceType.description}`)}`}
              position={deviceType.position}
              buttonName={deviceType.buttonName}
              onClick={() => {
                navigate(deviceType.conditionLink)
              }}
              isButtonDisabled={deviceType.isButtonDisabled}
              isCardDisabled={deviceType.isCardDisabled}
            />
          ))}
        </Wrapper>
      </Paper>
    )
  }
}

SelectZWaveDeviceType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

SelectZWaveDeviceType.displayName = 'SelectZWaveDeviceTypeIOS'

export default SelectZWaveDeviceType
