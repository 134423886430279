import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveConditionDataNumber } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import OffsetSettings
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateWhen/OffsetSettings/OffsetSettings'
import OffsetSettingsIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateWhen/OffsetSettings/OffsetSettings'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(OffsetSettingsIOS) : withStyles(OffsetSettings)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  const { dataNumber, stateDescription } = state.scenes.currentSceneCreating.currentCondition
  return {
    isMobile,
    path,
    isEditConditionMode,
    dataNumber,
    stateDescription,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionDataNumber,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const OffsetSettingsEnhanced = enhance

export default withRouter(OffsetSettingsEnhanced)
