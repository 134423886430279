const getSpecialPrefix = (title: string): string => {
  if (title === 'is on'
    || title === 'is off'
    || title === 'is open'
    || title === 'is closed'
  ) {
    return 'If'
  }
  return 'When'
}

export default getSpecialPrefix
