import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './Text.style'

class Text extends React.PureComponent {
  render() {
    const { title, value, classes } = this.props
    return (
      <ListItem className={classes.root} key={title}>
        <Typography className={classes.infoText}>
          {title}
        </Typography>
        <Typography className={classes.valueText}>
          {value}
        </Typography>
      </ListItem>
    )
  }
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

export default (withStyles(styles)(Text))
