import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import styles from './WarningLang.style'

class WarningLang extends React.PureComponent {
  state={
    chosenLang: 'AU',
  }

  handleLangChange=(event) => {
    this.setState({ chosenLang: event.target.value })
  }

  onChangeLangSubmitClick=() => {
    const {
      chosenLang,
    } = this.state
    const {
      syncFirmwareLang,
      saveFirmwareLang,
      closeChangeLangPopup,
    } = this.props
    saveFirmwareLang(chosenLang)
    syncFirmwareLang()
    closeChangeLangPopup()
  }

  render() {
    const { chosenLang } = this.state
    const {
      classes,
      open,
      onCancel,
      warningText,
      leftButton,
      rightButton,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open={open}
      >
        <DialogTitle
          className={classes.title}
        >
          {I18n.t('titles.warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="body1"
            className={classes.text}
          >
            { I18n.t(`${warningText}`)}
            ?
          </DialogContentText>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup aria-label="lang" name="lang" value={chosenLang} onChange={this.handleLangChange}>
              <FormControlLabel
                value="AU"
                control={<Radio color="primary" />}
                label={I18n.t('cloud_firmware_update.australian')}
              />
              <FormControlLabel
                value="US"
                control={<Radio color="primary" />}
                label={I18n.t('cloud_firmware_update.american')}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            className={classes.button}
            onClick={onCancel}
          >
            {I18n.t(`buttons.${leftButton}`)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={this.onChangeLangSubmitClick}
          >
            {I18n.t(`buttons.${rightButton}`)}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

WarningLang.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  warningText: PropTypes.string.isRequired,
  leftButton: PropTypes.string.isRequired,
  rightButton: PropTypes.string.isRequired,
  syncFirmwareLang: PropTypes.func.isRequired,
  saveFirmwareLang: PropTypes.func.isRequired,
  closeChangeLangPopup: PropTypes.func.isRequired,
}

export default withStyles(styles)(WarningLang)
