export const centralSceneValues = [
  {
    description: 'pressed 1 time',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
  },
  {
    description: 'released',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 1,
  },
  {
    description: 'held down',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 2,
  },
  {
    description: 'pressed 2 times',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 3,
  },
  {
    description: 'pressed 3 times',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 4,
  },
  {
    description: 'pressed 4 times',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 5,
  },
  {
    description: 'pressed 5 times',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 6,
  },
]

export const multichannelBinarySwitchValues = [
  {
    description: 'is on',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
    operand: 'nz',
  },
  {
    description: 'is off',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
    operand: 'eq',
  },
]
