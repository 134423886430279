const convertRoomOrGroupStateToBackend = (state) => {
  switch (state) {
  case 'TurnOff':
    return 0
  case 'TurnOn':
    return 1
  case 'AnyChanges':
    return 2
  default:
    return 0
  }
}
export default convertRoomOrGroupStateToBackend
