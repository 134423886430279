import {
  VALIDATION_REG_EXP_EMAIL,
  VALIDATION_REG_EXP_MOBILE,
  VALIDATION_REG_EXP_NAME,
} from '@app/lib/Contacts/contactsValidationRegExps'

export const validate = (values) => {
  const { contacts: { contactsList, formInitialValues } } = store.getState()
  const uniqueNumbersArray = contactsList.map((contact) => ({
    phone_number: contact.phone_number,
  }))
  const initialNumber = formInitialValues ? formInitialValues.phone_number : ''
  const errors = {}
  const preferred_name = 'preferred_name'
  const phone_number = 'phone_number'
  const email = 'email'
  const requiredFields = [preferred_name, phone_number]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })
  if (values[preferred_name] && !VALIDATION_REG_EXP_NAME.test(values[preferred_name])) {
    errors[preferred_name] = I18n.t('contacts_web.name_validation')
  }
  if (values[phone_number] && !VALIDATION_REG_EXP_MOBILE.test(values[phone_number])) {
    errors[phone_number] = I18n.t('contacts_web.phone_number_validation')
  }
  if (uniqueNumbersArray.find((contact) => contact.phone_number === values[phone_number]
    && contact.phone_number !== initialNumber)) {
    errors[phone_number] = I18n.t('contacts_web.contact_already_exists')
  }
  if (values[email] && !VALIDATION_REG_EXP_EMAIL.test(values[email])) {
    errors[email] = I18n.t('contacts_web.email_validation')
  }
  return errors
}
