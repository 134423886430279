import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/keypad/cancel_panic`

const cancelPanic = (accessToken, data) => Axios.patch(ENDPOINT, data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  cancelPanic,
}
