import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3811 45C10.6197 45 8.3811 47.2386 8.3811 50V82.6819C8.3811 85.4434 10.6197 87.6819 13.3811 87.6819H136C138.761 87.6819 141 85.4434 141 82.682V50C141 47.2386 138.761 45 136 45H13.3811ZM13.3811 50L136 50L136 82.682L13.3811 82.6819L13.3811 50Z" fill="currentColor"/>
            <path d="M22.8625 59.1009H47.2521V73.5822H22.8625V59.1009Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M74.8811 71.2949C77.512 71.2949 79.6447 69.1622 79.6447 66.5313C79.6447 63.9005 77.512 61.7677 74.8811 61.7677C72.2502 61.7677 70.1175 63.9005 70.1175 66.5313C70.1175 69.1622 72.2502 71.2949 74.8811 71.2949Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M90.1247 71.2949C92.7555 71.2949 94.8883 69.1622 94.8883 66.5313C94.8883 63.9005 92.7555 61.7677 90.1247 61.7677C87.4938 61.7677 85.3611 63.9005 85.3611 66.5313C85.3611 69.1622 87.4938 71.2949 90.1247 71.2949Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M105.368 71.2949C107.999 71.2949 110.132 69.1622 110.132 66.5313C110.132 63.9005 107.999 61.7677 105.368 61.7677C102.737 61.7677 100.605 63.9005 100.605 66.5313C100.605 69.1622 102.737 71.2949 105.368 71.2949Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M120.612 71.2949C123.243 71.2949 125.375 69.1622 125.375 66.5313C125.375 63.9005 123.243 61.7677 120.612 61.7677C117.981 61.7677 115.848 63.9005 115.848 66.5313C115.848 69.1622 117.981 71.2949 120.612 71.2949Z" fill="currentColor"/>
            <path d="M22.8624 84.25H35.8624V101.78H22.8624V84.25Z" fill="currentColor"/>
            <path d="M110.279 83.9922H123.279V101.522H110.279V83.9922Z" fill="currentColor"/>
            <path d="M8 101.02C8 99.126 9.53557 97.5905 11.4298 97.5905H137.189C139.083 97.5905 140.619 99.126 140.619 101.02C140.619 102.914 139.083 104.45 137.189 104.45H11.4298C9.53556 104.45 8 102.914 8 101.02Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwAVIcon')

export default icon
