import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { conditionTriggeringDeviceTypes, conditionTriggeringDeviceTypesNCentral } from '@app/lib/Scenes/conditionTriggeringObservingDeviceTypes'
import {
  SELECT_TRIGGERING_DEVICE,
  SELECT_TRIGGERING_GROUP,
  SELECT_TRIGGERING_ROOM,
  SELECT_TRIGGERING_ZWAVE_DEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTriggeringDeviceType extends React.Component {
  onBackClick = () => {
    const { navigate } = this.props
    navigate(SELECT_TRIGGERING_DEVICE)
  }

  onUseClick=(type) => {
    const {
      navigate,
      saveTriggeringDeviceType,
    } = this.props
    switch (type.type) {
    case 'ZWaveDevices':
      saveTriggeringDeviceType(2)
      return navigate(SELECT_TRIGGERING_ZWAVE_DEVICE)
    case 'RoomActivity':
      saveTriggeringDeviceType(3)
      return navigate(SELECT_TRIGGERING_ROOM)
    case 'GroupActivity':
      saveTriggeringDeviceType(4)
      return navigate(SELECT_TRIGGERING_GROUP)
    default:
      throw new Error('condition unsupported')
    }
  }

  render() {
    const {
      classes,
      isMobile,
      isNCentral,
    } = this.props
    const typesList = isNCentral ? conditionTriggeringDeviceTypesNCentral : conditionTriggeringDeviceTypes
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_triggering_device')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            typesList.map((type) => (
              <CardBlock
                key={type.title}
                title={I18n.t(`scene_web.${type.title}`)}
                description={I18n.t(`scene_web.${type.description}`)}
                position={type.position}
                buttonName={I18n.t(`scene_web.${type.buttonName}`)}
                isCardDisabled={type.isCardDisabled}
                isButtonDisabled={type.isButtonDisabled}
                onClick={() => {
                  this.onUseClick(type)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectTriggeringDeviceType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveTriggeringDeviceType: PropTypes.func.isRequired,
  isNCentral: PropTypes.bool.isRequired,
}

SelectTriggeringDeviceType.defaultProps = {

}

SelectTriggeringDeviceType.displayName = 'SelectTriggeringDeviceType'

export default SelectTriggeringDeviceType
