import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noActivities: {
    margin: 'auto',
  },
}))

export default useStyles
