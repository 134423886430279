export const multilevelSwitchActionTypes = [
  {
    title: null,
    description: 'turn off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'turn_off',
  },
  {
    title: null,
    description: 'turn on previous',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'turn_on_previous',
  },
  {
    title: null,
    description: 'turn on at',
    position: 'middle',
    buttonName: 'choose',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'turn_on_at',
  },
  {
    title: null,
    description: 'toggle',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'toggle',
  },
]
