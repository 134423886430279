import * as type from '@app/constants/scenes/twrDevices/twrDevicesByRooms'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/twrDevices/twrDevicesByRooms'
import { IRoomTwrBackend } from '@app/@types/scenes/scenesDevices'

type ITwrDevicesByRooms = ReturnType<InferValueTypes<typeof actions>>

interface TwrDevicesByRoomsState {
  rooms: Array<IRoomTwrBackend>,
}

const initialState = {
  rooms: [],
}

export default function twrDevicesByGroups(state: TwrDevicesByRoomsState = initialState, action: ITwrDevicesByRooms) {
  switch (action.type) {
  case type.RESET_TWR_DEVICES_BY_ROOMS:
    return initialState

  case type.UPDATE_TWR_DEVICES_BY_ROOMS: {
    return {
      ...state,
      rooms: action.rooms,
    }
  }

  default:
    return state
  }
}
