import { mezzoChimesState } from '@app/lib/Scenes/mezzoChimesState'
import { alarmPanicDuressValues, muteOrUnmuteVoiceValues } from '@app/lib/Scenes/mezzoActionsValues'

const getActionDataToBackend = (actionType, stateDescription, actionDeviceStateInfo) => {
  let currentChime
  switch (actionType) {
  case 'ArmTheSystem':
  case 'ArmInHomeMode':
  case 'Disarm':
  case 'ToggleVoice':
    return { type: actionType }

  case 'SetZwaveColor':
  case 'LightMezzo':
  case 'MezzoBottomLightColor':
  case 'MezzoTopLightColor':
  case 'ZwaveColorSetWithDuration':
  case 'ZwaveColorDelayDuration':
  case 'DelayedDeviceAction':
  case 'DelayedPlusDurationDeviceAction':
  case 'ToggleZwaveDeviceWithDuration':
  case 'SetDeviceStatus':
  case 'Toggle':
  case 'ControlRoom':
  case 'RoomColorControl':
  case 'RoomColorWithDuration':
  case 'RoomColorDelayDuration':
  case 'ControlGroup':
  case 'GroupColorControl':
  case 'GroupColorWithDuration':
  case 'GroupColorDelayDuration':
  case 'StartTimer':
  case 'StopTimer':
  case 'VirtualSwitch':
  case 'VirtualSwitchDelayDuration':
  case 'ZwaveMultichannelControl':
    return { type: actionType, data: actionDeviceStateInfo }
  case 'PushNotifications':
  case 'Email':
  case 'WebRequest':
    return { type: 'WebAction', data: actionDeviceStateInfo }

  case 'PlayAudio':
    currentChime = mezzoChimesState.filter((chime) => chime.description === stateDescription)
    return {
      type: actionType,
      data: {
        value: currentChime[0].stateType,
      },
    }

  case 'MuteOrUnmuteVoice':
    return {
      type: actionType,
      data: {
        value: muteOrUnmuteVoiceValues.indexOf(stateDescription),
      },
    }

  case 'AlarmPanicDuress':
    return {
      type: actionType,
      data: {
        value: alarmPanicDuressValues[stateDescription],
      },
    }

  default:
    throw new Error('action unsupported')
  }
}

export default getActionDataToBackend
