import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (userId) => `${API_URL}/api/sphere_users/${userId}`

const signIn = (accessToken, userId) => Axios.get(ENDPOINT(userId), {
  headers: { 'Access-Token': accessToken },
})

export default {
  signIn,
}
