import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103 130">
            <title> iconDevicePanickey</title>
            <desc> Created with Sketch.</desc>
            <g fill="none">
                <g fill="currentColor"  strokeWidth="5">
                    <path
                        d="M51.3 73.8C57.9 73.8 63.4 70.1 67 65 70.6 59.8 72.5 53.6 72.4 47.3 72.5 41 70.6 34.8 67 29.6 63.4 24.5 57.9 20.8 51.3 20.8 44.7 20.8 39.2 24.5 35.6 29.6 32 34.8 30.1 41 30.2 47.3 30.1 53.6 32 59.8 35.6 65 39.2 70.1 44.7 73.8 51.3 73.8L51.3 73.8Z"/>
                    <path
                        d="M51.3 3.7C64.2 3.7 77 4.9 89.7 7.3 93.9 8.1 97 11.6 97.4 15.8 100.4 48.7 100.4 81.7 97.4 114.5 97 118.7 93.9 122.2 89.7 123 77 125.4 64.2 126.6 51.3 126.7 38.4 126.6 25.5 125.4 12.9 123 8.7 122.2 5.5 118.7 5.2 114.4 2.2 81.6 2.2 48.6 5.2 15.8 5.6 11.6 8.7 8.1 12.9 7.3 25.5 4.9 38.4 3.7 51.3 3.7L51.3 3.7ZM51.3 0.9C38.2 0.9 25.2 2.2 12.4 4.6 7 5.6 2.9 10.1 2.4 15.6 -0.6 48.5-0.6 81.7 2.4 114.7 2.9 120.2 7 124.7 12.4 125.7 25.2 128.1 38.2 129.3 51.3 129.4 64.3 129.3 77.4 128.1 90.2 125.7 95.6 124.7 99.6 120.2 100.1 114.7 103.1 81.7 103.1 48.5 100.1 15.6 99.6 10.1 95.6 5.6 90.2 4.6 77.4 2.2 64.3 0.9 51.3 0.9L51.3 0.9Z"/>
                </g>
            </g>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwPanicButtonKeyFobIcon')

export default icon
