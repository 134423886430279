import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.96875 17.0156H3V9H0L9.98438 0L19.9688 9H16.9688V17.0156H12H7.96875ZM11.8713 9.34375C12.037 9.34375 12.1832 9.41146 12.3099 9.54688C12.4366 9.67188 12.5 9.82292 12.5 10V13.3438C12.5 13.5208 12.4366 13.6771 12.3099 13.8125C12.1832 13.9375 12.037 14 11.8713 14H8.12865C7.96296 14 7.81676 13.9375 7.69006 13.8125C7.56335 13.6771 7.5 13.5208 7.5 13.3438V10C7.5 9.82292 7.56335 9.67188 7.69006 9.54688C7.81676 9.41146 7.96296 9.34375 8.12865 9.34375H10.9649V8.67188C10.9649 8.39062 10.8674 8.15104 10.6725 7.95312C10.4873 7.74479 10.2632 7.64062 10 7.64062V7C10.4289 7 10.7943 7.16667 11.0965 7.5C11.4084 7.82292 11.5643 8.21354 11.5643 8.67188V9.34375H11.8713Z" fill="currentColor"/>
</svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'HomeDisarmedIcon')

export default icon
