const checkPeriod = (currentChart) => {
  const indexOfFilledEnergy = currentChart.findIndex((value) => value > 0)
  return indexOfFilledEnergy === -1
}

const checkZeroDataForGraph = (period, energies, unit) => {
  const currentUnit = energies.chart[unit]

  switch (period) {
  case 'last_week_period':
    return checkPeriod(currentUnit.week)
  case 'last_month_period':
    return checkPeriod(currentUnit.month)
  case 'last_6_month_period':
    return checkPeriod(currentUnit.half_year)
  case 'last_year_period':
    return checkPeriod(currentUnit.year)
  default:
    return true
  }
}

export default checkZeroDataForGraph
