import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  title: {
    fontSize: '18px',
    marginBottom: 11,
    padding: '0 16px',
  },
  devicesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    width: 132,
    height: 132,
  },
  empty: {
    backgroundColor: theme.backgrounds.cardBackground,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    padding: '9px 20px',
    fontSize: '16px',
    margin: '0 16px',
  },
}))

export default useStyles
