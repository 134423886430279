import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/devices/eco_action`

const postEcoModeDevicesTurnOff = (accessToken) => Axios.post(ENDPOINT, null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  postEcoModeDevicesTurnOff,
}
