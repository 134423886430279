import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from '@app/components/PropTypes'
import styles from './GroupsList.style'
import Group from './Group'

class GroupsList extends React.Component {
  render() {
    const {
      classes,
      saveConditionDescription,
      saveConditionGroupNumber,
      navigate,
      groupsScenes,
    } = this.props
    return (
      <List className={groupsScenes.length ? classes.root : classes.rootEmpty}>
        {groupsScenes.length
          ? groupsScenes.map((group) => (
            <ListItem className={classes.listItem} key={`group-${group.id}`}>
              <Group
                group={group}
                saveConditionDescription={saveConditionDescription}
                saveConditionGroupNumber={saveConditionGroupNumber}
                navigate={navigate}
              />
            </ListItem>
          )) : <Typography className={classes.empty} align="center">NO GROUPS</Typography>}
      </List>
    )
  }
}

GroupsList.propTypes = {
  classes: PropTypes.object.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionGroupNumber: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  groupsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

export default withStyles(styles)(GroupsList)
