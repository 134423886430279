import * as type from '@app/constants/scenes/anotherScenesList'

export const saveScenesForAnotherSceneCondition = (scenes) => ({
  type: type.SAVE_SCENES_FOR_ANOTHER_SCENE_CONDITION,
  scenes,
})

export const syncScenesForAnotherSceneCondition = () => ({
  type: type.SYNC_SCENES_FOR_ANOTHER_SCENE_CONDITION,
})
