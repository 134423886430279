export const INC_DAYS = '@@counter/IncDays'
export const DEC_DAYS = '@@counter/DecDays'

export const INC_HOURS = '@@counter/IncHours'
export const DEC_HOURS = '@@counter/DecHours'

export const INC_MINUTES = '@@counter/IncMinutes'
export const DEC_MINUTES = '@@counter/DecMinutes'

export const INC_SECONDS = '@@counter/IncSeconds'
export const DEC_SECONDS = '@@counter/DecSeconds'

export const MAX_DAYS = '@@counter/maxDays'
export const MAX_HOURS = '@@counter/maxHours'
export const MAX_MINUTES = '@@counter/maxMinutes'
export const MAX_SECONDS = '@@counter/maxSeconds'

export const RESET_DAYS = '@@counter/resetDays'
export const RESET_HOURS = '@@counter/resetHours'
export const RESET_MINUTES = '@@counter/resetMinutes'
export const RESET_SECONDS = '@@counter/resetSeconds'

export const RESET_COUNTER = '@@counter/reset'
