import { createLogic } from 'redux-logic'
import {
  SYNC_CANCEL_NOTIFICATIONS_CHANNEL,
  SYNC_NOTIFICATIONS_CHANNEL,
} from '@app/constants/notifications/notificationsChannel'

import { syncSent, update } from '@app/actions/notifications/notificationsChannel'
import { setNewNotification } from '@app/actions/notifications/notifications'
import notificationsChannel from '@app/api/notifications/notificationsChannel'

const subscribeNotificationsChannelLogic = createLogic({
  type: SYNC_NOTIFICATIONS_CHANNEL,
  cancelType: SYNC_CANCEL_NOTIFICATIONS_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    notificationsChannel({
      request: () => {
        dispatch(syncSent())
      },
      response: (data) => {
        if (data.created_at) {
          store.dispatch(setNewNotification(data))
        }
        store.dispatch(update(data))
      },
    })
  },
})

export default subscribeNotificationsChannelLogic
