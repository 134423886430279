import * as type from '@app/constants/guardPanel'
import { Status } from '@app/lib/guardPanel/guardPanel'

export const getGuardPanelStatus = () => ({
  type: type.GET_GUARD_PANEL_STATUS,
}as const)

export const updateGuardPanelStatus = (status: string) => ({
  type: type.UPDATE_GUARD_PANEL_STATUS,
  status,
}as const)

export const syncGuardPanelChannel = () => ({
  type: type.SYNC_GUARD_PANEL_CHANNEL,
}as const)

export const syncGuardPanelChannelSent = () => ({
  type: type.SYNC_GUARD_PANEL_CHANNEL_SENT,
}as const)

export const openGuardPanelChannel = () => ({
  type: type.OPEN_GUARD_PANEL_CHANNEL,
}as const)

export const closeGuardPanelChannel = () => ({
  type: type.CLOSE_GUARD_PANEL_CHANNEL,
}as const)

export const unsubscribeGuardPanelChannel = () => ({
  type: type.UNSUBSCRIBE_GUARD_PANEL_CHANNEL,
}as const)

export const setGuardPanelStatus = (status: Status) => ({
  type: type.SET_GUARD_PANEL_STATUS,
  status,
}as const)

export const setGuardPanelTimeout = () => ({
  type: type.SET_GUARD_PANEL_TIMEOUT,
}as const)

export const resetGuardPanelTimeout = () => ({
  type: type.RESET_GUARD_PANEL_TIMEOUT,
}as const)

export const resetGuardPanelProgress = () => ({
  type: type.RESET_GUARD_PANEL_PROGRESS,
}as const)

export const resetGuardPanelReducer = () => ({
  type: type.RESET_GUARD_PANEL_REDUCER,
}as const)

export const setGuardPanelProgress = (progress: boolean) => ({
  type: type.SET_GUARD_PANEL_PROGRESS,
  progress,
}as const)
