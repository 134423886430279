import {
  saveActionDescription,
  saveActionDeviceId,
  saveActionDeviceName,
  saveActionDeviceNumber,
  saveActionType,
  saveInitialActionInfo,
  saveActionZWaveCapabilityId,
} from '@app/actions/scenes/currentSceneCreating'
import { syncTwrDeviceStates } from '@app/actions/scenes/twrDevices/twrDevices'
import { syncZWaveDeviceCapabilities } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { useDispatch } from '@app/store'

const withSelectDeviceActionBehavior = (deviceType: string, device: any) => {
  const dispatch = useDispatch()

  const selectDevice = () => {
    if (deviceType === 'TwrDevices') {
      dispatch(saveActionDescription(device.device_type))
      dispatch(saveActionDeviceNumber(device.number))
      dispatch(saveActionDeviceName(device.name))
      dispatch(saveActionDeviceId(device.id))
      dispatch(saveActionType('TwrDevices'))
      dispatch(saveInitialActionInfo())
      dispatch(syncTwrDeviceStates())
    } else if (deviceType === 'ZWaveDevices') {
      dispatch(saveActionDeviceName(device.name))
      dispatch(saveActionDeviceNumber(device.number))
      dispatch(saveActionZWaveCapabilityId(device.capability_id))
      dispatch(saveActionDeviceId(device.id))
      dispatch(saveInitialActionInfo())
      dispatch(syncZWaveDeviceCapabilities())
    } else {
      throw new Error('action unsupported')
    }
  }
  return selectDevice
}

export default withSelectDeviceActionBehavior
