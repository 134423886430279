import { createLogic } from 'redux-logic'
import Api from '@app/api/mySecurity/cancelPanic'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { CANCEL_PANIC } from '@app/constants/mySecurity/mySecurity'
import { closePanicPopup } from '../../actions/mySecurity/mySecurity'

const cancelPanic = createLogic({
  type: CANCEL_PANIC,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { mySecurity: { workerInfo } } = getState()
    const data = { jid: workerInfo }

    next({
      ...action,
      accessToken,
      data,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(CANCEL_PANIC))
    try {
      await Api.cancelPanic(action.accessToken, action.data)
      dispatch(apiRequestDone(CANCEL_PANIC))
      dispatch(closePanicPopup())
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('cancelPanic failed without error')
      }
    } finally {
      done()
    }
  },
})

export default cancelPanic
