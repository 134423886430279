import * as type from '@app/constants/unit'

export const becomeOnlineUnit = () => ({
  type: type.BECOME_ONLINE_UNIT,
})

export const becomeOfflineUnit = () => ({
  type: type.BECOME_OFFLINE_UNIT,
})

export const updateUnit = (unit) => ({
  type: type.UPDATE_UNIT,
  unit,
})

export const resetUnit = () => ({
  type: type.RESET_UNIT,
})

export const change = (unit) => ({
  type: type.CHANGE_UNIT,
  unit,
})

export const selectUnit = (selectedUnit) => ({
  type: type.SELECT_UNIT,
  selectedUnit,
})

export const patchUnit = () => ({
  type: type.PATCH_UNIT,
})

export const patchUnitLocation = () => ({
  type: type.PATCH_UNIT_LOCATION,
})

export const patchUnitName = (name, serialNumber) => ({
  type: type.PATCH_UNIT_NAME,
  name,
  serialNumber,
})

export const updateUnitCoordinates = (coordinates) => ({
  type: type.UPDATE_UNIT_COORDINATES,
  coordinates,
})

export const fetchUnit = () => ({
  type: type.FETCH_UNIT,
})

export const updateUnitArmStatuses = (unit) => ({
  type: type.UPDATE_UNIT_ARM_STATUSES,
  unit,
})

export const openEditUnitNamePopup = () => ({
  type: type.OPEN_EDIT_UNIT_NAME_POPUP,
})

export const closeEditUnitNamePopup = () => ({
  type: type.CLOSE_EDIT_UNIT_NAME_POPUP,
})

export const openUnitList = () => ({
  type: type.OPEN_UNIT_LIST,
})

export const closeUnitList = () => ({
  type: type.CLOSE_UNIT_LIST,
})
