import React, { useEffect, useState } from 'react'
import {
  IconButton, Typography, Slider, Button,
} from '@mui/material'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import { MY_DEVICES } from '@app/constants/routes'
import { MEZZO_LIGHTS_ICON_ID } from '@app/constants/devicesIconsRestrictions'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { renderIcon } from '@app/helpers/myDevices/renderIcon'
import { mezzoLightsOffValue, mezzoLightsMaxValue, mezzoLightsValues } from '@app/lib/MyDevices/mezzoLights'
import { patchMezzoLights } from '@app/actions/myDevices/mezzoLights'
import { syncMezzoLightsChannel } from '@app/actions/myDevices/channels/devices/mezzoLightsChannel'
import useStyles from './MezzoLightsSettings.style'

interface Props {
  mezzoLights: number,
  isMobile: boolean,
}

const MezzoLightsSettings: React.FC<Props> = (props) => {
  const { mezzoLights, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [value, setSliderValue] = useState<number | Array<number>>(mezzoLights)

  useEffect(() => {
    dispatch(syncMezzoLightsChannel())
  }, [])

  useEffect(() => {
    setSliderValue(mezzoLights)
  }, [mezzoLights])

  const handleSliderChange = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    setSliderValue(newValue)
  }
  const handleSaveClick = () => {
    dispatch(patchMezzoLights(value))
  }

  const goBack = () => navigate(MY_DEVICES)

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classes.navigationControls}>
        <IconButton
          size="small"
          className={classes.arrowBack}
          onClick={goBack}
        >
          <ScenesArrowBack />
        </IconButton>
      </Wrapper>
      <Wrapper className={classes.contentContainer}>
        <Wrapper className={classes.iconAndName}>
          <Wrapper className={classNames(classes.iconContainer, isMobile && classes.iconContainerMobile)}>
            {renderIcon(MEZZO_LIGHTS_ICON_ID, { className: classNames(classes.icon, isMobile && classes.iconMobile) })}
          </Wrapper>
          <Wrapper className={classes.nameContainer}>
            <Typography variant="h6" className={classes.deviceName}>
              {`${I18n.t('my_devices.mezzo_lights')}`}
            </Typography>
            <Typography className={classes.lightsValue}>
              {`${I18n.t(`my_devices.${mezzoLightsValues[mezzoLights]}`)}`}
            </Typography>
          </Wrapper>
        </Wrapper>
        <Wrapper className={classNames(isMobile ? classes.sliderWrapperMobile : classes.sliderWrapper)}>
          <Typography variant="h6" className={classes.deviceName}>
            {`${I18n.t('my_devices.off')}`}
          </Typography>
          <Slider
            className={classes.slider}
            aria-label="LightsValue"
            min={mezzoLightsOffValue}
            max={mezzoLightsMaxValue}
            value={value}
            onChangeCommitted={handleSliderChange}
            marks
            size="small"
          />
          <Typography variant="h6" className={classes.deviceName}>
            {`${I18n.t('my_devices.high')}`}
          </Typography>
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.saveButton}
          onClick={handleSaveClick}
        >
          {I18n.t('buttons.save')}
        </Button>
      </Wrapper>
    </Wrapper>
  )
}

export default MezzoLightsSettings
