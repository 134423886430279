import * as type from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import {
  IBinarySensorType,
  IBinarySensorValue,
  IZwaveDeviceBackend,
  IZWaveDeviceCapabilities,
  IZWaveDeviceStates,
  IMultilevelSensorType,
} from '@app/@types/scenes/scenesDevices'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/zWaveDevices/zWaveDevices'

type IZwaveDevices = ReturnType<InferValueTypes<typeof actions>>

interface ZwaveDevicesState {
  devices: Array<IZwaveDeviceBackend>,
  zWaveDeviceCapabilities: {} | IZWaveDeviceCapabilities,
  zWaveDeviceStates: {} | IZWaveDeviceStates,
  binarySensorTypes: Array<IBinarySensorType>,
  binarySensorValues: Array<IBinarySensorValue>,
  multilevelSensorTypes: Array<IMultilevelSensorType>,
}
const initialState = {
  devices: [],
  zWaveDeviceCapabilities: {},
  zWaveDeviceStates: {},
  binarySensorTypes: [],
  binarySensorValues: [],
  multilevelSensorTypes: [],
}

export default function zWaveDevices(state: ZwaveDevicesState = initialState, action: IZwaveDevices) {
  switch (action.type) {
  case type.RESET_ZWAVE_DEVICES:
    return initialState

  case type.UPDATE_ZWAVE_DEVICES: {
    return {
      ...state,
      devices: action.zWaveDevices,
    }
  }

  case type.UPDATE_ZWAVE_DEVICE_CAPABILITIES: {
    return {
      ...state,
      zWaveDeviceCapabilities: action.zWaveDeviceCapabilities,
    }
  }

  case type.UPDATE_ZWAVE_DEVICE_STATES: {
    return {
      ...state,
      zWaveDeviceStates: action.zWaveDeviceStates,
    }
  }

  case type.UPDATE_ZWAVE_DEVICE_BINARY_SENSOR_TYPES: {
    return {
      ...state,
      binarySensorTypes: action.binarySensorTypes,
    }
  }

  case type.UPDATE_ZWAVE_DEVICE_BINARY_SENSOR_VALUES: {
    return {
      ...state,
      binarySensorValues: action.binarySensorValues,
    }
  }

  case type.UPDATE_ZWAVE_DEVICE_MULTILEVEL_SENSOR_TYPES: {
    return {
      ...state,
      multilevelSensorTypes: action.multilevelSensorTypes,
    }
  }

  default:
    return state
  }
}
