import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  rootMobile: {
    padding: '1rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  settingsGrid: {
    display: 'grid',
    gridGap: '1.25rem',
    alignItems: 'center',
    justifyItems: 'center',
    height: '100%',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr));',
  },
  settingsGridMobile: {
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
  },
  setting: {
    position: 'relative',
    backgroundColor: theme.backgrounds.cardBackground,
    width: 320,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 160,
    padding: 17,
    borderRadius: 5,
    marginBottom: 70,
    cursor: 'pointer',
    zIndex: 1,
  },
  settingCentered: {
    alignItems: 'center',
  },
  settingDisabled: {
    cursor: 'not-allowed',
  },
  settingMobile: {
    width: '100%',
    marginBottom: 20,
  },
  cardLeftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  chevronIcon: {
    transform: 'rotate(180deg)',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  rightSideIcons: {
    display: 'flex',
    width: 100,
    justifyContent: 'space-between',
  },
  timerTitle: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  timerType: {
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgba(255, 255, 255, 0.5)',
    margin: '3px 0',
  },
  timerDuration: {
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  button: {
    position: 'absolute',
    marginTop: 10,
    width: 48,
    zIndex: 999,
    cursor: 'pointer',
    bottom: 17,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  progressDots: {
    position: 'absolute',
    bottom: 17,
  },
}))

export default useStyles
