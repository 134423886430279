import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  coloursWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainColorsWrapper: {
    width: '90%',
    marginTop: 45,
    marginBottom: 80,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainColorsWrapperMobile: {
    margin: 30,
  },
  mainColor: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',
    margin: '12px',
  },
  checked: {
    position: 'relative',
    display: 'inline-block',
    transform: 'rotate(45deg)',
    height: 16,
    width: 10,
    borderBottom: '2px solid #000',
    borderRight: '2px solid #000',
    bottom: 2,
    opacity: 0,
    transition: 'opacity 0.1s ease-in',
  },
  show: {
    opacity: 1,
  },
  colorPickerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bigPicker: {
    width: 364,
    height: 364,
    borderRadius: '50%',
    overflow: 'hidden',
    transform: 'rotate(270deg)',
  },
  smallPicker: {
    width: 250,
    height: 250,
    position: 'absolute',
    borderRadius: '50%',
    overflow: 'hidden',
    transform: 'rotate(270deg)',
    zIndex: 3,
  },
  color: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transformOrigin: 'center',
  },
  smallPickerElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '100px',
    borderColor: 'transparent transparent transparent transparent',
    transformOrigin: 'center',
    clipPath: 'polygon(0% 100%, 100% 100%, -140% 0%)',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    '&:hover': {
      transition: 'opacity 0.1s ease-in',
      opacity: 0.7,
    },
  },
  bigPickerElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '100px',
    borderColor: 'transparent transparent transparent transparent',
    transformOrigin: 'center',
    clipPath: 'polygon(0% 100%, 100% 100%, -140% 0%)',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    '&:hover': {
      transition: 'opacity 0.1s ease-in',
      opacity: 0.7,
    },
  },
  bigBorder: {
    position: 'absolute',
    width: 270,
    height: 270,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#fff',
    zIndex: 100,
    overflow: 'hidden',
    boxShadow: '0 0 8.5px 0 #535252',
  },
  smallBorder: {
    position: 'absolute',
    width: 170,
    height: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#fff',
    overflow: 'hidden',
    boxShadow: '0 0 8.5px 0 #535252',
    zIndex: 500,
  },
  pickedColor: {
    zIndex: 1000,
    width: 150,
    height: 150,
    borderRadius: '50%',
    boxShadow: '0 0 8.5px 0 #535252',
    position: 'absolute',
    transition: 'background-color 1s ease',
    pointerEvents: 'none',
  },
  '@media (max-width: 450px)': {
    colorPickerWrapper: {
      transform: 'scale(0.9)',
    },
  },
  '@media (max-width: 350px)': {
    colorPickerWrapper: {
      transform: 'scale(0.7)',
    },
  },
}))

export default useStyles
