import * as type from '@app/constants/po/poConfig'
import { IPOConfigFromBackend, IPOFromBackend } from '@app/@types/po/poConfig'

export const savePoConfig = (items: Array<IPOConfigFromBackend>, initials: Array<IPOFromBackend>) => ({
  type: type.SAVE_PO_CONFIG,
  items,
  initials,
} as const)

export const resetPoConfig = () => ({
  type: type.RESET_PO_CONFIG,
} as const)

export const sync = () => ({
  type: type.SYNC_PO_FROM_CONFIG,
} as const)

export const set = (programOptions: Array<IPOFromBackend>) => ({
  type: type.SET_PO_FROM_CONFIG,
  programOptions,
} as const)

export const setInitialOptions = (programOptions: Array<IPOFromBackend>) => ({
  type: type.SET_PO_FROM_CONFIG_INITIAL_OPTIONS,
  programOptions,
} as const)

export const setCurrentOptions = (programOptions: Array<IPOFromBackend>) => ({
  type: type.SET_PO_FROM_CONFIG_CURRENT_OPTIONS,
  programOptions,
} as const)

export const setValidation = (validation: boolean) => ({
  type: type.SET_PO_FROM_CONFIG_VALIDATION,
  validation,
} as const)

export const startSavingProgress = () => ({
  type: type.START_SAVING_PROGRESS,
} as const)

export const endSavingProgress = () => ({
  type: type.END_SAVING_PROGRESS,
} as const)

export const apiRequestFailed = (message: string) => ({
  type: type.API_REQUEST_FAILED,
  message,
} as const)
