import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const getTwrHealth = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/twr_devices/diagnostic/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  getTwrHealth,
}
