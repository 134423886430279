const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  title: {
    margin: '25px 30px 0px 30px',
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
  },
  dialogContent: {
    paddingBottom: 35,
    margin: '0 auto',
  },
})

export default styles
