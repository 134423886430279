import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M109.326 127.212L108.921 127.41L76.9035 72.8427L77.3086 72.6441L109.326 127.212Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M48.6666 75.4173V113.194H102.138V75.4173H48.6666ZM53.4508 80.2015H97.354V108.41H53.4508V80.2015Z" fill="currentColor"/>
            <path d="M75.1799 73.1836H75.9742V141H75.1799V73.1836Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M40.6919 128.8L41.097 128.999L73.9091 72.8427L73.512 72.6441L40.6919 128.8Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M29 103.947L29.2383 104.328L71.272 72.3339L71.0417 71.9606L29 103.947Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M75.6455 54.8903C73.6516 54.8903 70.6221 55.8471 70.6221 55.8471V69.2428C70.6221 69.2428 73.6516 70.1996 75.6455 70.1996C77.6394 70.1996 80.6689 69.2428 80.6689 69.2428V55.8471C80.6689 55.8471 77.6394 54.8903 75.6455 54.8903ZM72.8285 67.8594C72.7282 67.8355 72.6304 67.8117 72.5358 67.788V57.3019C72.6304 57.2783 72.7282 57.2544 72.8285 57.2305C73.8104 56.9972 74.8843 56.804 75.6455 56.804C76.4066 56.804 77.4806 56.9972 78.4624 57.2305C78.5628 57.2544 78.6606 57.2783 78.7552 57.3019V67.788C78.6606 67.8117 78.5628 67.8355 78.4624 67.8594C77.4806 68.0927 76.4066 68.2859 75.6455 68.2859C74.8843 68.2859 73.8104 68.0927 72.8285 67.8594Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.1439 12.3626L32.6951 13.3903V125.043L37.3247 125.957C62.4726 130.919 88.3478 130.919 113.496 125.957L118.125 125.043V13.3903L113.677 12.3626C88.4972 6.54581 62.3232 6.54581 37.1439 12.3626ZM38.4361 120.324C62.8501 125.142 87.9703 125.142 112.384 120.324V17.9562C88.0553 12.3359 62.7651 12.3359 38.4361 17.9562V120.324Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M121.813 103.947L121.574 104.328L79.5407 72.3339L79.771 71.9606L121.813 103.947Z" fill="currentColor"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PIRIcon')

export default icon
