import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M70.1215 106.855C96.5518 111.396 115.644 82.189 100.881 59.8085C86.1185 37.4281 51.7524 43.4162 45.5137 69.5379" stroke="currentColor" strokeWidth="3"/>
            <path d="M16.0361 141.001V127.743C16.0361 124.154 18.9459 121.244 22.5352 121.244H29.285C32.8744 121.244 35.7841 118.334 35.7841 114.745V107.995C35.7841 104.406 38.6939 101.496 42.2832 101.496H49.033C52.6224 101.496 55.5321 98.586 55.5321 94.9967V88.2469C55.5244 86.5115 56.211 84.8451 57.439 83.6189C58.667 82.3927 60.3344 81.7085 62.0698 81.7189H75.3187" stroke="currentColor" strokeWidth="3"/>
            <path d="M85.2605 90.5508H72.002C68.4126 90.5508 65.5029 93.4605 65.5029 97.0499V103.8C65.5029 105.523 64.8182 107.176 63.5993 108.395C62.3805 109.614 60.7274 110.299 59.0038 110.299H52.254C48.6646 110.299 45.7549 113.209 45.7549 116.798V130.056" stroke="currentColor" strokeWidth="3"/>
            <path d="M65.3679 72.8945H52.1094C48.535 72.8945 45.6315 75.7808 45.6103 79.3551V86.1049C45.6103 89.6942 42.7005 92.604 39.1112 92.604H32.3614C28.772 92.604 25.8623 95.5137 25.8623 99.103V112.362" stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M105.992 94.2748C108.458 90.0422 109.99 85.3308 110.485 80.457L124.746 92.7417L105.992 94.2748Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M68.0386 111.293C72.8269 112.33 77.7816 112.33 82.5699 111.293L75.3091 128.65L68.0386 111.293Z" stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M60.8261 44.3807C56.3434 46.3573 52.3356 49.2703 49.0718 52.924L44.7134 34.5742L60.8261 44.3807Z" stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.6168 59.1038C42.1505 63.3364 40.6184 68.0478 40.1234 72.9216L10 55.4492L44.6168 59.1038Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M82.5699 42.0383C77.7816 41.0016 72.8269 41.0016 68.0386 42.0383L75.3091 8L82.5699 42.0383Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M101.537 52.924C98.2733 49.2703 94.2654 46.3573 89.7827 44.3807L105.886 34.5742L101.537 52.924Z" stroke="currentColor" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M110.475 72.8738C109.977 68.0174 108.448 63.3232 105.992 59.1042L140.609 55.4883L110.475 72.8738Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M89.7827 108.946C94.2654 106.969 98.2732 104.056 101.537 100.402L115.673 132.223L89.7827 108.946Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwUVIcon')

export default icon
