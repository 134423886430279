import React, { useState } from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography,
} from '@mui/material'
import { postNewGroup } from '@app/actions/groups/groups'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import validateName from '@app/helpers/myDevices/validateName'
import { NAME_PATTERN } from '@app/lib/RoomsAndGroups/roomsAndGroups'
import { Close } from 'icons'
import useStyles from './AddGroupPopup.style'

interface Props {
  names: Array<string>,
  open: boolean,
  close: () => void,
}

const AddGroupPopup: React.FC<Props> = (props) => {
  const {
    names,
    open,
    close,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [currentName, setCurrentName] = useState<string>('')

  const validateNonExistentName = (namesArray: Array<string>) => namesArray.map((name) => currentName === name).find((name) => name)

  return (
    <Dialog
      className={classNames(
        classes.root,
      )}
      open={open}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t('rooms_and_groups.add_group')}
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            close()
            setCurrentName('')
          }}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.popupContent}>
        <TextField
          variant="standard"
          label={I18n.t('rooms_and_groups.group_name')}
          className={classes.deviceName}
          inputProps={{ className: classes.innerInput, maxLength: 16, pattern: NAME_PATTERN }}
          value={currentName}
          onChange={(evt) => setCurrentName(evt.target.value.trimLeft())}
        />
        <Typography
          style={validateNonExistentName(names)
            ? { display: 'inline-block' }
            : { display: 'none' }}
          className={classes.errorMessage}
        >
          {I18n.t('rooms_and_groups.name_exists')}
        </Typography>
        <Button
          disabled={validateNonExistentName(names) || !currentName.length || !validateName(currentName)}
          onClick={() => {
            dispatch(postNewGroup({ name: currentName }))
            setCurrentName('')
          }}
          className={classes.saveButton}
        >
          {I18n.t('buttons.save')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default AddGroupPopup
