import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  email: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles
