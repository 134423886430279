import React, { useEffect } from 'react'
import { useDispatch } from '@app/store'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import { ChevronLeft } from 'icons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { sirenOptions, sirenValue } from '@app/lib/MyDevices/sirenOptions'
import { getTwrSirenOption } from '@app/actions/myDevices/twrDevices'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './SirenOptions.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  twrCurrentSirenOption: number,
  isMobile: boolean,
}

const SirenOptions: React.FC<Props> = (props) => {
  const { currentDevice, twrCurrentSirenOption, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (currentDevice.radio_siren_option) {
      if (currentDevice.radio_siren_option >= sirenValue.MIN && currentDevice.radio_siren_option <= sirenValue.MAX) {
        dispatch(getTwrSirenOption(currentDevice.id))
      }
    }
  }, [])

  const goSirenOptions = () => {
    navigate(`${location.pathname}/siren_options`)
  }

  return (
    <Wrapper
      className={classNames(classes.settingsCard, isMobile && classes.settingsCardMobile)}
      onClick={goSirenOptions}
    >
      <Typography className={classNames(classes.cardTitle, classes.marginBottomHelper)}>
        {I18n.t('my_devices.siren_options')}
      </Typography>
      <Wrapper className={classes.subTitleContainer}>
        <Typography className={classes.deviceName}>
          {I18n.t(`my_devices.${sirenOptions[twrCurrentSirenOption]}`)}
        </Typography>
      </Wrapper>
      <ChevronLeft className={classes.arrowRightIcon} />
    </Wrapper>
  )
}
export default SirenOptions
