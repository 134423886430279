import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/keypad/start_panic`

const startPanic = (accessToken) => Axios.patch(ENDPOINT, null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  startPanic,
}
