import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isClientCredentialsTokenValid from '@app/helpers/isClientCredentialsTokenValid'
import { withStyles } from '@app/components/styles'
import { signUpFields } from '@app/lib/Wizard/wizardFields'
import { getClientCredentialsToken, getClientCredentialsTokenDone } from '@app/actions/secrets'
import { hideUserExistsError, signUp } from '@app/actions/wizard/signUp'
import {
  withAccessDenied, withClientCredentialsAuth, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'
import SignUp from '@app/components/Wizard/SignUp/SignUp'
import { validate } from './formValidate'

let enhance = withStyles(SignUp)

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { secrets: { clientCredentialsToken } } = store.getState()
  const { login: { signUp: { isUserExists } } } = store.getState()
  const isTokenValid = isClientCredentialsTokenValid(clientCredentialsToken)
  return {
    isMobile,
    isTablet,
    path,
    isUserExists,
    initialValues: signUpFields.reduce((result, option) => {
      result[option.title] = option.value
      return result
    }, {}),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isTokenValid,
    validAccessToken: isTokenValid,
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  getToken: getClientCredentialsToken,
  getTokenSkip: getClientCredentialsTokenDone,
  hideUserExistsError,
  submit: (dispatch) => dispatch(submit('SignUp')),
}, parentDispatch)

enhance = reduxForm({
  form: 'SignUp',
  validate,
  onSubmit: () => {
    store.dispatch(signUp())
  },
})(enhance)

enhance = withRouter(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withClientCredentialsAuth(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SignUpEnhanced = enhance

export default SignUpEnhanced
