import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_CURRENT_MEMBER } from '@app/constants/users/currentMember'
import Api from '@app/api/users/deleteMember'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { USERS } from '@app/constants/routes'

const deleteCurrentMemberLogic = createLogic({
  type: DELETE_CURRENT_MEMBER,
  cancelType: API_REQUEST_CANCEL,
  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { users: { currentMember: { user: { ids: { sphere: sphereId, unit: unitId } } } } } = getState()
    next({
      ...action,
      token,
      sphereId,
      unitId,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(DELETE_CURRENT_MEMBER))
    Api.deleteMember(action.token, action.sphereId, action.unitId)
      .then(() => {
        dispatch(apiRequestDone(DELETE_CURRENT_MEMBER))
        history.push(USERS)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteMember failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteCurrentMemberLogic
