import React from 'react'
import createSvgIcon from './../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
      <g clipPath="url(#clip0)">
        <path fill="currentColor" d="M20.019 33.75a11.25 11.25 0 0 1-6.355-20.544 1.904 1.904 0 0 1 1.545-.27 1.856 1.856 0 0 1 1.188.963 1.878 1.878 0 0 1-.631 2.413A7.493 7.493 0 0 0 15 28.087a7.497 7.497 0 0 0 12.46-4.812 7.522 7.522 0 0 0-3.27-6.992 1.858 1.858 0 0 1-.598-2.37 1.87 1.87 0 0 1 2.006-1.003c.267.046.521.15.745.303a11.25 11.25 0 0 1 1.15 17.678 11.117 11.117 0 0 1-7.474 2.859zm-5.283-19.625c-.132 0-.26.04-.37.115a10 10 0 1 0 11.274.005.653.653 0 0 0-.53-.094.61.61 0 0 0-.4.322.605.605 0 0 0 .182.774 8.75 8.75 0 1 1-9.835.035.632.632 0 0 0 .074-1.014.61.61 0 0 0-.395-.143z"/>
        <path fill="currentColor" d="M20 23.75a1.875 1.875 0 0 1-1.875-1.875v-10a1.875 1.875 0 1 1 3.75 0v10A1.875 1.875 0 0 1 20 23.75zm0-12.5a.624.624 0 0 0-.625.625v10a.624.624 0 1 0 1.25 0v-10A.624.624 0 0 0 20 11.25zM4.688 20a.617.617 0 0 1-.522-.28.625.625 0 0 1-.091-.47A16.294 16.294 0 0 1 20 6.25a.625.625 0 1 1 0 1.25 15.04 15.04 0 0 0-14.7 12 .625.625 0 0 1-.612.5zM4.375 23.125a.625.625 0 0 1-.625-.625c0-.218.004-.434.013-.65a.626.626 0 0 1 1.25.05c-.009.199-.013.399-.013.6a.625.625 0 0 1-.625.625zM20 38.75a.624.624 0 1 1 0-1.25 15.04 15.04 0 0 0 14.7-12 .625.625 0 0 1 1.225.25A16.294 16.294 0 0 1 20 38.75zM35.613 23.75h-.024a.624.624 0 0 1-.6-.649A16 16 0 0 0 35 22.5a.625.625 0 0 1 1.25 0c0 .218-.004.434-.013.649a.625.625 0 0 1-.624.601zM31.73 3.834c-.44 0-.698.226-.856.666-.551 1.568-1.069 3.373-1.631 4.964l-1.665-4.975c-.146-.44-.45-.666-.9-.666-.597 0-.9.305-.9.903 0 .112.022.236.056.36l2.385 6.634c.214.598.551.903 1.013.903.495 0 .855-.328 1.09-.993l2.307-6.521a.78.78 0 0 0 .079-.327c0-.486-.518-.948-.979-.948z"/>
        <g clipPath="url(#clip1)">
          <path fill="currentColor" d="M37.89 7.858l-.316-.174a9.379 9.379 0 0 0-.375-.2c-.603-.303-.986-.513-1.148-.63a1.248 1.248 0 0 1-.353-.363.846.846 0 0 1-.11-.44c0-.27.09-.49.274-.657.183-.168.445-.252.788-.252.288 0 .581.039.88.116.108.029.227.065.357.11.43.146.91-.047 1.077-.47.157-.398-.024-.853-.426-.998a7.472 7.472 0 0 0-.56-.178 4.771 4.771 0 0 0-1.27-.166c-.903 0-1.61.227-2.123.682-.512.455-.768 1.08-.768 1.875 0 .422.078.791.234 1.107.082.166.179.32.29.464l.26.273.36.29.517.345c.08.042.286.16.372.202.557.278.926.481 1.107.608.18.127.318.26.411.397.094.137.14.294.14.47 0 .315-.106.553-.318.713-.212.16-.517.24-.914.24-.33 0-.696-.056-1.095-.166a5.601 5.601 0 0 1-.283-.087c-.564-.188-1.18.197-1.18.791 0 .304.168.588.451.696.59.227 1.245.34 1.967.34.989 0 1.76-.234 2.316-.701.555-.467.832-1.107.832-1.918 0-.586-.148-1.081-.444-1.485l-.02-.025a4.28 4.28 0 0 0-.489-.49l-.034-.03-.406-.29z"/>
        </g>
        <path stroke="currentColor" strokeWidth=".7" d="M4.415 33.673c2.108-.806 4.21-1.613 6.318-2.42l-.238-.629a.623.623 0 0 0-.806-.36c-1.913.733-3.825 1.466-5.732 2.2a.623.623 0 0 0-.36.806l.018.043a.62.62 0 0 0 .8.36z" clipRule="evenodd"/>
        <path fill="currentColor" fillRule="evenodd" d="M9.536 30.319l.147-.055a.65.65 0 0 1 .305-.037c-.232.525-.47 1.05-.703 1.582-.165.061-.33.129-.5.19.25-.562.5-1.124.75-1.68zM10.562 30.795c-.024-.061-.042-.116-.067-.177a.63.63 0 0 0-.159-.238l-.55 1.228c.165-.061.33-.129.501-.19.086-.207.178-.415.275-.623zM3.92 33.649c.129.06.251.079.392.048.244-.556.495-1.112.739-1.668-.165.061-.33.129-.501.19-.208.483-.422.96-.63 1.43zM8.54 30.697l.495-.19-.746 1.681-.5.19.75-1.68zM7.544 31.082l.495-.195-.746 1.68-.5.19.75-1.675zM6.548 31.461l.495-.19-.746 1.681-.5.19.75-1.68zM5.552 31.846l.495-.195-.746 1.68-.5.19.75-1.675z" clipRule="evenodd"/>
        <path stroke="currentColor" strokeWidth=".7" d="M3.713 35.31h7.393"/>
        <path stroke="currentColor" strokeWidth=".7" d="M4.342 34.009h6.764v4.479a.622.622 0 0 1-.623.623h-6.14a.622.622 0 0 1-.624-.623v-3.856a.618.618 0 0 1 .623-.623z" clipRule="evenodd"/>
        <path fill="currentColor" fillRule="evenodd" d="M5.338 35.31h-.531l-1.003-1.002a.604.604 0 0 1 .288-.25c.415.422.83.837 1.246 1.253zM11.106 34.675v.532L9.91 34.009h.531l.666.666zM10.14 35.31h.532L9.377 34.01h-.538l1.302 1.301zM9.071 35.31h.538L8.308 34.01h-.532l1.295 1.301zM8.008 35.31h.532L7.238 34.01h-.531l1.301 1.301zM6.939 35.31h.532L6.175 34.01h-.538L6.94 35.31zM5.87 35.31h.537L5.106 34.01h-.532L5.87 35.31z" clipRule="evenodd"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="currentColor" d="M0 0h40v40H0z"/>
        </clipPath>
        <clipPath id="clip1">
          <path fill="currentColor" d="M0 0h8.778v9.24H0z" transform="translate(32.11 3.556)"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'VirtualSwitch', '0 0 80 80', {fontSize: 60})

export default icon
