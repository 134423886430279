import React from 'react'
import {
  Button, FormControlLabel, Paper, Radio, RadioGroup, Typography, TextField,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import classNames from 'classnames'
import { convertTimeDataToDescription } from '@app/helpers/scenes/convertTimeDataToDescription'
import { convertTimeDataToBackend } from '@app/helpers/scenes/convertTimeDataToBackend'
import { conditionTimeTypeToBackend } from '@app/lib/Scenes/conditionTimeTypeToBackend'
import { conditionTimeOperandTypeToBackend } from '@app/lib/Scenes/conditionTimeOperandTypeToBackend'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_TIME_CONDITION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTimeConditionStateTime extends React.Component {
  state = {
    selected: 'at_this_time',
    atTime: new Date(),
    betweenStartTime: new Date(),
    betweenFinishTime: new Date(),
    beforeTime: new Date(),
    afterTime: new Date(),
  }

  componentDidMount() {
    const {
      isEditConditionMode,
      stateTimeData,
      type,
      timeOperand,
    } = this.props
    if (isEditConditionMode) {
      switch (type) {
      case 'Time':
        if (timeOperand === 'eq') {
          return this.setState({
            selected: 'at_this_time',
            atTime: new Date(stateTimeData),
          })
        }
        if (timeOperand === 'lt') {
          return this.setState({
            selected: 'before_this_time',
            beforeTime: new Date(stateTimeData),
          })
        }
        if (timeOperand === 'gt') {
          return this.setState({
            selected: 'after_this_time',
            afterTime: new Date(stateTimeData),
          })
        }
        break
      case 'TimeLimits':
        return this.setState({
          selected: 'between_these_times',
          betweenStartTime: new Date(stateTimeData.from),
          betweenFinishTime: new Date(stateTimeData.to),
        })
      default:
        return false
      }
    }
    return null
  }

  handleChange = (button) => {
    this.setState({ selected: button.target.value })
  }

  handleAtTimeChange = (selectedDate) => {
    this.setState({ atTime: selectedDate })
  }

  handleBetweenStartTimeChange = (selectedDate) => {
    this.setState({ betweenStartTime: selectedDate })
  }

  handleBetweenFinishTimeChange = (selectedDate) => {
    this.setState({ betweenFinishTime: selectedDate })
  }

  handleBeforeTimeChange = (selectedDate) => {
    this.setState({ beforeTime: selectedDate })
  }

  handleAfterTimeChange = (selectedDate) => {
    this.setState({ afterTime: selectedDate })
  }

  descriptionRender=() => {
    const {
      selected,
      atTime,
      betweenStartTime,
      betweenFinishTime,
      beforeTime,
      afterTime,
    } = this.state
    switch (selected) {
    case 'at_this_time':
      return convertTimeDataToDescription(atTime)
    case 'between_these_times':
      return `${convertTimeDataToDescription(betweenStartTime)} to ${convertTimeDataToDescription(betweenFinishTime)}`
    case 'before_this_time':
      return convertTimeDataToDescription(beforeTime)
    case 'after_this_time':
      return convertTimeDataToDescription(afterTime)
    default:
      return ''
    }
  }

  backendTimeData=() => {
    const {
      selected,
      atTime,
      betweenStartTime,
      betweenFinishTime,
      beforeTime,
      afterTime,
    } = this.state
    switch (selected) {
    case 'at_this_time':
      return convertTimeDataToBackend(atTime)
    case 'between_these_times':
      return {
        from: convertTimeDataToBackend(betweenStartTime),
        to: convertTimeDataToBackend(betweenFinishTime),
      }
    case 'before_this_time':
      return convertTimeDataToBackend(beforeTime)
    case 'after_this_time':
      return convertTimeDataToBackend(afterTime)
    default:
      return ''
    }
  }

  onAddStateClick=() => {
    const { selected } = this.state
    const {
      saveConditionType,
      saveConditionTimeOperand,
      saveConditionDescription,
      saveConditionStateDescription,
      saveConditionStateTimeData,
      isEditConditionMode,
      navigate,
    } = this.props
    saveConditionType(conditionTimeTypeToBackend[selected])
    saveConditionTimeOperand(conditionTimeOperandTypeToBackend[selected])
    saveConditionDescription('time_condition_state_time')
    saveConditionStateDescription(this.descriptionRender())
    saveConditionStateTimeData(this.backendTimeData())
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  onBackClick=() => {
    const {
      navigate,
      isEditConditionMode,
      isChangeConditionStateMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_TIME_CONDITION_TYPE)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      isTablet,
    } = this.props
    const {
      selected, atTime, betweenStartTime, betweenFinishTime, beforeTime, afterTime,
    } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RadioGroup className={classes.radioWrapper} aria-label="time" value={selected} onChange={this.handleChange}>
              <Wrapper className={classNames(
                classes.timeWrapper,
                (!isMobile || isTablet) && classes.timeWrapperDesktop,
              )}
              >
                <FormControlLabel
                  className={classes.buttonWrapper}
                  value="at_this_time"
                  control={(
                    <Radio
                      classes={(!isMobile || isTablet) ? { root: classes.radioIconDesktop } : { root: classes.radioIcon }}
                      color="primary"
                    />
                  )}
                  label={I18n.t('scene_web.time_condition_state_at_this_time')}
                  labelPlacement="start"
                />
                <TimePicker
                  value={atTime}
                  onChange={(selectedDate) => this.handleAtTimeChange(selectedDate)}
                  disabled={selected !== 'at_this_time'}
                  inputProps={{ readOnly: true }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      variant="standard"
                      className={classes.timePicker}
                    />
                  )}
                />
              </Wrapper>
              <Wrapper className={classNames(
                classes.timeWrapper,
                (!isMobile || isTablet) && classes.timeWrapperDesktop,
              )}
              >
                <FormControlLabel
                  className={classes.buttonWrapper}
                  value="between_these_times"
                  control={(
                    <Radio
                      classes={(!isMobile || isTablet) ? { root: classes.radioIconDesktop } : { root: classes.radioIcon }}
                      color="primary"
                    />
                  )}
                  label={I18n.t('scene_web.time_condition_state_between_these_times')}
                  labelPlacement="start"
                />
                <Wrapper className={classNames(
                  classes.inlinePickersWrapper,
                  (!isMobile || isTablet) && classes.inlinePickersWrapperDesktop,
                )}
                >
                  <TimePicker
                    value={betweenStartTime}
                    onChange={(selectedDate) => this.handleBetweenStartTimeChange(selectedDate)}
                    disabled={selected !== 'between_these_times'}
                    inputProps={{ readOnly: true }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        className={classNames(
                          classes.timePickerInline,
                          (!isMobile || isTablet) && classes.timePickerInlineDesktop,
                        )}
                      />
                    )}
                  />
                  <TimePicker
                    value={betweenFinishTime}
                    onChange={(selectedDate) => this.handleBetweenFinishTimeChange(selectedDate)}
                    disabled={selected !== 'between_these_times'}
                    inputProps={{ readOnly: true }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        className={classNames(
                          classes.timePickerInline,
                          (!isMobile || isTablet) && classes.timePickerInlineDesktop,
                        )}
                      />
                    )}
                  />
                </Wrapper>
              </Wrapper>
              <Wrapper className={classNames(
                classes.timeWrapper,
                (!isMobile || isTablet) && classes.timeWrapperDesktop,
              )}
              >
                <FormControlLabel
                  className={classes.buttonWrapper}
                  value="before_this_time"
                  control={(
                    <Radio
                      classes={(!isMobile || isTablet) ? { root: classes.radioIconDesktop } : { root: classes.radioIcon }}
                      color="primary"
                    />
                  )}
                  label={I18n.t('scene_web.time_condition_state_before_this_time')}
                  labelPlacement="start"
                />
                <TimePicker
                  value={beforeTime}
                  onChange={(selectedDate) => this.handleBeforeTimeChange(selectedDate)}
                  disabled={selected !== 'before_this_time'}
                  inputProps={{ readOnly: true }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      variant="standard"
                      className={classes.timePicker}
                    />
                  )}
                />
              </Wrapper>
              <Wrapper className={classNames(
                classes.timeWrapper,
                (!isMobile || isTablet) && classes.timeWrapperDesktop,
              )}
              >
                <FormControlLabel
                  className={classes.buttonWrapper}
                  value="after_this_time"
                  control={(
                    <Radio
                      classes={(!isMobile || isTablet) ? { root: classes.radioIconDesktop } : { root: classes.radioIcon }}
                      color="primary"
                    />
                  )}
                  label={I18n.t('scene_web.time_condition_state_after_this_time')}
                  labelPlacement="start"
                />
                <TimePicker
                  value={afterTime}
                  onChange={(selectedDate) => this.handleAfterTimeChange(selectedDate)}
                  disabled={selected !== 'after_this_time'}
                  inputProps={{ readOnly: true }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      variant="standard"
                      className={classes.timePicker}
                    />
                  )}
                />
              </Wrapper>
            </RadioGroup>
            <Button
              variant="outlined"
              color="primary"
              className={classNames(
                classes.addButton,
                (!isMobile || isTablet) && classes.addButtonDesktop,
              )}
              onClick={this.onAddStateClick}
            >
              {I18n.t('buttons.next')}
            </Button>
          </LocalizationProvider>
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimeConditionStateTime.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionTimeOperand: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionStateTimeData: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  timeOperand: PropTypes.string.isRequired,
  stateTimeData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

SelectTimeConditionStateTime.displayName = 'SelectTimeConditionStateTime'

export default SelectTimeConditionStateTime
