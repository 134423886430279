const styles = (theme) => ({
  root: {
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 20,
    background: 'transparent',
    borderRadius: '50%',
    width: 30,
    height: 30,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    minWidth: 0,
    boxShadow: 'none',
  },
  buttonDisabled: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 20,
    background: 'transparent',
    borderRadius: '50%',
    width: 30,
    height: 30,
    border: '2px solid',
    borderColor: '#5e5f62',
    boxSizing: 'border-box',
    minWidth: 0,
    boxShadow: 'none',
  },
  titleIcon: {
    position: 'relative',
  },
  svgWrapper: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  deviceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 290,
  },
  deviceIcon: {
    marginLeft: 20,
  },
  deviceName: {
    margin: '0 10px',
    fontSize: 20,
  },
})

export default styles
