import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material'
import { StatisticsBillingIcon } from 'icons'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import getDate from '@app/helpers/myEnergy/getDate'
import BillingStatsPopup from '@app/containers/MyEnergy/Tabs/BillingPeriod/BillingPeriodStats'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import styles from './Statistics.style'

class Statistics extends React.PureComponent {
  render() {
    const {
      classes,
      total,
      isMobile,
      isBillingPeriodsStatsPopupOpen,
      billingPeriodsStatsOpen,
    } = this.props
    const periodFrom = getDate(total.from, 'short')
    const totalBilling = total.billing
    const totalEnergy = total.energy
    return (
      <ListItem className={classes.listItem}>
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classNames(
            classes.title,
            !isMobile && classes.titleDesktop,
          )}
          >
            {I18n.t('my_energy.statistics')}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.infoWrapper}>
          <ListItemIcon>
            <StatisticsBillingIcon className={classNames(
              classes.icon,
              !isMobile && classes.iconDesktop,
            )}
            />
          </ListItemIcon>
          <ListItemText
            className={classNames(
              classes.info,
              classes.infoMargin,
            )}
            primary={(
              <>
                <Typography
                  component="span"
                  className={classes.infoTime}
                >
                  {periodFrom === ''
                    ? <Typography>{I18n.t('my_energy.no_statistics')}</Typography>
                    : `${periodFrom} - ${I18n.t('my_energy.today')}`}
                </Typography>
              </>
            )}
            secondary={(
              <>
                <Typography
                  component="span"
                  className={classNames(
                    classes.infoStat,
                    !isMobile && classes.infoStatDesktop,
                  )}
                >
                  {periodFrom === ''
                    ? `${I18n.t('my_energy.billing')}: ${I18n.t('my_energy.not_available')} $`
                    : `${I18n.t('my_energy.billing')}: ${totalBilling} $`}
                </Typography>
                <Typography
                  component="span"
                  className={classNames(
                    classes.infoStat,
                    !isMobile && classes.infoStatDesktop,
                  )}
                >
                  {periodFrom === ''
                    ? `${I18n.t('my_energy.energy')}:
                     ${I18n.t('my_energy.not_available')} ${I18n.t('my_energy.power_kwh')}`
                    : `${I18n.t('my_energy.energy')}: ${totalEnergy} ${I18n.t('my_energy.power_kwh')}`}
                </Typography>
              </>
            )}
          />
          <Wrapper className={classes.buttonStatWrapper}>
            <Button
              className={classNames(
                classes.buttonStat,
                !isMobile && classes.buttonStatDesktop,
              )}
              color="primary"
              onClick={() => {
                billingPeriodsStatsOpen()
              }}
            >
              {I18n.t('buttons.see_more_statistics')}
            </Button>
          </Wrapper>
        </Wrapper>
        {isBillingPeriodsStatsPopupOpen && (<BillingStatsPopup />)}
      </ListItem>
    )
  }
}

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
  total: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    billing: PropTypes.number.isRequired,
    energy: PropTypes.number.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isBillingPeriodsStatsPopupOpen: PropTypes.bool.isRequired,
  billingPeriodsStatsOpen: PropTypes.func.isRequired,
}

export default withStyles(styles)(Statistics)
