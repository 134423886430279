import React from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SELECT_TRIGGERING_DEVICE, SELECT_TRIGGERING_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './Room.style'

class Room extends React.Component {
  onRoomClick = (room) => {
    const {
      navigate,
      deviceType,
      saveTriggeringDeviceName,
      saveTriggeringDeviceNumber,
      saveObservingDeviceNumber,
      saveObservingDeviceName,
      saveConditionStateDescription,
    } = this.props
    if (deviceType === 'triggering') {
      saveTriggeringDeviceNumber(room.number)
      saveTriggeringDeviceName(room.name)
      navigate(SELECT_TRIGGERING_STATE)
    } else {
      saveConditionStateDescription(`exceeded Timeout value, while observing ${room.name}`)
      saveObservingDeviceNumber(room.number)
      saveObservingDeviceName(room.name)
      navigate(SELECT_TRIGGERING_DEVICE)
    }
  }

  render() {
    const {
      classes,
      room,
    } = this.props
    return (
      <Wrapper
        className={classes.room}
        onClick={() => {
          this.onRoomClick(room)
        }}
      >
        <Typography className={classes.title} variant="h6" component="h2">
          {room.name}
        </Typography>
      </Wrapper>
    )
  }
}

Room.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  saveTriggeringDeviceName: PropTypes.func.isRequired,
  saveTriggeringDeviceNumber: PropTypes.func.isRequired,
  saveObservingDeviceNumber: PropTypes.func.isRequired,
  saveObservingDeviceName: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
}

export default withStyles(styles)(Room)
