import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 25,
  },
  formControl: {
    width: '100%',
  },
}))

export default useStyles
