import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '2rem auto',
  },
  timePicker: {
    marginRight: '1rem',
  },
  timezoneSelect: {
    width: 300,
  },
  autocomplete: {
    display: 'inline-block',
    marginRight: '1rem',
  },
  difference: {
    fontWeight: 'bold',
  },
})

export default useStyles
