const styles = (theme) => ({
  listItem: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  avatar: {
    marginRight: 20,
  },
  button: {
    width: '20%',
  },
  paper: {
    padding: 80,
    minHeight: 200,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
