import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  title: {
    marginBottom: '1.5rem',
  },
  advancedSettingsContainer: {
    marginTop: '2rem',
    padding: '0 1rem',
  },
}))

export default useStyles
