import React from 'react'
import PropTypes from 'prop-types'

const withClientCredentialsAuth = (WrappedComponent) => {
  class Auth extends React.Component {
    componentDidMount() {
      const { getToken, validAccessToken, getTokenSkip } = this.props
      if (!validAccessToken) {
        getToken()
      } else {
        getTokenSkip()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  Auth.propTypes = {
    validAccessToken: PropTypes.bool,
    getToken: PropTypes.func.isRequired,
    getTokenSkip: PropTypes.func.isRequired,
  }

  Auth.defaultProps = {
    validAccessToken: false,
  }

  return Auth
}

export default withClientCredentialsAuth
