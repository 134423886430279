import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.backgrounds.mainBg,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  navigation: {
    backgroundColor: theme.backgrounds.componentBackground,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingLeft: 35,
    width: '100%',
  },
  navigationWebView: {
    padding: 0,
  },
  container: {
    backgroundColor: theme.backgrounds.componentBackground,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: 800,
  },
  containerMobile: {
    minHeight: '100vh',
    backgroundColor: theme.backgrounds.componentBackground,
    width: '100%',
  },
  webview: {
    backgroundColor: theme.webView.background,
  },
  main: {
    padding: '20px 35px 35px 35px',
    width: 800,
  },
  mainMobile: {
    padding: '20px 35px 35px 35px',
    width: '100%',
  },
  nameField: {
    width: '100%',
  },
  name: {
    width: '100%',
    marginBottom: 45,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyContent: 'space-between',
    alignItems: 'end',
    gridRowGap: 56,
    columnGap: 24,
    marginBottom: 100,
  },
  gridMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  pickerBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,0)',
    zIndex: 1,
  },
  durationSelect: {
    padding: 5,
  },
  scheduleTypes: {
    alignSelf: 'start',
  },
  scheduleChoice: {
    gridColumn: 'span 2',
    width: '100%',
  },
  reminderTimes: {
    alignSelf: 'start',
  },
  timeQuantityWrapper: {
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  quantity: {
    marginTop: 20,
  },
  saveButton: {
    width: '100%',
  },
  saveButtonMobile: {
    width: '100%',
    marginTop: 40,
  },
  backButton: {
    marginBottom: 15,
  },
  '@media (max-width: 800px)': {
    root: {
      width: '100%',
      position: 'relative',
      left: 0,
    },
    grid: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  '@media (max-width: 450px)': {
    root: {
      width: '100%',
      position: 'relative',
      left: 0,
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      alignItems: 'start',
    },
  },
}))

export default useStyles
