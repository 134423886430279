import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_GEOFENCE_USERS } from '@app/constants/users/geofenceUsers'
import { updateGeofenceUsers } from '@app/actions/users/geofenceUsers'
import Api from '@app/api/users/fetchGeofenceUsers'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchGeofenceUsersLogic = createLogic({
  type: SYNC_GEOFENCE_USERS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_GEOFENCE_USERS))
    Api.fetchGeofenceUsers(token)
      .then((response) => {
        const { data: { geofence_users } } = response
        dispatch(updateGeofenceUsers(geofence_users.map((user) => ({
          id: user.number,
          preferredName: user.preferred_name,
          checked: false,
        }))))
        dispatch(apiRequestDone(SYNC_GEOFENCE_USERS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchGeofenceUsers failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchGeofenceUsersLogic
