const canChangeRoleHelper = (currentUserRole, currentMemberRole) => {
  let canChangeRole = false
  if (currentUserRole === 'admin' && currentMemberRole !== 'admin') {
    canChangeRole = true
  } else if (currentUserRole === 'sub_admin') {
    if (currentMemberRole === 'family' || currentMemberRole === 'guest') {
      canChangeRole = true
    }
  }
  return canChangeRole
}
export default canChangeRoleHelper
