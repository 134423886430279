import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import styles from './Arrow.style'

class Arrow extends React.PureComponent {
  getMainClassName=(trend) => (trend === 'top' ? 'increasingArrow' : 'decreasingArrow')

  getDisabledClassName=(trend) => (trend === 'top' ? 'increasingArrowDisabled' : 'decreasingArrowDisabled')

  render() {
    const {
      trend,
      classes,
      disabled,
    } = this.props
    return (
      <div className={classNames(
        classes[this.getMainClassName(trend)],
        disabled && classes[this.getDisabledClassName(trend)],
      )}
      />
    )
  }
}

Arrow.propTypes = {
  classes: PropTypes.object.isRequired,
  trend: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Arrow)
