import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M129.387 13.9017V135.104H60.0873V98.9729H89.2297V75.5447H78.4694L77.4585 74.9118C81.2878 74.2034 84.3785 71.3742 85.4216 67.6221C86.4648 63.8701 85.278 59.8516 82.3637 57.2684C79.4495 54.6851 75.3177 53.989 71.7179 55.4747C71.2315 55.6857 70.7509 55.8762 70.2762 56.0461C70.0736 55.8233 69.8531 55.6175 69.6168 55.4308C69.2584 55.1476 68.8691 54.9058 68.4564 54.7099C68.1295 54.5535 67.7916 54.4213 67.4454 54.3143C66.8513 54.1345 66.2423 54.008 65.6257 53.9363C64.5162 53.8024 63.3946 53.8024 62.2851 53.9363C61.6685 54.0081 61.0595 54.1346 60.4653 54.3143L60.1049 54.4374V13.9017H129.405H129.387ZM132.921 10.3828H56.5708V59.4546C57.6282 59.82 58.709 60.1137 59.8059 60.3337V59.4546C59.8077 59.3205 59.8284 59.1873 59.8675 59.059C59.9024 58.936 59.9526 58.8178 60.0169 58.7073C60.0746 58.6097 60.1423 58.5183 60.2191 58.4348C60.2896 58.3585 60.366 58.288 60.4477 58.2238C60.6001 58.1062 60.7652 58.0059 60.94 57.9249C61.1136 57.8447 61.2928 57.7771 61.4762 57.7227C61.8723 57.6022 62.2783 57.5169 62.6894 57.4678C63.5128 57.3692 64.345 57.3692 65.1685 57.4678C65.5796 57.5169 65.9855 57.6022 66.3816 57.7227C66.565 57.7772 66.7442 57.8447 66.9179 57.9249C67.0927 58.0057 67.2579 58.106 67.4102 58.2238C67.5825 58.3569 67.7285 58.5209 67.841 58.7073C67.9053 58.8178 67.9555 58.936 67.9904 59.059C68.0294 59.1873 68.0502 59.3205 68.0519 59.4546V60.3337C69.7846 59.9593 71.4751 59.4115 73.098 58.6985C75.8941 57.4823 79.1589 58.3161 81.0293 60.7242C82.8996 63.1323 82.8996 66.502 81.0293 68.9101C79.1589 71.3181 75.8941 72.152 73.098 70.9357C71.4751 70.2227 69.7846 69.6749 68.0519 69.3006V74.8126C69.0006 75.0167 69.9345 75.2839 70.8475 75.6126C71.9737 76.0173 73.0695 76.502 74.1266 77.0631C75.2511 77.6602 76.3374 78.3267 77.3793 79.0587H85.7044V95.454H56.5708V138.618H132.904V10.3828H132.921Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M59.8059 74.8118V69.3438C58.709 69.5637 57.6282 69.8574 56.5708 70.2229V75.7261L57.0104 75.5678C57.9244 75.2541 58.8583 75.0015 59.8059 74.8118V74.8118Z" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M84.5881 80.1905V94.3178H40.6327C39.3404 94.3178 38.1361 93.9837 36.9581 95.0035C36.2987 95.5144 35.931 96.3155 35.9735 97.1485H21.8022C21.8001 90.5098 25.672 84.4803 31.7098 81.7202C34.8218 80.296 37.8635 80.1817 41.169 80.1817H50.8392C59.5658 73.9342 68.2953 73.9342 77.0278 80.1817L84.5881 80.1905Z" fill="currentColor"/>
            <path d="M32.7646 112.137C33.8108 109.764 32.7646 107.803 34.8569 106.062" stroke="currentColor"/>
            <path d="M22.2857 113.817C22.4264 110.951 20.6066 109.624 19.8418 107.391" stroke="currentColor"/>
            <path d="M36.1143 117.012C37.0885 115.801 37.6847 114.33 37.8285 112.783C38.0442 111.24 38.8278 109.832 40.0263 108.836" stroke="currentColor"/>
            <path d="M19.5604 116.758C17.4681 114.999 18.4439 111.237 16 109.566" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.6987 116.35C20.0998 119.2 20.2349 123.599 23.0038 126.284C25.7728 128.969 30.1739 128.969 32.9428 126.284C35.7117 123.599 35.8469 119.2 33.248 116.35C29.7315 112.464 27.9733 109.358 27.9733 107.031C27.9792 109.352 26.221 112.458 22.6987 116.35V116.35Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M35.094 99.8237V101.679H22.6459V99.8237H35.094V99.8237ZM36.8523 98.0664H20.8877V103.438H36.8523V98.0664V98.0664Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M73.5552 59.7801C71.4432 60.7148 69.2176 61.368 66.9355 61.7229V59.4548C66.9355 58.9098 65.9157 58.7428 65.5817 58.6724C64.4952 58.4686 63.3803 58.4686 62.2938 58.6724C61.9597 58.7428 60.94 58.9098 60.94 59.4548V61.7229C58.6579 61.368 56.4323 60.7148 54.3203 59.7801C51.9998 58.7739 49.2924 59.4676 47.7417 61.4658C46.1909 63.4639 46.1909 66.2588 47.7417 68.2569C49.2924 70.2551 51.9998 70.9488 54.3203 69.9426C56.4323 69.0079 58.6579 68.3547 60.94 67.9998V74.6106C62.9265 74.3017 64.9489 74.3017 66.9355 74.6106V67.9998C69.2176 68.3547 71.4432 69.0079 73.5552 69.9426C75.8757 70.9488 78.5831 70.2551 80.1338 68.2569C81.6845 66.2588 81.6845 63.4639 80.1338 61.4658C78.5831 59.4676 75.8757 58.7739 73.5552 59.7801V59.7801ZM65.863 67.3511H65.2037V64.9951H62.6718V67.3423H62.0125V62.3753H62.6718V64.4237H65.2037V62.3753H65.863V67.3511Z" fill="currentColor"/>
            <rect x="54.98" y="8" width="79.5152" height="2.38238" fill="currentColor"/>
            <rect x="54.98" y="138.617" width="79.5152" height="2.38238" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwWaterStorageIcon')

export default icon
