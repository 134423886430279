import React, {
  useEffect, useState, Dispatch, SetStateAction,
} from 'react'
import {
  Typography, IconButton, Button,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import {
  IConfigParameterValue,
  IConfigParameter,
  IZwaveDetails,
  IZwaveOrTwrDevice,
  ConfigParametersTypes,
  ConfigParametersUITypes,
} from '@app/@types/myDevices'
import { ScenesArrowBack } from 'icons'
import Range
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditComponents/Range/Range'
import Single
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditComponents/Single/Single'
import Ranges
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditComponents/Ranges/Ranges'
import {
  getZwaveDeviceParameter,
  parseZwaveDeviceParameter,
  setZwaveDeviceParameter,
} from '@app/actions/myDevices/zwaveDevices'
import { Optional } from '@app/@types/common'
import Multi
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditComponents/Multi/Multi'
import MultiRanges
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveParams/EditParams/EditComponents/MultiRanges/MultiRanges'
import useStyles from './EditParams.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceDetails: IZwaveDetails,
  currentParameter: Optional<IConfigParameter>,
  setCurrentParameter: Dispatch<SetStateAction<Optional<IConfigParameter>>>,
  setIsEditingParameter: Dispatch<SetStateAction<boolean>>,
  isMobile: boolean,
}

const EditParam: React.FC<Props> = (props) => {
  const {
    currentDevice, currentDeviceDetails, currentParameter, setCurrentParameter, setIsEditingParameter, isMobile,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const [temporaryValues, setTemporaryValues] = useState<Optional<Array<IConfigParameterValue>>>(null)
  const [isParameterInSync, setIsParameterInSync] = useState<boolean>(false)
  const [isParameterSetting, setIsParameterSetting] = useState<boolean>(false)
  const [isParameterDefaulting, setIsParameterDefaulting] = useState<boolean>(false)
  const [isParameterParsing, setIsParameterParsing] = useState<boolean>(false)
  const [isParameterParsed, setIsParameterParsed] = useState<boolean>(false)

  useEffect(() => {
    if (currentParameter) {
      parseParameterValues(currentParameter)
      setIsParameterParsed(true)
    }
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (currentParameter) {
      const parameter = currentDeviceDetails.config_params.find((param) => param.number === currentParameter.number)
      if (isParameterDefaulting) {
        if (parameter) {
          parseParameterValues({ ...parameter })
          setIsParameterParsed(true)
          setIsParameterDefaulting(false)
        }
      }
      if (isParameterInSync || isParameterSetting) {
        setIsParameterInSync(false)
        setIsParameterSetting(false)
      }
      if (isParameterParsing) {
        setIsParameterParsing(false)
      }
      if (currentDeviceDetails) {
        if (parameter) {
          setCurrentParameter(parameter)
          if (!isParameterParsed && temporaryValues) {
            parseParameterValues({ ...parameter, values: temporaryValues })
            setIsParameterParsed(true)
          }
        }
      }
    }
    setTemporaryValues(null)
  }, [currentDeviceDetails])

  const parseParameterValues = (param: IConfigParameter) => {
    setIsParameterParsing(true)
    if (currentParameter) {
      const data = {
        parameter_number: param.number,
        ui_type: param.ui_type,
        set_to: param.set_to,
        default: param.default,
        values: param.values,
      }
      dispatch(parseZwaveDeviceParameter(currentDevice.id, data))
    }
  }

  const goBackHandler = () => {
    setIsEditingParameter(false)
  }

  const syncParam = () => {
    setIsParameterInSync(true)
    setIsParameterParsed(false)
    if (currentParameter) {
      dispatch(getZwaveDeviceParameter(currentDevice.id, currentParameter.number))
    }
  }

  const setCurrentParam = () => {
    setIsParameterSetting(true)
    setIsParameterParsed(false)
    if (currentParameter && temporaryValues !== null) {
      dispatch(setZwaveDeviceParameter(currentDevice.id, {
        parameter_number: currentParameter.number,
        is_default: false,
        ui_type: currentParameter.ui_type,
        values: temporaryValues,
        size: currentParameter.size,
      }))
    }
  }

  const defaultParam = () => {
    setIsParameterDefaulting(true)
    setIsParameterParsed(false)
    if (currentParameter) {
      dispatch(setZwaveDeviceParameter(currentDevice.id, {
        parameter_number: currentParameter.number,
        is_default: false,
        size: currentParameter.size,
        value: currentParameter.default,
      }))
    }
  }

  const renderLoaderText = () => {
    if (isParameterInSync) {
      return I18n.t('my_devices.getting_parameter')
    } if (isParameterSetting) {
      return I18n.t('my_devices.setting_parameter')
    } if (isParameterDefaulting) {
      return I18n.t('my_devices.defaulting_parameter')
    } if (isParameterParsing) {
      return I18n.t('my_devices.calculating_parameter')
    }
    return I18n.t('my_devices.loading')
  }

  const renderEditorComponents = () => {
    if (currentParameter) {
      if (currentParameter.type === ConfigParametersTypes.range) {
        return (
          <Range
            currentParameter={currentParameter}
            temporaryValues={temporaryValues}
            setTemporaryValues={setTemporaryValues}
            isMobile={isMobile}
          />
        )
      }
      if (currentParameter.type === ConfigParametersTypes.ranges) {
        if (currentParameter.ui_type === ConfigParametersUITypes.single) {
          return (
            <Ranges
              currentParameter={currentParameter}
              temporaryValues={temporaryValues}
              setTemporaryValues={setTemporaryValues}
              isMobile={isMobile}
            />
          )
        } if (currentParameter.ui_type === ConfigParametersUITypes.multi) {
          return (
            <MultiRanges
              currentParameter={currentParameter}
              temporaryValues={temporaryValues}
              setTemporaryValues={setTemporaryValues}
              isMobile={isMobile}
            />
          )
        }
      } if (currentParameter.type === ConfigParametersTypes.bitset) {
        if (currentParameter.ui_type === ConfigParametersUITypes.single) {
          return (
            <Single
              currentParameter={currentParameter}
              setTemporaryValues={setTemporaryValues}
              isMobile={isMobile}
            />
          )
        }
        if (currentParameter.ui_type === ConfigParametersUITypes.multi) {
          return (
            <Multi
              currentParameter={currentParameter}
              setTemporaryValues={setTemporaryValues}
              isMobile={isMobile}
            />
          )
        }
      } if (currentParameter.type === ConfigParametersTypes.multiBitsets) {
        return (
          <MultiRanges
            currentParameter={currentParameter}
            temporaryValues={temporaryValues}
            setTemporaryValues={setTemporaryValues}
            isMobile={isMobile}
          />
        )
      }
      if (currentParameter.ui_type === ConfigParametersUITypes.single) {
        return (
          <Single
            currentParameter={currentParameter}
            setTemporaryValues={setTemporaryValues}
            isMobile={isMobile}
          />
        )
      }
      return (
        <Typography className={classes.notEditable}>Currently not editable</Typography>
      )
    }
    return null
  }

  return (
    <Wrapper className={classes.root}>
      <Wrapper className={classNames(classes.navigation, isMobile && classes.navigationMobile)}>
        <Wrapper className={classes.navigationLeft}>
          <IconButton
            size="small"
            onClick={goBackHandler}
            className={classNames(classes.goBackIcon, isMobile && classes.goBackIconMobile)}
          >
            <ScenesArrowBack />
          </IconButton>
          <Typography>{`Edit parameter ${currentParameter && currentParameter.number}`}</Typography>
        </Wrapper>
      </Wrapper>
      {(isParameterInSync || isParameterSetting || isParameterDefaulting || isParameterParsing) && (
        <Wrapper className={classes.preloader}>
          <Typography className={classes.preloaderMessage}>
            {renderLoaderText()}
          </Typography>
        </Wrapper>
      )}
      {currentParameter && (
        <Wrapper className={classNames(classes.editor, isMobile && classes.editorMobile,
          (isParameterInSync || isParameterSetting || isParameterDefaulting || isParameterParsing) && classes.blur)}
        >
          <Wrapper className={classes.infoWrapper}>
            <Typography className={classes.nameTitle}>Name</Typography>
            <Typography className={classes.name}>{currentParameter.name}</Typography>
            <Typography className={classes.descriptionTitle}>Description</Typography>
            <Typography className={classes.description}>{currentParameter.description}</Typography>
          </Wrapper>
          <Typography className={classes.settingsTitle}>{I18n.t('my_devices.settings')}</Typography>
          <Wrapper className={classes.editorComponents}>
            {renderEditorComponents()}
          </Wrapper>
          <Wrapper className={classes.buttonsWrapper}>
            <Wrapper className={classes.buttonsSecondWrapper}>
              <Button
                onClick={syncParam}
                variant="outlined"
                className={classes.button}
              >
                {I18n.t('buttons.sync')}
              </Button>
              <Button
                disabled={temporaryValues === null || JSON.stringify(temporaryValues) === JSON.stringify(currentParameter.values)}
                variant="contained"
                className={classes.button}
                onClick={setCurrentParam}
              >
                {I18n.t('buttons.apply')}
              </Button>
            </Wrapper>
            <Button
              onClick={defaultParam}
              variant="outlined"
              className={classes.resetButton}
            >
              {I18n.t('buttons.reset_to_default')}
            </Button>
          </Wrapper>
        </Wrapper>
      )}
    </Wrapper>
  )
}

export default EditParam
