const styles = (theme) => ({
  root: {
    padding: 15,
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    position: 'relative',
    boxSizing: 'border-box',
  },
  settingsIcon: {
    alignSelf: 'flex-end',
    marginRight: '5%',
  },
  settingsIconMobile: {
    marginRight: '2%',
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contactsCard: {
    boxSizing: 'border-box',
    width: '100%',
    height: 60,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '12px 4%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  cardMobile: {
    paddingLeft: 20,
  },
  cardContent: {
    width: '100%',
  },
  avatar: {
    marginRight: 10,
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  name: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: 40,
    cursor: 'pointer',
  },
  noContacts: {
    alignSelf: 'center',
    marginTop: '20%',
    textTransform: 'uppercase',
    fontSize: 20,
  },
  editBackgroundButton: {
    position: 'relative',
    width: 40,
    height: 40,
  },
  editButton: {
    position: 'absolute',
    top: '18%',
    left: '45%',
    cursor: 'pointer',
    transform: 'rotate(90deg)',
  },
  addButton: {
    position: 'fixed',
    bottom: 20,
    right: 'calc(50% - 28px)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    color: theme.backgrounds.cardBackground,
    backgroundColor: theme.palette.primary.main,
    zIndex: 101,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
})

export default styles
