import update from 'immutability-helper'
import * as type from '@app/constants/scenes/scenesList'
import * as actions from '@app/actions/scenes/scenesList'
import { IScene } from '@app/@types/scenes/scenes'
import { Optional } from '@app/@types/common'
import { InferValueTypes } from '@app/@types/typesHelper'

type IScenesList = ReturnType<InferValueTypes<typeof actions>>

const findChangedSceneIndex = (scenes: Array<IScene>, id: number) => scenes.findIndex((el) => el.id === id)

interface IScenesListState {
  scenes: Array<IScene> | [],
  scenesOrder: Array<IScene> | [],
  currentScene: {
    id: Optional<number>,
    name: string,
    enabled: Optional<boolean>,
    status: Optional<string>,
  },
  isDeleteScenePopupOpen: boolean,
  isSceneUsedInScenePopupOpen: boolean,
  isWebhookPopupOpen: boolean,
}

const initialState = {
  scenes: [],
  scenesOrder: [],
  currentScene: {
    id: null,
    name: '',
    enabled: null,
    status: null,
  },
  isDeleteScenePopupOpen: false,
  isSceneUsedInScenePopupOpen: false,
  isWebhookPopupOpen: false,
}

export default function scenesList(state: IScenesListState = initialState, action: IScenesList) {
  switch (action.type) {
  case type.UPDATE_SCENES: {
    const convertedScenes = action.scenes.map((scene) => {
      const resultStatus = scene.activate_status === 'progress'
      return { ...scene, isEnablingProgress: resultStatus }
    })
    return {
      ...state,
      scenes: convertedScenes,
    }
  }

  case type.SELECT_CURRENT_SCENE_ID: {
    return update(state, {
      currentScene: { id: { $set: action.id } },
    })
  }
  case type.CLEAR_CURRENT_SCENE_ID: {
    return update(state, {
      currentScene: { id: { $set: null } },
    })
  }

  case type.OPEN_DELETE_SCENE_POPUP: {
    return {
      ...state,
      isDeleteScenePopupOpen: true,
    }
  }
  case type.CLOSE_DELETE_SCENE_POPUP: {
    return {
      ...state,
      isDeleteScenePopupOpen: false,
    }
  }

  case type.CHANGE_SCENE_ENABLED_VALUE: {
    return update(state, {
      scenes: {
        [action.sceneIndex]: {
          enabled: { $set: action.enabled },
        },
      },
    })
  }

  case type.CHANGE_SCENE_ENABLED_VALUE_VIA_ID: {
    const changedSceneIndex = findChangedSceneIndex(state.scenes, action.id)
    return update(state, {
      scenes: {
        [changedSceneIndex]: {
          enabled: { $set: action.enabled },
        },
      },
    })
  }

  case type.START_ENABLING_PROGRESS: {
    const changedSceneIndex = findChangedSceneIndex(state.scenes, action.id)
    return update(state, {
      // @ts-ignore
      scenes: {
        [changedSceneIndex]: {
          isEnablingProgress: { $set: true },
        },
      },
    })
  }

  case type.STOP_ENABLING_PROGRESS: {
    const changedSceneIndex = findChangedSceneIndex(state.scenes, action.id)
    return update(state, {
      // @ts-ignore
      scenes: {
        [changedSceneIndex]: {
          isEnablingProgress: { $set: false },
        },
      },
    })
  }

  case type.SELECT_CURRENT_SCENE_ENABLED: {
    return update(state, {
      currentScene: { enabled: { $set: action.enabled } },
    })
  }
  case type.CLEAR_CURRENT_SCENE_ENABLED: {
    return update(state, {
      currentScene: { enabled: { $set: null } },
    })
  }

  case type.SAVE_CURRENT_SCENE_NAME: {
    return update(state, {
      currentScene: { name: { $set: action.name } },
    })
  }
  case type.CLEAR_CURRENT_SCENE_NAME: {
    return update(state, {
      currentScene: { name: { $set: '' } },
    })
  }

  case type.SAVE_CURRENT_SCENE_STATUS: {
    return update(state, {
      currentScene: { status: { $set: action.status } },
    })
  }

  case type.OPEN_SCENE_USED_IN_SCENE_POPUP: {
    return {
      ...state,
      isSceneUsedInScenePopupOpen: true,
    }
  }
  case type.CLOSE_SCENE_USED_IN_SCENE_POPUP: {
    return {
      ...state,
      isSceneUsedInScenePopupOpen: false,
    }
  }

  case type.OPEN_WEBHOOK_POPUP: {
    return {
      ...state,
      isWebhookPopupOpen: true,
    }
  }

  case type.CLOSE_WEBHOOK_POPUP: {
    return {
      ...state,
      isWebhookPopupOpen: false,
    }
  }

  case type.CHANGE_ENABLE_WEBHOOK_VALUE: {
    const changedSceneIndex = findChangedSceneIndex(state.scenes, action.id)
    return update(state, {
      scenes: {
        [changedSceneIndex]: {
          webhook_enabled: { $set: action.value },
        },
      },
    })
  }

  case type.CHANGE_TEST_CONDITIONS_ENABLE_VALUE: {
    const changedSceneIndex = findChangedSceneIndex(state.scenes, action.id)
    return update(state, {
      scenes: {
        [changedSceneIndex]: {
          webhook_test_conditions: { $set: action.value },
        },
      },
    })
  }
  case type.CLEAR_CURRENT_SCENE_ALL: {
    return update(state, {
      currentScene: {
        enabled: { $set: null },
        id: { $set: null },
        name: { $set: '' },
        status: { $set: null },
      },
    })
  }

  // IOS

  case type.PATCH_MOBILE_SCENE_IMPOSSIBLE:
    return { ...state, errorMessage: '', progress: false }

  case type.RESET_SCENES_LIST:
    return initialState
  default:
    return state
  }
}
