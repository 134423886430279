import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveConditionDeviceStateInfo,
  saveConditionStateDescription,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectMultilevelSwitchConditionsState
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSwitchConditions/SelectMultilevelSwitchConditionsState/SelectMultilevelSwitchConditionsState'
import SelectMultilevelSwitchConditionsStateIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MultilevelSwitchConditions/SelectMultilevelSwitchConditionsState/SelectMultilevelSwitchConditionsState'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectMultilevelSwitchConditionsStateIOS) : withStyles(SelectMultilevelSwitchConditionsState)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const { conditionDeviceNumber } = state.scenes.currentSceneCreating.currentCondition
  return {
    isMobile,
    path,
    isEditConditionMode,
    isChangeConditionStateMode,
    conditionDeviceNumber,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionStateDescription,
  saveConditionDeviceStateInfo,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectMultilevelSwitchConditionsStateEnhanced = enhance

export default withRouter(SelectMultilevelSwitchConditionsStateEnhanced)
