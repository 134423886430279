import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import UltravioletSensorExample
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorValue/UltravioletSensor/UltravioletSensorExample'
import UltravioletSensorExampleIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MultilevelSensorCondition/SelectMultilevelSensorValue/UltravioletSensor/UltravioletSensorExample'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

let enhance = isIOS ? UltravioletSensorExampleIOS : UltravioletSensorExample

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  return {
    isMobile,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const UltravioletSensorExampleEnhanced = enhance

export default withRouter(UltravioletSensorExampleEnhanced)
