import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'

import { withStyles } from '@app/components/styles'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import SelectSecurityOptionValue
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/SecurityOptionCondition/SelectSecurityOptionValue'
import { syncSecurityOptionsConditions } from '@app/actions/scenes/zWaveDevices/securityOptions'
import {
  saveConditionType,
  saveConditionStateDescription,
  saveConditionDeviceStateInfo,
} from '@app/actions/scenes/currentSceneCreating'

let enhance = withStyles(SelectSecurityOptionValue)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { conditions } = state.scenes.securityOptions
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    conditions,
    isEditConditionMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  refresh: syncSecurityOptionsConditions,
  tryAgain: syncSecurityOptionsConditions,
  saveConditionType,
  saveConditionStateDescription,
  saveConditionDeviceStateInfo,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectSecurityOptionValueEnhanced = enhance

export default withRouter(SelectSecurityOptionValueEnhanced)
