import {
  AnotherSceneMenuIcon, DevicesMenuIcon, HealthDashboardIcon, EnergyIcon, StatusDashboardIcon,
} from 'icons'
import {
  SCENES, MY_DEVICES, MY_ENERGY,
  HEALTH, MY_SECURITY,
} from '@app/constants/routes'

const cardsData = {
  security: {
    icon: StatusDashboardIcon,
    path: MY_SECURITY,
  },
  devices: {
    icon: DevicesMenuIcon,
    path: MY_DEVICES,
  },
  scenes: {
    icon: AnotherSceneMenuIcon,
    path: SCENES,
  },
  energy: {
    icon: EnergyIcon,
    path: MY_ENERGY,
  },
  health: {
    icon: HealthDashboardIcon,
    path: HEALTH,
  },
}

export default cardsData
