const styles = () => ({
  input: {
    width: '100%',
  },
  innerInput: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  text: {
    color: '#f44336',
    position: 'absolute',
    top: 28,
  },
  icon: {
    fontSize: 40,
    position: 'absolute',
    top: 6,
    left: 6.5,
  },
})

export default styles
