import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10400 10400"
      id="vector">
      <path
        id="path"
        d="M 9152.8 2358.77 C 9500.75 2358.77 9782.82 2633.82 9782.82 2973.1 C 9782.82 3312.38 9500.75 3587.43 9152.8 3587.43 C 8804.85 3587.43 8522.79 3312.39 8522.79 2973.1 C 8522.79 2633.81 8804.85 2358.77 9152.8 2358.77 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"/>
      <path
        id="path_1"
        d="M 9153 6729 L 9153 6729 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"/>
      <path
        id="path_2"
        d="M 8565 6681 L 8565 6681 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_3"
        d="M 9200 616.341 L 9200 616.341 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_4"
        d="M 7741 1514.31 L 8165 2011.69 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_5"
        d="M 6872.07 2987 L 6872.07 2987 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_6"
        d="M 7737.94 4485.04 L 8216.06 4006.93 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_7"
        d="M 353.094 7461 L 353.094 7461 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_8"
        d="M 2943 5452 L 7181 5452 C 8285.57 5452 9181 6347.43 9181 7452 L 9181 7520 C 9181 8624.57 8285.57 9520 7181 9520 L 2943 9520 C 1838.43 9520 943 8624.57 943 7520 L 943 7452 C 943 6347.43 1838.43 5452 2943 5452 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_9"
        d="M 6678.79 7525.27 C 6253.44 7884.65 5890.53 7884.65 5465.11 7525.27 C 5187.71 7290.93 4950.88 7290.93 4673.55 7525.27 C 4252.1 7881.16 3908.42 7881.16 3487.04 7525.27 C 3206.55 7288.21 3017.08 7288.21 2736.39 7525.27 L 2620.66 7351.41 C 2961.71 7063.42 3248.26 7051.75 3602.84 7351.41 C 3937.08 7633.79 4183.81 7667.3 4557.82 7351.41 C 4912.4 7051.75 5242.07 7065.24 5580.9 7351.41 C 5928.74 7645.32 6201.64 7656.84 6563.07 7351.41 C 6917.85 7051.75 7149.76 7051.75 7504.34 7351.41 L 7388.54 7525.27 C 7103.47 7284.26 6964.07 7284.26 6678.79 7525.27 Z M 6678.79 6935.49 C 6253.44 7294.87 5890.53 7294.87 5465.11 6935.49 C 5187.71 6701.01 4950.88 6701.01 4673.55 6935.49 C 4252.1 7291.39 3908.42 7291.39 3487.04 6935.49 C 3206.55 6698.43 3017.08 6698.43 2736.39 6935.49 L 2620.66 6761.63 C 2961.71 6473.49 3248.26 6461.97 3602.84 6761.63 C 3937.08 7044.02 4183.81 7077.51 4557.82 6761.63 C 4912.4 6461.97 5242.07 6475.46 5580.9 6761.63 C 5928.74 7055.53 6201.64 7067.06 6563.07 6761.63 C 6917.85 6461.97 7149.76 6461.97 7504.34 6761.63 L 7388.54 6935.49 C 7103.47 6694.49 6964.07 6694.49 6678.79 6935.49 Z M 3602.84 7941.19 C 3937.08 8223.73 4183.81 8257.07 4557.82 7941.19 C 4912.4 7641.52 5242.07 7655.02 5580.9 7941.19 C 5928.74 8235.24 6201.64 8246.77 6563.07 7941.19 C 6917.85 7641.52 7149.76 7641.52 7504.34 7941.19 L 7388.54 8115.05 C 7103.47 7874.2 6964.07 7874.2 6678.79 8115.05 C 6253.44 8474.43 5890.53 8474.58 5465.11 8115.05 C 5187.71 7880.71 4950.88 7880.71 4673.55 8115.05 C 4252.1 8471.1 3908.42 8471.1 3487.04 8115.05 C 3206.55 7877.98 3017.08 7877.98 2736.39 8115.05 L 2620.66 7941.19 C 2961.71 7653.2 3248.26 7641.52 3602.84 7941.19 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="200"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        id="path_10"
        d="M 9216.02 7461 L 9216.02 7461 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="300"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PoolSixth')

export default icon
