import React, { useState } from 'react'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { Visibility, VisibilityOff } from 'icons'
import { errorColor } from '@app/lib/Users/pinCode'

import useStyles from './PincodeField.style'

interface Props {
  meta: {
    touched: boolean,
    error: boolean,
  },
  input: {
    name: string,
    onBlur: () => void,
    onChange: () => void,
    onDragStart: () => void,
    onDrop: () => void,
    onFocus: () => void,
    value: string,
  },
  title: string,
  isNCentral: boolean,
  showHint: boolean,
}

const PincodeField: React.FC<Props> = (props) => {
  const {
    title, showHint, isNCentral, meta: { touched, error }, input,
  } = props
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const createAdorment = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="Toggle password visibility"
        onClick={handleClickShowPassword}
        size="large"
      >
        {showPassword ? <Visibility className={classes.icon} /> : <VisibilityOff className={classes.icon} />}
      </IconButton>
    </InputAdornment>
  )

  const createTextField = () => {
    const device = isNCentral ? 'ncentral' : 'mezzo'
    const color = touched && error && errorColor
    return (
      <TextField
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        label={title}
        // @ts-ignore
        sx={{
          '& .MuiOutlinedInput-root  fieldset': {
            borderColor: color,
          },
        }}
        InputProps={{
          endAdornment: createAdorment(),
          ...input,
        }}
        InputLabelProps={{
          shrink: true,
          className: (touched && error) ? classes.errorState : undefined,
        }}
        helperText={(!touched || error === undefined) && I18n.t(`my_users.pin_code_hint_${device}`)}
      />
    )
  }

  return (
    <FormControl variant="standard" className={classes.input}>
      <Tooltip open={showHint} title={I18n.t('my_users.pin_code_validation_zeros')}>{createTextField()}</Tooltip>
      {touched && error && (
        <FormHelperText id="component-error-text" className={classes.text}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default PincodeField
