import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.string.isRequired,
  is_user_triggerable: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  verified: PropTypes.bool.isRequired,
  activate_status: PropTypes.string.isRequired,
  is_used_another_scene: PropTypes.bool.isRequired,
  number: PropTypes.number,
  webhook_enabled: PropTypes.bool.isRequired,
  webhook_guid: PropTypes.string.isRequired,
  webhook_test_conditions: PropTypes.bool.isRequired,
  with_unsupported: PropTypes.bool.isRequired,

})

export default propTypes
