import React from 'react'
import { List, ListItem } from '@mui/material'
import { IGroupTwrBackend, IGroupZwaveBackend } from '@app/@types/scenes/scenesDevices'
import Group from './Group'
import useStyles from './GroupsList.style'

interface Props {
  groups: Array<IGroupZwaveBackend> | Array<IGroupTwrBackend>,
  deviceType: string,
}

const GroupsList: React.FC<Props> = (props) => {
  const {
    groups,
    deviceType,
  } = props

  const classes = useStyles()
  return (
    <List className={classes.root}>
      {groups.map((group) => (
        <ListItem key={group.id}>
          <Group
            name={group.name}
            deviceType={deviceType}
            // @ts-ignore
            devices={group.twr_devices || group.zwave_devices}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default GroupsList
