import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/zwave_devices/binary_sensors/${id}`

const fetchBinarySensorsTypes = (accessToken, id) => Axios.get(ENDPOINT(id), {
  headers: { 'Access-Token': accessToken },
})

export default fetchBinarySensorsTypes
