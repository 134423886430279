import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { SET_REJECT_REQUEST } from '@app/constants/users/currentRequest'
import Api from '@app/api/users/rejectUserRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { USERS } from '@app/constants/routes'

const rejectUserRequestLogic = createLogic({
  type: SET_REJECT_REQUEST,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { users: { currentRequest: { request: { id: requestId } } } } = getState()
    next({
      ...action,
      accessToken,
      requestId,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_REJECT_REQUEST))
    Api.rejectUserRequest(action.accessToken, action.requestId)
      .then(() => {
        dispatch(apiRequestDone(SET_REJECT_REQUEST))
        history.push(USERS)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('rejectUserRequest failed without error')
        }
      })
      .then(() => done())
  },
})

export default rejectUserRequestLogic
