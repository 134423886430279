import { createLogic } from 'redux-logic'
import Api from '@app/api/groups'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_GROUP } from '@app/constants/groups/groups'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setDeleteGroup } from '@app/actions/groups/groups'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const deleteGroup = createLogic({
  type: DELETE_GROUP,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(DELETE_GROUP))
    Api.deleteGroup(AccessToken, action.id)
      .then((response) => {
        dispatch(setDeleteGroup(response.data.group))
        dispatch(apiRequestDone(DELETE_GROUP))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteGroup failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteGroup
