import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 133 133">
      <path fill="currentColor" d="M66.5 0C29.773 0 0 29.773 0 66.5 0 103.227 29.773 133 66.5 133c36.727 0 66.5-29.773 66.5-66.5C132.961 29.79 103.211.04 66.5 0zM29.564 23.028A56.943 56.943 0 0166.5 9.5a56.735 56.735 0 0136.77 13.514l-80.275 80.275c-20.35-23.978-17.408-59.912 6.57-80.261zm73.872 86.944A56.944 56.944 0 0166.5 123.5a56.732 56.732 0 01-36.77-13.514l80.275-80.275c20.35 23.978 17.408 59.912-6.569 80.261z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CancelIcon', '0 0 133 133', {fontSize: 133})

export default icon
