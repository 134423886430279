const styles = (theme) => ({
  pickerContainer: {
    width: '100%',
  },
  colorWrapper: {
    width: '100%',
    height: 43,
    marginBottom: 10,
    boxSizing: 'border-box',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    backgroundColor: theme.backgrounds.cardBackground,
  },
  currentColor: {
    borderRadius: 5,
    width: '35%',
    textAlign: 'center',
    height: '100%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: 14,
    color: '#000',
    border: '1px solid black',
  },
  pickerWrapper: {
    width: '100%',
    height: 230,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    backgroundColor: theme.backgrounds.cardBackground,
  },
  overflow: {
    overflow: 'hidden',
    height: 165,
  },
  slider: {
    cursor: 'pointer',
  },

})

export default styles
