import type { Node } from 'react'
import React from 'react'
import {
  Icon, ListItem, ListItemIcon, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import type { Activity } from '@app/types/activities/activitiesTypes'
import { typeIconsMap } from '@app/lib/Activity/typeIcons'
import { statusIconsMap } from '@app/lib/Activity/statusIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './ActivityCard.style'

type Props = {
  activity: Activity,
}

const ActivityCard = (props: Props): Node => {
  const {
    activity,
  } = props
  const classes = useStyles()
  const { isMobile } = useSelector((state) => state.mobileDetector)
  const dateOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hourCycle: 'h12',
  }
  const activityDate = new Date(activity.created_at).toLocaleString('en-GB', dateOptions).replace(/,/g, '')

  return (
    <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile)}>
      <ListItem className={classes.cardInfo}>
        <ListItemIcon>
          {typeIconsMap[activity.type]}
        </ListItemIcon>
        <Wrapper className={classes.textWrapper}>
          <Typography className={classNames(classes.activityMessage, isMobile && classes.activityMessageMobile)}>
            { activity.message }
          </Typography>
          <Typography className={classNames(classes.activityTime, isMobile && classes.activityTimeMobile)}>
            {`${activityDate}`}
          </Typography>
        </Wrapper>
        <Icon color={activity.status === 'done' ? 'primary' : 'secondary'}>
          {statusIconsMap[activity.status]}
        </Icon>
      </ListItem>
    </Wrapper>
  )
}

export default ActivityCard
