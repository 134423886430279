import React from 'react'
import classNames from 'classnames'
import {
  Avatar, Button, Paper, Typography,
} from '@mui/material'
import { USERS } from '@app/constants/routes'
import { changeRoleInput, roleValues } from '@app/lib/Users/roleValues'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

import SelectString from './SelectString/SelectString'
import Text from '../UserRequestDetails/Text/Text'

class MemberDetails extends React.PureComponent {
  state = {
    warningDeletePopupOpen: false,
  }

  componentDidMount() {
    this.closeWarningDeletePopup()
  }

  onBackClick=() => {
    const { navigate, resetCurrentMember } = this.props
    navigate(USERS)
    resetCurrentMember()
  }

  toggleWarningDeletePopup = () => {
    const { warningDeletePopupOpen } = this.state
    this.setState({ warningDeletePopupOpen: !warningDeletePopupOpen })
  }

  closeWarningDeletePopup = () => {
    this.setState({ warningDeletePopupOpen: false })
  }

  deleteCurrentMember= () => {
    const {
      deleteMember,
    } = this.props
    this.closeWarningDeletePopup()
    deleteMember()
  }

  renderText = (el) => (
    <Text
      title={I18n.t(`my_users.${el.hint}`)}
      value={el.value}
      key={el.title}
    />
  )

  renderSelectString = () => {
    const { submit } = this.props
    return (
      <SelectString
        name={changeRoleInput}
        title={I18n.t(`my_users.${changeRoleInput}`)}
        values={roleValues}
        submit={submit}
      />
    )
  }

  renderDeleteButton=(classes) => (
    <Wrapper className={classes.buttonDeleteWrapper}>
      <Button
        onClick={this.toggleWarningDeletePopup}
        variant="outlined"
        color="secondary"
        className={classes.buttonDelete}
      >
        {I18n.t('buttons.delete')}
      </Button>
    </Wrapper>
  )

  render() {
    const {
      classes,
      member,
      memberAvatar,
      memberRole,
      canChangeRole,
      ssid,
      isMobile,
    } = this.props
    const { warningDeletePopupOpen } = this.state
    return (
      <Paper className={classNames(
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title={I18n.t('my_users.member_editing')}
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
          customTitle
        />
        <form className={classes.formWrapper}>
          <Wrapper className={classes.headerWrapper}>
            <Avatar src={memberAvatar} className={classes.avatar} />
            { canChangeRole
              ? this.renderSelectString() : <Typography className={classes.roleButton}>{I18n.t(`my_users.${memberRole}`)}</Typography>}
          </Wrapper>
          <Wrapper className={classes.fieldsWrapper}>
            { member.map((el) => this.renderText(el)) }
            <hr />
            { canChangeRole && this.renderDeleteButton(classes) }
          </Wrapper>
        </form>
        <WarningComponent
          open={warningDeletePopupOpen}
          onClose={this.toggleWarningDeletePopup}
          onClick={this.deleteCurrentMember}
          warningText="confirmations.delete_member"
          leftButton="warning_popup_accept_button"
          rightButton="warning_popup_decline_button"
          ssid={ssid}
        />
      </Paper>
    )
  }
}

MemberDetails.propTypes = {
  member: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    hint: PropTypes.string,
  })).isRequired,
  memberAvatar: PropTypes.string.isRequired,
  memberRole: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  canChangeRole: PropTypes.bool,
  ssid: PropTypes.string,
  deleteMember: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  resetCurrentMember: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

MemberDetails.defaultProps = {
  canChangeRole: false,
  ssid: 'My_Mezzo-XXXX',
}

MemberDetails.displayName = 'MemberDetails'

export default MemberDetails
