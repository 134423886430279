import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 150">
      <path stroke="#000000" strokeWidth="4" d="M2 9.074c0-2.527 1.558-4.25 4.577-5.443C9.635 2.42 13.713 2 17.5 2c3.589 0 7.676.42 10.79 1.634C31.393 4.844 33 6.582 33 9.074c0 22.296-5.794 64.395-10.319 87.072-.252.933-.477 1.828-.693 2.68-.571 2.265-1.069 4.236-1.786 5.852-.476 1.072-.97 1.783-1.487 2.22-.467.396-1.031.639-1.863.639-1.176 0-1.863-.276-2.325-.623-.489-.366-.929-.963-1.336-1.946-.412-.996-.733-2.24-1.068-3.775-.102-.47-.207-.971-.317-1.494-.234-1.12-.49-2.342-.789-3.57C5.525 73.624 2 31.49 2 9.074z"/>
      <circle cx="16.5" cy="136.5" r="11.5" stroke="#000000" strokeWidth="4"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ExclamationActiveIcon', '0 0 35 150', {fontSize: 140})

export default icon
