import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import DeviceGroups from '@app/components/MyDevices/DeviceInfo/DeviceGroups/DeviceGroups'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'

let enhance = DeviceGroups

const mapStateToProps = (state: AppState) => {
  const {
    currentDevice,
    devices,
  } = state.myDevices.myDevices
  const { groupsList } = state.groups
  const { isMobile } = state.mobileDetector
  return {
    groupsList,
    currentDevice,
    devices,
    isMobile,
    admin: checkIfAdmin(state.users.currentUser.user.role),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const DeviceGroupsEnhanced = enhance

export default DeviceGroupsEnhanced
