import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  nested: {
    width: '100%',
    // @ts-ignore
    paddingLeft: theme.spacing(4),
  },
}))

export default useStyles
