const convertBatteryBundle = (bundle) => (
  {
    type: 'battery',
    short_title: '%',
    title: 'Battery',
    ...bundle,
  }
)

export default convertBatteryBundle
