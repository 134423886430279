import { I18n } from 'react-redux-i18n'

const convertDeviceStatsForPopup = (deviceStats) => {
  const copiedStats = deviceStats.map((stat) => ({ ...stat }))
  copiedStats.forEach((stat) => {
    if (stat.status === null || stat.status === undefined) {
      stat.status = I18n.t('my_energy.not_available')
    }
    if (typeof stat.status === 'boolean') {
      stat.status = setValueStatus(stat.status)
    }
  })
  return copiedStats
}

const setValueStatus = (value) => (value === true ? I18n.t('my_status.detected') : I18n.t('my_status.not_detected'))

export default convertDeviceStatsForPopup
