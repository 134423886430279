import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 40">
        <path fill="currentColor" d="M25.653 35a.973.973 0 0 1-.973-.972V1.944H2.32v32.084a.973.973 0 0 1-1.945 0V.972C.375.436.811 0 1.347 0h24.306c.536 0 .972.436.972.972v33.056a.973.973 0 0 1-.972.972ZM23.465 3.646v30.382H3.535V3.646h19.93ZM8.395 19.08c0-.873-.598-1.58-1.336-1.58-.738 0-1.337.707-1.337 1.58 0 .872.599 1.58 1.337 1.58s1.337-.708 1.337-1.58Z"/>
      </svg></React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwDoorSensorClosedIcon')

export default icon
