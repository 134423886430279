import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { isIOS } from 'react-device-detect'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { withAccessDenied, withNotifications } from '@app/hocs'
import SelectConditionType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectConditionType/SelectConditionType'
import SelectConditionTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/SelectConditionType/SelectConditionType'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? SelectConditionTypeIOS : SelectConditionType

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isEditConditionMode, isChangeConditionMode, isAdditionalConditionMode, sceneReviewConditionTypes, sceneMode,
  } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    isEditConditionMode,
    isChangeConditionMode,
    isAdditionalConditionMode,
    sceneReviewConditionTypes,
    sceneMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectConditionTypeEnhanced = enhance

export default SelectConditionTypeEnhanced
