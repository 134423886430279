import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  name: PropTypes.string,
  icon: PropTypes.number,
  id: PropTypes.number,
  energy_info: PropTypes.shape({
    last_hour: PropTypes.number,
    today: PropTypes.number,
    all_times: PropTypes.number,
    date_of_last_reset: PropTypes.string,
  }),
})

export default propTypes
