import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import getConditionDataForBackend from '@app/helpers/scenes/getConditionDataForBackend'
import { PATCH_CONDITION } from '@app/constants/scenes/currentSceneCreating'
import Api from '@app/api/scenes/patchCondition'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchConditionLogic = createLogic({
  type: PATCH_CONDITION,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const {
      scenes: {
        currentSceneCreating: {
          currentCondition: { id },
          currentCondition,
          isEditConditionMode, screenName,
        },
      },
    } = getState()
    const conditionData = getConditionDataForBackend(currentCondition)
    next({
      ...action,
      token,
      id,
      conditionData,
      isEditConditionMode,
      screenName,
    })
  },
  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_CONDITION))
    Api.patchCondition(action.token, action.id, action.conditionData)
      .then(() => {
        dispatch(apiRequestDone(PATCH_CONDITION))
        history.push(action.screenName)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchCondition failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchConditionLogic
