import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  SELECT_MULTILEVEL_SENSOR_STATE_SELECTED,
  SELECT_MULTILEVEL_SENSOR_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import TemperatureSensorTabs from './TemperatureSensor/TemperatureSensorTabs'
import HumiditySensor from './HumiditySensor/HumiditySensor'
import LuminanceSensor from './LuminanceSensor/LuminanceSensor'
import UltravioletSensor from './UltravioletSensor/UltravioletSensor'
import UniversalSensorTabs from './UniversalSensor/UniversalSensorTabs'

class SelectMultilevelSensorValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSensorStateMode,
    } = this.props
    if (isChangeSensorStateMode) {
      navigate(SELECT_MULTILEVEL_SENSOR_STATE_SELECTED)
    } else {
      navigate(SELECT_MULTILEVEL_SENSOR_TYPE)
    }
  }

  renderSensorTypeSettings=(sensorType) => {
    const {
      isMobile,
      navigate,
      saveConditionDeviceStateInfo,
      conditionDeviceNumber,
      saveMultilevelSensorValueDescription,
      sensorScales,
    } = this.props
    switch (sensorType) {
    case 1:
      return (
        <TemperatureSensorTabs
          isMobile={isMobile}
          navigate={navigate}
          saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
          saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
          conditionDeviceNumber={conditionDeviceNumber}
          sensorType={sensorType}
        />
      )
    case 3:
      return (
        <LuminanceSensor
          isMobile={isMobile}
          navigate={navigate}
          saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
          saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
          conditionDeviceNumber={conditionDeviceNumber}
          sensorType={sensorType}
        />
      )
    case 5:
      return (
        <HumiditySensor
          isMobile={isMobile}
          navigate={navigate}
          saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
          saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
          conditionDeviceNumber={conditionDeviceNumber}
          sensorType={sensorType}
        />
      )
    case 27:
      return (
        <UltravioletSensor
          isMobile={isMobile}
          navigate={navigate}
          saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
          saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
          conditionDeviceNumber={conditionDeviceNumber}
          sensorType={sensorType}
        />
      )
    default:
      return (
        <UniversalSensorTabs
          isMobile={isMobile}
          navigate={navigate}
          saveConditionDeviceStateInfo={saveConditionDeviceStateInfo}
          saveMultilevelSensorValueDescription={saveMultilevelSensorValueDescription}
          conditionDeviceNumber={conditionDeviceNumber}
          sensorType={sensorType}
          sensorScales={sensorScales}
        />
      )
    }
  }

  render() {
    const {
      classes,
      isMobile,
      sensorType,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_sensor_value')}</Typography>
        </Wrapper>
        {this.renderSensorTypeSettings(sensorType)}
        <Wrapper className={classes.main} />
      </Paper>
    )
  }
}

SelectMultilevelSensorValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sensorType: PropTypes.number.isRequired,
  isChangeSensorStateMode: PropTypes.bool.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveMultilevelSensorValueDescription: PropTypes.func.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  sensorScales: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    short: PropTypes.string.isRequired,
  })).isRequired,
}

SelectMultilevelSensorValue.displayName = 'SelectMultilevelSensorValue'

export default SelectMultilevelSensorValue
