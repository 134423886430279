import React, { ReactNode } from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ChevronRight } from 'icons'
import useStyles from './MenuItem.style'

interface Props {
  icon?: ReactNode,
  title: string,
  open: any,
  close: () => void,
  disabled: boolean,
  sub?: boolean,
}
const MenuItem: React.FC<Props> = (props) => {
  const {
    icon = null,
    title,
    open,
    close,
    sub = false,
    disabled,
  } = props
  const classes = useStyles()
  const className = sub ? classes.nested : ''
  return (
    <ListItem
      button
      onClick={() => {
        close()
        open()
      }}
      className={className}
      disabled={disabled}
    >
      {icon && <ListItemIcon style={{ minWidth: '45px' }}>{icon}</ListItemIcon>}
      <ListItemText primary={I18n.t(`titles.${title}`)} />
      <ChevronRight />
    </ListItem>
  )
}

export default MenuItem
