import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="40" height="40"fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5091 13.1599C17.2953 13.1629 13.0707 17.3915 13.0728 22.6054C13.0748 27.8192 17.3025 32.0445 22.5164 32.0435C27.7303 32.0425 31.9564 27.8156 31.9564 22.6017C31.9564 20.0967 30.9609 17.6943 29.189 15.9234C27.4172 14.1526 25.0142 13.1585 22.5091 13.1599ZM26.5455 24.5108L25.2364 26.7254L17.9055 26.7581L22.1818 20.1526H18.0146L19.3455 18.0035H26.5455L22.4109 24.5108H26.5455Z" fill="currentColor"/>
      <path d="M22.5091 2.04358C9.77273 2.04358 2 11.5454 2 22.1218" stroke="currentColor" strokeWidth="3"/>
      <path d="M7.74365 21.8327C7.74365 14.8672 13.8637 7.54724 22.5091 7.54724" stroke="currentColor" strokeWidth="3"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'NessZwaveIcon', "0 0 30 40", {fontSize: 40})

export default icon
