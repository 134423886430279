import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, Typography,
} from '@mui/material'
import { deleteContact } from '@app/actions/contacts/contacts'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { Add, Close } from 'icons'
import { withStyles } from '@app/components/styles'

const UserSummary = (props) => {
  const {
    classes,
    openAvatarPopup,
    close,
    index,
    editContact,
  } = props
  const {
    id, address, phone_number, email, preferred_name, avatar,
  } = useSelector((state) => state.contacts.contactsList[index])
  const { isMobile, isTablet } = useSelector((state) => state.mobileDetector)
  const dispatch = useDispatch()

  return (
    <Dialog open className={classes.dialog} onClose={close} fullScreen={isMobile && !isTablet}>
      <IconButton className={classes.closeButton} onClick={close} size="large">
        <Close />
      </IconButton>
      <Wrapper
        className={classes.avatar}
        customStyle={{
          background: `url("${avatar}") no-repeat 50% 50%`,
          backgroundSize: 'cover',
        }}
      >
        <Wrapper className={classes.changeAvatar} onClick={openAvatarPopup}>
          <Add className={classes.addIcon} />
        </Wrapper>
      </Wrapper>
      <DialogTitle className={classNames(classes.title, isMobile && !isTablet && classes.titleMobile)}>{preferred_name}</DialogTitle>
      <DialogContent className={classes.content}>
        <Wrapper>
          <Typography className={classes.infoField}>{`${I18n.t('contacts_web.phone_number')}:`}</Typography>
          <Typography>{phone_number}</Typography>
        </Wrapper>
        <Wrapper>
          <Typography className={classes.infoField}>{`${I18n.t('contacts_web.email')}:`}</Typography>
          <Typography>{email || 'None'}</Typography>
        </Wrapper>
        <Wrapper>
          <Typography className={classes.infoField}>{`${I18n.t('contacts_web.address')}:`}</Typography>
          <Typography>{address || 'None'}</Typography>
        </Wrapper>
        <Wrapper className={classes.controls}>
          <Button className={classes.controlButton} onClick={() => dispatch(deleteContact(id))}>{I18n.t('buttons.delete')}</Button>
          <Button className={classes.controlButton} onClick={editContact}>{I18n.t('buttons.edit')}</Button>
        </Wrapper>
      </DialogContent>
    </Dialog>
  )
}

UserSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  openAvatarPopup: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  editContact: PropTypes.func.isRequired,
}

UserSummary.displayName = 'UserSummaryPopup'

export default withStyles(UserSummary)
