import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/roomsStats'

const initialState = {
  rooms: [],
  isRoomsStatsPopupOpen: false,
  currentRoom: {
    id: null,
    name: '',
  },
  roomStats: {
    today: {
      energy: 0,
      billing: 0,
    },
    last_week: {
      energy: 0,
      billing: 0,
    },
    last_month: {
      energy: 0,
      billing: 0,
    },
    last_3_months: {
      energy: 0,
      billing: 0,
    },
    last_6_months: {
      energy: 0,
      billing: 0,
    },
    last_year: {
      energy: 0,
      billing: 0,
    },
  },
}

const roomsStats = (state = initialState, action) => {
  switch (action.type) {
  case type.UPDATE_ROOMS_STATS: {
    return update(state, {
      rooms: { $set: action.rooms },
    })
  }

  case type.OPEN_ROOM_STATS_POPUP: {
    return {
      ...state,
      isRoomsStatsPopupOpen: true,
    }
  }
  case type.CLOSE_ROOM_STATS_POPUP: {
    return {
      ...state,
      isRoomsStatsPopupOpen: false,
    }
  }

  case type.SELECT_CURRENT_ROOM_ID_AND_NAME: {
    return update(state, {
      currentRoom: {
        id: { $set: action.id },
        name: { $set: action.name },
      },
    })
  }
  case type.CLEAR_CURRENT_ROOM_ID_AND_NAME: {
    return update(state, {
      currentRoom: {
        id: { $set: null },
        name: { $set: '' },
      },
    })
  }

  case type.UPDATE_CURRENT_ROOM_STATS: {
    return update(state, {
      roomStats: { $set: action.stats },
    })
  }
  case type.CLEAR_CURRENT_ROOM_STATS: {
    return { ...state, roomStats: initialState.roomStats }
  }
  default:
    return state
  }
}

export default roomsStats
