import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import getMezzoConditionDataNumber from '@app/helpers/scenes/getMezzoConditionDataNumber'
import getMezzoConditionDataValue from '@app/helpers/scenes/getMezzoConditionDataValue'
import { mezzoConditionStates } from '@app/lib/Scenes/mezzoConditionStates'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMezzoConditionState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      isEditConditionMode,
      saveConditionStateDescription,
      saveConditionType,
      saveConditionDescription,
      saveConditionDataNumber,
      saveConditionDataValue,
    } = this.props
    saveConditionDescription(I18n.t('scene_condition_web.my_device'))
    saveConditionStateDescription(state.descriptionUI)
    saveConditionType(state.stateType)
    saveConditionDataNumber(getMezzoConditionDataNumber(state.description))
    saveConditionDataValue(getMezzoConditionDataValue(state.description))
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            mezzoConditionStates.map((state) => (
              <CardBlock
                key={state.description}
                description={I18n.t(`scene_condition_web.${state.description}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoConditionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  saveConditionDataNumber: PropTypes.func.isRequired,
  saveConditionDataValue: PropTypes.func.isRequired,
}

SelectMezzoConditionState.displayName = 'SelectMezzoConditionStateIOS'

export default SelectMezzoConditionState
