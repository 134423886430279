import * as type from '@app/constants/myDevices/deviceSettings/voiceAssistant'

export type TVoiceAssistantAction =
  | { type: '@@myDevices/patchVoiceAssistant', data: IVoiceAssistantData }
  | { type: '@@myDevices/patchVoiceAssistantPin', data: IVoiceAssistantPin }

export interface IVoiceAssistantData {
  id: number,
  voice_assistant_status: string,
}

export interface IVoiceAssistantPin {
  voice_assistant_pin: string,
}

export const patchVoiceAssistant = (data: IVoiceAssistantData): TVoiceAssistantAction => ({
  type: type.PATCH_VOICE_ASSISTANT,
  data,
})

export const patchVoiceAssistantPin = (data: IVoiceAssistantPin): TVoiceAssistantAction => ({
  type: type.PATCH_VOICE_ASSISTANT_PIN,
  data,
})
