import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { doorLockStates } from '@app/lib/Scenes/doorLockStates'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectDoorLockConditionState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
  }

  selectState=(state) => {
    const {
      navigate,
      saveConditionStateDescription,
      saveConditionDeviceStateInfo,
      conditionDeviceNumber,
      isEditConditionMode,
    } = this.props
    saveConditionStateDescription(state.description)
    saveConditionDeviceStateInfo({
      type: 'Device',
      data: {
        type: 2,
        value: state.value,
        number: conditionDeviceNumber,
      },
    })
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            doorLockStates.map((state) => (
              <CardBlock
                key={state.description}
                description={state.description}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectDoorLockConditionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
}

SelectDoorLockConditionState.displayName = 'SelectDoorLockConditionStateIOS'

export default SelectDoorLockConditionState
