import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/scenes/`

const addScene = (accessToken) => Axios.post(ENDPOINT, null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  addScene,
}
