import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ZwaveDetailsAndParams
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveDetailsAndParams'
import { withAccessDenied, withNotifications } from '@app/hocs'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = ZwaveDetailsAndParams

const mapStateToProps = (state: AppState) => {
  const { currentDevice } = state.myDevices.myDevices
  const {
    currentDeviceDetails, error, configurationPopup, sleepingDevicePopup,
  } = state.myDevices.myDevices.deviceDetails
  const { isMobile } = state.mobileDetector
  return {
    currentDevice,
    currentDeviceDetails,
    error,
    configurationPopup,
    isMobile,
    sleepingDevicePopup,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ZwaveDetailsAndParamsEnhanced = enhance

export default ZwaveDetailsAndParamsEnhanced
