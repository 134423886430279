import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M72.3351 34.889C77.2258 34.233 80.9334 30.0585 80.9334 25.0893V21L36.9399 21.4907V25.3753C36.9399 30.4085 40.7683 34.6443 45.7817 35.1814L45.7883 35.1821C54.6632 36.1036 63.4922 36.0066 72.3199 34.891L72.3275 34.8901L72.3351 34.889ZM46.2048 31.2039C43.2354 30.8841 40.9762 28.3895 40.9404 25.4464L76.9334 25.0449V25.0893C76.9334 28.0305 74.7337 30.5279 71.8103 30.9236C63.2907 31.9996 54.7724 32.0931 46.2048 31.2039Z" fill="currentColor"/>
        <path d="M55.4351 33.7617V52.7812H59.4351V33.7617H55.4351Z" fill="currentColor"/>
        <path d="M127.253 87.082L109.567 82.082L108.75 84.9689L126.437 89.9689L127.253 87.082Z" fill="currentColor"/>
        <path d="M99.8335 116.147L117.407 121.147L118.228 118.262L100.654 113.262L99.8335 116.147Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M125.505 79.4766L138.423 83.0517L125.553 129.766L112.635 126.191L125.505 79.4766ZM128.298 84.3998L133.506 85.8411L122.76 124.843L117.552 123.402L128.298 84.3998Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M108.917 69.8038C111.682 70.6618 113.35 73.5219 112.635 76.3343C108.869 91.8741 104.531 107.461 99.6692 122.668C98.9541 124.956 96.8568 126.481 94.4734 126.481C94.0443 126.481 93.5677 126.433 93.1387 126.338C66.9689 119.903 40.6562 112.657 14.8678 104.84C12.1031 103.982 10.4824 101.169 11.1497 98.3569C14.8202 82.8172 19.0626 67.1821 23.7818 51.9283C24.4491 49.5926 26.5465 48.0195 28.9776 48.0195C29.4066 48.0195 29.8833 48.0672 30.3123 48.1625C56.5773 54.6454 83.0331 61.9386 108.917 69.8038ZM94.3294 121.473C94.2933 121.469 94.2653 121.465 94.2467 121.462C68.2025 115.056 42.0126 107.843 16.3417 100.062C16.1981 100.015 16.1101 99.9326 16.0575 99.841C16.0048 99.7492 15.9834 99.6428 16.0147 99.5113L16.0158 99.5063C19.661 84.0736 23.8738 68.5481 28.5584 53.4061L28.5744 53.3542L28.5894 53.3019C28.6203 53.1938 28.6715 53.1332 28.7224 53.0949C28.7741 53.0561 28.8534 53.0195 28.9776 53.0195C29.0116 53.0195 29.0633 53.0217 29.1215 53.0278C29.1569 53.0315 29.1846 53.0357 29.2031 53.0388C55.3443 59.4928 81.6769 66.7527 107.442 74.5813C107.577 74.625 107.68 74.7132 107.744 74.824C107.804 74.9302 107.81 75.0196 107.789 75.1023L107.782 75.1295L107.776 75.1567C104.036 90.5877 99.7302 106.06 94.9067 121.145L94.9017 121.16L94.8968 121.176C94.864 121.281 94.8087 121.35 94.7435 121.398C94.6769 121.447 94.5884 121.481 94.4734 121.481C94.4393 121.481 94.3876 121.479 94.3294 121.473Z" fill="currentColor"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'MyCamera')

export default icon
