import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginBottom: '1rem',
    paddingBottom: '2rem',
    paddingTop: '1rem',
  },
  leftArrow: {
    position: 'absolute',
    top: '63%',
    left: -60,
  },
  leftInner: {
    left: 0,
  },
  rightArrow: {
    position: 'absolute',
    top: '63%',
    right: -60,
    transform: 'rotate(180deg)',
  },
  rightInner: {
    right: 0,
  },
  progressTipContainer: {
    position: 'absolute',
    bottom: 10,
    left: 'calc(50% - 10px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressTip: {
    margin: 2,
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#C4C4C4',
  },
  active: {
    backgroundColor: '#DC3912',
  },
  chartPlaceholder: {
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default useStyles
