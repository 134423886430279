import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  deviceTitle: {
    paddingTop: '1rem',
  },
  chartPlaceholder: {
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reportContainer: {
    marginTop: '3rem',
  },
  reportControls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  report: {
    width: 330,
    marginRight: '1rem',
  },
  buttonWrapper: {
    width: '100%',
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
  },
})

export default useStyles
