import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/ecoMode'

const initialState = {
  eco_mode_devices: [],
  currentEcoModeDevice: {
    id: null,
    eco_mode: null,
  },
  isTurnOffPopupOpen: false,
}

const ecoMode = (state = initialState, action) => {
  switch (action.type) {
  case type.UPDATE_ECO_MODE_DEVICES:
    return update(state, {
      eco_mode_devices: { $set: action.devices },
    })

  case type.SELECT_CURRENT_ECO_MODE_DEVICE_ID: {
    return update(state, {
      currentEcoModeDevice: { id: { $set: action.id } },
    })
  }
  case type.CLEAR_CURRENT_ECO_MODE_DEVICE_ID: {
    return update(state, {
      currentEcoModeDevice: { id: { $set: null } },
    })
  }

  case type.SELECT_CURRENT_ECO_MODE_DEVICE_STATUS: {
    return update(state, {
      currentEcoModeDevice: { eco_mode: { $set: action.status } },
    })
  }
  case type.CLEAR_CURRENT_ECO_MODE_DEVICE_STATUS: {
    return update(state, {
      currentEcoModeDevice: { eco_mode: { $set: null } },
    })
  }

  case type.OPEN_TURN_OFF_POPUP: {
    return {
      ...state,
      isTurnOffPopupOpen: true,
    }
  }
  case type.CLOSE_TURN_OFF_POPUP: {
    return {
      ...state,
      isTurnOffPopupOpen: false,
    }
  }

  default:
    return state
  }
}

export default ecoMode
