import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  title: {
    textAlign: 'center',
    paddingTop: 30,
  },
  closeIconButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  checkboxesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  advancedTitle: {
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: 5,
  },
  linkWrapper: {
    display: 'flex',
    marginTop: 10,
  },
  info: {
    fontSize: 14,
    color: theme.texts.subText,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
  },
  infoWithoutList: {
    paddingLeft: 20,
  },
  link: {
    fontSize: 14,
    paddingLeft: 5,
  },
  url: {
    paddingTop: 20,
    wordWrap: 'break-word',
  },
}))

export default useStyles
