import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, List, ListItem, Popover, TextField, Typography,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import getTimerTypeDescription from '@app/helpers/timers/getTimerTypeDescription'
import getTimerType from '@app/helpers/timers/getTimerType'
import getTimerLimit from '@app/helpers/timers/getTimerLimit'
import disableSaveButton from '@app/helpers/timers/disableSaveButton'
import { TIMERS_IN_SCENES } from '@app/constants/routes'
import { putTimer, saveTimerData } from '@app/actions/timers/timers'
import { timerTypes } from '@app/lib/timers/timerTypes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import { ChevronLeft, ArrowIOS } from 'icons'
import {
  ITimer, ITimerData, ITimerDuration, ITimerType,
} from '@app/@types/timers/timersTypes'
import useStyles from './TimersInScenesSettings.style'

interface Props {
  timers: Array<ITimer>,
  timerData: ITimerData,
  isMobile: boolean,
}

const TimersInScenesSettingsIOS: React.FC<Props> = (props) => {
  const { isMobile, timers, timerData } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [timer, setTimer] = useState<ITimerDuration>({
    day: '', hour: '', minute: '', second: '',
  })
  const [timerSettings, setTimerSettings] = useState<ITimerData>({ ...timerData })
  const [timerUsedInScene, setTimerUsedInScene] = useState<boolean>(false)

  useEffect(() => setTimerSettings(timerData), [])

  useEffect(() => (
    setTimer({
      day: timerSettings.duration.day || '',
      hour: timerSettings.duration.hour || '',
      minute: timerSettings.duration.minute || '',
      second: timerSettings.duration.second || '',
    })
  ),
  [])

  useEffect(() => {
    dispatch(saveTimerData({
      ...timerSettings,
      duration: timer,
    }))
  }, [timer])

  useEffect(() => {
    dispatch(saveTimerData({
      ...timerData,
      type: timerSettings.type,
    }))
  }, [anchorEl])

  const open = Boolean(anchorEl)

  const selectType = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget)
  }

  const closeSelect = () => {
    setAnchorEl(null)
  }

  const setTimerType = (type: ITimerType) => {
    setTimerSettings({
      ...timerSettings,
      type: type.type,
    })
    const currentTimer = timers.find((t: ITimer) => t.number === timerData.number)
    if (currentTimer?.scenes.length) {
      if ((currentTimer.timer_type === 'one_shot' || currentTimer.timer_type === 'repeating') && type.type === 'retriggerable') {
        setTimerUsedInScene(!timerUsedInScene)
        setTimerSettings({
          ...timerSettings,
          type: currentTimer.timer_type,
        })
      } else if (currentTimer.timer_type === 'retriggerable' && (type.type === 'one_shot' || type.type === 'repeating')) {
        setTimerUsedInScene(!timerUsedInScene)
        setTimerSettings({
          ...timerSettings,
          type: currentTimer.timer_type,
        })
      }
    }
    setAnchorEl(null)
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classes.titleWrapper}>
        <Wrapper className={classes.iconWrapper}>
          <IconButton
            size="small"
            className={classes.backButton}
            onClick={() => navigate(TIMERS_IN_SCENES)}
            aria-label="Back"
          >
            <ArrowIOS />
          </IconButton>
        </Wrapper>
        <Typography className={classes.title}>{`Timer ${timerSettings.number}`}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        <Wrapper className={classes.settingsWrapper}>
          <Typography className={classes.subTitle}>{`${I18n.t('timers.timer_type')}`}</Typography>
          <Wrapper
            className={classes.typeCard}
            onClick={selectType}
          >
            <Wrapper className={classes.typeTitleWrapper}>
              <Typography className={classes.typeCardTitle}>{getTimerType(timerSettings.type)}</Typography>
              <Typography className={classes.typeCardDescription}>
                {timerSettings.type === '' ? getTimerTypeDescription('one_shot') : getTimerTypeDescription(timerSettings.type)}
              </Typography>
            </Wrapper>
            <ChevronLeft className={classNames(classes.chevronIcon, anchorEl && classes.chevronIconOpened)} />
          </Wrapper>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={closeSelect}
            anchorOrigin={{
              vertical: 60,
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className={classes.popupList}
          >
            <List className={classNames(classes.typeList, isMobile && classes.typeListMobile)}>
              {timerTypes.map((type, index) => (
                <ListItem
                  key={`type-${index + 1}`}
                  className={classes.typeItem}
                  onClick={() => setTimerType(type)}
                >
                  <Typography className={classes.typeTitle}>{getTimerType(type.type)}</Typography>
                  <Typography className={classes.typeDescription}>{type.description}</Typography>
                </ListItem>
              ))}
            </List>
          </Popover>
          <Typography className={classes.subTitle}>{`${I18n.t('timers.duration')}:`}</Typography>
          <Wrapper className={classes.durationCard}>
            <Typography className={classes.durationCardTitle}>{`${I18n.t('timers.max_255')}`}</Typography>
            <Wrapper className={classNames(classes.timerWrapper, isMobile && classes.timerWrapperMobile)}>
              { Object.keys(timer).map((time, index) => (
                <TextField
                  variant="standard"
                  key={`timer-${index + 1}`}
                  autoComplete="new-password"
                  label={time.charAt(0).toUpperCase() + time.slice(1)}
                  name={time}
                  value={timer[time]}
                  className={classes.timeInput}
                  onChange={(e) => {
                    // @ts-ignore
                    if ((/^\d+$/.test(e.target.value) || e.target.value === '') && !(e.target.value > getTimerLimit(time))) {
                      setTimer({
                        ...timer,
                        [e.target.name]: e.target.value,
                      })
                    }
                  }}
                  onKeyPress={(e) => {
                    // @ts-ignore
                    if (e.target.value.length === 0 && e.which === 48) {
                      e.preventDefault()
                    }
                  }}
                  inputProps={{
                    autoComplete: 'new-password',
                    maxLength: 3,
                  }}
                />
              ))}
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => {
            dispatch(putTimer())
          }}
          disabled={disableSaveButton(timer.day.toString(), timer.hour.toString(), timer.minute.toString(), timer.second.toString())}
        >
          {I18n.t('buttons.save')}
        </Button>
        {timerUsedInScene && (
          <SmallErrorPopup
            open
            warningText="timers.warning_change_timer_type"
            buttonName="buttons.okay"
            title="titles.warning"
            onClick={() => {
              setTimerUsedInScene(!timerUsedInScene)
            }}
          />
        )}
      </Wrapper>
    </Wrapper>
  )
}

export default TimersInScenesSettingsIOS
