import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStylesHome = makeStyles < ITheme >((theme) => createStyles({
  pink: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  green: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  red: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  darkPink: {
    backgroundColor: theme.palette.secondary.dark,
  },
  darkGreen: {
    backgroundColor: theme.palette.primary.dark,
  },
  darkRed: {
    backgroundColor: theme.palette.secondary.dark,
  },
  greenStatus: {
    backgroundColor: theme.palette.primary.main,
  },
  redStatus: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSecondLine: {
    color: theme.typography.color,
    width: '75%',
    margin: '10px 0',
    fontSize: 30,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 30,
    alignItems: 'center',
  },
  buttonArm: {
    paddingLeft: 15,
  },
  buttonDisarm: {
    paddingRight: 15,
    paddingLeft: 35,
  },
  mobileButtonDisarm: {
    paddingLeft: 30,
    justifyContent: 'flex-end',
  },
  armStatus: {
    position: 'absolute',
    top: '12%',
    width: 61,
    height: 61,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  armStatusIcon: {
    fontSize: 32,
  },
  chevronWrapper: {
    color: theme.texts.halfVisible,
    flexWrap: 'nowrap',
    display: 'flex',
  },
}))

export default useStylesHome
