import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import MyDevices from '@app/components/MyDevices/MyDevices'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage,
} from '@app/hocs'
import { AppDispatch } from '@app/store'
import { AppState } from '@app/@types/store'

let enhance = MyDevices

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const {
    devices, mezzoLights, isMezzoLightsLoading,
  } = state.myDevices.myDevices
  const { status: guardPanelStatus } = state.guardPanel
  return {
    isMobile,
    devices,
    mezzoLights,
    isMezzoLightsLoading,
    guardPanelStatus,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MyDevicesEnhanced = enhance

export default MyDevicesEnhanced
