import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import { createRootReducer } from '@app/reducers'

export const whitelist = [
  'events',
  'notifications',
  'currentUser',
  'currentRequest',
  'currentMember',
  'users',
  'secrets',
  'reminders',
  'unit',
  'units',
  'navigation',
  'myEnergy',
  'energyTab',
  'setUpBillingPeriod',
  'scenes',
  'scenesList',
  'currentSceneCreating',
  'currentSceneConditionsActions',
  'editScene',
  'devicesTabs',
  'twrDevices',
  'twrDevicesByGroups',
  'twrDevicesByRooms',
  'zWaveDevices',
  'zWaveDevicesByGroups',
  'zWaveDevicesByRooms',
  'roomsScenes',
  'groupsScenes',
  'forgotPassword',
  'counter',
  'timers',
  'guardPanel',
  'app',
  'mezzoTime',
  'countersStatus',
  'cloudFirmwareUpdate',
  'cloudFirmwareUpdateInfo',
  'myDevices',
  'appConfig',
]

const config = {
  key: 'root',
  storage,
  whitelist,
}

const configureReducer = () => {
  const routedReducer = createRootReducer()
  const persistedReducer = persistReducer(config, routedReducer)
  return persistedReducer
}

export default configureReducer
