import React from 'react'
import PropTypes from 'prop-types'
import { SIGN_IN } from '@app/constants/routes'
import loginUrlsBlocking from '@app/helpers/loginUrlsBlocking'
import { loginUrls } from '@app/lib/Wizard/loginUrls'
import { history } from '@app/store/history'

const withAccessDenied = (WrappedComponent) => {
  class AccessDeniedable extends React.Component {
    render() {
      const {
        accessDenied, isMobile, path, userLoggedIn, isWebView,
      } = this.props
      const { href } = window.location
      if (!isMobile && !path && !userLoggedIn && !isWebView && !loginUrlsBlocking(window.location.pathname, loginUrls)) {
        history.replace(SIGN_IN)
        window.location.reload()
      } if (!isMobile && !path && !accessDenied && !href.includes('?theme=Lendlease')) {
        return <WrappedComponent {...this.props} />
      }
      return <WrappedComponent {...this.props} />
    }
  }

  AccessDeniedable.propTypes = {
    accessDenied: PropTypes.bool,
    userLoggedIn: PropTypes.bool,
    isMobile: PropTypes.bool,
    isWebView: PropTypes.bool,
    path: PropTypes.string,
  }

  AccessDeniedable.defaultProps = {
    accessDenied: true,
    path: null,
    userLoggedIn: true,
    isMobile: false,
    isWebView: false,
  }

  return AccessDeniedable
}

export default withAccessDenied
