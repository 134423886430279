import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  title: {
    textAlign: 'center',
  },
  triggerMessage: {
    marginBottom: 10,
  },
  triggerCounter: {
    fontSize: 22,
    fontWeight: 500,
  },
  triggerPopupContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    alignItems: 'center',
  },
  triggerPopupButton: {
    width: 200,
  },
}))

export default useStyles
