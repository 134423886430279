import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (number) => `${API_URL}/api/timers/${number}`

const resetTimer = (accessToken, number) => Axios.delete(ENDPOINT(number), {
  headers: { 'Access-Token': accessToken },
})

export default {
  resetTimer,
}
