import { IRGBAColor } from '@app/@types/scenes/scenesDevices'

const convertRGBAStringToObj = (RGBAcolor: string): IRGBAColor => {
  const colorsOnly = RGBAcolor.substring(RGBAcolor.indexOf('(') + 1, RGBAcolor.lastIndexOf(')')).split(/,\s*/)
  const result = {
    red: colorsOnly[0],
    green: colorsOnly[1],
    blue: colorsOnly[2],
    opacity: colorsOnly[3],
  }
  return result
}
export default convertRGBAStringToObj
