import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@app/components/styles'
import { closeMenu, openMenu } from '@app/actions/menu'
import { signOut } from '@app/actions/signOut'
import {
  clearCurrentSceneInfo,
  saveScreenName,
} from '@app/actions/scenes/currentSceneCreating'
import {
  patchUnit, selectUnit, patchUnitName,
  openEditUnitNamePopup,
  closeEditUnitNamePopup,
  closeUnitList, openUnitList,
} from '@app/actions/unit'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { updateCurrentUserRole } from '@app/actions/users/currentUser'
import { sync as syncDevices } from '@app/actions/units'
import Toolbar from '@app/components/Toolbar'
import {
  patchChangeset,
  saveChangesetAction,
} from '@app/actions/scenes/editScene'
import { withRouter } from '@app/hocs'

let enhance = withStyles(Toolbar)

const mapStateToProps = (state) => {
  const { isMenuOpen } = state.menu
  const { isAuthenticated } = state.secrets
  const { devices } = state.units
  const { selectedUnit, isEditNamePopupOpen, isUnitListOpen } = state.unit
  const { isMobile } = state.mobileDetector
  const { sceneMode } = state.scenes.currentSceneCreating
  const {
    editedSceneInitialActions,
    editedSceneInitialConditions,
    editedSceneInitialName,
  } = state.scenes.editScene
  const { conditions, actions } = state.scenes.currentSceneConditionsActions
  const { name: sceneName } = state.scenes.scenesList.currentScene
  const { config: appConfig } = state.appConfig
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  return {
    isUnitListOpen,
    admin,
    appConfig,
    menuOpen: isMenuOpen,
    auth: isAuthenticated,
    unit: state.unit,
    units: devices,
    selectedUnit,
    isMobile,
    sceneMode,
    editedSceneInitialName,
    editedSceneInitialActions,
    editedSceneInitialConditions,
    sceneName,
    actions,
    conditions,
    isEditNamePopupOpen,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onMenuOpen: openMenu,
  onMenuClose: closeMenu,
  syncDevices,
  selectUnit,
  patchUnit,
  updateCurrentUserRole,
  onSignOut: signOut,
  clearCurrentSceneInfo,
  patchChangeset,
  saveChangesetAction,
  saveScreenName,
  patchUnitName,
  openEditUnitNamePopup,
  closeEditUnitNamePopup,
  openUnitList,
  closeUnitList,
}, dispatch)

enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ToolbarEnhanced = withRouter(enhance)

export default ToolbarEnhanced
