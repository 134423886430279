import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M70.7492 98.8359L64.0337 100.121C65.8256 99.9913 68.1356 99.7561 70.8251 99.5534C70.7987 99.3224 70.7756 99.0873 70.7492 98.8359Z" fill="currentColor"/>
      <path d="M126.364 113.394C126.757 111.335 130.922 95.801 87.6553 90.9648C87.5332 91.6742 87.4474 92.3715 87.3121 93.0768C87.1702 93.6687 87.1438 94.0984 87.0283 94.6537C106.597 96.1658 122.097 104.054 126.364 113.394Z" fill="currentColor"/>
      <path d="M30.8296 112.782C30.8296 117.464 42.1354 127.408 66.133 128.324C72.8123 129.557 105.04 132.99 116.362 119.289C118.267 116.346 122.068 111.274 112.498 105.186C106.977 101.683 96.8528 99.2104 86.3324 98.4928C86.2895 98.8415 86.24 99.2063 86.2037 99.5387C91.0514 99.9562 96.1631 100.84 101.225 102.648C102.806 104.371 109.007 112.093 92.576 116.564C95.6846 114.335 100.671 110.476 89.4905 106.106C92.576 108.753 97.1201 113.224 86.1212 116.771C88.4048 114.72 90.3254 112.203 84.5933 110.666C83.4251 107.216 82.6595 101.48 84.8639 92.4082C86.933 81.4347 87.6821 71.0774 78.4949 70.668C69.3242 71.0774 70.0733 81.4347 72.1259 92.4082C75.1817 104.865 72.6374 111.157 71.0765 113.621C70.0568 112.523 70.3505 111.238 71.4098 110.476C69.3242 110.974 63.6382 113.816 71.0666 117.079C68.3441 116.658 56.4343 112.401 64.9417 106.519C62.4799 107.431 54.286 111.189 62.8891 117.177C58.4242 115.547 51.4051 112.203 51.4843 107.431C51.5668 102.656 57.1867 100.637 57.6751 100.337C54.8569 101.14 47.6728 104.691 48.4219 109.462C49.171 114.25 56.761 121.251 78.5972 121.251C100.407 121.251 111.327 111.501 105.301 104.379C110.752 107.739 113.637 110.792 113.396 113.946C113.151 117.079 108.102 128.45 78.3332 128.045C68.7533 128.134 56.6818 125.714 48.0952 120.428C48.8278 121.04 51.1411 122.969 53.131 124.392C49.6594 123.079 38.6803 118.206 38.7628 110.788C38.8189 104.205 50.6494 97.0092 70.0403 94.9742C69.9182 94.3864 69.8918 93.9283 69.7367 93.304C69.5387 92.2744 69.4067 91.261 69.2351 90.2313C54.616 91.3137 22.771 96.4214 25.6948 113.621C25.6948 113.621 27.9256 106.321 35.9413 102.656C33.3046 105.591 30.8296 108.125 30.8296 112.782Z" fill="currentColor"/>
      <path d="M87.1998 78.1621C89.7738 74.1124 94.6314 73.9016 95.6841 71.7693C96.7797 69.6371 95.5422 66.9981 93.6348 66.8359C86.5332 67.1765 87.1998 78.1621 87.1998 78.1621Z" fill="currentColor"/>
      <path d="M52.5501 135.67C31.9251 131.823 17.4414 122.771 17.4414 112.228C17.4414 99.1177 39.888 88.3307 68.7367 86.9687C68.5684 85.6391 68.509 84.3702 68.4463 83.1055C35.0073 84.6256 9 97.4354 9 112.232C9 120.639 17.2764 128.888 30.879 133.429C87.5104 152.347 124.969 134.483 129.803 131.434C99.0637 144.07 52.5501 135.67 52.5501 135.67Z" fill="currentColor"/>
      <path d="M140.998 110.482C140.922 99.7197 127.59 93.631 127.59 93.631C116.357 87.5829 103.355 84.5588 88.5507 83.4805C88.4748 84.802 88.4022 86.1195 88.2174 87.4937C103.341 88.953 116.644 92.8203 124.554 98.3942C140.991 109.964 126.851 120.426 121.069 123.665C88.5045 140.221 51.6633 130.475 41.9712 127.828C61.4082 137.079 93.9561 136.459 104.123 134.432C141.978 126.633 140.998 110.482 140.998 110.482Z" fill="currentColor"/>
      <path d="M63.8066 77.4771C64.7042 77.8743 67.6709 79.3945 67.7666 80.1161C67.7666 80.1161 66.2816 69.9695 62.5691 73.5125C60.9059 75.5597 62.8925 77.0717 63.8066 77.4771Z" fill="currentColor"/>
      <path d="M76.8194 59.4893C76.8458 59.4893 76.8821 59.4893 76.8821 59.4893C81.4592 59.4893 85.6535 56.4693 87.8513 51.5764C90.0524 46.6633 89.9171 40.684 87.4883 35.9695C83.2313 27.6188 78.3605 15.5022 76.8986 9C75.3146 16.0008 70.6715 27.6796 66.3848 35.9412C63.9263 40.6232 63.7679 46.6025 65.936 51.4913C68.1041 56.4085 72.2852 59.4893 76.8194 59.4893ZM82.3931 49.8212C84.9407 46.7768 85.8713 42.9663 86.6402 43.023C87.3992 42.9663 87.6203 48.1267 84.3302 52.1642C81.0665 56.2626 76.8821 56.0274 76.9118 55.091C76.8491 54.183 79.9478 52.9871 82.3931 49.8212Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Drop')

export default icon
