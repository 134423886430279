import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 40">
      <path fill="currentColor" d="M5.24 19.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.24-1.15c1 0 1.99-.29 2.77-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.13.1.37.37 0 0 0-.07.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M18.79 9.77V3.02c0-.29-.19-.51-.32-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.74 20.74 0 0 0 9.39 0C6.93 0 4.65.38 2.97.93a8.1 8.1 0 0 0-2.06.96c-.48.34-.91.8-.91 1.37v6.5c0 .65.47 1.22 1.03 1.68.59.47 1.39.92 2.3 1.31.83.36 1.78.68 2.75.91.13.54.56.97 1.06 1.24.61.34 1.43.54 2.3.54.87 0 1.69-.2 2.3-.54.5-.28.94-.7 1.06-1.25a17.4 17.4 0 0 0 2.67-.88 9.7 9.7 0 0 0 2.29-1.32c.56-.46 1.03-1.03 1.03-1.68Zm-5.97 3.4.68-.2c.04-.13.06-.27.06-.41 0-1.32-1.84-2.4-4.12-2.4-2.28 0-4.12 1.08-4.12 2.4 0 .15.02.3.07.44h-.01l.68.18c.07-.62.54-1.09 1.08-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.69.2 2.3.54.54.3 1 .77 1.08 1.37Zm-7.96-.34c-2.41-.79-4.4-2.02-4.4-3.06V7.33C1.29 6.85 4.72 5.03 9.3 5c4.6 0 8.19 1.85 9.02 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04.02-.08.02-.17.02-.25 0-.92-.63-1.65-1.45-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.82.47-1.45 1.2-1.45 2.12 0 .09 0 .18.02.27ZM.46 3.26c0-1.3 4.1-2.8 8.93-2.8 4.84 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.02-2.3c-4.43 0-8 1.84-8.83 2.31v-1.3Zm0 1.93v1.5A19.1 19.1 0 0 1 9.3 4.37a18.1 18.1 0 0 1 9.02 2.31V5.2A19.25 19.25 0 0 0 9.3 2.87 18.1 18.1 0 0 0 .47 5.2Zm11.91 8.16c0 .9-1.31 1.62-2.93 1.62s-2.93-.72-2.93-1.62c0-.9 1.31-1.63 2.93-1.63s2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M8.65 33.28c-.03-.02-.08-.05-.08-.13v-7.32a1.9 1.9 0 0 0-3.78 0v7.33c0 .07-.06.1-.08.12a3.64 3.64 0 1 0 3.94 0Zm-1.97 6.05a2.99 2.99 0 0 1-1.4-5.61l.04-.02s.13-.06.13-.27v-7.6a1.23 1.23 0 0 1 2.45 0v7.6c0 .21.15.27.15.27l.03.02a2.98 2.98 0 0 1-1.4 5.61Z"/>
      <path fill="currentColor" d="M7.27 34.95c-.04-.01-.1-.03-.1-.15v-7.5a.49.49 0 1 0-.98 0v7.51c0 .11-.07.14-.1.15a1.67 1.67 0 1 0 1.18 0Zm4.46-8.99h2.62a.27.27 0 0 0 0-.54h-2.62a.27.27 0 0 0 0 .54Zm0 1.71h3.51a.27.27 0 0 0 0-.53h-3.5a.27.27 0 0 0 0 .53Zm0 1.72h2.62a.27.27 0 0 0 0-.53h-2.62a.27.27 0 0 0 0 .53Zm3.51 1.71a.27.27 0 0 0 0-.53h-3.5a.27.27 0 0 0 0 .53h3.5Zm.44 4.79-1.54-2a.54.54 0 0 0-.43-.21.54.54 0 0 0-.43.22l-1.54 1.99c-.12.16-.15.33-.08.48.08.15.23.23.43.23h.36c.05 0 .1.04.1.09v2.19c0 .34.27.62.61.62h1c.35 0 .63-.28.63-.62v-2.2c0-.04.04-.08.09-.08h.45c.2 0 .35-.08.42-.23.08-.15.05-.32-.07-.48Zm-.6.18h-.2a.62.62 0 0 0-.62.62v2.19c0 .04-.04.08-.1.08h-1a.09.09 0 0 1-.09-.08v-2.2a.62.62 0 0 0-.62-.61h-.12c-.05 0-.03-.03-.03-.03l1.33-1.73s.04-.04.08-.04.08.04.08.04l1.33 1.73s.02.03-.04.03Z"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'HeatIcon', '0 0 80 80', {fontSize: 35})

export default icon
