const styles = (theme) => ({
  content: {
    marginTop: -20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: 500,
    height: 450,
    borderRadius: 5,
  },
  contentMobile: {
    width: '100%',
  },
  input: {
    marginTop: -12,
    width: '60%',
  },
  inputMobile: {
    width: '67%',
  },
  formLayout: {
    padding: '30px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid black',
  },
  formControlsContainer: {
    display: 'flex',
    marginTop: 25,
  },
  formControlsButton: {
    flex: 1,
    boxSizing: 'border-box',
    height: 36,
    padding: 6,
    border: `1px solid ${theme.scenes.textColor}`,
  },
})

export default styles
