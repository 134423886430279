import { CLOUD_FIRMWARE_UPDATE_CHANNEL } from '@app/constants/channels'
import { closeChannel, openChannel } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateChannel'
import openCable from '../cable'

const cloudFirmwareUpdateChannel = ({ request, response }) => {
  const cable = openCable()
  let subscription = cable.subscriptions.consumer.subscriptions.subscriptions
    .find((sub) => sub.identifier.includes('CloudFirmwareUpdateChannel'))
  if (!subscription) {
    subscription = cable.subscriptions.create(CLOUD_FIRMWARE_UPDATE_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(openChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeChannel())
      },
    })
  }
  return subscription
}

export default cloudFirmwareUpdateChannel
