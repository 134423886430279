import * as type from '@app/constants/scenes/roomsScenes/roomsScenes'

type State = {
  roomsScenes: Array<{ id: number, number: number, name: string }>,
}
const initialState = {
  roomsScenes: [],
}

export default function roomsScenes(state: State = initialState, action: Object): State {
  switch (action.type) {
  case type.RESET_ROOMS_SCENES:
    return initialState

  case type.UPDATE_ROOMS_SCENES: {
    return {
      ...state,
      roomsScenes: action.roomsScenes,
    }
  }
  default:
    return state
  }
}
