export const isStub = false
export const stubDeps = {
  error: false,
  delay: 5,
}

export const CHANNEL_PROGRAM_OPTIONS = isStub ? 'Stub::ProgramOptionsChannel' : 'ProgramOptionsChannel'

export const deps = isStub ? stubDeps : {}

export const NOTIFICATIONS_CHANNEL = 'NotificationsChannel'

export const ACTIVITIES_CHANNEL = 'ActivitiesChannel'

export const CHANNEL_GUARD_PANEL = 'Unit::ArmStatusChannel'

export const DEVICES_CHANNEL = 'Unit::DevicesChannel'
export const DEVICES_STATUS_CHANNEL = 'Unit::DeviceStatusChannel'
export const DEVICE_INFO_CHANNEL = 'Unit::DeviceInfoChannel'

export const INTERVIEW_CHANNEL_TWR = 'Unit::TWRInterviewChannel'
export const INTERVIEW_CHANNEL_ZWAVE = 'Unit::ZwaveInterviewChannel'
export const DELETE_TWR_DEVICE_CHANNEL = 'Unit::TWRRemoveChannel'
export const DELETE_ZWAVE_DEVICE_CHANNEL = 'Unit::ZwaveRemoveChannel'

export const FAILED_NODE_CHANNEL = 'Unit::ZwaveFailedNodeChannel'

export const SYNC_COUNTERS_CHANNEL = 'Unit::SyncCountersChannel'

export const CLOUD_FIRMWARE_UPDATE_CHANNEL = 'Unit::CloudFirmwareUpdateChannel'

export const SCENES_CHANNEL = 'Unit::ScenesChannel'

export const MEZZO_LIGHTS_CHANNEL = 'Unit::MezzoLightsChannel'
