import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4844 15.8986L17.0582 20.4725L18.4725 19.0582L1.41421 2L0 3.41421L3.99998 7.41419V11.4844L7.51561 15V18H12.4844V15.8986ZM16 11.4844L14.4493 13.0351L5.5382 4.12398C5.66803 4.07901 5.78188 4.04524 5.85996 4.02209L5.85997 4.02208C5.93375 4.0002 5.97557 3.9878 5.96873 3.98438H6.01561V0H7.98436V3.98438H12.0156V0H13.9844L14.0312 3.98438C14.5312 3.98438 14.9844 4.20312 15.3906 4.64062C15.7969 5.04688 16 5.5 16 6V11.4844Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'LostMainsPowerIcon')

export default icon
