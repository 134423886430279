import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
// @ts-ignore
import MyStatus from '@app/containers/MyStatus/MyStatus'
import Keyboard from './Keyboard/Keyboard'
import useStyles from './MySecurity.style'
import ButtonsBlock from './ButtonsBlock/ButtonsBlock'

interface Props {
  isMobile: boolean,
  isNCentral: boolean,
}

const MySecurity: React.FC<Props> = (props) => {
  const {
    isMobile,
    isNCentral,
  } = props
  const classes = useStyles()

  return (
    <>
      <Paper className={classNames(
        classes.main,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <ButtonsBlock isMobile={isMobile} />
        {isNCentral && <Keyboard isMobile={isMobile} />}
      </Paper>
      <Wrapper>
        <Typography className={classes.statusHeader}>
          {I18n.t('security.status')}
        </Typography>
        <MyStatus />
      </Wrapper>
    </>
  )
}

export default MySecurity
