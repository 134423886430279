import React from 'react'
import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { LightSliderArrow, MezzoLogo, MezzoLogoMobile } from 'icons'
import logoOnlinePath from '@app/images/ness-logo-nav.svg'
import logoOfflinePath from '@app/images/ness-logo-nav-offline.svg'

const theme = createTheme(adaptV4Theme({
  palette: {
    mode: 'dark',
    background: {
      default: '#303030',
    },
    primary: {
      light: '#a2cf6e',
      main: '#8bc34a',
      dark: '#618833',
      contrastText: '#000',
    },
    secondary: {
      light: '#c54849',
      main: '#b71c1c',
      dark: '#801213',
      contrastText: '#000',
    },
    error: {
      main: '#d50000',
    },
    shadow: {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  typography: {
    fontFamily: [
      '"roboto-v18-latin-regular"',
    ].join(','),
    color: '#fff',
    shadowColor: 'rgba(255, 255, 255, 0.75)',
  },
  overrides: {
    MuiCardMedia: {
      root: {
        color: '#d50000',
      },
    },
    MuiPaper: {
      root: { background: '#424242' },
    },
  },
  loginMargin: {
    bottomMargin: 50,
  },
  title: 'Smartlink',
  loginLogo: <MezzoLogo />,
  loginLogoMobile: <MezzoLogoMobile />,
  sliderArrow: <LightSliderArrow />,
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.nesscorporation.mezzo&hl=en_US',
  appStoreUrl: 'https://apps.apple.com/ru/app/mezzo/id1018131263',
  logos: {
    onlinePath: logoOnlinePath,
    offlinePath: logoOfflinePath,
  },
  footerButtons: {
    activeColor: '#000',
    selectedColor: '#d50000',
  },
  dashboard: {
    componentBackground: '#303030',
    shadowColor: 'rgba(0, 0, 0, 0.5)',
    hoverShadowColor: 'rgba(255, 255, 255, 0.5)',
  },
  energyGraph: {
    backgroundHover: 'rgba(69, 69, 74, 0.5)',
    chartColor: {
      dollar: '#282DB0',
      kWh: '#8bc34a',
    },
    greenGradient:
      'linear-gradient(#A9CF39 0%, rgba(147,175,64,0.79) 29.48%,rgba(132,207,57,0.66) 64.37%,rgba(124,218,67,0.25) 100%)',
    redGradient:
      'linear-gradient(#FF0000 0%, rgba(255, 0, 0, 0.48) 29.48%,rgba(255, 0, 0, 0.27) 64.37%,rgba(255, 0, 0, 0.26) 100%)',
    blueGradient:
      'linear-gradient(#282DB0 0%, rgba(40, 45, 176, 0.52) 29.48%,rgba(39, 43, 154, 0.56) 64.37%,rgba(105, 109, 206, 0.39) 100%)',
    decreasingArrow: '#87A923',
    increasingArrow: '#FF0000',
  },
  scenes: {
    textColor: '#fff',
    title: 'rgba(229, 229, 229, 0.67)',
    titleTop: '#E5E5E5',
    descriptionTop: 'rgba(255, 255, 255, 0.7)',
    disabledElement: 'rgba(255, 255, 255, 0.3)',
  },
  backgrounds: {
    mainBg: '#303030',
    transparentBg: 'transparent',
    loginBg: '#303030',
    componentBackground: '#424242',
    cardBackground: '#35353B',
    navigation: '#3E3F3E',
    popup: '#303030',
    hover: 'rgba(97, 136, 51, 0.5)',
  },
  borders: {
    main: 'rgba(255, 255, 255, 0.7)',
    secondary: '#35353B',
    disabled: 'rgba(255, 255, 255, 0.23)',
  },
  texts: {
    main: '#FFF',
    subText: 'rgba(255, 255, 255, 0.7)',
    halfVisible: 'rgba(255, 255, 255, 0.5)',
  },
  myStatus: {
    subtitle: '#C4C4C4',
  },
  myDevices: {
    outline: '#8BC34A',
    green: '#8bc34a',
    red: '#DE1F26',
  },
  roomsAndGroups: {
    infoPopupColor: '#6F6D6D',
    hoverColor: '#C4C4C4',
  },

  webView: {
    background: '#141414',
  },

  firmwareUpdate: {
    title: 'rgba(255, 255, 255, 0.7)',
    buttons: {
      main: 'rgba(255, 255, 255, 0.3)',
      hover: 'rgba(255, 255, 255, 0.5)',
      active: 'rgba(255, 255, 255, 0.2)',
      focus: '#35353B',
    },
    disabledElement: 'rgba(255, 255, 255, 0.5)',
  },
}))

export default theme
