import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 7.5H31.5V8V141V141.5H32H118.094H118.594V141V8V7.5H118.094H32ZM111.015 15.9643L115.219 12.1886V136.863L111.015 133.088V15.9643ZM36.7141 138.125L40.5595 134.672H109.505L113.351 138.125H36.7141ZM41.8602 131.891V17.1094H108.233V131.891H41.8602ZM40.4518 14.3281L36.6065 10.8751H113.408L109.562 14.3281H40.4518ZM34.8748 136.836V12.2603L39.0789 16.0355V133.061L34.8748 136.836Z" fill="currentColor" stroke="currentColor"/>
      <path d="M78.7721 23.9176C77.7851 22.9026 76.462 22.3438 75.0461 22.3438C73.6869 22.3438 72.4007 22.8659 71.4257 23.8135C70.4306 24.781 69.8715 26.0778 69.8521 27.4653C69.8324 28.853 70.3545 30.165 71.3218 31.1601C72.3082 32.1752 73.6315 32.7343 75.047 32.7343C76.4068 32.7343 77.693 32.2122 78.668 31.2642C80.7218 29.267 80.7686 25.9714 78.7721 23.9176ZM71.0395 27.4822C71.0547 26.4119 71.486 25.4115 72.2537 24.6649C72.8756 24.0603 73.6627 23.6846 74.5092 23.5711L74.3973 31.4901C73.555 31.3537 72.7811 30.9575 72.1735 30.3323C71.427 29.5647 71.0241 28.5525 71.0395 27.4822ZM77.8398 30.4127C77.2181 31.0177 76.431 31.3935 75.584 31.5069L75.6962 23.588C76.5387 23.7246 77.3128 24.1206 77.9206 24.7457C79.4607 26.3296 79.4248 28.8718 77.8398 30.4127Z" fill="currentColor"/>
      <path d="M78.6324 117.706C77.6616 116.78 76.3885 116.27 75.0479 116.27C73.613 116.27 72.277 116.842 71.2868 117.88C69.3104 119.953 69.3892 123.249 71.4623 125.225C72.433 126.15 73.7059 126.661 75.0468 126.661C76.4819 126.661 77.8172 126.088 78.8076 125.05C80.7843 122.976 80.7054 119.681 78.6324 117.706ZM72.1462 118.7C72.7489 118.068 73.5187 117.663 74.3597 117.52L74.5478 125.438C73.7002 125.333 72.9088 124.963 72.2815 124.366C70.6824 122.841 70.6215 120.299 72.1462 118.7ZM77.9482 124.231C77.3453 124.863 76.5757 125.267 75.7343 125.411L75.5465 117.492C76.3943 117.597 77.1859 117.966 77.8127 118.565C79.4122 120.089 79.4727 122.631 77.9482 124.231Z" fill="currentColor"/>
      <path d="M84.4829 105.296V43.844H81.4368V38.9609H68.4V43.844H65.4829V105.296H84.4829V105.296ZM70.1093 40.7513H79.7576V47.4308H70.1093V40.7513ZM70.0888 49.1463H79.7493L81.543 75.7682H68.2952L70.0888 49.1463ZM68.254 77.6374H81.6873V103.021H68.254V77.6374Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'LightSwitchOn')

export default icon
