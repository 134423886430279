import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import { ADD_TWR_DEVICE, ADD_ZWAVE_DEVICE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import { Add, Ness2WayIcon, NessZWaveIcon } from 'icons'
import { Status } from '@app/lib/guardPanel/guardPanel'
import useStyles from './AddButtons.style'

type Props = {
  toggleChooseDevice: () => void,
  isChoosingWhichDeviceToAdd: boolean,
  isMobile: boolean,
  guardPanelStatus: string,
}

const AddButtons: React.FC<Props> = (props) => {
  const {
    toggleChooseDevice,
    isChoosingWhichDeviceToAdd,
    isMobile,
    guardPanelStatus,
  } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const [isArmedErrorPopupOpen, setIsArmedErrorPopupOpen] = useState<boolean>(false)

  const addZwaveDevice = () => {
    if (guardPanelStatus !== Status.SIMPLE_DISARMED) {
      setIsArmedErrorPopupOpen(true)
    } else {
      navigate(ADD_ZWAVE_DEVICE)
    }
  }

  const addTwrDevice = () => {
    if (guardPanelStatus !== Status.SIMPLE_DISARMED) {
      setIsArmedErrorPopupOpen(true)
    } else {
      navigate(ADD_TWR_DEVICE)
    }
  }

  return (
    <Wrapper>
      <Wrapper className={classes.caption}>
        <Typography className={classNames(
          classes.textHint,
          classes.zWaveHint,
          isChoosingWhichDeviceToAdd && classes.addHint,
          isMobile && classes.zWaveHintMobile,
        )}
        >
          {isMobile ? I18n.t('my_devices.z_wave') : I18n.t('my_devices.z_wave_expanded')}
        </Typography>
        <IconButton
          aria-label="addZwaveDevice"
          className={classNames(
            classes.addButton,
            isChoosingWhichDeviceToAdd && classes.addZWaveButton,
          )}
          onClick={addZwaveDevice}
          size="large"
        >
          <NessZWaveIcon className={classNames(
            classes.icon,
            isChoosingWhichDeviceToAdd && classes.activeIcon,
          )}
          />
        </IconButton>
      </Wrapper>
      <Wrapper className={classes.caption}>
        <Typography className={classNames(
          classes.textHint,
          classes.twrHint,
          isChoosingWhichDeviceToAdd && classes.addHint,
          isMobile && classes.twrHintMobile,
        )}
        >
          {isMobile ? I18n.t('my_devices.twr') : I18n.t('my_devices.twr_expanded')}
        </Typography>
        <IconButton
          aria-label="addTwrDevice"
          className={classNames(
            classes.addButton,
            isChoosingWhichDeviceToAdd && classes.addTwrButton,
          )}
          onClick={addTwrDevice}
          size="large"
        >
          <Ness2WayIcon className={classNames(
            classes.icon,
            isChoosingWhichDeviceToAdd && classes.activeIcon,
          )}
          />
        </IconButton>
      </Wrapper>
      <IconButton
        aria-label="add button"
        className={classNames(classes.addButton, classes.initialAddButton)}
        onClick={toggleChooseDevice}
        size="large"
      >
        <Add />
      </IconButton>
      {isArmedErrorPopupOpen && (
        <SmallErrorPopup
          open
          onClick={() => setIsArmedErrorPopupOpen(false)}
          buttonName="buttons.okay"
          title="my_devices.error"
          warningText="my_devices.armed_error"
        />
      )}
    </Wrapper>
  )
}

export default AddButtons
