import { createLogic } from 'redux-logic'
import { SYNC_EVENTS } from '@app/constants/events'
import { updateEvents } from '@app/actions/events'
import Api from '@app/api/fetchEvents'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchEventsLogic = createLogic({
  type: SYNC_EVENTS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const {
      events: {
        filterByType, filterFromDate, filterToDate, filterByText, currentPage,
      },
    } = store.getState()
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_EVENTS))
    Api.fetchEvents(token, filterByType, filterFromDate, filterToDate, filterByText, currentPage)
      .then((response) => {
        const { data: { events, total, pages_count: countPages } } = response
        dispatch(updateEvents(events, total, countPages))
        dispatch(apiRequestDone(SYNC_EVENTS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchEvents failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchEventsLogic
