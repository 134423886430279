import * as type from '@app/constants/pillsReminders/pillsReminders'

export const fetchPillsReminders = () => ({
  type: type.FETCH_PILLS_REMINDERS,
})

export const setPillsReminders = (pillsReminders) => ({
  type: type.SET_PILLS_REMINDERS,
  pillsReminders,
})

export const deletePillsReminder = (id) => ({
  type: type.DELETE_PILLS_REMINDER,
  id,
})

export const fetchPillsEvents = (date) => ({
  type: type.FETCH_PILLS_EVENTS,
  date,
})

export const setPillsEvents = (pillsEvents) => ({
  type: type.SET_PILLS_EVENTS,
  pillsEvents,
})

export const createPillsReminder = (reminderData) => ({
  type: type.CREATE_PILLS_REMINDER,
  reminderData,
})

export const patchPillsReminder = (id, reminderData) => ({
  type: type.PATCH_PILLS_REMINDER,
  id,
  reminderData,
})
