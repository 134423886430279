import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { saveActionColorType } from '@app/actions/scenes/currentSceneCreating'
import { withStyles } from '@app/components/styles'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectRoomActionColorType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/RoomActions/SelectRoomActionColorType/SelectRoomActionColorType'
import SelectRoomActionColorTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/RoomActions/SelectRoomActionColorType/SelectRoomActionColorType'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectRoomActionColorTypeIOS) : withStyles(SelectRoomActionColorType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { stateDescription, actionType } = state.scenes.currentSceneCreating.currentAction
  const { isChangeActionMode, isChangeActionStateMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    actionType,
    stateDescription,
    isChangeActionMode,
    isChangeActionStateMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveActionColorType,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectRoomActionColorTypeEnhanced = enhance

export default withRouter(SelectRoomActionColorTypeEnhanced)
