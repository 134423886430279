import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import { isIOS } from 'react-device-detect'
import { PATCH_SCENE, PATCH_MOBILE_SCENE } from '@app/constants/scenes/scenesList'
import {
  patchMobileSceneImpossible,
  clearCurrentSceneAll,
} from '@app/actions/scenes/scenesList'
import { saveChangesetAction } from '@app/actions/scenes/editScene'
import Api from '@app/api/scenes/patchScene'
import handleErrors from '@app/helpers/handleErrors'
import { SCENES } from '@app/constants/routes'
import getMobileJson from '@app/api/scenes/getMobileJson'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const useBackendPatchApi = (action, dispatch, done) => {
  store.dispatch(apiRequestSent(PATCH_SCENE))
  Api.patchScene(action.token, action.id, action.data)
    .then(() => {
      store.dispatch(apiRequestDone(PATCH_SCENE))
      history.push(SCENES)
    })
    .catch((error) => {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('patchScene failed without error')
      }
    })
    .then(() => done())
}

const patchScenesLogic = createLogic({
  type: PATCH_SCENE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { scenes: { scenesList: { currentScene: { id, name } } } } = getState()
    const data = { name, status: 'commit_to_mezzo' }
    next({
      ...action,
      token,
      id,
      data,
      name,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent())
    if (isIOS && isWebView) {
      getMobileJson(action.token, action.id)
        .then((result) => {
          const { data: { scene } } = result
          const newInfo = { ...scene.info, name: action.name }
          // eslint-disable-next-line max-len, no-alert
          const promptValue = prompt(`putScene&${JSON.stringify(newInfo)}&${JSON.stringify(scene.actions)}&${JSON.stringify(scene.conditions)}`)
          if (promptValue === 'Invalid JS Request' || !JSON.parse(promptValue).status) {
            dispatch(patchMobileSceneImpossible())
            useBackendPatchApi(action, dispatch, done)
          } else {
            const { response } = JSON.parse(promptValue)
            dispatch(apiRequestSent(PATCH_MOBILE_SCENE))
            const dataForMobile = {
              name: action.name, status: 'commit_to_mezzo', number: response,
            }
            Api.patchScene(action.token, action.id, dataForMobile)
              .then(() => {
                store.dispatch(apiRequestDone(PATCH_MOBILE_SCENE))
                history.push(SCENES)
                store.dispatch(clearCurrentSceneAll())
                store.dispatch(saveChangesetAction(''))
              })
              .catch((backendError) => {
                if (backendError) {
                  const currentError = handleErrors(backendError)
                  store.dispatch(apiRequestFailed(currentError))
                } else {
                  throw new Error('patchScene failed without error')
                }
              })
              .then(() => done())
          }
        })
        .catch((error) => {
          if (error) {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          } else {
            throw new Error('getMobileJson failed without error')
          }
        })
        .then(() => done())
    } else {
      useBackendPatchApi(action, dispatch, done)
    }
  },
})

export default patchScenesLogic
