const calculateGroupActionType = (stateDescription, actionDelay, actionDuration) => {
  if (Object.keys(actionDelay).length) {
    if (Object.keys(actionDuration).length) {
      return 'ControlGroup'
    }
    return 'ControlGroup'
  }
  return 'ControlGroup'
}

export default calculateGroupActionType
