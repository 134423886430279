import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import classNames from 'classnames'
import {
  Paper, Tab, Tabs, Typography,
} from '@mui/material'
import { SELECT_TRIGGERING_DEVICE_TYPE } from '@app/constants/routes'
import ZWaveDevicesList
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/ZWaveDevices/ZWaveDevicesList'
import ZWaveDevicesByGroupList
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/ZWaveDevices/ZWaveDevicesByGroupList'
import ZWaveDevicesByRoomsList
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/ZWaveDevices/ZWaveDevicesByRoomsList'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import { chooseTab } from '@app/actions/scenes/devicesTabs'
import useStyles from './SelectTriggeringZWaveDevice.style'

interface Props {
  currentTab: number,
  isMobile: boolean,
}

const SelectTriggeringZWaveDevice: React.FC<Props> = (props) => {
  const { currentTab, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onBackClick = () => {
    navigate(SELECT_TRIGGERING_DEVICE_TYPE)
  }

  const handleChange = (event: any, value: number) => {
    dispatch(chooseTab(value))
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.select_triggering_zwave')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        <Wrapper>
          <Tabs
            className={classes.deviceTabs}
            value={currentTab}
            indicatorColor="primary"
            onChange={handleChange}
            variant="fullWidth"
          >
            <Tab
              label="By devices"
            />
            <Tab
              label="By room"
            />
            <Tab
              label="By group"
            />
          </Tabs>
          {currentTab === 0 && (
            // @ts-ignore
            <ZWaveDevicesList />
          )}
          {currentTab === 1 && (
            // @ts-ignore
            <ZWaveDevicesByRoomsList />
          )}
          {currentTab === 2 && (
            // @ts-ignore
            <ZWaveDevicesByGroupList />
          )}
        </Wrapper>
      </Wrapper>
    </Paper>
  )
}

export default SelectTriggeringZWaveDevice
