import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import getBodyDataToBackend from '@app/helpers/scenes/getBodyDataToBackend'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import { CHECK_POST } from '@app/constants/scenes/pushNotifications/apiChecking'
import { CREATE_ACTION_SELECTED, EDIT_ACTION } from '@app/constants/routes'
import { openErrorPopup, checkPostFailed } from '@app/actions/scenes/pushNotifications/apiChecking'
import { saveActionDeviceStateInfo, saveActionStateDescription } from '@app/actions/scenes/currentSceneCreating'
import Api from '@app/api/scenes/pushNotifications/checkPost'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const checkPostLogic = createLogic({
  type: CHECK_POST,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const {
      scenes: {
        currentSceneCreating: {
          currentAction: {
            requestBody, requestHeader, requestUrl, requestType,
          },
          isEditActionMode,
        },
      },
    } = getState()
    const bodyData = getBodyDataToBackend(requestBody)
    const headerData = getBodyDataToBackend(requestHeader)
    next({
      ...action,
      bodyData,
      headerData,
      requestBody,
      requestHeader,
      requestUrl,
      requestType,
      isEditActionMode,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(CHECK_POST))
    Api.checkPost(action.requestUrl, action.bodyData, action.headerData)
      .then(() => {
        dispatch(apiRequestDone(CHECK_POST))
        dispatch(saveActionStateDescription('execute Web request'))
        const dataInfo = getActionDeviceStateInfo('WebRequest',
          '', 0, {}, 'none',
          {}, '', '', '', '',
          '', '', [], '', action.requestBody, action.requestHeader, action.requestUrl, action.requestType)
        dispatch(saveActionDeviceStateInfo(dataInfo))
        if (action.isEditActionMode) {
          history.push(EDIT_ACTION)
        } else {
          history.push(CREATE_ACTION_SELECTED)
        }
      })
      .catch((error) => {
        if (error) {
          if (error.message === 'Network Error') {
            const errorText = error.message
            dispatch(checkPostFailed(errorText, ''))
            dispatch(apiRequestFailed(error.message))
            dispatch(openErrorPopup())
          } else {
            const errorText = error.response.data
            const errorStatus = error.response.status
            dispatch(apiRequestFailed(error))
            dispatch(checkPostFailed(errorText, errorStatus))
            dispatch(openErrorPopup())
          }
        } else {
          throw new Error('checkPost failed without error')
        }
      })
      .then(() => done())
  },
})

export default checkPostLogic
