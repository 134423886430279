import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0C9.53125 0 7.40625 0.875 5.625 2.625C3.875 4.375 3 6.5 3 9H0L3.89062 12.8906L3.98438 13.0312L8.01562 9H5.01562C5.01562 7.0625 5.6875 5.42188 7.03125 4.07812C8.40625 2.70312 10.0625 2.01562 12 2.01562C13.9375 2.01562 15.5938 2.70312 16.9688 4.07812C18.3438 5.42188 19.0312 7.0625 19.0312 9C19.0312 10.9375 18.3438 12.5938 16.9688 13.9688C15.5938 15.3125 13.9375 15.9844 12 15.9844C10.0938 15.9844 8.45312 15.2969 7.07812 13.9219L5.67188 15.375C7.42188 17.125 9.53125 18 12 18C14.4688 18 16.5781 17.125 18.3281 15.375C20.1094 13.625 21 11.5 21 9C21 6.5 20.1094 4.375 18.3281 2.625C16.5781 0.875 14.4688 0 12 0ZM15.44 6L11.2213 9.96853L9.54133 8.3951L9 8.91958L11.2213 11L16 6.52448L15.44 6Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'RestoredAndReadyIcon')

export default icon
