import React from 'react'
import {
  Paper,
  Typography,
  List,
  ListItem,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import { SELECT_ACTION_TYPE, SELECT_VIRTUAL_SWITCH_ACTION_VALUE } from '@app/constants/routes'

class SelectVirtualSwitchAction extends React.Component {
  onBackClick= () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_ACTION_TYPE)
  }

  onSwitchClick=(currentSwitch) => {
    const {
      navigate,
      saveActionDeviceNumber,
      saveActionDescription,
    } = this.props
    saveActionDeviceNumber(currentSwitch.number)
    saveActionDescription(currentSwitch.number.toString())
    navigate(SELECT_VIRTUAL_SWITCH_ACTION_VALUE)
  }

  render() {
    const {
      classes,
      isMobile,
      virtualSwitches,
    } = this.props
    return (
      <Paper className={classes.wrapper}>
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_virtual_switch')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <List className={classes.root}>
            {virtualSwitches.map((currentSwitch) => (
              <ListItem className={classes.listItem} key={`virtual-switches-${currentSwitch.number}`}>
                <Wrapper
                  className={classes.currentSwitch}
                  onClick={() => {
                    this.onSwitchClick(currentSwitch)
                  }}
                >
                  <Typography className={classes.switchTitle} variant="h6" component="h2">
                    {`${I18n.t('scene_web.virtual_switch')} ${currentSwitch.number}`}
                  </Typography>
                </Wrapper>
              </ListItem>
            ))}
          </List>
        </Wrapper>
      </Paper>
    )
  }
}

SelectVirtualSwitchAction.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionDeviceNumber: PropTypes.func.isRequired,
  saveActionDescription: PropTypes.func.isRequired,
  virtualSwitches: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  })).isRequired,
}

SelectVirtualSwitchAction.displayName = 'SelectVirtualSwitchActionIOS'

export default SelectVirtualSwitchAction
