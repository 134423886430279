import React from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SELECT_TRIGGERING_DEVICE, SELECT_TRIGGERING_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './Group.style'

class Group extends React.Component {
  onGroupClick = (group) => {
    const {
      navigate,
      deviceType,
      saveTriggeringDeviceName,
      saveTriggeringDeviceNumber,
      saveObservingDeviceNumber,
      saveObservingDeviceName,
      saveConditionStateDescription,
    } = this.props
    if (deviceType === 'triggering') {
      saveTriggeringDeviceNumber(group.number)
      saveTriggeringDeviceName(group.name)
      navigate(SELECT_TRIGGERING_STATE)
    } else {
      saveConditionStateDescription(`exceeded Timeout value, while observing ${group.name}`)
      saveObservingDeviceNumber(group.number)
      saveObservingDeviceName(group.name)
      navigate(SELECT_TRIGGERING_DEVICE)
    }
  }

  render() {
    const {
      classes,
      group,
    } = this.props
    return (
      <Wrapper
        className={classes.group}
        onClick={() => {
          this.onGroupClick(group)
        }}
      >
        <Typography className={classes.title} variant="h6" component="h2">
          {group.name}
        </Typography>
      </Wrapper>
    )
  }
}

Group.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  saveTriggeringDeviceName: PropTypes.func.isRequired,
  saveTriggeringDeviceNumber: PropTypes.func.isRequired,
  saveObservingDeviceNumber: PropTypes.func.isRequired,
  saveObservingDeviceName: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
}

export default withStyles(styles)(Group)
