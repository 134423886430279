import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M98.9141 31.9579C98.9141 18.7263 88.1877 8 74.9562 8C61.7246 8 50.9983 18.7263 50.9983 31.9579V76.0254C25.1927 97.7361 40.2116 141 74.9562 141C109.694 141 124.744 97.7382 98.9141 76.0242V31.9579ZM73.0693 13.5987C63.7635 14.5438 56.5017 22.4028 56.5017 31.9579V78.703C31.9112 96.5946 44.5709 135.497 74.9562 135.497C105.341 135.497 118.02 96.5946 93.4106 78.703V31.9579C93.4106 21.7658 85.1483 13.5034 74.9562 13.5034C74.3192 13.5034 73.6897 13.5357 73.0693 13.5987Z" fill="currentColor"/>
            <path d="M88.4372 44.0547H75.6483M88.4372 58.9731H75.6483M88.4372 72.8336H75.6483M48.5571 107.563C60.3311 97.773 64.1604 98.2344 67.4637 99.6369C70.767 101.039 73.6552 103.383 77.383 105.432C81.1108 107.48 85.6875 109.242 100.525 100.523" stroke="currentColor" strokeWidth="3"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwTempSensorIcon')

export default icon
