export const mezzoLightsColors = [
  {
    color: 'rgba(11,252,21,1)',
    value: {
      red: 0,
      blue: 0,
      green: 1,
    },
  },
  {
    color: 'rgba(0,255,224,1)',
    value: {
      red: 0,
      blue: 1,
      green: 1,
    },
  },
  {
    color: 'rgba(0,0,255,1)',
    value: {
      red: 0,
      blue: 1,
      green: 0,
    },
  },
  {
    color: 'none',
    value: {
      red: 0,
      blue: 0,
      green: 0,
    },
  },
]

export const lightsInfo = [
  { color: '#0BFC15', value: 'rgba(11,252,21,1)', off: false },
  { color: '#0000FF', value: 'rgba(0,0,255,1)', off: false },
  { color: '#00FFE0', value: 'rgba(0,255,224,1)', off: false },
  { color: '#797979', value: 'none', off: true },
]
