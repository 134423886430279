import {
  accessDeniedError,
  authorizationError,
  firstStatusError,
  secondStatusError,
} from '@app/lib/RefreshToken/refreshTokenStatusError'

const authorizationErrorsChecker = (message: string | number) => {
  if ((message === 401)
    || (message === 403)
    || (message === firstStatusError)
    || (message === secondStatusError)
    || (message === authorizationError)
    || (message === accessDeniedError)) {
    return true
  } return false
}

export default authorizationErrorsChecker
