import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/billing_periods`

const resetBillingPeriods = (accessToken) => Axios.delete(ENDPOINT, {
  headers: { 'Access-Token': accessToken },
})

export default {
  resetBillingPeriods,
}
