import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  device: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 36,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 100,
    width: 100,
    cursor: 'pointer',
    padding: '15px 5px 0px 5px',
  },
  title: {
    fontSize: '12px',
    marginBottom: 11,
    textAlign: 'center',
    padding: '0 16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: 14,
  },
}))

export default useStyles
