import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import getActionDataToBackend from '@app/helpers/scenes/getActionDataToBackend'
import { PATCH_ACTION } from '@app/constants/scenes/currentSceneCreating'
import Api from '@app/api/scenes/patchAction'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchActionLogic = createLogic({
  type: PATCH_ACTION,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const {
      scenes: {
        currentSceneCreating:
      {
        currentAction: {
          actionType, id, stateDescription, actionDeviceStateInfo,
        }, screenName,
      },
      },
    } = getState()
    const data = getActionDataToBackend(actionType, stateDescription, actionDeviceStateInfo)
    next({
      ...action,
      token,
      id,
      data,
      screenName,
    })
  },
  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_ACTION))
    Api.patchAction(action.token, action.id, action.data)
      .then(() => {
        dispatch(apiRequestDone(PATCH_ACTION))
        history.push(action.screenName)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchAction failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchActionLogic
