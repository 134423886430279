import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, TextField, Typography } from '@mui/material'
import { TextEncoder } from 'text-encoding'
import classNames from 'classnames'
import getEditedConditionInfo from '@app/helpers/scenes/getEditedConditionInfo'
import getEditedActionInfo from '@app/helpers/scenes/getEditedActionInfo'
import sceneReviewPrefixChanger from '@app/helpers/scenes/sceneReviewPrefixChanger'
import countCompleteScenes from '@app/helpers/scenes/countCompleteScenes'
import {
  MAX_NUMBER_OF_ACTIONS,
  MAX_NUMBER_OF_CONDITIONS,
} from '@app/lib/Scenes/scenesRestrictions'
import {
  EDIT_ACTION, EDIT_CONDITION, SELECT_ACTION_TYPE, SELECT_CONDITION_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Info from '@app/components/Common/Info/Info'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import { IActionFromBackend, IConditionFromBackend, IScene } from '@app/@types/scenes/scenes'
import { useDispatch } from '@app/store'
import {
  activateAdditionalActionMode,
  activateAdditionalConditionMode,
  activateEditActionMode,
  activateEditConditionMode,
  clearCurrentActionInfo,
  clearCurrentConditionInfo,
  clearInitialActionInfo,
  clearInitialConditionInfo,
  deactivateCreateActionMode,
  deactivateCreateConditionMode,
  saveActionId,
  saveConditionId,
  saveConditionNumber,
  saveEditedAction,
  saveEditedCondition,
  saveReviewSceneConditionTypes,
} from '@app/actions/scenes/currentSceneCreating'
import { deleteAction, deleteCondition } from '@app/actions/scenes/currentSceneConditionsActions'
import getButtonDisableValue from '@app/helpers/scenes/getButtonDisableValue'
import { saveCurrentSceneName } from '@app/actions/scenes/scenesList'
import useStyles from './SceneReviewBase.style'
import RenderAction from './RenderAction'
import RenderCondition from './RenderCondition'

interface Props {
  conditions: Array<IConditionFromBackend>,
  actions: Array<IActionFromBackend>,
  isMobile: boolean,
  sceneName: string,
  sceneStatus: string,
  scenes: Array<IScene>,
  maxNumberOfScenes: number,
  maxNumberOfBytesSceneName: number,
  initialSceneId: number,
  handleChangeNameInput: (event: React.ChangeEvent<HTMLInputElement>) => void,
  changeRadio: (event: React.ChangeEvent<HTMLInputElement>, condition: IConditionFromBackend, index: number) => void,
  onSaveClick: () => void,
}

const SceneReviewBase: React.FC<Props> = (props) => {
  const {
    conditions,
    actions,
    isMobile,
    sceneName,
    sceneStatus,
    scenes,
    maxNumberOfScenes,
    maxNumberOfBytesSceneName,
    handleChangeNameInput,
    changeRadio,
    onSaveClick,
    initialSceneId,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const [isTooManyConditionsPopupOpen, changeTooManyConditionsPopupOpen] = useState<boolean>(false)
  const [isTooManyActionsPopupOpen, changeTooManyActionsPopupOpen] = useState<boolean>(false)
  const [isTooManyScenesPopupOpen, changeTooManyScenesPopupOpen] = useState<boolean>(false)
  const [isSceneSaveInProgress, changeSceneSaveInProgress] = useState<boolean>(false)
  const [isButtonDisabled, changeButtonDisable] = useState<boolean>(false)

  useEffect(() => {
    dispatch(deactivateCreateConditionMode())
    dispatch(deactivateCreateActionMode())
    dispatch(clearCurrentConditionInfo())
    dispatch(clearInitialConditionInfo())
    dispatch(clearCurrentActionInfo())
    dispatch(clearInitialActionInfo())
  }, [])

  useEffect(() => {
    changeButtonDisable(getButtonDisableValue(conditions, actions, sceneName, maxNumberOfBytesSceneName))
  }, [conditions, actions, sceneName, maxNumberOfBytesSceneName])

  const changeTooManyConditionsPopup = () => {
    changeTooManyConditionsPopupOpen(!isTooManyConditionsPopupOpen)
  }

  const changeTooManyActionsPopup = () => {
    changeTooManyActionsPopupOpen(!isTooManyActionsPopupOpen)
  }

  const changeTooManyScenesPopup = () => {
    changeTooManyScenesPopupOpen(!isTooManyScenesPopupOpen)
  }

  const onEditConditionClick = (condition: IConditionFromBackend) => {
    const conditionInfo = getEditedConditionInfo(condition)
    dispatch(activateEditConditionMode())
    dispatch(saveEditedCondition(conditionInfo))
    navigate(EDIT_CONDITION)
  }

  const onEditActionClick = (action: IActionFromBackend) => {
    const actionInfo = getEditedActionInfo(action)
    dispatch(activateEditActionMode())
    dispatch(saveEditedAction(actionInfo))
    navigate(EDIT_ACTION)
  }

  const addCondition = () => {
    if (conditions.length >= MAX_NUMBER_OF_CONDITIONS) {
      changeTooManyConditionsPopup()
    } else {
      dispatch(saveReviewSceneConditionTypes(conditions.map((condition) => condition.type)))
      dispatch(activateAdditionalConditionMode())
      if (conditions.length) {
        dispatch(saveConditionNumber(conditions.length))
      }
      navigate(SELECT_CONDITION_TYPE)
    }
  }

  const addAction = () => {
    if (actions.length >= MAX_NUMBER_OF_ACTIONS) {
      changeTooManyActionsPopup()
    } else {
      dispatch(activateAdditionalActionMode())
      navigate(SELECT_ACTION_TYPE)
    }
  }

  const onDeleteConditionClick = (condition: IConditionFromBackend) => {
    dispatch(saveConditionId(condition.id))
    dispatch(deleteCondition())
  }

  const onDeleteActionClick = (action: IActionFromBackend) => {
    dispatch(saveActionId(action.id))
    dispatch(deleteAction())
  }

  const renderTooManyPopup = (popupState: boolean, type: string, popupChange: () => void) => (
    <>
      <Info
        open={popupState}
        title="my_users.info"
        warningText={`errors.${type}_maximum_error`}
        onClick={popupChange}
        buttonName="buttons.okay"
      />
    </>
  )

  const onSubmitClick = () => {
    const savedScenesLength = countCompleteScenes(scenes)
    changeSceneSaveInProgress(true)
    dispatch(saveCurrentSceneName(sceneName.trimEnd()))
    if (savedScenesLength >= maxNumberOfScenes && sceneStatus !== 'complete') {
      changeTooManyScenesPopup()
    } else {
      onSaveClick()
    }
  }

  const getHelperText = (sceneNameBytes: boolean, isUsedName: boolean) => {
    if (sceneNameBytes) {
      return I18n.t('errors.scene_name_length_error')
    }
    if (isUsedName) {
      return I18n.t('errors.scene_name_dublication')
    }
    return ''
  }

  const sceneNameBytes = ((new TextEncoder('utf-8').encode(sceneName)).length) > maxNumberOfBytesSceneName
  const isUsedName = scenes.filter((scene) => scene.id !== initialSceneId).some((scene) => scene.name === sceneName.trimEnd())
  const helperText = getHelperText(sceneNameBytes, isUsedName)
  return (
    <Wrapper className={classes.main}>
      <Wrapper>
        <TextField
          variant="standard"
          onChange={handleChangeNameInput}
          id="sceneName"
          type="text"
          value={sceneName || ''}
          className={classNames(classes.textField, sceneNameBytes && classes.textFieldWithError)}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={helperText}
          error={sceneNameBytes || isUsedName}
        />
        <Wrapper className={classes.block}>
          <Wrapper className={classes.titleWrapper}>
            <Typography className={classes.title}>Conditions</Typography>
            <Typography className={classes.condition}>
              {conditions.length ? sceneReviewPrefixChanger(conditions[0].scene_prefix) : 'When...'}
            </Typography>
          </Wrapper>
          {conditions.map((condition, index, array) => (
            <RenderCondition
              key={condition.id}
              index={index}
              conditions={array}
              onEditConditionClick={() => {
                onEditConditionClick(condition)
              }}
              onDeleteConditionClick={() => {
                onDeleteConditionClick(condition)
              }}
              changeRadio={changeRadio}
            />
          ))}
          <Wrapper className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => {
                addCondition()
              }}
            >
              {I18n.t('scene_web.add_more_conditions')}
            </Button>
          </Wrapper>
        </Wrapper>
        <Wrapper className={classes.block}>
          <Wrapper className={classes.titleWrapper}>
            <Typography className={classes.title}>Actions</Typography>
            <Typography className={classes.condition}>then make...</Typography>
          </Wrapper>
          {actions.map((action, index, actionsArray) => (
            <RenderAction
              key={action.id}
              index={index}
              actions={actionsArray}
              onEditActionClick={onEditActionClick}
              onDeleteActionClick={() => {
                onDeleteActionClick(action)
              }}
            />
          ))}
        </Wrapper>
        <Wrapper className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            color="primary"
            onClick={() => {
              addAction()
            }}
          >
            {I18n.t('scene_web.add_more_actions')}
          </Button>
        </Wrapper>
      </Wrapper>
      <Wrapper className={classNames(classes.controlButtonsWrapper, isMobile && classes.controlButtonsWrapperMobile)}>
        <Button
          className={classes.controlButton}
          disabled={isSceneSaveInProgress || isButtonDisabled || isUsedName}
          onClick={onSubmitClick}
        >
          {isSceneSaveInProgress ? (
            <DotsLoading
              className={classes.armLoad}
              props={{
                width: 40, circleWidth: 12, circleHeight: 12, circleColor: '#fff',
              }}
            />
          ) : (I18n.t('buttons.submit'))}
        </Button>
      </Wrapper>
      { renderTooManyPopup(isTooManyConditionsPopupOpen, 'conditions', changeTooManyConditionsPopup) }
      { renderTooManyPopup(isTooManyActionsPopupOpen, 'actions', changeTooManyActionsPopup) }
      { renderTooManyPopup(isTooManyScenesPopupOpen, 'scenes', changeTooManyScenesPopup) }
    </Wrapper>
  )
}

export default SceneReviewBase
