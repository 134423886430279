export const SYNC_ROOMS_STATS = '@@roomsStats/sync'

export const UPDATE_ROOMS_STATS = '@@roomsStats/update'

export const OPEN_ROOM_STATS_POPUP = '@@roomsStats/openRoomStatsPopup'
export const CLOSE_ROOM_STATS_POPUP = '@@roomsStats/closeRoomStatsPopup'

export const SELECT_CURRENT_ROOM_ID_AND_NAME = '@@roomsStats/selectCurrentRoomIdAndName'
export const CLEAR_CURRENT_ROOM_ID_AND_NAME = '@@roomsStats/clearCurrentRoomIdAndName'

export const SYNC_CURRENT_ROOM_STATS = '@@roomsStats/syncCurrentRoomStats'

export const UPDATE_CURRENT_ROOM_STATS = '@@roomsStats/updateCurrentRoomStats'
export const CLEAR_CURRENT_ROOM_STATS = '@@roomsStats/clearCurrentRoomStats'

export const RESET_CURRENT_ROOM_COUNTERS = '@@roomsStats/resetCurrentRoomCounters'
export const RESET_CURRENT_ROOM_COUNTERS_CANCEL = '@@roomsStats/resetCurrentRoomCountersCancel'
