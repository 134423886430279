import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { isIOS } from 'react-device-detect'
import {
  List, Fab, Paper, Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PILLS_REMINDER_EDIT, PILLS_REMINDER_NEW, PILLS_REMINDERS } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { Add, RemindersHistory } from 'icons'
import { fetchPillsReminders, deletePillsReminder } from '@app/actions/pillsReminders/pillsReminders'
import TwoButtonsPopup from '@app/components/Common/TwoButtonsPopup/TwoButtonsPopup'
import ReminderCard from './ReminderCard/ReminderCard'
import RemindersTabs from './RemindersTabs/RemindersTabs'
import useStyles from './PillsReminders.style'

const PillsReminders = (): Node => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const { reminders } = useSelector((state) => state.pillsReminders)
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false)
  const [currentReminderId, setCurrentReminderId] = useState(null)

  useEffect(() => {
    if (isIOS && isWebView) {
      // eslint-disable-next-line no-alert
      prompt('showTopBar')
    }
    dispatch(fetchPillsReminders())
  }, [])

  const renderRemindersList = (remindersArray) => (
    <List className={classes.remindersList}>
      {remindersArray.map((reminder) => (
        <ReminderCard
          key={reminder.id}
          reminder={reminder}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </List>
  )

  const renderWithoutReminders = () => (
    <Wrapper className={classes.emptyReminders}>
      <RemindersHistory />
      <Typography className={classes.emptyText}>{I18n.t('my_reminders.no_reminders')}</Typography>
    </Wrapper>
  )

  const hideIOSToolbarInWebView = () => {
    if (isIOS && isWebView) {
    // eslint-disable-next-line no-alert
      prompt('hideTopBar')
    }
  }

  const onCreate = () => {
    hideIOSToolbarInWebView()
    navigate(PILLS_REMINDER_NEW)
  }

  const onEdit = (reminder) => {
    hideIOSToolbarInWebView()
    navigate(PILLS_REMINDER_EDIT.replace(':id', reminder.id))
  }

  const onDelete = (reminderId) => {
    setDeletePopupOpen(!isDeletePopupOpen)
    setCurrentReminderId(reminderId)
  }

  const onDeleteSubmit = () => {
    dispatch(deletePillsReminder(currentReminderId))
    setDeletePopupOpen(!isDeletePopupOpen)
  }

  return (
    <Paper className={classNames(isWebView && classes.webview, classes.root)}>
      <RemindersTabs active={PILLS_REMINDERS} />
      <Wrapper className={classNames(navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))}>
        {reminders.length ? renderRemindersList(reminders) : renderWithoutReminders()}
      </Wrapper>
      <Fab
        color="primary"
        aria-label="Add"
        className={classes.addButton}
        onClick={onCreate}
      >
        <Add />
      </Fab>
      <TwoButtonsPopup
        open={isDeletePopupOpen}
        withCloseIcon={false}
        title="titles.warning"
        text="confirmations.remove_reminder"
        firstButtonName="buttons.cancel"
        secondButtonName="buttons.okay"
        firstButtonOnClick={() => {
          setDeletePopupOpen(!isDeletePopupOpen)
        }}
        secondButtonOnClick={onDeleteSubmit}
      />
    </Paper>
  )
}

export default PillsReminders
