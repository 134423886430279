import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
  Button,
} from '@mui/material'
import {
  selectMultichannelActionValue,
  selectMultichannelActionSelectedDuration,
  selectMultichannelActionSubDevice,
} from '@app/lib/Scenes/actions'
import {
  ADJUST_DURATION_MULTICHANNEL_ACTION,
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_MULTICHANNEL_ACTION_SUBDEVICE,
  SELECT_MULTICHANNEL_ACTION_SUBDEVICE_VALUE,
} from '@app/constants/routes'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMultichannelActionStateSelected extends React.Component {
  onChangeSubDeviceClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MULTICHANNEL_ACTION_SUBDEVICE)
  }

  onChooseValueClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MULTICHANNEL_ACTION_SUBDEVICE_VALUE)
  }

  onChooseDurationClick=() => {
    const {
      navigate,
      saveActionDurationValueAfter,
      stateDescription,
    } = this.props
    navigate(ADJUST_DURATION_MULTICHANNEL_ACTION)
    if (stateDescription === 'toggle') {
      saveActionDurationValueAfter('toggle')
    }
  }

  clearDuration=() => {
    const {
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
  }

  onNextClick = () => {
    const {
      navigate,
      isEditActionMode,
      saveActionType,
      stateDescription,
      saveActionDeviceStateInfo,
      actionDeviceNumber,
      durationValueAfter,
      actionDuration,
      subDeviceNumber,
    } = this.props
    saveActionType('ZwaveMultichannelControl')
    const actionDeviceStateInfo = getActionDeviceStateInfo('ZwaveMultichannelControl', stateDescription,
      actionDeviceNumber, '', durationValueAfter, actionDuration, '',
      '', '', '', '', '', '', '', '',
      '', '', '', subDeviceNumber)
    saveActionDeviceStateInfo(actionDeviceStateInfo)
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      stateDescription,
      durationDescription,
      durationValueAfter,
      subDeviceName,
    } = this.props
    const durationFullDescription = `${durationDescription} then ${I18n.t(`scene_action_web.${durationValueAfter}`)}`
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={null}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <CardBlock
              key={I18n.t(`scene_web.${selectMultichannelActionSubDevice.title}`)}
              title={I18n.t(`scene_web.${selectMultichannelActionSubDevice.title}`)}
              description={subDeviceName}
              position={selectMultichannelActionSubDevice.position}
              buttonName={I18n.t(`scene_web.${selectMultichannelActionSubDevice.buttonName}`)}
              isCardDisabled={selectMultichannelActionSubDevice.isCardDisabled}
              isButtonDisabled={selectMultichannelActionSubDevice.isButtonDisabled}
              onClick={this.onChangeSubDeviceClick}
            />
            <CardBlock
              key={I18n.t(`scene_web.${selectMultichannelActionValue.title}`)}
              title={I18n.t(`scene_web.${selectMultichannelActionValue.title}`)}
              description={I18n.t(`scene_action_web.${stateDescription}`)}
              position={selectMultichannelActionValue.position}
              buttonName={I18n.t(`scene_web.${selectMultichannelActionValue.buttonName}`)}
              isCardDisabled={selectMultichannelActionValue.isCardDisabled}
              isButtonDisabled={selectMultichannelActionValue.isButtonDisabled}
              onClick={this.onChooseValueClick}
            />
            <CardBlock
              key={I18n.t(`scene_web.${selectMultichannelActionSelectedDuration.title}`)}
              title={I18n.t(`scene_web.${selectMultichannelActionSelectedDuration.title}`)}
              description={durationDescription
                ? durationFullDescription
                : I18n.t(`scene_web.${selectMultichannelActionSelectedDuration.description}`)}
              position={selectMultichannelActionSelectedDuration.position}
              buttonName={I18n.t(`scene_web.${selectMultichannelActionSelectedDuration.buttonName}`)}
              isCardDisabled={selectMultichannelActionSelectedDuration.isCardDisabled}
              isButtonDisabled={selectMultichannelActionSelectedDuration.isButtonDisabled}
              onClick={this.onChooseDurationClick}
              hasClearButton={!!durationDescription}
              clearDescription={this.clearDuration}
            />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onNextClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultichannelActionStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  stateDescription: PropTypes.string.isRequired,
  saveActionType: PropTypes.func.isRequired,
  saveActionDeviceStateInfo: PropTypes.func.isRequired,
  actionDeviceNumber: PropTypes.number.isRequired,
  actionDuration: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  durationDescription: PropTypes.string.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  subDeviceName: PropTypes.string.isRequired,
  subDeviceNumber: PropTypes.number.isRequired,
}

SelectMultichannelActionStateSelected.displayName = 'SelectMultichannelActionStateSelectedIOS'

export default SelectMultichannelActionStateSelected
