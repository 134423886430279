import { createLogic } from 'redux-logic'
import { SYNC_DEVICES_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { syncDevicesChannelSent, updateDevices } from '@app/actions/myDevices/channels/devices/devicesChannel'
import Api from '@app/api/myDevices/channels/devices/devicesChannels'

const subscribeDevicesLogic = createLogic({
  type: SYNC_DEVICES_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.devicesChannel({
      request: () => {
        dispatch(syncDevicesChannelSent())
      },
      response: (data) => {
        const { myDevices: { myDevices: { devices } } } = store.getState()
        const mergedDevices = devices.map((stateDevice) => (
          Object.assign({}, stateDevice, ...data.payload
            .filter((actionDevice) => actionDevice.id === stateDevice.id
              && actionDevice.device_type === stateDevice.device_type))))
        store.dispatch(updateDevices(mergedDevices))
      },
    })
  },
})

export default subscribeDevicesLogic
