import React from 'react'

interface Props {
  children?: React.ReactNode,
  backdropClass: string,
  clicked: () => void,
}

const CustomBackdrop: React.FC<Props> = (props) => {
  const { backdropClass, clicked, children } = props
  return (
    <div role="presentation" className={backdropClass} onClick={clicked}>
      {children}
    </div>
  )
}

CustomBackdrop.defaultProps = {
  children: null,
}

export default CustomBackdrop
