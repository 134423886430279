import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleApplicationTab, toggleMezzoTab, toggleSecurityTab, toggleAutomationTab, closeAllTabs, closeMenu,
} from '@app/actions/menu'
import { States, defaultConfig } from '@app/helpers/convertAppConfig'
// @ts-ignore
import elementsData from '@app/lib/Menu/ElementsData'
import { IAppConfig } from '@app/@types/appConfig'
import { AppState } from '@app/@types/store'
import MenuItem from '@app/components/Menu/MenuItem'
import { useNavigate } from 'react-router-dom'
import SubMenuItem from './SubMenuItem'
import SubMenuListNew from './SubMenuListNew'

interface Props {
  appConfig: IAppConfig,
}

const SettingsMenu: React.FC<Props> = (props) => {
  const {
    appConfig,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    isApplicationMenuOpen, isMezzoMenuOpen, isSecurityMenuOpen, isMyAutomationMenuOpen,
  } = useSelector((state: AppState) => state.menu)
  const resultConfig = appConfig.menu ? appConfig.menu.settings : defaultConfig.menu.settings
  const [applicationConfig, automationConfig, hardwareConfig, securityConfig] = resultConfig

  const openMenuItem = (path: string) => {
    navigate(path)
    dispatch(closeMenu())
  }

  return (
    <>
      {applicationConfig.state !== States.Invisible && (
        <SubMenuItem
          primary={I18n.t(`titles.${applicationConfig.code}`)}
          isSubMenuOpen={isApplicationMenuOpen}
          icon={elementsData[applicationConfig.code].icon}
          onItemClick={() => dispatch(toggleApplicationTab())}
          disabled={applicationConfig.state === States.Disabled}
        />
      )}
      <SubMenuListNew
        isSubMenuOpen={isApplicationMenuOpen}
        subMenuData={applicationConfig?.items || [] as Array<{ code: string, state: States }>}
      />
      {automationConfig.state !== States.Invisible && (
        <SubMenuItem
          primary={I18n.t(`titles.${automationConfig.code}`)}
          isSubMenuOpen={isMyAutomationMenuOpen}
          icon={elementsData[automationConfig.code].icon}
          onItemClick={() => dispatch(toggleAutomationTab())}
          disabled={automationConfig.state === States.Disabled}
        />
      )}
      <SubMenuListNew
        isSubMenuOpen={isMyAutomationMenuOpen}
        subMenuData={automationConfig?.items || [] as Array<{ code: string, state: States }>}
      />
      {hardwareConfig.state !== States.Invisible && (
        <SubMenuItem
          primary={I18n.t(`titles.${hardwareConfig.code}`)}
          isSubMenuOpen={isMezzoMenuOpen}
          icon={elementsData[hardwareConfig.code].icon}
          onItemClick={() => dispatch(toggleMezzoTab())}
          disabled={hardwareConfig.state === States.Disabled}
        />
      )}
      <SubMenuListNew
        isSubMenuOpen={isMezzoMenuOpen}
        subMenuData={hardwareConfig?.items || [] as Array<{ code: string, state: States }>}
      />
      {securityConfig.state !== States.Invisible && (!securityConfig.items ? (
        <MenuItem
          key={securityConfig.code}
          icon={elementsData[securityConfig.code].icon}
          title={securityConfig.code}
          open={() => openMenuItem(elementsData[securityConfig.code].path)}
          close={() => dispatch(closeAllTabs())}
          disabled={securityConfig.state === States.Disabled}
        />
      ) : (
        <SubMenuItem
          primary={I18n.t(`titles.${securityConfig.code}`)}
          isSubMenuOpen={isSecurityMenuOpen}
          icon={elementsData[securityConfig.code].icon}
          onItemClick={() => dispatch(toggleSecurityTab())}
          disabled={securityConfig.state === States.Disabled}
        />
      ))}
      <SubMenuListNew
        isSubMenuOpen={isSecurityMenuOpen}
        subMenuData={securityConfig?.items || [] as Array<{ code: string, state: States }>}
      />
    </>
  )
}

export default SettingsMenu
