import Axios from 'axios'

import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/cloud_firmware_update/set_language`

const patchFirmwareLang = (access_token, data) => Axios.patch(ENDPOINT, data, {
  headers: { 'Access-Token': access_token },
})

export default patchFirmwareLang
