const calculateRoomColorActionType = (actionDelay, actionDuration) => {
  if (Object.keys(actionDuration).length && Object.keys(actionDelay).length) {
    return 'RoomColorDelayDuration'
  }
  if (Object.keys(actionDelay).length) {
    return 'RoomColorDelayDuration'
  }
  if (Object.keys(actionDuration).length) {
    return 'RoomColorWithDuration'
  }
  return 'RoomColorControl'
}

export default calculateRoomColorActionType
