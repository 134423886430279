import React from 'react'
import { Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import renderSensorIcon from '@app/helpers/myDevices/renderSensorIcon'
import { IZwaveOrTwrDevice, TwrTypes } from '@app/@types/myDevices'
import { readableElapsedTime } from '@app/helpers/myDevices/readableElapsedTime'
import { twrValueType } from '@app/helpers/myDevices/twrValueType'
import TwrHealth from '@app/components/MyDevices/DeviceInfo/DeviceStats/TwrHealth/TwrHealth'
import useStyles from './DeviceStats.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
}

const TwrStats: React.FC<Props> = (props) => {
  const {
    currentDevice,
  } = props
  const classes = useStyles()

  const renderBatteryStatus = () => {
    if (currentDevice.info && typeof currentDevice.info.twr_battery.value === 'boolean') {
      return `${currentDevice.info.twr_battery.value ? 'Low' : 'Normal'}`
    }
    return `${I18n.t('my_devices.not_available')}`
  }

  const renderSensorName = (type: string): string => {
    const twrSensorNameMap = {
      Door: 'Door detector',
      Motion: 'Motion detector',
      Siren: 'Siren detector',
      Keyfob: 'Keyfob detector',
      'Glass break': 'Glass break detector',
      REED_SWITCH: 'Reed switch detector',
      PIR: 'PIR detector',
      GLASS_BREAK: 'Glass break detector',
      SMOKE_ALARM: 'Smoke alarm detector',
      ENVIRONMENTAL_SENSOR: 'Environmental detector',
      default: 'Unknown detector',
    }

    return twrSensorNameMap[type] || twrSensorNameMap.default
  }

  const renderStatusAndTime = () => {
    if (!currentDevice.info) {
      return null
    }

    const twrSensor = currentDevice.info.twr_sensor
    const twrType = currentDevice.info.twr_type

    const renderStatus = () => {
      if (twrSensor.value === null) {
        return `${I18n.t('my_devices.not_available')}`
      }
      return twrValueType(twrType, twrSensor.value)
    }

    const renderTime = () => {
      if (twrSensor.update) {
        return readableElapsedTime(twrSensor.update)
      }
      return `${I18n.t('my_devices.not_available')}`
    }

    if (twrType === TwrTypes.pir || twrType === TwrTypes.motion || twrType === TwrTypes.glassBreak) {
      return (
        <Typography className={classes.status}>
          {renderStatus()}
          {', '}
          {renderTime()}
        </Typography>
      )
    }

    return (
      <>
        <Typography className={classes.status}>
          {renderStatus()}
        </Typography>
        <Typography className={classes.timestamp}>
          {`Last update: ${renderTime()}`}
        </Typography>
      </>
    )
  }

  if (currentDevice.info) {
    return (
      <Wrapper className={classes.grid}>
        {Object.keys(currentDevice.info.twr_battery).length !== 0 && (
          <Wrapper className={classes.gridElement}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon('battery')}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>{I18n.t('my_status.battery_level')}</Typography>
              <Typography className={classes.status}>
                {renderBatteryStatus()}
              </Typography>
              <Typography className={classes.timestamp}>
                {`Last update: ${currentDevice.info.twr_battery.update
                  ? readableElapsedTime(currentDevice.info.twr_battery.update)
                  : `${I18n.t('my_devices.not_available')}`}`}
              </Typography>
            </Wrapper>
          </Wrapper>
        )}
        {Object.keys(currentDevice.info.twr_sensor).length !== 0 && (
          <Wrapper className={classes.gridElement}>
            <Wrapper className={classes.iconWrapper}>{renderSensorIcon('pir_sensor')}</Wrapper>
            <Wrapper className={classes.info}>
              <Typography className={classes.name}>{renderSensorName(currentDevice.info.twr_type)}</Typography>
              {renderStatusAndTime()}
            </Wrapper>
          </Wrapper>
        )}
        <TwrHealth currentDevice={currentDevice} />
      </Wrapper>
    )
  }
  return null
}

export default TwrStats
