import * as type from '@app/constants/myEnergy/energyTab'

export const choosePeriod = (chosenPeriod) => ({
  type: type.CHOOSE_PERIOD,
  chosenPeriod,
})

export const chooseSliderTab = (chosenTab) => ({
  type: type.CHOOSE_SLIDER_TAB,
  chosenTab,
})

export const updateEnergies = (energies) => ({
  type: type.UPDATE_ENERGIES,
  energies,
})

export const sync = () => ({
  type: type.SYNC_ENERGIES,
})
