import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { syncZWaveDevicesByRooms } from '@app/actions/scenes/zWaveDevices/zWaveDevicesByRooms'
import { withAccessDenied, withProgressAndErrorMessage, withRefresh } from '@app/hocs'
import { isIOS } from 'react-device-detect'
import RoomsList
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/DevicesActions/SelectDeviceCommon/Rooms/RoomsList'
import RoomsListIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/DevicesActions/SelectDeviceCommon/Rooms/RoomsList'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? RoomsListIOS : RoomsList

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { rooms } = state.scenes.zWaveDevicesByRooms
  const { deviceType } = state.scenes.currentSceneCreating.currentAction
  return {
    deviceType,
    isMobile,
    rooms,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: syncZWaveDevicesByRooms,
  tryAgain: syncZWaveDevicesByRooms,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RoomsListEnhanced = enhance

export default RoomsListEnhanced
