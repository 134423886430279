import PropTypes from 'prop-types'

const propTypes = PropTypes.arrayOf(PropTypes.shape({
  avatar: PropTypes.string,
  clientToken: PropTypes.string, // TODO: Need to remove it
  email: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  mobile: PropTypes.string,
  preferredName: PropTypes.string,
}))

export default propTypes
