import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  main: {
    width: '90%',
    padding: '25px 0',
  },
  textField: {
    borderColor: theme.palette.primary.main,
    width: '100%',
    marginBottom: 40,
  },
  textFieldWithError: {
    marginBottom: 18,
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  titleWrapper: {
    display: 'flex',
    marginBottom: 10,
  },
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    opacity: 0.5,
    marginRight: 15,
  },
  condition: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    textDecoration: 'underline',
  },
  controlButtonsWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: 50,
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.shadow.color,
    borderRadius: '5px',
    marginTop: 150,
  },
  controlButtonsWrapperMobile: {
    marginTop: 0,
  },
  controlButton: {
    width: '50%',
    boxSizing: 'border-box',
    borderRadius: 0,
    color: theme.texts.main,
    borderColor: theme.palette.shadow.color,
    '&:nth-child(1)': {
      border: '1px solid',
      borderTop: 0,
      borderBottom: 0,
      borderLeft: 0,
      borderColor: theme.palette.shadow.color,
    },
  },
}))

export default useStyles
