import React, { useEffect } from 'react'
import classNames from 'classnames'
import {
  Button, Typography,
} from '@mui/material'
import { syncGuardPanelChannel, updateGuardPanelStatus } from '@app/actions/guardPanel'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { useDispatch } from '@app/store'
import { Status } from '@app/lib/guardPanel/guardPanel'
import { BUTTON } from '@app/lib/MySecurity/security'
import {
  Area1ArmedIcon,
  PerimeterArmedIcon,
  Area2ArmedIcon,
  DisarmedSecurityIcon,
  Lock,
  LeftArrowIcon,
  RightArrowIcon,
} from 'icons'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
// @ts-ignore
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import useStyles from './ArmDisarmBlock.style'
import useStylesHome from './ArmDisarmBlockHome.style'

interface Props {
  type: number,
  disabled?: boolean,
}
const ArmDisarmBlock: React.FC<Props> = (props) => {
  const { type, disabled = false } = props
  const classes = type === 1 ? useStyles() : useStylesHome()
  const dispatch = useDispatch()
  const { status, inProgress } = useSelector((state: AppState) => state.guardPanel)
  const { online, arm_types } = useSelector((state: AppState) => state.unit)
  const { isMobile } = useSelector((state: AppState) => state.mobileDetector)
  const inPanic = arm_types?.panic

  useEffect(() => {
    if (online) {
      dispatch(syncGuardPanelChannel())
    }
  }, [online])

  const onArmClick = () => {
    dispatch(updateGuardPanelStatus(Status.SIMPLE_ARMED))
  }

  const onDisarmClick = () => {
    dispatch(updateGuardPanelStatus(Status.SIMPLE_DISARMED))
  }

  const getColorForStatus = () => {
    const isDisarmed = status === Status.SIMPLE_DISARMED
    if (inProgress) {
      return isDisarmed ? classes.darkGreen : classes.darkRed
    }
    return isDisarmed ? classes.darkGreen : classes.redStatus
  }

  return (
    <>
      <Button
        className={classNames(classes.buttonSecondLine,
          type === 2 && classes.buttonArm,
          inProgress ? classes.darkPink : classes.pink)}
        onClick={() => {
          onArmClick()
        }}
        disabled={inProgress || disabled || (status === Status.SIMPLE_ARMED && !inPanic)}
      >
        <Wrapper className={classes.buttonTitleWrapper}>
          <Typography>{I18n.t(`security.${BUTTON.arm}`)}</Typography>
          <Typography>{I18n.t('security.all')}</Typography>
        </Wrapper>
        {(type === 2 && !isMobile) && (
          <Wrapper className={classes.chevronWrapper}>
            <LeftArrowIcon />
            <LeftArrowIcon />
            <LeftArrowIcon />
          </Wrapper>
        )}
      </Button>
      <Button
        className={classNames(classes.buttonSecondLine,
          type === 2 && classes.buttonDisarm,
          inProgress ? classes.darkGreen : classes.green,
          isMobile && classes.mobileButtonDisarm)}
        onClick={() => {
          onDisarmClick()
        }}
        // disabled={inProgress || disabled || (status === Status.SIMPLE_DISARMED && !inPanic)}
      >
        {(type === 2 && !isMobile) && (
          <Wrapper className={classes.chevronWrapper}>
            <RightArrowIcon />
            <RightArrowIcon />
            <RightArrowIcon />
          </Wrapper>
        )}
        <Wrapper className={classes.buttonTitleWrapper}>
          <Typography>{I18n.t(`security.${BUTTON.disarm}`)}</Typography>
          <Typography>{I18n.t('security.all')}</Typography>
        </Wrapper>
      </Button>
      <Wrapper
        className={classNames(classes.armStatus, getColorForStatus())}
      >
        {(!inProgress && status === Status.SIMPLE_DISARMED) && <DisarmedSecurityIcon className={classes.armStatusIcon} />}
        {(!inProgress && status === Status.SIMPLE_ARMED) && <Lock className={classes.armStatusIcon} />}
        {(!inProgress && status === Status.ARMED_PERIMETER) && <PerimeterArmedIcon className={classes.armStatusIcon} />}
        {(!inProgress && status === Status.ARMED_AREA_1) && <Area1ArmedIcon className={classes.armStatusIcon} />}
        {(!inProgress && status === Status.ARMED_AREA_2) && <Area2ArmedIcon className={classes.armStatusIcon} />}
        { inProgress && (
          <DotsLoading
            props={{
              width: '70%', circleColor: '#fff',
            }}
          />
        )}
      </Wrapper>
    </>
  )
}

export default ArmDisarmBlock
