import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M82.1454 130.031L85.3886 140.626L87.301 140.041L84.0578 129.446L82.1454 130.031ZM65.5331 129.446L62.2899 140.041L64.2023 140.626L67.4455 130.031L65.5331 129.446ZM56.9018 128.2L50.9681 137.315L52.6442 138.406L58.5779 129.292L56.9018 128.2ZM73.8115 130.051V142.002H75.8115V130.051H73.8115ZM91.0132 129.292L96.9782 138.448L98.654 137.356L92.6889 128.2L91.0132 129.292Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M125.545 22.6055C125.545 22.4 125.546 22.1959 125.546 21.9932C125.555 18.2358 125.563 14.9535 122.417 11.906C120.712 10.1741 118.41 9.15937 115.982 9.06952C114.41 8.96519 112.838 9.00003 111.269 9.03481C110.486 9.05218 109.704 9.06952 108.922 9.06952H35.2251C33.3869 8.967 31.5505 9.29926 29.8649 10.0394C26.298 11.7524 24.0338 15.3637 24.0459 19.3206V81.995C24.0459 82.669 24.0343 83.3441 24.0227 84.0197C23.9995 85.3719 23.9763 86.7256 24.0459 88.0748C24.2597 91.8797 26.5646 95.2527 30.0317 96.8346C31.6787 97.5293 33.4604 97.8465 35.2459 97.7627H114.408C116.252 97.8423 118.088 97.485 119.768 96.7199C123.313 95.0001 125.559 91.4001 125.545 87.4595V22.6055ZM118.14 16.1144L118.19 16.1658L118.242 16.2159C118.964 16.915 119.205 17.4766 119.35 18.1737C119.547 19.1237 119.546 20.1581 119.545 22.158L119.545 87.4595H125.545L119.545 87.4811C119.551 89.0921 118.646 90.5658 117.212 91.2905C116.411 91.6424 115.541 91.806 114.666 91.7683L114.537 91.7627H35.1052L34.9647 91.7693C34.1042 91.8097 33.2454 91.6633 32.4474 91.3408C31.0528 90.6717 30.1283 89.2974 30.0371 87.7496C29.9784 86.5983 29.9968 85.5923 30.0187 84.3968C30.0316 83.6865 30.0459 82.9093 30.0459 81.995V19.3115L30.0458 19.3024C30.0409 17.6918 30.944 16.2192 32.3745 15.4914C33.1696 15.1598 34.0299 15.0122 34.8909 15.0602L35.0579 15.0695H108.922C110.016 15.0695 110.928 15.0478 111.754 15.028C113.125 14.9953 114.258 14.9683 115.585 15.0564L115.673 15.0622L115.76 15.0654C116.658 15.0986 117.51 15.4739 118.14 16.1144Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M62.12 123.178C59.2864 123.178 56.9893 120.881 56.9893 118.047V102.613H92.6334V118.047C92.6334 120.881 90.3363 123.178 87.5026 123.178H62.12ZM59.9893 105.613H89.6334V118.047C89.6334 119.224 88.6794 120.178 87.5026 120.178H62.12C60.9432 120.178 59.9893 119.224 59.9893 118.047V105.613Z" fill="currentColor"/>
            <rect x="53.8711" y="97.8594" width="41.8386" height="4.76575" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwSirenIcon')

export default icon
