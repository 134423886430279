import React from 'react'
import {
  Button, TextField, Typography, Paper,
} from '@mui/material'
import classNames from 'classnames'
import validateName from '@app/helpers/myDevices/validateName'
import { NAME_PATTERN } from '@app/lib/RoomsAndGroups/roomsAndGroups'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { withStyles } from '@app/components/styles'

class EditRoom extends React.PureComponent {
  state = {
    newRoomName: '',
    roomsList: [],
    twrDevices: [],
    zWaveDevices: [],
  }

  componentDidMount() {
    const { location } = this.props
    if (location.state) {
      this.setState({
        newRoomName: location.state.room.name,
        roomsList: location.state.roomsList.filter((room) => room.name !== location.state.room.name),
        twrDevices: location.state.room.twr_devices,
        zWaveDevices: location.state.room.zwave_devices,
      })
    }
  }

  filterDeletedDevice = (deviceId, isZwave) => {
    this.setState((prevState) => (isZwave
      ? ({ zWaveDevices: prevState.zWaveDevices.filter((device) => device.id !== deviceId) })
      : ({ twrDevices: prevState.twrDevices.filter((device) => device.id !== deviceId) })))
  }

  validateNonExistentName = (namesArray, currentName) => namesArray.map((name) => currentName === name).find((name) => name === true)

  handleSave = (data) => {
    const { location, patchRoom } = this.props
    patchRoom({ ...data, id: location.state.room.id })
  }

  render() {
    const {
      classes,
      isMobile,
      location,
      roomDeleteDevice,
    } = this.props
    const {
      newRoomName,
      roomsList,
      twrDevices,
      zWaveDevices,
    } = this.state
    return (
      <Paper className={classes.root}>
        <Wrapper className={classNames(classes.main, isMobile && classes.mainMobile)}>
          <Wrapper className={classNames(classes.nameAndSave, isMobile && classes.nameAndSaveMobile)}>
            <TextField
              variant="standard"
              id="standard-basic"
              label={I18n.t('rooms_and_groups.room_name')}
              value={newRoomName}
              className={classes.nameField}
              inputProps={{ className: classes.innerInput, maxLength: 16, pattern: NAME_PATTERN }}
              onChange={(e) => this.setState({ newRoomName: e.target.value.trimLeft() })}
            />
            {!!roomsList.length && (
              <Typography
                style={this.validateNonExistentName(roomsList.map((room) => room.name), newRoomName)
                  ? { display: 'inline-block' }
                  : { display: 'none' }}
                className={classes.errorMessage}
              >
                {I18n.t('rooms_and_groups.name_exists')}
              </Typography>
            )}
            <Button
              className={classes.saveButton}
              disabled={
                !newRoomName.length
              || (!!roomsList.length && this.validateNonExistentName(roomsList.map((room) => room.name), newRoomName))
              || !validateName(newRoomName)
              }
              onClick={() => this.handleSave({ name: newRoomName })}
            >
              {I18n.t('buttons.save')}
            </Button>
          </Wrapper>
          <Wrapper>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.twr_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {location.state && twrDevices.length
                ? twrDevices.map((device) => (
                  <Wrapper
                    className={classNames(
                      classes.devicesCard,
                      isMobile && classes.cardMobile,
                    )}
                    key={`${device.name + 1}`}
                  >
                    {device.name}
                    <Button
                      className={classes.cardControls}
                      onClick={() => {
                        roomDeleteDevice(device.id, false)
                        this.filterDeletedDevice(device.id, false)
                      }}
                    >
                      {I18n.t('buttons.delete')}
                    </Button>
                  </Wrapper>
                ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.zWaveDevicesContainer}>
            <Typography className={classes.deviceLabel}>{I18n.t('rooms_and_groups.zwave_devices')}</Typography>
            <Wrapper className={classes.devicesContainer}>
              {location.state && zWaveDevices.length
                ? zWaveDevices.map((device) => (
                  <Wrapper
                    className={classNames(
                      classes.devicesCard,
                      isMobile && classes.cardMobile,
                    )}
                    key={`${device.name + 1}`}
                  >
                    {device.name}
                    <Button
                      className={classes.cardControls}
                      onClick={() => {
                        roomDeleteDevice(device.id, true)
                        this.filterDeletedDevice(device.id, true)
                      }}
                    >
                      {I18n.t('buttons.delete')}
                    </Button>
                  </Wrapper>
                ))
                : <Typography className={classes.noDevices}>{I18n.t('my_devices.no_devices')}</Typography>}
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

EditRoom.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      room: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        twr_devices: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })).isRequired,
        zwave_devices: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })).isRequired,
      }),
      roomsList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })),
    }),
  }).isRequired,
  patchRoom: PropTypes.func.isRequired,
  roomDeleteDevice: PropTypes.func.isRequired,
}

EditRoom.displayName = 'EditRoom'

export default withStyles(EditRoom)
