const styles = () => ({
  root: {
    minHeight: 70,
    justifyContent: 'space-between',
  },
  title: {
    maxWidth: '20%',
    fontSize: '1rem',
  },
})

export default styles
