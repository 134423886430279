import * as type from '@app/constants/myDevices/deviceSettings/failedNode'

export type TFailedNodeAction =
  | { type: '@@myDevices/getDeviceHealth', id: number }
  | { type: '@@myDevices/deleteUnhealthyDevice', id: number }
  | { type: '@@myDevices/resetFailedNodeData' }
  | { type: '@@myDevices/resetFailedNodeStatus' }
  | { type: '@@myDevices/replaceUnhealthyDevice', id: number }
  | { type: '@@myDevices/cancelReplaceDevice' }

export const getDeviceHealth = (id: number): TFailedNodeAction => ({
  type: type.GET_DEVICE_HEALTH,
  id,
})

export const deleteUnhealthyDevice = (id: number): TFailedNodeAction => ({
  type: type.DELETE_UNHEALTHY_DEVICE,
  id,
})

export const resetFailedNodeData = (): TFailedNodeAction => ({
  type: type.RESET_FAILED_NODE_DATA,
})

export const resetFailedNodeStatus = (): TFailedNodeAction => ({
  type: type.RESET_FAILED_NODE_STATUS,
})

export const replaceUnhealthyDevice = (id: number): TFailedNodeAction => ({
  type: type.REPLACE_UNHEALTHY_DEVICE,
  id,
})

export const cancelReplaceDevice = (): TFailedNodeAction => ({
  type: type.CANCEL_REPLACE_DEVICE,
})
