import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import convertUserToForm from '@app/helpers/convertUserToForm'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { set, sync, updateCurrentUser } from '@app/actions/users/currentUser'
import { updateAccessToken } from '@app/actions/secrets'
import {
  cleanAvatarFile,
  closePopup as closeAvatarPopup,
  openPopup as openAvatarPopup,
} from '@app/actions/users/avatar'
import {
  closePopup as closeChangeCodePopup,
  closeWarningChangeEmailPopup,
  openPopup as openChangeCodePopup,
  openChangePinPopup,
  closeChangePinPopup,
  getPinCode,
  closeShowPinPopup,
} from '@app/actions/users/secretCode'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import UserEdit from '@app/components/Users/UserEdit/UserEdit'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
  withRouter,
} from '@app/hocs'
import { validate } from './formValidate'

let enhance = withStyles(UserEdit)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isNCentral } = state.unit
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const { user } = state.users.currentUser
  const { pinCode: pin } = state.users.secretCode
  const pinCode = pin !== null && isNCentral ? pin.replace(/^0+/, '') : pin
  const userData = convertUserToForm(user)
  const userAvatar = user.avatar
  const userRole = user.role
  const { avatarPopupOpen } = state.users.avatar
  const {
    changeCodePopupOpen,
    warningChangeEmailPopupOpen,
    isChangePinPopupOpen,
    isShowPinPopupOpen,
  } = state.users.secretCode
  return {
    path,
    isWebView,
    isMobile,
    avatarPopupOpen,
    changeCodePopupOpen,
    warningChangeEmailPopupOpen,
    userData,
    userAvatar,
    userRole,
    isChangePinPopupOpen,
    isShowPinPopupOpen,
    pinCode,
    isNCentral,
    enableReinitialize: true,
    initialValues: userData.reduce((result, option) => {
      result[option.title] = option.value
      return result
    }, {}),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
  openAvatarPopup,
  closeAvatarPopup,
  cleanAvatarFile,
  openChangeCodePopup,
  closeChangeCodePopup,
  closeWarningChangeEmailPopup,
  openChangePinPopup,
  closeChangePinPopup,
  getPinCode,
  closeShowPinPopup,
  submit: (dispatch) => dispatch(submit('UserEditForm')),
}, parentDispatch)

enhance = reduxForm({
  form: 'UserEditForm',
  validate,
  onSubmit: () => {
    store.dispatch(set())
  },
})(enhance)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withRouter(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const UserEditEnhanced = enhance

export default UserEditEnhanced
