import React, { useEffect, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import { renderIcon } from '@app/helpers/myDevices/renderIcon'
import { deviceControls } from '@app/lib/MyDevices/deviceControls'
import * as slider from '@app/lib/MyDevices/sliderValues'
import { patchZwaveDeviceStatus } from '@app/actions/myDevices/zwaveDevices'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ChevronLeft, SortIcon } from 'icons'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { setCurrentDevice, setCurrentDeviceControl } from '@app/actions/myDevices/myDevices'
import { readableElapsedTime } from '@app/helpers/myDevices/readableElapsedTime'
import { zwaveSensorTypes } from '@app/lib/MyDevices/zwaveSensorTypes'
import SensorStatus from '@app/components/MyDevices/DevicesList/DeviceCard/CardUIKit/SensorStatus/SensorStatus'
import { twrValueType } from '@app/helpers/myDevices/twrValueType'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
import useStyles from './DeviceCard.style'

interface Props {
  device: IZwaveOrTwrDevice,
  deviceType: string,
  isDragging: boolean,
  isMobile: boolean,
  inProgress: boolean,
}

const DeviceCard: React.FC<Props> = (props) => {
  const {
    device,
    deviceType,
    isDragging,
    isMobile,
  } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState<boolean>(!!device.status)
  const [status, setStatus] = useState<number | boolean | null>(device.status || slider.MIN_SLIDER_VALUE)
  const [rerender, setRerender] = useState<boolean>(false)
  const { devices: devicesConfig } = useSelector((state: AppState) => state.appConfig.config)
  const zones = devicesConfig?.twr?.state ?? defaultConfig?.devices?.twr?.zones?.state
  const showZones = zones === States.Visible

  useEffect(() => {
    const rerenderInterval = setInterval(() => setRerender(!rerender), 1000)
    return () => {
      clearInterval(rerenderInterval)
    }
  }, [rerender])

  useEffect(() => {
    setStatus(device.status ? device.status : slider.MIN_SLIDER_VALUE)
    setChecked(device.status ? !!device.status : false)
  }, [device.status])

  const DeviceControlData = device && deviceControls[device.widget || device.type]
  const ControlComponent = DeviceControlData && DeviceControlData.component

  const renderControllerArea = () => {
    if (device.last_event && zwaveSensorTypes.includes(device.type)) {
      return (
        <SensorStatus
          sensorType={device.last_event.sensor_type}
          sensorStatus={device.last_event.status}
        />
      )
    } if (ControlComponent) {
      return <ControlComponent {...deviceControlHandler()} />
    } return null
  }

  const renderStatus = () => {
    if (showZones && device.details_info === 'Unknown' && device.status !== null) {
      return twrValueType('ZONE', checked)
    }
    if (device.last_event?.update) {
      return readableElapsedTime(device.last_event.update)
    }
    if (device.device_type === DeviceTypes.twrDevice) {
      return device.info?.twr_sensor.value === undefined && device.status === null
        ? null
        : twrValueType(device.info?.twr_type!, device.info?.twr_sensor.value!)
    }
    if (!DeviceControlData) {
      return null
    }
    if (checked) {
      return DeviceControlData.checked
    }
    if (DeviceControlData.isMultilevel && typeof status !== 'boolean') {
      return `${status && status >= slider.MAX_SLIDER_VALUE ? slider.MAX_SLIDER_UI_STUB : status} %`
    }
    return DeviceControlData.unchecked
  }

  const patchWithZwaveChanges = (deviceStatus: number) => {
    dispatch(patchZwaveDeviceStatus({
      id: device.id,
      status: deviceStatus,
    }))
  }

  const deviceControlHandler = () => {
    if (typeof status === 'number') {
      if (DeviceControlData.isMultilevel) {
        return {
          max: slider.MAX_SLIDER_VALUE,
          onChange: (_: any, value: number) => {
            setStatus(value)
          },
          onChangeCommitted: (_: any, value: number) => {
            patchWithZwaveChanges(value)
          },
          isFullWidth: !isMobile,
          value: status > slider.MAX_SLIDER_VALUE ? slider.MAX_SLIDER_VALUE : status,
          onOnButtonClick: () => {
            setStatus(slider.MAX_SLIDER_VALUE)
            patchWithZwaveChanges(slider.MAX_SLIDER_VALUE)
          },
          onOffButtonClick: () => {
            setStatus(slider.MIN_SLIDER_VALUE)
            patchWithZwaveChanges(slider.MIN_SLIDER_VALUE)
          },
        }
      }
    }
    return {
      onChange: () => {
        setChecked(!checked)
      },
      onChangeCommitted: () => {
        patchWithZwaveChanges(checked ? slider.MIN_SWITCH_VALUE : slider.MAX_SWITCH_VALUE)
      },
      checked,
      id: device.id,
      locker: DeviceControlData && DeviceControlData.key === 'switch_locked_unlocked',
      chevronsLeftRight: DeviceControlData && DeviceControlData.key === 'switch_left_right',
      chevronsUpDown: DeviceControlData && DeviceControlData.key === 'switch_up_down',
      inProgress: device.activate_status === 'progress',
      multiSwitchValue: checked,
      multiSwitchHandler: (multiSwitchState: boolean) => {
        setChecked(multiSwitchState)
        patchWithZwaveChanges(!multiSwitchState ? slider.MIN_SWITCH_VALUE : slider.MAX_SWITCH_VALUE)
      },
    }
  }

  const goToDeviceInfoPage = () => {
    dispatch(setCurrentDevice(device))
    dispatch(setCurrentDeviceControl(DeviceControlData))
    navigate(`/web/my_devices/info/${deviceType}/${device.id}`)
  }

  const renderStatusColorClasses = () => {
    if (deviceType === DeviceTypes.zwaveDevice) {
      if (device.last_event?.update) {
        return classes.green
      }
      if (DeviceControlData && !DeviceControlData.isMultilevel && !device.last_event?.update) {
        if (checked) {
          return classes.green
        }
      }
    }
    if (deviceType === DeviceTypes.twrDevice) {
      if (device.info?.twr_type === 'Door') {
        if (device.info?.twr_sensor.value) {
          return classes.red
        }
        return classes.green
      }
      if (device.info?.twr_sensor.value) {
        return classes.green
      }
      if (showZones && device.details_info === 'Unknown' && checked) {
        return classes.green
      }
    }
    return classes.red
  }

  return (
    <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile, isDragging && classes.dragging)}>
      <Wrapper className={classNames(classes.cardInfo, isMobile && classes.cardInfoMobile)}>
        <Wrapper className={classes.cardIconContainer}>
          {renderIcon(device.icon, { className: classes.icon })}
        </Wrapper>
        <Wrapper className={isMobile ? classes.nameAndStatus : null}>
          <Typography className={classes.cardName}>{device.name}</Typography>
          <Typography className={classNames(
            classes.cardStatus,
            renderStatusColorClasses(),
          )}
          >
            {renderStatus()}
          </Typography>
        </Wrapper>
        <IconButton
          size="small"
          className={classes.chevronIcon}
          onClick={goToDeviceInfoPage}
        >
          {isDragging ? <SortIcon /> : <ChevronLeft />}
        </IconButton>
      </Wrapper>
      <Wrapper className={classNames(zwaveSensorTypes.includes(device.type) ? classes.sensorControl : classes.cardControl)}>
        {renderControllerArea()}
      </Wrapper>
    </Wrapper>
  )
}

export default DeviceCard
