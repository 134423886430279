import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/devices/reset`

const resetDevicesEnergies = (accessToken, idsArray) => Axios.post(ENDPOINT, '', {
  params: {
    device_ids: idsArray,
  },
  headers: {
    'Access-Token': accessToken,
  },
})

export default {
  resetDevicesEnergies,
}
