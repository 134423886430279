import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    width: 309,
    height: 20,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 309,
    minHeight: 90,
  },
  deviceName: {
    width: 255,
    margin: '20px 0',
  },
  errorMessage: {
    color: theme.palette.error.main,
    position: 'absolute',
    marginTop: 28,
  },
  saveButton: {
    marginTop: 15,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    width: 122,

    '&:disabled': {
      borderColor: theme.borders.disabled,
    },
  },
  innerInput: {
    '&:invalid': {
      borderRadius: 5,
      backgroundColor: 'rgba(148, 52, 52, 0.5)',
    },
  },
}))

export default useStyles
