import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './SelectFieldString.style'

class SelectFieldString extends React.PureComponent {
   handleChange = () => {
     const { submit } = this.props
     submit()
   };

   render() {
     const {
       input,
       number,
       values,
       startValue,
       createItems,
       title,
       classes,
     } = this.props
     return (
       <FormControl className={classes.root}>
         <TextField
           select
           label={title}
           inputProps={{ ...input }}
           onChange={this.handleChange}
           variant="outlined"
         >
           {createItems(number, values, startValue)}
         </TextField>
       </FormControl>
     )
   }
}

SelectFieldString.propTypes = {
  input: PropTypes.object.isRequired,
  createItems: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  number: PropTypes.number,
  startValue: PropTypes.number,
  title: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

SelectFieldString.defaultProps = {
  values: [],
  startValue: 0,
  number: null,
}

export default (withStyles(styles)(SelectFieldString))
