import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { restoreActionInfo, saveActionColorType } from '@app/actions/scenes/currentSceneCreating'
import { withStyles } from '@app/components/styles'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGroupActionColorType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/SelectGroupActionColorType/SelectGroupActionColorType'
import SelectGroupActionColorTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/GroupActions/SelectGroupActionColorType/SelectGroupActionColorType'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectGroupActionColorTypeIOS) : withStyles(SelectGroupActionColorType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { stateDescription, actionType } = state.scenes.currentSceneCreating.currentAction
  const { isChangeActionMode, isChangeActionStateMode, isEditActionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    stateDescription,
    isChangeActionMode,
    isChangeActionStateMode,
    isEditActionMode,
    actionType,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveActionColorType,
  restoreActionInfo,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGroupActionColorTypeEnhanced = enhance

export default withRouter(SelectGroupActionColorTypeEnhanced)
