import React from 'react'
import { Button, Paper, Typography } from '@mui/material'
import classNames from 'classnames'
import { SIGN_IN } from '@app/constants/routes'
import { signUpFields } from '@app/lib/Wizard/wizardFields'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import EditText from './EditText/EditText'
import NavigationAndTitle from './NavigationAndTitle/NavigationAndTitle'

class SignUp extends React.PureComponent {
  renderError = () => {
    const {
      classes,
    } = this.props
    return (
      <Typography className={classes.error}>
        {I18n.t('errors.user_exists')}
      </Typography>
    )
  }

  render() {
    const {
      classes,
      pristine,
      submit,
      submitting,
      isMobile,
      isTablet,
      navigate,
      isUserExists,
      hideUserExistsError,
      invalid,
    } = this.props
    return (
      <Paper className={classes.root}>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <NavigationAndTitle
            title={I18n.t('wizard_web.user_registration')}
            onBackClick={() => {
              navigate(SIGN_IN)
              hideUserExistsError()
            }}
            isMobile={isMobile}
          />
          <form className={classes.form}>
            {signUpFields.map((field) => (
              <EditText
                key={field.title}
                className={classes.item}
                name={field.title}
                hint={I18n.t(`wizard_web.${field.title}_hint`)}
                title={field.title}
                placeholder={`${I18n.t(`wizard_web.${field.title}`)} *`}
                autoComplete={field.autocomplete}
              />
            ))}
            {isUserExists && this.renderError()}
            <Button
              className={classes.sendButton}
              variant="contained"
              color="primary"
              disabled={pristine || submitting || invalid}
              onClick={submit}
            >
              {I18n.t('wizard_web.submit')}
            </Button>
          </form>
        </Wrapper>
      </Paper>
    )
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isUserExists: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  hideUserExistsError: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
}

SignUp.displayName = 'SignUp'

export default SignUp
