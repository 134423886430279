import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '../Wrapper/Wrapper'
import styles from './WarningAddition.style'

class WarningAddition extends React.PureComponent {
  render() {
    const {
      classes, status,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Typography className={classes.title}>
          {I18n.t(status)}
        </Typography>
      </Wrapper>
    )
  }
}

WarningAddition.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
}

export default withStyles(styles)(WarningAddition)
