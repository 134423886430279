import { InputBase } from '@mui/material'

import { withStyles } from '@mui/styles'

const CustomInput = withStyles((theme) => ({
  input: {
    position: 'relative',
    backgroundColor: theme.backgrounds.cardBackground,
    border: '1px solid transparent',
    '&:focus': {
      backgroundColor: theme.backgrounds.cardBackground,
    },
  },
}))(InputBase)

export default CustomInput
