const styles = (theme) => ({
  root: {
    minHeight: 70,
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid',
    borderColor: theme.typography.color,
    marginRight: 5,
    alignItems: 'flex-start',
  },
  infoText: {
    fontSize: '0.75rem',
    color: theme.typography.shadowColor,
    paddingBottom: '5px',
  },
  valueText: {
    fontSize: '1rem',
  },
})

export default styles
