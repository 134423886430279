export const SYNC_ECO_MODE_DEVICES = '@@ecoMode/sync'

export const UPDATE_ECO_MODE_DEVICES = '@@ecoMode/update'

export const SELECT_CURRENT_ECO_MODE_DEVICE_ID = '@@ecoMode/selectCurrentEcoModeDeviceId'
export const CLEAR_CURRENT_ECO_MODE_DEVICE_ID = '@@ecoMode/clearCurrentEcoModeDeviceId'
export const SELECT_CURRENT_ECO_MODE_DEVICE_STATUS = '@@ecoMode/selectCurrentEcoModeDeviceStatus'
export const CLEAR_CURRENT_ECO_MODE_DEVICE_STATUS = '@@ecoMode/clearCurrentEcoModeDeviceStatus'

export const PATCH_CURRENT_ECO_MODE_DEVICE = '@@ecoMode/patchCurrentEcoModeDevice'

export const POST_ECO_MODE_DEVICES_TURN_OFF = '@@ecoMode/postEcoModeDevicesTurnOff'

export const OPEN_TURN_OFF_POPUP = '@@ecoMode/openTurnOffPopup'
export const CLOSE_TURN_OFF_POPUP = '@@ecoMode/closeTurnOffPopup'
