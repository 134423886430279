import * as type from '@app/constants/scenes/currentSceneConditionsActions'
import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'

export const saveConditions = (conditions: Array<IConditionFromBackend>) => ({
  type: type.SAVE_CONDITIONS,
  conditions,
} as const)

export const saveActions = (actions: Array<IActionFromBackend>) => ({
  type: type.SAVE_ACTIONS,
  actions,
} as const)

export const getConditionsActions = () => ({
  type: type.GET_CONDITIONS_ACTIONS,
} as const)

export const deleteCondition = () => ({
  type: type.DELETE_CONDITION,
} as const)

export const deleteAction = () => ({
  type: type.DELETE_ACTION,
} as const)

export const changeConditionOperandValue = (conditionIndex: number, operand: string) => ({
  type: type.CHANGE_CONDITION_OPERAND_VALUE,
  conditionIndex,
  operand,
} as const)

export const resetCurrentSceneConditionsActions = () => ({
  type: type.RESET_CURRENT_SCENE_CONDITIONS_ACTIONS,
} as const)
