import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.97571 17.688L2.34271 11.055L0.131714 13.266L8.97571 22.11L27.9271 3.15857L25.7161 0.947571L8.97571 17.688Z" fill="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CheckIcon')

export default icon
