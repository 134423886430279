const styles = () => ({
  root: {
    backgroundColor: 'red',
    display: 'flex',
    padding: 10,
  },
  title: {
    display: 'block',
    margin: '0 auto',
    fontSize: '1rem',
  },
})

export default styles
