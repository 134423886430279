import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { List, Paper, Typography } from '@mui/material'
import Filters from '@app/containers/Events/Filters/Filters'
import Pagination from '@app/components/Events/Pagination/Pagination'
import EventItem from './EventItem/EventItem'

class Events extends React.PureComponent {
  componentDidMount() {
    const {
      refresh,
      clearFilterByType,
      clearFilterFromDate,
      clearFilterToDate,
      clearFilterByText,
    } = this.props
    clearFilterByType()
    clearFilterFromDate()
    clearFilterToDate()
    clearFilterByText()
    refresh()
  }

  noEvents = () => {
    const {
      classes,
    } = this.props
    return (
      <Paper className={classNames(
        classes.paper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <Typography align="center" className={classes.text}>
          {I18n.t('my_events.no_events')}
        </Typography>
      </Paper>
    )
  }

  noEventsTotal = () => {
    const {
      classes,
    } = this.props
    return (
      <Paper className={classNames(
        classes.paper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <Typography align="center" className={classes.text}>
          {I18n.t('my_events.no_events_yet')}
        </Typography>
      </Paper>
    )
  }

  EventsList = () => {
    const {
      eventsData,
      isMobile,
    } = this.props
    const timeSortedEvents = eventsData.sort((a, b) => new Date(b.happened_at) - new Date(a.happened_at))
    return (timeSortedEvents.map((event, index) => (
      <EventItem key={`event-item-${index + 1}`} event={event} isMobile={isMobile} />
    )))
  }

  renderEventsBlock = (eventsData, total) => {
    if (total === -1) {
      return null
    } if (total === 0) {
      return this.noEventsTotal()
    } if (total > 0 && !eventsData.length) {
      return this.noEvents()
    }
    return this.EventsList()
  }

  render() {
    const {
      eventsData,
      classes,
      total,
    } = this.props
    return (
      <Paper className={classNames(
        classes.root,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <List className={classes.item}>
          <Filters />
          { this.renderEventsBlock(eventsData, total)}
        </List>
        {!!eventsData.length && <Pagination />}
      </Paper>
    )
  }
}

Events.propTypes = {
  eventsData: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.string,
    happened_at: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  classes: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  clearFilterByType: PropTypes.func.isRequired,
  clearFilterFromDate: PropTypes.func.isRequired,
  clearFilterToDate: PropTypes.func.isRequired,
  clearFilterByText: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

Events.displayName = 'Events'

export default Events
