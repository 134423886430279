const styles = () => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    marginBottom: 25,
    height: 32,
  },
  input: {
    fontSize: '24px',
  },
})

export default styles
