// < Update to 371S05 R30
const ProgramOptions = {
  PROGRAM_0: 0, // < Adding/Programming a new mobile phone, radio key and detector.
  USER_DEVICE_FIRST_1: 1, // < User device 1
  USER_DEVICE_LAST_127: 127, // < User device 127
  SENSOR_FIRST_1001: 1001, // < Sensor 1
  SENSOR_LAST_1127: 1127, // < Sensor 127

  PHONE_NUMBER_1_1200: 1200, // < Phone number 1. reserved
  PHONE_NUMBER_2_1201: 1201, // < Phone number 2. reserved
  PHONE_NUMBER_3_1202: 1202, // < Phone number 3. reserved
  PHONE_NUMBER_4_1203: 1203, // < Phone number 4. reserved
  PHONE_NUMBER_5_1204: 1204, // < Phone number 5. reserved
  PHONE_NUMBER_6_1205: 1205, // < Phone number 6. reserved
  PHONE_NUMBER_7_1206: 1206, // < Phone number 7. reserved
  PHONE_NUMBER_8_1207: 1207, // < Phone number 8. reserved

  CLIENT_ACCOUNT_1220: 1220, // < Client Account
  ARMOR_IP_1343: 1343,
  ARMOR_PORT_1344: 1344,
  INACTIVITY_REPORTING_1351: 1351,
  SECONDARY_ARMOR_IP_1353: 1353,
  SECONDARY_ARMOR_PORT_1354: 1354,

  IP_REPORTING_TCP_UPD_1360: 1360, // < Primary Domain Name for Armor IP, up to 30 digits
  PRIMARY_ARMOR_DOMAIN_1363: 1363, // < Primary Domain Name for Armor IP, up to 30 digits
  SECONDARY_ARMOR_DOMAIN_1364: 1364, // < Secondary Domain Name for Armor IP, up to 30 digits

  CSV_REPORTING_USERNAME_1361: 1361, // CSV reporting username
  CSV_REPORTING_PASSWORD_1362: 1362, // CSV reporting password

  DEVICE_SIGNAL_1230: 1230, // < Device signal strength. Simple - any, P1230EGNNNE - specific.
  GSM_SIGNAL_1231: 1231, // < GSM/3G signal strength
  LAST_PROGRAMMED_USER_1240: 1240, // < Get last programmed user number
  LAST_PROGRAMMED_DETECTOR_1241: 1241, // < Get last programmed detector number
  GET_ERROR_CODE_1242: 1242, // < Check Mezzo system and get eror code
  CID_DIALLER_1311: 1311, // < CID dialler
  ENTRY_DELAY_1300: 1300, // < Entry Delay time
  EXIT_DELAY_1301: 1301, // < Exit Delay time
  SUPERVISION_TIME_1302: 1302, // < Supervision time
  LOCK_OUT_1303: 1303, // < Lock out
  VOICE_ON_ARM_1304: 1304, // < Voice on Arm
  VOICE_ON_ALARM_1305: 1305, // < Voice on Alarm
  OPEN_CLOSE_1306: 1306, // < Open/Close
  PIR_EVENT_1372: 1372,
  LOW_BATTERY_1307: 1307, // < Low battery
  ABORT_TIME_1308: 1308, // < Abort time
  MEDICAL_ABORT_TIME_1309: 1309, // < Medical abort time
  TEST_CALL_1310: 1310, // < Test call
  MAIN_VOLUME_1312: 1312, // < Main volume
  DOOR_BELL_1313: 1313, // < Door bell
  DOOR_BELL_VOLUME_1314: 1314, // < Door bell volume
  LIGHTS_1315: 1315, // < Lights
  MEZZO_SSID_1316: 1316, // < Mezzo SSID
  MEZZO_PASSWORD_1317: 1317, // < Mezzo Password
  HOME_SSID_1318: 1318, // < Home SSID
  HOME_PASSWORD_1319: 1319, // < Home Password
  ROUTER_MODE_1320: 1320, // < Router Mode
  ROUTER_MODE_1321: 1321, // < SecuriNet IP Address
  SECURENET_PORT_1322: 1322, // < SecuriNet Port number
  CUSTOMER_1323: 1323, // < Customer Account
  CELLULAR_MAC_1324: 1324, // < Cellular MAC address
  DISABLE_ALL_VOICE_1325: 1325, // < Disable All Voice
  APN_1326: 1326, // < APN name. Will get the APN which is in use.
  MAIN_BOARD_REV_1327: 1327, // < Main board hardware revision number
  ZWAVE_REV_1328: 1328, // < ZWave revision number
  BOOT_LOADER_REV_1329: 1329, // < Boot Loader revision number
  VOICE_REV_1330: 1330, // < Voice file revision number
  CELLULAR_REV_1331: 1331, // < Cellular module revision number
  ROUTER_REV_1332: 1332, // < Mezzo router revision number
  PRODUCT_SERIAL_1333: 1333, // < Mezzo product serial number
  ROUTER_SECURITY_1334: 1334, // < Home Router Security
  SENSITIVITY_MEZZO_UNIT_TAMPER_1335: 1335, // < Sensitivity of Mezzo Unit Tamper
  CLOCK_MODE_1336: 1336, // < Mezzo clock mode
  TOKEN_1337: 1337, // < Mezzo unit token
  ZWAVE_NEW_HOME_ID_1338: 1338, // < Generate new Z-Wave Home ID
  CELLULAR_ANTENNA_1339: 1339, // < Cellular antenna
  FIRMWARE_REV_1340: 1340, // < Mezzo firmware revision number
  CELLULAR_IMEI_1341: 1341, // < Cellular IMEI number
  LOW_POWER_OPERATION_1342: 1342, // < Low power operation

  ROUTER_DEFAULT_SSID_1345: 1345, // < This PO is used by hub during hard-reset (overwrites MEZZO_SSID_1316).
  ROUTER_DEFAULT_WIFI_PASSWORD_1346: 1346, // < This PO is used by hub during hard-reset (overwrites MEZZO_PASSWORD_1317).

  CLOUD_CONNECTION_1347: 1347,
  SIM_ICCID_1352: 1352,
  ARMOR_IP_POLL_TIME_1357: 1357,
  EOL_RESISTOR_VALUES_1373: 1373, // < EoL Resistor Values, XX: 2 digits  Default XX = 00, 2k2 resistor
  CELLULAR_TYPE_1374: 1374, // NA, read only
  NUMBER_OF_ZONES_1375: 1375, // values 1-32

  CONFIG_VERSION_COUNTERS_1348: 1348, // < General, Scene, Z-Wave, Reserved: 4-digit hex counters

  LAST_ROOM_1400: 1400, // < Last named/added ROOM ID number
  ROOM_1_1401: 1401, // < ROOM name for ROOM 1
  ROOM_30_1430: 1430, // < ROOM name for ROOM 30 (max)
  LAST_GROUP_1500: 1500, // < Last named/added GROUP ID number
  GROUP_1_1501: 1501, // < GROUP name for GROUP 1
  GROUP_30_1530: 1530, // < GROUP name for GROUP 30 (max)
  ASSOCIATE_SENSOR_1_1601: 1601, // < Associate SENSOR 1 to ROOM number and/or GROUP number
  ASSOCIATE_SENSOR_127_1727: 1727, // < Associate SENSOR 127 to ROOM number and/or GROUP number

  CHANGE_TO_FACTORY_2000: 2000, // < Change the settings to Factory Default
  PROGRAM_MODE_2001: 2001, // <

  CELLULAR_IMSI_SIZE_12: 12,
}

export default ProgramOptions
