import * as type from '@app/constants/activities/activitiesChannel'

const initialState = {
  isChannelOpen: false,
  activity: null,
  progress: false,
}

export default function activitiesChannel(state = initialState, action) {
  switch (action.type) {
  case type.OPEN_ACTIVITIES_CHANNEL:
    return { ...state, isChannelOpen: true }

  case type.CLOSE_ACTIVITIES_CHANNEL:
    return { ...state, isChannelOpen: false }

  case type.UPDATE_ACTIVITIES_CHANNEL:
    return { ...state, activity: action.data }

  case type.CLEAR_ACTIVITIES_CHANNEL:
    return { ...state, activity: null }

  default:
    return state
  }
}
