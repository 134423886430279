import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
} from '@mui/material'
import getConditionCapabilities from '@app/helpers/scenes/getConditionCapabilities'
import {
  SELECT_CONDITION_DEVICE_TABS,
  SELECT_MULTILEVEL_SWITCH_STATE,
  EDIT_CONDITION,
  CREATE_CONDITION_SELECTED,
  SELECT_COLOR_CONDITION_STATE,
  SELECT_MULTILEVEL_SENSOR_TYPE,
  SELECT_CENTRAL_SCENE_SUB_DEVICE,
  SELECT_MULTICHANNEL_SUB_DEVICE,
  SELECT_DOOR_LOCK_STATE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectConditionDeviceStateType extends React.Component {
  onBackClick= () => {
    const {
      navigate,
      isChangeConditionStateMode,
      restoreConditionInfo,
      isEditConditionMode,
      isChangeConditionMode,
    } = this.props
    if (isEditConditionMode) {
      if (isChangeConditionMode) {
        navigate(SELECT_CONDITION_DEVICE_TABS)
      } else if (isChangeConditionStateMode) {
        restoreConditionInfo()
        navigate(EDIT_CONDITION)
      }
    } else if (isChangeConditionMode) {
      navigate(SELECT_CONDITION_DEVICE_TABS)
    } else if (isChangeConditionStateMode) {
      restoreConditionInfo()
      navigate(CREATE_CONDITION_SELECTED)
    } else {
      navigate(SELECT_CONDITION_DEVICE_TABS)
    }
  }

  getOnClickFunction=(capabilityType) => {
    const { capabilities } = this.props
    if (capabilities.includes('central_scene')) {
      switch (capabilityType) {
      case 'central_scene':
        return this.selectCentralSceneState()
      case 'switch_binary':
        return this.selectBinarySwitchState()
      case 'sensor_binary':
        return this.selectBinarySensorState()
      case 'switch_multilevel':
        return this.selectMultilevelSwitchState()
      case 'switch_color':
        return this.selectColorSwitchState()
      case 'sensor_multilevel':
        return this.selectMultilevelSensorState()
      case 'door_lock':
        return this.selectDoorLockState()
      case 'notification':
        return this.selectNotificationState()
      default:
        throw new Error('capability unsupported')
      }
    } if (capabilities.includes('multichannel')) {
      switch (capabilityType) {
      case 'switch_binary':
        return this.selectBinarySwitchState()
      case 'multichannel_switch':
        return this.selectMultichannelBinarySwitchState()
      case 'sensor_binary':
        return this.selectBinarySensorState()
      case 'switch_multilevel':
        return this.selectMultilevelSwitchState()
      case 'switch_color':
        return this.selectColorSwitchState()
      case 'sensor_multilevel':
        return this.selectMultilevelSensorState()
      case 'door_lock':
        return this.selectDoorLockState()
      case 'notification':
        return this.selectNotificationState()
      default:
        throw new Error('capability unsupported')
      }
    } else {
      switch (capabilityType) {
      case 'switch_binary':
        return this.selectBinarySwitchState()
      case 'sensor_binary':
        return this.selectBinarySensorState()
      case 'switch_multilevel':
        return this.selectMultilevelSwitchState()
      case 'switch_color':
        return this.selectColorSwitchState()
      case 'sensor_multilevel':
        return this.selectMultilevelSensorState()
      case 'door_lock':
        return this.selectDoorLockState()
      case 'notification':
        return this.selectNotificationState()
      default:
        throw new Error('capability unsupported')
      }
    }
  }

  selectBinarySwitchState=() => {
    const { syncZWaveDeviceStates, saveConditionType } = this.props
    saveConditionType('ZWaveDevices')
    syncZWaveDeviceStates()
  }

  selectBinarySensorState=() => {
    const {
      saveConditionType,
      syncZWaveDeviceBinarySensorTypes,
    } = this.props
    saveConditionType('ZWaveDevices')
    syncZWaveDeviceBinarySensorTypes()
  }

  selectMultilevelSwitchState=() => {
    const {
      navigate,
      saveConditionType,
      saveConditionDescription,
    } = this.props
    saveConditionType('ZWaveDevices')
    saveConditionDescription('If')
    navigate(SELECT_MULTILEVEL_SWITCH_STATE)
  }

  selectDoorLockState=() => {
    const {
      navigate,
      saveConditionType,
    } = this.props
    saveConditionType('ZWaveDevices')
    navigate(SELECT_DOOR_LOCK_STATE)
  }

  selectColorSwitchState=() => {
    const {
      navigate,
      saveConditionType,
    } = this.props
    saveConditionType('ZwaveColor')
    navigate(SELECT_COLOR_CONDITION_STATE)
  }

  selectMultilevelSensorState=() => {
    const {
      navigate,
      saveConditionType,
      syncZWaveDeviceMultilevelSensorTypes,
    } = this.props
    saveConditionType('ZwaveSensorMultilevel')
    syncZWaveDeviceMultilevelSensorTypes()
    navigate(SELECT_MULTILEVEL_SENSOR_TYPE)
  }

  selectCentralSceneState=() => {
    const {
      navigate,
      saveConditionType,
    } = this.props
    saveConditionType('ZwaveSceneController')
    navigate(SELECT_CENTRAL_SCENE_SUB_DEVICE)
  }

  selectMultichannelBinarySwitchState=() => {
    const {
      navigate,
      saveConditionType,
    } = this.props
    saveConditionType('ZwaveMultichannel')
    navigate(SELECT_MULTICHANNEL_SUB_DEVICE)
  }

  selectNotificationState=() => {
    const {
      syncSensorNotifications,
    } = this.props
    syncSensorNotifications()
  }

  createCapabilitiesList = (capabilitiesTemplate) => (capabilitiesTemplate.map((capability) => (
    <CardBlock
      key={capability.title}
      title={I18n.t(`scene_web.${capability.title}`)}
      description={I18n.t(`scene_web.${capability.description}`)}
      position="top"
      buttonName="choose"
      isCardDisabled={capability.isCardDisabled}
      isButtonDisabled={capability.isButtonDisabled}
      onClick={() => {
        this.getOnClickFunction(capability.type, capabilitiesTemplate)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
      capabilities,
    } = this.props
    const capabilitiesTemplate = getConditionCapabilities(capabilities)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {capabilitiesTemplate.length
            ? this.createCapabilitiesList(capabilitiesTemplate)
            : <Typography className={classes.noCommandClasses}>{I18n.t('scene_web.no_command_classes')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectConditionDeviceStateType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  syncZWaveDeviceStates: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  restoreConditionInfo: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  capabilities: PropTypes.arrayOf(PropTypes.string.isRequired),
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionMode: PropTypes.bool.isRequired,
  syncZWaveDeviceBinarySensorTypes: PropTypes.func.isRequired,
  syncZWaveDeviceMultilevelSensorTypes: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  syncSensorNotifications: PropTypes.func.isRequired,
}

SelectConditionDeviceStateType.defaultProps = {
  capabilities: [],
}

SelectConditionDeviceStateType.displayName = 'SelectConditionDeviceStateType'

export default SelectConditionDeviceStateType
