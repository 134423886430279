import React from 'react'
import { Paper, Typography } from '@mui/material'
import PropTypes from 'prop-types'

class LoadingCard extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Paper
        className={classes.root}
      >
        <Typography
          variant="h2"
          className={classes.title}
        >
          {I18n.t('titles.loading')}
        </Typography>
        <Typography
          variant="h5"
        >
          {I18n.t('titles.please_wait')}
        </Typography>
      </Paper>
    )
  }
}

LoadingCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

LoadingCard.displayName = 'LoadingCard'

export default LoadingCard
