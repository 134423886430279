import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 67.1958L24.3446 56.8783V35.0529H39.247V47.4868L74.5 25L141 67.1958L135.071 76.1905L125.457 70.1058V125H121.611V68.254L74.5 38.3598L27.5494 68.1217V125H23.7036V70.1058L14.0892 76.1905L8 67.1958ZM65.0458 92.1958H84.1145V125H65.0458V92.1958ZM99.6578 66.2698H108.151V76.0582H99.6578V66.2698ZM41.0096 87.5661H49.5024V78.0423H41.0096V87.5661ZM51.906 87.5661H60.3988V78.0423H51.906V87.5661ZM60.3988 76.0582V66.2698H51.906V76.0582H60.3988ZM49.5024 66.2698H41.0096V76.0582H49.5024V66.2698ZM108.151 87.5661H99.6578V78.0423H108.151V87.5661ZM97.2542 87.5661H88.7614V78.0423H97.2542V87.5661ZM88.7614 76.0582V66.2698H97.2542V76.0582H88.7614ZM88.7614 103.704V113.492H97.2542V103.704H88.7614ZM49.5024 113.492H41.0096V103.704H49.5024V113.492ZM41.0096 92.1958H49.5024V101.72H41.0096V92.1958ZM51.906 92.1958H60.3988V101.72H51.906V92.1958ZM60.3988 103.704V113.492H51.906V103.704H60.3988ZM99.6578 113.492H108.151V103.704H99.6578V113.492ZM108.151 92.1958H99.6578V101.72H108.151V92.1958ZM97.2542 92.1958H88.7614V101.72H97.2542V92.1958Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BigHome')

export default icon
