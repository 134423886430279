import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/changeset/${id}`

const putChangeset = (accessToken, id) => Axios.put(
  ENDPOINT(id),
  null,
  {
    headers: {
      'Access-Token': accessToken,
    },
  },
)

export default {
  putChangeset,
}
