import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import { IDeviceColoursData } from '@app/@types/myDevices'

const postDeviceColours = (access_token: string, id: number, data: IDeviceColoursData) => Axios.post(
  `${API_URL}/api/zwave/devices/${id}/colors`, data, {
    headers: {
      'Access-Token': access_token,
    },
  },
)

export default {
  postDeviceColours,
}
