import React from 'react'
import { Button, TextField } from '@mui/material'
import classNames from 'classnames'
import { codeField } from '@app/lib/Wizard/ForgotPasswordFields'
import { requiredLength } from '@app/lib/Users/codeLength'
import { SIGN_IN } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import NavigationAndTitles from '../NavigationAndTitles/NavigationAndTitles'
import CustomerServiceText from '../CustomerServiceText/CustomerServiceText'
import Info from '../Info/Info'

class Code extends React.PureComponent {
  handleChange = (event) => {
    const {
      saveCode,
      postCode,
    } = this.props
    const { target: { value } } = event
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      saveCode(value)
      if (value.length === requiredLength) {
        postCode()
      }
    }
  }

  popupInfoClose = () => {
    const { closeWrongCodePopup } = this.props
    closeWrongCodePopup()
  }

  resendCodeClick = () => {
    const { postEmail } = this.props
    postEmail()
  }

  render() {
    const {
      classes,
      code,
      isWrongCodePopupOpened,
      isMobile,
      isTablet,
      navigate,
      clearEmail,
      clearCode,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <NavigationAndTitles
            firstTitle="titles.reset_password"
            secondTitle="forgot_password.password_assistance"
            thirdTitle="forgot_password.enter_code_text"
            onBackClick={() => {
              clearEmail()
              clearCode()
              navigate(SIGN_IN)
            }}
            isMobile={isMobile}
          />
          <TextField
            variant="standard"
            className={classes.code}
            id="custom-css-standard-input"
            name={codeField.title}
            placeholder={codeField.placeholder}
            value={code}
            onChange={this.handleChange}
            InputProps={{
              classes: {
                input: classes.codeInput,
              },
              inputProps: {
                type: 'text',
                maxLength: requiredLength,
                required: true,
              },
            }}
          />
          <Button
            className={classes.sendButton}
            variant="contained"
            color="primary"
            onClick={this.resendCodeClick}
          >
            {I18n.t('forgot_password.resend_code_button')}
          </Button>
          <CustomerServiceText />
        </Wrapper>
        <Info
          open={isWrongCodePopupOpened}
          title="my_users.info"
          warningText="errors.invalid_code_forgot_password"
          buttonName="buttons.okay"
          onClick={this.popupInfoClose}
        />
      </Wrapper>
    )
  }
}

Code.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  isWrongCodePopupOpened: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  saveCode: PropTypes.func.isRequired,
  postCode: PropTypes.func.isRequired,
  postEmail: PropTypes.func.isRequired,
  clearCode: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
  closeWrongCodePopup: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

Code.displayName = 'Code'

export default Code
