import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getConditionDescription from '@app/helpers/scenes/getConditionDescription'
import getConditionStateDescription from '@app/helpers/scenes/getConditionStateDescription'
import getRedirectForConditionStateChangeButton from '@app/helpers/scenes/getRedirectForConditionStateChangeButton'
import { sceneConditionSelected, sceneStateSelected } from '@app/lib/Scenes/conditions'
import { MAX_CONDITIONS_CHAIN } from '@app/lib/Scenes/scenesRestrictions'
import {
  SCENE_REVIEW,
  SCENE_REVIEW_EDIT,
  SCENES,
  SELECT_ACTION_TYPE,
  SELECT_CONDITION_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import Info from '@app/components/Common/Info/Info'
import EditPopups from '@app/components/MyAutomation/Tabs/ScenesScreens/EditScenesCommon/EditPopups'
import { IUsersInfo } from '@app/@types/scenes/conditionsTypes'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'
import { useDispatch } from '@app/store'
import { deleteScene } from '@app/actions/scenes/scenesList'
import {
  activateChangeConditionMode,
  activateChangeConditionStateMode,
  addNewConditionToChain,
  closeSceneSaveDraftPopup,
  deactivateChangeConditionMode,
  deactivateChangeConditionStateMode,
  openSceneSaveDraftPopup,
  postCondition,
  saveConditionOperand,
  saveInitialConditionInfo,
  saveScreenName,
  deactivateCreateConditionMode,
} from '@app/actions/scenes/currentSceneCreating'
import {
  openEditDraftSavePopup,
  openEditSceneQuitPopup,
} from '@app/actions/scenes/editScene'
import useStyles from './CreateConditionSelected.style'

const defaultProps = {
  type: '',
}

interface Props {
  isMobile: boolean,
  isSaveSceneDraftPopupOpen: boolean,
  description: string,
  timeOperand: string,
  usersInfo: IUsersInfo,
  type: string,
  stateDescription: string,
  isAdditionalConditionMode: boolean,
  sceneMode: string,
  isAdditionalActionMode: boolean,
  isEditDraftSavePopupOpen: boolean,
  isEditSceneQuitPopupOpen: boolean,
  conditionDeviceName: string,
  conditionNumber: number,
  dataNumber: number,
  deviceState: number,
  triggeringDeviceName: string,
  sceneReviewConditionTypes: Array<string>,
  initialConditions: Array<IConditionFromBackend>,
}

const CreateConditionSelected: React.FC<Props> = (props) => {
  const {
    isMobile,
    isSaveSceneDraftPopupOpen,
    description,
    timeOperand,
    usersInfo,
    type = defaultProps.type,
    stateDescription,
    isAdditionalConditionMode,
    sceneMode,
    isAdditionalActionMode,
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
    conditionDeviceName,
    conditionNumber,
    dataNumber,
    deviceState,
    triggeringDeviceName,
    sceneReviewConditionTypes,
    initialConditions,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  const [isPostButtonDisabled, changePostButtonDisabled] = useState<boolean>(false)
  const [isTooManyConditionsPopupOpen, changeTooManyConditionsPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(deactivateChangeConditionMode())
    dispatch(deactivateChangeConditionStateMode())
    dispatch(saveInitialConditionInfo())
  }, [])

  const getScreenName = () => {
    switch (sceneMode) {
    case 'editMezzoScene':
    case 'editDraftScene':
      return SCENE_REVIEW_EDIT
    case 'create':
      if (isAdditionalConditionMode) {
        return SCENE_REVIEW
      }
      return SELECT_ACTION_TYPE
    default:
      return SCENE_REVIEW
    }
  }

  const selectConditionType = () => {
    dispatch(activateChangeConditionMode())
    navigate(SELECT_CONDITION_TYPE)
  }

  const selectConditionState = () => {
    dispatch(activateChangeConditionStateMode())
    const redirectUrl = getRedirectForConditionStateChangeButton(type, initialConditions)
    navigate(redirectUrl)
  }

  const onSubmitClick = () => {
    const screenName = getScreenName()
    dispatch(saveScreenName(screenName))
    changePostButtonDisabled(true)
    dispatch(deactivateCreateConditionMode())
    dispatch(postCondition())
  }

  const saveDraftAccepted = () => {
    dispatch(saveScreenName(SCENES))
    dispatch(postCondition())
    dispatch(closeSceneSaveDraftPopup())
  }

  const saveDraftDeclined = () => {
    dispatch(deleteScene())
    dispatch(closeSceneSaveDraftPopup())
  }

  const onCloseIconClick = () => {
    dispatch(closeSceneSaveDraftPopup())
  }

  const onBackClick = () => {
    if (isAdditionalConditionMode) {
      if (sceneMode === 'editDraftScene') {
        dispatch(openEditDraftSavePopup())
      } else if (sceneMode === 'editMezzoScene') {
        dispatch(openEditSceneQuitPopup())
      } else {
        navigate(SCENE_REVIEW)
      }
    } else {
      dispatch(openSceneSaveDraftPopup())
    }
  }

  const onAdditionalConditionButtonsClick = (operand: string) => {
    if (sceneReviewConditionTypes.length === MAX_CONDITIONS_CHAIN) {
      changeTooManyConditionsPopupOpen(true)
    } else {
      dispatch(saveScreenName(SELECT_CONDITION_TYPE))
      changePostButtonDisabled(true)
      dispatch(saveConditionOperand(operand))
      dispatch(postCondition())
      dispatch(addNewConditionToChain(type))
    }
  }

  const renderTooManyConditionsPopup = () => (
    <>
      <Info
        open={isTooManyConditionsPopupOpen}
        title="my_users.info"
        warningText="errors.conditions_maximum_error"
        onClick={() => {
          changeTooManyConditionsPopupOpen(!isTooManyConditionsPopupOpen)
        }}
        buttonName="buttons.okay"
      />
    </>
  )

  const renderSceneCreatingAdditionalButtons = () => (
    <Wrapper className={classes.sceneCreatingAdditionalWrapper}>
      <Typography className={classes.optionsTitle}>add more options</Typography>
      <Wrapper className={classes.buttonThirdSizeWrapper}>
        <Button
          onClick={() => onAdditionalConditionButtonsClick('and')}
          variant="outlined"
          className={classNames(classes.buttonThirdSize, classes.andOrButtions)}
        >
          {I18n.t('buttons.and')}
        </Button>
        <Button
          onClick={() => onAdditionalConditionButtonsClick('or')}
          variant="outlined"
          className={classNames(classes.buttonThirdSize, classes.andOrButtions)}
        >
          {I18n.t('buttons.or')}
        </Button>
        <Button
          onClick={() => onSubmitClick()}
          variant="outlined"
          className={classNames(classes.buttonThirdSize, classes.thenButton)}
          disabled={isPostButtonDisabled}
        >
          {I18n.t('buttons.then')}
        </Button>
      </Wrapper>
    </Wrapper>
  )

  const usersNames = Object.entries(usersInfo).length === 0 ? [] : usersInfo.usersNames.join(', ')
  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={onBackClick}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.create_condition')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        <Wrapper>
          <CardBlock
            title={I18n.t(`scene_web.${sceneConditionSelected.title}`)}
            description={getConditionDescription(type, description, conditionDeviceName, conditionNumber)}
            position={sceneConditionSelected.position}
            isCardDisabled={sceneConditionSelected.isCardDisabled}
            isButtonDisabled={sceneConditionSelected.isButtonDisabled}
            onClick={() => {
              selectConditionType()
            }}
          />
          <CardBlock
            title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
            hasAdditionalDesc={type === 'ZwaveColor'}
            descColor={stateDescription}
            description={getConditionStateDescription(
              type, stateDescription, timeOperand, usersNames, dataNumber, deviceState, triggeringDeviceName,
            )}
            position={stateDescription === '' ? 'noneDescr' : 'bottom'}
            isCardDisabled={stateDescription === ''}
            isButtonDisabled={false}
            onClick={() => {
              selectConditionState()
            }}
          />
        </Wrapper>
        { (sceneMode !== 'create' || (sceneMode === 'create' && isAdditionalConditionMode)) && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.submitButton}
            onClick={onSubmitClick}
            disabled={isPostButtonDisabled}
          >
            {I18n.t('buttons.submit')}
          </Button>
        )}
        {(sceneMode === 'create' && !isAdditionalConditionMode) && renderSceneCreatingAdditionalButtons()}
      </Wrapper>
      <WarningComponent
        open={isSaveSceneDraftPopupOpen}
        onClose={() => {
          saveDraftDeclined()
        }}
        withCloseIcon
        onCloseIconClick={onCloseIconClick}
        onClick={saveDraftAccepted}
        warningText="scene_web.save_draft"
        leftButton="yeah"
        rightButton="nope"
      />
      <EditPopups
        isEditDraftSavePopupOpen={isEditDraftSavePopupOpen}
        isEditSceneQuitPopupOpen={isEditSceneQuitPopupOpen}
        sceneMode={sceneMode}
        isAdditionalConditionMode={isAdditionalConditionMode}
        isAdditionalActionMode={isAdditionalActionMode}
      />
      {renderTooManyConditionsPopup()}
    </Paper>
  )
}

export default CreateConditionSelected
