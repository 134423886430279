const styles = () => ({
  input: {
    maxWidth: '60%',
  },
  text: {
    color: '#f44336',
    position: 'absolute',
    top: 28,
  },
  icon: {
    fontSize: 40,
    position: 'absolute',
    top: 5,
    left: 8,
  },
})

export default styles
