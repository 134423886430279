import oAuthApplications from '@app/lib/oAuthApplications'

const oAuthApplication = (host = window.location.hostname) => {
  if (host.includes('ngrok')) {
    const applicationName = `${new URL(process.env.API_URL).hostname.split('.')[0].replace('api', 'web')}`
    return oAuthApplications[applicationName]
  }
  const [subdomain] = host.split('.')
  return oAuthApplications[subdomain]
}

export default oAuthApplication
