const filterDevicesByName = (state, action) => {
  if (action.deviceName === '') {
    return {
      ...state,
      zWaveDevices: state.backupZWaveDevices,
      twrDevices: state.backupTwrDevices,
    }
  }
  return {
    ...state,
    zWaveDevices: state.zWaveDevices.filter((device) => device.name.match(new RegExp(action.deviceName, 'gi'))),
    twrDevices: state.twrDevices.filter((device) => device.name.match(new RegExp(action.deviceName, 'gi'))),
  }
}

export default filterDevicesByName
