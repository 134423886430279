import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M32.6239 89.433C32.6239 83.7379 28.0071 79.1211 22.3119 79.1211C16.6185 79.1252 12.0041 83.7396 12 89.433C12 95.1281 16.6168 99.745 22.3119 99.745C28.0071 99.745 32.6239 95.1281 32.6239 89.433ZM22.3127 81.1211C26.9029 81.1215 30.6239 84.8427 30.6239 89.433C30.6239 94.0236 26.9025 97.745 22.3119 97.745C17.7216 97.745 14.0004 94.024 14 89.4338C14.0037 84.8444 17.7233 81.1248 22.3127 81.1211Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M140.624 90.433C140.624 84.7379 136.007 80.1211 130.312 80.1211C124.619 80.1252 120.004 84.7396 120 90.433C120 96.1281 124.617 100.745 130.312 100.745C136.007 100.745 140.624 96.1281 140.624 90.433ZM130.313 82.1211C134.903 82.1215 138.624 85.8427 138.624 90.433C138.624 95.0236 134.902 98.745 130.312 98.745C125.722 98.745 122 95.024 122 90.4338C122.004 85.8444 125.723 82.1248 130.313 82.1211Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.1579 92.5932C23.902 92.5932 25.3159 91.1794 25.3159 89.4353C25.3159 87.6912 23.902 86.2773 22.1579 86.2773C20.4139 86.2773 19 87.6912 19 89.4353C19 91.1794 20.4139 92.5932 22.1579 92.5932Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M130.158 93.5932C131.902 93.5932 133.316 92.1794 133.316 90.4353C133.316 88.6912 131.902 87.2773 130.158 87.2773C128.414 87.2773 127 88.6912 127 90.4353C127 92.1794 128.414 93.5932 130.158 93.5932Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.158 52.5318C22.6622 52.5318 23.071 52.9405 23.071 53.4447C23.071 53.9489 22.6622 54.3577 22.158 54.3577C21.6538 54.3577 21.2451 53.9489 21.2451 53.4447C21.2451 52.9405 21.6538 52.5318 22.158 52.5318V52.5318ZM22.1579 50.2852C20.4139 50.2852 19 51.699 19 53.4431C19 55.1872 20.4139 56.601 22.1579 56.601C23.902 56.601 25.3159 55.1872 25.3159 53.4431C25.3159 51.699 23.902 50.2852 22.1579 50.2852V50.2852Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M130.158 53.5318C130.662 53.5318 131.071 53.9405 131.071 54.4447C131.071 54.9489 130.662 55.3577 130.158 55.3577C129.654 55.3577 129.245 54.9489 129.245 54.4447C129.245 53.9405 129.654 53.5318 130.158 53.5318V53.5318ZM130.158 51.2852C128.414 51.2852 127 52.699 127 54.4431C127 56.1872 128.414 57.601 130.158 57.601C131.902 57.601 133.316 56.1872 133.316 54.4431C133.316 52.699 131.902 51.2852 130.158 51.2852V51.2852Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7 46C7 45.4477 7.44772 45 8 45H36C36.5523 45 37 45.4477 37 46V104.248C37 104.8 36.5523 105.248 36 105.248H8C7.44772 105.248 7 104.8 7 104.248V46ZM11 101.248V49H33V101.248H11Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M115 47C115 46.4477 115.448 46 116 46H144C144.552 46 145 46.4477 145 47V105.248C145 105.8 144.552 106.248 144 106.248H116C115.448 106.248 115 105.8 115 105.248V47ZM119 102.248V50H141V102.248H119Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M68.6074 95.9214C69.414 95.1148 69.414 93.8071 68.6074 93.0005C67.8008 92.1939 66.4931 92.1939 65.6865 93.0005C64.8799 93.8071 64.8799 95.1148 65.6865 95.9214C66.4931 96.728 67.8008 96.728 68.6074 95.9214Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M78.749 95.9214C79.5556 95.1148 79.5556 93.8071 78.749 93.0005C77.9424 92.1939 76.6347 92.1939 75.8281 93.0005C75.0215 93.8071 75.0215 95.1148 75.8281 95.9214C76.6347 96.728 77.9424 96.728 78.749 95.9214Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M88.8818 95.9214C89.6884 95.1148 89.6884 93.8071 88.8818 93.0005C88.0752 92.1939 86.7675 92.1939 85.9609 93.0005C85.1544 93.8071 85.1544 95.1148 85.9609 95.9214C86.7675 96.728 88.0752 96.728 88.8818 95.9214Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M99.0176 95.9253C99.8241 95.1187 99.8241 93.811 99.0176 93.0044C98.211 92.1978 96.9033 92.1978 96.0967 93.0044C95.2901 93.811 95.2901 95.1187 96.0967 95.9253C96.9033 96.7319 98.211 96.7319 99.0176 95.9253Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M104.19 75.6641H47.1846C44.4231 75.6641 42.1846 77.9026 42.1846 80.6641V100.246C42.1846 103.007 44.4231 105.246 47.1846 105.246H104.19C106.952 105.246 109.19 103.007 109.19 100.246V80.6641C109.19 77.9026 106.952 75.6641 104.19 75.6641ZM46.1846 80.6641C46.1846 80.1118 46.6323 79.6641 47.1846 79.6641H104.19C104.742 79.6641 105.19 80.1118 105.19 80.6641V100.246C105.19 100.798 104.742 101.246 104.19 101.246H47.1846C46.6323 101.246 46.1846 100.798 46.1846 100.246V80.6641Z" fill="currentColor"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwStereoIcon')

export default icon
