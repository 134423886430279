import React from 'react'
import createSvgIcon from './../../helper'

const fragment = (
  <React.Fragment>
    <svg width="37" height="32" viewBox="0 0 50 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z" stroke="currentColor" strokeWidth="1.1"/>
      <rect x="25" y="8" width="7" height="17" fill="#333333"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.8814 13.4114C10.0599 15.0255 10.0899 17.8786 11.945 19.454L20.2435 26.5013V21.2672H35.4088C35.9611 21.2672 36.4088 20.8195 36.4088 20.2672V12.6715C36.4088 12.1192 35.9611 11.6715 35.4088 11.6715L20.2435 11.6715V6.00127L11.8814 13.4114Z" fill="currentColor" stroke="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Entering', '0 0 28 28', {fontSize: 100})

export default icon
