import React from 'react'
import { Button, Paper, Typography } from '@mui/material'
import {
  Circle, GoogleMap, LoadScript, Marker,
} from '@react-google-maps/api'
import classNames from 'classnames'
import calculateBounds from '@app/helpers/scenes/calculateBoundsForCircle'
import { libraries, options } from '@app/lib/Scenes/geofences'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_GEOFENCES_USER } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import { Entering, Exiting } from 'icons'

class SelectGeofencesState extends React.PureComponent {
  state = {
    isExiting: false,
  }

  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_GEOFENCES_USER)
  }

  onAddClick = () => {
    const {
      navigate,
      saveConditionGeofenceExit,
      saveConditionStateDescription,
      isEditConditionMode,
    } = this.props
    const { isExiting } = this.state
    saveConditionStateDescription(isExiting ? 'exits out' : 'enters in')
    saveConditionGeofenceExit(isExiting)
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      lat,
      lng,
      size,
      isMobile,
      address,
    } = this.props
    const {
      isExiting,
    } = this.state
    const coordinates = { lat, lng }
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.main}>
          <Wrapper className={classes.buttonsContainer}>
            <Wrapper
              className={classNames(classes.stateButton, !isExiting && classes.active)}
              onClick={() => (this.setState({ isExiting: false }))}
            >
              <Entering className={classes.iconEnter} />
              <Typography>{I18n.t('scene_web.enters_in')}</Typography>
            </Wrapper>
            <Wrapper
              className={classNames(classes.stateButton, isExiting && classes.active)}
              onClick={() => (this.setState({ isExiting: true }))}
            >
              <Typography>{I18n.t('scene_web.exits_out')}</Typography>
              <Exiting className={classes.iconExit} />
            </Wrapper>
          </Wrapper>
          <Wrapper className={classes.mapContainer}>
            <LoadScript
              id="script-loader"
              googleMapsApiKey="AIzaSyAX8HCt8teGLIgx7AWiJY3PDHifmHb7V_s"
              libraries={libraries}
            >
              <GoogleMap
                id="searchbox"
                mapContainerStyle={{
                  height: isMobile ? '400px' : '580px',
                  width: '100%',
                }}
                zoom={calculateBounds(size)}
                center={coordinates}
                options={{ streetViewControl: false, disableDefaultUI: true }}
              >
                <Circle
                  radius={size}
                  center={coordinates}
                  options={options}
                />
                <Marker position={coordinates} />
              </GoogleMap>
            </LoadScript>
          </Wrapper>
          <Typography className={classes.address}>{address}</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.onAddClick}
            className={classes.button}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectGeofencesState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  lng: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionGeofenceExit: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
}

SelectGeofencesState.displayName = 'SelectGeofencesStateIOS'

export default SelectGeofencesState
