import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  rootMobile: {
    padding: '1rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  settingsGrid: {
    display: 'grid',
    gridGap: '1.25rem',
    alignItems: 'center',
    justifyItems: 'center',
    height: '100%',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr));',
  },
  settingsGridMobile: {
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
  },
  setting: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 320,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 88,
    padding: '5px 17px',
    borderRadius: 5,
    marginBottom: 40,
  },
  settingMobile: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 88,
    padding: '0 17px',
    borderRadius: 5,
    marginBottom: 20,
  },
  cardLeftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  resetButton: {
    color: '#d50000',
    height: 30,
  },
  timerTitle: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  timerType: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.texts.halfVisible,
    margin: '3px 0',
  },
  timerDuration: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.texts.halfVisible,
  },
}))

export default useStyles
