import React from 'react'
import { Paper, Typography } from '@mui/material'
import { withTheme, withStyles } from '@mui/styles'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './SelectMezzoConnection.style'
import MezzoList from './MezzoList/MezzoList'

class SelectMezzoConnection extends React.PureComponent {
  render() {
    const {
      classes,
      isMobile,
      isTablet,
      theme,
      devices,
      selectUnit,
      patchUnit,
      updateCurrentUserRole,
      ...other
    } = this.props
    return (
      <Paper className={classes.root}>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <Wrapper className={classes.mainLogo}>
            {!isMobile ? theme.loginLogo : theme.loginLogoMobile}
          </Wrapper>
          <Typography className={classNames(
            classes.title,
            (!isMobile || isTablet) && classes.titleDesktop,
          )}
          >
            Select Mezzo for connection
          </Typography>
          <MezzoList
            {...other}
            theme={theme}
            devices={devices}
            selectUnit={selectUnit}
            patchUnit={patchUnit}
            updateCurrentUserRole={updateCurrentUserRole}
          />
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoConnection.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({
    serial_number: PropTypes.string,
    ssid: PropTypes.string,
  })).isRequired,
  selectUnit: PropTypes.func.isRequired,
  patchUnit: PropTypes.func.isRequired,
  updateCurrentUserRole: PropTypes.func.isRequired,
}

SelectMezzoConnection.displayName = 'SelectMezzoConnection'

export default withTheme(withStyles(styles)(SelectMezzoConnection))
