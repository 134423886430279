import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import sortDevicesGroups from '@app/helpers/scenes/sortDevicesGroups'
import { SYNC_TWR_DEVICES_BY_GROUPS } from '@app/constants/scenes/twrDevices/twrDevicesByGroups'
import { updateTwrDevicesByGroups } from '@app/actions/scenes/twrDevices/twrDevicesByGroups'
import fetchTwrDevicesByGroups from '@app/api/scenes/TwrDevices/fetchTwrDevicesByGroups'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchTwrDevicesByGroupsLogic = createLogic({
  type: SYNC_TWR_DEVICES_BY_GROUPS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_TWR_DEVICES_BY_GROUPS))
    fetchTwrDevicesByGroups(token)
      .then((response) => {
        const { data: { groups } } = response
        const sortedGroups = sortDevicesGroups(groups, 'TwrDevices')
        dispatch(updateTwrDevicesByGroups(sortedGroups))
        dispatch(apiRequestDone(SYNC_TWR_DEVICES_BY_GROUPS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchTwrDevicesByGroups failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchTwrDevicesByGroupsLogic
