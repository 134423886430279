import React from 'react'
import classNames from 'classnames'
import {
  Card, CardActions, CardContent, IconButton, ListItemIcon, Typography,
} from '@mui/material'
import { ClearIcon, ExpandMore } from 'icons'
import getContrastColor from '@app/helpers/scenes/getContrastColor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './CardBlock.style'

const defaultProps = {
  title: null,
  icon: null,
  description: null,
  secondDescription: null,
  position: null,
  onClick: () => {},
  withIcon: false,
  isMobile: false,
  hasAdditionalDesc: false,
  hasSecondAdditionalDesc: false,
  descColor: '',
  secondDescColor: '',
  hasClearButton: false,
  clearDescription: () => {},
  delayDesc: '',
  durationDesc: '',
  isValueButton: false,
  showExpandMoreButton: true,
}

interface Props {
  icon?: any,
  title?: string,
  description?: string | Array<string>,
  secondDescription?: string,
  position?: string,
  withIcon?: boolean,
  isCardDisabled: boolean,
  isButtonDisabled: boolean,
  isMobile?: boolean,
  hasAdditionalDesc?: boolean,
  hasSecondAdditionalDesc?: boolean,
  descColor?: string,
  delayDesc?: string,
  durationDesc?: string,
  secondDescColor?: string,
  hasClearButton?: boolean,
  clearDescription?: () => void,
  onClick?: () => void,
  isValueButton?: boolean,
  showExpandMoreButton?: boolean,
}

const CardBlock: React.FC<Props> = (props) => {
  const {
    icon = defaultProps.icon,
    title = defaultProps.title,
    description = defaultProps.description,
    secondDescription = defaultProps.secondDescription,
    position = defaultProps.position,
    withIcon = defaultProps.withIcon,
    isCardDisabled,
    isButtonDisabled,
    isMobile = defaultProps.isMobile,
    hasAdditionalDesc = defaultProps.hasAdditionalDesc,
    hasSecondAdditionalDesc = defaultProps.hasSecondAdditionalDesc,
    descColor = defaultProps.descColor,
    delayDesc = defaultProps.delayDesc,
    durationDesc = defaultProps.durationDesc,
    secondDescColor = defaultProps.secondDescColor,
    hasClearButton = defaultProps.hasClearButton,
    clearDescription = defaultProps.clearDescription,
    onClick = defaultProps.onClick,
    isValueButton = defaultProps.isValueButton,
    showExpandMoreButton = defaultProps.showExpandMoreButton,
  } = props
  const classes = useStyles()
  const showTopButton = () => (
    (
      <Wrapper>
        <Wrapper className={classes.typeButtonWrapper}>
          <Typography className={classes.blockTitleTop}>{title}</Typography>
          <CardActions
            className={classes.buttonWrapper}
            onClick={!isButtonDisabled ? onClick : () => {}}
          >
            {showExpandMoreButton && (
              <ExpandMore
                className={classes.button}
                color="primary"
                disabled={isButtonDisabled}
              />
            )}
          </CardActions>
        </Wrapper>
      </Wrapper>
    )
  )

  const showMiddleButton = () => (
    (
      <Wrapper className={classes.typeButtonWrapper}>
        <Typography className={classes.blockDescription}>{description}</Typography>
        <CardActions
          className={classes.buttonWrapper}
          onClick={!isButtonDisabled ? onClick : () => {}}
        >
          {!isValueButton && (
            <ExpandMore
              className={classes.button}
              color="primary"
              disabled={isButtonDisabled}
            />
          )}
        </CardActions>
      </Wrapper>
    )
  )

  const showBlockInfo = () => (
    <Wrapper className={classes.cardText}>
      <Typography className={
        position === 'top'
          ? classes.blockDescriptionTop
          : classes.blockDescription
      }
      >
        {description}
        {hasAdditionalDesc
        && (
          <span
            className={classes.additionalDesc}
            style={{ backgroundColor: descColor, borderColor: getContrastColor(descColor) }}
          />
        )}
      </Typography>
      {delayDesc && (
        <Typography className={
          position === 'top'
            ? classes.blockDescriptionTop
            : classes.blockDescription
        }
        >
          {delayDesc}
        </Typography>
      )}
      {durationDesc && (
        <Typography className={
          position === 'top'
            ? classes.blockDescriptionTop
            : classes.blockDescription
        }
        >
          {durationDesc}
        </Typography>
      )}
      {hasSecondAdditionalDesc && (
        <Typography className={
          position === 'top'
            ? classes.blockDescriptionTop
            : classes.blockDescription
        }
        >
          {secondDescription}
          {secondDescColor && (
            <span
              className={classes.additionalDesc}
              style={{ backgroundColor: secondDescColor, borderColor: getContrastColor(secondDescColor) }}
            />
          )}
        </Typography>
      )}
      {hasClearButton && (
        <IconButton
          onClick={clearDescription}
          aria-label="Delete"
          className={classes.clearButton}
          size="large"
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      )}
    </Wrapper>
  )

  return (
    <Card className={classNames(isCardDisabled ? classes.cardDisabled : classes.card, isValueButton && classes.valueButton)}>
      <CardContent className={classNames(
        isMobile ? classes.cardContentMobile : classes.cardContent,
      )}
      >
        { withIcon && (
          <ListItemIcon className={classNames(
            !isMobile && classes.svgWrapper,
          )}
          >
            {icon}
          </ListItemIcon>
        )}
        <Wrapper className={classes.cardData}>
          { position === 'middle'
              && (
                <>
                  {showMiddleButton()}
                  <Typography className={classes.blockTitle}>{title}</Typography>
                </>
              )}
          { position === 'top'
              && (
                <>
                  {showTopButton()}
                  {showBlockInfo()}
                </>
              )}
          {position === 'bottom'
            && (
              <>
                <Typography className={classes.blockTitle}>{title}</Typography>
                {showBlockInfo()}
                <CardActions
                  className={classes.buttonWrapper}
                  onClick={!isButtonDisabled ? onClick : () => {}}
                >
                  <ExpandMore
                    className={classes.button}
                    color="primary"
                    disabled={isButtonDisabled}
                  />
                </CardActions>
              </>
            )}
          { position === 'topDoubleDescr'
          && (
            <>
              {showTopButton()}
              <Typography className={classes.blockTitle}>{description}</Typography>
              <Typography className={classes.blockTitle}>{secondDescription}</Typography>
            </>
          )}
          { position === 'noneDescr'
            && (
              <>
                <Typography className={classes.blockTitle}>{title}</Typography>
                <Typography className={classes.blockDescription}>{description}</Typography>
              </>
            )}
        </Wrapper>
      </CardContent>
    </Card>
  )
}

export default CardBlock
