import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_MANUAL_UPDATE, SYNC_START_DOWNLOADING } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import patchCloudFirmwareInfo from '@app/api/cloudFirmwareUpdate/patchCloudFirmwareInfo'
import startDownloadingFirmware from '@app/api/cloudFirmwareUpdate/startDownloadingFirmware'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const manualUpdateLogic = createLogic({
  type: SYNC_MANUAL_UPDATE,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { cloudFirmwareUpdate: { cloudFirmwareUpdateInfo: { downloadingType, autoInstall, selectedFirmwareVersion } } } = store.getState()
    const newData = { downloading_type: downloadingType, autoinstall: autoInstall, selected_version: selectedFirmwareVersion }
    dispatch(apiRequestSent(SYNC_MANUAL_UPDATE))
    patchCloudFirmwareInfo(token, newData)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(apiRequestDone(SYNC_MANUAL_UPDATE))
      })
      .then(() => {
        dispatch(apiRequestSent(SYNC_START_DOWNLOADING))
        startDownloadingFirmware(token)
          .then((response) => {
            const { data } = response
            dispatch(update(data))
            apiRequestDone(SYNC_START_DOWNLOADING)
          })
          .catch((error) => {
            if (error) {
              const currentError = handleErrors(error)
              dispatch(apiRequestFailed(currentError))
            } else {
              throw new Error('startDownloadingFirmware failed without error')
            }
          })
          .then(() => done())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchCloudFirmwareInfo failed without error')
        }
      })
      .then(() => done())
  },
})

export default manualUpdateLogic
