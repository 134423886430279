import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveConditionDataNumber, saveConditionStateDescription, saveConditionDataValue } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectCentralSceneSubDevice
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDevice/SelectCentralSceneSubDevice'
import SelectCentralSceneSubDeviceIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/WallmoteConditions/CentralSceneCondition/SelectCentralSceneSubDevice/SelectCentralSceneSubDevice'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectCentralSceneSubDeviceIOS) : withStyles(SelectCentralSceneSubDevice)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode, isChangeConditionStateMode, isChangeSubDeviceMode } = state.scenes.currentSceneCreating
  const { subDevicesAmount, wallmoteSettings } = state.scenes.currentSceneCreating.currentCondition
  return {
    isMobile,
    isEditConditionMode,
    isChangeConditionStateMode,
    isChangeSubDeviceMode,
    subDevicesAmount,
    wallmoteSettings,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionDataNumber,
  saveConditionStateDescription,
  saveConditionDataValue,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectCentralSceneSubDeviceEnhanced = enhance

export default withRouter(SelectCentralSceneSubDeviceEnhanced)
