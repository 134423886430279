import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Button, Collapse, Grid, List, ListItem, ListItemText,
} from '@mui/material'
import { ExpandLess, ExpandMore } from 'icons'
import FilterByType from './FilterByType/FilterByType'
import FilterByText from './FilterByText/FilterByText'
import FilterFromDate from './FilterFromDate/FilterFromDate'
import FilterToDate from './FilterToDate/FilterToDate'

class Filters extends React.Component {
  state = {
    open: false,
  }

  toggleFilters = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  onResetClick =() => {
    const {
      clearFilterByType,
      clearFilterFromDate,
      clearFilterToDate,
      clearFilterByText,
      reset,
    } = this.props
    clearFilterByType()
    clearFilterFromDate()
    clearFilterToDate()
    clearFilterByText()
    reset()
  }

  submitFilters =(evt) => {
    const {
      submit,
      saveCurrentPage,
    } = this.props
    evt.preventDefault()
    saveCurrentPage(1)
    submit()
  }

  render() {
    const {
      classes,
      menuItemName,
      setFilterByType,
      clearFilterByType,
      isButtonDisabled,
      filterByType,
      filterByText,
      setFilterByText,
      clearFilterByText,
    } = this.props
    const { open } = this.state
    return (
      <form
        onSubmit={this.submitFilters}
        className={classNames(
          classes.root,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
        )}
      >
        <List className={classes.list}>
          <ListItem
            button
            onClick={this.toggleFilters}
            divider
            className={classNames(
              (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
              && classes.filterWebView,
            )}
          >
            <ListItemText inset primary={I18n.t('titles.filters_title_events')} />
            {open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
          </ListItem>
          <Collapse
            className={classes.wrapper}
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Grid container>
              <Grid container item xs={12} className={classes.container}>
                <Grid item lg={5}>
                  <FilterByType
                    menuItemName={menuItemName}
                    setFilterByType={setFilterByType}
                    clearFilterByType={clearFilterByType}
                    filterByType={filterByType}
                  />
                </Grid>
                <Grid item lg={5}>
                  <FilterByText
                    setFilterByText={setFilterByText}
                    clearFilterByText={clearFilterByText}
                    filterByText={filterByText}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} className={classes.container}>
                <Grid item lg={5}>
                  <FilterFromDate />
                </Grid>
                <Grid item lg={5} className={classes.toFilterItem}>
                  <FilterToDate />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.button}>
                <Button
                  disabled={isButtonDisabled}
                  className={classes.btn}
                  variant="outlined"
                  color="primary"
                  type="submit"
                >
                  {I18n.t('buttons.submit')}
                </Button>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  color="primary"
                  onClick={this.onResetClick}
                >
                  {I18n.t('buttons.reset')}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </List>
      </form>
    )
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItemName: PropTypes.string,
  setFilterByType: PropTypes.func.isRequired,
  clearFilterByType: PropTypes.func.isRequired,
  clearFilterFromDate: PropTypes.func.isRequired,
  clearFilterToDate: PropTypes.func.isRequired,
  setFilterByText: PropTypes.func.isRequired,
  clearFilterByText: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  filterByType: PropTypes.string.isRequired,
  filterByText: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  saveCurrentPage: PropTypes.func.isRequired,
}
Filters.defaultProps = {
  menuItemName: null,
}

Filters.displayName = 'Filters'

export default Filters
