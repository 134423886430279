const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  mainDesktop: {
    width: '90%',
    padding: '60px 0',
  },
  dayWrapper: {
    backgroundColor: theme.backgrounds.cardBackground,
    margin: '0 0 12px 0',
    padding: '13px 20px 13px 24px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  buttonsWrapperDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  stateTitle: {
    fontSize: '18px',
    color: '#929292',
    margin: '0 0 7px 3px',
  },
  stateTitleDesktop: {
    fontSize: '18px',
    color: '#929292',
    margin: '0 0 20px 3px',
  },
  weekTitle: {
    fontSize: '18px',
    color: '#929292',
    margin: '0 0 7px 3px',
  },
  weekTitleDesktop: {
    fontSize: '18px',
    color: '#929292',
    margin: '0 0 5px 3px',
  },
  everyButton: {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    padding: 0,
  },
  daysWrapper: {
    marginLeft: 3,
  },
  checkbox: {
    margin: '0 4px 0 0',
    padding: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxDesktop: {
    margin: '0 4px 0 0',
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxIcon: {
    padding: 0,
  },
  addButton: {
    width: '100%',
    height: 36,
    marginTop: 20,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
