import { connect, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import QueryString from 'query-string'
import { bindActionCreators } from 'redux'
import { isMobile, isTablet } from 'react-device-detect'
import themes from '@app/themes'
import getTheme from '@app/helpers/getTheme'
import { setNavigationStatus } from '@app/actions/menu'
import { signOut as signOutAction } from '@app/actions/signOut'
import { sync } from '@app/actions/hotUpdate'
import { refreshTokenInterceptor } from '@app/actions/secrets'
import { checkMobile, checkTablet } from '@app/actions/mobileDetector'
import { closeDevicesChannel } from '@app/actions/myDevices/channels/devices/devicesChannel'
import loginUrlsBlocking from '@app/helpers/loginUrlsBlocking'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import RefreshToken from '@app/components/Common/RefreshToken/RefreshToken'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { refreshUrls } from '@app/lib/Wizard/refreshUrls'
// @ts-ignore
import openCable from '@app/api/cable'
// @ts-ignore
import type { AppDispatch } from '@app/store'
import { AppState } from '@app/@types/store'
import { DEVICES_CHANNEL } from '@app/constants/channels'
import { getAppConfig } from '@app/actions/appConig/appConfig'
import ActivationPopups from '@app/components/Wizard/ActivationPopups/ActivationPopups'
import { CookiesProvider } from 'react-cookie'
import ScrollToTop from '@app/hooks/scrollToTop'
// @ts-ignore
import Layout from './Layout'
import packageJson from '../../../../package.json'
// @ts-ignore
import SyncPopups from './Synchronize/SyncPopups'

const NOTIFICATIONS_WARNING_SHOWING = 3000

interface Props {
  setNavigation: (navigation: boolean) => void,
  checkMobileStatus: (isMobile: boolean) => void,
  checkTabletStatus: (isTable: boolean) => void,
  hotUpdate: () => void,
  syncConfig: () => void,
  accessToken: string,
  isWebView: boolean,
  version: string,
  signOut: () => void,
  desktop?: boolean,
  menuOpen?: boolean,
  isShowNavigation: boolean,
  isChannelOpen?: boolean,
  accessDenied: boolean,
  refreshToken: () => void,
  currentUrl: string,
  userLoggedIn: boolean,
  authProgress: boolean,
}

const App: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isNotificationsTimeout, setIsNotificationsTimeout] = useState<boolean>(true)
  const {
    setNavigation,
    checkMobileStatus,
    checkTabletStatus,
    hotUpdate,
    accessToken,
    isWebView,
    version,
    signOut,
    desktop = false,
    menuOpen = false,
    isShowNavigation,
    isChannelOpen = false,
    accessDenied,
    refreshToken,
    currentUrl,
    userLoggedIn,
    authProgress,
    syncConfig,
  } = props
  const isWizardUrls = loginUrlsBlocking(currentUrl, refreshUrls)

  useEffect(() => {
    checkMobileStatus(isMobile)
    checkTabletStatus(isTablet)
    const params = QueryString.parse(location.search)
    if (isWizardUrls) {
      setNavigation(false)
    } else if (params.navigation) {
      const navigation = params.navigation !== 'hide'
      setNavigation(navigation)
    } else {
      setNavigation(true)
    }
    if (!isWizardUrls) {
      setTimeout(() => setIsNotificationsTimeout(false), NOTIFICATIONS_WARNING_SHOWING)
      if (accessToken && !isWebView) {
        if (version !== packageJson.version) {
          signOut()
        } else {
          hotUpdate()
          syncConfig()
        }
      }
      if (accessToken && isWebView) {
        hotUpdate()
        syncConfig()
      }
    }
  }, [isWizardUrls])

  useEffect(() => {
    const cable = openCable()
    if (!location.pathname.includes('my_devices')
        && cable && cable.subscriptions.subscriptions
      .find((subscription: { identifier: string }) => subscription.identifier.includes(DEVICES_CHANNEL))) {
      dispatch(closeDevicesChannel())
    }
  }, [location])

  const params = QueryString.parse(location.search)
  const brand: string = getTheme(window.location.hostname, params)
  const theme: Record<string, string> = themes[brand]
  return (
    <ScrollToTop location={location}>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <Layout
          brand={brand}
          logos={theme.logos}
          desktop={desktop}
          menuOpen={menuOpen}
          navigation={isShowNavigation}
          authProgress={authProgress}
          currentUrl={currentUrl}
          notificationsWarning={
            !isNotificationsTimeout
          && !isChannelOpen
          && !isMobile
          && !isWizardUrls
          && userLoggedIn
          }
          isWebView={isWebView}
        />
        { ((accessDenied && accessToken) || (accessDenied && !accessToken))
        && (
          <RefreshToken
            refreshToken={refreshToken}
            currentUrl={currentUrl}
          />
        )}
        <SyncPopups />
        <ActivationPopups />
      </CookiesProvider>
    </ScrollToTop>
  )
}

const mapStateToProps = (state: AppState) => ({
  version: state.app.version,
  isShowNavigation: state.menu.isShowNavigation,
  accessToken: state.secrets.accessToken,
  isChannelOpen: state.notifications.notificationsChannel.isChannelOpen,
  currentUrl: window.location.pathname,
  userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  accessDenied: (!isAccessTokenValid(state.secrets.accessToken)),
  path: state.shadowIn.path,
  authProgress: state.login.authByPassword.authInProgress,
  isWebView: navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'),
})
const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  signOut: signOutAction,
  setNavigation: setNavigationStatus,
  hotUpdate: sync,
  checkMobileStatus: checkMobile,
  checkTabletStatus: checkTablet,
  refreshToken: refreshTokenInterceptor,
  syncConfig: getAppConfig,
}, dispatch)

// @ts-ignore
const enhance = connect(mapStateToProps, mapDispatchToProps)(App)

// @ts-ignore
export default enhance
