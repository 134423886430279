import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1622 91.8802C18.6928 124.854 55.7747 136 55.7747 136C32.7746 108.135 62.8156 104.419 63.285 82.5918C67.5095 83.985 67.9789 96.9888 67.9789 96.9888C78.3055 91.8802 78.7749 68.6592 78.7749 68.6592C106 94.2022 90.9791 135.071 90.9791 135.071C129.469 126.712 129 88.1648 129 88.1648C128.53 50.0824 98.4893 27.7903 100.367 34.2921C102.714 40.794 98.0199 47.2959 98.0199 47.2959C89.1015 12.4644 62.8156 12 62.8156 12C66.5707 40.3296 47.7951 57.0487 47.7951 57.0487C35.5909 33.3633 36.5297 43.5805 34.1827 57.9775C16.3459 77.9476 19.1622 91.8802 19.1622 91.8802Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Fire')

export default icon
