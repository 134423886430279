import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M80.0224 56.2673H69.4862C55.444 56.2673 44.0195 67.648 44.0195 81.6364V116.631C44.0195 130.619 55.444 142 69.4862 142H80.0224C94.0646 142 105.489 130.619 105.489 116.631V81.6364C105.489 67.648 94.0646 56.2673 80.0224 56.2673ZM74.7543 124.21C70.3244 124.21 66.7179 120.618 66.7179 116.205C66.7179 111.792 70.3244 108.199 74.7543 108.199C79.1842 108.199 82.7877 111.792 82.7877 116.205C82.7877 120.618 79.1842 124.21 74.7543 124.21ZM74.7543 98.1414C66.6412 98.1414 60.0421 91.5677 60.0421 83.4886C60.0421 75.4066 66.6412 68.8328 74.7543 68.8328C82.8645 68.8328 89.4636 75.4066 89.4636 83.4886C89.4635 91.5677 82.8645 98.1414 74.7543 98.1414Z" fill="currentColor"/>
      <path d="M115.361 28.7281C94.4659 2.44675 54.5704 2.40121 33.6389 28.7281C32.6252 30.0029 32.8411 31.8551 34.121 32.8648C35.4008 33.8744 37.2599 33.6594 38.2736 32.3845C56.8045 9.07712 92.1673 9.04143 110.727 32.3845C111.741 33.6604 113.6 33.8737 114.879 32.8648C116.159 31.8548 116.375 30.0029 115.361 28.7281Z" fill="currentColor"/>
      <path d="M47.1638 39.3766C46.1498 40.6512 46.3651 42.5033 47.6446 43.5133C48.9246 44.5237 50.7836 44.3086 51.7972 43.0343C63.4151 28.4295 85.5632 28.4027 97.2024 43.0343C98.2166 44.3089 100.076 44.5234 101.355 43.5133C102.635 42.5032 102.85 40.6512 101.836 39.3766C87.8505 21.7954 61.1706 21.7686 47.1638 39.3766Z" fill="currentColor"/>
      <path d="M60.6813 50.0243C59.6681 51.2996 59.8844 53.1513 61.1645 54.1608C62.4447 55.1701 64.3038 54.9547 65.317 53.6794C70.006 47.7777 78.9857 47.7678 83.6827 53.6794C84.696 54.9547 86.5547 55.1703 87.8351 54.1608C89.1153 53.1513 89.3314 51.2996 88.3183 50.0243C81.2638 41.1454 67.7473 41.1312 60.6813 50.0243Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'RemoteDark')

export default icon
