import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getWallmoteMultichannelBinarySwitchStateDescription
  from '@app/helpers/scenes/getWallmoteMultichannelBinarySwitchStateDescription'
import {
  centralSceneStateSelected,
  centralSceneValueSelected,
} from '@app/lib/Scenes/conditions'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_CENTRAL_SCENE_SUB_DEVICE,
  SELECT_CENTRAL_SCENE_SUB_DEVICE_VALUE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectCentralSceneStateSelected extends React.Component {
  componentDidMount() {
    const {
      deactivateChangeSubDeviceMode,
      deactivateChangeSubDeviceStateMode,
    } = this.props
    deactivateChangeSubDeviceMode()
    deactivateChangeSubDeviceStateMode()
  }

  selectSubDevice=() => {
    const {
      navigate,
      activateChangeSubDeviceMode,
    } = this.props
    activateChangeSubDeviceMode()
    navigate(SELECT_CENTRAL_SCENE_SUB_DEVICE)
  }

  selectSubDeviceValue=() => {
    const {
      navigate,
      activateChangeSubDeviceStateMode,
    } = this.props
    activateChangeSubDeviceStateMode()
    navigate(SELECT_CENTRAL_SCENE_SUB_DEVICE_VALUE)
  }

  onBackClick =() => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
      restoreConditionInfo,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
        restoreConditionInfo()
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    }
  }

  addStatePressed=() => {
    const {
      navigate,
      isEditConditionMode,
    } = this.props
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      dataNumber,
      dataValue,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <CardBlock
              key={I18n.t(`scene_web.${centralSceneStateSelected.title}`)}
              title={I18n.t(`scene_web.${centralSceneStateSelected.title}`)}
              description={`Button ${dataNumber}`}
              position={centralSceneStateSelected.position}
              buttonName={I18n.t(`scene_web.${centralSceneStateSelected.buttonName}`)}
              isCardDisabled={centralSceneStateSelected.isCardDisabled}
              isButtonDisabled={centralSceneStateSelected.isButtonDisabled}
              onClick={() => {
                this.selectSubDevice()
              }}
            />
            <CardBlock
              key={I18n.t(`scene_web.${centralSceneValueSelected.title}`)}
              title={I18n.t(`scene_web.${centralSceneValueSelected.title}`)}
              description={getWallmoteMultichannelBinarySwitchStateDescription(dataValue)}
              position={centralSceneValueSelected.position}
              buttonName={I18n.t(`scene_web.${centralSceneValueSelected.buttonName}`)}
              isCardDisabled={centralSceneValueSelected.isCardDisabled}
              isButtonDisabled={centralSceneValueSelected.isButtonDisabled}
              onClick={() => {
                this.selectSubDeviceValue()
              }}
            />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.addStatePressed()
            }}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectCentralSceneStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  dataNumber: PropTypes.number.isRequired,
  dataValue: PropTypes.number.isRequired,
  activateChangeSubDeviceMode: PropTypes.func.isRequired,
  activateChangeSubDeviceStateMode: PropTypes.func.isRequired,
  deactivateChangeSubDeviceMode: PropTypes.func.isRequired,
  deactivateChangeSubDeviceStateMode: PropTypes.func.isRequired,
  restoreConditionInfo: PropTypes.func.isRequired,
}

SelectCentralSceneStateSelected.displayName = 'SelectCentralSceneStateSelectedIOS'

export default SelectCentralSceneStateSelected
