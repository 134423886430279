import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { binarySwitchActionValues } from '@app/lib/Scenes/binarySwitchActionValues'
import {
  EDIT_ACTION,
  SELECT_ACTION_DEVICE_STATE_TYPE,
  SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectBinarySwitchActionStateValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isEditActionMode,
      restoreActionInfo,
      actions,
      initialAction,
    } = this.props
    if (isEditActionMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    } else {
      navigate(SELECT_ACTION_DEVICE_STATE_TYPE)
    }
  }

  onAddClick=(value) => {
    const {
      navigate,
      saveActionStateDescription,
      saveActionDelay,
      saveActionDelayDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionStateDescription(value.description)
    saveActionDelay({})
    saveActionDelayDescription('')
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
    navigate(SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_action_web.select_state_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            binarySwitchActionValues.map((value) => (
              <CardBlock
                key={value.description}
                title={value.title}
                description={I18n.t(`scene_action_web.${value.description}`)}
                position={value.position}
                buttonName={value.buttonName}
                isCardDisabled={value.isCardDisabled}
                isButtonDisabled={value.isButtonDisabled}
                isValueButton
                onClick={() => {
                  this.onAddClick(value)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectBinarySwitchActionStateValue.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectBinarySwitchActionStateValue.displayName = 'SelectBinarySwitchActionStateValueIOS'

export default SelectBinarySwitchActionStateValue
