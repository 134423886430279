import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, LinearProgress, TextField, Typography,
} from '@mui/material'
import { useDispatch } from '@app/store'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import validateName from '@app/helpers/myDevices/validateName'
import validateTXID from '@app/helpers/myDevices/validateTXID'
import renderError from '@app/helpers/myDevices/constructErrorMessage'
import { addTwrDevice, patchNewTwrDevice } from '@app/actions/myDevices/twrDevices'
import { clearInterviewResult } from '@app/actions/myDevices/myDevices'
import { closeTwrChannel } from '@app/actions/myDevices/channels/twr/twrChannel'
import { MY_DEVICES } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Warning from '@app/components/Common/Warning/WarningComponent'
import { DeviceAddedIcon, ScenesArrowBack, TwrDeviceIcon } from 'icons'
import useStyles from './AddTwrDevice.style'

interface Props {
  interviewResult: string,
  isLoading: boolean,
  isMobile: boolean,
}

const AddTwrDevice: React.FC<Props> = (props) => {
  const { interviewResult, isLoading, isMobile } = props
  const [isAdded, setIsAdded] = useState<boolean>(false)
  const [deviceName, setDeviceName] = useState<string>('')
  const [txid, setTxid] = useState<string>('')
  const [omittingName, setOmittingName] = useState<boolean>(false)
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (interviewResult === 'success') {
      setIsAdded(true)
    }
  }, [interviewResult])

  useEffect(() => {
    clearInterviewResult()
    return () => {
      dispatch(closeTwrChannel())
      dispatch(clearInterviewResult())
    }
  }, [])

  const deviceTxidHandler = (evt: React.ChangeEvent<HTMLInputElement>) => setTxid(evt.target.value.trimLeft().toUpperCase())

  const nextButtonHandler = () => {
    dispatch(closeTwrChannel())
    dispatch(clearInterviewResult())
    dispatch(addTwrDevice(txid))
  }

  const setDeviceNameHandler = (evt: React.ChangeEvent<HTMLInputElement>) => setDeviceName(evt.target.value.trimLeft())

  const submitHandler = () => {
    dispatch(patchNewTwrDevice({ name: deviceName }))
    navigate(MY_DEVICES)
  }

  const goBackHandler = () => {
    if (!isAdded) {
      navigate(MY_DEVICES)
    } else {
      setOmittingName(true)
    }
  }

  const renderDeviceNotAdded = () => (
    <>
      <Wrapper className={classes.iconContainer}>
        <TwrDeviceIcon className={classNames(classes.icon, isMobile && classes.iconMobile)} />
        <Typography
          className={classNames(classes.subText, classes.iconHint, isMobile && classes.iconHintMobile)}
        >
          {I18n.t('my_devices.instructions_twr_device')}
        </Typography>

      </Wrapper>
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile)}>
        <Wrapper className={classes.statusContainer}>
          {isLoading && <LinearProgress className={classes.progressBar} />}
          {interviewResult && <Typography className={classes.errorMessage}>{renderError(interviewResult)}</Typography>}
        </Wrapper>
        <Typography
          className={classNames(classes.title, isMobile && classes.titleMobile)}
        >
          {I18n.t('my_devices.enter_device_txid')}
        </Typography>
        <TextField
          variant="standard"
          value={txid}
          label={I18n.t('my_devices.txid')}
          className={classes.input}
          onChange={deviceTxidHandler}
          inputProps={{ maxLength: 8 }}
        />
        <Typography className={classNames(classes.subText, classes.subTitle)}>{I18n.t('my_devices.enter_txid_code')}</Typography>
        <Button
          className={classes.submitButton}
          variant="outlined"
          color="primary"
          disabled={isLoading || !validateTXID(txid)}
          onClick={nextButtonHandler}
        >
          {I18n.t('buttons.next')}
        </Button>
      </Wrapper>
    </>
  )

  const renderDeviceAdded = () => (
    <>
      <DeviceAddedIcon className={classNames(classes.deviceAddedIcon, isMobile && classes.deviceAddedIconMobile)} />
      <Wrapper className={classNames(classes.content, isMobile && classes.contentMobile, isMobile && classes.contentMobileAdded)}>
        <Typography className={classNames(classes.title, isMobile && classes.titleMobile)}>
          {I18n.t('my_devices.twr_added')}
        </Typography>
        <TextField
          variant="standard"
          value={deviceName}
          inputProps={{ maxLength: 16 }}
          label={I18n.t('my_devices.name_your_device')}
          className={classes.input}
          onChange={setDeviceNameHandler}
        />
        <Typography className={classNames(classes.subText, classes.subTitle)}>{I18n.t('my_devices.name_hint')}</Typography>
        <Button
          className={classes.submitButton}
          variant="outlined"
          color="primary"
          disabled={!validateName(deviceName)}
          onClick={submitHandler}
        >
          {I18n.t('buttons.submit')}
        </Button>
      </Wrapper>
    </>
  )

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classNames(classes.navigationControls, isMobile && classes.navigationControlsMobile)}>
        <IconButton size="small" className={classes.arrowBack} onClick={goBackHandler}>
          <ScenesArrowBack />
        </IconButton>
        {omittingName && (
          <Warning
            open
            leftButton="ok"
            onClick={() => navigate(MY_DEVICES)}
            onClose={() => setOmittingName(false)}
            rightButton="cancel"
            warningText="my_devices.name_warning"
          />
        )}
        <Typography>{I18n.t('my_devices.add_device')}</Typography>
      </Wrapper>
      <Wrapper className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}>
        {isAdded ? renderDeviceAdded() : renderDeviceNotAdded()}
      </Wrapper>
      <Wrapper className={classes.progressTipContainer}>
        <div className={classNames(classes.progressTip, !isAdded && classes.active)} />
        <div className={classNames(classes.progressTip, isAdded && classes.active)} />
      </Wrapper>
    </Wrapper>
  )
}

export default AddTwrDevice
