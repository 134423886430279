import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 31C8 18.2975 18.2975 8 31 8H118C130.703 8 141 18.2975 141 31V118C141 130.703 130.703 141 118 141H31C18.2975 141 8 130.703 8 118V31ZM31 13H118C127.941 13 136 21.0589 136 31V118C136 127.941 127.941 136 118 136H31C21.0589 136 13 127.941 13 118V31C13 21.0589 21.0589 13 31 13Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M79.3108 92.8021C79.4683 92.178 79.6284 91.5431 79.8004 90.9086C83.1736 74.0207 87.5105 42.5672 87.5105 25.8323C87.5105 20.348 79.9844 19.0859 74.4996 19.0859C68.7338 19.0859 61.4888 20.3475 61.4888 25.8323C61.4888 42.5668 64.1139 74.0207 68.2351 90.9086C68.4522 91.7982 68.6326 92.661 68.8039 93.4808C69.6494 97.5259 70.2762 100.524 74.0178 100.524C77.3626 100.524 78.278 96.8959 79.3108 92.8021ZM65.4888 25.8323C65.4888 25.4135 65.5446 24.8172 67.3026 24.1222C69.1391 23.3962 71.7981 23.0859 74.4996 23.0859C77.0428 23.0859 79.7188 23.3922 81.6086 24.1292C83.4768 24.8577 83.5105 25.4831 83.5105 25.8323C83.5105 42.1428 79.2518 73.1834 75.9037 89.9956C75.7446 90.5878 75.5967 91.1724 75.4584 91.7204L75.4345 91.8149C75.2924 92.3783 75.1612 92.8985 75.027 93.3992C74.738 94.4773 74.4814 95.284 74.2115 95.8918C74.0681 96.2147 73.9514 96.41 73.8752 96.5208C73.8405 96.5189 73.8108 96.5164 73.7855 96.5137C73.7449 96.4445 73.6874 96.3339 73.6178 96.1658C73.4034 95.6479 73.2035 94.9122 72.9572 93.7845C72.8899 93.4765 72.817 93.1278 72.7385 92.7526C72.5603 91.901 72.3538 90.9137 72.1211 89.9603C68.102 73.4909 65.4888 42.382 65.4888 25.8323ZM73.6823 96.497C73.6726 96.4932 73.6672 96.4918 73.6669 96.4921C73.6668 96.4922 73.668 96.4928 73.6706 96.4937C73.6731 96.4945 73.677 96.4957 73.6823 96.497Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M84.1376 120.28C84.1376 125.869 79.6069 130.399 74.018 130.399C68.4291 130.399 63.8984 125.869 63.8984 120.28C63.8984 114.691 68.4291 110.16 74.018 110.16C79.6069 110.16 84.1376 114.691 84.1376 120.28ZM80.1376 120.28C80.1376 123.659 77.3977 126.399 74.018 126.399C70.6383 126.399 67.8984 123.659 67.8984 120.28C67.8984 116.9 70.6383 114.16 74.018 114.16C77.3977 114.16 80.1376 116.9 80.1376 120.28Z" fill="currentColor"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwNotificationIcon')

export default icon
