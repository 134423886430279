import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  home: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })),
    elements: PropTypes.shape({
      arm_disarm_panel: PropTypes.string.isRequired,
      events_panel: PropTypes.string.isRequired,
    }),
  }),
  menu: PropTypes.shape({
    all_menu: PropTypes.string.isRequired,
    guard_panel: PropTypes.string.isRequired,
    controls: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })),
    toolbar: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })),
  }),
})

export default propTypes
