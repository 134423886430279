import { useEffect } from 'react'
import { useDispatch } from '@app/store'
import {
  clearCurrentActionInfo,
  clearCurrentConditionInfo,
  deactivateAdditionalActionMode,
  deactivateAdditionalConditionMode,
  deactivateEditActionMode,
  deactivateEditConditionMode,
} from '@app/actions/scenes/currentSceneCreating'

export const useClearSceneReviewState = (isEditActionMode: boolean, isEditConditionMode: boolean, isAdditionalConditionMode: boolean, isAdditionalActionMode: boolean) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (isEditActionMode) {
      dispatch(deactivateEditActionMode())
    }
    if (isEditConditionMode) {
      dispatch(deactivateEditConditionMode())
    }
    if (isAdditionalConditionMode) {
      dispatch(deactivateAdditionalConditionMode())
    }
    if (isAdditionalActionMode) {
      dispatch(deactivateAdditionalActionMode())
    }
    dispatch(clearCurrentActionInfo())
    dispatch(clearCurrentConditionInfo())
  }, [])
}
