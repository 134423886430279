import { createLogic } from 'redux-logic'
import { SYNC_DEVICES_STATUS_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { syncDevicesStatusChannelSent, updateDevicesStatus } from '@app/actions/myDevices/channels/devices/devicesStatusChannel'
import Api from '@app/api/myDevices/channels/devices/devicesChannels'

const subscribeDevicesStatusLogic = createLogic({
  type: SYNC_DEVICES_STATUS_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.devicesStatusChannel({
      request: () => {
        dispatch(syncDevicesStatusChannelSent())
      },
      response: (data) => {
        store.dispatch(updateDevicesStatus(data))
      },
    })
  },
})

export default subscribeDevicesStatusLogic
