import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/pills_reminders`

const createPillsReminder = (access_token, data) => Axios.post(ENDPOINT, data, {
  headers: { 'Access-Token': access_token },
})

export default {
  createPillsReminder,
}
