import React, { useEffect, useState } from 'react'
import {
  Button, IconButton, LinearProgress, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from '@app/store'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CustomBackdrop from '@app/components/Common/CustomBackdrop/CustomBackdrop'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import {
  Delete, InfoIcon, MyDevicesWarningIcon, ScenesArrowBack,
} from 'icons'
import { MY_DEVICES } from '@app/constants/routes'
import * as slider from '@app/lib/MyDevices/sliderValues'
import { deviceControls } from '@app/lib/MyDevices/deviceControls'
import { renderIcon } from '@app/helpers/myDevices/renderIcon'
import {
  clearInterviewResult,
  syncMyDevices,
  setCurrentDeviceControl,
  setCurrentDevice,
} from '@app/actions/myDevices/myDevices'
import { patchZwaveDeviceStatus, breakFromReinterview } from '@app/actions/myDevices/zwaveDevices'
import { closeZwaveChannel } from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import {
  cancelZwaveDelete,
  clearDeletingResult,
  deleteTwrDevice,
  deleteZwaveDevice,
} from '@app/actions/myDevices/myDevicesDelete'
import {
  syncDeleteChannel,
  closeDeleteChannel,
} from '@app/actions/myDevices/channels/devices/deleteChannel'
import { checkIfGuestOrFamily } from '@app/helpers/checkIfGuestOrFamily'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import DeviceStats from '@app/components/MyDevices/DeviceInfo/DeviceStats/DeviceStats'
import { fetchZwaveDeviceInfo } from '@app/actions/myDevices/deviceInfo/fetchZwaveDeviceInfo'
import { fetchTwrDeviceInfo } from '@app/actions/myDevices/deviceInfo/fetchTwrDeviceInfo'
import { clearDeviceInfoError } from '@app/actions/myDevices/deviceInfo/deviceInfoChannel'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
import ColoursButton from '@app/components/MyDevices/DeviceInfo/SettingsButtons/ColoursButton'
import RoomsButton from '@app/components/MyDevices/DeviceInfo/SettingsButtons/RoomsButton'
import GroupsButton from '@app/components/MyDevices/DeviceInfo/SettingsButtons/GroupsButton'
import { isTwrKeyfob } from '@app/helpers/myDevices/isTwrKeyfob'
import { Status } from '@app/lib/guardPanel/guardPanel'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import FailedInterviewPopup from './DeviceInfoPopups/FailedInterviewPopup'
import ErrorAndProgress from './ErrorAndProgress'
import InfoPopup from './DeviceInfoPopups/InfoPopup'
import SettingsButton from './SettingsButtons/SettingsButton'
import SleepingModePopup from './DeviceInfoPopups/SleepingModePopup'
import useStyles from './DeviceInfo.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  devices: Array<IZwaveOrTwrDevice>,
  fetchingDevicesFinished: boolean,
  reinterviewInProgress: boolean,
  interviewResult: string,
  deletingResult: string,
  deletingDeviceInProgress: boolean,
  isMobile: boolean,
  guardPanelStatus: string,
  role: string,
  fetchingDeviceStats: boolean,
  deviceInfoError: string,
}

const DeviceInfo: React.FC<Props> = (props) => {
  const {
    currentDevice,
    devices,
    fetchingDevicesFinished,
    reinterviewInProgress,
    interviewResult,
    deletingResult,
    deletingDeviceInProgress,
    guardPanelStatus,
    isMobile,
    role,
    fetchingDeviceStats,
    deviceInfoError,
  } = props
  const { type, deviceId } = useParams<{ type: string, deviceId?: string }>()
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showDeviceInfo, setShowDeviceInfo] = useState<boolean>(false)
  const [showFailedInterview, setShowFailedInterview] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(!!currentDevice.status)
  const [errorFindingDevice, setErrorFindingDevice] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [isArmedErrorPopupOpen, setIsArmedErrorPopupOpen] = useState<boolean>(false)
  const [isAnotherDeviceDeleted, setIsAnotherDeviceDeleted] = useState<boolean>(false)
  const [isFailedDeviceDeleting, setIsFailedDeviceDeleting] = useState<boolean>(false)
  const [activateStatusProgress, setActivateStatusProgress] = useState<boolean>(false)
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean>(false)

  const { devices: devicesConfig } = useSelector((state: AppState) => state.appConfig.config)
  const twrDeviceDetails = devicesConfig.twr?.details_info?.state ?? defaultConfig?.devices?.twr?.details_info?.state
  const showTwrDeviceDetails = twrDeviceDetails === States.Visible
  const twrDeviceDelete = devicesConfig.twr?.delete?.state ?? defaultConfig?.devices?.twr?.delete?.state
  const showDeleteButton = twrDeviceDelete === States.Visible

  const DeviceControlData = deviceControls[currentDevice.widget || currentDevice.type]
  const ControlComponent = DeviceControlData && DeviceControlData.component
  const noop = () => {}

  useEffect(() => {
    if (!currentDevice) {
      navigate(MY_DEVICES)
    } else if (type === DeviceTypes.zwaveDevice) {
      dispatch(fetchZwaveDeviceInfo(currentDevice.id))
    } else if (type === DeviceTypes.twrDevice) {
      dispatch(fetchTwrDeviceInfo(currentDevice.id))
    }
    dispatch(setCurrentDeviceControl(DeviceControlData))
    dispatch(clearInterviewResult())
    dispatch(clearDeletingResult())
    return () => {
      dispatch(closeZwaveChannel())
      dispatch(clearInterviewResult())
      dispatch(breakFromReinterview())
      dispatch(closeDeleteChannel())
      dispatch(clearDeletingResult())
    }
  }, [])

  useEffect(() => {
    const device = devices.find((dev) => (dev.id === Number(deviceId) && dev.device_type === type))
    if (device) {
      dispatch(setCurrentDevice(device))
    }
  }, [devices])

  useEffect(() => {
    if (currentDevice.activate_status === 'progress') {
      setActivateStatusProgress(true)
    } else {
      setActivateStatusProgress(false)
    }
  }, [currentDevice])

  useEffect(() => {
    if (interviewResult && interviewResult !== 'pending') {
      dispatch(breakFromReinterview())
    }
    if (interviewResult === 'success') {
      dispatch(syncMyDevices())
    }
  }, [interviewResult])

  useEffect(() => {
    if (deletingResult && deletingResult === 'success') {
      navigate(MY_DEVICES)
    }
    if (deletingResult && deletingResult === 'success_another') {
      setIsAnotherDeviceDeleted(true)
    }
    if (deletingResult && deletingResult === 'timeout') {
      setIsFailedDeviceDeleting(true)
    }
  }, [deletingResult])

  useEffect(() => {
    if (fetchingDevicesFinished) {
      setErrorFindingDevice(true)
    }
  }, [fetchingDevicesFinished])

  useEffect(() => {
    if (deviceInfoError) {
      setIsErrorPopupOpen(true)
    } else {
      setIsErrorPopupOpen(false)
    }
  }, [deviceInfoError])

  const updateZwaveDevice = (deviceStatus: number) => {
    dispatch(patchZwaveDeviceStatus({
      id: Number(deviceId),
      status: deviceStatus,
    }))
  }

  const renderDeviceSwitchControl = () => ({
    onChange: () => {
      setChecked(!checked)
    },
    onChangeCommitted: () => {
      updateZwaveDevice(checked ? slider.MIN_SWITCH_VALUE : slider.MAX_SWITCH_VALUE)
    },
    checked,
    id: currentDevice.id,
    locker: DeviceControlData && DeviceControlData.key === 'switch_locked_unlocked',
    chevronsLeftRight: DeviceControlData && DeviceControlData.key === 'switch_left_right',
    chevronsUpDown: DeviceControlData && DeviceControlData.key === 'switch_up_down',
    inProgress: activateStatusProgress,
    multiSwitchValue: checked,
    multiSwitchHandler: (multiSwitchState: boolean) => {
      setChecked(multiSwitchState)
      updateZwaveDevice(!multiSwitchState ? slider.MIN_SWITCH_VALUE : slider.MAX_SWITCH_VALUE)
    },
    bigSize: true,
    big: !isMobile,
    medium: isMobile,
  })

  const toggleDeletePopup = () => {
    if (type === DeviceTypes.zwaveDevice) {
      if (guardPanelStatus !== Status.SIMPLE_DISARMED) {
        setIsArmedErrorPopupOpen(true)
      } else {
        setShowDeletePopup((prevState) => !prevState)
      }
    }
    if (type === DeviceTypes.twrDevice) {
      setShowDeletePopup((prevState) => !prevState)
    }
  }

  const startDeletingProcess = () => {
    if (type === DeviceTypes.twrDevice) {
      dispatch(deleteTwrDevice(Number(deviceId)))
    }
    if (type === DeviceTypes.zwaveDevice) {
      dispatch(deleteZwaveDevice(Number(deviceId)))
    }
    dispatch(syncDeleteChannel(Number(deviceId), type))
    setShowDeletePopup(false)
  }

  const cancelDeletingProcess = () => {
    dispatch(cancelZwaveDelete())
    dispatch(clearDeletingResult())
    dispatch(closeDeleteChannel())
  }

  const renderDeletingProcessStatus = () => {
    if (type === DeviceTypes.zwaveDevice) {
      if (deletingResult === 'sleeping') {
        return I18n.t('my_devices.in_sleep')
      }
      return I18n.t('my_devices.deleting_zwave')
    } if (type === DeviceTypes.twrDevice) {
      return I18n.t('my_devices.deleting_twr')
    } return null
  }

  const renderDeviceStatsAndController = () => {
    if (currentDevice.info && currentDevice.device_type === DeviceTypes.zwaveDevice) {
      const isInfoEmpty = Object.values(currentDevice.info)
        .every((value) => value === null || JSON.stringify(value) === '{}' || value.length === 0)
      if (!isInfoEmpty || fetchingDeviceStats) {
        return (
          <DeviceStats
            currentDevice={currentDevice}
            isMobile={isMobile}
            fetchingDeviceStats={fetchingDeviceStats}
          />
        )
      }
      return ControlComponent ? <ControlComponent {...renderDeviceSwitchControl()} /> : null
    }
    if (currentDevice.device_type === DeviceTypes.twrDevice) {
      return (
        <DeviceStats
          currentDevice={currentDevice}
          isMobile={isMobile}
          fetchingDeviceStats={fetchingDeviceStats}
        />
      )
    }
    return ControlComponent ? <ControlComponent {...renderDeviceSwitchControl()} /> : null
  }

  const renderDeviceSettingsButtons = () => {
    const isKeyfob = currentDevice.device_type === DeviceTypes.twrDevice && isTwrKeyfob(currentDevice.txid!)
    return (
      <Wrapper className={classes.settingsButtonsWrapper}>
        {!isKeyfob && <RoomsButton />}
        {!isKeyfob && <GroupsButton />}
        {currentDevice.info?.color_state && (<ColoursButton />)}
        <SettingsButton />
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      {currentDevice ? (
        <>
          <Wrapper className={classes.navigationControls}>
            <IconButton
              size="small"
              className={classes.arrowBack}
              onClick={() => navigate(MY_DEVICES)}
            >
              <ScenesArrowBack />
            </IconButton>
            {showDeleteButton && (
              <Wrapper>
                {currentDevice.interview_status !== 'complete' && type !== DeviceTypes.twrDevice && (
                  <IconButton
                    size="small"
                    className={classes.warningIcon}
                    onClick={() => setShowFailedInterview(true)}
                  >
                    <MyDevicesWarningIcon />
                  </IconButton>
                )}
                <IconButton size="small" className={classes.deleteIcon} onClick={toggleDeletePopup}>
                  <Delete />
                </IconButton>
                {showFailedInterview && (
                  <FailedInterviewPopup
                    close={() => setShowFailedInterview(false)}
                    id={Number(deviceId)}
                    isMobile={isMobile}
                  />
                )}
              </Wrapper>
            )}
          </Wrapper>
          <Wrapper className={classes.contentContainer}>
            <Wrapper className={classes.iconAndName}>
              <Wrapper className={classNames(classes.iconContainer, isMobile && classes.iconContainerMobile)}>
                {renderIcon(currentDevice.icon, { className: classNames(classes.icon, isMobile && classes.iconMobile) })}
              </Wrapper>
              <Wrapper className={classes.nameContainer}>
                <IconButton
                  size="small"
                  className={classes.infoIcon}
                  onClick={() => setShowDeviceInfo(!showDeviceInfo)}
                >
                  <InfoIcon />
                </IconButton>
                <Typography variant="h6" className={classes.deviceName}>{currentDevice.name}</Typography>
                {showDeviceInfo && (
                  <InfoPopup
                    onClickAway={() => setShowDeviceInfo(!showDeviceInfo)}
                    device={currentDevice}
                    type={type}
                    isMobile={isMobile}
                    showTwrDeviceDetails={showTwrDeviceDetails}
                  />
                )}
              </Wrapper>
            </Wrapper>
            <Wrapper className={classes.deviceContent}>
              {renderDeviceStatsAndController()}
            </Wrapper>
            {!checkIfGuestOrFamily(role) ? renderDeviceSettingsButtons() : <Wrapper />}
          </Wrapper>
          {interviewResult === 'in_sleep' && (
            <SleepingModePopup
              clearInterviewResult={() => dispatch(clearInterviewResult())}
            />
          )}
          {(reinterviewInProgress || deletingDeviceInProgress) && (
            <CustomBackdrop
              backdropClass={classes.backdrop}
              clicked={noop}
            >
              <Wrapper className={classes.backdropInner}>
                {reinterviewInProgress && (
                  <Typography variant={isMobile ? 'h6' : 'h5'}>
                    {I18n.t('my_devices.reinterview_in_progress')}
                  </Typography>
                )}
                {deletingDeviceInProgress && (
                  <Typography variant={isMobile ? 'h6' : 'h5'}>
                    {renderDeletingProcessStatus()}
                  </Typography>
                )}
                <LinearProgress className={classes.overlayLinearBar} />
                {deletingDeviceInProgress && type === DeviceTypes.zwaveDevice && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={cancelDeletingProcess}
                    className={classes.overlayButton}
                  >
                    {I18n.t('buttons.cancel')}
                  </Button>
                )}
              </Wrapper>
            </CustomBackdrop>
          )}
          {showDeletePopup && (
            <WarningComponent
              open
              warningText="my_devices.want_to_delete"
              onClose={toggleDeletePopup}
              onClick={startDeletingProcess}
              rightButton="cancel"
              leftButton="okay"
            />
          )}
          {isArmedErrorPopupOpen && (
            <SmallErrorPopup
              open
              onClick={() => setIsArmedErrorPopupOpen(false)}
              buttonName="buttons.okay"
              title="my_devices.error"
              warningText="my_devices.armed_error"
            />
          )}
          {isAnotherDeviceDeleted && (
            <SmallErrorPopup
              open
              onClick={() => setIsAnotherDeviceDeleted(false)}
              buttonName="buttons.okay"
              title="titles.warning"
              warningText="my_devices.deleted_another_device"
            />
          )}
          {isFailedDeviceDeleting && (
            <SmallErrorPopup
              open
              onClick={() => setIsFailedDeviceDeleting(false)}
              buttonName="buttons.okay"
              title="my_devices.error"
              warningText="my_devices.delete_failed"
            />
          )}
        </>
      ) : (
        <ErrorAndProgress
          errorFindingDevice={errorFindingDevice}
        />
      )}
      {isErrorPopupOpen && (
        <SmallErrorPopup
          open
          title="titles.title_error"
          error={deviceInfoError}
          buttonName="buttons.okay"
          onClick={() => {
            dispatch(clearDeviceInfoError())
          }}
        />
      )}
    </Wrapper>
  )
}

export default DeviceInfo
