import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/pills_reminders`

const patchPillsReminder = (access_token, id, data) => Axios.patch(`${ENDPOINT}/${id}`, data, {
  headers: { 'Access-Token': access_token },
})

export default {
  patchPillsReminder,
}
