import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1904 10.1914V138.811H138.81V10.1914H10.1904ZM72.1578 129.45H19.5045V76.8338H72.1578V129.45ZM72.1578 72.1589H19.5045V19.5055H72.1578V72.1589ZM129.45 129.45H76.8332V76.8338H129.44L129.45 129.45ZM129.45 72.1589H76.8332V19.5055H129.44L129.45 72.1589Z" fill="currentColor"/>
            <rect x="8" y="8" width="133" height="133" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M108.088 48.293H121.452L108.088 43.9673L117.522 35.7117L108.088 38.3071L90.942 26.1953L104.471 41.6112L90.399 39.0158L102.032 45.4583L83.7173 60.8742L103.993 50.2533L113.905 60.8742L108.088 48.293V48.293Z" stroke="currentColor" strokeWidth="0.5"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'GlassBreakSensorIcon')

export default icon
