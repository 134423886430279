import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 34C8 31.2386 10.2386 29 13 29H136C138.761 29 141 31.2386 141 34V110C141 112.761 138.761 115 136 115H13C10.2386 115 8 112.761 8 110V34ZM13 31H136C137.657 31 139 32.3431 139 34V110C139 111.657 137.657 113 136 113H13C11.3431 113 10 111.657 10 110V34C10 32.3431 11.3431 31 13 31Z" fill="currentColor"/>
      <path d="M27 115L34.5 120C34.5 120 64.7588 116.172 84.25 115H27Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 113L15 107V36H129C131.761 36 134 38.2383 134 41V113H51.238L51.5173 112.969C61.5339 111.863 74.0737 110.602 83.95 110.008C93.7786 109.418 106.319 109.168 116.347 109.062C119.811 109.027 122.985 109.008 125.658 109L128.95 108.992H129V47.8164C119.721 47.4961 99.4885 46.9375 85.25 47.5C69.1265 48.1367 45.6379 50.7969 38 51.6992V113H24ZM28.0139 41L36.7361 46.8164C37.5674 46.7148 38.657 46.5859 39.96 46.4375C43.1165 46.0742 47.5286 45.582 52.5535 45.0625C62.5737 44.0312 75.1414 42.8945 85.0532 42.5039C94.7271 42.1211 107.042 42.25 116.871 42.4688C120.221 42.5469 123.295 42.6328 125.894 42.7109L129 42.8125V41H28.0139ZM20 41.668L33 50.332V112.992L20 104.324V41.668Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M123 75.5C123 83.5081 116.508 90 108.5 90C100.492 90 94 83.5081 94 75.5C94 67.4919 100.492 61 108.5 61C116.508 61 123 67.4919 123 75.5ZM118 75.5C118 80.7467 113.747 85 108.5 85C103.253 85 99 80.7467 99 75.5C99 70.2533 103.253 66 108.5 66C113.747 66 118 70.2533 118 75.5Z" fill="currentColor"/>
      <path d="M49 78.2875L70.753 75L71.5001 79.9439L49.7472 83.2314L49 78.2875Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SwitchHorizontal')

export default icon
