import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_NEW_TWR_DEVICE } from '@app/constants/myDevices/twrDevices'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { setMyDevices } from '@app/actions/myDevices/myDevices'

const patchNewTwrDeviceLogic = createLogic({
  type: PATCH_NEW_TWR_DEVICE,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { myDevices: { myDevices: { devices } } } = getState()
    const { myDevices: { myDevices: { deviceToAddInfo } } } = getState()
    const { myDevices: { myDevices: { deviceToAddInfo: { id } } } } = getState()
    next({
      ...action,
      token,
      devices,
      id,
      deviceToAddInfo,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_NEW_TWR_DEVICE))
    try {
      await Api.patchTwrDevice(action.token, action.id, action.data)
      const newPatchedDevice = { ...action.deviceToAddInfo, name: action.data.name }
      const updatedDevices = [...action.devices, newPatchedDevice]
      dispatch(setMyDevices(updatedDevices))
      dispatch(apiRequestDone(PATCH_NEW_TWR_DEVICE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_NEW_TWR_DEVICE failed without error')
      }
    }
    done()
  },
})

export default patchNewTwrDeviceLogic
