import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_SECRET_CODE } from '@app/constants/users/secretCode'
import { sync } from '@app/actions/users/currentUser'
import { closePopup as closeSecretCodePopup } from '@app/actions/users/secretCode'
import Api from '@app/api/users/changePassword'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitChangedUserCode = createLogic({
  type: SET_SECRET_CODE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { users: { currentUser: { user: { ids: { sphere: sphereId } } } } } = getState()
    const { form: { SecretCodePopup: { values: { old_code: oldCode } } } } = getState()
    const { form: { SecretCodePopup: { values: { new_code_first: newCode } } } } = getState()
    const formData = { old_password: oldCode, new_password: newCode }
    next({
      ...action,
      formData,
      token,
      sphereId,
      newCode,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_SECRET_CODE))
    Api.changePassword(action.token, action.sphereId, action.formData)
      .then(() => {
        dispatch(apiRequestDone(SET_SECRET_CODE))
        dispatch(closeSecretCodePopup())
        dispatch(sync())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('changePassword failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitChangedUserCode
