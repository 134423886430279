import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/v4/sphere_users`

const signUp = (accessToken, data) => Axios.post(ENDPOINT, data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  signUp,
}
