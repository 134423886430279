import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M119.297 96.332C106.721 96.3392 96.5325 106.342 96.5374 118.675C96.5422 131.008 106.739 141.003 119.314 141.001C131.889 140.998 142.082 131 142.082 118.666C142.082 112.741 139.681 107.058 135.408 102.869C131.134 98.6802 125.338 96.3286 119.297 96.332V96.332ZM129.031 123.184L125.874 128.422L108.193 128.5L118.507 112.875H108.456L111.666 107.791H129.031L119.06 123.184H129.031Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M50.731 8.00001C27.1209 8.01338 7.99091 26.7936 8 49.9496C8.00909 73.1057 27.1538 91.8714 50.7639 91.8669C74.374 91.8624 93.5113 73.0895 93.5114 49.9335C93.5114 38.8078 89.0034 28.1382 80.9798 20.2735C72.9562 12.4087 62.0748 7.99358 50.731 8.00001V8.00001ZM69.009 58.4123L63.081 68.2476L29.8842 68.393L49.249 39.0565H30.3782L36.405 29.5119H69.009L50.2864 58.4123H69.009Z" fill="currentColor"/>
            <path d="M119.296 68.0352C87.4257 68.0352 67.8313 91.4552 67.8313 117.53H71.8313C71.8313 93.5677 89.7303 72.0352 119.296 72.0352V68.0352Z" fill="currentColor"/>
            <path d="M85.684 116.844C85.684 101.444 99.5763 85.052 119.296 85.052V81.052C97.3128 81.052 81.684 99.2901 81.684 116.844H85.684Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwRepeaterIcon')

export default icon
