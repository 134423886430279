import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M90.516 127.735C90.2042 127.97 89.863 128.164 89.5011 128.312C85.1186 130.181 80.4072 131.157 75.6429 131.182C70.8934 131.151 66.1974 130.175 61.8285 128.312C61.107 128.022 60.4758 127.545 60 126.93" stroke="currentColor" strokeWidth="2"/>
            <path d="M60 126.929C69.9178 123.485 80.75 123.772 90.4722 127.733" stroke="currentColor" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M100.517 12.3473L101.204 12.5136C104.919 13.3995 107.728 16.2683 108.365 19.8276L108.634 21.3149C119.947 84.2366 103.034 119.809 95.9194 132.347C94.8837 133.779 93.4145 134.886 91.7027 135.522C86.5415 137.616 80.985 138.708 75.3655 138.733C74.4119 138.733 73.4429 138.701 72.4584 138.637C69.177 138.412 65.935 137.825 62.7991 136.887C62.6302 136.838 62.4622 136.789 62.295 136.741C59.2623 135.86 56.5175 135.064 54.4401 132.303C47.5393 119.748 29.9296 84.2541 42.2824 21.1924L42.6632 19.6176C43.3426 16.147 46.0893 13.3586 49.7126 12.4611L50.4556 12.2773C53.8085 11.4374 64.4523 11 75.1704 11C85.8978 11 96.7181 11.4374 100.517 12.3473ZM103.713 22.1996L103.713 22.2007C114.693 83.2739 98.4836 117.608 91.7332 129.592C91.3016 130.119 90.698 130.562 89.9605 130.836L89.8913 130.861L89.823 130.889C85.261 132.74 80.3397 133.71 75.3554 133.733C74.5198 133.733 73.6654 133.705 72.7918 133.648C69.8812 133.448 67.0079 132.927 64.2316 132.097L64.2168 132.092L64.2021 132.088C60.8352 131.104 59.6078 130.704 58.6249 129.535C51.9845 117.388 35.2595 83.195 47.1682 22.2606L47.5489 20.686L47.5701 20.5782C47.8502 19.1471 49.0433 17.7787 50.9128 17.3149L51.6632 17.1293L51.6706 17.1275C52.7856 16.8482 55.7822 16.5366 60.2933 16.3178C64.6133 16.1083 69.8704 16 75.1704 16C80.4752 16 85.777 16.1083 90.1954 16.3264C94.766 16.5519 97.966 16.8788 99.3476 17.2087L99.3521 17.2098L100.036 17.3753L100.044 17.3772C101.965 17.8353 103.181 19.2453 103.443 20.709L103.713 22.1996Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M55.0074 72.8704C62.3652 71.0069 68.052 77.9535 69.6355 84.2439C71.219 90.5343 69.5393 99.2832 62.1727 101.112C54.8062 102.94 49.1282 96.0286 47.5447 89.7382C45.9611 83.4478 47.6496 74.7339 55.0074 72.8704V72.8704Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M94.8146 72.8704C87.4568 71.0069 81.77 77.9535 80.1865 84.2439C78.603 90.5343 80.2915 99.3182 87.6493 101.182C95.007 103.045 100.694 96.0986 102.277 89.8082C103.861 83.5177 102.181 74.7339 94.8146 72.8704V72.8704Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M94.8146 59.6348C87.4568 61.4983 81.77 54.5517 80.1865 48.2613C78.603 41.9709 80.2915 33.187 87.6493 31.3235C95.007 29.46 100.694 36.4066 102.277 42.697C103.861 48.9875 102.181 57.7713 94.8146 59.6348V59.6348Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M55.0074 59.6348C62.3652 61.4983 68.052 54.5517 69.6355 48.2613C71.219 41.9709 69.5305 33.187 62.1727 31.3235C54.815 29.46 49.1282 36.4066 47.5447 42.697C45.9611 48.9875 47.6496 57.7713 55.0074 59.6348V59.6348Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'FourButtonKeyFobIcon')

export default icon
