import * as type from '@app/constants/pillsReminders/pillsReminders'

const initialState = {
  reminders: [],
  events: [],
  currentReminder: {},
}

const pillsReminders = (state = initialState, action) => {
  switch (action.type) {
  case type.SET_PILLS_REMINDERS:
    return { ...state, reminders: action.pillsReminders }
  case type.SET_PILLS_EVENTS:
    return { ...state, events: action.pillsEvents }
  case type.DELETE_PILLS_REMINDER:
    return {
      ...state,
      reminders: state.reminders.filter((reminder) => reminder.id !== action.id),
    }
  default:
    return state
  }
}

export default pillsReminders
