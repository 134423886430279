import { VALIDATION_REG_EXP_PASSWORD } from '@app/lib/Wizard/forgotPasswordValidationRegExps'

export const validate = (values) => {
  const errors = {}
  const newPassword = 'new_password'
  const PasswordConfirmation = 'password_confirmation'

  const requiredFields = [newPassword, PasswordConfirmation]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })
  if (values[newPassword] && !VALIDATION_REG_EXP_PASSWORD.test(values[newPassword])) {
    errors[newPassword] = I18n.t('my_users.user_code_validation')
  }
  if (values[PasswordConfirmation] && !VALIDATION_REG_EXP_PASSWORD.test(values[PasswordConfirmation])) {
    errors[PasswordConfirmation] = I18n.t('my_users.user_code_validation')
  }
  if (values[newPassword] !== values[PasswordConfirmation]) {
    errors[PasswordConfirmation] = I18n.t('my_users.user_codes_match')
  }
  return errors
}
