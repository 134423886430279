import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { syncVirtualSwitches } from '@app/actions/virtualSwitches/virtualSwitches'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import VirtualSwitches
  from '@app/components/MyDevices/MyMezzo/VirtualSwitches/VirtualSwitches'
import {
  withAccessDenied, withNotifications, withRefresh, withProgressAndErrorMessage,
} from '@app/hocs'

let enhance = VirtualSwitches

const mapStateToProps = (state) => {
  const { virtualSwitches } = state.virtualSwitches
  const { isMobile } = state.mobileDetector
  return {
    virtualSwitches,
    isMobile,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncVirtualSwitches,
  tryAgain: syncVirtualSwitches,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const VirtualSwitchesEnhanced = enhance

export default VirtualSwitchesEnhanced
