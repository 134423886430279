import Axios from 'axios'
import { DEVICES_STATUS_CHANNEL } from '@app/constants/channels'
import { API_URL } from '@app/constants/endpoints'
import { closeChannel, openChannel } from '@app/actions/myStatus'
import openCable from './cable'

const ENDPOINT = (id) => `${API_URL}/api/my_status/zwave_sensors/${id}`

const fetchMyStatusDeviceStats = (access_token, id) => Axios.get(ENDPOINT(id), {
  headers: {
    'Access-Token': access_token,
  },
})

const fetchMyStatusDevices = (access_token) => Axios.get(`${API_URL}/api/my_status/devices/`, {
  headers: {
    'Access-Token': access_token,
  },
})

const myStatusChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.create(DEVICES_STATUS_CHANNEL, {
    connected() {
      request(this)
      store.dispatch(openChannel())
    },
    received(data) {
      response(data)
    },
    disconnected: () => {
      store.dispatch(closeChannel())
    },
  })
  return subscription
}

export default {
  fetchMyStatusDeviceStats,
  fetchMyStatusDevices,
  myStatusChannel,
}
