import { createLogic } from 'redux-logic'
import { isAndroid, isIOS } from 'react-device-detect'
import { ACTIVATE_SCENE } from '@app/constants/scenes/scenesList'
import {
  clearCurrentSceneId,
  activateSceneDoneAndroid,
  activateSceneDoneIOS,
} from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/activateScene'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const useBackendActivateApi = (action, dispatch, done) => {
  Api.activateScene(action.accessToken, action.id)
    .then(() => {
      dispatch(apiRequestDone(ACTIVATE_SCENE))
      dispatch(clearCurrentSceneId())
    })
    .catch((error) => {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('activateScene failed without error')
      }
    })
    .then(() => done())
}

const activateScenesLogic = createLogic({
  type: ACTIVATE_SCENE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = getState()
    const { scenes: { scenesList: { scenes } } } = getState()
    next({
      ...action,
      accessToken,
      scenes,
      id,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(ACTIVATE_SCENE))
    const currentScene = action.scenes.filter((scene) => scene.id === action.id)[0]
    if (isAndroid && isWebView) {
      // eslint-disable-next-line no-undef
      if (AndroidFunction.triggerScene) {
        // eslint-disable-next-line no-undef
        AndroidFunction.triggerScene(currentScene.number)
        store.dispatch(activateSceneDoneAndroid())
        store.dispatch(clearCurrentSceneId())
        done()
      } else {
        useBackendActivateApi(action, dispatch, done)
      }
    } else if (isIOS && isWebView) {
      // eslint-disable-next-line no-alert
      const promptValue = prompt(`triggerScene&${currentScene.number}`)
      if (promptValue === 'Invalid JS Request'
        || !JSON.parse(promptValue).status) {
        useBackendActivateApi(action, dispatch, done)
      } else {
        store.dispatch(activateSceneDoneIOS())
        store.dispatch(clearCurrentSceneId())
        done()
      }
    } else {
      useBackendActivateApi(action, dispatch, done)
    }
  },
})

export default activateScenesLogic
