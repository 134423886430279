import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.125 74.4861V81.375H17.2361C14.4569 81.375 12.1944 79.1125 12.1944 76.3333V71.2917H67.1111H81.8889H136.806V76.3333C136.806 79.1125 134.543 81.375 131.764 81.375H124.875V74.4861V73.9861H124.375H81.8889H67.1111H24.625H24.125V74.4861ZM131.764 31.5H81.8889H67.1111H17.2361C11.8672 31.5 7.5 35.8672 7.5 41.2361V76.3333C7.5 81.7023 11.8672 86.0694 17.2361 86.0694H67.1111H81.8889H131.764C137.133 86.0694 141.5 81.7023 141.5 76.3333V41.2361C141.5 35.8672 137.133 31.5 131.764 31.5ZM17.2361 36.1944H67.1111H81.8889H131.764C134.543 36.1944 136.806 38.4569 136.806 41.2361V66.5972H81.8889H67.1111H12.1944V41.2361C12.1944 38.4569 14.4569 36.1944 17.2361 36.1944ZM67.1111 81.375H28.8194V78.6806H67.1111H81.8889H120.181V81.375H81.8889H67.1111Z" fill="currentColor" stroke="currentColor"/>
      <path d="M17.2363 55.5156H16.7363V56.0156V59.7101V60.2101H17.2363H32.0141H32.5141V59.7101V56.0156V55.5156H32.0141H17.2363Z" fill="currentColor" stroke="currentColor"/>
      <path d="M35.7085 55.5156H35.2085V56.0156V59.7101V60.2101H35.7085H39.4029H39.9029V59.7101V56.0156V55.5156H39.4029H35.7085Z" fill="currentColor" stroke="currentColor"/>
      <path d="M34.3611 92.957V92.457H33.8611H30.1666H29.6666V92.957C29.6666 93.6209 29.5425 94.0703 29.3388 94.4608C29.1252 94.8704 28.8156 95.2354 28.3847 95.739L28.3846 95.739C27.3476 96.9467 25.9722 98.5485 25.9722 101.574C25.9722 104.598 27.3437 106.197 28.3799 107.406L28.3847 107.412C28.3847 107.412 28.3848 107.412 28.3848 107.412C28.8157 107.915 29.1252 108.28 29.3388 108.69C29.5425 109.081 29.6666 109.53 29.6666 110.195C29.6666 110.86 29.5425 111.311 29.3386 111.701C29.1249 112.111 28.815 112.476 28.3835 112.978L28.3828 112.979L28.3768 112.986C27.3427 114.194 25.9722 115.796 25.9722 118.818V119.318H26.4722H30.1666H30.6666V118.818C30.6666 118.153 30.7907 117.703 30.9946 117.312C31.2083 116.902 31.5182 116.537 31.9497 116.035L31.9504 116.034L31.9545 116.03C32.9889 114.821 34.3611 113.218 34.3611 110.195C34.3611 107.17 32.9878 105.568 31.9509 104.359L31.9501 104.358L31.9497 104.357C31.5182 103.855 31.2083 103.49 30.9946 103.08C30.7907 102.69 30.6666 102.239 30.6666 101.574C30.6666 100.909 30.7907 100.46 30.9944 100.069C31.2079 99.6598 31.5174 99.2952 31.9482 98.7928L31.9482 98.7927L31.9534 98.7867C32.9895 97.5783 34.3611 95.9785 34.3611 92.957Z" fill="currentColor" stroke="currentColor"/>
      <path d="M56.528 92.957V92.457H56.028H52.3336H51.8336V92.957C51.8336 93.6209 51.7095 94.0703 51.5058 94.4608C51.2922 94.8704 50.9826 95.2354 50.5517 95.739L50.5516 95.739C49.5146 96.9467 48.1392 98.5485 48.1392 101.574C48.1392 104.598 49.5107 106.197 50.5469 107.406L50.5517 107.412C50.5517 107.412 50.5518 107.412 50.5518 107.412C50.9827 107.915 51.2922 108.28 51.5058 108.69C51.7095 109.081 51.8336 109.53 51.8336 110.195C51.8336 110.86 51.7095 111.311 51.5056 111.701C51.2919 112.111 50.982 112.476 50.5505 112.978L50.5498 112.979L50.5438 112.986C49.5097 114.194 48.1392 115.796 48.1392 118.818V119.318H48.6392H52.3336H52.8336V118.818C52.8336 118.153 52.9577 117.703 53.1616 117.312C53.3753 116.902 53.6852 116.537 54.1167 116.035L54.1174 116.034L54.1215 116.03C55.1558 114.821 56.528 113.218 56.528 110.195C56.528 107.17 55.1548 105.568 54.1178 104.359L54.1171 104.358L54.1167 104.357C53.6852 103.855 53.3753 103.49 53.1616 103.08C52.9577 102.69 52.8336 102.239 52.8336 101.574C52.8336 100.909 52.9577 100.46 53.1614 100.069C53.3749 99.6598 53.6844 99.2952 54.1152 98.7928L54.1152 98.7927L54.1204 98.7867C55.1565 97.5783 56.528 95.9785 56.528 92.957Z" fill="currentColor" stroke="currentColor"/>
      <path d="M78.6946 92.957V92.457H78.1946H74.5001H74.0001V92.957C74.0001 93.6209 73.876 94.0703 73.6723 94.4608C73.4587 94.8704 73.1491 95.2354 72.7182 95.739L72.7181 95.739C71.6811 96.9467 70.3057 98.5485 70.3057 101.574C70.3057 104.598 71.6772 106.197 72.7134 107.406L72.7182 107.412C72.7182 107.412 72.7183 107.412 72.7184 107.412C73.1492 107.915 73.4587 108.28 73.6723 108.69C73.876 109.081 74.0001 109.53 74.0001 110.195C74.0001 110.86 73.876 111.311 73.6721 111.701C73.4584 112.111 73.1485 112.476 72.717 112.978L72.7163 112.979L72.7103 112.986C71.6762 114.194 70.3057 115.796 70.3057 118.818V119.318H70.8057H74.5001H75.0001V118.818C75.0001 118.153 75.1242 117.703 75.3281 117.312C75.5418 116.902 75.8517 116.537 76.2832 116.035L76.2839 116.034L76.288 116.03C77.3224 114.821 78.6946 113.218 78.6946 110.195C78.6946 107.17 77.3213 105.568 76.2843 104.359L76.2836 104.358L76.2832 104.357C75.8517 103.855 75.5418 103.49 75.3281 103.08C75.1242 102.69 75.0001 102.239 75.0001 101.574C75.0001 100.909 75.1242 100.46 75.3279 100.069C75.5414 99.6598 75.8509 99.2952 76.2817 98.7928L76.2817 98.7927L76.2869 98.7867C77.323 97.5783 78.6946 95.9785 78.6946 92.957Z" fill="currentColor" stroke="currentColor"/>
      <path d="M100.861 92.957V92.457H100.361H96.6666H96.1666V92.957C96.1666 93.6209 96.0425 94.0703 95.8388 94.4608C95.6252 94.8704 95.3156 95.2354 94.8847 95.739L94.8846 95.739C93.8476 96.9467 92.4722 98.5485 92.4722 101.574C92.4722 104.598 93.8437 106.197 94.8799 107.406L94.8847 107.412C94.8847 107.412 94.8848 107.412 94.8849 107.412C95.3157 107.915 95.6252 108.28 95.8388 108.69C96.0425 109.081 96.1666 109.53 96.1666 110.195C96.1666 110.86 96.0425 111.311 95.8386 111.701C95.6249 112.111 95.315 112.476 94.8835 112.978L94.8828 112.979L94.8768 112.986C93.8427 114.194 92.4722 115.796 92.4722 118.818V119.318H92.9722H96.6666H97.1666V118.818C97.1666 118.153 97.2907 117.703 97.4946 117.312C97.7083 116.902 98.0182 116.537 98.4497 116.035L98.4504 116.034L98.4545 116.03C99.4889 114.821 100.861 113.218 100.861 110.195C100.861 107.17 99.4878 105.568 98.4509 104.359L98.4501 104.358L98.4497 104.357C98.0182 103.855 97.7083 103.49 97.4946 103.08C97.2907 102.69 97.1666 102.239 97.1666 101.574C97.1666 100.909 97.2907 100.46 97.4944 100.069C97.7079 99.6598 98.0174 99.2952 98.4482 98.7928L98.4482 98.7927L98.4534 98.7867C99.4895 97.5783 100.861 95.9785 100.861 92.957Z" fill="currentColor" stroke="currentColor"/>
      <path d="M123.028 92.957V92.457H122.528H118.833H118.333V92.957C118.333 93.6209 118.209 94.0703 118.005 94.4608C117.792 94.8704 117.482 95.2354 117.051 95.739L117.051 95.739C116.014 96.9467 114.639 98.5485 114.639 101.574C114.639 104.598 116.01 106.197 117.046 107.406L117.051 107.412C117.051 107.412 117.051 107.412 117.051 107.412C117.482 107.915 117.792 108.28 118.005 108.69C118.209 109.081 118.333 109.53 118.333 110.195C118.333 110.86 118.209 111.311 118.005 111.701C117.791 112.111 117.482 112.476 117.05 112.978L117.049 112.979L117.043 112.986C116.009 114.194 114.639 115.796 114.639 118.818V119.318H115.139H118.833H119.333V118.818C119.333 118.153 119.457 117.703 119.661 117.312C119.875 116.902 120.185 116.537 120.616 116.035L120.617 116.034L120.621 116.03C121.655 114.821 123.028 113.218 123.028 110.195C123.028 107.17 121.654 105.568 120.617 104.359L120.617 104.358L120.616 104.357C120.185 103.855 119.875 103.49 119.661 103.08C119.457 102.69 119.333 102.239 119.333 101.574C119.333 100.909 119.457 100.46 119.661 100.069C119.874 99.6598 120.184 99.2952 120.615 98.7928L120.615 98.7927L120.62 98.7867C121.656 97.5783 123.028 95.9785 123.028 92.957Z" fill="currentColor" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ConditionerWithColdAir')

export default icon
