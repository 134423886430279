import * as type from '@app/constants/mezzoTime/mezzoTime'
import type { Action, State } from '@app/types/mezzoTime/mezzoTimeTypes'

const initialState = {
  mezzoTime: '',
}

const mezzoTime = (state: State = initialState, action: Action): State => {
  switch (action.type) {
  case type.SET_MEZZO_TIME: return {
    ...state,
    mezzoTime: action.data.device_time,
  }
  case type.RESET_MEZZO_TIME:
    return initialState

  default:
    return state
  }
}

export default mezzoTime
