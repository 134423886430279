import { INTERVIEW_CHANNEL_TWR } from '@app/constants/channels'
import { closeTwrChannel, openTwrChannel } from '@app/actions/myDevices/channels/twr/twrChannel'
import openCable from '@app/api/cable'

const myDevicesTwrChannel = ({ request, response }, id) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(INTERVIEW_CHANNEL_TWR),
  )
  if (!subscription) {
    return cable.subscriptions.create({
      channel: INTERVIEW_CHANNEL_TWR,
      id,
    }, {
      connected() {
        request(this)
        store.dispatch(openTwrChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeTwrChannel())
      },
    })
  }
  return subscription
}

export default {
  myDevicesTwrChannel,
}
