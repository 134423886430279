import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ZWAVE_DEVICE_BINARY_SENSOR_TYPES } from '@app/constants/scenes/zWaveDevices/zWaveDevices'
import { updateZWaveDeviceBinarySensorTypes, syncZWaveDeviceBinarySensorValues } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import {
  SELECT_BINARY_SENSOR_TYPE,
} from '@app/constants/routes'
import fetchBinarySensorsTypes from '@app/api/scenes/ZWaveDevices/BinarySensors/fetchBinarySensorsTypes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { saveBinarySensorType, saveBinarySensorTypeDescription } from '@app/actions/scenes/currentSceneCreating'

const fetchZWaveBinarySensorTypesLogic = createLogic({
  type: SYNC_ZWAVE_DEVICE_BINARY_SENSOR_TYPES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionDeviceId } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ZWAVE_DEVICE_BINARY_SENSOR_TYPES))
    fetchBinarySensorsTypes(token, conditionDeviceId)
      .then((response) => {
        const { data: { binary_sensors } } = response
        dispatch(updateZWaveDeviceBinarySensorTypes(binary_sensors))
        dispatch(apiRequestDone(SYNC_ZWAVE_DEVICE_BINARY_SENSOR_TYPES))
        if (binary_sensors.length === 1) {
          dispatch(saveBinarySensorType(binary_sensors[0].sensor_type))
          dispatch(saveBinarySensorTypeDescription(binary_sensors[0].title))
          dispatch(syncZWaveDeviceBinarySensorValues())
        } else {
          history.push(SELECT_BINARY_SENSOR_TYPE)
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchBinarySensorsTypes failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchZWaveBinarySensorTypesLogic
