import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/sphere_users/change_password_by_reset_code`

const sendNewPassword = (accessToken, data) => Axios.post(ENDPOINT, data, {
  headers: { 'Access-Token': accessToken },
})

export default {
  sendNewPassword,
}
