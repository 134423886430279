import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import { convertConditionTimersForScreen } from '@app/helpers/scenes/convertTimersForScreen'
import getTimerDurationDescription from '@app/helpers/timers/getTimerDurationDescription'
import {
  SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE,
  SELECT_CONDITION_TIMER_VALUE,
  SELECT_TRIGGERING_DEVICE,
  TIMERS_IN_SCENES,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import { ReloadCircle } from 'icons'

class SelectTimer extends React.Component {
  onBackClick = () => {
    const { navigate } = this.props
    navigate(SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE)
  }

  onUseClick=(timer) => {
    const {
      saveConditionDeviceStateInfo,
      saveConditionDescription,
      clearTriggeringAndObservingDevices,
      saveConditionStateDescription,
      navigate,
    } = this.props
    clearTriggeringAndObservingDevices()
    saveConditionStateDescription('')
    saveConditionDescription(timer.name.toString())
    switch (timer.type) {
    case 'retriggerable':
      return navigate(SELECT_TRIGGERING_DEVICE)
    case 'repeating':
    case 'one_shot':
      saveConditionDeviceStateInfo({ number: timer.name.toString() })
      return navigate(SELECT_CONDITION_TIMER_VALUE)
    default:
      throw new Error('timer type unsupported')
    }
  }

  onSettingsClick=() => {
    const { navigate } = this.props
    navigate(TIMERS_IN_SCENES)
  }

  onReloadClick=() => {
    const { refresh } = this.props
    refresh()
  }

  renderTimersList=() => {
    const {
      classes,
      timers,
    } = this.props
    const timersInfo = convertConditionTimersForScreen(timers)
    switch (timersInfo) {
    case 'all_used':
      return (
        <>
          <Wrapper className={classes.emptyInfoWrapper}>
            <Typography>{I18n.t('scene_condition_web.all_used')}</Typography>
          </Wrapper>
        </>
      )
    case 'no_timers_configured':
      return (
        <>
          <Wrapper className={classes.emptyInfoWrapper}>
            <Typography>{I18n.t('scene_condition_web.no_timers_configured')}</Typography>
            <Typography>{I18n.t('scene_condition_web.go_to_settings')}</Typography>
            <Button
              color="primary"
              className={classes.button}
              onClick={this.onSettingsClick}
            >
              {I18n.t('scene_condition_web.settings')}
            </Button>
          </Wrapper>
        </>
      )
    default:
      return (timersInfo.map((timer) => (
        <CardBlock
          key={timer.name}
          title={I18n.t(`timers.timer_${timer.name}`)}
          description={`${I18n.t('timers.type')}: ${I18n.t(`timers.${timer.type}`)}`}
          position="topDoubleDescr"
          secondDescription={`${I18n.t('timers.duration')}: ${getTimerDurationDescription(timer.type, timer.duration)}`}
          buttonName={I18n.t('scene_web.use')}
          isCardDisabled={false}
          isButtonDisabled={false}
          onClick={() => {
            this.onUseClick(timer)
          }}
        />
      )))
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_condition_web.select_timer')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.reloadButtonWrapper}>
            <Button
              size="small"
              aria-label="Refresh"
              className={classNames(classes.refreshButton)}
              onClick={() => {
                this.onReloadClick()
              }}
            >
              <ReloadCircle />
            </Button>
          </Wrapper>
          {this.renderTimersList()}
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimer.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  clearTriggeringAndObservingDevices: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  timers: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    timer_type: PropTypes.string.isRequired,
    duration: PropTypes.shape({
      day: PropTypes.number.isRequired,
      hour: PropTypes.number.isRequired,
      minute: PropTypes.number.isRequired,
      second: PropTypes.number.isRequired,
    }),
    status: PropTypes.string.isRequired,
  })).isRequired,
}

SelectTimer.defaultProps = {

}

SelectTimer.displayName = 'SelectTimer'

export default SelectTimer
