export const GET_DEVICE_HEALTH = '@@myDevices/getDeviceHealth'

export const DELETE_UNHEALTHY_DEVICE = '@@myDevices/deleteUnhealthyDevice'

export const RESET_FAILED_NODE_DATA = '@@myDevices/resetFailedNodeData'
export const RESET_FAILED_NODE_STATUS = '@@myDevices/resetFailedNodeStatus'

export const REPLACE_UNHEALTHY_DEVICE = '@@myDevices/replaceUnhealthyDevice'

export const CANCEL_REPLACE_DEVICE = '@@myDevices/cancelReplaceDevice'
