import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width="100" height="92" viewBox="0 0 125 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.9124 72.3776L67.9095 72.3761L51.9905 64.4174C51.9898 64.4171 51.9891 64.4167 51.9884 64.4164L51.9878 64.4161L52.435 63.5217C51.7015 63.1577 51.0845 62.5955 50.6542 61.8988C50.2238 61.2022 49.9972 60.3989 50 59.58L67.9124 72.3776ZM67.9124 72.3776C70.248 73.5358 72.2125 75.3252 73.583 77.5429C74.9536 79.7606 75.6754 82.318 75.6667 84.925V84.9283V90.6667H1V84.6033L1 84.603C0.999245 82.0808 1.67825 79.6051 2.96558 77.4362L2.10564 76.9258L2.96558 77.4362C4.25291 75.2673 6.10098 73.4854 8.31538 72.278L8.31552 72.2779L23.1839 64.1679L23.1846 64.1675C24.9197 63.2192 26 61.4032 26 59.4233V53.9083V53.5701L25.7946 53.3013C24.7159 51.8892 21.6106 47.4595 20.1312 41.6018L20.0491 41.2765L19.7873 41.0665C18.4477 39.9923 17.6667 38.3862 17.6667 36.665V29.9983C17.6667 28.643 18.1737 27.3209 19.0848 26.2831L19.3333 26V25.6233V16.7583V16.7075L19.3282 16.6569C19.2429 15.8205 18.9075 10.7224 22.5718 6.5427C25.7325 2.93885 30.9536 1 38.3333 1C45.713 1 50.934 2.93884 54.0947 6.54421L54.0947 6.54423C57.7591 10.724 57.4238 15.8205 57.3385 16.6587L57.3333 16.7092V16.76V25.625V26.0017L57.5818 26.2847C58.4929 27.3225 59 28.6447 59 30V36.6667V36.6677C59.0011 37.7468 58.6913 38.8033 58.1074 39.7108C57.5236 40.6183 56.6906 41.3383 55.7081 41.7845L55.3146 41.9633L55.1751 42.3724C54.0336 45.7218 52.4193 48.891 50.3811 51.7837C49.9772 52.3546 49.5899 52.8669 49.2259 53.3119L49 53.5881V53.945V59.5789L67.9124 72.3776Z" stroke="currentColor" strokeWidth="2"/>
      <path d="M75.7928 67.2544L75.8174 67.2667L75.8427 67.2776L92.022 74.2832C96.3641 76.4644 99 80.7433 99 85.505V90.6666H80.165V84.9267C80.1765 81.4819 79.2228 78.1026 77.4119 75.1721C75.6009 72.2416 73.0052 69.877 69.9189 68.3464C69.9185 68.3462 69.918 68.3459 69.9176 68.3457L53.9988 60.3872L53.998 60.3868C53.6806 60.2285 53.5 59.9377 53.5 59.5783V55.1437C55.7796 52.0638 57.6219 48.6831 58.9741 45.0979C60.3116 44.2062 61.4199 43.0089 62.206 41.6031C63.0493 40.095 63.4941 38.3969 63.4983 36.6691V36.6666V30C63.4983 28.0253 62.912 26.1024 61.8317 24.4576V16.9199C61.9184 15.8964 62.0118 13.1678 61.0329 9.98865C62.4069 9.72384 63.8418 9.57483 65.3435 9.56168C70.0835 9.602 73.6194 10.8284 76.272 12.6043C78.9331 14.386 80.759 16.7573 82.0131 19.1594C83.2689 21.5647 83.9412 23.9824 84.2986 25.8185C84.4769 26.7344 84.5758 27.4997 84.63 28.0383C84.6824 28.5578 84.6929 28.8629 84.6936 28.8851C84.9282 40.9322 92.3472 52.7046 95.4651 57.1416L95.4655 57.142C95.8042 57.6235 96.0309 58.1747 96.1289 58.7552C96.2269 59.3356 96.1937 59.9307 96.0319 60.4967L96.0305 60.5017C95.8734 61.0618 95.5931 61.5796 95.21 62.0173C94.8271 62.4549 94.3511 62.8013 93.817 63.0311C91.7312 63.927 89.2824 64.1283 87.545 64.1283C87.0783 64.1283 86.6961 64.1135 86.4329 64.099C86.3014 64.0917 86.1999 64.0845 86.1328 64.0793C86.0993 64.0767 86.0743 64.0746 86.0586 64.0732L86.0419 64.0717L86.0393 64.0714L86.0392 64.0714L86.0391 64.0714L86.0389 64.0714L86.0388 64.0714L86.0386 64.0714L85.9902 64.0666L85.9409 64.0666L75.0659 64.075L73.3254 64.0763L74.2031 65.5793C74.6036 66.2651 75.1509 66.9335 75.7928 67.2544Z" stroke="currentColor" strokeWidth="2"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EventsDashboardIcon')

export default icon
