const renderIcon = (sensorType) => {
  switch (sensorType) {
  case 'battery': return 'iconBattery'
  case 'illuminance': return 'iconIlluminanceLevel'
  case 'smoke_level': return 'iconSmokeLevel'
  case 'humidity': return 'humidityLevel'
  case 'ultraviolet': return 'ultravioletLevel'
  case 'air_temperature': return 'airTemperatureLevel'
  case 'non_existent': return ''
  default: return ''
  }
}
export default renderIcon
