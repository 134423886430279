import * as type from '@app/constants/myDevices/deviceSettings/securityOption'

export interface ISecurityOptionData {
  enabled?: boolean,
  option: string,
}

export type TSecurityOptionAction =
  | { type: '@@myDevices/patchSecurityOption', id: number, data: ISecurityOptionData }

export const patchSecurityOption = (id: number, data: ISecurityOptionData): TSecurityOptionAction => ({
  type: type.PATCH_SECURITY_OPTION,
  id,
  data,
})
