import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { syncGeofenceUsers } from '@app/actions/users/geofenceUsers'
import { saveConditionUsersInfo } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGeofencesUser
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesUser/SelectGeofencesUser'
import SelectGeofencesUserIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesUser/SelectGeofencesUser'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectGeofencesUserIOS) : withStyles(SelectGeofencesUser)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  const { users } = state.users.geofenceUsers
  return {
    isMobile,
    path,
    isEditConditionMode,
    users,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncGeofenceUsers,
  tryAgain: syncGeofenceUsers,
  syncGeofenceUsers,
  saveConditionUsersInfo,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGeofencesUserEnhanced = enhance

export default withRouter(SelectGeofencesUserEnhanced)
