import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import {
  IconButton, Tabs, Tab, Typography, Button,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import Colour from '@app/components/MyDevices/DeviceInfo/DeviceColours/Colour/Colour'
import Brightness from '@app/components/MyDevices/DeviceInfo/DeviceColours/Brightness/Brightness'
import { postDeviceColour } from '@app/actions/myDevices/deviceSettings/deviceColours'
import { setCurrentDevice } from '@app/actions/myDevices/myDevices'
import { MIN_BRIGHTNESS } from '@app/lib/MyDevices/deviceColours'
import useStyles from './DeviceColours.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  devices: Array<IZwaveOrTwrDevice>,
  isMobile: boolean,
}

const DeviceColours: React.FC<Props> = (props) => {
  const {
    currentDevice,
    devices,
    isMobile,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    red, green, blue, white_warm, white_cold,
  } = currentDevice.info?.color_state?.value!
  const [currentTab, setCurrentTab] = useState<number>(
    (white_warm >= MIN_BRIGHTNESS || white_cold >= MIN_BRIGHTNESS) ? 1 : 0,
  )
  const [pickedColor, setPickedColor] = useState<string>(
    currentDevice.info?.color_state?.value ? `rgb(${red}, ${green}, ${blue})` : 'rgb(0, 0, 0)',
  )
  const [pickedBrightness, setPickedBrightness] = useState<string>('')
  const [whiteWarmValue, setWhiteWarmValue] = useState<number>(
    typeof white_warm === 'number' ? white_warm : 0,
  )
  const [whiteColdValue, setWhiteColdValue] = useState<number>(
    typeof white_cold === 'number' ? white_cold : 0,
  )
  const [isColorSetting, setIsColorSetting] = useState<boolean>(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    const device = devices.find((dev) => (dev.id === currentDevice.id && dev.device_type === currentDevice.device_type))
    if (device) {
      dispatch(setCurrentDevice(device))
    }
  }, [devices])

  useEffect(() => {
    if (isColorSetting) {
      goBackHandler()
    }
  }, [currentDevice.info?.color_state?.value])

  useEffect(() => {
    if ((white_warm === whiteWarmValue)
      && (white_cold === whiteColdValue)
      && `rgb(${red}, ${green}, ${blue})` === pickedColor) {
      setIsSaveButtonDisabled(true)
    } else {
      setIsSaveButtonDisabled(false)
    }
  }, [pickedColor, whiteWarmValue, whiteColdValue])

  const goBackHandler = () => navigate(-1)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const parseRGB = (rgbString: string) => {
    const [r, g, b] = rgbString.match(/\d+/g)!.map(Number)
    return { red: r, green: g, blue: b }
  }

  const submitColoursChanges = () => {
    setIsColorSetting(true)
    if (currentTab === 0) {
      dispatch(postDeviceColour(
        currentDevice.id,
        {
          colors: {
            ...parseRGB(pickedColor),
          },
        },
      ))
    } else if (currentTab === 1) {
      dispatch(postDeviceColour(
        currentDevice.id,
        {
          colors: {
            [pickedBrightness]: pickedBrightness === 'white_warm' ? whiteWarmValue : whiteColdValue,
          },
        },
      ))
    }
  }

  return (
    <>
      {currentDevice ? (
        <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          {isColorSetting && (
            <Wrapper className={classes.preloader}>
              <Typography className={classes.preloaderMessage}>{I18n.t('zwave_colour.setting_colour')}</Typography>
            </Wrapper>
          )}
          <Wrapper className={classes.navigationControls}>
            <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
              <ScenesArrowBack />
            </IconButton>
            <Typography className={classes.navigationTitle}>{I18n.t('zwave_colour.colour_options')}</Typography>
          </Wrapper>
          <Tabs
            className={classes.tabsWrapper}
            value={currentTab}
            onChange={handleChange}
            variant="fullWidth"
          >
            <Tab className={classes.tab} label="COLOUR" />
            <Tab className={classes.tab} label="BRIGHTNESS" />
          </Tabs>
          <Wrapper className={classNames(classes.main, isColorSetting && classes.blur)}>
            {currentTab === 0 && (
              <Colour
                currentDevice={currentDevice}
                pickedColor={pickedColor}
                setPickedColor={setPickedColor}
                setWhiteWarmValue={setWhiteWarmValue}
                setWhiteColdValue={setWhiteColdValue}
                isMobile={isMobile}
              />
            )}
            {currentTab === 1 && (
              <Brightness
                currentDevice={currentDevice}
                pickedBrightness={pickedBrightness}
                setPickedBrightness={setPickedBrightness}
                whiteWarmValue={whiteWarmValue}
                setWhiteWarmValue={setWhiteWarmValue}
                whiteColdValue={whiteColdValue}
                setWhiteColdValue={setWhiteColdValue}
                setPickedColor={setPickedColor}
              />
            )}
            <Button
              onClick={submitColoursChanges}
              color="primary"
              variant="outlined"
              className={classNames(classes.saveButton, isMobile && classes.saveButtonMobile)}
              disabled={isSaveButtonDisabled}
            >
              {I18n.t('buttons.save')}
            </Button>
          </Wrapper>
        </Wrapper>
      ) : <Navigate to={location.pathname.split('/').slice(0, -1).join('/')} />}
    </>
  )
}

export default DeviceColours
