export const MY_STATUS_POPUP_OPEN = '@@myStatus/myStatusPopupOpen'
export const MY_STATUS_POPUP_CLOSED = '@@myStatus/myStatusPopupClosed'

export const SYNC_MY_STATUS = '@@myStatus/myStatusSync'

export const SYNC_MY_STATUS_CHANNEL_SENT = '@@myStatus/syncMyStatusChannelSent'
export const SYNC_MY_STATUS_CHANNEL_CANCEL = '@@myStatus/syncMyStatusChannelCancel'

export const SYNC_MY_STATUS_POPUP = '@@myStatus/myStatusPopupSync'

export const UPDATE_MY_STATUS_POPUP_DATA = '@@myStatus/myStatusPopupDeviceStats'
export const SET_MY_STATUS_DEVICES = '@@myStatus/myStatusSetDevices'
export const FILTER_MY_STATUS_DEVICES = '@@myStatus/myStatusDevicesFiltered'

export const SYNC_MY_STATUS_CHANNEL = '@@myStatusChannel/sync'
export const OPEN_MY_STATUS_CHANNEL = '@@myStatusChannel/open'
export const CLOSE_MY_STATUS_CHANNEL = '@@myStatusChannel/closed'
export const UPDATE_MY_STATUS_DEVICES = '@@myStatusChannel/updatedDevices'
export const RESET_MY_STATUS_PIR = '@@myStatusChannel/resettedPIR'
export const RESET_MY_PIR_DEVICES = '@@myStatusChannel/resetMyPIRDevices'
