const getMezzoConditionDataValue = (state) => {
  switch (state) {
  case 'is_in_panic_alarm':
  case 'is_in_duress_alarm':
  case 'is_in_fire_alarm':
    return 1
  default:
    return 0
  }
}

export default getMezzoConditionDataValue
