import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import {
  getConditionsActions,
} from '@app/actions/scenes/currentSceneConditionsActions'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied,
  withNotifications,
  withProgressAndErrorMessage,
  withRefresh,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import SceneReviewEdit from '@app/components/MyAutomation/Tabs/ScenesScreens/SceneReviewEdit/SceneReviewEdit'
import SceneReviewEditIOS from '@app/components/MyAutomation/Tabs/iOS/SceneReviewEdit/SceneReviewEdit'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? SceneReviewEditIOS : SceneReviewEdit

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { conditions, actions } = state.scenes.currentSceneConditionsActions
  const { name: sceneName } = state.scenes.scenesList.currentScene
  const {
    isEditActionMode,
    isEditConditionMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
    sceneMode,
  } = state.scenes.currentSceneCreating
  const {
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
    editedSceneInitialActions,
    editedSceneInitialConditions,
    editedSceneInitialName,
  } = state.scenes.editScene
  const title = 'review_scene'
  const withButton = isIOS
  return {
    conditions,
    actions,
    title,
    withButton,
    isMobile,
    path,
    sceneName,
    isEditActionMode,
    isEditConditionMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
    sceneMode,
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
    editedSceneInitialActions,
    editedSceneInitialConditions,
    editedSceneInitialName,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: getConditionsActions,
  tryAgain: getConditionsActions,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SceneReviewEditEnhanced = enhance

export default SceneReviewEditEnhanced
