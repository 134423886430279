import { googleMapZoomToMeters } from '@app/lib/Scenes/geofences'

const calculateBounds = (radius) => {
  const zoomToMetersObj = Object.values(googleMapZoomToMeters).map((zoom) => zoom / 50)
  const newZoom = zoomToMetersObj.map((value, i) => {
    if (zoomToMetersObj[i] > radius && zoomToMetersObj[i + 1] < radius) {
      return Object.keys(googleMapZoomToMeters)[i]
    }
    return null
  }).filter((zoom) => typeof zoom === 'string')[0]
  if (!newZoom) {
    throw new Error('Check calculate bounds function')
  }
  return +newZoom
}

export default calculateBounds
