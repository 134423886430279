const styles = () => ({
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginRight: 20,
    marginTop: 20,
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    width: 160,
  },
})

export default styles
