import { IArmTypes } from '@app/@types/armStatus'
import { Status } from '@app/lib/guardPanel/guardPanel'

export const getArmStatus = (armStatus: Status.SIMPLE_ARMED | Status.SIMPLE_DISARMED, armTypes: IArmTypes, isNCentral: boolean): Status => {
  if (!isNCentral) {
    if (armTypes.perimeter) {
      return Status.ARMED_PERIMETER
    }
    if (armStatus === Status.SIMPLE_ARMED) {
      return Status.SIMPLE_ARMED
    }
    if (armStatus === Status.SIMPLE_DISARMED) {
      return Status.SIMPLE_DISARMED
    }
    throw new Error('unknown Mezzo guard status')
  }
  if (armTypes.perimeter) {
    return Status.ARMED_PERIMETER
  }
  if (armTypes.area_one && armTypes.area_two) {
    return Status.SIMPLE_ARMED
  }
  if (armTypes.area_one) {
    return Status.ARMED_AREA_1
  }
  if (armTypes.area_two) {
    return Status.ARMED_AREA_2
  }
  return Status.SIMPLE_DISARMED
}
