import React from 'react'
import classNames from 'classnames'
import {
  Button, FormControlLabel, Paper, Radio, RadioGroup, Typography,
} from '@mui/material'
import { conditionTimeTypeToBackend } from '@app/lib/Scenes/conditionTimeTypeToBackend'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  OFFSET_SETTINGS,
  SELECT_TIME_CONDITION_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectTimeConditionStateWhen extends React.Component {
  state = { selected: 'after_sunset' };

  handleChange = (button) => {
    this.setState({ selected: button.target.value })
  };

  onNextClick=() => {
    const { selected } = this.state
    const {
      saveConditionType,
      saveConditionDescription,
      saveConditionStateDescription,
      isEditConditionMode,
      navigate,
    } = this.props
    saveConditionType(conditionTimeTypeToBackend[selected])
    saveConditionDescription('time_condition_state_when')
    if (selected === 'when_sunset' || selected === 'when_sunrise') {
      saveConditionStateDescription(selected === 'when_sunrise' ? 'sunrise' : 'sunset')
      navigate(OFFSET_SETTINGS)
    } else {
      saveConditionStateDescription(I18n.t(`scene_web.time_condition_state_${selected}`))
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    }
  }

  onBackClick=() => {
    const {
      navigate,
      isEditConditionMode,
      isChangeConditionStateMode,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_TIME_CONDITION_TYPE)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const { selected } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <RadioGroup className={classes.radioWrapper} aria-label="time" value={selected} onChange={this.handleChange}>
            <FormControlLabel
              className={classes.buttonWrapper}
              value="after_sunset"
              control={<Radio color="primary" />}
              label={I18n.t('scene_web.time_condition_state_after_sunset')}
              labelPlacement="start"
            />
            <FormControlLabel
              className={classes.buttonWrapper}
              value="after_sunrise"
              control={<Radio color="primary" />}
              label={I18n.t('scene_web.time_condition_state_after_sunrise')}
              labelPlacement="start"
            />
            <FormControlLabel
              className={classes.buttonWrapper}
              value="when_sunset"
              control={<Radio color="primary" />}
              label={I18n.t('scene_web.time_condition_state_when_sunset_offset')}
              labelPlacement="start"
            />
            <FormControlLabel
              className={classes.buttonWrapper}
              value="when_sunrise"
              control={<Radio color="primary" />}
              label={I18n.t('scene_web.time_condition_state_when_sunrise_offset')}
              labelPlacement="start"
            />
          </RadioGroup>
          <Button
            variant="outlined"
            color="primary"
            className={classes.addButton}
            onClick={this.onNextClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimeConditionStateWhen.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
}

SelectTimeConditionStateWhen.displayName = 'SelectTimeConditionStateWhen'

export default SelectTimeConditionStateWhen
