import * as type from '@app/constants/scenes/devicesTabs'

export const chooseTab = (chosenTab: number) => ({
  type: type.CHOOSE_TAB,
  chosenTab,
} as const)

export const resetDeviceTabs = () => ({
  type: type.RESET_DEVICE_TABS,
} as const)
