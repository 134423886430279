import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_CONDITION } from '@app/constants/scenes/currentSceneConditionsActions'
import { getConditionsActions } from '@app/actions/scenes/currentSceneConditionsActions'
import Api from '@app/api/scenes/deleteCondition'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { clearCurrentConditionInfo } from '@app/actions/scenes/currentSceneCreating'

const deleteConditionLogic = createLogic({
  type: DELETE_CONDITION,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { id } } } } = store.getState()
    dispatch(apiRequestSent(DELETE_CONDITION))
    Api.deleteCondition(token, id)
      .then(() => {
        dispatch(apiRequestDone(DELETE_CONDITION))
        dispatch(getConditionsActions())
        dispatch(clearCurrentConditionInfo())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteCondition failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteConditionLogic
