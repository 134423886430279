import React from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from '@app/components/Rooms/RoomsList/ErrorPopup/ErrorPopup.style'

interface Props {
  open: boolean,
  close: () => void,
}

const ErrorPopup: React.FC<Props> = (props) => {
  const {
    open,
    close,
  } = props
  const classes = useStyles()

  return (
    <Dialog className={classes.root} open={open}>
      <DialogTitle className={classes.title}>{I18n.t('scene_web.error')}</DialogTitle>
      <DialogContent className={classes.popupContent}>
        <Typography className={classes.errorMessage}>{I18n.t('rooms_and_groups.too_many_rooms')}</Typography>
        <Wrapper className={classes.popupButtons}>
          <Button
            className={classes.retryButton}
            onClick={close}
          >
            {I18n.t('buttons.okay')}
          </Button>
        </Wrapper>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorPopup
