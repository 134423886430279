import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, LinearProgress, Typography } from '@mui/material'
import { MY_DEVICES } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './DeviceInfo.style'

interface Props {
  errorFindingDevice: boolean,
}

const ErrorAndProgress: React.FC<Props> = (props) => {
  const { errorFindingDevice } = props
  const navigate = useNavigate()
  const classes = useStyles()

  if (errorFindingDevice) {
    return (
      <Wrapper className={classes.errorContainer}>
        <Typography className={classes.errorText}>{I18n.t('my_devices.no_such_device')}</Typography>
        <Button
          className={classes.errorButton}
          variant="outlined"
          color="primary"
          onClick={() => navigate(MY_DEVICES)}
        >
          {I18n.t('my_devices.go_back')}
        </Button>
      </Wrapper>
    )
  }
  return <LinearProgress className={classes.progressBar} />
}

export default ErrorAndProgress
