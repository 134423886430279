export const SAVE_CONDITIONS = '@@currentSceneConditionsActions/saveConditions'
export const SAVE_ACTIONS = '@@currentSceneConditionsActions/saveActions'

export const RESET_CURRENT_SCENE_CONDITIONS_ACTIONS = '@@currentSceneConditionsActions/reset'

export const GET_CONDITIONS_ACTIONS = '@@currentSceneConditionsActions/getConditionsActions'

export const DELETE_CONDITION = '@@currentSceneConditionsActions/deleteCondition'

export const DELETE_ACTION = '@@currentSceneConditionsActions/deleteAction'

export const CHANGE_CONDITION_OPERAND_VALUE = '@@currentSceneConditionsActions/changeConditionOperandValue'
