import React, { Node } from 'react'
import { Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import useStyles from './ForbiddenAccess.style'

const Forbiddenable = (): Node => {
  const classes = useStyles()
  return (
    <Wrapper className={classes.wrapper}>
      <Typography className={classes.title}>{I18n.t('errors.forbidden_access')}</Typography>
    </Wrapper>
  )
}

export default Forbiddenable
