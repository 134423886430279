import {
  I18n, loadTranslations, setLocale, syncTranslationWithStore,
} from 'react-redux-i18n'
import translationsObject from '@app/locales'

const checkForMezzoErrors = (key) => {
  const pattern = new RegExp('mezzo_error_codes')
  return pattern.test(key)
}

const myHandleMissingTranslation = (key) => {
  if (checkForMezzoErrors(key)) {
    return `${I18n.t('mezzo_error_codes.unknown_error_type')}`.replace('%d', key.match(/\d+/))
  }
  return `Missing translation: ${key}`
}

const configureLocales = (store) => {
  syncTranslationWithStore(store)
  store.dispatch(loadTranslations(translationsObject))
  store.dispatch(setLocale('en'))
  global.I18n = I18n
  I18n.setHandleMissingTranslation(myHandleMissingTranslation)
}

export default configureLocales
