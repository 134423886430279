import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250 250"
      id="vector">
      <g
        id="group"
        transform="translate(9 38)">
        <path
          id="path"
          d="M 107.5 0 L 123 0.5 L 107.5 1 L 107.5 0 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_1"
          d="M 94.5 1 L 100 1.5 L 94.5 2 L 94.5 1 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_2"
          d="M 130.5 1 L 136 1.5 L 130.5 2 L 130.5 1 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_3"
          d="M 87.5 2 L 91 2.5 L 87.5 3 L 87.5 2 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_4"
          d="M 140.5 2 L 144 2.5 L 140.5 3 L 140.5 2 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_5"
          d="M 81.5 3 L 85 3.5 L 81.5 4 L 81.5 3 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_6"
          d="M 147.5 3 L 150 3.5 L 147.5 4 L 147.5 3 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_7"
          d="M 76.5 4 L 79 4.5 L 76.5 5 L 76.5 4 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_8"
          d="M 152.5 4 L 155 4.5 L 152.5 5 L 152.5 4 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_9"
          d="M 72.5 5 L 75 5.5 L 72.5 6 L 72.5 5 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_10"
          d="M 156.5 5 L 159 5.5 L 156.5 6 L 156.5 5 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_11"
          d="M 160.5 6 L 163 6.5 L 160.5 7 L 160.5 6 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_12"
          d="M 60.5 9 L 62 9.5 L 58.5 11 L 58.5 10 L 60.5 9 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_13"
          d="M 100.5 9 L 108 9.5 L 100.5 10 L 100.5 9 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_14"
          d="M 122.5 9 L 131 9.5 L 122.5 10 L 122.5 9 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_15"
          d="M 170.5 9 L 174 10.5 L 172.5 11 L 170.5 9 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_16"
          d="M 91.5 10 L 95 10.5 L 91.5 11 L 91.5 10 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_17"
          d="M 135.5 10 L 140 10.5 L 135.5 11 L 135.5 10 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_18"
          d="M 55.5 11 L 57 11.5 L 50.5 15 L 51.5 13 L 55.5 11 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_19"
          d="M 85.5 11 L 88 11.5 L 85.5 12 L 85.5 11 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_20"
          d="M 143.5 11 L 146 11.5 L 143.5 12 L 143.5 11 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_21"
          d="M 80.5 12 L 83 12.5 L 80.5 13 L 80.5 12 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_22"
          d="M 148.5 12 L 152 12.5 L 148.5 13 L 148.5 12 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_23"
          d="M 153.5 13 L 156 13.5 L 153.5 14 L 153.5 13 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_24"
          d="M 72.5 14 L 75 14.5 L 72.5 15 L 72.5 14 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_25"
          d="M 157.5 14 L 160 14.5 L 157.5 15 L 157.5 14 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_26"
          d="M 181.5 14 L 195 25.5 L 197 30.5 L 196 30.5 Q 193.6 21.9 186.5 18 Q 181.9 16.8 181.5 14 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_27"
          d="M 48.5 15 L 47.5 17 L 48.5 15 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_28"
          d="M 45.5 17 L 43.5 20 L 45.5 17 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_29"
          d="M 63.5 17 L 65 17.5 L 61.5 19 L 61.5 18 L 63.5 17 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_30"
          d="M 169.5 18 L 173 19.5 L 171.5 20 L 169.5 18 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_31"
          d="M 41.5 20 L 39.5 23 L 41.5 20 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_32"
          d="M 174.5 20 Q 182.3 23.3 187 29.5 L 181.5 25 L 175.5 22 L 174.5 20 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_33"
          d="M 55.5 21 L 54.5 23 L 52.5 24 L 53.5 22 L 55.5 21 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_34"
          d="M 38.5 24 L 37 26.5 L 34.5 33 L 34 30.5 L 38.5 24 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_35"
          d="M 50.5 24 L 44 31.5 L 42.5 36 L 42 33.5 L 50.5 24 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_36"
          d="M 197.5 33 L 198 58.5 L 197 58.5 L 197.5 33 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_37"
          d="M 33.5 35 L 34 47.5 L 33 47.5 L 33.5 35 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_38"
          d="M 42.5 47 L 43 88.5 L 44 89.5 L 42 90 L 42.5 47 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_39"
          d="M 188.5 58 L 189 89.5 L 186.5 91 L 186.5 90 L 188 89.5 L 188.5 58 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_40"
          d="M 91.5 81 L 100 81.5 L 91.5 82 L 91.5 81 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_41"
          d="M 124.5 81 L 134 81.5 L 124.5 82 L 124.5 81 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_42"
          d="M 79.5 82 L 85 82.5 L 79.5 83 L 79.5 82 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_43"
          d="M 139.5 82 L 145 82.5 L 139.5 83 L 139.5 82 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_44"
          d="M 71.5 83 L 76 83.5 L 71.5 84 L 71.5 83 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_45"
          d="M 149.5 83 L 153 83.5 L 149.5 84 L 149.5 83 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_46"
          d="M 65.5 84 L 69 84.5 L 65.5 85 L 65.5 84 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_47"
          d="M 157.5 84 L 160 84.5 L 157.5 85 L 157.5 84 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_48"
          d="M 59.5 85 L 63 85.5 L 59.5 86 L 59.5 85 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_49"
          d="M 54.5 86 L 57 86.5 L 54.5 87 L 54.5 86 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_50"
          d="M 49.5 87 L 52 87.5 L 49.5 88 L 49.5 87 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_51"
          d="M 45.5 88 L 48 88.5 L 45.5 89 L 45.5 88 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_52"
          d="M 93.5 90 L 103 90.5 L 93.5 91 L 93.5 90 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_53"
          d="M 126.5 90 L 136 90.5 L 126.5 91 L 126.5 90 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_54"
          d="M 196.5 90 L 197 94 L 201 95.5 L 199.5 96 L 196 93.5 L 196.5 90 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_55"
          d="M 82.5 91 L 87 91.5 L 82.5 92 L 82.5 91 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_56"
          d="M 141.5 91 L 146 91.5 L 141.5 92 L 141.5 91 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_57"
          d="M 74.5 92 L 78 92.5 L 74.5 93 L 74.5 92 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_58"
          d="M 151.5 92 L 154 92.5 L 151.5 93 L 151.5 92 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_59"
          d="M 68.5 93 L 71 93.5 L 68.5 94 L 68.5 93 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_60"
          d="M 30.5 94 L 32 94.5 L 28.5 96 L 28.5 95 L 30.5 94 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_61"
          d="M 62.5 94 L 65 94.5 L 62.5 95 L 62.5 94 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_62"
          d="M 57.5 95 L 60 95.5 L 57.5 96 L 57.5 95 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_63"
          d="M 53.5 96 L 56 96.5 L 53.5 97 L 53.5 96 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_64"
          d="M 173.5 96 L 176 96.5 L 173.5 97 L 173.5 96 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_65"
          d="M 49.5 97 L 52 97.5 L 49.5 98 L 49.5 97 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_66"
          d="M 204.5 97 L 209 99.5 L 207.5 99 Q 203.8 98.8 204.5 97 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_67"
          d="M 45.5 98 L 48 98.5 L 45.5 99 L 45.5 98 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_68"
          d="M 108.5 98 L 117 98.5 L 108.5 99 L 108.5 98 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_69"
          d="M 86.5 99 L 94 99.5 L 86.5 100 L 86.5 99 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_70"
          d="M 131.5 99 L 139 99.5 L 131.5 100 L 131.5 99 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_71"
          d="M 76.5 100 L 82 100.5 L 76.5 101 L 76.5 100 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_72"
          d="M 144.5 100 L 150 100.5 L 144.5 101 L 144.5 100 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_73"
          d="M 16.5 101 L 15.5 103 L 16.5 101 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_74"
          d="M 36.5 101 L 38 101.5 L 34.5 103 L 34.5 102 L 36.5 101 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_75"
          d="M 69.5 101 L 73 101.5 L 69.5 102 L 69.5 101 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_76"
          d="M 153.5 101 L 157 101.5 L 153.5 102 L 153.5 101 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_77"
          d="M 212.5 101 L 213.5 103 L 212.5 101 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_78"
          d="M 63.5 102 L 66 102.5 L 63.5 103 L 63.5 102 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_79"
          d="M 160.5 102 L 163 102.5 L 160.5 103 L 160.5 102 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_80"
          d="M 13.5 103 L 11.5 106 L 5 111.5 L 1.5 118 L 1 116.5 L 10.5 105 L 13.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_81"
          d="M 31.5 103 L 33 103.5 L 29.5 105 L 29.5 104 L 31.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_82"
          d="M 57.5 103 L 61 103.5 L 57.5 104 L 57.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_83"
          d="M 165.5 103 L 169 103.5 L 165.5 104 L 165.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_84"
          d="M 196.5 103 L 200 104.5 L 198.5 105 L 196.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_85"
          d="M 215.5 103 L 225 111.5 L 228 117.5 L 227 117.5 Q 224.4 110.1 218.5 106 L 215.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_86"
          d="M 53.5 104 L 56 104.5 L 53.5 105 L 53.5 104 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_87"
          d="M 170.5 104 L 173 104.5 L 170.5 105 L 170.5 104 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_88"
          d="M 174.5 105 L 177 105.5 L 174.5 106 L 174.5 105 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_89"
          d="M 45.5 106 L 48 106.5 L 45.5 107 L 45.5 106 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_90"
          d="M 178.5 106 L 181 106.5 L 178.5 107 L 178.5 106 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_91"
          d="M 23.5 107 L 22.5 109 L 20.5 110 L 21.5 108 L 23.5 107 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_92"
          d="M 94.5 107 L 108 107.5 L 94.5 108 L 94.5 107 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_93"
          d="M 117.5 107 L 132 107.5 L 117.5 108 L 117.5 107 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_94"
          d="M 205.5 107 L 209 109.5 L 207.5 109 L 205.5 107 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_95"
          d="M 81.5 108 L 87 108.5 L 81.5 109 L 81.5 108 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_96"
          d="M 138.5 108 L 144 108.5 L 138.5 109 L 138.5 108 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_97"
          d="M 185.5 108 L 189 109.5 L 187.5 110 L 185.5 108 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_98"
          d="M 36.5 109 L 38 109.5 L 34.5 111 L 34.5 110 L 36.5 109 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_99"
          d="M 73.5 109 L 78 109.5 L 73.5 110 L 73.5 109 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_100"
          d="M 148.5 109 L 153 109.5 L 148.5 110 L 148.5 109 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_101"
          d="M 18.5 110 L 15.5 114 L 13 115.5 L 10.5 119 L 12 116.5 L 14.5 113 L 18.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_102"
          d="M 66.5 110 L 70 110.5 L 65.5 112 L 66.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_103"
          d="M 155.5 110 L 159 110.5 L 155.5 111 L 155.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_104"
          d="M 190.5 110 L 199 114.5 L 197.5 114 L 190.5 111 L 190.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_105"
          d="M 210.5 110 L 212.5 113 L 210.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_106"
          d="M 161.5 111 L 164 111.5 L 154.5 115 L 154.5 114 Q 159.7 114.2 161.5 111 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_107"
          d="M 67.5 112 L 72 113.5 L 69.5 114 L 67.5 112 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_108"
          d="M 28.5 113 L 27.5 115 L 20 121.5 L 18.5 126 L 18 123.5 L 26.5 114 L 28.5 113 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_109"
          d="M 52.5 113 L 54.5 113 L 59 117.5 L 54.5 114 Q 51.5 114.5 52.5 113 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_110"
          d="M 214.5 113 L 216.5 116 L 214.5 113 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_111"
          d="M 48.5 114 L 51 114.5 L 48.5 115 L 48.5 114 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_112"
          d="M 173.5 114 L 170.5 118 L 173.5 114 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_113"
          d="M 175.5 114 L 181 115.5 L 178.5 116 L 175.5 115 L 175.5 114 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_114"
          d="M 77.5 115 L 80 115.5 L 77.5 116 L 77.5 115 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_115"
          d="M 149.5 115 L 153 115.5 L 149.5 116 L 149.5 115 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_116"
          d="M 200.5 115 L 205.5 121 L 200.5 115 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_117"
          d="M 81.5 116 L 85 116.5 L 81.5 117 L 81.5 116 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_118"
          d="M 144.5 116 L 148 116.5 L 144.5 117 L 144.5 116 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_119"
          d="M 182.5 116 L 186 117.5 L 184.5 118 L 182.5 116 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_120"
          d="M 39.5 117 L 41 117.5 L 32.5 122 L 33.5 120 L 39.5 117 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_121"
          d="M 88.5 117 L 93 117.5 L 88.5 118 L 88.5 117 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_122"
          d="M 136.5 117 L 141 117.5 L 136.5 118 L 136.5 117 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_123"
          d="M 217.5 117 L 218.5 119 L 217.5 117 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_124"
          d="M 60.5 118 L 63 119.5 L 61.5 120 L 60.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_125"
          d="M 97.5 118 L 107 118.5 L 97.5 119 L 97.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_126"
          d="M 122.5 118 L 132 118.5 L 122.5 119 L 122.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_127"
          d="M 168.5 118 L 167.5 120 L 166 119.5 L 168.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_128"
          d="M 187.5 118 Q 194.9 120.1 199 125.5 L 200 128.5 L 196.5 133 L 198 130.5 L 199 127.5 L 195.5 123 L 187.5 119 L 187.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_129"
          d="M 0.5 119 L 1 121.5 L 0 121.5 L 0.5 119 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_130"
          d="M 228.5 119 L 229 121.5 L 228 121.5 L 228.5 119 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_131"
          d="M 64.5 120 L 72 122.5 L 69.5 123 L 64.5 121 L 64.5 120 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_132"
          d="M 163.5 120 L 165 120.5 L 161.5 122 L 161.5 121 L 163.5 120 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_133"
          d="M 8.5 122 L 9 126.5 L 8 126.5 L 8.5 122 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_134"
          d="M 30.5 122 L 27.5 126 L 30.5 122 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_135"
          d="M 157.5 122 L 160 122.5 L 157.5 123 L 157.5 122 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_136"
          d="M 220.5 122 L 221 126.5 L 220 126.5 L 220.5 122 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_137"
          d="M 73.5 123 L 76 123.5 L 73.5 124 L 73.5 123 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_138"
          d="M 153.5 123 L 156 123.5 L 153.5 124 L 153.5 123 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_139"
          d="M 78.5 124 L 81 124.5 L 78.5 125 L 78.5 124 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_140"
          d="M 148.5 124 L 151 124.5 L 148.5 125 L 148.5 124 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_141"
          d="M 207.5 124 L 208 126.5 L 207 126.5 L 207.5 124 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_142"
          d="M 83.5 125 L 88 125.5 L 83.5 126 L 83.5 125 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_143"
          d="M 141.5 125 L 146 125.5 L 141.5 126 L 141.5 125 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_144"
          d="M 91.5 126 L 98 126.5 L 91.5 127 L 91.5 126 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_145"
          d="M 131.5 126 L 138 126.5 L 131.5 127 L 131.5 126 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_146"
          d="M 0.5 127 L 1 129.5 L 0 129.5 L 0.5 127 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_147"
          d="M 106.5 127 L 123 127.5 L 106.5 128 L 106.5 127 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_148"
          d="M 9.5 128 L 16 136.5 L 11 132.5 L 9.5 128 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_149"
          d="M 219.5 128 L 220 129.5 L 218 130.5 L 219.5 128 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_150"
          d="M 207.5 129 L 208 131.5 L 207 131.5 L 207.5 129 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_151"
          d="M 18.5 130 Q 20 137.5 26 140.5 L 19 134.5 L 18.5 130 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_152"
          d="M 27.5 130 L 33 134.5 L 31.5 134 L 27.5 130 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_153"
          d="M 1.5 131 L 4 135.5 L 2 134.5 L 1.5 131 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_154"
          d="M 217.5 132 L 212.5 138 L 209.5 140 L 211.5 137 L 217.5 132 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_155"
          d="M 194.5 133 L 193.5 135 L 194.5 133 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_156"
          d="M 206.5 133 L 206 134.5 L 200.5 141 L 198.5 142 L 199.5 140 L 205 135.5 L 206.5 133 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_157"
          d="M 226.5 134 L 226 135.5 L 217.5 145 L 225 136.5 L 226.5 134 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_158"
          d="M 4.5 137 L 15 146.5 L 12.5 145 L 4.5 137 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_159"
          d="M 17.5 137 L 22 140.5 L 20.5 140 L 17.5 137 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_160"
          d="M 186.5 137 L 188 137.5 L 184.5 139 L 184.5 138 L 186.5 137 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_161"
          d="M 207.5 140 L 206.5 142 L 197.5 146 L 197.5 145 L 207.5 140 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_162"
          d="M 23.5 141 L 27.5 143 L 27.5 141 L 36 145.5 L 35.5 147 L 38.5 146 L 38.5 147 L 35.5 148 L 33 146.5 L 33.5 145 L 31.5 146 L 31.5 144 L 28.5 145 Q 23.9 143.8 23.5 141 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_163"
          d="M 49.5 141 L 52 141.5 L 49.5 142 L 49.5 141 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_164"
          d="M 174.5 141 L 177 141.5 L 174.5 142 L 174.5 141 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_165"
          d="M 53.5 142 L 56 142.5 L 53.5 143 L 53.5 142 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_166"
          d="M 170.5 142 L 173 142.5 L 170.5 143 L 170.5 142 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_167"
          d="M 196.5 142 L 195.5 144 L 192.5 145 L 192.5 144 L 196.5 142 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_168"
          d="M 57.5 143 L 60 143.5 L 57.5 144 L 57.5 143 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_169"
          d="M 166.5 143 L 169 143.5 L 166.5 144 L 166.5 143 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_170"
          d="M 62.5 144 L 65 144.5 L 62.5 145 L 62.5 144 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_171"
          d="M 160.5 144 L 164 144.5 L 160.5 145 L 160.5 144 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_172"
          d="M 68.5 145 L 72 145.5 L 68.5 146 L 68.5 145 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_173"
          d="M 154.5 145 L 158 145.5 L 154.5 146 L 154.5 145 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_174"
          d="M 215.5 145 L 214.5 147 L 215.5 145 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_175"
          d="M 75.5 146 L 79 146.5 L 75.5 147 L 75.5 146 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_176"
          d="M 146.5 146 L 151 146.5 L 146.5 147 L 146.5 146 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_177"
          d="M 194.5 146 L 196 146.5 L 192.5 148 L 192.5 147 L 194.5 146 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_178"
          d="M 16.5 147 L 22 150.5 L 20.5 150 Q 16.5 149.5 16.5 147 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_179"
          d="M 40.5 147 L 42 147.5 L 38.5 149 L 38.5 148 L 40.5 147 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_180"
          d="M 84.5 147 L 90 147.5 L 84.5 148 L 84.5 147 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_181"
          d="M 136.5 147 L 142 147.5 L 136.5 148 L 136.5 147 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_182"
          d="M 212.5 147 L 211.5 149 L 209.5 150 L 210.5 148 L 212.5 147 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_183"
          d="M 43.5 148 L 45 148.5 L 41.5 150 L 41.5 149 L 43.5 148 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_184"
          d="M 98.5 148 L 128 148.5 L 98.5 149 L 98.5 148 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_185"
          d="M 46.5 149 L 48 149.5 L 44.5 151 L 44.5 150 L 46.5 149 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_186"
          d="M 177.5 149 L 180 149.5 L 177.5 150 L 177.5 149 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_187"
          d="M 49.5 150 L 52 150.5 L 48.5 152 L 49.5 150 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_188"
          d="M 173.5 150 L 176 150.5 L 173.5 151 L 173.5 150 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_189"
          d="M 182.5 150 L 185 150.5 L 182.5 151 L 182.5 150 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_190"
          d="M 54.5 151 L 57 151.5 L 52.5 153 L 52.5 152 L 54.5 151 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_191"
          d="M 169.5 151 L 172 151.5 L 169.5 152 L 169.5 151 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_192"
          d="M 25.5 152 L 29 153.5 L 27.5 154 L 25.5 152 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_193"
          d="M 58.5 152 L 62 152.5 L 56.5 154 L 56.5 153 L 58.5 152 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_194"
          d="M 164.5 152 L 167 152.5 L 164.5 153 L 164.5 152 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_195"
          d="M 202.5 152 L 204 152.5 L 200.5 154 L 200.5 153 L 202.5 152 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_196"
          d="M 64.5 153 L 67 153.5 L 60.5 155 L 60.5 154 L 64.5 153 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_197"
          d="M 158.5 153 L 162 153.5 L 158.5 154 L 158.5 153 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_198"
          d="M 170.5 153 L 173 153.5 L 170.5 154 L 170.5 153 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_199"
          d="M 30.5 154 L 34 155.5 L 32.5 156 L 30.5 154 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_200"
          d="M 70.5 154 L 75 154.5 L 70.5 155 L 70.5 154 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_201"
          d="M 151.5 154 L 156 154.5 L 151.5 155 L 151.5 154 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_202"
          d="M 165.5 154 L 169 154.5 L 165.5 155 L 165.5 154 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_203"
          d="M 66.5 155 L 69 155.5 L 66.5 156 L 66.5 155 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_204"
          d="M 78.5 155 L 84 155.5 L 78.5 156 L 78.5 155 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_205"
          d="M 142.5 155 L 148 155.5 L 142.5 156 L 142.5 155 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_206"
          d="M 160.5 155 L 163 155.5 L 160.5 156 L 160.5 155 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_207"
          d="M 72.5 156 L 76 156.5 L 72.5 157 L 72.5 156 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_208"
          d="M 89.5 156 L 99 156.5 L 89.5 157 L 89.5 156 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_209"
          d="M 127.5 156 L 137 156.5 L 127.5 157 L 127.5 156 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_210"
          d="M 153.5 156 L 157 156.5 L 153.5 157 L 153.5 156 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_211"
          d="M 79.5 157 L 85 157.5 L 79.5 158 L 79.5 157 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_212"
          d="M 145.5 157 L 150 157.5 L 145.5 158 L 145.5 157 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_213"
          d="M 41.5 158 L 44 158.5 L 41.5 159 L 41.5 158 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_214"
          d="M 89.5 158 L 97 158.5 L 89.5 159 L 89.5 158 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_215"
          d="M 133.5 158 L 140 158.5 L 133.5 159 L 133.5 158 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_216"
          d="M 110.5 159 L 120 159.5 L 110.5 160 L 110.5 159 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_217"
          d="M 182.5 159 L 185 159.5 L 182.5 160 L 182.5 159 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_218"
          d="M 48.5 160 L 51 160.5 L 48.5 161 L 48.5 160 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_219"
          d="M 178.5 160 L 181 160.5 L 178.5 161 L 178.5 160 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_220"
          d="M 52.5 161 L 55 161.5 L 52.5 162 L 52.5 161 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_221"
          d="M 174.5 161 L 177 161.5 L 174.5 162 L 174.5 161 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_222"
          d="M 57.5 162 L 60 162.5 L 57.5 163 L 57.5 162 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_223"
          d="M 169.5 162 L 172 162.5 L 169.5 163 L 169.5 162 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_224"
          d="M 62.5 163 L 65 163.5 L 62.5 164 L 62.5 163 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_225"
          d="M 164.5 163 L 167 163.5 L 164.5 164 L 164.5 163 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_226"
          d="M 68.5 164 L 72 164.5 L 68.5 165 L 68.5 164 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_227"
          d="M 157.5 164 L 161 164.5 L 157.5 165 L 157.5 164 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_228"
          d="M 75.5 165 L 79 165.5 L 75.5 166 L 75.5 165 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_229"
          d="M 150.5 165 L 155 165.5 L 150.5 166 L 150.5 165 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_230"
          d="M 83.5 166 L 90 166.5 L 83.5 167 L 83.5 166 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_231"
          d="M 140.5 166 L 146 166.5 L 140.5 167 L 140.5 166 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_232"
          d="M 96.5 167 L 111 167.5 L 96.5 168 L 96.5 167 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_233"
          d="M 119.5 167 L 133 167.5 L 119.5 168 L 119.5 167 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_234"
          d="M 100.5 1 L 129.5 1 L 130.5 2 L 146.5 3 L 163.5 7 Q 182.9 12.1 194 25.5 L 197 31.5 L 197 89.5 L 196 93.5 L 211.5 101 L 224 111.5 Q 229.3 116.7 229 127.5 Q 225.6 139.1 216.5 145 L 197.5 155 L 173.5 162 L 150.5 165 L 149.5 166 L 140.5 166 L 139.5 167 L 111.5 168 L 110.5 167 L 79.5 166 L 78.5 165 L 55.5 162 L 40.5 158 L 24.5 152 L 15.5 147 L 4 136.5 Q 0 131.5 0 122.5 Q 2.6 111.6 10.5 106 Q 20.4 97.7 34 94 L 34 33.5 Q 36.5 25 42.5 20 Q 54.6 10.6 71.5 6 L 100.5 1 Z M 101 9 L 73 14 Q 60 17 51 24 L 42 34 L 42 90 L 70 84 L 85 83 L 86 82 L 100 82 L 101 81 L 124 81 L 125 82 L 149 83 L 150 84 L 157 84 L 174 87 L 187 91 L 189 90 L 189 34 L 187 30 Q 181 22 171 18 L 152 12 L 147 12 L 140 10 L 132 10 L 131 9 L 101 9 Z M 94 90 L 93 91 L 83 91 L 82 92 L 63 94 L 46 98 Q 28 103 15 113 L 8 123 Q 7 130 11 133 L 25 143 L 45 151 L 80 158 L 110 159 L 111 160 L 140 159 L 141 158 L 150 158 L 151 157 L 169 155 L 194 148 L 213 138 Q 218 134 221 127 L 221 123 L 217 116 Q 209 108 198 103 L 176 96 L 154 92 L 137 91 L 136 90 L 94 90 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_235"
          d="M 94.5 99 L 130.5 99 L 131.5 100 L 143.5 100 L 144.5 101 L 152.5 101 L 169.5 104 L 189.5 110 Q 200.1 113.4 206 121.5 L 208 128.5 Q 206.8 136.8 200.5 140 L 189.5 146 L 163.5 153 L 142.5 155 L 141.5 156 L 127.5 156 L 126.5 157 L 99.5 157 L 98.5 156 L 84.5 156 L 83.5 155 L 67.5 154 L 48.5 150 L 26.5 141 L 20 134.5 L 18 126.5 Q 20.1 118.6 26.5 115 L 33.5 111 L 51.5 105 L 73.5 101 L 93.5 100 L 94.5 99 Z M 95 107 L 94 108 L 82 108 L 81 109 L 67 110 L 65 112 L 70 114 L 82 117 L 97 118 L 98 119 L 132 119 L 133 118 L 141 118 L 157 115 L 164 112 L 153 109 L 133 108 L 132 107 L 95 107 Z M 53 113 Q 40 116 31 122 L 26 128 L 27 131 L 31 134 L 50 142 L 76 147 L 98 148 L 99 149 L 128 149 L 129 148 L 142 148 L 143 147 L 158 146 L 186 139 L 197 133 L 200 129 L 199 126 L 193 120 L 178 114 L 174 114 L 170 118 L 161 122 L 141 126 L 132 126 L 131 127 L 99 127 L 98 126 L 82 125 L 64 120 L 55 113 L 53 113 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DownLightSecond')

export default icon
