import * as type from '@app/constants/sphereSort'
import * as actions from '@app/actions/sphereSort'
import { InferValueTypes } from '@app/@types/typesHelper'

type ISphereSort = ReturnType<InferValueTypes<typeof actions>>

interface ISphereSortState {
  model: string,
  field: string,
  order: Array<number>,
}

const initialState = {
  model: '',
  field: '',
  order: [],
}

export default function sphereSort(state: ISphereSortState = initialState, action: ISphereSort) {
  switch (action.type) {
  case type.SAVE_SPHERE_SORT_MODEL: {
    return {
      ...state,
      model: action.model,
    }
  }

  case type.SAVE_SPHERE_SORT_FIELD: {
    return {
      ...state,
      field: action.field,
    }
  }

  case type.SAVE_SPHERE_SORT_ORDER: {
    return {
      ...state,
      order: action.order,
    }
  }
  default:
    return state
  }
}
