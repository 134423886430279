import { MEZZO_ERRORS } from '@app/constants/routes'

const aboutData = [{
  name: 'hardware_errors',
  link: MEZZO_ERRORS,
  mezzoDependent: true,
},
{
  name: 'privacy_policy',
  href: 'https://support.mezzobyness.com/privacy-policy/',
  mezzoDependent: false,
},
{
  name: 'credits',
  href: 'https://support.mezzobyness.com',
  mezzoDependent: false,
}]

export default aboutData
