import PropTypes from 'prop-types'
import user from './user'
import unit from './unit'
import units from './units'
import users from './users'
import request from './request'
import usersRequests from './usersRequests'
import energies from './energies'
import energyDevice from './energyDevice'
import scene from './scene'
import sceneDevice from './sceneDevice'
import roomStats from './roomStats'
import condition from './condition'
import action from './action'
import myStatus from './myStatus'
import myStatusPopup from './myStatusPopup'
import myDevices from './myDevices'
import geofence from './geofence'
import reminder from './reminder'
import appConfig from './appConfig'

PropTypes.user = user
PropTypes.unit = unit
PropTypes.units = units
PropTypes.users = users
PropTypes.request = request
PropTypes.usersRequests = usersRequests
PropTypes.energies = energies
PropTypes.energyDevice = energyDevice
PropTypes.scene = scene
PropTypes.sceneDevice = sceneDevice
PropTypes.roomStats = roomStats
PropTypes.condition = condition
PropTypes.action = action
PropTypes.myStatus = myStatus
PropTypes.myStatusPopup = myStatusPopup
PropTypes.myDevices = myDevices
PropTypes.geofence = geofence
PropTypes.reminder = reminder
PropTypes.appConfig = appConfig

export default PropTypes
