import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  title: {
    textAlign: 'center',
  },
  buttonWrapper: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
  },
}))

export default useStyles
