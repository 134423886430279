import React from 'react'
import PropTypes from 'prop-types'

const OPEN_CHANNEL_DELAY = 1000

const withNotifications = (WrappedComponent) => {
  class Notificationable extends React.Component {
    componentDidMount() {
      const {
        isMobile, path, notifications,
      } = this.props
      if (!isMobile && !path) {
        setTimeout(() => {
          notifications()
        }, OPEN_CHANNEL_DELAY)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  Notificationable.propTypes = {
    notifications: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    accessDenied: PropTypes.bool.isRequired,
    path: PropTypes.string,
  }

  Notificationable.defaultProps = {
    path: null,
  }

  return Notificationable
}

export default withNotifications
