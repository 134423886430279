export const mainEventsSettings = [
  'arming_events',
  'pills_reminders',
  'system_outputs',
  'scene',
  'pir_event',
]

export const mainEventsSettingsNCentral = [
  'arming_events',
  'system_outputs',
  'scene',
  'pir_event',
]

export const zoneEventsSettings = [
  'zone_sealed',
  'zone_alarms',
  'zone_tamper',
  'other_zone',
]

export const systemEventsSettings = [
  'power_failure',
  'battery_failure',
  'report_failure',
  'other_system',
]
