const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    minHeight: 400,
  },
  listItem: {
    width: 132,
    height: 132,
  },
  currentSwitch: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 36,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height: 100,
    width: 100,
    cursor: 'pointer',
    padding: '15px 5px 0px 5px',
  },
  switchTitle: {
    width: '90%',
    fontSize: '12px',
    marginBottom: 11,
    textAlign: 'center',
    padding: '0 16px',
    textWrap: 'wrap',
    overflow: 'hidden',
  },
})

export default styles
