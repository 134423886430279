import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.74997 10C3.74997 6.98833 5.90869 4.51984 8.74999 3.93581V5.00002L12.5 2.50003L8.74999 0V1.35652C4.51734 1.9662 1.24994 5.60174 1.24994 10C1.24994 12.6484 2.43748 15.021 4.30269 16.627L6.49156 15.1676C4.83866 14.0417 3.74997 12.1462 3.74997 10V10Z" fill="currentColor"/>
      <path d="M15.6972 3.37311L13.5084 4.83247C15.1613 5.95833 16.25 7.85388 16.25 10C16.25 13.0155 14.0956 15.5173 11.2499 16.1007V15L7.49988 17.5L11.2499 20V18.6485C15.483 18.0387 18.75 14.3987 18.75 10C18.75 7.3517 17.5625 4.97911 15.6972 3.37311V3.37311Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SyncButtonIcon')

export default icon
