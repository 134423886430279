import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  circlesContainer: {
    position: 'relative',
    width: 200,
    height: 200,
    overflow: 'hidden',
    margin: 20,
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    width: 0,
    height: 0,
    opacity: 1,
    animation: '$circleAnimation 2.5s linear forwards',
  },

  '@keyframes circleAnimation': {
    '0%': {
      width: 0,
      height: 0,
      opacity: 1,
    },
    '100%': {
      width: 200,
      height: 200,
      opacity: 0,
    },
  },
}))

export default useStyles
