import * as type from '@app/constants/wizard/wizard'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as SignUpAction from '@app/actions/wizard/signUp'

type SignUpActions = ReturnType<InferValueTypes<typeof SignUpAction>>

export interface ISignUpState {
  isUserExists: boolean,
  isActivationInfoPopupOpened: boolean,
  isActivationWarningPopupOpened: boolean,
  isActivationErrorPopupOpened: boolean,
}

const initialState = {
  isUserExists: false,
  isActivationInfoPopupOpened: false,
  isActivationWarningPopupOpened: false,
  isActivationErrorPopupOpened: false,
}

export default function signUp(state: ISignUpState = initialState, action: SignUpActions): ISignUpState {
  switch (action.type) {
  case type.SHOW_USER_EXISTS_ERROR: {
    return {
      ...state,
      isUserExists: true,
    }
  }

  case type.HIDE_USER_EXISTS_ERROR: {
    return {
      ...state,
      isUserExists: false,
    }
  }

  case type.SHOW_ACTIVATION_INFO_POPUP: {
    return {
      ...state,
      isActivationInfoPopupOpened: true,
    }
  }

  case type.CLOSE_ACTIVATION_INFO_POPUP: {
    return {
      ...state,
      isActivationInfoPopupOpened: false,
    }
  }

  case type.SHOW_ACTIVATION_WARNING_POPUP: {
    return {
      ...state,
      isActivationWarningPopupOpened: true,
    }
  }

  case type.CLOSE_ACTIVATION_WARNING_POPUP: {
    return {
      ...state,
      isActivationWarningPopupOpened: false,
    }
  }

  case type.SHOW_ACTIVATION_ERROR_POPUP: {
    return {
      ...state,
      isActivationErrorPopupOpened: true,
    }
  }

  case type.CLOSE_ACTIVATION_ERROR_POPUP: {
    return {
      ...state,
      isActivationErrorPopupOpened: false,
    }
  }

  default:
    return state
  }
}
