export const SET_CURRENT_REQUEST = '@@currentRequest/set'

export const SET_REJECT_REQUEST = '@@rejectRequest/set'

export const SET_APPROVE_REQUEST = '@@approveRequest/set'

export const RESET_CURRENT_REQUEST = '@@currentRequest/reset'

export const OPEN_REQUEST_INFO_POPUP = '@@currentRequest/openRequestInfoPopup'
export const CLOSE_REQUEST_INFO_POPUP = '@@currentRequest/closenRequestInfoPopup'
