import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  LinearProgress,
  Typography,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import Wrapper from '../../Common/Wrapper/Wrapper'
import styles from './ButtonsOrProgressComponent.style'

class ButtonsOrProgressComponent extends React.Component {
  saveSetTimeSettings=() => {
    const { syncPatchCloudFirmwareInfo } = this.props
    syncPatchCloudFirmwareInfo()
  }

  startContinueDownloading=(type) => {
    const {
      saveContinueDownloadingType,
      syncContinueDownloading,
    } = this.props
    saveContinueDownloadingType(type)
    syncContinueDownloading()
  }

  handleRadioChange = (event, saveValue) => {
    const { syncPatchCloudFirmwareInfo } = this.props
    saveValue(Number(event.target.value))
    syncPatchCloudFirmwareInfo()
  };

  renderRadioButtons=(value, title, saveValue, errorMessage) => {
    const { classes, fwInSlots } = this.props
    const fwInA = fwInSlots?.A ? `(${fwInSlots.A})` : '(none)'
    const fwInB = fwInSlots?.B ? `(${fwInSlots.B})` : '(none)'
    const slotA = title === 'Installation from slot' ? `A ${fwInA}` : 'A'
    const slotB = title === 'Installation from slot' ? `B ${fwInB}` : 'B'
    return (
      <>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend" className={classes.installationErrorLabel}>
            <Typography>{title}</Typography>
            {errorMessage && (
              <Typography className={classes.installationError}>
                :
                {' '}
                {errorMessage}
              </Typography>
            )}
          </FormLabel>
          <RadioGroup
            aria-label={title}
            name={title}
            value={value}
            onChange={(event) => {
              this.handleRadioChange(event, saveValue)
            }}
            className={classes.radioButtons}
          >
            <FormControlLabel value={1} control={<Radio color="primary" />} label={slotA} />
            <FormControlLabel value={2} control={<Radio color="primary" />} label={slotB} />
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  render() {
    const {
      syncManualUpdate,
      updateStatus,
      fwToInstall,
      openResetPopup,
      downloadingType,
      uploadProcess,
      syncStopUploading,
      uploadStatusValue,
      saveSlotForDownload,
      saveSlotForInstall,
      isMobile,
      classes,
      slotForDownload,
      slotForInstall,
      syncInstall,
      installationMessage,
    } = this.props
    if (installationMessage.error) {
      return (
        <Wrapper>
          {this.renderRadioButtons(slotForDownload, 'Slot for download', saveSlotForDownload, null)}
          <Button
            color="secondary"
            className={classNames(classes.resetButton, isMobile && classes.resetButtonMobile)}
            onClick={openResetPopup}
            disabled={false}
          >
            {I18n.t('buttons.reset_process')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.downloadButton}
            onClick={syncManualUpdate}
            disabled={false}
          >
            {I18n.t('cloud_firmware_update.download_firmware')}
          </Button>
          {this.renderRadioButtons(slotForInstall, 'Installation from slot', saveSlotForInstall, installationMessage.message)}
          <Button
            variant="outlined"
            color="primary"
            className={classes.downloadButton}
            onClick={syncInstall}
            disabled={!fwToInstall}
          >
            {I18n.t('cloud_firmware_update.install')}
            {' '}
            {fwToInstall}
            {' '}
            {I18n.t('cloud_firmware_update.firmware')}
          </Button>
        </Wrapper>
      )
    } if (installationMessage.message) {
      return (<Typography className={classes.installationProcessTitle}>{installationMessage.message}</Typography>)
    }
    switch (updateStatus) {
    case 'done':
    case 'upload_finish':
      if (downloadingType === 'manual') {
        return (
          <>
            {this.renderRadioButtons(slotForDownload, 'Slot for download', saveSlotForDownload, null)}
            <Button
              color="secondary"
              className={classNames(classes.resetButton, isMobile && classes.resetButtonMobile)}
              onClick={openResetPopup}
              disabled={false}
            >
              {I18n.t('buttons.reset_process')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.downloadButton}
              onClick={syncManualUpdate}
              disabled={false}
            >
              {I18n.t('cloud_firmware_update.download_firmware')}
            </Button>
            {this.renderRadioButtons(slotForInstall, 'Installation from slot', saveSlotForInstall, null)}
            <Button
              variant="outlined"
              color="primary"
              className={classes.downloadButton}
              onClick={syncInstall}
              disabled={!fwToInstall}
            >
              {I18n.t('cloud_firmware_update.install')}
              {' '}
              {fwToInstall}
              {' '}
              {I18n.t('cloud_firmware_update.firmware')}
            </Button>
          </>
        )
      }
      return (
        <Button
          variant="outlined"
          color="primary"
          className={classes.downloadButton}
          onClick={this.saveSetTimeSettings}
          disabled={false}
        >
          {I18n.t('buttons.submit')}
        </Button>
      )
    case 'upload_started':
      if (uploadProcess === 'paused') {
        return (
          <>
            <Typography className={classes.pausedTitle}>Upload paused</Typography>
            <Wrapper className={classes.pausedButtonsWrapper}>
              <Button
                color="secondary"
                className={classes.buttonInPaused}
                onClick={() => {
                  this.startContinueDownloading('reset')
                }}
                disabled={false}
              >
                {I18n.t('buttons.reset_process')}
              </Button>
              <Button
                color="primary"
                className={classes.buttonInPaused}
                onClick={() => {
                  this.startContinueDownloading('continue')
                }}
                disabled={false}
              >
                {I18n.t('cloud_firmware_update.continue')}
              </Button>
            </Wrapper>
          </>
        )
      }
      return (
        <>
          <Wrapper className={classes.progressHeaderWrapper}>
            <Typography>{I18n.t('cloud_firmware_update.firmware_downloading')}</Typography>
            <Button
              color="primary"
              className={classes.resetButton}
              onClick={() => {
                syncStopUploading()
              }}
              disabled={false}
            >
              {I18n.t('cloud_firmware_update.stop_uploading')}
            </Button>
          </Wrapper>
          <Wrapper className={classes.progressWrapper}>
            <LinearProgress variant="determinate" value={uploadStatusValue} className={classes.progress} />
            <Typography>
              {uploadStatusValue}
              %
            </Typography>
          </Wrapper>
        </>
      )
    case 'resetting':
      return (
        <>
          <Typography>
            {I18n.t('cloud_firmware_update.resetting')}
            ...
          </Typography>
          <LinearProgress className={classes.progress} />
        </>
      )
    default:
      throw new Error('unknown update_status')
    }
  }
}

ButtonsOrProgressComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  downloadingType: PropTypes.string.isRequired,
  syncManualUpdate: PropTypes.func.isRequired,
  updateStatus: PropTypes.string.isRequired,
  fwToInstall: PropTypes.string.isRequired,
  openResetPopup: PropTypes.func.isRequired,
  saveContinueDownloadingType: PropTypes.func.isRequired,
  syncContinueDownloading: PropTypes.func.isRequired,
  uploadProcess: PropTypes.string.isRequired,
  syncStopUploading: PropTypes.func.isRequired,
  syncPatchCloudFirmwareInfo: PropTypes.func.isRequired,
  uploadStatusValue: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveSlotForDownload: PropTypes.func.isRequired,
  saveSlotForInstall: PropTypes.func.isRequired,
  slotForDownload: PropTypes.number.isRequired,
  slotForInstall: PropTypes.number.isRequired,
  syncInstall: PropTypes.func.isRequired,
  installationMessage: PropTypes.string || PropTypes.shape({
    message: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
  }),
  fwInSlots: PropTypes.shape({
    A: PropTypes.string,
    B: PropTypes.string,
  }),
}

ButtonsOrProgressComponent.defaultProps = {
  installationMessage: '',
  fwInSlots: {
    A: null,
    B: null,
  },
}

export default withStyles(styles)(ButtonsOrProgressComponent)
