import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/billing_periods`

const updateBillingSettings = (accessToken, data) => Axios.put(
  ENDPOINT,
  data,
  {
    headers: {
      'Access-Token': accessToken,
    },
  },
)

export default {
  updateBillingSettings,
}
