const styles = (theme) => ({
  dialog: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  avatar: {
    height: 175,
    width: 175,
    borderRadius: '50%',
    margin: '20px auto 0',
    position: 'relative',
  },
  changeAvatar: {
    cursor: 'pointer',
    width: 65,
    height: 65,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#71C386',
    position: 'absolute',
    borderRadius: '50%',
    bottom: -15,
    right: -15,

    '&:hover': {
      background: '#4d915f',
    },
  },
  addIcon: {
    fontSize: 40,
  },
  title: {
    width: 300,
    textAlign: 'center',
    paddingBottom: 0,
  },
  titleMobile: {
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    height: 250,
    paddingTop: 0,
    fontSize: 20,
  },
  infoField: {
    textDecoration: 'underline',
  },
  controls: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
  },
  controlButton: {
    flex: 1,
    borderRadius: 7,
    border: `1px solid ${theme.scenes.textColor}`,
  },
})

export default styles
