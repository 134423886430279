export const WEB = '/web'
export const HOME = '/web/home'
export const SYNCHRONIZE_SCREEN = '/web/synchronize_screen'

export const USERS = '/web/users'
export const USER_EDIT = '/web/users/:user_id/edit'
export const USER_REQUEST_DETAILS = '/web/users/:request_id/request_details'
export const MEMBER_DETAILS = '/web/users/:member_id/member_details'

export const ABOUT = '/web/about'
export const ARMOR_IP = '/web/alarm_reporting_settings'
export const ARMOR_IP_OLD = '/web/armor_ip_settings'
export const TAKE_ON_BOARD_SETTINGS = '/web/take_on_board_settings'
export const NUMBER_OF_ZONES = '/web/number_of_zones'

export const PILLS_REMINDERS = '/web/pills_reminders'
export const PILLS_REMINDER_EDIT = '/web/pills_reminders/:id/edit'
export const PILLS_REMINDER_NEW = '/web/pills_reminders/new'
export const PILLS_REMINDERS_EVENTS = '/web/pills_reminders/events'

export const NOTIFICATIONS = '/web/notifications'
export const NOTIFICATIONS_SETTINGS = '/web/notifications/settings'
export const SECURITY_RULES = '/web/security_rules'
export const SECRETS = '/web/secrets'
export const MEZZO_TIME = '/web/mezzo_time'
export const MEZZO_LOCATION = '/web/mezzo_location'
export const TWR_DEVICES_HEALTH = '/web/twr_devices_health'
export const MEZZO_ERRORS = '/web/mezzo_errors'
export const PROGRAM_OPTIONS = '/web/program_options'
export const PROGRAM_OPTIONS_FROM_CONFIG = '/web/config_program_options'
export const EVENTS = '/web/events'

export const SHADOW_AUTH = '/web/shadow_auth'
export const SHADOW_AUTH_TOKEN = '/web/shadow_auth_via_token'

export const CAMERAS = '/web/cameras'
export const CLOUD = '/web/cloud'
export const ZWAVE = '/web/zwave'
export const ROOMS = '/web/rooms'
export const GROUPS = '/web/groups'
export const GEOFENCES = '/web/geofences'
export const CREATE_GEOFENCE = '/web/geofences/create'
export const MY_ENERGY = '/web/my_energy'
export const SCENES = '/web/scenes'

export const CREATE_CONDITION_SELECTED = '/web/scenes/create_condition_selected'
export const EDIT_CONDITION = '/web/scenes/edit_condition'
export const SELECT_CONDITION_TYPE = '/web/scenes/select_condition_type'
export const SELECT_CONDITION_DEVICE_TYPE = '/web/scenes/select_condition_device_type'

export const SELECT_ZWAVE_DEVICE_TYPE = '/web/scenes/select_zwave_device_type'
export const SELECT_SECURITY_OPTION_VALUE = '/web/scenes/select_security_option_value'

export const SELECT_CONDITION_DEVICE_STATE_TYPE = '/web/scenes/select_condition_device_state_type'
export const SELECT_CONDITION_DEVICE_TABS = '/web/scenes/select_condition_device_tabs'

export const SELECT_ROOM_CONDITION = '/web/scenes/select_room_condition'
export const SELECT_ROOM_CONDITION_STATE = '/web/scenes/select_room_condition_state'

export const SELECT_GROUP_CONDITION = '/web/scenes/select_group_condition'
export const SELECT_GROUP_CONDITION_STATE = '/web/scenes/select_group_condition_state'

export const SELECT_CONDITION_STATE_TWR = '/web/scenes/select_condition_state_twr'
export const SELECT_CONDITION_STATE_ZWAVE = '/web/scenes/select_condition_state_zwave'

export const SELECT_ANOTHER_SCENE_CONDITION = '/web/scenes/select_another_scene_condition'

export const SELECT_GEOFENCES_CONDITION = '/web/scenes/select_geofences_condition'
export const SELECT_GEOFENCES_LOCATION = '/web/scenes/select_geofences_location'
export const SELECT_GEOFENCES_NAME = '/web/scenes/select_geofences_name'
export const SELECT_GEOFENCES_USER = '/web/scenes/select_geofences_user'
export const SELECT_GEOFENCES_STATE = '/web/scenes/select_geofences_state'

export const SELECT_MEZZO_CONDITION_STATE = '/web/scenes/select_mezzo_condition_state'

export const SELECT_MEZZO_LIGHT_STATE = '/web/scenes/select_mezzo_light_state'
export const SELECT_CONDITION_SECURITY_MANAGEMENT_DEVICE = '/web/scenes/select_condition_security_management_device'
export const SELECT_CONDITION_TIMER = '/web/scenes/select_condition_timer'
export const SELECT_CONDITION_TIMER_VALUE = '/web/scenes/select_condition_timer_value'
export const TIMERS_IN_SCENES = '/web/scenes/timers'
export const TIMERS_IN_SCENES_RESET = '/web/scenes/timers/reset'
export const TIMERS_IN_SCENES_SETTINGS = '/web/scenes/timers/:timerNumber/settings'

export const SELECT_TRIGGERING_DEVICE = '/web/scenes/select_triggering_device'
export const SELECT_TRIGGERING_DEVICE_TYPE = '/web/scenes/select_triggering_device_type'
export const SELECT_TRIGGERING_ZWAVE_DEVICE = '/web/scenes/select_triggering_zwave_device'
export const SELECT_TRIGGERING_ROOM = '/web/scenes/select_triggering_room'
export const SELECT_TRIGGERING_GROUP = '/web/scenes/select_triggering_group'
export const SELECT_TRIGGERING_STATE = '/web/scenes/select_triggering_state'

export const SELECT_OBSERVING_DEVICE_TYPE = '/web/scenes/select_observing_device_type'
export const SELECT_OBSERVING_TWR_DEVICE = '/web/scenes/select_observing_twr_device'
export const SELECT_OBSERVING_ZWAVE_DEVICE = '/web/scenes/select_observing_zwave_device'
export const SELECT_OBSERVING_ROOM = '/web/scenes/select_observing_room'
export const SELECT_OBSERVING_GROUP = '/web/scenes/select_observing_group'

export const SELECT_TIME_CONDITION_TYPE = '/web/scenes/select_time_condition_type'
export const SELECT_TIME_CONDITION_STATE_WHEN = '/web/scenes/select_time_condition_state_when'
export const SELECT_TIME_CONDITION_STATE_TIME = '/web/scenes/select_time_condition_state_time'
export const SELECT_TIME_CONDITION_STATE_DAY = '/web/scenes/select_time_condition_state_day'
export const SELECT_TIME_CONDITION_STATE_DATE = '/web/scenes/select_time_condition_state_date'
export const OFFSET_SETTINGS = '/web/scenes/offset_settings'

export const SELECT_COLOR_CONDITION_STATE = '/web/scenes/select_color_condition_state'
export const SELECT_BINARY_SENSOR_STATE_SELECTED = '/web/scenes/select_binary_sensor_state_selected'
export const SELECT_BINARY_SENSOR_TYPE = '/web/scenes/select_binary_sensor_type'
export const SELECT_BINARY_SENSOR_VALUE = '/web/scenes/select_binary_sensor_value'
export const SELECT_MULTILEVEL_SENSOR_STATE_SELECTED = '/web/scenes/select_multilevel_sensor_state_selected'
export const SELECT_MULTILEVEL_SENSOR_TYPE = '/web/scenes/select_multilevel_sensor_type'
export const SELECT_MULTILEVEL_SENSOR_VALUE = '/web/scenes/select_multilevel_sensor_value'
export const LUMINANCE_SENSOR_VALUE_EXAMPLE = '/web/scenes/luminance_sensor_value_example'
export const ULTRAVIOLET_SENSOR_VALUE_EXAMPLE = '/web/scenes/ultraviolet_sensor_value_example'
export const SELECT_CENTRAL_SCENE_STATE_SELECTED = '/web/scenes/select_central_scene_binary_switch_state_selected'
export const SELECT_CENTRAL_SCENE_SUB_DEVICE = '/web/scenes/select_central_scene_sub_device'
export const SELECT_CENTRAL_SCENE_SUB_DEVICE_VALUE = '/web/scenes/select_central_scene_sub_device_value'
export const SELECT_MULTICHANNEL_BINARY_SWITCH_STATE_SELECTED = '/web/scenes/select_multichannel_binary_switch_state_selected'
export const SELECT_MULTICHANNEL_SUB_DEVICE = '/web/scenes/select_multichannel_sub_device'
export const SELECT_MULTICHANNEL_SUB_DEVICE_VALUE = '/web/scenes/select_multichannel_sub_device_value'
export const SELECT_DOOR_LOCK_STATE = '/web/scenes/create_condition/select_door_lock_state'
export const SELECT_VIRTUAL_SWITCH_CONDITION = '/web/scenes/create_condition/select_virtual_switch_condition'
export const SELECT_VIRTUAL_SWITCH_CONDITION_STATE = '/web/scenes/create_condition/select_virtual_switch_condition_state'
export const SELECT_NOTIFICATION_SENSOR_TYPE = '/web/scenes/create_condition/select_notification_sensor_type'
export const SELECT_EVENT_TYPE = '/web/scenes/create_condition/select_notification_sensor_event_type'
export const SELECT_NOTIFICATION_SENSOR_STATE_SELECTED = '/web/scenes/create_condition/select_notification_sensor_state_selected'

export const CREATE_ACTION_SELECTED = '/web/scenes_selected'
export const SELECT_ACTION_TYPE = '/web/scenes/action/select_action_type'
export const EDIT_ACTION = '/web/scenes/edit_action'
export const SELECT_ACTION_DEVICE_TYPE = '/web/scenes/select_action_device_type'
export const SELECT_MEZZO_CHIMES_STATE = '/web/scenes/select_mezzo_chimes_state'
export const SELECT_ACTION_DEVICE_TABS = '/web/scenes/select_action_device_tabs'
export const SELECT_ACTION_DEVICE_STATE_TYPE = '/web/scenes/select_action_device_state_type'
export const SELECT_MEZZO_ACTION_STATE = '/web/scenes/select_mezzo_action_state'

export const SELECT_COLOR_ACTION_HUE = '/web/scenes/select_color_action_hue'
export const SELECT_COLOR_ACTION_STATE_SELECTED = '/web/scenes/select_color_action_state_selected'
export const SELECT_ACTION_COLOR_TYPE = '/web/scenes/select_action_color_type'

export const SELECT_BINARY_SWITCH_ACTION_STATE_SELECTED = '/web/scenes/select_binary_switch_action_state_selected'
export const SELECT_BINARY_SWITCH_ACTION_STATE_VALUE = '/web/scenes/select_binary_switch_action_state_value'

export const SELECT_MEZZO_LIGHTS_ACTION_STATE_SELECTED = '/web/scenes/mezzo_lights_action_state_selected'
export const SELECT_MEZZO_LIGHTS_ACTION_STATE_VALUE = '/web/scenes/select_mezzo_lights_action_state_value'
export const SELECT_MEZZO_ACTION_STATE_TYPE = '/web/scenes/select_mezzo_action_state_type'
export const ADJUST_DURATION_MEZZO_LIGHTS = '/web/scenes/adjust_duration_mezzo_lights'
export const SELECT_BRIGHTNESS_VALUE = '/web/scenes/select_brightness_value'
export const SELECT_BRIGHTNESS_ACTION_STATE_SELECTED = '/web/scenes/select_brightness_action_state_selected'
export const ADJUST_DURATION_BRIGHTNESS = '/web/scenes/adjust_duration_brightness'
export const SELECT_BRIGHTNESS_VALUE_AFTER_DURATION = '/web/scenes/select_brightness_value_after_duration'

export const SETTINGS_TYPE = '/web/scenes/settings_type'
export const SELECT_NOTIFICATION_USER = '/web/scenes/select_notification_user'

export const SELECT_MULTILEVEL_SWITCH_ACTION_TYPE = '/web/scenes/select_multilevel_switch_action_type'
export const SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED = '/web/scenes/select_multilevel_switch_action_state_selected'
export const SELECT_MULTILEVEL_SWITCH_ACTION_STATE_VALUE = '/web/scenes/select_multilevel_switch_action_state_value'
export const SELECT_DOORLOCK_ACTION_STATE_VALUE = '/web/scenes/select_doorlock_action_state_value'
export const SELECT_DOORLOCK_ACTION_STATE_SELECTED = '/web/scenes/select_doorlock_action_state_selected'
export const ADJUST_DELAY_MULTILEVEL_SWITCH = '/web/scenes/adjust_delay_multilevel_switch'
export const ADJUST_DURATION_MULTILEVEL_SWITCH = '/web/scenes/adjust_duration_multilevel_switch'
export const SELECT_MULTILEVEL_SWITCH_ACTION_VALUE_AFTER_DURATION = '/web/scenes/select_multilevel_switch_action_value_after_duration'
export const ADJUST_DELAY_DOORLOCK = '/web/scenes/adjust_delay_doorlock'
export const ADJUST_DURATION_DOORLOCK = '/web/scenes/adjust_duration_doorlock'
export const SELECT_DOORLOCK_ACTION_VALUE_AFTER_DURATION = '/web/scenes/select_doorlock_action_value_after_duration'

export const SCENE_REVIEW = '/web/scenes/scene_review'
export const SCENE_REVIEW_EDIT = '/web/scenes/scene_review_edit'
export const SELECT_MULTILEVEL_SWITCH_STATE = '/web/scenes/select_multilevel_switch_state'

export const ADJUST_DELAY_BINARY_SWITCH = '/web/adjust_delay_binary_switch'
export const ADJUST_DELAY_COLOR = '/web/adjust_delay_color'
export const ADJUST_DURATION_BINARY_SWITCH = '/web/adjust_duration_binary_switch'
export const ADJUST_DURATION_COLOR = '/web/adjust_duration_color'
export const SELECT_BINARY_SWITCH_ACTION_VALUE_AFTER_DURATION = '/web/select_binary_switch_value_after_duration'
export const SELECT_ACTION_COLOR_TYPE_AFTER_DURATION = '/web/select_action_color_type_after_duration'
export const SELECT_COLOR_ACTION_HUE_AFTER_DURATION = '/web/select_color_action_hue_after_duration'

export const SELECT_ROOM_ACTION = '/web/select_room_action'
export const SELECT_ROOM_ACTION_STATE = '/web/select_room_action_state'
export const SELECT_ACTION_ROOM_STATE_TYPE = '/web/select_action_room_state_type'
export const SELECT_ROOM_ACTION_STATE_SELECTED = '/web/select_room_action_state_selected'
export const SELECT_ROOM_COLOR_ACTION_HUE = '/web/scenes/select_room_color_action_hue'
export const SELECT_ROOM_COLOR_ACTION_STATE_SELECTED = '/web/scenes/select_room_color_action_state_selected'
export const SELECT_ROOM_ACTION_COLOR_TYPE = '/web/scenes/select_action_room_color_type'
export const ADJUST_DELAY_COLOR_ROOM = '/web/adjust_delay_color_room'
export const ADJUST_DURATION_COLOR_ROOM = '/web/adjust_duration_color_room'
export const SELECT_ACTION_ROOM_COLOR_TYPE_AFTER_DURATION = '/web/select_action_room_color_type_after_duration'
export const SELECT_COLOR_ROOM_ACTION_HUE_AFTER_DURATION = '/web/select_color_room_action_hue_after_duration'

export const SELECT_GROUP_ACTION = '/web/select_group_action'
export const SELECT_GROUP_ACTION_STATE = '/web/select_group_action_state'
export const SELECT_ACTION_GROUP_STATE_TYPE = '/web/select_action_group_state_type'
export const SELECT_GROUP_ACTION_STATE_SELECTED = '/web/select_group_action_state_selected'
export const SELECT_GROUP_COLOR_ACTION_HUE = '/web/scenes/select_group_color_action_hue'
export const SELECT_GROUP_COLOR_ACTION_STATE_SELECTED = '/web/scenes/select_group_color_action_state_selected'
export const SELECT_GROUP_ACTION_COLOR_TYPE = '/web/scenes/select_action_group_color_type'
export const ADJUST_DELAY_COLOR_GROUP = '/web/adjust_delay_color_group'
export const ADJUST_DURATION_COLOR_GROUP = '/web/adjust_duration_color_group'
export const SELECT_ACTION_GROUP_COLOR_TYPE_AFTER_DURATION = '/web/select_action_group_color_type_after_duration'
export const SELECT_COLOR_GROUP_ACTION_HUE_AFTER_DURATION = '/web/select_color_group_action_hue_after_duration'

export const SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE = '/web/scenes/select_action_security_management_device'
export const SELECT_ACTION_TIMER = '/web/scenes/select_action_timer'
export const SELECT_ACTION_TIMER_VALUE = '/web/scenes/select_action_timer_value'

export const SELECT_VIRTUAL_SWITCH_ACTION = '/web/scenes/create_action/select_virtual_switch_action'
export const SELECT_VIRTUAL_SWITCH_ACTION_VALUE = '/web/scenes/create_action/select_virtual_switch_action_value'
export const SELECT_VIRTUAL_SWITCH_ACTION_STATE = '/web/scenes/create_action/select_virtual_switch_action_state'
export const ADJUST_DELAY_VIRTUAL_SWITCH = '/web/scenes/adjust_delay_virtual_switch'
export const ADJUST_DURATION_VIRTUAL_SWITCH = '/web/scenes/adjust_duration_virtual_switch'
export const SELECT_VIRTUAL_SWITCH_VALUE_AFTER_DURATION = '/web/scenes/select_virtual_switch_value_after_duration'

export const SELECT_MULTICHANNEL_ACTION_SUBDEVICE = '/web/scenes/select_multichannel_action_sub_device'
export const SELECT_MULTICHANNEL_ACTION_SUBDEVICE_VALUE = '/web/scenes/select_multichannel_action_subdevice_value'
export const SELECT_MULTICHANNEL_ACTION_STATE_SELECTED = '/web/scenes/select_multichannel_action_state_selected'
export const ADJUST_DURATION_MULTICHANNEL_ACTION = '/web/scenes/adjust_duration_multichannel_action'
export const MULTICHANNEL_ACTION_VALUE_AFTER_DURATION = '/web/select_multichannel_action_value_after_duration'

export const EMAIL = '/web/forgot_password/email'
export const CODE = '/web/forgot_password/code'
export const NEW_PASSWORD = '/web/forgot_password/new_password'
export const SIGN_IN = '/web/login'
export const SIGN_UP = '/web/sign_up'
export const SELECT_MEZZO_DEVICE_CONNECTION = '/web/select_mezzo_device_connection'

export const MY_STATUS = '/web/my_status'

export const MY_DEVICES = '/web/my_devices'
export const ADD_TWR_DEVICE = '/web/my_devices/add_twr_device'
export const ADD_ZWAVE_DEVICE = '/web/my_devices/add_zwave_device'
export const DEVICE_INFO = '/web/my_devices/info/:type/:deviceId'
export const DEVICE_ROOMS = '/web/my_devices/info/:type/:deviceId/rooms'
export const DEVICE_GROUPS = '/web/my_devices/info/:type/:deviceId/groups'
export const DEVICE_COLOURS = '/web/my_devices/info/:type/:deviceId/colours'
export const DEVICE_SETTINGS = '/web/my_devices/info/:type/:deviceId/settings'
export const DEVICE_ICONS = '/web/my_devices/info/:type/:deviceId/settings/edit'
export const TWR_ADDITIONAL_MODES = '/web/my_devices/info/:type/:deviceId/settings/additional_modes'
export const TWR_SIREN_OPTIONS = '/web/my_devices/info/:type/:deviceId/settings/siren_options'
export const TAKE_ON_BOARD_DEVICE_TYPES = '/web/my_devices/info/:type/:deviceId/settings/take_on_board_device_types'
export const CONTROL_OPTIONS = '/web/my_devices/info/:type/:deviceId/settings/control_options'
export const TIMERS = '/web/my_devices/my_mezzo_settings/timers'
export const TIMERS_RESET = '/web/my_devices/my_mezzo_settings/timers/reset'
export const TIMER_SETTINGS = '/web/my_devices/my_mezzo_settings/timers/:timerNumber/settings'
export const MEZZO_LIGHTS = '/web/my_devices/mezzo_lights'

export const FAILED_NODE_REPLACE_DEVICE = '/web/my_devices/info/zwave_device/:deviceId/settings/replace_device'
export const ZWAVE_SECURITY_OPTIONS = '/web/my_devices/info/zwave_device/:deviceId/settings/zwave_security_options'
export const ZWAVE_DETAILS = '/web/my_devices/info/zwave_device/:deviceId/settings/zwave_details'

export const VIRTUAL_SWITCHES = '/web/my_devices/my_mezzo_settings/virtual_switches'

export const GROUPS_ADD_DEVICE = '/web/groups/add-device'
export const GROUPS_EDIT = '/web/groups/edit'

export const ROOMS_ADD_DEVICE = '/web/rooms/add-device'
export const ROOMS_EDIT = '/web/rooms/edit'
export const ROOMS_SHOW = '/web/rooms/show'
export const GROUPS_SHOW = '/web/groups/show'

export const CONTACTS = '/web/contacts'

export const HEALTH = '/web/health'
export const ACTIVITY = '/web/activity'

export const CLOUD_FIRMWARE_UPDATE = '/web/cloud_firmware_update'

export const MY_SECURITY = '/web/my_security'

export const DELETE_ACCOUNT = '/web/delete_account'
