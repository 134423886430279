import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { securityManagementDevicesConditions, securityManagementDevicesConditionsNCentral } from '@app/lib/Scenes/securityManagementDevicesConditions'
import {
  SELECT_CONDITION_DEVICE_TYPE,
  SELECT_CONDITION_TIMER,
  SELECT_MEZZO_CONDITION_STATE,
  SELECT_MEZZO_LIGHT_STATE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectConditionSecurityManagementDevice extends React.Component {
  onBackClick = () => {
    const { navigate } = this.props
    navigate(SELECT_CONDITION_DEVICE_TYPE)
  }

  disableAdditionalConditions = (type) => {
    const { sceneReviewConditionTypes } = this.props
    switch (type.title) {
    case 'timers':
      return (sceneReviewConditionTypes.includes('UserTrigger'))
    default:
      return false
    }
  }

  onUseClick=(type) => {
    const {
      navigate,
    } = this.props
    switch (type.type) {
    case 'mezzo':
      return navigate(SELECT_MEZZO_CONDITION_STATE)
    case 'mezzoLight':
      return navigate(SELECT_MEZZO_LIGHT_STATE)
    case 'timers':
      return navigate(SELECT_CONDITION_TIMER)
    default:
      throw new Error('condition unsupported')
    }
  }

  render() {
    const {
      classes,
      isMobile,
      isNCentral,
    } = this.props
    const conditionsList = isNCentral ? securityManagementDevicesConditionsNCentral : securityManagementDevicesConditions
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_security_management_device')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            conditionsList.map((type) => (
              <CardBlock
                key={type.title}
                title={I18n.t(`scene_web.${type.title}`)}
                description={I18n.t(`scene_web.${type.description}`)}
                position={type.position}
                buttonName={I18n.t(`scene_web.${type.buttonName}`)}
                isCardDisabled={
                  this.disableAdditionalConditions(type) || type.isCardDisabled
                }
                isButtonDisabled={
                  this.disableAdditionalConditions(type) || type.isButtonDisabled
                }
                onClick={() => {
                  this.onUseClick(type)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectConditionSecurityManagementDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  isNCentral: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sceneReviewConditionTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
}

SelectConditionSecurityManagementDevice.defaultProps = {

}

SelectConditionSecurityManagementDevice.displayName = 'SelectConditionSecurityManagementDevice'

export default SelectConditionSecurityManagementDevice
