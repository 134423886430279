import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  ListItem,
  Typography,
  Tooltip,
} from '@mui/material'
import {
  Delete, Edit, ErrorOutline, SortIcon, PlayButton,
} from 'icons'
import { useDispatch } from '@app/store'
// @ts-ignore
import descriptionTransformer from '@app/helpers/scenes/descriptionTransformer'
import getSceneStatus from '@app/helpers/scenes/getSceneStatus'
import { SCENE_REVIEW_EDIT } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import DotsLoading from '@app/components/Common/DotsLoading/DotsLoading'
import {
  putChangeset, selectSceneInitialId,
} from '@app/actions/scenes/editScene'
import {
  activateScene,
  openDeleteScenePopup,
  openSceneUsedInScenePopup,
  saveCurrentSceneName,
  saveCurrentSceneStatus,
  selectCurrentSceneId,
  triggerSceneActions,
} from '@app/actions/scenes/scenesList'
import { changeSceneMode, saveScreenName } from '@app/actions/scenes/currentSceneCreating'
import { IScene } from '@app/@types/scenes/scenes'
import { statusesForHiding } from '@app/lib/Scenes/scenesListRestrictions'
import Toggle from './Toggle/Toggle'
import useStyles from './Scene.style'

const DefaultProps = {
  isSorting: false,
}

interface Props {
  sceneIndex: number,
  scene: IScene,
  admin: boolean,
  isSorting: boolean,
}
const Scene: React.FC<Props> = (props) => {
  const {
    sceneIndex,
    scene,
    admin,
    isSorting = DefaultProps.isSorting,
  } = props
  const {
    id,
    name,
    status,
    verified,
    enabled,
    with_unsupported,
    is_used_another_scene,
    is_user_triggerable,
    isEnablingProgress,
  } = scene
  const dispatch = useDispatch()
  const classes = useStyles()

  const activateCurrentScene = () => {
    dispatch(selectCurrentSceneId(id))
    dispatch(activateScene())
  }

  const deleteCurrentScene = () => {
    if (is_used_another_scene) {
      dispatch(openSceneUsedInScenePopup())
    } else {
      dispatch(openDeleteScenePopup())
      dispatch(selectCurrentSceneId(id))
    }
  }

  const triggerCurrentSceneActions = () => {
    dispatch(triggerSceneActions(id))
  }

  const editCurrentScene = () => {
    dispatch(saveCurrentSceneName(name))
    dispatch(saveCurrentSceneStatus(status))
    dispatch(selectSceneInitialId(id))
    dispatch(selectCurrentSceneId(id))
    if (status === 'incomplete') {
      if (!verified) {
        dispatch(changeSceneMode('editMezzoScene'))
      } else {
        dispatch(changeSceneMode('editDraftScene'))
      }
    } else {
      dispatch(changeSceneMode('editMezzoScene'))
    }
    dispatch(saveScreenName(SCENE_REVIEW_EDIT))
    dispatch(putChangeset())
  }

  const getSceneName = () => {
    const statusResult = getSceneStatus(verified, status)
    return statusResult ? `${name} (${I18n.t(`scene_web.${statusResult}`)})` : name
  }

  const getButtons = () => (
    <Wrapper className={classes.editButtons}>
      {!statusesForHiding.includes(status) && (
        <>
          <Tooltip
            title="Trigger Action"
            placement="left"
          >
            <IconButton
              aria-label="Actions"
              onClick={() => {
                triggerCurrentSceneActions()
              }}
              size="large"
            >
              <PlayButton />
            </IconButton>
          </Tooltip>
        </>
      )}
      {!with_unsupported && admin && (
        <IconButton
          aria-label="Edit"
          onClick={() => {
            editCurrentScene()
          }}
          size="large"
        >
          <Edit />
        </IconButton>
      )}
      { admin && (
        <IconButton
          aria-label="Delete"
          onClick={() => {
            deleteCurrentScene()
          }}
          size="large"
        >
          <Delete />
        </IconButton>
      )}
    </Wrapper>
  )

  const getRightElement = () => {
    if (isSorting) {
      return (
        <SortIcon className={classes.sortedIcon} />
      )
    }
    if (!verified) {
      return (
        <ErrorOutline color="error" />
      )
    }
    if (status !== 'complete') {
      return (
        <Wrapper customStyle={{ color: 'orange' }}>
          <ErrorOutline color="inherit" />
        </Wrapper>
      )
    }
    if (is_user_triggerable) {
      return (
        <Button
          variant="outlined"
          className={classes.activateButton}
          color="primary"
          onClick={(event) => {
            event.stopPropagation()
            activateCurrentScene()
          }}
        >
          {I18n.t('scene_web.activate')}
        </Button>
      )
    }
    return (isEnablingProgress ? (
      <DotsLoading
        props={{
          width: 35,
        }}
        tooltip="scene_web.sync"
      />
    ) : (
      <Toggle
        id={id}
        sceneIndex={sceneIndex}
        enabled={enabled}
      />
    )
    )
  }

  return (
    <ListItem>
      <Accordion className={classes.sceneItem}>
        <AccordionSummary
          aria-controls="content"
          classes={{
            content: classes.content,
          }}
        >
          <Typography className={classes.sceneTitle}>{getSceneName()}</Typography>
          {getRightElement()}
        </AccordionSummary>
        <AccordionDetails className={classes.sceneInfo}>
          <Typography className={classes.sceneInfoText}>
            {descriptionTransformer(scene.description)}
          </Typography>
          {!isSorting && getButtons()}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default Scene
