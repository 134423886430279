import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from '@app/components/PropTypes'
import styles from './RoomsList.style'
import Room from './Room'

class RoomsList extends React.Component {
  render() {
    const {
      classes,
      saveConditionDescription,
      saveConditionRoomNumber,
      navigate,
      roomsScenes,
    } = this.props
    return (
      <List className={roomsScenes.length ? classes.root : classes.rootEmpty}>
        {roomsScenes.length
          ? roomsScenes.map((room) => (
            <ListItem className={classes.listItem} key={`room-${room.id}`}>
              <Room
                room={room}
                saveConditionDescription={saveConditionDescription}
                saveConditionRoomNumber={saveConditionRoomNumber}
                navigate={navigate}
              />
            </ListItem>
          )) : <Typography className={classes.empty} align="center">NO ROOMS</Typography>}
      </List>
    )
  }
}

RoomsList.propTypes = {
  classes: PropTypes.object.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionRoomNumber: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  roomsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

export default withStyles(styles)(RoomsList)
