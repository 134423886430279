import * as type from '@app/constants/myDevices/channels/devices/devicesChannels'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

export const syncDevicesStatusChannel = () => ({
  type: type.SYNC_DEVICES_STATUS_CHANNEL,
} as const)

export const syncDevicesStatusChannelSent = () => ({
  type: type.SYNC_DEVICES_STATUS_CHANNEL_SENT,
} as const)

export const openDevicesStatusChannel = () => ({
  type: type.OPEN_DEVICES_STATUS_CHANNEL,
} as const)

export const closeDevicesStatusChannel = () => ({
  type: type.CLOSE_DEVICES_STATUS_CHANNEL,
} as const)

export const updateDevicesStatus = (data: IZwaveOrTwrDevice) => ({
  type: type.UPDATE_DEVICES_STATUS,
  data,
} as const)
