import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  FormControl, FormHelperText, MenuItem, Select, Typography, TextField,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { ExpandMore } from 'icons'
import convertTimeToField from '@app/helpers/cloudFirmwareUpdate/convertTimeToField'
import convertTimeToBackend from '@app/helpers/cloudFirmwareUpdate/convertTimeToBackend'
import { downloadFirmwareTypes } from '@app/lib/CloudFirmwareUpdate/mainScreenLibs'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CustomInput from '@app/components/Common/CustomInput/CustomInput'
import styles from './FirmwareDownloadSection.style'

class FirmwareDownloadSection extends React.Component {
  handleFirmwareVersionChange=(event) => {
    const {
      saveSelectedFirmwareVersion,
    } = this.props
    saveSelectedFirmwareVersion(event.target.value)
  }

  handleDownloadTypeChange=(event) => {
    const {
      saveDownloadingType,
    } = this.props
    saveDownloadingType(event.target.value)
  }

  handleCurrentTimeChange=(selectedDate) => {
    const {
      saveDownloadingTime,
    } = this.props
    saveDownloadingTime(convertTimeToBackend(selectedDate))
  }

  renderSetTimeSection =() => {
    const {
      downloadingTime,
      classes,
      isMobile,
    } = this.props
    return (
      <>
        <Typography className={classes.timeTitle}>{I18n.t('cloud_firmware_update.set_time')}</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            margin="normal"
            id="time-picker"
            value={convertTimeToField(downloadingTime)}
            onChange={(selectedDate) => this.handleCurrentTimeChange(selectedDate)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                variant="standard"
                className={classNames(classes.timeInput, isMobile && classes.timeInputMobile)}
              />
            )}
          />
        </LocalizationProvider>
        <FormHelperText className={classes.timeHelperText}>{I18n.t('cloud_firmware_update.set_time_helper_text')}</FormHelperText>
      </>
    )
  }

  render() {
    const {
      classes,
      downloadingType,
      firmwareList,
      selectedFirmwareVersion,
      isMobile,
      isButtonsDisabled,
    } = this.props
    return (
      <>
        <Typography
          align="left"
          className={classNames(classes.downloadTitle, isMobile && classes.downloadTitleMobile, isButtonsDisabled && classes.disabledText)}
        >
          {I18n.t('cloud_firmware_update.firmware_download_selection')}
        </Typography>
        <Wrapper className={classNames(classes.downloadWrapper, isMobile && classes.downloadWrapperMobile)}>
          <FormControl
            style={{
              padding: '15px 3% 15px 1%',
              marginRight: '20px',
              marginBottom: '15px',
            }}
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <Select
              variant="standard"
              id="type-select"
              value={downloadingType}
              onChange={this.handleDownloadTypeChange}
              input={<CustomInput />}
              IconComponent={ExpandMore}
              disabled={isButtonsDisabled}
              classes={{
                icon: classNames(downloadingType === 'nightly' && classes.iconForLongText),
                root: classes.downloadingTypeSelect,
              }}
            >
              {downloadFirmwareTypes.map((type) => (
                <MenuItem className={classes.typeMenuItem} value={type.value} key={type.value}>
                  <Typography className={classNames(classes.mainText, isButtonsDisabled && classes.disabledText)}>
                    {I18n.t(`cloud_firmware_update.${type.mainText}`)}
                  </Typography>
                  {!isMobile && (
                    <>
                      <Typography className={classNames(classes.infoTextFirst, isButtonsDisabled && classes.disabledText)}>
                        {I18n.t(`cloud_firmware_update.${type.infoText}`)}
                      </Typography>
                      {(type.value === 'nightly' || type.value === 'disabled') && (
                        <Typography className={classNames(classes.infoText, isButtonsDisabled && classes.disabledText)}>
                          {I18n.t(`cloud_firmware_update.${type.secondInfoText}`)}
                        </Typography>
                      )}
                    </>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              padding: '0 3% 0 3%',
              marginBottom: '15px',
            }}
            classes={{
              root: classes.formControl,
            }}
            variant="outlined"
          >
            <Typography
              className={classNames(
                classes.versionsTitleText,
                isButtonsDisabled && classes.disabledText,
                (downloadingType === 'nightly' || downloadingType === 'disabled') && classes.disabledText,
              )}
            >
              {I18n.t('cloud_firmware_update.firmware_version')}
            </Typography>
            <Select
              variant="standard"
              id="type-select"
              value={selectedFirmwareVersion}
              onChange={this.handleFirmwareVersionChange}
              input={<CustomInput />}
              IconComponent={ExpandMore}
              disabled={isButtonsDisabled || downloadingType === 'nightly' || downloadingType === 'disabled'}
              classes={{
                icon: classes.iconVersions,
              }}
            >
              {firmwareList.map((item) => (
                <MenuItem key={item} className={classes.typeMenuItem} value={item}>
                  <Typography className={classNames(
                    classes.versionInfoText,
                    !isButtonsDisabled && classes.versionInfoTextEnadled,
                    (downloadingType === 'nightly' || downloadingType === 'disabled') && classes.disabledText,
                  )}
                  >
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Wrapper>
        {downloadingType === 'set_time' && this.renderSetTimeSection()}
      </>
    )
  }
}

FirmwareDownloadSection.propTypes = {
  classes: PropTypes.object.isRequired,
  downloadingType: PropTypes.string.isRequired,
  selectedFirmwareVersion: PropTypes.string.isRequired,
  firmwareList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  saveSelectedFirmwareVersion: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isButtonsDisabled: PropTypes.bool.isRequired,
  saveDownloadingType: PropTypes.func.isRequired,
  downloadingTime: PropTypes.string.isRequired,
  saveDownloadingTime: PropTypes.func.isRequired,
}

export default withStyles(styles)(FirmwareDownloadSection)
