import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.9094 127.199V127.449H57.1594H92.9398H93.1898V127.199V103.367V103.117H92.9398H57.1594H56.9094V103.367V127.199ZM60.041 106.249H90.0582V124.318H60.041V106.249Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M51.7326 134.767H52.0826H98.0174H100.649H131.1H131.45V134.417V105.017V86.3191V85.9691H131.1H108.258V78.734C108.258 75.5665 111.058 72.9425 114.559 72.9425C118.059 72.9425 120.859 75.5665 120.859 78.734C120.859 79.654 121.605 80.3998 122.525 80.3998C123.445 80.3998 124.191 79.654 124.191 78.734C124.191 73.6851 119.851 69.6109 114.559 69.6109C109.266 69.6109 104.927 73.6851 104.927 78.734V85.9691H100.649H98.0174H94.789C94.5891 84.8911 93.6495 84.0719 92.5107 84.0719H79.7118C78.573 84.0719 77.6334 84.8911 77.4336 85.9691H72.6658C72.4659 84.8911 71.5264 84.0719 70.3875 84.0719H57.5893C56.4505 84.0719 55.5109 84.8911 55.3111 85.9691H52.0826H49.451H19H18.65V86.3191V134.416V134.766H19H49.451L51.7326 134.767ZM100.999 131.435V105.367H128.118V131.435H100.999ZM21.9816 131.435V89.3007H49.101V96.1504V98.782V131.435H21.9816ZM97.6674 131.435H52.4326V99.132H97.6674V105.017V131.435ZM100.999 89.3007H128.118V102.036H100.999V98.782V96.1504V89.3007ZM52.4326 89.3007H97.6674V95.8004H52.4326V89.3007Z" fill="currentColor" stroke="currentColor" strokeWidth="0.7"/>
      <path d="M52.4326 15V14.65H52.0826H19H18.65V15V37.1296V37.4796H19H52.0826H52.4326V37.1296V15ZM49.101 17.9816V34.148H21.9816V17.9816H49.101Z" fill="currentColor" stroke="currentColor" strokeWidth="0.7"/>
      <path d="M97.6676 37.1296V37.4796H98.0176H131.1H131.45V37.1296V15V14.65H131.1H98.0176H97.6676V15V37.1296ZM100.999 17.9816H128.119V34.148H100.999V17.9816Z" fill="currentColor" stroke="currentColor" strokeWidth="0.7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M70 18H80V31H70V18ZM66 14H70H80H84V18V31V31.8045C84.3325 31.9669 84.6355 32.1911 84.8914 32.4693L97.1396 45.7883L99 47.8113V55C99 56.6569 97.6569 58 96 58H54C52.3431 58 51 56.6569 51 55V49.2216V47.2215L52.8862 45.2445L65.1136 32.4291C65.3699 32.1604 65.6711 31.9445 66 31.7887V31V18V14ZM55 54V49.2708L95 49.7623V54H55ZM58.3508 45.3116L91.6433 45.7208L82.2442 35.5H67.7121L58.3508 45.3116Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Kitchen')

export default icon
