import React from 'react'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import { checkIconNumber } from '@app/helpers/myDevices/checkIconNumber'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import { StatusNotOkIcon, StatusOkIcon } from 'icons'
import styles from './MyStatusDeviceCard.style'

class TwrCard extends React.PureComponent {
  renderIcon = (iconId) => {
    const { classes } = this.props
    return React.cloneElement(devicesIcons[checkIconNumber(iconId)], { className: classes.icon })
  }

  renderCardIcon = (status, classes) => {
    const { isMobile } = this.props
    return status
      ? <StatusOkIcon className={classNames(classes.statusIcon, isMobile && classes.statusIconMobile)} />
      : <StatusNotOkIcon className={classNames(classes.statusIcon, isMobile && classes.statusIconMobile)} />
  }

  render() {
    const { classes, deviceStats, isMobile } = this.props
    return (
      <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile)}>
        <Wrapper className={classes.cardHeader}>
          {this.renderCardIcon(deviceStats.status, classes)}
          <Wrapper className={classes.iconContainer}>
            { this.renderIcon(deviceStats.icon)}
          </Wrapper>
          <Typography className={classes.title} variant="h5" component="h2" gutterBottom>
            {deviceStats.name}
          </Typography>
        </Wrapper>
        <Wrapper className={classes.content}>
          <Wrapper className={classes.contentItem}>
            <Typography color="textSecondary" component="span" className={classNames(classes.info, classes.subtitleColor)}>
              {' '}
              {I18n.t('my_status.last_update')}
              :
              {' '}
            </Typography>
            <Typography component="span" className={classes.info}>{deviceStats.happened_at}</Typography>
          </Wrapper>
          <Wrapper className={classes.contentItem}>
            <Typography component="span" className={classNames(classes.info, classes.subtitleColor)}>
              {I18n.t('my_status.room')}
              :
              {' '}
            </Typography>
            <Typography
              component="span"
              className={classes.info}
            >
              {deviceStats.room ? deviceStats.room : I18n.t('my_energy.not_available')}
            </Typography>
          </Wrapper>
          <Wrapper className={classes.contentItem}>
            <Typography
              component="span"
              className={classNames(classes.info, classes.subtitleColor)}
            >
              {I18n.t('my_status.group')}
              :
              {' '}
            </Typography>
            <Typography
              component="span"
              className={classes.info}
            >
              {deviceStats.group ? deviceStats.group : I18n.t('my_energy.not_available')}
            </Typography>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    )
  }
}

TwrCard.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  deviceStats: PropTypes.myStatus.isRequired,
}

TwrCard.displayName = 'MyStatusDeviceCard'

export default withStyles(styles)(TwrCard)
