import React from 'react'
import {
  AnotherSceneCondition,
  AnotherSceneConditionMobile,
  DevicesCondition,
  DevicesConditionMobile,
  Geofence,
  GeofenceMobile,
  Time,
  TimeMobile,
  UserTrigger,
  UserTriggerMobile,
  VirtualSwitch,
  VirtualSwitchMobile,
} from 'icons'

export const conditionTypesIcons = {
  0: <UserTrigger />,
  1: <DevicesCondition />,
  2: <Geofence />,
  3: <AnotherSceneCondition />,
  4: <Time />,
  5: <VirtualSwitch />,
}

export const conditionTypesIconsMobile = {
  0: <UserTriggerMobile />,
  1: <DevicesConditionMobile />,
  2: <GeofenceMobile />,
  3: <AnotherSceneConditionMobile />,
  4: <TimeMobile />,
  5: <VirtualSwitchMobile />,
}
