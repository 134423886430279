import { createLogic } from 'redux-logic'
import { SYNC_ACTIVITIES } from '@app/constants/activities/activities'
import { set } from '@app/actions/activities/activities'
import Api from '@app/api/activities/fetchActivities'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import handleErrors from '@app/helpers/handleErrors'

const fetchActivitiesLogic = createLogic({
  type: SYNC_ACTIVITIES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_ACTIVITIES))
    Api.fetchActivities(token)
      .then((response) => {
        const { data } = response
        dispatch(set(data.activities))
        dispatch(apiRequestDone(SYNC_ACTIVITIES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchActivities failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchActivitiesLogic
