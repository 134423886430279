import * as type from '@app/constants/po/poConfig'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/po/poConfig'
import { IPOConfigFromBackend, IPOFromBackend } from '@app/@types/po/poConfig'
import merge from '@app/helpers/merge'

type IPoConfig = ReturnType<InferValueTypes<typeof actions>>

export interface IPoConfigState {
  initialOptions: Array<IPOFromBackend>,
  currentOptions: Array<IPOFromBackend>,
  defaultOptions: Array<IPOFromBackend>,
  items: Array<IPOConfigFromBackend>,
  inProgress: boolean,
  errorMessage: string,
  validation: boolean,
}

const initialState = {
  initialOptions: [],
  currentOptions: [],
  defaultOptions: [],
  items: [],
  inProgress: false,
  errorMessage: '',
  validation: true,
}

const poConfig = (state: IPoConfigState = initialState, action: IPoConfig) => {
  switch (action.type) {
  case type.SAVE_PO_CONFIG: {
    return {
      ...state,
      items: action.items,
      initialOptions: action.initials,
      currentOptions: action.initials,
      defaultOptions: action.initials,
    }
  }

  case type.SET_PO_FROM_CONFIG_INITIAL_OPTIONS: {
    const updatedPOs = merge(state.initialOptions, action.programOptions)
    return {
      ...state,
      initialOptions: updatedPOs,
    }
  }
  case type.SET_PO_FROM_CONFIG_CURRENT_OPTIONS: {
    return {
      ...state,
      currentOptions: action.programOptions,
    }
  }
  case type.START_SAVING_PROGRESS: {
    return {
      ...state,
      inProgress: true,
    }
  }
  case type.END_SAVING_PROGRESS: {
    return {
      ...state,
      inProgress: false,
    }
  }

  case type.API_REQUEST_FAILED:
    return {
      ...state,
      errorMessage: action.message,
      inProgress: false,
    }

  case type.SET_PO_FROM_CONFIG_VALIDATION: {
    return {
      ...state,
      validation: action.validation,
    }
  }

  case type.RESET_PO_CONFIG:
    return initialState

  default:
    return state
  }
}

export default poConfig
