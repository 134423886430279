import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveActionDescription, saveActionRoomNumber } from '@app/actions/scenes/currentSceneCreating'
import { syncRoomsScenes } from '@app/actions/scenes/roomsScenes/roomsScenes'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectRoomAction
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/RoomActions/RoomsList/SelectRoomAction'
import SelectRoomActionIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/RoomActions/RoomsList/SelectRoomAction'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectRoomActionIOS) : withStyles(SelectRoomAction)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { roomsScenes } = state.scenes.roomsScenes
  return {
    isMobile,
    roomsScenes,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncRoomsScenes,
  tryAgain: syncRoomsScenes,
  notifications: syncNotifications.sync,
  saveActionDescription,
  saveActionRoomNumber,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectRoomActionEnhanced = enhance

export default withRouter(SelectRoomActionEnhanced)
