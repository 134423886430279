import React from 'react'
import {
  Button, ClickAwayListener, Paper, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import { startZwaveReinterview } from '@app/actions/myDevices/zwaveDevices'
import useStyles from '../DeviceInfo.style'

interface Props {
  id: number,
  close: () => void,
  isMobile: boolean,
}

const FailedInterviewPopup: React.FC<Props> = (props) => {
  const { close, id, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const startReinterview = () => {
    dispatch(startZwaveReinterview(id))
    close()
  }

  return (
    <ClickAwayListener onClickAway={close}>
      <Paper className={classNames(classes.failedInterviewPopup, isMobile && classes.failedInterviewPopupMobile)}>
        <Typography className={classNames(classes.failedInterviewMessage, isMobile && classes.failedInterviewMessageMobile)}>
          {I18n.t('my_devices.did_not_pass_interview')}
        </Typography>
        <Typography className={classNames(classes.failedInterviewMessage, isMobile && classes.failedInterviewMessageMobile)}>
          {I18n.t('my_devices.press_reinterview_button')}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={startReinterview}
        >
          {I18n.t('my_devices.reinterview')}
        </Button>
      </Paper>
    </ClickAwayListener>
  )
}

export default FailedInterviewPopup
