import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import {
  updateSensorNotifications,
} from '@app/actions/scenes/zWaveDevices/sensorNotifications'
import {
  saveSensorNotificationsType,
  saveSensorNotificationsTypeDescription,
  saveSensorNotificationsEventType,
  saveSensorNotificationsEventDescription,
} from '@app/actions/scenes/currentSceneCreating'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

import Api from '@app/api/scenes/fetchSensorNotifications'
import handleErrors from '@app/helpers/handleErrors'
import {
  SYNC_SENSOR_NOTIFICATIONS,
} from '@app/constants/scenes/zWaveDevices/sensorNotifications'
import {
  SELECT_EVENT_TYPE,
  SELECT_NOTIFICATION_SENSOR_STATE_SELECTED,
  SELECT_NOTIFICATION_SENSOR_TYPE,
} from '@app/constants/routes'

const fetchSensorNotificationsLogic = createLogic({
  type: SYNC_SENSOR_NOTIFICATIONS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { currentSceneCreating: { currentCondition: { conditionDeviceId } } } } = store.getState()
    dispatch(apiRequestSent(SYNC_SENSOR_NOTIFICATIONS))
    Api.fetchSensorNotifications(token, conditionDeviceId)
      .then((response) => {
        const { data: { device_notifications } } = response
        dispatch(updateSensorNotifications(device_notifications))
        dispatch(apiRequestDone(SYNC_SENSOR_NOTIFICATIONS))
        if (device_notifications.length === 1) {
          const first_notification = device_notifications[0]
          dispatch(saveSensorNotificationsType(first_notification.notification_type))
          dispatch(saveSensorNotificationsTypeDescription(first_notification.notification_name))
          if (first_notification.event_types.length === 1) {
            const first_event = first_notification.event_types[0]
            dispatch(saveSensorNotificationsEventType(first_event.type))
            dispatch(saveSensorNotificationsEventDescription(first_event.name))
            history.push(SELECT_NOTIFICATION_SENSOR_STATE_SELECTED)
          } else {
            history.push(SELECT_EVENT_TYPE)
          }
        } else {
          history.push(SELECT_NOTIFICATION_SENSOR_TYPE)
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchSensorNotifications failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchSensorNotificationsLogic
