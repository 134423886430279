import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_CURRENT_USER } from '@app/constants/users/currentUser'
import { updateCurrentUser } from '@app/actions/users/currentUser'
import { openWarningChangeEmailPopup } from '@app/actions/users/secretCode'
import Api from '@app/api/users/patchUser'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitCurrentUserLogic = createLogic({
  type: SET_CURRENT_USER,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { form: { UserEditForm: { initial } } } = getState()
    const { form: { UserEditForm: { values } } } = getState()
    const { secrets: { accessToken: { token } } } = getState()
    const { users: { currentUser: { user: { ids: { sphere: sphereId } } } } } = getState()
    const formData = {
      phone_number: values.mobile.trim(),
      email: values.email.trim(),
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      middle_name: values.middleName ? values.middleName.trim() : '',
      preferred_name: values.preferredName.trim(),
    }
    next({
      ...action,
      values,
      token,
      initial,
      sphereId,
      formData,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_CURRENT_USER))
    Api.patchUser(action.token, action.sphereId, action.formData)
      .then(() => {
        dispatch(updateCurrentUser(action.values))
        dispatch(apiRequestDone(SET_CURRENT_USER))
        if (action.values.email !== action.initial.email) {
          dispatch(openWarningChangeEmailPopup())
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchUser failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitCurrentUserLogic
