import * as type from '@app/constants/scenes/twrDevices/twrDevices'
import { ITwrDeviceBackend } from '@app/@types/scenes/scenesDevices'

export const syncTwrDevices = () => ({
  type: type.SYNC_TWR_DEVICES,
} as const)

export const updateTwrDevices = (twrDevices: Array<ITwrDeviceBackend>) => ({
  type: type.UPDATE_TWR_DEVICES,
  twrDevices,
} as const)

export const syncTwrDeviceStates = () => ({
  type: type.SYNC_TWR_DEVICE_STATES,
} as const)

export const updateTwrDeviceStates = (twrDeviceStates: Array<string>) => ({
  type: type.UPDATE_TWR_DEVICE_STATES,
  twrDeviceStates,
} as const)

export const resetTwrDevices = () => ({
  type: type.RESET_TWR_DEVICES,
} as const)
