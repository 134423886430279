import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  title: {
    textAlign: 'left',
  },
  text: {
    maxWidth: 240,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12,
  },
  button: {
    marginLeft: 10,
  },
}))

export default useStyles
