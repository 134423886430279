import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M71.6162 44.3765H79.4368V31.9141C76.6449 33.495 74.0381 34.8405 71.6162 35.9505L71.6162 44.3765Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M69.901 36.7109C67.5791 37.7432 65.1844 38.6032 62.7363 39.2842V44.3297H69.901V36.7109Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M89.0234 26.1602V26.1602V44.3745H93.413V26.1602H89.0234Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M61.021 39.6836C59.5789 40.0014 58.108 40.1705 56.6313 40.1881V44.3759H61.021V39.6836Z" fill="currentColor" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M95.6835 74.5024C95.6835 85.9831 86.3766 95.29 74.896 95.29C63.4153 95.29 54.1084 85.9831 54.1084 74.5024C54.1084 63.0218 63.4153 53.7148 74.896 53.7148C86.3766 53.7148 95.6835 63.0218 95.6835 74.5024ZM63.81 60.8275L62.3829 62.2546L71.3022 71.1739L72.7293 69.7468L63.81 60.8275Z" fill="currentColor"/>
            <rect x="28.5" y="5.5" width="92.7416" height="138" rx="3.5" stroke="currentColor" strokeWidth="5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M87.0056 27.3242L81.1528 30.7047V44.3781H87.0056V27.3242Z" fill="currentColor" stroke="currentColor"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwDimmerIcon')

export default icon
