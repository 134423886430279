import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.5159 49.5213L49.5159 49.5213C35.7422 63.2977 35.7422 85.7096 49.5159 99.486L49.5159 99.486C56.4031 106.373 65.4518 109.816 74.497 109.816C83.5422 109.816 92.5908 106.373 99.478 99.486C113.252 85.7125 113.252 63.2984 99.4787 49.522C98.15 48.1871 95.9908 48.1893 94.6588 49.5213C93.3258 50.8542 93.3258 53.0102 94.6588 54.3432C105.775 65.459 105.775 83.5483 94.6588 94.6641C83.5456 105.777 65.4537 105.777 54.3378 94.6641C43.222 83.5482 43.222 65.459 54.3378 54.3432C55.6708 53.0102 55.6708 50.8542 54.3378 49.5213C53.0049 48.1883 50.8489 48.1883 49.5159 49.5213ZM74.5 7.25C37.4177 7.25 7.25 37.4177 7.25 74.5C7.25 111.582 37.4177 141.75 74.5 141.75C111.582 141.75 141.75 111.582 141.75 74.5C141.75 37.4177 111.582 7.25 74.5 7.25ZM74.5 134.93C41.1801 134.93 14.07 107.823 14.07 74.5C14.07 41.1801 41.1801 14.07 74.5 14.07C107.823 14.07 134.93 41.1801 134.93 74.5C134.93 107.823 107.823 134.93 74.5 134.93ZM74.5 67.27C76.3825 67.27 77.91 65.7425 77.91 63.86V37.26C77.91 35.3775 76.3825 33.85 74.5 33.85C72.6175 33.85 71.09 35.3775 71.09 37.26V63.86C71.09 65.7425 72.6175 67.27 74.5 67.27Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PowerButton')

export default icon
