import * as type from '@app/constants/notifications/notificationsChannel'
import { INotification } from '@app/@types/notifications'

export const update = (data: INotification) => ({
  type: type.UPDATE_NOTIFICATIONS_CHANNEL,
  data,
} as const)

export const sync = () => ({
  type: type.SYNC_NOTIFICATIONS_CHANNEL,
} as const)

export const syncSent = () => ({
  type: type.SYNC_SENT_NOTIFICATIONS_CHANNEL,
} as const)

export const syncCancel = () => ({
  type: type.SYNC_CANCEL_NOTIFICATIONS_CHANNEL,
} as const)

export const openChannel = () => ({
  type: type.OPEN_NOTIFICATION_CHANNEL,
} as const)

export const closeChannel = () => ({
  type: type.CLOSE_NOTIFICATION_CHANNEL,
} as const)

export const clearNotificationsChannel = () => ({
  type: type.CLEAR_NOTIFICATIONS_CHANNEL,
} as const)
