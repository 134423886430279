import * as type from '@app/constants/myDevices/channels/devices/devicesChannels'

export const syncMezzoLightsChannel = () => ({
  type: type.SYNC_MEZZO_LIGHTS_CHANNEL,
} as const)

export const syncMezzoLightsChannelSent = () => ({
  type: type.SYNC_MEZZO_LIGHTS_CHANNEL_SENT,
} as const)

export const openMezzoLightsChannel = () => ({
  type: type.OPEN_MEZZO_LIGHTS_CHANNEL,
} as const)

export const closeMezzoLightsChannel = () => ({
  type: type.CLOSE_MEZZO_LIGHTS_CHANNEL,
} as const)
