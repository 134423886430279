import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { roomActionsStateTypes } from '@app/lib/Scenes/roomActionsStateTypes'
import {
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_ROOM_ACTION,
  SELECT_ROOM_ACTION_COLOR_TYPE,
  SELECT_ROOM_ACTION_STATE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectActionRoomStateType extends React.Component {
  onBackClick= () => {
    const {
      navigate,
      isChangeActionStateMode,
      isEditActionMode,
      restoreActionInfo,
      actions,
      initialAction,
    } = this.props
    if (isChangeActionStateMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      if (isEditActionMode) {
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      navigate(SELECT_ROOM_ACTION)
    }
  }

  onChooseClick= (type) => {
    const {
      navigate,
    } = this.props
    if (type === 'device_switch_rooms') {
      navigate(SELECT_ROOM_ACTION_STATE)
    } else {
      navigate(SELECT_ROOM_ACTION_COLOR_TYPE)
    }
  }

  createStatesList = (states) => (states.map((state) => (
    <CardBlock
      key={state.title}
      title={I18n.t(`scene_web.${state.title}`)}
      description={I18n.t(`scene_web.${state.description}`)}
      position="top"
      buttonName="choose"
      isCardDisabled={state.isCardDisabled}
      isButtonDisabled={state.isButtonDisabled}
      onClick={() => {
        this.onChooseClick(state.type)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {this.createStatesList(roomActionsStateTypes)}
        </Wrapper>
      </Paper>
    )
  }
}

SelectActionRoomStateType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectActionRoomStateType.defaultProps = {

}

SelectActionRoomStateType.displayName = 'SelectConditionDeviceStateType'

export default SelectActionRoomStateType
