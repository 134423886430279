const styles = (theme) => ({
  downloadTitle: {
    marginTop: '45px',
    marginBottom: '20px',
    color: theme.firmwareUpdate.title,
  },
  downloadTitleMobile: {
    marginTop: '25px',
  },
  formControl: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  typeMenuItem: {
    backgroundColor: theme.backgrounds.cardBackground,
  },
  downloadWrapper: {
    display: 'flex',
  },
  downloadWrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
  downloadingTypeSelect: {
    marginTop: -10,
  },
  mainText: {
    marginLeft: 10,
    marginTop: 10,
  },
  infoTextFirst: {
    fontSize: '14px',
    marginLeft: 10,
    marginTop: 10,
  },
  infoText: {
    fontSize: '14px',
    marginLeft: 10,
    marginTop: 10,
  },
  versionInfoText: {
    fontSize: '14px',
    paddingTop: 5,
  },
  versionInfoTextEnadled: {
    color: theme.palette.primary.main,
  },
  versionsTitleText: {
    marginTop: 22,
  },
  disabledText: {
    color: theme.firmwareUpdate.disabledElement,
  },
  iconVersions: {
    marginTop: '-12px',
  },
  timeTitle: {
    marginTop: '20px',
    fontSize: '16px',
  },
  timeHelperText: {
    color: theme.palette.primary.main,
  },
  timeInput: {
    marginBottom: 0,
    marginTop: 5,
    width: '20%',
  },
  timeInputMobile: {
    width: '50%',
  },
  iconForLongText: {
    marginTop: '-10px',
  },
})

export default styles
