import React from 'react'
import classNames from 'classnames'
import { List, ListItem, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { matchValues } from '@app/lib/MyEnergy/intervalValues'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Arrow from '@app/components/Common/Arrow/Arrow'

import PropTypes from '@app/components/PropTypes'
import styles from './EnergyTotals.style'

class EnergyTotals extends React.Component {
  render() {
    const {
      classes,
      energies,
      periodSelector,
      isMobile,
    } = this.props
    const comparison = energies.comparison[matchValues[periodSelector]]
    const consumption = energies.total.consumption[matchValues[periodSelector]]
    const payment = energies.total.payment[matchValues[periodSelector]]
    return (
      <Wrapper className={classes.root}>
        <List className={classNames(
          classes.list,
          !isMobile && classes.listDesktop,
        )}
        >
          <ListItem className={classes.topWrapper}>
            <Wrapper className={classes.item}>
              <Wrapper className={classNames(
                classes.itemWrapper,
                classes.itemWrapperGreen,
              )}
              >
                <Typography className={classes.info}>
                  {consumption}
                  {' '}
                  {I18n.t('my_energy.power_kwh')}
                </Typography>
              </Wrapper>
              <Typography className={classes.itemTitle}>Power consumption</Typography>
            </Wrapper>

            <Wrapper className={classes.item}>
              <Wrapper className={classNames(
                classes.itemWrapper,
                classes.itemWrapperRed,
              )}
              >

                <Wrapper className={classNames(
                  classes.info,
                  comparison && classes.infoArrows,
                )}
                >
                  <Arrow trend={comparison} />
                  <Typography>
                    {Math.abs(comparison)}
                    {' '}
                    {I18n.t('my_energy.power_kwh')}
                  </Typography>
                </Wrapper>
              </Wrapper>
              <Typography className={classes.itemTitle}>Comparison with the previous period</Typography>
            </Wrapper>
          </ListItem>

          <ListItem className={classes.item}>
            <Wrapper className={classNames(
              classes.itemWrapper,
              classes.itemWrapperBlue,
            )}
            >
              <Typography className={classes.info}>
                {payment}
                {' '}
                $
              </Typography>
            </Wrapper>
            <Typography className={classes.itemTitle}>Payment</Typography>
          </ListItem>
        </List>
      </Wrapper>
    )
  }
}

EnergyTotals.propTypes = {
  classes: PropTypes.object.isRequired,
  energies: PropTypes.energies.isRequired,
  periodSelector: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

EnergyTotals.displayName = 'EnergyTotals'

export default withStyles(styles)(EnergyTotals)
