import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width="100" height="100" viewBox="0 0 500 500"><path d="M41 45.4l-9.6-4.8C30.6 40.2 30 39.3 30 38.2v-3.4c0.2-0.3 0.5-0.6 0.7-1 1.2-1.7 2.2-3.7 3-5.8C35.1 27.5 36 26.1 36 24.5v-4c0-1-0.4-1.9-1-2.6v-5.3c0.1-0.5 0.3-3.8-2.1-6.5C30.9 3.7 27.5 2.5 23 2.5s-7.9 1.2-9.9 3.5c-2.4 2.7-2.1 6-2.1 6.5v5.3c-0.6 0.7-1 1.7-1 2.6v4c0 1.2 0.6 2.4 1.5 3.1 0.9 3.6 2.8 6.4 3.5 7.2v3.3c0 1-0.5 1.9-1.4 2.3l-8.9 4.9C1.8 46.9 0 50 0 53.3V57.5h46v-4C46 50 44.1 46.9 41 45.4z"/><path d="M55.5 46.5l-9.7-4.2c-0.2-0.1-0.5-0.4-0.7-0.8l6.5 0c0 0 0.4 0 1 0 1.1 0 2.6-0.1 4-0.7 0.8-0.4 1.4-1 1.7-1.9 0.2-0.9 0.1-1.8-0.4-2.5 -1.9-2.7-6.2-9.6-6.4-16.6 0-0.1-0.4-12.1-12.2-12.2 -1.2 0-2.3 0.2-3.4 0.4 0.8 2.1 0.7 4 0.7 4.6v4.7c0.6 0.9 1 2 1 3.1v4c0 1.9-1 3.7-2.6 4.7 -0.7 2-1.7 3.9-2.9 5.6 -0.1 0.2-0.3 0.4-0.4 0.6v2.9c0 0.4 0.2 0.8 0.6 1l9.6 4.8c3.6 1.8 5.8 5.4 5.8 9.4V57.5H60v-3.7C60 50.7 58.3 47.9 55.5 46.5z"/></svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'UsersIcon', /*'0 0 28 26'*/)

export default icon
