export const binarySwitchActionValues = [
  {
    title: null,
    description: 'turn_on',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'SetDeviceStatus',
  },
  {
    title: null,
    description: 'turn_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'SetDeviceStatus',
  },
  {
    title: null,
    description: 'toggle',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'Toggle',
  },
]

export const valuesAfterDuration = [
  {
    title: null,
    description: 'turn_on',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
  },
  {
    title: null,
    description: 'turn_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
  },
]
