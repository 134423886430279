import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'

import { withStyles } from '@app/components/styles'
import { syncZWaveDeviceBinarySensorValues } from '@app/actions/scenes/zWaveDevices/zWaveDevices'
import { saveBinarySensorType, saveBinarySensorTypeDescription } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'
import { isIOS } from 'react-device-detect'
import SelectBinarySensorType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/BinarySensorConditions/SelectBinarySensorType/SelectBinarySensorType'
import SelectBinarySensorTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/BinarySensorConditions/SelectBinarySensorType/SelectBinarySensorType'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

let enhance = isIOS && isWebView ? withStyles(SelectBinarySensorTypeIOS) : withStyles(SelectBinarySensorType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { binarySensorTypes } = state.scenes.zWaveDevices
  const { isEditConditionMode, isChangeConditionStateMode, isChangeSensorMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    sensors: binarySensorTypes,
    isEditConditionMode,
    isChangeConditionStateMode,
    isChangeSensorMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  syncZWaveDeviceBinarySensorValues,
  saveBinarySensorType,
  saveBinarySensorTypeDescription,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectBinarySensorTypeEnhanced = enhance

export default withRouter(SelectBinarySensorTypeEnhanced)
