import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import { DELETE_SCENE } from '@app/constants/scenes/scenesList'
import {
  sync, clearCurrentSceneId, clearCurrentSceneName,
} from '@app/actions/scenes/scenesList'
import Api from '@app/api/scenes/deleteScene'
import handleErrors from '@app/helpers/handleErrors'
import { SCENES } from '@app/constants/routes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'

const deleteSceneLogic = createLogic({
  type: DELETE_SCENE,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = store.getState()
    dispatch(apiRequestSent(DELETE_SCENE))
    Api.deleteScene(token, id)
      .then(() => {
        dispatch(apiRequestDone(DELETE_SCENE))
        history.push(SCENES)
        dispatch(sync())
        dispatch(clearCurrentSceneId())
        dispatch(clearCurrentSceneName())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteScene failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteSceneLogic
