import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 253 253" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path d="M50.3508 6.40773C54.1871 2.62237 59.3598 0.5 64.7493 0.5H181.412C186.812 0.5 191.995 2.63136 195.834 6.43094L239.422 49.5792C243.312 53.4295 245.5 58.6753 245.5 64.1483V180.083C245.5 185.555 243.313 190.799 239.425 194.649L195.834 237.821C191.995 241.623 186.811 243.755 181.409 243.755H64.7522C59.361 243.755 54.1869 241.632 50.3502 237.844L6.59804 194.652C2.69625 190.801 0.5 185.546 0.5 180.064V64.1682C0.5 58.6837 2.69762 53.428 6.60154 49.5759L50.3508 6.40773Z" fill="#7C7C7C" stroke="#7C7C7C"/>
      <rect x="135.127" y="211.352" width="48.507" height="23.3873" fill="white"/>
      <path d="M147.687 216.982L146.82 228.243" stroke="black" strokeLinecap="square"/>
      <path d="M138.158 221.313L155.482 223.912" stroke="black" strokeLinecap="square"/>
      <path d="M169.342 214.384L173.673 231.708" stroke="black" strokeLinecap="square"/>
      <path d="M165.877 223.912L177.137 221.313" stroke="black" strokeLinecap="square"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M146.82 232.141C151.843 232.141 155.915 228.069 155.915 223.046C155.915 218.023 151.843 213.951 146.82 213.951C141.797 213.951 137.725 218.023 137.725 223.046C137.725 228.069 141.797 232.141 146.82 232.141Z" stroke="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M171.074 232.141C176.097 232.141 180.169 228.069 180.169 223.046C180.169 218.023 176.097 213.951 171.074 213.951C166.051 213.951 161.979 218.023 161.979 223.046C161.979 228.069 166.051 232.141 171.074 232.141Z" stroke="black"/>
      <rect x="62.3662" y="211.352" width="48.507" height="23.3873" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M98.3133 232.141C103.336 232.141 107.408 228.069 107.408 223.046C107.408 218.023 103.336 213.951 98.3133 213.951C93.2903 213.951 89.2183 218.023 89.2183 223.046C89.2183 228.069 93.2903 232.141 98.3133 232.141Z" stroke="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M74.0599 232.141C79.083 232.141 83.155 228.069 83.155 223.046C83.155 218.023 79.083 213.951 74.0599 213.951C69.0368 213.951 64.9648 218.023 64.9648 223.046C64.9648 228.069 69.0368 232.141 74.0599 232.141Z" stroke="black"/>
      <path d="M68.8628 216.116L80.1234 229.109" stroke="black" strokeLinecap="square"/>
      <path d="M79.2569 218.715L70.595 226.511" stroke="black" strokeLinecap="square"/>
      <path d="M99.1797 214.384V231.708" stroke="black" strokeLinecap="square"/>
      <path d="M93.1162 223.046H104.377" stroke="black" strokeLinecap="square"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M207.021 59.7676C210.848 59.7676 213.951 56.6651 213.951 52.838C213.951 49.0109 210.848 45.9084 207.021 45.9084C203.194 45.9084 200.092 49.0109 200.092 52.838C200.092 56.6651 203.194 59.7676 207.021 59.7676Z" fill="#82C040"/>
      <path d="M73.6267 106.975H92.683" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M73.627 96.1479H92.6833" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M73.6267 107.408H92.683" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M73.6267 117.803H92.683" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M73.6267 129.063H92.683" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M73.6267 140.324H92.683" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M73.6267 151.585H92.683" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.3662 95.2817C57.8891 95.2817 62.3662 99.7588 62.3662 105.282V138.986C62.3662 144.509 57.8891 148.986 52.3662 148.986H0.299316V95.2817H52.3662Z" fill="white"/>
      <path d="M46.7747 125.599V144.655" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M35.5142 125.599V144.655" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M25.1196 125.599V144.655" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M13.8591 125.599L13.8591 144.655" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M7.36278 96.581V147.687" stroke="black" strokeWidth="3" strokeLinecap="square"/>
      <path d="M46.7747 99.6127V118.669" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M35.5142 99.6127V118.669" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M25.1196 99.6127V118.669" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M13.8591 99.6127L13.8591 118.669" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M193.634 94.4155C188.111 94.4155 183.634 98.8927 183.634 104.416V138.12C183.634 143.643 188.111 148.12 193.634 148.12H245.697V94.4155H193.634Z" fill="white"/>
      <path d="M231.275 123.866V142.923" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M220.014 123.866V142.923" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M209.62 123.866V142.923" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M198.359 123.866V142.923" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M240.37 95.7148V146.82" stroke="black" strokeWidth="3" strokeLinecap="square"/>
      <path d="M231.275 98.7465V117.803" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M220.014 98.7465V117.803" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M209.62 98.7465V117.803" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M198.359 98.7465V117.803" stroke="black" strokeWidth="4" strokeLinecap="square"/>
      <path d="M100.479 96.1479H119.536" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M100.479 107.408H119.535" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M100.479 117.803H119.535" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M100.479 129.063H119.535" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M100.479 140.324H119.535" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M100.479 151.585H119.535" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M125.599 96.1479H144.655" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M125.599 107.408H144.655" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M125.599 117.803H144.655" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M125.599 129.063H144.655" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M125.599 140.324H144.655" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M125.599 151.585H144.655" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M152.451 96.1479H171.507" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M152.451 107.408H171.507" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M152.451 117.803H171.507" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M152.451 129.063H171.507" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M152.451 140.324H171.507" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M152.451 151.585H171.507" stroke="white" strokeWidth="4" strokeLinecap="square"/>
      <path d="M175.247 53.1686C162.327 59.7865 164.331 74.9393 171.477 80.9356C178.623 86.9319 189.277 85.9998 195.274 78.8537C201.27 71.7076 201.806 60.0082 189.414 53.1686" stroke="white" strokeWidth="3"/>
      <path d="M174.539 53.2711V39.412" stroke="white" strokeWidth="3" strokeLinecap="square"/>
      <path d="M190.13 53.2711V39.412" stroke="white" strokeWidth="3" strokeLinecap="square"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M181.468 101.345C204.67 101.345 223.479 82.5363 223.479 59.3345C223.479 36.1327 204.67 17.3239 181.468 17.3239C158.267 17.3239 139.458 36.1327 139.458 59.3345C139.458 82.5363 158.267 101.345 181.468 101.345Z" stroke="#D0021B" strokeWidth="5"/>
      <rect x="94.4155" y="11.2606" width="57.169" height="19.0563" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M103.078 27.7183C106.905 27.7183 110.007 24.6158 110.007 20.7887C110.007 16.9616 106.905 13.8592 103.078 13.8592C99.2504 13.8592 96.1479 16.9616 96.1479 20.7887C96.1479 24.6158 99.2504 27.7183 103.078 27.7183Z" stroke="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M123 27.7183C126.827 27.7183 129.929 24.6158 129.929 20.7887C129.929 16.9616 126.827 13.8592 123 13.8592C119.173 13.8592 116.07 16.9616 116.07 20.7887C116.07 24.6158 119.173 27.7183 123 27.7183Z" stroke="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M142.922 27.7183C146.75 27.7183 149.852 24.6158 149.852 20.7887C149.852 16.9616 146.75 13.8592 142.922 13.8592C139.095 13.8592 135.993 16.9616 135.993 20.7887C135.993 24.6158 139.095 27.7183 142.922 27.7183Z" stroke="black"/>
      <path d="M98.3135 25.5528L107.842 16.0247" stroke="black" strokeLinecap="square"/>
      <path d="M116.503 20.7887H129.496" stroke="black" strokeLinecap="square"/>
      <path d="M142.923 14.2923V27.2852" stroke="black" strokeLinecap="square"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZWaveDeviceBackIcon', "0 0 193 193")

export default icon
