const getBodyDataToBackend = (requestBody) => {
  const data = {}
  requestBody.forEach((el, index) => {
    if (index !== requestBody.length - 1) {
      data[el.key] = el.value
    }
  })
  return data
}

export default getBodyDataToBackend
