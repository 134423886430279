import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { syncRoomsScenes } from '@app/actions/scenes/roomsScenes/roomsScenes'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectTriggeringRoom
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringRoom/SelectTriggeringRoom'
import SelectTriggeringRoomIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/TriggeringDevices/SelectTriggeringRoom/SelectTriggeringRoom'
import {
  withAccessDenied, withNotifications, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTriggeringRoomIOS) : withStyles(SelectTriggeringRoom)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { roomsScenes } = state.scenes.roomsScenes
  return {
    isMobile,
    roomsScenes,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncRoomsScenes,
  tryAgain: syncRoomsScenes,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTriggeringRoomEnhanced = enhance

export default withRouter(SelectTriggeringRoomEnhanced)
