import update from 'immutability-helper'
import * as type from '@app/constants/scenes/currentSceneConditionsActions'
import * as actions from '@app/actions/scenes/currentSceneConditionsActions'
import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'
import { InferValueTypes } from '@app/@types/typesHelper'

export type TCurrentSceneConditionsActions = ReturnType<InferValueTypes<typeof actions>>

export interface ICurrentSceneConditionsActionsState {
  conditions: Array<IConditionFromBackend>,
  actions: Array<IActionFromBackend>,
}
const initialState = {
  conditions: [],
  actions: [],
}

export default function currentScene(state: ICurrentSceneConditionsActionsState = initialState, action: TCurrentSceneConditionsActions) {
  switch (action.type) {
  case type.SAVE_CONDITIONS: {
    return update(state, {
      conditions: { $set: action.conditions },
    })
  }
  case type.SAVE_ACTIONS: {
    return update(state, {
      actions: { $set: action.actions },
    })
  }

  case type.RESET_CURRENT_SCENE_CONDITIONS_ACTIONS:
    return initialState

  case type.CHANGE_CONDITION_OPERAND_VALUE: {
    return update(state, {
      conditions: {
        [action.conditionIndex]: {
          operand: { $set: action.operand },
        },
      },
    })
  }

  default:
    return state
  }
}
