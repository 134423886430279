import { createLogic } from 'redux-logic'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import Api from '../../api/groups'
import handleErrors from '../../helpers/handleErrors'
import { SYNC_GROUPS_LIST } from '../../constants/groups/groups'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '../../actions/apiRequest'
import { setGroupsList } from '../../actions/groups/groups'

const fetchGroupList = createLogic({
  type: SYNC_GROUPS_LIST,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_GROUPS_LIST))
    Api.fetchGroups(AccessToken)
      .then((response) => {
        dispatch(setGroupsList(response.data))
        dispatch(apiRequestDone(SYNC_GROUPS_LIST))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchGroups failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchGroupList
