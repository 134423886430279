import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { updateAccessToken } from '@app/actions/secrets'
// @ts-ignore
import { updateCurrentUser } from '@app/actions/users/currentUser'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage,
} from '@app/hocs'
import MySecurity from '@app/components/MySecurity/MySecurity'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isNCentral } = state.unit
  return {
    isNCentral,
    isMobile,
    path,
    isWebView,
    admin: checkIfAdmin(state.users.currentUser.user.role),
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
}, dispatch)

let enhance = withNotifications(MySecurity)
enhance = withAccessDenied(enhance)
enhance = withProgressAndErrorMessage(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MySecurityEnhanced = enhance

export default MySecurityEnhanced
