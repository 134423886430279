const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  radioWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    backgroundColor: theme.backgrounds.cardBackground,
    margin: '0 0 12px 0',
    padding: '0 14px 0 20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
  },
  addButton: {
    width: '100%',
    height: 36,
    marginTop: 20,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
