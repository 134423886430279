import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectActionDeviceType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/SelectActionDeviceType/SelectActionDeviceType'
import SelectActionDeviceTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/SelectActionDeviceType/SelectActionDeviceType'
import { withAccessDenied, withNotifications } from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? SelectActionDeviceTypeIOS : SelectActionDeviceType

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditActionMode } = state.scenes.currentSceneCreating
  const { isNCentral } = state.unit
  return {
    isMobile,
    path,
    isEditActionMode,
    isNCentral,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
const SelectActionDeviceTypeEnhanced = connect(mapStateToProps, mapDispatchToProps)(enhance)

export default SelectActionDeviceTypeEnhanced
