import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  brightnessWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioWrapper: {
    width: '100%',
    maxWidth: 671,
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 20,
  },
  title: {
    marginLeft: 20,
  },
  sliderWrapper: {
    width: '100%',
    maxWidth: 650,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    background: theme.backgrounds.cardBackground,
    marginBottom: 30,
  },
  slider: {
    width: '80%',
    padding: '38px 0',
    '& .MuiSlider-rail': {
      padding: '0 12px',
      left: -12,
      height: 23,
      // eslint-disable-next-line max-len
      background: 'linear-gradient(270deg, #FFFFFF 2.39%, #C4C4C4 28.84%, rgba(196, 196, 196, 0.83) 53.14%, rgba(196, 196, 196, 0.550636) 74.32%, rgba(196, 196, 196, 0.44) 92.03%)',
      opacity: 1,
    },
    '& .MuiSlider-track': {
      opacity: 0,
    },
  },
  saveButton: {
    marginTop: 80,
    width: '90%',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.25,
  },
}))

export default useStyles
