export const OPEN_SECRET_CODE_POPUP = '@@SecretCodePopup/open'
export const CLOSE_SECRET_CODE_POPUP = '@@SecretCodePopup/close'

export const SET_SECRET_CODE = '@@secretCode/set'

export const OPEN_WARNING_CHANGE_EMAIL_POPUP = '@@WarningChangeEmailPopup/open'
export const CLOSE_WARNING_CHANGE_EMAIL_POPUP = '@@WarningChangeEmailPopup/close'

export const OPEN_CHANGE_PIN_POPUP = '@@userEdit/openChangePinPopup'
export const CLOSE_CHANGE_PIN_POPUP = '@@serEdit/closeChangePinPopup'

export const SET_PIN_CODE = '@@userEdit/setPinCode'

export const GET_PIN_CODE = '@@userEdit/getPinCode'
export const SAVE_PIN_CODE = '@@userEdit/savePinCode'

export const OPEN_SHOW_PIN_POPUP = '@@userEdit/openShowPinPopup'
export const CLOSE_SHOW_PIN_POPUP = '@@serEdit/closeShowPinPopup'
