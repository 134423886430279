import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.3445 44.5352C67.7054 44.5352 61.5101 50.736 61.5101 58.3821C61.5101 64.748 65.8091 70.0972 71.6548 71.7156V137.308C71.6548 139.348 73.3077 141.001 75.3445 141.001C77.3829 141.001 79.0359 139.348 79.0359 137.308V71.714C84.8816 70.0955 89.1805 64.7464 89.1805 58.3821C89.1805 50.736 82.9853 44.5352 75.3445 44.5352Z" fill="currentColor"/>
      <path d="M52.7713 34.8627C51.3307 33.4208 48.995 33.4208 47.5544 34.8627C34.5988 47.8301 34.5988 68.9306 47.5544 81.898C48.2731 82.619 49.2204 82.9786 50.1645 82.9786C51.1069 82.9786 52.0526 82.619 52.7729 81.898C54.2135 80.4545 54.2135 78.1183 52.7729 76.6764C42.6936 66.5879 42.6936 50.1727 52.7729 40.0843C54.2119 38.6424 54.2119 36.3046 52.7713 34.8627Z" fill="currentColor"/>
      <path d="M40.1213 27.4124C41.5619 25.9705 41.5619 23.6327 40.1213 22.1908C38.6807 20.7489 36.3451 20.7489 34.9044 22.1908C15.159 41.9541 15.4693 74.4216 35.597 94.5674C36.3157 95.2868 37.2614 95.648 38.2054 95.648C39.1495 95.648 40.0936 95.2868 40.8139 94.5674C42.2561 93.1239 42.2561 90.7877 40.8139 89.3458C23.5625 72.0789 23.2522 44.2952 40.1213 27.4124Z" fill="currentColor"/>
      <path d="M103.139 34.8627C101.698 33.4208 99.3626 33.4208 97.9236 34.8627C96.4814 36.3046 96.4814 38.6424 97.9236 40.0843C108 50.1727 108 66.5879 97.9236 76.6764C96.4814 78.12 96.4814 80.4561 97.9236 81.898C98.6423 82.619 99.588 82.9786 100.532 82.9786C101.476 82.9786 102.419 82.619 103.14 81.898C116.094 68.9323 116.094 47.8301 103.139 34.8627Z" fill="currentColor"/>
      <path d="M115.79 22.1908C114.349 20.7489 112.013 20.7489 110.574 22.1908C109.132 23.6327 109.132 25.9705 110.574 27.4124C127.439 44.2968 127.13 72.0805 109.882 89.3458C108.441 90.7894 108.441 93.1255 109.882 94.5674C110.602 95.2868 111.546 95.648 112.49 95.648C113.434 95.648 114.378 95.2868 115.099 94.5674C135.221 74.4216 135.532 41.9541 115.79 22.1908Z" fill="currentColor"/>
      <path d="M27.1088 14.303C28.551 12.8611 28.551 10.5233 27.1088 9.08143C25.6698 7.63952 23.3325 7.63952 21.8935 9.08143C-5.00265 36.002 -4.57146 80.2352 22.8556 107.687C23.5742 108.408 24.52 108.769 25.464 108.769C26.4081 108.769 27.3522 108.408 28.0725 107.687C29.5147 106.245 29.5147 103.907 28.0725 102.467C3.52174 77.8925 3.09054 38.343 27.1088 14.303Z" fill="currentColor"/>
      <path d="M129.106 9.08143C127.665 7.63952 125.329 7.63952 123.89 9.08143C122.448 10.5217 122.448 12.8611 123.89 14.303C147.909 38.3447 147.477 77.8925 122.927 102.467C121.486 103.909 121.486 106.247 122.927 107.687C123.647 108.408 124.593 108.769 125.537 108.769C126.479 108.769 127.423 108.408 128.145 107.687C155.571 80.2352 156.003 36.002 129.106 9.08143Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Antenna')

export default icon
