import { Optional } from '@app/@types/common'

const getSceneStatus = (verified: boolean, status: string): Optional<string> => {
  if (!verified) {
    return 'broken'
  }
  switch (status) {
  case 'in_progress':
  case 'commit_to_mezzo':
    return 'saving'
  case 'not_saved':
    return 'not_saved'
  case 'incomplete':
    return 'draft'
  case 'too_big':
    return 'too_big_for_saving'
  case 'complete':
    return null
  default:
    return null
  }
}

export default getSceneStatus
