import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  root: {
    minHeight: 90,
    display: 'flex',
    flexDirection: 'column',
  },
  rootDesktop: {
    minHeight: 70,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    maxWidth: '100%',
    fontSize: '1rem',
  },
  titleDesktop: {
    maxWidth: '56%',
    fontSize: '1rem',
  },
}))

export default useStyles
