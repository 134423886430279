import * as type from '@app/constants/secrets'

export const getClientCredentialsToken = () => ({
  type: type.GET_CLIENT_CREDENTIALS_TOKEN,
})

export const getClientCredentialsTokenDone = () => ({
  type: type.GET_CLIENT_CREDENTIALS_TOKEN_DONE,
})

export const saveClientCredentialsToken = (clientCredentialsToken: string) => ({
  type: type.SAVE_CLIENT_CREDENTIALS_TOKEN,
  clientCredentialsToken,
})
export const clearClientCredentialsToken = () => ({
  type: type.CLEAR_CLIENT_CREDENTIALS_TOKEN,
})

export const updateSecrets = (unitToken: string, clientId: string) => ({
  type: type.UPDATE_SECRETS,
  unitToken,
  clientId,
})

export const authentificationSuccess = () => ({
  type: type.AUTHENTIFICATION_SUCCESS,
})

export const updateAccessToken = (token: string, expiresIn: string, tokenType: string, refreshToken: string) => ({
  type: type.UPDATE_ACCESS_TOKEN,
  token,
  expiresIn,
  tokenType,
  refreshToken,
})

export const resetSecrets = () => ({
  type: type.RESET_SECRETS,
})

export const sync = () => ({
  type: type.SYNC_SECRETS,
})

export const refreshTokenInterceptor = () => ({
  type: type.REFRESH_TOKEN_INTERCEPTOR,
})

export const tokenStatusNone = () => ({
  type: type.TOKEN_STATUS_NONE,
})

export const tokenStatusRefreshing = () => ({
  type: type.TOKEN_STATUS_REFRESHING,
})

export const tokenStatusRefreshed = () => ({
  type: type.TOKEN_STATUS_REFRESHED,
})
