import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@app/components/styles'
import { clearNotificationsChannel } from '@app/actions/notifications/notificationsChannel'
import { signOut } from '@app/actions/signOut'
import {
  becomeOfflineUnit, becomeOnlineUnit, fetchUnit, closeEditUnitNamePopup, closeUnitList,
  patchUnit, selectUnit,
} from '@app/actions/unit'
import { sync as syncCurrentUser, updateCurrentUser, updateCurrentUserRole } from '@app/actions/users/currentUser'
import { showActivationWarningPopup, showActivationErrorPopup } from '@app/actions/wizard/signUp'
import { closeSyncErrorPopup, closeSyncWarningPopup, closeSyncInProgressPopup } from '@app/actions/syncCounters/countersChannel'
import Snackbar from '@app/components/Common/Snackbar'
import { sync as syncDevices } from '@app/actions/units'
import { withRouter } from '@app/hocs'

let enhance = withStyles(Snackbar)

const mapStateToProps = (state) => ({
  notification: state.notifications.notificationsChannel.notification,
  isMobile: state.mobileDetector.isMobile,
  selectedSerialnumber: state.unit.serialNumber,
  units: state.units.devices,

})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  clearNotificationsChannel,
  signOut,
  syncCurrentUser,
  becomeOnlineUnit,
  becomeOfflineUnit,
  fetchUnit,
  closeSyncErrorPopup,
  closeSyncWarningPopup,
  showActivationWarningPopup,
  showActivationErrorPopup,
  updateCurrentUser,
  closeEditUnitNamePopup,
  closeUnitList,
  selectUnit,
  patchUnit,
  updateCurrentUserRole,
  syncDevices,
  closeSyncInProgressPopup,
}, dispatch)

enhance = withRouter(enhance)
const SnackbarEnhanced = connect(mapStateToProps, mapDispatchToProps)(enhance)

export default SnackbarEnhanced
