import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_NEW_ZWAVE_DEVICE } from '@app/constants/myDevices/zwaveDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/zwaveDevices'
import { setMyDevices } from '@app/actions/myDevices/myDevices'

const patchNewZwaveDeviceLogic = createLogic({
  type: PATCH_NEW_ZWAVE_DEVICE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { myDevices: { myDevices: { devices } } } = getState()
    const { myDevices: { myDevices: { deviceToAddInfo } } } = getState()
    const { myDevices: { myDevices: { deviceToAddInfo: { id } } } } = getState()
    next({
      ...action,
      token,
      devices,
      id,
      deviceToAddInfo,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_NEW_ZWAVE_DEVICE))
    try {
      const patchedDevice = await Api.patchZwaveDeviceStatus(action.token, action.id, action.data)
      const updatedDevices = [...action.devices, patchedDevice.data.zwave_device, ...patchedDevice.data.sub_devices]
      dispatch(setMyDevices(updatedDevices))
      dispatch(apiRequestDone(PATCH_NEW_ZWAVE_DEVICE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_NEW_ZWAVE_DEVICE failed without error')
      }
    }
    done()
  },
})

export default patchNewZwaveDeviceLogic
