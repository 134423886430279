import { createLogic } from 'redux-logic'
import Api from '@app/api/contacts'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_CONTACT } from '@app/constants/contacts/contacts'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import { setDeleteContact } from '@app/actions/contacts/contacts'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const deleteContact = createLogic({
  type: DELETE_CONTACT,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(DELETE_CONTACT))
    Api.deleteContact(AccessToken, action.id)
      .then((response) => {
        dispatch(setDeleteContact(response.data))
        dispatch(apiRequestDone(DELETE_CONTACT))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteContact failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteContact
