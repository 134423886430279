import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_APP_CONFIG } from '@app/constants/appCofig/appConfig'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import Api from '@app/api/appConfig'
import { saveRawConfig, saveAppConfig } from '@app/actions/appConig/appConfig'
import { convertAppConfig } from '@app/helpers/convertAppConfig'

const getAppConfigLogic = createLogic({
  type: GET_APP_CONFIG,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { unit: { isNCentral, activation } } = getState()
    const { users: { currentUser: { user: { role } } } } = getState()
    next({
      ...action,
      token,
      isNCentral,
      activation,
      role,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_APP_CONFIG))
    try {
      const data = await Api.getAppConfig(action.token)
      const rawConfigs = data.data.accounts
      dispatch(saveRawConfig(rawConfigs))
      const convertedConfig = convertAppConfig(rawConfigs, action.isNCentral, action.activation, action.role)
      dispatch(saveAppConfig(convertedConfig))
      dispatch(apiRequestDone(GET_APP_CONFIG))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('GET_APP_CONFIG failed without error')
      }
    } finally {
      done()
    }
  },
})

export default getAppConfigLogic
