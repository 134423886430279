import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 19.0312V15H13.9688V19.0312C13.9688 19.5625 13.7656 20.0312 13.3594 20.4375C12.9844 20.8438 12.5312 21.0469 12 21.0469V23H13C13.5523 23 14 23.4477 14 24C14 24.5523 13.5523 25 13 25H1C0.447716 25 0 24.5523 0 24C0 23.4477 0.447715 23 1 23H2V21.0469H1.96875C1.4375 21.0469 0.96875 20.8438 0.5625 20.4375C0.1875 20.0312 0 19.5625 0 19.0312V2.01562C0 1.48438 0.1875 1.01562 0.5625 0.609375C0.96875 0.203125 1.4375 0 1.96875 0H12C12.5312 0 12.9844 0.203125 13.3594 0.609375C13.7656 1.01562 13.9688 1.48438 13.9688 2.01562V6H12V2.01562H8V12H6V2.01562H1.96875V19.0312H12ZM4 21.0469V23H10V21.0469H4ZM19.0141 8.76023V7.88012L15 10.0702L10.9859 7.88012V8.76023L15 10.9298L19.0141 8.76023ZM19.0141 7C19.2801 7 19.507 7.08869 19.6948 7.26608C19.8983 7.44347 20 7.64815 20 7.88012V13.1199C20 13.3519 19.8983 13.5565 19.6948 13.7339C19.507 13.9113 19.2801 14 19.0141 14H10.9859C10.7199 14 10.4851 13.9113 10.2817 13.7339C10.0939 13.5565 10 13.3519 10 13.1199V7.88012C10 7.64815 10.0939 7.44347 10.2817 7.26608C10.4851 7.08869 10.7199 7 10.9859 7H19.0141Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'ReportingAgainIcon')

export default icon
