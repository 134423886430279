import Axios from 'axios'

import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/v3/user_requests/${id}/reject`

const rejectUserRequest = (accessToken, id) => Axios.post(ENDPOINT(id), null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  rejectUserRequest,
}
