import React from 'react'
import {
  AnotherSceneAction,
  AnotherSceneActionMobile,
  DevicesAction,
  DevicesActionMobile,
  VirtualSwitch,
  VirtualSwitchMobile,
} from 'icons'

export const actionTypesIcons = {
  0: <DevicesAction />,
  1: <AnotherSceneAction />,
  2: <VirtualSwitch />,
}

export const actionTypesIconsMobile = {
  0: <DevicesActionMobile />,
  1: <AnotherSceneActionMobile />,
  2: <VirtualSwitchMobile />,
}
