import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { syncZWaveDevicesByGroups } from '@app/actions/scenes/zWaveDevices/zWaveDevicesByGroups'
import { withAccessDenied, withProgressAndErrorMessage, withRefresh } from '@app/hocs'
import { isIOS } from 'react-device-detect'
import GroupsList
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/DevicesConditions/SelectDeviceCommon/Groups/GroupsList'
import GroupsListIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/DevicesConditions/SelectDeviceCommon/Groups/GroupsList'
import { AppDispatch } from '@app/store'
import { AppState } from '@app/@types/store'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? GroupsListIOS : GroupsList

const mapStateToProps = (state: AppState) => {
  const { isMobile } = state.mobileDetector
  const { groups } = state.scenes.zWaveDevicesByGroups
  const { deviceType } = state.scenes.currentSceneCreating.currentCondition
  return {
    isMobile,
    groups,
    deviceType,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: syncZWaveDevicesByGroups,
  tryAgain: syncZWaveDevicesByGroups,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const GroupsListEnhanced = enhance

export default GroupsListEnhanced
