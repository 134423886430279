const convertColorConditionDataToBackend = (RGBAcolor, conditionDeviceNumber) => {
  const colorsOnly = RGBAcolor.substring(RGBAcolor.indexOf('(') + 1, RGBAcolor.lastIndexOf(')')).split(/,\s*/)
  const result = {
    number: conditionDeviceNumber,
    channels: {
      red: Number(colorsOnly[0]),
      green: Number(colorsOnly[1]),
      blue: Number(colorsOnly[2]),
    },
  }
  return result
}
export default convertColorConditionDataToBackend
