import * as type from '@app/constants/users/usersRequests'

const initialState = {
  requests: [],
}
export default function usersRequests(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_USERS_REQUESTS: {
    return {
      ...state,
      requests: action.requests,
    }
  }

  default:
    return state
  }
}
