export const mezzoBrightnessValues = [
  {
    title: null,
    description: 'turn_off',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'LightMezzo',
  },
  {
    title: null,
    description: 'turn_on_low',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'LightMezzo',
  },
  {
    title: null,
    description: 'turn_on_medium',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'LightMezzo',
  },
  {
    title: null,
    description: 'turn_on_high',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    actionType: 'LightMezzo',
  },
]

export const brightnessValues = [
  'turn_off',
  'turn_on_low',
  'turn_on_medium',
  'turn_on_high',
]

export const brightnessValueStrings = {
  turn_off: 'turn off',
  turn_on_low: 'turn on low',
  turn_on_medium: 'turn on medium',
  turn_on_high: 'turn on high',
}
