import * as type from '@app/constants/notifications/notificationsSettings'

export const update = (settings: Record<string, boolean>) => ({
  type: type.UPDATE_NOTIFICATIONS_SETTING,
  settings,
} as const)

export const sync = () => ({
  type: type.SYNC_NOTIFICATIONS_SETTING,
} as const)

export const set = (notificationSettings: Record<string, boolean>) => ({
  type: type.SET_NOTIFICATIONS_SETTING,
  notificationSettings,
} as const)
