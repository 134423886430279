import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 54.8672H124V67.3415H24V54.8672Z" fill="currentColor"/>
            <path d="M24 97.1953H124V109.67H24V97.1953Z" fill="currentColor"/>
            <path d="M24 111.309H124V124.602H24V111.309Z" fill="currentColor"/>
            <path d="M24 39.9375H124V53.2311H24V39.9375Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M124 81.4477V68.9805H24V81.4477H65.6684V78.655H82.3316V81.4477H124Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M124 95.5609V83.0938H82.3316V85.8793H65.6684V83.0938H24V95.5609H124Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24 123.605H124Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24 38.9375H124Z" fill="currentColor"/>
            <rect x="67.3794" y="80.3008" width="14.234" height="3.94677" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M139.575 26.4259V122.181H130.129V35.8724H18.8714V122.181H9.4248V26.4259H139.575ZM141 25H8V123.605H20.2962V37.2962H128.704V123.605H141V25Z" fill="currentColor" stroke="currentColor" strokeWidth="3"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwGarageDoorIcon')

export default icon
