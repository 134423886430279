import React from 'react'
import classNames from 'classnames'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import getPristineValue from '@app/helpers/scenes/getPristineValue'
import { alphanumeric } from '@app/lib/RegEx/regex'
import { SCENES } from '@app/constants/routes'
import SceneReviewBase from '@app/containers/MyAutomation/SceneReviewBase'
import NavBar from '@app/components/Common/NavBar/NavBar'
import { IActionFromBackend, IConditionFromBackend } from '@app/@types/scenes/scenes'
import { useDispatch } from '@app/store'
import {
  patchConditionOperand,
  saveConditionId,
  saveConditionOperand,
  saveScreenName,
} from '@app/actions/scenes/currentSceneCreating'
import {
  changeConditionOperandValue,
} from '@app/actions/scenes/currentSceneConditionsActions'
import {
  openEditDraftSavePopup,
  openEditSceneQuitPopup,
  patchChangeset,
  saveChangesetAction,
} from '@app/actions/scenes/editScene'
import { saveCurrentSceneName, saveCurrentSceneStatus } from '@app/actions/scenes/scenesList'
import { useClearSceneReviewState } from '@app/hooks/withClearSceneReviewState'
import useStyles from './SceneReviewEdit.style'
import EditPopups from '../EditScenesCommon/EditPopups'

interface Props {
  title: string,
  conditions: Array<IConditionFromBackend>,
  actions: Array<IActionFromBackend>,
  isMobile: boolean,
  sceneName: string,
  isEditDraftSavePopupOpen: boolean,
  isEditSceneQuitPopupOpen: boolean,
  sceneMode: string,
  isAdditionalConditionMode: boolean,
  isAdditionalActionMode: boolean,
  editedSceneInitialConditions: Array<IConditionFromBackend>,
  editedSceneInitialActions: Array<IActionFromBackend>,
  editedSceneInitialName: string,
  isEditActionMode: boolean,
  isEditConditionMode: boolean,
}

const SceneReviewEdit: React.FC<Props> = (props) => {
  const {
    title,
    conditions,
    actions,
    isMobile,
    sceneName,
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
    sceneMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
    editedSceneInitialConditions,
    editedSceneInitialActions,
    editedSceneInitialName,
    isEditActionMode,
    isEditConditionMode,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  useClearSceneReviewState(isEditActionMode, isEditConditionMode, isAdditionalConditionMode, isAdditionalActionMode)

  const onBackClick = () => {
    const isScreenPristine = getPristineValue(editedSceneInitialName, editedSceneInitialActions,
      editedSceneInitialConditions, sceneName, actions, conditions)
    if (isScreenPristine) {
      navigate(SCENES)
    } else if (sceneMode === 'editDraftScene') {
      dispatch(openEditDraftSavePopup())
    } else if (sceneMode === 'editMezzoScene') {
      dispatch(openEditSceneQuitPopup())
    }
  }

  const onSubmitClick = () => {
    dispatch(saveChangesetAction('apply'))
    dispatch(saveScreenName(SCENES))
    dispatch(saveCurrentSceneStatus('commit_to_mezzo'))
    dispatch(patchChangeset())
  }

  const changeRadio = (event: React.ChangeEvent<HTMLInputElement>, condition: IConditionFromBackend, index: number) => {
    dispatch(changeConditionOperandValue(index, event.target.value))
    dispatch(saveConditionId(condition.id))
    dispatch(saveConditionOperand(event.target.value))
    dispatch(patchConditionOperand())
  }

  const handleChangeNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (alphanumeric.test(event.target.value) || event.target.value === '') {
      dispatch(saveCurrentSceneName(event.target.value.trimLeft()))
    }
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title={title}
        isMobile={isMobile}
        withButton={false}
      />
      {/* @ts-ignore */}
      <SceneReviewBase
        handleChangeNameInput={handleChangeNameInput}
        changeRadio={changeRadio}
        onCancelClick={onBackClick}
        onSaveClick={onSubmitClick}
      />
      <EditPopups
        isEditDraftSavePopupOpen={isEditDraftSavePopupOpen}
        isEditSceneQuitPopupOpen={isEditSceneQuitPopupOpen}
        sceneMode={sceneMode}
        isAdditionalConditionMode={isAdditionalConditionMode}
        isAdditionalActionMode={isAdditionalActionMode}
      />
    </Paper>
  )
}

export default SceneReviewEdit
