import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>
      <path fill="none" d="M0 0h24v24H0z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ExpandLess')

export default icon
