import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3.33333V0H3.33333V0.833333H0.833333V3.33333H0ZM13.3333 0H6.66667V0.833333H13.3333V0ZM16.6667 0V0.833333H19.1667V3.33333H20V0H16.6667ZM20 6.66667H19.1667V13.3333H20V6.66667ZM20 16.6667H19.1667V19.1667H16.6667V20H20V16.6667ZM13.3333 20V19.1667H6.66667V20H13.3333ZM3.33333 20V19.1667H0.833333V16.6667H0V20H3.33333ZM0 13.3333H0.833333V6.66667H0V13.3333Z" fill="currentColor"/>
      <path d="M12.9398 8.42592H8.10186V6.6435C8.10186 5.66064 8.90141 4.86111 9.88428 4.86111C10.8671 4.86111 11.6667 5.66064 11.6667 6.64353V7.66205C11.6667 7.80261 11.7808 7.91669 11.9213 7.91669H12.9398C13.0804 7.91669 13.1945 7.80261 13.1945 7.66205V6.64353C13.1945 4.81833 11.7095 3.33333 9.88428 3.33333C8.05908 3.33333 6.57408 4.81833 6.57408 6.64353V8.45191C5.99353 8.57005 5.55556 9.08491 5.55556 9.69908V14.2824C5.55558 14.9842 6.12644 15.5556 6.82872 15.5556H12.9398C13.6421 15.5556 14.213 14.9842 14.213 14.2824V9.69908C14.213 8.99731 13.6421 8.42592 12.9398 8.42592ZM10.4491 12.3625L10.6456 13.737C10.6563 13.8103 10.6344 13.8842 10.5861 13.9397C10.5377 13.9952 10.4674 14.0278 10.3936 14.0278H9.37503C9.30119 14.0278 9.23091 13.9957 9.18253 13.9402C9.13414 13.8847 9.11225 13.8104 9.12294 13.7375L9.31952 12.363C8.89378 12.1502 8.61114 11.7107 8.61114 11.2269C8.61114 10.5251 9.18202 9.95372 9.88428 9.95372C10.5865 9.95372 11.1574 10.5251 11.1574 11.2269C11.1574 11.7106 10.8748 12.1501 10.4491 12.3625Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'PerimeterArmedIcon')

export default icon
