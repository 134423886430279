import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 18">
      <path fill="#fff" d="M11.37 7.5H3.5V4.97c0-1.31.53-2.9 1.75-3.17 1.67-.26 2.63 0 3.5 1.75.23.47.43.87.87.87s1.04-.38.88-.87C9.63.92 7.88-.25 5.25.05c-2.27.26-3.5 2.73-3.5 5V7.5H.87a.88.88 0 0 0-.87.88v8.75c0 .43.44.87.87.87h10.5c.53 0 .88-.44.88-.87V8.38c0-.53-.35-.88-.88-.88ZM7 13.36v1.14c0 .53-.35.88-.88.88-.52 0-.87-.35-.87-.88v-1.14a1.8 1.8 0 0 1-.88-1.48c0-1.14 1.05-1.93 2.19-1.66.61.17 1.14.7 1.31 1.3A1.7 1.7 0 0 1 7 13.37Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'UnlockedIcon', '0 0 13 18', { fontSize: 18 })

export default icon
