import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import { binarySensorSelected, binarySensorValueSelected } from '@app/lib/Scenes/conditions'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION, SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_EVENT_TYPE,
  SELECT_NOTIFICATION_SENSOR_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectNotificationSensorStateSelected extends React.Component {
  componentDidMount() {
    const {
      deactivateChangeSensorMode,
      deactivateChangeSensorStateMode,
    } = this.props
    deactivateChangeSensorMode()
    deactivateChangeSensorStateMode()
  }

  selectSensorType=() => {
    const {
      navigate,
      activateChangeSensorMode,
    } = this.props
    activateChangeSensorMode()
    navigate(SELECT_NOTIFICATION_SENSOR_TYPE)
  }

  selectSensorValue=() => {
    const {
      navigate,
      activateChangeSensorStateMode,
    } = this.props
    activateChangeSensorStateMode()
    navigate(SELECT_EVENT_TYPE)
  }

  onBackClick =() => {
    const {
      navigate,
      isChangeConditionStateMode,
      isEditConditionMode,
      restoreConditionInfo,
      notificationSensors,
      sensorTypeDescription,
    } = this.props
    const currentSensor = notificationSensors.find((sensor) => sensor.notification_name === sensorTypeDescription)
    const currentEventsList = currentSensor.event_types
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
        restoreConditionInfo()
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else if (currentEventsList.length === 1) {
      if (notificationSensors.length === 1) {
        navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
      } else {
        navigate(SELECT_NOTIFICATION_SENSOR_TYPE)
      }
    } else {
      navigate(SELECT_EVENT_TYPE)
    }
  }

  addStatePressed=() => {
    const {
      navigate,
      sensorTypeDescription,
      sensorEventDescription,
      saveConditionStateDescription,
      isEditConditionMode,
      saveConditionType,
      saveConditionDeviceStateInfo,
      conditionDeviceNumber,
      sensorType,
      sensorEventType,
    } = this.props
    saveConditionType('ZwaveSensorNotification')
    saveConditionDeviceStateInfo({ number: conditionDeviceNumber, notification_type: sensorType, event_type: sensorEventType })
    saveConditionStateDescription(`${sensorTypeDescription} is ${sensorEventDescription}`)
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      sensorTypeDescription,
      sensorEventDescription,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <CardBlock
              key={I18n.t(`scene_web.${binarySensorSelected.title}`)}
              title={I18n.t(`scene_web.${binarySensorSelected.title}`)}
              description={sensorTypeDescription}
              position={binarySensorSelected.position}
              buttonName={I18n.t(`scene_web.${binarySensorSelected.buttonName}`)}
              isCardDisabled={binarySensorSelected.isCardDisabled}
              isButtonDisabled={binarySensorSelected.isButtonDisabled}
              onClick={() => {
                this.selectSensorType()
              }}
            />
            <CardBlock
              key={I18n.t(`scene_web.${binarySensorValueSelected.title}`)}
              title={I18n.t(`scene_web.${binarySensorValueSelected.title}`)}
              description={sensorEventDescription}
              position={binarySensorValueSelected.position}
              buttonName={I18n.t(`scene_web.${binarySensorValueSelected.buttonName}`)}
              isCardDisabled={binarySensorValueSelected.isCardDisabled}
              isButtonDisabled={binarySensorValueSelected.isButtonDisabled}
              onClick={() => {
                this.selectSensorValue()
              }}
            />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.addStatePressed()
            }}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectNotificationSensorStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sensorTypeDescription: PropTypes.string.isRequired,
  sensorEventDescription: PropTypes.string.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  sensorType: PropTypes.number.isRequired,
  sensorEventType: PropTypes.number.isRequired,
  activateChangeSensorMode: PropTypes.func.isRequired,
  activateChangeSensorStateMode: PropTypes.func.isRequired,
  deactivateChangeSensorMode: PropTypes.func.isRequired,
  deactivateChangeSensorStateMode: PropTypes.func.isRequired,
  restoreConditionInfo: PropTypes.func.isRequired,
  notificationSensors: PropTypes.arrayOf(
    PropTypes.shape({
      notification_name: PropTypes.string.isRequired,
      notification_type: PropTypes.number.isRequired,
    }),
  ),
}

SelectNotificationSensorStateSelected.defaultProps = {

  notificationSensors: [],
}

SelectNotificationSensorStateSelected.displayName = 'SelectNotificationSensorStateSelectedIOS'

export default SelectNotificationSensorStateSelected
