import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  Button, List, Paper, Typography,
} from '@mui/material'
import useStyles from '@app/components/Notifications/NotificationsSettings/NotificationsSettings.style'
import { checkIfArraysOrObjectsAreEqual } from '@app/helpers/checkIfArraysOrObjectsAreEqual'
import { set } from '@app/actions/notifications/notificationsSettings'
import Toggle from '@app/components/Common/Form/Toggle/Toggle'
import { useDispatch } from '@app/store'
import {
  mainEventsSettings, zoneEventsSettings, systemEventsSettings, mainEventsSettingsNCentral,
} from '@app/lib/NotificationsSettings/notificationsSettings'

interface Props {
  settingsValues: Record<string, boolean>,
  isMobile: boolean,
  isNCentral: boolean,
}

const NotificationsSettings: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { settingsValues, isMobile, isNCentral } = props
  const classes = useStyles()
  const [initialValues, setInitialValues] = useState<Record<string, boolean>>(settingsValues)
  const [currentValues, setCurrentValues] = useState<Record<string, boolean>>(settingsValues)
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
  const mainSettings = isNCentral ? mainEventsSettingsNCentral : mainEventsSettings

  useEffect(() => {
    if (checkIfArraysOrObjectsAreEqual(initialValues, currentValues)) {
      setIsFormChanged(false)
    } else {
      setIsFormChanged(true)
    }
  }, [currentValues])

  useEffect(() => {
    setCurrentValues(settingsValues)
    setInitialValues(settingsValues)
    setIsFormChanged(false)
  }, [settingsValues])

  const handleValuesChange = (fieldKey: string, fieldValue: boolean) => {
    setCurrentValues({ ...currentValues, [fieldKey]: fieldValue })
  }

  const getTitle = (title: string) => I18n.t(`notification_settings.${title}`)

  const setNotificationSettings = () => {
    dispatch(set(currentValues))
  }

  return (
    <Paper
      className={classNames(
        classes.root,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
    >
      <form className={classes.form}>
        <List className={classes.list}>
          {mainSettings.map((key) => (
            <Toggle
              key={key}
              name={key}
              title={getTitle(key)}
              value={settingsValues[key]}
              isMobile={isMobile}
              handleValuesChange={handleValuesChange}
            />
          )) }
        </List>
        <Typography>Alarm Zones/User Events</Typography>
        <List className={classes.list}>
          {zoneEventsSettings.map((key) => (
            <Toggle
              key={key}
              name={key}
              title={getTitle(key)}
              value={settingsValues[key]}
              isMobile={isMobile}
              handleValuesChange={handleValuesChange}
            />
          )) }
        </List>
        <Typography>System Events</Typography>
        <List className={classes.list}>
          {systemEventsSettings.map((key) => (
            <Toggle
              key={key}
              name={key}
              title={getTitle(key)}
              value={settingsValues[key]}
              isMobile={isMobile}
              handleValuesChange={handleValuesChange}
            />
          )) }
        </List>
        <Button
          onClick={setNotificationSettings}
          variant="contained"
          className={classNames(
            classes.button,
            (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
              && classes.webView,
          )}
          disabled={!isFormChanged}
        >
          {I18n.t('buttons.submit')}
        </Button>
      </form>
    </Paper>
  )
}

export default NotificationsSettings
