import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  type: PropTypes.string,
  icon: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.bool,
  update: PropTypes.string,
  signal_level: PropTypes.number,
  room: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  id: PropTypes.number,
})

export default propTypes
