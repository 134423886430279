export const libraries = ['places']
export const options = {
  strokeColor: 'rgba(0, 0, 255, 0.5)',
  strokeWeight: 2,
  fillColor: 'rgba(0, 0, 255, 0.3)',
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
}
export const circleSizeBounds = {
  min: 100,
  max: 9999,
}
export const defaultCoordinates = {
  lat: -33.8219067,
  lng: 150.9541388,
}
export const apiKey = 'AIzaSyAX8HCt8teGLIgx7AWiJY3PDHifmHb7V_s'

export const googleMapZoomToMeters = {
  20: 1128.497220,
  19: 2256.994440,
  18: 4513.988880,
  17: 9027.977761,
  16: 18055.955520,
  15: 36111.911040,
  14: 72223.822090,
  13: 144447.644200,
  12: 288895.288400,
  11: 577790.576700,
  10: 1155581.153000,
  9: 2311162.307000,
  8: 4622324.614000,
  7: 9244649.227000,
  6: 18489298.450000,
  5: 36978596.910000,
  4: 73957193.820000,
  3: 147914387.600000,
  2: 295828775.300000,
  1: 591657550.500000,
}

export const NEW_GEOFENCE_NAME_PATTERN = '[A-Za-z0-9- ]{1,16}'

export const maxAmountOfGeofences = 10
