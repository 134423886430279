import React from 'react'
import { Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import ZwaveDetailsCard
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/ZwaveDetailsAndParams/ZwaveDetailsCard'
import FailedNode
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/FailedNode/FailedNode'
import SecurityOptions
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/zwave/SecurityOptions/SecurityOptions'
import { sirenValue } from '@app/lib/MyDevices/sirenOptions'
import { withoutTakeOnBoard } from '@app/lib/MyDevices/takeOnBoardDeviceTypes'
import AdditionalModes
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/twr/AdditionalModes/AdditionalModes'
import SirenOptions
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/twr/SirenOptions/SirenOptions'
import TakeOnBoardDeviceTypes
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/twr/TakeOnBoardDeviceTypes/TakeOnBoardDeviceTypes'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './AdvancedSettings.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  deviceHealth: string,
  failedNodeStatus: string,
  twrCurrentSirenOption: number,
  currentTakeOnBoardDeviceType: number,
  isMobile: boolean,
}

const AdvancedSettings: React.FC<Props> = (props) => {
  const {
    currentDevice, deviceHealth, failedNodeStatus, twrCurrentSirenOption, currentTakeOnBoardDeviceType, isMobile,
  } = props
  const classes = useStyles()

  return (
    <>
      {currentDevice.device_type === DeviceTypes.zwaveDevice && (
        <Wrapper className={classes.advancedSettingsContainer}>
          <Typography className={classes.title}>{I18n.t('my_devices.advanced_settings')}</Typography>
          <Wrapper>
            <ZwaveDetailsCard
              currentDevice={currentDevice}
              isMobile={isMobile}
            />
            <FailedNode
              currentDevice={currentDevice}
              deviceHealth={deviceHealth}
              status={failedNodeStatus}
              isMobile={isMobile}
            />
            {(currentDevice.security_option?.possibility && currentDevice.interview_status === 'complete')
            && (
              <SecurityOptions
                currentDevice={currentDevice}
                isMobile={isMobile}
              />
            )}
          </Wrapper>
        </Wrapper>
      )}
      {currentDevice.device_type === DeviceTypes.twrDevice && (
        <Wrapper className={classes.advancedSettingsContainer}>
          <Typography className={classes.title}>{I18n.t('my_devices.advanced_settings')}</Typography>
          <AdditionalModes
            currentDevice={currentDevice}
            isMobile={isMobile}
          />
          {currentDevice.radio_siren_option !== sirenValue.WITHOUT_SIREN
          && (
            <SirenOptions
              currentDevice={currentDevice}
              twrCurrentSirenOption={twrCurrentSirenOption}
              isMobile={isMobile}
            />
          )}
          {currentDevice.take_on_board_device_type !== withoutTakeOnBoard
          && (
            <TakeOnBoardDeviceTypes
              currentDevice={currentDevice}
              currentTakeOnBoardDeviceType={currentTakeOnBoardDeviceType}
              isMobile={isMobile}
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default AdvancedSettings
