import update from 'immutability-helper'
import updateDevices from '@app/helpers/myDevices/updateDevicesViaActionCable'
import * as devicesChannel from '@app/constants/myDevices/channels/devices/devicesChannels'
import * as zwaveChannel from '@app/constants/myDevices/channels/zwave/zwaveChannels'
import * as twrChannel from '@app/constants/myDevices/channels/twr/twrChannels'
import * as devices from '@app/constants/myDevices/myDevices'
import * as zwave from '@app/constants/myDevices/zwaveDevices'
import * as twr from '@app/constants/myDevices/twrDevices'
import {
  DeviceTypes, IControl, IZwaveOrTwrDevice, TDevice,
} from '@app/@types/myDevices'
import * as DevicesChannelAction from '@app/actions/myDevices/channels/devices/devicesChannel'
import * as ZwaveChannelAction from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import * as TwrChannelAction from '@app/actions/myDevices/channels/twr/twrChannel'
import * as MyDevicesAction from '@app/actions/myDevices/myDevices'
import * as TwrDevicesAction from '@app/actions/myDevices/twrDevices'
import * as ZwaveDevicesAction from '@app/actions/myDevices/zwaveDevices'
import * as MezzoLightsAction from '@app/actions/myDevices/mezzoLights'
import * as DeleteChannelAction from '@app/actions/myDevices/channels/devices/deleteChannel'
import * as MyDevicesDeleteAction from '@app/actions/myDevices/myDevicesDelete'
import * as DevicesStatusChannelAction from '@app/actions/myDevices/channels/devices/devicesStatusChannel'
import * as DeviceInfoChannel from '@app/actions/myDevices/deviceInfo/deviceInfoChannel'
import * as ZwaveDeviceInfo from '@app/actions/myDevices/deviceInfo/fetchZwaveDeviceInfo'
import * as TwrDeviceInfo from '@app/actions/myDevices/deviceInfo/fetchTwrDeviceInfo'
import { Optional } from '@app/@types/common'
import { InferValueTypes } from '@app/@types/typesHelper'
import updateDeviceInfoViaActionCable from '@app/helpers/myDevices/updateDeviceInfoViaActionCable'

type MyDevicesActions = ReturnType<InferValueTypes<
  typeof DevicesChannelAction
  | typeof ZwaveChannelAction
  | typeof TwrChannelAction
  | typeof MyDevicesAction
  | typeof TwrDevicesAction
  | typeof ZwaveDevicesAction
  | typeof MezzoLightsAction
  | typeof DeleteChannelAction
  | typeof MyDevicesDeleteAction
  | typeof DevicesStatusChannelAction
  | typeof DeviceInfoChannel
  | typeof ZwaveDeviceInfo
  | typeof TwrDeviceInfo>>

type twrChannelAction = ReturnType<typeof TwrChannelAction.setResponseFromTwrChannel>

export interface IMyDevicesState {
  errorMessage: string,
  inProgress: boolean,
  isLoading: boolean,
  isInfoPopupOpen: boolean,
  fetchingDevicesFinished: boolean,
  interviewResult: string,
  reinterviewInProgress: boolean,
  deletingDeviceInProgress: boolean,
  deletingResult: string,
  deviceToAddInfo: Optional<IZwaveOrTwrDevice>,
  devices: Array<TDevice>,
  lastDevice: string,
  invalidTXIDError: boolean,
  twrOptions: Array<string>,
  currentDevice: Optional<IZwaveOrTwrDevice>,
  currentDeviceControl: Optional<IControl>,
  twrCurrentSirenOption: number,
  currentTakeOnBoardDeviceType: number,
  mezzoLights: number,
  isMezzoLightsLoading: boolean,
  fetchingDeviceStats: boolean,
  deviceInfoError: Optional<string>,
  deviceDetails: {
    currentDeviceDetails: Optional<Object>,
    error: string,
    sleepingDevicePopup: boolean,
    configurationPopup: {
      number: number | string,
      value: number | string,
      size: number | string,
      status: string,
    },
  },
}

const initialState = {
  errorMessage: '',
  inProgress: false,
  isLoading: false,
  isInfoPopupOpen: false,
  fetchingDevicesFinished: false,
  interviewResult: '',
  reinterviewInProgress: false,
  deletingDeviceInProgress: false,
  deletingResult: '',
  deviceToAddInfo: null,
  devices: [],
  lastDevice: '',
  invalidTXIDError: false,
  twrOptions: [],
  currentDevice: null,
  currentDeviceControl: null,
  twrCurrentSirenOption: 2,
  currentTakeOnBoardDeviceType: 3,
  mezzoLights: 0,
  isMezzoLightsLoading: false,
  fetchingDeviceStats: true,
  deviceInfoError: null,
  deviceDetails: {
    currentDeviceDetails: null,
    error: '',
    sleepingDevicePopup: false,
    configurationPopup: {
      number: '',
      value: '',
      size: '',
      status: '',
    },
  },
}

const getCurrentDeviceById = (state: IMyDevicesState, id: number) => state.devices.find(
  (device) => (device.device_type === DeviceTypes.zwaveDevice || device.device_type === DeviceTypes.twrDevice) && device.id === id,
)

const handleInvalidTXID = (state: IMyDevicesState, action: twrChannelAction): IMyDevicesState => (action.channelResponse === 'invalid_txid'
  ? {
    ...state,
    interviewResult: action.channelResponse,
    invalidTXIDError: true,
    isLoading: false,
  } : {
    ...state,
    interviewResult: action.channelResponse,
    isLoading: !state.isLoading,
    invalidTXIDError: false,
  })

const myDevices = (state: IMyDevicesState = initialState, action: MyDevicesActions): IMyDevicesState => {
  switch (action.type) {
  case devices.SET_MY_DEVICES: return {
    ...state,
    devices: action.devices,
  }
  case devices.SET_CURRENT_DEVICE: return {
    ...state,
    currentDevice: action.device,
  }
  case devices.SET_CURRENT_DEVICE_CONTROL: return {
    ...state,
    currentDeviceControl: action.control,
  }
  case devices.CLEAR_CURRENT_DEVICE: return {
    ...state,
    currentDevice: initialState.currentDevice,
  }
  case devices.UPDATE_MEZZO_LIGHTS: return {
    ...state,
    mezzoLights: action.value,
  }
  case devices.START_MEZZO_LIGHTS_LOADING: {
    return update(state, {
      isMezzoLightsLoading: { $set: true },
    })
  }
  case devices.END_MEZZO_LIGHTS_LOADING: {
    return update(state, {
      isMezzoLightsLoading: { $set: false },
    })
  }
  case devices.CLEAR_INTERVIEW_RESULT:
    return { ...state, interviewResult: '', isLoading: false }
  case devices.STOP_DELETING_PROGRESS:
    return { ...state, deletingDeviceInProgress: false }
  case devices.CLEAR_DELETING_RESULT:
    return { ...state, deletingResult: '', deletingDeviceInProgress: false }

  case zwave.SET_NEW_ZWAVE_DEVICE:
    return {
      ...state,
      deviceToAddInfo: action.newZwaveDevice,
    }
  case zwave.BREAK_FROM_REINTERVIEW: return {
    ...state,
    reinterviewInProgress: false,
  }

  case devicesChannel.UPDATE_DEVICES_CHANNEL: return {
    ...state,
    devices: action.devices,
  }
  case devicesChannel.UPDATE_DEVICES_STATUS:
    return updateDevices(state, action)
  case twrChannel.SET_RESPONSE_FROM_TWR_CHANNEL:
    return handleInvalidTXID(state, action)
  case devicesChannel.OPEN_DELETE_CHANNEL:
    return { ...state, deletingDeviceInProgress: true }
  case devicesChannel.SET_RESPONSE_FROM_DELETE_CHANNEL:
    return {
      ...state, deletingResult: action.channelResponse, deletingDeviceInProgress: true,
    }
  case zwaveChannel.SET_RESPONSE_FROM_ZWAVE_CHANNEL:
    return {
      ...state,
      interviewResult: action.channelResponse,
      isLoading: !state.isLoading,
    }

  case twr.SET_TWR_ALARM_OPTIONS:
    return { ...state, twrOptions: action.options, inProgress: false }

  case twr.SET_NEW_TWR_DEVICE:
    return {
      ...state,
      deviceToAddInfo: action.newTwrDevice,
    }
  case twr.SAVE_TWR_CURRENT_SIREN_OPTION:
    return { ...state, twrCurrentSirenOption: action.option, inProgress: false }
  case twr.SAVE_TAKE_ON_BOARD_DEVICE_TYPE:
    return {
      ...state, currentTakeOnBoardDeviceType: action.currentTakeOnBoardDeviceType, inProgress: false,
    }
  case zwave.SET_ZWAVE_DEVICE_INFO:
    return {
      ...state,
      // @ts-ignore
      devices: state.devices.map((device) => (device.device_type === DeviceTypes.zwaveDevice && device.id === action.id
        ? { ...getCurrentDeviceById(state, action.id), info: action.data } : device)),
    }
  case devicesChannel.UPDATE_DEVICE_INFO:
    // @ts-ignore
    return updateDeviceInfoViaActionCable(state, action.data)

  case devicesChannel.SET_DEVICE_INFO_ERROR:
    return { ...state, deviceInfoError: action.error }

  case devicesChannel.CLEAR_DEVICE_INFO_ERROR:
    return { ...state, deviceInfoError: initialState.deviceInfoError }

  case zwave.START_FETCH_DEVICE_STATS: {
    return update(state, {
      fetchingDeviceStats: { $set: true },
    })
  }
  case zwave.END_FETCH_DEVICE_STATS: {
    return update(state, {
      fetchingDeviceStats: { $set: false },
    })
  }
  case zwave.SET_ZWAVE_DEVICE_DETAILS: {
    return { ...state, deviceDetails: { ...state.deviceDetails, currentDeviceDetails: action.details } }
  }
  case zwave.RESET_ZWAVE_DEVICE_DETAILS: {
    return { ...state, deviceDetails: initialState.deviceDetails }
  }
  case zwave.SET_ZWAVE_DEVICE_DETAILS_ERROR: {
    return { ...state, deviceDetails: { ...state.deviceDetails, error: action.error } }
  }
  case zwave.RESET_ZWAVE_DEVICE_DETAILS_ERROR: {
    return { ...state, deviceDetails: { ...state.deviceDetails, error: initialState.deviceDetails.error } }
  }
  case zwave.SET_CONFIGURATION_POPUP_DATA: {
    return {
      ...state,
      deviceDetails: {
        ...state.deviceDetails,
        configurationPopup: {
          ...state.deviceDetails.configurationPopup,
          number: action.data.number,
          value: action.data.value,
          size: action.data.size,
        },
      },
    }
  }
  case zwave.SET_CONFIGURATION_POPUP_STATUS: {
    return {
      ...state,
      deviceDetails: {
        ...state.deviceDetails,
        configurationPopup: {
          ...state.deviceDetails.configurationPopup,
          status: action.status,
        },
      },
    }
  }
  case zwave.RESET_CONFIGURATION_POPUP_STATUS: {
    return {
      ...state,
      deviceDetails: {
        ...state.deviceDetails,
        configurationPopup: {
          ...state.deviceDetails.configurationPopup,
          status: initialState.deviceDetails.configurationPopup.status,
        },
      },
    }
  }
  case zwave.SET_IS_SLEEPING_DEVICE_POPUP_OPEN: {
    return { ...state, deviceDetails: { ...state.deviceDetails, sleepingDevicePopup: action.status } }
  }
  case devices.RESET_MY_DEVICES: {
    return initialState
  }

  default:
    return state
  }
}

export default myDevices
