import React from 'react'
import { Icon, ListItemIcon, Typography } from '@mui/material'
import { checkIconNumber } from '@app/helpers/myDevices/checkIconNumber'
import { devicesIcons } from '@app/lib/MyDevices/devicesIcons'
import { SELECT_TRIGGERING_DEVICE, SELECT_TRIGGERING_STATE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import {
  saveConditionStateDescription,
  saveObservingDeviceName,
  saveObservingDeviceNumber,
  saveTriggeringDeviceName,
  saveTriggeringDeviceNumber,
} from '@app/actions/scenes/currentSceneCreating'
import { ITwrDeviceBackend, IZwaveDeviceBackend } from '@app/@types/scenes/scenesDevices'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import useStyles from './Device.style'

interface Props {
  device: ITwrDeviceBackend | IZwaveDeviceBackend,
  deviceType: string,
}

const Device: React.FC<Props> = (props) => {
  const {
    device,
    deviceType,
  } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()

  const selectDevice = () => {
    if (deviceType === 'triggering') {
      dispatch(saveTriggeringDeviceNumber(device.number))
      dispatch(saveTriggeringDeviceName(device.name))
      navigate(SELECT_TRIGGERING_STATE)
    } else {
      dispatch(saveConditionStateDescription(`exceeded Timeout value, while observing ${device.name}`))
      dispatch(saveObservingDeviceNumber(device.number))
      dispatch(saveObservingDeviceName(device.name))
      navigate(SELECT_TRIGGERING_DEVICE)
    }
  }

  return (
    <Wrapper
      className={classes.device}
      onClick={() => {
        selectDevice()
      }}
    >
      <ListItemIcon className={classes.iconWrapper}>
        <Icon fontSize="large" className={classes.icon}>{devicesIcons[checkIconNumber(device.icon)]}</Icon>
      </ListItemIcon>
      <Typography className={classes.title} variant="h6" component="h2">
        {device.name}
      </Typography>
    </Wrapper>
  )
}

export default Device
