const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  card: {
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '3% 10%',
    marginBottom: 22,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: 100,
  },
  blockTitle: {
    color: 'rgba(229, 229, 229, 0.67)',
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  webView: {
    background: theme.webView.background,
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
  },
  group: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  allGroup: {
    flexWrap: 'nowrap',
  },
  allBulbsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.scenes.disabledElement,
  },
  bulbsWrapperTop: {
    color: 'inherit',
    '&:disabled': {
      color: theme.palette.primary.main,
    },
  },
  bulbsWrapperTopLabel: {
    display: 'flex',
    alignItems: 'center',
    width: 110,
  },
  bulbsWrapperBottom: {
    display: 'flex',
    alignItems: 'center',
    width: 160,
    color: 'inherit',
    '&:disabled': {
      color: theme.palette.primary.main,
    },
  },
  buttonBulbs: {
    textDecoration: 'underline',
    color: 'inherit',
    margin: '0 10px',
  },

})

export default styles
