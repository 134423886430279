import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { actionDeviceTypes, actionDeviceTypesNCentral } from '@app/lib/Scenes/actionDeviceTypes'
import {
  SELECT_ACTION_DEVICE_TABS,
  SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE,
  SELECT_ACTION_TYPE,
  SELECT_GROUP_ACTION,
  SELECT_ROOM_ACTION,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import { saveActionDeviceType } from '@app/actions/scenes/currentSceneCreating'
import { useDispatch } from '@app/store'
import useStyles from './SelectActionDeviceType.style'

interface Props {
  isMobile: boolean,
  isNCentral: boolean,
}

const SelectActionDeviceType: React.FC<Props> = (props) => {
  const { isMobile, isNCentral } = props

  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const deviceList = isNCentral ? actionDeviceTypesNCentral : actionDeviceTypes

  const onBackClick = () => {
    navigate(SELECT_ACTION_TYPE)
  }

  const onUseClick = (type: string) => {
    if (type === 'mezzo') {
      navigate(SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE)
    } else if (type === 'ZWaveDevices') {
      dispatch(saveActionDeviceType(type))
      navigate(SELECT_ACTION_DEVICE_TABS)
    } else if (type === 'Room') {
      navigate(SELECT_ROOM_ACTION)
    } else if (type === 'Group') {
      navigate(SELECT_GROUP_ACTION)
    } else {
      throw new Error('action unsupported')
    }
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_web.select_device_room_group')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        {
          deviceList.map((type) => (
            <CardBlock
              key={type.title}
              title={I18n.t(`scene_web.${type.title}`)}
              description={I18n.t(`scene_web.${type.description}`)}
              position={type.position}
              buttonName={I18n.t(`scene_web.${type.buttonName}`)}
              isCardDisabled={type.isCardDisabled}
              isButtonDisabled={type.isButtonDisabled}
              onClick={() => {
                onUseClick(type.type)
              }}
            />
          ))
        }
      </Wrapper>
    </Paper>
  )
}

export default SelectActionDeviceType
