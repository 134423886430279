import * as type from '@app/constants/myDevices/channels/devices/devicesChannels'
import { TDevice } from '@app/@types/myDevices'

export const syncDevicesChannel = () => ({
  type: type.SYNC_DEVICES_CHANNEL,
} as const)

export const syncDevicesChannelSent = () => ({
  type: type.SYNC_DEVICES_CHANNEL_SENT,
} as const)

export const openDevicesChannel = () => ({
  type: type.OPEN_DEVICES_CHANNEL,
} as const)

export const closeDevicesChannel = () => ({
  type: type.CLOSE_DEVICES_CHANNEL,
} as const)

export const updateDevices = (devices: Array<TDevice>) => ({
  type: type.UPDATE_DEVICES_CHANNEL,
  devices,
} as const)
