import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="47.1689" y="42.0847" width="2.05927" height="51.8611" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41 8V98.2981H109V8H41ZM51.4102 96.1605H44.9783V39.875H51.4102V96.1605Z" fill="currentColor"/>
        <rect x="47.1689" y="106.288" width="2.05927" height="28.5329" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41 100.082V141.002H109V100.038L41 100.082ZM51.4102 137.046H44.9783V104.08H51.4102V137.046Z" fill="currentColor"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwFridgeIcon')

export default icon
