import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import styles from './Info.style'

class Info extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      title,
      warningText,
      buttonName,
      onClick,
      disableButton,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open={open}
      >
        <DialogTitle
          className={classes.title}
        >
          {I18n.t(`${title}`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="body1"
            className={classes.text}
          >
            {I18n.t(`${warningText}`)}
          </DialogContentText>
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            className={classes.button}
            onClick={onClick}
            disabled={disableButton}
          >
            {I18n.t(`${buttonName}`)}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

Info.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  warningText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  disableButton: PropTypes.bool,
}

Info.defaultProps = {
  disableButton: false,
}

export default withStyles(styles)(Info)
