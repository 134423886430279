import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.77778 3.77778H3.27778V4.27778V6.94444V7.44444H3.77778H16.2222H16.7222V6.94444V4.27778V3.77778H16.2222H3.77778ZM16.2222 10C12.4794 10 9.5 12.9794 9.5 16.7222C9.5 17.1522 9.53946 17.5866 9.62056 18H3.77778C3.07614 18 2.5 17.4239 2.5 16.7222V4.27778C2.5 3.57614 3.07614 3 3.77778 3H16.2222C16.9239 3 17.5 3.57614 17.5 4.27778V10.1206C17.0866 10.0395 16.6522 10 16.2222 10Z" stroke="currentColor"/>
      <path d="M15.35 11.5C13.9151 11.5 12.7 12.7222 12.7 14.1697C12.7 15.6857 14.1085 17.236 15.056 18.3257C15.0597 18.33 15.2128 18.5 15.4014 18.5H15.418C15.6068 18.5 15.7588 18.33 15.7625 18.3257C16.6516 17.3036 17.9501 15.6181 17.9501 14.1697C17.9501 12.722 17.0022 11.5 15.35 11.5ZM15.4377 18.0321C15.43 18.0397 15.4189 18.0483 15.409 18.0555C15.399 18.0485 15.388 18.0397 15.3799 18.0321L15.2655 17.9004C14.3673 16.87 13.1372 15.4588 13.1372 14.1695C13.1372 12.9593 14.1505 11.9371 15.3498 11.9371C16.8436 11.9371 17.5124 13.0582 17.5124 14.1695C17.5124 15.1482 16.8143 16.4481 15.4377 18.0321ZM15.3327 12.8246C14.6079 12.8246 14.0201 13.4122 14.0201 14.1371C14.0201 14.8621 14.6079 15.4497 15.3327 15.4497C16.0574 15.4497 16.6452 14.8619 16.6452 14.1371C16.6452 13.4124 16.0576 12.8246 15.3327 12.8246ZM15.3327 15.0121C14.8501 15.0121 14.4476 14.6101 14.4476 14.1275C14.4476 13.6449 14.84 13.2525 15.3226 13.2525C15.8054 13.2525 16.1977 13.6449 16.1977 14.1275C16.1979 14.6101 15.8155 15.0121 15.3327 15.0121Z" fill="#8BC34A"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DeviceLocationIcon')

export default icon
