import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.backgrounds.navigation,
    height: 77,
    padding: '0 40px',
  },
  navigationMobile: {
    padding: '0 10px',
  },
  navigationLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  goBackIcon: {
    marginRight: 44,
  },
  goBackIconMobile: {
    marginRight: 22,
  },
  navigationRight: {
    display: 'flex',
    alignItems: 'center',
  },
  reload: {
    marginRight: 15,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      scale: '1.15',
    },
  },
  setter: {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      scale: '1.15',
    },
  },
}))

export default useStyles
