import type { Node } from 'react'
import React, { useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from '@app/components/PropTypes'
import {
  ListItem, ListItemText, Typography,
} from '@mui/material'
import { ReminderIcon } from 'icons'
import { useSelector } from 'react-redux'
import useStyles from './ReminderCard.style'
import RemindersMenu from './RemindersMenu/RemindersMenu'

const ReminderCard = ({ reminder, onDelete, onEdit }): Node => {
  const classes = useStyles()
  const [dateFormat] = useState('DD/MM/YYYY')
  const { isMobile } = useSelector((state) => state.mobileDetector)

  const description = () => {
    const startOn = moment(reminder.start_on)
    const from = startOn.format(dateFormat)
    const days = reminder.finish_after_days === '' ? 0 : parseInt(reminder.finish_after_days, 10)
    const to = startOn.add(days, I18n.t('my_reminders.days')).format(dateFormat)
    const toOrInfinity = days === 0 ? I18n.t('my_reminders.continous_item') : to
    return `from ${from} to ${toOrInfinity}`
  }

  const handleDelete = () => {
    onDelete(reminder.id)
  }

  const handleEdit = () => {
    onEdit(reminder)
  }

  return (
    <ListItem className={classNames(isMobile ? classes.cardMobile : classes.card,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
            && classes.webView)}
    >
      <ReminderIcon className={classes.icon} />
      <ListItemText
        disableTypography
        primary={(
          <Typography
            className={classNames(
              isMobile ? classes.cardTitleMobile : classes.cardTitle,
            )}
          >
            {reminder.name}
          </Typography>
        )}
        secondary={(
          <Typography
            className={classNames(
              isMobile ? classes.cardDescriptionMobile : classes.cardDescription,
            )}
          >
            {description()}
          </Typography>
        )}
      />
      <RemindersMenu
        id={reminder.id}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </ListItem>
  )
}

ReminderCard.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  reminder: PropTypes.reminder.isRequired,
}

export default ReminderCard
