import React, { useState, useEffect } from 'react'
import { Typography, IconButton } from '@mui/material'
import { withTheme } from '@mui/styles'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import { COOKIE_NAME } from '@app/constants/signOut'
import { EVENTS, HOME } from '@app/constants/routes'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
import { useNavigate } from 'react-router-dom'
// @ts-ignore
import cardsData from '@app/lib/Home/CardsData'
import ArmDisarmBlock from '@app/components/MySecurity/ButtonsBlock/ArmDisarmBlock'
import { ChevronRight, SealedSecurityIcon, UnSealedSecurityIcon } from 'icons'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import { ITwrDevice } from '@app/@types/myStatus'
// @ts-ignore
import { iconMap } from '@app/lib/Events/icons'
import { IHomeConfig } from '@app/@types/appConfig'
import { getNotificationForStatusBar } from '@app/helpers/notifications/getNotificationForStatusBar'
import MobileApplicationsUrls from './MobileApplicationsUrls/MobileApplicationsUrls'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './Home.style'

interface Props {
  userLoggedIn: boolean,
  isMobile: boolean,
  isTablet: boolean,
  withUnitDevice: string,
  theme: any,
  homeConfig: IHomeConfig,
  syncMyStatus: () => void,
  isActivationInfoPopupOpened: boolean,
  closeActivationInfoPopup: () => void,
  userEmail: string,
}

const Home: React.FC<Props> = (props) => {
  const {
    userLoggedIn, isMobile, isTablet, withUnitDevice, theme, homeConfig, syncMyStatus,
    isActivationInfoPopupOpened, closeActivationInfoPopup, userEmail,
  } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { notifications } = useSelector((state: AppState) => state.notifications.notifications)
  const { events } = useSelector((state: AppState) => state.events)
  const { twrDevices } = useSelector((state: AppState) => state.myStatus.myStatusDevices)
  const selectedSerialnumber = useSelector((state: AppState) => state.unit.selectedUnit)
  const isUnSealed = twrDevices.some((device: ITwrDevice) => !device.status)
  const currentNotification = getNotificationForStatusBar(notifications, selectedSerialnumber)
  const cards = homeConfig.items ? homeConfig.items : defaultConfig.home.items
  const elements = homeConfig.elements ? homeConfig.elements : defaultConfig.home.elements
  const event = events.length ? events[0] : null
  const [currentEvent, setCurrentEvent] = useState(currentNotification || event)
  const [cookies, removeCookie] = useCookies([COOKIE_NAME])

  useEffect(() => {
    if (cookies) {
      removeCookie(COOKIE_NAME, { path: '/' })
    }
    if (userLoggedIn) {
      return navigate(HOME)
    }
    return undefined
  }, [navigate, userLoggedIn])

  useEffect(() => {
    const newNotification = getNotificationForStatusBar(notifications, selectedSerialnumber)
    const newEvent = events.length ? events[0] : null
    setCurrentEvent(newNotification || newEvent)
  }, [events, notifications])

  useEffect(() => {
    syncMyStatus()
  }, [])

  const getIcon = (code: string) => {
    const IconComponent = cardsData[code].icon
    return (
      <IconComponent className={classNames(
        classes.icon,
        isMobile && classes.iconMobile,
        isTablet && classes.iconTablet,
      )}
      />
    )
  }

  const renderCard = (item: any) => (item.state !== States.Invisible) && (
    <Wrapper
      key={item.code}
      onClick={() => (item.state !== States.Disabled ? navigate(cardsData[item.code].path) : null)}
      className={classNames(classes.card,
        isMobile && classes.cardMobile,
        item.state === States.Disabled && classes.cardDisabled)}
    >
      {getIcon(item.code)}
      <Typography className={classNames(
        classes.cardLabel,
        isMobile && classes.cardLabelMobile,
        isTablet && classes.cardLabelTablet,
      )}
      >
        {item.code}
      </Typography>
    </Wrapper>
  )

  const renderWithMezzoHome = () => (
    <>
      <Wrapper className={classNames(classes.elementsWrapper, isMobile && classes.elementsWrapperMobile)}>
        {elements.events_panel !== States.Invisible && (
          <Wrapper className={classNames(classes.topElement, isMobile && classes.topElementMobile)}>
            <Wrapper className={classNames(classes.eventWindow, isMobile && classes.eventWindowMobile)}>
              {currentEvent ? (
                <Wrapper className={classes.eventMessageWrapper}>
                  {isUnSealed
                    ? <UnSealedSecurityIcon className={classes.unSealedIcon} />
                    : <SealedSecurityIcon className={classes.sealedIcon} />}
                  <Typography className={classes.eventMessage}>{'message' in currentEvent && currentEvent.message}</Typography>
                  <Wrapper className={classes.eventIcon}>
                    {'icon' in currentEvent && iconMap[currentEvent.icon]}
                  </Wrapper>
                </Wrapper>
              ) : (<Typography>no events</Typography>)}
              <IconButton
                onClick={() => navigate(EVENTS)}
                size="large"
              >
                <ChevronRight className={classes.arrowIconInner} />
              </IconButton>
            </Wrapper>
          </Wrapper>
        )}
        {elements.arm_disarm_panel !== States.Invisible && (
          <Wrapper className={classNames(classes.topElement, isMobile && classes.topElementMobile)}>
            <Wrapper className={classNames(classes.armBlockWrapper, isMobile && classes.armBlockWrapperMobile)}>
              <Wrapper className={classNames(classes.armButtonsWrapper, isMobile && classes.armButtonsWrapperMobile)}>
                <ArmDisarmBlock type={2} disabled={elements.arm_disarm_panel === States.Disabled} />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        )}
      </Wrapper>
      <Wrapper className={classNames(classes.cardContainer, isMobile && classes.cardContainerMobile)}>
        {cards.map((item) => renderCard(item))}
      </Wrapper>
    </>
  )

  const renderMain = () => {
    switch (withUnitDevice) {
    case 'activatedMezzo':
      return renderWithMezzoHome()
    case 'withoutMezzo':
      return (
        <MobileApplicationsUrls
          theme={theme}
          isActivationInfoPopupOpened={isActivationInfoPopupOpened}
          closeActivationInfoPopup={closeActivationInfoPopup}
          userEmail={userEmail}
        />
      )
    case 'notActivatedMezzo':
      return (<Typography variant="h6" className={classes.notActivated}>{I18n.t('wizard_web.mezzo_not_activated')}</Typography>)
    default:
      return null
    }
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      {renderMain()}
    </Wrapper>
  )
}

export default withTheme(Home)
