import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 40">
      <path fill="currentColor" d="M33.63 3.443A41.507 41.507 0 0017.03 0C11.303 0 5.731 1.152.472 3.425A.781.781 0 000 4.142v4.92a.781.781 0 001.563 0V4.66a40.098 40.098 0 0111.42-2.88v2.2a37.99 37.99 0 00-8.714 1.98.781.781 0 00-.519.735V18.92c.137 5.952.245 10.652 12.938 16.85a.781.781 0 00.686 0c12.694-6.198 12.802-10.898 12.938-16.85V6.694a.781.781 0 00-.519-.736 37.988 37.988 0 00-8.713-1.98V1.78A40.098 40.098 0 0132.5 4.66v14.276c-.084 3.646-.304 6.676-2.249 9.712-2.205 3.441-6.41 6.531-13.22 9.71-6.81-3.179-11.015-6.269-13.22-9.71-1.945-3.036-2.165-6.066-2.248-9.712v-3.194a.781.781 0 00-1.563 0V18.963c.086 3.764.334 7.154 2.495 10.527 2.409 3.759 6.924 7.076 14.209 10.438a.781.781 0 00.654 0c7.286-3.362 11.8-6.679 14.209-10.437 2.162-3.374 2.409-6.764 2.495-10.528V4.26c0-.18 0-.6-.431-.817zM14.128 15.241a.778.778 0 00.418-.772V1.653c.75-.046 1.5-.07 2.25-.075v5.925a.781.781 0 101.563 0V1.599c.386.013.772.03 1.158.054V14.54a.78.78 0 00.426.696 6.374 6.374 0 013.495 5.701 6.388 6.388 0 01-2.813 5.301v-4.547a.781.781 0 00-.38-.67l-2.812-1.688a.781.781 0 00-.804 0l-2.812 1.688a.781.781 0 00-.38.67v4.533a6.391 6.391 0 01-2.792-5.287 6.376 6.376 0 013.483-5.696zm14.622-7.99v11.642c-.065 2.82-.122 5.255-1.595 7.644-1.602 2.598-4.823 5.037-10.124 7.66-5.3-2.623-8.522-5.062-10.123-7.66-1.473-2.39-1.53-4.825-1.596-7.644V7.25a36.45 36.45 0 017.67-1.7v8.54a7.928 7.928 0 00-3.9 6.847 7.953 7.953 0 004.83 7.32.781.781 0 001.088-.719v-5.405l2.031-1.219 2.032 1.219v5.414a.782.782 0 001.086.72A7.95 7.95 0 0025 20.936c0-2.845-1.49-5.43-3.92-6.86V5.552c2.617.29 5.19.86 7.67 1.7z"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'VoiceAssistantIcon')

export default icon
