import React from 'react'
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './RenderRadioGroup.style'

interface Props {
  index: number,
  condition: IConditionFromBackend,
  changeRadio: (event: React.ChangeEvent<HTMLInputElement>, condition: IConditionFromBackend, index: number) => void,
}

const RenderRadioGroup: React.FC<Props> = (props) => {
  const {
    index,
    condition,
    changeRadio,
  } = props

  const classes = useStyles()
  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup
        row
        aria-label="operands"
        name="operands"
        className={classes.group}
        value={condition.operand}
        onChange={(event) => {
          changeRadio(event, condition, index)
        }}
      >
        <FormControlLabel value="and" control={<Radio color="primary" />} label="and" />
        <FormControlLabel value="or" control={<Radio color="primary" />} label="or" />
      </RadioGroup>
    </FormControl>
  )
}

export default RenderRadioGroup
