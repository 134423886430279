import * as type from '@app/constants/wizard/forgotPassword'

export const saveEmail = (email) => ({
  type: type.SAVE_EMAIL,
  email,
})
export const clearEmail = () => ({
  type: type.CLEAR_EMAIL,
})

export const openWrongEmailPopup = () => ({
  type: type.OPEN_WRONG_EMAIL_POPUP,
})

export const closeWrongEmailPopup = () => ({
  type: type.CLOSE_WRONG_EMAIL_POPUP,
})

export const saveCode = (code) => ({
  type: type.SAVE_CODE,
  code,
})
export const clearCode = () => ({
  type: type.CLEAR_CODE,
})

export const showError = () => ({
  type: type.SHOW_ERROR,
})
export const hideError = () => ({
  type: type.HIDE_ERROR,
})

export const postEmail = () => ({
  type: type.POST_EMAIL,
})

export const postCode = () => ({
  type: type.POST_CODE,
})

export const saveRequestId = (requestId) => ({
  type: type.SAVE_REQUEST_ID,
  requestId,
})
export const clearRequestId = () => ({
  type: type.CLEAR_REQUEST_ID,
})

export const openWrongCodePopup = () => ({
  type: type.OPEN_WRONG_CODE_POPUP,
})

export const closeWrongCodePopup = () => ({
  type: type.CLOSE_WRONG_CODE_POPUP,
})

export const postNewPassword = () => ({
  type: type.POST_NEW_PASSWORD,
})
