import { createLogic } from 'redux-logic'
import { CLOSE_FAILED_NODE_CHANNEL } from '@app/constants/myDevices/channels/deviceSettings/failedNode'
import { FAILED_NODE_CHANNEL } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeFailedNodeLogic = createLogic({
  type: CLOSE_FAILED_NODE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(FAILED_NODE_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeFailedNodeLogic
