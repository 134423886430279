import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 50">
      <path fill="currentColor" d="M21.24 24.03a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.24-1.15c1 0 1.99-.29 2.77-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.99 3.99 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.13.1.37.37 0 0 0-.07.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M34.79 14.77V8.02c0-.29-.19-.51-.32-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.74 20.74 0 0 0 25.39 5c-2.46 0-4.74.38-6.42.93a8.1 8.1 0 0 0-2.06.96c-.48.34-.91.8-.91 1.37v6.5c0 .65.47 1.22 1.03 1.68.59.47 1.39.92 2.3 1.31.83.36 1.78.68 2.75.91.13.54.56.97 1.06 1.24.61.34 1.43.54 2.3.54.87 0 1.69-.2 2.3-.54.5-.28.94-.7 1.06-1.25a17.4 17.4 0 0 0 2.67-.88 9.7 9.7 0 0 0 2.29-1.32c.56-.46 1.03-1.03 1.03-1.68Zm-5.97 3.4.68-.2c.04-.13.06-.27.06-.41 0-1.32-1.84-2.4-4.12-2.4-2.28 0-4.12 1.08-4.12 2.4 0 .15.02.3.07.44h-.01l.68.18c.07-.61.54-1.09 1.08-1.39a4.81 4.81 0 0 1 2.3-.54c.87 0 1.69.2 2.3.54.54.3 1 .77 1.08 1.37Zm-7.96-.34c-2.41-.79-4.4-2.02-4.4-3.06v-2.44a18.5 18.5 0 0 1 8.84-2.32c4.6 0 8.19 1.85 9.02 2.32v2.44c0 1.05-1.93 2.26-4.3 3.04.02-.08.02-.17.02-.25 0-.92-.63-1.65-1.45-2.12a6.39 6.39 0 0 0-3.15-.77c-1.2 0-2.32.29-3.15.77-.82.47-1.45 1.2-1.45 2.12 0 .09 0 .18.02.27Zm-4.4-9.57c0-1.3 4.1-2.8 8.93-2.8 4.84 0 8.93 1.82 8.93 2.56v1.52a18.1 18.1 0 0 0-9.02-2.3c-4.43 0-8 1.84-8.84 2.31v-1.3Zm0 1.93v1.5a19.1 19.1 0 0 1 8.84-2.32 18.1 18.1 0 0 1 9.02 2.31V10.2a19.26 19.26 0 0 0-9.02-2.32 18.38 18.38 0 0 0-8.84 2.32Zm11.91 8.16c0 .9-1.31 1.62-2.93 1.62s-2.93-.72-2.93-1.62c0-.9 1.31-1.63 2.93-1.63s2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M24.84 37.27h-.95a.28.28 0 1 0 0 .55h.95a.28.28 0 1 0 0-.55Z"/>
      <path fill="currentColor" d="M30.5 29.74h-4.1v-.52a.28.28 0 0 0-.34-.27l-5.54 1.48a.28.28 0 0 0-.2.26v.9a.28.28 0 1 0 .55 0v-.68l4.99-1.33v14.8l-4.99-1V32.53a.28.28 0 1 0-.55 0v11.1c0 .13.09.24.22.27l5.54 1.1a.28.28 0 0 0 .33-.27v-.52h2.55a.28.28 0 1 0 0-.56h-2.55v-.79h2.74c.15 0 .28-.12.28-.28v-11.2a.28.28 0 0 0-.28-.28h-2.74v-.8h3.81v13.35h-.4a.28.28 0 1 0 0 .56h.68c.15 0 .27-.13.27-.28v-13.9a.28.28 0 0 0-.27-.28Zm-1.63 1.9V42.3h-2.46V31.64h2.46Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DoorIcon', '0 0 80 80', {fontSize: 35})

export default icon
