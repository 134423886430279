import PO from './programOptions'
import aboutNumbers from './aboutNumbers'

const strings = [
  {
    number: PO.SENSITIVITY_MEZZO_UNIT_TAMPER_1335,
    values: [
      '1335_sensitivity_mezzo_off',
      '1335_sensitivity_mezzo_low',
      '1335_sensitivity_mezzo_mid',
      '1335_sensitivity_mezzo_high'],
  },
  {
    number: PO.OPEN_CLOSE_1306,
    values: [
      '1306_open_close_reports_not_enabled',
      '1306_open_close_reports_enabled',
      '1306_restore_reports_enabled',
      '1306_open_close_restore_reports_enabled'],
  },

  {
    number: PO.LOW_BATTERY_1307,
    values: [
      '1307_not_report',
      '1307_main_unit_battery_low',
      '1307_device_battery_low',
      '1307_any_battery_low'],
  },
  {
    number: PO.TEST_CALL_1310,
    values: [
      '1310_off',
      '1310_1_day',
      '1310_7_days',
      '1310_14_days',
      '1310_30_days'],
  },
  {
    number: PO.MAIN_VOLUME_1312,
    values: [
      '1312_main_volume_low',
      '1312_main_volume_medium',
      '1312_main_volume_high'],
    startValue: 1,
  },
  {
    number: PO.DOOR_BELL_VOLUME_1314,
    values: [
      '1314_door_bell_volume_low',
      '1314_door_bell_volume_medium',
      '1314_door_bell_volume_high'],
    startValue: 1,
  },
  {
    number: PO.LIGHTS_1315,
    values: [
      '1315_lights_off',
      '1315_lights_low',
      '1315_lights_medium',
      '1315_lights_high'],
  },
  {
    number: PO.ROUTER_MODE_1320,
    values: [
      '1320_router_mode_ap',
      '1320_router_mode_ap_client',
      '1320_router_mode_wifi_client'],
  },
  {
    number: PO.ROUTER_SECURITY_1334,
    values: [
      '1334_home_router_security_wpa_tkip',
      '1334_home_router_security_wpa_aes',
      '1334_home_router_security_wpa2_tkip',
      '1334_home_router_security_wpa2_aes',
      '1334_home_router_security_wpawpa2_tkip',
      '1334_home_router_security_wpawpa2_aes',
      '1334_home_router_security_none',
      '1334_home_router_security_wep_open',
      '1334_home_router_security_wep',
      '1334_home_router_security_auto'],
  },
  {
    number: PO.LOW_POWER_OPERATION_1342,
    values: [
      '1342_low_power_title_balanced',
      '1342_low_power_title_full',
      '1342_low_power_title_maximum',
      '1342_low_power_title_medium',
    ],
  },
  {
    number: PO.CLOUD_CONNECTION_1347,
    values: [
      '1347_cloud_server_title_auto_udp',
      '1347_cloud_server_title_ethernet_udp',
      '1347_cloud_server_title_cellular_udp',
      '1347_cloud_server_title_none',
      '1347_cloud_server_title_auto_tcp',
      '1347_cloud_server_title_ethernet_tcp',
      '1347_cloud_server_title_cellular_tcp'],
  },
  {
    number: PO.CLOCK_MODE_1336,
    values: [
      '1336_mezzo_clock_mode_title_0',
      '1336_mezzo_clock_mode_title_1',
    ],
  },
  {
    number: PO.CID_DIALLER_1311,
    values: [
      '1311_cellular_on_dialler_disabled',
      '1311_cellular_on_armor_ip_report',
      '1311_cellular_on_csv_ip_report',
      '1311_cellular_on_cid_report',
      '1311_cellular_on_sms_report',
      '1311_cellular_on_audio_report',
      '1311_cellular_off',
    ],
  },
  {
    number: PO.ARMOR_IP_POLL_TIME_1357,
    values: [
      '1357_armor_api_poll_time_disabled',
      '1357_armor_api_poll_time_every_one_hour',
      '1357_armor_api_poll_time_every_two_hours',
      '1357_armor_api_poll_time_every_four_hours',
      '1357_armor_api_poll_time_every_eight_hours',
      '1357_armor_api_poll_time_every_twenty_four_hours',
    ],
  },
  {
    number: PO.IP_REPORTING_TCP_UPD_1360,
    values: [
      '1360_armor_ip_csv_udp',
      '1360_armor_ip_csv_tdp',
    ],
  },
  {
    number: PO.SUPERVISION_TIME_1302,
    values: [
      '1302_supervision_time_off',
      '1302_supervision_time_on',
    ],
  },
  {
    number: PO.PIR_EVENT_1372,
    values: [
      '1372_pir_event_off',
      '1372_pir_event_on',
    ],
  },
  {
    number: PO.LOCK_OUT_1303,
    values: [
      '1372_lock_out_off',
      '1372_lock_out_on',
    ],
  },
  {
    number: PO.EOL_RESISTOR_VALUES_1373,
    values: [
      '1373_2k2_resistor',
      '1373_0k_resistor',
      '1373_1k_resistor',
      '1373_1k5_resistor',
      '1373_3k3_resistor',
      '1373_3k9_resistor',
      '1373_4k7_resistor',
      '1373_5k6_resistor',
      '1373_6k8_resistor',
      '1373_8k2_resistor',
      '1373_10k_resistor',
      '1373_12k_resistor',
      '1373_22k_resistor'],
  },
]

const switches = [
  PO.VOICE_ON_ARM_1304,
  PO.VOICE_ON_ALARM_1305,
  PO.DOOR_BELL_1313,
  PO.DISABLE_ALL_VOICE_1325,
  PO.CELLULAR_ANTENNA_1339,
]

const texts = [
  ...aboutNumbers,
  PO.MEZZO_SSID_1316,
  PO.GSM_SIGNAL_1231,
  PO.GET_ERROR_CODE_1242,
  PO.ROUTER_MODE_1321,
  PO.SECURENET_PORT_1322,
  PO.ROUTER_DEFAULT_SSID_1345,
  PO.ROUTER_DEFAULT_WIFI_PASSWORD_1346,
]

const inputs = [
  { number: PO.CLIENT_ACCOUNT_1220, hint: true },
  { number: PO.CSV_REPORTING_USERNAME_1361, hint: true },
  { number: PO.CSV_REPORTING_PASSWORD_1362, hint: true },
  { number: PO.PRIMARY_ARMOR_DOMAIN_1363, hint: true },
  { number: PO.SECONDARY_ARMOR_DOMAIN_1364, hint: true },
  { number: PO.ARMOR_IP_1343, hint: true },
  { number: PO.ARMOR_PORT_1344, hint: true },
  { number: PO.SECONDARY_ARMOR_IP_1353, hint: true },
  { number: PO.SECONDARY_ARMOR_PORT_1354, hint: true },
  { number: PO.APN_1326, hint: false },
  { number: PO.MEZZO_PASSWORD_1317, hint: false },
  { number: PO.CUSTOMER_1323, hint: false },
  { number: PO.ENTRY_DELAY_1300, hint: true },
  { number: PO.EXIT_DELAY_1301, hint: true },
  { number: PO.ABORT_TIME_1308, hint: true },
  { number: PO.MEDICAL_ABORT_TIME_1309, hint: true },
  { number: PO.INACTIVITY_REPORTING_1351, hint: true },
  { number: PO.NUMBER_OF_ZONES_1375, hint: true },
]

export default {
  strings,
  switches,
  texts,
  inputs,
}
