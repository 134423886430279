import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3655 6.02679C11.7963 6.02679 12.1764 6.20089 12.5058 6.54911C12.8353 6.87054 13 7.25893 13 7.71429V16.3125C13 16.7679 12.8353 17.1696 12.5058 17.5179C12.1764 17.8393 11.7963 18 11.3655 18H1.6345C1.2037 18 0.823587 17.8393 0.494152 17.5179C0.164717 17.1696 0 16.7679 0 16.3125V7.71429C0 7.25893 0.164717 6.87054 0.494152 6.54911C0.823587 6.20089 1.2037 6.02679 1.6345 6.02679H9.00877V4.29911C9.00877 3.57589 8.75536 2.95982 8.24854 2.45089C7.76706 1.91518 7.18421 1.64732 6.5 1.64732C5.81579 1.64732 5.22027 1.91518 4.71345 2.45089C4.23197 2.95982 3.99123 3.57589 3.99123 4.29911H2.43275C2.43275 3.12054 2.82554 2.11607 3.61111 1.28571C4.42203 0.428571 5.38499 0 6.5 0C7.61501 0 8.5653 0.428571 9.35088 1.28571C10.1618 2.11607 10.5673 3.12054 10.5673 4.29911V6.02679H11.3655ZM8.9834 14.9834V13.5311L10 12.5L8.9834 11.4689V10.0166H7.53112L6.5 9L5.46888 10.0166H4.0166V11.4689L3 12.5L4.0166 13.5311V14.9834H5.46888L6.5 16L7.53112 14.9834H8.9834Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'DisarmedDayIcon')

export default icon
