import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { BackToLoginIcon } from 'icons'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './NavigationAndTitles.style'

class NavigationAndTitles extends React.Component {
  render() {
    const {
      classes,
      isMobile,
      firstTitle,
      secondTitle,
      thirdTitle,
      onBackClick,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <IconButton
          className={classNames(
            classes.backButton,
            !isMobile && classes.backButtonDesktop,
          )}
          onClick={onBackClick}
          size="large"
        >
          <BackToLoginIcon />
        </IconButton>
        <Typography
          className={classes.firstLine}
        >
          {I18n.t(firstTitle)}
        </Typography>
        <Typography
          className={classes.secondLine}
        >
          {I18n.t(secondTitle)}
        </Typography>
        <Typography
          className={classes.thirdLine}
          align="center"
        >
          {I18n.t(thirdTitle)}
        </Typography>
      </Wrapper>
    )
  }
}

NavigationAndTitles.propTypes = {
  classes: PropTypes.object.isRequired,
  firstTitle: PropTypes.string.isRequired,
  secondTitle: PropTypes.string.isRequired,
  thirdTitle: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default withStyles(styles)(NavigationAndTitles)
