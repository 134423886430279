import { createLogic } from 'redux-logic'
import Geocode from 'react-geocode'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { setGeofences } from '../../actions/geofences/geofences'
import Api from '../../api/geofences'
import handleErrors from '../../helpers/handleErrors'
import {
  SYNC_GEOFENCES,
} from '../../constants/geofences/geofences'
import { apiKey } from '../../lib/Scenes/geofences'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '../../actions/apiRequest'

const fetchGeofences = createLogic({
  type: SYNC_GEOFENCES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_GEOFENCES))
    Api.fetchGeofences(AccessToken)
      .then((response) => {
        const { data: { geofences } } = response
        return { geofences }
      })
      .then((data) => {
        if (data.geofences.length) {
          Geocode.setApiKey(apiKey)
          const geocodes = Promise.all(data.geofences.map((geofence) => Geocode.fromLatLng(geofence.latitude, geofence.longitude)
            .then((result) => result)))
          return { data, geocodes }
        }
        return data
      })
      .then((result) => {
        if (result.geocodes) {
          result.geocodes.then((res) => {
            store.dispatch(setGeofences(result.data.geofences.map((geofence, i) => ({
              ...geofence,
              address: res[i].results[0].formatted_address,
              expanded: false,
            }))))
            store.dispatch(apiRequestDone(SYNC_GEOFENCES))
          })
        } else {
          dispatch(setGeofences(result.geofences))
          dispatch(apiRequestDone(SYNC_GEOFENCES))
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchGeofences failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchGeofences
