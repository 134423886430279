import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@mui/material'
import SecretItem from './SecretItem'

class Secrets extends Component {
  render() {
    const {
      classes,
      serialNumber,
      accessToken,
      unitToken,
      mezzoIdentifier,
    } = this.props
    return (
      <Paper className={classes.root}>
        <Typography className={classes.title}>{I18n.t('secrets.don\'t_show_this_anyone')}</Typography>
        <SecretItem name={I18n.t('secrets.access_token')} info={accessToken} />
        <SecretItem name={I18n.t('secrets.mezzo_serial_number')} info={serialNumber} />
        <SecretItem name={I18n.t('secrets.mezzo_token')} info={unitToken} />
        <SecretItem name={I18n.t('secrets.user_mezzo_indentifier')} info={mezzoIdentifier} />
      </Paper>
    )
  }
}

Secrets.propTypes = {
  classes: PropTypes.object.isRequired,
  serialNumber: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  unitToken: PropTypes.string.isRequired,
  mezzoIdentifier: PropTypes.string.isRequired,
}

Secrets.displayName = 'Secrets'

export default Secrets
