import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99998 10C3.99998 7.03492 6.16972 4.5891 8.99999 4.1084V5L11 3.50002L9.00001 2V3.08098C5.6134 3.56885 3 6.48109 3 10C3 12.5292 4.35309 14.743 6.36902 15.9732L7.23842 15.3211C5.31696 14.3198 4.00001 12.3126 3.99998 10Z" fill="currentColor"/>
      <path d="M17 10C17 7.47082 15.6469 5.25702 13.631 4.02685L12.7616 4.67889C14.683 5.68018 16 7.68743 16 10C16 12.9659 13.8314 15.4188 11 15.8984V14.9999L9 16.4999L11 18V16.9183C14.3863 16.4304 17 13.5189 17 10Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SyncIcon')

export default icon
