import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveActionDelay,
  saveActionDelayDescription,
  saveActionDeviceStateInfo,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveActionStateDescription,
  saveActionType,
  saveInitialActionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectDoorlockActionStateSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/DoorlockActions/SelectDoorlockActionStateSelected/SelectDoorlockActionStateSelected'
import SelectDoorlockActionStateSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/DoorlockActions/SelectDoorlockActionStateSelected/SelectDoorlockActionStateSelected'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectDoorlockActionStateSelectedIOS) : withStyles(SelectDoorlockActionStateSelected)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    stateDescription,
    actionDeviceNumber,
    delay: actionDelay,
    delayDescription,
    durationDescription,
    durationValueAfter,
    duration: actionDuration,
  } = state.scenes.currentSceneCreating.currentAction
  const { isEditActionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    stateDescription,
    isEditActionMode,
    actionDeviceNumber,
    actionDelay,
    delayDescription,
    durationDescription,
    durationValueAfter,
    actionDuration,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveInitialActionInfo,
  saveActionDeviceStateInfo,
  saveActionType,
  saveActionStateDescription,
  saveActionDelay,
  saveActionDelayDescription,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectDoorlockActionStateSelectedEnhanced = enhance

export default withRouter(SelectDoorlockActionStateSelectedEnhanced)
