import React from 'react'
import classNames from 'classnames'
import {
  Paper, Tab, Tabs, Typography,
} from '@mui/material'
import { SELECT_OBSERVING_DEVICE_TYPE } from '@app/constants/routes'
import ZWaveDevicesList
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/ZWaveDevices/ZWaveDevicesList'
import ZWaveDevicesByGroupList
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/ZWaveDevices/ZWaveDevicesByGroupList'
import ZWaveDevicesByRoomsList
  from '@app/containers/MyAutomation/CreateCondition/MezzoConditions/TimersConditions/SelectDeviceCommon/ZWaveDevices/ZWaveDevicesByRoomsList'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
// eslint-disable-next-line max-len
// eslint-disable-next-line max-len

class SelectObservingZWaveDevice extends React.Component {
  onBackClick= () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_OBSERVING_DEVICE_TYPE)
  }

  handleChange = (event, value) => {
    const { chooseTab } = this.props
    chooseTab(value)
  }

  render() {
    const {
      classes,
      isMobile,
      currentTab,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_observing_zwave')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <Tabs
              className={classes.deviceTabs}
              value={currentTab}
              indicatorColor="primary"
              onChange={this.handleChange}
              variant="fullWidth"
            >
              <Tab
                label="By devices"
              />
              <Tab
                label="By room"
              />
              <Tab
                label="By group"
              />
            </Tabs>
            {currentTab === 0 && (
              <ZWaveDevicesList />
            )}
            {currentTab === 1 && (
              <ZWaveDevicesByRoomsList />
            )}
            {currentTab === 2 && (
              <ZWaveDevicesByGroupList />
            )}
          </Wrapper>
        </Wrapper>
      </Paper>
    )
  }
}

SelectObservingZWaveDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  chooseTab: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
}

SelectObservingZWaveDevice.displayName = 'SelectObservingZWaveDeviceIOS'

export default SelectObservingZWaveDevice
