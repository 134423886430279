import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getDelayDurationDescription from '@app/helpers/scenes/getDelayDurationDescription'
import { adjustDurationValue, multilevelSwitchToggleAdjustDurationValue } from '@app/lib/Scenes/actions'
import {
  SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED,
  SELECT_MULTILEVEL_SWITCH_ACTION_VALUE_AFTER_DURATION,
} from '@app/constants/routes'
import Counter from '@app/containers/MyAutomation/Counter'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import PropTypes from '@app/components/PropTypes'

class AdjustDuration extends React.Component {
  onAddStateClick=() => {
    const {
      counterInfo,
      saveActionDuration,
      resetCounter,
      navigate,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      stateDescription,
    } = this.props
    saveActionDuration(counterInfo)
    saveActionDurationDescription(getDelayDurationDescription(counterInfo))
    resetCounter()
    if (stateDescription === 'toggle') {
      saveActionDurationValueAfter('toggle')
    }
    navigate(SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED)
  }

  onBackClick =() => {
    const {
      navigate,
      resetCounter,
      restoreActionInfo,
    } = this.props
    navigate(SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED)
    resetCounter()
    restoreActionInfo()
  }

  onChooseValueClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_MULTILEVEL_SWITCH_ACTION_VALUE_AFTER_DURATION)
  }

  onDisableButtonValue=() => {
    const {
      durationValueAfter,
      stateDescription,
      counterInfo: {
        day,
        hour,
        minute,
        second,
      },
    } = this.props
    if (day || hour || minute || second) {
      if (stateDescription === 'toggle') {
        return durationValueAfter === 'toggle'
      }
      return durationValueAfter === 'none'
    }
    return true
  }

  render() {
    const {
      classes,
      isMobile,
      durationValueAfter,
      stateDescription,
    } = this.props
    const isToggle = stateDescription === 'toggle'
    const isButtonDisabled = this.onDisableButtonValue()
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.adjust_duration')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <Counter title="duration" />
            { isToggle ? (
              <CardBlock
                key={multilevelSwitchToggleAdjustDurationValue.title}
                title={I18n.t(`scene_web.${multilevelSwitchToggleAdjustDurationValue.title}`)}
                description={multilevelSwitchToggleAdjustDurationValue.description}
                position={multilevelSwitchToggleAdjustDurationValue.position}
                buttonName={I18n.t(`scene_web.${multilevelSwitchToggleAdjustDurationValue.buttonName}`)}
                isCardDisabled={multilevelSwitchToggleAdjustDurationValue.isCardDisabled}
                isButtonDisabled
                showExpandMoreButton={false}
              />
            ) : (
              <CardBlock
                key={adjustDurationValue.title}
                title={I18n.t(`scene_web.${adjustDurationValue.title}`)}
                description={durationValueAfter === 'none' ? I18n.t(`scene_action_web.${durationValueAfter}`) : durationValueAfter}
                position={adjustDurationValue.position}
                buttonName={I18n.t(`scene_web.${adjustDurationValue.buttonName}`)}
                isCardDisabled={adjustDurationValue.isCardDisabled}
                onClick={this.onChooseValueClick}
                isButtonDisabled={false}
                showExpandMoreButton
              />
            )}
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
            disabled={isButtonDisabled}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

AdjustDuration.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  counterInfo: PropTypes.shape({
    day: PropTypes.number.isRequired,
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    second: PropTypes.number.isRequired,
  }).isRequired,
  resetCounter: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  stateDescription: PropTypes.string.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
}

AdjustDuration.displayName = 'AdjustDurationIOS'

export default AdjustDuration
