import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2981 3.23701C10.1105 3.14317 9.88953 3.14317 9.70187 3.23701L4.36853 5.90367C4.14267 6.0166 4 6.24745 4 6.49996V10.5C4 12.2748 4.57369 13.7608 5.60196 14.9774C6.61951 16.1812 8.05647 17.0918 9.7476 17.7836C9.90933 17.8498 10.0907 17.8498 10.2524 17.7836C11.9435 17.0918 13.3805 16.1812 14.3981 14.9774C15.4263 13.7608 16 12.2748 16 10.5V6.49996C16 6.24745 15.8573 6.0166 15.6315 5.90367L10.2981 3.23701ZM5.33333 10.5V6.91198L10 4.57865L14.6667 6.91198V10.5C14.6667 11.9616 14.2033 13.1423 13.3797 14.1166C12.5907 15.0501 11.4481 15.8202 10 16.4437C8.55191 15.8202 7.40927 15.0501 6.62026 14.1166C5.79668 13.1423 5.33333 11.9616 5.33333 10.5ZM13.1715 8.93545C13.4119 8.65667 13.3809 8.2357 13.1021 7.99519C12.8234 7.75467 12.4024 7.78569 12.1619 8.06447L9.17513 11.5264L7.80015 10.1778C7.53728 9.92003 7.11519 9.92409 6.85738 10.187C6.59957 10.4498 6.60366 10.8719 6.86652 11.1297L8.74887 12.9759C8.8804 13.1049 9.0594 13.1737 9.2434 13.166C9.42747 13.1584 9.60013 13.0749 9.72047 12.9354L13.1715 8.93545Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
    )
const icon = createSvgIcon(fragment, 'SecurityRulesIcon')

export default icon
