import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 114px)',
    borderRadius: 0,
  },
  textField: {
    borderColor: theme.palette.primary.main,
    width: '100%',
    marginBottom: 40,
  },
  textFieldWithError: {
    marginBottom: 18,
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  titleWrapper: {
    display: 'flex',
    marginBottom: 10,
  },
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    opacity: 0.5,
    marginRight: 15,
  },
  condition: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  button: {
    textDecoration: 'underline',
  },
  controlButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  controlButton: {
    width: '100%',
    height: 50,
    margin: '0 auto',
    maxWidth: 300,
    boxSizing: 'border-box',
    border: '1px solid #E1E1E1',
    borderRadius: 5,
  },
}))

export default useStyles
