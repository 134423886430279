import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: 16,
    paddingTop: 90,
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    marginBottom: 20,
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr));',
    gridGap: '1.25rem',
    justifyItems: 'center',
    marginBottom: 50,
  },
  wrapperMobile: {
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
  },
  noDevices: {
    textTransform: 'uppercase',
    width: '100%',
    minHeight: '15rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  refreshButton: {
    position: 'absolute',
    top: 20,
    right: 25,
    width: 30,
    height: 30,
  },
  sortButton: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 20,
    right: 60,
  },
  submitDragButton: {
    position: 'absolute',
    top: 20,
    right: 25,
  },
  backdrop: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
}))

export default useStyles
