import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/pills_reminders`

const deletePillsReminder = (access_token, id) => Axios.delete(`${ENDPOINT}/${id}`, {
  headers: { 'Access-Token': access_token },
})

export default {
  deletePillsReminder,
}
