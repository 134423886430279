import type { Node } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import useStyles from './SelectField.style'

const SelectField = (props): Node => {
  const classes = useStyles()
  const {
    dataId, styleClass, label, value, handleValue, arrayOfValues, disabled,
  } = props

  return (
    <TextField
      disabled={disabled}
      select
      label={label}
      value={value}
      onChange={handleValue}
      SelectProps={{
        native: true,
      }}
      InputProps={{ className: classes.select, 'data-id': dataId }}
      variant="standard"
      className={styleClass}
    >
      {arrayOfValues.map((option) => (
        <option key={option.value} value={option.value}>
          {option.title}
        </option>
      ))}
    </TextField>
  )
}

SelectField.propTypes = {
  dataId: PropTypes.number,
  styleClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  handleValue: PropTypes.func.isRequired,
  arrayOfValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
}

SelectField.defaultProps = {
  dataId: null,
  styleClass: null,
  disabled: false,
}

export default SelectField
