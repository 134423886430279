import React from 'react'
import {
  BatteryIcon,
  EnergyInfoIcon,
  AirTemperatureIcon,
  LuminanceIcon,
  HumidityIcon,
  UltravioletIcon,
  ColorChannelsIcon,
  DefaultIcon,
  NotificationSensorIcon,
  SmokeIcon,
  COIcon,
  CO2Icon,
  HeatIcon,
  WaterIcon,
  FreezeIcon,
  TamperIcon,
  AuxIcon,
  DoorIcon,
  WindowIcon,
  TiltIcon,
  MotionIcon,
  GlassBreakIcon,
  PIRSensor,
} from 'icons'

const renderSensorIcon = (sensorType: string): JSX.Element => {
  switch (sensorType) {
  case 'battery': return <BatteryIcon />
  case 'energy': return <EnergyInfoIcon />
  case 'smoke': return <SmokeIcon />
  case 'co': return <COIcon />
  case 'co2': return <CO2Icon />
  case 'heat': return <HeatIcon />
  case 'water': return <WaterIcon />
  case 'freeze': return <FreezeIcon />
  case 'tamper': return <TamperIcon />
  case 'door': return <DoorIcon />
  case 'window': return <WindowIcon />
  case 'aux': return <AuxIcon />
  case 'tilt': return <TiltIcon />
  case 'motion': return <MotionIcon />
  case 'glass_break': return <GlassBreakIcon />
  case 'air_temperature': return <AirTemperatureIcon />
  case 'illuminance': return <LuminanceIcon />
  case 'humidity': return <HumidityIcon />
  case 'ultraviolet': return <UltravioletIcon />
  case 'color_channels': return <ColorChannelsIcon />
  case 'notification_sensor': return <NotificationSensorIcon />
  case 'pir_sensor': return <PIRSensor />
  default: return <DefaultIcon />
  }
}

export default renderSensorIcon
