import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_GROUPS_SCENES } from '@app/constants/scenes/groupsScenes/groupsScenes'
import { updateGroupsScenes } from '@app/actions/scenes/groupsScenes/groupsScenes'
import Api from '@app/api/scenes/groupsScenes/fetchGroupsScenes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchGroups = createLogic({
  type: SYNC_GROUPS_SCENES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(SYNC_GROUPS_SCENES))
    Api.fetchGroupsScenes(AccessToken)
      .then((response) => {
        dispatch(updateGroupsScenes(response.data.groups))
        dispatch(apiRequestDone(SYNC_GROUPS_SCENES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchGroupsScenes failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchGroups
