import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  activateChangeSubDeviceMode,
  activateChangeSubDeviceStateMode,
  deactivateChangeSubDeviceMode,
  deactivateChangeSubDeviceStateMode,
  restoreConditionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectMultichannelBinarySwitchStateSelected
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelBinarySwitchStateSelected/SelectMultichannelBinarySwitchStateSelected'
import SelectMultichannelBinarySwitchStateSelectedIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MultichannelConditions/MultichannelBinarySwitch/SelectMultichannelBinarySwitchStateSelected/SelectMultichannelBinarySwitchStateSelected'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView
  ? withStyles(SelectMultichannelBinarySwitchStateSelectedIOS) : withStyles(SelectMultichannelBinarySwitchStateSelected)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const { dataNumber, dataOperand } = state.scenes.currentSceneCreating.currentCondition
  return {
    dataNumber,
    dataOperand,
    isEditConditionMode,
    isChangeConditionStateMode,
    isMobile,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  activateChangeSubDeviceMode,
  activateChangeSubDeviceStateMode,
  deactivateChangeSubDeviceMode,
  deactivateChangeSubDeviceStateMode,
  restoreConditionInfo,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectMultichannelBinarySwitchStateSelectedEnhanced = enhance

export default withRouter(SelectMultichannelBinarySwitchStateSelectedEnhanced)
