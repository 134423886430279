import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    color: 'inherit',
    hover: {
      backgroundColor: '#000',
    },
  },
  tab: {
    backgroundColor: theme.palette.background.default,
  },
}))

export default useStyles
