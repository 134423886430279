import * as type from '@app/constants/notifications/notifications'
import { INotification } from '@app/@types/notifications'

export const sync = () => ({
  type: type.SYNC_NOTIFICATIONS,
} as const)

export const update = (notifications: Array<INotification>) => ({
  type: type.UPDATE_NOTIFICATIONS,
  notifications,
} as const)

export const setNewNotification = (notification: INotification) => ({
  type: type.SET_NEW_NOTIFICATION,
  notification,
} as const)

export const resetNotification = () => ({
  type: type.RESET_NOTIFICATION_REDUCER,
} as const)
