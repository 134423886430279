import { AnyAction } from 'redux'
import { RESET_APP } from '@app/constants/app'
import packageJson from '../../../../package.json'

export interface IAppState {
  version: string,
}

const initialState = {
  version: packageJson.version,
}

const app = (state: IAppState = initialState, action: AnyAction): IAppState => {
  switch (action.type) {
  case RESET_APP:
    return initialState
  default:
    return state
  }
}

export default app
