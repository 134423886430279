import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { valuesAfterDuration } from '@app/lib/Scenes/binarySwitchActionValues'
import { ADJUST_DURATION_BINARY_SWITCH } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectBinarySwitchValueAfterDuration extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(ADJUST_DURATION_BINARY_SWITCH)
  }

  onAddClick=(value) => {
    const {
      navigate,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDurationValueAfter(value.description)
    navigate(ADJUST_DURATION_BINARY_SWITCH)
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_action_web.select_state_value_after_duration')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            valuesAfterDuration.map((value) => (
              <CardBlock
                key={value.description}
                title={value.title}
                description={I18n.t(`scene_action_web.${value.description}`)}
                position={value.position}
                buttonName={value.buttonName}
                isCardDisabled={value.isCardDisabled}
                isButtonDisabled={value.isButtonDisabled}
                onClick={() => {
                  this.onAddClick(value)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectBinarySwitchValueAfterDuration.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

SelectBinarySwitchValueAfterDuration.displayName = 'SelectBinarySwitchValueAfterDuration'

export default SelectBinarySwitchValueAfterDuration
