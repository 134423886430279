import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { sync } from '@app/actions/po/poConfig'
import { updateAccessToken } from '@app/actions/secrets'
// @ts-ignore
import { updateCurrentUser } from '@app/actions/users/currentUser'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs/index'
import ProgramOptions from '@app/components/ProgramOptions/ProgramOptions'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = ProgramOptions

const mapStateToProps = (state: AppState) => {
  const {
    initialOptions, currentOptions, defaultOptions, validation, items, inProgress, errorMessage,
  } = state.poConfig
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    initialOptions,
    currentOptions,
    defaultOptions,
    validation,
    items,
    isMobile,
    path,
    isWebView,
    inProgress,
    errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (parentDispatch: AppDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
  notifications: syncNotifications.sync,
}, parentDispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ProgramOptionsEnhanced = enhance

export default ProgramOptionsEnhanced
