const styles = () => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  counterInfo: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#141414',
    marginBottom: 22,
    borderRadius: '5px',
    padding: '11px 17px',
    width: '100%',
    boxShadow: 'none',
  },
  title: {
    fontSize: '18px',
    color: '#929292',
  },
  info: {
    fontSize: '15px',
  },
  counter: {
    backgroundColor: '#141414',
    marginBottom: 22,
    borderRadius: '5px',
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '15px 8px',
    boxShadow: 'none',
  },
  counterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20%',
  },
  counterTitle: {
    fontSize: '16px',
  },
  buttonInc: {
    transform: 'rotate(-90deg)',
  },
  buttonDec: {
    transform: 'rotate(90deg)',
  },
  number: {
    fontSize: '18px',
  },
})

export default styles
