import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id, sensorType) => `${API_URL}/api/scenes/zwave_devices/${id}/binary_sensors_values/${sensorType}`

const fetchBinarySensorsValues = (accessToken, id, sensorType) => Axios.get(ENDPOINT(id, sensorType), {
  headers: { 'Access-Token': accessToken },
})

export default fetchBinarySensorsValues
