import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_VOICE_ASSISTANT_PIN } from '@app/constants/myDevices/deviceSettings/voiceAssistant'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/voiceAssistant'

const patchVoiceAssistantPinLogic = createLogic({
  type: PATCH_VOICE_ASSISTANT_PIN,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      token,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_VOICE_ASSISTANT_PIN))
    try {
      await Api.patchVoiceAssistantPin(action.token, action.data)
      dispatch(apiRequestDone(PATCH_VOICE_ASSISTANT_PIN))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_VOICE_ASSISTANT_PIN failed without error')
      }
    }
    done()
  },
})

export default patchVoiceAssistantPinLogic
