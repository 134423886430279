import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.19114 4.29911V6.02679H4.07202V4.29911C4.07202 3.57589 4.31764 2.95982 4.80886 2.45089C5.32595 1.91518 5.93352 1.64732 6.63158 1.64732C7.32964 1.64732 7.92428 1.91518 8.41551 2.45089C8.93259 2.95982 9.19114 3.57589 9.19114 4.29911ZM12.759 6.54911C12.4229 6.20089 12.0351 6.02679 11.5956 6.02679H10.7812V4.29911C10.7812 3.12054 10.3675 2.11607 9.54017 1.28571C8.73869 0.428571 7.76916 0 6.63158 0C5.494 0 4.51154 0.428571 3.68421 1.28571C2.88273 2.11607 2.48199 3.12054 2.48199 4.29911V6.02679H1.66759C1.22807 6.02679 0.840259 6.20089 0.504155 6.54911C0.168052 6.87054 0 7.25893 0 7.71429V16.3125C0 16.7679 0.168052 17.1696 0.504155 17.5179C0.840259 17.8393 1.22807 18 1.66759 18H11.5956C12.0351 18 12.4229 17.8393 12.759 17.5179C13.0951 17.1696 13.2632 16.7679 13.2632 16.3125V7.71429C13.2632 7.25893 13.0951 6.87054 12.759 6.54911ZM6.5 9.05451C7.30208 9.24905 8.00694 9.65028 8.61458 10.2582L9.72656 9.1457C9.04601 8.46483 8.21354 8.08792 7.22917 8.01496C6.03819 7.9177 5.01736 8.29461 4.16667 9.1457C4.15451 9.1457 4.14844 9.15178 4.14844 9.16394C3.38281 9.92992 3 10.86 3 11.9543C3 13.0364 3.38281 13.9605 4.14844 14.7264L5.26042 13.6139C4.65278 13.006 4.25174 12.3069 4.05729 11.5166C3.86285 10.7141 3.89323 9.936 4.14844 9.18218C4.07552 9.76578 4.1849 10.4102 4.47656 11.1154C4.78038 11.8084 5.23003 12.4528 5.82552 13.0486L8.04948 10.8236C7.45399 10.2278 6.80382 9.78402 6.09896 9.49222C5.40625 9.18826 4.76823 9.07275 4.1849 9.1457C4.93837 8.89037 5.71007 8.85998 6.5 9.05451ZM7.5026 11.9361L6.9375 12.5014L9.4349 15L10 14.4529L7.5026 11.9361Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'VacationArmedIcon')

export default icon
