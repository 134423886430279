import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton,
} from '@mui/material'
import classNames from 'classnames'
import { Close } from 'icons'
import { pinCodeFields } from '@app/lib/Users/pinCode'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import Pincode from '../Pincode/Pincode'

class PinCodePopup extends React.PureComponent {
  render() {
    const {
      classes,
      submit,
      closeChangePinPopup,
      isMobile,
      isNCentral,
      invalid,
    } = this.props
    return (
      <Dialog
        className={classes.root}
        open
      >
        <DialogTitle
          className={classes.title}
        >
          {I18n.t('my_users.change_pin_code_title')}
          <IconButton aria-label="Close" className={classes.closeButton} onClick={closeChangePinPopup}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={classNames(
            classes.dialogContent,
            !isMobile && classes.dialogContentDesktop,
          )}
        >
          {pinCodeFields.map((field) => (
            <Pincode
              key={field.title}
              className={classes.item}
              name={field.title}
              title={I18n.t(`my_users.${field.title}`)}
              isMobile={isMobile}
              isNCentral={isNCentral}
            />
          ))}
        </DialogContent>
        <Wrapper
          className={classes.buttonWrapper}
        >
          <Button
            disabled={invalid}
            variant="outlined"
            className={classes.button}
            onClick={submit}
            color="primary"
          >
            {I18n.t('buttons.save')}
          </Button>
        </Wrapper>
      </Dialog>
    )
  }
}

PinCodePopup.propTypes = {
  classes: PropTypes.object.isRequired,
  closeChangePinPopup: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  isNCentral: PropTypes.bool.isRequired,
}

PinCodePopup.displayName = 'PinCodePopup'

export default PinCodePopup
