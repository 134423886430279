import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 27">
          <path fill="currentColor" d="M13.5 0C8.266 0 4.008 4.258 4.008 9.492c0 1.769.49 3.494 1.417 4.992l7.535 12.142a.79.79 0 00.672.374h.006a.791.791 0 00.672-.384l7.343-12.26a9.481 9.481 0 001.34-4.864C22.992 4.258 18.733 0 13.5 0zm6.796 13.543L13.62 24.69 6.77 13.65a7.9 7.9 0 01-1.19-4.158c0-4.361 3.558-7.92 7.92-7.92s7.915 3.559 7.915 7.92c0 1.43-.39 2.831-1.12 4.051z"/>
          <path fill="currentColor" d="M13.5 4.746a4.751 4.751 0 00-4.746 4.746 4.74 4.74 0 004.746 4.746 4.737 4.737 0 004.746-4.746A4.751 4.751 0 0013.5 4.746zm0 7.92a3.177 3.177 0 01-3.175-3.174A3.186 3.186 0 0113.5 6.318c1.745 0 3.17 1.43 3.17 3.174a3.17 3.17 0 01-3.17 3.175z"/>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'MarkerIcon')

export default icon
