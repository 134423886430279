import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { withStyles } from '@app/components/styles'
import {
  addAllDevicesToResetArray,
  addDeviceToResetArray,
  clearDevicesResetArray,
  deleteDeviceFromResetArray,
  resetModeOff,
  resetModeOn,
  set,
  sync,
  toggleSelectedSorting,
} from '@app/actions/myEnergy/deviceManager'
import DeviceManager from '@app/components/MyEnergy/Tabs/DeviceManager/DeviceManager'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'

let enhance = withStyles(DeviceManager)

const mapStateToProps = (state) => {
  const { resetMode } = state.deviceManager
  const devices = state.deviceManager.energy_devices
  const { sort } = state.deviceManager
  const { devicesForReset } = state.deviceManager
  const { isMobile } = state.mobileDetector
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isMobile,
    resetMode,
    devices,
    sort,
    admin,
    devicesForReset,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
  resetModeOn,
  resetModeOff,
  toggleSelectedSorting,
  addDeviceToResetArray,
  deleteDeviceFromResetArray,
  addAllDevicesToResetArray,
  clearDevicesResetArray,
  resetDevicesEnergies: set,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const DeviceManagerEnhanced = enhance

export default DeviceManagerEnhanced
