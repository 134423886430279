const styles = () => ({
  root: {
    display: 'flex',
    marginBottom: 40,
    width: '100%',
  },
  backButtonDesktop: {
    marginLeft: '5%',
    marginRight: '10%',
  },
  title: {
    fontSize: '24px',
    marginTop: 10,
  },
})

export default styles
