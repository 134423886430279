import type { Node } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, FormControlLabel, FormGroup, Checkbox, Typography,
} from '@mui/material'
import values from '@app/lib/PillsReminders/values'
import useStyles from './SelectWeekdays.style'

const SelectWeekdays = (props): Node => {
  const classes = useStyles()
  const { weekdays, handleValue } = props
  const currentDay = (value) => values.weekdays.find((day) => day.value === value)

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className={classes.wrapper}
    >
      <Typography className={classes.title}>Day of week</Typography>
      <FormGroup>
        {Object.entries(weekdays).map(([key, value]) => (
          <FormControlLabel
            className={classes.day}
            key={key}
            control={
              <Checkbox checked={value} onChange={handleValue} name={currentDay(key).label} />
            }
            labelPlacement="start"
            label={currentDay(key).label}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

SelectWeekdays.propTypes = {
  weekdays: PropTypes.object.isRequired,
  handleValue: PropTypes.func.isRequired,
}

export default SelectWeekdays
