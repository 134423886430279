import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width="148" height="148" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72.0963 24.038C58.8363 24.5567 46.2731 30.1123 36.967 39.5724C27.661 49.0326 22.3125 61.6853 22.0116 74.952L31.9735 75.178C32.2169 64.4468 36.5432 54.2124 44.0706 46.5603C51.5981 38.9082 61.7601 34.4145 72.4858 33.9949L72.0963 24.038Z" fill="currentColor"/>
      <path d="M73.8015 4.34016C64.5291 4.34645 55.3516 6.20793 46.8094 9.81498C38.2673 13.422 30.5332 18.7017 24.0626 25.3432C17.5919 31.9847 12.5155 39.8537 9.13226 48.4869C5.74899 57.1201 4.12726 66.343 4.36256 75.6125L14.6778 75.3507C14.4775 67.4572 15.8584 59.6035 18.7395 52.2519C21.6205 44.9003 25.9433 38.1995 31.4534 32.5439C36.9635 26.8883 43.5494 22.3924 50.8234 19.3208C58.0975 16.2493 65.9126 14.6641 73.8085 14.6588L73.8015 4.34016Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M71 105C88.1208 105 102 91.1208 102 74C102 56.8792 88.1208 43 71 43C53.8792 43 40 56.8792 40 74C40 91.1208 53.8792 105 71 105ZM54.5 68L60.5 58H88L74 80H88L81.5 90H54L68 68H54.5Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwaveIcon')

export default icon
