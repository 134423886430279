import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
      <g fill="red" clipPath="url(#a)">
        <path d="M7.5 7.48a.7.7 0 0 0-.13-.42L3.62 1.44A.74.74 0 0 0 3 1.1H.75a.75.75 0 0 0-.75.75c0 .15.05.3.13.41L3.6 7.48l-3.47 5.2a.7.7 0 0 0-.13.42c0 .41.34.75.75.75H3c.26 0 .49-.13.62-.34L7.37 7.9a.7.7 0 0 0 .13-.41Z"/>
        <path d="M15 7.48a.7.7 0 0 0-.13-.42l-3.75-5.62a.74.74 0 0 0-.62-.34H8.25a.75.75 0 0 0-.75.75c0 .15.04.3.13.41l3.47 5.22-3.47 5.2a.7.7 0 0 0-.13.42c0 .41.34.75.75.75h2.25c.26 0 .49-.13.62-.34l3.75-5.62a.7.7 0 0 0 .13-.41Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 15h15V0H0z"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ChevronsOffIcon', '0 0 13 18', { fontSize: 18 })

export default icon
