import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: theme.backgrounds.transparentBg,
  },
  main: {
    maxWidth: 450,
    minHeight: 600,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 20px',
    margin: 0,
    backgroundColor: theme.backgrounds.mainBg,
  },
  mainDesktop: {
    maxWidth: 450,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid rgba(255, 255, 255, 0.54)',
    boxSizing: 'border-box',
    boxShadow: `0px 4px 4px ${theme.palette.shadow.color}`,
    borderRadius: 10,
    padding: '35px 34px',
    margin: '32px 0px',
    backgroundColor: theme.backgrounds.mainBg,
  },
  form: {
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
  },
  header: {
    marginBottom: 70,
    fontSize: 30,
  },
  button: {
    marginTop: 50,
    marginBottom: 40,
    width: '50%',
  },
  info: {
    textAlign: 'center',
  },
}))

export default useStyles
