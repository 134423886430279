import React, { useEffect, useState } from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import getTimerType from '@app/helpers/timers/getTimerType'
import getTimerDurationDescription from '@app/helpers/timers/getTimerDurationDescription'
import { TIMERS } from '@app/constants/routes'
import {
  resetAllTimers,
  resetTimer,
  saveTimerData,
  syncTimers,
  unsubscribeTimerChannel,
} from '@app/actions/timers/timers'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import SmallErrorPopup from '@app/components/Common/SmallErrorPopup/SmallErrorPopup'
import { ResetGear, ScenesArrowBack } from 'icons'
import { IScene } from '@app/@types/scenes/scenes'
import { ITimer } from '@app/@types/timers/timersTypes'
import useStyles from './TimersReset.style'

interface Props {
  timers: Array<ITimer>,
  isMobile: boolean,
}

const TimersReset: React.FC<Props> = (props) => {
  const { timers, isMobile } = props
  const [showResetTimerPopup, setShowResetTimerPopup] = useState<boolean>(false)
  const [showResetAllPopup, setShowResetAllPopup] = useState<boolean>(false)
  const [showUsedInScenePopup, setShowUsedInScenePopup] = useState<boolean>(false)
  const [showAllUsedInScenePopup, setShowAllUsedInScenePopup] = useState<boolean>(false)
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [timersList, setTimersList] = useState(timers)

  useEffect(() => setTimersList(timers), [])

  useEffect(() => setTimersList(timers), [timers])

  useEffect(() => () => {
    dispatch(unsubscribeTimerChannel())
  }, [])

  const getTimerName = (number: number, status: string): string => {
    let currentName = `${I18n.t(`timers.timer_${number}`)}`
    if (status === 'commit_to_mezzo') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (saving)`
    } else if (status === 'not_saved') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (not saved)`
    } else if (status === 'deleting') {
      currentName = `${I18n.t(`timers.timer_${number}`)} (deleting)`
    }
    return currentName
  }

  const getTimerScenesNames = (scenesList: Array<IScene>) => {
    if (scenesList) {
      return (scenesList.length ? scenesList.map((scene) => scene.name).join(', ') : I18n.t('timers.n_a'))
    }
    return I18n.t('timers.n_a')
  }

  const checkSceneUsage = (list: Array<ITimer>) => (list.filter((timer) => timer.scenes.length))

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classes.iconWrapper}>
        <IconButton
          size="small"
          className={classes.backButton}
          onClick={() => navigate(TIMERS)}
          aria-label="Back"
        >
          <ScenesArrowBack />
        </IconButton>
        <Button
          onClick={() => (
            checkSceneUsage(timersList).length
              ? setShowAllUsedInScenePopup(!showAllUsedInScenePopup)
              : setShowResetAllPopup(!showResetAllPopup)
          )}
          className={classes.resetButton}
        >
          {`${I18n.t('buttons.reset_all')}`}
        </Button>
      </Wrapper>
      {timers.length ? (
        <Wrapper className={classNames(classes.settingsGrid, isMobile && classes.settingsGridMobile)}>
          {timersList.map((timer: ITimer, index: number) => (
            <Wrapper
              className={classNames(classes.setting, isMobile && classes.settingMobile)}
              key={`timer-${index + 1}`}
            >
              <Wrapper className={classes.cardLeftSide}>
                <Typography className={classes.timerTitle}>{getTimerName(timer.number, timer.status)}</Typography>
                <Typography className={classes.timerType}>
                  {`${I18n.t('timers.type')}: ${timer.timer_type === 'no_timer' ? 'N/A' : getTimerType(timer.timer_type)}`}
                </Typography>
                <Typography className={classes.timerDuration}>
                  {`${I18n.t('timers.duration')}: ${getTimerDurationDescription(timer.timer_type, timer.duration)}`}
                </Typography>
                <Typography className={classes.timerType}>
                  {`${I18n.t('timers.scenes')}:
                   ${timer.timer_type === 'no_timer' ? I18n.t('timers.n_a') : getTimerScenesNames(timer.scenes)}`}
                </Typography>
              </Wrapper>
              <IconButton
                size="small"
                className={classes.backButton}
                onClick={() => {
                  if (timer.scenes.length) {
                    setShowUsedInScenePopup(!showUsedInScenePopup)
                  } else {
                    setShowResetTimerPopup(!showResetTimerPopup)
                    dispatch(saveTimerData({
                      number: timer.number,
                      type: timer.timer_type === 'no_timer' ? 'one_shot' : timer.timer_type,
                      duration: timer.duration,
                    }))
                  }
                }}
                aria-label="reset"
              >
                <ResetGear />
              </IconButton>
            </Wrapper>
          ))}
        </Wrapper>
      )
        : (
          <Wrapper className={classNames(classes.settingsGrid, isMobile && classes.settingsGridMobile)}>
            {[...Array(8)].map((timer, index) => (
              <Wrapper
                className={classNames(classes.setting, isMobile && classes.settingMobile)}
                key={`timer-${index + 1}`}
              >
                <Wrapper className={classes.cardLeftSide}>
                  <Typography className={classes.timerTitle}>{`${I18n.t(`timers.timer_${index + 1}`)}`}</Typography>
                  <Typography className={classes.timerType}>{`${I18n.t('timers.type')}: ${I18n.t('timers.n_a')}`}</Typography>
                  <Typography className={classes.timerDuration}>{`${I18n.t('timers.duration')}: ${I18n.t('timers.n_a')}`}</Typography>
                </Wrapper>
                <IconButton
                  size="small"
                  className={classes.backButton}
                  onClick={() => (
                    timer.scenes.length ? setShowUsedInScenePopup(!showUsedInScenePopup) : setShowResetTimerPopup(!showResetTimerPopup)
                  )}
                  aria-label="reset"
                >
                  <ResetGear />
                </IconButton>
              </Wrapper>
            ))}
          </Wrapper>
        )}
      {showResetTimerPopup && (
        <WarningComponent
          open
          onClose={() => {
            setShowResetTimerPopup(!showResetTimerPopup)
          }}
          onClick={() => {
            dispatch(resetTimer())
            setTimeout(() => {
              dispatch(syncTimers())
            }, 1000)
            setShowResetTimerPopup(!showResetTimerPopup)
          }}
          warningText="confirmations.do_full_sync"
          additionalWarningText={I18n.t('confirmations.reset_timer')}
          leftButton="okay"
          rightButton="cancel"
        />
      )}
      {showResetAllPopup && (
        <WarningComponent
          open
          onClose={() => {
            setShowResetAllPopup(!showResetAllPopup)
          }}
          onClick={() => {
            dispatch(resetAllTimers())
            setTimeout(() => {
              dispatch(syncTimers())
            }, 1000)
            setShowResetAllPopup(!showResetAllPopup)
          }}
          warningText="confirmations.do_full_sync"
          additionalWarningText={I18n.t('confirmations.reset_all_timers')}
          leftButton="okay"
          rightButton="cancel"
        />
      )}
      {showUsedInScenePopup && (
        <SmallErrorPopup
          open
          warningText="timers.reset_timer_used_scene"
          buttonName="buttons.okay"
          title="titles.warning"
          onClick={() => {
            setShowUsedInScenePopup(!showUsedInScenePopup)
          }}
        />
      )}
      {showAllUsedInScenePopup && (
        <SmallErrorPopup
          open
          warningText="timers.reset_all_timer_used_scene"
          buttonName="buttons.okay"
          title="titles.warning"
          onClick={() => {
            setShowAllUsedInScenePopup(!showAllUsedInScenePopup)
          }}
        />
      )}
    </Wrapper>
  )
}

export default TimersReset
