import { createLogic } from 'redux-logic'
import { CLOSE_ZWAVE_CHANNEL } from '@app/constants/myDevices/channels/zwave/zwaveChannels'
import { INTERVIEW_CHANNEL_ZWAVE } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeZwaveChannelLogic = createLogic({
  type: CLOSE_ZWAVE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(INTERVIEW_CHANNEL_ZWAVE) && sub.unsubscribe())
  },
})

export default unsubscribeZwaveChannelLogic
