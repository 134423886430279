import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
} from '@mui/material'
import getActionCapabilities from '@app/helpers/scenes/getActionCapabilities'
import {
  EDIT_ACTION,
  CREATE_ACTION_SELECTED,
  SELECT_ACTION_DEVICE_TABS,
  SELECT_ACTION_COLOR_TYPE,
  SELECT_BINARY_SWITCH_ACTION_STATE_VALUE,
  SELECT_MULTILEVEL_SWITCH_ACTION_TYPE,
  SELECT_DOORLOCK_ACTION_STATE_VALUE,
  SELECT_MULTICHANNEL_ACTION_SUBDEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectActionDeviceStateType extends React.Component {
  onBackClick= () => {
    const {
      navigate,
      isEditActionMode,
      isChangeActionStateMode,
      restoreActionInfo,
      actions,
      initialAction,
    } = this.props
    if (isChangeActionStateMode) {
      restoreActionInfo()
      if (isEditActionMode) {
        restoreEditedActionInfo(actions, initialAction)
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      navigate(SELECT_ACTION_DEVICE_TABS)
    }
  }

  getOnClickFunction=(capabilityType) => {
    const {
      navigate,
    } = this.props
    switch (capabilityType) {
    case 'switch_color':
      return navigate(SELECT_ACTION_COLOR_TYPE)
    case 'switch_binary':
      return navigate(SELECT_BINARY_SWITCH_ACTION_STATE_VALUE)
    case 'switch_multilevel':
      return navigate(SELECT_MULTILEVEL_SWITCH_ACTION_TYPE)
    case 'door_lock':
      return navigate(SELECT_DOORLOCK_ACTION_STATE_VALUE)
    case 'multichannel':
      return navigate(SELECT_MULTICHANNEL_ACTION_SUBDEVICE)
    default:
      throw new Error('capability unsupported')
    }
  }

  createCapabilitiesList = (capabilitiesTemplate) => (capabilitiesTemplate.map((capability) => (
    <CardBlock
      key={capability.title}
      title={I18n.t(`scene_web.${capability.title}`)}
      description={I18n.t(`scene_web.${capability.description}`)}
      position="top"
      buttonName="choose"
      isCardDisabled={capability.isCardDisabled}
      isButtonDisabled={capability.isButtonDisabled}
      onClick={() => {
        this.getOnClickFunction(capability.type)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
      capabilities,
    } = this.props
    const capabilitiesTemplate = getActionCapabilities(capabilities)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {capabilitiesTemplate.length
            ? this.createCapabilitiesList(capabilitiesTemplate)
            : <Typography className={classes.noCommandClasses}>{I18n.t('scene_web.no_command_classes')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectActionDeviceStateType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  capabilities: PropTypes.arrayOf(PropTypes.string.isRequired),
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectActionDeviceStateType.defaultProps = {
  capabilities: [],
}

SelectActionDeviceStateType.displayName = 'SelectConditionDeviceStateTypeIOS'

export default SelectActionDeviceStateType
