import { TextField } from '@mui/material'

import { withStyles } from '@mui/styles'

const CustomTextField = withStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.backgrounds.cardBackground,
    margin: '5px 3%',
    width: '90%',
    '& label.Mui-focused': {
      color: 'rgba(229, 229, 229, 0.67)',
    },
    '& .MuiOutlinedInput-root': {
      paddingBottom: 0,
      paddingTop: 12,
      fontSize: 18,
      '& fieldset': {
        border: '1px solid transparent',
      },
      '& input': {
        paddingBottom: 0,
        paddingTop: 25,
      },
      '&:hover fieldset': {
        border: '1px solid transparent',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid transparent',
      },
    },
  },
}))(TextField)

export default CustomTextField
