import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveConditionDescription,
  saveConditionStateDescription,
  saveConditionStateTimeData,
  saveConditionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectTimeConditionStateDay
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/TimeCondition/SelectTimeConditionStateDay/SelectTimeConditionStateDay'
import SelectTimeConditionStateDayIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/TimeCondition/SelectTimeConditionStateDay/SelectTimeConditionStateDay'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTimeConditionStateDayIOS) : withStyles(SelectTimeConditionStateDay)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  const { type } = state.scenes.currentSceneCreating.currentCondition
  return {
    notifications: syncNotifications.sync,
    isMobile,
    path,
    isEditConditionMode,
    isChangeConditionStateMode,
    type,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionStateDescription,
  saveConditionType,
  saveConditionDescription,
  saveConditionStateTimeData,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTimeConditionStateDayEnhanced = enhance

export default withRouter(SelectTimeConditionStateDayEnhanced)
