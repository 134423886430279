const convertRequestToForm = (backendUser) => [
  { title: 'preferred_name', value: backendUser.preferred_name, hint: 'preferred_name' },
  { title: 'first_name', value: backendUser.first_name, hint: 'first_name' },
  { title: 'middle_name', value: backendUser.middle_name, hint: 'middle_name' },
  { title: 'last_name', value: backendUser.last_name, hint: 'last_name' },
  { title: 'email', value: backendUser.email, hint: 'email' },
  { title: 'phone_number', value: backendUser.phone_number, hint: 'phone_number' },
]

export default convertRequestToForm
