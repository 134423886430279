export const SYNC_CLOUD_FIRMWARE_INFO = '@@cloudFirmwareInfo/syncCloudFirmwareInfo'

export const UPDATE_CLOUD_FIRMWARE_INFO = '@@cloudFirmwareInfo/updateCloudFirmwareInfo'

export const SAVE_SELECTED_FIRMWARE_VERSION = '@@cloudFirmwareInfo/saveSelectedFirmwareVersion'
export const SAVE_DOWNLOADING_TYPE = '@@cloudFirmwareInfo/saveDownloadingType'
export const SAVE_AUTO_INSTALL_SETTING = '@@cloudFirmwareInfo/saveAutoInstallSetting'
export const SAVE_FIRMWARE_LANG = '@@cloudFirmwareInfo/saveFirmwareLang'

export const SYNC_MANUAL_UPDATE = '@@cloudFirmwareInfo/syncManualUpdate'

export const SYNC_START_DOWNLOADING = '@@cloudFirmwareInfo/syncStartDownloading'

export const SYNC_FIRMWARE_LANG = '@@cloudFirmwareInfo/syncFirmwareLang'

export const SYNC_CELLULAR_MODULE = '@@cloudFirmwareInfo/syncCellularModule'
export const SAVE_CELLULAR_MODULE = '@@cloudFirmwareInfo/saveCellularModule'
export const OPEN_CHANGE_CELLULAR_MODULE_POPUP = '@@cloudFirmwareInfo/openChangeCellularModulePopup'
export const CLOSE_CHANGE_CELLULAR_MODULE_POPUP = '@@cloudFirmwareInfo/closeChangeCellularModulePopup'

export const OPEN_CHANGE_LANG_POPUP = '@@cloudFirmwareInfo/openChangeLangPopup'
export const CLOSE_CHANGE_LANG_POPUP = '@@cloudFirmwareInfo/closeChangeLangPopup'

export const SYNC_RESET_PROCESS = '@@cloudFirmwareInfo/syncResetProcess'

export const OPEN_RESET_POPUP = '@@cloudFirmwareInfo/openResetPopup'
export const CLOSE_RESET_POPUP = '@@cloudFirmwareInfo/closeResetPopup'

export const SYNC_CONTINUE_DOWNLOADING = '@@cloudFirmwareInfo/syncContinueDownloading'

export const SAVE_CONTINUE_DOWNLOADING_TYPE = '@@cloudFirmwareInfo/saveContinueDownloadingType'
export const SAVE_DOWNLOADING_TIME = '@@cloudFirmwareInfo/saveDownloadingTime'

export const SYNC_PATCH_CLOUD_FIRMWARE_INFO = '@@cloudFirmwareInfo/syncPatchCloudFirmwareUpdateInfo'

export const SAVE_SUBMITTED_DOWNLOADING_TIME = '@@cloudFirmwareInfo/saveSubmittedDownloadingTime'
export const SAVE_SUBMITTED_NIGHTLY_DOWNLOADING = '@@cloudFirmwareInfo/saveSubmittedNightlyDownloading'

export const SYNC_STOP_UPLOADING = '@@cloudFirmwareInfo/syncStopUploading'

export const SAVE_SLOT_FOR_DOWNLOAD = '@@cloudFirmwareInfo/saveSlotForDownload'
export const SAVE_SLOT_FOR_INSTALL = '@@cloudFirmwareInfo/saveSlotForInstall'

export const SYNC_START_INSTALL = '@@cloudFirmwareInfo/syncStartInstall'

export const RESET_CLOUD_FIRMWARE_INFO = '@@cloudFirmwareInfo/reset'

export const START_REBOOT = '@@cloudFirmwareInfo/startReboot'
