import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { set, sync } from '@app/actions/myEnergy/setUpBillingPeriod'
import SetUpBillingPopup from '@app/components/MyEnergy/Tabs/BillingPeriod/SetUpBillingPopup/SetUpBillingPopup'
import { withAccessDenied, withProgressAndErrorMessage, withRefresh } from '@app/hocs'
import { validate } from './formValidateSetUpBillingPeriod'

let enhance = withStyles(SetUpBillingPopup)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { billingSettings } = state.setUpBillingPeriod
  return {
    isMobile,
    initialValues: {
      cost_of_kwh: billingSettings.cost_of_kwh,
      payment_day: billingSettings.payment_day,
    },
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  submit: (dispatch) => dispatch(submit('SetUpBillingPeriodForm')),
}, parentDispatch)

enhance = reduxForm({
  form: 'SetUpBillingPeriodForm',
  validate,
  enableReinitialize: true,
  onSubmit: () => {
    store.dispatch(set())
  },
})(enhance)

enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SetUpBillingPeriodEnhanced = enhance

export default SetUpBillingPeriodEnhanced
