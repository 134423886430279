import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getDelayDurationDescription from '@app/helpers/scenes/getDelayDurationDescription'
import { adjustDurationValue, selectColorActionSelectedValue } from '@app/lib/Scenes/actions'
import { ColorTypes } from '@app/lib/Scenes/colorActions'
import {
  SELECT_ACTION_ROOM_COLOR_TYPE_AFTER_DURATION,
  SELECT_ROOM_COLOR_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Counter from '@app/containers/MyAutomation/Counter'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import PropTypes from '@app/components/PropTypes'

class AdjustDuration extends React.Component {
  onAddStateClick=() => {
    const {
      counterInfo,
      saveActionDuration,
      resetCounter,
      navigate,
      saveActionDurationDescription,
    } = this.props
    saveActionDuration(counterInfo)
    saveActionDurationDescription(getDelayDurationDescription(counterInfo))
    resetCounter()
    navigate(SELECT_ROOM_COLOR_ACTION_STATE_SELECTED)
  }

  onBackClick =() => {
    const {
      navigate,
      resetCounter,
      restoreActionInfo,
    } = this.props
    navigate(SELECT_ROOM_COLOR_ACTION_STATE_SELECTED)
    resetCounter()
    restoreActionInfo()
  }

  onChooseValueClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_ACTION_ROOM_COLOR_TYPE_AFTER_DURATION)
  }

  onDisableButtonValue=() => {
    const {
      durationValueAfter,
      counterInfo: {
        day,
        hour,
        minute,
        second,
      },
    } = this.props
    if (day || hour || minute || second) {
      return durationValueAfter === 'none'
    }
    return true
  }

  renderValueBlock =() => {
    const {
      durationValueAfter,
      colorTypeDurationValueAfter,
    } = this.props
    if (durationValueAfter === 'none') {
      return (
        <CardBlock
          key={adjustDurationValue.title}
          title={I18n.t(`scene_web.${adjustDurationValue.title}`)}
          position={adjustDurationValue.position}
          description={I18n.t(`scene_action_web.${durationValueAfter}`)}
          buttonName={I18n.t(`scene_web.${adjustDurationValue.buttonName}`)}
          isCardDisabled={adjustDurationValue.isCardDisabled}
          isButtonDisabled={adjustDurationValue.isButtonDisabled}
          onClick={this.onChooseValueClick}
        />
      )
    }
    if (colorTypeDurationValueAfter === ColorTypes.whiteWarm) {
      return (
        <CardBlock
          key={adjustDurationValue.title}
          title={I18n.t(`scene_web.${adjustDurationValue.title}`)}
          position={adjustDurationValue.position}
          buttonName={I18n.t(`scene_web.${adjustDurationValue.buttonName}`)}
          isCardDisabled={adjustDurationValue.isCardDisabled}
          isButtonDisabled={adjustDurationValue.isButtonDisabled}
          onClick={this.onChooseValueClick}
          description={`set white warm on at ${durationValueAfter}%`}
        />
      )
    } if (colorTypeDurationValueAfter === ColorTypes.whiteCold) {
      return (
        <CardBlock
          key={adjustDurationValue.title}
          title={I18n.t(`scene_web.${adjustDurationValue.title}`)}
          position={adjustDurationValue.position}
          description={`set white cold on at ${durationValueAfter}%`}
          buttonName={I18n.t(`scene_web.${adjustDurationValue.buttonName}`)}
          isCardDisabled={adjustDurationValue.isCardDisabled}
          isButtonDisabled={adjustDurationValue.isButtonDisabled}
          onClick={this.onChooseValueClick}
        />
      )
    }
    return (
      <CardBlock
        key={adjustDurationValue.title}
        title={I18n.t(`scene_web.${adjustDurationValue.title}`)}
        description={durationValueAfter === 'none' ? I18n.t(`scene_action_web.${durationValueAfter}`) : I18n.t('scene_web.set_color')}
        hasAdditionalDesc
        descColor={durationValueAfter}
        position={selectColorActionSelectedValue.position}
        buttonName={I18n.t(`scene_web.${selectColorActionSelectedValue.buttonName}`)}
        isCardDisabled={selectColorActionSelectedValue.isCardDisabled}
        isButtonDisabled={selectColorActionSelectedValue.isButtonDisabled}
        onClick={this.onChooseValueClick}
      />
    )
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const isButtonDisabled = this.onDisableButtonValue()
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.adjust_duration')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Counter title="duration" />
          {this.renderValueBlock()}
        </Wrapper>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={this.onAddStateClick}
          disabled={isButtonDisabled}
        >
          {I18n.t('buttons.next')}
        </Button>
      </Paper>
    )
  }
}

AdjustDuration.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  counterInfo: PropTypes.shape({
    day: PropTypes.number.isRequired,
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    second: PropTypes.number.isRequired,
  }).isRequired,
  resetCounter: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  colorTypeDurationValueAfter: PropTypes.string.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
}

AdjustDuration.defaultProps = {

}

AdjustDuration.displayName = 'AdjustDuration'

export default AdjustDuration
