const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #505050',
  },
  title: {
    fontSize: 16,
  },
  subTitle: {
    fontSize: 14,
    textAlign: 'center',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 185px)',
  },
  checkbox: {
    margin: '0 2px 0 0',
    padding: '15px 5%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #505050',
  },
  checkboxIcon: {
    padding: 0,
  },
  blockTitle: {
    color: theme.scenes.color,
    fontSize: '18px',
    marginBottom: 5,
  },
  blockType: {
    fontSize: '18px',
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
    margin: '0 auto',
    maxWidth: 300,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
