import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import {
  CREATE_CONDITION_SELECTED, EDIT_CONDITION,
  SELECT_CONDITION_DEVICE_TABS,
} from '@app/constants/routes'

class SelectSecurityOptionValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_TABS)
  }

  getConditionName = (condition) => {
    switch (condition.type) {
    case 'ZwaveSensorNotification':
      return 'security_option_name_notification'
    case 'ZwaveV1BinarySensor':
      if (condition.data.value === 1) {
        return 'security_option_when_open'
      }
      return 'security_option_when_closed'

    default:
      return null
    }
  }

  setCondition = (condition) => {
    const {
      navigate,
      saveConditionType,
      saveConditionStateDescription,
      saveConditionDeviceStateInfo,
      isEditConditionMode,
    } = this.props
    if (condition.type === 'ZwaveV1BinarySensor') {
      saveConditionType('ZWaveDevices')
      saveConditionStateDescription(condition.data.value === 1
        ? `${I18n.t('scene_web.security_option_when_open')}`
        : `${I18n.t('scene_web.security_option_when_closed')}`)
      saveConditionDeviceStateInfo({ type: 'ZwaveV1BinarySensor', data: condition.data })
    }
    if (condition.type === 'ZwaveSensorNotification') {
      saveConditionType('ZwaveSensorNotification')
      saveConditionStateDescription(condition.title)
      saveConditionDeviceStateInfo(condition.data)
    }
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      conditions,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_sensor_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {conditions.length
            ? (conditions.map((condition, index) => (
              <CardBlock
                key={`condition-${index + 1}`}
                description={I18n.t(`scene_web.${this.getConditionName(condition)}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                onClick={() => {
                  this.setCondition(condition)
                }}
                isButtonDisabled={false}
                isCardDisabled={false}
              />
            )))
            : (<Typography className={classes.somethingWrong}>no supported conditions</Typography>)}
        </Wrapper>
      </Paper>
    )
  }
}

SelectSecurityOptionValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.condition).isRequired,
}

SelectSecurityOptionValue.defaultProps = {

}

SelectSecurityOptionValue.displayName = 'SelectSecurityOptionValue'

export default SelectSecurityOptionValue
