import { createStyles, makeStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 300,
    maxWidth: 400,
    width: '100%',
  },
  webView: {
    background: theme.webView.background,
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: '1%',
    position: 'relative',
  },
  lineWrapperMobile: {
    width: '93%',
  },
  buttonFirstLine: {
    color: theme.typography.color,
    textTransform: 'none',
    height: 100,
    width: '32%',
    fontSize: 17,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: 20,
  },
  mobileButtonFirstLine: {
    justifyContent: 'center',
    height: 55,
    fontSize: 15,
    padding: 0,
    flexDirection: 'row',
  },
  mobileButtonDisarm: {
    paddingLeft: 30,
  },
  mobileArmHomeText: {
    lineHeight: 1,
  },
  pink: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  green: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  red: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  darkPink: {
    backgroundColor: theme.palette.secondary.dark,
  },
  darkGreen: {
    backgroundColor: theme.palette.primary.dark,
  },
  darkRed: {
    backgroundColor: theme.palette.secondary.dark,
  },
  greenStatus: {
    backgroundColor: theme.palette.primary.main,
  },
  redStatus: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSecondLine: {
    color: theme.typography.color,
    width: '49%',
    height: 180,
    margin: '20px 0',
    fontSize: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  armStatus: {
    position: 'absolute',
    top: '22%',
    width: 100,
    height: 100,
    borderRadius: '50%',
    borderColor: theme.palette.background.default,
    border: 'solid 3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  armStatusIcon: {
    fontSize: 55,
  },
  eventWindow: {
    width: '100%',
    height: 67,
    marginTop: 12,
    marginBottom: 15,
    backgroundColor: 'white',
    display: 'flex',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: 4,
  },
  sealedIcon: {
    fontSize: 27,
  },
  unSealedIcon: {
    fontSize: 20,
    color: theme.palette.secondary.dark,
  },
  linearProgress: {
    width: '50%',
    marginTop: 50,
  },
}))

export default useStyles
