import React from 'react'
import PropTypes from 'prop-types'
import ForbiddenAccess from '@app/components/Common/ForbiddenAccess/ForbiddenAccess'

const withForbiddenAccess = (WrappedComponent) => {
  class AccessForbidden extends React.Component {
    render() {
      const {
        forbiddenRoles,
        currentRole,
      } = this.props
      if (forbiddenRoles.includes(currentRole)) {
        return <ForbiddenAccess />
      }
      return <WrappedComponent {...this.props} />
    }
  }

  AccessForbidden.propTypes = {
    forbiddenRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentRole: PropTypes.string.isRequired,
  }

  return AccessForbidden
}

export default withForbiddenAccess
