import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  menuBlocker: {
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 56,
    bottom: 0,
    zIndex: 300,
  },
}))

export default useStyles
