import { createLogic } from 'redux-logic'
import { SYNC_HOT_UPDATE } from '@app/constants/hotUpdate'
import Api from '@app/api/hotUpdate'
import { updateCurrentUser } from '@app/actions/users/currentUser'
import { updateSecrets } from '@app/actions/secrets'
import { updateUnit } from '@app/actions/unit'
import handleErrors from '@app/helpers/handleErrors'
import { setGuardPanelStatus } from '@app/actions/guardPanel'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { getArmStatus } from '@app/helpers/getArmStatus'

const hotUpdateLogic = createLogic({
  type: SYNC_HOT_UPDATE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { unit: { isNCentral } } = getState()

    next({
      ...action,
      token,
      isNCentral,
    })
  },
  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SYNC_HOT_UPDATE))
    Api.hotUpdate(action.token)
      .then((responseInfo) => {
        const { mobileUser, unitDevice } = responseInfo.data
        dispatch(updateCurrentUser(mobileUser))
        if (unitDevice) {
          dispatch(updateUnit(unitDevice))
          dispatch(updateSecrets(
            unitDevice.token,
          ))
          const statusResult = getArmStatus(unitDevice.arm_status, unitDevice.arm_types, action.isNCentral)
          dispatch(setGuardPanelStatus(statusResult))
        }
        dispatch(apiRequestDone(SYNC_HOT_UPDATE))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('hotUpdate failed without error')
        }
      })
      .then(() => done())
  },
})

export default hotUpdateLogic
