import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  sceneItem: {
    width: '100%',
    backgroundColor: '#141414',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    paddingLeft: '5%',
  },
  sceneInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 70,
    flexDirection: 'column',
    padding: 12,
  },
  sceneTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  sceneInfoText: {
    maxWidth: '100%',
    marginBottom: 12,
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    justifyContent: 'space-between',
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  activateButton: {
    fontSize: '0.75rem',
    padding: '3px 0px',
  },
  additionalDesc: {
    display: 'inline-block',
    marginLeft: 5,
    width: 10,
    height: 10,
  },
  sortedIcon: {
    margin: '4px 0',
  },
}))

export default useStyles
