import { createLogic } from 'redux-logic'
import { SYNC_MEZZO_ERRORS_OPTIONS } from '@app/constants/mezzoErrors'
import { deps } from '@app/constants/channels'
import { PROGRAM_OPTIONS_TIMEOUT } from '@app/constants/timeouts'
import { setInitialOptions, setCurrentOptions } from '@app/actions/programOptions'
import PO from '@app/lib/ProgramOptions'
import programOptionChannel from '@app/api/programOptions'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchMezzoErrorsOptionLogic = createLogic({
  type: SYNC_MEZZO_ERRORS_OPTIONS,
  cancelType: API_REQUEST_CANCEL,
  warnTimeout: PROGRAM_OPTIONS_TIMEOUT,

  process(_, dispatch, done) {
    programOptionChannel({
      request: (channel) => {
        const numbers = [PO.GET_ERROR_CODE_1242]
        channel.perform('get_program_options', { numbers, ...deps })
        dispatch(apiRequestSent(SYNC_MEZZO_ERRORS_OPTIONS))
      },
      response: (data, isFinished) => {
        const { programOptions, error } = data
        if (error && error.msg) {
          dispatch(apiRequestFailed(error.msg, error.code))
        } else {
          dispatch(setInitialOptions(programOptions))
          dispatch(setCurrentOptions(programOptions))
          if (isFinished) {
            dispatch(apiRequestDone(SYNC_MEZZO_ERRORS_OPTIONS))
          }
        }
        if (isFinished) {
          done()
        }
      },
    })
  },
})

export default fetchMezzoErrorsOptionLogic
