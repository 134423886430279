import React from 'react'
import { List, ListItem } from '@mui/material'
import { IRoomTwrBackend, IRoomZwaveBackend } from '@app/@types/scenes/scenesDevices'
import Room from './Room'
import useStyles from './RoomsList.style'

interface Props {
  rooms: Array<IRoomZwaveBackend> | Array<IRoomTwrBackend>,
  deviceType: string,
}

const RoomsList: React.FC<Props> = (props) => {
  const {
    rooms,
    deviceType,
  } = props

  const classes = useStyles()

  return (
    <List className={classes.root}>
      {rooms.map((room) => (
        <ListItem key={room.id}>
          <Room
            name={room.name}
            deviceType={deviceType}
            // @ts-ignore
            devices={room.twr_devices || room.zwave_devices}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default RoomsList
