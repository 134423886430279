export const roomActionStates = [
  {
    description: 'turn_on',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOn',
  },
  {
    description: 'turn_off',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOff',
  },
]

export const groupActionStates = [
  {
    description: 'turn_on',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOn',
  },
  {
    description: 'turn_off',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'TurnOff',
  },
]
