import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_CONDITION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectAnotherSceneCondition extends React.Component {
  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_TYPE)
  }

  selectAnotherScene=(scene) => {
    const {
      navigate,
      isEditConditionMode,
      saveConditionType,
      saveConditionDescription,
      saveConditionAnotherSceneNumber,
      saveConditionDeviceNumber,
      saveConditionStateDescription,
      saveConditionDeviceName,
    } = this.props
    saveConditionDeviceNumber(0)
    saveConditionStateDescription('')
    saveConditionDeviceName('')
    saveConditionType('AnotherScene')
    saveConditionDescription(`${scene.name} is active`)
    saveConditionAnotherSceneNumber(scene.number)
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      scenes,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_scene')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {scenes.length
            ? scenes.map((scene) => (
              <CardBlock
                key={scene.id}
                description={scene.name}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectAnotherScene(scene)
                }}
              />
            )) : <Typography align="center" variant="h6">{I18n.t('scene_web.no_scenes')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectAnotherSceneCondition.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  scenes: PropTypes.arrayOf(PropTypes.scene).isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionAnotherSceneNumber: PropTypes.func.isRequired,
  saveConditionDeviceNumber: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  saveConditionDeviceName: PropTypes.func.isRequired,
}

SelectAnotherSceneCondition.displayName = 'SelectAnotherSceneConditionIOS'

export default SelectAnotherSceneCondition
