import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg width="104" height="104" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="104" height="104" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.6263 37.2419C49.1459 39.7952 46.6656 41.4731 43.3098 42.9321C39.954 44.3912 35.7228 45.6678 32.6953 46.981C29.6678 48.2941 27.8805 49.7167 26.6403 51.6134C25.4002 53.5466 24.7071 55.954 24.6706 58.6168C24.6342 61.243 25.2178 64.0881 26.3485 66.3861C27.5158 68.6476 29.2301 70.362 30.2514 71.2374C31.3092 72.0764 31.674 72.0399 31.9658 71.7481C32.2576 71.4563 32.4765 70.8727 33.1695 69.1948C33.8626 67.5169 34.9933 64.7447 36.9995 62.1185C39.0056 59.4922 41.8872 57.0483 44.8053 55.4798C47.7234 53.9114 50.6779 53.2183 52.1734 52.8536C53.669 52.4888 53.7054 52.4159 53.6325 52.5618C53.5595 52.7077 53.3407 52.9995 52.9759 53.6925C52.6476 54.3856 52.1734 55.4434 51.1156 57.0483C50.0943 58.6532 48.4894 60.7688 46.4102 62.8844C44.3311 65 41.7778 67.0792 39.7352 69.9243C37.6925 72.7694 36.1605 76.417 36.8536 81.0494C37.5466 85.6819 40.4647 91.2991 50.7509 93.6336C61.0371 95.9681 78.6914 95.0197 91.0932 76.8182C106.778 47.8199 88.8682 12.0371 56.2587 7.22227C23.6493 2.40745 -3.85349 31.4787 2.82159 63.7963C-0.0600049 73.2071 1.18017 82.1802 1.90969 85.3171C7.96469 84.004 14.0197 82.6908 20.0382 81.3777C18.7251 75.1768 17.0472 69.1218 10.7369 62.082C4.82776 33.0837 31.8199 8.17064 60.2711 16.4142C88.6858 24.6213 98.2425 60.1123 77.7795 81.4871C69.6454 89.0012 58.3014 88.5635 51.9181 86.8856C45.4984 85.2077 44.0393 82.3261 44.4406 80.3929C44.8418 78.4596 47.1762 77.4748 49.7296 76.6723C52.3193 75.8699 55.1645 75.2862 57.7542 74.0461C60.344 72.7694 62.6785 70.8362 64.7941 67.3345C66.9097 63.8328 68.7335 58.7262 69.1347 54.0208C69.5359 49.3154 68.4417 45.0477 66.5085 40.4153C64.5752 35.7829 61.7666 30.8951 57.9731 22.7245C56.6235 31.2599 54.1067 34.6886 51.6263 37.2419Z" stroke="currentColor" strokeWidth="2"/>
      <path d="M6.10437 86.5936L8.03759 93.8887" stroke="currentColor" strokeWidth="2"/>
      <path d="M16.4635 84.3321L18.3967 91.6638" stroke="currentColor" strokeWidth="2"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EnergyDashboardIcon')

export default icon
