import PO from './programOptions'

const aboutNumbers = [
  PO.FIRMWARE_REV_1340,
  PO.PRODUCT_SERIAL_1333,
  PO.CELLULAR_TYPE_1374,
  PO.CELLULAR_IMEI_1341,
  PO.SIM_ICCID_1352,
  PO.MAIN_BOARD_REV_1327,
  PO.BOOT_LOADER_REV_1329,
  PO.ZWAVE_REV_1328,
  PO.VOICE_REV_1330,
]

export default aboutNumbers
