import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 21px 16px 21px',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
    marginBottom: 20,
  },
  main: {
    width: '100%',
  },
}))

export default useStyles
