import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: '#141414',
    zIndex: 999,
    borderRadius: 0,
  },
  main: {
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    border: '5vw solid #141414',
  },
  title: {
    fontSize: '14px',
    marginBottom: 10,
  },
  description: {
    fontSize: '14px',
    marginBottom: 20,
  },
}))

export default useStyles
