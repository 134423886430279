import * as type from '@app/constants/menu'

export const openMenu = () => ({
  type: type.OPEN_MENU,
})

export const closeMenu = () => ({
  type: type.CLOSE_MENU,
} as const)

export const setNavigationStatus = (navigation: boolean) => ({
  type: type.SET_NAVIGATION_STATUS,
  navigation,
} as const)

export const toggleHomeTab = () => ({
  type: type.TOGGLE_HOME_TAB,
} as const)

export const toggleApplicationTab = () => ({
  type: type.TOGGLE_APPLICATION_TAB,
} as const)

export const toggleSecurityTab = () => ({
  type: type.TOGGLE_SECURITY_TAB,
} as const)

export const toggleMySecurityTab = () => ({
  type: type.TOGGLE_MY_SECURITY_TAB,
} as const)

export const toggleMezzoTab = () => ({
  type: type.TOGGLE_MEZZO_TAB,
} as const)

export const toggleAutomationTab = () => ({
  type: type.TOGGLE_AUTOMATION_TAB,
} as const)

export const closeAllTabs = () => ({
  type: type.CLOSE_ALL_TABS,
} as const)
