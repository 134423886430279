import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { saveGeofenceInfo } from '@app/actions/geofences/geofences'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGeofencesLocation
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesLocation/SelectGeofencesLocation'
import SelectGeofencesLocationIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesLocation/SelectGeofencesLocation'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

let enhance = isIOS && isWebView ? withStyles(SelectGeofencesLocationIOS) : withStyles(SelectGeofencesLocation)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    isEditConditionMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveGeofenceInfo,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGeofencesLocationEnhanced = enhance

export default withRouter(SelectGeofencesLocationEnhanced)
