import { createLogic } from 'redux-logic'
import { SYNC_DEVICE_INFO_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { setDeviceInfoError, syncDeviceInfoChannelSent, updateDeviceInfo } from '@app/actions/myDevices/deviceInfo/deviceInfoChannel'
import Api from '@app/api/myDevices/deviceInfo/deviceInfoChannel'
import {
  setConfigurationPopupData,
  setConfigurationPopupStatus,
  setIsSleepingDevicePopupOpen,
  setZwaveDeviceDetails,
  setZwaveDeviceDetailsError,
} from '@app/actions/myDevices/zwaveDevices'
import { IDeviceInfoChannelData } from '@app/@types/myDevices'
import { endFetchingDeviceStats } from '@app/actions/myDevices/deviceInfo/fetchTwrDeviceInfo'

const subscribeDeviceInfoLogic = createLogic({
  type: SYNC_DEVICE_INFO_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.deviceInfoChannel({
      request: () => {
        dispatch(syncDeviceInfoChannelSent())
      },
      response: (data: IDeviceInfoChannelData) => {
        const { myDevices: { myDevices: { currentDevice } } } = store.getState()
        const { myDevices: { myDevices: { deviceDetails: { currentDeviceDetails } } } } = store.getState()
        if (data.zwave_details) {
          if (data.error) {
            if (data.device_is_sleeping) {
              store.dispatch(setIsSleepingDevicePopupOpen(true))
            } else {
              store.dispatch(setZwaveDeviceDetailsError(data.error))
            }
          } else if (data.zwave_device_id === currentDevice.id) {
            store.dispatch(setZwaveDeviceDetails(data.details))
          }
        } else if (data.zwave_config_params) {
          if (data.error) {
            if (data.device_is_sleeping) {
              store.dispatch(setIsSleepingDevicePopupOpen(true))
            } else {
              store.dispatch(setConfigurationPopupStatus(data.error))
              store.dispatch(setConfigurationPopupData({
                number: data.parameter_number,
                value: '',
                size: '',
              }))
            }
          } else if (data.values) {
            const updatedParams = currentDeviceDetails.config_params.map((parameter) => (
              parameter.number === data.parameter_number
                ? { ...parameter, values: data.values } : parameter))
            const updatedDetails = { ...currentDeviceDetails, config_params: updatedParams }
            store.dispatch(setZwaveDeviceDetails(updatedDetails))
          } else if (data.config_params) {
            if (currentDeviceDetails) {
              const updatedParams = currentDeviceDetails.config_params.map((parameter) => {
                const updatedData = data.config_params.find((newParam) => parameter.number === newParam.parameter_number)
                return updatedData ? {
                  ...parameter,
                  set_to: updatedData.value !== null ? updatedData.value : parameter.set_to,
                  size: updatedData.size !== null ? updatedData.size : parameter.size,
                  isFetched: true,
                } : parameter
              })
              const updatedDetails = {
                ...currentDeviceDetails,
                config_params: updatedParams,
              }
              store.dispatch(setZwaveDeviceDetails(updatedDetails))
            }
            store.dispatch(setConfigurationPopupData({
              number: data.config_params[0].parameter_number,
              value: data.config_params[0].value,
              size: data.config_params[0].size,
            }))
            store.dispatch(setConfigurationPopupStatus('Done'))
          }
        } else if (data.error) {
          store.dispatch(endFetchingDeviceStats())
          store.dispatch(setDeviceInfoError(data.error))
        } else {
          if (data.twr_info && (currentDevice && data.twr_device_id === currentDevice.id)) {
            store.dispatch(endFetchingDeviceStats())
          }
          store.dispatch(updateDeviceInfo(data))
        }
      },
    })
  },
})

export default subscribeDeviceInfoLogic
