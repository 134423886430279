export const zwaveDetailsFields = [
  'product_name',
  'brand_name',
  'product_line',
  'product_code',
  'ness_id',
  'node_id',
  'hardware',
  'firmware',
  'inclusion_info',
]
