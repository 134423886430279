import update from 'immutability-helper'
import * as type from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'

const initialState = {
  selectedFirmwareVersion: '',
  autoInstall: false,
  currentVersion: '',
  newVersion: '',
  downloadingType: 'manual',
  downloadingTime: '04:00',
  submittedDownloadingTime: '',
  firmwareList: [],
  firmwareLang: 'AU',
  isChangeLangPopupOpen: false,
  updateStatus: 'done',
  fwToInstall: '',
  isResetPopupOpen: false,
  continueDownloadingType: '',
  uploadProcess: '',
  submittedNightlyDownloading: false,
  uploadStatusValue: 0,
  slotForDownload: 1,
  slotForInstall: 1,
  fwInSlots: { A: null, B: null },
  cellularModule: '3G',
  isChangeCellularModulePopupOpen: false,
}

export default function cloudFirmwareUpdateInfo(state = initialState, action) {
  let currentNewVersion
  let fwToInstall
  switch (action.type) {
  case type.UPDATE_CLOUD_FIRMWARE_INFO:
    if (action.info.new_version === null) {
      currentNewVersion = ''
    } else if (action.info.new_version) {
      currentNewVersion = action.info.new_version
    } else {
      currentNewVersion = state.newVersion
    }
    if (action.info.fw_to_install === null) {
      fwToInstall = ''
    } else if (action.info.fw_to_install) {
      fwToInstall = action.info.fw_to_install
    } else {
      fwToInstall = state.fwToInstall
    }
    return update(state, {
      currentVersion: { $set: action.info.current_version || state.currentVersion },
      newVersion: { $set: currentNewVersion },
      downloadingType: { $set: action.info.downloading_type || state.downloadingType },
      downloadingTime: { $set: action.info.time || state.downloadingTime },
      firmwareList: { $set: action.info.firmware_list || state.firmwareList },
      firmwareLang: { $set: action.info.firmware_lang || state.firmwareLang },
      autoInstall: { $set: action.info.autoinstall },
      selectedFirmwareVersion: { $set: action.info.selected_version || state.selectedFirmwareVersion },
      updateStatus: { $set: action.info.update_status || state.updateStatus },
      fwToInstall: { $set: fwToInstall },
      uploadProcess: { $set: action.info.upload_process || state.uploadProcess },
      submittedDownloadingTime: { $set: action.info.downloading_type === 'set_time' ? action.info.time : '' },
      submittedNightlyDownloading: { $set: action.info.downloading_type === 'nightly' },
      uploadStatusValue: { $set: action.info.upload_status ? action.info.upload_status : state.uploadStatusValue },
      slotForDownload: { $set: action.info.slot_for_download || state.slotForDownload },
      slotForInstall: { $set: action.info.slot_for_install || state.slotForInstall },
      installationMessage: { $set: action.info.installation_message || state.installationMessage },
      fwInSlots: { $set: action.info.fw_in_slots || state.fwInSlots },
      cellularModule: { $set: action.info.cellular_module || state.cellularModule },
    })
  case type.SAVE_SELECTED_FIRMWARE_VERSION:
    return { ...state, selectedFirmwareVersion: action.version }

  case type.SAVE_DOWNLOADING_TYPE:
    return { ...state, downloadingType: action.downloadingType }

  case type.SAVE_AUTO_INSTALL_SETTING:
    return { ...state, autoInstall: action.value }

  case type.SAVE_FIRMWARE_LANG:
    return { ...state, firmwareLang: action.lang }

  case type.OPEN_CHANGE_LANG_POPUP:
    return { ...state, isChangeLangPopupOpen: true }
  case type.CLOSE_CHANGE_LANG_POPUP:
    return { ...state, isChangeLangPopupOpen: false }

  case type.SAVE_CELLULAR_MODULE:
    return { ...state, cellularModule: action.cellularModule }

  case type.OPEN_CHANGE_CELLULAR_MODULE_POPUP:
    return { ...state, isChangeCellularModulePopupOpen: true }
  case type.CLOSE_CHANGE_CELLULAR_MODULE_POPUP:
    return { ...state, isChangeCellularModulePopupOpen: false }

  case type.OPEN_RESET_POPUP:
    return { ...state, isResetPopupOpen: true }
  case type.CLOSE_RESET_POPUP:
    return { ...state, isResetPopupOpen: false }

  case type.SAVE_CONTINUE_DOWNLOADING_TYPE:
    return { ...state, continueDownloadingType: action.downloadingType }

  case type.SAVE_DOWNLOADING_TIME:
    return { ...state, downloadingTime: action.downloadingTime }

  case type.SAVE_SUBMITTED_DOWNLOADING_TIME:
    return { ...state, saveSubmittedDownloadingTime: action.time }

  case type.SAVE_SUBMITTED_NIGHTLY_DOWNLOADING:
    return { ...state, submittedNightlyDownloading: true }

  case type.SAVE_SLOT_FOR_DOWNLOAD:
    return { ...state, slotForDownload: action.slot }

  case type.SAVE_SLOT_FOR_INSTALL:
    return { ...state, slotForInstall: action.slot }

  case type.RESET_CLOUD_FIRMWARE_INFO:
    return initialState

  default:
    return state
  }
}
