import Axios from 'axios'

import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/sphere_users/avatar_file`

const updateUserAvatar = (accessToken, data) => Axios.post(ENDPOINT, data, {
  headers: {
    'Access-Token': accessToken,
    'Content-Type': 'multipart/form-data',
  },
})

export default {
  updateUserAvatar,
}
