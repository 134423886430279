import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.60938 2.39062C9.35938 2.10938 9.04688 1.96875 8.67188 1.96875H7.03125V0H3V1.96875H1.35938C0.984375 1.96875 0.65625 2.10938 0.375 2.39062C0.125 2.64062 0 2.95312 0 3.32812V18.6562C0 19.0312 0.125 19.3438 0.375 19.5938C0.65625 19.8438 0.984375 19.9688 1.35938 19.9688H8.67188C9.04688 19.9688 9.35938 19.8438 9.60938 19.5938C9.89062 19.3438 10.0312 19.0312 10.0312 18.6562V3.32812C10.0312 2.95312 9.89062 2.64062 9.60938 2.39062ZM7.91589 3C8.21495 3 8.46418 3.09375 8.66355 3.28125C8.88785 3.44792 9 3.65625 9 3.90625V13L1 15V3.90625C1 3.65625 1.09969 3.44792 1.29907 3.28125C1.52336 3.09375 1.78505 3 2.08411 3H6.60748H7.91589Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'BatteryLowIcon')

export default icon
