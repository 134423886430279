import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import getActionDataToBackend from '@app/helpers/scenes/getActionDataToBackend'
import { POST_ACTION_CHANGESET } from '@app/constants/scenes/currentSceneCreating'
import { saveChangesetAction } from '@app/actions/scenes/editScene'
import { clearCurrentSceneAll } from '@app/actions/scenes/scenesList'
import postAction from '@app/api/scenes/postAction'
import patchScene from '@app/api/scenes/patchScene'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const postConditionChangesetLogic = createLogic({
  type: POST_ACTION_CHANGESET,
  cancelType: API_REQUEST_CANCEL,

  async process(_, dispatch, done) {
    const { secrets: { accessToken: { token: accessToken } } } = store.getState()
    const {
      scenes: {
        currentSceneCreating:
          {
            currentAction: { stateDescription, actionType, actionDeviceStateInfo },
            screenName,
          },
      },
    } = store.getState()
    const actionData = getActionDataToBackend(actionType, stateDescription, actionDeviceStateInfo)
    const { scenes: { scenesList: { currentScene: { id } } } } = store.getState()
    const { scenes: { editScene: { changesetAction } } } = store.getState()
    const changesetData = { changeset: changesetAction }
    dispatch(apiRequestSent(POST_ACTION_CHANGESET))
    try {
      await postAction.postAction(accessToken, id, actionData)
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('postAction failed without error')
      }
    }
    try {
      await patchScene.patchScene(accessToken, id, changesetData)
        .then(() => {
          history.push(screenName)
          dispatch(clearCurrentSceneAll())
          dispatch(saveChangesetAction(''))
          dispatch(apiRequestDone(POST_ACTION_CHANGESET))
        })
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('patchScene failed without error')
      }
    }
    done()
  },
})

export default postConditionChangesetLogic
