import { createLogic } from 'redux-logic'
import { SYNC_NUMBER_OF_ZONES_OPTIONS } from '@app/constants/numberOfZones'
import { deps } from '@app/constants/channels'
import { numberOfZonesNumbers } from '@app/lib/ProgramOptions/index'
import programOptionChannel from '@app/api/programOptions'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setInitialOptions } from '@app/actions/numberOfZones'

const fetchNumberOfZonesOptionsLogic = createLogic({
  type: SYNC_NUMBER_OF_ZONES_OPTIONS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    programOptionChannel({
      request: (channel) => {
        channel.perform('get_program_options', { numbers: numberOfZonesNumbers, ...deps })
        dispatch(apiRequestSent(SYNC_NUMBER_OF_ZONES_OPTIONS))
      },
      response: (data, isFinished) => {
        const { programOptions, error } = data
        if (error && error.msg) {
          dispatch(apiRequestFailed(error.msg, error.code))
        } else {
          dispatch(setInitialOptions(programOptions))
          if (isFinished) {
            dispatch(apiRequestDone(SYNC_NUMBER_OF_ZONES_OPTIONS))
          }
        }
        if (isFinished) {
          done()
        }
      },
    })
  },
})

export default fetchNumberOfZonesOptionsLogic
