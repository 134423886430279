import * as type from '@app/constants/myEnergy/setUpBillingPeriod'

export const set = () => ({
  type: type.SET_SET_UP_BILLING_PERIOD_OPTIONS,
})

export const updateBillingSettings = (settings) => ({
  type: type.UPDATE_BILLING_SETTINGS,
  settings,
})

export const sync = () => ({
  type: type.SYNC_BILLING_SETTING,
})
