import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 40">
      <path fill="currentColor" d="M5.23 19.02a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.32-.08.45.45 0 0 0-.29.16Zm4.25-1.15c1 0 1.98-.29 2.76-.82a.35.35 0 0 0 .07-.55.52.52 0 0 0-.65-.06 3.98 3.98 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.12.1.37.37 0 0 0-.07.14.33.33 0 0 0-.01.15l.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M18.78 9.76V3.02c0-.29-.19-.51-.32-.65a3.26 3.26 0 0 0-.62-.46c-.5-.3-1.2-.6-2.03-.89A20.73 20.73 0 0 0 9.4 0C6.93 0 4.65.38 2.97.93c-.84.27-1.55.6-2.06.96-.48.34-.91.8-.91 1.36v6.51c0 .64.47 1.21 1.03 1.67.59.48 1.39.93 2.29 1.31.84.37 1.79.68 2.76.91.12.55.56.97 1.06 1.25a4.8 4.8 0 0 0 2.3.53c.87 0 1.68-.2 2.3-.53.5-.29.94-.71 1.06-1.26.93-.23 1.84-.53 2.66-.88a9.7 9.7 0 0 0 2.29-1.31c.56-.46 1.03-1.04 1.03-1.69Zm-5.96 3.4.67-.19c.04-.14.07-.28.07-.42 0-1.32-1.85-2.39-4.12-2.39-2.28 0-4.12 1.07-4.12 2.39 0 .15.02.3.06.44l.67.18c.08-.61.54-1.08 1.09-1.39a4.81 4.81 0 0 1 2.3-.53c.87 0 1.68.2 2.3.53.53.3 1 .77 1.08 1.37Zm-7.97-.34C2.45 12.04.46 10.8.46 9.76V7.32c.82-.47 4.25-2.3 8.83-2.31 4.6 0 8.19 1.85 9.02 2.32v2.43c0 1.05-1.92 2.27-4.29 3.05l.02-.26c0-.92-.64-1.65-1.46-2.12a6.38 6.38 0 0 0-3.14-.76c-1.21 0-2.32.28-3.15.76-.82.47-1.46 1.2-1.46 2.12l.02.27ZM.46 3.25C.46 1.95 4.56.46 9.4.46s8.92 1.82 8.92 2.56v1.52c-.8-.48-4.16-2.3-9.02-2.3-4.43 0-7.99 1.84-8.83 2.31v-1.3Zm0 1.94v1.5c.84-.47 4.4-2.32 8.83-2.32 4.86 0 8.22 1.83 9.02 2.3V5.2A19.24 19.24 0 0 0 9.3 2.87 18.23 18.23 0 0 0 .46 5.2Zm11.9 8.15c0 .9-1.3 1.63-2.92 1.63-1.62 0-2.93-.73-2.93-1.63 0-.9 1.3-1.63 2.93-1.63 1.61 0 2.92.73 2.92 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M17.48 38.34v-3.77a.86.86 0 0 0-.57-.8v-.34a.86.86 0 0 0-.86-.86H2.91a.86.86 0 0 0-.86.86v.34a.86.86 0 0 0-.57.8v3.77a.86.86 0 0 0-.57.8v.57c0 .16.13.29.28.29h16.57c.16 0 .29-.13.29-.29v-.57a.86.86 0 0 0-.57-.8ZM2.62 33.43c0-.16.13-.29.29-.29h13.14c.16 0 .29.13.29.29v.28H2.62v-.28Zm-.57 1.14c0-.16.13-.28.29-.28h14.28c.16 0 .29.12.29.28v3.72H2.05v-3.72Zm15.43 4.86h-16v-.29c0-.15.13-.28.29-.28h15.42c.16 0 .29.13.29.28v.29Z"/>
      <path fill="currentColor" d="M16.05 34.86H2.91a.29.29 0 1 0 0 .57h13.14a.29.29 0 1 0 0-.57Zm-.61-3.41h-2.32v-.83c0-.56-.46-1.02-1.02-1.02h-.55v-.45a.6.6 0 0 0-.6-.61h-.57v-.17a.75.75 0 0 0-1.51 0v.17h-.4a.18.18 0 0 0 0 .35h2.48c.14 0 .25.12.25.26v.45H8.04v-.49a.18.18 0 1 0-.34-.04.6.6 0 0 0 0 .08v.45h-.55c-.56 0-1.02.46-1.02 1.02v.83H3.81a.18.18 0 1 0 0 .35h7.44a.18.18 0 1 0 0-.35H6.48v-.83c0-.37.3-.67.67-.67h4.95c.37 0 .67.3.67.67v.83h-.82a.18.18 0 1 0 0 .35h3.49a.18.18 0 1 0 0-.35Zm-6.22-2.91v-.17a.4.4 0 0 1 .8 0v.17h-.8Z"/>
      <path fill="currentColor" d="M10.26 30.21a.18.18 0 0 0 0 .35h.1a.18.18 0 0 0 0-.35h-.1Zm.81 0a.18.18 0 0 0 0 .35h.07a.18.18 0 0 0 0-.35h-.07Zm.88.35a.18.18 0 0 0 0-.35h-.07a.18.18 0 0 0 0 .35h.07ZM9 27a.18.18 0 0 0 .25.24.53.53 0 0 1 .75 0 .18.18 0 0 0 .25-.25A.88.88 0 0 0 9 27Z"/>
      <path fill="currentColor" d="M10.86 26.63a.18.18 0 0 0 0-.25 1.73 1.73 0 0 0-2.47 0 .18.18 0 1 0 .25.25 1.38 1.38 0 0 1 1.97 0c.07.07.18.07.25 0Z"/>
      <path fill="currentColor" d="M7.9 25.9a2.43 2.43 0 0 1 3.44 0 .18.18 0 0 0 .25-.25 2.78 2.78 0 0 0-3.93 0 .18.18 0 0 0 .25.25Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'AuxIcon', '0 0 80 80', {fontSize: 35})

export default icon
