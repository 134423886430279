import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M30 50c-11.03 0-20-8.97-20-20s8.97-20 20-20 20 8.97 20 20-8.97 20-20 20Zm0-38c-9.92 0-18 8.08-18 18s8.08 18 18 18 18-8.08 18-18-8.08-18-18-18Zm0 48a1 1 0 0 1-1-1v-6a1 1 0 0 1 2 0v6a1 1 0 0 1-1 1Zm0-52a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v6a1 1 0 0 1-1 1ZM7 31H1a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2Zm52 0h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2Z"/>
        <path d="M25 45a1 1 0 0 1-.97-1.22L26.74 32H22a1 1 0 0 1-.76-1.65l13-15a1 1 0 0 1 1.73.88L33.26 28H38a1 1 0 0 1 .76 1.65l-13 15A1 1 0 0 1 25 45Zm-.81-15H28a1 1 0 0 1 .97 1.23l-2.1 9.08L35.82 30H32a1 1 0 0 1-.98-1.22l2.1-9.09L24.2 30ZM50.5 51.5a1 1 0 0 1-.7-.29l-4.24-4.24a1 1 0 1 1 1.41-1.41l4.24 4.24a1 1 0 0 1-.7 1.7ZM13.74 14.74a1 1 0 0 1-.71-.3L8.79 10.2a1 1 0 1 1 1.41-1.4l4.24 4.24a1 1 0 0 1-.7 1.7ZM9.5 51.5a1 1 0 0 1-.71-1.7l4.24-4.24a1 1 0 1 1 1.41 1.41l-4.24 4.24a1 1 0 0 1-.7.3Zm36.76-36.76a1 1 0 0 1-.7-1.71l4.24-4.24a1 1 0 1 1 1.41 1.41l-4.24 4.24a1 1 0 0 1-.7.3Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h60v60H0z"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EnergyInfoIcon', '0 0 80 80', {fontSize: 35})

export default icon
