import * as type from '@app/constants/myDevices/channels/zwave/zwaveChannels'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'

export const syncZwaveChannel = (newDevice: IZwaveOrTwrDevice) => ({
  type: type.SYNC_ZWAVE_CHANNEL,
  newDevice,
} as const)

export const syncZwaveChannelSent = () => ({
  type: type.SYNC_ZWAVE_CHANNEL_SENT,
} as const)

export const openZwaveChannel = () => ({
  type: type.OPEN_ZWAVE_CHANNEL,
} as const)

export const closeZwaveChannel = () => ({
  type: type.CLOSE_ZWAVE_CHANNEL,
} as const)

export const setResponseFromZwaveChannel = (channelResponse: string) => ({
  type: type.SET_RESPONSE_FROM_ZWAVE_CHANNEL,
  channelResponse,
} as const)
