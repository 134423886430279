import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M67.8963 86.377C71.7736 86.377 74.9168 83.2338 74.9168 79.3565C74.9168 75.4791 71.7736 72.3359 67.8963 72.3359C64.0189 72.3359 60.8757 75.4791 60.8757 79.3565C60.8757 83.2338 64.0189 86.377 67.8963 86.377Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M35.8533 55.5781L63.9724 71.5702C61.3958 72.6754 59.6121 75.0801 59.3019 77.8665L35.8533 55.5781Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M117.727 75.5592C114.225 53.1378 94.6262 37.774 72.9069 35.7565C51.1875 33.739 29.1504 45.031 20.7406 65.8783C18.7426 70.76 17.6628 75.9685 17.5555 81.2421C17.3968 82.8549 17.4664 84.482 17.7624 86.0752C17.8832 86.5674 18.1158 87.0252 18.4423 87.4128C19.9868 89.2677 21.7604 88.6396 23.6153 88.6396H91.2415C91.3154 88.6396 91.4337 88.5805 91.4337 88.7061V96.7612C91.4337 96.9016 91.6406 97.1751 91.3672 97.1751H22.6398C20.2381 97.1751 18.0063 97.0568 15.7523 95.9409C11.9703 94.0648 9.46086 90.3301 9.15301 86.1196C8.721 80.5555 9.20533 74.9583 10.5867 69.5511C12.8256 60.3239 17.5546 51.8883 24.2582 45.164C39.5261 29.3345 62.6717 23.5334 83.6446 29.0168C104.618 34.5002 121.984 51.0686 125.93 73.0909H125.317C123.35 73.0707 121.411 73.5568 119.686 74.5024L117.727 75.5592Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M121.178 76.3047C121.178 85.8157 126.004 85.9117 130.157 86.8798C134.311 87.8479 137.791 89.8358 139.764 91.8016C141.553 93.4121 142.35 95.8529 141.856 98.2088C141.442 100.559 140.112 103.197 136.143 105.237C138.752 106.936 137.503 109.552 134.554 110.572C138.538 111.851 138.345 114.216 136.912 115.871C135.478 117.526 132.81 118.465 126.765 115.509C126.617 120.586 122.028 126.158 111.298 119.026C106.975 122.315 103.775 122.056 101.114 120.896C98.3489 119.78 96.2652 117.434 95.4833 114.556C94.6334 111.437 95.0473 107.262 100.575 101.726C96.4218 99.8272 95.5572 97.4919 95.4981 95.0754C95.3785 92.6677 96.1492 90.3002 97.6633 88.4243C99.1414 86.7838 101.358 86.03 103.834 85.6309C106.31 85.2319 109.17 85.1727 112.613 84.4042C116.057 83.6356 120.144 82.1872 121.178 76.3047V76.3047Z" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwGasMeterIcon')

export default icon
