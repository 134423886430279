import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_TIMERS } from '@app/constants/timers/timers'
import { setTimers, syncTimerChannel } from '@app/actions/timers/timers'
import timers from '@app/api/timers/timers'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchTimers = createLogic({
  type: SYNC_TIMERS,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SYNC_TIMERS))
    timers(action.accessToken)
      .then((response) => {
        const { data } = response
        dispatch(setTimers(data.timers))
        dispatch(apiRequestDone(SYNC_TIMERS))
        dispatch(syncTimerChannel())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('timers failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchTimers
