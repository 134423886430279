import PropTypes from 'prop-types'

const propTypes = PropTypes.shape({
  today: PropTypes.shape({
    billing: PropTypes.number,
    energy: PropTypes.number,
  }),
  last_week: PropTypes.shape({
    billing: PropTypes.number,
    energy: PropTypes.number,
  }),
  last_month: PropTypes.shape({
    billing: PropTypes.number,
    energy: PropTypes.number,
  }),
  last_3_months: PropTypes.shape({
    billing: PropTypes.number,
    energy: PropTypes.number,
  }),
  last_6_months: PropTypes.shape({
    billing: PropTypes.number,
    energy: PropTypes.number,
  }),
  last_year: PropTypes.shape({
    billing: PropTypes.number,
    energy: PropTypes.number,
  }),
})

export default propTypes
