import React, {
  useState, useEffect, CSSProperties, Dispatch, SetStateAction,
} from 'react'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import {
  mainColors, bigCircleColors, smallCircleColors,
} from '@app/lib/MyDevices/deviceColours'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './Colour.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  pickedColor: string,
  setPickedColor: Dispatch<SetStateAction<string>>,
  setWhiteWarmValue: Dispatch<SetStateAction<number>>,
  setWhiteColdValue: Dispatch<SetStateAction<number>>,
  isMobile: boolean,
}

const Colour: React.FC<Props> = (props) => {
  const {
    currentDevice,
    pickedColor,
    setPickedColor,
    setWhiteWarmValue,
    setWhiteColdValue,
    isMobile,
  } = props
  const classes = useStyles()
  const [isAbleToClickingBigPicker, setIsAbleToClickingBigPicker] = useState('auto')
  const [isAbleToClickingSmallPicker, setIsAbleToClickingSmallPicker] = useState('auto')
  const { white_warm, white_cold } = currentDevice.info?.color_state?.value!

  useEffect(() => {
    setWhiteWarmValue(white_warm)
    setWhiteColdValue(white_cold)
    const handleMouseMove = (event: MouseEvent) => {
      const setCircleClickable = (circleElement: HTMLElement | null, setClickable: (value: string) => void) => {
        if (circleElement) {
          const rect = circleElement.getBoundingClientRect()
          const radius = rect.width / 2
          const centerX = rect.left + radius
          const centerY = rect.top + radius
          const cursorX = event.clientX - centerX
          const cursorY = event.clientY - centerY
          const distance = Math.sqrt(cursorX * cursorX + cursorY * cursorY)
          if (distance <= radius) {
            setClickable('auto')
          } else {
            setClickable('none')
          }
        }
      }
      setCircleClickable(document.getElementById('smallPicker'), setIsAbleToClickingSmallPicker)
      setCircleClickable(document.getElementById('bigPicker'), setIsAbleToClickingBigPicker)
    }
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const renderColorsCircle = (arrayOfColors: Array<string>, size: string) => {
    const colorDegree = 360 / arrayOfColors.length
    let flag = 0
    return arrayOfColors.map((color, index) => {
      if (index !== 0) {
        flag += colorDegree
      }
      return (
        <div
          key={`colorWrap-${index + 1}`}
          className={classes.color}
          style={{
            transform: `rotate(${flag}deg`,
          }}
        >
          <div
            key={`color-${index + 1}`}
            role="presentation"
            className={size === 'big' ? classes.bigPickerElement : classes.smallPickerElement}
            style={{
              backgroundColor: color,
            }}
            onClick={() => {
              setPickedColor(color)
            }}
          />
        </div>
      )
    })
  }

  return (
    <Wrapper className={classes.coloursWrapper}>
      <Wrapper className={classNames(classes.mainColorsWrapper, isMobile && classes.mainColorsWrapperMobile)}>
        {mainColors.map((color, index) => (
          <div
            key={`mainColor-${index + 1}`}
            className={classes.mainColor}
            role="presentation"
            style={{
              backgroundColor: color,
            }}
            onClick={() => {
              setPickedColor(color)
            }}
          >
            <div className={classNames(classes.checked, pickedColor === color && classes.show)} />
          </div>
        ))}
      </Wrapper>
      <Wrapper className={classes.colorPickerWrapper}>
        <div
          id="bigPicker"
          className={classes.bigPicker}
          style={{ pointerEvents: isAbleToClickingBigPicker as CSSProperties['pointerEvents'] }}
        >
          {renderColorsCircle(bigCircleColors, 'big')}
        </div>
        <div className={classes.bigBorder}>
          <div
            id="smallPicker"
            className={classes.smallPicker}
            style={{ pointerEvents: isAbleToClickingSmallPicker as CSSProperties['pointerEvents'] }}
          >
            {renderColorsCircle(smallCircleColors, 'small')}
          </div>
        </div>
        <div className={classes.smallBorder}>
          <div
            className={classes.pickedColor}
            style={{ backgroundColor: pickedColor }}
          />
        </div>
      </Wrapper>
    </Wrapper>
  )
}

export default Colour
