import {
  INotificationSettingsFromBackend,
  INotificationSettingsFrontendMezzo,
  INotificationSettingsFrontendNCentral,
} from '@app/@types/notifications'
import {
  mainEventsSettings,
  mainEventsSettingsNCentral,
  systemEventsSettings,
  zoneEventsSettings,
} from '@app/lib/NotificationsSettings/notificationsSettings'

export const filterNotificationSettings = (notificationSettings: INotificationSettingsFromBackend, isNCentral: boolean): INotificationSettingsFrontendMezzo | INotificationSettingsFrontendNCentral | {} => {
  const fieldsArr = isNCentral
    ? [...mainEventsSettingsNCentral, ...zoneEventsSettings, ...systemEventsSettings]
    : [...mainEventsSettings, ...zoneEventsSettings, ...systemEventsSettings]
  const result = {}
  fieldsArr.forEach((el) => {
    result[el] = notificationSettings[el]
  })
  return result
}
