import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import HealthAlert from '@app/components/HealthAlert/HealthAlert'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'

let enhance = HealthAlert

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  return {
    isMobile,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const HealthAlertEnhanced = enhance

export default HealthAlertEnhanced
