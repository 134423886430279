import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button, FormControl, InputBase, MenuItem, Select, Typography,
} from '@mui/material'
import { ExpandMore } from 'icons'
import {
  pushNewToRequestBody,
  pushNewToRequestHeader,
  saveRequestBody,
  saveRequestHeader,
  saveRequestType,
  saveRequestUrl,
  updateRequestBody,
  updateRequestHeader,
} from '@app/actions/scenes/currentSceneCreating'
import { checkPost, closeErrorPopup } from '@app/actions/scenes/pushNotifications/apiChecking'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CustomInput from '@app/components/Common/CustomInput/CustomInput'
import CustomTextInfo from '@app/components/Common/CustomTextInfo/CustomTextInfo'
import useStyles from './WebRequestFields.style'

const WebRequestFields = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { isErrorPopupOpen, errorText, errorStatus } = useSelector((state) => state.scenes.apiChecking)
  const {
    requestUrl, requestType, requestBody, requestHeader,
  } = useSelector((state) => state.scenes.currentSceneCreating.currentAction)

  useEffect(() => {
    if (requestBody.filter((obj) => obj.key === '' && obj.value === '').length > 1) {
      dispatch(saveRequestBody(requestBody.filter((obj) => obj.key !== '' && obj.value !== '')))
    } else if (checkFullLastDataBlock(requestBody)) {
      dispatch(pushNewToRequestBody())
    }
  }, [requestBody])

  useEffect(() => {
    if (requestHeader.filter((obj) => obj.key === '' && obj.value === '').length > 1) {
      dispatch(saveRequestHeader(requestHeader.filter((obj) => obj.key !== '' && obj.value !== '')))
    } else if (checkFullLastDataBlock(requestHeader)) {
      dispatch(pushNewToRequestHeader())
    }
  }, [requestHeader])

  const onCheckClick = () => {
    dispatch(checkPost())
  }

  const checkFullLastDataBlock = (type) => type[type.length - 1].key.length && type[type.length - 1].value.length

  const requestUrlFieldChange = (event) => {
    dispatch(saveRequestUrl(event.target.value))
  }
  const requestTypeChange = (event) => {
    dispatch(saveRequestType(event.target.value))
  }

  const fieldValueChange = (event, number, entryType, fieldType) => {
    switch (fieldType) {
    case 'header':
      return dispatch(updateRequestHeader(entryType, event.target.value, number))
    case 'body':
      return dispatch(updateRequestBody(entryType, event.target.value, number))
    default:
      throw new Error('fieldType unsupported')
    }
  }

  const handleCloseErrorPopup = () => {
    dispatch(closeErrorPopup())
  }
  const renderBodyDataBlock = (el, number) => (
    <Wrapper key={`${number}-1`}>
      <FormControl
        style={{
          padding: '0 3%',
        }}
        classes={{ root: classes.formControl }}
        variant="outlined"
      >
        <Select
          variant="standard"
          id="request_type_change"
          value={el.value_type}
          onChange={requestTypeChange}
          input={<CustomInput />}
          IconComponent={ExpandMore}
        >
          <MenuItem className={classes.typeMenuItem} value="key_text">{I18n.t('scene_action_web.key_text')}</MenuItem>
          {/*
          <MenuItem className={classes.typeMenuItem} value="key_file">{I18n.t('scene_action_web.key_file')}</MenuItem>
*/}
        </Select>
      </FormControl>
      <Wrapper className={classes.keyValueWrapper}>
        <Wrapper className={classNames(classes.formControl, classes.keyWrapper)}>
          <InputBase
            className={classes.halfInput}
            id="key_body_input"
            placeholder="Key"
            aria-label="Key"
            multiline
            value={el.key}
            onChange={(event) => {
              fieldValueChange(event, number, 'key', 'body')
            }}
          />
        </Wrapper>
        <Wrapper className={classNames(classes.formControl, classes.valueWrapper)}>
          <InputBase
            className={classes.halfInput}
            id="value_body_input"
            placeholder={I18n.t('scene_action_web.value')}
            aria-label={`${I18n.t('scene_action_web.value')}`}
            variant="outlined"
            multiline
            value={el.value}
            onChange={(event) => {
              fieldValueChange(event, number, 'value', 'body')
            }}
          />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )

  const renderHeaderDataBlock = (el, number) => (
    <Wrapper key={`${number}-1`} className={classes.keyValueWrapper}>
      <Wrapper className={classNames(classes.formControl, classes.keyWrapper)}>
        <InputBase
          className={classes.halfInput}
          id="key_header_input"
          placeholder="Key"
          aria-label="Key"
          variant="outlined"
          multiline
          value={el.key}
          onChange={(event) => {
            fieldValueChange(event, number, 'key', 'header')
          }}
        />
      </Wrapper>
      <Wrapper className={classNames(classes.formControl, classes.valueWrapper)}>
        <InputBase
          className={classes.halfInput}
          id="value_header_input"
          placeholder={I18n.t('scene_action_web.value')}
          aria-label={I18n.t('scene_action_web.value')}
          multiline
          value={el.value}
          onChange={(event) => {
            fieldValueChange(event, number, 'value', 'header')
          }}
        />
      </Wrapper>
    </Wrapper>
  )

  return (
    <>
      <Wrapper
        className={classNames(classes.formControl, classes.urlField)}
      >
        <InputBase
          id="request_url_input"
          placeholder={I18n.t('scene_action_web.enter_request_url')}
          aria-label={`${I18n.t('scene_action_web.enter_request_url')}:`}
          multiline
          value={requestUrl}
          onChange={requestUrlFieldChange}
          style={{
            width: '100%',
          }}
        />
      </Wrapper>
      <FormControl
        style={{
          padding: '15px 3%',
        }}
        classes={{ root: classes.formControl }}
        variant="outlined"
      >
        <Select
          variant="standard"
          id="request_type_change"
          value={requestType}
          onChange={requestTypeChange}
          input={<CustomInput />}
          IconComponent={ExpandMore}
          classes={{ root: classes.urlSelect }}
        >
          <MenuItem className={classes.typeMenuItem} value="post">{I18n.t('scene_action_web.post').toUpperCase()}</MenuItem>
          {/*
          <MenuItem className={classes.typeMenuItem} value="get">{I18n.t('scene_action_web.get').toUpperCase()}</MenuItem>
*/}
        </Select>
      </FormControl>
      <Typography className={classes.dataTitle}>
        {I18n.t('scene_action_web.body')}
        :
      </Typography>
      {requestBody.map((el, index) => renderBodyDataBlock(el, index))}
      <Typography className={classes.dataTitle}>
        {I18n.t('scene_action_web.headers')}
        :
      </Typography>
      {requestHeader.map((el, index) => renderHeaderDataBlock(el, index))}
      <CustomTextInfo
        open={isErrorPopupOpen}
        title={errorStatus && `error code ${errorStatus}`}
        warningText={errorText}
        buttonName="buttons.ok"
        onClick={handleCloseErrorPopup}
        disableButton={false}
      />
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={onCheckClick}
      >
        {I18n.t('buttons.check')}
      </Button>
    </>
  )
}

export default WebRequestFields
