import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { authByPassword, hideAccountLockedError, hideSignInError } from '@app/actions/wizard/signIn'
import { loginFields } from '@app/lib/Wizard/wizardFields'
import SignIn from '@app/components/Wizard/SignIn/SignIn'
import { withProgressAndErrorMessage } from '@app/hocs'
import { validate } from './loginValidate'

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { loginError } = state.login.authByPassword
  const { isAccountLocked } = state.login.authByPassword
  const isTokenValid = isAccessTokenValid(state.secrets.accessToken)
  const { secrets: { tokenStatus } } = store.getState()
  return {
    isMobile,
    isTablet,
    path,
    tokenStatus,
    loginError,
    isTokenValid,
    isAccountLocked,
    initialValues: loginFields.reduce((result, option) => {
      result[option.title] = option.value
      return result
    }, {}),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    userLoggedIn: !!((isUserLoggedIn(state.users.currentUser.user.preferredName)) && state.unit.serialNumber),
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  submit: (dispatch) => dispatch(submit('SignIn')),
  hideAccountLockedError,
  hideSignInError,
}, parentDispatch)

let enhance = (SignIn)
enhance = reduxForm({
  form: 'SignIn',
  validate,
  onSubmit: () => {
    store.dispatch(authByPassword())
  },
})(enhance)

enhance = withProgressAndErrorMessage(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SignInEnhanced = enhance

export default SignInEnhanced
