export const RESET_MODE_ON = '@@deviceManager/reset_mode_on'
export const RESET_MODE_OFF = '@@deviceManager/reset_mode_off'

export const RESET_DEVICE_MANAGER = '@@deviceManager/reset'

export const SYNC_DEVICE_ENERGIES = '@@deviceManager/sync'

export const UPDATE_DEVICE_ENERGIES = '@@deviceManager/update'
export const TOGGLE_SELECTED_SORTING = '@@deviceManager/toggleSelectedSorting'
export const ADD_DEVICE_TO_RESET_ARRAY = '@@deviceManager/addDeviceToResetArray'
export const DELETE_DEVICE_FROM_RESET_ARRAY = '@@deviceManager/deleteDeviceFromResetArray'
export const ADD_ALL_DEVICES_TO_RESET_ARRAY = '@@deviceManager/addAllDevicesToResetArray'
export const CLEAR_DEVICES_RESET_ARRAY = '@@deviceManager/clearDevicesResetArray'

export const RESET_DEVICES_ENERGIES = '@@deviceManager/resetDevicesEnergies'
