import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 8338 6430"
         shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality">
      <path className="fil0"
            d="M6539 3906h1296c51-230 183-522 419-524l84-1v1763l-84-1c-236-2-368-294-419-524H6539c-179 243-510 314-774 168-321-179-407-600-184-891l-198-693-2735 781c-293 83-603-4-867-211-323-255-555-673-630-1126l-389 111c-407-235-627-468-757-926-33-131 104-176 104-182L5887 0c1203 880 1344 1479 777 2837l-595 170 198 694c108 40 203 111 272 205z"/>
    </svg>  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CameraIcon')

export default icon
