export const mezzoConditionStates = [
  {
    descriptionUI: 'ui_when_alarm',
    description: 'when_alarm',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'IsInAlarm',
  },
  {
    descriptionUI: 'ui_when_panic',
    description: 'when_panic',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'Panic',
  },
  {
    descriptionUI: 'ui_when_duress',
    description: 'when_duress',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 'Duress',
  },
  {
    title: null,
    descriptionUI: 'ui_arm_system',
    description: 'arm_system',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfArmed',
  },
  {
    title: null,
    descriptionUI: 'ui_arm_home_mode',
    description: 'arm_home_mode',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfArmedHome',
  },
  {
    title: null,
    descriptionUI: 'ui_disarm',
    description: 'disarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfDisarmed',
  },
  {
    title: null,
    descriptionUI: 'ui_when_fire_alarm',
    description: 'when_fire_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenFire',
  },
  {
    title: null,
    descriptionUI: 'ui_health_alarm',
    description: 'health_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenMedicalEvent',
  },
  {
    title: null,
    descriptionUI: 'ui_sound_chime',
    description: 'sound_chime',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'Chime',
  },
  {
    title: null,
    descriptionUI: 'ui_mains_power_missing',
    description: 'mains_power_missing',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfMainsHasFailed',
  },
  {
    title: null,
    descriptionUI: 'ui_when_mains_power_fails',
    description: 'when_mains_power_fails',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenMainsFail',
  },
  {
    title: null,
    descriptionUI: 'ui_mains_power_present',
    description: 'mains_power_present',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfMainsPowerIsPresent',
  },
  {
    title: null,
    descriptionUI: 'ui_when_mains_power_restores',
    description: 'when_mains_power_restores',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenMainsRestores',
  },
  {
    title: null,
    descriptionUI: 'ui_not_armed',
    description: 'not_armed',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'NotArmed',
  },
  {
    title: null,
    descriptionUI: 'ui_not_disarmed',
    description: 'not_disarmed',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'NotDisarmed',
  },
  {
    title: null,
    descriptionUI: 'ui_has_been_armed',
    description: 'has_been_armed',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenArmed',
  },
  {
    title: null,
    descriptionUI: 'ui_has_been_disarmed',
    description: 'has_been_disarmed',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenDisarmed',
  },
  {
    title: null,
    descriptionUI: 'ui_has_been_home_mode',
    description: 'has_been_home_mode',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhenMezzoSetToHomeMode',
  },
  {
    title: null,
    descriptionUI: 'ui_while_in_medical_alarm',
    description: 'while_in_medical_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'WhileInMedicalAlarm',
  },
  {
    title: null,
    descriptionUI: 'ui_sounding_siren',
    description: 'sounding_siren',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'Siren',
  },
  {
    title: null,
    descriptionUI: 'ui_is_in_fire_alarm',
    description: 'is_in_fire_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfPanicDuressFire',
  },
  {
    title: null,
    descriptionUI: 'ui_is_in_panic_alarm',
    description: 'is_in_panic_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfPanicDuressFire',
  },
  {
    title: null,
    descriptionUI: 'ui_is_in_duress_alarm',
    description: 'is_in_duress_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfPanicDuressFire',
  },
  {
    title: null,
    descriptionUI: 'ui_is_not_in_fire_alarm',
    description: 'is_not_in_fire_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfPanicDuressFire',
  },
  {
    title: null,
    descriptionUI: 'ui_is_not_in_panic_alarm',
    description: 'is_not_in_panic_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfPanicDuressFire',
  },
  {
    title: null,
    descriptionUI: 'ui_is_not_in_duress_alarm',
    description: 'is_not_in_duress_alarm',
    isCardDisabled: true,
    isButtonDisabled: true,
    stateType: 'IfPanicDuressFire',
  },
]
