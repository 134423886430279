import React from 'react'
import classNames from 'classnames'
import {
  Button,
  IconButton,
  Menu,
  MenuItem as MaterialMenuItem,
  MenuList,
  Paper,
  Popover,
  Toolbar as MaterialToolbar,
  Typography,
} from '@mui/material'
import {
  AccountCircle, ExpandMore, HomeIcon, Menu as MenuIcon, Edit,
} from 'icons'
import resetPersistent from '@app/helpers/resetPersistent'
import { HOME, SELECT_MEZZO_DEVICE_CONNECTION } from '@app/constants/routes'
import { whitelist } from '@app/store/reducer'
import { whitelistExceptions } from '@app/lib/Toolbar/whitelistExceptionsWhenChangingUnitDevice'
import MenuItem from '@app/components/Menu/MenuItem'
import getPristineValue from '@app/helpers/scenes/getPristineValue'
import elementsData from '@app/lib/Menu/ElementsData'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { Activation, defaultConfig, States } from '@app/helpers/convertAppConfig'
import PropTypes from '../PropTypes'
import Wrapper from '../Common/Wrapper/Wrapper'
import WarningComponent from '../Common/Warning/WarningComponent'
import { closeAllTabs } from '../../actions/menu'
import EditNamePopup from './EditNamePopup/EditNamePopup'

class Toolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorContextMenu: null,
      warningPopupOpen: false,
      changeUnitPopupOpen: false,
      selectedDeviceSsid: '',
      selectedDeviceSerialNumber: '',
      selectedDeviceName: '',
      userRole: '',
    }

    this.unitSwitchRef = React.createRef()
    this.contextMenuRef = React.createRef()
  }

  onClickAwayUnitsList = () => {
    const { closeUnitList } = this.props
    closeUnitList()
  }

  renderUnitsDropDownList=() => {
    const {
      classes,
      units,
      unit: { ssid },
      isUnitListOpen,
    } = this.props
    return (
      <Popover
        open={isUnitListOpen}
        anchorEl={this.unitSwitchRef.current}
        onClose={this.onClickAwayUnitsList}
        className={classes.unitsList}
      >
        <Paper>
          <MenuList
            className={classes.list}
          >
            { units.map((unit) => {
              const showEditButton = checkIfAdmin(unit.unit_user.role) && unit.activation === Activation.Activated
              return (
                <Wrapper key={unit.serial_number} className={classes.listItem}>
                  {showEditButton && (
                    <IconButton onClick={() => this.onEditNameClickOpenPopup(unit)}>
                      <Edit />
                    </IconButton>
                  )}
                  <MaterialMenuItem
                    key={unit.ssid}
                    onClick={() => this.changeUnitPopupOpen(unit.ssid, unit.serial_number, unit.unit_user.role)}
                    disabled={ssid === unit.ssid}
                    style={{ paddingLeft: showEditButton ? 0 : 40 }}
                  >
                    <Typography>
                      {unit.name}
                      {'\u00A0'}
                    </Typography>
                    <Typography variant="body2">{`(${unit.ssid})`}</Typography>
                  </MaterialMenuItem>
                </Wrapper>
              )
            })}
          </MenuList>
        </Paper>
      </Popover>
    )
  }

  renderUnitSwitch = () => {
    const {
      classes,
      units,
      unit: {
        ssid, name, serialNumber, activation,
      },
      isMobile,
      admin,
      isUnitListOpen,
    } = this.props
    const currentUnit = { ssid, serial_number: serialNumber, name }
    const hasMultiUnits = units.length > 1
    const showEditButton = !hasMultiUnits && admin && activation === Activation.Activated
    return (
      <Wrapper className={classNames(isMobile && classes.mobileWrapper)}>
        <Button
          ref={this.unitSwitchRef}
          aria-owns={isUnitListOpen ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={this.onUnitSwitchToggle}
          className={isMobile ? classes.ssidButtonMobile : classes.ssidButton}
        >
          <span className={classNames(classes.nameText, isMobile && classes.nameMobileText)}>
            { showEditButton && (
              <IconButton onClick={() => this.onEditNameClickOpenPopup(currentUnit)}>
                <Edit />
              </IconButton>
            )}
            {name}
            {!isMobile && '\u00A0'}
          </span>
          <span className={classNames(isMobile && classes.ssidMobileText)}>
            {`(${ssid})`}
          </span>
          { hasMultiUnits && (
            <ExpandMore className={classes.expandIcon} color="action" />
          )}
        </Button>
        {hasMultiUnits && this.renderUnitsDropDownList()}
      </Wrapper>
    )
  }

  renderContextMenu = () => {
    const { anchorContextMenu } = this.state
    const { classes, appConfig, navigate } = this.props
    const isContextMenuOpen = Boolean(anchorContextMenu)
    const secretsConfig = appConfig.menu && appConfig.menu.toolbar ? appConfig.menu.toolbar[0] : defaultConfig.menu.toolbar[0]
    return (
      <div>
        <IconButton
          classes={{
            root: classes.iconRoot,
          }}
          ref={this.contextMenuRef}
          aria-owns={isContextMenuOpen ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.onContextMenuOpen}
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={this.contextMenuRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isContextMenuOpen}
          onClose={this.onContextMenuClose}
          onClick={this.onContextMenuClose}
        >
          <MenuItem
            title={secretsConfig.code}
            open={() => {
              navigate(elementsData[secretsConfig.code].path)
            }}
            close={() => store.dispatch(closeAllTabs())}
            disabled={secretsConfig.state === States.Disabled}
          />
          <MaterialMenuItem
            className={classes.logout}
            onClick={this.onLogoutClickOpenPopup}
          >
            {I18n.t('titles.logout')}
          </MaterialMenuItem>
        </Menu>
      </div>
    )
  }

  renderConfirmationPopup = () => {
    const { warningPopupOpen } = this.state
    return (
      <WarningComponent
        open={warningPopupOpen}
        onClose={this.onLogoutClickClose}
        onClick={this.onAcceptClickClose}
        warningText="confirmations.warning_popup_text_logout"
        leftButton="warning_popup_accept_button"
        rightButton="warning_popup_decline_button"
      />
    )
  }

  renderChangeUnitConfirmationPopup = () => {
    const { changeUnitPopupOpen, selectedDeviceSsid } = this.state
    return (
      <WarningComponent
        open={changeUnitPopupOpen}
        onClose={this.changeUnitCanceled}
        onClick={this.changeUnitAccepted}
        warningText="confirmations.switch_mezzo_web"
        leftButton="warning_popup_accept_button"
        rightButton="warning_popup_decline_button"
        ssid={selectedDeviceSsid}
      />
    )
  }

  renderEditNamePopup =() => {
    const { selectedDeviceSsid, selectedDeviceSerialNumber, selectedDeviceName } = this.state
    const { patchUnitName } = this.props
    return (
      <EditNamePopup
        ssid={selectedDeviceSsid}
        oldName={selectedDeviceName}
        serialNumber={selectedDeviceSerialNumber}
        onCloseIconClick={this.handleCloseIconClick}
        onClick={patchUnitName}
      />
    )
  }

  handleCloseIconClick =() => {
    const { closeEditUnitNamePopup } = this.props
    closeEditUnitNamePopup()
  }

  changeUnitCanceled = () => {
    const { selectUnit, unit: { serialNumber, ssid }, units } = this.props
    selectUnit(serialNumber)
    const currentRole = units.find((unit) => unit.serial_number === serialNumber).unit_user.role
    this.setState({ changeUnitPopupOpen: false, selectedDeviceSsid: ssid, userRole: currentRole })
  }

  changeUnitAccepted = () => {
    const {
      patchUnit, updateCurrentUserRole, closeUnitList,
    } = this.props
    const { userRole } = this.state
    closeUnitList()
    this.setState({ changeUnitPopupOpen: false })
    updateCurrentUserRole(userRole)
    patchUnit()
    whitelist.forEach((target) => {
      if (!whitelistExceptions.includes(target)) {
        store.dispatch(resetPersistent(target))
      }
    })
    global.cable.disconnect()
    global.cable.connect()
  }

  changeUnitPopupOpen = (ssid, unit, role) => {
    const { selectUnit } = this.props
    selectUnit(unit)
    this.setState({ selectedDeviceSsid: ssid, userRole: role, changeUnitPopupOpen: true })
  }

  onEditNameClickOpenPopup = (unit) => {
    const { openEditUnitNamePopup } = this.props
    openEditUnitNamePopup()
    this.setState({
      selectedDeviceSsid: unit.ssid,
      selectedDeviceSerialNumber: unit.serial_number,
      selectedDeviceName: unit.name,
    })
  }

  onAcceptClickClose = () => {
    const { onSignOut } = this.props
    this.setState({ warningPopupOpen: false })
    onSignOut()
  }

  onLogoutClickOpenPopup = () => {
    this.setState({ warningPopupOpen: true })
  }

  onLogoutClickClose = () => {
    this.setState({ warningPopupOpen: false })
  }

  onContextMenuOpen = (event) => {
    this.setState({ anchorContextMenu: event.currentTarget })
  }

  onContextMenuClose = () => {
    this.setState({ anchorContextMenu: null })
  }

  onUnitSwitchToggle = () => {
    const {
      syncDevices, isUnitListOpen, closeUnitList, openUnitList,
    } = this.props
    if (isUnitListOpen) {
      closeUnitList()
    } else {
      openUnitList()
    }
    syncDevices()
  }

  clearSceneData = () => {
    const {
      clearCurrentSceneInfo,
      sceneMode,
      saveChangesetAction,
      patchChangeset,
      saveScreenName,
      editedSceneInitialName,
      editedSceneInitialActions,
      editedSceneInitialConditions,
      sceneName,
      actions,
      conditions,
    } = this.props
    const isScreenPristine = getPristineValue(editedSceneInitialName, editedSceneInitialActions,
      editedSceneInitialConditions, sceneName, actions, conditions)
    if (sceneMode === 'editDraftScene' || sceneMode === 'editMezzoScene') {
      if (!isScreenPristine) {
        saveChangesetAction('cancel')
        saveScreenName('HOME')
        patchChangeset()
      }
    }

    clearCurrentSceneInfo()
  }

  goHome = () => {
    const { navigate } = this.props
    navigate(HOME)
  }

  render() {
    const {
      classes,
      menuOpen,
      onMenuOpen,
      auth,
      logo,
      isEditNamePopupOpen,
    } = this.props
    return (
      <Wrapper>
        { (window.location.pathname !== SELECT_MEZZO_DEVICE_CONNECTION && auth) && (
          <MaterialToolbar className={classes.root} disableGutters={!menuOpen}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onMenuOpen}
              className={classNames(
                menuOpen && classes.menuButtonHidden,
              )}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Wrapper
              className={classes.homeButton}
              onClick={this.goHome}
            >
              <IconButton
                color="inherit"
                className={classNames(
                  menuOpen && classes.menuButtonHidden,
                )}
                onClick={this.clearSceneData}
                size="large"
              >
                <HomeIcon />
              </IconButton>
            </Wrapper>
            {logo}
            {this.renderUnitSwitch()}
            {this.renderContextMenu()}
            {this.renderConfirmationPopup()}
            {this.renderChangeUnitConfirmationPopup()}
            {isEditNamePopupOpen && this.renderEditNamePopup()}
          </MaterialToolbar>
        )}
      </Wrapper>
    )
  }
}

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.bool,
  unit: PropTypes.unit,
  selectUnit: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func,
  logo: PropTypes.node.isRequired,
  menuOpen: PropTypes.bool,
  onSignOut: PropTypes.func.isRequired,
  clearCurrentSceneInfo: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.shape({
    serial_number: PropTypes.string,
    ssid: PropTypes.string,
  })).isRequired,
  patchUnit: PropTypes.func.isRequired,
  syncDevices: PropTypes.func.isRequired,
  updateCurrentUserRole: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sceneMode: PropTypes.string.isRequired,
  saveChangesetAction: PropTypes.func.isRequired,
  patchChangeset: PropTypes.func.isRequired,
  saveScreenName: PropTypes.func.isRequired,
  patchUnitName: PropTypes.func.isRequired,
  openEditUnitNamePopup: PropTypes.func.isRequired,
  closeEditUnitNamePopup: PropTypes.func.isRequired,
  editedSceneInitialName: PropTypes.string.isRequired,
  isEditNamePopupOpen: PropTypes.bool.isRequired,
  admin: PropTypes.bool.isRequired,
  editedSceneInitialConditions: PropTypes.arrayOf(PropTypes.condition),
  editedSceneInitialActions: PropTypes.arrayOf(PropTypes.action),
  sceneName: PropTypes.string,
  conditions: PropTypes.arrayOf(PropTypes.condition),
  actions: PropTypes.arrayOf(PropTypes.action),
  appConfig: PropTypes.appConfig.isRequired,
  isUnitListOpen: PropTypes.bool.isRequired,
  closeUnitList: PropTypes.func.isRequired,
  openUnitList: PropTypes.func.isRequired,
}

Toolbar.defaultProps = {
  auth: false,
  unit: null,
  onMenuOpen: null,
  menuOpen: false,
  editedSceneInitialConditions: [],
  editedSceneInitialActions: [],
  conditions: [],
  actions: [],
  sceneName: null,
}

Toolbar.displayName = 'Toolbar'

export default Toolbar
