import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  saveConditionAnotherSceneNumber,
  saveConditionDescription,
  saveConditionDeviceName,
  saveConditionDeviceNumber,
  saveConditionStateDescription,
  saveConditionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectAnotherSceneCondition
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/SelectAnotherSceneCondition/SelectAnotherSceneCondition'
import SelectAnotherSceneConditionIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/SelectAnotherSceneCondition/SelectAnotherSceneCondition'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import { syncScenesForAnotherSceneCondition } from '@app/actions/scenes/anotherScenesList'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectAnotherSceneConditionIOS) : withStyles(SelectAnotherSceneCondition)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { scenes } = state.scenes.anotherScenesList
  const { isEditConditionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    scenes,
    isEditConditionMode,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncScenesForAnotherSceneCondition,
  tryAgain: syncScenesForAnotherSceneCondition,
  notifications: syncNotifications.sync,
  saveConditionType,
  saveConditionDescription,
  saveConditionAnotherSceneNumber,
  saveConditionDeviceNumber,
  saveConditionStateDescription,
  saveConditionDeviceName,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectAnotherSceneConditionEnhanced = enhance

export default withRouter(SelectAnotherSceneConditionEnhanced)
