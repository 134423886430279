const secondsToReadableTime = (elapsedTime: number, timestamp: string): string => {
  const hours = Math.floor(elapsedTime / 3600)
  const minutes = Math.floor((elapsedTime % 3600) / 60)
  const seconds = Math.floor(elapsedTime % 3600 % 60)

  const displaySeconds = (seconds || seconds === 0) && (seconds < 3) ? 'just now' : `${seconds} secs ago`
  const displayMinutes = minutes && minutes === 1 ? '1 min ago' : `${minutes} mins ago`
  const displayHours = hours && hours === 1 ? '1 hour ago' : `${hours} hours ago`
  const displayDate = hours && hours > 24 ? `${new Date(timestamp).toLocaleDateString()}` : displayHours

  if (displayDate && hours > 0) {
    return displayDate
  } if (displayHours && hours > 0) {
    return displayHours
  } if (displayMinutes && minutes > 0) {
    return displayMinutes
  } if (displaySeconds && seconds >= 0) {
    return displaySeconds
  } return `${I18n.t('my_devices.not_available')}`
}

export const readableElapsedTime = (timestamp: string): string => {
  const lastEvent = new Date(timestamp).getTime()
  const currentTime = Date.now()
  const elapsed = (currentTime - lastEvent) / 1000
  return `${secondsToReadableTime(elapsed, timestamp)}`
}
