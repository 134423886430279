import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import { syncTimers } from '@app/actions/timers/timers'
import { saveActionDescription, saveActionDeviceNumber } from '@app/actions/scenes/currentSceneCreating'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectTimer
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/MezzoActions/TimersActions/SelectTimer/SelectTimer'
import SelectTimerIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/MezzoActions/TimersActions/SelectTimer/SelectTimer'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTimerIOS) : withStyles(SelectTimer)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { timers } = state.timers
  return {
    isMobile,
    timers,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveActionDeviceNumber,
  saveActionDescription,
  refresh: syncTimers,
  tryAgain: syncTimers,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTimerEnhanced = enhance

export default withRouter(SelectTimerEnhanced)
