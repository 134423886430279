const styles = (theme) => ({
  root: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 290,
  },
  titleWrapper: {
    display: 'flex',
  },
  titleDevice: {
    width: 140,
    wordWrap: 'break-word',
  },
  titleIcon: {
    marginRight: 5,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  checkbox: {
    margin: 0,
    padding: 0,
    marginLeft: 20,
  },
  fakeCheckbox: {
    width: 24,
    marginLeft: 20,
  },
})

export default styles
