import React from 'react'
import { SCENES } from '@app/constants/routes'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import { useDispatch } from '@app/store'
import { postActionChangeset, postConditionChangeset, saveScreenName } from '@app/actions/scenes/currentSceneCreating'
import {
  closeEditDraftSavePopup, closeEditSceneQuitPopup, patchChangeset, saveChangesetAction,
} from '@app/actions/scenes/editScene'
import { saveCurrentSceneStatus } from '@app/actions/scenes/scenesList'

interface Props {
  isEditDraftSavePopupOpen: boolean,
  isEditSceneQuitPopupOpen: boolean,
  sceneMode: string,
  isAdditionalConditionMode: boolean,
  isAdditionalActionMode: boolean,
}

const EditPopups: React.FC<Props> = (props) => {
  const {
    sceneMode,
    isAdditionalConditionMode,
    isAdditionalActionMode,
    isEditDraftSavePopupOpen,
    isEditSceneQuitPopupOpen,
  } = props
  const dispatch = useDispatch()

  const saveDraftAccepted = () => {
    dispatch(saveScreenName(SCENES))
    dispatch(saveChangesetAction('apply'))
    dispatch(closeEditDraftSavePopup())
    if (sceneMode === 'editDraftScene' || sceneMode === 'editMezzoScene') {
      if (isAdditionalConditionMode) {
        dispatch(postConditionChangeset())
      } else if (isAdditionalActionMode) {
        dispatch(postActionChangeset())
      } else {
        dispatch(patchChangeset())
      }
    } else {
      dispatch(patchChangeset())
    }
  }

  const saveDraftDeclined = () => {
    dispatch(saveChangesetAction('cancel'))
    dispatch(closeEditDraftSavePopup())
    dispatch(saveScreenName(SCENES))
    dispatch(patchChangeset())
  }

  const quitAccepted = () => {
    dispatch(saveChangesetAction('cancel'))
    dispatch(saveCurrentSceneStatus('incomplete'))
    dispatch(closeEditSceneQuitPopup())
    dispatch(saveScreenName(SCENES))
    dispatch(patchChangeset())
  }

  const quitDeclined = () => {
    dispatch(closeEditSceneQuitPopup())
  }

  const onCloseIconClick = () => {
    dispatch(closeEditSceneQuitPopup())
    dispatch(closeEditDraftSavePopup())
  }

  return (
    <>
      <WarningComponent
        open={isEditDraftSavePopupOpen}
        onClose={() => {
          saveDraftDeclined()
        }}
        withCloseIcon
        onCloseIconClick={onCloseIconClick}
        onClick={saveDraftAccepted}
        warningText="scene_web.edit_draft_popup"
        leftButton="yeah"
        rightButton="nope"
      />
      <WarningComponent
        open={isEditSceneQuitPopupOpen}
        onClose={() => {
          quitDeclined()
        }}
        withCloseIcon
        onCloseIconClick={onCloseIconClick}
        onClick={quitAccepted}
        warningText="scene_web.edit_scene_popup"
        leftButton="yeah"
        rightButton="nope"
      />
    </>
  )
}

export default EditPopups
