import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { Visibility, VisibilityOff } from 'icons'

import styles from './PasswordField.style'

class PasswordField extends Component {
  state = {
    showPassword: false,
  }

  handleClickShowPassword=() => {
    const {
      showPassword,
    } = this.state
    this.setState({ showPassword: !showPassword })
  }

  createAdorment =() => {
    const {
      showPassword,
    } = this.state
    const {
      classes,
    } = this.props
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={this.handleClickShowPassword}
          size="large"
        >
          {showPassword ? <Visibility className={classes.icon} /> : <VisibilityOff className={classes.icon} />}
        </IconButton>
      </InputAdornment>
    )
  }

  createTextField =() => {
    const {
      showPassword,
    } = this.state
    const {
      input,
      title,
    } = this.props
    return (
      <TextField
        type={showPassword ? 'text' : 'password'}
        onChange={(event, index, value) => input.onChange(value)}
        variant="outlined"
        label={title}
        InputProps={{
          endAdornment: this.createAdorment(),
          ...input,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  }

  render() {
    const {
      meta: { touched, error },
      classes,
      hint,
    } = this.props
    return (
      <FormControl variant="standard" className={classes.input}>
        { hint
          ? <Tooltip title={hint}>{this.createTextField()}</Tooltip>
          : this.createTextField() }
        {touched && error && (
          <FormHelperText id="component-error-text" className={classes.text}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

PasswordField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  hint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default (withStyles(styles)(PasswordField))
