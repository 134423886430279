import { createLogic } from 'redux-logic'
import { CLOSE_DELETE_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { DELETE_TWR_DEVICE_CHANNEL, DELETE_ZWAVE_DEVICE_CHANNEL } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeDeleteDeviceLogic = createLogic({
  type: CLOSE_DELETE_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(
      DELETE_TWR_DEVICE_CHANNEL,
    ) && sub.unsubscribe())
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(
      DELETE_ZWAVE_DEVICE_CHANNEL,
    ) && sub.unsubscribe())
  },
})

export default unsubscribeDeleteDeviceLogic
