import React from 'react'
import classNames from 'classnames'
import {
  Button, FormControl, Paper, RadioGroup, Typography,
} from '@mui/material'
import { lightsInfo } from '@app/lib/Scenes/mezzoLightsColors'
import {
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_MEZZO_ACTION_STATE_TYPE,
  SELECT_MEZZO_LIGHTS_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'
import LightBlock from './LightBlock/LightBlock'
import Arrow from './Arrow/Arrow'

class SelectMezzoLightsActionStateValue extends React.Component {
  state = {
    colorValue: '#0BFC15',
    isTopBulbs: true,
  }

  toggleBulbsButtons=() => {
    const { isTopBulbs } = this.state
    this.setState({ isTopBulbs: !isTopBulbs })
  }

  onBackClick = () => {
    const {
      navigate,
      isChangeActionStateMode,
      isChangeActionMode,
      isEditActionMode,
      stateDescription,
      restoreActionInfo,
      actions,
      initialAction,
    } = this.props
    if (isEditActionMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    } else if (isChangeActionMode || isChangeActionStateMode) {
      navigate(CREATE_ACTION_SELECTED)
    } else if (stateDescription !== 'none') {
      navigate(SELECT_MEZZO_LIGHTS_ACTION_STATE_SELECTED)
    } else {
      navigate(SELECT_MEZZO_ACTION_STATE_TYPE)
    }
  }

  onAddValueClick=() => {
    const {
      navigate,
      saveActionStateDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      isChangeActionStateMode,
      isChangeActionMode,
      saveActionType,
    } = this.props
    const {
      colorValue,
      isTopBulbs,
    } = this.state
    saveActionStateDescription((lightsInfo.filter((el) => el.color === colorValue))[0].value)
    saveActionType(isTopBulbs ? 'MezzoTopLightColor' : 'MezzoBottomLightColor')
    if (isChangeActionStateMode || isChangeActionMode) {
      saveActionDuration({})
      saveActionDurationDescription('')
      saveActionDurationValueAfter('none')
    }
    navigate(SELECT_MEZZO_LIGHTS_ACTION_STATE_SELECTED)
  }

  changeRadio=(event) => {
    this.setState({ colorValue: event.target.value })
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const {
      colorValue,
      isTopBulbs,
    } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_action_web.colour_settings')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <FormControl variant="standard" component="fieldset" className={classes.group}>
              <RadioGroup
                className={classes.allGroup}
                aria-label="colors"
                name="colors"
                value={colorValue}
                onChange={(event) => {
                  this.changeRadio(event)
                }}
              >
                {lightsInfo.map((light) => (
                  <LightBlock
                    color={light.color}
                    key={light.color}
                    off={light.off}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Wrapper className={classes.allBulbsWrapper}>
              <Button
                classes={{
                  root: classes.bulbsWrapperTop,
                  label: classes.bulbsWrapperTopLabel,
                }}
                disabled={isTopBulbs}
                onClick={this.toggleBulbsButtons}
              >
                <Arrow trend="top" disabled={!isTopBulbs} />
                <Typography
                  className={classes.buttonBulbs}
                >
                  {I18n.t('buttons.top_bulbs')}
                </Typography>
              </Button>
              <Button
                className={classNames(
                  classes.bulbsWrapperBottom,
                )}
                disabled={!isTopBulbs}
                onClick={this.toggleBulbsButtons}
              >
                <Typography
                  className={classes.buttonBulbs}
                >
                  {I18n.t('buttons.bottom_bulbs')}
                </Typography>
                <Arrow trend="bottom" disabled={isTopBulbs} />
              </Button>
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddValueClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoLightsActionStateValue.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isChangeActionMode: PropTypes.bool.isRequired,
  saveActionType: PropTypes.func.isRequired,
  stateDescription: PropTypes.string.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectMezzoLightsActionStateValue.defaultProps = {

}

SelectMezzoLightsActionStateValue.displayName = 'SelectMezzoLightsActionStateValueIOS'

export default SelectMezzoLightsActionStateValue
