import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 15">
      <path fill="currentColor" d="M22.5 6.25H4.79l4.47-4.49L7.5 0 0 7.5 7.5 15l1.76-1.76L4.8 8.75h17.7v-2.5Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ScenesArrowBack')

export default icon
