import update from 'immutability-helper'
import * as type from '@app/constants/scenes/counter'

export const initialState = {
  day: 0,
  hour: 0,
  minute: 0,
  second: 0,
}

export default function counter(state = initialState, action) {
  switch (action.type) {
  case type.INC_DAYS:
    return { ...state, day: state.day + 1 }
  case type.DEC_DAYS:
    return { ...state, day: state.day - 1 }
  case type.INC_HOURS:
    return { ...state, hour: state.hour + 1 }
  case type.DEC_HOURS:
    return { ...state, hour: state.hour - 1 }
  case type.INC_MINUTES:
    return { ...state, minute: state.minute + 1 }
  case type.DEC_MINUTES:
    return { ...state, minute: state.minute - 1 }
  case type.INC_SECONDS:
    return { ...state, second: state.second + 1 }
  case type.DEC_SECONDS:
    return { ...state, second: state.second - 1 }

  case type.MAX_DAYS:
    return update(state, {
      day: { $set: 3 },
    })
  case type.MAX_HOURS:
    return update(state, {
      hour: { $set: 23 },
    })
  case type.MAX_MINUTES:
    return update(state, {
      minute: { $set: 59 },
    })
  case type.MAX_SECONDS:
    return update(state, {
      second: { $set: 59 },
    })

  case type.RESET_DAYS:
    return { ...state, day: initialState.day }
  case type.RESET_HOURS:
    return { ...state, hour: initialState.hour }
  case type.RESET_MINUTES:
    return { ...state, minute: initialState.minute }
  case type.RESET_SECONDS:
    return { ...state, second: initialState.second }

  case type.RESET_COUNTER:
    return initialState

  default:
    return state
  }
}
