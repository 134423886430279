import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { restoreActionInfo } from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectActionGroupStateType
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/GroupActions/SelectActionGroupStateType/SelectActionGroupStateType'
import SelectActionGroupStateTypeIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/GroupActions/SelectActionGroupStateType/SelectActionGroupStateType'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectActionGroupStateTypeIOS) : withStyles(SelectActionGroupStateType)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const {
    isChangeActionMode,
    isChangeActionStateMode,
    isEditActionMode,
    initialAction,
  } = state.scenes.currentSceneCreating
  const { actions } = state.scenes.currentSceneConditionsActions
  return {
    isChangeActionMode,
    isChangeActionStateMode,
    isEditActionMode,
    isMobile,
    actions,
    initialAction,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  restoreActionInfo,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectActionGroupStateTypeEnhanced = enhance

export default withRouter(SelectActionGroupStateTypeEnhanced)
