import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_PIN_CODE } from '@app/constants/users/secretCode'
import { sync } from '@app/actions/users/currentUser'
import { closeChangePinPopup } from '@app/actions/users/secretCode'
import Api from '@app/api/users/changePinCode'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitPinCode = createLogic({
  type: SET_PIN_CODE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { users: { currentUser: { user: { ids: { sphere: sphereId } } } } } = getState()
    const { form: { PinCodePopup: { values: { new_pin_code: newCode } } } } = getState()
    const formData = { new_pin: newCode }
    next({
      ...action,
      formData,
      token,
      sphereId,
      newCode,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_PIN_CODE))
    Api.changePinCode(action.token, action.sphereId, action.formData)
      .then(() => {
        dispatch(apiRequestDone(SET_PIN_CODE))
        dispatch(closeChangePinPopup())
        dispatch(sync())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('SET_PIN_CODE failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitPinCode
