import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  restoreActionInfo,
  saveActionDelay,
  saveActionDelayDescription,
  saveActionDeviceStateInfo,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveActionStateDescription,
  saveActionType,
  saveDurationValueAfterActionColorType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectDoorlockActionStateValue
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateAction/DoorlockActions/SelectDoorlockActionStateValue/SelectDoorlockActionStateValue'
import SelectDoorlockActionStateValueIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateAction/DoorlockActions/SelectDoorlockActionStateValue/SelectDoorlockActionStateValue'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectDoorlockActionStateValueIOS) : withStyles(SelectDoorlockActionStateValue)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const {
    isChangeActionStateMode, isChangeActionMode, isEditActionMode, initialAction,
  } = state.scenes.currentSceneCreating
  const { actionDeviceNumber, stateDescription } = state.scenes.currentSceneCreating.currentAction
  const { actions } = state.scenes.currentSceneConditionsActions
  return {
    isMobile,
    path,
    isChangeActionStateMode,
    isChangeActionMode,
    isEditActionMode,
    actionDeviceNumber,
    stateDescription,
    actions,
    initialAction,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveActionStateDescription,
  saveActionType,
  saveActionDeviceStateInfo,
  saveActionDelay,
  saveActionDelayDescription,
  saveActionDuration,
  saveActionDurationDescription,
  saveActionDurationValueAfter,
  saveDurationValueAfterActionColorType,
  restoreActionInfo,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectDoorlockActionStateValueEnhanced = enhance

export default withRouter(SelectDoorlockActionStateValueEnhanced)
