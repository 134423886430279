import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import getConditionDataForBackend from '@app/helpers/scenes/getConditionDataForBackend'
import { POST_CONDITION } from '@app/constants/scenes/currentSceneCreating'
import Api from '@app/api/scenes/postCondition'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const postConditionLogic = createLogic({
  type: POST_CONDITION,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { scenes: { scenesList: { currentScene: { id } } } } = getState()
    const {
      scenes: {
        currentSceneCreating: {
          currentCondition,
          screenName,
        },
      },
    } = store.getState()
    const conditionData = getConditionDataForBackend(currentCondition)
    next({
      ...action,
      accessToken,
      id,
      conditionData,
      screenName,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_CONDITION))
    Api.postCondition(action.accessToken, action.id, action.conditionData)
      .then(() => {
        dispatch(apiRequestDone(POST_CONDITION))
        history.push(action.screenName)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('postCondition failed without error')
        }
      })
      .then(() => done())
  },
})

export default postConditionLogic
