import React from 'react'
import getContrastColor from './getContrastColor'

const descriptionTransformer = (description) => {
  const array = description.split(' ௐcolor')
  return array.map((color, index) => {
    if (color.startsWith('(')) {
      const rgb = color.split(' ')[0]
      const contrastColor = getContrastColor(`rgba${rgb}`)
      const coloredBlock = (
        <span style={{
          display: 'inline-block',
          width: 10,
          height: 10,
          margin: '0px 5px',
          backgroundColor: `rgba${rgb}`,
          border: `1px solid ${contrastColor}`,
        }}
        />
      )
      const newArray = color.split(',1)')
      newArray.unshift(coloredBlock)
      return (
        <React.Fragment
          key={`color-${index + 1}`}
        >
          {newArray[0]}
          {newArray[2]}
        </React.Fragment>
      )
    }
    return color.concat(' ')
  })
}

export default descriptionTransformer
