import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.0969 10.8685V16.8189C4.0969 16.9965 4.08497 18.3012 4.11677 18.4809C4.21988 19.0486 4.6906 19.4518 5.22783 19.4327H8.31851V14.9581C8.31851 14.8537 8.35704 14.7536 8.42563 14.6798C8.49421 14.606 8.58723 14.5645 8.68423 14.5645H11.3178C11.5197 14.5645 11.6835 14.7407 11.6835 14.9581V19.4327H14.7722C15.3113 19.4513 15.7827 19.0447 15.8832 18.4745C15.915 18.2948 15.9031 16.9922 15.9031 16.8147V10.8685M2 11.7754L8.66435 4.06032C9.00956 3.6605 9.49384 3.43335 10.001 3.43335C10.5082 3.43335 10.9924 3.6605 11.3376 4.06032L18 11.7754" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'HomeMenuIcon')

export default icon
