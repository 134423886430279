import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  card: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 160,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: 160,
    padding: '20px 16px',
    borderRadius: 5,
    marginBottom: 40,
    cursor: 'pointer',
  },
  cardMobile: {
    width: '100%',
  },
  cardTopSide: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 30px 0',
  },
  cardBottomSide: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
  },
  cardIcon: {
    backgroundColor: theme.backgrounds.componentBackground,
    width: 50,
    height: 36,
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
  },
  switchRoot: {
    margin: 0,
  },
  cardStatus: {
    fontSize: '15px',
  },
  cardStatusOn: {
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  cardStatusOff: {
    fontSize: '20px',
    color: theme.palette.error.main,
  },
}))

export default useStyles
