import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_UNITS } from '@app/constants/units'
import { update } from '@app/actions/units'
import Api from '@app/api/fetchUnits'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchUnitsLogic = createLogic({
  type: SYNC_UNITS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_UNITS))
    Api.fetchUnits(token)
      .then((response) => {
        const { data } = response
        dispatch(update(data.unit_devices))
        dispatch(apiRequestDone(SYNC_UNITS))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchUnits failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchUnitsLogic
