import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.5759 91.849C20.4951 92.83 20.1292 94.3748 20.655 95.7364C21.1808 97.098 22.49 97.9958 23.9496 97.9958C25.4092 97.9958 26.7184 97.098 27.2442 95.7364C27.77 94.3748 27.404 92.83 26.3233 91.849C24.9116 90.7481 24.0474 89.0886 23.9548 87.3008C23.8597 89.0903 22.9915 90.7502 21.5759 91.849V91.849Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M41.7392 130.697C40.6585 131.678 40.2925 133.222 40.8183 134.584C41.3441 135.946 42.6533 136.843 44.1129 136.843C45.5725 136.843 46.8817 135.946 47.4075 134.584C47.9333 133.222 47.5674 131.678 46.4866 130.697C45.0749 129.596 44.2107 127.936 44.1182 126.148C44.023 127.938 43.1548 129.598 41.7392 130.697V130.697Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M80.5356 12.6296C81.0044 13.3425 81.5646 13.9908 82.2019 14.5579C82.9608 15.2484 83.217 16.3343 82.8468 17.2911C82.4767 18.2479 81.5563 18.8787 80.5304 18.8787C79.5045 18.8787 78.5841 18.2479 78.214 17.2911C77.8438 16.3343 78.1 15.2484 78.8589 14.5579C79.4962 13.9908 80.0564 13.3425 80.5252 12.6296H80.5356ZM80.5252 9.23438C80.4352 11.0205 79.5749 12.6797 78.1672 13.7826C77.0864 14.7636 76.7205 16.3084 77.2463 17.67C77.7721 19.0316 79.0813 19.9294 80.5409 19.9294C82.0005 19.9294 83.3097 19.0316 83.8355 17.67C84.3613 16.3084 83.9953 14.7636 82.9146 13.7826C81.5029 12.6817 80.6387 11.0222 80.5461 9.23438H80.5252Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M105.949 29.2904C106.418 30.0033 106.978 30.6516 107.616 31.2187C108.375 31.9092 108.631 32.9951 108.261 33.9519C107.89 34.9087 106.97 35.5395 105.944 35.5395C104.918 35.5395 103.998 34.9087 103.628 33.9519C103.258 32.9951 103.514 31.9092 104.273 31.2187C104.91 30.6516 105.47 30.0033 105.939 29.2904H105.949ZM105.939 25.8945C105.846 27.6823 104.982 29.3419 103.57 30.4428C102.49 31.4238 102.124 32.9685 102.649 34.3301C103.175 35.6917 104.485 36.5896 105.944 36.5896C107.404 36.5896 108.713 35.6917 109.239 34.3301C109.764 32.9685 109.399 31.4238 108.318 30.4428C106.906 29.3419 106.042 27.6823 105.949 25.8945H105.939Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M78.6808 67.8572C79.3475 69.0376 80.2044 70.0998 81.217 71.0012C82.3712 72.0492 82.7619 73.6993 82.2002 75.1537C81.6384 76.6081 80.2399 77.5671 78.6808 77.5671C77.1217 77.5671 75.7232 76.6081 75.1615 75.1537C74.5997 73.6993 74.9904 72.0492 76.1447 71.0012C77.1572 70.0998 78.0141 69.0376 78.6808 67.8572V67.8572ZM78.6808 63.9688C78.6808 66.1486 77.6014 68.2166 75.4425 70.1728C73.9663 71.5114 73.4659 73.6204 74.1833 75.4795C74.9007 77.3386 76.6881 78.5647 78.6808 78.5647C80.6735 78.5647 82.4609 77.3386 83.1783 75.4795C83.8957 73.6204 83.3953 71.5114 81.9191 70.1728C79.7602 68.2166 78.6808 66.1486 78.6808 63.9688V63.9688Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M100.835 98.6264C99.7544 99.6074 99.3885 101.152 99.9143 102.514C100.44 103.875 101.749 104.773 103.209 104.773C104.668 104.773 105.978 103.875 106.503 102.514C107.029 101.152 106.663 99.6074 105.583 98.6264C104.167 97.5275 103.299 95.8677 103.204 94.0781C103.111 95.8659 102.247 97.5255 100.835 98.6264V98.6264Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.967 77.1733C43.8863 78.1543 43.5203 79.699 44.0461 81.0606C44.5719 82.4222 45.8811 83.3201 47.3407 83.3201C48.8003 83.3201 50.1095 82.4222 50.6353 81.0606C51.1611 79.699 50.7951 78.1543 49.7144 77.1733C48.2988 76.0744 47.4306 74.4145 47.3355 72.625C47.2429 74.4128 46.3787 76.0724 44.967 77.1733V77.1733Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M94.652 74.469C95.1207 75.1819 95.6809 75.8302 96.3183 76.3973C97.0771 77.0878 97.3333 78.1737 96.9631 79.1305C96.593 80.0873 95.6726 80.7181 94.6467 80.7181C93.6208 80.7181 92.7004 80.0873 92.3303 79.1305C91.9601 78.1737 92.2164 77.0878 92.9752 76.3973C93.6125 75.8302 94.1727 75.1819 94.6415 74.469H94.652ZM94.6415 71.0742C94.5489 72.862 93.6847 74.5216 92.273 75.6225C91.1923 76.6035 90.8263 78.1482 91.3521 79.5098C91.8779 80.8714 93.1871 81.7693 94.6467 81.7693C96.1063 81.7693 97.4155 80.8714 97.9413 79.5098C98.4671 78.1482 98.1012 76.6035 97.0204 75.6225C95.6048 74.5236 94.7366 72.8638 94.6415 71.0742V71.0742Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M67.6245 49.9227C68.0932 50.6355 68.6534 51.2838 69.2908 51.851C70.0496 52.5414 70.3058 53.6273 69.9357 54.5842C69.5655 55.541 68.6452 56.1718 67.6193 56.1718C66.5933 56.1718 65.673 55.541 65.3028 54.5842C64.9327 53.6273 65.1889 52.5414 65.9477 51.851C66.5888 51.2845 67.1525 50.6362 67.6245 49.9227V49.9227ZM67.6245 46.5273C67.5319 48.3152 66.6677 49.9747 65.2561 51.0756C64.1753 52.0566 63.8094 53.6014 64.3352 54.9629C64.861 56.3245 66.1702 57.2224 67.6298 57.2224C69.0893 57.2224 70.3986 56.3245 70.9244 54.9629C71.4502 53.6014 71.0842 52.0566 70.0035 51.0756C68.5878 49.9767 67.7197 48.3169 67.6245 46.5273V46.5273Z" fill="currentColor"/>
            <path d="M115.172 127.047C113.872 120.938 116.838 102.315 118.882 82.7176C120.925 63.1202 122.025 42.5587 117.404 7.99609H9.00012V140.996H141.99V8.00657H124.656C125.641 15.4473 126.448 22.9089 127.003 30.3916C127.485 36.9205 127.779 43.4704 127.831 50.0099C127.873 55.6341 127.734 61.2583 127.412 66.8825C127.129 71.9128 126.7 76.9431 126.196 81.9525C125.693 86.9619 125.075 92.2647 124.467 97.4103C124.132 100.268 123.783 103.129 123.419 105.993C123.199 107.943 122.979 109.892 122.78 111.841C122.602 113.549 122.445 115.268 122.308 116.976C122.253 117.724 122.2 118.471 122.151 119.219C122.151 119.785 122.123 120.351 122.067 120.917C122.067 121.504 122.067 122.08 122.067 122.667C122.067 123.254 122.067 123.568 122.067 124.019C122.067 124.469 122.067 124.71 122.141 125.067C122.141 125.182 122.141 125.287 122.141 125.402C122.141 125.517 122.141 125.517 122.141 125.58" stroke="currentColor" strokeWidth="5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M108.161 64.6932C108.823 65.8369 109.665 66.8666 110.655 67.7428C111.809 68.7908 112.2 70.441 111.638 71.8953C111.076 73.3497 109.678 74.3087 108.119 74.3087C106.56 74.3087 105.161 73.3497 104.599 71.8953C104.038 70.441 104.428 68.7908 105.583 67.7428C106.595 66.8414 107.452 65.7792 108.119 64.5988L108.161 64.6932ZM108.119 60.7109C108.119 62.8907 107.039 64.9588 104.88 66.915C103.404 68.2536 102.904 70.3625 103.621 72.2217C104.339 74.0808 106.126 75.3069 108.119 75.3069C110.111 75.3069 111.899 74.0808 112.616 72.2217C113.334 70.3625 112.833 68.2536 111.357 66.915C109.198 64.9588 108.119 62.8907 108.119 60.7109V60.7109Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M134.874 104.001C135.54 105.182 136.397 106.244 137.41 107.145C138.564 108.194 138.955 109.844 138.393 111.298C137.831 112.752 136.433 113.711 134.874 113.711C133.315 113.711 131.916 112.752 131.354 111.298C130.793 109.844 131.183 108.194 132.338 107.145C133.35 106.244 134.207 105.182 134.874 104.001V104.001ZM134.874 100.113C134.874 102.293 133.794 104.361 131.635 106.317C130.159 107.656 129.659 109.765 130.376 111.624C131.094 113.483 132.881 114.709 134.874 114.709C136.866 114.709 138.654 113.483 139.371 111.624C140.089 109.765 139.588 107.656 138.112 106.317C135.953 104.361 134.874 102.293 134.874 100.113V100.113Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M77.8318 128.524C78.4985 129.705 79.3554 130.767 80.3679 131.668C81.5222 132.716 81.9129 134.367 81.3511 135.821C80.7894 137.275 79.3909 138.234 77.8318 138.234C76.2727 138.234 74.8742 137.275 74.3124 135.821C73.7507 134.367 74.1414 132.716 75.2956 131.668C76.3082 130.767 77.1651 129.705 77.8318 128.524V128.524ZM77.8318 124.637C77.8318 126.817 76.7524 128.885 74.5935 130.841C73.1173 132.179 72.6169 134.288 73.3343 136.147C74.0517 138.007 75.8391 139.233 77.8318 139.233C79.8245 139.233 81.6119 138.007 82.3293 136.147C83.0467 134.288 82.5463 132.179 81.0701 130.841C78.9112 128.885 77.8318 126.817 77.8318 124.637V124.637Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M32.0557 100.619C32.7224 101.799 33.5793 102.862 34.5918 103.763C35.7461 104.811 36.1368 106.461 35.5751 107.915C35.0133 109.37 33.6148 110.329 32.0557 110.329C30.4966 110.329 29.0981 109.37 28.5364 107.915C27.9746 106.461 28.3653 104.811 29.5196 103.763C30.5321 102.862 31.389 101.799 32.0557 100.619V100.619ZM32.0557 96.7305C32.0557 98.9103 30.9762 100.978 28.8174 102.935C27.3412 104.273 26.8408 106.382 27.5582 108.241C28.2756 110.1 30.0629 111.326 32.0557 111.326C34.0484 111.326 35.8358 110.1 36.5532 108.241C37.2706 106.382 36.7702 104.273 35.294 102.935C33.1351 100.978 32.0557 98.9103 32.0557 96.7305V96.7305Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 119.817C16.3738 120.998 17.2307 122.06 18.2432 122.961C19.4013 124.009 19.7945 125.661 19.2323 127.118C18.6701 128.575 17.2686 129.534 15.7071 129.532C14.1293 129.545 12.7099 128.575 12.149 127.101C11.5881 125.626 12.0041 123.958 13.192 122.919C14.2045 122.018 15.0614 120.956 15.7281 119.775L15.7071 119.817ZM15.728 115.887C15.728 118.067 14.6486 120.135 12.4898 122.091C11.0136 123.429 10.5131 125.538 11.2305 127.397C11.9479 129.257 13.7353 130.483 15.728 130.483C17.7208 130.483 19.5081 129.257 20.2255 127.397C20.9429 125.538 20.4425 123.429 18.9663 122.091C16.7935 120.163 15.7071 118.115 15.7071 115.95L15.728 115.887Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M50.7205 105.42C51.3872 106.6 52.2441 107.662 53.2566 108.564C54.4109 109.612 54.8016 111.262 54.2398 112.716C53.6781 114.171 52.2796 115.13 50.7205 115.13C49.1614 115.13 47.7629 114.171 47.2012 112.716C46.6394 111.262 47.0301 109.612 48.1844 108.564C49.1969 107.662 50.0538 106.6 50.7205 105.42V105.42ZM50.7205 101.531C50.7205 103.711 49.641 105.779 47.4822 107.735C46.006 109.074 45.5056 111.183 46.223 113.042C46.9404 114.901 48.7277 116.127 50.7205 116.127C52.7132 116.127 54.5006 114.901 55.218 113.042C55.9354 111.183 55.4349 109.074 53.9588 107.735C51.7999 105.779 50.7205 103.711 50.7205 101.531V101.531Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M109.041 43.0752C107.565 44.4138 107.064 46.5227 107.782 48.3818C108.499 50.241 110.286 51.4671 112.279 51.4671C114.272 51.4671 116.059 50.241 116.777 48.3818C117.494 46.5227 116.994 44.4138 115.517 43.0752C113.359 41.1189 112.279 39.0509 112.279 36.8711C112.286 39.0439 111.207 41.1119 109.041 43.0752V43.0752Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M117.11 132.981C115.941 134.594 115.885 136.761 116.969 138.432C118.053 140.104 120.054 140.937 122.004 140.527C123.954 140.118 125.452 138.551 125.772 136.585C126.092 134.618 125.17 132.657 123.451 131.65C120.935 130.176 119.451 128.373 118.997 126.242C119.451 128.373 118.822 130.619 117.11 132.981V132.981Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M87.8821 118.949C87.7848 120.939 88.9222 122.785 90.7441 123.592C92.566 124.399 94.6968 124.002 96.1059 122.593C97.5149 121.184 97.9118 119.053 97.1045 117.231C96.2972 115.41 94.4522 114.272 92.4618 114.369C89.5484 114.509 87.3232 113.81 85.7861 112.273C87.3302 113.81 88.0288 116.036 87.8821 118.949V118.949Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M68.8191 33.8369C67.3429 35.1755 66.8425 37.2844 67.5599 39.1435C68.2773 41.0027 70.0646 42.2288 72.0574 42.2288C74.0501 42.2288 75.8375 41.0027 76.5549 39.1435C77.2723 37.2844 76.7719 35.1755 75.2957 33.8369C73.1368 31.8807 72.0574 29.8126 72.0574 27.6328C72.0574 29.8126 70.978 31.8807 68.8191 33.8369V33.8369Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M91.2356 53.29C89.7594 54.6286 89.259 56.7375 89.9764 58.5967C90.6938 60.4558 92.4811 61.6819 94.4739 61.6819C96.4666 61.6819 98.254 60.4558 98.9714 58.5967C99.6888 56.7375 99.1884 54.6286 97.7122 53.29C95.5533 51.3338 94.4739 49.2657 94.4739 47.0859C94.4739 49.2588 93.3945 51.3268 91.2356 53.29V53.29Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M30.8926 71.095C31.5593 72.2753 32.4162 73.3376 33.4287 74.2389C34.583 75.287 34.9737 76.9371 34.4119 78.3915C33.8501 79.8458 32.4516 80.8048 30.8926 80.8048C29.3335 80.8048 27.935 79.8458 27.3732 78.3915C26.8115 76.9371 27.2022 75.287 28.3564 74.2389C29.369 73.3376 30.2258 72.2753 30.8926 71.095V71.095ZM30.8925 67.207C30.8925 69.3868 29.813 71.4549 27.6542 73.4111C26.178 74.7497 25.6776 76.8586 26.395 78.7178C27.1124 80.5769 28.8997 81.803 30.8925 81.803C32.8852 81.803 34.6726 80.5769 35.39 78.7178C36.1074 76.8586 35.6069 74.7497 34.1307 73.4111C31.9719 71.4549 30.8925 69.3868 30.8925 67.207V67.207Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M34.3194 51.1971C34.9861 52.3774 35.843 53.4396 36.8555 54.341C38.0098 55.3891 38.4005 57.0392 37.8387 58.4935C37.277 59.9479 35.8785 60.9069 34.3194 60.9069C32.7603 60.9069 31.3618 59.9479 30.8 58.4935C30.2383 57.0392 30.629 55.3891 31.7833 54.341C32.7958 53.4396 33.6527 52.3774 34.3194 51.1971V51.1971ZM34.3193 47.3086C34.3193 49.4884 33.2399 51.5564 31.0811 53.5127C29.6049 54.8513 29.1044 56.9602 29.8218 58.8193C30.5392 60.6785 32.3266 61.9046 34.3193 61.9046C36.3121 61.9046 38.0995 60.6785 38.8169 58.8193C39.5343 56.9602 39.0338 54.8513 37.5576 53.5127C35.3988 51.5564 34.3193 49.4884 34.3193 47.3086V47.3086Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.6173 65.8642C13.1411 67.2028 12.6407 69.3118 13.3581 71.1709C14.0755 73.03 15.8629 74.2561 17.8556 74.2561C19.8483 74.2561 21.6357 73.03 22.3531 71.1709C23.0705 69.3118 22.5701 67.2028 21.0939 65.8642C18.935 63.908 17.8556 61.84 17.8556 59.6602C17.8556 61.84 16.7762 63.908 14.6173 65.8642V65.8642Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M52.1667 27.1736C52.8334 28.354 53.6903 29.4162 54.7028 30.3176C55.8571 31.3656 56.2478 33.0157 55.686 34.4701C55.1243 35.9245 53.7258 36.8835 52.1667 36.8835C50.6076 36.8835 49.2091 35.9245 48.6473 34.4701C48.0856 33.0157 48.4763 31.3656 49.6305 30.3176C50.6431 29.4162 51.5 28.354 52.1667 27.1736V27.1736ZM52.1666 23.2852C52.1666 25.465 51.0872 27.533 48.9284 29.4892C47.4522 30.8278 46.9517 32.9368 47.6691 34.7959C48.3865 36.655 50.1739 37.8811 52.1666 37.8811C54.1594 37.8811 55.9467 36.655 56.6641 34.7959C57.3815 32.9368 56.8811 30.8278 55.4049 29.4892C53.2461 27.533 52.1666 25.465 52.1666 23.2852V23.2852Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9333 21.9148C17.6001 23.0951 18.4569 24.1573 19.4695 25.0587C20.596 26.1115 20.9685 27.7439 20.4102 29.1812C19.8519 30.6185 18.4752 31.5714 16.9333 31.5877C15.3719 31.59 13.9704 30.6301 13.4082 29.1734C12.8459 27.7167 13.2391 26.0642 14.3972 25.0168C15.4097 24.1154 16.2666 23.0532 16.9333 21.8728V21.9148ZM16.9334 17.9844C16.9334 20.1642 15.8539 22.2322 13.6951 24.1885C12.2189 25.5271 11.7185 27.636 12.4359 29.4951C13.1533 31.3542 14.9406 32.5803 16.9334 32.5803C18.9261 32.5803 20.7135 31.3542 21.4309 29.4951C22.1483 27.636 21.6478 25.5271 20.1716 24.1885C18.0128 22.2322 16.9334 20.1642 16.9334 17.9844V17.9844Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.0867 16.9385C42.6105 18.2771 42.1101 20.386 42.8275 22.2451C43.5449 24.1042 45.3322 25.3303 47.325 25.3303C49.3177 25.3303 51.1051 24.1042 51.8225 22.2451C52.5399 20.386 52.0394 18.2771 50.5632 16.9385C48.4044 14.9822 47.325 12.9142 47.325 10.7344C47.325 12.9142 46.2455 14.9822 44.0867 16.9385V16.9385Z" fill="currentColor"/>
            <path d="M83.6063 114.451C65.7171 99.3286 58.5804 79.2702 52.5754 65.4577C46.5704 51.6452 41.6973 43.9949 31.8462 33.3579C30.2847 31.7964 35.4827 26.8289 37.0861 28.4742C39.4546 31.0313 41.7602 33.6513 43.9295 36.3761C44.9426 37.6406 45.9242 38.9332 46.8743 40.2536C47.8245 41.5741 48.7293 42.8945 49.5886 44.215C51.3493 46.9398 52.9632 49.7589 54.4513 52.5989C56.0966 55.7429 57.5953 59.0126 59.01 62.2928C59.8903 64.3049 60.7392 66.3275 61.5881 68.3501C62.437 70.3728 63.2229 72.2067 64.0718 74.1245C64.9207 76.0424 65.8743 78.1174 66.828 80.0771C67.7817 82.0369 68.8611 84.1433 69.972 86.1345C71.0828 88.1257 72.309 90.1902 73.5875 92.1604C74.8661 94.1306 76.2809 96.1323 77.7795 98.0187C79.2781 99.905 80.9234 101.854 82.6107 103.646C84.3822 105.511 86.2538 107.278 88.2174 108.939" stroke="currentColor" strokeWidth="5"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwHumidityIcon')

export default icon
