import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0312 16V13H15.0312V11H12.0312V8H10.0312V11H7.03125V13H10.0312V16H12.0312ZM0 18.9844L11.0156 0L22.0312 18.9844H0Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'HealthAlertIcon')

export default icon
