const styles = (theme) => ({
  root: {
    width: '100%',
  },
  tabs: {
    background: 'transparent',
    color: theme.scenes.textColor,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: 10,
  },
})

export default styles
