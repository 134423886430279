import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from '@app/store'
import {
  Button, IconButton, TextField, Typography,
} from '@mui/material'
import validateName from '@app/helpers/myDevices/validateName'
import { patchZwaveDevice } from '@app/actions/myDevices/zwaveDevices'
import { patchTwrDevice } from '@app/actions/myDevices/twrDevices'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { DeviceTypes, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { setCurrentDevice } from '@app/actions/myDevices/myDevices'
import IconGallery from './IconGallery/IconGallery'
import useStyles from './EditIcon.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  isMobile: boolean,
}

const EditIcon: React.FC<Props> = (props) => {
  const { currentDevice, isMobile } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [name, setName] = useState<string>('')
  const [icon, setIcon] = useState<number>(1)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentDevice) {
      setName(currentDevice.name)
      setIcon(currentDevice.icon)
    }
  }, [])

  const goBackHandler = () => navigate(-1)

  const submitDeviceChanges = () => {
    if (currentDevice.device_type === DeviceTypes.twrDevice) {
      dispatch(patchTwrDevice({ name, icon }, currentDevice.id))
      dispatch(setCurrentDevice({ ...currentDevice, name, icon }))
    }
    if (currentDevice.device_type === DeviceTypes.zwaveDevice) {
      dispatch(patchZwaveDevice({ name, icon, id: currentDevice.id }))
      dispatch(setCurrentDevice({ ...currentDevice, name, icon }))
    }
    navigate(location.pathname.split('/').slice(0, -2).join('/'))
  }

  return (
    <>
      {currentDevice ? (
        <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          <Wrapper className={classes.navigationControls}>
            <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
              <ScenesArrowBack />
            </IconButton>
          </Wrapper>
          <Wrapper className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}>
            <Wrapper>
              <TextField
                variant="standard"
                value={name}
                onChange={(evt) => setName(evt.target.value.trimLeft())}
                inputProps={{ maxLength: 16 }}
                className={classes.nameInput}
                label={I18n.t('my_devices.device_name')}
                helperText={I18n.t('my_devices.name_hint')}
              />
            </Wrapper>
            <Typography variant="h6" className={classes.title}>{I18n.t('my_devices.icons')}</Typography>
            <IconGallery
              activeIcon={icon}
              setActiveIcon={setIcon}
              isMobile={isMobile}
            />
            <Button
              className={classes.saveButton}
              variant="outlined"
              disabled={!validateName(name)}
              onClick={submitDeviceChanges}
            >
              {I18n.t('buttons.save')}
            </Button>
          </Wrapper>
        </Wrapper>
      ) : <Navigate to={location.pathname.split('/').slice(0, -2).join('/')} /> }
    </>
  )
}

export default EditIcon
