import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_TWR_DEVICE } from '@app/constants/myDevices/twrDevices'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { setMyDevices } from '@app/actions/myDevices/myDevices'

const patchTwrDeviceLogic = createLogic({
  type: PATCH_TWR_DEVICE,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { myDevices: { myDevices: { devices } } } = getState()
    if (!action.id) {
      const { myDevices: { myDevices: { deviceToAddInfo: { id } } } } = getState()
      next({
        ...action,
        token,
        devices,
        id,
      })
    } else {
      next({
        ...action,
        token,
        devices,
      })
    }
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_TWR_DEVICE))
    try {
      const patchedDevice = await Api.patchTwrDevice(action.token, action.id, action.data)
      const updatedDevices = action.devices.map((device) => ((device.id === patchedDevice.data.twr_device.id
        && device.device_type === patchedDevice.data.twr_device.device_type)
        ? { ...device, ...patchedDevice.data.twr_device }
        : device))
      dispatch(setMyDevices(updatedDevices))
      dispatch(apiRequestDone(PATCH_TWR_DEVICE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_TWR_DEVICE failed without error')
      }
    }
    done()
  },
})

export default patchTwrDeviceLogic
