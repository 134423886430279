import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { doorlockActionValues } from '@app/lib/Scenes/doorlockActionValues'
import {
  EDIT_ACTION,
  SELECT_ACTION_DEVICE_STATE_TYPE,
  SELECT_DOORLOCK_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectDoorlockActionStateValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      stateDescription,
      isChangeActionMode,
      isChangeActionStateMode,
      isEditActionMode,
      restoreActionInfo,
      actions,
      initialAction,
    } = this.props
    if (isEditActionMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    } else if (stateDescription !== 'none' && !isChangeActionMode && !isChangeActionStateMode) {
      navigate(SELECT_DOORLOCK_ACTION_STATE_SELECTED)
    } else {
      navigate(SELECT_ACTION_DEVICE_STATE_TYPE)
    }
  }

  onAddClick=(value) => {
    const {
      navigate,
      saveActionStateDescription,
      isChangeActionStateMode,
      isChangeActionMode,
      saveActionDelay,
      saveActionDelayDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      saveDurationValueAfterActionColorType,
    } = this.props
    saveActionStateDescription(value.description)
    navigate(SELECT_DOORLOCK_ACTION_STATE_SELECTED)
    if (isChangeActionStateMode || isChangeActionMode) {
      saveActionDelay({})
      saveActionDelayDescription('')
      saveActionDuration({})
      saveActionDurationDescription('')
      saveActionDurationValueAfter('none')
      saveDurationValueAfterActionColorType('')
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            doorlockActionValues.map((value) => (
              <CardBlock
                key={value.description}
                title={value.title}
                description={I18n.t(`scene_action_web.${value.description}`)}
                position={value.position}
                buttonName={value.buttonName}
                isCardDisabled={value.isCardDisabled}
                isButtonDisabled={value.isButtonDisabled}
                onClick={() => {
                  this.onAddClick(value)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectDoorlockActionStateValue.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  stateDescription: PropTypes.string.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isChangeActionMode: PropTypes.bool.isRequired,
  saveDurationValueAfterActionColorType: PropTypes.func.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectDoorlockActionStateValue.displayName = 'SelectBinarySwitchActionStateValue'

export default SelectDoorlockActionStateValue
