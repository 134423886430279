import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { conditionObservingDeviceTypes, conditionObservingDeviceTypesNCentral } from '@app/lib/Scenes/conditionTriggeringObservingDeviceTypes'
import {
  SELECT_OBSERVING_GROUP,
  SELECT_OBSERVING_ROOM,
  SELECT_OBSERVING_TWR_DEVICE,
  SELECT_OBSERVING_ZWAVE_DEVICE,
  SELECT_TRIGGERING_DEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectObservingDeviceType extends React.Component {
  onBackClick = () => {
    const { navigate } = this.props
    navigate(SELECT_TRIGGERING_DEVICE)
  }

  onUseClick=(type) => {
    const {
      navigate,
      saveObservingDeviceType,
    } = this.props
    switch (type.type) {
    case 'TwrDevices':
      saveObservingDeviceType(1)
      return navigate(SELECT_OBSERVING_TWR_DEVICE)
    case 'ZWaveDevices':
      saveObservingDeviceType(2)
      return navigate(SELECT_OBSERVING_ZWAVE_DEVICE)
    case 'RoomActivity':
      saveObservingDeviceType(3)
      return navigate(SELECT_OBSERVING_ROOM)
    case 'GroupActivity':
      saveObservingDeviceType(4)
      return navigate(SELECT_OBSERVING_GROUP)
    default:
      throw new Error('condition unsupported')
    }
  }

  render() {
    const {
      classes,
      isMobile,
      isNCentral,
    } = this.props
    const typesList = isNCentral ? conditionObservingDeviceTypesNCentral : conditionObservingDeviceTypes
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_observing_device')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            typesList.map((type) => (
              <CardBlock
                key={type.title}
                title={I18n.t(`scene_web.${type.title}`)}
                description={I18n.t(`scene_web.${type.description}`)}
                position={type.position}
                buttonName={I18n.t(`scene_web.${type.buttonName}`)}
                isCardDisabled={type.isCardDisabled}
                isButtonDisabled={type.isButtonDisabled}
                onClick={() => {
                  this.onUseClick(type)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectObservingDeviceType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveObservingDeviceType: PropTypes.func.isRequired,
  isNCentral: PropTypes.bool.isRequired,
}

SelectObservingDeviceType.displayName = 'SelectObservingDeviceTypeIOS'

export default SelectObservingDeviceType
