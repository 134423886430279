import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
      <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 8V141H141V8H8ZM72.0779 131.322H17.6312V76.9132H72.0779V131.322ZM72.0779 72.079H17.6312V17.6323H72.0779V72.079ZM131.321 131.322H76.9125V76.9132H131.312L131.321 131.322ZM131.321 72.079H76.9125V17.6323H131.312L131.321 72.079Z" fill="currentColor"/>
      </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'WindowSensorIcon')

export default icon
