import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppBar, Tab, Tabs as MaterialTabs } from '@mui/material'
import { transferParams } from '@app/helpers/transferParams'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './Tabs.styles'

const Tabs = ({ active, tabs }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const currentIndex = tabs.findIndex((tab) => tab.route === active)
    setActiveIndex(currentIndex)
  }, [])

  const onChange = (event, currentTab) => {
    const { route } = tabs[currentTab]
    setActiveIndex(currentTab)
    navigate(route + transferParams())
  }

  return (
    <Wrapper>
      <AppBar position="static" enableColorOnDark>
        <MaterialTabs
          variant="fullWidth"
          centered
          onChange={onChange}
          value={activeIndex}
        >
          {tabs.map((tab) => (
            <Tab
              classes={{ root: classes.tab }}
              key={tab.titleKey.toString()}
              label={
                I18n.t(tab.titleKey)
              }
            />
          ))}
        </MaterialTabs>
      </AppBar>
    </Wrapper>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string.isRequired,
    titleKey: PropTypes.string.isRequired,
  })).isRequired,
  active: PropTypes.string.isRequired,
}

export default Tabs
