import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: 1000,
    position: 'absolute',
    top: -125,
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.backgrounds.navigation,
    height: 77,
    padding: '0 40px',
  },
  navigationMobile: {
    padding: '0 10px',
  },
  navigationLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  goBackIcon: {
    marginRight: 44,
  },
  goBackIconMobile: {
    marginRight: 22,
  },
  editor: {
    padding: '40px 70px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.backgrounds.componentBackground,
  },
  editorMobile: {
    padding: 20,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: 20,
    marginBottom: 70,
  },
  nameTitle: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 8,
  },
  descriptionTitle: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 8,
  },
  name: {
    color: theme.texts.subText,
    marginBottom: 30,
  },
  description: {
    color: theme.texts.subText,
  },
  settingsTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 30,
    alignSelf: 'start',
  },
  editorComponents: {
    width: '100%',
    marginBottom: 80,
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonsSecondWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  button: {
    width: '50%',
    maxWidth: 376.5,
    '&:first-of-type': {
      marginRight: 32,
    },
  },
  resetButton: {
    width: '100%',
    borderColor: theme.borders.main,
    color: theme.texts.main,
  },
  preloader: {
    display: 'flex',
    position: 'fixed',
    width: '100vw',
    height: 'calc(100vh - 65px)',
    top: 130,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1199,
  },
  preloaderMessage: {
    fontSize: '1.5rem',
  },
  blur: {
    filter: 'blur(5px)',
  },
  notEditable: {
    width: '100%',
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: '24px 24px 24px 44px',
    position: 'relative',
  },
}))

export default useStyles
