import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { POST_EMAIL } from '@app/constants/wizard/forgotPassword'
import { CODE } from '@app/constants/routes'
import { openWrongEmailPopup, saveRequestId } from '@app/actions/wizard/forgotPassword'
import Api from '@app/api/forgotPassword/sendCodeToEmail'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const sendCodeToEmailLogic = createLogic({
  type: POST_EMAIL,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { login: { forgotPassword: { email } } } = getState()
    const { secrets: { clientCredentialsToken } } = getState()
    const data = { email }
    next({
      ...action,
      clientCredentialsToken,
      data,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_EMAIL))
    Api.sendCodeToEmail(action.clientCredentialsToken, action.data)
      .then((response) => {
        const { id } = response.data
        dispatch(saveRequestId(id))
      })
      .then(() => {
        dispatch(apiRequestDone(POST_EMAIL))
        history.push(CODE)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(openWrongEmailPopup())
        } else {
          throw new Error('sendCodeToEmail failed without error')
        }
      })
      .then(() => done())
  },
})

export default sendCodeToEmailLogic
