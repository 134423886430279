import { zWaveDevicesCapabilities, supportedActionCapabilities } from '@app/lib/Scenes/zWaveDevicesCapabilities'

const getActionCapabilities = (capabilities) => {
  const template = []
  const initialCapabilities = capabilities
  if ((initialCapabilities.includes('switch_multilevel') || initialCapabilities.includes('switch_binary'))
    && initialCapabilities.includes('door_lock')) {
    const doorLockFilteredCapabilities = initialCapabilities
      .filter((capability) => (capability !== 'switch_multilevel' && capability !== 'switch_binary'))
    doorLockFilteredCapabilities.forEach((capability) => {
      supportedActionCapabilities.forEach((supportedCapability) => {
        if (capability === supportedCapability) {
          const currentCapability = zWaveDevicesCapabilities.find((el) => el.type === capability)
          template.push(currentCapability)
        }
      })
    })
  } else if (initialCapabilities.includes('switch_multilevel') && initialCapabilities.includes('switch_binary')) {
    template.push(zWaveDevicesCapabilities.find((capability) => capability.type === 'switch_multilevel'))
  } else {
    initialCapabilities.forEach((capability) => {
      supportedActionCapabilities.forEach((supportedCapability) => {
        if (capability === supportedCapability) {
          const currentCapability = zWaveDevicesCapabilities.find((el) => el.type === capability)
          template.push(currentCapability)
        }
      })
    })
  }
  return template
}
export default getActionCapabilities
