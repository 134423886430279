import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  FormControl, MenuItem, Paper, Select, Typography,
} from '@mui/material'
import { ExpandMore } from 'icons'
import { clearCurrentActionInfo, saveActionType } from '@app/actions/scenes/currentSceneCreating'
import { CREATE_ACTION_SELECTED, EDIT_ACTION, SELECT_ACTION_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import CustomInput from '@app/components/Common/CustomInput/CustomInput'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'
import PushAndEmailFields from './PushAndEmailFields/PushAndEmailFields'
import WebRequestFields from './WebRequestFields/WebRequestFields'
import useStyles from './SettingsType.style'

const SettingsType = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isMobile } = useSelector((state) => state.mobileDetector)
  const { isChangeActionStateMode, isEditActionMode, initialAction } = useSelector((state) => state.scenes.currentSceneCreating)
  const { actionType } = useSelector((state) => state.scenes.currentSceneCreating.currentAction)
  const { actions } = useSelector((state) => state.scenes.currentSceneConditionsActions)

  const onBackClick = () => {
    if (isChangeActionStateMode) {
      if (isEditActionMode) {
        restoreEditedActionInfo(actions, initialAction)
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      dispatch(clearCurrentActionInfo())
      navigate(SELECT_ACTION_TYPE)
    }
  }

  const typeSelectChange = (event) => {
    dispatch(saveActionType(event.target.value))
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{I18n.t('scene_action_web.settings_type')}</Typography>
      </Wrapper>
      <Wrapper className={classNames(
        classes.main,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webViewMain,
      )}
      >
        <FormControl
          style={{
            padding: '15px 3%',
            marginBottom: '22px',
          }}
          classes={{ root: classes.formControl }}
          variant="outlined"
        >
          <Select
            variant="standard"
            id="type-select"
            value={actionType}
            onChange={typeSelectChange}
            input={<CustomInput />}
            IconComponent={ExpandMore}
          >
            <MenuItem className={classes.typeMenuItem} value="PushNotifications">{I18n.t('scene_action_web.push_notification')}</MenuItem>
            <MenuItem className={classes.typeMenuItem} value="Email">{I18n.t('scene_action_web.email')}</MenuItem>
            <MenuItem className={classes.typeMenuItem} value="WebRequest">{I18n.t('scene_action_web.web_request')}</MenuItem>
          </Select>
        </FormControl>
        {actionType === 'WebRequest' ? <WebRequestFields /> : <PushAndEmailFields />}
      </Wrapper>
    </Paper>
  )
}

export default SettingsType
