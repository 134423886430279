import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_TWR_DEVICES } from '@app/constants/scenes/twrDevices/twrDevices'
import { updateTwrDevices } from '@app/actions/scenes/twrDevices/twrDevices'
import fetchTwrDevices from '@app/api/scenes/TwrDevices/fetchTwrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchTwrDevicesLogic = createLogic({
  type: SYNC_TWR_DEVICES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_TWR_DEVICES))
    fetchTwrDevices(token)
      .then((response) => {
        const { data: { sensors } } = response
        dispatch(updateTwrDevices(sensors))
        dispatch(apiRequestDone(SYNC_TWR_DEVICES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchTwrDevices failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchTwrDevicesLogic
