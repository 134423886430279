import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import {
  SELECT_MULTICHANNEL_ACTION_SUBDEVICE_VALUE,
  SELECT_ACTION_DEVICE_STATE_TYPE,
  SELECT_MULTICHANNEL_ACTION_STATE_SELECTED,
} from '@app/constants/routes'

class SelectMultichannelActionSubDevice extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      name,
      isChangeActionStateMode,
    } = this.props
    if (isChangeActionStateMode) {
      navigate(SELECT_ACTION_DEVICE_STATE_TYPE)
    } else if (name) {
      navigate(SELECT_MULTICHANNEL_ACTION_STATE_SELECTED)
    } else {
      navigate(SELECT_ACTION_DEVICE_STATE_TYPE)
    }
  }

  onAddClick=(device) => {
    const {
      navigate,
      saveCurrentActionSubdevice,
      stateDescription,
    } = this.props
    saveCurrentActionSubdevice(device)
    if (stateDescription !== 'none') {
      navigate(SELECT_MULTICHANNEL_ACTION_STATE_SELECTED)
    } else {
      navigate(SELECT_MULTICHANNEL_ACTION_SUBDEVICE_VALUE)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      subDevices,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_subdevice')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            subDevices.map((device) => (
              <CardBlock
                key={device.name}
                title={device.name}
                position="top"
                buttonName="add"
                isCardDisabled={false}
                isButtonDisabled={false}
                onClick={() => {
                  this.onAddClick(device)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultichannelActionSubDevice.propTypes = {
  subDevices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  })).isRequired,
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveCurrentActionSubdevice: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  stateDescription: PropTypes.string.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
}

SelectMultichannelActionSubDevice.displayName = 'SelectMultichannelActionSubDevice'

export default SelectMultichannelActionSubDevice
