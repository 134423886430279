import React from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { IZwaveDeviceBackend } from '@app/@types/scenes/scenesDevices'
import Device from './Device'
import useStyles from './DevicesList.style'

interface Props {
  devices: Array<IZwaveDeviceBackend>,
  deviceType: string,
}

const DevicesList: React.FC<Props> = (props) => {
  const {
    devices,
    deviceType,
  } = props

  const classes = useStyles()

  const wallmoteFilteredDevices = devices.filter((device) => device.wallmote === false)
  return (
    <List className={classes.root}>
      {wallmoteFilteredDevices.length
        ? wallmoteFilteredDevices.map((device) => (
          <ListItem className={classes.listItem} key={device.id}>
            <Device
              device={device}
              deviceType={deviceType}
            />
          </ListItem>
        )) : <Typography className={classes.empty} align="center">NO DEVICES</Typography>}
    </List>
  )
}

export default DevicesList
