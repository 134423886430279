import React, { useEffect } from 'react'
import {
  Button, IconButton, Radio, RadioGroup, Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useDispatch } from '@app/store'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getTwrSirenOption,
  patchTwrSirenOptions,
  saveTwrCurrentSirenOption,
} from '@app/actions/myDevices/twrDevices'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import { sirenOptions } from '@app/lib/MyDevices/sirenOptions'
import { IZwaveOrTwrDevice } from '@app/@types/myDevices'
import useStyles from './TwrSirenOptions.style'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  twrCurrentSirenOption: number,
  isMobile: boolean,
}

const TwrSirenOptions: React.FC<Props> = (props) => {
  const { currentDevice, twrCurrentSirenOption, isMobile } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    dispatch(getTwrSirenOption(currentDevice.id))
  }, [])

  const goBackHandler = () => navigate(-1)

  const submitOption = () => {
    dispatch(patchTwrSirenOptions(currentDevice.id))
    navigate(location.pathname.split('/').slice(0, -2).join('/'))
  }

  return (
    <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
      <Wrapper className={classes.navigationControls}>
        <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
          <ScenesArrowBack />
        </IconButton>
      </Wrapper>
      <Wrapper>
        <RadioGroup
          className={classNames(classes.contentContainer, isMobile && classes.contentContainerMobile)}
          value={twrCurrentSirenOption}
          onChange={(evt) => dispatch(saveTwrCurrentSirenOption(+evt.target.value))}
        >
          { sirenOptions.map((sirenOption, index) => ((
            <Wrapper
              className={classes.modeCard}
              onClick={() => dispatch(saveTwrCurrentSirenOption(index))}
              key={`twr_option_${index + 1}`}
            >
              <Typography className={classNames(classes.marginBottomHelper)}>
                {
                  I18n.t(`my_devices.${sirenOption}`)
                }
              </Typography>
              <Wrapper className={classes.subTitleContainer}>
                <Typography className={classes.cardSubTitle}>
                  {I18n.t(`my_devices.${sirenOption}_desc`)}
                </Typography>
              </Wrapper>
              <Radio value={index} className={classes.radioButton} color="primary" />
            </Wrapper>
          )
          ))}
        </RadioGroup>
      </Wrapper>
      <Button
        variant="outlined"
        className={classes.saveButton}
        color="primary"
        onClick={submitOption}
      >
        {I18n.t('buttons.save')}
      </Button>
    </Wrapper>
  )
}

export default TwrSirenOptions
