import * as type from '@app/constants/healthAlert'

type State = {
  address: string,
  inProgress: boolean,
}

type Action = {
  type: string,
  address: string,
  inProgress: boolean,
}

const initialState = {
  address: '',
  inProgress: false,
}

const healthAlert = (state: State = initialState, action: Action): State => {
  switch (action.type) {
  case type.SET_GUARD_PANEL_ADDRESS:
    return { ...state, address: action.address }
  case type.ACTIVATE_IN_PROGRESS_ALARM:
    return { ...state, inProgress: true }
  case type.DEACTIVATE_IN_PROGRESS_ALARM:
    return { ...state, inProgress: false }
  default:
    return state
  }
}

export default healthAlert
