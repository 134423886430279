export const SYNC_MY_DEVICES = '@@myDevices/sync'

export const SET_MY_DEVICES = '@@myDevices/set'
export const SORT_MY_DEVICES = '@@myDevices/sort'

export const CLEAR_INTERVIEW_RESULT = '@@myDevices/clearInterviewResult'

export const CLEAR_DELETING_RESULT = '@@myDevices/clearDeletingResult'
export const STOP_DELETING_PROGRESS = '@@myDevices/stopDeletingProgress'

export const SET_CURRENT_DEVICE = '@@myDevices/setCurrentDevice'
export const SET_CURRENT_DEVICE_CONTROL = '@@myDevices/setCurrentDeviceControl'
export const CLEAR_CURRENT_DEVICE = '@@myDevices/clearCurrentDevice'

export const SYNC_MEZZO_LIGHTS = '@@myDevices/syncMezzoLights'
export const UPDATE_MEZZO_LIGHTS = '@@myDevices/updateMezzoLights'
export const PATCH_MEZZO_LIGHTS = '@@myDevices/patchMezzoLights'

export const START_MEZZO_LIGHTS_LOADING = '@@myDevices/startMezzoLightsLoading'
export const END_MEZZO_LIGHTS_LOADING = '@@myDevices/endMezzoLightsLoading'
export const RESET_MY_DEVICES = '@@myDevices/reset'
