const disableAdditionalConditions = (title: string, sceneReviewConditionTypes: Array<string>, isChangeConditionMode: boolean): boolean => {
  if (isChangeConditionMode && sceneReviewConditionTypes.length === 1) {
    return false
  }
  switch (title) {
  case 'geofence':
    return (sceneReviewConditionTypes.includes('Geofencing') || sceneReviewConditionTypes.includes('UserTrigger'))
  case 'user_trigger':
    return (sceneReviewConditionTypes.length !== 0)
  case 'another_scene':
    return (sceneReviewConditionTypes.includes('UserTrigger'))
  default:
    return false
  }
}

export default disableAdditionalConditions
