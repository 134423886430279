import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/sphere_users/warning_link`

const warningLink = (accessToken) => Axios.post(ENDPOINT, null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  warningLink,
}
