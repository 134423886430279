import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 64 80" enableBackground="new 0 0 64 64" ><path d="M61,32h-3V13c0-0.8-0.313-1.554-0.878-2.121c-1.134-1.134-3.11-1.134-4.244,0C52.313,11.446,52,12.2,52,13v19H12V13  c0-0.801-0.312-1.555-0.879-2.121c-1.133-1.134-3.109-1.134-4.242,0C6.312,11.445,6,12.199,6,13v19H3c-1.654,0-3,1.346-3,3v12  c0,1.654,1.346,3,3,3h3v2h2v-2h4v2h2v-2h36v2h2v-2h4v2h2v-2h3c1.654,0,3-1.346,3-3V35C64,33.346,62.654,32,61,32z M54,13  c0-0.267,0.104-0.518,0.294-0.708c0.377-0.377,1.035-0.377,1.412,0C55.896,12.482,56,12.733,56,13v19h-2V13z M8,13  c0-0.267,0.104-0.518,0.293-0.707c0.378-0.378,1.036-0.378,1.414,0C9.896,12.482,10,12.733,10,13v19H8V13z M62,47  c0,0.552-0.448,1-1,1H3c-0.551,0-1-0.448-1-1V35c0-0.552,0.449-1,1-1h58c0.552,0,1,0.448,1,1V47z M28,44h6v-6h-6V44z M30,40h2v2h-2  V40z M36,44h6v-6h-6V44z M38,40h2v2h-2V40z M44,44h6v-6h-6V44z M46,40h2v2h-2V40z M52,44h6v-6h-6V44z M54,40h2v2h-2V40z M32,22  c2.206,0,4,1.794,4,4h-2c0-1.103-0.896-2-2-2c-1.103,0-2,0.897-2,2h-2C28,23.794,29.794,22,32,22z M32,20c-3.309,0-6,2.691-6,6h-2  c0-4.411,3.589-8,8-8s8,3.589,8,8h-2C38,22.691,35.309,20,32,20z M42,26c0-5.514-4.486-10-10-10c-5.514,0-10,4.486-10,10h-2  c0-6.617,5.383-12,12-12s12,5.383,12,12H42z M18,26h-2c0-8.822,7.178-16,16-16c8.822,0,16,7.178,16,16h-2c0-7.72-6.279-14-14-14  C24.28,12,18,18.28,18,26z M6,38h2v2H6V38z M10,38h2v2h-2V38z M14,38h2v2h-2V38z M18,38h2v2h-2V38z M6,42h2v2H6V42z M10,42h2v2h-2  V42z M14,42h2v2h-2V42z M18,42h2v2h-2V42z"/></svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Router')

export default icon
