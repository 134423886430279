import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { virtualSwitchesStatesCondition } from '@app/lib/Scenes/virtualSwitchesStates'
import {
  CREATE_CONDITION_SELECTED,
  EDIT_CONDITION,
  SELECT_VIRTUAL_SWITCH_CONDITION,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectVirtualSwitchConditionState extends React.Component {
  onBackClick = () => {
    const {
      isChangeConditionStateMode,
      isEditConditionMode,
      navigate,
    } = this.props
    if (isChangeConditionStateMode) {
      if (isEditConditionMode) {
        navigate(EDIT_CONDITION)
      } else {
        navigate(CREATE_CONDITION_SELECTED)
      }
    } else {
      navigate(SELECT_VIRTUAL_SWITCH_CONDITION)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      saveConditionType,
      saveConditionStateDescription,
      isEditConditionMode,
      saveConditionDeviceStateInfo,
      conditionDeviceNumber,
    } = this.props
    saveConditionType('VirtualSwitch')
    saveConditionStateDescription(state.title)
    const stateValue = state.title === 'is_on' ? 1 : 0
    saveConditionDeviceStateInfo({
      number: conditionDeviceNumber,
      value: stateValue,
    })
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            virtualSwitchesStatesCondition.map((state) => (
              <CardBlock
                key={state.title}
                description={I18n.t(`scene_web.${state.title}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                isValueButton
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectVirtualSwitchConditionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionType: PropTypes.func.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  conditionDeviceNumber: PropTypes.number.isRequired,
  isChangeConditionStateMode: PropTypes.bool.isRequired,
}

SelectVirtualSwitchConditionState.defaultProps = {

}

SelectVirtualSwitchConditionState.displayName = 'SelectVirtualSwitchConditionStateIOS'

export default SelectVirtualSwitchConditionState
