import openCable from '../cable'
import { SCENES_CHANNEL } from '../../constants/channels'
import { subscribeScenesListChannel, unsubscribeScenesListChannel } from '../../actions/scenes/scenesListChannel'

const scenesListChannel = ({ request, response }) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(SCENES_CHANNEL),
  )
  if (!subscription) {
    return cable.subscriptions.create(SCENES_CHANNEL, {
      connected() {
        request(this)
        store.dispatch(subscribeScenesListChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(unsubscribeScenesListChannel())
      },
    })
  }
  return subscription
}

export default {
  scenesListChannel,
}
