import * as zwave from '@app/constants/myDevices/zwaveDevices'
import * as twr from '@app/constants/myDevices/twrDevices'
import * as devices from '@app/constants/myDevices/myDevices'

export const deleteZwaveDevice = (id: number) => ({
  type: zwave.DELETE_ZWAVE_DEVICE,
  id,
} as const)

export const cancelZwaveDelete = () => ({
  type: zwave.CANCEL_ZWAVE_DELETE,
} as const)

export const deleteTwrDevice = (id: number) => ({
  type: twr.DELETE_TWR_DEVICE,
  id,
} as const)

export const clearDeletingResult = () => ({
  type: devices.CLEAR_DELETING_RESULT,
} as const)

export const stopDeletingProgress = () => ({
  type: devices.STOP_DELETING_PROGRESS,
} as const)
