import React, { useState, useEffect } from 'react'
import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { closePanicPopup } from '@app/actions/mySecurity/mySecurity'
import { useDispatch } from '@app/store'
import useStyles from './PanicWarning.style'

interface Props {
  open: boolean,
  onCancelClick: () => void,
}

const WarningComponent: React.FC<Props> = (props) => {
  const {
    open,
    onCancelClick,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [countdown, setCountdown] = useState<number>(5)

  useEffect(() => {
    let timer: NodeJS.Timeout | boolean
    const clearCountdown = (intervalId: any) => {
      clearInterval(intervalId)
    }
    if (open) {
      timer = (countdown > 0) && setInterval(() => setCountdown(countdown - 1), 1000)
      if (countdown === 0) {
        dispatch(closePanicPopup())
      }
    }
    return () => clearCountdown(timer)
  }, [countdown, open])

  useEffect(() => {
    setCountdown(5)
  }, [])

  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle
        className={classes.title}
      >
        {I18n.t('titles.warning') }
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          className={classes.text}
        >
          {`The panic alarm will be activated after ${countdown} seconds`}
        </DialogContentText>
      </DialogContent>
      <Wrapper
        className={classes.buttonWrapper}
      >
        <Button
          color="primary"
          className={classes.button}
          onClick={() => {
            onCancelClick()
          }}
        >
          {I18n.t('buttons.cancel')}
        </Button>
      </Wrapper>
    </Dialog>
  )
}
export default WarningComponent
