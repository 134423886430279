import getEditedActionInfo from '@app/helpers/scenes/getEditedActionInfo'
import {
  saveEditedAction,
} from '@app/actions/scenes/currentSceneCreating'
import { IActionFromBackend } from '@app/@types/scenes/scenes'

const restoreEditedActionInfo = (editedSceneInitialActions: Array<IActionFromBackend>, initialAction: IActionFromBackend) => {
  const currentEditingAction = editedSceneInitialActions.find((action) => action.id === initialAction.id)
  if (currentEditingAction) {
    const actionInfo = getEditedActionInfo(currentEditingAction)
    store.dispatch(saveEditedAction(actionInfo))
  }
}

export default restoreEditedActionInfo
