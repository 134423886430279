import * as type from '@app/constants/users/avatar'

const initialState = {
  avatarPopupOpen: false,
  avatarData: null,
}
export default function avatar(state = initialState, action) {
  switch (action.type) {
  case type.OPEN_AVATAR_POPUP:
    return { ...state, avatarPopupOpen: true }
  case type.CLOSE_AVATAR_POPUP:
    return { ...state, avatarPopupOpen: false }

  case type.CHOOSE_AVATAR_FILE:
    return { ...state, avatarData: action.data }
  case type.CLEAN_AVATAR_FILE:
    return { ...state, avatarData: null }

  default:
    return state
  }
}
