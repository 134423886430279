import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/unit_devices/use`

const patchUnitDevice = (accessToken, serialNumber) => Axios.patch(ENDPOINT, '', {
  params: {
    serial_number: serialNumber,
  },
  headers: {
    'Access-Token': accessToken,
  },
})

export default {
  patchUnitDevice,
}
