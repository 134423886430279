import * as type from '@app/constants/healthAlert'

export const setGuardPanelAddress = (address) => ({
  type: type.SET_GUARD_PANEL_ADDRESS,
  address,
})

export const activateInProgressAlarm = () => ({
  type: type.ACTIVATE_IN_PROGRESS_ALARM,
})

export const deactivateInProgressAlarm = () => ({
  type: type.DEACTIVATE_IN_PROGRESS_ALARM,
})
