import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg data-name="Layer 1" viewBox="0 0 512 512">
      <path fill="#000000"
        d="M448 38.4h-38.4a38.4 38.4 0 0 0-76.8 0H179.2a38.4 38.4 0 1 0-76.8 0H64a64 64 0 0 0-64 64v326.4A83.2 83.2 0 0 0 83.2 512h345.6a83.2 83.2 0 0 0 83.2-83.2V102.4a64 64 0 0 0-64-64Zm-89.6 0a12.8 12.8 0 0 1 25.6 0V64a12.8 12.8 0 0 1-25.6 0Zm-230.4 0a12.8 12.8 0 1 1 25.6 0V64a12.8 12.8 0 1 1-25.6 0Zm-102.4 64a38.33 38.33 0 0 1 16.94-31.83A38.18 38.18 0 0 1 64 64h38.4a38.4 38.4 0 1 0 76.8 0h153.6a38.4 38.4 0 0 0 76.8 0H448a38.44 38.44 0 0 1 38.2 34.48 38.69 38.69 0 0 1 .2 3.92v51.2H25.6Zm403.2 384H83.2a57.56 57.56 0 0 1-53-35.33A63.61 63.61 0 0 0 64 460.8h384a63.61 63.61 0 0 0 33.8-9.73 57.56 57.56 0 0 1-53 35.33Zm57.6-89.6a38.44 38.44 0 0 1-38.4 38.4H64a38.44 38.44 0 0 1-38.4-38.4V179.2h460.8Z"
        className="cls-1"/>
      <path fill="#000000"
        d="M256 243.2a64 64 0 1 1-64 64 64.07 64.07 0 0 1 64-64m0-25.6a89.6 89.6 0 1 0 89.6 89.6 89.6 89.6 0 0 0-89.6-89.6Z"
        className="cls-1"/>
      <path fill="#000000"
        d="M281.6 345.6a12.76 12.76 0 0 1-9.05-3.75l-51.2-51.2a12.8 12.8 0 0 1 18.1-18.1l51.2 51.2a12.8 12.8 0 0 1-9.05 21.85Z"
        className="cls-1"/>
      <path fill="#000000"
        d="M230.4 345.6a12.8 12.8 0 0 1-9.05-21.85l51.2-51.2a12.8 12.8 0 0 1 18.1 18.1l-51.2 51.2a12.76 12.76 0 0 1-9.05 3.75Z"
        className="cls-1"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'EmptyEvents', '0 0 512 512', {fontSize: 220})

export default icon
