import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_ENERGIES } from '@app/constants/myEnergy/energyTab'
import { updateEnergies } from '@app/actions/myEnergy/energyTab'
import Api from '@app/api/myEnergy/fetchEnergies'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchEnergiesLogic = createLogic({
  type: SYNC_ENERGIES,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const currentDate = new Date()
    dispatch(apiRequestSent(SYNC_ENERGIES))
    Api.fetchEnergies(currentDate, AccessToken)
      .then((response) => {
        const { data: { energies } } = response
        dispatch(updateEnergies(energies))
        dispatch(apiRequestDone(SYNC_ENERGIES))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchEnergies failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchEnergiesLogic
