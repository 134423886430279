import { RGBThreshold } from '@app/lib/Scenes/colorActions'
import convertRGBAStringToObj from './convertRGBAStringToObj'

const getContrastColor = (color: string): string => {
  const convertedColor = convertRGBAStringToObj(color)
  // @ts-ignore
  if (convertedColor.red > RGBThreshold && convertedColor.blue > RGBThreshold && convertedColor.green > RGBThreshold) {
    return 'black'
  }
  return 'white'
}

export default getContrastColor
