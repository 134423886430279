const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 106px)',
  },
  mainDesktop: {
    width: '90%',
    padding: '60px 0',
  },
  radioWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    fontSize: '14px',
    margin: 0,
    height: '100%',
    color: '#929292',
  },
  datePicker: {
    maxWidth: 214,
    margin: '37px 0 13px 0',
  },
  dateWrapper: {
    position: 'relative',
    backgroundColor: '#141414',
    margin: '0 0 12px 0',
    padding: '0 14px 0 20px',
    borderRadius: '5px',
    minHeight: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  dateWrapperDesktop: {
    position: 'relative',
    backgroundColor: '#141414',
    margin: '0 0 60px 0',
    padding: '0 14px 0 20px',
    borderRadius: '5px',
    minHeight: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  inlinePickersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 245,
  },
  inlinePickersWrapperDesktop: {
    maxWidth: 480,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 45,
  },
  datePickerInline: {
    maxWidth: 120,
    margin: '37px 0 13px 0',
    fontSize: '16px',
  },
  datePickerInlineDesktop: {
    maxWidth: 214,
    width: '100%',
    margin: '37px 12px 13px 0',
    fontSize: '18px',
  },
  addButton: {
    width: '100%',
    height: 36,
    marginTop: 20,
  },
  button: {
    width: '100%',
    height: 50,
    marginTop: 20,
    margin: '0 auto',
    maxWidth: 300,
  },
  iconButton: {
    padding: 0,
    marginRight: 7,
  },
  radioIconDesktop: {
    marginTop: 15,
  },
  radioIcon: {
    marginTop: 15,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
