import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  closeRequestInfoPopup, resetCurrentRequest, setApprove, setReject,
} from '@app/actions/users/currentRequest'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import UserRequestDetails from '@app/components/Users/UserRequestDetails/UserRequestDetails'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

let enhance = withStyles(UserRequestDetails)

const mapStateToProps = (state) => {
  const { request: backendRequest, isInfoPopupOpen } = state.users.currentRequest
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const request = { ...backendRequest, ...{ choose_role: '' } }
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    path,
    isWebView,
    isMobile,
    request,
    isInfoPopupOpen,
    enableReinitialize: true,
    initialValues: request,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (parentDispatch) => bindActionCreators({
  reject: setReject,
  notifications: syncNotifications.sync,
  closeRequestInfoPopup,
  resetCurrentRequest,
  submit: (dispatch) => dispatch(submit('UserRequestDetailsForm')),
}, parentDispatch)

enhance = reduxForm({
  form: 'UserRequestDetailsForm',
  onSubmit: () => {
    store.dispatch(setApprove())
  },
})(enhance)

enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withRouter(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const UserRequestDetailsEnhanced = enhance

export default UserRequestDetailsEnhanced
