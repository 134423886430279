export const triggeringDeviceStates = [
  {
    description: 'is_off',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 0,
  },
  {
    description: 'is_on',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 1,
  },
  {
    description: 'is_any',
    isCardDisabled: false,
    isButtonDisabled: false,
    value: 2,
  },
]
