import React from 'react'
import createSvgIcon from '../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 40">
      <path fill="currentColor" d="M7.17 19.02a.4.4 0 0 0-.08.3.4.4 0 0 0 .17.27 7.25 7.25 0 0 0 8.45-.09.4.4 0 0 0 .07-.57.45.45 0 0 0-.6-.08 6.34 6.34 0 0 1-7.4.09.44.44 0 0 0-.33-.08.45.45 0 0 0-.28.16Zm4.24-1.15a5 5 0 0 0 2.77-.82c.1-.06.15-.16.17-.26a.35.35 0 0 0-.1-.29.52.52 0 0 0-.65-.06 3.98 3.98 0 0 1-4.27.08.54.54 0 0 0-.52-.02.44.44 0 0 0-.12.1.37.37 0 0 0-.08.14.33.33 0 0 0 0 .15c0 .05.03.1.06.15a4.97 4.97 0 0 0 2.74.83Z"/>
      <path fill="currentColor" fillRule="evenodd" d="M20.71 9.76V3.02c0-.29-.18-.51-.32-.65a3.25 3.25 0 0 0-.62-.46c-.5-.3-1.2-.6-2.02-.89A20.73 20.73 0 0 0 11.32 0C8.86 0 6.58.38 4.9.93c-.83.27-1.54.6-2.05.96-.49.34-.92.8-.92 1.36v6.51c0 .64.48 1.21 1.04 1.67.58.48 1.38.93 2.28 1.31.84.37 1.79.68 2.76.91.13.55.56.97 1.06 1.25.61.34 1.43.53 2.3.53.87 0 1.68-.2 2.3-.53.5-.29.94-.71 1.06-1.26.94-.23 1.85-.53 2.66-.88a9.7 9.7 0 0 0 2.3-1.31c.55-.46 1.02-1.04 1.02-1.69Zm-5.96 3.4.68-.19c.04-.14.06-.28.06-.42 0-1.32-1.85-2.39-4.12-2.39-2.28 0-4.12 1.07-4.12 2.39 0 .15.02.3.07.44H7.3l.68.18c.07-.61.54-1.08 1.08-1.39a4.81 4.81 0 0 1 2.3-.53c.87 0 1.68.2 2.3.53.54.3 1 .77 1.08 1.37Zm-7.96-.34c-2.41-.78-4.4-2.02-4.4-3.06V7.32c.82-.47 4.25-2.3 8.84-2.31 4.6 0 8.18 1.85 9.02 2.32v2.43c0 1.05-1.93 2.27-4.3 3.05.02-.09.02-.17.02-.26 0-.92-.64-1.65-1.45-2.12a6.38 6.38 0 0 0-3.15-.76 6.4 6.4 0 0 0-3.15.76c-.82.47-1.45 1.2-1.45 2.12 0 .1 0 .18.02.27Zm-4.4-9.57c0-1.3 4.1-2.79 8.93-2.79 4.84 0 8.93 1.82 8.93 2.56v1.52c-.8-.48-4.16-2.3-9.02-2.3-4.43 0-8 1.84-8.83 2.31v-1.3Zm0 1.94v1.5a19.1 19.1 0 0 1 8.84-2.32 18.3 18.3 0 0 1 9.02 2.3V5.2a19.24 19.24 0 0 0-9.02-2.32C6.64 2.88 3.2 4.71 2.4 5.2Zm11.9 8.15c0 .9-1.3 1.63-2.92 1.63-1.62 0-2.93-.73-2.93-1.63 0-.9 1.31-1.63 2.93-1.63s2.93.73 2.93 1.63Z" clipRule="evenodd"/>
      <path fill="currentColor" d="M19.41 38.34v-3.77a.86.86 0 0 0-.57-.8v-.34a.86.86 0 0 0-.86-.86H4.84a.86.86 0 0 0-.85.86v.34a.86.86 0 0 0-.58.8v3.77a.86.86 0 0 0-.57.8v.57c0 .16.13.29.29.29H19.7c.16 0 .28-.13.28-.29v-.57a.86.86 0 0 0-.57-.8ZM4.56 33.43c0-.16.13-.29.28-.29h13.14c.16 0 .3.13.3.29v.28H4.55v-.28Zm-.57 1.14c0-.16.12-.28.28-.28h14.29c.15 0 .28.12.28.28v3.72H4v-3.72Zm15.42 4.86h-16v-.29c0-.15.13-.28.29-.28h15.43c.16 0 .28.13.28.28v.29Z"/>
      <path fill="currentColor" d="M17.99 34.86H4.84a.29.29 0 1 0 0 .57H18a.29.29 0 1 0 0-.57Zm-7.43-3.72a.86.86 0 1 0 1.71 0 .86.86 0 0 0-1.71 0Zm1.14 0a.29.29 0 1 1-.57 0 .29.29 0 0 1 .57 0Zm-2-.2a.17.17 0 0 0 .07-.2l-.57-1.56a.17.17 0 0 0-.05-.07l-.56-.5-3.3-5.74.2-.11a.44.44 0 0 0 .16-.6l-.05-.08a.44.44 0 0 0-.56-.18 1.08 1.08 0 0 1-.7-1.33c.06-.3 0-.6-.14-.85L2.28 16.4a1.24 1.24 0 0 0-2.04-.16c-.1.12-.16.26-.2.4-.08.3-.04.65.14.97l1.92 3.32a1.24 1.24 0 0 0 .69.56c.58.15.94.7.84 1.22a.44.44 0 0 0-.12.58l.04.07a.44.44 0 0 0 .6.17l.2-.12 3.31 5.73.16.74c0 .03.02.05.04.07l1.06 1.28a.17.17 0 0 0 .21.04l.57-.33ZM3.78 19.66a.4.4 0 0 1-.39-.2L2.15 17.3a.4.4 0 0 1 .02-.43l1.6 2.79ZM.65 17.75a.4.4 0 0 1 .38.2l1.25 2.15a.4.4 0 0 1-.02.44l-1.61-2.8Zm2.23 3.42a.9.9 0 0 1-.44-.33.73.73 0 0 0 .13-.9l-1.25-2.16a.73.73 0 0 0-.85-.33c-.26-.46-.14-.98.28-1.22a.91.91 0 0 1 1.24.34.73.73 0 0 0-.13.9l1.25 2.16a.73.73 0 0 0 .84.33c.08.17.1.37.06.55a1.42 1.42 0 0 0 .67 1.59l-.7.4a1.45 1.45 0 0 0-1.1-1.33Zm.96 2.03-.05-.08a.11.11 0 0 1 .04-.15l1.34-.76a.1.1 0 0 1 .15.04l.04.07a.11.11 0 0 1-.04.15l-1.33.77a.1.1 0 0 1-.15-.04Zm4.3 6.57-.16-.72a.17.17 0 0 0-.02-.05l-3.32-5.76.36-.2 3.32 5.75c0 .02.02.03.03.04l.55.5.51 1.4-.32.18-.95-1.14Zm-2.94.53.53-.53-1.86-.95a.26.26 0 0 1-.11-.11c-.02-.05-.02-.09 0-.11l.78-.78c.03-.03.09-.02.14.02l2.4 1.63c.04.03.08.07.1.11l-.01.1-.56.55 1.76 1.07c.07.04.11.1.12.16 0 .06-.05.08-.1.06l-3.06-.98c-.1-.04-.2-.18-.13-.24Zm2.35.73-1.16-.71c-.1-.06-.15-.17-.1-.22l.56-.55-2.13-1.46-.62.62 1.86.95c.1.05.16.17.1.22l-.5.5 2 .65Z"/>
      <path fill="currentColor" d="m3.66 31.47.29-.64-1.93-.2a.24.24 0 0 1-.13-.05c-.04-.03-.05-.07-.04-.1l.42-.93c.01-.03.06-.05.12-.03l2.62.6c.05.02.09.04.12.07.03.03.04.06.02.09l-.29.66 1.88.33c.07.01.13.06.15.1.02.05 0 .09-.07.1l-2.96.16c-.1 0-.23-.09-.2-.16Zm2.28-.16L4.7 31.1c-.1-.02-.18-.1-.16-.15l.3-.67-2.33-.55-.33.75 1.92.19c.1.01.2.1.17.16l-.27.6 1.94-.1Z"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'TamperIcon', '0 0 80 80', {fontSize: 35})

export default icon
