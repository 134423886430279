import { createLogic } from 'redux-logic'
import { SYNC_NOTIFICATIONS_SETTING } from '@app/constants/notifications/notificationsSettings'
import { update } from '@app/actions/notifications/notificationsSettings'
import Api from '@app/api/notifications/fetchNotificationsSettings'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import handleErrors from '@app/helpers/handleErrors'

const fetchNotificationsSettingsLogic = createLogic({
  type: SYNC_NOTIFICATIONS_SETTING,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_NOTIFICATIONS_SETTING))
    Api.fetchNotificationsSettings(token)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(apiRequestDone(SYNC_NOTIFICATIONS_SETTING))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchNotificationsSettings failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchNotificationsSettingsLogic
