import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M80.7119 86.8536L80.6646 86.6641H80.4693H38.501H38.3056L38.2584 86.8536L33.947 104.151C31.2185 104.984 29.2185 107.505 29.2185 110.503V119.117C29.2185 121.967 31.0169 124.371 33.5122 125.338V129.851C33.5122 132.344 35.5344 134.395 38.0559 134.395H44.4965C46.9898 134.395 49.0402 132.373 49.0402 129.851V125.807H67.8356V129.851C67.8356 132.344 69.8578 134.395 72.3793 134.395H78.8199C81.3133 134.395 83.3636 132.373 83.3636 129.851V125.803C86.9462 125.672 89.8041 122.732 89.8041 119.117L89.8041 110.529L89.8041 110.527C89.7774 107.529 87.7505 105.01 85.0236 104.153L80.7119 86.8536ZM76.9229 91.4839L80.0182 103.865H38.9521L42.0474 91.4839H76.9229ZM44.2203 129.575H38.2797V125.781H44.2203V129.575ZM78.5437 129.575H72.6032V125.781H78.5437V129.575ZM84.9843 119.117C84.9843 120.157 84.1275 121.014 83.0874 121.014H35.8829C34.8428 121.014 33.986 120.157 33.986 119.117V110.529C33.986 109.489 34.8428 108.633 35.8829 108.633H83.0874C84.1275 108.633 84.9843 109.489 84.9843 110.529V119.117Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M40.1765 112.426H39.9265V112.676V116.969V117.219H40.1765H44.4702H44.7202V116.969V112.676V112.426H44.4702H40.1765Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M72.3533 112.426H72.1033V112.676V116.969V117.219H72.3533H76.647H76.897V116.969V112.676V112.426H76.647H72.3533Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M23.278 136.266V76.4563H95.7185V136.266V136.516H95.9685H141H141.25V136.266V131.972V131.722H141H130.516V31.1486V30.8986H130.266H119.781V14V13.75H119.531H29.4423H29.1923V14V30.8986H18.7343H18.4843V31.1486V131.722H8H7.75V131.972V136.266V136.516H8H23.028H23.278V136.266ZM100.236 50.2203H23.2518V35.6923H125.722V131.722H100.486V50.4703V50.2203H100.236ZM33.986 18.5437H115.014V30.8986H33.986V18.5437ZM95.7185 60.9283H23.278V54.9878H95.7185V60.9283ZM23.278 71.6626V65.722H95.7185V71.6626H23.278Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M72.3533 22.3359H72.1033V22.5859V26.8796V27.1296H72.3533H76.647H76.897V26.8796V22.5859V22.3359H76.647H72.3533Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M80.9407 22.3359H80.6907V22.5859V26.8796V27.1296H80.9407H85.2344H85.4844V26.8796V22.5859V22.3359H85.2344H80.9407Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M63.7656 22.3359H63.5156V22.5859V26.8796V27.1296H63.7656H68.0593H68.3093V26.8796V22.5859V22.3359H68.0593H63.7656Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'OpenedGarage')

export default icon
