import React from 'react'
import {
  Button, Paper, Slider, Typography,
} from '@mui/material'
import classNames from 'classnames'
import {
  DEFAULT_VALUE, marks, MAX, MIN,
} from '@app/lib/Scenes/multilevelSwitchActionStates'
import {
  EDIT_ACTION,
  SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED,
  SELECT_MULTILEVEL_SWITCH_ACTION_TYPE,
} from '@app/constants/routes'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectMultilevelSwitchActionStateValue extends React.PureComponent {
  state ={
    currentValue: 50,
  }

  onBackClick=() => {
    const {
      navigate,
      restoreActionInfo,
      isEditActionMode,
      actions,
      initialAction,
    } = this.props
    if (isEditActionMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    }
    restoreActionInfo()
    navigate(SELECT_MULTILEVEL_SWITCH_ACTION_TYPE)
  }

  getValueTextHandler = (value) => `${value}%`

  getLabelValueHandler = (value) => {
    if (value === 99) {
      return 100
    }
    return value
  }

  getOnChangeSliderValue = (evt, value) => {
    if (value === 99) {
      this.setState({ currentValue: 100 })
    } else {
      this.setState({ currentValue: value })
    }
  }

  getSliderDescription = (value) => {
    switch (value) {
    case 100:
    case 99:
      return 'turn on at 100%'
    case 0:
      return 'turn off'
    default:
      return `turn on at ${value}%`
    }
  }

  onAddButtonClick=() => {
    const { currentValue } = this.state
    const {
      saveActionStateDescription,
      navigate,
      isChangeActionStateMode,
      isChangeActionMode,
      saveActionDelay,
      saveActionDelayDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      saveDurationValueAfterActionColorType,
    } = this.props
    saveActionStateDescription(this.getSliderDescription(currentValue))
    navigate(SELECT_MULTILEVEL_SWITCH_ACTION_STATE_SELECTED)
    if (isChangeActionStateMode || isChangeActionMode) {
      saveActionDelay({})
      saveActionDelayDescription('')
      saveActionDuration({})
      saveActionDurationDescription('')
      saveActionDurationValueAfter('none')
      saveDurationValueAfterActionColorType('')
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    const { currentValue } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_action_web.select_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <Wrapper className={classNames(classes.switchContainer, isMobile && classes.switchContainerMobile)}>
              <Wrapper className={classes.containerMisc}>
                <Typography className={classes.miscTitle}>
                  {I18n.t('scene_action_web.turn_on_at')}
                  {` ${currentValue}%`}
                </Typography>
              </Wrapper>
              <Slider
                className={classes.slider}
                defaultValue={DEFAULT_VALUE}
                min={MIN}
                max={MAX}
                getAriaValueText={this.getValueTextHandler}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay={isMobile ? 'off' : 'auto'}
                valueLabelFormat={this.getLabelValueHandler}
                marks={marks}
                onChange={this.getOnChangeSliderValue}
              />
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddButtonClick}
          >
            {' '}
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultilevelSwitchActionStateValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isChangeActionMode: PropTypes.bool.isRequired,
  saveDurationValueAfterActionColorType: PropTypes.func.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectMultilevelSwitchActionStateValue.displayName = 'SelectMultilevelSwitchConditionsStateIOS'

export default SelectMultilevelSwitchActionStateValue
