import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import { mezzoActionStates } from '@app/lib/Scenes/mezzoActionStates'
import {
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE,
  SELECT_MEZZO_CHIMES_STATE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectMezzoActionState extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeActionStateMode,
      isEditActionMode,
      actions,
      initialAction,
    } = this.props
    if (isChangeActionStateMode) {
      if (isEditActionMode) {
        restoreEditedActionInfo(actions, initialAction)
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      navigate(SELECT_ACTION_SECURITY_MANAGEMENT_DEVICE)
    }
  }

  selectState=(type) => {
    const {
      navigate,
      saveActionType,
      saveActionStateDescription,
      isEditActionMode,
    } = this.props
    if (type.stateType === 'PlayAudio') {
      navigate(SELECT_MEZZO_CHIMES_STATE)
    } else {
      saveActionType(type.stateType)
      saveActionStateDescription(type.description)
      if (isEditActionMode) {
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            mezzoActionStates.map((type) => (
              <CardBlock
                key={type.description}
                title={type.title}
                description={I18n.t(`scene_action_web.${type.description}`)}
                position={type.position}
                buttonName={type.buttonName}
                isCardDisabled={type.isCardDisabled}
                isButtonDisabled={type.isButtonDisabled}
                isValueButton={type.isValueButton}
                onClick={() => {
                  this.selectState(type)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectMezzoActionState.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionType: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectMezzoActionState.displayName = 'SelectMezzoActionStateIOS'

export default SelectMezzoActionState
