import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/devices`

const convertDate = (current_time) => current_time.toISOString()

const fetchDevicesWithEnergies = (current_time, access_token, sort) => Axios.get(ENDPOINT, {
  params: {
    current_time: convertDate(current_time),
    sort,
  },
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchDevicesWithEnergies,
}
