import React from 'react'
import { Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { GearsIcon } from 'icons'
import useStyles from './SettingsButton.style'

const SettingsButton: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  return (
    <Wrapper className={classes.deviceSettingsContainer}>
      <Link
        to={`${location.pathname}/settings`}
      >
        <Wrapper className={classes.deviceSettingsItem}>
          <Wrapper className={classes.deviceSettingsIcon}>
            <GearsIcon />
          </Wrapper>
          <Typography className={classes.deviceSettingsLabel}>{I18n.t('my_devices.settings')}</Typography>
        </Wrapper>
      </Link>
    </Wrapper>
  )
}

export default SettingsButton
