import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
    margin: '1rem 0',

    '& .carousel .slide': {
      background: 'none',
    },

    '& .carousel-root': {
      outline: 0,
      border: 'none',
    },
  },
  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '0 2rem 3rem',
  },
  icon: {
    margin: '1rem',
    fontSize: 40,
  },
  iconMobile: {
    fontSize: 30,
    margin: '.5rem',
  },
  iconOutline: {
    cursor: 'pointer',
    borderRadius: '50%',
    width: 72,
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s ease-out',

    '&:hover': {
      backgroundColor: theme.backgrounds.cardBackground,
    },
  },
  iconOutlineMobile: {
    width: 62,
    height: 62,
  },
  active: {
    backgroundColor: theme.backgrounds.cardBackground,
  },
}))

export default useStyles
