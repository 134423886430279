import { createLogic } from 'redux-logic'
import Api from '@app/api/groups'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_GROUP } from '@app/constants/groups/groups'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setPatchGroup } from '@app/actions/groups/groups'
import { GROUPS } from '@app/constants/routes'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const patchGroup = createLogic({
  type: PATCH_GROUP,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(PATCH_GROUP))
    Api.patchGroup(AccessToken, action.data, action.data.id)
      .then((response) => {
        dispatch(setPatchGroup(response.data.group))
        dispatch(apiRequestDone(PATCH_GROUP))
        history.push(GROUPS)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchGroup failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchGroup
