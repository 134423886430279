// @ts-nocheck
import { brightnessValues } from '@app/lib/Scenes/mezzoBrightnessValues'
import { mezzoChimesState } from '@app/lib/Scenes/mezzoChimesState'
import { ColorTypes, swatchColorsStrings } from '@app/lib/Scenes/colorActions'
import { alarmPanicDuressNumbers, muteOrUnmuteVoiceValues } from '@app/lib/Scenes/mezzoActionsValues'
import { virtualSwitchesStatesAction } from '@app/lib/Scenes/virtualSwitchesStates'
import { IActionFromBackend, IActionFront } from '@app/@types/scenes/scenes'
import getDelayDurationDescription from './getDelayDurationDescription'
import getSelectedUsersString from './getSelectedUsersString'

// eslint-disable-next-line max-len
const checkIfDoubleColoursActions = (actionType) => actionType === 'ZwaveColorSetWithDuration' || actionType === 'RoomColorDelayDuration' || actionType === 'RoomColorWithDuration' || actionType === 'ZwaveColorDelayDuration'

const getActionColorType = (action, currentColor) => {
  let colorType
  if (action.data[currentColor].white_warm) {
    colorType = ColorTypes.whiteWarm
  } else if (action.data[currentColor].white_cold) {
    colorType = ColorTypes.whiteCold
  } else if (swatchColorsStrings.find((el) => el === action.value_title)) {
    colorType = ColorTypes.swatch
  } else if (swatchColorsStrings.find((el) => el === action.value_title[currentColor])) {
    colorType = ColorTypes.swatch
  } else {
    colorType = ColorTypes.rgb
  }
  return colorType
}

const getSwitchesDescription = (switchType, value) => {
  switch (switchType) {
  case 'door_lock':
    return value === 255 ? 'lock' : 'unlock'
  case 'switch_multilevel':
    if (value === 99) {
      return 'turn on at 100%'
    }
    if (value === 0) {
      return 'turn off'
    }
    if (value === 0xFF) {
      return 'turn on previous'
    }
    return `turn on at ${value}%`
  case 'multichannel':
    if (value === 0) {
      return 'turn_off'
    }
    if (value === 1) {
      return 'turn_on'
    }
    return 'toggle'
  default:
    return value === 1 ? 'turn_on' : 'turn_off'
  }
}

const getActionStateDescription = (action, currentColor, actionType) => {
  let stateDescription
  if (action.data[currentColor].white_warm) {
    stateDescription = action.data[currentColor].white_warm.toString()
  } else if (action.data[currentColor].white_cold) {
    stateDescription = action.data[currentColor].white_cold.toString()
  } else if (swatchColorsStrings.find((el) => el === action.value_title)) {
    stateDescription = action.value_title
  } else if (swatchColorsStrings.find((el) => el === action.value_title[currentColor])) {
    stateDescription = action.value_title[currentColor]
  } else {
    stateDescription = checkIfDoubleColoursActions(actionType) ? action.value_title[currentColor] : action.value_title
  }
  return stateDescription
}

const getEditedActionInfo = (action: IActionFromBackend): IActionFront => {
  const actionInfo = {
    id: 0,
    stateType: '',
    subDevices: [],
    currentSubDevice: {},
    actionType: '',
    actionDescription: '',
    stateDescription: '',
    actionDeviceNumber: 0,
    actionDeviceName: '',
    actionZWaveCapabilityId: 0,
    actionDeviceId: 0,
    actionDeviceStateInfo: {},
    deviceType: '',
    colorType: '',
    delay: {},
    delayDescription: '',
    duration: {},
    durationDescription: '',
    durationValueAfter: 'none',
    colorTypeDurationValueAfter: '',
    roomInfo: {
      roomNumber: 0,
      roomValue: 0,
    },
    groupInfo: {
      groupNumber: 0,
      groupValue: 0,
    },
    selectedUsers: [],
    text: '',
    requestUrl: '',
    requestType: 'post',
    requestBody: [{ value_type: 'key_text', key: '', value: '' }],
    requestHeader: [{ key: '', value: '' }],
  }
  actionInfo.id = action.id
  switch (action.type) {
  case 'PlayAudio':
    actionInfo.actionType = 'PlayAudio'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = mezzoChimesState.filter((chime) => chime.stateType
      === action.data.value)[0].description
    return actionInfo
  case 'SetZwaveColor':
    actionInfo.actionType = 'SetZwaveColor'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.colorType = getActionColorType(action, 'colours')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours')
    return actionInfo

  case 'ZwaveColorSetWithDuration':
    actionInfo.actionType = 'ZwaveColorSetWithDuration'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.colorType = getActionColorType(action, 'colours_before')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours_before', 'ZwaveColorSetWithDuration')
    actionInfo.colorTypeDurationValueAfter = getActionColorType(action, 'colours_after')
    actionInfo.durationValueAfter = getActionStateDescription(action, 'colours_after', 'ZwaveColorSetWithDuration')
    actionInfo.duration = action.data.duration
    actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
    return actionInfo

  case 'LightMezzo':
    actionInfo.actionType = 'LightMezzo'
    actionInfo.actionDescription = 'Device light'
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = brightnessValues[action.data.value]
    actionInfo.duration = action.data.duration
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.durationValueAfter = brightnessValues[action.data.duration.value_after]
    }
    return actionInfo

  case 'MezzoBottomLightColor':
    actionInfo.actionType = 'MezzoBottomLightColor'
    actionInfo.actionDescription = 'Device light'
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = action.value_title === 'rgba(0,0,0,1)' ? 'none' : action.value_title
    actionInfo.duration = action.data.duration
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.durationValueAfter = 'turn_off'
    }
    return actionInfo

  case 'MezzoTopLightColor':
    actionInfo.actionType = 'MezzoTopLightColor'
    actionInfo.actionDescription = 'Device light'
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = action.value_title === 'rgba(0,0,0,1)' ? 'none' : action.value_title
    actionInfo.duration = action.data.duration
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.durationValueAfter = 'turn_off'
    }
    return actionInfo

  case 'ZwaveColorDelayDuration':
    actionInfo.actionType = 'ZwaveColorDelayDuration'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.colorType = getActionColorType(action, 'colours_before')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours_before', 'ZwaveColorDelayDuration')
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.durationValueAfter = getActionStateDescription(action, 'colours_after', 'ZwaveColorDelayDuration')
      actionInfo.duration = action.data.duration
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.colorTypeDurationValueAfter = getActionColorType(action, 'colours_after')
    }
    actionInfo.delay = action.data.delay
    actionInfo.delayDescription = getDelayDurationDescription(actionInfo.delay)
    return actionInfo

  case 'Toggle':
    actionInfo.actionType = 'Toggle'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.stateDescription = 'toggle'
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    return actionInfo
  case 'SetDeviceStatus':
    actionInfo.actionType = 'SetDeviceStatus'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.stateDescription = getSwitchesDescription(action.device.switch_type, action.data.value)
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.duration = {
        day: action.data.duration.day,
        hour: action.data.duration.hour,
        second: action.data.duration.second,
        minute: action.data.duration.minute,
        value_after: action.data.duration.value_after,
      }
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.durationValueAfter = getSwitchesDescription(action.device.switch_type, action.data.duration.value_after)
    }
    return actionInfo

  case 'DelayedPlusDurationDeviceAction':
    actionInfo.actionType = 'DelayedPlusDurationDeviceAction'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.stateDescription = getSwitchesDescription(action.device.switch_type, action.data.delay.value_after)
    actionInfo.duration = {
      day: action.data.duration.day,
      hour: action.data.duration.hour,
      second: action.data.duration.second,
      minute: action.data.duration.minute,
    }
    actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
    actionInfo.durationValueAfter = getSwitchesDescription(action.device.switch_type, action.data.duration.value_after)
    actionInfo.delay = {
      day: action.data.delay.day,
      hour: action.data.delay.hour,
      second: action.data.delay.second,
      minute: action.data.delay.minute,
    }
    actionInfo.delayDescription = getDelayDurationDescription(actionInfo.delay)
    return actionInfo
  case 'DelayedDeviceAction':
    actionInfo.actionType = 'DelayedDeviceAction'
    actionInfo.stateDescription = getSwitchesDescription(action.device.switch_type, action.data.value)
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.delay = action.data.delay
    actionInfo.delayDescription = getDelayDurationDescription(action.data.delay)
    return actionInfo
  case 'ToggleZwaveDeviceWithDuration':
    actionInfo.actionType = 'ToggleZwaveDeviceWithDuration'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.stateDescription = 'toggle'
    actionInfo.duration = {
      day: action.data.duration.day,
      hour: action.data.duration.hour,
      second: action.data.duration.second,
      minute: action.data.duration.minute,
    }
    actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
    actionInfo.durationValueAfter = 'toggle'
    return actionInfo
  case 'ArmTheSystem':
    actionInfo.actionType = 'ArmTheSystem'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = 'arm_system'
    return actionInfo
  case 'ArmInHomeMode':
    actionInfo.actionType = 'ArmInHomeMode'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = 'arm_home_mode'
    return actionInfo
  case 'Disarm':
    actionInfo.actionType = 'Disarm'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = 'disarm'
    return actionInfo
  case 'ToggleVoice':
    actionInfo.actionType = 'ToggleVoice'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = 'toggle_voice'
    return actionInfo
  case 'MuteOrUnmuteVoice':
    actionInfo.actionType = 'MuteOrUnmuteVoice'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = muteOrUnmuteVoiceValues[action.data.value]
    return actionInfo
  case 'AlarmPanicDuress':
    actionInfo.actionType = 'AlarmPanicDuress'
    actionInfo.actionDescription = 'Device'
    actionInfo.stateDescription = alarmPanicDuressNumbers[action.data.value]
    return actionInfo
  case 'ControlRoom':
    actionInfo.actionType = 'ControlRoom'
    actionInfo.actionDescription = action.room.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = getSwitchesDescription('room', action.data.value)
    actionInfo.roomInfo.roomNumber = action.data.number
    actionInfo.roomInfo.roomValue = action.data.value
    return actionInfo
  case 'RoomColorControl':
    actionInfo.actionType = 'RoomColorControl'
    actionInfo.actionDescription = action.room.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.colorType = getActionColorType(action, 'colours')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours')
    actionInfo.roomInfo.roomNumber = action.data.number
    actionInfo.roomInfo.roomValue = 0
    return actionInfo

  case 'RoomColorWithDuration':
    actionInfo.actionType = 'RoomColorWithDuration'
    actionInfo.actionDescription = action.room.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.colorType = getActionColorType(action, 'colours_before')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours_before', 'RoomColorWithDuration')
    actionInfo.colorTypeDurationValueAfter = getActionColorType(action, 'colours_after')
    actionInfo.durationValueAfter = getActionStateDescription(action, 'colours_after', 'RoomColorWithDuration')
    actionInfo.duration = action.data.duration
    actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
    actionInfo.roomInfo.roomNumber = action.data.number
    actionInfo.roomInfo.roomValue = 0
    return actionInfo

  case 'RoomColorDelayDuration':
    actionInfo.actionType = 'RoomColorDelayDuration'
    actionInfo.actionDescription = action.room.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.colorType = getActionColorType(action, 'colours_before')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours_before', 'RoomColorDelayDuration')
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.durationValueAfter = getActionStateDescription(action, 'colours_after', 'RoomColorDelayDuration')
      actionInfo.duration = action.data.duration
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.colorTypeDurationValueAfter = getActionColorType(action, 'colours_after')
    }
    actionInfo.roomInfo.roomNumber = action.data.number
    actionInfo.roomInfo.roomValue = 0
    actionInfo.delay = action.data.delay
    actionInfo.delayDescription = getDelayDurationDescription(action.data.delay)
    return actionInfo

  case 'ControlGroup':
    actionInfo.actionType = 'ControlGroup'
    actionInfo.actionDescription = action.group.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = getSwitchesDescription('group', action.data.value)
    actionInfo.groupInfo.groupNumber = action.data.number
    actionInfo.groupInfo.groupValue = action.data.value
    return actionInfo

  case 'GroupColorControl':
    actionInfo.actionType = 'GroupColorControl'
    actionInfo.actionDescription = action.group.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.colorType = getActionColorType(action, 'colours')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours')
    actionInfo.groupInfo.groupNumber = action.data.number
    actionInfo.groupInfo.groupValue = 0
    return actionInfo
  case 'GroupColorWithDuration':
    actionInfo.actionType = 'GroupColorWithDuration'
    actionInfo.actionDescription = action.group.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.colorType = getActionColorType(action, 'colours_before')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours_before', 'GroupColorWithDuration')
    actionInfo.colorTypeDurationValueAfter = getActionColorType(action, 'colours_after')
    actionInfo.durationValueAfter = getActionStateDescription(action, 'colours_after', 'GroupColorWithDuration')
    actionInfo.duration = action.data.duration
    actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
    actionInfo.groupInfo.groupNumber = action.data.number
    actionInfo.groupInfo.groupValue = 0
    return actionInfo

  case 'GroupColorDelayDuration':
    actionInfo.actionType = 'GroupColorDelayDuration'
    actionInfo.actionDescription = action.group.name
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.colorType = getActionColorType(action, 'colours_before')
    actionInfo.stateDescription = getActionStateDescription(action, 'colours_before', 'GroupColorDelayDuration')
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.durationValueAfter = getActionStateDescription(action, 'colours_after', 'GroupColorDelayDuration')
      actionInfo.duration = action.data.duration
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.colorTypeDurationValueAfter = getActionColorType(action, 'colours_after')
    }
    actionInfo.groupInfo.groupNumber = action.data.number
    actionInfo.groupInfo.groupValue = 0
    actionInfo.delay = action.data.delay
    actionInfo.delayDescription = getDelayDurationDescription(action.data.delay)
    return actionInfo
  case 'StartTimer':
    actionInfo.actionType = 'StartTimer'
    actionInfo.actionDescription = action.data.number.toString()
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = 'turn_on'
    return actionInfo
  case 'StopTimer':
    actionInfo.actionType = 'StopTimer'
    actionInfo.actionDescription = action.data.number.toString()
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = 'turn_off'
    return actionInfo
  case 'WebAction':
    if (action.data.web_action_type === 'PushNotifications') {
      actionInfo.stateDescription = getSelectedUsersString(action.data.users)
      actionInfo.selectedUsers = action.data.users
      actionInfo.text = action.data.text
      actionInfo.actionType = 'PushNotifications'
      actionInfo.actionDeviceStateInfo = {
        text: action.data.text,
        users: action.data.users,
        web_action_type: 'PushNotifications',
      }
    } else if (action.data.web_action_type === 'Email') {
      actionInfo.stateDescription = getSelectedUsersString(action.data.users)
      actionInfo.selectedUsers = action.data.users
      actionInfo.text = action.data.text
      actionInfo.actionType = 'Email'
      actionInfo.actionDeviceStateInfo = {
        text: action.data.text,
        users: action.data.users,
        web_action_type: 'Email',
      }
    } else {
      actionInfo.actionType = 'WebRequest'
      actionInfo.stateDescription = 'execute Web request'
      actionInfo.actionDeviceStateInfo = action.data
      actionInfo.requestBody = [...action.data.body, { value_type: 'key_text', key: '', value: '' }]
      actionInfo.requestHeader = [...action.data.headers, { key: '', value: '' }]
      actionInfo.requestType = action.data.request_type
      actionInfo.requestUrl = action.data.url
    }
    return actionInfo
  case 'VirtualSwitch':
    actionInfo.actionType = 'VirtualSwitch'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDescription = action.data.number.toString()
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = virtualSwitchesStatesAction[virtualSwitchesStatesAction
      .findIndex((el) => el.value === action.data.value)].title
    return actionInfo
  case 'VirtualSwitchDelayDuration':
    actionInfo.actionType = 'VirtualSwitchDelayDuration'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDescription = action.data.number.toString()
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.stateDescription = virtualSwitchesStatesAction[virtualSwitchesStatesAction
      .findIndex((el) => el.value === action.data.delay.value_after)].title
    actionInfo.delay = {
      day: action.data.delay.day,
      hour: action.data.delay.hour,
      second: action.data.delay.second,
      minute: action.data.delay.minute,
    }
    actionInfo.duration = {
      day: action.data.duration.day,
      hour: action.data.duration.hour,
      second: action.data.duration.second,
      minute: action.data.duration.minute,
    }
    actionInfo.delayDescription = getDelayDurationDescription(actionInfo.delay)
    actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
    actionInfo.durationValueAfter = getDelayDurationDescription(actionInfo.duration)
      ? virtualSwitchesStatesAction[virtualSwitchesStatesAction
        .findIndex((el) => el.value === action.data.duration.value_after)].title
      : 'none'
    return actionInfo

  case 'ZwaveMultichannelControl':
    actionInfo.actionType = 'ZwaveMultichannelControl'
    actionInfo.actionDeviceNumber = action.data.number
    actionInfo.actionDeviceName = action.device.name
    actionInfo.actionZWaveCapabilityId = action.device.capability_id
    actionInfo.actionDeviceId = action.device.id
    actionInfo.actionDeviceStateInfo = action.data
    actionInfo.deviceType = 'ZWaveDevices'
    actionInfo.stateDescription = getSwitchesDescription('multichannel', action.data.value)
    actionInfo.currentSubDevice = action.device.sub_devices.find((device) => device.number === action.data.channel)
    if (action.data.duration.day || action.data.duration.hour || action.data.duration.second || action.data.duration.minute) {
      actionInfo.duration = {
        day: action.data.duration.day,
        hour: action.data.duration.hour,
        second: action.data.duration.second,
        minute: action.data.duration.minute,
        value_after: action.data.duration.value_after,
      }
      actionInfo.durationDescription = getDelayDurationDescription(actionInfo.duration)
      actionInfo.durationValueAfter = getSwitchesDescription(action.device.switch_type, action.data.duration.value_after)
    }
    return actionInfo
  default:
    throw new Error('action unsupported')
  }
}

export default getEditedActionInfo
