import { createLogic } from 'redux-logic'
import Api from '@app/api/mySecurity/mySecurity'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { SEND_KEYBOARD_COMMAND } from '@app/constants/mySecurity/mySecurity'

const postKeypadCommand = createLogic({
  type: SEND_KEYBOARD_COMMAND,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const currentCommand = { command: action.command }
    next({
      ...action,
      accessToken,
      currentCommand,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SEND_KEYBOARD_COMMAND))
    try {
      await Api.postKeypadCommand(action.accessToken, action.currentCommand)
      dispatch(apiRequestDone(SEND_KEYBOARD_COMMAND))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('postKeypadCommand failed without error')
      }
    } finally {
      done()
    }
  },
})

export default postKeypadCommand
