import * as type from '@app/constants/myEnergy/deviceManager'

export const resetModeOn = () => ({
  type: type.RESET_MODE_ON,
})

export const resetModeOff = () => ({
  type: type.RESET_MODE_OFF,
})

export const updateDeviceEnergies = (energy_devices, sort) => ({
  type: type.UPDATE_DEVICE_ENERGIES,
  energy_devices,
  sort,
})

export const sync = () => ({
  type: type.SYNC_DEVICE_ENERGIES,
})

export const toggleSelectedSorting = () => ({
  type: type.TOGGLE_SELECTED_SORTING,
})

export const addDeviceToResetArray = (device_id) => ({
  type: type.ADD_DEVICE_TO_RESET_ARRAY,
  device_id,
})

export const deleteDeviceFromResetArray = (device_id) => ({
  type: type.DELETE_DEVICE_FROM_RESET_ARRAY,
  device_id,
})

export const addAllDevicesToResetArray = () => ({
  type: type.ADD_ALL_DEVICES_TO_RESET_ARRAY,
})

export const clearDevicesResetArray = () => ({
  type: type.CLEAR_DEVICES_RESET_ARRAY,
})

export const set = () => ({
  type: type.RESET_DEVICES_ENERGIES,
})
