import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import ShowGroup from '@app/components/Groups/ShowGroup/ShowGroup'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

let enhance = ShowGroup

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  return {
    isMobile,
    isTablet,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRouter(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ShowGroupEnhanced = enhance

export default ShowGroupEnhanced
