import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  typeButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    fontSize: '20px',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  card: {
    backgroundColor: '#141414',
    marginBottom: 22,
    borderRadius: '5px',
    minHeight: 50,
    'box-shadow': 'none',
  },
  cardDisabled: {
    backgroundColor: '#141414',
    marginBottom: 22,
    borderRadius: '5px',
    minHeight: 50,
    opacity: 0.5,
    'box-shadow': 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '1%',
    '&:last-child': {
      paddingBottom: '2%',
    },
  },
  cardContentMobile: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '1% 3%',
    '&:last-child': {
      paddingBottom: '2%',
    },
  },
  cardData: {
    width: '100%',
  },
  blockTitle: {
    color: theme.scenes.title,
    fontSize: '18px',
    paddingRight: 20,
  },
  blockTitleTop: {
    color: theme.scenes.titleTop,
    fontSize: '18px',
    paddingRight: 20,
  },
  blockDescription: {
    fontSize: '18px',
    display: 'inline-block',
    marginRight: 5,
    paddingRight: 20,
  },
  blockDescriptionTop: {
    fontSize: '16px',
    color: theme.scenes.descriptionTop,
  },
  buttonWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  button: {
    padding: 0,
    position: 'absolute',
    right: 5,
    top: '50%',
    marginTop: '-12px',
    color: '#fff',
    transform: 'rotate(-90deg)',
  },
  svgWrapper: {
    width: 60,
    marginRight: 15,
  },
  additionalDesc: {
    display: 'inline-block',
    marginLeft: 5,
    marginRight: 5,
    width: 10,
    height: 10,
    border: '1px solid rgba(0,0,0, 0.3)',
  },
  cardText: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  clearButton: {
    marginLeft: 5,
  },
  clearIcon: {
    fontSize: 40,
    position: 'absolute',
    top: 6,
    left: 6,
  },
}))

export default useStyles
