const convertRoomOrGroupStateFromEditedConditionInfo = (state: number): string => {
  switch (state) {
  case 0:
    return 'turn_off'
  case 1:
    return 'turn_on'
  case 2:
    return 'any_changes'
  default:
    return ''
  }
}
export default convertRoomOrGroupStateFromEditedConditionInfo
