export const sirenOptions = [
  'chirp_strobe_on_arm',
  'no_chirp_strobe_on_arm',
  'strobe_only_on_arm',
  'chirp_only_on_arm',
  'reset_output',
  'radio_siren_off',
]

export const sirenValue = {
  MIN: 0,
  MAX: 5,
  WITHOUT_SIREN: -1,
}
