import React from 'react'
import classNames from 'classnames'
import {
  Button, Input, Paper, Slider, Tooltip, Typography,
} from '@mui/material'
import { getOffsetString } from '@app/helpers/scenes/getOffsetString'
import {
  DEFAULT_VALUE, marks, MAX, MIN, STEP,
} from '@app/lib/Scenes/offsetSettings'
import { CREATE_CONDITION_SELECTED, EDIT_CONDITION, SELECT_TIME_CONDITION_STATE_WHEN } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class OffsetSettings extends React.Component {
  componentDidMount() {
    const {
      saveConditionDataNumber,
    } = this.props
    saveConditionDataNumber(DEFAULT_VALUE)
  }

  handleSliderChange = (evt, value) => {
    const {
      saveConditionDataNumber,
    } = this.props
    saveConditionDataNumber(value)
  };

   handleInputChange = (event) => {
     const {
       saveConditionDataNumber,
     } = this.props
     if (event.target.value > MAX) {
       saveConditionDataNumber(MAX)
     } else if (event.target.value < MIN) {
       saveConditionDataNumber(MIN)
     } else {
       saveConditionDataNumber(event.target.value === '' ? '' : Number(event.target.value))
     }
   };

  handleBlur = () => {
    const { dataNumber, saveConditionDataNumber } = this.props
    if (dataNumber < MIN) {
      saveConditionDataNumber(MIN)
    } else if (dataNumber > MAX) {
      saveConditionDataNumber(MAX)
    }
  };

  onNextClick=() => {
    const {
      isEditConditionMode,
      navigate,
      dataNumber,
      saveConditionDataNumber,
    } = this.props
    if (dataNumber === '') {
      saveConditionDataNumber(DEFAULT_VALUE)
    }
    if (isEditConditionMode) {
      navigate(EDIT_CONDITION)
    } else {
      navigate(CREATE_CONDITION_SELECTED)
    }
  }

  onBackClick=() => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_TIME_CONDITION_STATE_WHEN)
  }

  onResetClick=() => {
    const { saveConditionDataNumber } = this.props
    saveConditionDataNumber(DEFAULT_VALUE)
  }

  render() {
    const {
      classes,
      isMobile,
      dataNumber,
      stateDescription,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_condition_web.offset_settings')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classNames(classes.offsetHandlerWrapper, isMobile && classes.offsetHandlerWrapperMobile)}>
            <Wrapper className={classes.subTitleWrapper}>
              <Button
                onClick={this.onResetClick}
                color="secondary"
                className={classes.resetButton}
              >
                {I18n.t('buttons.reset')}
              </Button>
              <Typography className={classes.subTitle}>
                {I18n.t(`scene_condition_web.${stateDescription === 'sunrise' ? 'when_sunrise' : 'when_sunset'}`)}
                :&nbsp;
              </Typography>
              <Typography className={classes.subTitleValue}>{getOffsetString(dataNumber)}</Typography>
            </Wrapper>
            <Slider
              className={classNames(classes.slider, isMobile && classes.sliderMobile)}
              value={typeof dataNumber === 'number' ? dataNumber : DEFAULT_VALUE}
              min={MIN}
              max={MAX}
              aria-labelledby="input-slider"
              valueLabelDisplay={isMobile ? 'off' : 'on'}
              marks={marks}
              onChange={this.handleSliderChange}
            />
            <Wrapper className={classes.infoWrapper}>
              <Typography className={classes.infoText}>
                {I18n.t('scene_condition_web.value_mins')}
                :&nbsp;&nbsp;
              </Typography>
              <Tooltip title="from -255 to 255">
                <Input
                  className={classes.infoInput}
                  value={dataNumber}
                  size="small"
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    step: STEP,
                    min: MIN,
                    max: MAX,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </Tooltip>
            </Wrapper>
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.nextButton}
            onClick={this.onNextClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

OffsetSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionDataNumber: PropTypes.func.isRequired,
  isEditConditionMode: PropTypes.bool.isRequired,
  stateDescription: PropTypes.string.isRequired,
  dataNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

OffsetSettings.defaultProps = {

  dataNumber: '',
}

OffsetSettings.displayName = 'OffsetSettings'

export default OffsetSettings
