const styles = () => ({
  title: {
    fontSize: 16,
    paddingRight: 150,
    marginTop: 20,
  },
  progress: {
    marginTop: 40,
    marginBottom: 60,
  },

})

export default styles
