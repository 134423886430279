import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="18" y="10" width="115" height="129" fill="currentColor">
        <rect fill="white" x="18" y="10" width="115" height="129"/>
        <path d="M20.8065 53.3553C20.5099 53.661 20.3501 54.0744 20.3655 54.5C20.3974 55.5002 21.3404 78.7942 36.3428 87.0176L34.0848 121.064H33.6968C30.6774 121.064 28.2204 123.521 28.2204 126.541V132.404C28.2204 135.424 30.6774 137.88 33.6968 137.88H110.266C113.285 137.88 115.742 135.424 115.742 132.404V126.541C115.742 124 113.995 121.878 111.645 121.262L110.453 107.746L127.425 91.977C129.76 89.8043 131.1 86.7296 131.1 83.5412V58.1448C131.1 53.8838 127.633 50.4173 123.374 50.4173H105.063C101.518 35.4292 88.1179 24.4339 72.1525 24.4339C55.3177 24.4339 41.3431 36.6623 38.7629 52.8759H21.9374C21.5118 52.8759 21.103 53.0495 20.8065 53.3553ZM112.595 126.541V132.404C112.595 133.689 111.55 134.734 110.266 134.734H33.6968C32.4122 134.734 31.3673 133.689 31.3673 132.404V126.541C31.3673 125.256 32.4122 124.211 33.6968 124.211H110.266C111.55 124.211 112.595 125.256 112.595 126.541ZM106.344 61.1657H120.622V82.4456C120.622 82.6684 120.528 82.8835 120.364 83.0357L109.189 93.4207L106.344 61.1657ZM123.374 53.5642C125.899 53.5642 127.953 55.6186 127.953 58.1448V83.5412C127.953 85.8583 126.979 88.0941 125.283 89.6721L110.102 103.777L109.539 97.3907L122.504 85.3421C123.308 84.5968 123.769 83.5412 123.769 82.4456V60.9828C123.769 59.3479 122.44 58.0188 120.805 58.0188H106.066L105.956 56.768C105.907 55.6846 105.802 54.6174 105.656 53.5642H123.374ZM72.1525 27.5808C83.6956 27.5808 93.7462 33.9273 98.9872 43.406H45.3071C50.543 33.9381 60.5998 27.5808 72.1525 27.5808ZM43.8063 46.5529H100.488C101.819 49.7813 102.652 53.2793 102.816 56.9754L108.468 121.064H37.2393L41.4933 56.9063C41.6566 53.238 42.4837 49.7631 43.8063 46.5529ZM38.4175 56.0228C38.4018 56.2614 38.3618 56.4927 38.3511 56.7327L36.5775 83.4789C26.3796 76.7618 24.1009 61.1105 23.6276 56.0228H38.4175Z"/>
        <path d="M72.9625 113.686C78.7446 113.686 83.4495 108.981 83.4495 103.199V64.4216C83.4495 58.6395 78.7446 53.9361 72.9625 53.9361C67.1803 53.9361 62.4769 58.6395 62.4769 64.4216V103.199C62.4769 108.981 67.1803 113.686 72.9625 113.686ZM65.6238 64.4216C65.6238 60.3759 68.9167 57.083 72.9625 57.083C77.0098 57.083 80.3027 60.3759 80.3027 64.4216V103.199C80.3027 107.246 77.0098 110.539 72.9625 110.539C68.9167 110.539 65.6238 107.246 65.6238 103.199V64.4216Z"/>
        <path d="M70.9065 67.2244H75.0199C75.8896 67.2244 76.5934 66.5206 76.5934 65.6509C76.5934 64.7812 75.8896 64.0775 75.0199 64.0775H70.9065C70.0368 64.0775 69.3331 64.7812 69.3331 65.6509C69.3331 66.5206 70.0368 67.2244 70.9065 67.2244Z"/>
        <path d="M70.9065 74.5876H75.0199C75.8896 74.5876 76.5934 73.8839 76.5934 73.0142C76.5934 72.1445 75.8896 71.4407 75.0199 71.4407H70.9065C70.0368 71.4407 69.3331 72.1445 69.3331 73.0142C69.3331 73.8839 70.0368 74.5876 70.9065 74.5876Z"/>
        <path d="M70.9065 81.9509H75.0199C75.8896 81.9509 76.5934 81.2471 76.5934 80.3774C76.5934 79.5077 75.8896 78.804 75.0199 78.804H70.9065C70.0368 78.804 69.3331 79.5077 69.3331 80.3774C69.3331 81.2471 70.0368 81.9509 70.9065 81.9509Z"/>
        <path d="M70.9065 89.3141H75.0199C75.8896 89.3141 76.5934 88.6104 76.5934 87.7407C76.5934 86.871 75.8896 86.1672 75.0199 86.1672H70.9065C70.0368 86.1672 69.3331 86.871 69.3331 87.7407C69.3331 88.6104 70.0368 89.3141 70.9065 89.3141Z"/>
        <path d="M70.9065 96.6835H75.0199C75.8896 96.6835 76.5934 95.9797 76.5934 95.11C76.5934 94.2403 75.8896 93.5366 75.0199 93.5366H70.9065C70.0368 93.5366 69.3331 94.2403 69.3331 95.11C69.3331 95.9797 70.0368 96.6835 70.9065 96.6835Z"/>
        <path d="M70.9065 104.047H75.0199C75.8896 104.047 76.5934 103.343 76.5934 102.473C76.5934 101.604 75.8896 100.9 75.0199 100.9H70.9065C70.0368 100.9 69.3331 101.604 69.3331 102.473C69.3331 103.343 70.0368 104.047 70.9065 104.047Z"/>
        <path d="M38.1162 29.2992C37.4924 29.7678 36.7241 29.9614 35.9512 29.8523C35.0907 29.7325 34.2963 30.3271 34.1703 31.1876C34.0474 32.0481 34.6451 32.844 35.5056 32.9685C35.7975 33.01 36.0879 33.03 36.3783 33.03C37.6798 33.03 38.9413 32.612 40.0031 31.8176C41.3 30.8465 42.1405 29.4282 42.371 27.8256C42.6015 26.2214 42.1927 24.6234 41.2216 23.325C40.2505 22.0281 38.8307 21.1876 37.2265 20.9571C35.6346 20.7297 34.5237 19.2469 34.7527 17.6535C34.9816 16.0601 36.4506 14.9491 38.0578 15.1781C38.9214 15.3041 39.7142 14.7033 39.8387 13.8428C39.9616 12.9823 39.3639 12.1864 38.5034 12.0619C35.1967 11.5871 32.1128 13.895 31.6365 17.2064C31.1632 20.5177 33.4696 23.5985 36.7809 24.0733C37.5523 24.1839 38.2361 24.588 38.7032 25.2119C39.1703 25.8357 39.367 26.6056 39.2548 27.3784C39.1442 28.1498 38.7401 28.832 38.1162 29.2992Z"/>
        <path d="M23.3176 15.7309C23.9414 15.2622 24.7081 15.0686 25.4826 15.1777C26.3477 15.3037 27.139 14.7029 27.2635 13.8424C27.3864 12.9819 26.7887 12.186 25.9282 12.0615C24.3255 11.8341 22.729 12.2413 21.4306 13.2124C20.1338 14.1835 19.2933 15.6018 19.0628 17.2044C18.8323 18.8086 19.241 20.4066 20.2121 21.7051C21.1833 23.0019 22.603 23.8424 24.2072 24.0729C25.7991 24.3003 26.9101 25.7831 26.6811 27.3765C26.4522 28.97 24.974 30.0809 23.3759 29.8519C22.5155 29.7321 21.7195 30.3268 21.5951 31.1872C21.4721 32.0477 22.0699 32.8437 22.9303 32.9681C23.2207 33.0096 23.5081 33.0296 23.7939 33.0296C26.7595 33.0296 29.3624 30.8446 29.7973 27.8237C30.2705 24.5124 27.9641 21.4315 24.6528 20.9567C23.8815 20.8461 23.1977 20.442 22.7306 19.8181C22.2635 19.1943 22.0668 18.4245 22.179 17.6516C22.2896 16.8802 22.6937 16.198 23.3176 15.7309Z"/>
      </mask>
      <path d="M20.8065 53.3553C20.5099 53.661 20.3501 54.0744 20.3655 54.5C20.3974 55.5002 21.3404 78.7942 36.3428 87.0176L34.0848 121.064H33.6968C30.6774 121.064 28.2204 123.521 28.2204 126.541V132.404C28.2204 135.424 30.6774 137.88 33.6968 137.88H110.266C113.285 137.88 115.742 135.424 115.742 132.404V126.541C115.742 124 113.995 121.878 111.645 121.262L110.453 107.746L127.425 91.977C129.76 89.8043 131.1 86.7296 131.1 83.5412V58.1448C131.1 53.8838 127.633 50.4173 123.374 50.4173H105.063C101.518 35.4292 88.1179 24.4339 72.1525 24.4339C55.3177 24.4339 41.3431 36.6623 38.7629 52.8759H21.9374C21.5118 52.8759 21.103 53.0495 20.8065 53.3553ZM112.595 126.541V132.404C112.595 133.689 111.55 134.734 110.266 134.734H33.6968C32.4122 134.734 31.3673 133.689 31.3673 132.404V126.541C31.3673 125.256 32.4122 124.211 33.6968 124.211H110.266C111.55 124.211 112.595 125.256 112.595 126.541ZM106.344 61.1657H120.622V82.4456C120.622 82.6684 120.528 82.8835 120.364 83.0357L109.189 93.4207L106.344 61.1657ZM123.374 53.5642C125.899 53.5642 127.953 55.6186 127.953 58.1448V83.5412C127.953 85.8583 126.979 88.0941 125.283 89.6721L110.102 103.777L109.539 97.3907L122.504 85.3421C123.308 84.5968 123.769 83.5412 123.769 82.4456V60.9828C123.769 59.3479 122.44 58.0188 120.805 58.0188H106.066L105.956 56.768C105.907 55.6846 105.802 54.6174 105.656 53.5642H123.374ZM72.1525 27.5808C83.6956 27.5808 93.7462 33.9273 98.9872 43.406H45.3071C50.543 33.9381 60.5998 27.5808 72.1525 27.5808ZM43.8063 46.5529H100.488C101.819 49.7813 102.652 53.2793 102.816 56.9754L108.468 121.064H37.2393L41.4933 56.9063C41.6566 53.238 42.4837 49.7631 43.8063 46.5529ZM38.4175 56.0228C38.4018 56.2614 38.3618 56.4927 38.3511 56.7327L36.5775 83.4789C26.3796 76.7618 24.1009 61.1105 23.6276 56.0228H38.4175Z" fill="currentColor"/>
      <path d="M72.9625 113.686C78.7446 113.686 83.4495 108.981 83.4495 103.199V64.4216C83.4495 58.6395 78.7446 53.9361 72.9625 53.9361C67.1803 53.9361 62.4769 58.6395 62.4769 64.4216V103.199C62.4769 108.981 67.1803 113.686 72.9625 113.686ZM65.6238 64.4216C65.6238 60.3759 68.9167 57.083 72.9625 57.083C77.0098 57.083 80.3027 60.3759 80.3027 64.4216V103.199C80.3027 107.246 77.0098 110.539 72.9625 110.539C68.9167 110.539 65.6238 107.246 65.6238 103.199V64.4216Z" fill="currentColor"/>
      <path d="M70.9065 67.2244H75.0199C75.8896 67.2244 76.5934 66.5206 76.5934 65.6509C76.5934 64.7812 75.8896 64.0775 75.0199 64.0775H70.9065C70.0368 64.0775 69.3331 64.7812 69.3331 65.6509C69.3331 66.5206 70.0368 67.2244 70.9065 67.2244Z" fill="currentColor"/>
      <path d="M70.9065 74.5876H75.0199C75.8896 74.5876 76.5934 73.8839 76.5934 73.0142C76.5934 72.1445 75.8896 71.4407 75.0199 71.4407H70.9065C70.0368 71.4407 69.3331 72.1445 69.3331 73.0142C69.3331 73.8839 70.0368 74.5876 70.9065 74.5876Z" fill="currentColor"/>
      <path d="M70.9065 81.9509H75.0199C75.8896 81.9509 76.5934 81.2471 76.5934 80.3774C76.5934 79.5077 75.8896 78.804 75.0199 78.804H70.9065C70.0368 78.804 69.3331 79.5077 69.3331 80.3774C69.3331 81.2471 70.0368 81.9509 70.9065 81.9509Z" fill="currentColor"/>
      <path d="M70.9065 89.3141H75.0199C75.8896 89.3141 76.5934 88.6104 76.5934 87.7407C76.5934 86.871 75.8896 86.1672 75.0199 86.1672H70.9065C70.0368 86.1672 69.3331 86.871 69.3331 87.7407C69.3331 88.6104 70.0368 89.3141 70.9065 89.3141Z" fill="currentColor"/>
      <path d="M70.9065 96.6835H75.0199C75.8896 96.6835 76.5934 95.9797 76.5934 95.11C76.5934 94.2403 75.8896 93.5366 75.0199 93.5366H70.9065C70.0368 93.5366 69.3331 94.2403 69.3331 95.11C69.3331 95.9797 70.0368 96.6835 70.9065 96.6835Z" fill="currentColor"/>
      <path d="M70.9065 104.047H75.0199C75.8896 104.047 76.5934 103.343 76.5934 102.473C76.5934 101.604 75.8896 100.9 75.0199 100.9H70.9065C70.0368 100.9 69.3331 101.604 69.3331 102.473C69.3331 103.343 70.0368 104.047 70.9065 104.047Z" fill="currentColor"/>
      <path d="M38.1162 29.2992C37.4924 29.7678 36.7241 29.9614 35.9512 29.8523C35.0907 29.7325 34.2963 30.3271 34.1703 31.1876C34.0474 32.0481 34.6451 32.844 35.5056 32.9685C35.7975 33.01 36.0879 33.03 36.3783 33.03C37.6798 33.03 38.9413 32.612 40.0031 31.8176C41.3 30.8465 42.1405 29.4282 42.371 27.8256C42.6015 26.2214 42.1927 24.6234 41.2216 23.325C40.2505 22.0281 38.8307 21.1876 37.2265 20.9571C35.6346 20.7297 34.5237 19.2469 34.7527 17.6535C34.9816 16.0601 36.4506 14.9491 38.0578 15.1781C38.9214 15.3041 39.7142 14.7033 39.8387 13.8428C39.9616 12.9823 39.3639 12.1864 38.5034 12.0619C35.1967 11.5871 32.1128 13.895 31.6365 17.2064C31.1632 20.5177 33.4696 23.5985 36.7809 24.0733C37.5523 24.1839 38.2361 24.588 38.7032 25.2119C39.1703 25.8357 39.367 26.6056 39.2548 27.3784C39.1442 28.1498 38.7401 28.832 38.1162 29.2992Z" fill="currentColor"/>
      <path d="M23.3176 15.7309C23.9414 15.2622 24.7081 15.0686 25.4826 15.1777C26.3477 15.3037 27.139 14.7029 27.2635 13.8424C27.3864 12.9819 26.7887 12.186 25.9282 12.0615C24.3255 11.8341 22.729 12.2413 21.4306 13.2124C20.1338 14.1835 19.2933 15.6018 19.0628 17.2044C18.8323 18.8086 19.241 20.4066 20.2121 21.7051C21.1833 23.0019 22.603 23.8424 24.2072 24.0729C25.7991 24.3003 26.9101 25.7831 26.6811 27.3765C26.4522 28.97 24.974 30.0809 23.3759 29.8519C22.5155 29.7321 21.7195 30.3268 21.5951 31.1872C21.4721 32.0477 22.0699 32.8437 22.9303 32.9681C23.2207 33.0096 23.5081 33.0296 23.7939 33.0296C26.7595 33.0296 29.3624 30.8446 29.7973 27.8237C30.2705 24.5124 27.9641 21.4315 24.6528 20.9567C23.8815 20.8461 23.1977 20.442 22.7306 19.8181C22.2635 19.1943 22.0668 18.4245 22.179 17.6516C22.2896 16.8802 22.6937 16.198 23.3176 15.7309Z" fill="currentColor"/>
      <path d="M20.8065 53.3553C20.5099 53.661 20.3501 54.0744 20.3655 54.5C20.3974 55.5002 21.3404 78.7942 36.3428 87.0176L34.0848 121.064H33.6968C30.6774 121.064 28.2204 123.521 28.2204 126.541V132.404C28.2204 135.424 30.6774 137.88 33.6968 137.88H110.266C113.285 137.88 115.742 135.424 115.742 132.404V126.541C115.742 124 113.995 121.878 111.645 121.262L110.453 107.746L127.425 91.977C129.76 89.8043 131.1 86.7296 131.1 83.5412V58.1448C131.1 53.8838 127.633 50.4173 123.374 50.4173H105.063C101.518 35.4292 88.1179 24.4339 72.1525 24.4339C55.3177 24.4339 41.3431 36.6623 38.7629 52.8759H21.9374C21.5118 52.8759 21.103 53.0495 20.8065 53.3553ZM112.595 126.541V132.404C112.595 133.689 111.55 134.734 110.266 134.734H33.6968C32.4122 134.734 31.3673 133.689 31.3673 132.404V126.541C31.3673 125.256 32.4122 124.211 33.6968 124.211H110.266C111.55 124.211 112.595 125.256 112.595 126.541ZM106.344 61.1657H120.622V82.4456C120.622 82.6684 120.528 82.8835 120.364 83.0357L109.189 93.4207L106.344 61.1657ZM123.374 53.5642C125.899 53.5642 127.953 55.6186 127.953 58.1448V83.5412C127.953 85.8583 126.979 88.0941 125.283 89.6721L110.102 103.777L109.539 97.3907L122.504 85.3421C123.308 84.5968 123.769 83.5412 123.769 82.4456V60.9828C123.769 59.3479 122.44 58.0188 120.805 58.0188H106.066L105.956 56.768C105.907 55.6846 105.802 54.6174 105.656 53.5642H123.374ZM72.1525 27.5808C83.6956 27.5808 93.7462 33.9273 98.9872 43.406H45.3071C50.543 33.9381 60.5998 27.5808 72.1525 27.5808ZM43.8063 46.5529H100.488C101.819 49.7813 102.652 53.2793 102.816 56.9754L108.468 121.064H37.2393L41.4933 56.9063C41.6566 53.238 42.4837 49.7631 43.8063 46.5529ZM38.4175 56.0228C38.4018 56.2614 38.3618 56.4927 38.3511 56.7327L36.5775 83.4789C26.3796 76.7618 24.1009 61.1105 23.6276 56.0228H38.4175Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M72.9625 113.686C78.7446 113.686 83.4495 108.981 83.4495 103.199V64.4216C83.4495 58.6395 78.7446 53.9361 72.9625 53.9361C67.1803 53.9361 62.4769 58.6395 62.4769 64.4216V103.199C62.4769 108.981 67.1803 113.686 72.9625 113.686ZM65.6238 64.4216C65.6238 60.3759 68.9167 57.083 72.9625 57.083C77.0098 57.083 80.3027 60.3759 80.3027 64.4216V103.199C80.3027 107.246 77.0098 110.539 72.9625 110.539C68.9167 110.539 65.6238 107.246 65.6238 103.199V64.4216Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M70.9065 67.2244H75.0199C75.8896 67.2244 76.5934 66.5206 76.5934 65.6509C76.5934 64.7812 75.8896 64.0775 75.0199 64.0775H70.9065C70.0368 64.0775 69.3331 64.7812 69.3331 65.6509C69.3331 66.5206 70.0368 67.2244 70.9065 67.2244Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M70.9065 74.5876H75.0199C75.8896 74.5876 76.5934 73.8839 76.5934 73.0142C76.5934 72.1445 75.8896 71.4407 75.0199 71.4407H70.9065C70.0368 71.4407 69.3331 72.1445 69.3331 73.0142C69.3331 73.8839 70.0368 74.5876 70.9065 74.5876Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M70.9065 81.9509H75.0199C75.8896 81.9509 76.5934 81.2471 76.5934 80.3774C76.5934 79.5077 75.8896 78.804 75.0199 78.804H70.9065C70.0368 78.804 69.3331 79.5077 69.3331 80.3774C69.3331 81.2471 70.0368 81.9509 70.9065 81.9509Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M70.9065 89.3141H75.0199C75.8896 89.3141 76.5934 88.6104 76.5934 87.7407C76.5934 86.871 75.8896 86.1672 75.0199 86.1672H70.9065C70.0368 86.1672 69.3331 86.871 69.3331 87.7407C69.3331 88.6104 70.0368 89.3141 70.9065 89.3141Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M70.9065 96.6835H75.0199C75.8896 96.6835 76.5934 95.9797 76.5934 95.11C76.5934 94.2403 75.8896 93.5366 75.0199 93.5366H70.9065C70.0368 93.5366 69.3331 94.2403 69.3331 95.11C69.3331 95.9797 70.0368 96.6835 70.9065 96.6835Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M70.9065 104.047H75.0199C75.8896 104.047 76.5934 103.343 76.5934 102.473C76.5934 101.604 75.8896 100.9 75.0199 100.9H70.9065C70.0368 100.9 69.3331 101.604 69.3331 102.473C69.3331 103.343 70.0368 104.047 70.9065 104.047Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M38.1162 29.2992C37.4924 29.7678 36.7241 29.9614 35.9512 29.8523C35.0907 29.7325 34.2963 30.3271 34.1703 31.1876C34.0474 32.0481 34.6451 32.844 35.5056 32.9685C35.7975 33.01 36.0879 33.03 36.3783 33.03C37.6798 33.03 38.9413 32.612 40.0031 31.8176C41.3 30.8465 42.1405 29.4282 42.371 27.8256C42.6015 26.2214 42.1927 24.6234 41.2216 23.325C40.2505 22.0281 38.8307 21.1876 37.2265 20.9571C35.6346 20.7297 34.5237 19.2469 34.7527 17.6535C34.9816 16.0601 36.4506 14.9491 38.0578 15.1781C38.9214 15.3041 39.7142 14.7033 39.8387 13.8428C39.9616 12.9823 39.3639 12.1864 38.5034 12.0619C35.1967 11.5871 32.1128 13.895 31.6365 17.2064C31.1632 20.5177 33.4696 23.5985 36.7809 24.0733C37.5523 24.1839 38.2361 24.588 38.7032 25.2119C39.1703 25.8357 39.367 26.6056 39.2548 27.3784C39.1442 28.1498 38.7401 28.832 38.1162 29.2992Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
      <path d="M23.3176 15.7309C23.9414 15.2622 24.7081 15.0686 25.4826 15.1777C26.3477 15.3037 27.139 14.7029 27.2635 13.8424C27.3864 12.9819 26.7887 12.186 25.9282 12.0615C24.3255 11.8341 22.729 12.2413 21.4306 13.2124C20.1338 14.1835 19.2933 15.6018 19.0628 17.2044C18.8323 18.8086 19.241 20.4066 20.2121 21.7051C21.1833 23.0019 22.603 23.8424 24.2072 24.0729C25.7991 24.3003 26.9101 25.7831 26.6811 27.3765C26.4522 28.97 24.974 30.0809 23.3759 29.8519C22.5155 29.7321 21.7195 30.3268 21.5951 31.1872C21.4721 32.0477 22.0699 32.8437 22.9303 32.9681C23.2207 33.0096 23.5081 33.0296 23.7939 33.0296C26.7595 33.0296 29.3624 30.8446 29.7973 27.8237C30.2705 24.5124 27.9641 21.4315 24.6528 20.9567C23.8815 20.8461 23.1977 20.442 22.7306 19.8181C22.2635 19.1943 22.0668 18.4245 22.179 17.6516C22.2896 16.8802 22.6937 16.198 23.3176 15.7309Z" stroke="currentColor" strokeWidth="2" mask="url(#path-1-outside-1)"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BoilingKettle')

export default icon
