export const FETCH_PILLS_REMINDERS = '@@pillsReminders/fetchPillsReminders'
export const SET_PILLS_REMINDERS = '@@pillsReminders/setPillsReminders'

export const CREATE_PILLS_REMINDER = '@@pillsReminders/createPillsReminder'

export const DELETE_PILLS_REMINDER = '@@pillsReminders/deletePillsReminder'

export const PATCH_PILLS_REMINDER = '@@pillsReminders/patchPillsReminder'

export const FETCH_PILLS_EVENTS = '@@pillsRemindersEvents/fetchPillsRemindersEvents'
export const SET_PILLS_EVENTS = '@@pillsRemindersEvents/setPillsRemindersEvents'
