import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem 2rem 70px 2rem',
    minHeight: 710,
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem .5rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  navigationTitle: {
    marginTop: 7,
    marginLeft: 75,
  },
  goBackIcon: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tabsWrapper: {
    width: '100%',
    marginTop: 20,
  },
  tab: {
    width: '50%',
  },
  saveButton: {
    marginTop: 80,
    width: '90%',
  },
  saveButtonMobile: {
    marginTop: 40,
  },
  preloader: {
    display: 'flex',
    position: 'fixed',
    width: '100vw',
    height: 'calc(100vh - 153px - 2rem)',
    top: 'calc(153px + 2rem)',
    left: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  preloaderMessage: {
    fontSize: '1.5rem',
  },
  blur: {
    filter: 'blur(5px)',
    pointerEvents: 'none',
  },
}))

export default useStyles
