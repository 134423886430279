import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  rgbColors, setColors, whiteCold, whiteWarm,
} from '@app/lib/Scenes/colorActionTypes'
import { ColorTypes } from '@app/lib/Scenes/colorActions'
import {
  SELECT_ACTION_ROOM_STATE_TYPE,
  SELECT_ROOM_ACTION_STATE_SELECTED,
  SELECT_ROOM_COLOR_ACTION_HUE,
  SELECT_ROOM_COLOR_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectRoomActionColorType extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      stateDescription,
      isChangeActionMode,
      isChangeActionStateMode,
      actionType,
    } = this.props
    if (stateDescription !== 'none') {
      if (actionType === 'ControlRoom') {
        navigate(SELECT_ROOM_ACTION_STATE_SELECTED)
      } else {
        navigate(SELECT_ROOM_COLOR_ACTION_STATE_SELECTED)
      }
    } else if (stateDescription !== 'none' && !isChangeActionMode && !isChangeActionStateMode) {
      if (actionType === 'ControlRoom') {
        navigate(SELECT_ROOM_ACTION_STATE_SELECTED)
      } else {
        navigate(SELECT_ROOM_COLOR_ACTION_STATE_SELECTED)
      }
    } else {
      navigate(SELECT_ACTION_ROOM_STATE_TYPE)
    }
  }

  onTypeClick=(type) => {
    const {
      saveActionColorType,
      navigate,
    } = this.props
    saveActionColorType(type)
    navigate(SELECT_ROOM_COLOR_ACTION_HUE)
  }

  render() {
    const {
      classes,
      isMobile,
      stateDescription,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={stateDescription === 'none' ? this.onBackClick : null}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_colour_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <CardBlock
            key={I18n.t(`scene_web.${setColors.title}`)}
            description={I18n.t(`scene_web.${setColors.description}`)}
            position={setColors.position}
            buttonName={I18n.t(`scene_web.${setColors.buttonName}`)}
            isCardDisabled={setColors.isCardDisabled}
            isButtonDisabled={setColors.isButtonDisabled}
            onClick={() => {
              this.onTypeClick(ColorTypes.swatch)
            }}
          />
          <CardBlock
            key={I18n.t(`scene_web.${rgbColors.description}`)}
            description={I18n.t(`scene_web.${rgbColors.description}`)}
            position={rgbColors.position}
            buttonName={I18n.t(`scene_web.${rgbColors.buttonName}`)}
            isCardDisabled={rgbColors.isCardDisabled}
            isButtonDisabled={rgbColors.isButtonDisabled}
            onClick={() => {
              this.onTypeClick(ColorTypes.rgb)
            }}
          />
          <CardBlock
            key={I18n.t(`scene_web.${whiteWarm.description}`)}
            description={I18n.t(`scene_web.${whiteWarm.description}`)}
            position={whiteWarm.position}
            buttonName={I18n.t(`scene_web.${whiteWarm.buttonName}`)}
            isCardDisabled={whiteWarm.isCardDisabled}
            isButtonDisabled={whiteWarm.isButtonDisabled}
            onClick={
              () => {
                this.onTypeClick(ColorTypes.whiteWarm)
              }
            }
          />
          <CardBlock
            key={I18n.t(`scene_web.${whiteCold.description}`)}
            description={I18n.t(`scene_web.${whiteCold.description}`)}
            position={whiteCold.position}
            buttonName={I18n.t(`scene_web.${whiteCold.buttonName}`)}
            isCardDisabled={whiteCold.isCardDisabled}
            isButtonDisabled={whiteCold.isButtonDisabled}
            onClick={
              () => {
                this.onTypeClick(ColorTypes.whiteCold)
              }
            }
          />
        </Wrapper>
      </Paper>
    )
  }
}

SelectRoomActionColorType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionColorType: PropTypes.func.isRequired,
  stateDescription: PropTypes.string.isRequired,
  isChangeActionMode: PropTypes.bool.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  actionType: PropTypes.string.isRequired,
}

SelectRoomActionColorType.displayName = 'SelectActionColorTypeIOS'

export default SelectRoomActionColorType
