import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { sync } from '@app/actions/notifications/notificationsSettings'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import NotificationsSettings from '@app/components/Notifications/NotificationsSettings/NotificationsSettings'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = NotificationsSettings

const mapStateToProps = (state: AppState) => {
  const settingsValues = state.notifications.notificationsSettings.push_setting
  const { isMobile } = state.mobileDetector
  const { isNCentral } = state.unit
  return {
    isMobile,
    settingsValues,
    isNCentral,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const NotificationsSettingsEnhanced = enhance

export default NotificationsSettingsEnhanced
