import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_UNIT_LOCATION } from '@app/constants/unit'
import { updateUnit } from '@app/actions/unit'
import Api from '@app/api/unit/patchUnitInfo'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const patchUnitLocationLogic = createLogic({
  type: PATCH_UNIT_LOCATION,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { unit: { latitude, longitude } } = getState()
    const data = { time_location: { longitude, latitude } }
    next({
      ...action,
      token,
      data,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_UNIT_LOCATION))
    Api.patchUnitInfo(action.token, action.data)
      .then((response) => {
        const { unit_device } = response.data
        dispatch(updateUnit(unit_device))
        dispatch(apiRequestDone(PATCH_UNIT_LOCATION))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('patchUnitInfo failed without error')
        }
      })
      .then(() => done())
  },
})

export default patchUnitLocationLogic
