import { createLogic } from 'redux-logic'
import openCable from '../../api/cable'
import {
  CLOSE_SCENES_LIST_CHANNEL,
} from '../../constants/scenes/scenesListChannel'
import { SCENES_CHANNEL } from '../../constants/channels'

const unsubscribeScenesListLogic = createLogic({
  type: CLOSE_SCENES_LIST_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(SCENES_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeScenesListLogic
