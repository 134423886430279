export const sceneConditionSelected = {
  title: 'condition',
  position: 'bottom',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const sceneStateSelected = {
  title: 'state',
  description: 'none',
  buttonName: null,
  isCardDisabled: false,
  isButtonDisabled: true,
}

export const binarySensorSelected = {
  title: 'sensor',
  position: 'bottom',
  buttonName: 'choose',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const binarySensorValueSelected = {
  title: 'value',
  position: 'bottom',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const multilevelSensorSelected = {
  title: 'sensor',
  position: 'bottom',
  buttonName: 'choose',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const multilevelSensorValueSelected = {
  title: 'value',
  position: 'bottom',
  buttonName: 'adjust',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const centralSceneStateSelected = {
  title: 'state',
  position: 'bottom',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const centralSceneValueSelected = {
  title: 'value',
  position: 'bottom',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const multichannelBinarySwitchStateSelected = {
  title: 'state',
  position: 'bottom',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}

export const multichannelBinarySwitchValueSelected = {
  title: 'value',
  position: 'bottom',
  buttonName: 'change',
  isCardDisabled: false,
  isButtonDisabled: false,
}
