import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
  },
  rootMobile: {
    height: 'calc(100vh - 60px)',
    minHeight: 500,
    padding: '1rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  navigationControlsMobile: {
    marginBottom: '1rem',
  },
  arrowBack: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '2px 4%',
    marginBottom: 26,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
  },
  optionCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '12px 4%',
    marginBottom: 26,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  cardLeftSideWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 24,
  },
  titleMobile: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 14,
  },
  cardTitle: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  chevronIcon: {
    transition: '0.2s ease-in',
    transform: 'rotate(90deg)',
  },
  chevronIconOpened: {
    transform: 'rotate(270deg)',
  },
  optionList: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 360,
    padding: '16px 0',
  },
  optionListMobile: {
    backgroundColor: theme.backgrounds.cardBackground,
    width: 300,
    padding: '8px 0',
  },
  optionItem: {
    backgroundColor: theme.backgrounds.cardBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    transition: '0.2s ease-in',
    padding: '8px 20px',
  },
  optionCardTitle: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 10,
  },
  optionCardDescription: {
    color: '#929292',
    fontSize: '16px',
    lineHeight: '19px',
  },
  optionTitle: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  optionDescription: {
    color: '#929292',
    fontSize: '16px',
    lineHeight: '19px',
  },
  saveButton: {
    width: '100%',
    height: 44,
    marginTop: 140,
  },
  disabled: {
    opacity: 0.5,
    color: '#fff',
  },
  disabledCursor: {
    cursor: 'not-allowed',
  },
}))

export default useStyles
