import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    width: '100%',
    paddingBottom: 50,
    overflowX: 'auto',
  },
  item: {
    minHeight: 70,
    borderBottom: '1px solid rgba(50, 50, 50, 1)',
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: '1rem',
    paddingLeft: 15,
    color: theme.typography.color,
  },
  icon: {
    fontSize: '2rem',
  },
  webView: {
    background: theme.webView.background,
  },
  preloader: {
    display: 'flex',
    position: 'fixed',
    width: '100vw',
    height: 'calc(100vh - 65px)',
    top: 65,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1199,
  },
  preloaderMessage: {
    fontSize: '1.5rem',
  },
  blur: {
    filter: 'blur(5px)',
  },
}))

export default useStyles
