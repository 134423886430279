import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { actionTypes } from '@app/lib/Scenes/actionTypes'
// @ts-ignore
import { actionTypesIcons, actionTypesIconsMobile } from '@app/lib/Scenes/actionTypesIcons'
import {
  SELECT_ACTION_DEVICE_TYPE,
  SCENE_REVIEW,
  SCENE_REVIEW_EDIT,
  SCENES,
  EDIT_ACTION,
  SETTINGS_TYPE,
  SELECT_VIRTUAL_SWITCH_ACTION,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'
import InfoScreen
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/SelectConditionType/InfoScreen/InfoScreen'
import { InfoIcon } from 'icons'
import { IActionFromBackend } from '@app/@types/scenes/scenes'
import { useDispatch } from '@app/store'
import {
  closeSceneSaveDraftPopup,
  openSceneSaveDraftPopup,
  restoreActionInfo,
  saveActionType,
  activateCreateActionMode,
  clearCurrentConditionInfo,
  clearCurrentActionInfo,
} from '@app/actions/scenes/currentSceneCreating'
import { deleteScene } from '@app/actions/scenes/scenesList'
import useStyles from './SelectActionType.style'

interface Props {
  isMobile: boolean,
  isAdditionalActionMode: boolean,
  sceneMode: string,
  isSaveSceneDraftPopupOpen: boolean,
  isEditActionMode: boolean,
  sceneActionTypes: Array<string>,
  actions: Array<IActionFromBackend>,
  initialAction: IActionFromBackend,
}

const SelectActionType: React.FC<Props> = (props) => {
  const {
    isMobile,
    isAdditionalActionMode,
    sceneMode,
    isSaveSceneDraftPopupOpen,
    isEditActionMode,
    sceneActionTypes,
    actions,
    initialAction,
  } = props

  const [isBackButtonPopupOpen, changeBackButtonPopupOpen] = useState<boolean>(false)
  const [infoScreenShow, changeInfoScreenShow] = useState<boolean>(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isEditActionMode) {
      dispatch(activateCreateActionMode())
      dispatch(clearCurrentConditionInfo())
      dispatch(clearCurrentActionInfo())
    }
  }, [])

  const onSingleActionBackClick = () => {
    if (isEditActionMode) {
      dispatch(restoreActionInfo())
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    } else if (sceneMode === 'create') {
      if (isAdditionalActionMode) {
        navigate(SCENE_REVIEW)
      } else {
        dispatch(openSceneSaveDraftPopup())
      }
    } else if (sceneMode === 'editDraftScene' || sceneMode === 'editMezzoScene') {
      navigate(SCENE_REVIEW_EDIT)
    }
  }

  const onChainedActionBackClick = () => {
    changeBackButtonPopupOpen(true)
  }

  const onBackClick = () => {
    if (infoScreenShow) {
      return changeInfoScreenShow(false)
    }
    if ((!isEditActionMode && !isAdditionalActionMode) && sceneActionTypes.length) {
      return onChainedActionBackClick()
    }
    return onSingleActionBackClick()
  }

  const toSceneReviewAccepted = () => {
    navigate(SCENE_REVIEW)
  }

  const toSceneReviewDeclined = () => {
    changeBackButtonPopupOpen(false)
  }

  const onUseClick = (title: string) => {
    switch (title) {
    case 'devices':
      return navigate(SELECT_ACTION_DEVICE_TYPE)
    case 'notification':
      dispatch(saveActionType('PushNotifications'))
      return navigate(SETTINGS_TYPE)
    case 'virtual_switch':
      dispatch(saveActionType('VirtualSwitch'))
      return navigate(SELECT_VIRTUAL_SWITCH_ACTION)
    default:
      throw new Error('action unsupported')
    }
  }

  const saveDraftAccepted = () => {
    navigate(SCENES)
    dispatch(closeSceneSaveDraftPopup())
  }

  const saveDraftDeclined = () => {
    dispatch(deleteScene())
    dispatch(closeSceneSaveDraftPopup())
  }

  const onCloseIconClick = () => {
    dispatch(closeSceneSaveDraftPopup())
  }

  const infoBackClick = () => {
    changeInfoScreenShow(!infoScreenShow)
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <InfoIcon
          className={classes.infoIcon}
          onClick={() => {
            changeInfoScreenShow(!infoScreenShow)
          }}
        />
        <Typography className={classes.title}>{I18n.t('scene_web.select_action_type')}</Typography>
        <Typography className={classes.subTitle}>{I18n.t('scene_web.scenes_define_action')}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        {
          actionTypes.map((type, index) => (
            <CardBlock
              key={type.title}
              withIcon={type.withIcon}
              icon={isMobile ? actionTypesIconsMobile[index] : actionTypesIcons[index]}
              title={I18n.t(`scene_web.${type.title}`)}
              description={I18n.t(`scene_web.${type.description}`)}
              position={type.position}
              isCardDisabled={type.isCardDisabled}
              isButtonDisabled={type.isButtonDisabled}
              onClick={() => {
                onUseClick(type.title)
              }}
              isMobile={isMobile}
            />
          ))
        }
      </Wrapper>
      <WarningComponent
        open={isSaveSceneDraftPopupOpen}
        onClose={() => {
          saveDraftDeclined()
        }}
        withCloseIcon
        onCloseIconClick={onCloseIconClick}
        onClick={saveDraftAccepted}
        warningText="scene_web.save_draft"
        leftButton="yeah"
        rightButton="nope"
      />
      <WarningComponent
        open={isBackButtonPopupOpen}
        title={I18n.t('titles.info')}
        onClose={toSceneReviewDeclined}
        onClick={toSceneReviewAccepted}
        warningText="scene_web.info_edit_scene"
        leftButton="yeah"
        rightButton="cancel"
      />
      {
        infoScreenShow && (
          <InfoScreen
            isMobile={isMobile}
            onBackClick={infoBackClick}
          />
        )
      }
    </Paper>
  )
}

export default SelectActionType
