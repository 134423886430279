import React, {
  useState, Dispatch, SetStateAction,
} from 'react'
import classNames from 'classnames'
import {
  Paper, Typography, TextField, Button, IconButton,
} from '@mui/material'
import { Close } from 'icons'
import { Optional } from '@app/@types/common'
import { IConfigParameterValue } from '@app/@types/myDevices'
import useStyles from './EditParamValuePopup.style'

const defaultProps = {
  values: null,
  setValues: () => {},
  setTemporaryValues: () => {},
  isMulti: false,
}

interface Props {
  editableParameter: Optional<IConfigParameterValue>,
  values?: Array<IConfigParameterValue>,
  setValues?: Dispatch<SetStateAction<Array<IConfigParameterValue>>>,
  setTemporaryValues?: Dispatch<SetStateAction<Optional<Array<IConfigParameterValue>>>>,
  setIsEditingValue: Dispatch<SetStateAction<boolean>>,
  isMulti?: boolean,
  isMobile: boolean,
}

const EditParamValuePopup: React.FC<Props> = (props) => {
  const {
    editableParameter,
    setIsEditingValue,
    isMobile,
    values = defaultProps.values,
    setValues = defaultProps.setValues,
    setTemporaryValues = defaultProps.setTemporaryValues,
    isMulti = defaultProps.isMulti,
  } = props
  const classes = useStyles()
  const [value, setValue] = useState<string>('')

  const onValueChange = (currentValue: string) => {
    setValue(currentValue)
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === '') {
      onValueChange(event.target.value)
    } else {
      event.preventDefault()
    }
  }

  const closePopup = () => {
    setIsEditingValue(false)
  }

  const onOkCLick = () => {
    if (values && editableParameter) {
      setValues(values.map((param) => (
        param.description === editableParameter.description
          ? { ...param, value: +value }
          : param)))
      setTemporaryValues(values.map((param) => (
        param.description === editableParameter.description
          ? { ...param, value: +value }
          : param)))
    }
    setIsEditingValue(false)
  }

  return (
    <Paper className={classes.popupWrapper}>
      {editableParameter && (
        <div
          className={classNames(classes.popup, isMobile && classes.popupMobile)}
        >
          <Typography className={classes.popupTitle}>Change value</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeIconButton}
            onClick={closePopup}
            size="large"
          >
            <Close />
          </IconButton>
          <TextField
            className={classes.field}
            label="Value"
            value={value}
            onChange={handleValueChange}
          />
          <Typography className={classes.rangeValues}>
            {`from ${isMulti ? editableParameter.ui_from : editableParameter.from} to ${isMulti ? editableParameter.ui_to : editableParameter.to}`}
          </Typography>
          <Button
            className={classes.getButton}
            variant="outlined"
            color="primary"
            disabled={editableParameter
          && !(isMulti
            ? +value >= editableParameter.ui_from && +value <= editableParameter.ui_to
            : +value >= editableParameter.from && +value <= editableParameter.to)}
            onClick={onOkCLick}
          >
            {I18n.t('buttons.ok')}
          </Button>
        </div>
      )}
    </Paper>
  )
}

export default EditParamValuePopup
