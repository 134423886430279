const styles = (theme) => ({
  snackbar: {
    maxWidth: '50%',
    marginLeft: 'auto',
  },
  message: {
    wordBreak: 'break-word',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  closeAllButton: {
    color: theme.palette.primary.contrastText,
    marginRight: 5,
  },
})
export default styles
