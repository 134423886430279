import React from 'react'
import {
  Button, Paper, Slider, TextField, Typography,
} from '@mui/material'
import { Navigate } from 'react-router-dom'
import {
  Autocomplete, Circle, GoogleMap, LoadScript, Marker,
} from '@react-google-maps/api'
import Geocode from 'react-geocode'
import classNames from 'classnames'
import calculateBounds from '@app/helpers/scenes/calculateBoundsForCircle'
import validateName from '@app/helpers/myDevices/validateName'
import { GEOFENCES } from '@app/constants/routes'
import {
  apiKey, circleSizeBounds, libraries, NEW_GEOFENCE_NAME_PATTERN, options,
} from '@app/lib/Scenes/geofences'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import { withStyles } from '@app/components/styles'

class CreateGeofenceScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    const { location: { state } } = props
    const linkState = state
    this.state = {
      isChoosingAddress: false,
      newGeofenceName: linkState ? linkState.name : '',
      address: '',
      coordinates: {
        lat: linkState ? linkState.latitude : -33.8219067,
        lng: linkState ? linkState.longitude : 150.9541388,
      },
      size: linkState ? linkState.size : 100,
      id: linkState ? linkState.id : null,
      zoom: calculateBounds(linkState ? linkState.size : 100),
      dragged: false,
      isEditing: !!linkState,
    }
    this.map = null
    this.autocomplete = null
  }

  componentDidMount() {
    const { fetchedGeofences } = this.props
    const { isEditing, coordinates: { lat, lng } } = this.state
    if (navigator.geolocation && fetchedGeofences) {
      navigator.geolocation.getCurrentPosition((pos) => {
        Geocode.setApiKey(apiKey)
        if (isEditing) {
          Geocode.fromLatLng(lat, lng).then((response) => {
            this.setState({
              address: response.results[0].formatted_address,
            })
          })
        } else {
          Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then((response) => {
            this.setState({
              coordinates: {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
              },
              address: response.results[0].formatted_address,
            })
          })
        }
      })
    }
  }

  onLoadAutoComplete = (autocomplete) => {
    this.autocomplete = autocomplete
  }

  onLoadMap = (map) => {
    this.map = map
  }

  onBackClickFromAddress = () => {
    this.setState({
      isChoosingAddress: false,
    })
  }

  onBackClickFromLocation = () => {
    const { navigate } = this.props
    navigate(GEOFENCES)
  }

  onPlaceChanged = () => {
    const { coordinates, dragged } = this.state
    Geocode.setApiKey(apiKey)
    let autoLat; let autoLng; let
      finalAddress
    if (this.autocomplete !== null && this.autocomplete.getPlace()) {
      autoLat = this.autocomplete.getPlace().geometry.location.lat()
      autoLng = this.autocomplete.getPlace().geometry.location.lng()
    }
    Geocode.fromLatLng((!dragged && autoLat) || coordinates.lat, (!dragged && autoLng) || coordinates.lng).then((response) => {
      finalAddress = response.results[0].formatted_address
      this.setState({
        coordinates: this.autocomplete.getPlace() && !dragged
          ? {
            lat: autoLat,
            lng: autoLng,
          }
          : {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
          },
        address: finalAddress,
      })
    })
  }

  onSizeChanged = (_, size) => {
    this.setState({ size })

    const newZoom = calculateBounds(size)
    this.setState({ zoom: newZoom })
  }

  onChangeCenter = () => {
    if (this.map !== null) {
      const { coordinates, dragged } = this.state
      Geocode.setApiKey(apiKey)
      let autoLat
      let autoLng
      Geocode.fromLatLng((!dragged && autoLat) || coordinates.lat, (!dragged && autoLng) || coordinates.lng).then((response) => {
        this.setState({
          coordinates: {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
          },
          address: response.results[0].formatted_address,
          dragged: true,
        })
      })
    }
  }

  onSubmitGeofence = () => {
    const { navigate, postGeofence, patchGeofence } = this.props
    const {
      newGeofenceName,
      coordinates: { lat, lng },
      size,
      id,
      isEditing,
    } = this.state
    const data = {
      name: newGeofenceName,
      latitude: lat,
      longitude: lng,
      size,
    }
    if (isEditing) {
      patchGeofence(data, id)
    } else {
      postGeofence(data)
    }
    navigate(GEOFENCES)
  }

  render() {
    const { classes, isMobile, fetchedGeofences } = this.props
    const {
      newGeofenceName,
      size,
      isChoosingAddress,
      coordinates,
      address,
      zoom,
      isEditing,
    } = this.state
    return fetchedGeofences ? (
      <Paper className={classes.root}>
        <NavBar
          title={isEditing ? I18n.t('geofence_web.edit_geofence') : I18n.t('geofence_web.create_geofence')}
          withButton={false}
          isMobile={isMobile}
          backClick={isChoosingAddress ? this.onBackClickFromAddress : this.onBackClickFromLocation}
          titleCentered
          customTitle
        />
        <Wrapper className={classes.main}>
          <Wrapper className={classes.mapContainer}>
            {isChoosingAddress && (
              <TextField
                variant="standard"
                label={I18n.t('geofence_web.name_geofence')}
                className={classes.nameInput}
                inputProps={{ pattern: NEW_GEOFENCE_NAME_PATTERN, maxLength: 16, className: classes.innerInput }}
                onChange={(e) => this.setState({
                  newGeofenceName: e.target.value.trimLeft(),
                })}
                value={newGeofenceName}
              />
            )}
            <LoadScript
              id="script-loader"
              googleMapsApiKey={apiKey}
              libraries={libraries}
              onUnmount={() => [...document.querySelectorAll("script[src*='maps.googleapis.com']")].map((script) => (
                script.parentNode.removeChild(script)))}
            >
              <GoogleMap
                id="searchbox"
                mapContainerStyle={{
                  height: isMobile ? '400px' : '580px',
                  width: '100%',
                }}
                zoom={zoom}
                center={coordinates}
                onLoad={this.onLoadMap}
                onDragEnd={!isChoosingAddress && this.onChangeCenter}
                options={{ streetViewControl: false, disableDefaultUI: true }}
              >
                {!isChoosingAddress && (
                  <Autocomplete
                    onLoad={this.onLoadAutoComplete}
                    onPlaceChanged={this.onPlaceChanged}
                  >
                    <input
                      type="text"
                      placeholder={address}
                      className={classNames(classes.autocompleteInput, isMobile && classes.autocompleteInputMobile)}
                      onChange={() => this.setState({ dragged: false })}
                    />
                  </Autocomplete>
                )}
                <Circle
                  radius={size}
                  center={coordinates}
                  options={options}
                />
                <Marker position={coordinates} />
                {!isChoosingAddress && (
                  <Wrapper className={classNames(classes.sliderContainer, isMobile && classes.sliderContainerMobile)}>
                    <Typography className={classes.sliderValue}>{`${size} m`}</Typography>
                    <Slider
                      classes={{
                        root: classes.slider,
                        thumb: classes.thumb,
                      }}
                      aria-label="size"
                      defaultValue={size}
                      min={circleSizeBounds.min}
                      max={circleSizeBounds.max}
                      onChange={this.onSizeChanged}
                    />
                  </Wrapper>
                )}
              </GoogleMap>
            </LoadScript>
          </Wrapper>
          {isChoosingAddress && <Typography className={classes.address}>{address}</Typography>}
          {!isChoosingAddress && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.onPlaceChanged()
                this.setState({ isChoosingAddress: true })
              }}
            >
              {I18n.t('buttons.continue')}
            </Button>
          )}
          {isChoosingAddress && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              disabled={!validateName(newGeofenceName) || newGeofenceName.length < 2}
              onClick={this.onSubmitGeofence}
            >
              {isEditing ? I18n.t('geofence_web.update_geofence') : I18n.t('geofence_web.add_geofence')}
            </Button>
          )}
        </Wrapper>
      </Paper>
    ) : <Navigate to={GEOFENCES} />
  }
}

CreateGeofenceScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  fetchedGeofences: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  postGeofence: PropTypes.func.isRequired,
  patchGeofence: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      name: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      size: PropTypes.number,
      id: PropTypes.number,
    }),
  }),
}

CreateGeofenceScreen.defaultProps = {
  location: {
    state: {
      name: '',
      latitude: null,
      longitude: null,
      size: null,
      id: null,
    },
  },
}

CreateGeofenceScreen.displayName = 'CreateGeofenceScreen'

export default withStyles(CreateGeofenceScreen)
