import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58.7334 20.4336H34.6286C33.3977 20.4336 32.2175 20.8868 31.349 21.6929C30.4804 22.4991 29.995 23.5918 30 24.7295V122.039C30 124.402 32.0723 126.318 34.6286 126.318H58.7334" stroke="currentColor" strokeWidth="5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M115.819 16.8779L71.9046 8.16284C68.5427 7.4938 65.7617 8.94632 65.7617 11.42V137.983C65.7617 140.403 68.5141 141.609 71.8379 140.694L115.4 128.739C117.71 128.113 119.371 126.245 119.59 124.03L120 21.3323C119.845 19.1486 118.136 17.3275 115.819 16.8779V16.8779ZM71.6095 77.38C70.3524 77.38 69.3429 75.6194 69.3429 73.3658C69.3429 71.1122 70.3619 69.3516 71.6095 69.3516C72.8571 69.3516 73.8762 71.1122 73.8762 73.3658C73.8762 75.6194 72.8571 77.38 71.6095 77.38V77.38Z" fill="currentColor"/>
        </svg>

    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'DoorSensorIcon')

export default icon
