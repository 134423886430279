import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_STOP_UPLOADING } from '@app/constants/cloudFirmwareUpdate/cloudFirmwareInfo'
import { update } from '@app/actions/cloudFirmwareUpdate/cloudFirmwareUpdateInfo'
import Api from '@app/api/cloudFirmwareUpdate/stopUploading'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const stopUploadingLogic = createLogic({
  type: SYNC_STOP_UPLOADING,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_STOP_UPLOADING))
    Api.stopUploading(token)
      .then((response) => {
        const { data } = response
        dispatch(update(data))
        dispatch(apiRequestDone(SYNC_STOP_UPLOADING))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('stopUploading failed without error')
        }
      })
      .then(() => done())
  },
})

export default stopUploadingLogic
