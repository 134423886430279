import React from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Paper, Typography } from '@mui/material'
import { SELECT_CONDITION_DEVICE_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import TwrTabs from './SelectTWRDevice/TwrTabs'
import ZWaveTabs from './SelectZWaveDevice/ZWaveTabs'
import useStyles from './SelectConditionDeviceTabs.style'

interface Props {
  isMobile: boolean,
  deviceType: string,
  currentTab: number,
}

const SelectConditionDeviceTabs: React.FC<Props> = (props) => {
  const {
    isMobile,
    deviceType,
    currentTab,
  } = props

  const classes = useStyles()
  const navigate = useNavigate()

  const onBackClick = () => {
    navigate(SELECT_CONDITION_DEVICE_TYPE)
  }

  const selectTab = () => {
    switch (deviceType) {
    case 'TwrDevices':
      return (<TwrTabs currentTab={currentTab} />)
    case 'ZWaveDevices':
      return (<ZWaveTabs currentTab={currentTab} />)
    default:
      return (<TwrTabs currentTab={currentTab} />)
    }
  }

  const getTitle = () => {
    switch (deviceType) {
    case 'TwrDevices':
      return ('Select TWR device grouped...')
    case 'ZWaveDevices':
      return ('Select Z-Wave device grouped...')

    default:
      return ('Select TWR device grouped...')
    }
  }

  return (
    <Paper className={classNames(
      classes.wrapper,
      (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
      && classes.webView,
    )}
    >
      <NavBar
        title="create_scene"
        withButton={false}
        isMobile={isMobile}
        backClick={() => {
          onBackClick()
        }}
      />
      <Wrapper className={classes.titleWrapper}>
        <Typography className={classes.title}>{getTitle()}</Typography>
      </Wrapper>
      <Wrapper className={classes.main}>
        {selectTab()}
      </Wrapper>
    </Paper>
  )
}

export default SelectConditionDeviceTabs
