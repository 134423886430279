import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3308 4.00631C12.3308 4.70014 11.7683 5.26261 11.0745 5.26261C10.3806 5.26261 9.81818 4.70014 9.81818 4.00631C9.81818 3.31247 10.3806 2.75 11.0745 2.75C11.7683 2.75 12.3308 3.31247 12.3308 4.00631Z" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M13.126 14.1532C13.0705 14.1159 13.0082 14.0901 12.9427 14.0772C12.8771 14.0644 12.8097 14.0647 12.7443 14.0783C12.6788 14.0918 12.6168 14.1183 12.5618 14.1561C12.5067 14.194 12.4598 14.2425 12.4237 14.2987C11.9389 15.0168 11.3449 15.6548 10.6632 16.1896C10.4977 16.315 9.88075 16.7915 9.61993 16.6912C9.43937 16.636 9.5447 16.2799 9.57981 16.1294L9.84564 15.342C9.95599 15.021 11.877 9.32305 12.0877 8.671C12.3987 7.71801 12.2632 6.78006 10.8438 7.00577C10.4576 7.04589 6.54025 7.55249 6.47003 7.5575C6.40416 7.56178 6.33978 7.579 6.28057 7.60816C6.22135 7.63732 6.16846 7.67786 6.12491 7.72746C6.08137 7.77707 6.04801 7.83476 6.02676 7.89725C6.00551 7.95975 5.99678 8.02581 6.00106 8.09168C6.00534 8.15755 6.02255 8.22193 6.05172 8.28114C6.08088 8.34036 6.12142 8.39325 6.17102 8.4368C6.22062 8.48035 6.27832 8.5137 6.34081 8.53495C6.4033 8.5562 6.46937 8.56494 6.53524 8.56065C6.53524 8.56065 8.03997 8.36504 8.20549 8.34999C8.29027 8.34172 8.37563 8.35815 8.45129 8.39731C8.52694 8.43647 8.58964 8.49668 8.63183 8.57069C8.72271 8.85163 8.71026 9.1558 8.59672 9.42838C8.46631 9.92996 6.40483 15.7382 6.33962 16.0743C6.26969 16.3555 6.2891 16.6515 6.39518 16.9212C6.50125 17.1909 6.6887 17.4208 6.93148 17.579C7.38717 17.8827 7.92974 18.0289 8.47634 17.9953C9.00779 17.9888 9.53339 17.8834 10.0262 17.6843C11.2751 17.1828 12.5792 15.8486 13.2664 14.8003C13.3256 14.6938 13.3439 14.5694 13.3181 14.4503C13.2923 14.3312 13.224 14.2256 13.126 14.1532Z" fill="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'HelpIcon')

export default icon
