import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = (id) => `${API_URL}/api/scenes/${id}/force_activate`

const triggerSceneActions = (accessToken, id) => Axios.post(ENDPOINT(id), null, {
  headers: { 'Access-Token': accessToken },
})

export default {
  triggerSceneActions,
}
