import React from 'react'
import { Typography } from '@mui/material'
import getContrastColor from '@app/helpers/scenes/getContrastColor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IActionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './ColorDescription.style'

interface Props {
  action: IActionFromBackend,
}
const ColorDescription: React.FC<Props> = (props) => {
  const {
    action,
  } = props
  const classes = useStyles()

  return (
    <Wrapper>
      <Typography
        className={classes.sceneInfoText}
      >
        {action.description.split('ௐ')[0]}
        <span
          className={classes.colorBlock}
          // @ts-ignore
          style={{ backgroundColor: action.value_title, borderColor: getContrastColor(action.value_title) }}
        />
      </Typography>

    </Wrapper>
  )
}

export default ColorDescription
