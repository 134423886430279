import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '96%',
  },
  button: {
    color: '#f44336',
    borderColor: '#f44336',
  },
  closeButton: {
    color: '#f44336',
  },
}))

export default useStyles
