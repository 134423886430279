const styles = (theme) => ({
  root: {
    paddingBottom: 60,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    width: '100%',
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
