import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_948_247)">
        <path d="M11.6648 20.25L6.22125 15L4.40674 16.75L11.6648 23.75L27.2178 8.75L25.4033 7L11.6648 20.25Z" fill="#87A63E"/>
      </g>
      <defs>
        <clipPath id="clip0_948_247">
          <rect width="31.106" height="30" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SealedSecurityIcon')

export default icon
