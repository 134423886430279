import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6811 109.337H20.7407V104.16H127.793V109.337H133.853V104.16H141V71.3228C141 67.0332 138.048 63.3353 133.853 62.152V50.6145C133.853 45.2896 129.347 41 123.754 41H24.7804C19.1869 41 14.6811 45.2896 14.6811 50.6145V62.152C10.7967 63.4832 8 67.0332 8 71.3228V104.16H14.6811V109.337V109.337ZM20.5853 50.6145C20.5853 48.3958 22.4498 46.6208 24.7804 46.6208H123.598C125.929 46.6208 127.793 48.3958 127.793 50.6145V62.0041C123.598 63.1874 120.646 66.8853 120.646 71.3228V77.0915H28.1986V71.3228C28.1986 66.8853 24.9357 63.0395 20.5853 62.0041V50.6145ZM120.491 82.8602V84.7831H28.1986V82.8602H120.491ZM13.9042 98.5393V71.3228C13.9042 69.104 15.7687 67.329 18.0993 67.329C20.4299 67.329 22.2944 69.104 22.2944 71.3228V77.0915V90.5518H126.55V77.0915V71.3228C126.55 69.104 128.415 67.329 130.745 67.329C133.076 67.329 134.94 69.104 134.94 71.3228V98.5393H13.9042Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Sofa')

export default icon
