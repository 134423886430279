const calculateActionType = (stateDescription, actionDelay, actionDuration) => {
  if (stateDescription === 'toggle') {
    if (Object.keys(actionDuration).length) {
      return 'ToggleZwaveDeviceWithDuration'
    }
    return 'Toggle'
  }
  if (Object.keys(actionDelay).length) {
    if (Object.keys(actionDuration).length) {
      return 'DelayedPlusDurationDeviceAction'
    }
    return 'DelayedDeviceAction'
  }
  return 'SetDeviceStatus'
}

export default calculateActionType
