import * as type from '@app/constants/activities/activitiesChannel'

export const update = (data) => ({
  type: type.UPDATE_ACTIVITIES_CHANNEL,
  data,
})

export const sync = () => ({
  type: type.SYNC_ACTIVITIES_CHANNEL,
})

export const syncSent = () => ({
  type: type.SYNC_SENT_ACTIVITIES_CHANNEL,
})

export const syncCancel = () => ({
  type: type.SYNC_CANCEL_ACTIVITIES_CHANNEL,
})

export const openChannel = () => ({
  type: type.OPEN_ACTIVITIES_CHANNEL,
})

export const closeChannel = () => ({
  type: type.CLOSE_ACTIVITIES_CHANNEL,
})

export const clearActivitiesChannel = () => ({
  type: type.CLEAR_ACTIVITIES_CHANNEL,
})
