import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from '@app/store'
import getActionStateDescription from '@app/helpers/scenes/getActionStateDescription'
import { checkIfColorActionRGBOrSwatchType } from '@app/helpers/scenes/checkIfColorActionRGBOrSwatchType'
import getRedirectForActionStateChangeButton from '@app/helpers/scenes/getRedirectForActionStateChangeButton'
import { sceneStateSelected } from '@app/lib/Scenes/actions'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import {
  activateChangeActionStateMode,
} from '@app/actions/scenes/currentSceneCreating'

interface Props {
  stateDescription: string,
  actionType: string,
  colorType: string,
  delayDescription: string,
  durationDescription: string,
  durationValueAfter: string,
  colorTypeDurationValueAfter: string,
  subDeviceName: string,
}

const StateBlockCommon: React.FC<Props> = (props) => {
  const {
    stateDescription,
    actionType,
    colorType,
    delayDescription,
    durationDescription,
    durationValueAfter,
    colorTypeDurationValueAfter,
    subDeviceName,
  } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const changeActionState = () => {
    dispatch(activateChangeActionStateMode())
    const redirectUrl = getRedirectForActionStateChangeButton(actionType)
    navigate(redirectUrl)
  }

  const resultDescription = getActionStateDescription(actionType,
    stateDescription, colorType, delayDescription, durationDescription, durationValueAfter, colorTypeDurationValueAfter, subDeviceName)
  if (actionType === 'SetZwaveColor' || actionType === 'RoomColorControl' || actionType === 'GroupColorControl') {
    return (
      <CardBlock
        key={sceneStateSelected.title}
        title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
        hasAdditionalDesc={checkIfColorActionRGBOrSwatchType(colorType)}
        descColor={stateDescription}
        description={resultDescription}
        position={sceneStateSelected.position}
        buttonName={I18n.t('scene_web.change')}
        isCardDisabled={sceneStateSelected.isCardDisabled}
        isButtonDisabled={sceneStateSelected.isButtonDisabled}
        onClick={() => {
          changeActionState()
        }}
      />
    )
  }
  if (actionType === 'ZwaveColorDelayDuration'
      || actionType === 'ZwaveColorSetWithDuration'
      || actionType === 'RoomColorDelayDuration'
      || actionType === 'RoomColorWithDuration'
      || actionType === 'GroupColorDelayDuration'
      || actionType === 'GroupColorWithDuration'
  ) {
    return (
      <CardBlock
        key={sceneStateSelected.title}
        title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
        description={resultDescription[0]}
        hasAdditionalDesc={checkIfColorActionRGBOrSwatchType(colorType)}
        descColor={checkIfColorActionRGBOrSwatchType(colorType) ? stateDescription : ''}
        delayDesc={resultDescription[1]}
        durationDesc={resultDescription[2]}
        hasSecondAdditionalDesc
        secondDescColor={checkIfColorActionRGBOrSwatchType(colorTypeDurationValueAfter) ? durationValueAfter : ''}
        secondDescription={resultDescription[3]}
        position={sceneStateSelected.position}
        buttonName={I18n.t('scene_web.change')}
        isCardDisabled={sceneStateSelected.isCardDisabled}
        isButtonDisabled={sceneStateSelected.isButtonDisabled}
        onClick={() => {
          changeActionState()
        }}
      />
    )
  }
  if (stateDescription !== 'none') {
    if (actionType === 'MezzoBottomLightColor'
      || actionType === 'MezzoTopLightColor') {
      return (
        <CardBlock
          key={sceneStateSelected.title}
          title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
          description={resultDescription[0]}
          hasAdditionalDesc
          hasSecondAdditionalDesc
          descColor={stateDescription}
          durationDesc={resultDescription[1]}
          secondDescription={resultDescription[3]}
          position={sceneStateSelected.position}
          buttonName={I18n.t('scene_web.change')}
          isCardDisabled={sceneStateSelected.isCardDisabled}
          isButtonDisabled={sceneStateSelected.isButtonDisabled}
          onClick={() => {
            changeActionState()
          }}
        />
      )
    }
  }
  if (actionType === 'LightMezzo') {
    return (
      <CardBlock
        key={sceneStateSelected.title}
        title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
        description={resultDescription[0]}
        hasSecondAdditionalDesc
        descColor={stateDescription}
        durationDesc={resultDescription[1]}
        secondDescription={resultDescription[3]}
        position={sceneStateSelected.position}
        buttonName={I18n.t('scene_web.change')}
        isCardDisabled={sceneStateSelected.isCardDisabled}
        isButtonDisabled={sceneStateSelected.isButtonDisabled}
        onClick={() => {
          changeActionState()
        }}
      />
    )
  }
  return (
    <CardBlock
      key={sceneStateSelected.title}
      title={I18n.t(`scene_web.${sceneStateSelected.title}`)}
      description={resultDescription}
      position={sceneStateSelected.position}
      buttonName={I18n.t('scene_web.change')}
      isCardDisabled={sceneStateSelected.isCardDisabled}
      isButtonDisabled={sceneStateSelected.isButtonDisabled}
      onClick={() => {
        changeActionState()
      }}
    />
  )
}

export default StateBlockCommon
