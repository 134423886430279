import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AppBar, CssBaseline } from '@mui/material'
import { withStyles } from '@mui/styles'
import { HOME } from '@app/constants/routes'
import WarningAddition from '@app/components/Common/WarningAddition'

import { ComponentGrid } from '@app/components'
import loginUrlsBlocking from '@app/helpers/loginUrlsBlocking'
import { refreshUrls } from '@app/lib/Wizard/refreshUrls'
import Router from './AppRouter'
import Drawer from './Drawer'
import Toolbar from './Toolbar'
import Logo from './Toolbar/Logo'
import Snackbar from './Snackbar'

import styles from './styles'

class Layout extends React.Component {
  renderAppbar = () => {
    const { classes, menuOpen } = this.props
    return (
      <AppBar enableColorOnDark className={classNames(classes.appBar, menuOpen && classes.appBarShift)}>
        {this.renderToolbar()}
      </AppBar>
    )
  }

  renderToolbar = () => {
    const {
      menuOpen,
      onMenuOpen,
      logos,
    } = this.props
    return (
      <Toolbar
        menuOpen={menuOpen}
        onMenuOpen={onMenuOpen}
        logo={(
          <Logo
            logos={logos}
          />
        )}
      />
    )
  }

  renderDrawer = () => {
    const {
      desktop,
      brand,
    } = this.props
    return (
      <Drawer
        isGuardPanel={brand === 'Ness'}
        isDesktop={desktop}
        brand={brand}
      />
    )
  }

  renderMain = () => {
    const {
      classes,
      navigation,
      floatButton,
      notificationsWarning,
      currentUrl,
      isWebView,
    } = this.props
    return (
      <main className={
        classNames(loginUrlsBlocking(currentUrl, refreshUrls) ? classes.login : classes.content,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView, currentUrl === HOME ? classes.homeContent : classes.content)
      }
      >
        <ComponentGrid dashboardWidth={currentUrl === HOME}>
          { ((navigation && !isWebView) && !loginUrlsBlocking(currentUrl, refreshUrls)) && <div className={classes.appBarSpacer} /> }
          { !isWebView && <Snackbar /> }
          { notificationsWarning && <WarningAddition status="errors.notifications_channel_warning" /> }
          <Router />
          { floatButton && <div className={classes.fabSpacer} /> }
        </ComponentGrid>
      </main>
    )
  }

  render() {
    const {
      classes, navigation, authProgress, isWebView,
    } = this.props
    return (
      <>
        <CssBaseline />
        <div className={classes.root}>
          {navigation && !authProgress && !isWebView && this.renderAppbar()}
          {navigation && !authProgress && !isWebView && this.renderDrawer()}
          {this.renderMain()}
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  desktop: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  brand: PropTypes.string.isRequired,
  logos: PropTypes.object.isRequired,
  navigation: PropTypes.bool,
  floatButton: PropTypes.bool,
  notificationsWarning: PropTypes.bool,
  currentUrl: PropTypes.string.isRequired,
  authProgress: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
}

Layout.defaultProps = {
  onMenuOpen: null,
  menuOpen: false,
  navigation: true,
  floatButton: false,
  notificationsWarning: false,
}

const enhanced = withStyles(styles)(Layout)

export default enhanced
