import PO from './programOptions'

const armorNumbers = [
  PO.CID_DIALLER_1311, // 1311
  PO.TEST_CALL_1310, // 1310E
  PO.ARMOR_IP_POLL_TIME_1357, // P1357E
  PO.OPEN_CLOSE_1306, // P1306E
  PO.LOW_BATTERY_1307, // P1307E
  PO.INACTIVITY_REPORTING_1351, // P1351E
  PO.CLIENT_ACCOUNT_1220, // 1220
  PO.CSV_REPORTING_USERNAME_1361, // 1361
  PO.CSV_REPORTING_PASSWORD_1362, // E1362
  PO.IP_REPORTING_TCP_UPD_1360, // P1360E
  PO.PRIMARY_ARMOR_DOMAIN_1363, // 1363
  PO.ARMOR_IP_1343, // 1343
  PO.ARMOR_PORT_1344, // 1344
  PO.SECONDARY_ARMOR_DOMAIN_1364, // 1364
  PO.SECONDARY_ARMOR_IP_1353, // 1353
  PO.SECONDARY_ARMOR_PORT_1354, // 1354
]

export default armorNumbers
