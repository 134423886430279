export const AUTH_BY_PASSWORD = '@@wizard/authByPassword'
export const AUTH_STARTED = '@@wizard/authStarted'
export const AUTH_FINISHED = '@@wizard/authFinished'

export const SHOW_SIGN_IN_ERROR = '@@wizard/showSignInError'
export const HIDE_SIGN_IN_ERROR = '@@wizard/hideSignInError'

export const SHOW_ACCOUNT_LOCKED_ERROR = '@@wizard/showAccountLockedError'
export const HIDE_ACCOUNT_LOCKED_ERROR = '@@wizard/hideAccountLockedError'

export const SIGN_UP = '@@wizard/signUp'

export const SHOW_USER_EXISTS_ERROR = '@@wizard/showUserExistsError'
export const HIDE_USER_EXISTS_ERROR = '@@wizard/hideUserExistsError'

export const SHOW_ACTIVATION_INFO_POPUP = '@@wizard/showActivationInfoPopup'
export const CLOSE_ACTIVATION_INFO_POPUP = '@@wizard/closeActivationInfoPopup'

export const SHOW_ACTIVATION_WARNING_POPUP = '@@wizard/showActivationWarningPopup'
export const CLOSE_ACTIVATION_WARNING_POPUP = '@@wizard/closeActivationWarningPopup'

export const SHOW_ACTIVATION_ERROR_POPUP = '@@wizard/showActivationErrorPopup'
export const CLOSE_ACTIVATION_ERROR_POPUP = '@@wizard/closeActivationErrorPopup'

export const SEND_ACCOUNT_VERIFICATION_LINK = '@@wizard/sendAccountVerificationLink'
