import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="150" height="150" viewBox="0 0 45 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.74973 15.6933C5.91171 15.8553 6.13145 15.9462 6.36052 15.9462C6.58965 15.9462 6.80933 15.8552 6.97126 15.6933L18.747 3.91755C18.909 3.75557 19 3.53588 19 3.30682C19 3.07769 18.909 2.85801 18.747 2.69602L16.3039 0.252973C15.9666 -0.0842955 15.4197 -0.0843531 15.0823 0.252973L6.36075 8.97468L3.91759 6.53151C3.7556 6.36953 3.53586 6.27849 3.30679 6.27849C3.07773 6.27849 2.85804 6.36953 2.69606 6.53151L0.252951 8.97462C-0.0843171 9.31195 -0.0843171 9.85882 0.252951 10.1961L5.74973 15.6933Z" fill="#8BC34A"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'humidityIcon')

export default icon