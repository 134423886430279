import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  clearCurrentConditionInfo,
  saveConditionNumber,
  saveConditionType,
  selectConditionDeviceType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectTriggeringDevice
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/MezzoConditions/TimersConditions/SelectTriggeringDevice/SelectTriggeringDevice'
import SelectTriggeringDeviceIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/MezzoConditions/TimersConditions/SelectTriggeringDevice/SelectTriggeringDevice'
import { withAccessDenied, withNotifications, withRouter } from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectTriggeringDeviceIOS) : withStyles(SelectTriggeringDevice)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { deviceState, deviceName: triggeringDeviceName } = state.scenes.currentSceneCreating.currentCondition.triggeringDevice
  const { deviceName: observingDeviceName } = state.scenes.currentSceneCreating.currentCondition.observingDevice
  const { conditionNumber } = state.scenes.currentSceneCreating.currentCondition
  const { isChangeConditionStateMode, isEditConditionMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    deviceState,
    triggeringDeviceName,
    observingDeviceName,
    conditionNumber,
    isChangeConditionStateMode,
    isEditConditionMode,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  selectConditionDeviceType,
  saveConditionType,
  clearCurrentConditionInfo,
  saveConditionNumber,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectTriggeringDeviceEnhanced = enhance

export default withRouter(SelectTriggeringDeviceEnhanced)
