import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
      <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 10.8V3.77778L4.55498 3.65779C5.90968 3.55326 7.23911 3.2337 8.49333 2.71111L10.2 2L11.9066 2.71111C13.1609 3.2337 14.4903 3.55326 15.845 3.65779L17.4 3.77778V10.8C17.4 14.7765 14.1765 18 10.2 18C6.22355 18 3 14.7765 3 10.8Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M7.00002 9.99998L9.00002 12L13.4 7.59998" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'SecurityIcon')

export default icon
