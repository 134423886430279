import React from 'react'
import { Typography } from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IConditionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './Description.style'

interface Props {
  index: number,
  condition: IConditionFromBackend,
}

const Description: React.FC<Props> = (props) => {
  const {
    index,
    condition,
  } = props
  const classes = useStyles()

  return (
    <Wrapper className={classes.descriptionWrapper}>
      <Typography
        className={classes.sceneInfoText}
      >
        {index > 0 && condition.verified && I18n.t('scene_web.if')}
        {' '}
        {condition.description}
      </Typography>
    </Wrapper>
  )
}

export default Description
