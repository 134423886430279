import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '2rem',
    minHeight: 710,
    width: '100%',
    position: 'relative',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    boxSizing: 'border-box',
  },
  rootMobile: {
    padding: '1rem .5rem',
  },
  navigationControls: {
    height: '2.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  goBackIcon: {
    position: 'absolute',
    top: 5,
    left: 10,
  },
  contentContainer: {
    marginTop: '1.25rem',
    padding: '0 1rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
    gridGap: '1.25rem',
    marginBottom: '3rem',
  },
  contentContainerMobile: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  modeCard: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: 64,
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '12px 4% 12px 3.5rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  cardTitle: {
    marginTop: 8,
  },
  radioButton: {
    transform: 'rotate(180deg)',
    position: 'absolute',
    left: '0.3rem',
    top: 'calc(50% - 21px)',
  },
  saveButton: {
    width: '100%',
  },
  progressWrapper: {
    width: '100%',
    padding: '30% 0 40%',
  },
}))

export default useStyles
