import * as type from '@app/constants/myDevices/channels/devices/devicesChannels'

export const openDeleteChannel = () => ({
  type: type.OPEN_DELETE_CHANNEL,
} as const)

export const closeDeleteChannel = () => ({
  type: type.CLOSE_DELETE_CHANNEL,
} as const)

export const syncDeleteChannel = (id: number, deviceType?: string) => ({
  type: type.SYNC_DELETE_CHANNEL,
  id,
  deviceType,
} as const)

export const syncDeleteChannelSent = () => ({
  type: type.SYNC_DELETE_CHANNEL_SENT,
} as const)

export const setResponseFromDeleteChannel = (channelResponse: string) => ({
  type: type.SET_RESPONSE_FROM_DELETE_CHANNEL,
  channelResponse,
} as const)
