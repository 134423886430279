// @ts-nocheck
const updateDevices = <T, A>(state: T, action: A): T => {
  const update = [action.data]
  const filter = (sensorToUpdateArray, sensorToBeUpdatedWithArray) => (
    sensorToUpdateArray.map((device) => ({
      ...device,
      ...sensorToBeUpdatedWithArray.find((updatedObj) => updatedObj.id === device.id && updatedObj.name === device.name),
      type: device.type,
    } || device))
  )
  return {
    ...state,
    devices: filter(state.devices, update),
  }
}

export default updateDevices
