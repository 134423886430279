import aboutNumbers from './aboutNumbers'
import armorNumbers from './armorNumbers'
import securityRulesNumbers from './securityRulesNumbers'
import PO from './programOptions'

const programOptionNumbers = [
  ...aboutNumbers, ...armorNumbers, ...securityRulesNumbers,
  PO.APN_1326,
  PO.GSM_SIGNAL_1231,
  PO.GET_ERROR_CODE_1242,
  PO.LOCK_OUT_1303,
  PO.VOICE_ON_ARM_1304,
  PO.VOICE_ON_ALARM_1305,
  PO.LOW_BATTERY_1307,
  PO.TEST_CALL_1310,
  PO.MAIN_VOLUME_1312,
  PO.DOOR_BELL_1313,
  PO.DOOR_BELL_VOLUME_1314,
  PO.LIGHTS_1315,
  PO.MEZZO_SSID_1316,
  PO.MEZZO_PASSWORD_1317,
  PO.ROUTER_MODE_1320,
  PO.ROUTER_MODE_1321,
  PO.SECURENET_PORT_1322,
  PO.CUSTOMER_1323,
  PO.DISABLE_ALL_VOICE_1325,
  PO.ROUTER_SECURITY_1334,
  PO.CLOCK_MODE_1336,
  PO.CELLULAR_ANTENNA_1339,
  PO.LOW_POWER_OPERATION_1342,
  PO.ROUTER_DEFAULT_SSID_1345,
  PO.ROUTER_DEFAULT_WIFI_PASSWORD_1346,
  PO.CLOUD_CONNECTION_1347,
]

export default programOptionNumbers
