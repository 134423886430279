import React from 'react'
import loginUrlsBlocking from '@app/helpers/loginUrlsBlocking'
import { refreshUrls } from '@app/lib/Wizard/refreshUrls'

interface Props {
  refreshToken: () => void,
  currentUrl: string,
}

class RefreshToken extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props)
    const {
      refreshToken,
      currentUrl,
    } = this.props
    if (!loginUrlsBlocking(currentUrl, refreshUrls)) {
      refreshToken()
    }
  }

  render() {
    return (
      <></>
    )
  }
}

export default RefreshToken
