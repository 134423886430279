import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { checkIfAdmin } from '@app/helpers/checkIfAdmin'
import { withStyles } from '@app/components/styles'
import {
  closeTurnOffPopup,
  openTurnOffPopup,
  patchCurrentEcoModeDevice,
  postEcoModeDevicesTurnOff,
  selectCurrentEcoModeDeviceId,
  selectCurrentEcoModeDeviceStatus,
  sync,
} from '@app/actions/myEnergy/ecoMode'
import EcoMode from '@app/components/MyEnergy/Tabs/EcoMode/EcoMode'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'

let enhance = withStyles(EcoMode)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const ecoModeDevices = state.ecoMode.eco_mode_devices
  const { ecoMode: { isTurnOffPopupOpen } } = store.getState()
  const userRole = state.users.currentUser.user.role
  const admin = checkIfAdmin(userRole)
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  return {
    isMobile,
    ecoModeDevices,
    admin,
    isTurnOffPopupOpen,
    isWebView,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  notifications: syncNotifications.sync,
  selectCurrentEcoModeDeviceId,
  selectCurrentEcoModeDeviceStatus,
  patchCurrentEcoModeDevice,
  postEcoModeDevicesTurnOff,
  openTurnOffPopup,
  closeTurnOffPopup,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const EcoModeEnhanced = enhance

export default EcoModeEnhanced
