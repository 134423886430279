import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>(() => createStyles({
  switch: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: 75,
    height: 30,
    borderRadius: '50px',
    backgroundColor: '#C4C4C4',
    cursor: 'pointer',
  },
  redGradient: {
    display: 'none',
    position: 'absolute',
    left: 0,
    width: '70%',
    height: 30,
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    zIndex: 10,
    opacity: 0,
    overflow: 'hidden',
    '&::after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      width: 75,
      height: 30,
      top: 0,
      left: 0,
      // eslint-disable-next-line max-len
      background: 'linear-gradient(270deg, #EB292E 0%, #EB2B30 19.48%, #E4454A 33.5%, #E25054 39.05%, #DF5A5E 44.48%, #DA6D71 48.18%, #D48688 52.93%, #C5C2C2 59.88%, #C4C4C4 100%)',
      zIndex: 5,
      transform: 'rotate(-180deg)',
    },
  },
  greenGradient: {
    display: 'none',
    position: 'absolute',
    right: 0,
    width: '70%',
    height: 30,
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    zIndex: 10,
    overflow: 'hidden',
    opacity: 0,
    '&::after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      width: 75,
      height: 30,
      top: 0,
      right: 0,
      background: 'linear-gradient(270deg, #7ED321 37.5%, #C4C4C4 52.6%)',
      zIndex: 5,
    },
  },
  toggleWrapper: {
    zIndex: 20,
  },
  toggleButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: '50%',
    backgroundColor: '#43434A',
    zIndex: 20,
  },
  toggleState: {
    fontSize: '10px',
    color: '#fff',
    userSelect: 'none',
  },
  progress: {
    height: 28,
  },
  show: {
    opacity: 1,
    display: 'block',
  },
  hide: {
    opacity: 0,
    display: 'block',
  },
  lockerIcon: {
    zIndex: 25,
  },
  bigSize: {
    scale: 1.75,
  },
  disabled: {
    cursor: 'not-allowed !important',
  },
}))

export default useStyles
