import * as type from '@app/constants/geofences/geofences'

export const syncGeofences = () => ({
  type: type.SYNC_GEOFENCES,
})

export const setGeofences = (data) => ({
  type: type.SET_GEOFENCES,
  data,
})

export const toggleGeofenceSize = (index) => ({
  type: type.TOGGLE_GEOFENCE_SIZE,
  index,
})

export const postGeofence = (data) => ({
  type: type.POST_GEOFENCE,
  data,
})

export const setPostGeofence = (response) => ({
  type: type.SET_POST_GEOFENCE,
  response,
})

export const patchGeofence = (patchedGeofence, id) => ({
  type: type.PATCH_GEOFENCE,
  patchedGeofence,
  id,
})

export const deleteGeofence = (id) => ({
  type: type.DELETE_GEOFENCE,
  id,
})

export const saveGeofenceInfo = (address, lat, lng, size) => ({
  type: type.SAVE_GEOFENCE_INFO,
  address,
  lat,
  lng,
  size,
})
