import update from 'immutability-helper'
import * as PO from '@app/constants/programOptions'
import * as type from '@app/constants/unit'
import { initialValues } from '@app/lib/ProgramOptions'
import merge from '@app/helpers/merge'
import { checkIfNCentral } from '../helpers/checkIfNCentral'

const initialState = {
  initialOptions: initialValues.programOptions,
  currentOptions: initialValues.programOptions,
  defaultOptions: initialValues.programOptions,
  validation: true,
  online: false,
  arm_status: 'armed',
  arm_types: {
    fire: false,
    panic: false,
    duress: false,
    medical: false,
    area_one: false,
    area_two: false,
    perimeter: false,
  },
  ssid: 'My_Mezzo-XXXX',
  name: 'My_Mezzo-name',
  latitude: null,
  longitude: null,
  serialNumber: null,
  token: null,
  selectedUnit: null,
  activation: null,
  activated: false,
  oldHardwareVersion: false,
  firmwareVersion: null,
  isNCentral: false,
  isEditNamePopupOpen: false,
  isUnitListOpen: false,
}

const unit = (state = initialState, action) => {
  switch (action.type) {
  case PO.SET_PROGRAM_INITIAL_OPTIONS: {
    const updatedProgramOptions = merge(state.initialOptions, action.programOptions)
    return {
      ...state,
      initialOptions: updatedProgramOptions,
    }
  }
  case PO.SET_PROGRAM_CURRENT_OPTIONS: {
    return {
      ...state,
      currentOptions: action.programOptions,
    }
  }
  case PO.SET_PROGRAM_OPTIONS_VALIDATION: {
    return {
      ...state,
      validation: action.validation,
    }
  }
  case type.BECOME_ONLINE_UNIT:
    return update(state, {
      online: { $set: true },
    })
  case type.BECOME_OFFLINE_UNIT:
    return update(state, {
      online: { $set: false },
    })
  case type.UPDATE_UNIT:
    return update(state, {
      token: { $set: action.unit.token },
      latitude: { $set: action.unit.latitude },
      longitude: { $set: action.unit.longitude },
      serialNumber: { $set: action.unit.serial_number },
      ssid: { $set: action.unit.ssid || state.ssid },
      name: { $set: action.unit.name || state.name },
      online: { $set: typeof action.unit.online === 'boolean' ? action.unit.online : state.online },
      activation: { $set: action.unit.activation || state.activation },
      activated: { $set: typeof action.unit.activated === 'boolean' ? action.unit.activated : state.activated },
      arm_status: { $set: action.unit.arm_status ? action.unit.arm_status : state.arm_status },
      arm_types: { $set: action.unit.arm_types ? action.unit.arm_types : state.arm_types },
      oldHardwareVersion: { $set: action.unit.old_hardware_version },
      firmwareVersion: { $set: action.unit.firmware_version ? action.unit.firmware_version : state.firmwareVersion },
      isNCentral: { $set: checkIfNCentral(action.unit.serial_number) },
    })

  case type.UPDATE_UNIT_ARM_STATUSES:
    return update(state, {
      arm_status: { $set: action.unit.arm_status ? action.unit.arm_status : state.arm_status },
      arm_types: { $set: action.unit.arm_types ? action.unit.arm_types : state.arm_types },
    })

  case type.SELECT_UNIT:
    return update(state, {
      selectedUnit: { $set: action.selectedUnit },
    })

  case type.UPDATE_UNIT_COORDINATES:
    return update(state, {
      latitude: { $set: action.coordinates.lat },
      longitude: { $set: action.coordinates.lng },
    })

  case type.OPEN_EDIT_UNIT_NAME_POPUP:
    return update(state, {
      isEditNamePopupOpen: { $set: true },
    })

  case type.CLOSE_EDIT_UNIT_NAME_POPUP:
    return update(state, {
      isEditNamePopupOpen: { $set: false },
    })

  case type.OPEN_UNIT_LIST:
    return update(state, {
      isUnitListOpen: { $set: true },
    })

  case type.CLOSE_UNIT_LIST:
    return update(state, {
      isUnitListOpen: { $set: false },
    })

  case type.RESET_UNIT:
    return initialState
  default:
    return state
  }
}

export default unit
