import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '10px 3% 25px 3%',
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
