const styles = (theme) => ({
  screenWrapper: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 60,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '90%',
  },
  button: {
    textDecoration: 'underline',
  },
  list: {
    width: '90%',
    maxWidth: 780,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    backgroundColor: theme.backgrounds.cardBackground,
    marginBottom: 20,
    paddingLeft: 0,
    paddingRight: 8,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  titleWrapper: {
    position: 'relative',
    width: '100%',
  },
  title: {
    padding: '8px 20px',
    borderBottom: '0.5px solid #52525A',
    width: '100%',
    fontSize: '18px',
    lineHeight: '26px',
  },
  titleDesktop: {
    padding: '12px 46px',
  },
  icon: {
    fontSize: '56px',
    marginLeft: 23,
    marginRight: 23,
    margin: 'auto',
  },
  iconDesktop: {
    fontSize: '80px',
    marginLeft: 54,
    marginRight: 54,
  },
  infoWrapper: {
    display: 'flex',
    width: '100%',
    paddingTop: 14,
    paddingBottom: 14,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
  },
  infoMargin: {
    marginBottom: 16,
  },
  infoTime: {
    opacity: 0.5,
    marginBottom: 10,
    fontSize: '16px',
  },
  infoNumbers: {
    fontSize: '16px',
  },
  infoNumbersDesktop: {
    fontSize: '20px',
  },
  infoStat: {
    fontSize: '16px',
  },
  infoStatDesktop: {
    fontSize: '18px',
  },
  buttonStatWrapper: {
    position: 'absolute',
    bottom: 10,
    right: 16,
  },
  buttonStat: {
    textTransform: 'none',
    lineHeight: '16px',
    textDecoration: 'underline',
    padding: 0,
  },
  buttonStatDesktop: {
    lineHeight: '24px',
    fontSize: '16px',
    padding: 6,
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
