import * as type from '@app/constants/scenes/pushNotifications/apiChecking'

const initialState = {
  errorText: '',
  errorStatus: '',
  isErrorPopupOpen: false,
}

export default function apiChecking(state = initialState, action) {
  switch (action.type) {
  case type.CHECK_POST_FAILED:
    return {
      ...state, errorText: action.errorText, errorStatus: action.errorStatus,
    }
  case type.OPEN_ERROR_POPUP:
    return { ...state, isErrorPopupOpen: true }
  case type.CLOSE_ERROR_POPUP:
    return { ...state, isErrorPopupOpen: false }
  default:
    return state
  }
}
