import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.6991 44.5238L33.6991 44.5238L33.6988 44.5276C32.5774 56.3018 36.2232 67.5247 44.0775 76.2206L44.0817 76.2251C50.9118 83.6017 54.4777 93.7269 54.4777 104.425V107.473C54.4777 108.418 54.7949 109.244 55.3862 109.835C55.9775 110.427 56.8037 110.744 57.7486 110.744H91.2757C92.2205 110.744 93.0468 110.427 93.6381 109.835C94.2294 109.244 94.5465 108.418 94.5465 107.473V104.425C94.5465 94.025 98.3762 83.8881 106.06 75.1063L106.06 75.1064L106.066 75.1001C112.234 67.8098 115.882 58.2757 115.882 48.7312C115.882 26.0114 97.3706 7.5 74.6507 7.5H74.6352C72.9872 7.5 71.57 7.49999 69.8735 7.78131C50.7944 9.75301 35.6615 25.461 33.6991 44.5238ZM40.2353 44.9058L40.2355 44.9035C41.8742 29.0627 54.7158 15.9537 70.5459 14.3161L70.5693 14.3137L70.5925 14.309C71.9215 14.0432 73.2575 14.0417 74.6507 14.0417C93.7704 14.0417 109.34 29.6116 109.34 48.7312C109.34 56.9159 106.341 64.5624 101.145 70.8531C92.8141 80.5742 88.3387 92.5113 88.2825 104.202H61.019C60.9697 91.7087 56.7785 80.3104 48.9771 71.6724C42.4158 64.2901 39.1431 55.0081 40.2353 44.9058Z" fill="currentColor" stroke="currentColor"/>
      <path d="M92.384 116.113H56.9173C55.9724 116.113 55.1462 116.43 54.5549 117.022C53.9636 117.613 53.6465 118.439 53.6465 119.384C53.6465 120.329 53.9636 121.155 54.5549 121.747C55.1462 122.338 55.9724 122.655 56.9173 122.655H92.384C93.3289 122.655 94.1551 122.338 94.7464 121.747C95.3377 121.155 95.6548 120.329 95.6548 119.384C95.6548 118.439 95.3377 117.613 94.7464 117.022C94.1551 116.43 93.3289 116.113 92.384 116.113Z" fill="currentColor" stroke="currentColor"/>
      <path d="M92.384 125.543H56.9173C55.9724 125.543 55.1462 125.86 54.5549 126.451C53.9636 127.043 53.6465 127.869 53.6465 128.814C53.6465 129.759 53.9636 130.585 54.5549 131.176C55.1462 131.767 55.9724 132.085 56.9173 132.085H92.384C93.3289 132.085 94.1551 131.767 94.7464 131.176C95.3377 130.585 95.6548 129.759 95.6548 128.814C95.6548 127.869 95.3377 127.043 94.7464 126.451C94.1551 125.86 93.3289 125.543 92.384 125.543Z" fill="currentColor" stroke="currentColor"/>
      <path d="M88.7814 134.957H60.5189C59.574 134.957 58.7478 135.274 58.1565 135.865C57.5652 136.457 57.248 137.283 57.248 138.228C57.248 139.173 57.5652 139.999 58.1565 140.59C58.7478 141.182 59.574 141.499 60.5189 141.499H88.7814C89.7263 141.499 90.5525 141.182 91.1438 140.59C91.7351 139.999 92.0522 139.173 92.0522 138.228C92.0522 136.257 90.4099 134.957 88.7814 134.957Z" fill="currentColor" stroke="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Bulb')

export default icon
