import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  SELECT_CENTRAL_SCENE_STATE_SELECTED,
  SELECT_CENTRAL_SCENE_SUB_DEVICE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import getWallmoteButtonStates from '@app/helpers/scenes/getWallmoteButtonStates'

class SelectCentralSceneSubDeviceValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSubDeviceStateMode,
    } = this.props
    if (isChangeSubDeviceStateMode) {
      navigate(SELECT_CENTRAL_SCENE_STATE_SELECTED)
    } else {
      navigate(SELECT_CENTRAL_SCENE_SUB_DEVICE)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      saveConditionStateDescription,
      saveConditionDataValue,
    } = this.props
    saveConditionStateDescription(state.description)
    saveConditionDataValue(state.value)
    navigate(SELECT_CENTRAL_SCENE_STATE_SELECTED)
  }

  render() {
    const {
      classes,
      isMobile,
      wallmoteSettings,
      dataNumber,
    } = this.props
    const buttonStates = getWallmoteButtonStates(wallmoteSettings, dataNumber)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {buttonStates.map((state) => (
            <CardBlock
              key={state.description}
              description={state.description}
              position="middle"
              buttonName={I18n.t('scene_web.add')}
              isCardDisabled={false}
              isButtonDisabled={false}
              onClick={() => {
                this.selectState(state)
              }}
            />
          ))}
        </Wrapper>
      </Paper>
    )
  }
}

SelectCentralSceneSubDeviceValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionStateDescription: PropTypes.func.isRequired,
  isChangeSubDeviceStateMode: PropTypes.bool.isRequired,
  saveConditionDataValue: PropTypes.func.isRequired,
  wallmoteSettings: PropTypes.arrayOf(PropTypes.shape({
    button_number: PropTypes.number.isRequired,
    press_settings: PropTypes.arrayOf(PropTypes.number.isRequired),
  })).isRequired,
  dataNumber: PropTypes.number.isRequired,
}

SelectCentralSceneSubDeviceValue.displayName = 'SelectCentralSceneSubDeviceValue'

export default SelectCentralSceneSubDeviceValue
