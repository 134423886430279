import React from 'react'
import { Delete, Edit, ErrorOutline } from 'icons'
import { IconButton, Typography } from '@mui/material'
import getShowAndStringValue from '@app/helpers/scenes/getShowAndStringValue'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IActionFromBackend } from '@app/@types/scenes/scenes'
import useStyles from './RenderAction.style'
import ColorDescription from './RenderActionDescriptions/ColorDescription'
import MezzoLightsColorDescription from './RenderActionDescriptions/MezzoLightsColorDescription'
import DoubleColorDescriptionSetColorWithDuration
  from './RenderActionDescriptions/DoubleColorDescriptionSetColorWithDuration'
import Description from './RenderActionDescriptions/Description'

interface Props {
  index: number,
  actions: Array<IActionFromBackend>,
  onEditActionClick: (action: IActionFromBackend) => void,
  onDeleteActionClick: (id: number) => void,
}

const RenderAction: React.FC<Props> = (props) => {
  const {
    index,
    actions,
    onEditActionClick,
    onDeleteActionClick,
  } = props
  const classes = useStyles()
  const action = actions[index]

  const getRightElement = () => {
    if (action.verified) {
      return (
        <Wrapper className={classes.rightElementWrapper}>
          <IconButton
            className={classes.iconButton}
            aria-label="Edit"
            onClick={() => {
              onEditActionClick(action)
            }}
            size="large"
          >
            <Edit />
          </IconButton>
          {actions.length > 1 && (
            <IconButton
              className={classes.iconButton}
              aria-label="Delete"
              onClick={() => {
                onDeleteActionClick(action.id)
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          )}
        </Wrapper>
      )
    }
    return (
      <Wrapper className={classes.deleteWrapper}>
        <ErrorOutline color="error" className={classes.errorOutline} />
        {actions.length > 1 && (
          <IconButton
            className={classes.iconButton}
            aria-label="Delete"
            onClick={() => {
              onDeleteActionClick(action.id)
            }}
            size="large"
          >
            <Delete />
          </IconButton>
        )}
      </Wrapper>
    )
  }

  const renderActionDescription = (type: string) => {
    const actionData = action.data
    if (type === 'SetZwaveColor' || type === 'RoomColorControl' || type === 'GroupColorControl') {
      if (actionData.colours.white_warm || actionData.colours.white_cold) {
        return <Description action={action} />
      }
      return <ColorDescription action={action} />
    }
    if (type === 'ZwaveColorSetWithDuration' || type === 'RoomColorWithDuration' || type === 'GroupColorWithDuration') {
      if ((actionData.colours_before.white_warm || actionData.colours_before.white_cold)
        && (actionData.colours_after.white_warm || actionData.colours_after.white_cold)
      ) {
        return <Description action={action} />
      }
      return <DoubleColorDescriptionSetColorWithDuration action={action} />
    }
    if (type === 'ZwaveColorDelayDuration' || type === 'RoomColorDelayDuration' || type === 'GroupColorDelayDuration') {
      if (!actionData.colours_after) {
        if ((actionData.colours_before.white_warm || actionData.colours_before.white_cold)
        ) {
          return <Description action={action} />
        }
      }
      if ((actionData.colours_before.white_warm || actionData.colours_before.white_cold)
        && (actionData.colours_after.white_warm || actionData.colours_after.white_cold)
      ) {
        return <Description action={action} />
      }
      return <DoubleColorDescriptionSetColorWithDuration action={action} />
    }
    if (type === 'MezzoTopLightColor' || type === 'MezzoBottomLightColor') {
      if (actionData.duration.day
        || actionData.duration.hour
        || actionData.duration.minute
        || actionData.duration.second) {
        return <MezzoLightsColorDescription action={action} />
      }
      if (actionData.colours.red !== 0 || actionData.colours.blue !== 0 || actionData.colours.green !== 0) {
        return <ColorDescription action={action} />
      }
    }
    return <Description action={action} />
  }

  return (
    <Wrapper>
      <Wrapper className={classes.sceneInfo} key={action.id}>
        {renderActionDescription(action.type)}
        {getRightElement()}
      </Wrapper>
      { getShowAndStringValue(actions, index)
        && <Typography color="primary" className={classes.andText}>{I18n.t('scene_web.and_actions')}</Typography>}
    </Wrapper>
  )
}

export default RenderAction
