import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import filterRoomGroupForScreen from '@app/helpers/scenes/filterRoomGroupForScreen'
import { SELECT_CONDITION_DEVICE_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import RoomsList from './RoomsList/RoomsList'

class SelectRoomCondition extends React.Component {
  onBackClick= () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_CONDITION_DEVICE_TYPE)
  }

  render() {
    const {
      classes,
      isMobile,
      roomsScenes,
      saveConditionDescription,
      saveConditionRoomNumber,
      navigate,
    } = this.props
    const filteredRooms = filterRoomGroupForScreen(roomsScenes)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>Select room</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <RoomsList
            navigate={navigate}
            roomsScenes={filteredRooms}
            saveConditionDescription={saveConditionDescription}
            saveConditionRoomNumber={saveConditionRoomNumber}
          />
        </Wrapper>
      </Paper>
    )
  }
}

SelectRoomCondition.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveConditionDescription: PropTypes.func.isRequired,
  saveConditionRoomNumber: PropTypes.func.isRequired,
  roomsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

SelectRoomCondition.defaultProps = {

}

SelectRoomCondition.displayName = 'SelectRoomConditionIOS'

export default SelectRoomCondition
