import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 16">
      <rect width="14.645" height="21.505" x=".903" y="15.398" stroke="#fff" rx="2.5" transform="rotate(-90 .903 15.398)"/>
      <path stroke="currentColor" d="M15.527 12.45a4.208 4.208 0 100-8.415 4.208 4.208 0 000 8.416z" clipRule="evenodd"/>
      <path fill="currentColor" d="M15.254 8.667h.777v3.179h-.777zM12.25 7.456l2.056-2.424.592.503-2.056 2.424zM16.385 5.533l.592-.502 2.057 2.424-.592.502z"/>
      <path fill="currentColor" fillRule="evenodd" d="M8.733 8.465v1.754a.474.474 0 01-.473.475H4.528a.474.474 0 01-.473-.473V8.465M8.427 5.626H4.361c-.26 0-.473.548-.473.807l.167 1.92h4.678l.167-1.92c0-.26-.214-.807-.473-.807zM7 6.324H5.781v-.46h1.22v.46z" clipRule="evenodd"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'VirtualSwitchIcon')

export default icon
