import { createLogic } from 'redux-logic'
import { SYNC_SCENES_LIST_CHANNEL, SYNC_SCENES_LIST_CHANNEL_CANCEL } from '@app/constants/scenes/scenesListChannel'
import { SYNC_SCENES } from '@app/constants/scenes/scenesList'
import {
  update, changeSceneEnabledValueViaId, stopEnablingProgress, startEnablingProgress,
} from '@app/actions/scenes/scenesList'
import { apiRequestDone } from '@app/actions/apiRequest'

import { syncScenesListChannelSent } from '@app/actions/scenes/scenesListChannel'
import Api from '@app/api/scenes/sceneListChannel'

const subscribeScenesListLogic = createLogic({
  type: SYNC_SCENES_LIST_CHANNEL,
  cancelType: SYNC_SCENES_LIST_CHANNEL_CANCEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    Api.scenesListChannel({
      request: () => {
        dispatch(syncScenesListChannelSent())
      },
      response: (data) => {
        const { action_type, payload } = data
        if (action_type === 'activate_status') {
          const { scene_id, enabled, activate_status } = payload
          if (activate_status === 'progress') {
            store.dispatch(startEnablingProgress(scene_id))
          } else {
            store.dispatch(changeSceneEnabledValueViaId(scene_id, enabled))
            store.dispatch(stopEnablingProgress(scene_id))
          }
        } else if (action_type === 'scenes_list') {
          store.dispatch(update(payload))
          store.dispatch(apiRequestDone(SYNC_SCENES))
        } else {
          throw new Error('unknown action type')
        }
      },
    })
  },
})

export default subscribeScenesListLogic
