import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M70.1445 14.0208H73.2235C73.7174 11.3283 74.01 9.23787 74.1619 8H70.1445V14.0208Z" fill="currentColor"/>
      <path d="M138 8H128.574V14.0208H138V8Z" fill="currentColor"/>
      <path d="M119.063 93.2811C119.099 93.0242 119.16 92.7625 119.16 92.512C119.16 90.709 118.142 89.0906 116.62 88.2461C116.393 90.0475 115.779 93.143 114.101 96.8357L116.065 95.4068L115.905 97.4442C116.913 95.5063 117.992 93.9506 119.063 93.2811Z" fill="currentColor"/>
      <path d="M29.453 92.9224C29.445 92.7843 29.4418 92.6462 29.4418 92.5081C29.4418 90.6634 30.1484 88.9374 31.3266 87.6289H29.226H13V92.9224H27.8384H29.453V92.9224Z" fill="currentColor"/>
      <path d="M127.134 98.8016L126.517 99.2881C126.371 99.4021 112.342 110.825 111.666 140.995C114.454 139.206 119.708 136.169 123.826 135.626V126.052H126.224V135.625C130.27 136.158 135.296 139.157 137.998 140.958V95.332H126.248L127.134 98.8016Z" fill="currentColor"/>
      <path d="M119.673 87.6367C120.849 88.9452 121.558 90.6712 121.558 92.5159C121.558 92.654 121.554 92.7921 121.546 92.9302H123.161H137.999V87.6367H121.775H119.673Z" fill="currentColor"/>
      <path d="M13 85.2269H27.3524L27.589 84.2732L28.0718 84.0243C58.6614 68.1986 69.2986 33.4243 72.7597 16.4297H13V85.2269Z" fill="currentColor"/>
      <path d="M34.3801 88.2461C32.8566 89.0906 31.8398 90.709 31.8398 92.512C31.8398 92.7625 31.9006 93.0242 31.9374 93.2811C33.0084 93.9506 34.0875 95.5063 35.0946 97.4426L34.9348 95.4052L36.8995 96.8341C35.2225 93.143 34.6071 90.0491 34.3801 88.2461Z" fill="currentColor"/>
      <path d="M67.7471 8H55.0381V14.0208H67.7471V8Z" fill="currentColor"/>
      <path d="M37.5323 8H24.8232V14.0208H37.5323V8Z" fill="currentColor"/>
      <path d="M52.6397 8H39.9307V14.0208H52.6397V8Z" fill="currentColor"/>
      <path d="M24.4845 99.2943L23.8642 98.811L24.7499 95.3398H13V140.964C15.7017 139.163 20.7277 136.164 24.7738 135.631V126.059H27.1718V135.632C31.2898 136.175 36.5445 139.211 39.3325 141.001C38.6595 110.729 24.63 99.4083 24.4845 99.2943Z" fill="currentColor"/>
      <path d="M22.4255 8H13V14.0208H22.4255V8Z" fill="currentColor"/>
      <path d="M122.926 84.0243L123.409 84.2732L123.645 85.2269H137.998V16.4297H78.2427C81.7101 33.4275 92.3569 68.2082 122.926 84.0243Z" fill="currentColor"/>
      <path d="M126.176 8H113.467V14.0208H126.176V8Z" fill="currentColor"/>
      <path d="M80.8556 8H76.8398C76.9917 9.23787 77.2859 11.3283 77.7798 14.0208H80.8556V8Z" fill="currentColor"/>
      <path d="M111.069 8H98.3599V14.0208H111.069V8Z" fill="currentColor"/>
      <path d="M95.9625 8H83.2534V14.0208H95.9625V8Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Curtains')

export default icon
