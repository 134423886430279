const getTimerType = (type: string): string => {
  switch (type) {
  case 'one_shot':
    return `${I18n.t('timers.one_shot')}`
  case 'repeating':
    return `${I18n.t('timers.repeating')}`
  case 'retriggerable':
    return `${I18n.t('timers.retriggerable')}`
  default:
    return ''
  }
}

export default getTimerType
