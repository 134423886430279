const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    paddingBottom: 30,
    position: 'relative',
  },
  button: {
    marginTop: 29,
    marginRight: 22,
  },
  item: {
    width: '100%',
    padding: 0,
  },
  paper: {
    padding: 20,
    boxShadow: 'none',
  },
  text: {
    fontSize: '1rem',
  },
  webView: {
    background: theme.webView.background,
  },
})

export default styles
