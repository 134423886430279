import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
  <React.Fragment>
    <svg width="100" height="100" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="LinkToSupportPortal">
        <g id="Group 59">
          <g id="Vector">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.454 26.3076C20.7259 26.3076 26.6208 20.5499 26.6208 13.4473C26.6208 6.34471 20.7259 0.586945 13.454 0.586945C6.18221 0.586945 0.287231 6.34471 0.287231 13.4473C0.287231 20.5499 6.18221 26.3076 13.454 26.3076Z" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5013 26.2558C19.5449 26.2558 22.5477 19.6074 22.5477 13.4364C22.5477 7.26528 19.5449 0.616891 13.5013 0.616891C7.45768 0.616891 4.08459 7.26528 4.08459 13.4364C4.08459 19.6074 7.45768 26.2558 13.5013 26.2558Z" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5269 26.3376C17.5505 26.3376 19.5497 19.668 19.5497 13.4772C19.5497 7.28647 17.5505 0.616891 13.5269 0.616891C9.50326 0.616891 7.25757 7.28647 7.25757 13.4772C7.25757 19.668 9.50326 26.3376 13.5269 26.3376Z" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6337 26.3376C16.2507 26.3376 17.5509 19.668 17.5509 13.4772C17.5509 7.28647 16.2507 0.616891 13.6337 0.616891C11.0167 0.616891 9.5561 7.28647 9.5561 13.4772C9.5561 19.668 11.0167 26.3376 13.6337 26.3376Z" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.557415 13.5776C0.557415 16.8534 7.25321 18.481 13.4683 18.481C19.6834 18.481 26.3792 16.8534 26.3792 13.5776C26.3792 10.3018 19.6834 8.47342 13.4683 8.47342C7.25321 8.47342 0.557415 10.3018 0.557415 13.5776Z" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.556926 13.8629C0.556926 18.9553 7.25272 21.4854 13.4678 21.4854C19.6829 21.4854 26.3787 18.9553 26.3787 13.8629C26.3787 8.77059 19.6829 5.92843 13.4678 5.92843C7.25272 5.92843 0.556926 8.77059 0.556926 13.8629Z" stroke="currentColor" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.556926 13.9207C0.556926 20.7726 7.25272 24.1769 13.4678 24.1769C19.6829 24.1769 26.3787 20.7726 26.3787 13.9207C26.3787 7.0689 19.6829 3.24473 13.4678 3.24473C7.25272 3.24473 0.556926 7.0689 0.556926 13.9207Z" stroke="currentColor" strokeWidth="0.5"/>
          </g>
          <g id="Vector_2">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.48854 10.6304L7.68511 14.3955H6.89901L5.33604 11.932H4.51293L2.63552 15.8631H1.20203L3.70833 10.6304H6.41809L7.23195 11.9146L7.85159 10.6304H9.48854Z" fill="#D0021B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.1584 14.0951C16.1213 14.1652 16.0842 14.244 16.0563 14.3141C16.8821 14.3141 17.6799 14.4893 18.5149 14.1739C19.3221 13.8674 19.9066 13.2806 20.5653 12.7901C22.3745 11.4237 23.1816 11.6602 25.2505 11.6602C25.399 11.3361 25.5567 11.0033 25.7051 10.6705C24.4248 10.6705 23.1631 10.4865 21.8735 10.9069C20.5839 11.3274 19.7118 12.2733 18.6448 12.9828C18.8025 12.6587 18.9603 12.3259 19.118 12.0018C18.0139 12.0018 16.9934 11.8441 15.9914 12.6499C15.1378 13.3331 14.4049 14.1214 13.2823 14.4717C12.3638 14.7608 7.63218 14.6819 6.74152 14.6819C6.23125 13.8849 5.73026 13.0879 5.21998 12.2908C5.05298 12.2908 4.88598 12.2908 4.72826 12.2908C4.14377 13.5083 3.56855 14.7257 2.98406 15.9344C3.45722 15.9344 3.9211 15.9344 4.39427 15.9344C4.59838 15.5053 4.81176 15.0673 5.01587 14.6382C5.2942 15.0673 5.56326 15.5053 5.84159 15.9344C7.65074 15.9344 9.45989 15.9344 11.269 15.9344C12.1783 15.9344 13.0504 16.0395 13.9503 15.6542C14.8038 15.2863 15.3141 14.4893 16.1584 14.0951Z" fill="#D0021B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.2802 15.9407C15.4747 15.5298 15.6785 15.119 15.8731 14.6995C18.3929 14.6995 18.3836 14.8481 20.4495 13.2835C22.4227 11.7888 22.7469 11.9898 25.0814 11.9898C24.8962 12.3832 24.7201 12.7677 24.5349 13.1611C21.8391 13.1523 22.1077 13.6069 20.0789 15.0054C18.5226 16.0893 16.9662 15.9407 15.2802 15.9407Z" fill="#D0021B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1756 13.3269C10.2313 13.2035 10.2964 13.0801 10.3521 12.9568C11.1789 12.9568 12.0151 12.9568 12.8419 12.9568C12.9813 12.6572 13.1299 12.3575 13.2693 12.0579C12.4424 12.0579 11.6063 12.0579 10.7795 12.0579C10.8352 11.9346 10.8909 11.82 10.9467 11.6966C11.7735 11.6966 12.6097 11.6966 13.4365 11.6966C13.6037 11.3442 13.7709 10.9829 13.9475 10.6304C12.5632 10.6304 11.1697 10.6304 9.7854 10.6304C9.18153 11.8993 8.57766 13.1682 7.9738 14.4284C9.27444 14.4284 10.5658 14.4284 11.8664 14.4284C12.5446 14.4284 13.2135 14.2697 13.8174 13.9701C14.124 13.8203 14.4027 13.6353 14.6721 13.4238C14.9322 13.2123 15.1831 12.9832 15.4432 12.7629C15.9634 12.3135 16.4465 11.8905 17.1805 11.776C17.4778 11.7319 17.8958 11.7407 18.1931 11.7407C18.5276 11.7407 18.8713 11.7407 19.2058 11.7407C19.3823 11.3706 19.5588 11.0005 19.7353 10.6392C19.5588 10.6392 19.3823 10.6392 19.2058 10.6392C18.6855 10.6392 18.1745 10.6392 17.6543 10.6392C17.1619 10.6392 16.6602 10.7273 16.2143 10.9212C15.7126 11.1415 15.2667 11.4852 14.8393 11.8465C14.5606 12.0844 14.2912 12.3223 14.0125 12.5602C13.7245 12.807 13.3807 13.0008 13.0091 13.133C12.6468 13.2652 12.2566 13.3357 11.8757 13.3357C11.309 13.3269 10.7423 13.3269 10.1756 13.3269Z" fill="#D0021B"/>
          </g>
        </g>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'supportPortal')

export default icon