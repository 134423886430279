import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  gridElement: {
    width: '100%',
    maxWidth: 350,
    minWidth: 290,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.backgrounds.cardBackground,
    borderRadius: 6,
    padding: '10px 15px',
  },
  name: {
    marginBottom: 10,
  },
  healthWrapper: {
    height: 24,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
  },
  healthTitle: {
    fontSize: '14px',
    color: theme.texts.subText,
    marginRight: 12,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 'auto',
  },
  button: {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  signalLow: {
    color: 'rgba(222, 31, 38, 1)',
  },
  signalMedium: {
    color: 'rgba(255, 153, 0, 1)',
  },
  signalGood: {
    color: 'rgba(169, 207, 57, 1)',
  },
  red: {
    color: 'rgba(222, 31, 38, 1)',
  },
  green: {
    color: 'rgba(169, 207, 57, 1)',
  },
}))

export default useStyles
