import React from 'react'
import {
  Dialog, DialogContent, DialogTitle, List, ListItem, Typography,
} from '@mui/material'
import { Close, Refresh } from 'icons'
import classNames from 'classnames'
import IconButton from '@mui/material/IconButton'
import convertDeviceValueColor from '@app/helpers/myStatus/convertDeviceValueColor'
import convertDeviceStatsForPopup from '@app/helpers/myStatus/convertDeviceStatsForPopup'
import renderIconForEachSensor from '@app/helpers/myStatus/renderIconForEachSensor'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'

class MyStatusDevicePopup extends React.PureComponent {
    getClassNameFromColor = (sensorType) => {
      const { classes } = this.props
      return classes[convertDeviceValueColor(sensorType.type, sensorType.status, sensorType.scale)]
    }

    renderIcon = (sensorType) => {
      const { classes } = this.props
      const color = convertDeviceValueColor(sensorType.type, sensorType.status, sensorType.scale)
      return (
        <p className={classNames(
          classes.icon,
          classes[renderIconForEachSensor(sensorType.type) + color],
          classes[color],
        )}
        />
      )
    }

    render() {
      const {
        classes,
        isMobile,
        isMyStatusDevicePopupOpen,
        setMyStatusPopupClosed,
        errorMessage,
        syncMyStatusPopup,
        deviceName,
        deviceStats,
      } = this.props
      const transformedDeviceStats = convertDeviceStatsForPopup(deviceStats)
      return (
        <Dialog
          className={classNames(
            classes.root,
          )}
          open={isMyStatusDevicePopupOpen}
          onClose={('backdropClick', () => setMyStatusPopupClosed())}
        >
          <DialogTitle
            className={classes.title}
          >
            <Refresh className={classes.refreshButton} onClick={syncMyStatusPopup} />
            {deviceName}
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={setMyStatusPopupClosed}
              size="large"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classNames(classes.statsList, isMobile && classes.statsListMobile)}>
            <Wrapper>
              <List>
                {
                  deviceStats.length > 0 ? transformedDeviceStats.map((deviceStat, index) => (
                    <ListItem
                      divider
                      key={`deviceStats-${index + 1}`}
                      className={classes.listItem}
                    >
                      <Wrapper className={classes.listItemWrapper}>
                        <Wrapper className={classes.itemContainer}>
                          <Wrapper className={classes.labels}>
                            <Typography className={classNames(
                              classes.devices,
                            )}
                            >
                              {deviceStat.title}
                            </Typography>
                            <Typography className={classNames(
                              classes.lastUpdate,
                            )}
                            >
                              {I18n.t('my_status.last_update')}
                            </Typography>
                          </Wrapper>
                          <Wrapper className={classes.data}>
                            <Wrapper className={classes.valueIconContainer}>
                              <Typography className={classNames(
                                classes.info,
                                this.getClassNameFromColor(deviceStat),
                              )}
                              >
                                {`${deviceStat.status} ${(deviceStat.status !== 'N/A' ? deviceStat.short_title : '')}`}
                              </Typography>
                              {this.renderIcon(deviceStat)}
                            </Wrapper>
                            <Typography className={classNames(
                              classes.lastUpdate,
                            )}
                            >
                              {deviceStat.happened_at}
                            </Typography>
                          </Wrapper>
                        </Wrapper>
                      </Wrapper>
                    </ListItem>
                  )) : <Typography className={classes.errorMessage} variant="h6">{errorMessage}</Typography>
                }
              </List>
            </Wrapper>
          </DialogContent>
        </Dialog>
      )
    }
}

MyStatusDevicePopup.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMyStatusDevicePopupOpen: PropTypes.bool.isRequired,
  syncMyStatusPopup: PropTypes.func.isRequired,
  setMyStatusPopupClosed: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
  deviceStats: PropTypes.arrayOf(PropTypes.myStatusPopup.isRequired),
}

MyStatusDevicePopup.defaultProps = {
  deviceStats: [],
}

MyStatusDevicePopup.displayName = 'MyStatusDevicePopup'

export default MyStatusDevicePopup
