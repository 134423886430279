import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M20.5534 7.37129C20.4627 7.28047 20.3367 7.22827 20.2083 7.22827C20.08 7.22827 19.9541 7.28047 19.8632 7.37129C19.7725 7.46211 19.7202 7.58804 19.7202 7.71641C19.7202 7.84477 19.7724 7.9707 19.8632 8.06147C19.954 8.15224 20.08 8.20454 20.2083 8.20454C20.3367 8.20454 20.4626 8.15229 20.5534 8.06147C20.6442 7.97075 20.6965 7.84477 20.6965 7.71641C20.6965 7.58804 20.6442 7.46206 20.5534 7.37129Z" />
      <path fill="currentColor" d="M20.2084 6.2771C20.4779 6.2771 20.6965 6.0585 20.6965 5.78896V1.46905C20.6965 0.661583 20.0396 0.00469971 19.2321 0.00469971H1.4644C0.656932 0.00465088 0 0.661583 0 1.46905V23.5323C0 24.3398 0.656932 24.9967 1.4644 24.9967H19.2322C20.0397 24.9967 20.6966 24.3398 20.6966 23.5323V21.34C20.6966 21.0704 20.478 20.8518 20.2085 20.8518C19.9389 20.8518 19.7203 21.0704 19.7203 21.34V23.5323C19.7203 23.8015 19.5013 24.0205 19.2322 24.0205H1.4644C1.19526 24.0205 0.976268 23.8015 0.976268 23.5323V1.46905C0.976268 1.19991 1.19526 0.980918 1.4644 0.980918H19.2322C19.5013 0.980918 19.7203 1.19991 19.7203 1.46905V5.78896C19.7203 6.0585 19.9389 6.2771 20.2084 6.2771Z" />
      <path fill="currentColor" d="M24.5709 19.6365L22.0529 17.1185C22.4864 16.3445 22.7342 15.4529 22.7342 14.5044C22.7342 11.5454 20.3269 9.13812 17.3679 9.13812C17.3384 9.13812 17.309 9.13876 17.2796 9.13925V6.45341C17.2796 6.18388 17.061 5.96528 16.7914 5.96528H13.9386V3.32944C13.9386 3.05991 13.72 2.84131 13.4505 2.84131H10.1096C9.84003 2.84131 9.62142 3.05991 9.62142 3.32944V6.2582H6.7686C6.49907 6.2582 6.28046 6.4768 6.28046 6.74633V9.38221H3.90488C3.63535 9.38221 3.41675 9.60082 3.41675 9.87035V12.5062C3.41675 12.7758 3.63535 12.9944 3.90488 12.9944H12.2182C12.0774 13.4736 12.0015 13.9803 12.0015 14.5045C12.0015 17.4634 14.4088 19.8708 17.3678 19.8708C18.3163 19.8708 19.2079 19.6231 19.9819 19.1895L22.4834 21.6909C22.7781 21.9857 23.1611 22.1342 23.5426 22.1341C23.8985 22.1341 24.2531 22.0048 24.5328 21.7442C24.8267 21.4706 24.9924 21.099 24.9996 20.6982C25.0067 20.297 24.8545 19.9201 24.5709 19.6365ZM6.28042 12.0181H4.39302V10.3585H6.28046V12.0181H6.28042ZM9.62138 12.0181H7.25668V9.87035V7.23446H9.62138V12.0181ZM10.5976 12.0181V6.74633V3.81758H12.9623V6.45346V8.22079V8.88793C12.9623 9.15746 13.1809 9.37606 13.4504 9.37606C13.72 9.37606 13.9386 9.15746 13.9386 8.88793V8.22079V6.94159H16.3033V9.24437C14.6995 9.56844 13.353 10.6099 12.6136 12.0181H10.5976V12.0181ZM17.3678 18.8945C14.9472 18.8945 12.9778 16.9251 12.9778 14.5044C12.9778 12.0838 14.9471 10.1143 17.3678 10.1143C19.7885 10.1143 21.7579 12.0837 21.7579 14.5044C21.7579 16.9251 19.7885 18.8945 17.3678 18.8945ZM23.8674 21.0298C23.6776 21.2066 23.3666 21.1934 23.1737 21.0006L20.7997 18.6265C21.05 18.4176 21.2812 18.1866 21.49 17.9362L23.8806 20.3267C23.9752 20.4213 24.0259 20.5469 24.0235 20.6806C24.0211 20.8144 23.9657 20.9383 23.8674 21.0298Z" />
      <path fill="currentColor" d="M18.6389 12.0085C18.4042 11.876 18.1064 11.9588 17.9739 12.1936L15.3493 16.8427C15.2168 17.0775 15.2996 17.3753 15.5344 17.5078C15.6102 17.5506 15.6926 17.571 15.7739 17.571C15.9442 17.571 16.1097 17.4816 16.1994 17.3227L18.824 12.6736C18.9566 12.4387 18.8737 12.141 18.6389 12.0085Z" />
      <path fill="currentColor" d="M19.0735 14.9212C18.2661 14.9212 17.6091 15.5781 17.6091 16.3856C17.6091 17.193 18.2661 17.8499 19.0735 17.8499C19.881 17.8499 20.5379 17.193 20.5379 16.3856C20.5379 15.5781 19.881 14.9212 19.0735 14.9212ZM19.0735 16.8737C18.8044 16.8737 18.5854 16.6547 18.5854 16.3855C18.5854 16.1164 18.8044 15.8974 19.0735 15.8974C19.3427 15.8974 19.5617 16.1164 19.5617 16.3855C19.5617 16.6547 19.3427 16.8737 19.0735 16.8737Z" />
      <path fill="currentColor" d="M15.6624 11.1589C14.8549 11.1589 14.198 11.8158 14.198 12.6233C14.198 13.4308 14.8549 14.0877 15.6624 14.0877C16.4699 14.0877 17.1268 13.4308 17.1268 12.6233C17.1268 11.8158 16.4698 11.1589 15.6624 11.1589ZM15.6624 13.1114C15.3933 13.1114 15.1743 12.8924 15.1743 12.6233C15.1743 12.3541 15.3933 12.1351 15.6624 12.1351C15.9315 12.1351 16.1505 12.3541 16.1505 12.6233C16.1505 12.8924 15.9315 13.1114 15.6624 13.1114Z" />
      <path fill="currentColor" d="M16.6387 21.3839H3.12412C2.85459 21.3839 2.63599 21.6025 2.63599 21.872C2.63599 22.1416 2.85459 22.3602 3.12412 22.3602H16.6387C16.9083 22.3602 17.1269 22.1416 17.1269 21.872C17.1269 21.6025 16.9083 21.3839 16.6387 21.3839Z" />
      <path fill="currentColor" d="M10.3482 18.6504H7.32163C7.0521 18.6504 6.8335 18.869 6.8335 19.1385C6.8335 19.4081 7.0521 19.6267 7.32163 19.6267H10.3482C10.6178 19.6267 10.8364 19.4081 10.8364 19.1385C10.8364 18.869 10.6178 18.6504 10.3482 18.6504Z" />
      <path fill="currentColor" d="M12.4834 18.7934C12.3926 18.7026 12.2667 18.6504 12.1383 18.6504C12.0099 18.6504 11.884 18.7026 11.7932 18.7934C11.7019 18.8842 11.6501 19.0102 11.6501 19.1385C11.6501 19.2669 11.7019 19.3928 11.7932 19.4836C11.8839 19.5744 12.0095 19.6267 12.1383 19.6267C12.2667 19.6267 12.3926 19.5744 12.4834 19.4836C12.5741 19.3929 12.6264 19.2669 12.6264 19.1385C12.6264 19.0101 12.5742 18.8842 12.4834 18.7934Z" />
      <path fill="currentColor" d="M10.3482 15.9169H7.32163C7.0521 15.9169 6.8335 16.1355 6.8335 16.405C6.8335 16.6745 7.0521 16.8931 7.32163 16.8931H10.3482C10.6178 16.8931 10.8364 16.6745 10.8364 16.405C10.8364 16.1355 10.6178 15.9169 10.3482 15.9169Z" />
      <path fill="currentColor" d="M4.198 17.1611C3.875 17.1611 3.61225 16.9428 3.61225 16.6746C3.61225 16.4063 3.875 16.1881 4.198 16.1881C4.52099 16.1881 4.78374 16.4064 4.78374 16.6746C4.78374 16.9441 5.00234 17.1627 5.27187 17.1627C5.5414 17.1627 5.76001 16.9441 5.76001 16.6746C5.76001 16.0275 5.30879 15.4776 4.68564 15.2853C4.68061 15.0201 4.4644 14.8065 4.198 14.8065C3.93159 14.8065 3.71533 15.0201 3.71035 15.2853C3.08721 15.4776 2.63599 16.0275 2.63599 16.6746C2.63599 17.4811 3.33667 18.1374 4.198 18.1374C4.52099 18.1374 4.78374 18.3556 4.78374 18.6238C4.78374 18.892 4.52099 19.1103 4.198 19.1103C3.875 19.1103 3.61225 18.892 3.61225 18.6238C3.61225 18.3543 3.39365 18.1357 3.12412 18.1357C2.85459 18.1357 2.63599 18.3543 2.63599 18.6238C2.63599 19.2708 3.08696 19.8206 3.70986 20.013V20.0412C3.70986 20.3107 3.92847 20.5293 4.198 20.5293C4.46753 20.5293 4.68613 20.3107 4.68613 20.0412V20.013C5.30903 19.8206 5.76001 19.2708 5.76001 18.6238C5.76001 17.8173 5.05927 17.1611 4.198 17.1611Z" />
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'BillingPeriodIcon')

export default icon
