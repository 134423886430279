import React from 'react'
import { Button, Typography } from '@mui/material'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './RoomsStatsItem.style'

class RoomsStatsItem extends React.Component {
  RoomStatsOpen = () => {
    const {
      openRoomStatsPopup,
      selectCurrentRoomIdAndName,
      id,
      name,
      syncCurrentRoomStats,
    } = this.props
    selectCurrentRoomIdAndName(id, name)
    syncCurrentRoomStats()
    openRoomStatsPopup()
  }

  render() {
    const {
      classes,
      name,
      billing,
      energy,
      isMobile,
    } = this.props
    return (
      <Wrapper className={classes.listItem}>
        <Wrapper className={classes.itemHeader}>
          <Typography className={classes.roomName}>{name}</Typography>
          <Typography className={classes.roomPeriod}>{I18n.t('my_energy.today')}</Typography>
        </Wrapper>
        <Wrapper>
          <Typography className={classes.roomStats}>
            {`${I18n.t('my_energy.kilowatt_title')}: ${energy} ${I18n.t('my_energy.power_kwh')}`}
          </Typography>
          <Typography className={classes.roomStats}>{`${I18n.t('my_energy.dollar_title')}: ${billing} $`}</Typography>
        </Wrapper>
        <Wrapper className={classes.buttonStatWrapper}>
          <Button
            className={classNames(
              classes.buttonStat,
              !isMobile && classes.buttonStatDesktop,
            )}
            color="primary"
            onClick={() => {
              this.RoomStatsOpen()
            }}
          >
            See more
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}
RoomsStatsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  billing: PropTypes.number.isRequired,
  energy: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  openRoomStatsPopup: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  selectCurrentRoomIdAndName: PropTypes.func.isRequired,
  syncCurrentRoomStats: PropTypes.func.isRequired,
}

export default withStyles(styles)(RoomsStatsItem)
