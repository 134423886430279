import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { sync } from '@app/actions/numberOfZones'
import { updateAccessToken } from '@app/actions/secrets'
// @ts-ignore
import { updateCurrentUser } from '@app/actions/users/currentUser'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withForbiddenAccess, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs/index'
import NumberOfZones from '@app/components/NumberOfZones/NumberOfZones'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = NumberOfZones

const mapStateToProps = (state: AppState) => {
  const {
    initialOptions, currentOptions, defaultOptions, validation,
  } = state.numberOfZones
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const forbiddenRoles = ['family', 'guest']
  const currentRole = state.users.currentUser.user.role
  return {
    initialOptions,
    currentOptions,
    defaultOptions,
    validation,
    isMobile,
    path,
    isWebView,
    forbiddenRoles,
    currentRole,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (parentDispatch: AppDispatch) => bindActionCreators({
  refresh: sync,
  tryAgain: sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
  notifications: syncNotifications.sync,
}, parentDispatch)

enhance = withRefresh(enhance)
enhance = withForbiddenAccess(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const NumberOfZonesEnhanced = enhance

export default NumberOfZonesEnhanced
