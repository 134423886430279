import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isClientCredentialsTokenValid from '@app/helpers/isClientCredentialsTokenValid'
import { withStyles } from '@app/components/styles'
import {
  clearCode,
  clearEmail,
  closeWrongCodePopup,
  postCode,
  postEmail,
  saveCode,
} from '@app/actions/wizard/forgotPassword'
import Code from '@app/components/Wizard/ForgotPassword/CodeScreen/Code'
import { withAccessDenied, withProgressAndErrorMessage, withRouter } from '@app/hocs'

let enhance = withStyles(Code)

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { login: { forgotPassword: { code, isWrongCodePopupOpened } } } = store.getState()
  const { secrets: { clientCredentialsToken } } = store.getState()
  const isTokenValid = isClientCredentialsTokenValid(clientCredentialsToken)
  return {
    isMobile,
    isTablet,
    path,
    code,
    isWrongCodePopupOpened,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isTokenValid,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveCode,
  postCode,
  closeWrongCodePopup,
  postEmail,
  clearEmail,
  clearCode,
}, dispatch)

enhance = withRouter(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const CodeEnhanced = enhance

export default CodeEnhanced
