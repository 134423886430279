import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import {
  saveConditionGroupState,
  saveConditionStateDescription,
  saveConditionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGroupConditionState
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/GroupCondition/SelectGroupConditionState'
import SelectGroupConditionStateIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/GroupCondition/SelectGroupConditionState'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
let enhance = isIOS && isWebView ? withStyles(SelectGroupConditionStateIOS) : withStyles(SelectGroupConditionState)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    isEditConditionMode,
    isChangeConditionStateMode,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  saveConditionType,
  saveConditionStateDescription,
  saveConditionGroupState,
}, dispatch)

enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGroupConditionStateEnhanced = enhance

export default withRouter(SelectGroupConditionStateEnhanced)
