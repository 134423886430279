import React from 'react'
import classNames from 'classnames'
import {
  Button, Paper, TextField, Typography,
} from '@mui/material'
import {
  Circle, GoogleMap, LoadScript, Marker,
} from '@react-google-maps/api'
import calculateBounds from '@app/helpers/scenes/calculateBoundsForCircle'
import validateName from '@app/helpers/myDevices/validateName'
import { libraries, NEW_GEOFENCE_NAME_PATTERN, options } from '@app/lib/Scenes/geofences'
import { SELECT_GEOFENCES_CONDITION, SELECT_GEOFENCES_LOCATION } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectGeofencesName extends React.PureComponent {
  state = {
    geofenceName: '',
  }

  onBackClick = () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_GEOFENCES_LOCATION)
  }

  onAddClick = () => {
    const {
      navigate,
      postGeofence,
      lat,
      lng,
      size,
      saveConditionGeofenceName,
    } = this.props
    const { geofenceName } = this.state
    const data = {
      name: geofenceName,
      latitude: lat,
      longitude: lng,
      size,
    }
    saveConditionGeofenceName(geofenceName)
    postGeofence(data)
    navigate(SELECT_GEOFENCES_CONDITION)
  }

  render() {
    const {
      classes,
      lat,
      lng,
      size,
      isMobile,
      address,
    } = this.props
    const coordinates = { lat, lng }
    const { geofenceName } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.create_geofence')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.mapContainer}>
            <TextField
              variant="standard"
              label={I18n.t('geofence_web.name_geofence')}
              className={classes.nameInput}
              inputProps={{ pattern: NEW_GEOFENCE_NAME_PATTERN, maxLength: 16, className: classes.innerInput }}
              onChange={(e) => this.setState({
                geofenceName: e.target.value.trimLeft(),
              })}
              value={geofenceName}
            />
            <LoadScript
              id="script-loader"
              googleMapsApiKey="AIzaSyAX8HCt8teGLIgx7AWiJY3PDHifmHb7V_s"
              libraries={libraries}
            >
              <GoogleMap
                id="searchbox"
                mapContainerStyle={{
                  height: isMobile ? '400px' : '580px',
                  width: '100%',
                }}
                zoom={calculateBounds(size)}
                center={coordinates}
                options={{ streetViewControl: false, disableDefaultUI: true }}
              >
                <Circle
                  radius={size}
                  center={coordinates}
                  options={options}
                />
                <Marker position={coordinates} />
              </GoogleMap>
            </LoadScript>
          </Wrapper>
          <Typography className={classes.address}>{address}</Typography>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddClick}
            disabled={!validateName(geofenceName) || geofenceName.length < 2}
          >
            {I18n.t('scene_web.add_geofence')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectGeofencesName.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  postGeofence: PropTypes.func.isRequired,
  saveConditionGeofenceName: PropTypes.func.isRequired,
}

SelectGeofencesName.defaultProps = {

}

SelectGeofencesName.displayName = 'SelectGeofencesNameIOS'

export default SelectGeofencesName
