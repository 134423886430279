import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isClientCredentialsTokenValid from '@app/helpers/isClientCredentialsTokenValid'
import { withStyles } from '@app/components/styles'
import {
  clearCode,
  clearEmail,
  closeWrongEmailPopup,
  hideError,
  postEmail,
  saveEmail,
  showError,
} from '@app/actions/wizard/forgotPassword'
import { getClientCredentialsToken, getClientCredentialsTokenDone } from '@app/actions/secrets'
import {
  withAccessDenied, withClientCredentialsAuth, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'
import Email from '@app/components/Wizard/ForgotPassword/EmailScreen/Email'

let enhance = withStyles(Email)

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { login: { forgotPassword: { email, validateError, isWrongEmailPopupOpened } } } = store.getState()
  const { secrets: { clientCredentialsToken } } = store.getState()
  const isTokenValid = isClientCredentialsTokenValid(clientCredentialsToken)
  return {
    isMobile,
    isTablet,
    path,
    email,
    validateError,
    isWrongEmailPopupOpened,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isTokenValid,
    validAccessToken: isTokenValid,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getToken: getClientCredentialsToken,
  getTokenSkip: getClientCredentialsTokenDone,
  saveEmail,
  showError,
  hideError,
  postEmail,
  clearEmail,
  clearCode,
  closeWrongEmailPopup,
}, dispatch)

enhance = withRouter(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withClientCredentialsAuth(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const EmailEnhanced = enhance

export default EmailEnhanced
