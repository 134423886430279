import React from 'react'
import createSvgIcon from './helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3175 11.1705V8.39678C13.3175 6.926 12.1252 5.7337 10.6545 5.7337C9.18369 5.7337 7.99139 6.926 7.99139 8.39678V11.1705M2.96153 10.6651C2.86591 14.4432 2.89816 18.2215 3.05827 22H17.9719C18.132 18.2215 18.1642 14.4432 18.0686 10.6651H20.0149C20.0251 10.6651 20.021 10.655 20.0149 10.6496L19.7768 10.3918C19.4611 10.0504 19.1454 9.70966 18.8297 9.3696C16.2035 6.55127 13.5146 3.79607 10.763 1.10402C10.6685 1.00113 10.5184 0.971056 10.3916 1.02961C10.3612 1.04039 10.3323 1.05518 10.3057 1.07358C10.0189 1.27247 9.81523 1.54714 9.57236 1.78459C8.56031 2.77861 7.55795 3.78277 6.56529 4.79708C5.21813 6.1731 3.88767 7.56422 2.57389 8.97046C2.29832 9.26541 2.02299 9.56082 1.74787 9.85668L1.33385 10.3039L1.12616 10.5291L1.02198 10.6414C1.01589 10.6482 0.987478 10.6644 1.00642 10.6644L2.96153 10.6651ZM7.12677 19.1161H14.1827C14.5129 19.1161 14.6495 18.9807 14.6495 18.6493V11.6373C14.6495 11.3071 14.5142 11.1705 14.1827 11.1705H7.12677C6.79663 11.1705 6.65998 11.3058 6.65998 11.6373V18.6493C6.65998 18.9794 6.79663 19.1161 7.12677 19.1161ZM11.3048 14.7783C11.9929 14.1775 11.568 13.0451 10.6547 13.0451C9.74144 13.0451 9.31591 14.1782 10.0046 14.7783C9.95454 15.4489 9.90425 16.1196 9.85374 16.7902H11.455C11.405 16.1196 11.3549 15.4489 11.3048 14.7783Z" stroke="currentColor"/>
    </svg>


  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ArmHomeModeIcon')

export default icon
