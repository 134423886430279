import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import filterRoomGroupForScreen from '@app/helpers/scenes/filterRoomGroupForScreen'
import { SELECT_ACTION_DEVICE_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import GroupsList from './GroupsList'

class SelectGroupAction extends React.Component {
  onBackClick= () => {
    const {
      navigate,
    } = this.props
    navigate(SELECT_ACTION_DEVICE_TYPE)
  }

  render() {
    const {
      classes,
      isMobile,
      groupsScenes,
      saveActionDescription,
      saveActionGroupNumber,
      navigate,
    } = this.props
    const filteredGroups = filterRoomGroupForScreen(groupsScenes)
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>Select group</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <GroupsList
            navigate={navigate}
            groupsScenes={filteredGroups}
            saveActionDescription={saveActionDescription}
            saveActionGroupNumber={saveActionGroupNumber}
          />
        </Wrapper>
      </Paper>
    )
  }
}

SelectGroupAction.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionDescription: PropTypes.func.isRequired,
  saveActionGroupNumber: PropTypes.func.isRequired,
  groupsScenes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

SelectGroupAction.displayName = 'SelectGroupAction'

export default SelectGroupAction
