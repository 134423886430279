import type { Node } from 'react'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, List, Typography } from '@mui/material'
import { CalendarPicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { PILLS_REMINDERS_EVENTS } from '@app/constants/routes'
import { EmptyEvents } from 'icons'
import { fetchPillsEvents } from '@app/actions/pillsReminders/pillsReminders'
import RemindersTabs from '@app/components/PillsReminders/RemindersTabs/RemindersTabs'
import EventCard from '@app/components/PillsReminders/PillsRemindersEvents/EventCard/EventCard'
import useStyles from './PillsRemindersEvents.style'

const PillsRemindersEvents = (): Node => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const { events } = useSelector((state) => state.pillsReminders)

  const dateForBackend = (date) => date.toISOString().split('T')[0]

  useEffect(() => {
    dispatch(fetchPillsEvents(dateForBackend(selectedDate)))
  }, [])

  const handleCalendarDateChange = (newDate) => {
    setSelectedDate(newDate)
    dispatch(fetchPillsEvents(dateForBackend(newDate)))
  }

  return (
    <Paper className={classNames(isWebView && classes.webview, classes.root)}>
      <RemindersTabs active={PILLS_REMINDERS_EVENTS} />
      <Wrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CalendarPicker
            value={selectedDate}
            onChange={handleCalendarDateChange}
          />
        </LocalizationProvider>
        <List className={classes.eventsList}>
          {events.length ? events.map((event, index) => (
            <EventCard key={`event-${index + 1}`} event={event} />
          ))
            : (
              <Wrapper className={classes.emptyEventsWrapper}>
                <EmptyEvents className={classes.emptyEvents} />
                <Typography className={classes.emptyText}>No Events</Typography>
              </Wrapper>
            )}
        </List>
      </Wrapper>
    </Paper>
  )
}

export default PillsRemindersEvents
