import { createLogic } from 'redux-logic'
import {
  DELETE_GEOFENCE,
} from '@app/constants/geofences/geofences'
import { syncGeofences } from '@app/actions/geofences/geofences'
import Api from '@app/api/geofences'
import handleErrors from '@app/helpers/handleErrors'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const deleteGeofenceLogic = createLogic({
  type: DELETE_GEOFENCE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(DELETE_GEOFENCE))
    Api.deleteGeofence(action.accessToken, action.id)
      .then(() => {
        dispatch(apiRequestDone(DELETE_GEOFENCE))
        dispatch(syncGeofences())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteGeofence failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteGeofenceLogic
