import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import getDeviceStateInfo from '@app/helpers/scenes/getDeviceStateInfo'
import { SELECT_BINARY_SENSOR_STATE_SELECTED, SELECT_BINARY_SENSOR_TYPE } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectBinarySensorValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSensorStateMode,
    } = this.props
    if (isChangeSensorStateMode) {
      navigate(SELECT_BINARY_SENSOR_STATE_SELECTED)
    } else {
      navigate(SELECT_BINARY_SENSOR_TYPE)
    }
  }

  selectSensor=(sensor) => {
    const {
      saveBinarySensorValueDescription,
      saveConditionDeviceStateInfo,
      navigate,
    } = this.props
    saveBinarySensorValueDescription(sensor.title)
    const deviceState = getDeviceStateInfo(sensor)
    saveConditionDeviceStateInfo(deviceState)
    navigate(SELECT_BINARY_SENSOR_STATE_SELECTED)
  }

  renderSensorsList=(sensors) => (sensors.map((sensor) => (
    <CardBlock
      key={sensor.title}
      description={sensor.title.replace('sensor', '')}
      position="middle"
      buttonName={I18n.t('scene_web.add')}
      isCardDisabled={false}
      isButtonDisabled={false}
      isValueButton
      onClick={() => {
        this.selectSensor(sensor)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
      sensors,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_sensor_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {sensors.length
            ? this.renderSensorsList(sensors)
            : <Typography className={classes.somethingWrong}>{I18n.t('errors.something_went_wrong')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectBinarySensorValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveBinarySensorValueDescription: PropTypes.func.isRequired,
  saveConditionDeviceStateInfo: PropTypes.func.isRequired,
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({
        type: PropTypes.number.isRequired,
        value: PropTypes.number,
        number: PropTypes.number.isRequired,
        class: PropTypes.number,
      }),
    }),
  ).isRequired,
  isChangeSensorStateMode: PropTypes.bool.isRequired,
}

SelectBinarySensorValue.displayName = 'SelectBinarySensorValueIOS'

export default SelectBinarySensorValue
