import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { useDispatch } from 'react-redux'
import ZWaveDevicesList
  from '@app/containers/MyAutomation/CreateCondition/DevicesConditions/ZWaveDevices/ZWaveDevicesList'
// eslint-disable-next-line max-len
import ZWaveDevicesByGroupList
  from '@app/containers/MyAutomation/CreateCondition/DevicesConditions/ZWaveDevices/ZWaveDevicesByGroupList'
// eslint-disable-next-line max-len
import ZWaveDevicesByRoomsList
  from '@app/containers/MyAutomation/CreateCondition/DevicesConditions/ZWaveDevices/ZWaveDevicesByRoomsList'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { chooseTab } from '@app/actions/scenes/devicesTabs'
import useStyles from './ZWaveTabs.style'

interface Props {
  currentTab: number,
}

const ZWaveTabs: React.FC<Props> = (props) => {
  const {
    currentTab,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = (event: any, value: number) => {
    dispatch(chooseTab(value))
  }

  return (
    <Wrapper>
      <Tabs
        className={classes.deviceTabs}
        value={currentTab}
        indicatorColor="primary"
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab
          label="By devices"
        />
        <Tab
          label="By room"
        />
        <Tab
          label="By group"
        />
      </Tabs>
      {currentTab === 0 && (
        // @ts-ignore
        <ZWaveDevicesList />
      )}
      {currentTab === 1 && (
        // @ts-ignore
        <ZWaveDevicesByRoomsList />
      )}
      {currentTab === 2 && (
        // @ts-ignore
        <ZWaveDevicesByGroupList />
      )}
    </Wrapper>
  )
}

export default ZWaveTabs
