import React from 'react'
import {
  AlarmIcon,
  AlertCancelledIcon,
  AutoArmingIcon,
  BatteryLowIcon,
  BatteryRestoredIcon,
  DayArmedIcon,
  DeviceExcludedIcon,
  DeviceIncludedIcon,
  DisarmedDayIcon,
  HealthAlertIcon,
  HighestArmedIcon,
  HomeArmedIcon,
  LostBatteryIcon,
  LostMainsPowerIcon,
  MainsPowerRestoredIcon,
  NightArmedIcon,
  PanicAlertIcon,
  PerimeterArmedIcon,
  ReportingAgainIcon,
  RestoredAfterAlarmIcon,
  RestoredAfterTamperIcon,
  RestoredAndReadyIcon,
  SceneActivatedIcon,
  SealedIcon,
  TamperAlertIcon,
  UnableSendReportIcon,
  UniversalIcon,
  UnsealedIcon,
  VacationArmedIcon,
  Area1ArmedIcon,
  Area2ArmedIcon,
  LockOpen,
} from 'icons'

export const iconMap = {
  alarm: <AlarmIcon />,
  alert_cancelled: <AlertCancelledIcon />,
  auto_arming: <AutoArmingIcon />,
  battery_low: <BatteryLowIcon />,
  battery_restored: <BatteryRestoredIcon />,
  day_armed: <DayArmedIcon />,
  device_excluded: <DeviceExcludedIcon />,
  device_included: <DeviceIncludedIcon />,
  disarmed_day: <DisarmedDayIcon />,
  health_alert: <HealthAlertIcon />,
  highest_armed: <HighestArmedIcon />,
  home_armed: <HomeArmedIcon />,
  home_disarmed: <LockOpen />,
  lost_battery: <LostBatteryIcon />,
  lost_mains_power: <LostMainsPowerIcon />,
  mains_power_restored: <MainsPowerRestoredIcon />,
  night_armed: <NightArmedIcon />,
  panic_alert: <PanicAlertIcon />,
  perimeter_armed: <PerimeterArmedIcon />,
  perimeter_disarmed: <LockOpen />,
  reporting_again: <ReportingAgainIcon />,
  restored_after_alarm: <RestoredAfterAlarmIcon />,
  restored_after_tamper: <RestoredAfterTamperIcon />,
  restored_and_ready: <RestoredAndReadyIcon />,
  scene_activated: <SceneActivatedIcon />,
  sealed: <SealedIcon />,
  tamper_alert: <TamperAlertIcon />,
  unable_send_report: <UnableSendReportIcon />,
  universal: <UniversalIcon />,
  unsealed: <UnsealedIcon />,
  vacation_armed: <VacationArmedIcon />,
  area1_armed: <Area1ArmedIcon />,
  area2_armed: <Area2ArmedIcon />,
}
