import * as type from '@app/constants/users/geofenceUsers'

const initialState = {
  users: [],
}

export default function geofenceUsers(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_GEOFENCE_USERS: {
    return {
      ...state,
      users: action.users,
    }
  }

  default:
    return state
  }
}
