import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9844 0C19.5156 0 19.9844 0.203125 20.3906 0.609375C20.7969 1.01562 21 1.48438 21 2.01562V20.0156C21 20.5469 20.7969 21.0156 20.3906 21.4219C19.9844 21.8281 19.5156 22.0312 18.9844 22.0312H9C8.46875 22.0312 8 21.8281 7.59375 21.4219C7.1875 21.0156 6.98438 20.5469 6.98438 20.0156V17.0156H9V19.0312H18.9844V3H9V5.01562H6.98438V2.01562C6.98438 1.48438 7.1875 1.01562 7.59375 0.609375C8 0.203125 8.46875 0 9 0H18.9844ZM5.12498 13.825L1.79998 10.5L0.69165 11.6083L5.12498 16.0417L14.625 6.54168L13.5167 5.43335L5.12498 13.825Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'DeviceIncludedIcon')

export default icon
