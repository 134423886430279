import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import rgba from 'color-rgba'
import { ColorTypes } from '@app/lib/Scenes/colorActions'
import { ADJUST_DURATION_COLOR, SELECT_ACTION_COLOR_TYPE_AFTER_DURATION } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import RGBColorPicker from '../SelectColorActionHue/ColorPickers/RGBColorPicker/RGBColorPicker'
import Swatches from '../SelectColorActionHue/ColorPickers/Swatches/Swatches'
import WhitePicker from '../SelectColorActionHue/ColorPickers/WhitePicker/WhitePicker'

class SelectColorActionHueAfterDuration extends React.Component {
  state = {
    currentColor: 'rgba(255, 255, 255, 1)',
  }

  onBackClick = () => {
    const {
      navigate,
      restoreActionInfo,
    } = this.props
    restoreActionInfo()
    navigate(SELECT_ACTION_COLOR_TYPE_AFTER_DURATION)
  }

  onChangeColor = (color) => {
    const { rgb } = color
    this.setState({ currentColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` })
  }

  onNextClick=() => {
    const {
      saveActionDurationValueAfter,
      colorTypeDurationValueAfter,
      navigate,
    } = this.props
    const { currentColor } = this.state
    if (colorTypeDurationValueAfter === ColorTypes.swatch || colorTypeDurationValueAfter === ColorTypes.rgb) {
      saveActionDurationValueAfter(currentColor)
    }
    if (colorTypeDurationValueAfter === ColorTypes.whiteWarm || colorTypeDurationValueAfter === ColorTypes.whiteCold) {
      const currentValue = Math.round(rgba(currentColor)[3] * 100).toString()
      saveActionDurationValueAfter(currentValue)
    }
    navigate(ADJUST_DURATION_COLOR)
  }

  render() {
    const {
      classes,
      isMobile,
      colorTypeDurationValueAfter,
    } = this.props
    const {
      currentColor,
    } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_action_web.select_colour_after_duration')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.colorPickerWrapper}>
            {colorTypeDurationValueAfter === ColorTypes.rgb
            && <RGBColorPicker color={currentColor} oldColor={currentColor} changeColor={this.onChangeColor} presetColors={[]} />}
            {colorTypeDurationValueAfter === ColorTypes.swatch && <Swatches changeColor={this.onChangeColor} />}
            {(colorTypeDurationValueAfter === ColorTypes.whiteCold || colorTypeDurationValueAfter === ColorTypes.whiteWarm) && (
              <WhitePicker
                className={classes.slider}
                color={currentColor}
                colorTemperature={colorTypeDurationValueAfter === ColorTypes.whiteCold ? 'cold' : 'warm'}
                changeColor={this.onChangeColor}
              />
            )}
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onNextClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectColorActionHueAfterDuration.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  colorTypeDurationValueAfter: PropTypes.string.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
}

SelectColorActionHueAfterDuration.defaultProps = {

}

SelectColorActionHueAfterDuration.displayName = 'SelectColorActionHue'

export default SelectColorActionHueAfterDuration
