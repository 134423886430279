const styles = (theme) => ({
  webView: {
    background: theme.webView.background,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    background: '#141414',
    position: 'relative',
    minHeight: 'calc(100vh - 56px)',
    borderRadius: 0,
  },
  titleWrapper: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    borderBottom: '1px solid #929292',
  },
  title: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  main: {
    width: '100%',
    height: '100%',
    padding: '25px 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 106px)',
  },
  offsetHandlerWrapper: {
    backgroundColor: '#141414',
  },
  subTitleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: 30,
    flexWrap: 'wrap',
  },
  resetButton: {
    position: 'absolute',
    right: 0,
    top: -25,
    fontSize: '18px',
  },
  subTitle: {
    fontSize: '18px',
    textAlign: 'center',
  },
  subTitleValue: {
    fontSize: '18px',
    opacity: '70%',
  },
  slider: {
    fontSize: '18px',
    margin: '50px 0',
  },
  sliderMobile: {
    '& .MuiSlider-markLabel[data-index="0"]': {
      marginLeft: '10%',
    },
    '& .MuiSlider-markLabel[data-index="2"]': {
      marginLeft: '-10%',
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
  },
  infoText: {
    fontSize: '18px',
  },
  infoInput: {
    fontSize: '18px',
    opacity: '70%',
  },
  nextButton: {
    width: '100%',
    height: 50,
    margin: '0 auto',
    maxWidth: 300,
  },
})

export default styles
