import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Search } from 'icons'
import styles from './FilterByText.style'

class FilterByText extends React.Component {
  createAdorment =() => {
    const {
      classes,
    } = this.props
    return (
      <InputAdornment position="start">
        <Search className={classes.icon} />
      </InputAdornment>
    )
  }

  handleChange = (event) => {
    const {
      setFilterByText,
      clearFilterByText,
    } = this.props
    if (event.target.value === '') {
      clearFilterByText()
    } else {
      setFilterByText(event.target.value)
    }
  }

  render() {
    const { classes, filterByText } = this.props

    return (
      <FormControl variant="standard" className={classes.formControl}>
        <TextField
          variant="standard"
          value={filterByText}
          label={I18n.t('my_events.filter_by_text')}
          className={classes.textField}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: this.createAdorment(),
          }}
        />
      </FormControl>
    )
  }
}

FilterByText.propTypes = {
  classes: PropTypes.object.isRequired,
  setFilterByText: PropTypes.func.isRequired,
  clearFilterByText: PropTypes.func.isRequired,
  filterByText: PropTypes.string.isRequired,
}

export default withStyles(styles)(FilterByText)
