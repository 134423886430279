import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_NOTIFICATION_SENSOR_STATE_SELECTED,
  SELECT_NOTIFICATION_SENSOR_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectEventType extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSensorStateMode,
      notificationSensors,
    } = this.props
    if (isChangeSensorStateMode) {
      navigate(SELECT_NOTIFICATION_SENSOR_STATE_SELECTED)
    } else if (notificationSensors.length === 1) {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    } else {
      navigate(SELECT_NOTIFICATION_SENSOR_TYPE)
    }
  }

  selectEvent=(event) => {
    const {
      navigate,
      saveSensorNotificationsEventType,
      saveSensorNotificationsEventDescription,
    } = this.props
    saveSensorNotificationsEventType(event.type)
    saveSensorNotificationsEventDescription(event.name)
    navigate(SELECT_NOTIFICATION_SENSOR_STATE_SELECTED)
  }

  renderEventsList=(events) => (events.map((event) => (
    <CardBlock
      key={event.name}
      description={event.name}
      position="middle"
      buttonName={I18n.t('scene_web.add')}
      isCardDisabled={false}
      isButtonDisabled={false}
      onClick={() => {
        this.selectEvent(event)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
      events,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_sensor_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {events.length
            ? this.renderEventsList(events)
            : <Typography className={classes.somethingWrong}>{I18n.t('errors.something_went_wrong')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectEventType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  saveSensorNotificationsEventType: PropTypes.func.isRequired,
  saveSensorNotificationsEventDescription: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isChangeSensorStateMode: PropTypes.bool.isRequired,
  notificationSensors: PropTypes.arrayOf(
    PropTypes.shape({
      notification_name: PropTypes.string.isRequired,
      notification_type: PropTypes.number.isRequired,
    }),
  ),
}

SelectEventType.defaultProps = {

  notificationSensors: [],
}

SelectEventType.displayName = 'SelectEventType'

export default SelectEventType
