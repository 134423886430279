import * as type from '@app/constants/activities/activities'

export const sync = () => ({
  type: type.SYNC_ACTIVITIES,
})

export const set = (activities) => ({
  type: type.SET_ACTIVITIES,
  activities,
})

export const setNewActivity = (activity) => ({
  type: type.SET_NEW_ACTIVITY,
  activity,
})
