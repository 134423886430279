import * as type from '@app/constants/virtualSwitches/virtualSwitches'
import { IVirtualSwitch, IVirtualSwitches } from '@app/@types/virtualSwitches'

export type TVirtualSwitchesAction =
  | { type: '@@virtualSwitches/virtualSwitchesSync' }
  | { type: '@@virtualSwitches/virtualSwitchesSet', virtualSwitches: IVirtualSwitches }
  | { type: '@@virtualSwitches/saveChangedVirtualSwitchData', changedVirtualSwitchData: IVirtualSwitch }
  | { type: '@@virtualSwitches/triggerVirtualSwitch' }
  | { type: '@@virtualSwitches/virtualSwitchChannelOpen' }
  | { type: '@@virtualSwitches/virtualSwitchChannelClose' }
  | { type: '@@virtualSwitches/virtualSwitchChannelSync' }
  | { type: '@@virtualSwitches/virtualSwitchesChannelSyncSent' }
  | { type: '@@virtualSwitches/unsubscribeVirtualSwitchChannel' }
  | { type: '@@virtualSwitches/startEnablingProgress', number: number }
  | { type: '@@virtualSwitches/stopEnablingProgress', number: number }
  | { type: '@@virtualSwitches/updateVirtualSwitchByNumber', number: number, enabled: boolean, status: string }

export const syncVirtualSwitches = (): TVirtualSwitchesAction => ({
  type: type.SYNC_VIRTUAL_SWITCHES,
})

export const setVirtualSwitches = (virtualSwitches: IVirtualSwitches): TVirtualSwitchesAction => ({
  type: type.SET_VIRTUAL_SWITCHES,
  virtualSwitches,
})
export const saveChangedVirtualSwitchData = (changedVirtualSwitchData: IVirtualSwitch): TVirtualSwitchesAction => ({
  type: type.SAVE_CHANGED_VIRTUAL_SWITCH_DATA,
  changedVirtualSwitchData,
})

export const triggerVirtualSwitch = (): TVirtualSwitchesAction => ({
  type: type.TRIGGER_VIRTUAL_SWITCH,
})

export const openChannel = (): TVirtualSwitchesAction => ({
  type: type.OPEN_VIRTUAL_SWITCH_CHANNEL,
})

export const closeChannel = (): TVirtualSwitchesAction => ({
  type: type.CLOSE_VIRTUAL_SWITCH_CHANNEL,
})

export const syncVirtualSwitchChannel = (): TVirtualSwitchesAction => ({
  type: type.SYNC_VIRTUAL_SWITCH_CHANNEL,
})

export const syncVirtualSwitchChannelSent = (): TVirtualSwitchesAction => ({
  type: type.SYNC_VIRTUAL_SWITCH_CHANNEL_SENT,
})

export const unsubscribeVirtualSwitchChannel = (): TVirtualSwitchesAction => ({
  type: type.UNSUBSCRIBE_VIRTUAL_SWITCH_CHANNEL,
})

export const startEnablingProgress = (number: number): TVirtualSwitchesAction => ({
  type: type.START_ENABLING_PROGRESS,
  number,
})

export const stopEnablingProgress = (number: number): TVirtualSwitchesAction => ({
  type: type.STOP_ENABLING_PROGRESS,
  number,
})

export const updateVirtualSwitchByNumber = (number: number, enabled: boolean, status: string): TVirtualSwitchesAction => ({
  type: type.UPDATE_VIRTUAL_SWITCH_BY_NUMBER,
  number,
  enabled,
  status,
})
