import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { POST_ECO_MODE_DEVICES_TURN_OFF } from '@app/constants/myEnergy/ecoMode'
import Api from '@app/api/myEnergy/postEcoModeDevicesTurnOff'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const postEcoModeDevicesTurnOffLogic = createLogic({
  type: POST_ECO_MODE_DEVICES_TURN_OFF,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_ECO_MODE_DEVICES_TURN_OFF))
    Api.postEcoModeDevicesTurnOff(action.accessToken)
      .then(() => {
        dispatch(apiRequestDone(POST_ECO_MODE_DEVICES_TURN_OFF))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('postEcoModeDevicesTurnOff failed without error')
        }
      })
      .then(() => done())
  },
})

export default postEcoModeDevicesTurnOffLogic
