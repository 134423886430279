import update from 'immutability-helper'
import * as type from '@app/constants/myEnergy/deviceManager'

const initialState = {
  resetMode: false,
  devicesForReset: [],
  selectedSorting: 'desc',
  sort: 'desc',
  energy_devices: [],
}

const myEnergy = (state = initialState, action) => {
  const anotherSorting = state.selectedSorting === 'desc' ? 'asc' : 'desc'
  switch (action.type) {
  case type.RESET_MODE_ON: {
    return {
      ...state,
      resetMode: true,
    }
  }
  case type.RESET_MODE_OFF: {
    return {
      ...state,
      resetMode: false,
      devicesForReset: [],
    }
  }
  case type.ADD_DEVICE_TO_RESET_ARRAY:
    return update(state, {
      devicesForReset: { $push: [action.device_id] },
    })
  case type.DELETE_DEVICE_FROM_RESET_ARRAY:
    return {
      ...state,
      devicesForReset: state.devicesForReset.filter((item) => item !== action.device_id),
    }
  case type.ADD_ALL_DEVICES_TO_RESET_ARRAY:
    return {
      ...state,
      devicesForReset: state.energy_devices.map((device) => device.id),
    }
  case type.CLEAR_DEVICES_RESET_ARRAY: {
    return {
      ...state,
      devicesForReset: [],
    }
  }
  case type.TOGGLE_SELECTED_SORTING:
    return { ...state, selectedSorting: anotherSorting }

  case type.UPDATE_DEVICE_ENERGIES:
    return update(state, {
      energy_devices: { $set: action.energy_devices },
      sort: { $set: action.sort },
    })

  case type.RESET_DEVICE_MANAGER:
    return initialState
  default:
    return state
  }
}

export default myEnergy
