import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M117.907 135.682H32.0932C30.9357 135.682 30 134.746 30 133.589V10.0932C30 8.93567 30.9357 8 32.0932 8H117.907C119.064 8 120 8.93567 120 10.0932V133.589C120 134.746 119.064 135.682 117.907 135.682ZM34.1864 131.496H115.814V12.1864H34.1864V131.496Z" fill="currentColor"/>
      <path d="M36.2788 141.925C35.1212 141.925 34.1855 140.989 34.1855 139.832V133.585C34.1855 132.428 35.1212 131.492 36.2788 131.492C37.4363 131.492 38.372 132.428 38.372 133.585V139.832C38.372 140.989 37.4363 141.925 36.2788 141.925Z" fill="currentColor"/>
      <path d="M46.7428 141.929H36.2788C35.1212 141.929 34.1855 140.993 34.1855 139.835C34.1855 138.678 35.1212 137.742 36.2788 137.742H46.7428C47.9003 137.742 48.836 138.678 48.836 139.835C48.836 140.993 47.9003 141.929 46.7428 141.929Z" fill="currentColor"/>
      <path d="M46.7441 141.925C45.5865 141.925 44.6509 140.989 44.6509 139.832V133.585C44.6509 132.428 45.5865 131.492 46.7441 131.492C47.9016 131.492 48.8373 132.428 48.8373 133.585V139.832C48.8373 140.989 47.9016 141.925 46.7441 141.925Z" fill="currentColor"/>
      <path d="M103.259 141.925C102.102 141.925 101.166 140.989 101.166 139.832V133.585C101.166 132.428 102.102 131.492 103.259 131.492C104.417 131.492 105.352 132.428 105.352 133.585V139.832C105.352 140.989 104.417 141.925 103.259 141.925Z" fill="currentColor"/>
      <path d="M113.722 141.929H103.258C102.1 141.929 101.165 140.993 101.165 139.835C101.165 138.678 102.1 137.742 103.258 137.742H113.722C114.879 137.742 115.815 138.678 115.815 139.835C115.815 140.993 114.879 141.929 113.722 141.929Z" fill="currentColor"/>
      <path d="M113.723 141.925C112.565 141.925 111.629 140.989 111.629 139.832V133.585C111.629 132.428 112.565 131.492 113.723 131.492C114.88 131.492 115.816 132.428 115.816 133.585V139.832C115.816 140.989 114.88 141.925 113.723 141.925Z" fill="currentColor"/>
      <path d="M117.907 43.5575H32.0932C30.9357 43.5575 30 42.6219 30 41.4643C30 40.3068 30.9357 39.3711 32.0932 39.3711H117.907C119.064 39.3711 120 40.3068 120 41.4643C120 42.6219 119.064 43.5575 117.907 43.5575Z" fill="currentColor"/>
      <path d="M117.907 75.0224H32.0932C30.9357 75.0224 30 74.0867 30 72.9292C30 71.7716 30.9357 70.8359 32.0932 70.8359H117.907C119.064 70.8359 120 71.7716 120 72.9292C120 74.0867 119.064 75.0224 117.907 75.0224Z" fill="currentColor"/>
      <path d="M117.907 106.429H32.0932C30.9357 106.429 30 105.493 30 104.335C30 103.178 30.9357 102.242 32.0932 102.242H117.907C119.064 102.242 120 103.178 120 104.335C120 105.493 119.064 106.429 117.907 106.429Z" fill="currentColor"/>
      <path d="M40.4604 43.6053C39.3029 43.6053 38.3672 42.6696 38.3672 41.512V28.9799C38.3672 27.8224 39.3029 26.8867 40.4604 26.8867C41.618 26.8867 42.5536 27.8224 42.5536 28.9799V41.512C42.5536 42.6696 41.618 43.6053 40.4604 43.6053Z" fill="currentColor"/>
      <path d="M59.3036 37.2802H40.4604C39.3029 37.2802 38.3672 36.3445 38.3672 35.187C38.3672 34.0294 39.3029 33.0938 40.4604 33.0938H59.3036C60.4611 33.0938 61.3968 34.0294 61.3968 35.187C61.3968 36.3445 60.4611 37.2802 59.3036 37.2802Z" fill="currentColor"/>
      <path d="M59.3036 31.0732H40.4604C39.3029 31.0732 38.3672 30.1375 38.3672 28.9799C38.3672 27.8224 39.3029 26.8867 40.4604 26.8867H59.3036C60.4611 26.8867 61.3968 27.8224 61.3968 28.9799C61.3968 30.1375 60.4611 31.0732 59.3036 31.0732Z" fill="currentColor"/>
      <path d="M59.3002 43.6053C58.1427 43.6053 57.207 42.6696 57.207 41.512V28.9799C57.207 27.8224 58.1427 26.8867 59.3002 26.8867C60.4578 26.8867 61.3935 27.8224 61.3935 28.9799V41.512C61.3935 42.6696 60.4578 43.6053 59.3002 43.6053Z" fill="currentColor"/>
      <path d="M84.4062 74.9732C83.2487 74.9732 82.313 74.0375 82.313 72.88V54.062C82.313 52.9044 83.2487 51.9688 84.4062 51.9688C85.5638 51.9688 86.4994 52.9044 86.4994 54.062V72.88C86.4994 74.0375 85.5638 74.9732 84.4062 74.9732Z" fill="currentColor"/>
      <path d="M109.535 68.7177H84.4062C83.2487 68.7177 82.313 67.782 82.313 66.6245C82.313 65.4669 83.2487 64.5312 84.4062 64.5312H109.535C110.693 64.5312 111.629 65.4669 111.629 66.6245C111.629 67.782 110.693 68.7177 109.535 68.7177Z" fill="currentColor"/>
      <path d="M109.535 62.4364H84.4062C83.2487 62.4364 82.313 61.5008 82.313 60.3432C82.313 59.1857 83.2487 58.25 84.4062 58.25H109.535C110.693 58.25 111.629 59.1857 111.629 60.3432C111.629 61.5008 110.693 62.4364 109.535 62.4364Z" fill="currentColor"/>
      <path d="M109.535 56.1552H84.4062C83.2487 56.1552 82.313 55.2195 82.313 54.062C82.313 52.9044 83.2487 51.9688 84.4062 51.9688H109.535C110.693 51.9688 111.629 52.9044 111.629 54.062C111.629 55.2195 110.693 56.1552 109.535 56.1552Z" fill="currentColor"/>
      <path d="M109.536 74.9732C108.379 74.9732 107.443 74.0375 107.443 72.88V54.062C107.443 52.9044 108.379 51.9688 109.536 51.9688C110.694 51.9688 111.629 52.9044 111.629 54.062V72.88C111.629 74.0375 110.694 74.9732 109.536 74.9732Z" fill="currentColor"/>
      <path d="M67.6674 43.5646C66.5099 43.5646 65.5742 42.6289 65.5742 41.4714V22.6596C65.5742 21.5021 66.5099 20.5664 67.6674 20.5664C68.825 20.5664 69.7607 21.5021 69.7607 22.6596V41.4714C69.7607 42.6289 68.825 43.5646 67.6674 43.5646Z" fill="currentColor"/>
      <path d="M78.1 24.7528H67.6674C66.5099 24.7528 65.5742 23.8172 65.5742 22.6596C65.5742 21.5021 66.5099 20.5664 67.6674 20.5664H78.1C79.2576 20.5664 80.1933 21.5021 80.1933 22.6596C80.1933 23.8172 79.2576 24.7528 78.1 24.7528Z" fill="currentColor"/>
      <path d="M78.1005 43.5981C76.943 43.5981 76.0073 42.6624 76.0073 41.5049V22.6596C76.0073 21.5021 76.943 20.5664 78.1005 20.5664C79.2581 20.5664 80.1938 21.5021 80.1938 22.6596V41.5049C80.1938 42.6603 79.2581 43.5981 78.1005 43.5981Z" fill="currentColor"/>
      <path d="M71.8574 74.9529C70.6998 74.9529 69.7642 74.0172 69.7642 72.8597V51.9526C69.7642 50.795 70.6998 49.8594 71.8574 49.8594C73.0149 49.8594 73.9506 50.795 73.9506 51.9526V72.8597C73.9506 74.0172 73.0149 74.9529 71.8574 74.9529Z" fill="currentColor"/>
      <path d="M78.0993 54.0458H71.8574C70.6998 54.0458 69.7642 53.1101 69.7642 51.9526C69.7642 50.795 70.6998 49.8594 71.8574 49.8594H78.0993C79.2569 49.8594 80.1926 50.795 80.1926 51.9526C80.1926 53.1101 79.2569 54.0458 78.0993 54.0458Z" fill="currentColor"/>
      <path d="M78.0991 74.9864C76.9415 74.9864 76.0059 74.0507 76.0059 72.8932V51.9526C76.0059 50.795 76.9415 49.8594 78.0991 49.8594C79.2566 49.8594 80.1923 50.795 80.1923 51.9526V72.8932C80.1923 74.0507 79.2566 74.9864 78.0991 74.9864Z" fill="currentColor"/>
      <path d="M57.2417 74.9482C56.0841 74.9482 55.1484 74.0125 55.1484 72.855V58.2338C55.1484 57.0763 56.0841 56.1406 57.2417 56.1406C58.3992 56.1406 59.3349 57.0763 59.3349 58.2338V72.855C59.3349 74.0125 58.3971 74.9482 57.2417 74.9482Z" fill="currentColor"/>
      <path d="M65.5789 60.3271H57.2417C56.0841 60.3271 55.1484 59.3914 55.1484 58.2338C55.1484 57.0763 56.0841 56.1406 57.2417 56.1406H65.5789C66.7365 56.1406 67.6722 57.0763 67.6722 58.2338C67.6722 59.3914 66.7365 60.3271 65.5789 60.3271Z" fill="currentColor"/>
      <path d="M65.581 74.9817C64.4235 74.9817 63.4878 74.046 63.4878 72.8885V58.2338C63.4878 57.0763 64.4235 56.1406 65.581 56.1406C66.7386 56.1406 67.6742 57.0763 67.6742 58.2338V72.8885C67.6742 74.046 66.7386 74.9817 65.581 74.9817Z" fill="currentColor"/>
      <path d="M40.5307 74.9529C39.3732 74.9529 38.4375 74.0172 38.4375 72.8597V51.9526C38.4375 50.795 39.3732 49.8594 40.5307 49.8594C41.6883 49.8594 42.6239 50.795 42.6239 51.9526V72.8597C42.6239 74.0172 41.6883 74.9529 40.5307 74.9529Z" fill="currentColor"/>
      <path d="M50.9658 54.0458H40.5332C39.3756 54.0458 38.4399 53.1101 38.4399 51.9526C38.4399 50.795 39.3756 49.8594 40.5332 49.8594H50.9658C52.1233 49.8594 53.059 50.795 53.059 51.9526C53.059 53.1101 52.1212 54.0458 50.9658 54.0458Z" fill="currentColor"/>
      <path d="M50.9653 74.9864C49.8077 74.9864 48.8721 74.0507 48.8721 72.8932V51.9526C48.8721 50.795 49.8077 49.8594 50.9653 49.8594C52.1228 49.8594 53.0585 50.795 53.0585 51.9526V72.8932C53.0585 74.0507 52.1207 74.9864 50.9653 74.9864Z" fill="currentColor"/>
      <path d="M90.6928 43.5505C89.5353 43.5505 88.5996 42.6149 88.5996 41.4573V20.5502C88.5996 19.3927 89.5353 18.457 90.6928 18.457C91.8504 18.457 92.786 19.3927 92.786 20.5502V41.4573C92.786 42.6149 91.8504 43.5505 90.6928 43.5505Z" fill="currentColor"/>
      <path d="M96.9348 22.6435H90.6928C89.5353 22.6435 88.5996 21.7078 88.5996 20.5502C88.5996 19.3927 89.5353 18.457 90.6928 18.457H96.9348C98.0923 18.457 99.028 19.3927 99.028 20.5502C99.028 21.7078 98.0923 22.6435 96.9348 22.6435Z" fill="currentColor"/>
      <path d="M96.935 43.584C95.7775 43.584 94.8418 42.6484 94.8418 41.4908V20.5503C94.8418 19.3927 95.7775 18.457 96.935 18.457C98.0926 18.457 99.0282 19.3927 99.0282 20.5503V41.4908C99.0282 42.6463 98.0926 43.584 96.935 43.584Z" fill="currentColor"/>
      <path d="M103.265 43.5826C102.108 43.5826 101.172 42.6469 101.172 41.4893V24.7729C101.172 23.6154 102.108 22.6797 103.265 22.6797C104.423 22.6797 105.358 23.6154 105.358 24.7729V41.4893C105.358 42.6469 104.423 43.5826 103.265 43.5826Z" fill="currentColor"/>
      <path d="M109.507 26.8661H103.265C102.108 26.8661 101.172 25.9305 101.172 24.7729C101.172 23.6154 102.108 22.6797 103.265 22.6797H109.507C110.665 22.6797 111.6 23.6154 111.6 24.7729C111.6 25.9305 110.665 26.8661 109.507 26.8661Z" fill="currentColor"/>
      <path d="M109.506 43.6161C108.349 43.6161 107.413 42.6804 107.413 41.5228V24.7729C107.413 23.6154 108.349 22.6797 109.506 22.6797C110.664 22.6797 111.6 23.6154 111.6 24.7729V41.5228C111.6 42.6783 110.664 43.6161 109.506 43.6161Z" fill="currentColor"/>
      <path d="M84.4111 43.5739C83.2535 43.5739 82.3179 42.6382 82.3179 41.4806V18.4448C82.3179 17.2872 83.2535 16.3516 84.4111 16.3516C85.5686 16.3516 86.5043 17.2872 86.5043 18.4448V41.4806C86.5043 42.6361 85.5686 43.5739 84.4111 43.5739Z" fill="currentColor"/>
      <path d="M40.457 106.371C39.2994 106.371 38.3638 105.435 38.3638 104.278V91.7456C38.3638 90.588 39.2994 89.6523 40.457 89.6523C41.6145 89.6523 42.5502 90.588 42.5502 91.7456V104.278C42.5502 105.435 41.6145 106.371 40.457 106.371Z" fill="currentColor"/>
      <path d="M84.4077 135.719C83.2501 135.719 82.3145 134.783 82.3145 133.625V121.093C82.3145 119.936 83.2501 119 84.4077 119C85.5652 119 86.5009 119.936 86.5009 121.093V133.625C86.5009 134.783 85.5652 135.719 84.4077 135.719Z" fill="currentColor"/>
      <path d="M109.537 129.397H84.4077C83.2501 129.397 82.3145 128.462 82.3145 127.304C82.3145 126.147 83.2501 125.211 84.4077 125.211H109.537C110.694 125.211 111.63 126.147 111.63 127.304C111.63 128.462 110.694 129.397 109.537 129.397Z" fill="currentColor"/>
      <path d="M109.537 123.186H84.4077C83.2501 123.186 82.3145 122.251 82.3145 121.093C82.3145 119.936 83.2501 119 84.4077 119H109.537C110.694 119 111.63 119.936 111.63 121.093C111.63 122.251 110.694 123.186 109.537 123.186Z" fill="currentColor"/>
      <path d="M109.533 135.719C108.375 135.719 107.439 134.783 107.439 133.625V121.093C107.439 119.936 108.375 119 109.533 119C110.69 119 111.626 119.936 111.626 121.093V133.625C111.626 134.783 110.69 135.719 109.533 135.719Z" fill="currentColor"/>
      <path d="M109.506 106.322C108.349 106.322 107.413 105.387 107.413 104.229V85.4174C107.413 84.2599 108.349 83.3242 109.506 83.3242C110.664 83.3242 111.6 84.2599 111.6 85.4174V104.229C111.6 105.387 110.664 106.322 109.506 106.322Z" fill="currentColor"/>
      <path d="M109.504 87.5107H99.0717C97.9142 87.5107 96.9785 86.575 96.9785 85.4174C96.9785 84.2599 97.9142 83.3242 99.0717 83.3242H109.504C110.662 83.3242 111.598 84.2599 111.598 85.4174C111.598 86.575 110.662 87.5107 109.504 87.5107Z" fill="currentColor"/>
      <path d="M99.0717 106.356C97.9142 106.356 96.9785 105.42 96.9785 104.263V85.4174C96.9785 84.2599 97.9142 83.3242 99.0717 83.3242C100.229 83.3242 101.165 84.2599 101.165 85.4174V104.263C101.165 105.418 100.229 106.356 99.0717 106.356Z" fill="currentColor"/>
      <path d="M61.3164 106.322C60.1588 106.322 59.2231 105.386 59.2231 104.228V81.226C59.2231 80.0685 60.1588 79.1328 61.3164 79.1328C62.4739 79.1328 63.4096 80.0685 63.4096 81.226V104.228C63.4096 105.386 62.4739 106.322 61.3164 106.322Z" fill="currentColor"/>
      <path d="M61.3187 83.3192H52.9814C51.8239 83.3192 50.8882 82.3836 50.8882 81.226C50.8882 80.0685 51.8239 79.1328 52.9814 79.1328H61.3187C62.4762 79.1328 63.4119 80.0685 63.4119 81.226C63.4119 82.3836 62.4762 83.3192 61.3187 83.3192Z" fill="currentColor"/>
      <path d="M52.9755 106.355C51.818 106.355 50.8823 105.419 50.8823 104.262V81.226C50.8823 80.0685 51.818 79.1328 52.9755 79.1328C54.1331 79.1328 55.0688 80.0685 55.0688 81.226V104.262C55.0688 105.417 54.1331 106.355 52.9755 106.355Z" fill="currentColor"/>
      <path d="M46.7753 135.674C45.6178 135.674 44.6821 134.738 44.6821 133.581V114.769C44.6821 113.611 45.6178 112.676 46.7753 112.676C47.9329 112.676 48.8686 113.611 48.8686 114.769V133.581C48.8686 134.738 47.9308 135.674 46.7753 135.674Z" fill="currentColor"/>
      <path d="M46.7766 116.862H40.5346C39.3771 116.862 38.4414 115.927 38.4414 114.769C38.4414 113.611 39.3771 112.676 40.5346 112.676H46.7766C47.9341 112.676 48.8698 113.611 48.8698 114.769C48.8698 115.927 47.9321 116.862 46.7766 116.862Z" fill="currentColor"/>
      <path d="M40.5332 135.707C39.3756 135.707 38.4399 134.772 38.4399 133.614V114.769C38.4399 113.611 39.3756 112.676 40.5332 112.676C41.6907 112.676 42.6264 113.611 42.6264 114.769V133.614C42.6264 134.772 41.6907 135.707 40.5332 135.707Z" fill="currentColor"/>
      <path d="M61.3901 135.689C60.2325 135.689 59.2969 134.753 59.2969 133.596V121.07C59.2969 119.912 60.2325 118.977 61.3901 118.977C62.5476 118.977 63.4833 119.912 63.4833 121.07V133.596C63.4833 134.753 62.5476 135.689 61.3901 135.689Z" fill="currentColor"/>
      <path d="M61.3914 123.163H53.0542C51.8966 123.163 50.9609 122.227 50.9609 121.07C50.9609 119.912 51.8966 118.977 53.0542 118.977H61.3914C62.549 118.977 63.4847 119.912 63.4847 121.07C63.4847 122.227 62.549 123.163 61.3914 123.163Z" fill="currentColor"/>
      <path d="M53.0542 135.722C51.8966 135.722 50.9609 134.787 50.9609 133.629V121.07C50.9609 119.912 51.8966 118.977 53.0542 118.977C54.2117 118.977 55.1474 119.912 55.1474 121.07V133.629C55.1474 134.787 54.2096 135.722 53.0542 135.722Z" fill="currentColor"/>
      <path d="M78.103 135.674C76.9454 135.674 76.0098 134.738 76.0098 133.581V114.769C76.0098 113.611 76.9454 112.676 78.103 112.676C79.2605 112.676 80.1962 113.611 80.1962 114.769V133.581C80.1962 134.738 79.2605 135.674 78.103 135.674Z" fill="currentColor"/>
      <path d="M78.1025 116.862H67.6699C66.5123 116.862 65.5767 115.927 65.5767 114.769C65.5767 113.611 66.5123 112.676 67.6699 112.676H78.1025C79.26 112.676 80.1957 113.611 80.1957 114.769C80.1957 115.927 79.26 116.862 78.1025 116.862Z" fill="currentColor"/>
      <path d="M67.6699 135.707C66.5123 135.707 65.5767 134.772 65.5767 133.614V114.769C65.5767 113.611 66.5123 112.676 67.6699 112.676C68.8274 112.676 69.7631 113.611 69.7631 114.769V133.614C69.7631 134.772 68.8274 135.707 67.6699 135.707Z" fill="currentColor"/>
      <path d="M86.479 106.32C85.3214 106.32 84.3857 105.384 84.3857 104.227V83.3198C84.3857 82.1622 85.3214 81.2266 86.479 81.2266C87.6365 81.2266 88.5722 82.1622 88.5722 83.3198V104.227C88.5722 105.384 87.6365 106.32 86.479 106.32Z" fill="currentColor"/>
      <path d="M86.4807 85.413H80.2387C79.0812 85.413 78.1455 84.4773 78.1455 83.3198C78.1455 82.1622 79.0812 81.2266 80.2387 81.2266H86.4807C87.6383 81.2266 88.5739 82.1622 88.5739 83.3198C88.5739 84.4773 87.6383 85.413 86.4807 85.413Z" fill="currentColor"/>
      <path d="M80.2387 106.354C79.0812 106.354 78.1455 105.418 78.1455 104.26V83.3198C78.1455 82.1622 79.0812 81.2266 80.2387 81.2266C81.3963 81.2266 82.3319 82.1622 82.3319 83.3198V104.26C82.3319 105.416 81.3963 106.354 80.2387 106.354Z" fill="currentColor"/>
      <path d="M73.9096 106.336C72.7521 106.336 71.8164 105.401 71.8164 104.243V87.5268C71.8164 86.3693 72.7521 85.4336 73.9096 85.4336C75.0672 85.4336 76.0028 86.3693 76.0028 87.5268V104.243C76.0028 105.401 75.0672 106.336 73.9096 106.336Z" fill="currentColor"/>
      <path d="M73.9099 89.62H67.6679C66.5104 89.62 65.5747 88.6844 65.5747 87.5268C65.5747 86.3693 66.5104 85.4336 67.6679 85.4336H73.9099C75.0674 85.4336 76.0031 86.3693 76.0031 87.5268C76.0031 88.6844 75.0674 89.62 73.9099 89.62Z" fill="currentColor"/>
      <path d="M67.6679 106.37C66.5104 106.37 65.5747 105.434 65.5747 104.277V87.5268C65.5747 86.3693 66.5104 85.4336 67.6679 85.4336C68.8255 85.4336 69.7611 86.3693 69.7611 87.5268V104.277C69.7611 105.432 68.8255 106.37 67.6679 106.37Z" fill="currentColor"/>
      <path d="M92.7631 106.355C91.6056 106.355 90.6699 105.419 90.6699 104.262V81.226C90.6699 80.0685 91.6056 79.1328 92.7631 79.1328C93.9207 79.1328 94.8564 80.0685 94.8564 81.226V104.262C94.8564 105.417 93.9207 106.355 92.7631 106.355Z" fill="currentColor"/>
      <path d="M46.7366 93.8388H40.457C39.2994 93.8388 38.3638 92.9031 38.3638 91.7456C38.3638 90.588 39.2994 89.6523 40.457 89.6523H46.7366C47.8942 89.6523 48.8299 90.588 48.8299 91.7456C48.8299 92.9031 47.8942 93.8388 46.7366 93.8388Z" fill="currentColor"/>
      <path d="M46.7382 106.446C45.5807 106.446 44.645 105.511 44.645 104.353V91.7456C44.645 90.588 45.5807 89.6523 46.7382 89.6523C47.8958 89.6523 48.8315 90.588 48.8315 91.7456V104.353C48.8315 105.511 47.8958 106.446 46.7382 106.446Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Bookcase')

export default icon
