import PO from './programOptions'

const securityRulesNumbers = [
  PO.ENTRY_DELAY_1300,
  PO.EXIT_DELAY_1301,
  PO.ABORT_TIME_1308,
  PO.MEDICAL_ABORT_TIME_1309,
  PO.SENSITIVITY_MEZZO_UNIT_TAMPER_1335,
  PO.SUPERVISION_TIME_1302,
  PO.LOCK_OUT_1303,
]

export default securityRulesNumbers
