import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import { actionTimersValues } from '@app/lib/Scenes/actionTimersValues'
import { CREATE_ACTION_SELECTED, EDIT_ACTION, SELECT_ACTION_TIMER } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectTimerValue extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeActionStateMode,
      isEditActionMode,
      actions,
      initialAction,
    } = this.props
    if (isChangeActionStateMode) {
      if (isEditActionMode) {
        restoreEditedActionInfo(actions, initialAction)
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      navigate(SELECT_ACTION_TIMER)
    }
  }

  selectValue=(value) => {
    const {
      saveActionStateDescription,
      saveActionType,
      isEditActionMode,
      actionDescription,
      saveActionDeviceStateInfo,
      navigate,
    } = this.props
    saveActionStateDescription(value.description)
    saveActionType(value.type)
    const deviceStateInfo = getActionDeviceStateInfo(value.type, '', actionDescription, '', 'none', '')
    saveActionDeviceStateInfo(deviceStateInfo)
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_condition_web.select_timer_value')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {actionTimersValues.map((value) => (
            <CardBlock
              key={value.title}
              description={I18n.t(`scene_action_web.${value.description}`)}
              position={value.position}
              buttonName={I18n.t('scene_web.add')}
              isCardDisabled={value.isCardDisabled}
              isButtonDisabled={value.isButtonDisabled}
              onClick={() => {
                this.selectValue(value)
              }}
            />
          ))}
        </Wrapper>
      </Paper>
    )
  }
}

SelectTimerValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  saveActionType: PropTypes.func.isRequired,
  saveActionDeviceStateInfo: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  actionDescription: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectTimerValue.displayName = 'SelectTimerValueIOS'

export default SelectTimerValue
