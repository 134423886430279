import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 54,
    background: theme.backgrounds.navigation,
    padding: '0 3%',
    zIndex: 2,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  title: {
    color: '#fff',
    fontSize: '22px',
    lineHeight: '26px',
  },
  titleCentered: {
    marginLeft: 'calc(50% - 140px)',
  },
  withoutBackButton: {
    paddingLeft: 20,
  },
  centeredWithoutBackButton: {
    paddingLeft: 60,
  },
  button: {
    fontSize: '18px',
    marginRight: 34,
    color: '#fff',
  },
  buttonMobile: {
    marginRight: 18,
  },
  buttonWithCenteredTitleMobile: {
    marginRight: 12,
  },
  submit: {
    textTransform: 'none',
  },
}))

export default useStyles
