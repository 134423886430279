import * as ApiRequestAction from '@app/actions/apiRequest'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as type from '../constants/apiRequest'

type ApiRequestActions = ReturnType<InferValueTypes<typeof ApiRequestAction>>

export interface IApiRequestState {
  inProgress: boolean,
  errorMessage: string,
}

const initialState = {
  inProgress: false,
  errorMessage: '',
}

export default function apiRequest(state: IApiRequestState = initialState, action: ApiRequestActions): IApiRequestState {
  switch (action.type) {
  case type.API_REQUEST_SENT:
    return { ...state, errorMessage: '', inProgress: true }
  case type.API_REQUEST_DONE:
    return { ...state, errorMessage: '', inProgress: false }
  case type.API_REQUEST_FAILED:
    return { ...state, errorMessage: action.message, inProgress: false }

  case type.API_REQUEST_CLEAR:
    return initialState

  default:
    return state
  }
}
