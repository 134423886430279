import { VALIDATION_REG_EXP_SECRET_CODE } from '@app/lib/Users/userEditvalidationRegExps'
import { mezzoDefaultPassword } from '@app/lib/Users/secretCode'

export const validate = (values) => {
  const errors = {}
  const oldCode = 'old_code'
  const newCodeFirst = 'new_code_first'
  const newCodeSecond = 'new_code_second'

  const requiredFields = [oldCode, newCodeFirst, newCodeSecond]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })

  if (values[newCodeFirst] && !VALIDATION_REG_EXP_SECRET_CODE.test(values[newCodeFirst])) {
    errors[newCodeFirst] = I18n.t('my_users.user_code_validation')
  }
  if (values[newCodeSecond] && !VALIDATION_REG_EXP_SECRET_CODE.test(values[newCodeSecond])) {
    errors[newCodeSecond] = I18n.t('my_users.user_code_validation')
  }
  if (values[newCodeFirst] !== values[newCodeSecond]) {
    errors[newCodeSecond] = I18n.t('my_users.user_codes_match')
  }
  if (values[newCodeFirst] === mezzoDefaultPassword) {
    errors[newCodeFirst] = I18n.t('my_users.user_code_equal_default')
  }
  if (values[newCodeSecond] === mezzoDefaultPassword) {
    errors[newCodeSecond] = I18n.t('my_users.user_code_equal_default')
  }
  return errors
}
