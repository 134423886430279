import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { ADD_NEW_TWR_DEVICE } from '@app/constants/myDevices/twrDevices'
import { syncTwrChannel } from '@app/actions/myDevices/channels/twr/twrChannel'
import { setNewTwrDevice } from '@app/actions/myDevices/twrDevices'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const postNewTwrDeviceLogic = createLogic({
  type: ADD_NEW_TWR_DEVICE,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(ADD_NEW_TWR_DEVICE))
    try {
      const newDevice = await Api.postNewTwrDevice(action.accessToken, action.txid)
      dispatch(setNewTwrDevice(newDevice.data.twr_device))
      dispatch(apiRequestDone(ADD_NEW_TWR_DEVICE))
      dispatch(syncTwrChannel(newDevice.data.twr_device))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('ADD_NEW_TWR_DEVICE failed without error')
      }
    }
    done()
  },
})

export default postNewTwrDeviceLogic
