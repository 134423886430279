import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M26 0.436983V21.5628C26 21.8043 25.7657 22 25.4766 22H0.523446C0.234295 22 0 21.8043 0 21.5628L0 8.9387C0 8.69721 0.234295 8.50154 0.523446 8.50154H10.6831L10.6831 0.437159C10.6831 0.195672 10.9174 -7.91971e-07 11.2066 -7.91971e-07H25.4763C25.7655 -0.000175656 26 0.195496 26 0.436983ZM24.9531 8.52025V0.874144H11.73L11.73 8.52025L24.9531 8.52025ZM24.9531 16.8948V9.3944H17.9414V16.8948H24.9531ZM24.9531 21.1257V17.7693H17.9414V21.1257H24.9531ZM16.8945 17.332V9.3944L11.2066 9.3944C11.1536 9.3944 11.1025 9.38775 11.0541 9.37569H5.5881L5.5881 21.1257H16.8945V17.332ZM4.54121 14.8229L4.54121 9.37569H1.04689L1.04689 14.8229H4.54121ZM4.54121 21.1257V15.6974H1.04689L1.04689 21.1257H4.54121Z" />
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'RoomsStatsIcon')

export default icon
