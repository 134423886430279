export const mezzoChimesState = [
  {
    title: null,
    description: 'mezzo_doorbell_1',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 67,
  },
  {
    title: null,
    description: 'mezzo_doorbell_2',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 68,
  },
  {
    title: null,
    description: 'mezzo_long_beep',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 70,
  },
  {
    title: null,
    description: 'mezzo_short_beep',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 71,
  },
  {
    title: null,
    description: 'mezzo_chime',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 69,
  },
  {
    title: null,
    description: 'mezzo_chime_2_short',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 80,
  },

  {
    title: null,
    description: 'mezzo_chime_2_long',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 81,
  },
  {
    title: null,
    description: 'mezzo_chime_3',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 82,
  },
  {
    title: null,
    description: 'mezzo_knock',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 83,
  },
  {
    title: null,
    description: 'mezzo_notification',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 84,
  },
  {
    title: null,
    description: 'mezzo_siren_short',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 85,
  },
  {
    title: null,
    description: 'mezzo_siren_long',
    position: 'middle',
    buttonName: 'add',
    isCardDisabled: false,
    isButtonDisabled: false,
    stateType: 86,
  },
]
