import { createLogic } from 'redux-logic'
import { SET_NOTIFICATIONS_SETTING } from '@app/constants/notifications/notificationsSettings'
import { update } from '@app/actions/notifications/notificationsSettings'
import Api from '@app/api/notifications/updateNotificationsSettings'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import handleErrors from '@app/helpers/handleErrors'
import { filterNotificationSettings } from '../../helpers/notifications/filterNotificationSetiings'

const submitNotificationsSettingsLogic = createLogic({
  type: SET_NOTIFICATIONS_SETTING,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    const { unit: { isNCentral } } = getState()
    next({
      ...action,
      token,
      isNCentral,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_NOTIFICATIONS_SETTING))
    const filteredNotifications = filterNotificationSettings(action.notificationSettings, action.isNCentral)
    Api.updateNotificationsSettings(action.token, filteredNotifications)
      .then(() => {
        dispatch(update(action.notificationSettings))
        dispatch(apiRequestDone(SET_NOTIFICATIONS_SETTING))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('updateNotificationsSettings failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitNotificationsSettingsLogic
