import React from 'react'
import createSvgIcon from './../../helper'

const fragment = (
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -9 512 512">
      <path color="currentColor" d="M7.5 364.832a7.5 7.5 0 0 0 7.5-7.5V138.891h443.129v82.441a7.5 7.5 0 0 0 7.5 7.5c4.144 0 7.5-3.355 7.5-7.5V73.109c0-25.144-20.457-45.601-45.598-45.601h-19.668v-9.29C407.863 8.173 399.691 0 389.645 0H375.71c-10.043 0-18.215 8.172-18.215 18.219v9.289h-49.742v-9.29C307.754 8.173 299.582 0 289.535 0h-13.933c-10.043 0-18.215 8.172-18.215 18.219v9.289H207.64v-9.29C207.64 8.173 199.469 0 189.42 0h-13.929c-10.047 0-18.219 8.172-18.219 18.219v9.289h-49.742v-9.29C107.531 8.173 99.36 0 89.313 0h-13.93C65.336 0 57.164 8.172 57.164 18.219v9.289H45.602C20.457 27.508 0 47.965 0 73.109v284.223a7.5 7.5 0 0 0 7.5 7.5zM372.496 18.219A3.224 3.224 0 0 1 375.715 15h13.933a3.22 3.22 0 0 1 3.215 3.219v36.437a3.22 3.22 0 0 1-3.215 3.219h-13.933a3.222 3.222 0 0 1-3.219-3.219zm-100.11 0A3.22 3.22 0 0 1 275.603 15h13.933a3.224 3.224 0 0 1 3.219 3.219v36.437a3.224 3.224 0 0 1-3.219 3.219h-13.933a3.218 3.218 0 0 1-3.215-3.219zm-100.113 0a3.224 3.224 0 0 1 3.22-3.219h13.933a3.224 3.224 0 0 1 3.219 3.219v36.437a3.224 3.224 0 0 1-3.22 3.219h-13.933a3.222 3.222 0 0 1-3.219-3.219zm-100.109 0A3.224 3.224 0 0 1 75.383 15h13.933a3.22 3.22 0 0 1 3.215 3.219v36.437a3.22 3.22 0 0 1-3.215 3.219H75.383a3.222 3.222 0 0 1-3.219-3.219zM45.602 42.508h11.562v12.148c0 10.047 8.172 18.219 18.219 18.219h13.933c10.043 0 18.215-8.172 18.215-18.219V42.508h49.746v12.148c0 10.047 8.172 18.219 18.215 18.219h13.934c10.047 0 18.219-8.172 18.219-18.219V42.508h49.742v12.148c0 10.047 8.172 18.219 18.218 18.219h13.934c10.043 0 18.215-8.172 18.215-18.219V42.508h49.742v12.148c0 10.047 8.172 18.219 18.219 18.219h13.933c10.043 0 18.215-8.172 18.215-18.219V42.508h19.668c16.875 0 30.602 13.726 30.602 30.601v50.782H15V73.109c0-16.875 13.727-30.601 30.602-30.601zm0 0M84.91 434.582H19.512A4.516 4.516 0 0 1 15 430.07v-38.558a7.5 7.5 0 1 0-15 0v38.558c0 10.758 8.754 19.512 19.512 19.512H84.91a7.5 7.5 0 1 0 0-15zm0 0"/>
      <path color="currentColor" d="M84.66 160.762H58.59a7.5 7.5 0 1 0 0 15h26.07a7.5 7.5 0 1 0 0-15zm0 0M138.707 175.762h26.07a7.5 7.5 0 1 0 0-15h-26.07a7.5 7.5 0 1 0 0 15zm0 0M218.824 175.762h26.067a7.5 7.5 0 1 0 0-15h-26.067a7.5 7.5 0 0 0-7.5 7.5c0 4.144 3.356 7.5 7.5 7.5zm0 0M298.938 175.762h26.07a7.5 7.5 0 1 0 0-15h-26.07a7.5 7.5 0 1 0 0 15zm0 0M405.121 160.762h-26.066a7.5 7.5 0 0 0-7.5 7.5c0 4.144 3.355 7.5 7.5 7.5h26.066a7.5 7.5 0 1 0 0-15zm0 0M84.66 198.477H58.59a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h26.07a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zm0 0M138.707 213.477h26.07a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-26.07a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5zm0 0M218.824 213.477h26.067a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-26.067a7.497 7.497 0 0 0-7.5 7.5c0 4.144 3.356 7.5 7.5 7.5zm0 0M298.938 213.477h26.07a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-26.07a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5zm0 0M405.121 198.477h-26.066a7.497 7.497 0 0 0-7.5 7.5c0 4.144 3.355 7.5 7.5 7.5h26.066a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zm0 0M138.89 360.715l38.563 38.562c5.578 5.578 12.992 8.649 20.883 8.649 7.89 0 15.309-3.07 20.883-8.649l105.605-105.605c11.516-11.516 11.516-30.254 0-41.77s-30.254-11.515-41.77 0l-84.718 84.72-17.68-17.677c-11.511-11.515-30.25-11.515-41.77 0-11.511 11.516-11.511 30.254.005 41.77zm10.606-31.164a14.491 14.491 0 0 1 10.277-4.25c3.723 0 7.446 1.418 10.278 4.25l22.98 22.98a7.497 7.497 0 0 0 10.606 0l90.023-90.02c5.672-5.667 14.89-5.667 20.555-.003 5.668 5.668 5.668 14.89 0 20.558L208.613 388.668a14.437 14.437 0 0 1-10.277 4.258 14.437 14.437 0 0 1-10.277-4.258l-38.563-38.563c-5.668-5.668-5.668-14.886 0-20.554zm0 0"/>
      <path color="currentColor" d="M473.129 315.36v-59.848c0-4.145-3.356-7.5-7.5-7.5a7.5 7.5 0 0 0-7.5 7.5v50.281c-13.676-7.09-29.195-11.102-45.633-11.102-54.867 0-99.504 44.637-99.504 99.504 0 14.371 3.067 28.035 8.57 40.387H119.086a7.5 7.5 0 1 0 0 15H329.87c17.879 26.59 48.242 44.117 82.625 44.117 54.867 0 99.504-44.637 99.504-99.504 0-32.062-15.25-60.629-38.871-78.836zM412.496 478.7c-46.598 0-84.504-37.907-84.504-84.505 0-46.597 37.91-84.504 84.504-84.504S497 347.598 497 394.195c0 46.598-37.91 84.504-84.504 84.504zm0 0"/>
      <path color="currentColor" d="M412.496 320.527c-40.621 0-73.668 33.051-73.668 73.668 0 40.621 33.047 73.668 73.668 73.668s73.664-33.047 73.664-73.668c0-40.617-33.047-73.668-73.664-73.668zm6.715 131.938a7.48 7.48 0 0 0 .785-3.324v-9.137c0-4.14-3.36-7.5-7.5-7.5s-7.5 3.36-7.5 7.5v9.137c0 1.195.285 2.32.785 3.324-26.992-3.09-48.465-24.563-51.554-51.555 1 .5 2.128.785 3.324.785h9.133c4.144 0 7.5-3.355 7.5-7.5 0-4.144-3.356-7.5-7.5-7.5h-9.133c-1.196 0-2.324.29-3.324.785 3.09-26.992 24.562-48.464 51.554-51.554a7.427 7.427 0 0 0-.785 3.324v9.137a7.5 7.5 0 1 0 15 0v-9.137a7.48 7.48 0 0 0-.785-3.324c26.992 3.09 48.465 24.562 51.555 51.554a7.48 7.48 0 0 0-3.325-.785h-9.136a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h9.136c1.196 0 2.32-.285 3.325-.785-3.094 26.992-24.563 48.465-51.555 51.555zm0 0"/>
      <path color="currentColor" d="M429.027 386.695h-9.03v-7.691a7.5 7.5 0 1 0-15 0v15.191c0 4.145 3.355 7.5 7.5 7.5h16.53a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zm0 0"/>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'TimeMobile', '0 0 80 80', {fontSize: 35})

export default icon
