import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.05 18.25C43.783 18.25 18.25 43.8214 18.25 75.1309C18.25 106.443 43.7859 131.85 75.05 131.85C106.317 131.85 131.85 106.279 131.85 74.9691C131.85 43.6569 106.314 18.25 75.05 18.25ZM75.05 127.115C46.3903 127.115 22.9805 103.673 22.9805 74.9691C22.9805 46.2684 46.3873 22.9852 75.05 22.9852C103.71 22.9852 127.119 46.4272 127.119 75.1309C127.119 103.832 103.713 127.115 75.05 127.115ZM75.05 73.2221C74.4529 73.2221 73.9638 73.5228 73.6515 73.9748C73.3527 74.4073 73.2221 74.9595 73.2221 75.5322V96.1255C73.2221 96.6982 73.3527 97.2504 73.6515 97.6829C73.9638 98.1349 74.4529 98.4356 75.05 98.4356C75.6471 98.4356 76.1362 98.1349 76.4485 97.6829C76.7473 97.2504 76.8779 96.6982 76.8779 96.1255V75.5322C76.8779 74.9595 76.7473 74.4073 76.4485 73.9748C76.1362 73.5228 75.6471 73.2221 75.05 73.2221ZM65.442 49.1532L51.2482 64.2341C50.366 65.1715 50.366 66.5949 51.2482 67.5324C51.6997 68.012 52.3573 68.2548 52.8627 68.2548C53.3682 68.2548 54.0258 68.012 54.4772 67.5324L68.671 52.4515C69.5533 51.5141 69.5533 50.0907 68.671 49.1532C67.7644 48.1899 66.3486 48.1899 65.442 49.1532ZM84.658 49.1532C83.7514 48.19 82.3356 48.19 81.429 49.1532C80.5467 50.0907 80.5467 51.5141 81.429 52.4515L95.6228 67.5324C96.0742 68.012 96.7318 68.2548 97.2373 68.2548C97.7427 68.2548 98.4003 68.012 98.8517 67.5324C99.734 66.5949 99.734 65.1715 98.8517 64.2341L84.658 49.1532Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'PowerPoint')

export default icon
