import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { DELETE_ROOM } from '@app/constants/rooms/rooms'
import Api from '@app/api/rooms'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { setDeleteRoom } from '@app/actions/rooms/rooms'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const deleteRoom = createLogic({
  type: DELETE_ROOM,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(DELETE_ROOM))
    Api.deleteRoom(AccessToken, action.id)
      .then((response) => {
        dispatch(setDeleteRoom(response.data.room))
        dispatch(apiRequestDone(DELETE_ROOM))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('deleteRoom failed without error')
        }
      })
      .then(() => done())
  },
})

export default deleteRoom
