import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { PATCH_SECURITY_OPTION } from '@app/constants/myDevices/deviceSettings/securityOption'
import { apiRequestSent, apiRequestFailed, apiRequestDone } from '@app/actions/apiRequest'
import Api from '@app/api/myDevices/deviceSettings/securityOption'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const patchSecurityOptionLogic = createLogic({
  type: PATCH_SECURITY_OPTION,
  latest: true,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      token,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(PATCH_SECURITY_OPTION))
    try {
      await Api.patchSecurityOption(action.token, action.id, action.data)
      dispatch(apiRequestDone(PATCH_SECURITY_OPTION))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('PATCH_SECURITY_OPTION failed without error')
      }
    }
    done()
  },
})

export default patchSecurityOptionLogic
