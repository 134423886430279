import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import {
  clearFilterByText, clearFilterByType, clearFilterFromDate, clearFilterToDate, sync,
} from '@app/actions/events'
import { updateAccessToken } from '@app/actions/secrets'
import { updateCurrentUser } from '@app/actions/users/currentUser'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh,
} from '@app/hocs/index'
import Events from '@app/components/Events/Events'

let enhance = withStyles(Events)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')
  const { events: { total, events: eventsData, countPages } } = store.getState()
  return {
    isMobile,
    path,
    isWebView,
    eventsData,
    total,
    countPages,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  clearFilterFromDate,
  clearFilterByType,
  clearFilterToDate,
  clearFilterByText,
  refresh: sync,
  tryAgain: sync,
  updateToken: updateAccessToken,
  updateUser: updateCurrentUser,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withRefresh(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const EventsEnhanced = enhance

export default EventsEnhanced
