import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/billing_setting`

const fetchBillingSetting = (accessToken) => Axios.get(ENDPOINT, {
  headers: {
    'Access-Token': accessToken,
  },
})

export default {
  fetchBillingSetting,
}
