import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.41245L1.41245 0L26.9614 25.5489L25.5489 26.9614L0 1.41245Z" fill="currentColor"/>
      <path d="M26.0141 8.76023V7.88012L22 10.0702L17.9859 7.88012V8.76023L22 10.9298L26.0141 8.76023ZM26.0141 7C26.2801 7 26.507 7.08869 26.6948 7.26608C26.8983 7.44347 27 7.64815 27 7.88012V13.1199C27 13.3519 26.8983 13.5565 26.6948 13.7339C26.507 13.9113 26.2801 14 26.0141 14H17.9859C17.7199 14 17.4851 13.9113 17.2817 13.7339C17.0939 13.5565 17 13.3519 17 13.1199V7.88012C17 7.64815 17.0939 7.44347 17.2817 7.26608C17.4851 7.08869 17.7199 7 17.9859 7H26.0141Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 6.41235V19.0312C7 19.5625 7.1875 20.0312 7.5625 20.4375C7.96875 20.8438 8.4375 21.0469 8.96875 21.0469H9V23H8C7.44772 23 7 23.4477 7 24C7 24.5523 7.44772 25 8 25H20C20.5523 25 21 24.5523 21 24C21 23.4477 20.5523 23 20 23H19V21.0469C19.5312 21.0469 19.9844 20.8438 20.3594 20.4375C20.4761 20.3207 20.5761 20.1988 20.6593 20.0717L19 18.4124V19.0312H8.96875V8.3811L7 6.41235ZM13 9.58745V2.01562H8.96875V5.5562L7 3.58745V2.01562C7 1.48438 7.1875 1.01562 7.5625 0.609375C7.96875 0.203125 8.4375 0 8.96875 0H19C19.5312 0 19.9844 0.203125 20.3594 0.609375C20.7656 1.01562 20.9688 1.48438 20.9688 2.01562V6H19V2.01562H15V11.5874L13 9.58745ZM19 15.5874L20.9688 17.5562V15H19V15.5874ZM11 23V21.0469H17V23H11Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'UnableSendReportIcon')

export default icon
