const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
    height: 535,
  },
  rootDesktop: {
    height: 610,
  },
  rootWebView: {
    marginTop: -40,
  },
  title: {
    textAlign: 'center',
    borderBottom: '1px solid #52525A',
    paddingBottom: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
  },
  icon: {
    fontSize: '50px',
    margin: '0 auto',
  },
  iconDesktop: {
    fontSize: '72px',
    marginRight: 24,
  },
  iconPeriodStats: {
    fontSize: '120px',
    margin: 0,
  },
  statsList: {
    padding: 0,
  },
  listItem: {
    padding: '15px 25px ',
    minWidth: 290,
  },
  info: {
    paddingLeft: 0,
    paddingRight: 8,
  },
  infoTime: {
    fontSize: '14px',
  },
  infoTimeDesktop: {
    fontSize: '18px',
  },
  infoNumbers: {
    fontSize: '14px',
  },
  infoNumbersDesktop: {
    fontSize: '18px',
  },
  infoStat: {
    fontSize: '14px',
  },
  infoStatDesktop: {
    fontSize: '18px',
  },
  statsNotAvailable: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 0 10px 0',
  },
  text: {
    fontSize: '20px',
    lineHeight: '22px',
    textAlign: 'center',
    padding: '0 40px',
    width: 305,
    marginTop: 40,
    marginBottom: 60,
  },
  resetButtonWrapper: {
    width: '100%',
  },
  resetPeriodsButton: {
    color: '#d50000',
    marginRight: 'auto',
    marginLeft: 25,
    textTransform: 'none',
    textDecoration: 'underline',

  },
})

export default styles
