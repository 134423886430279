import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import Api from '@app/api/wizard/warningLink'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { SEND_ACCOUNT_VERIFICATION_LINK } from '../../constants/wizard/wizard'

const sendAccountVerificationLink = createLogic({
  type: SEND_ACCOUNT_VERIFICATION_LINK,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token } } } = getState()
    next({
      ...action,
      token,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SEND_ACCOUNT_VERIFICATION_LINK))
    try {
      await Api.warningLink(action.token)
      dispatch(apiRequestDone(SEND_ACCOUNT_VERIFICATION_LINK))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('sendAccountVerificationLink failed without error')
      }
    } finally {
      done()
    }
  },
})

export default sendAccountVerificationLink
