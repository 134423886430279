import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  root: {
    padding: '40px 70px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rootMobile: {
    padding: '20px',
  },
  productDetailCard: {
    display: 'flex',
    width: '100%',
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: 20,
    marginBottom: 60,
    flexWrap: 'wrap',
  },
  productDetailCardMobile: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: 18,
  },
  values: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  imageWrapper: {
    marginLeft: 'auto',
  },
  imageWrapperMobile: {
    margin: '24px auto',
  },
  image: {
    maxWidth: 220,
  },
  imagePreloader: {
    width: 220,
    height: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productDescription: {
    width: '100%',
    background: theme.backgrounds.cardBackground,
    borderRadius: '5px',
    padding: 20,
    display: 'flex',
  },
  descriptionTitle: {
    marginRight: 36,
  },
  preloader: {
    width: '100vw',
    height: 'calc(100vh - 269px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
  },
  preloaderTitle: {
    marginBottom: 34,
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    fontWeight: 500,
  },
  progress: {
    maxWidth: 400,
    width: '80%',
  },
  error: {
    display: 'flex',
    height: 'calc(100vh - 189px)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    padding: '0 10%',
  },
  errorText: {
    marginBottom: 20,
    color: 'red',
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    fontWeight: 500,
  },
  '@media (max-width: 640px)': {
    imageWrapper: {
      margin: 0,
    },
    productDetailCard: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      background: theme.backgrounds.cardBackground,
      borderRadius: '5px',
      padding: 20,
      marginBottom: 60,
      flexWrap: 'wrap',
    },
    infoWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
  },
}))

export default useStyles
