import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    marginRight: 20,
    marginTop: 20,
    justifyContent: 'flexStart',
  },
  datePicker: {
    marginLeft: 10,
    marginRight: 10,
    width: 160,
  },
  label: {
    marginLeft: 10,
    fontSize: '0.7rem',
    opacity: 0.7,
  },
  error: {
    position: 'absolute',
    top: 35,
  },
}))

export default useStyles
