import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
  },
  day: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '12px',
    marginLeft: 16,
  },
}))

export default useStyles
