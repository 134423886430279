const styles = () => ({
  input: {
    width: '100%',
    minHeight: 70,
    marginBottom: 10,
  },
  text: {
    color: '#f44336',
    position: 'absolute',
    bottom: -10,
  },
  icon: {
    fontSize: 40,
    position: 'absolute',
    top: 5,
    left: 8,
  },
})

export default styles
