import { createLogic } from 'redux-logic'
import { SYNC_TIMER_CHANNEL, SYNC_TIMER_CHANNEL_CANCEL } from '@app/constants/timers/timers'
import {
  syncTimerChannelSent,
  updateTimer,
} from '@app/actions/timers/timers'
import Api from '@app/api/timers/timerChannel'

const subscribeTimerChannelLogic = createLogic({
  type: SYNC_TIMER_CHANNEL,
  cancelType: SYNC_TIMER_CHANNEL_CANCEL,
  latest: true,
  warnTimeout: 0,

  transform({ getState, action }, next) {
    const { timers: { timers } } = getState()
    next({
      ...action,
      timers,
    })
  },

  process(_, dispatch) {
    Api.timerChannel({
      request: () => {
        dispatch(syncTimerChannelSent())
      },
      response: (data) => {
        store.dispatch(updateTimer(data))
      },
    })
  },
})

export default subscribeTimerChannelLogic
