import * as type from '@app/constants/notifications/notifications'
import * as NotificationsAction from '@app/actions/notifications/notifications'
import { InferValueTypes } from '@app/@types/typesHelper'
import { INotification } from '@app/@types/notifications'

type NotificationsActions = ReturnType<InferValueTypes<typeof NotificationsAction>>

export interface INotificationsState {
  notifications: Array<INotification>,
}

const initialState = {
  notifications: [],
}

export default function notifications(state = initialState, action: NotificationsActions) {
  switch (action.type) {
  case type.UPDATE_NOTIFICATIONS:
    return { ...state, notifications: action.notifications }
  case type.SET_NEW_NOTIFICATION:
    return { ...state, notifications: [action.notification, ...state.notifications] }
  case type.RESET_NOTIFICATION_REDUCER:
    return initialState
  default:
    return state
  }
}
