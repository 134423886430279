import * as type from '@app/constants/users/currentMember'

export const chooseCurrentMember = (user) => ({
  type: type.CHOOSE_CURRENT_MEMBER,
  user,
})

export const updateCurrentMember = (user) => ({
  type: type.UPDATE_CURRENT_MEMBER,
  user,
})

export const sync = () => ({
  type: type.SYNC_CURRENT_MEMBER,
})

export const set = () => ({
  type: type.SET_CURRENT_MEMBER,
})

export const deleteMember = () => ({
  type: type.DELETE_CURRENT_MEMBER,
})

export const resetCurrentMember = () => ({
  type: type.RESET_CURRENT_MEMBER,
})
