import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2317 2317"
      id="vector">
      <g
        id="group"
        transform="translate(64 692)">
        <path
          id="path"
          d="M 1058 1 L 1131 1 L 1131 183 L 1058 183 L 1058 1 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_1"
          d="M 758.5 16 L 761 17.5 L 868 161.5 L 810.5 205 L 701 59.5 L 758.5 16 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_2"
          d="M 1429.5 16 L 1488 59.5 L 1378.5 205 L 1321 161.5 L 1429.5 16 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_3"
          d="M 74 256 L 2114.5 256 L 2115 256.5 L 2115 329 L 2188 329 L 2188 402 L 2115 402 L 2115 546.5 L 2116.5 548 L 2188 548 L 2188 621 L 2115 621 L 2115 693.5 L 2114.5 694 L 74 694 L 74 621 L 1 621 L 1 548 L 72.5 548 L 74 546.5 L 74 402 L 1 402 L 1 329 L 74 329 L 74 256 Z M 146 329 L 146 620 L 148 621 L 292 621 L 292 329 L 146 329 Z M 365 329 L 365 621 L 366 621 L 1824 621 L 1824 330 L 1824 329 L 365 329 Z M 1897 329 L 1897 621 L 2042 621 L 2043 620 L 2043 329 L 1897 329 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_4"
          d="M 438 402 L 765.5 402 L 766 402.5 L 766 475 L 511.5 475 L 511 475.5 L 511 548 L 438 548 L 438 402 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_5"
          d="M 839 402 L 912 402 L 912 475 L 839 475 L 839 402 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_6"
          d="M 809 745 L 812.5 746 L 868 788.5 L 760 934 L 755.5 932 L 701 890.5 L 809 745 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_7"
          d="M 1378.5 745 Q 1380.8 744.3 1380 746.5 L 1488 890.5 L 1433.5 932 L 1429 934 L 1321 788.5 L 1378.5 745 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_8"
          d="M 1058 767 L 1131 767 L 1131 949 L 1058 949 L 1058 767 Z"
          fill="currentColor"
          fillOpacity="0.9960784"
          stroke="currentColor"
          strokeOpacity="0.9960784"
          strokeWidth="1"/>
        <path
          id="path_9"
          d="M 1058.5 0 L 1131 0.5 L 1058.5 1 L 1058.5 0 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_10"
          d="M 758.5 15 L 760 15.5 L 757.5 17 L 758.5 15 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_11"
          d="M 1429.5 15 L 1432 16.5 L 1430.5 16 L 1429.5 15 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_12"
          d="M 754.5 18 L 753.5 20 L 754.5 18 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_13"
          d="M 761.5 18 L 762.5 20 L 761.5 18 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_14"
          d="M 1427.5 18 L 1426.5 20 L 1427.5 18 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_15"
          d="M 1434.5 18 L 1435.5 20 L 1434.5 18 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_16"
          d="M 750.5 21 L 749.5 23 L 750.5 21 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_17"
          d="M 1438.5 21 L 1439.5 23 L 1438.5 21 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_18"
          d="M 764.5 22 L 765.5 24 L 764.5 22 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_19"
          d="M 1424.5 22 L 1423.5 24 L 1424.5 22 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_20"
          d="M 746.5 24 L 745.5 26 L 746.5 24 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_21"
          d="M 1442.5 24 L 1443.5 26 L 1442.5 24 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_22"
          d="M 767.5 26 L 768.5 28 L 767.5 26 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_23"
          d="M 1421.5 26 L 1420.5 28 L 1421.5 26 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_24"
          d="M 742.5 27 L 741.5 29 L 742.5 27 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_25"
          d="M 1446.5 27 L 1447.5 29 L 1446.5 27 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_26"
          d="M 738.5 30 L 737.5 32 L 738.5 30 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_27"
          d="M 770.5 30 L 771.5 32 L 770.5 30 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_28"
          d="M 1418.5 30 L 1417.5 32 L 1418.5 30 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_29"
          d="M 1450.5 30 L 1451.5 32 L 1450.5 30 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_30"
          d="M 734.5 33 L 733.5 35 L 734.5 33 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_31"
          d="M 1454.5 33 L 1455.5 35 L 1454.5 33 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_32"
          d="M 773.5 34 L 774.5 36 L 773.5 34 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_33"
          d="M 1415.5 34 L 1414.5 36 L 1415.5 34 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_34"
          d="M 730.5 36 L 729.5 38 L 730.5 36 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_35"
          d="M 1458.5 36 L 1459.5 38 L 1458.5 36 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_36"
          d="M 776.5 38 L 777.5 40 L 776.5 38 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_37"
          d="M 1412.5 38 L 1411.5 40 L 1412.5 38 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_38"
          d="M 726.5 39 L 725.5 41 L 726.5 39 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_39"
          d="M 1462.5 39 L 1463.5 41 L 1462.5 39 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_40"
          d="M 722.5 42 L 721.5 44 L 722.5 42 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_41"
          d="M 779.5 42 L 780.5 44 L 779.5 42 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_42"
          d="M 1409.5 42 L 1408.5 44 L 1409.5 42 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_43"
          d="M 1466.5 42 L 1467.5 44 L 1466.5 42 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_44"
          d="M 718.5 45 L 717.5 47 L 718.5 45 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_45"
          d="M 1470.5 45 L 1471.5 47 L 1470.5 45 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_46"
          d="M 782.5 46 L 783.5 48 L 782.5 46 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_47"
          d="M 1406.5 46 L 1405.5 48 L 1406.5 46 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_48"
          d="M 714.5 48 L 713.5 50 L 714.5 48 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_49"
          d="M 1474.5 48 L 1475.5 50 L 1474.5 48 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_50"
          d="M 785.5 50 L 786.5 52 L 785.5 50 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_51"
          d="M 1403.5 50 L 1402.5 52 L 1403.5 50 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_52"
          d="M 710.5 51 L 709.5 53 L 710.5 51 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_53"
          d="M 1478.5 51 L 1479.5 53 L 1478.5 51 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_54"
          d="M 706.5 54 L 705.5 56 L 706.5 54 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_55"
          d="M 788.5 54 L 789.5 56 L 788.5 54 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_56"
          d="M 1400.5 54 L 1399.5 56 L 1400.5 54 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_57"
          d="M 1482.5 54 L 1483.5 56 L 1482.5 54 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_58"
          d="M 702.5 57 L 701.5 61 Q 698 59 702.5 57 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_59"
          d="M 1486.5 57 L 1487.5 59 L 1486.5 57 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_60"
          d="M 791.5 58 L 792.5 60 L 791.5 58 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_61"
          d="M 1397.5 58 L 1396.5 60 L 1397.5 58 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_62"
          d="M 1487.5 60 L 1486.5 62 L 1487.5 60 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_63"
          d="M 794.5 62 L 795.5 64 L 794.5 62 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_64"
          d="M 1394.5 62 L 1393.5 64 L 1394.5 62 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_65"
          d="M 703.5 63 L 704.5 65 L 703.5 63 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_66"
          d="M 1484.5 64 L 1483.5 66 L 1484.5 64 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_67"
          d="M 797.5 66 L 798.5 68 L 797.5 66 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_68"
          d="M 1391.5 66 L 1390.5 68 L 1391.5 66 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_69"
          d="M 706.5 67 L 707.5 69 L 706.5 67 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_70"
          d="M 1481.5 68 L 1480.5 70 L 1481.5 68 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_71"
          d="M 800.5 70 L 801.5 72 L 800.5 70 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_72"
          d="M 1388.5 70 L 1387.5 72 L 1388.5 70 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_73"
          d="M 709.5 71 L 710.5 73 L 709.5 71 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_74"
          d="M 1478.5 72 L 1477.5 74 L 1478.5 72 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_75"
          d="M 803.5 74 L 804.5 76 L 803.5 74 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_76"
          d="M 1385.5 74 L 1384.5 76 L 1385.5 74 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_77"
          d="M 712.5 75 L 713.5 77 L 712.5 75 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_78"
          d="M 1475.5 76 L 1474.5 78 L 1475.5 76 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_79"
          d="M 806.5 78 L 807.5 80 L 806.5 78 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_80"
          d="M 1382.5 78 L 1381.5 80 L 1382.5 78 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_81"
          d="M 715.5 79 L 716.5 81 L 715.5 79 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_82"
          d="M 1472.5 80 L 1471.5 82 L 1472.5 80 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_83"
          d="M 809.5 82 L 810.5 84 L 809.5 82 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_84"
          d="M 1379.5 82 L 1378.5 84 L 1379.5 82 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_85"
          d="M 718.5 83 L 719.5 85 L 718.5 83 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_86"
          d="M 1469.5 84 L 1468.5 86 L 1469.5 84 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_87"
          d="M 812.5 86 L 813.5 88 L 812.5 86 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_88"
          d="M 1376.5 86 L 1375.5 88 L 1376.5 86 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_89"
          d="M 721.5 87 L 722.5 89 L 721.5 87 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_90"
          d="M 1466.5 88 L 1465.5 90 L 1466.5 88 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_91"
          d="M 815.5 90 L 816.5 92 L 815.5 90 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_92"
          d="M 1373.5 90 L 1372.5 92 L 1373.5 90 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_93"
          d="M 724.5 91 L 725.5 93 L 724.5 91 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_94"
          d="M 1463.5 92 L 1462.5 94 L 1463.5 92 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_95"
          d="M 818.5 94 L 819.5 96 L 818.5 94 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_96"
          d="M 1370.5 94 L 1369.5 96 L 1370.5 94 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_97"
          d="M 727.5 95 L 728.5 97 L 727.5 95 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_98"
          d="M 1460.5 96 L 1459.5 98 L 1460.5 96 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_99"
          d="M 821.5 98 L 822.5 100 L 821.5 98 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_100"
          d="M 1367.5 98 L 1366.5 100 L 1367.5 98 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_101"
          d="M 730.5 99 L 731.5 101 L 730.5 99 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_102"
          d="M 1457.5 100 L 1456.5 102 L 1457.5 100 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_103"
          d="M 824.5 102 L 825.5 104 L 824.5 102 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_104"
          d="M 1364.5 102 L 1363.5 104 L 1364.5 102 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_105"
          d="M 733.5 103 L 734.5 105 L 733.5 103 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_106"
          d="M 1454.5 104 L 1453.5 106 L 1454.5 104 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_107"
          d="M 827.5 106 L 828.5 108 L 827.5 106 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_108"
          d="M 1361.5 106 L 1360.5 108 L 1361.5 106 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_109"
          d="M 736.5 107 L 737.5 109 L 736.5 107 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_110"
          d="M 1451.5 108 L 1450.5 110 L 1451.5 108 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_111"
          d="M 830.5 110 L 831.5 112 L 830.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_112"
          d="M 1358.5 110 L 1357.5 112 L 1358.5 110 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_113"
          d="M 739.5 111 L 740.5 113 L 739.5 111 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_114"
          d="M 1448.5 112 L 1447.5 114 L 1448.5 112 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_115"
          d="M 833.5 114 L 834.5 116 L 833.5 114 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_116"
          d="M 1355.5 114 L 1354.5 116 L 1355.5 114 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_117"
          d="M 742.5 115 L 743.5 117 L 742.5 115 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_118"
          d="M 1445.5 116 L 1444.5 118 L 1445.5 116 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_119"
          d="M 836.5 118 L 837.5 120 L 836.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_120"
          d="M 1352.5 118 L 1351.5 120 L 1352.5 118 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_121"
          d="M 745.5 119 L 746.5 121 L 745.5 119 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_122"
          d="M 1442.5 120 L 1441.5 122 L 1442.5 120 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_123"
          d="M 839.5 122 L 840.5 124 L 839.5 122 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_124"
          d="M 1349.5 122 L 1348.5 124 L 1349.5 122 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_125"
          d="M 748.5 123 L 749.5 125 L 748.5 123 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_126"
          d="M 1439.5 124 L 1438.5 126 L 1439.5 124 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_127"
          d="M 842.5 126 L 843.5 128 L 842.5 126 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_128"
          d="M 1346.5 126 L 1345.5 128 L 1346.5 126 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_129"
          d="M 751.5 127 L 752.5 129 L 751.5 127 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_130"
          d="M 1436.5 128 L 1435.5 130 L 1436.5 128 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_131"
          d="M 845.5 130 L 846.5 132 L 845.5 130 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_132"
          d="M 1343.5 130 L 1342.5 132 L 1343.5 130 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_133"
          d="M 754.5 131 L 755.5 133 L 754.5 131 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_134"
          d="M 1433.5 132 L 1432.5 134 L 1433.5 132 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_135"
          d="M 848.5 134 L 849.5 136 L 848.5 134 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_136"
          d="M 1340.5 134 L 1339.5 136 L 1340.5 134 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_137"
          d="M 757.5 135 L 758.5 137 L 757.5 135 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_138"
          d="M 1430.5 136 L 1429.5 138 L 1430.5 136 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_139"
          d="M 851.5 138 L 852.5 140 L 851.5 138 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_140"
          d="M 1337.5 138 L 1336.5 140 L 1337.5 138 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_141"
          d="M 760.5 139 L 761.5 141 L 760.5 139 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_142"
          d="M 1427.5 140 L 1426.5 142 L 1427.5 140 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_143"
          d="M 854.5 142 L 855.5 144 L 854.5 142 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_144"
          d="M 1334.5 142 L 1333.5 144 L 1334.5 142 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_145"
          d="M 763.5 143 L 764.5 145 L 763.5 143 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_146"
          d="M 1424.5 144 L 1423.5 146 L 1424.5 144 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_147"
          d="M 857.5 146 L 858.5 148 L 857.5 146 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_148"
          d="M 1331.5 146 L 1330.5 148 L 1331.5 146 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_149"
          d="M 766.5 147 L 767.5 149 L 766.5 147 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_150"
          d="M 1421.5 148 L 1420.5 150 L 1421.5 148 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_151"
          d="M 860.5 150 L 861.5 152 L 860.5 150 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_152"
          d="M 1328.5 150 L 1327.5 152 L 1328.5 150 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_153"
          d="M 769.5 151 L 770.5 153 L 769.5 151 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_154"
          d="M 1418.5 152 L 1417.5 154 L 1418.5 152 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_155"
          d="M 863.5 154 L 864.5 156 L 863.5 154 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_156"
          d="M 1325.5 154 L 1324.5 156 L 1325.5 154 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_157"
          d="M 772.5 155 L 773.5 157 L 772.5 155 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_158"
          d="M 1415.5 156 L 1414.5 158 L 1415.5 156 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_159"
          d="M 866.5 158 L 867.5 160 L 866.5 158 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_160"
          d="M 1322.5 158 L 1321.5 160 L 1322.5 158 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_161"
          d="M 775.5 159 L 776.5 161 L 775.5 159 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_162"
          d="M 1412.5 160 L 1411.5 162 L 1412.5 160 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_163"
          d="M 778.5 163 L 779.5 165 L 778.5 163 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_164"
          d="M 865.5 163 L 864.5 165 L 865.5 163 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_165"
          d="M 1323.5 163 L 1324.5 165 L 1323.5 163 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_166"
          d="M 1409.5 164 L 1408.5 166 L 1409.5 164 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_167"
          d="M 861.5 166 L 860.5 168 L 861.5 166 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_168"
          d="M 1327.5 166 L 1328.5 168 L 1327.5 166 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_169"
          d="M 781.5 167 L 782.5 169 L 781.5 167 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_170"
          d="M 1406.5 168 L 1405.5 170 L 1406.5 168 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_171"
          d="M 857.5 169 L 856.5 171 L 857.5 169 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_172"
          d="M 1331.5 169 L 1332.5 171 L 1331.5 169 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_173"
          d="M 784.5 171 L 785.5 173 L 784.5 171 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_174"
          d="M 853.5 172 L 852.5 174 L 853.5 172 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_175"
          d="M 1335.5 172 L 1336.5 174 L 1335.5 172 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_176"
          d="M 1403.5 172 L 1402.5 174 L 1403.5 172 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_177"
          d="M 787.5 175 L 788.5 177 L 787.5 175 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_178"
          d="M 849.5 175 L 848.5 177 L 849.5 175 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_179"
          d="M 1339.5 175 L 1340.5 177 L 1339.5 175 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_180"
          d="M 1400.5 176 L 1399.5 178 L 1400.5 176 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_181"
          d="M 845.5 178 L 844.5 180 L 845.5 178 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_182"
          d="M 1343.5 178 L 1344.5 180 L 1343.5 178 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_183"
          d="M 790.5 179 L 791.5 181 L 790.5 179 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_184"
          d="M 1397.5 180 L 1396.5 182 L 1397.5 180 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_185"
          d="M 841.5 181 L 840.5 183 L 841.5 181 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_186"
          d="M 1347.5 181 L 1348.5 183 L 1347.5 181 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_187"
          d="M 793.5 183 L 794.5 185 L 793.5 183 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_188"
          d="M 1058.5 183 L 1131 183.5 L 1058.5 184 L 1058.5 183 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_189"
          d="M 837.5 184 L 836.5 186 L 837.5 184 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_190"
          d="M 1351.5 184 L 1352.5 186 L 1351.5 184 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_191"
          d="M 1394.5 184 L 1393.5 186 L 1394.5 184 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_192"
          d="M 796.5 187 L 797.5 189 L 796.5 187 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_193"
          d="M 833.5 187 L 832.5 189 L 833.5 187 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_194"
          d="M 1355.5 187 L 1356.5 189 L 1355.5 187 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_195"
          d="M 1391.5 188 L 1390.5 190 L 1391.5 188 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_196"
          d="M 829.5 190 L 828.5 192 L 829.5 190 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_197"
          d="M 1359.5 190 L 1360.5 192 L 1359.5 190 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_198"
          d="M 799.5 191 L 800.5 193 L 799.5 191 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_199"
          d="M 1388.5 192 L 1387.5 194 L 1388.5 192 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_200"
          d="M 825.5 193 L 824.5 195 L 825.5 193 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_201"
          d="M 1363.5 193 L 1364.5 195 L 1363.5 193 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_202"
          d="M 802.5 195 L 803.5 197 L 802.5 195 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_203"
          d="M 821.5 196 L 820.5 198 L 821.5 196 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_204"
          d="M 1367.5 196 L 1368.5 198 L 1367.5 196 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_205"
          d="M 1385.5 196 L 1384.5 198 L 1385.5 196 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_206"
          d="M 805.5 199 L 806.5 201 L 805.5 199 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_207"
          d="M 817.5 199 L 816.5 201 L 817.5 199 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_208"
          d="M 1371.5 199 L 1372.5 201 L 1371.5 199 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_209"
          d="M 1382.5 200 L 1381.5 202 L 1382.5 200 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_210"
          d="M 813.5 202 L 812.5 204 L 813.5 202 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_211"
          d="M 1375.5 202 L 1376.5 204 L 1375.5 202 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_212"
          d="M 808.5 203 L 809.5 205 L 808.5 203 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_213"
          d="M 73.5 256 L 74 329 L 1 329 L 1 401.5 L 0 401.5 L 0 329.5 L 1.5 328 L 73 328 L 73.5 256 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_214"
          d="M 2115.5 256 L 2116 328 L 2187.5 328 L 2189 329.5 L 2188.5 402 L 2188 329 L 2115 329 L 2115.5 256 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_215"
          d="M 146.5 329 L 147 620 L 292 620.5 L 147.5 621 L 146 619.5 L 146.5 329 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_216"
          d="M 365.5 329 L 366 620 L 1822.5 620 L 1823 619.5 L 1823 329.5 L 1824 329.5 L 1824 620.5 L 1823.5 621 L 365 621 L 365.5 329 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_217"
          d="M 2042.5 329 L 2043 619.5 L 2041.5 621 L 1897.5 621 L 1897.5 620 L 2042 620 L 2042.5 329 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_218"
          d="M 438.5 401 L 765.5 401 L 767 402.5 L 766.5 475 L 766 402 L 438.5 402 L 438.5 401 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_219"
          d="M 840.5 401 L 911.5 401 L 913 402.5 L 912.5 475 L 912 402 L 840.5 402 L 840.5 401 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_220"
          d="M 73.5 402 L 74 546.5 L 72.5 548 L 1 548 L 0.5 621 L 0 547 L 73 547 L 73.5 402 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_221"
          d="M 2115.5 402 L 2116 547 L 2189 547 L 2189 620.5 L 2188 620.5 L 2188 548 L 2116.5 548 L 2115 546.5 L 2115.5 402 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_222"
          d="M 511.5 475 L 512 546.5 L 511 546.5 L 511.5 475 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_223"
          d="M 73.5 621 L 74 693.5 L 73 693.5 L 73.5 621 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_224"
          d="M 2115.5 621 L 2116 693.5 L 2115 693.5 L 2115.5 621 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_225"
          d="M 810.5 744 L 811.5 746 L 810.5 744 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_226"
          d="M 1378.5 744 L 1377.5 746 L 1378.5 744 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_227"
          d="M 808.5 746 L 807.5 748 L 808.5 746 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_228"
          d="M 1380.5 746 L 1381.5 748 L 1380.5 746 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_229"
          d="M 814.5 747 L 815.5 749 L 814.5 747 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_230"
          d="M 1374.5 747 L 1373.5 749 L 1374.5 747 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_231"
          d="M 805.5 750 L 804.5 752 L 805.5 750 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_232"
          d="M 818.5 750 L 819.5 752 L 818.5 750 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_233"
          d="M 1370.5 750 L 1369.5 752 L 1370.5 750 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_234"
          d="M 1383.5 750 L 1384.5 752 L 1383.5 750 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_235"
          d="M 822.5 753 L 823.5 755 L 822.5 753 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_236"
          d="M 1366.5 753 L 1365.5 755 L 1366.5 753 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_237"
          d="M 802.5 754 L 801.5 756 L 802.5 754 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_238"
          d="M 1386.5 754 L 1387.5 756 L 1386.5 754 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_239"
          d="M 826.5 756 L 827.5 758 L 826.5 756 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_240"
          d="M 1362.5 756 L 1361.5 758 L 1362.5 756 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_241"
          d="M 799.5 758 L 798.5 760 L 799.5 758 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_242"
          d="M 1389.5 758 L 1390.5 760 L 1389.5 758 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_243"
          d="M 830.5 759 L 831.5 761 L 830.5 759 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_244"
          d="M 1358.5 759 L 1357.5 761 L 1358.5 759 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_245"
          d="M 796.5 762 L 795.5 764 L 796.5 762 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_246"
          d="M 834.5 762 L 835.5 764 L 834.5 762 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_247"
          d="M 1354.5 762 L 1353.5 764 L 1354.5 762 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_248"
          d="M 1392.5 762 L 1393.5 764 L 1392.5 762 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_249"
          d="M 838.5 765 L 839.5 767 L 838.5 765 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_250"
          d="M 1350.5 765 L 1349.5 767 L 1350.5 765 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_251"
          d="M 793.5 766 L 792.5 768 L 793.5 766 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_252"
          d="M 1058.5 766 L 1131 766.5 L 1058.5 767 L 1058.5 766 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_253"
          d="M 1395.5 766 L 1396.5 768 L 1395.5 766 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_254"
          d="M 842.5 768 L 843.5 770 L 842.5 768 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_255"
          d="M 1346.5 768 L 1345.5 770 L 1346.5 768 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_256"
          d="M 790.5 770 L 789.5 772 L 790.5 770 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_257"
          d="M 1398.5 770 L 1399.5 772 L 1398.5 770 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_258"
          d="M 846.5 771 L 847.5 773 L 846.5 771 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_259"
          d="M 1342.5 771 L 1341.5 773 L 1342.5 771 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_260"
          d="M 787.5 774 L 786.5 776 L 787.5 774 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_261"
          d="M 850.5 774 L 851.5 776 L 850.5 774 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_262"
          d="M 1338.5 774 L 1337.5 776 L 1338.5 774 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_263"
          d="M 1401.5 774 L 1402.5 776 L 1401.5 774 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_264"
          d="M 854.5 777 L 855.5 779 L 854.5 777 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_265"
          d="M 1334.5 777 L 1333.5 779 L 1334.5 777 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_266"
          d="M 784.5 778 L 783.5 780 L 784.5 778 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_267"
          d="M 1404.5 778 L 1405.5 780 L 1404.5 778 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_268"
          d="M 858.5 780 L 859.5 782 L 858.5 780 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_269"
          d="M 1330.5 780 L 1329.5 782 L 1330.5 780 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_270"
          d="M 781.5 782 L 780.5 784 L 781.5 782 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_271"
          d="M 1407.5 782 L 1408.5 784 L 1407.5 782 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_272"
          d="M 862.5 783 L 863.5 785 L 862.5 783 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_273"
          d="M 1326.5 783 L 1325.5 785 L 1326.5 783 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_274"
          d="M 778.5 786 L 777.5 788 L 778.5 786 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_275"
          d="M 866.5 786 L 868.5 789 L 866.5 786 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_276"
          d="M 1322.5 786 L 1320.5 789 L 1322.5 786 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_277"
          d="M 1410.5 786 L 1411.5 788 L 1410.5 786 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_278"
          d="M 775.5 790 L 774.5 792 L 775.5 790 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_279"
          d="M 1413.5 790 L 1414.5 792 L 1413.5 790 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_280"
          d="M 866.5 791 L 865.5 793 L 866.5 791 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_281"
          d="M 1322.5 791 L 1323.5 793 L 1322.5 791 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_282"
          d="M 772.5 794 L 771.5 796 L 772.5 794 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_283"
          d="M 1416.5 794 L 1417.5 796 L 1416.5 794 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_284"
          d="M 863.5 795 L 862.5 797 L 863.5 795 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_285"
          d="M 1325.5 795 L 1326.5 797 L 1325.5 795 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_286"
          d="M 769.5 798 L 768.5 800 L 769.5 798 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_287"
          d="M 1419.5 798 L 1420.5 800 L 1419.5 798 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_288"
          d="M 860.5 799 L 859.5 801 L 860.5 799 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_289"
          d="M 1328.5 799 L 1329.5 801 L 1328.5 799 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_290"
          d="M 766.5 802 L 765.5 804 L 766.5 802 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_291"
          d="M 1422.5 802 L 1423.5 804 L 1422.5 802 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_292"
          d="M 857.5 803 L 856.5 805 L 857.5 803 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_293"
          d="M 1331.5 803 L 1332.5 805 L 1331.5 803 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_294"
          d="M 763.5 806 L 762.5 808 L 763.5 806 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_295"
          d="M 1425.5 806 L 1426.5 808 L 1425.5 806 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_296"
          d="M 854.5 807 L 853.5 809 L 854.5 807 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_297"
          d="M 1334.5 807 L 1335.5 809 L 1334.5 807 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_298"
          d="M 760.5 810 L 759.5 812 L 760.5 810 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_299"
          d="M 1428.5 810 L 1429.5 812 L 1428.5 810 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_300"
          d="M 851.5 811 L 850.5 813 L 851.5 811 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_301"
          d="M 1337.5 811 L 1338.5 813 L 1337.5 811 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_302"
          d="M 757.5 814 L 756.5 816 L 757.5 814 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_303"
          d="M 1431.5 814 L 1432.5 816 L 1431.5 814 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_304"
          d="M 848.5 815 L 847.5 817 L 848.5 815 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_305"
          d="M 1340.5 815 L 1341.5 817 L 1340.5 815 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_306"
          d="M 754.5 818 L 753.5 820 L 754.5 818 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_307"
          d="M 845.5 819 L 844.5 821 L 845.5 819 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_308"
          d="M 1343.5 819 L 1344.5 821 L 1343.5 819 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_309"
          d="M 1436.5 821 L 1437.5 823 L 1436.5 821 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_310"
          d="M 751.5 822 L 750.5 824 L 751.5 822 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_311"
          d="M 842.5 823 L 841.5 825 L 842.5 823 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_312"
          d="M 1346.5 823 L 1347.5 825 L 1346.5 823 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_313"
          d="M 1439.5 825 L 1440.5 827 L 1439.5 825 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_314"
          d="M 748.5 826 L 747.5 828 L 748.5 826 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_315"
          d="M 839.5 827 L 838.5 829 L 839.5 827 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_316"
          d="M 1349.5 827 L 1350.5 829 L 1349.5 827 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_317"
          d="M 1442.5 829 L 1443.5 831 L 1442.5 829 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_318"
          d="M 745.5 830 L 744.5 832 L 745.5 830 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_319"
          d="M 836.5 831 L 835.5 833 L 836.5 831 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_320"
          d="M 1352.5 831 L 1353.5 833 L 1352.5 831 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_321"
          d="M 1445.5 833 L 1446.5 835 L 1445.5 833 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_322"
          d="M 742.5 834 L 741.5 836 L 742.5 834 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_323"
          d="M 833.5 835 L 832.5 837 L 833.5 835 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_324"
          d="M 1355.5 835 L 1356.5 837 L 1355.5 835 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_325"
          d="M 1448.5 837 L 1449.5 839 L 1448.5 837 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_326"
          d="M 739.5 838 L 738.5 840 L 739.5 838 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_327"
          d="M 830.5 839 L 829.5 841 L 830.5 839 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_328"
          d="M 1358.5 839 L 1359.5 841 L 1358.5 839 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_329"
          d="M 1451.5 841 L 1452.5 843 L 1451.5 841 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_330"
          d="M 736.5 842 L 735.5 844 L 736.5 842 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_331"
          d="M 827.5 843 L 826.5 845 L 827.5 843 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_332"
          d="M 1361.5 843 L 1362.5 845 L 1361.5 843 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_333"
          d="M 1454.5 845 L 1455.5 847 L 1454.5 845 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_334"
          d="M 733.5 846 L 732.5 848 L 733.5 846 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_335"
          d="M 824.5 847 L 823.5 849 L 824.5 847 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_336"
          d="M 1364.5 847 L 1365.5 849 L 1364.5 847 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_337"
          d="M 1457.5 849 L 1458.5 851 L 1457.5 849 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_338"
          d="M 730.5 850 L 729.5 852 L 730.5 850 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_339"
          d="M 821.5 851 L 820.5 853 L 821.5 851 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_340"
          d="M 1367.5 851 L 1368.5 853 L 1367.5 851 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_341"
          d="M 1460.5 853 L 1461.5 855 L 1460.5 853 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_342"
          d="M 727.5 854 L 726.5 856 L 727.5 854 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_343"
          d="M 818.5 855 L 817.5 857 L 818.5 855 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_344"
          d="M 1370.5 855 L 1371.5 857 L 1370.5 855 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_345"
          d="M 1463.5 857 L 1464.5 859 L 1463.5 857 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_346"
          d="M 724.5 858 L 723.5 860 L 724.5 858 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_347"
          d="M 815.5 859 L 814.5 861 L 815.5 859 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_348"
          d="M 1373.5 859 L 1374.5 861 L 1373.5 859 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_349"
          d="M 1466.5 861 L 1467.5 863 L 1466.5 861 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_350"
          d="M 721.5 862 L 720.5 864 L 721.5 862 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_351"
          d="M 812.5 863 L 811.5 865 L 812.5 863 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_352"
          d="M 1376.5 863 L 1377.5 865 L 1376.5 863 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_353"
          d="M 1469.5 865 L 1470.5 867 L 1469.5 865 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_354"
          d="M 718.5 866 L 717.5 868 L 718.5 866 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_355"
          d="M 809.5 867 L 808.5 869 L 809.5 867 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_356"
          d="M 1379.5 867 L 1380.5 869 L 1379.5 867 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_357"
          d="M 1472.5 869 L 1473.5 871 L 1472.5 869 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_358"
          d="M 715.5 870 L 714.5 872 L 715.5 870 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_359"
          d="M 806.5 871 L 805.5 873 L 806.5 871 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_360"
          d="M 1382.5 871 L 1383.5 873 L 1382.5 871 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_361"
          d="M 1475.5 873 L 1476.5 875 L 1475.5 873 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_362"
          d="M 712.5 874 L 711.5 876 L 712.5 874 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_363"
          d="M 803.5 875 L 802.5 877 L 803.5 875 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_364"
          d="M 1385.5 875 L 1386.5 877 L 1385.5 875 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_365"
          d="M 1478.5 877 L 1479.5 879 L 1478.5 877 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_366"
          d="M 709.5 878 L 708.5 880 L 709.5 878 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_367"
          d="M 800.5 879 L 799.5 881 L 800.5 879 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_368"
          d="M 1388.5 879 L 1389.5 881 L 1388.5 879 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_369"
          d="M 1481.5 881 L 1482.5 883 L 1481.5 881 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_370"
          d="M 706.5 882 L 705.5 884 L 706.5 882 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_371"
          d="M 797.5 883 L 796.5 885 L 797.5 883 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_372"
          d="M 1391.5 883 L 1392.5 885 L 1391.5 883 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_373"
          d="M 1484.5 885 L 1485.5 887 L 1484.5 885 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_374"
          d="M 703.5 886 L 702.5 888 L 703.5 886 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_375"
          d="M 794.5 887 L 793.5 889 L 794.5 887 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_376"
          d="M 1394.5 887 L 1395.5 889 L 1394.5 887 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_377"
          d="M 700.5 890 L 701.5 892 L 700.5 890 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_378"
          d="M 791.5 891 L 790.5 893 L 791.5 891 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_379"
          d="M 1397.5 891 L 1398.5 893 L 1397.5 891 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_380"
          d="M 704.5 893 L 705.5 895 L 704.5 893 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_381"
          d="M 1484.5 893 L 1483.5 895 L 1484.5 893 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_382"
          d="M 788.5 895 L 787.5 897 L 788.5 895 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_383"
          d="M 1400.5 895 L 1401.5 897 L 1400.5 895 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_384"
          d="M 708.5 896 L 709.5 898 L 708.5 896 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_385"
          d="M 1480.5 896 L 1479.5 898 L 1480.5 896 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_386"
          d="M 712.5 899 L 713.5 901 L 712.5 899 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_387"
          d="M 785.5 899 L 784.5 901 L 785.5 899 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_388"
          d="M 1403.5 899 L 1404.5 901 L 1403.5 899 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_389"
          d="M 1476.5 899 L 1475.5 901 L 1476.5 899 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_390"
          d="M 716.5 902 L 717.5 904 L 716.5 902 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_391"
          d="M 1472.5 902 L 1471.5 904 L 1472.5 902 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_392"
          d="M 782.5 903 L 781.5 905 L 782.5 903 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_393"
          d="M 1406.5 903 L 1407.5 905 L 1406.5 903 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_394"
          d="M 720.5 905 L 721.5 907 L 720.5 905 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_395"
          d="M 1468.5 905 L 1467.5 907 L 1468.5 905 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_396"
          d="M 779.5 907 L 778.5 909 L 779.5 907 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_397"
          d="M 1409.5 907 L 1410.5 909 L 1409.5 907 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_398"
          d="M 724.5 908 L 725.5 910 L 724.5 908 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_399"
          d="M 1464.5 908 L 1463.5 910 L 1464.5 908 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_400"
          d="M 728.5 911 L 729.5 913 L 728.5 911 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_401"
          d="M 776.5 911 L 775.5 913 L 776.5 911 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_402"
          d="M 1412.5 911 L 1413.5 913 L 1412.5 911 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_403"
          d="M 1460.5 911 L 1459.5 913 L 1460.5 911 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_404"
          d="M 732.5 914 L 733.5 916 L 732.5 914 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_405"
          d="M 1456.5 914 L 1455.5 916 L 1456.5 914 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_406"
          d="M 773.5 915 L 772.5 917 L 773.5 915 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_407"
          d="M 1415.5 915 L 1416.5 917 L 1415.5 915 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_408"
          d="M 736.5 917 L 737.5 919 L 736.5 917 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_409"
          d="M 1452.5 917 L 1451.5 919 L 1452.5 917 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_410"
          d="M 770.5 919 L 769.5 921 L 770.5 919 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_411"
          d="M 1418.5 919 L 1419.5 921 L 1418.5 919 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_412"
          d="M 740.5 920 L 741.5 922 L 740.5 920 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_413"
          d="M 1448.5 920 L 1447.5 922 L 1448.5 920 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_414"
          d="M 744.5 923 L 745.5 925 L 744.5 923 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_415"
          d="M 767.5 923 L 766.5 925 L 767.5 923 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_416"
          d="M 1421.5 923 L 1422.5 925 L 1421.5 923 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_417"
          d="M 1444.5 923 L 1443.5 925 L 1444.5 923 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_418"
          d="M 748.5 926 L 749.5 928 L 748.5 926 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_419"
          d="M 1440.5 926 L 1439.5 928 L 1440.5 926 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_420"
          d="M 764.5 927 L 763.5 929 L 764.5 927 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_421"
          d="M 1424.5 927 L 1425.5 929 L 1424.5 927 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_422"
          d="M 752.5 929 L 753.5 931 L 752.5 929 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_423"
          d="M 1436.5 929 L 1435.5 931 L 1436.5 929 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_424"
          d="M 761.5 931 L 760.5 933 L 761.5 931 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_425"
          d="M 1427.5 931 L 1428.5 933 L 1427.5 931 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_426"
          d="M 756.5 932 L 757.5 934 L 756.5 932 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
        <path
          id="path_427"
          d="M 1432.5 932 L 1431.5 934 L 1432.5 932 Z"
          fill="currentColor"
          fillOpacity="0.4980392"
          stroke="currentColor"
          strokeOpacity="0.4980392"
          strokeWidth="1"/>
      </g>
    </svg>

  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'FluorescentLampSecond')

export default icon
