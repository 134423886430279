import * as type from '@app/constants/scenes/zWaveDevices/zWaveDevicesByRooms'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/zWaveDevices/zWaveDevicesByRooms'
import { IRoomZwaveBackend } from '@app/@types/scenes/scenesDevices'

type IZWaveDevicesByRooms = ReturnType<InferValueTypes<typeof actions>>

interface State {
  rooms: Array<IRoomZwaveBackend>,
}
const initialState = {
  rooms: [],
}

export default function zWaveDevicesByGroups(state: State = initialState, action: IZWaveDevicesByRooms): State {
  switch (action.type) {
  case type.RESET_ZWAVE_DEVICES_BY_ROOMS:
    return initialState

  case type.UPDATE_ZWAVE_DEVICES_BY_ROOMS: {
    return {
      ...state,
      rooms: action.rooms,
    }
  }

  default:
    return state
  }
}
