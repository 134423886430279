import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M97.1877 141.003H52.8543C51.3248 141.003 50.0835 139.761 50.0835 138.232C50.0835 136.702 51.3248 135.461 52.8543 135.461H97.1877C98.7172 135.461 99.9585 136.702 99.9585 138.232C99.9585 139.761 98.7172 141.003 97.1877 141.003Z" fill="currentColor"/>
      <path d="M80.5624 102.208H69.4791C58.7837 102.208 50.0833 93.5079 50.0833 82.8125V27.3958C50.0833 16.7004 58.7837 8 69.4791 8H80.5624C91.2578 8 99.9583 16.7004 99.9583 27.3958V82.8125C99.9583 93.5079 91.2578 102.208 80.5624 102.208ZM69.4791 13.5417C61.8427 13.5417 55.6249 19.7594 55.6249 27.3958V82.8125C55.6249 90.4489 61.8427 96.6667 69.4791 96.6667H80.5624C88.1988 96.6667 94.4166 90.4489 94.4166 82.8125V27.3958C94.4166 19.7594 88.1988 13.5417 80.5624 13.5417H69.4791Z" fill="currentColor"/>
      <path d="M86.1042 113.292H63.9375C50.1886 113.292 39 102.103 39 88.3542V60.6458C39 59.1163 40.2413 57.875 41.7708 57.875C43.3003 57.875 44.5417 59.1163 44.5417 60.6458V88.3542C44.5417 99.0496 53.2421 107.75 63.9375 107.75H86.1042C96.7996 107.75 105.5 99.0496 105.5 88.3542V60.6458C105.5 59.1163 106.741 57.875 108.271 57.875C109.8 57.875 111.042 59.1163 111.042 60.6458V88.3542C111.042 102.103 99.853 113.292 86.1042 113.292Z" fill="currentColor"/>
      <path d="M75.0208 141C73.4913 141 72.25 139.759 72.25 138.229V110.521C72.25 108.991 73.4913 107.75 75.0208 107.75C76.5503 107.75 77.7917 108.991 77.7917 110.521V138.229C77.7917 139.759 76.5503 141 75.0208 141Z" fill="currentColor"/>
      <path d="M97.1877 57.4831H52.8543C51.3248 57.4831 50.0835 56.2417 50.0835 54.7122C50.0835 53.1827 51.3248 51.9414 52.8543 51.9414H97.1877C98.7172 51.9414 99.9585 53.1827 99.9585 54.7122C99.9585 56.2417 98.7172 57.4831 97.1877 57.4831Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'StudioMicrophone')

export default icon
