import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { ListItem, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './SelectString.style'
import SelectFieldString from './SelectFieldString'

class SelectString extends React.Component {
    createItems = (number, values, startValue) => values.map((el, index) => (
      <MenuItem key={number + el} value={index + startValue}>
        {`${I18n.t(`drop_down_menu.${el}`)}`}
      </MenuItem>
    ))

    render() {
      const {
        classes,
        number,
        title,
        name,
        value,
        values,
        startValue,
        submit,
        ...other
      } = this.props

      return (
        <ListItem className={classes.root}>
          <Field
            name={name}
            component={SelectFieldString}
            number={number}
            createItems={this.createItems}
            value={value}
            values={values}
            startValue={startValue}
            title={title}
            submit={submit}
            {...other}
          />
        </ListItem>
      )
    }
}

SelectString.propTypes = {
  classes: PropTypes.object.isRequired,
  number: PropTypes.number,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  startValue: PropTypes.number,
  submit: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
}

SelectString.defaultProps = {
  values: [],
  startValue: 0,
  value: null,
  number: null,
  submit: () => {},
}

export default (withStyles(styles)(SelectString))
