import React from 'react'
import classNames from 'classnames'
import getDataForGraph from '@app/helpers/myEnergy/getDataForGraph'
import checkZeroDataForGraph from '@app/helpers/myEnergy/checkZeroDataForGraph'
import { intervalValues } from '@app/lib/MyEnergy/intervalValues'
import { Paper, Typography } from '@mui/material'
import Slider from '@app/components/Common/Slider'
import PropTypes from '@app/components/PropTypes'
import EnergyTotals from './EnergyTotals/EnergyTotals'
import SelectString from './SelectString/SelectString'
import Chart from '../../../Charts/Charts'

class EnergyTab extends React.Component {
    renderSelectString = (periodSelector, choosePeriod) => (
      <SelectString
        values={intervalValues}
        periodSelector={periodSelector}
        choosePeriod={choosePeriod}
      />
    )

    render() {
      const {
        classes,
        periodSelector,
        choosePeriod,
        energies,
        selectedSliderTab,
        chooseSliderTab,
        isMobile,
      } = this.props
      return (
        <Paper className={classNames(
          classes.root,
          (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
          && classes.webView,
        )}
        >
          {this.renderSelectString(periodSelector, choosePeriod)}
          <Slider
            firstComponent={checkZeroDataForGraph(periodSelector, energies, 'kwh') ? (
              <Typography className={classes.zeroData}>{I18n.t('my_energy.no_statistic_yet').toUpperCase()}</Typography>
            ) : (
              <Chart
                title="View kWh"
                xAxis={I18n.t('my_energy.period')}
                yAxis="kWh"
                data={getDataForGraph(periodSelector, energies, 'kwh')}
              />
            )}
            secondComponent={checkZeroDataForGraph(periodSelector, energies, 'dollar') ? (
              <Typography className={classes.zeroData}>{I18n.t('my_energy.no_statistic_yet').toUpperCase()}</Typography>
            ) : (
              <Chart
                title="View Dollars"
                xAxis={I18n.t('my_energy.period')}
                yAxis="dollar"
                data={getDataForGraph(periodSelector, energies, 'dollar')}
              />
            )}
            thirdComponent={<EnergyTotals energies={energies} periodSelector={periodSelector} isMobile={isMobile} />}
            selectedItem={selectedSliderTab}
            chooseSliderTab={chooseSliderTab}
          />
        </Paper>
      )
    }
}

EnergyTab.propTypes = {
  classes: PropTypes.object.isRequired,
  periodSelector: PropTypes.string.isRequired,
  choosePeriod: PropTypes.func.isRequired,
  energies: PropTypes.energies.isRequired,
  selectedSliderTab: PropTypes.number.isRequired,
  chooseSliderTab: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

EnergyTab.displayName = 'EnergyTab'

export default EnergyTab
