import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/energy/energies`

const convertDate = (current_time) => current_time.toISOString()

const fetchEnergies = (current_time, access_token) => Axios.get(ENDPOINT, {
  params: { current_time: convertDate(current_time) },
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchEnergies,
}
