import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { getMezzoStatusForScreens } from '@app/helpers/getMezzoStatusForScreens'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { withAccessDenied, withNotifications, withRefresh } from '@app/hocs'
import {
  sync,
} from '@app/actions/events'
import Home from '@app/components/Home/Home'
import {
  syncMyStatus,
} from '@app/actions/myStatus'
import {
  closeActivationInfoPopup,
} from '@app/actions/wizard/signUp'

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const { path } = state.shadowIn
  const { unitToken, tokenStatus } = state.secrets
  const { activation } = state.unit
  const { email: userEmail } = state.users.currentUser.user
  const { isActivationInfoPopupOpened } = state.login.signUp
  const withUnitDevice = getMezzoStatusForScreens(activation)
  const { home: homeConfig } = state.appConfig.config
  return {
    isMobile,
    isTablet,
    path,
    tokenStatus,
    unitToken,
    withUnitDevice,
    homeConfig,
    isActivationInfoPopupOpened,
    userEmail,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    urlParams: document.location.search.substring(1),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    accessDenied: (!isAccessTokenValid(state.secrets.accessToken)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  refresh: sync,
  syncMyStatus,
  closeActivationInfoPopup,
}, dispatch)

let enhance = withRefresh(Home)
enhance = withNotifications(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const HomeEnhanced = enhance

export default HomeEnhanced
