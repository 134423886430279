import React from 'react'
import createSvgIcon from '../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 113 132" version="1.1">
            <title>iconDevicePanickey2</title>
            <desc>Created with Sketch.</desc>
            <defs>
            </defs>
            <g id="Map" stroke="none" strokeWidth="5" fill="none" fillRule="evenodd">
                <g id="iconDevicePanickey2" transform="translate(-9.000000, 1.000000)">
                    <g transform="translate(9.285714, 0.000000)" id="Shape">
                        <path
                            d="M27.0785714,5.1 C24.6785714,5.29285714 22.1357143,5.81428571 20.4785714,7.58571429 C18.4785714,9.69285714 18.3357143,12.8928571 18.2571429,15.7928571 C18.2604116,16.6924923 18.1887352,17.5908363 18.0428571,18.4785714 C17.9119941,19.1765446 17.6708834,19.8492436 17.3285714,20.4714286 C17.0804451,20.948069 16.7230985,21.3592623 16.2857143,21.6714286 C15.8519639,21.922492 15.3889587,22.1192092 14.9071429,22.2571429 C9.4366305,24.2132112 6.16199209,29.8165483 7.14285714,35.5428571"
                            stroke="currentColor" strokeWidth="3">
                        </path>
                        <path
                            d="M20.75,0.642857143 C18.35,0.835714286 15.8071429,1.35714286 14.15,3.12857143 C12.15,5.23571429 12.0071429,8.43571429 11.9285714,11.3357143 C11.9318402,12.2353494 11.8601638,13.1336935 11.7142857,14.0214286 C11.5834227,14.7194017 11.3423119,15.3921007 11,16.0142857 C10.7659467,16.4915086 10.4234869,16.9073527 10,17.2285714 C9.56624965,17.4796348 9.10324443,17.6763521 8.62142857,17.8142857 C3.13714044,19.7507004 -0.160109073,25.3505995 0.807142857,31.0857143"
                            stroke="currentColor" strokeWidth="3">
                        </path>
                        <path
                            d="M84.6428571,124.935714 C87.0428571,124.742857 89.5857143,124.221429 91.2428571,122.45 C93.2428571,120.342857 93.3857143,117.142857 93.4642857,114.242857 C93.461017,113.343222 93.5326933,112.444878 93.6785714,111.557143 C93.8094344,110.85917 94.0505452,110.186471 94.3928571,109.564286 C94.6409835,109.087645 94.99833,108.676452 95.4357143,108.364286 C95.8694646,108.113222 96.3324699,107.916505 96.8142857,107.778571 C102.284145,105.831911 105.566926,100.239135 104.6,94.5142857"
                            stroke="currentColor" strokeWidth="3">
                        </path>
                        <path
                            d="M90.9714286,129.392857 C93.3714286,129.2 95.9142857,128.678571 97.5714286,126.907143 C99.5714286,124.8 99.7142857,121.6 99.7928571,118.7 C99.7895884,117.800365 99.8612648,116.902021 100.007143,116.014286 C100.138006,115.316313 100.379117,114.643614 100.721429,114.021429 C100.969555,113.544788 101.326901,113.133595 101.764286,112.821429 C102.198036,112.570365 102.661041,112.373648 103.142857,112.235714 C108.612716,110.289053 111.895498,104.696278 110.928571,98.9714286"
                            stroke="currentColor" strokeWidth="3">
                        </path>
                        <path
                            d="M54.7714286,79.3642857 C59.0571429,79.3642857 62.6857143,76.9142857 65.0642857,73.5642857 C69.7468459,66.5553765 69.7468459,57.416052 65.0642857,50.4071429 C62.6857143,47.0571429 59.0857143,44.6071429 54.7714286,44.6071429 C50.4571429,44.6071429 46.8571429,47.0571429 44.4785714,50.4071429 C39.7960112,57.416052 39.7960112,66.5553765 44.4785714,73.5642857 C46.8571429,76.9214286 50.4571429,79.3642857 54.7714286,79.3642857 L54.7714286,79.3642857 Z"
                            fill="currentColor" strokeWidth="3">
                        </path>
                        <path
                            d="M54.7714286,25.6214286 C63.2079919,25.626166 71.625615,26.4201009 79.9142857,27.9928571 C82.6691215,28.5007587 84.7483539,30.7814898 85,33.5714286 C86.9431163,55.0561548 86.9431163,76.6724167 85,98.1571429 C84.748557,100.93565 82.684108,103.210043 79.9428571,103.728571 C71.6541865,105.301328 63.2365634,106.095263 54.8,106.1 C46.3634366,106.095263 37.9458135,105.301328 29.6571429,103.728571 C26.915892,103.210043 24.851443,100.93565 24.6,98.1571429 C22.647379,76.6732763 22.6378164,55.0570144 24.5714286,33.5714286 C24.8228716,30.792921 26.8873205,28.5185281 29.6285714,28 C37.9172421,26.4272438 46.3348652,25.6333089 54.7714286,25.6285714 L54.7714286,25.6214286 Z M54.7714286,23.8285714 C46.2201018,23.8355722 37.6880137,24.6390424 29.2857143,26.2285714 C25.7708814,26.9020518 23.1192045,29.8095923 22.7714286,33.3714286 C20.8277804,54.9634909 20.8277804,76.6865091 22.7714286,98.2785714 C23.1192045,101.840408 25.7708814,104.747948 29.2857143,105.421429 C37.6854444,107.012913 46.2151226,107.818772 54.7642857,107.828571 C63.3134489,107.818772 71.8431271,107.012913 80.2428571,105.421429 C83.75769,104.747948 86.4093669,101.840408 86.7571429,98.2785714 C88.7007525,76.6865074 88.7007525,54.9634926 86.7571429,33.3714286 C86.4093669,29.8095923 83.75769,26.9020518 80.2428571,26.2285714 C71.8431271,24.6370874 63.3134489,23.8312277 54.7642857,23.8214286 L54.7714286,23.8285714 Z"
                            fill="currentColor" strokeWidth="3">
                        </path>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwWindowSensorIcon')

export default icon
