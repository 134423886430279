import React from 'react'
import { Button, TextField, Typography } from '@mui/material'
import classNames from 'classnames'
import { emailField } from '@app/lib/Wizard/ForgotPasswordFields'
import { emailValidate } from '@app/containers/Wizard/ForgotPassword/emailValidate'
import { SIGN_IN } from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import NavigationAndTitles from '../NavigationAndTitles/NavigationAndTitles'
import CustomerServiceText from '../CustomerServiceText/CustomerServiceText'
import Info from '../Info/Info'

class Email extends React.PureComponent {
  handleChange = (event) => {
    const {
      saveEmail,
      email,
      hideError,
      showError,
    } = this.props
    const { target: { value } } = event
    saveEmail(value)
    if (!emailValidate(email) && value) {
      showError()
    } else {
      hideError()
    }
  }

  handleKeyUp = (event) => {
    const {
      hideError,
      showError,
    } = this.props
    const { target: { value } } = event
    if (!emailValidate(value) && value) {
      showError()
    } else {
      hideError()
    }
  }

  handlePaste = (event) => {
    const {
      saveEmail,
      hideError,
      showError,
    } = this.props
    event.clipboardData.items[0].getAsString((text) => {
      saveEmail(text)
      if (!emailValidate(text) && text) {
        showError()
      } else {
        hideError()
      }
    })
  }

  handleClick = () => {
    const {
      email,
      postEmail,
    } = this.props
    if (emailValidate(email) && email) {
      postEmail()
    }
  }

  renderError = () => {
    const {
      classes,
    } = this.props
    return (
      <Typography className={classes.inputError} align="center">
        {I18n.t('my_users.email_validation')}
      </Typography>
    )
  }

  popupInfoClose = () => {
    const { closeWrongEmailPopup } = this.props
    closeWrongEmailPopup()
  }

  render() {
    const {
      classes,
      email,
      validateError,
      isWrongEmailPopupOpened,
      isMobile,
      isTablet,
      navigate,
      clearEmail,
      clearCode,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <Wrapper className={classNames(
          classes.main,
          (!isMobile || isTablet) && classes.mainDesktop,
        )}
        >
          <NavigationAndTitles
            firstTitle="titles.reset_password"
            secondTitle="forgot_password.password_assistance"
            thirdTitle="forgot_password.enter_email_text"
            onBackClick={() => {
              navigate(SIGN_IN)
              clearEmail()
              clearCode()
            }}
            isMobile={isMobile}
          />
          <Wrapper className={classes.fieldWrapper}>
            <TextField
              variant="standard"
              className={classes.email}
              id="custom-css-standard-input"
              name={emailField.title}
              placeholder={emailField.placeholder}
              value={email}
              onChange={this.handleChange}
              onKeyUp={this.handleKeyUp}
              onPaste={this.handlePaste}
              InputProps={{
                classes: {
                  input: classes.emailInput,
                },
                inputProps: {
                  required: true,
                },
              }}
            />
            {validateError && this.renderError()}
          </Wrapper>
          <Button
            className={classes.sendButton}
            variant="contained"
            color="primary"
            onClick={this.handleClick}
          >
            {I18n.t('forgot_password.send_reset_code_button')}
          </Button>
          <CustomerServiceText />
        </Wrapper>
        <Info
          open={isWrongEmailPopupOpened}
          title="my_users.info"
          warningText="forgot_password.email_address_not_valid"
          buttonName="buttons.okay"
          onClick={this.popupInfoClose}
        />
      </Wrapper>
    )
  }
}

Email.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  validateError: PropTypes.bool.isRequired,
  isWrongEmailPopupOpened: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  saveEmail: PropTypes.func.isRequired,
  closeWrongEmailPopup: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  postEmail: PropTypes.func.isRequired,
  clearCode: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

Email.displayName = 'Email'

export default Email
