import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const fetchZwaveDeviceInfo = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/zwave_devices/info/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

const fetchTwrDeviceInfo = (access_token: string, id: number) => Axios.get(`${API_URL}/api/devices/twr_devices/info/${id}`, {
  headers: {
    'Access-Token': access_token,
  },
})

export default {
  fetchTwrDeviceInfo,
  fetchZwaveDeviceInfo,
}
