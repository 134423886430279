export const zwaveSecurityOptions = [
  {
    option: 'alarm',
    name: 'alarm',
    description: 'alarm_desc',
  },
  {
    option: 'perimeter',
    name: 'perimeter',
    description: 'perimeter_desc',
  },
  {
    option: 'fire_24_hours',
    name: 'fire_24_hours',
    description: 'fire_24_hours_desc',
  },
  {
    option: 'chime',
    name: 'chime',
    description: 'chime_desc',
  },
  {
    option: 'alarm_chime',
    name: 'alarm_chime',
    description: 'alarm_chime_desc',
  },
  {
    option: 'silent_alarm',
    name: 'silent',
    description: 'silent_desc',
  },
  {
    option: 'no_alarm',
    name: 'no_alarm',
    description: 'no_alarm_desc',
  },
]
