import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import oAuthApplication from '@app/helpers/oAuthApplication'
import parseJwt from '@app/helpers/parseJwt'
import { AUTH_BY_PASSWORD, SIGN_UP } from '@app/constants/wizard/wizard'
import { HOME } from '@app/constants/routes'
import { hideUserExistsError, showUserExistsError, showActivationInfoPopup } from '@app/actions/wizard/signUp'
import { tokenStatusRefreshed, updateAccessToken, updateSecrets } from '@app/actions/secrets'
import { signInCurrentUser } from '@app/actions/users/currentUser'
import { tokenData, userData } from '@app/lib/Wizard/wizardLogicFunctions'
import AuthUserScope from '@app/api/wizard/signIn'
import AuthToken from '@app/api/wizard/authByPassword'
import Api from '@app/api/wizard/signUp'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import handleErrors from '@app/helpers/handleErrors'
import ApiConfig from '@app/api/appConfig'
import { saveRawConfig, saveAppConfig } from '@app/actions/appConig/appConfig'
import { GET_APP_CONFIG } from '@app/constants/appCofig/appConfig'
import { convertAppConfig, Role } from '@app/helpers/convertAppConfig'

const signUpLogic = createLogic({
  type: SIGN_UP,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { clientCredentialsToken } } = getState()
    const { clientId, secret } = oAuthApplication()
    const {
      form: {
        SignUp: {
          values: {
            first_name,
            last_name,
            preferred_name,
            phone_number,
            email,
            password,
          },
        },
      },
    } = getState()
    const formInfo = {
      first_name,
      last_name,
      preferred_name,
      phone_number,
      email,
      password,
    }
    next({
      ...action,
      clientCredentialsToken,
      clientId,
      secret,
      formInfo,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SIGN_UP))
    try {
      await Api.signUp(action.clientCredentialsToken, action.formInfo)
      dispatch(apiRequestDone(SIGN_UP))
      dispatch(apiRequestSent(AUTH_BY_PASSWORD))
      const jwt = await AuthToken.authByPassword(action.formInfo.email, action.formInfo.password, action.clientId, action.secret, 'user')
      const parsedToken = tokenData(jwt)
      const dataJwt = parseJwt(parsedToken.token)
      const user = await AuthUserScope.signIn(parsedToken.token, dataJwt.user.id)
      const parsedUser = userData(user)
      dispatch(updateSecrets(
        null,
        parsedUser.ids.sphere,
      ))
      dispatch(signInCurrentUser(parsedUser))
      dispatch(updateAccessToken(
        parsedToken.token,
        parsedToken.expiresIn,
        parsedToken.tokenType,
        parsedToken.refreshToken,
        parsedToken.createdAt,
        parsedToken.scope,
      ))
      dispatch(hideUserExistsError())
      dispatch(tokenStatusRefreshed())
      dispatch(apiRequestDone(AUTH_BY_PASSWORD))
      dispatch(showActivationInfoPopup())
      try {
        const configData = await ApiConfig.getAppConfig(parsedToken.token)
        const rawConfigs = configData.data.accounts
        dispatch(saveRawConfig(rawConfigs))
        const isNCentral = false
        const activation = null
        const currentRole = Role.NoUnitDevice
        const convertedConfig = convertAppConfig(rawConfigs, isNCentral, activation, currentRole)
        dispatch(saveAppConfig(convertedConfig))
        dispatch(apiRequestDone(GET_APP_CONFIG))
        history.push(HOME)
      } catch (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      }
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
        dispatch(showUserExistsError())
      } else {
        throw new Error('signUp failed without error')
      }
    }
    done()
  },
})

export default signUpLogic
