import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M140.35 58.8354V58.831L140.35 17.5287C140.35 13.1891 136.811 9.65 132.471 9.65H91.1516C86.8119 9.65 83.2728 13.1891 83.2728 17.5287V58.8341C83.2728 63.1737 86.8119 66.7128 91.1516 66.7128H132.457C136.809 66.7128 140.335 63.1897 140.35 58.8354ZM140.35 133.463V133.459L140.35 92.142C140.35 87.8024 136.811 84.2633 132.471 84.2633H91.1518C86.8121 84.2633 83.273 87.8024 83.273 92.142V133.447C83.273 137.787 86.8121 141.326 91.1518 141.326H132.457C136.81 141.326 140.335 137.802 140.35 133.463ZM16.5287 66.7128H57.8341C62.1744 66.7128 65.7128 63.1872 65.7128 58.8341V17.5287C65.7128 13.1891 62.1737 9.65 57.8341 9.65H16.5287C12.1891 9.65 8.65 13.1891 8.65 17.5287V58.8341C8.65 63.1737 12.1891 66.7128 16.5287 66.7128ZM13.0453 17.5287C13.0453 15.6035 14.6035 14.0453 16.5287 14.0453H57.8341C59.7593 14.0453 61.3175 15.6035 61.3175 17.5287V58.8341C61.3175 60.7593 59.7593 62.3175 57.8341 62.3175H16.5287C14.6035 62.3175 13.0453 60.7593 13.0453 58.8341V17.5287ZM87.6681 17.5287C87.6681 15.6035 89.2263 14.0453 91.1516 14.0453H132.471C134.396 14.0453 135.955 15.6035 135.955 17.5287V58.8341C135.955 60.7593 134.396 62.3175 132.471 62.3175H91.1516C89.2263 62.3175 87.6681 60.7593 87.6681 58.8341V17.5287ZM16.5287 141.326H57.8341C62.1752 141.326 65.7128 137.8 65.7128 133.462V92.142C65.7128 87.8024 62.1737 84.2633 57.8341 84.2633H16.5287C12.1891 84.2633 8.65 87.8024 8.65 92.142V133.447C8.65 137.787 12.1891 141.326 16.5287 141.326ZM13.0453 92.142C13.0453 90.2168 14.6035 88.6586 16.5287 88.6586H57.8341C59.7593 88.6586 61.3175 90.2168 61.3175 92.142V133.462C61.3175 135.387 59.7593 136.945 57.8341 136.945H16.5287C14.6035 136.945 13.0453 135.387 13.0453 133.462V92.142ZM87.6684 92.142C87.6684 90.2168 89.2266 88.6586 91.1518 88.6586H132.471C134.397 88.6586 135.955 90.2168 135.955 92.142V133.462C135.955 135.387 134.397 136.945 132.471 136.945H91.1518C89.2266 136.945 87.6684 135.387 87.6684 133.462V92.142Z" fill="currentColor" stroke="currentColor" strokeWidth="0.7"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'Buttons')

export default icon
