import update from 'immutability-helper'
import * as type from '@app/constants/scenes/currentSceneCreating'
import * as actions from '@app/actions/scenes/currentSceneCreating'
import { InferValueTypes } from '@app/@types/typesHelper'
import { ISubDevice } from '@app/@types/scenes/scenesDevices'
import { IUser } from '@app/@types/scenes/conditionsTypes'
import { IRequestBody, IRequestHeader } from '@app/@types/scenes/actionTypes'
import { IConditionFront } from '@app/@types/scenes/scenes'

export type TCurrentSceneCreatingActions = ReturnType<InferValueTypes<typeof actions>>

export interface ICurrentSceneCreatingActionsState {
  isCreateConditionMode: boolean,
  isCreateActionMode: boolean,
  isEditConditionMode: boolean,
  isEditActionMode: boolean,
  isAdditionalConditionMode: boolean,
  isAdditionalActionMode: boolean,
  isChangeConditionMode: boolean,
  isChangeConditionStateMode: boolean,
  isChangeActionMode: boolean,
  isChangeActionStateMode: boolean,
  isChangeSensorMode: boolean,
  isChangeSensorStateMode: boolean,
  isChangeSubDeviceMode: boolean,
  isChangeSubDeviceStateMode: boolean,
  sceneMode: string,
  currentCondition: IConditionFront,
  initialCondition: IConditionFront,
  currentAction: {
    id: number,
    actionType: string,
    actionDescription: string,
    stateType: string,
    stateDescription: string,
    actionDeviceNumber: number,
    actionDeviceName: string,
    actionZWaveCapabilityId: number,
    actionDeviceId: number,
    colorType: string,
    deviceType: string,
    actionDeviceStateInfo: {
      data: {
        type: number,
        value: number,
        number: number,
      },
    } | {},
    delay: {
      day: number,
      hour: number,
      minute: number,
      second: number,
    } | {},
    delayDescription: string,
    duration: {
      day: number,
      hour: number,
      minute: number,
      second: number,
    } | {},
    durationDescription: string,
    durationValueAfter: string,
    colorTypeDurationValueAfter: string,
    roomInfo: {
      roomNumber: number,
      roomValue: number,
    },
    groupInfo: {
      groupNumber: number,
      groupValue: number,
    },
    selectedUsers: Array<IUser>,
    text: string,
    requestUrl: string,
    requestType: string,
    requestBody: Array<IRequestBody>,
    requestHeader: Array<IRequestHeader>,
    subDevices: Array<ISubDevice>,
    currentSubDevice: { number: number, name: string },
  },
  initialAction: {
    id: number,
    actionType: string,
    actionDescription: string,
    stateType: string,
    stateDescription: string,
    actionDeviceNumber: number,
    actionDeviceName: string,
    actionZWaveCapabilityId: number,
    actionDeviceId: number,
    colorType: string,
    deviceType: string,
    actionDeviceStateInfo: {
      data: {
        type: number,
        value: number,
        number: number,
      },
    } | {},
    delay: {
      day: number,
      hour: number,
      minute: number,
      second: number,
    } | {},
    delayDescription: string,
    duration: {
      day: number,
      hour: number,
      minute: number,
      second: number,
    } | {},
    durationDescription: string,
    durationValueAfter: string,
    colorTypeDurationValueAfter: string,
    roomInfo: {
      roomNumber: number,
      roomValue: number,
    },
    groupInfo: {
      groupNumber: number,
      groupValue: number,
    },
    selectedUsers: Array<IUser>,
    text: string,
    requestUrl: string,
    requestType: string,
    requestBody: Array<IRequestBody>,
    requestHeader: Array<IRequestHeader>,
    subDevices: Array<ISubDevice>,
    currentSubDevice: { number: number, name: string },
  },
  isSaveSceneDraftPopupOpen: boolean,
  screenName: string,
  sceneReviewConditionTypes: Array<string>,
  sceneActionTypes: Array<string>,
}

const initialState = {
  isCreateConditionMode: false,
  isCreateActionMode: false,
  isEditConditionMode: false,
  isEditActionMode: false,
  isAdditionalConditionMode: false,
  isAdditionalActionMode: false,
  isChangeConditionMode: false,
  isChangeConditionStateMode: false,
  isChangeActionMode: false,
  isChangeActionStateMode: false,
  isChangeSensorMode: false,
  isChangeSensorStateMode: false,
  isChangeSubDeviceMode: false,
  isChangeSubDeviceStateMode: false,
  sceneMode: 'init',
  currentCondition: {
    type: '',
    description: 'none',
    id: 0,
    operand: '',
    deviceType: '',
    conditionDeviceNumber: 0,
    conditionDeviceName: '',
    stateDescription: '',
    stateTimeData: '',
    timeOperand: '',
    conditionNumber: 0,
    conditionZWaveCapabilityId: 0,
    conditionDeviceId: 0,
    sensorType: 0,
    sensorScales: [],
    deviceStateInfo: {},
    subDevicesAmount: 0,
    sensorTypeDescription: '',
    sensorValueDescription: '',
    sensorEventType: 0,
    sensorEventDescription: '',
    anotherSceneNumber: 0,
    geofenceNumber: 0,
    geofenceName: '',
    usersInfo: {},
    geofenceExit: false,
    roomInfo: {
      roomNumber: 0,
      roomValue: 0,
    },
    groupInfo: {
      groupNumber: 0,
      groupValue: 0,
    },
    dataValue: 0,
    dataNumber: 0,
    dataOperand: '',
    triggeringDevice: {
      deviceType: 0,
      deviceNumber: 0,
      deviceName: '',
      deviceState: -1,
    },
    observingDevice: {
      deviceType: 0,
      deviceNumber: 0,
      deviceName: '',
    },
    wallmoteSettings: [],
  },
  initialCondition: {
    type: '',
    description: 'none',
    id: 0,
    operand: '',
    deviceType: '',
    conditionDeviceNumber: 0,
    conditionDeviceName: '',
    stateDescription: '',
    stateTimeData: '',
    timeOperand: '',
    conditionNumber: 0,
    conditionZWaveCapabilityId: 0,
    conditionDeviceId: 0,
    sensorType: 0,
    sensorScales: [],
    deviceStateInfo: {},
    subDevicesAmount: 0,
    sensorTypeDescription: '',
    sensorValueDescription: '',
    sensorEventType: 0,
    sensorEventDescription: '',
    anotherSceneNumber: 0,
    geofenceNumber: 0,
    geofenceName: '',
    usersInfo: {},
    geofenceExit: false,
    roomInfo: {
      roomNumber: 0,
      roomValue: 0,
    },
    groupInfo: {
      groupNumber: 0,
      groupValue: 0,
    },
    dataValue: 0,
    dataNumber: 0,
    dataOperand: '',
    triggeringDevice: {
      deviceType: 0,
      deviceNumber: 0,
      deviceName: '',
      deviceState: -1,
    },
    observingDevice: {
      deviceType: 0,
      deviceNumber: 0,
      deviceName: '',
    },
    wallmoteSettings: [],
  },
  currentAction: {
    id: 0,
    actionType: '',
    actionDescription: 'none',
    stateType: '',
    stateDescription: 'none',
    actionDeviceNumber: 0,
    actionDeviceName: '',
    actionZWaveCapabilityId: 0,
    actionDeviceId: 0,
    actionDeviceStateInfo: {},
    deviceType: '',
    colorType: '',
    delay: {},
    delayDescription: '',
    duration: {},
    durationDescription: '',
    durationValueAfter: 'none',
    colorTypeDurationValueAfter: '',
    roomInfo: {
      roomNumber: 0,
      roomValue: 0,
    },
    groupInfo: {
      groupNumber: 0,
      groupValue: 0,
    },
    selectedUsers: [],
    text: '',
    requestUrl: '',
    requestType: 'post',
    requestBody: [{ value_type: 'key_text', key: '', value: '' }],
    requestHeader: [{ key: '', value: '' }],
    subDevices: [],
    currentSubDevice: { number: 0, name: '' },
  },
  initialAction: {
    id: 0,
    actionType: '',
    actionDescription: 'none',
    stateType: '',
    stateDescription: 'none',
    actionDeviceNumber: 0,
    actionDeviceName: '',
    actionZWaveCapabilityId: 0,
    actionDeviceId: 0,
    actionDeviceStateInfo: {},
    deviceType: '',
    colorType: '',
    delay: {},
    delayDescription: '',
    duration: {},
    durationDescription: '',
    durationValueAfter: 'none',
    colorTypeDurationValueAfter: '',
    roomInfo: {
      roomNumber: 0,
      roomValue: 0,
    },
    groupInfo: {
      groupNumber: 0,
      groupValue: 0,
    },
    selectedUsers: [],
    text: '',
    requestUrl: '',
    requestType: 'post',
    requestBody: [{ value_type: 'key_text', key: '', value: '' }],
    requestHeader: [{ key: '', value: '' }],
    subDevices: [],
    currentSubDevice: { number: 0, name: '' },
  },
  isSaveSceneDraftPopupOpen: false,
  screenName: '',
  sceneReviewConditionTypes: [],
  sceneActionTypes: [],
}

export default function currentSceneCreating(state: ICurrentSceneCreatingActionsState = initialState, action: TCurrentSceneCreatingActions) {
  switch (action.type) {
  case type.SAVE_CONDITION_TYPE: {
    return update(state, {
      currentCondition: {
        type: { $set: action.conditionType },
      },
    })
  }

  case type.SAVE_CONDITION_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        description: { $set: action.description },
      },
    })
  }
  case type.SAVE_CONDITION_ID:
    return update(state, {
      currentCondition: {
        id: { $set: action.id },
      },
    })
  case type.SAVE_CONDITION_NUMBER:
    return update(state, {
      currentCondition: {
        conditionNumber: { $set: action.conditionNumber },
      },
    })
  case type.SAVE_CONDITION_OPERAND: {
    return update(state, {
      currentCondition: {
        operand: { $set: action.operand },
      },
    })
  }
  case type.SAVE_CONDITION_STATE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        stateDescription: { $set: action.stateDescription },
      },
    })
  }
  case type.CLEAR_CURRENT_CONDITION_STATE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        stateDescription: { $set: '' },
      },
    })
  }

  case type.SAVE_CONDITION_DEVICE_STATE_INFO:
    return update(state, {
      currentCondition: {
        deviceStateInfo: { $set: action.deviceStateInfo },
      },
    })

  case type.SAVE_SUB_DEVICES_AMOUNT:
    return update(state, {
      currentCondition: {
        subDevicesAmount: { $set: action.subDevicesAmount },
      },
    })

  case type.SELECT_CONDITION_DEVICE_TYPE: {
    return update(state, {
      currentCondition: {
        deviceType: { $set: action.deviceType },
      },
    })
  }
  case type.SAVE_CONDITION_DEVICE_NUMBER: {
    return update(state, {
      currentCondition: {
        conditionDeviceNumber: { $set: action.conditionDeviceNumber },
      },
    })
  }
  case type.SAVE_CONDITION_DEVICE_NAME: {
    return update(state, {
      currentCondition: {
        conditionDeviceName: { $set: action.name },
      },
    })
  }
  case type.SAVE_CONDITION_ZWAVE_CAPABILITY_ID: {
    return update(state, {
      currentCondition: {
        conditionZWaveCapabilityId: { $set: action.conditionZWaveCapabilityId },
      },
    })
  }
  case type.SAVE_CONDITION_DEVICE_ID: {
    return update(state, {
      currentCondition: {
        conditionDeviceId: { $set: action.conditionDeviceId },
      },
    })
  }
  case type.SAVE_BINARY_SENSOR_TYPE: {
    return update(state, {
      currentCondition: {
        sensorType: { $set: action.sensorType },
      },
    })
  }
  case type.SAVE_BINARY_SENSOR_TYPE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        sensorTypeDescription: { $set: action.description },
      },
    })
  }
  case type.SAVE_BINARY_SENSOR_VALUE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        sensorValueDescription: { $set: action.description },
      },
    })
  }
  case type.SAVE_MULTILEVEL_SENSOR_TYPE: {
    return update(state, {
      currentCondition: {
        sensorType: { $set: action.sensorType },
      },
    })
  }

  case type.SAVE_MULTILEVEL_SENSOR_SCALES: {
    return update(state, {
      currentCondition: {
        sensorScales: { $set: action.scales },
      },
    })
  }

  case type.SAVE_MULTILEVEL_SENSOR_TYPE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        sensorTypeDescription: { $set: action.description },
      },
    })
  }
  case type.SAVE_MULTILEVEL_SENSOR_VALUE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        sensorValueDescription: { $set: action.description },
      },
    })
  }

  case type.SAVE_SENSOR_NOTIFICATIONS_EVENT_TYPE: {
    return update(state, {
      currentCondition: {
        sensorEventType: { $set: action.sensorEventType },
      },
    })
  }

  case type.SAVE_SENSOR_NOTIFICATIONS_EVENT_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        sensorEventDescription: { $set: action.sensorEventDescription },
      },
    })
  }

  case type.SAVE_SENSOR_NOTIFICATIONS_TYPE: {
    return update(state, {
      currentCondition: {
        sensorType: { $set: action.sensorType },
      },
    })
  }
  case type.SAVE_SENSOR_NOTIFICATIONS_TYPE_DESCRIPTION: {
    return update(state, {
      currentCondition: {
        sensorTypeDescription: { $set: action.description },
      },
    })
  }

  case type.SAVE_CONDITION_ANOTHER_SCENE_NUMBER: {
    return update(state, {
      currentCondition: {
        anotherSceneNumber: { $set: action.anotherSceneNumber },
      },
    })
  }
  case type.SAVE_CONDITION_STATE_TIME_DATA: {
    return update(state, {
      currentCondition: {
        stateTimeData: { $set: action.stateTimeData },
      },
    })
  }
  case type.SAVE_CONDITION_TIME_OPERAND: {
    return update(state, {
      currentCondition: {
        timeOperand: { $set: action.timeOperand },
      },
    })
  }
  case type.SAVE_CONDITION_GEOFENCE_NUMBER: {
    return update(state, {
      currentCondition: {
        geofenceNumber: { $set: action.geofenceNumber },
      },
    })
  }
  case type.SAVE_CONDITION_GEOFENCE_NAME: {
    return update(state, {
      currentCondition: {
        geofenceName: { $set: action.geofenceName },
      },
    })
  }
  case type.SAVE_CONDITION_USERS_INFO: {
    return update(state, {
      currentCondition: {
        usersInfo: { $set: action.usersInfo },
      },
    })
  }
  case type.SAVE_CONDITION_GEOFENCE_EXIT: {
    return update(state, {
      currentCondition: {
        geofenceExit: { $set: action.geofenceExit },
      },
    })
  }
  case type.SAVE_CONDITION_ROOM_NUMBER: {
    return update(state, {
      currentCondition: {
        roomInfo: {
          roomNumber: { $set: action.roomNumber },
        },
      },
    })
  }
  case type.SAVE_CONDITION_ROOM_STATE: {
    return update(state, {
      currentCondition: {
        roomInfo: {
          roomValue: { $set: action.roomValue },
        },
      },
    })
  }
  case type.SAVE_CONDITION_DATA_VALUE: {
    return update(state, {
      currentCondition: {
        dataValue: { $set: action.dataValue },
      },
    })
  }
  case type.SAVE_CONDITION_DATA_NUMBER: {
    return update(state, {
      currentCondition: {
        dataNumber: { $set: action.dataNumber },
      },
    })
  }

  case type.SAVE_CONDITION_DATA_OPERAND: {
    return update(state, {
      currentCondition: {
        dataOperand: { $set: action.dataOperand },
      },
    })
  }

  case type.SAVE_CONDITION_GROUP_NUMBER: {
    return update(state, {
      currentCondition: {
        groupInfo: {
          groupNumber: { $set: action.groupNumber },
        },
      },
    })
  }
  case type.SAVE_CONDITION_GROUP_STATE: {
    return update(state, {
      currentCondition: {
        groupInfo: {
          groupValue: { $set: action.groupValue },
        },
      },
    })
  }

  case type.CLEAR_TRIGGERING_AND_OBSERVING_DEVICES: {
    return update(state, {
      currentCondition: {
        triggeringDevice: {
          deviceType: { $set: 0 },
          deviceNumber: { $set: 0 },
          deviceName: { $set: '' },
          deviceState: { $set: -1 },
        },
        observingDevice: {
          deviceType: { $set: 0 },
          deviceNumber: { $set: 0 },
          deviceName: { $set: '' },
        },
      },
    })
  }

  case type.SAVE_TRIGGERING_DEVICE_TYPE: {
    return update(state, {
      currentCondition: {
        triggeringDevice: {
          deviceType: { $set: action.deviceType },
        },
      },
    })
  }

  case type.SAVE_TRIGGERING_DEVICE_NUMBER: {
    return update(state, {
      currentCondition: {
        triggeringDevice: {
          deviceNumber: { $set: action.deviceNumber },
        },
      },
    })
  }

  case type.SAVE_TRIGGERING_DEVICE_NAME: {
    return update(state, {
      currentCondition: {
        triggeringDevice: {
          deviceName: { $set: action.deviceName },
        },
      },
    })
  }

  case type.SAVE_TRIGGERING_DEVICE_STATE: {
    return update(state, {
      currentCondition: {
        triggeringDevice: {
          deviceState: { $set: action.deviceState },
        },
      },
    })
  }

  case type.SAVE_OBSERVING_DEVICE_TYPE: {
    return update(state, {
      currentCondition: {
        observingDevice: {
          deviceType: { $set: action.deviceType },
        },
      },
    })
  }

  case type.SAVE_OBSERVING_DEVICE_NUMBER: {
    return update(state, {
      currentCondition: {
        observingDevice: {
          deviceNumber: { $set: action.deviceNumber },
        },
      },
    })
  }

  case type.SAVE_OBSERVING_DEVICE_NAME: {
    return update(state, {
      currentCondition: {
        observingDevice: {
          deviceName: { $set: action.deviceName },
        },
      },
    })
  }

  case type.SAVE_WALLMOTE_SETTINGS: {
    return update(state, {
      currentCondition: {
        wallmoteSettings: { $set: action.wallmoteSettings },
      },
    })
  }

  case type.CLEAR_CURRENT_CONDITION_INFO: {
    return update(state, {
      currentCondition: { $set: initialState.initialCondition },
    })
  }

  case type.SAVE_INITIAL_CONDITION_INFO: {
    return update(state, {
      initialCondition: { $set: state.currentCondition },
    })
  }
  case type.CLEAR_INITIAL_CONDITION_INFO: {
    return update(state, {
      initialCondition: { $set: initialState.initialCondition },
    })
  }

  case type.RESTORE_CONDITION_INFO: {
    return update(state, {
      currentCondition: { $set: state.initialCondition },
    })
  }

  case type.SAVE_REVIEW_SCENE_CONDITION_TYPES: {
    return update(state, {
      sceneReviewConditionTypes: { $set: action.conditionTypes },
    })
  }

  case type.ADD_NEW_CONDITION_TO_CHAIN: {
    return update(state, {
      sceneReviewConditionTypes: { $push: [action.conditionType] },
    })
  }
  case type.SAVE_EDITED_CONDITION: {
    return update(state, {
      currentCondition: { $set: action.condition },
    })
  }

  //  actions
  case type.SAVE_EDITED_ACTION: {
    return update(state, {
      currentAction: { $set: action.action },
    })
  }

  case type.ADD_NEW_ACTION_TO_CHAIN: {
    return update(state, {
      sceneActionTypes: { $push: [action.actionType] },
    })
  }

  case type.SAVE_INITIAL_ACTION_INFO: {
    return update(state, {
      initialAction: { $set: state.currentAction },
    })
  }

  case type.CLEAR_INITIAL_ACTION_INFO: {
    return update(state, {
      initialAction: { $set: initialState.initialAction },
    })
  }

  case type.RESTORE_ACTION_INFO: {
    return update(state, {
      currentAction: { $set: state.initialAction },
    })
  }

  case type.SAVE_ACTION_TYPE: {
    return update(state, {
      currentAction: {
        actionType: { $set: action.actionType },
      },
    })
  }
  case type.SAVE_ACTION_DESCRIPTION: {
    return update(state, {
      currentAction: {
        actionDescription: { $set: action.actionDescription },
      },
    })
  }
  case type.SAVE_ACTION_STATE_DESCRIPTION: {
    return update(state, {
      currentAction: {
        stateDescription: { $set: action.stateDescription },
      },
    })
  }
  case type.SAVE_ACTION_ID:
    return update(state, {
      currentAction: {
        id: { $set: action.id },
      },
    })
  case type.SAVE_ACTION_COLOR_TYPE: {
    return update(state, {
      currentAction: {
        colorType: { $set: action.colorType },
      },
    })
  }
  case type.SAVE_DURATION_VALUE_AFTER_ACTION_COLOR_TYPE: {
    return update(state, {
      currentAction: {
        colorTypeDurationValueAfter: { $set: action.colorTypeDurationValueAfter },
      },
    })
  }
  case type.SAVE_ACTION_DEVICE_TYPE: {
    return update(state, {
      currentAction: {
        deviceType: { $set: action.deviceType },
      },
    })
  }
  case type.SAVE_ACTION_DEVICE_STATE_INFO:
    return update(state, {
      currentAction: {
        actionDeviceStateInfo: { $set: action.actionDeviceStateInfo },
      },
    })
  case type.SAVE_ACTION_DEVICE_NUMBER: {
    return update(state, {
      currentAction: {
        actionDeviceNumber: { $set: action.actionDeviceNumber },
      },
    })
  }
  case type.SAVE_ACTION_DEVICE_NAME: {
    return update(state, {
      currentAction: {
        actionDeviceName: { $set: action.name },
      },
    })
  }
  case type.SAVE_ACTION_ZWAVE_CAPABILITY_ID: {
    return update(state, {
      currentAction: {
        actionZWaveCapabilityId: { $set: action.actionZWaveCapabilityId },
      },
    })
  }
  case type.SAVE_ACTION_DEVICE_ID: {
    return update(state, {
      currentAction: {
        actionDeviceId: { $set: action.actionDeviceId },
      },
    })
  }

  case type.SAVE_ACTION_DELAY:
    return update(state, {
      currentAction: {
        delay: { $set: action.delay },
      },
    })

  case type.SAVE_ACTION_DELAY_DESCRIPTION:
    return update(state, {
      currentAction: {
        delayDescription: { $set: action.delayDescription },
      },
    })

  case type.SAVE_ACTION_DURATION:
    return update(state, {
      currentAction: {
        duration: { $set: action.duration },
      },
    })

  case type.SAVE_ACTION_DURATION_DESCRIPTION:
    return update(state, {
      currentAction: {
        durationDescription: { $set: action.durationDescription },
      },
    })

  case type.SAVE_ACTION_DURATION_VALUE_AFTER:
    return update(state, {
      currentAction: {
        durationValueAfter: { $set: action.value },
      },
    })

  case type.SAVE_ACTION_ROOM_NUMBER: {
    return update(state, {
      currentAction: {
        roomInfo: {
          roomNumber: { $set: action.roomNumber },
        },
      },
    })
  }

  case type.SAVE_ACTION_ROOM_STATE: {
    return update(state, {
      currentAction: {
        roomInfo: {
          roomValue: { $set: action.roomValue },
        },
      },
    })
  }

  case type.SAVE_ACTION_GROUP_NUMBER: {
    return update(state, {
      currentAction: {
        groupInfo: {
          groupNumber: { $set: action.groupNumber },
        },
      },
    })
  }

  case type.SAVE_ACTION_GROUP_STATE: {
    return update(state, {
      currentAction: {
        groupInfo: {
          groupValue: { $set: action.groupValue },
        },
      },
    })
  }

  case type.SAVE_TEXT:
    return update(state, {
      currentAction: {
        text: { $set: action.text },
      },
    })

  case type.SAVE_SELECTED_USERS:
    return update(state, {
      currentAction: {
        selectedUsers: { $set: action.selectedUsers },
      },
    })

  case type.SAVE_REQUEST_URL:
    return update(state, {
      currentAction: {
        requestUrl: { $set: action.requestUrl },
      },
    })

  case type.SAVE_REQUEST_TYPE:
    return update(state, {
      currentAction: {
        requestType: { $set: action.requestType },
      },
    })

  case type.UPDATE_REQUEST_BODY: {
    const currentData = action.data
    const newRequestBody = update(state.currentAction.requestBody, { [action.number]: { [action.entryType]: { $set: currentData } } })
    return update(state, {
      currentAction: {
        requestBody: { $set: newRequestBody },
      },
    })
  }

  case type.PUSH_NEW_TO_REQUEST_BODY:
    return update(state, {
      currentAction: {
        requestBody: { $push: [{ value_type: 'key_text', key: '', value: '' }] },
      },
    })

  case type.UPDATE_REQUEST_HEADER: {
    const currentData = action.data
    const newRequestHeader = update(state.currentAction.requestHeader, { [action.number]: { [action.entryType]: { $set: currentData } } })
    return update(state, {
      currentAction: {
        requestHeader: { $set: newRequestHeader },
      },
    })
  }
  case type.PUSH_NEW_TO_REQUEST_HEADER:
    return update(state, {
      currentAction: {
        requestHeader: { $push: [{ key: '', value: '' }] },
      },
    })
  case type.SAVE_REQUEST_HEADER:
    return update(state, {
      currentAction: {
        requestHeader: { $set: action.header },
      },
    })
  case type.SAVE_REQUEST_BODY:
    return update(state, {
      currentAction: {
        requestBody: { $set: action.body },
      },
    })
  case type.SAVE_CURRENT_ACTION_SUBDEVICES_LIST:
    return update(state, {
      currentAction: {
        subDevices: { $set: action.devices },
      },
    })
  case type.SAVE_CURRENT_ACTION_SUBDEVICE:
    return update(state, {
      currentAction: {
        currentSubDevice: { $set: action.device },
      },
    })

  case type.CLEAR_CURRENT_ACTION_INFO: {
    return update(state, {
      currentAction: { $set: initialState.initialAction },
    })
  }

  case type.CLEAR_CURRENT_SCENE_INFO: {
    return initialState
  }

  case type.OPEN_SCENE_SAVE_DRAFT_POPUP:
    return { ...state, isSaveSceneDraftPopupOpen: true }
  case type.CLOSE_SCENE_SAVE_DRAFT_POPUP:
    return { ...state, isSaveSceneDraftPopupOpen: false }

  case type.ACTIVATE_EDIT_CONDITION_MODE:
    return { ...state, isEditConditionMode: true }
  case type.DEACTIVATE_EDIT_CONDITION_MODE:
    return { ...state, isEditConditionMode: false }

  case type.ACTIVATE_CREATE_CONDITION_MODE:
    return { ...state, isCreateConditionMode: true }
  case type.DEACTIVATE_CREATE_CONDITION_MODE:
    return { ...state, isCreateConditionMode: false }

  case type.ACTIVATE_CREATE_ACTION_MODE:
    return { ...state, isCreateActionMode: true }
  case type.DEACTIVATE_CREATE_ACTION_MODE:
    return { ...state, isCreateActionMode: false }

  case type.ACTIVATE_ADDITIONAL_CONDITION_MODE:
    return { ...state, isAdditionalConditionMode: true }
  case type.DEACTIVATE_ADDITIONAL_CONDITION_MODE:
    return { ...state, isAdditionalConditionMode: false }
  case type.ACTIVATE_ADDITIONAL_ACTION_MODE:
    return { ...state, isAdditionalActionMode: true }
  case type.DEACTIVATE_ADDITIONAL_ACTION_MODE:
    return { ...state, isAdditionalActionMode: false }

  case type.ACTIVATE_EDIT_ACTION_MODE:
    return { ...state, isEditActionMode: true }
  case type.DEACTIVATE_EDIT_ACTION_MODE:
    return { ...state, isEditActionMode: false }

  case type.SAVE_SCREEN_NAME:
    return { ...state, screenName: action.screenName }

  case type.CHANGE_SCENE_MODE:
    return { ...state, sceneMode: action.sceneMode }

  case type.ACTIVATE_CHANGE_ACTION_MODE:
    return { ...state, isChangeActionMode: true }
  case type.DEACTIVATE_CHANGE_ACTION_MODE:
    return { ...state, isChangeActionMode: false }
  case type.ACTIVATE_CHANGE_ACTION_STATE_MODE:
    return { ...state, isChangeActionStateMode: true }
  case type.DEACTIVATE_CHANGE_ACTION_STATE_MODE:
    return { ...state, isChangeActionStateMode: false }

  case type.ACTIVATE_CHANGE_CONDITION_MODE:
    return { ...state, isChangeConditionMode: true }
  case type.DEACTIVATE_CHANGE_CONDITION_MODE:
    return { ...state, isChangeConditionMode: false }
  case type.ACTIVATE_CHANGE_CONDITION_STATE_MODE:
    return { ...state, isChangeConditionStateMode: true }
  case type.DEACTIVATE_CHANGE_CONDITION_STATE_MODE:
    return { ...state, isChangeConditionStateMode: false }

  case type.ACTIVATE_CHANGE_SENSOR_MODE:
    return { ...state, isChangeSensorMode: true }
  case type.DEACTIVATE_CHANGE_SENSOR_MODE:
    return { ...state, isChangeSensorMode: false }
  case type.ACTIVATE_CHANGE_SENSOR_STATE_MODE:
    return { ...state, isChangeSensorStateMode: true }
  case type.DEACTIVATE_CHANGE_SENSOR_STATE_MODE:
    return { ...state, isChangeSensorStateMode: false }

  case type.ACTIVATE_CHANGE_SUB_DEVICE_MODE:
    return { ...state, isChangeSubDeviceMode: true }
  case type.DEACTIVATE_CHANGE_SUB_DEVICE_MODE:
    return { ...state, isChangeSubDeviceMode: false }
  case type.ACTIVATE_CHANGE_SUB_DEVICE_STATE_MODE:
    return { ...state, isChangeSubDeviceStateMode: true }
  case type.DEACTIVATE_CHANGE_SUB_DEVICE_STATE_MODE:
    return { ...state, isChangeSubDeviceStateMode: false }

  case type.RESET_CURRENT_SCENE_CREATING:
    return initialState
  default:
    return state
  }
}
