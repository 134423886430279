import update from 'immutability-helper'
import * as type from '@app/constants/syncCounters/countersStatus'

const initialState = {
  countersStatus: {},
}

export default function countersStatus(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_COUNTERS_STATUS:
    return update(state, {
      countersStatus: { $set: action.countersStatus },
    })
  case type.RESET_COUNTERS_STATUS:
    return initialState

  default:
    return state
  }
}
