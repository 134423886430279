import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { withStyles } from '@app/components/styles'
import { patchGeofence, saveGeofenceInfo, syncGeofences } from '@app/actions/geofences/geofences'
import {
  saveConditionDescription,
  saveConditionGeofenceName,
  saveConditionGeofenceNumber,
  saveConditionType,
} from '@app/actions/scenes/currentSceneCreating'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import { isIOS } from 'react-device-detect'
import SelectGeofencesCondition
  from '@app/components/MyAutomation/Tabs/ScenesScreens/CreateCondition/GeofencesCondition/SelectGeofencesCondition/SelectGeofencesCondition'
import SelectGeofencesConditionIOS
  from '@app/components/MyAutomation/Tabs/iOS/CreateCondition/GeofencesCondition/SelectGeofencesCondition/SelectGeofencesCondition'
import {
  withAccessDenied, withNotifications, withProgressAndErrorMessage, withRefresh, withRouter,
} from '@app/hocs'

const isWebView = navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv')

let enhance = isIOS && isWebView ? withStyles(SelectGeofencesConditionIOS) : withStyles(SelectGeofencesCondition)

const mapStateToProps = (state) => {
  const { isMobile } = state.mobileDetector
  const { path } = state.shadowIn
  const { geofencesList } = state.geofences
  const { isEditConditionMode, isChangeConditionStateMode } = state.scenes.currentSceneCreating
  return {
    isMobile,
    path,
    geofencesList,
    isEditConditionMode,
    isChangeConditionStateMode,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refresh: syncGeofences,
  tryAgain: syncGeofences,
  syncGeofences,
  saveGeofenceInfo,
  patchGeofence,
  saveConditionType,
  saveConditionDescription,
  saveConditionGeofenceNumber,
  saveConditionGeofenceName,
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withNotifications(enhance)
enhance = withRefresh(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const SelectGeofencesConditionEnhanced = enhance

export default withRouter(SelectGeofencesConditionEnhanced)
