import React from 'react'
import { useDispatch } from 'react-redux'
import { closeAllTabs, closeMenu } from '@app/actions/menu'
import { ChevronRight } from 'icons'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
// @ts-ignore
import elementsData from '@app/lib/Menu/ElementsData'
import { IAppConfig } from '@app/@types/appConfig'
import { supportLink } from '@app/lib/Help/help'
import { useNavigate } from 'react-router-dom'
import MenuItem from './MenuItem'

interface Props {
  appConfig: IAppConfig,
}

const Other: React.FC<Props> = (props) => {
  const { appConfig } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const otherConfig = appConfig.menu ? appConfig.menu.other : defaultConfig.menu.other

  const openMenuItem = (path: string) => {
    navigate(path)
    dispatch(closeMenu())
  }

  return (
    <>
      {otherConfig.map((item) => {
        if (item.state === States.Invisible) {
          return null
        }
        if (item.code === 'help') {
          return (
            <a
              href={supportLink}
              target="_blank"
              rel="noreferrer"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
              key={item.code}
            >
              <ListItem disabled={item.state === States.Disabled}>
                <ListItemIcon style={{ minWidth: '45px' }}>{elementsData[item.code].icon}</ListItemIcon>
                <ListItemText primary={I18n.t(`titles.${item.code}`)} />
                <ChevronRight />
              </ListItem>
            </a>
          )
        }
        return (
          <MenuItem
            key={item.code}
            icon={elementsData[item.code].icon}
            title={item.code}
            open={() => openMenuItem(elementsData[item.code].path)}
            close={() => dispatch(closeAllTabs())}
            disabled={item.state === States.Disabled}
          />
        )
      })}
    </>
  )
}

export default Other
