import { INTERVIEW_CHANNEL_ZWAVE } from '@app/constants/channels'
import { closeZwaveChannel, openZwaveChannel } from '@app/actions/myDevices/channels/zwave/zwaveChannel'
import openCable from '@app/api/cable'

const myDevicesZwaveChannel = ({ request, response }, id) => {
  const cable = openCable()
  const subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find(
    (sub) => sub.identifier.includes(INTERVIEW_CHANNEL_ZWAVE),
  )
  if (!subscription) {
    return cable.subscriptions.create({
      channel: INTERVIEW_CHANNEL_ZWAVE,
      id,
    }, {
      connected() {
        request(this)
        store.dispatch(openZwaveChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeZwaveChannel())
      },
    })
  }
  return subscription
}

export default {
  myDevicesZwaveChannel,
}
