import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
// @ts-ignore
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import TwrTakeOnBoardDeviceType
  from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/twr/TakeOnBoardDeviceTypes/TwrTakeOnBoardDeviceTypes/TwrTakeOnBoardDeviceTypes'
import { withAccessDenied, withNotifications, withProgressAndErrorMessage } from '@app/hocs'
import { AppState } from '@app/@types/store'
import { AppDispatch } from '@app/store'

let enhance = TwrTakeOnBoardDeviceType

const mapStateToProps = (state: AppState) => {
  const { currentDevice, currentTakeOnBoardDeviceType } = state.myDevices.myDevices
  const { isMobile } = state.mobileDetector
  return {
    currentDevice,
    currentTakeOnBoardDeviceType,
    isMobile,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = withNotifications(enhance)
// @ts-ignore
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const TwrTakeOnBoardDeviceTypeEnhanced = enhance

export default TwrTakeOnBoardDeviceTypeEnhanced
