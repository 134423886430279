export const GET_CLIENT_CREDENTIALS_TOKEN = '@@secrets/getClientCredentialsToken'
export const GET_CLIENT_CREDENTIALS_TOKEN_DONE = '@@secrets/getClientCredentialsTokenDone'

export const SAVE_CLIENT_CREDENTIALS_TOKEN = '@@secrets/saveClientCredentialsToken'
export const CLEAR_CLIENT_CREDENTIALS_TOKEN = '@@secrets/clearClientCredentialsToken'

export const UPDATE_SECRETS = '@@secrets/update'
export const RESET_SECRETS = '@@secrets/reset'

export const UPDATE_ACCESS_TOKEN = '@@accessToken/update'

export const AUTHENTIFICATION_SUCCESS = '@@accessToken/authentificationSuccess'

export const SYNC_SECRETS = '@@secrets/sync'

export const REFRESH_TOKEN = '@@secrets/refreshToken'
export const REFRESH_TOKEN_INTERCEPTOR = '@@secrets/refreshTokenInterceptor'

export const TOKEN_STATUS_REFRESHING = '@@secrets/tokenStatusRefreshing'
export const TOKEN_STATUS_REFRESHED = '@@secrets/tokenStatusRefreshed'
export const TOKEN_STATUS_NONE = '@@secrets/tokenStatusNone'
