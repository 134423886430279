import React, { Component } from 'react'
import classNames from 'classnames'
import { Avatar, Button, Paper } from '@mui/material'
import convertRequestToForm from '@app/helpers/convertRequestToForm'
import { USERS } from '@app/constants/routes'
import { roleInput, roleValues } from '@app/lib/Users/roleValues'

import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import WarningComponent from '@app/components/Common/Warning/WarningComponent'
import Info from '@app/components/Common/Info/Info'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import Text from './Text/Text'
import SelectString from '../MemberDetails/SelectString/SelectString'

class UserRequestDetails extends Component {
  state ={
    confirmRejectPopupOpen: false,
    confirmApprovePopupOpen: false,
  }

  onBackClick=() => {
    const { navigate, resetCurrentRequest } = this.props
    navigate(USERS)
    resetCurrentRequest()
  }

  rejectPopupToggle =() => {
    const { confirmRejectPopupOpen } = this.state
    this.setState({ confirmRejectPopupOpen: !confirmRejectPopupOpen })
  }

  approvePopupToggle =() => {
    const { confirmApprovePopupOpen } = this.state
    this.setState({ confirmApprovePopupOpen: !confirmApprovePopupOpen })
  }

  toggleInfoPopup = () => {
    const { infoPopupOpen } = this.state
    this.setState({ infoPopupOpen: !infoPopupOpen })
  }

  closeRequestScreen = () => {
    const { closeRequestInfoPopup, resetCurrentRequest } = this.props
    closeRequestInfoPopup()
    const { navigate } = this.props
    navigate(USERS)
    resetCurrentRequest()
  }

  approveRequest =() => {
    const { submit } = this.props
    this.approvePopupToggle()
    submit()
    this.toggleInfoPopup()
  }

  rejectRequest=() => {
    const { reject } = this.props
    this.rejectPopupToggle()
    reject()
  }

  renderText = (el) => (
    <Text
      title={I18n.t(`my_users.${el.hint}`)}
      value={el.value}
      key={el.title}
    />
  )

  render() {
    const {
      confirmRejectPopupOpen,
      confirmApprovePopupOpen,
    } = this.state
    const {
      submitting,
      pristine,
      classes,
      request,
      isInfoPopupOpen,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title={I18n.t('my_users.request_details')}
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
          customTitle
        />
        <form>
          <Wrapper className={classes.headerWrapper}>
            <Avatar src={request.avatar} className={classes.avatar} />
            <SelectString
              name={roleInput}
              title={I18n.t(`my_users.${roleInput}`)}
              number={0}
              values={roleValues}
              startValue={0}
            />
          </Wrapper>
          <Wrapper className={classes.fieldsWrapper}>
            { convertRequestToForm(request).map((el) => this.renderText(el)) }
          </Wrapper>
          <Wrapper className={classes.buttonWrapper}>
            <Button
              onClick={this.rejectPopupToggle}
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              {I18n.t('buttons.reject')}
            </Button>
            <Button
              onClick={this.approvePopupToggle}
              variant="outlined"
              color="primary"
              className={classes.button}
              disabled={pristine || submitting}
            >
              {I18n.t('buttons.approve')}
            </Button>
          </Wrapper>
        </form>
        <WarningComponent
          open={confirmRejectPopupOpen}
          onClose={this.rejectPopupToggle}
          onClick={this.rejectRequest}
          warningText="confirmations.reject_confirmation"
          leftButton="okay"
          rightButton="cancel"
        />
        <WarningComponent
          open={confirmApprovePopupOpen}
          onClose={this.approvePopupToggle}
          onClick={this.approveRequest}
          warningText="confirmations.approve_confirmation"
          leftButton="okay"
          rightButton="cancel"
        />
        <Info
          open={isInfoPopupOpen}
          title="my_users.info"
          warningText="my_users.approve_info"
          onClick={this.closeRequestScreen}
          buttonName="buttons.okay"
        />
      </Paper>
    )
  }
}

UserRequestDetails.propTypes = {
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  request: PropTypes.request,
  reject: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isInfoPopupOpen: PropTypes.bool.isRequired,
  closeRequestInfoPopup: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  resetCurrentRequest: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

UserRequestDetails.defaultProps = {
  request: null,
}

UserRequestDetails.displayName = 'UserRequestDetails'

export default UserRequestDetails
