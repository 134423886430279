import { createLogic } from 'redux-logic'
import { SYNC_MEZZO_LIGHTS } from '@app/constants/myDevices/myDevices'
import { updateMezzoLights } from '@app/actions/myDevices/mezzoLights'
import Api from '@app/api/myDevices/myDevices'
import handleErrors from '@app/helpers/handleErrors'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchMezzoLightsLogic = createLogic({
  type: SYNC_MEZZO_LIGHTS,
  cancelType: API_REQUEST_CANCEL,

  async process(_, dispatch, done) {
    const { secrets: { accessToken: { token } } } = store.getState()
    dispatch(apiRequestSent(SYNC_MEZZO_LIGHTS))
    try {
      const mezzoLights = await Api.fetchMezzoLights(token)
      const { data: { value } } = mezzoLights
      dispatch(updateMezzoLights(value))
      dispatch(apiRequestDone(SYNC_MEZZO_LIGHTS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('SYNC_MEZZO_LIGHTS failed without error')
      }
    }
    done()
  },
})

export default fetchMezzoLightsLogic
