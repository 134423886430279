import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M129.909 97.5078V35.1641H19.0756V84.0636C19.5053 83.997 19.9485 83.9528 20.4052 83.9528H38.4157C42.2396 83.9636 45.3292 86.4353 45.3427 89.4945V97.5078H129.908H129.909Z" fill="currentColor"/>
      <path d="M132.687 26H16.3125C11.7271 26.0108 8.01353 28.9812 8 32.65V99.9038C8 101.577 8.62344 103.129 9.77344 104.37C10.3552 104.991 11.0755 105.523 11.9484 105.922C12.4333 106.144 12.974 106.31 13.5417 106.421V32.6504C13.5417 32.0409 13.8463 31.4975 14.3594 31.0875C14.8724 30.6775 15.5505 30.4333 16.313 30.4333H132.688C133.45 30.4333 134.129 30.6771 134.641 31.0875C135.154 31.4979 135.459 32.0404 135.459 32.6504V99.9042C135.459 100.514 135.154 101.057 134.641 101.467C134.128 101.877 133.449 102.121 132.688 102.121H45.4068V106.554H71.8125V115.554H46.7641V119.987H102.416V115.554H77.3542V106.554H132.687C137.273 106.543 140.986 103.573 141 99.9042V32.6504C140.986 28.9817 137.273 26.0113 132.687 26.0004V26Z" fill="currentColor"/>
      <path d="M31.1296 108.601C31.1296 109.47 30.2491 110.175 29.1624 110.175C28.0757 110.175 27.1952 109.47 27.1952 108.601C27.1952 107.732 28.0757 107.027 29.1624 107.027C30.2491 107.027 31.1296 107.732 31.1296 108.601Z" fill="currentColor"/>
      <path d="M31.1296 96.5464C31.1296 97.4158 30.2491 98.1202 29.1624 98.1202C28.0757 98.1202 27.1952 97.4158 27.1952 96.5464C27.1952 95.6771 28.0757 94.9727 29.1624 94.9727C30.2491 94.9727 31.1296 95.6771 31.1296 96.5464Z" fill="currentColor"/>
      <path d="M31.1296 102.46C31.1296 103.33 30.2491 104.034 29.1624 104.034C28.0757 104.034 27.1952 103.33 27.1952 102.46C27.1952 101.591 28.0757 100.887 29.1624 100.887C30.2491 100.887 31.1296 101.591 31.1296 102.46Z" fill="currentColor"/>
      <path d="M37.8401 88.375H21.2709C20.7026 88.375 20.1901 88.5633 19.8027 88.8625C19.4146 89.1729 19.1927 89.5829 19.1927 90.0375V122.179C19.2068 123.709 20.7302 124.939 22.6563 124.95H36.4547C38.3808 124.939 39.9042 123.709 39.9183 122.179V90.0375C39.9183 89.5833 39.6964 89.1729 39.3083 88.8625C38.9203 88.5521 38.4214 88.375 37.8396 88.375H37.8401ZM35.7615 95.7121V121.625H23.3479V91.7667H35.7615V95.7121Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'FullTv')

export default icon
