import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles<ITheme>((theme) => createStyles({
  card: {
    width: 200,
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    boxSizing: 'border-box',
    backgroundColor: theme.backgrounds.cardBackground,
    color: theme.texts.main,
    boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.25)',
  },
  cardMobile: {
    width: '100%',
    height: 108,
  },
  cardInfo: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    padding: '1rem',
  },
  cardInfoMobile: {
    display: 'flex',
    paddingBottom: 0,
  },
  cardIconContainer: {
    width: 70,
    height: 55,
    backgroundColor: theme.backgrounds.componentBackground,
    borderRadius: 3,
    marginBottom: '.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 28,
  },
  nameAndStatus: {
    width: 'calc(80% - 70px)',
    marginLeft: '.5rem',
  },
  cardName: {
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 20,
  },
  cardNameMobile: {
    fontSize: 16,
  },
  cardStatus: {
    fontSize: 16,
  },
  cardControl: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  chevronIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    transform: 'rotate(180deg)',
  },
  lightsValue: {
    color: theme.palette.primary.main,
    fontSize: 16,
    marginBottom: 10,
  },
  switchRoot: {
    margin: 0,
    marginRight: 10,
  },
  controlWrapper: {
    display: 'flex',
    marginRight: '1rem',
    justifyContent: 'flex-end',
  },
  dragging: {
    cursor: 'pointer',
  },
}))

export default useStyles
