import { createLogic } from 'redux-logic'
import { CLOSE_DEVICE_INFO_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { DEVICE_INFO_CHANNEL } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeDeviceInfoLogic = createLogic({
  type: CLOSE_DEVICE_INFO_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(DEVICE_INFO_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeDeviceInfoLogic
