import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5916 1.96875C13.9666 1.96875 14.2791 2.10938 14.5291 2.39062C14.8104 2.64062 14.951 2.95312 14.951 3.32812V12.617L5.05472 2.72073C5.11472 2.60069 5.19474 2.49065 5.29477 2.39062C5.57602 2.10938 5.90414 1.96875 6.27914 1.96875H7.91977V0H11.951V1.96875H13.5916ZM4.91977 5.41421L14.951 15.4455V15.4601L19.0901 19.5992L17.6777 21.0117L14.951 18.285V18.6562C14.951 19.0312 14.8104 19.3438 14.5291 19.5938C14.2791 19.8438 13.9666 19.9688 13.5916 19.9688H6.27914C5.90414 19.9688 5.57602 19.8438 5.29477 19.5938C5.04477 19.3438 4.91977 19.0312 4.91977 18.6562V8.25375L0 3.33398L1.41245 1.92153L4.91977 5.42885V5.41421Z" fill="currentColor"/>
    </svg>

  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'LostBatteryIcon')

export default icon
