import * as type from '@app/constants/scenes/devicesTabs'
import { InferValueTypes } from '@app/@types/typesHelper'
import * as actions from '@app/actions/scenes/devicesTabs'

type IDevicesTabs = ReturnType<InferValueTypes<typeof actions>>

interface IDevicesTabsState {
  currentTab: number,
}
const initialState = {
  currentTab: 0,
}

const devicesTabs = (state: IDevicesTabsState = initialState, action: IDevicesTabs) => {
  switch (action.type) {
  case type.CHOOSE_TAB: {
    return {
      ...state,
      currentTab: action.chosenTab,
    }
  }
  case type.RESET_DEVICE_TABS:
    return initialState

  default:
    return state
  }
}

export default devicesTabs
