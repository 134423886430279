import React, {
  useEffect, useState, Dispatch, SetStateAction,
} from 'react'
import {
  Typography, Radio,
} from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { IConfigParameter, IConfigParameterValue } from '@app/@types/myDevices'
import { Optional } from '@app/@types/common'
import useStyles from './Single.style'

interface Props {
  currentParameter: IConfigParameter,
  setTemporaryValues: Dispatch<SetStateAction<Optional<Array<IConfigParameterValue>>>>,
  isMobile: boolean,
}

const Single: React.FC<Props> = (props) => {
  const {
    currentParameter, setTemporaryValues, isMobile,
  } = props
  const classes = useStyles()
  const [values, setValues] = useState<Array<IConfigParameterValue>>(currentParameter.values)

  useEffect(() => {
    setValues(currentParameter.values)
  }, [currentParameter])

  const handleChange = (parameter: IConfigParameterValue) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(values.map((param) => (
      param.description === parameter.description
        ? { ...param, isEnabled: event.target.checked }
        : { ...param, isEnabled: false })))
    setTemporaryValues(values.map((param) => (
      param.description === parameter.description
        ? { ...param, isEnabled: event.target.checked }
        : { ...param, isEnabled: false })))
  }

  return (
    <Wrapper className={classes.root}>
      {values.map((parameter, index) => (
        <Wrapper className={classNames(classes.card, isMobile && classes.cardMobile)} key={`parameter - ${index + 1}`}>
          <Radio
            className={classes.radioButton}
            checked={parameter.isEnabled}
            onChange={handleChange(parameter)}
            value={parameter.value}
          />
          <Typography className={classNames(classes.cardDescription)}>
            {parameter.description}
          </Typography>
        </Wrapper>
      ))}
    </Wrapper>
  )
}

export default Single
