import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    marginBottom: '3rem',
  },
  listSortControls: {
    margin: '1.5rem auto',
    width: '100%',
  },
  controlField: {
    marginRight: '2rem',
  },
  listContainer: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  action: {
    textAlign: 'center',
  },
  paginationAndLimitContainer: {
    display: 'flex',
  },
  pagination: {
    flex: 9,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textAlign: 'center',
    textDecoration: 'none',
    color: 'black',
  },
  limit: {
    flex: 1.5,
  },
  total: {
    alignSelf: 'center',
    textAlign: 'center',
    paddingRight: '1rem',
  },
  noDevices: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
})

export default useStyles
