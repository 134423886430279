import React, { useEffect } from 'react'
import { Paper, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { IZwaveOrTwrDevice, TDevice } from '@app/@types/myDevices'
import TwrHealth from '@app/components/MyDevices/DeviceInfo/DeviceStats/TwrHealth/TwrHealth'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { fetchTwrDevicesHealth } from '@app/actions/twrDevicesHealth'
import { syncDeviceInfoChannel } from '@app/actions/myDevices/deviceInfo/deviceInfoChannel'
import { clearCurrentDevice, syncMyDevices } from '@app/actions/myDevices/myDevices'
import useStyles from './TwrDevicesHealth.style'

interface Props {
  devices: Array<TDevice>,
  twrDevices: Array<IZwaveOrTwrDevice>,
}

const TwrDevicesHealth: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { devices, twrDevices } = props

  useEffect(() => {
    if (devices.length === 0) {
      dispatch(syncMyDevices())
    }
    dispatch(clearCurrentDevice())
    dispatch(syncDeviceInfoChannel())
    dispatch(fetchTwrDevicesHealth())
  }, [])

  return (
    <Paper className={classes.root}>
      {twrDevices.length === 0 ? (
        <Typography>NO TWR DEVICES</Typography>
      ) : (
        <Wrapper className={classes.grid}>
          {twrDevices.map((twrDevice, index) => (
            <TwrHealth key={`twrHealth-${index + 1}`} currentDevice={twrDevice} twrDevicesHealthScreen />
          ))}
        </Wrapper>
      )}
    </Paper>
  )
}

export default TwrDevicesHealth
