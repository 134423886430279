import { createLogic } from 'redux-logic'
import Api from '@app/api/contacts'
import handleErrors from '@app/helpers/handleErrors'
import { UPDATE_AVATAR } from '@app/constants/contacts/contacts'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import { setAvatar } from '@app/actions/contacts/contacts'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'

const updateAvatar = createLogic({
  type: UPDATE_AVATAR,
  cancelType: API_REQUEST_CANCEL,

  process({ action }, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    dispatch(apiRequestSent(UPDATE_AVATAR))
    action.avatar.append('user_contact_id', action.contactId)
    Api.updateUserAvatar(AccessToken, action.avatar)
      .then((response) => {
        dispatch(setAvatar(response.data))
        dispatch(apiRequestDone(UPDATE_AVATAR))
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('updateUserAvatar failed without error')
        }
      })
      .then(() => done())
  },
})

export default updateAvatar
