import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import getActionDeviceStateInfo from '@app/helpers/scenes/getActionDeviceStateInfo'
import calculateActionType from '@app/helpers/scenes/calculateActionType'
import {
  selectBinarySwitchActionSelectedDelay,
  selectBinarySwitchActionSelectedDuration,
  selectBinarySwitchActionSelectedValue,
} from '@app/lib/Scenes/actions'
import {
  ADJUST_DELAY_MULTILEVEL_SWITCH,
  ADJUST_DURATION_MULTILEVEL_SWITCH,
  CREATE_ACTION_SELECTED,
  EDIT_ACTION,
  SELECT_MULTILEVEL_SWITCH_ACTION_TYPE,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectMultilevelSwitchActionStateSelected extends React.Component {
  onChooseValueClick = () => {
    const {
      navigate,
      saveActionDelay,
      saveActionDelayDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDelay({})
    saveActionDelayDescription('')
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
    navigate(SELECT_MULTILEVEL_SWITCH_ACTION_TYPE)
  }

  onChooseDelayClick =() => {
    const {
      navigate,
    } = this.props
    navigate(ADJUST_DELAY_MULTILEVEL_SWITCH)
  }

  onChooseDurationClick=() => {
    const {
      navigate,
      saveActionDurationValueAfter,
      saveInitialActionInfo,
    } = this.props
    navigate(ADJUST_DURATION_MULTILEVEL_SWITCH)
    saveInitialActionInfo()
    saveActionDurationValueAfter('none')
  }

  clearDelay=() => {
    const {
      saveActionDelay,
      saveActionDelayDescription,
    } = this.props
    saveActionDelay({})
    saveActionDelayDescription('')
  }

  clearDuration=() => {
    const {
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
    } = this.props
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
  }

  onAddStateClick = () => {
    const {
      navigate,
      isEditActionMode,
      saveActionType,
      stateDescription,
      saveActionDeviceStateInfo,
      actionDeviceNumber,
      actionDelay,
      durationValueAfter,
      actionDuration,
    } = this.props
    const actionType = calculateActionType(stateDescription, actionDelay, actionDuration)
    saveActionType(actionType)
    const actionDeviceStateInfo = getActionDeviceStateInfo(actionType, stateDescription,
      actionDeviceNumber, actionDelay, durationValueAfter, actionDuration, '', '')
    saveActionDeviceStateInfo(actionDeviceStateInfo)
    if (isEditActionMode) {
      navigate(EDIT_ACTION)
    } else {
      navigate(CREATE_ACTION_SELECTED)
    }
  }

  render() {
    const {
      classes,
      isMobile,
      stateDescription,
      delayDescription,
      durationDescription,
      durationValueAfter,
    } = this.props
    const durationFullDescription = `${durationDescription} then ${durationValueAfter}`
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={null}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper>
            <CardBlock
              key={I18n.t(`scene_web.${selectBinarySwitchActionSelectedValue.title}`)}
              title={I18n.t(`scene_web.${selectBinarySwitchActionSelectedValue.title}`)}
              description={stateDescription}
              position={selectBinarySwitchActionSelectedValue.position}
              buttonName={I18n.t(`scene_web.${selectBinarySwitchActionSelectedValue.buttonName}`)}
              isCardDisabled={selectBinarySwitchActionSelectedValue.isCardDisabled}
              isButtonDisabled={selectBinarySwitchActionSelectedValue.isButtonDisabled}
              onClick={this.onChooseValueClick}
            />
            {!(stateDescription === 'toggle')
            && (
              <CardBlock
                key={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDelay.title}`)}
                title={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDelay.title}`)}
                description={delayDescription || I18n.t(`scene_web.${selectBinarySwitchActionSelectedDelay.description}`)}
                position={selectBinarySwitchActionSelectedDelay.position}
                buttonName={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDelay.buttonName}`)}
                isCardDisabled={selectBinarySwitchActionSelectedValue.isCardDisabled}
                isButtonDisabled={selectBinarySwitchActionSelectedValue.isButtonDisabled}
                onClick={this.onChooseDelayClick}
                hasClearButton={!!delayDescription}
                clearDescription={this.clearDelay}
              />
            )}
            <CardBlock
              key={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.title}`)}
              title={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.title}`)}
              description={durationDescription
                ? durationFullDescription
                : I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.description}`)}
              position={selectBinarySwitchActionSelectedDuration.position}
              buttonName={I18n.t(`scene_web.${selectBinarySwitchActionSelectedDuration.buttonName}`)}
              isCardDisabled={selectBinarySwitchActionSelectedValue.isCardDisabled}
              isButtonDisabled={selectBinarySwitchActionSelectedValue.isButtonDisabled}
              onClick={this.onChooseDurationClick}
              hasClearButton={!!durationDescription}
              clearDescription={this.clearDuration}
            />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onAddStateClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectMultilevelSwitchActionStateSelected.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  stateDescription: PropTypes.string.isRequired,
  saveActionType: PropTypes.func.isRequired,
  saveActionDeviceStateInfo: PropTypes.func.isRequired,
  actionDeviceNumber: PropTypes.number.isRequired,
  delayDescription: PropTypes.string.isRequired,
  actionDelay: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  actionDuration: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
  }).isRequired,
  durationDescription: PropTypes.string.isRequired,
  durationValueAfter: PropTypes.string.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  saveInitialActionInfo: PropTypes.func.isRequired,
}

SelectMultilevelSwitchActionStateSelected.displayName = 'SelectBinarySwitchActionStateSelectedIOS'

export default SelectMultilevelSwitchActionStateSelected
