import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { FETCH_PILLS_REMINDERS } from '@app/constants/pillsReminders/pillsReminders'
import Api from '@app/api/pillsReminders/fetchPillsReminders'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { setPillsReminders } from '@app/actions/pillsReminders/pillsReminders'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchPillsReminders = createLogic({
  type: FETCH_PILLS_REMINDERS,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(FETCH_PILLS_REMINDERS))
    try {
      const reminders = await Api.fetchPillsReminders(action.accessToken)
      dispatch(setPillsReminders(reminders.data))
      dispatch(apiRequestDone(FETCH_PILLS_REMINDERS))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('fetchPillsReminders failed without error')
      }
    }
    done()
  },
})

export default fetchPillsReminders
