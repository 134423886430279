import { makeStyles, createStyles } from '@mui/styles'
import { ITheme } from '@mui/material/styles'

const useStyles = makeStyles < ITheme >((theme) => createStyles({
  button: {
    width: '20%',
  },
  paper: {
    padding: 20,
  },
  avatarIcon: {
    marginRight: 20,
  },
  webView: {
    background: theme.webView.background,
  },
}))

export default useStyles
