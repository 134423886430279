import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 500,
  },
  main: {
    width: '90%',
    padding: '25px 0',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '17px 4%',
    marginBottom: 26,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
  },
  cardLeftSideWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  timeCard: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '17px 4%',
    marginBottom: 26,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
  },
  cardTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 24,
  },
  cardTitleMobile: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 14,
  },
  cardDescription: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.texts.subText,
  },
  cardDescriptionMobile: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  button: {
    width: '100%',
    height: 50,
  },
  setTimeContainer: {
    display: 'flex',
    transition: 'max-height 0.3s linear',
    maxHeight: 0,
    width: '100%',
    justifyContent: 'space-between',
  },
  setTimeContainerMobile: {
    display: 'flex',
    transition: 'max-height 0.3s linear',
    maxHeight: 0,
    width: '100%',
    flexDirection: 'column',
  },
  setTimeContainerActive: {
    maxHeight: 200,
    transition: 'max-height 0.3s linear',
  },
  setTimeContainerActiveMobile: {
    maxHeight: 400,
    transition: 'max-height 0.3s linear',
  },
  setTimeCardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '49%',
    backgroundColor: theme.backgrounds.cardBackground,
    padding: '17px 4%',
    marginBottom: 26,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    position: 'relative',
  },
  setTimeCardWrapperMobile: {
    width: '100%',
  },
  datePicker: {
    display: 'none',
  },
  timePicker: {
    display: 'none',
  },
  recommendationsLeftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  recommendations: {
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    backgroundColor: theme.backgrounds.cardBackground,
    '&:before': {
      height: 0,
    },
    marginBottom: 140,
  },
  recommendationsMobile: {
    marginBottom: 80,
  },
  recommendationsSummary: {
    padding: '17px 4%',
    height: 85,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  recommendationsDetails: {
    padding: '17px 4%',
    position: 'relative',
    boxSizing: 'border-box',
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  },
  mainQuestion: {
    margin: '20px 0 20px 0',
    fontSize: '18px',
    lineHeight: '21px',
  },
  mainQuestionMobile: {
    margin: '12px 0 12px 0',
    fontSize: '14px',
    lineHeight: '16px',
  },
  question: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: 8,
  },
  questionMobile: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 8,
  },
  answer: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.texts.subText,
    marginBottom: 15,
  },
  answerMobile: {
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.texts.subText,
    marginBottom: 15,
  },
  calendarIcon: {
    fontSize: 25,
  },
}))

export default useStyles
