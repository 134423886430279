export const recommendations = [
  {
    question: `${I18n.t('mezzo_time.mezzo_online')}`,
    answer: `${I18n.t('mezzo_time.recommendation_mezzo_online')}`,
  },
  {
    question: `${I18n.t('mezzo_time.wrong_geolocation')}`,
    answer: `${I18n.t('mezzo_time.recommendation_wrong_geolocation')}`,
  },
  {
    question: `${I18n.t('mezzo_time.method_ethernet')}`,
    answer: `${I18n.t('mezzo_time.recommendation_method_ethernet')}`,
  },
  {
    question: `${I18n.t('mezzo_time.method_cellular')}`,
    answer: `${I18n.t('mezzo_time.recommendation_method_cellular')}`,
  },
]
