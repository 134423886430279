const OAuthApplications = {
  smartlink: {
    clientId: 'c06e7fa325293b3fbfc5b4a0aeb65f34da2cba25eaab505c061971006e443e63',
    redirectUri: 'https://smartlink.nessosphere.com/web/home',
    responseType: 'code',
    secret: '332f03d70cc22952eea2f9e27c914fa818abf9032a96718bff12a238353b8dc3',
    scope: 'user',
  },
  lendlease: {
    clientId: 'c06e7fa325293b3fbfc5b4a0aeb65f34da2cba25eaab505c061971006e443e63',
    redirectUri: 'https://lendlease.nessosphere.com/web/home',
    responseType: 'code',
    secret: '332f03d70cc22952eea2f9e27c914fa818abf9032a96718bff12a238353b8dc3',
    scope: 'user',
  },
  slc01: {
    clientId: 'c06e7fa325293b3fbfc5b4a0aeb65f34da2cba25eaab505c061971006e443e63',
    redirectUri: 'https://lendlease.nessosphere.com/web/home',
    responseType: 'code',
    secret: '332f03d70cc22952eea2f9e27c914fa818abf9032a96718bff12a238353b8dc3',
    scope: 'user',
  },
  cloud: {
    clientId: 'e12acbea92a5ef80c750f2a126065ee1d3a492b3491d2496baee5cb36befdec4',
    redirectUri: 'https://cloud.nessosphere.com/web/home',
    responseType: 'code',
    secret: '59e9178df9a6437e5fc8a02b91953037968936c2db12b188adcb3316c48edfdd',
    scope: 'user',
  },
  staging: {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://staging.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'lendlease-web': {
    clientId: 'c06e7fa325293b3fbfc5b4a0aeb65f34da2cba25eaab505c061971006e443e63',
    redirectUri: 'https://lendlease-web.nesscs.com/web/home',
    responseType: 'code',
    secret: '332f03d70cc22952eea2f9e27c914fa818abf9032a96718bff12a238353b8dc3',
    scope: 'user',
  },
  'smartlink-web': {
    clientId: 'c06e7fa325293b3fbfc5b4a0aeb65f34da2cba25eaab505c061971006e443e63',
    redirectUri: 'https://smartlink-web.nesscs.com/web/home',
    responseType: 'code',
    secret: '332f03d70cc22952eea2f9e27c914fa818abf9032a96718bff12a238353b8dc3',
    scope: 'user',
  },
  'mezzo-web': {
    clientId: 'e12acbea92a5ef80c750f2a126065ee1d3a492b3491d2496baee5cb36befdec4',
    redirectUri: 'https://mezzo-web.nesscs.com/web/home',
    responseType: 'code',
    secret: '59e9178df9a6437e5fc8a02b91953037968936c2db12b188adcb3316c48edfdd',
    scope: 'user',
  },
  'staging-dev': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://staging-dev.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'cloud-sphere': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://cloud-sphere.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'cloud-sphere-dev': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://cloud-sphere-dev.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'lendlease-sphere': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://lendlease-sphere.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'lendlease-sphere-dev': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://lendlease-sphere-dev.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'smartlink-sphere': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://smartlink-sphere.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'smartlink-sphere-dev': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://smartlink-sphere-dev.nessosphere.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'pony-web': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://pony-web.nesscs.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  'ocelot-web': {
    clientId: 'f0ad865bf6cd2707bd891f1caaadc03bb6d4047073b5d0c2b71fa4ea68e01e35',
    redirectUri: 'https://ocelot-web.nesscs.com/web/home',
    responseType: 'code',
    secret: '5a0e3d91ed62606a746bfd33d565086f39b4bd62739928fd7fa749526df5aea0',
    scope: 'user',
  },
  // dev in browser
  'dev-web': {
    clientId: '668fce6c00afb3c5ecab8e5bc90f427e322c8726bb90e1d7f70adc55f710ac18',
    redirectUri: 'http://dev-web.mezzo.local:3000/web/home',
    responseType: 'code',
    secret: '65adcd069faf536e07c4057716370f00c4765203d7c837558621dbee8dac9fc9',
    scope: 'user',
  },
  'dev-ncentral-web': {
    clientId: '668fce6c00afb3c5ecab8e5bc90f427e322c8726bb90e1d7f70adc55f710ac18',
    redirectUri: 'http://dev-ncentral-web.mezzo.local:3000/web/home',
    responseType: 'code',
    secret: '65adcd069faf536e07c4057716370f00c4765203d7c837558621dbee8dac9fc9',
    scope: 'user',
  },
  // Capybara specs in browser
  'test-web': {
    clientId: '668fce6c00afb3c5ecab8e5bc90f427e322c8726bb90e1d7f70adc55f710ac18',
    redirectUri: 'http://test-web.mezzo.local:4000/web/home',
    responseType: 'code',
    secret: '65adcd069faf536e07c4057716370f00c4765203d7c837558621dbee8dac9fc9',
    scope: 'user',
  },
}

export default OAuthApplications
