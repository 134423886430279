import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { GET_TAKE_ON_BOARD_DEVICE_TYPE } from '@app/constants/myDevices/twrDevices'
import Api from '@app/api/myDevices/deviceSettings/twrDevices'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestDone, apiRequestFailed, apiRequestSent } from '@app/actions/apiRequest'
import { saveTakeOnBoardDeviceType } from '@app/actions/myDevices/twrDevices'

const getTakeOnBoardDeviceType = createLogic({
  type: GET_TAKE_ON_BOARD_DEVICE_TYPE,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    next({
      ...action,
      accessToken,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(apiRequestSent(GET_TAKE_ON_BOARD_DEVICE_TYPE))
    try {
      const deviceType = await Api.getTakeOnBoardDeviceType(action.accessToken, action.id)
      dispatch(saveTakeOnBoardDeviceType(deviceType.data.take_on_board_device_type))
      dispatch(apiRequestDone(GET_TAKE_ON_BOARD_DEVICE_TYPE))
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('GET_TAKE_ON_BOARD_DEVICE_TYPE failed without error')
      }
    }
    done()
  },
})

export default getTakeOnBoardDeviceType
