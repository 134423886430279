const styles = (theme) => ({
  root: {
    height: '100%',
    border: 'none',
    paddingBottom: 60,
  },
  title: {
    display: 'block',
    margin: '0 auto',
    fontSize: '1rem',
  },
  consumptionsItem: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonText: {
    fontSize: '0.1rem',
    fontTransform: 'none',
  },
  buttonInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    border: 'none',
  },
  webView: {
    background: theme.webView.background,
  },
  zeroData: {
    marginTop: 100,
    fontSize: 18,
  },
})

export default styles
