export const OPEN_EDIT_DRAFT_SAVE_POPUP = '@@editScene/openEditDraftSavePopup'
export const CLOSE_EDIT_DRAFT_SAVE_POPUP = '@@editScene/closeEditDraftSavePopup'

export const OPEN_EDIT_SCENE_QUIT_POPUP = '@@editScene/openEditSceneQuitPopup'
export const CLOSE_EDIT_SCENE_QUIT_POPUP = '@@editScene/closeEditSceneQuitPopup'

export const PUT_CHANGESET = '@@editScene/putChangeset'

export const PATCH_CHANGESET = '@@editScene/patchChangeset'

export const SAVE_CHANGESET_ACTION = '@@editScene/saveChangesetAction'

export const SAVE_EDITED_SCENE_INITIAL_NAME = '@@editScene/saveEditedSceneInitialName'
export const SAVE_EDITED_SCENE_INITIAL_CONDITIONS = '@@editScene/saveEditedSceneInitialConditions'
export const SAVE_EDITED_SCENE_INITIAL_ACTIONS = '@@editScene/saveEditedSceneInitialActions'

export const RESET_EDIT_SCENE = '@@editScene/reset'

export const SELECT_SCENE_INITIAL_ID = '@@editScene/selectSceneInitialId'
