import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_APPROVE_REQUEST } from '@app/constants/users/currentRequest'
import { openRequestInfoPopup } from '@app/actions/users/currentRequest'
import { roleValues } from '@app/lib/Users/roleValues'
import Api from '@app/api/users/approveUserRequest'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const approveUserRequestLogic = createLogic({
  type: SET_APPROVE_REQUEST,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { form: { UserRequestDetailsForm: { values: { choose_role } } } } = getState()
    const roleData = { role: roleValues[choose_role] }
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { users: { currentRequest: { request: { id: requestId } } } } = getState()
    next({
      ...action,
      accessToken,
      requestId,
      roleData,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_APPROVE_REQUEST))
    Api.approveUserRequest(action.accessToken, action.requestId, action.roleData)
      .then(() => {
        dispatch(openRequestInfoPopup())
        dispatch(apiRequestDone(SET_APPROVE_REQUEST))
      })
      .catch((error) => {
        if (error) {
          if (error.response.data.error.code === 340) {
            dispatch(apiRequestFailed(error.response.data.error.message))
          } else {
            const currentError = handleErrors(error)
            dispatch(apiRequestFailed(currentError))
          }
        } else {
          throw new Error('approveUserRequest failed without error')
        }
      })
      .then(() => done())
  },
})

export default approveUserRequestLogic
