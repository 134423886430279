const convertMezzoLightStateFromEditedConditionInfo = (state: number): string => {
  switch (state) {
  case 0:
    return 'is off'
  case 1:
    return 'is low'
  case 2:
    return 'is medium'
  case 3:
    return 'is high'
  case 4:
    return 'is any level'
  default:
    return ''
  }
}
export default convertMezzoLightStateFromEditedConditionInfo
