import React from 'react'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { swatchColors } from '@app/lib/Scenes/colorActions'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import PropTypes from '@app/components/PropTypes'
import styles from './Swatches.style'

class Swatches extends React.PureComponent {
  state = {
    chosenColorIndex: 0,
  }

  handleChooseColor = (index) => {
    this.setState({ chosenColorIndex: index })
  }

  render() {
    const { classes, changeColor } = this.props
    const { chosenColorIndex } = this.state
    return (
      <Wrapper className={classes.colorsContainer}>
        {swatchColors.map((color, index) => (
          <Wrapper
            key={`color-${index + 1}`}
            className={classes.singleColor}
            onClick={() => {
              this.handleChooseColor(index)
              changeColor(swatchColors[index])
            }}
          >
            <div
              className={classes.colorPreview}
              style={{ backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` }}
            />
            <div
              className={
                classNames(
                  classes.colorControls,
                  index === chosenColorIndex && classes.chosenControlsColor,
                )
              }
            />
          </Wrapper>
        ))}
      </Wrapper>
    )
  }
}

Swatches.propTypes = {
  classes: PropTypes.object.isRequired,
  changeColor: PropTypes.func.isRequired,
}

export default withStyles(styles)(Swatches)
