import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'

const ENDPOINT = `${API_URL}/api/sphere_sort`

const putSphereSort = (accessToken, model, field, order) => Axios.put(ENDPOINT, '', {
  params: {
    model,
    field,
    order,
  },
  headers: {
    'Access-Token': accessToken,
  },
})

export default {
  putSphereSort,
}
