import {
  VALIDATION_REG_EXP_EMAIL,
  VALIDATION_REG_EXP_FIRST_NAME,
  VALIDATION_REG_EXP_LAST_NAME,
  VALIDATION_REG_EXP_MIDDLE_NAME,
  VALIDATION_REG_EXP_MOBILE,
  VALIDATION_REG_EXP_PREFERRED_NAME,
} from '@app/lib/Users/userEditvalidationRegExps'

export const validate = (values) => {
  const errors = {}
  const firstName = 'firstName'
  const lastName = 'lastName'
  const middleName = 'middleName'
  const preferredName = 'preferredName'
  const mobile = 'mobile'
  const email = 'email'

  const requiredFields = [firstName, lastName, preferredName, mobile, email]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = I18n.t('validations.name_required')
    }
  })
  if (values[firstName] && !VALIDATION_REG_EXP_FIRST_NAME.test(values[firstName])) {
    errors[firstName] = I18n.t('my_users.first_name_validation')
  }
  if (values[lastName] && !VALIDATION_REG_EXP_LAST_NAME.test(values[lastName])) {
    errors[lastName] = I18n.t('my_users.last_name_validation')
  }
  if (values[middleName] && !VALIDATION_REG_EXP_MIDDLE_NAME.test(values[middleName])) {
    errors[middleName] = I18n.t('my_users.middle_name_validation')
  }
  if (values[preferredName] && !VALIDATION_REG_EXP_PREFERRED_NAME.test(values[preferredName])) {
    errors[preferredName] = I18n.t('my_users.preferred_name_validation')
  }
  if (values[mobile] && !VALIDATION_REG_EXP_MOBILE.test(values[mobile])) {
    errors[mobile] = I18n.t('my_users.phone_number_validation')
  }
  if (values[email] && !VALIDATION_REG_EXP_EMAIL.test(values[email])) {
    errors[email] = I18n.t('my_users.email_validation')
  }
  return errors
}
