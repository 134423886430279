import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  titleContainer: {
    textAlign: 'center',
    paddingBottom: '.5rem',
  },
  title: {
    fontSize: 22,
  },
  formContainer: {
    textAlign: 'center',
    minHeight: 200,
    width: 250,
    margin: '0 auto',
  },
  input: {
    width: '100%',
    margin: '0.5rem auto',
    '& .MuiInput-input': {
      paddingLeft: 5,
    },
  },
  btn: {
    margin: '1rem auto',
    textTransform: 'none',
  },
  googleButton: {
    marginBottom: '1rem',
    width: 200,
    height: 40,
    position: 'relative',
    textTransform: 'none',
  },
  googleLink: {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: 12,
  },
})

export default useStyles
