import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.531 20.0012L21.1714 7.36105L18.367 6.34033L15.6362 13.8432L13.7568 13.1591L16.4876 5.65629L13.6832 4.63557L9.0428 17.2757L16.531 20.0012ZM22.3453 5.64333C22.7564 5.79297 23.046 6.04802 23.2141 6.40851C23.3822 6.76899 23.3915 7.15479 23.2418 7.5659L18.4535 20.7216C18.3039 21.1327 18.0489 21.4223 17.6884 21.5904C17.3279 21.7585 16.9421 21.7677 16.531 21.6181L16.0611 21.4471L15.7191 22.3868L16.6588 22.7288C17.1778 22.9177 17.4454 23.4915 17.2565 24.0105C17.0676 24.5295 16.4937 24.7971 15.9748 24.6082L6.57784 21.188C6.05886 20.9991 5.79127 20.4253 5.98017 19.9063C6.16906 19.3873 6.7429 19.1197 7.26188 19.3086L8.20157 19.6506L8.54359 18.7109L8.07375 18.5399C7.66263 18.3903 7.37302 18.1352 7.20493 17.7748C7.03683 17.4143 7.0276 17.0285 7.17724 16.6174L11.9655 3.46166C12.1152 3.05054 12.3702 2.76094 12.7307 2.59284C13.0912 2.42475 13.477 2.41552 13.8881 2.56515L22.3453 5.64333ZM10.081 20.3347L13.8397 21.7027L14.1817 20.7631L10.423 19.395L10.081 20.3347ZM19.8862 19.6634L23.3171 10.2371L25.2112 10.9265L21.7803 20.3528L19.8862 19.6634ZM25.4468 13.1573L27.3409 13.8466L25.2888 19.4848L23.3947 18.7954L25.4468 13.1573ZM4.86584 14.1964L8.29673 4.77015L10.1908 5.45953L6.75991 14.8858L4.86584 14.1964ZM2.73615 11.2763L4.78827 5.63816L6.68234 6.32754L4.63022 11.9657L2.73615 11.2763Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)
const icon = createSvgIcon(fragment, 'TamperAlertIcon')

export default icon
