import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { filterNames } from '@app/lib/Events/filters'
import styles from './FilterByType.style'

class FilterByType extends React.Component {
  handleChange = (event) => {
    const {
      setFilterByType,
      clearFilterByType,
    } = this.props
    if (event.target.value === '') {
      clearFilterByType()
    } else {
      setFilterByType(event.target.value)
    }
  }

  render() {
    const { classes, filterByType } = this.props

    return (
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel className={classes.label} htmlFor="type">{I18n.t('my_events.filter_by_type')}</InputLabel>
        <Select
          variant="standard"
          value={filterByType}
          className={classes.textField}
          onChange={this.handleChange}
          inputProps={{
            name: 'type',
            id: 'type',
          }}
        >
          {filterNames.map((menuName) => (
            <MenuItem key={menuName} value={menuName}>{I18n.t(`my_events.${menuName}`)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

FilterByType.propTypes = {
  classes: PropTypes.object.isRequired,
  setFilterByType: PropTypes.func.isRequired,
  clearFilterByType: PropTypes.func.isRequired,
  filterByType: PropTypes.string.isRequired,
}

export default withStyles(styles)(FilterByType)
