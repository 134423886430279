import update from 'immutability-helper'
import * as type from '@app/constants/geofences/geofences'

type State = {
  geofencesList: Array<{
    id: number,
    name: string,
    status: string,
    latitude: number,
    longitude: number,
    size: number,
    scenes: Array<string>,
    expanded?: boolean,
  }>,
  fetchedGeofences: boolean,
  geofenceUsers: Array<string>,
  geofenceInfo: {
    address: string,
    lat: number,
    lng: number,
    size: number,
  },
}

const initialState = {
  geofencesList: [],
  fetchedGeofences: false,
  geofenceUsers: [],
  geofenceInfo: {
    address: '',
    lat: 0,
    lng: 0,
    size: 0,
  },
}

const geofences = (state: State = initialState, action: Object): State => {
  switch (action.type) {
  case type.SET_GEOFENCES:
    return {
      ...state,
      geofencesList: action.data,
      fetchedGeofences: true,
    }
  case type.TOGGLE_GEOFENCE_SIZE: return {
    ...state,
    geofencesList: state.geofencesList.map((geofence, i) => (i === action.index
      ? { ...geofence, expanded: !state.geofencesList[i].expanded }
      : geofence)),
  }
  case type.SET_POST_GEOFENCE:
    return { ...state, geofencesList: [...state.geofencesList, action.response] }
  case type.SAVE_GEOFENCE_INFO: {
    return update(state, {
      geofenceInfo: {
        address: { $set: action.address },
        lat: { $set: action.lat },
        lng: { $set: action.lng },
        size: { $set: action.size },
      },
    })
  }
  default:
    return state
  }
}

export default geofences
