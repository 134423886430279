import { createLogic } from 'redux-logic'
import { history } from '@app/store/history'
import handleErrors from '@app/helpers/handleErrors'
import { POST_NEW_PASSWORD } from '@app/constants/wizard/forgotPassword'
import { SIGN_IN } from '@app/constants/routes'
import { clearCode, clearEmail, clearRequestId } from '@app/actions/wizard/forgotPassword'
import { clearClientCredentialsToken } from '@app/actions/secrets'
import Api from '@app/api/forgotPassword/sendNewPassword'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const submitNewPasswordLogic = createLogic({
  type: POST_NEW_PASSWORD,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { login: { forgotPassword: { requestId, code } } } = getState()
    const { form: { NewPassword: { values: { new_password: password } } } } = getState()
    const { secrets: { clientCredentialsToken } } = getState()
    const request_id = requestId
    const data = { request_id, code, password }
    next({
      ...action,
      clientCredentialsToken,
      data,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(POST_NEW_PASSWORD))
    Api.sendNewPassword(action.clientCredentialsToken, action.data)
      .then(() => {
        dispatch(apiRequestDone(POST_NEW_PASSWORD))
        dispatch(clearClientCredentialsToken())
        dispatch(clearEmail())
        dispatch(clearCode())
        dispatch(clearRequestId())
        history.push(SIGN_IN)
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('sendNewPassword failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitNewPasswordLogic
