import Axios from 'axios'
import { API_URL } from '@app/constants/endpoints'
import { CHANNEL_GUARD_PANEL } from '@app/constants/channels'
import { closeGuardPanelChannel, openGuardPanelChannel } from '@app/actions/guardPanel'
import openCable from './cable'

const getGuardPanelStatus = (access_token) => Axios.get(`${API_URL}/api/unit_devices/get_arm_status`, {
  headers: {
    'Access-Token': access_token,
  },
})

const patchGuardPanelStatus = (access_token, sphereId, data) => Axios.patch(`${API_URL}/api/unit_devices/set_arm_status`, data, {
  headers: {
    'Access-Token': access_token,
  },
})

const guardPanelChannel = ({ request, response }) => {
  const cable = openCable()
  let subscription = cable.subscriptions.consumer.subscriptions.subscriptions.find((sub) => sub.identifier.includes(CHANNEL_GUARD_PANEL))
  if (!subscription) {
    subscription = cable.subscriptions.create(CHANNEL_GUARD_PANEL, {
      connected() {
        request(this)
        store.dispatch(openGuardPanelChannel())
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        store.dispatch(closeGuardPanelChannel())
      },
    })
  }
  return subscription
}

export default {
  getGuardPanelStatus,
  patchGuardPanelStatus,
  guardPanelChannel,
}
