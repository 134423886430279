import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  rootWebView: {
    marginTop: -40,
  },
  buttonWrapper: {
    display: 'flex',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  button: {
    marginTop: 10,
    marginBottom: 20,
    width: '100%',
    height: 35,
  },
  title: {
    marginBottom: 5,
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  dialogContent: {
    paddingBottom: 5,
    paddingRight: 20,
    paddingLeft: 40,
  },
}))

export default useStyles
