import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import isUserLoggedIn from '@app/helpers/isUserLoggedIn'
import { groupDeleteDevice, patchGroup } from '@app/actions/groups/groups'
import * as syncNotifications from '@app/actions/notifications/notificationsChannel'
import EditGroup from '@app/components/Groups/EditGroup/EditGroup'
import {
  withNotifications, withAccessDenied, withProgressAndErrorMessage, withRouter,
} from '@app/hocs'

let enhance = EditGroup

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.mobileDetector
  const {
    groups: {
      zWaveDevices,
      twrDevices,
    },
  } = store.getState()
  return {
    isMobile,
    isTablet,
    zWaveDevices,
    twrDevices,
    userLoggedIn: (isUserLoggedIn(state.users.currentUser.user.preferredName)),
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessToken: state.secrets.accessToken && state.secrets.accessToken.token,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  notifications: syncNotifications.sync,
  patchGroup,
  groupDeleteDevice,
}, dispatch)

enhance = withRouter(enhance)
enhance = withNotifications(enhance)
enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const EditGroupEnhanced = enhance

export default EditGroupEnhanced
