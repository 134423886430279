import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 110 110" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g fill="currentColor" clipPath="url(#clip0)">
        <path d="M8.36 101.417c.465.222 1.016.16 1.419-.162l7.524-6.018 7.525 6.018c.49.392 1.187.392 1.676 0l7.524-6.018 7.523 6.018c.49.392 1.187.392 1.677 0l8.219-6.575c5.617 11.834 18.96 17.879 31.561 14.298 12.601-3.581 20.772-15.74 19.326-28.759C100.889 67.2 90.249 57.13 77.17 56.4V37.105h17.598c.742 0 1.342-.601 1.342-1.342V10.811A10.824 10.824 0 0 0 85.817.012V0H19.813C13.07.008 7.607 5.472 7.6 12.213v87.994c0 .516.295.986.76 1.21zm91.454-18.238c0 13.333-10.808 24.14-24.14 24.141-13.332 0-24.14-10.808-24.14-24.14s10.808-24.14 24.14-24.14c13.326.015 24.124 10.814 24.14 24.14zm-6.387-72.368v23.61H77.17v-23.61a8.128 8.128 0 0 1 8.128-8.085 8.127 8.127 0 0 1 8.129 8.085zm-83.144 1.402a9.54 9.54 0 0 1 9.53-9.53h58.37a10.786 10.786 0 0 0-3.696 8.128v45.574a26.818 26.818 0 0 0-24.12 35.68l-.483.428-7.497 5.996-7.522-6.018a1.342 1.342 0 0 0-1.677 0l-7.523 6.018-7.524-6.018a1.342 1.342 0 0 0-1.677 0l-6.181 4.946V12.213z"/>
        <path d="M17.62 45.49h.598a1.341 1.341 0 1 0 0-2.682h-.597a1.342 1.342 0 0 0 0 2.683zM24.186 45.49h4.517a1.341 1.341 0 1 0 0-2.682h-4.517a1.342 1.342 0 0 0 0 2.683zM34.767 45.49H67.15a1.342 1.342 0 0 0 0-2.682H34.767a1.341 1.341 0 1 0 0 2.683zM17.62 32.75h.598a1.341 1.341 0 1 0 0-2.683h-.597a1.342 1.342 0 0 0 0 2.683zM24.186 32.75h4.517a1.341 1.341 0 1 0 0-2.683h-4.517a1.342 1.342 0 0 0 0 2.683zM34.767 32.75H67.15a1.342 1.342 0 0 0 0-2.683H34.767a1.341 1.341 0 1 0 0 2.683zM17.62 20.01h.598a1.341 1.341 0 1 0 0-2.682h-.597a1.342 1.342 0 0 0 0 2.683zM24.186 20.01h4.517a1.341 1.341 0 1 0 0-2.682h-4.517a1.342 1.342 0 0 0 0 2.683zM34.767 20.01H67.15a1.342 1.342 0 0 0 0-2.682H34.767a1.341 1.341 0 1 0 0 2.683zM17.62 58.232h.598a1.341 1.341 0 1 0 0-2.683h-.597a1.341 1.341 0 1 0 0 2.683zM24.186 58.232h4.517a1.341 1.341 0 1 0 0-2.683h-4.517a1.341 1.341 0 1 0 0 2.683zM34.767 58.232H54.41a1.342 1.342 0 0 0 0-2.683H34.767a1.34 1.34 0 1 0 0 2.683zM21.448 84.627l-.027-.002c-.01 0-.018 0-.027.002a2.46 2.46 0 0 1-2.431-2.456 1.341 1.341 0 1 0-2.683 0 5.148 5.148 0 0 0 3.8 4.962v1.097a1.34 1.34 0 1 0 2.682 0v-1.097a5.14 5.14 0 0 0-1.341-10.102 2.458 2.458 0 1 1 2.456-2.458 1.341 1.341 0 1 0 2.683 0 5.15 5.15 0 0 0-3.798-4.962v-.924a1.341 1.341 0 1 0-2.683 0v.924a5.14 5.14 0 0 0 1.342 10.103 2.457 2.457 0 0 1 .027 4.913zM33.641 83.893h11.34a1.342 1.342 0 0 0 0-2.683h-11.34a1.342 1.342 0 0 0 0 2.683zM64.397 80.656a1.34 1.34 0 0 0-2.29.42c-.16.453-.064.958.25 1.322l7.388 8.653a1.341 1.341 0 0 0 1.866.17l17.207-14a1.341 1.341 0 0 0-1.693-2.08l-16.19 13.17-6.538-7.655z"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="currentColor" d="M0 0h110v110H0z"/>
        </clipPath>
      </defs>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'CurrentBillingIcon')

export default icon
