const styles = (theme) => ({
  root: {
    padding: '15px 0',
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgrounds.componentBackground,
    color: theme.texts.main,
  },
  searchField: {
    transform: 'translateX(80px)',
  },
  searchFieldMobile: {
    transform: 'translateX(25px)',
  },
  interfaceElements: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  interfaceElementsTablet: {
    paddingLeft: 50,
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  refreshIcon: {
    width: 30,
    height: 30,
    marginRight: 80,
    marginTop: 40,
    color: theme.myStatus.subtitle,
    cursor: 'pointer',
    '&:hover': {
      color: theme.texts.main,
      backgroundColor: 'rgba(204, 204, 204, 0.1)',
      borderRadius: '50%',
    },
  },
  refreshIconMobile: {
    marginRight: 21,
  },
  refreshIconTablet: {
    marginRight: 50,
  },
  list: {
    paddingRight: 40,
    paddingLeft: 55,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  listMobile: {
    flexDirection: 'column',
    padding: 0,
    flexWrap: 'nowrap',
  },
  listTablet: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 30,
    paddingLeft: 45,
    boxSizing: 'border-box',
  },
  listItem: {
    margin: 5,
    width: 219,
  },
  listItemMobile: {
    width: '100%',
  },
  listItemTablet: {
    width: '48%',
  },
  message: {
    margin: '0 auto',
  },
})

export default styles
