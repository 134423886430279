import React from 'react'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import {
  SELECT_CONDITION_DEVICE_STATE_TYPE,
  SELECT_EVENT_TYPE, SELECT_NOTIFICATION_SENSOR_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/iOS/CardBlockIOS/CardBlock'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'

class SelectNotificationSensorType extends React.Component {
  onBackClick = () => {
    const {
      navigate,
      isChangeSensorMode,
    } = this.props
    if (isChangeSensorMode) {
      navigate(SELECT_NOTIFICATION_SENSOR_STATE_SELECTED)
    } else {
      navigate(SELECT_CONDITION_DEVICE_STATE_TYPE)
    }
  }

  selectSensor=(sensor) => {
    const {
      navigate,
      saveSensorNotificationsType,
      saveSensorNotificationsTypeDescription,
      saveSensorNotificationsEventType,
      saveSensorNotificationsEventDescription,
    } = this.props
    saveSensorNotificationsType(sensor.notification_type)
    saveSensorNotificationsTypeDescription(sensor.notification_name)
    if (sensor.event_types.length === 1) {
      const first_event = sensor.event_types[0]
      saveSensorNotificationsEventType(first_event.type)
      saveSensorNotificationsEventDescription(first_event.name)
      navigate(SELECT_NOTIFICATION_SENSOR_STATE_SELECTED)
    } else {
      navigate(SELECT_EVENT_TYPE)
    }
  }

  renderSensorNotificationsList=(sensors) => (sensors.map((sensor) => (
    <CardBlock
      key={sensor.notification_name}
      description={sensor.notification_name.replace('sensor', '')}
      position="middle"
      buttonName={I18n.t('scene_web.add')}
      isCardDisabled={false}
      isButtonDisabled={false}
      onClick={() => {
        this.selectSensor(sensor)
      }}
    />
  )))

  render() {
    const {
      classes,
      isMobile,
      sensors,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_sensor_type')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {sensors.length
            ? this.renderSensorNotificationsList(sensors)
            : <Typography className={classes.somethingWrong}>{I18n.t('errors.something_went_wrong')}</Typography>}
        </Wrapper>
      </Paper>
    )
  }
}

SelectNotificationSensorType.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  saveSensorNotificationsType: PropTypes.func.isRequired,
  saveSensorNotificationsTypeDescription: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      notification_name: PropTypes.string.isRequired,
      notification_type: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isChangeSensorMode: PropTypes.bool.isRequired,
  saveSensorNotificationsEventType: PropTypes.func.isRequired,
  saveSensorNotificationsEventDescription: PropTypes.func.isRequired,
}

SelectNotificationSensorType.displayName = 'SelectNotificationSensorTypeIOS'

export default SelectNotificationSensorType
