import { createLogic } from 'redux-logic'
import { CLOSE_DEVICES_STATUS_CHANNEL } from '@app/constants/myDevices/channels/devices/devicesChannels'
import { DEVICES_STATUS_CHANNEL } from '@app/constants/channels'
import openCable from '@app/api/cable'

const unsubscribeDevicesStatusLogic = createLogic({
  type: CLOSE_DEVICES_STATUS_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process() {
    const cable = openCable()
    cable.subscriptions.subscriptions.forEach((sub) => sub.identifier.includes(DEVICES_STATUS_CHANNEL) && sub.unsubscribe())
  },
})

export default unsubscribeDevicesStatusLogic
