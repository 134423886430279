const getMezzoConditionDataNumber = (state) => {
  switch (state) {
  case 'is_in_panic_alarm':
  case 'is_not_in_panic_alarm':
    return 1
  case 'is_in_duress_alarm':
  case 'is_not_in_duress_alarm':
    return 2
  case 'is_in_fire_alarm':
  case 'is_not_in_fire_alarm':
    return 3
  default:
    return 0
  }
}

export default getMezzoConditionDataNumber
