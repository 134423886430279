import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SET_NUMBER_OF_ZONES_OPTIONS } from '@app/constants/numberOfZones'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'
import Api from '@app/api/numberOfZones/patchNumberOfZones'
import {
  openSyncErrorPopup,
  openSyncInProgressPopup,
  syncChannel,
} from '@app/actions/syncCounters/countersChannel'
import { syncType } from '@app/lib/Synchronize/syncTypes'

const submitNumberOfZonesOptionsLogic = createLogic({
  type: SET_NUMBER_OF_ZONES_OPTIONS,
  cancelType: API_REQUEST_CANCEL,

  transform({ action }, next) {
    next({
      ...action,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(apiRequestSent(SET_NUMBER_OF_ZONES_OPTIONS))
    const { secrets: { accessToken: { token } } } = store.getState()
    const zonesNumber = action.programOptions[0].value
    const data = { push_after_finish: true, zones_number: zonesNumber }
    Api.patchNumberOfZones(token, data)
      .then(() => {
        dispatch(apiRequestDone(SET_NUMBER_OF_ZONES_OPTIONS))
        dispatch(syncChannel())
        dispatch(openSyncInProgressPopup())
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
          dispatch(openSyncErrorPopup(syncType.SYNC_ZONES))
        } else {
          throw new Error('submitNumberOfZonesOptions failed without error')
        }
      })
      .then(() => done())
  },
})

export default submitNumberOfZonesOptionsLogic
