const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 20,
    paddingTop: 20,
  },
  buttonWrapper: {
    display: 'flex',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  button: {
    margin: '10px auto 20px auto',
    width: '30%',
    height: 35,
  },
  title: {
    color: theme.palette.primary.main,
    margin: '0 auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    paddingBottom: 5,
    paddingLeft: 12,
    paddingRight: 12,
  },
  dialogContentDesktop: {
    paddingBottom: 5,
    paddingRight: 20,
    paddingLeft: 40,
  },
})

export default styles
