import React, { Dispatch, SetStateAction } from 'react'
import {
  IPOFromBackend,
} from '@app/@types/po/poConfig'
import * as poConfigActions from '@app/actions/po/poConfig'
import { InferValueTypes } from '@app/@types/typesHelper'
import Toggle from './Toggle/Toggle'
import Text from './Text/Text'
import InputField from './InputField/InputField'
import SelectString from './SelectString/SelectString'

interface Props {
  programOption: IPOFromBackend,
  options: Array<IPOFromBackend>,
  setOptions: Dispatch<SetStateAction<Array<IPOFromBackend>>>,
  validationSetter: (validation: boolean) => ReturnType<InferValueTypes<
    typeof poConfigActions>>,
  inProgress: boolean,
  items: any,
}

export const getName = (number: number): string => `programOptions_${number}`

const ProgramOption: React.FC<Props> = (props) => {
  const {
    programOption, options, setOptions, validationSetter, inProgress, items,
  } = props

  const optionInfo = items.length ? items.find((el: any) => Number(el.number) === programOption.number) : { type: null }

  const resetInputField = () => {
    setOptions(options.map((rule) => (rule.number === programOption.number
      ? { ...rule, value: '' } : rule)))
  }

  switch (optionInfo.type) {
  case 'strings':
    return (
      <SelectString
        name={getName(programOption.number)}
        title={optionInfo.title}
        number={programOption.number}
        values={optionInfo.values}
        value={programOption.value}
        options={options}
        setOptions={setOptions}
      />
    )
  case 'switch':
    return (
      <Toggle
        name={getName(programOption.number)}
        title={optionInfo.title}
        number={programOption.number}
        value={programOption.value}
        options={options}
        setOptions={setOptions}
      />
    )
  case 'text':
    return (
      <Text
        title={optionInfo.title}
        value={programOption.value}
        key={programOption.number}
      />
    )
  case 'inputs':
    return (
      <InputField
        title={optionInfo.title}
        hint={optionInfo.hint}
        required={optionInfo.required}
        name={getName(programOption.number)}
        number={programOption.number}
        value={programOption.value}
        options={options}
        setOptions={setOptions}
        resetInputField={resetInputField}
        min={optionInfo.min}
        max={optionInfo.max}
        regexpString={optionInfo.regexp}
        validationSetter={validationSetter}
        inProgress={inProgress}
      />
    )
  default:
    return null
  }
}

export default ProgramOption
