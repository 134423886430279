import React from 'react'
import classNames from 'classnames'
import {
  Paper,
  Typography,
} from '@mui/material'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import CardBlock from '@app/components/Common/CardBlock/CardBlock'
import NavBar from '@app/components/Common/NavBar/NavBar'
import PropTypes from '@app/components/PropTypes'
import {
  SELECT_VIRTUAL_SWITCH_ACTION,
  EDIT_ACTION,
  CREATE_ACTION_SELECTED,
  SELECT_VIRTUAL_SWITCH_ACTION_STATE,
} from '@app/constants/routes'
import { virtualSwitchesStatesAction } from '@app/lib/Scenes/virtualSwitchesStates'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'

class SelectVirtualSwitchActionValue extends React.Component {
  onBackClick = () => {
    const {
      isChangeActionStateMode,
      isEditActionMode,
      actions,
      initialAction,
      navigate,
    } = this.props
    if (isChangeActionStateMode) {
      if (isEditActionMode) {
        restoreEditedActionInfo(actions, initialAction)
        navigate(EDIT_ACTION)
      } else {
        navigate(CREATE_ACTION_SELECTED)
      }
    } else {
      navigate(SELECT_VIRTUAL_SWITCH_ACTION)
    }
  }

  selectState=(state) => {
    const {
      navigate,
      saveActionStateDescription,
      actionDeviceNumber,
      saveActionDelay,
      saveActionDelayDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      saveActionDeviceNumber,
    } = this.props
    saveActionStateDescription(state.title)
    saveActionDeviceNumber(actionDeviceNumber)
    navigate(SELECT_VIRTUAL_SWITCH_ACTION_STATE)
    saveActionDelay({})
    saveActionDelayDescription('')
    saveActionDuration({})
    saveActionDurationDescription('')
    saveActionDurationValueAfter('none')
  }

  render() {
    const {
      classes,
      isMobile,
    } = this.props
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={() => {
            this.onBackClick()
          }}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_device_state')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          {
            virtualSwitchesStatesAction.map((state) => (
              <CardBlock
                key={state.title}
                description={I18n.t(`scene_action_web.${state.title}`)}
                position="middle"
                buttonName={I18n.t('scene_web.add')}
                isCardDisabled={false}
                isButtonDisabled={false}
                onClick={() => {
                  this.selectState(state)
                }}
              />
            ))
          }
        </Wrapper>
      </Paper>
    )
  }
}

SelectVirtualSwitchActionValue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  actionDeviceNumber: PropTypes.number.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  saveActionDeviceNumber: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectVirtualSwitchActionValue.displayName = 'SelectVirtualSwitchActionValue'

export default SelectVirtualSwitchActionValue
