import React, { useState } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { IconButton } from '@mui/material'
import classNames from 'classnames'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import { ScenesArrowBack } from 'icons'
import MainSettings from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/MainSettings/MainSettings'
import AdvancedSettings from '@app/components/MyDevices/DeviceInfo/DeviceSettings/GeneralSettings/AdvancedSettings/AdvancedSettings'
import BottomTabs from '@app/components/MyDevices/DeviceInfo/DeviceSettings/BottomTabs/BottomTabs'
import { IControl, IZwaveOrTwrDevice } from '@app/@types/myDevices'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
import useStyles from './DeviceSettings.style'
import VoiceAssistant from './VoiceAssistant/VoiceAssistant'

interface Props {
  currentDevice: IZwaveOrTwrDevice,
  currentDeviceControl: IControl,
  deviceHealth: string,
  failedNodeStatus: string,
  twrCurrentSirenOption: number,
  currentTakeOnBoardDeviceType: number,
  isMobile: boolean,
}

const DeviceSettings: React.FC<Props> = (props) => {
  const {
    currentDevice,
    currentDeviceControl,
    deviceHealth,
    failedNodeStatus,
    twrCurrentSirenOption,
    currentTakeOnBoardDeviceType,
    isMobile,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const [settingTab, setSettingTab] = useState<number>(0)
  const { devices: devicesConfig } = useSelector((state: AppState) => state.appConfig.config)
  const advancedSettingsState = devicesConfig?.twr?.advanced_settings?.state ?? defaultConfig?.devices?.twr?.advanced_settings?.state
  const showAdvancedSettings = advancedSettingsState === States.Visible

  const goBackHandler = () => navigate(-1)

  return (
    <>
      {currentDevice ? (
        <Wrapper className={classNames(classes.root, isMobile && classes.rootMobile)}>
          {settingTab === 0 && (
            <>
              <Wrapper className={classes.navigationControls}>
                <IconButton size="small" onClick={goBackHandler} className={classes.goBackIcon}>
                  <ScenesArrowBack />
                </IconButton>
              </Wrapper>
              <MainSettings
                currentDevice={currentDevice}
                currentDeviceControl={currentDeviceControl}
                isMobile={isMobile}
              />
              {showAdvancedSettings && (
                <AdvancedSettings
                  currentDevice={currentDevice}
                  deviceHealth={deviceHealth}
                  failedNodeStatus={failedNodeStatus}
                  twrCurrentSirenOption={twrCurrentSirenOption}
                  currentTakeOnBoardDeviceType={currentTakeOnBoardDeviceType}
                  isMobile={isMobile}
                />
              )}
            </>
          )}
          {settingTab === 1 && (
            <VoiceAssistant
              currentDevice={currentDevice}
              isMobile={isMobile}
            />
          )}
          <BottomTabs settingTab={settingTab} setSettingTab={setSettingTab} />
        </Wrapper>
      ) : <Navigate to={location.pathname.split('/').slice(0, -1).join('/')} />}
    </>
  )
}

export default DeviceSettings
