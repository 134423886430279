import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M135.261 61.5678L135.65 61.7702L135.902 61.4112L137.281 59.4421L137.608 58.9751L137.103 58.7117L100.493 39.6503L100.262 39.53L100.031 39.6503L94.7608 42.3947L94.2263 42.673L94.5984 43.1469C95.0691 43.7464 95.4524 44.3983 95.7377 45.0881L95.9464 45.5926L96.4306 45.3405L100.262 43.3455L127.892 57.7307V96.6469H74.6757H74.3082L74.1985 96.9977C74.0113 97.5964 73.7551 98.1462 73.4495 98.6544L72.9941 99.412H73.8781H142H142.5V98.912V97.1469V96.6469H142H131.564V59.6429L135.261 61.5678Z" fill="currentColor" stroke="currentColor"/>
      <path d="M75.2916 56.3481L75.8886 56.0373L75.4186 55.5555C74.8779 55.0013 74.4101 54.3884 74.0277 53.7295L73.7879 53.3164L73.3643 53.537L63.4262 58.7118L62.9205 58.9751L63.2475 59.4421L64.6263 61.4111L64.8778 61.7704L65.2668 61.5678L68.9647 59.6423V73.5377V73.9025L69.3121 74.0139C70.2139 74.3029 71.094 74.6193 71.9546 74.9601L72.6387 75.231V74.4952V57.7296L75.2916 56.3481Z" fill="currentColor" stroke="currentColor"/>
      <path d="M41.0305 71.0338C41.0211 68.9562 40.1577 66.8751 38.3974 65.2266C34.6555 61.7237 28.3281 61.499 24.2652 64.7262C20.2018 67.9534 19.9413 73.4101 23.6832 76.9137C27.426 80.4182 33.7521 80.6429 37.8154 77.4153C38.0204 77.2523 38.2089 77.0802 38.3948 76.9064C40.1572 75.258 41.0398 73.1493 41.0305 71.0338Z" fill="currentColor"/>
      <path d="M44.6685 82.8214C48.0391 86.2116 52.8441 90.6881 56.0601 95.5768L42.9025 102.228C41.585 102.894 40.6576 103.977 40.3045 105.258L35.5631 122.515C34.8548 125.09 36.7018 127.673 39.6887 128.283C40.1196 128.371 40.5509 128.414 40.9755 128.414C43.492 128.414 45.7734 126.929 46.3789 124.725L50.5726 109.463L54.7925 107.33L53.4173 111.42C52.998 112.668 53.1803 113.997 53.925 115.125C54.6698 116.254 55.9013 117.067 57.3584 117.393L80.1834 122.506C80.652 122.611 81.1219 122.661 81.5843 122.661C84.0542 122.661 86.3089 121.23 86.9589 119.072C87.7303 116.511 85.9478 113.894 82.9778 113.228L65.6852 109.355L68.8215 100.022C71.2514 98.2867 72.9272 95.6992 71.2172 91.302C69.5267 87.8497 67.9172 85.1225 66.0525 82.6914C65.3762 82.4613 64.6897 82.2439 63.9792 82.0537C60.0336 81 56.3237 80.7779 53.9068 80.7779L53.8601 80.7787C51.7188 80.7787 50.3721 80.9497 50.3583 80.9505C50.1351 80.9812 49.9025 80.9969 49.6718 80.9969C47.47 80.9969 45.6244 79.6322 45.2843 77.7528C45.1015 76.7412 45.3861 75.7278 46.0873 74.9006C46.7871 74.0727 47.8195 73.5313 48.9924 73.3733C49.0675 73.363 50.9474 73.1103 53.8783 73.1083C54.5373 73.1087 55.1917 73.1252 55.844 73.1494C51.272 70.2301 46.3838 70.77 43.6005 72.6317C43.0367 72.9047 42.5508 73.3108 42.2191 73.8373L38.7808 79.2939L27.4181 97.3254L25.6806 96.4017L23.943 98.832L25.8348 99.8378L25.5463 100.296C24.6077 101.785 25.2471 103.648 26.9731 104.457C27.5124 104.71 28.0944 104.829 28.6684 104.83C29.9298 104.83 31.1521 104.25 31.7963 103.226L31.8995 103.062L32.7949 103.538L34.5325 101.107L33.4832 100.549L43.0723 85.3323L44.66 82.8122C44.6623 82.816 44.6654 82.8187 44.6685 82.8214Z" fill="currentColor"/>
      <path d="M75.8514 87.3453C76.5495 87.9707 77.481 88.2855 78.4142 88.2855C79.3012 88.2855 80.1896 88.0006 80.8801 87.4277C82.2953 86.2528 82.338 84.3106 80.9757 83.09C76.4832 79.0652 71.2363 76.7503 66.4019 75.4634C61.5574 74.173 57.0992 73.8804 53.9361 73.8789C51.1239 73.8797 49.3294 74.1147 49.1863 74.1343C47.2455 74.395 45.9174 75.9638 46.2211 77.6384C46.5234 79.3122 48.3419 80.4576 50.284 80.1956C50.4139 80.1795 51.8037 80.0139 53.9361 80.015C56.5794 80.0131 60.3569 80.2712 64.2998 81.3246C68.2493 82.381 72.3381 84.202 75.8514 87.3453Z" fill="currentColor"/>
      <path d="M21.7535 100.163L24.8058 95.8914L9 87.4883C12.3017 95.8899 21.7535 100.163 21.7535 100.163Z" fill="currentColor"/>
      <path d="M79.3269 56.5648C80.0245 56.8942 80.7128 57.1235 81.329 57.2838V61.281H91.1569V54.2411C89.7541 55.0617 88.0623 55.5418 86.243 55.5418C84.4236 55.5418 82.7322 55.0617 81.329 54.2407V56.074C80.671 55.8769 79.9503 55.5931 79.2691 55.1828C77.8143 54.297 76.5 52.8913 76.2003 50.3976C76.9446 50.1756 77.4822 49.5732 77.4822 48.8561C77.4822 47.9519 76.6321 47.2188 75.5832 47.2188C74.5352 47.2188 73.6847 47.9519 73.6847 48.8561C73.6847 49.5364 74.1662 50.1196 74.8509 50.3666C75.1853 53.7123 77.2839 55.6154 79.3269 56.5648Z" fill="currentColor"/>
      <path d="M86.159 54.8473C90.5625 54.8473 94.1333 51.7685 94.1333 47.9703C94.1333 44.1726 90.563 41.0938 86.159 41.0938C81.7555 41.0938 78.1857 44.1726 78.1857 47.9703C78.1861 51.7685 81.7555 54.8473 86.159 54.8473ZM86.1595 45.8901C87.4916 45.8901 88.572 46.8215 88.572 47.9703C88.572 49.1196 87.4916 50.051 86.1595 50.051C84.8274 50.051 83.747 49.1196 83.747 47.9703C83.747 46.8215 84.8274 45.8901 86.1595 45.8901Z" fill="currentColor"/>
      <path d="M77.6146 42.2907C77.6302 42.2922 77.6458 42.293 77.6613 42.293C77.8863 42.293 78.0793 42.1469 78.1042 41.9502V41.9494C78.1042 41.9494 78.1144 41.8574 78.1673 41.6925C78.3558 41.1764 78.7791 40.2614 80.3046 40.2553C80.4749 40.2553 80.6616 40.2668 80.8653 40.294C81.108 40.3259 81.3357 40.1824 81.3726 39.9735C81.3832 39.9113 81.3721 39.8527 81.3508 39.7967C81.3001 39.664 81.1716 39.5582 81.0013 39.5359C80.7541 39.5033 80.5229 39.4884 80.305 39.4884C78.9547 39.4796 78.1135 40.1 77.708 40.6898C77.6058 40.8347 77.5253 40.9777 77.4608 41.1116C77.2638 41.5222 77.2252 41.8447 77.2198 41.8681C77.1931 42.079 77.3701 42.2681 77.6146 42.2907Z" fill="currentColor"/>
      <path d="M75.7097 41.423C75.7338 41.4264 75.7573 41.4279 75.7809 41.4279C75.9952 41.4279 76.1846 41.2933 76.2197 41.1054C76.2197 41.1047 76.2477 40.9559 76.3433 40.7043C76.6595 39.9328 77.4642 38.4853 79.822 38.481C80.1564 38.481 80.5254 38.5106 80.9327 38.5781C81.1732 38.6183 81.4062 38.4822 81.4533 38.2744C81.4996 38.0665 81.3417 37.8656 81.1012 37.8257C80.6424 37.7494 80.2173 37.7149 79.822 37.7149C77.9235 37.7076 76.7439 38.5455 76.1237 39.3573C75.4937 40.1698 75.3492 40.9524 75.342 40.9839C75.3025 41.1913 75.4674 41.3884 75.7097 41.423Z" fill="currentColor"/>
      <path d="M94.8195 53.7592C96.2751 53.1338 96.7384 52.141 96.7215 51.3649C96.7193 50.9289 96.6024 50.5627 96.4939 50.3119C96.4054 50.1071 96.3227 49.9779 96.3152 49.9641C96.1942 49.7797 95.9226 49.7153 95.7096 49.8192C95.4957 49.9239 95.4206 50.157 95.5415 50.3414H95.5406C95.5406 50.3414 95.5531 50.3587 95.5798 50.4089C95.662 50.5589 95.8336 50.9404 95.8319 51.3649C95.8159 51.9178 95.6082 52.5341 94.4149 53.0763C94.1961 53.1718 94.109 53.4034 94.221 53.592C94.2708 53.676 94.3535 53.7343 94.4469 53.768C94.5011 53.7876 94.558 53.801 94.6176 53.801C94.6856 53.801 94.755 53.7876 94.8195 53.7592Z" fill="currentColor"/>
      <path d="M95.4969 54.4997C95.2741 54.5886 95.1776 54.8172 95.2812 55.0093C95.3564 55.1489 95.5169 55.2313 95.685 55.2313C95.7476 55.2313 95.8112 55.2198 95.8721 55.1957C98.2415 54.2673 98.9009 52.7381 98.8822 51.5731C98.8795 50.5335 98.4331 49.7659 98.4176 49.7356C98.3086 49.5469 98.0419 49.4694 97.8222 49.5634C97.6021 49.6581 97.5137 49.8882 97.6226 50.0776V50.0769C97.6226 50.0776 97.6395 50.1064 97.6742 50.1792C97.7782 50.3978 97.9943 50.94 97.9934 51.5735C97.9739 52.5203 97.5826 53.6499 95.4969 54.4997Z" fill="currentColor"/>
      <path d="M89.995 37.6762L90.3978 37.8089L95.9876 29.2973L91.6303 29.2286L94.8285 22.51L93.0375 22L89.7998 30.3709L93.7205 30.1957L89.995 37.6762Z" fill="currentColor"/>
      <path d="M92.7297 39.5032L92.9614 39.746L101.214 35.5237L98.0465 33.894L103.669 30.1207L102.603 29.0977L96.1471 34.0696L99.1105 35.3623L92.7297 39.5032Z" fill="currentColor"/>
      <path d="M85.5867 31.32L85.8993 38.0954L86.2554 38.0708L86.9166 29.9426L83.6126 31.2387L83.2213 25.1992L81.6625 25.3699L82.7122 32.6633L85.5867 31.32Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'HomeSecurityAlarm')

export default icon
