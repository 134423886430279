import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
    <React.Fragment>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M90.5315 13.8711L90.7927 15.4756C91.8282 21.7539 93.0689 28.0042 94.6175 34.1331C95.9734 39.6153 97.6207 45.0212 99.5524 50.3279C100.442 52.7409 101.406 55.126 102.444 57.483C103.104 58.9756 103.797 60.4496 104.525 61.9048C105.563 63.982 106.683 66.0188 107.883 68.0152C109.021 69.8809 110.234 71.7467 111.531 73.5005C112.706 75.0957 113.937 76.6349 115.262 78.1182C116.488 79.498 117.784 80.815 119.143 82.0643C120.454 83.2825 121.83 84.4286 123.266 85.4973C115.001 102.269 109.807 120.385 107.93 138.988L107.818 140.537H139.731V13.8711H90.5315ZM138.323 139.117H109.31C111.207 120.275 116.531 101.938 125.02 85.0101C108.906 73.8094 97.951 50.5559 92.1548 15.2497H138.323V139.117Z" fill="currentColor"/>
            <rect x="116.605" y="79.293" width="24.3947" height="8.94627" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M126.643 89.6172C119.735 104.154 115.185 119.697 113.163 135.664H116.577C118.443 124.724 122.159 109.375 127.725 89.6172L126.643 89.6172Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M129.153 77.9103H129.955C126.41 52.8346 123.879 33.095 122.361 18.6914H116.54C116.54 19.7362 116.587 20.781 116.661 21.8165C116.755 23.0759 116.876 24.326 117.034 25.548C117.212 27.0127 117.426 28.4679 117.65 29.9232C117.918 31.59 118.207 33.2505 118.518 34.9048C118.872 36.7705 119.245 38.6363 119.637 40.502C120.393 44.0656 121.214 47.6105 122.063 51.1555C123.275 56.1681 124.553 61.1621 125.897 66.1374C126.96 70.0742 128.045 73.9985 129.153 77.9103V77.9103Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M121.736 77.9103H127.716C119.345 48.1951 115.159 28.4555 115.159 18.6914H96.25C97.2482 24.2327 98.4329 29.7366 99.8696 35.1753C101.129 40.002 102.63 44.7625 104.366 49.439C105.675 52.9609 107.17 56.4112 108.844 59.7752C109.826 61.7405 110.884 63.6653 112.016 65.5497C113.079 67.3035 114.208 69.02 115.421 70.6712C116.633 72.3224 117.771 73.7217 119.059 75.149C119.917 76.1068 120.809 77.0272 121.736 77.9103V77.9103Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M134.871 77.9103V18.6914H123.751C125.275 33.0888 127.809 52.8284 131.354 77.9103H134.871Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M134.871 89.6172H131.345C128.783 107.759 126.749 123.107 125.244 135.664H134.871V89.6172Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M129.955 89.6172H129.153C128.052 93.5353 126.973 97.4471 125.916 101.353C124.578 106.328 123.3 111.322 122.081 116.335C121.223 119.87 120.412 123.425 119.656 126.988C119.264 128.854 118.891 130.72 118.536 132.585C118.344 133.605 118.16 134.628 117.986 135.655H123.863C125.362 123.111 127.393 107.765 129.955 89.6172V89.6172Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.0454 89.6172H19.8477C20.9547 93.5353 22.0337 97.4471 23.0848 101.353C24.4219 106.328 25.6999 111.322 26.9189 116.335C27.7771 119.87 28.5887 123.425 29.3444 126.988C29.7362 128.854 30.1093 130.72 30.4638 132.585C30.6566 133.605 30.8401 134.628 31.0142 135.655H25.1371C23.6631 123.111 21.6326 107.765 19.0454 89.6172V89.6172Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1289 89.6172H17.6552C20.2175 107.759 22.2511 123.107 23.7562 135.664H14.1289V89.6172Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1289 77.9103V18.6914H25.2488C23.7002 33.0888 21.169 52.8284 17.6552 77.9103H14.1289Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.2639 77.9103H21.2842C29.6552 48.1951 33.8407 28.4555 33.8407 18.6914H52.778C51.7799 24.2327 50.5951 29.7366 49.1585 35.1753C47.8991 40.0021 46.3984 44.7626 44.662 49.439C43.3528 52.961 41.8581 56.4112 40.1842 59.7752C39.2016 61.7405 38.1443 63.6653 37.0125 65.5497C35.949 67.3035 34.8202 69.02 33.6075 70.6712C32.3947 72.3224 31.2566 73.7217 29.9692 75.149C29.0923 76.1068 28.1906 77.0272 27.2639 77.9103V77.9103Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.8477 77.9103H19.0454C22.5903 52.8346 25.1278 33.095 26.6577 18.6914H32.4788C32.4788 19.7362 32.4322 20.781 32.3575 21.8165C32.2643 23.0759 32.143 24.326 31.9844 25.548C31.8071 27.0127 31.5926 28.4679 31.3687 29.9232C31.1013 31.59 30.8121 33.2505 30.5011 34.9048C30.1466 36.7705 29.7735 38.6363 29.3817 40.502C28.626 44.0656 27.8051 47.6105 26.9562 51.1555C25.7435 56.1681 24.4654 61.1621 23.1221 66.1374C22.0462 70.0742 20.9547 73.9985 19.8477 77.9103V77.9103Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.357 89.6172C29.2644 104.154 33.8147 119.697 35.837 135.664H32.432C30.5663 124.724 26.8503 109.375 21.2842 89.6172H22.357Z" fill="currentColor"/>
            <rect x="8" y="79.293" width="24.3947" height="8.94627" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.30591 13.8711V140.537H41.2196L41.0703 139.026C39.1943 120.409 33.9968 102.28 25.7245 85.4973C27.1592 84.4406 28.5357 83.3071 29.8478 82.1016C31.2068 80.8522 32.502 79.5351 33.7286 78.1555C35.0439 76.6816 36.2847 75.133 37.4601 73.5378C38.7568 71.7653 39.9695 69.9369 41.1076 68.0525C42.2457 66.1681 43.4491 64.0411 44.4753 61.9422C45.2029 60.4869 45.8964 59.0129 46.5556 57.5203C47.5942 55.1695 48.5582 52.7844 49.4475 50.3652C51.3784 45.0646 53.0257 39.6649 54.3824 34.1891C55.931 28.0135 57.1811 21.7632 58.2072 15.5316L58.4684 13.9271L9.30591 13.8711ZM23.9895 85.0474C32.4783 101.975 37.8023 120.312 39.6991 139.154H10.6867V15.2497H56.8454C51.0242 50.5559 40.0692 73.8218 23.9802 85.0474H23.9895Z" fill="currentColor"/>
            <rect x="8.23315" y="9" width="132.543" height="6.25026" fill="currentColor"/>
        </svg>
    </React.Fragment>
)

const icon = createSvgIcon(fragment, 'ZwCurtainIcon')

export default icon
