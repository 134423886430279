import { createLogic } from 'redux-logic'
import { SYNC_PO_FROM_CONFIG } from '@app/constants/po/poConfig'
import handleErrors from '@app/helpers/handleErrors'
import { deps } from '@app/constants/channels'
import {
  savePoConfig, setInitialOptions, endSavingProgress, startSavingProgress, apiRequestFailed,
} from '@app/actions/po/poConfig'
import Api from '@app/api/po/poScreen'
import programOptionChannel from '@app/api/programOptions'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import extractInitialValues from '@app/helpers/programOptions/extractInitialValues'
import extractPoNumbers from '@app//helpers/programOptions/extractPoNumbers'

const fetchProgramOptionsFormConfigLogic = createLogic({
  type: SYNC_PO_FROM_CONFIG,
  cancelType: API_REQUEST_CANCEL,

  transform({ getState, action }, next) {
    const { secrets: { accessToken: { token: accessToken } } } = getState()
    const { unit: { isNCentral } } = getState()
    const deviceType = isNCentral ? 'ncentral' : 'mezzo'
    const currentScreen = `${window.location.pathname.slice(5)}_${deviceType}`
    next({
      ...action,
      accessToken,
      currentScreen,
    })
  },

  async process({ action }, dispatch, done) {
    dispatch(startSavingProgress())
    let items
    let itemsData
    try {
      itemsData = await Api.fetchPoScreen(action.accessToken, action.currentScreen)
      if (!itemsData.data.error) {
        items = itemsData.data
        const initials = extractInitialValues(items)
        dispatch(savePoConfig(items, initials))
        programOptionChannel({
          request: (channel) => {
            const numbers = extractPoNumbers(items)
            channel.perform('get_program_options', { numbers, ...deps })
          },
          response: (data, isFinished) => {
            const {
              programOptions,
              error,
            } = data
            if (error && error.msg) {
              store.dispatch(apiRequestFailed(error.msg, error.code))
            } else {
              const stringifyProgramOption = programOptions.map((option) => ({
                ...option,
                value: option.value.toString(),
              }))
              store.dispatch(setInitialOptions(stringifyProgramOption))
              if (isFinished) {
                store.dispatch(endSavingProgress())
              }
            }
            if (isFinished) {
              done()
            }
          },
        })
      } else {
        dispatch(apiRequestFailed(itemsData.data.error))
      }
    } catch (error) {
      if (error) {
        const currentError = handleErrors(error)
        dispatch(apiRequestFailed(currentError))
      } else {
        throw new Error('get Po screens failed without error')
      }
    }
  },
})

export default fetchProgramOptionsFormConfigLogic
