import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import logic from '../logic'

const development = require('./development')

const isDevelopment = () => process.env.NODE_ENV !== 'production'

export const createMiddlewareEnhancer = () => {
  const middleware = []
  middleware.push(thunk)
  middleware.push(logic)
  if (isDevelopment()) {
    middleware.push(...development.devMiddlewares())
  }
  return applyMiddleware(...middleware)
}
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
/* eslint-enable */

export const createEnhancer = () => {
  const enhancers = []
  enhancers.push(createMiddlewareEnhancer())
  if (isDevelopment()) {
    enhancers.push(...development.devEnhancers())
  }
  return composeEnhancers(...enhancers)
}
