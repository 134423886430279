import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { SyncButtonIcon } from 'icons'
import { defaultConfig, States } from '@app/helpers/convertAppConfig'
// @ts-ignore
import { openSyncWarningPopup } from '@app/actions/syncCounters/countersChannel'
import { IAppConfig } from '@app/@types/appConfig'
import Wrapper from '../Common/Wrapper/Wrapper'
import useStyles from './Actions.style'

interface Props {
  appConfig: IAppConfig,
  online: boolean,
}

const Actions: React.FC<Props> = (props) => {
  const {
    appConfig,
    online,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const resultConfig = appConfig.menu ? appConfig.menu.actions : defaultConfig.menu.actions

  const [actionsConfig] = resultConfig

  const isButtonDisabled = actionsConfig.state === States.Disabled || !online

  const onSyncButtonClick = () => {
    dispatch(openSyncWarningPopup())
  }

  return (
    actionsConfig.state !== States.Invisible ? (
      <Wrapper>
        <Button
          className={classes.syncButton}
          variant="outlined"
          disabled={isButtonDisabled}
          onClick={onSyncButtonClick}
          startIcon={<SyncButtonIcon />}
          color="primary"
        >
          {I18n.t('sync_web.sync_button')}
        </Button>
      </Wrapper>
    ) : null)
}

export default Actions
