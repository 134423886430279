export const SYNC_TIMERS = '@@timers/timersSync'

export const SET_TIMERS = '@@timers/timersSet'
export const SAVE_TIMER_DATA = '@@timers/saveTimerData'

export const PUT_TIMER = '@@timers/putTimer'

export const RESET_TIMER = '@@timers/resetTimer'

export const RESET_ALL_TIMERS = '@@timers/resetAllTimers'

export const TIMER_CHANNEL = 'Unit::TimerChannel'
export const SYNC_TIMER_CHANNEL = '@@timers/timerChannelSync'
export const SYNC_TIMER_CHANNEL_SENT = '@@timers/timersChannelSyncSent'
export const SYNC_TIMER_CHANNEL_CANCEL = '@@timers/timerChannelSyncCancel'
export const OPEN_TIMER_CHANNEL = '@@timers/timerChannelOpen'
export const CLOSE_TIMER_CHANNEL = '@@timers/timerChannelClose'
export const UPDATE_TIMERS = '@@timers/timersChannelUpdate'
export const UNSUBSCRIBE_TIMER_CHANNEL = '@@timers/unsubscribeTimerChannel'

export const SET_CURRENT_TRIGGER = '@@timers/setCurrentTrigger'

export const TIMER_TRIGGER = '@@timers/timerTrigger'

export const UPDATE_TIMER = '@@timers/updateTimer'

export const RESET_TIMERS_REDUCER = '@@timers/reset'
