import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import isAccessTokenValid from '@app/helpers/isAccessTokenValid'
import { withStyles } from '@app/components/styles'
import {
  chooseAvatarFile, cleanAvatarFile, closePopup, set,
} from '@app/actions/users/avatar'
import AvatarPopup from '@app/components/Users/UserEdit/AvatarPopup/AvatarPopup'
import { withAccessDenied, withProgressAndErrorMessage } from '@app/hocs'

let enhance = withStyles(AvatarPopup)

const mapStateToProps = (state) => {
  const { avatarData } = state.users.avatar
  const fileChoosen = Boolean(avatarData)
  const { isMobile } = state.mobileDetector
  return {
    avatarData,
    fileChoosen,
    isMobile,
    inProgress: state.apiRequest.inProgress,
    errorMessage: state.apiRequest.errorMessage,
    accessDenied: !isAccessTokenValid(state.secrets.accessToken),
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  chooseAvatarFile,
  cleanAvatarFile,
  closePopup,
  submit: set,
}, dispatch)

enhance = withProgressAndErrorMessage(enhance)
enhance = withAccessDenied(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const AvatarPopupEnhanced = enhance

export default AvatarPopupEnhanced
