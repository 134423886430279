import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 500,
  },
  scenesList: {
    width: '100%',
    paddingBottom: 100,
  },
  noScenes: {
    paddingTop: 200,
  },
  sortingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 4px',
    marginTop: 28,
    height: 42,
    position: 'relative',
  },
  button: {
    border: 0,
    padding: '0 12px',
    fontSize: '16px',
  },
  iconButtonReload: {
    position: 'absolute',
    left: 0,
  },
}))

export default useStyles
