import React from 'react'
import classNames from 'classnames'
import { Button, Paper, Typography } from '@mui/material'
import rgba from 'color-rgba'
import { ColorTypes } from '@app/lib/Scenes/colorActions'
import {
  EDIT_ACTION,
  SELECT_ACTION_COLOR_TYPE,
  SELECT_COLOR_ACTION_STATE_SELECTED,
} from '@app/constants/routes'
import Wrapper from '@app/components/Common/Wrapper/Wrapper'
import NavBar from '@app/components/Common/iOS/NavBarIOS/NavBar'
import PropTypes from '@app/components/PropTypes'
import restoreEditedActionInfo from '@app/helpers/scenes/restoreEditedActionInfo'
import RGBColorPicker from './ColorPickers/RGBColorPicker/RGBColorPicker'
import Swatches from './ColorPickers/Swatches/Swatches'
import WhitePicker from './ColorPickers/WhitePicker/WhitePicker'

class SelectColorActionHue extends React.Component {
  state = {
    currentColor: 'rgba(255, 255, 255, 1)',
  }

  onBackClick = () => {
    const {
      navigate,
      restoreActionInfo,
      isEditActionMode,
      actions,
      initialAction,
    } = this.props
    restoreActionInfo()
    if (isEditActionMode) {
      restoreActionInfo()
      restoreEditedActionInfo(actions, initialAction)
      navigate(EDIT_ACTION)
    } else {
      navigate(SELECT_ACTION_COLOR_TYPE)
    }
  }

  onChangeColor = (color) => {
    const { rgb } = color
    this.setState({ currentColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` })
  }

  onNextClick=() => {
    const {
      saveActionStateDescription,
      colorType,
      navigate,
      isChangeActionStateMode,
      isChangeActionMode,
      saveActionDelay,
      saveActionDelayDescription,
      saveActionDuration,
      saveActionDurationDescription,
      saveActionDurationValueAfter,
      saveDurationValueAfterActionColorType,
    } = this.props
    const { currentColor } = this.state
    if (colorType === ColorTypes.swatch || colorType === ColorTypes.rgb) {
      saveActionStateDescription(currentColor)
    }
    if (colorType === ColorTypes.whiteWarm || colorType === ColorTypes.whiteCold) {
      let currentValue
      if (currentColor === 'rgba(255, 255, 255, 0)') {
        currentValue = '1'
      } else {
        currentValue = Math.round(rgba(currentColor)[3] * 100).toString()
      }
      saveActionStateDescription(currentValue)
    }
    if (isChangeActionStateMode || isChangeActionMode) {
      saveActionDelay({})
      saveActionDelayDescription('')
      saveActionDuration({})
      saveActionDurationDescription('')
      saveActionDurationValueAfter('none')
      saveDurationValueAfterActionColorType('')
    }
    navigate(SELECT_COLOR_ACTION_STATE_SELECTED)
  }

  render() {
    const {
      classes,
      isMobile,
      colorType,
    } = this.props
    const {
      currentColor,
    } = this.state
    return (
      <Paper className={classNames(
        classes.wrapper,
        (navigator.userAgent.includes('WebView') || navigator.userAgent.includes('wv'))
        && classes.webView,
      )}
      >
        <NavBar
          title="create_scene"
          withButton={false}
          isMobile={isMobile}
          backClick={this.onBackClick}
        />
        <Wrapper className={classes.titleWrapper}>
          <Typography className={classes.title}>{I18n.t('scene_web.select_colour')}</Typography>
        </Wrapper>
        <Wrapper className={classes.main}>
          <Wrapper className={classes.colorPickerWrapper}>
            {colorType === ColorTypes.rgb
            && <RGBColorPicker color={currentColor} oldColor={currentColor} changeColor={this.onChangeColor} presetColors={[]} />}
            {colorType === ColorTypes.swatch && <Swatches changeColor={this.onChangeColor} />}
            {(colorType === ColorTypes.whiteCold || colorType === ColorTypes.whiteWarm) && (
              <WhitePicker
                className={classes.slider}
                color={currentColor}
                colorTemperature={colorType === ColorTypes.whiteCold ? 'cold' : 'warm'}
                changeColor={this.onChangeColor}
              />
            )}
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.onNextClick}
          >
            {I18n.t('buttons.next')}
          </Button>
        </Wrapper>
      </Paper>
    )
  }
}

SelectColorActionHue.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  colorType: PropTypes.string.isRequired,
  saveActionStateDescription: PropTypes.func.isRequired,
  restoreActionInfo: PropTypes.func.isRequired,
  saveActionDelay: PropTypes.func.isRequired,
  saveActionDelayDescription: PropTypes.func.isRequired,
  saveActionDuration: PropTypes.func.isRequired,
  saveActionDurationDescription: PropTypes.func.isRequired,
  saveActionDurationValueAfter: PropTypes.func.isRequired,
  isChangeActionStateMode: PropTypes.bool.isRequired,
  isChangeActionMode: PropTypes.bool.isRequired,
  saveDurationValueAfterActionColorType: PropTypes.func.isRequired,
  isEditActionMode: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.action).isRequired,
  initialAction: PropTypes.action.isRequired,
}

SelectColorActionHue.defaultProps = {

}

SelectColorActionHue.displayName = 'SelectColorActionHueIOS'

export default SelectColorActionHue
