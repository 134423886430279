import React from 'react'
import createSvgIcon from './../helper'

const fragment = (
  <React.Fragment>
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.6957 59.3701C34.7272 59.3701 28.2391 52.882 28.2391 44.9136C28.2391 36.9451 34.7272 30.457 42.6957 30.457C50.6641 30.457 57.1522 36.9451 57.1522 44.9136C57.1522 52.882 50.6641 59.3701 42.6957 59.3701ZM42.6957 36.2396C37.9134 36.2396 34.0217 40.1313 34.0217 44.9136C34.0217 49.6958 37.9134 53.5875 42.6957 53.5875C47.4779 53.5875 51.3696 49.6958 51.3696 44.9136C51.3696 40.1313 47.4779 36.2396 42.6957 36.2396Z" fill="currentColor"/>
      <path d="M42.6956 73.8269C41.0996 73.8269 39.8043 72.5316 39.8043 70.9356V65.153C39.8043 63.557 41.0996 62.2617 42.6956 62.2617C44.2916 62.2617 45.5869 63.557 45.5869 65.153V70.9356C45.5869 72.5316 44.2916 73.8269 42.6956 73.8269Z" fill="currentColor"/>
      <path d="M42.6956 27.5652C41.0996 27.5652 39.8043 26.2699 39.8043 24.6739V18.8913C39.8043 17.2953 41.0996 16 42.6956 16C44.2916 16 45.5869 17.2953 45.5869 18.8913V24.6739C45.5869 26.2699 44.2916 27.5652 42.6956 27.5652Z" fill="currentColor"/>
      <path d="M68.7174 47.806H62.9348C61.3388 47.806 60.0435 46.5107 60.0435 44.9147C60.0435 43.3187 61.3388 42.0234 62.9348 42.0234H68.7174C70.3134 42.0234 71.6087 43.3187 71.6087 44.9147C71.6087 46.5107 70.3134 47.806 68.7174 47.806Z" fill="currentColor"/>
      <path d="M22.4565 47.806H16.6739C15.0779 47.806 13.7826 46.5107 13.7826 44.9147C13.7826 43.3187 15.0779 42.0234 16.6739 42.0234H22.4565C24.0525 42.0234 25.3478 43.3187 25.3478 44.9147C25.3478 46.5107 24.0525 47.806 22.4565 47.806Z" fill="currentColor"/>
      <path d="M61.096 66.2025C60.3559 66.2025 59.6157 65.9192 59.049 65.3583L54.9607 61.27C53.8331 60.1423 53.8331 58.3093 54.9607 57.1816C56.0883 56.054 57.9214 56.054 59.049 57.1816L63.1373 61.27C64.2649 62.3976 64.2649 64.2306 63.1373 65.3583C62.5706 65.9192 61.8362 66.2025 61.096 66.2025V66.2025Z" fill="currentColor"/>
      <path d="M28.3836 33.4895C27.6435 33.4895 26.9033 33.2062 26.3366 32.6453L22.2483 28.5512C21.1207 27.4236 21.1207 25.5905 22.2483 24.4629C23.3759 23.3353 25.209 23.3353 26.3366 24.4629L30.4249 28.557C31.5525 29.6846 31.5525 31.5177 30.4249 32.6453C29.864 33.212 29.1238 33.4895 28.3836 33.4895Z" fill="currentColor"/>
      <path d="M57.0077 33.4895C56.2676 33.4895 55.5274 33.2062 54.9607 32.6453C53.8331 31.5177 53.8331 29.6846 54.9607 28.557L59.049 24.4629C60.1766 23.3353 62.0097 23.3353 63.1373 24.4629C64.2649 25.5905 64.2649 27.4236 63.1373 28.5512L59.049 32.6453C58.4823 33.212 57.7479 33.4895 57.0077 33.4895V33.4895Z" fill="currentColor"/>
      <path d="M24.2953 66.2025C23.5552 66.2025 22.815 65.9192 22.2483 65.3583C21.1207 64.2306 21.1207 62.3976 22.2483 61.27L26.3366 57.1816C27.4642 56.054 29.2973 56.054 30.4249 57.1816C31.5525 58.3093 31.5525 60.1423 30.4249 61.27L26.3366 65.3583C25.7757 65.9192 25.0355 66.2025 24.2953 66.2025V66.2025Z" fill="currentColor"/>
      <path d="M138.109 134.544H10.8913C9.2953 134.544 8 133.243 8 131.653C8 130.063 9.2953 128.762 10.8913 128.762H138.109C139.699 128.762 141 130.063 141 131.653C141 133.243 139.699 134.544 138.109 134.544Z" fill="currentColor"/>
      <path d="M48.4782 131.653C46.8822 131.653 45.5869 130.352 45.5869 128.761V99.8483C45.5869 98.2581 46.8822 96.957 48.4782 96.957C50.0742 96.957 51.3695 98.2581 51.3695 99.8483V128.761C51.3695 130.352 50.0742 131.653 48.4782 131.653Z" fill="currentColor"/>
      <path d="M29.228 121.99C28.4878 121.99 27.7476 121.707 27.1809 121.146C26.0533 120.019 26.0533 118.18 27.1809 117.058L59.8931 84.3457C61.0207 83.2181 62.8538 83.2181 63.9814 84.3457C65.109 85.4675 65.109 87.3064 63.9814 88.4282L31.2692 121.146C30.7083 121.707 29.9681 121.99 29.228 121.99V121.99Z" fill="currentColor"/>
      <path d="M83.1739 131.653C81.5837 131.653 80.2826 130.352 80.2826 128.761V99.8483C80.2826 98.2581 81.5837 96.957 83.1739 96.957C84.7641 96.957 86.0652 98.2581 86.0652 99.8483V128.761C86.0652 130.352 84.7641 131.653 83.1739 131.653Z" fill="currentColor"/>
      <path d="M63.9236 121.99C63.1835 121.99 62.4433 121.707 61.8766 121.146C60.749 120.019 60.749 118.18 61.8766 117.058L94.5946 84.3457C95.7164 83.2181 97.5553 83.2181 98.6829 84.3457C99.8047 85.4675 99.8047 87.3064 98.6829 88.4282L65.9649 121.146C65.404 121.707 64.6638 121.99 63.9236 121.99V121.99Z" fill="currentColor"/>
      <path d="M117.87 131.653C116.279 131.653 114.978 130.352 114.978 128.761V99.8483C114.978 98.2581 116.279 96.957 117.87 96.957C119.46 96.957 120.761 98.2581 120.761 99.8483V128.761C120.761 130.352 119.46 131.653 117.87 131.653Z" fill="currentColor"/>
      <path d="M98.6194 121.99C97.8792 121.99 97.139 121.707 96.5723 121.146C95.4505 120.019 95.4505 118.18 96.5723 117.058L129.29 84.3457C130.412 83.2181 132.251 83.2181 133.379 84.3457C134.5 85.4675 134.5 87.3064 133.379 88.4282L100.661 121.146C100.1 121.707 99.3595 121.99 98.6194 121.99Z" fill="currentColor"/>
    </svg>
  </React.Fragment>
)

const icon = createSvgIcon(fragment, 'SolarPanels')

export default icon
