import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_BILLING_CURRENT_STATS } from '@app/constants/myEnergy/billingPeriod'
import { updateBillingCurrentStats } from '@app/actions/myEnergy/billingCurrentStats'
import Api from '@app/api/myEnergy/fetchBillingCurrentStats'
import { API_REQUEST_CANCEL } from '@app/constants/apiRequest'
import { apiRequestSent, apiRequestDone, apiRequestFailed } from '@app/actions/apiRequest'

const fetchBillingCurrentStatsLogic = createLogic({
  type: SYNC_BILLING_CURRENT_STATS,
  cancelType: API_REQUEST_CANCEL,

  process(_, dispatch, done) {
    const { secrets: { accessToken: { token: AccessToken } } } = store.getState()
    const currentDate = new Date()
    dispatch(apiRequestSent(SYNC_BILLING_CURRENT_STATS))
    Api.fetchBillingCurrentStats(currentDate, AccessToken)
      .then((response) => {
        if (response.status === 204) {
          dispatch(apiRequestDone(SYNC_BILLING_CURRENT_STATS))
        } else {
          const { data } = response
          dispatch(updateBillingCurrentStats(data))
          dispatch(apiRequestDone(SYNC_BILLING_CURRENT_STATS))
        }
      })
      .catch((error) => {
        if (error) {
          const currentError = handleErrors(error)
          dispatch(apiRequestFailed(currentError))
        } else {
          throw new Error('fetchBillingCurrentStats failed without error')
        }
      })
      .then(() => done())
  },
})

export default fetchBillingCurrentStatsLogic
