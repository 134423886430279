const calculateRoomActionType = (stateDescription, actionDelay, actionDuration) => {
  if (Object.keys(actionDelay).length) {
    if (Object.keys(actionDuration).length) {
      return 'ControlRoom'
    }
    return 'ControlRoom'
  }
  return 'ControlRoom'
}

export default calculateRoomActionType
