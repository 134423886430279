const getPretextForDescription = (conditionNumber: number): string => {
  if (conditionNumber) {
    return 'If'
  }
  return 'When'
}

const getConditionDescription = (type: string, description: string, conditionDeviceName: string, conditionNumber: number): string => {
  const pretext = getPretextForDescription(conditionNumber)
  switch (type) {
  case 'TwrDevices':
    if (description === 'If') {
      return `${description} ${conditionDeviceName}`
    }
    return `${pretext} ${conditionDeviceName}`
  case 'ZWaveDevices':
    if (description === 'If') {
      return `${description} ${conditionDeviceName}`
    }
    return `${pretext} ${conditionDeviceName}`
  case 'Light':
    return I18n.t(`scene_web.${description}`)
  case 'Dark':
    return I18n.t(`scene_web.${description}`)
  case 'SunWithOffset':
    return I18n.t(`scene_web.${description}`)
  case 'Time':
    return I18n.t(`scene_web.${description}`)
  case 'TimeLimits':
    return I18n.t(`scene_web.${description}`)
  case 'DayOfTheWeek':
    return I18n.t(`scene_web.${description}`)
  case 'Weekdays':
    return I18n.t(`scene_web.${description}`)
  case 'Weekends':
    return I18n.t(`scene_web.${description}`)
  case 'Date':
    return I18n.t(`scene_web.${description}`)
  case 'BetweenDatesSimple':
    return I18n.t(`scene_web.${description}`)
  case 'UserTrigger':
    return 'When I press a button in app'
  case 'AnotherScene':
    return `When ${description}`
  case 'IsInAlarm':
    return `When ${description}`
  case 'Panic':
    return `When ${description}`
  case 'Duress':
    return `When ${description}`
  case 'IfArmed':
    return `If ${description}`
  case 'IfArmedHome':
    return `If ${description}`
  case 'IfDisarmed':
    return `If ${description}`
  case 'WhenFire':
    return `When ${description}`
  case 'WhenMedicalEvent':
    return `If ${description}`
  case 'Chime':
    return `If ${description}`
  case 'IfMainsHasFailed':
    return `If ${description}`
  case 'WhenMainsFail':
    return `When ${description}`
  case 'IfMainsPowerIsPresent':
    return `If ${description}`
  case 'WhenMainsRestores':
    return `When ${description}`
  case 'NotArmed':
    return `If ${description}`
  case 'NotDisarmed':
    return `If ${description}`
  case 'WhenArmed':
    return `When ${description}`
  case 'WhenDisarmed':
    return `When ${description}`
  case 'WhenMezzoSetToHomeMode':
    return `When ${description}`
  case 'WhileInMedicalAlarm':
    return `While ${description}`
  case 'Siren':
    return `If ${description}`
  case 'ZwaveColor':
    return `If ${conditionDeviceName}`
  case 'Geofencing':
    return `If my geofence is ${description}`
  case 'RoomActivity':
    return description
  case 'GroupActivity':
    return description
  case 'ZwaveSensorMultilevel':
    return `${pretext} ${conditionDeviceName}`
  case 'HubLight':
    return `If ${description}`
  case 'IfPanicDuressFire':
    return `If ${description}`
  case 'ZwaveSceneController':
  case 'ZwaveSensorNotification':
    return `When ${conditionDeviceName}`
  case 'ZwaveMultichannel':
    return `If ${conditionDeviceName}`
  case 'TimerExpired':
    return `If Timer ${description}`
  case 'DeviceTimeout':
    return `When Timer ${description}`
  case 'VirtualSwitch':
    return `If Virtual switch ${description}`
  default:
    throw new Error('condition unsupported')
  }
}

export default getConditionDescription
